import './treeSelectionView.scss';

import AutoTextField from 'components/AutoTextfield/AutoTextField';
import { DebouncedCheckbox } from 'components/Common/DebouncedCheckbox';
import { cloneDeep } from 'lodash';
import FilterEntityType from 'models/FilterEntityType';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { useToggle } from 'utils/hooks';
import { TranslateText } from 'utils/Translations';

import { FormControlLabel, Typography } from '@material-ui/core';

import RadioButtonContainer, { ButtonConfig } from '../RadioButtonContainer';
import SelectionUtil from '../SelectionDialog/SelectionUtil';
import SelectionPanel from '../SelectionPanel';
import SelectionTree from '../SelectionTree';
import { ITreeNode } from '../SelectionTree/TreeNode/types';

export interface TreeViewFilter {
	customerId: string;
	filterText: string;
	showPersons: boolean;
	showObjects: boolean;
	showAssets: boolean;
	showDrivers: boolean;
	showInactive: boolean;
}

export interface EntityTypeFilter {
	filterEntityType: FilterEntityType;
	translationText: string;
}

interface Props {
	retrieveDataCallback: (filter: TreeViewFilter) => Promise<ITreeNode[]>;
	selectionChanged: (data: ITreeNode[]) => void;
	filterPlaceholder: string;
	enableClientFilter: boolean;
	displayInactive?: boolean;
	disableShowInactiveFilter?: boolean;
	hasValidations?: boolean;
	buttonContainerVisible: boolean;
	validator?: Validator;
	filters?: EntityTypeFilter[];
	currentSelection?: ITreeNode[];
	hasSecondButton?: boolean;
	secondButtonText?: string;
	secondButtonCallback?: (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => void;
	setDataCallBack?: (data: any) => void;
	isForNewAddWizard?: boolean;
}

const TreeSelectionView = (props: Props, ref: React.Ref<unknown>) => {
	const selectionUtil = SelectionUtil;
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);

	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [selectedItems, setSelectedItems] = useState<ITreeNode[]>([]);
	const [selectedFilter, setSelectedFilter] = useState<FilterEntityType>(
		props.filters && props.filters.length > 0 ? props.filters[0].filterEntityType : FilterEntityType.All
	);
	const [expanded, setExpanded] = useState([] as string[]);
	const [treeData, setTreeData] = useState<ITreeNode[]>([]);
	const [clientTreeData, setClientTreeData] = useState([] as ITreeNode[]);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const [showInactive, setShowInactive] = useToggle(false);

	useImperativeHandle(ref, () => ({
		forceRefetchData: async () => {
			const showPersons = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Person;
			const showObjects = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Object;
			const showAssets = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Asset;
			const showDrivers = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Driver;

			const filter: TreeViewFilter = {
				filterText,
				customerId,
				showPersons,
				showObjects,
				showAssets,
				showInactive,
				showDrivers,
			};

			try {
				//reset tree data
				setTreeData([]);
				const data = await props.retrieveDataCallback(filter);
				setSelectedItems([]);
				setTreeData([]);
				selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
				updateExpandedState(filterText, data);
			} catch (error) {
				setTreeData([]);
			}
		},
	}));

	const updateExpandedState = (filterText: string, data: ITreeNode[]) => {
		if (filterText && filterText.trim().length > 0) {
			setExpanded(data.map((d) => d.id));
		} else if (!filterText || previousFilterText !== filterText) {
			setExpanded([]);
		}
		setPreviousFilterText(filterText?.trim());
	};

	useEffect(() => {
		if (!props.filters) {
			return;
		}
		//set the selected filter to be the first button
		if (props.filters[0] != null) {
			setSelectedFilter(props.filters[0].filterEntityType);
			setSelectedItems([]);
			setFilterText('');
		}
	}, [props.filters]);

	useEffect(() => {
		const showPersons = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Person;
		const showObjects = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Object;
		const showAssets = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Asset;
		const showDrivers = selectedFilter === FilterEntityType.All || selectedFilter === FilterEntityType.Driver;

		const filter: TreeViewFilter = {
			filterText,
			customerId,
			showPersons,
			showObjects,
			showAssets,
			showInactive,
			showDrivers,
		};

		if (props.enableClientFilter) {
			if (clientTreeData.length > 0) {
				let filteredData = cloneDeep(clientTreeData);

				filteredData = filteredData.filter((node) => {
					if (node.items?.length > 0) {
						if (selectedFilter !== FilterEntityType.All) {
							node.items = node.items.filter((t) => {
								const filter = FilterEntityType[selectedFilter];
								const r = t.type === filter;
								return r;
							});
						}

						if (filterText && filterText.trim() !== '') {
							node.items = node.items.filter((t) =>
								TranslateText(t.text)
									.toLowerCase()
									.includes(filterText.trim().toLowerCase())
							);
							node.childCount = node.items.length;
						}

						return node.items.length > 0;
					} else {
						if (filterText && filterText.trim() !== '') {
							return node.text.toLowerCase().includes(filterText.trim().toLowerCase());
						}
						return true;
					}
				});
				selectionUtil.prepareTreeData(filteredData, selectedItems, expanded, setTreeData);
				updateExpandedState(filterText, filteredData);
			} else {
				props
					.retrieveDataCallback(filter)
					.then((data) => {
						setTreeData([]);
						setClientTreeData(data);
						selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
						updateExpandedState(filterText, data);
					})
					.catch(() => setTreeData([]));
			}
		} else {
			props
				.retrieveDataCallback(filter)
				.then((data) => {
					selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
					updateExpandedState(filterText, data);
				})
				.catch(() => setTreeData([]));
		}
	}, [filterText, showInactive, selectedFilter]);

	useEffect(() => {
		if (props.currentSelection) {
			setSelectedItems(props.currentSelection);
		} else {
			setSelectedItems([]);
		}
	}, [props.currentSelection]);

	useEffect(() => {
		//Update tree when selection changes
		selectionUtil.prepareTreeData(treeData, selectedItems, expanded, setTreeData);
		props.selectionChanged(selectedItems);

		if (props.hasValidations) {
			props.validator.validate(selectedItems).then((result) => {
				setValidationResult(result.validationResult);
				if(props.isForNewAddWizard !== null && props.isForNewAddWizard)
					props.setDataCallBack(result.formResult);

			});
		}
	}, [selectedItems, expanded]);

	useEffect(() => {
		if (props.hasValidations) {
			props.validator.validate(selectedItems).then((result) => {
				setValidationResult(result.validationResult);
				if(props.isForNewAddWizard !== null && props.isForNewAddWizard)
					props.setDataCallBack(result.formResult);

			});
		}
	},[]);

	const clearSelection = useCallback(async () => {
		await selectionUtil.clearSelection(treeData, setTreeData, setSelectedItems);
	}, [treeData, selectedItems]);

	const selectCallback = useCallback(
		(node: ITreeNode) => {
			selectionUtil.select(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	const expandCallback = useCallback(
		(node: ITreeNode) => {
			selectionUtil.expandCallback(node, expanded, setExpanded);
		},
		[expanded]
	);

	const removeSelectedItem = useCallback(
		(node: ITreeNode) => {
			selectionUtil.deselectNode(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	const getButtonConfig = (filters: EntityTypeFilter[]) => {
		if (!filters) {
			return [];
		}

		let configs: ButtonConfig[] = [];
		filters.forEach((filter) => {
			const config = {
				title: TranslateText(filter.translationText),
				id: filter.filterEntityType,
			};
			configs = [...configs, config];
		});

		return configs;
	};

	return (
		<>
			<div className={'buttons'}>
				<RadioButtonContainer
					buttons={getButtonConfig(props.filters)}
					visible={props.filters !== null && props.buttonContainerVisible}
					selectedButton={selectedFilter}
					buttonSelectCallback={setSelectedFilter}
				/>
				{!props.disableShowInactiveFilter && (
					<div className={'showInactive'}>
						<FormControlLabel
							control={
								<DebouncedCheckbox
									name="showInactive"
									color={'primary'}
									checked={showInactive}
									onChange={setShowInactive}
									debounceTime={500}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginLeft: 5 }}>
									{TranslateText('fleetSelection.showInactive')}
								</Typography>
							}
						/>
					</div>
				)}
			</div>
			<div className={`report-selection-view ${!!props.filters ? 'has-buttons' : ''}`}>
				<div className={'selection-container'}>
					<AutoTextField
						placeHolder={props.filterPlaceholder}
						value={filterText}
						showClearButton={true}
						debounceDuration={100}
						onChange={(value) => {
							setFilterText(value ?? ''.toLowerCase().trim());
						}}
					/>
					<SelectionTree data={treeData} expandCallback={expandCallback} selectCallback={selectCallback} />
				</div>
				<SelectionPanel
					selection={selectedItems}
					removeAll={clearSelection}
					removeItem={removeSelectedItem}
					hasSecondButton={props.hasSecondButton}
					secondButtonText={props.secondButtonText}
					secondButtonCallback={() => {
						props.secondButtonCallback(treeData, setTreeData, selectedItems, setSelectedItems);
					}}
					hasValidations={props.hasValidations}
					validationResult={validationResult}
				/>
			</div>
		</>
	);
};

export default forwardRef(TreeSelectionView);
