import './searchResults.scss';

import React, { useRef, useState } from 'react';
import { TranslateText } from 'utils/Translations';

import { CircularProgress, Popper } from '@material-ui/core';

import { useClickAndDoubleClick } from '../../shared/effects/useClickAndDoubleClick';
import { SearchResult, SearchTypeEnum } from './SearchComponent';
import SearchResultFollowup from './SearchResultFollowup';

export interface Props {
	isSearching: boolean;
	searchType: SearchTypeEnum;
	selectedResult?: SearchResult;
	searchResults: SearchResult[];
	onSelection: (selection: SearchResult) => void;
	isMaterial?: boolean;
}

const SearchResults = (props: Props) => {
	const anchorDetails = useRef(null);
	const [openDetails, setOpenDetails] = useState(false);

	const clickHandler = useClickAndDoubleClick();

	return (
		<>
			<div className="search-results-container">
				{!props.isMaterial && <div className="title">{TranslateText('topBar.address')}</div>}
				<div className={`content ${props.isMaterial ? ' no-min-height' : ''}`}>
					{props.isSearching ? (
						<div className="loading-container">
							<CircularProgress size={20} color="secondary" />
						</div>
					) : props.searchResults.length ? (
						props.searchResults.map((result) => (
							<div
								key={result.id}
								className={`result-item ${props.selectedResult?.id === result.id ? 'selected' : ''}`}
								onClick={(e) => {
									clickHandler(
										e.currentTarget,
										() => {
											props.onSelection(result);
											setOpenDetails(false);
										},
										props.isMaterial
											? () => {}
											: () => {
													props.onSelection(result);
													anchorDetails.current = e.currentTarget;
													setOpenDetails(true);
											  }
									);
								}}
							>
								<span className="material-icons-outlined icon">place</span>
								<span className="result-text">{result.text}</span>
								{!props.isMaterial &&
									(openDetails && props.selectedResult?.id === result.id ? (
										<span className="material-icons expand-icon">keyboard_double_arrow_left</span>
									) : (
										<span className="material-icons expand-icon">keyboard_double_arrow_right</span>
									))}
							</div>
						))
					) : (
						<div className="no-result">{TranslateText('topBar.noResults')}</div>
					)}
				</div>
			</div>
			<Popper
				className="search-followup-popper"
				open={openDetails}
				anchorEl={anchorDetails.current}
				placement="left-start"
			>
				<SearchResultFollowup followupSearch={props.selectedResult} />
			</Popper>
		</>
	);
};

export default SearchResults;
