import './styles.scss';

import React from 'react';
import { TripAuditChangesEnum } from 'store/HistoryStore';
import { FormatDate } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { Button, Fade, Tooltip } from '@material-ui/core';

import { LastChangeDetails } from './LastChangeDetails';
import { SummaryTabFooterProps } from './types';

const SummaryTabFooter = ({
	renderForTable,
	tripHasUnsavedData,
	isNonTrip,
	lastChangedDateTime,
	useTripTimezone,
	lastChangedByPerson,
	lastTripAuditChanges,
	previousClick,
	nextClick,
	saveClick,
	restoreClick,
}: SummaryTabFooterProps) => {
	const previousButton = () => {
		return (
			<Button className="footer-button" variant="contained" disabled={!previousClick} onClick={previousClick}>
				{tripHasUnsavedData ? `${TranslateText('common.buttonSave')} & ` : ''}
				{TranslateText('common.buttonPrevious')}
			</Button>
		);
	};

	const nextButton = () => {
		return (
			<Button className="footer-button" variant="contained" disabled={!nextClick} onClick={nextClick}>
				{tripHasUnsavedData ? `${TranslateText('common.buttonSave')} & ` : ''}
				{TranslateText('common.buttonNext')}
			</Button>
		);
	};
	const saveButton = () => {
		return (
			<Button className="footer-button" variant="contained" disabled={!saveClick} onClick={saveClick}>
				{TranslateText('common.buttonSave')}
			</Button>
		);
	};

	const restoreButton = () => {
		return (
			<Button
				className="footer-button restore-btn"
				variant="contained"
				disabled={!restoreClick}
				onClick={restoreClick}
			>
				{TranslateText('tripDetailsSection.restore')}
			</Button>
		);
	};

	return (
		<div className={`summary-tab-footer ${renderForTable ? 'render-for-table' : 'summary-footer'}`}>
			<div className={'footer-button-container'}>
				{renderForTable ? (
					<>
						{saveButton()}
						{nextButton()}
						{previousButton()}
						{restoreButton()}
					</>
				) : (
					<>
						{previousButton()}
						{restoreButton()}
						{saveButton()}
						{nextButton()}
					</>
				)}
			</div>
			{!!lastChangedDateTime?.length && (
				<div className={'footer-area'}>
					<Tooltip
						placement={'top'}
						TransitionComponent={Fade}
						disableFocusListener={lastTripAuditChanges === null}
						title={
							<LastChangeDetails
								tripAudit={lastTripAuditChanges}
								toolTipsMessage={isNonTrip ? TranslateText('tripDetailsSection.created') : null}
							/>
						}
					>
						<div className="footer-text">
							{lastTripAuditChanges?.lastChangedAction === TripAuditChangesEnum.Create ||
							lastTripAuditChanges?.lastChangedAction === TripAuditChangesEnum.ReprocessCreate ||
							lastTripAuditChanges === null
								? lastTripAuditChanges?.lastChangedAction === TripAuditChangesEnum.ReprocessCreate
									? TranslateText('tripDetailsSection.reprocessedBy')
									: TranslateText('tripDetailsSection.createdBy')
								: TranslateText('tripDetailsSection.lastChangedBy')}{' '}
							{lastChangedByPerson.personId
								? lastChangedByPerson.personName
								: TranslateText('tripDetailsSection.system')}{' '}
							{FormatDate(new Date(lastChangedDateTime), false, false, useTripTimezone)}
						</div>
					</Tooltip>
				</div>
			)}
		</div>
	);
};

export default SummaryTabFooter;
