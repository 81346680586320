import IdIdentifiable from "./IdIdentifiable";
import LoginReasonNotification from "./LoginReasonNotification";

class InformationNotification extends IdIdentifiable {
	constructor(customerId?: string, personId?: string) {
		super();
		this.customerId = customerId;
		this.urgent = true;
		this.driver = true;
		this.active = true;
		this.startDateTime = new Date();
		this.endDateTime = null;
		this.type = '';
		this.modifiedBy = personId;
		this.createdBy = personId;
		this.lastDateTimeModified = null;
		this.userName = '';
	}

	//base information
	customerId?: string;
	name: string;
	description: string;
	urgent: boolean;
	driver: boolean;
	type: string;
	startDateTime: Date;
	endDateTime?: Date;
	active: boolean;
	createdBy?: string;
	modifiedBy?: string;
	lastDateTimeModified?: Date;
	userName?: string;

	//login reason
	loginReasons:  Record<string, LoginReasonNotification[]> = {};

}

export default InformationNotification;
