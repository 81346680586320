enum ReportRenderFormatEnum {
	pdf = 'pdf',
	xls = 'xls',
	csv = 'csv',
	ssv = 'ssv',
	xlsx = 'xlsx',
	zip = 'zip'
}

export default ReportRenderFormatEnum;
