import { extractTimeZone } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { Group } from 'components/RightSidebarMenu/HistorySideBar';
import { getGrouping } from 'components/RightSidebarMenu/HistoryTripsUtils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { commonDataActionCreators } from 'store/CommonData';
import {
	HISTORY_SET_EXPANDED_SECTIONS,
	ProcessedTripDto,
	SET_DETAILED_TRIPS_ON_MAP,
	SET_SELECTED_TRIPS_IN_OVERVIEW,
	SHOW_HISTORY_TRIP_DETAILS,
} from 'store/HistoryStore';
import SelectionHelper from 'utils/SelectionHelper';

const getTripsFromGroups = (groups: Group[]): ProcessedTripDto[] => {
	let trips: ProcessedTripDto[] = [];
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.trips.length) {
			trips.push(...group.trips);
		}
		if (group.childGroups.length) {
			trips.push(...getTripsFromGroups(group.childGroups));
		}
	}
	trips = trips.filter((d) => !d.isParked);
	return trips;
};

export const useTripsNavigation = (
	tripDetails: ProcessedTripDto,
	historyTrips: ProcessedTripDto[],
	historyGridTrips: ProcessedTripDto[],
	renderForTable: boolean,
	dispatch: Dispatch<Action>
) => {
	const dateFilter = useSelector((state: ApplicationState) => state.historyFilter.dateFilter);
	const expandedSection = useSelector((s: ApplicationState) => s.historyStore.expandedSections);
	const groupingOptions = useSelector((state: ApplicationState) => state.historyStore.grouping);
	const [shownTrips, setShownTrips] = useState<ProcessedTripDto[]>([]);
	const [groupedTrips, setGroupedTrips] = useState<Group[]>([]);
	const [previousTrip, setPreviousTrip] = useState<ProcessedTripDto>(null);
	const [nextTrip, setNextTrip] = useState<ProcessedTripDto>(null);

	useEffect(() => {
		const groupedTripsData = getGrouping(
			historyGridTrips,
			groupingOptions.grouping,
			groupingOptions.ascending,
			historyGridTrips.length,
			dateFilter
		);
		setGroupedTrips(groupedTripsData);
		setShownTrips(getTripsFromGroups(groupedTripsData));
	}, []);

	useEffect(() => {
		const groupedTripsData = getGrouping(
			historyTrips,
			groupingOptions.grouping,
			groupingOptions.ascending,
			historyTrips.length,
			dateFilter
		);
		setGroupedTrips(groupedTripsData);
	}, [groupingOptions]);

	useEffect(() => {
		const groupedTripsData = getGrouping(
			historyGridTrips,
			groupingOptions.grouping,
			groupingOptions.ascending,
			historyGridTrips.length,
			dateFilter
		);
		setShownTrips(getTripsFromGroups(groupedTripsData));
	}, [historyGridTrips, groupingOptions.grouping, groupingOptions.ascending]);

	useEffect(() => {
		if (shownTrips.length) {
			const index = shownTrips.findIndex((t) => t.idWithParent === tripDetails.idWithParent);
			const filteredPreviousList = filteredList(groupingOptions.ascending, index);
			const filteredNextList = filteredList(!groupingOptions.ascending, index);
			setPreviousTrip(
				filteredPreviousList !== undefined ? filteredPreviousList[filteredPreviousList.length - 1] : undefined
			);
			setNextTrip(filteredNextList !== undefined ? filteredNextList[filteredNextList.length - 1] : undefined);
		}
	}, [tripDetails, shownTrips]);

	const filteredList = (ascending: boolean, index: number) => {
		if (ascending) {
			return shownTrips.find((d, i) => i === index - 1) !== undefined
				? historyTrips.filter(
						(ht) => ht.idWithParent === shownTrips.find((d, i) => i === index - 1).idWithParent
				  )
				: undefined;
		} else {
			return shownTrips.find((d, i) => i === index + 1) !== undefined
				? historyTrips.filter(
						(ht) => ht.idWithParent === shownTrips.find((d, i) => i === index + 1).idWithParent
				  )
				: undefined;
		}
	};

	useEffect(() => {
		if (groupedTrips && !renderForTable) {
			expandGroup(tripDetails);
		}
	}, [groupedTrips]);

	const expandGroup = (tripToNavigateTo: ProcessedTripDto) => {
		let parentGroupName = '';
		let childGroupName = '';
		let foundTrip = false;
		for (let i = 0; i < groupedTrips.length; i++) {
			if (foundTrip) {
				break;
			}
			const parentGroup = groupedTrips[i];
			parentGroupName = parentGroup.name;
			childGroupName = '';
			if (parentGroup.childGroups.length) {
				for (let j = 0; j < parentGroup.childGroups.length; j++) {
					const group = parentGroup.childGroups[j];
					childGroupName = group.name;
					if (group.trips.some((t) => t.idWithParent === tripToNavigateTo.idWithParent)) {
						foundTrip = true;
						break;
					}
				}
			} else if (parentGroup.trips.some((t) => t.idWithParent === tripToNavigateTo.idWithParent)) {
				break;
			}
		}
		const parentSection = expandedSection.find((s) => s.group === parentGroupName);
		if (parentSection && !parentSection.expanded) {
			dispatch({
				type: HISTORY_SET_EXPANDED_SECTIONS,
				payload: {
					expanded: true,
					groupName: parentSection.group,
				},
			});
		}
		if (childGroupName) {
			const childGroupSection = expandedSection.find((s) => s.group === parentGroupName + childGroupName);
			if (childGroupSection && !childGroupSection.expanded) {
				dispatch({
					type: HISTORY_SET_EXPANDED_SECTIONS,
					payload: {
						expanded: true,
						groupName: parentGroupName + childGroupName,
					},
				});
			}
		}
	};

	const navigateToTrip = (tripToNavigateTo: ProcessedTripDto) => {
		expandGroup(tripToNavigateTo);
		dispatch({
			type: SHOW_HISTORY_TRIP_DETAILS,
			payload: tripToNavigateTo,
		});
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: SelectionHelper.createNewSelection(
					[],
					tripToNavigateTo.idWithParent,
					false
				),
				selectedFromOverview: false,
			},
		});
		dispatch({
			type: SET_DETAILED_TRIPS_ON_MAP,
			payload: [],
		});
	};

	return { previousTrip, nextTrip, navigateToTrip };
};

export const useTripFullTimeZoneInfo = (tripTimezoneId: string, objectNameWithTimeZoneOffset: string) => {
	const dispatch = useDispatch();

	const timezonesDataSource = useSelector((state: ApplicationState) => state.commonData.timezonesDataSource);
	const timezonesFetched = useSelector((state: ApplicationState) => state.commonData.timezonesFetched);

	const [tripTimezone, setTripTimezone] = useState<string>('');

	useEffect(() => {
		if (!objectNameWithTimeZoneOffset) {
			setTripTimezone('');
			return;
		}
		if (!timezonesFetched) {
			dispatch(commonDataActionCreators.fetchTimezones());
		}
	}, [objectNameWithTimeZoneOffset]);

	useEffect(() => {
		if (timezonesDataSource.length && objectNameWithTimeZoneOffset) {
			const objectTimezoneOffset = extractTimeZone(objectNameWithTimeZoneOffset);
			const fullTimeZoneName = timezonesDataSource.find((t) => t.id === tripTimezoneId)?.display;
			if (fullTimeZoneName) {
				const timeZoneNameWithoutOffset = fullTimeZoneName.substring(fullTimeZoneName.indexOf(')') + 1);
				setTripTimezone(objectTimezoneOffset + timeZoneNameWithoutOffset);
			}
		}
	}, [tripTimezoneId, timezonesDataSource.length, objectNameWithTimeZoneOffset]);

	return { tripTimezone };
};
