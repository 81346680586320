import * as GlobalSettings from 'GlobalSettings.json';
import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationState } from 'store';
import { notificationsActionCreators, NotificationsStatus } from 'store/NotificationsStore';
import ajaxUtil from 'utils/Ajax';
import { queryClientKeys } from 'utils/queryClientKeys';

import { AlertNotification } from './types';

export const useAlertsNotificationNumber = (opened: boolean) => {
	//alerts notification number
	const { data } = useQuery(
		queryClientKeys.ALERTS_NOTIFICATION_NUMBER,
		async () => {
			return ajaxUtil.get<{ alertsNotificationNumber: number }>(
				`${GlobalSettings.notificationsMaintenanceApi}/getAlertsNotificationNumber`
			);
		},
		{
			initialData: { alertsNotificationNumber: 0 },
		}
	);

	//update alerts notification
	const queryClient: QueryClient = useQueryClient();
	const updateAlertsNotification = () => {
		if (opened) {
			queryClient.invalidateQueries(queryClientKeys.ALERTS_NOTIFICATION);
		} else {
			queryClient.invalidateQueries(queryClientKeys.ALERTS_NOTIFICATION_NUMBER);
			queryClient.invalidateQueries(queryClientKeys.ALERTS_NOTIFICATION);
		}
	};

	const receivedAlertsNotification = useSelector(
		(s: ApplicationState) => s.notificationsStore.receivedAlertsNotification
	);
	useEffect(() => {
		if (receivedAlertsNotification.update) {
			updateAlertsNotification();
		}
	}, [receivedAlertsNotification]);

	const notifStatus = useSelector((s: ApplicationState) => s.notificationsStore.notificationsStatus);
	const previousStatus = useRef<NotificationsStatus>(NotificationsStatus.NotSet);
	useEffect(() => {
		if (
			previousStatus.current === NotificationsStatus.Disconnected &&
			notifStatus === NotificationsStatus.Connected
		) {
			updateAlertsNotification();
		}
		previousStatus.current = notifStatus;
	}, [notifStatus]);

	//set notification as seen
	const isWaiting = useRef<boolean>(false);
	const seenNotifications = useRef<string[]>([]);
	const connectionId = useSelector((s: ApplicationState) => s.notificationsStore.connectionId);
	const connectionIdRef = useRef(connectionId);
	connectionIdRef.current = connectionId;
	const setNotificationAsSeen = useCallback((id: string) => {
		if (!seenNotifications.current.some((x) => x === id)) {
			seenNotifications.current.push(id);
		}

		if (!isWaiting.current) {
			isWaiting.current = true;
			setTimeout(() => {
				const ids = [...seenNotifications.current];
				seenNotifications.current = [];
				isWaiting.current = false;

				ajaxUtil
					.post<{ success: boolean }>(
						`${GlobalSettings.notificationsMaintenanceApi}/setSeenAlertsNotification`,
						{ notificationIds: ids, connectionId: connectionIdRef.current }
					)
					.then((result) => {
						if (result.success) {
							const alerts = queryClient.getQueryData<AlertNotification[]>(
								queryClientKeys.ALERTS_NOTIFICATION
							);

							let unseenNumber = 0;
							for (let i = 0; i < alerts.length; i++) {
								if (ids.some((id) => id === alerts[i].id)) {
									alerts[i] = { ...alerts[i], isSeen: true };
								} else {
									unseenNumber++;
								}
							}

							queryClient.setQueryData(queryClientKeys.ALERTS_NOTIFICATION, [...alerts]);
							queryClient.setQueryData(queryClientKeys.ALERTS_NOTIFICATION_NUMBER, {
								alertsNotificationNumber: unseenNumber,
							});
						}
					});
			}, 500);
		}
	}, []);

	return { alertsNotificationNumber: data.alertsNotificationNumber, setNotificationAsSeen };
};

export const useRedirectToUrl = (): ((url: string) => void) => {
	const history = useHistory();
	const [redirectUrl, setRedirectUrl] = useState('');
	useEffect(() => {
		if (redirectUrl) {
			history.push(redirectUrl);
			setRedirectUrl('');
		}
	}, [redirectUrl]);

	return setRedirectUrl;
};
