import './buttonWidget.scss';

import { ButtonConfig } from 'components/RadioButtonContainer/RadioButtonContainer';
import GlobalSettings from 'GlobalSettings.json';
import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import { groupMembersSelectionActionCreators } from 'store/GroupMembersSelectionStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';
import GroupMemberSelectionUtil from 'utils/TreeSelection/GroupMemberSelectionUtil';
import TreeConfigurationUtil from 'utils/TreeSelection/TreeConfigurationUtil';

import { Button } from '@material-ui/core';

import { ITreeNode } from '../../SelectionTree/TreeNode/types';
import TreeSelectionDialog from '../../TreeSelectionDialog';
import { GroupMember } from '../Views/GroupMembersView';
import { IActionButton } from './ButtonWidget';

interface SelectionInfo {
	id?: string;
	groupId: string;
	memberId: string;
	type: string;
}

interface SelectionDataFilter {
	customerId: string;
	showInactive: boolean;
	filterText: string;
	selectedFilter: FleetEntityTypeFilter;
}

const mapToTreeNode = (data: any[]): ITreeNode[] => {
	const treeNodeSelection: ITreeNode[] = [];
	const groupMemberList = data as GroupMember[];
	if (groupMemberList) {
		groupMemberList.forEach((item: GroupMember) => {
			treeNodeSelection.push({
				id: item.memberId,
				text: item.name,
				icon: item.icon,
				active: item.active,
				type: item.type,
				selected: true,
				items: [],
				childCount: 0,
				canBeChecked: true,
			});
		});
	}
	return treeNodeSelection;
};

type Props = IActionButton;

const AddGroupMembersButton = (props: Props) => {
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);
	const showInactiveMembers = useSelector(
		(state: ApplicationState) => state.groupMembersSelection.showInactiveMembers
	);

	const [filterButtons, setFilterButtons] = useState<ButtonConfig[]>([]);
	useEffect(() => {
		setFilterButtons(
			TreeConfigurationUtil.getGroupMembersButtons(
				featuresSettings.driverIdentification,
				featuresSettings.assetTracking,
				user
			)
		);
	}, [featuresSettings.driverIdentification, featuresSettings.assetTracking, user]);

	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setInitialTreeData(mapToTreeNode(props.currentSelection));
	}, [props.currentSelection]);

	const [dialogVisible, setDialogVisible] = useState(false);
	const events = {
		closeDialog: () => {
			setDialogVisible(false);
		},
		saveSelectionCallback: (selection: ITreeNode[]) => {
			const removedEntries: SelectionInfo[] = [];
			const addedEntries: SelectionInfo[] = [];
			const currentSelection = props.currentSelection as GroupMember[];

			//get removed entries
			if (currentSelection) {
				currentSelection.forEach((item) => {
					if (!selection.some((x) => x.id === item.memberId)) {
						removedEntries.push({
							id: item.id,
							groupId: props.entityId,
							memberId: item.memberId,
							type: item.type,
						});
					}
				});
			}

			//get added entries
			selection.forEach((item) => {
				if (!currentSelection || !currentSelection.some((x) => x.memberId === item.id)) {
					addedEntries.push({
						groupId: props.entityId,
						memberId: item.id,
						type: item.type,
					});
				}
			});

			//update connections
			if (addedEntries.length || removedEntries.length) {
				ajaxUtil
					.post(`${GlobalSettings.groupsMaintenanceApi}/updateGroupMemberConnection`, {
						newConnections: addedEntries,
						removedConnections: removedEntries,
					})
					.then(() => {
						setDialogVisible(false);
						props.actionExecuted(true);
					});
			} else {
				setDialogVisible(false);
				props.actionExecuted(true);
			}
		},
		retrieveData: useCallback(
			(filter: SelectionDataFilter) => {
				let selectedFilter: FleetEntityTypeFilter = FleetEntityTypeFilter.all;
				if (filter.selectedFilter !== null && filter.selectedFilter !== undefined) {
					selectedFilter = filter.selectedFilter;
				}

				dispatch(groupMembersSelectionActionCreators.setShowInactiveMembers(filter.showInactive));

				return ajaxUtil
					.post<ITreeNode[]>(`${GlobalSettings.groupsMaintenanceApi}/GetGroupPersonSelectionData`, {
						customerId: filter.customerId,
						showInactive: filter.showInactive,
						filterText: filter.filterText,
						showObjects: TreeConfigurationUtil.getShowObjects(selectedFilter),
						showPersons: TreeConfigurationUtil.getShowPersons(
							selectedFilter,
							featuresSettings.driverIdentification
						),
						objectFunction: TreeConfigurationUtil.getObjectFunctionFilter(
							selectedFilter,
							featuresSettings.assetTracking
						),
						personType: PersonTypeFilterEnum.Driver,
					})
					.then((data) => {
						if (data) {
							//hide current group
							for (let i = 0; i < data.length; i++) {
								if (data[i].id === props.entityId) {
									data[i].hidden = true;
									break;
								}
							}
							return data;
						}
						return [];
					});
			},
			[featuresSettings.driverIdentification]
		),
	};

	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionDialog
				title={TranslateText('common.addGroupMembers')}
				filterPlaceholder={TranslateText('fleetSelection.searchEntitiesPlaceholder')}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveSelectionCallback}
				retrieveData={events.retrieveData}
				selectionUtil={GroupMemberSelectionUtil}
				filterButtons={filterButtons}
				showInactive={showInactiveMembers}
			/>
		</>
	);
};

export default AddGroupMembersButton;
