import './styles.scss';

import AutoTextField from 'components/AutoTextfield/AutoTextField';
import { DebouncedCheckbox } from 'components/Common/DebouncedCheckbox';
import GridListSelection from 'components/GridListSelection';
import SelectionListPanel from 'components/SelectionListPanel';
import React, { useState } from 'react';
import { useToggle } from 'utils/hooks';
import { TranslateText } from 'utils/Translations';

import { FormControlLabel, Typography } from '@material-ui/core';

import { ListSelectionViewProps } from './types';

const ListSelectionView = (props: ListSelectionViewProps) => {
	const [showInactive, setShowInactive] = useToggle(false);
	const [filterText, setFilterText] = useState('');

	return (
		<div className="list-selection-view">
			<div className={'buttons'}>
				{!props.disableShowInactiveFilter && (
					<div className={'showInactive'}>
						<FormControlLabel
							control={
								<DebouncedCheckbox
									name="showInactive"
									color={'primary'}
									checked={showInactive}
									onChange={setShowInactive}
									debounceTime={500}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginLeft: 5 }}>
									{TranslateText('fleetSelection.showInactive')}
								</Typography>
							}
						/>
					</div>
				)}
			</div>
			<div className={'list-selection-container'}>
				<div className={'selection-container'}>
					<AutoTextField
						placeHolder={props.filterPlaceholder}
						value={filterText}
						showClearButton={true}
						debounceDuration={100}
						onChange={(value) => {
							setFilterText(value ?? ''.toLowerCase().trim());
						}}
					/>
					<GridListSelection
						selection={props.selection}
						filterText={filterText}
						showInactive={showInactive}
						onSelectionToggle={props.onSelectionToggle}
						columns={props.columns}
						initialFilters={props.initialFilters}
						getGridDataUrl={props.getGridDataUrl}
					/>
				</div>
				<SelectionListPanel
					selection={props.selection}
					removeAll={props.removeAllSelection}
					removeItem={props.removeSelection}
				/>
			</div>
		</div>
	);
};

export default ListSelectionView;
