import './rietveld-menu.scss';

import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { gridActionCreators } from 'store/GridOverview';

import { Divider, ListItem, ListItemText } from '@material-ui/core';

import ClaimType from '../../../authorization/ClaimType';
import EasyTrackFeature from '../../../authorization/EasyTrackFeature';
import BackToMainMenu from './BackToMainMenu';
import LinkHighlighted from './LinkHighlighted';
import ProtectedContainer from './ProtectedContainer';

enum MenuType {
	None,
	Config,
}

interface MenuProps {
	requestMenu: (menu: MenuType) => void;
	showDrivers: boolean;
	showConfigTracking: boolean;
	showConfigBilling: boolean;
	showConfigCommunity: boolean;
	showInformationNotifications: boolean;
}

const ConfigMenu = (props: MenuProps) => {
	const dispatch = useDispatch();
	const updateGridFromMenu = useCallback(
		(entityType: EntityTypeEnum) => dispatch(gridActionCreators.updateGridFromMenu(entityType, true)),
		[]
	);
	return (
		<div className={'config-menu'}>
			<ProtectedContainer>
				<BackToMainMenu requestMenu={props.requestMenu} />
			</ProtectedContainer>
			{props.showConfigTracking ? (
				<ProtectedContainer>
					<div className="sidebar-item-category">
						<Trans>menu.configMenu.tracking</Trans>
					</div>
				</ProtectedContainer>
			) : null}
			<ProtectedContainer claim={ClaimType.Devices}>
				<LinkHighlighted
					to="/devices"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Device)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.trackingSection.devices</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.SimCards}>
				<LinkHighlighted
					to="/simcards"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.SimCard)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.trackingSection.simCards</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Objects}>
				<LinkHighlighted
					to="/objects"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Object)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.trackingSection.objects</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Templates} feature={EasyTrackFeature.Templates}>
				<LinkHighlighted
					to="/templates"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Template)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.templates</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			{props.showConfigCommunity ? (
				<>
					<Divider className="sidebar-item-separator" />
					<ProtectedContainer>
						<div className="sidebar-item-category">
							<Trans>menu.configMenu.community</Trans>
						</div>
					</ProtectedContainer>
				</>
			) : null}
			<ProtectedContainer claim={ClaimType.Locations} feature={EasyTrackFeature.Locations}>
				<LinkHighlighted
					to="/locations"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Location)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.locations</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Groups}>
				<LinkHighlighted
					to="/groups"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Group)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.groups</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Persons}>
				<LinkHighlighted
					to="/persons"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Person)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.persons</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			{props.showDrivers ? (
				<ProtectedContainer claim={ClaimType.Drivers}>
					<LinkHighlighted
						to="/drivers"
						className="link-style"
						onClick={() => updateGridFromMenu(EntityTypeEnum.Driver)}
					>
						<ListItem>
							<ListItemText className="e-text-submenu">
								<Trans>menu.configMenu.communitySection.drivers</Trans>
							</ListItemText>
						</ListItem>
					</LinkHighlighted>
				</ProtectedContainer>
			) : null}
			<ProtectedContainer claim={ClaimType.Roles}>
				<LinkHighlighted
					to="/roles"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Role)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.roles</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Keys} feature={EasyTrackFeature.DriverIdentification}>
				<LinkHighlighted
					to="/keys"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Key)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.keys</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Alerts} feature={EasyTrackFeature.Alerts}>
				<LinkHighlighted
					to="/alerts"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Alert)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.alerts</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Integration} feature={EasyTrackFeature.Integration}>
				<LinkHighlighted
					to="/integrations"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Integration)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.integrations</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer
				claim={ClaimType.ConfigurationGroup}
				features={[EasyTrackFeature.DriverStyleScores, EasyTrackFeature.MediaFootage]}
				featureCheckOptions={false}
			>
				<LinkHighlighted
					to="/configurationGroups"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.ConfigurationGroup)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.configurationGroups</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			<ProtectedContainer claim={ClaimType.Translations}>
				<LinkHighlighted
					to="/translations"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Translation)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.communitySection.translations</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			{props.showConfigBilling ? (
				<>
					<Divider className="sidebar-item-separator" />
					<ProtectedContainer>
						<div className="sidebar-item-category">
							<Trans>menu.configMenu.billing</Trans>
						</div>
					</ProtectedContainer>
				</>
			) : null}
			<ProtectedContainer claim={ClaimType.Customers}>
				<LinkHighlighted
					to="/customers"
					className="link-style"
					onClick={() => updateGridFromMenu(EntityTypeEnum.Customer)}
				>
					<ListItem>
						<ListItemText className="e-text-submenu">
							<Trans>menu.configMenu.billingSection.customers</Trans>
						</ListItemText>
					</ListItem>
				</LinkHighlighted>
			</ProtectedContainer>
			{props.showInformationNotifications ? (
				<ProtectedContainer claim={ClaimType.InformationNotificationManage}>
					<LinkHighlighted
						to="/informationNotifications"
						className="link-style"
						onClick={() => updateGridFromMenu(EntityTypeEnum.InformationNotification)}
					>
						<ListItem>
							<ListItemText className="e-text-submenu">
								<Trans>menu.configMenu.billingSection.informationNotifications</Trans>
							</ListItemText>
						</ListItem>
					</LinkHighlighted>
				</ProtectedContainer>
			) : null}
		</div>
	);
};

export default ConfigMenu;
