import { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { getI18n } from 'react-i18next';

// use this function to translate resources that are not a React Component, e.g.
// plain object properties
export function TranslateText(key: string): string {
	const i18n = getI18n();
	return i18n ? i18n.t(key) : key;
}

export function TranslateWithFailText(key: string, failText: string): string {
	const i18n = getI18n();
	if (i18n) {
		const translation = i18n.t(key);
		if (translation !== key) {
			return translation;
		}
	}
	return failText;
}

export function TranslateTextInterpolated(key: string, interpolatedStrings: string[]): string {
	let resource = TranslateText(key);

	for (let index = 0; index < interpolatedStrings.length; index++) {
		const element = interpolatedStrings[index];

		const replacedString = '[' + index + ']';

		resource = resource.replace(replacedString, element);
	}

	return resource;
}

export function TranslateMonth(monthNumber: number): string {
	switch (monthNumber) {
		case 1:
			return TranslateText('fields.months.january');
		case 2:
			return TranslateText('fields.months.february');
		case 3:
			return TranslateText('fields.months.march');
		case 4:
			return TranslateText('fields.months.april');
		case 5:
			return TranslateText('fields.months.may');
		case 6:
			return TranslateText('fields.months.june');
		case 7:
			return TranslateText('fields.months.july');
		case 8:
			return TranslateText('fields.months.august');
		case 9:
			return TranslateText('fields.months.september');
		case 10:
			return TranslateText('fields.months.october');
		case 11:
			return TranslateText('fields.months.november');
		case 12:
			return TranslateText('fields.months.december');
		default:
			return '';
	}
}

export const getMultiSelectionEnumColumn = (
	enumType: { [key: string]: string | number },
	prefixKey: string,
	sort = false
): { name: string; code: string }[] => {
	const result = Object.keys(enumType)
		.filter((key) => isNaN(Number(key)))
		.map((key) => {
			const translationKey = prefixKey + key;
			const translation = TranslateText(translationKey);
			return {
				name: translation !== translationKey ? translation : key,
				code: enumType[key].toString(),
			};
		});

	if (sort) {
		return result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
	}

	return result;
};

export const getAutocompleteEnumOptions = (
	enumType: { [key: string]: string | number },
	prefixKey: string,
	sort = false
): AutocompleteItem[] => {
	const result = Object.keys(enumType)
		.filter((key) => isNaN(Number(key)))
		.map((key) => {
			const translationKey = prefixKey + key;
			const translation = TranslateText(translationKey);
			return {
				display: translation !== translationKey ? translation : key,
				id: enumType[key],
			};
		});

	if (sort) {
		return result.sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0));
	}

	return result;
};
