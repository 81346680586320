import { Icon } from '@material-ui/core';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef } from 'react';
import { TranslateText } from 'utils/Translations';

import { useTerminateConnection } from './hooks';
import { AlertData, AlertEntitiesViewProps, AlertEntity } from './types';
import { AlertEntity as AlertEntityComponent } from './AlertEntity';

const AlertEntitiesView = (props: AlertEntitiesViewProps): JSX.Element => {
	const alertData: React.MutableRefObject<AlertData> = useRef<AlertData>();
	alertData.current = props.data as AlertData;

	useEffect(() => {
		props.setUrl(props.getUrl);
	}, []);

	const terminateConnectionCallback = useTerminateConnection(
		alertData.current.id,
		alertData.current.trackedEntities,
		props.updateUrl,
		props.reloadDataCallback
	);

	return (
		<DataTable
			value={alertData.current.trackedEntities}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: AlertEntity) => <AlertEntityComponent {...rowData} editMode={props.editMode} />}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: AlertEntity) =>
					props.editMode && (
						<Icon className="clear-icon" onClick={() => terminateConnectionCallback(rowData)}>
							clear
						</Icon>
					)
				}
			/>
		</DataTable>
	);
};

export default AlertEntitiesView;
