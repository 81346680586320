import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import * as GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import { Person } from 'models/Person';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import {
	ConflictConfirmation,
	Props as ConflictConfirmationProps,
} from '../ConnectionConflictsNotifier/ConflictConfirmation';
import { CustomerPersonSettings } from '../Widgets/PersonObjectWidget/AddConnection';
import PersonLoginData from './Person/Forms/PersonLoginData';
import PersonPersonalData from './Person/Forms/PersonPersonalData';
import PersonSystemInformation from './Person/Forms/PersonSystemInformation';
import { getCommonValidationRules } from './Person/PersonUtils';
import RenderForEnum from './RenderForEnum';
import UsedFromEnum from './UsedFromEnum';
import WidgetStateEnum from './WidgetStateEnum';

const mapState = (state: ApplicationState) => {
	return {
		entityDefaultsSettings: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.entityDefaultsSettings
			: state.currentSession.customer.entityDefaultsSettings,
		customerLevel: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel,
		user: state.oidc.user,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	setPerson: (person: Person, unsavedData: boolean) => void;
	objectsData: AutocompleteItem[];
	usedFrom: UsedFromEnum;
	readOnly: boolean;
	renderFor: RenderForEnum;
	widgetState: WidgetStateEnum;
	defaultCustomerId: string;
	defaultCustomerSettings?: CustomerPersonSettings;
	allowCustomerChange: boolean;
	addNew: boolean;
};

interface State {
	person: Person;
	unsavedData: boolean;
	selectedAccordionIndex: number;
	validationResult?: ValidationResult;
	conflictConfirmationProps: ConflictConfirmationProps;
	isLoading: boolean;
}

class PersonView extends BaseView<Props, State> {
	initialPerson: Person;

	validator: Validator;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
		usedFrom: UsedFromEnum.Person,
		defaultCustomerSettings: null as CustomerPersonSettings,
		addNew: false,
	};

	constructor(props: Props) {
		super(props);

		const person = new Person(props.defaultCustomerId, props.customerLevel);
		person.driver = this.props.renderFor === RenderForEnum.ConnectDialog;

		this.state = {
			person: person,
			unsavedData: false,
			selectedAccordionIndex: 0,
			conflictConfirmationProps: {
				visible: false,
				confirmCallback: null,
				canSolveConflicts: true,
				conflictData: [],
				connectionType: ConnectionTypeEnum.PersonObject,
			},
			isLoading: false,
		};

		this.validator = getCommonValidationRules(this.props.user);
		this.validator.addRules({
			addInGroups: {
				rules: {
					warningNotEmpty: {
						message: TranslateText('fieldsValidations.addInGroupsWarning'),
						validationFunction: (data) => {
							const personData = data as Person;
							return ValidatorFunctions.wrapInPromise(personData.addInGroups?.length > 0);
						},
						disabled: !ClaimUtil.validateClaim(this.props.user, {
							claim: ClaimType.Groups,
							values: [ClaimValue.edit],
						}),
						isWarning: true,
					},
				},
			},
			username: {
				rules: {
					checkReservedKeywords: ValidatorFunctions.checkReservedKeywords(),
					requiredIfFieldNotNull: ValidatorFunctions.requiredIfFieldNotNull('password'),
					maxLength: ValidatorFunctions.maxLength(50),
					alphaNumeric: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.onlyAlphaNumeric(),
						TranslateText('fieldsValidations.onlyalphanumeric')
					),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							const personData = data as Person;

							if (!data[fieldName]) {
								return ValidatorFunctions.wrapInPromise(true);
							}

							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
								customerId: personData.customerId,
								editedEntityId: personData.id ? personData.id : null,
								value: data[fieldName],
								Field: 'username',
							});
						},
					},
				},
				dependencies: ['password'],
			},
		});
		this.validator.addRules(
			this.props.usedFrom === UsedFromEnum.Object
				? {
						password: {
							rules: {
								requiredIfFieldNotNull: ValidatorFunctions.requiredIfFieldNotNull('username'),
								noEmptySpace: ValidatorFunctions.noEmptySpace(),
								maxLength: ValidatorFunctions.maxLength(50),
								minLength: ValidatorFunctions.minLength(6),
							},
							dependencies: ['username'],
						},
				  }
				: {
						objectId: {
							rules: {
								anyConflicts: {
									message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
										TranslateText('connections.object'),
									]),
									validationFunction: (data, fieldName) => {
										const personData = data as Person;
										return this.checkObjectConnectionConflicts(personData);
									},
									disabled: !ClaimUtil.validateHasClaim(this.props.user, ClaimType.Objects),
								},
							},
						},
						password: {
							rules: {
								requiredIfFieldNotNull: ValidatorFunctions.requiredIfFieldNotNull('username'),
								noEmptySpace: ValidatorFunctions.noEmptySpace(),
								maxLength: ValidatorFunctions.maxLength(50),
								minLength: ValidatorFunctions.minLength(6),
							},
							dependencies: ['username'],
						},
				  }
		);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.props.defaultCustomerId !== prevProps.defaultCustomerId) {
			this.setState({
				person: { ...this.state.person, customerId: this.props.defaultCustomerId },
			});
		}

		if (this.state.person !== prevState.person) {
			this.props.setPerson(this.state.person, this.state.unsavedData);
		}
	}

	public prepareViewWithData(customerId: string, objectId?: string, objectName?: string) {
		if (this.state.person) {
			const person = { ...this.state.person };
			person.customerId = customerId ? customerId : person.customerId;
			person.objectId = objectId ? objectId : person.objectId;
			person.objectName = objectName ? objectName : person.objectName;

			if (!person.code && this.props.defaultCustomerSettings.autoGeneratePersonCode) {
				const url = `${GlobalSettings.driversMaintenanceApi}/generatePersonCode/${person.customerId}`;
				ajaxUtil.get(url).then((id: any) => {
					person.code = id.toString();
					if (!person.externalCode && this.props.defaultCustomerSettings.externalCodeSameCode) {
						person.externalCode = id.toString();
					}

					this.setState({ person: person });
				});
			} else {
				this.setState({ person: person });
			}
		}

		this.focusDefaultInput();
	}

	public focusDefaultInput() {
		setTimeout(() => {
			const focusElement = document.getElementById('person-code');
			if (focusElement) {
				focusElement.focus();
			}
		}, 400);
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.person).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	public clearView() {
		const person = new Person(this.props.defaultCustomerId, this.props.customerLevel);
		person.driver = this.props.renderFor === RenderForEnum.ConnectDialog;
		this.setState({ person: person, selectedAccordionIndex: 0, unsavedData: false });

		this.validator.clearValidation();
	}

	private async checkObjectConnectionConflicts(personData: Person): Promise<boolean> {
		if (personData.objectId === null || personData.objectId === undefined || personData.objectId === '') {
			return ValidatorFunctions.wrapInPromise(true);
		}

		const candidateConnection = {
			entity1Id: personData.id ? personData.id : null,
			entity2Id: personData.objectId,
			connectionStartDate: new Date(),
			connectionType: ConnectionTypeEnum.PersonObject,
		};

		this.setState({ isLoading: true });
		const url = GlobalSettings.connectionsApi + '/ValidateEntitiesCandidateConnection';
		const result: any = await ajaxUtil.post(url, candidateConnection);
		this.setState({ isLoading: false });

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				this.setState({
					conflictConfirmationProps: {
						visible: true,
						canSolveConflicts: result.fixableConflict,
						connectionType: ConnectionTypeEnum.PersonObject,
						conflictData: result.conflictingConnections,
						confirmCallback: (confirm) => {
							this.setState({
								conflictConfirmationProps: { ...this.state.conflictConfirmationProps, visible: false },
							});
							resolve(confirm);
						},
					},
				});
			});
		} else {
			return ValidatorFunctions.wrapInPromise(true);
		}
	}

	handleValueChange(changedPerson: Person) {
		if (!changedPerson.username && !!this.state.person.username) {
			changedPerson.password = '';
		}

		this.setState({
			unsavedData: true,
			person: changedPerson,
		});
	}

	render() {
		return (
			<div>
				<ConflictConfirmation {...this.state.conflictConfirmationProps} />
				<Accordion
					expandedCallback={(index) => {
						this.setState({
							selectedAccordionIndex: index,
						});
					}}
				>
					<AccordionSection
						expanded={this.state.selectedAccordionIndex === 0}
						header={TranslateText('common.systemInformation')}
					>
						<PersonSystemInformation
							disabledDriver={true}
							disabledActive={true}
							user={this.props.user}
							readOnly={this.props.readOnly}
							defaultCustomerId={this.state.person.customerId}
							entityDefaultsSettings={this.props.entityDefaultsSettings}
							handleValueChange={this.handleValueChange.bind(this)}
							person={this.state.person}
							validationResult={this.state.validationResult}
							editableObject={this.props.usedFrom !== UsedFromEnum.Object}
							objectsData={this.props.objectsData}
							showAddToGroupsFied={
								this.props.renderFor === RenderForEnum.ConnectDialog &&
								ClaimUtil.validateClaim(this.props.user, {
									claim: ClaimType.Groups,
									values: [ClaimValue.edit],
								})
							}
							isLoading={this.state.isLoading}
						/>
					</AccordionSection>
					<AccordionSection
						expanded={this.state.selectedAccordionIndex === 1}
						header={TranslateText('common.personalData')}
					>
						<PersonPersonalData
							readOnly={this.props.readOnly}
							handleValueChange={this.handleValueChange.bind(this)}
							person={this.state.person}
							validationResult={this.state.validationResult}
						/>
					</AccordionSection>
					<AccordionSection
						expanded={this.state.selectedAccordionIndex === 2}
						header={TranslateText('common.loginData')}
					>
						<PersonLoginData
							readOnly={this.props.readOnly}
							customerLevel={this.props.customerLevel}
							handleValueChange={this.handleValueChange.bind(this)}
							person={this.state.person}
							validationResult={this.state.validationResult}
						/>
					</AccordionSection>
				</Accordion>
			</div>
		);
	}
}

export default connector(PersonView);
