import './styles.scss';

import { TreeSelectionIcon } from 'components/Common/Icons/TreeSelectionIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import React from 'react';
import { TranslateText } from 'utils/Translations';

import { SelectionPanelItemProps } from './types';

const SelectionPanelItem = (props: SelectionPanelItemProps): JSX.Element => {
	const { icon } = useTryGetSvgIconFromQueryCache(props.icon);

	return (
		<div className={`selection-panel-item ${!props.active ? 'inactive' : ''}`}>
			<TreeSelectionIcon type={props.type} iconContent={icon.content} />
			<span className="name">{TranslateText(props.title)}</span>
			<span className={'m-icon delete'} onClick={props.removeCallback} />
		</div>
	);
};

export default React.memo(SelectionPanelItem);
