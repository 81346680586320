import IdIdentifiable from './IdIdentifiable';

class Device extends IdIdentifiable {
	constructor(defaultProductionDate?: Date, customerId?: string) {
		super();
		this.id = null;
		this.productionDate = defaultProductionDate;
		this.customerId = customerId;
		this.code = '';
	}

	code: string = null;
	customerId: string = null;
	customerDisplay: string = null;
	deviceTypeId: string = null;
	deviceFamily: string = null;
	isTripDevice: boolean = false;
	productionDate: Date;
	serialNumber: string = null;
	firmwareVersion: string = null;
	hardwareVersionNumber: string = null;
	certificateNumber: string = null;
	isCanFmsActive = false;
	authenticationType: number = null;
	authenticationTypeDisplay: string = null;
	disconnectDriverAfterIgnitionOff: boolean = null;
	disconnectDriverAfterIgnitionOffTimeOutSeconds: number = null;
	deviceNeedToBeSyncedWithEd2: boolean = false;
	deviceShouldHaveEd2MediaSupport: boolean = false;
	isTripDeviceChangeConsent: boolean = false;
	isTripDeviceChangeAgreementRequired: boolean = false;
}

export default Device;
