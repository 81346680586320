import './selectionTree.scss';

import React, { useEffect, useRef, useState } from 'react';

import FilterEntityType from '../../models/FilterEntityType';
import { FleetEntityTypeFilter } from '../../store/FleetSelection';
import { TranslateText } from '../../utils/Translations';
import { TreeNode } from './TreeNode';
import { ITreeNode } from './TreeNode/types';
import { FleetSelectionPlaceholder } from '../FleetSelectionPlaceholder';

type Props = {
	data: ITreeNode[];
	entityFilter: FleetEntityTypeFilter;
	filterText?: string;
	expandCallback?: (node: ITreeNode) => void;
	selectCallback?: (node: ITreeNode) => void;
	showPlaceholder?: boolean;
	isForLive?: boolean;
};

const SelectionTree = (props: Props) => {
	const [emptyTree, setEmptyTree] = useState(false);
	const hidden = useRef({ ids: [] });

	useEffect(() => {
		setEmptyTree(
			!props.data ||
				!props.data.length ||
				props.data.every((n) => n.hidden || hidden.current.ids.some((id) => id === n.id))
		);
	}, [props.data]);

	return (
		<div className={'selection-tree'}>
			{props.data.map((node) => {
				return (
					<TreeNode
						{...node}
						childCount={node.items ? node.items.length : 0}
						entityFilter={props.entityFilter}
						filterText={props.filterText}
						key={node.id}
						parent={null}
						expandCallback={props.expandCallback}
						selectCallback={props.selectCallback}
					/>
				);
			})}
			<div hidden={!emptyTree || props.showPlaceholder}>{TranslateText('common.noRecordFound')}</div>
			{props.showPlaceholder && <FleetSelectionPlaceholder isForLive={props.isForLive} />}
		</div>
	);
};
SelectionTree.defaultProps = {
	entityFilter: FilterEntityType.All,
};
export default React.memo(SelectionTree);
