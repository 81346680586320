import { Column } from 'primereact/column';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import { GridWidgetOverview } from '../GridOverview/GridWidgetOverview';

interface Props {
	visible: boolean;
	userAnswearCallback: (sender: ConnectionsConflictResolver, confirm: boolean) => void;
	connectionType: ConnectionTypeEnum;
}

interface State {
	conflictingConnections: object[];
	conflictsCanBeAutomaticallyFixed: boolean;
	showDialog: boolean;
}

class ConnectionConflictsNotifier extends React.PureComponent<Props, State> {
	private sender: ConnectionsConflictResolver;

	constructor(props: Props) {
		super(props);

		this.state = {
			conflictingConnections: null,
			conflictsCanBeAutomaticallyFixed: false,
			showDialog: this.props.visible,
		};
	}

	setConflictingConnectionsContext(conflictingConnections: any, conflictsCanBeAutomaticallyFixed: boolean) {
		this.setState({
			conflictsCanBeAutomaticallyFixed: conflictsCanBeAutomaticallyFixed,
			conflictingConnections: conflictingConnections,
		});
	}

	showDialog(sender?: ConnectionsConflictResolver) {
		this.sender = sender;
		this.setState({ showDialog: true });
	}

	getDialogTextContent(): string {
		if (this.state.conflictsCanBeAutomaticallyFixed) {
			return TranslateText('connections.fixableConnectionConflict');
		} else {
			return TranslateText('connections.nonfixableConnectionConflict');
		}
	}

	getHeaderText(): string {
		switch (this.props.connectionType) {
			case ConnectionTypeEnum.DeviceObject: {
				return TranslateText('connections.objectDeviceConnectionConflict');
			}
			case ConnectionTypeEnum.PersonObject: {
				return TranslateText('connections.objectDriverConnectionConflict');
			}
			case ConnectionTypeEnum.PersonKey: {
				return TranslateText('connections.driverKeyConnectionConflict');
			}
			case ConnectionTypeEnum.DeviceSimCard: {
				return TranslateText('connections.deviceSimCardConnectionConflict');
			}
		}
	}

	getColumnHeaderText(column: string): string {
		if (column === 'displayNameEntity1') {
			switch (this.props.connectionType) {
				case ConnectionTypeEnum.DeviceObject: {
					return TranslateText('widgets.grid.colDeviceCode');
				}
				case ConnectionTypeEnum.PersonObject: {
					return TranslateText('widgets.grid.colDriverCode');
				}
				case ConnectionTypeEnum.PersonKey: {
					return TranslateText('widgets.grid.colDriverCode');
				}
				case ConnectionTypeEnum.DeviceSimCard: {
					return TranslateText('widgets.grid.colDeviceCode');
				}
			}
		}

		if (column === 'displayExtraEntity1') {
			switch (this.props.connectionType) {
				case ConnectionTypeEnum.DeviceObject: {
					return TranslateText('widgets.grid.colSerialNumber');
				}
				case ConnectionTypeEnum.PersonObject: {
					return TranslateText('widgets.grid.colDriverName');
				}
				case ConnectionTypeEnum.PersonKey: {
					return TranslateText('widgets.grid.colDriverName');
				}
				case ConnectionTypeEnum.DeviceSimCard: {
					return TranslateText('widgets.grid.colSerialNumber');
				}
			}
		}
		if (column === 'displayNameEntity2') {
			switch (this.props.connectionType) {
				case ConnectionTypeEnum.DeviceObject: {
					return TranslateText('widgets.grid.colObjectCode');
				}
				case ConnectionTypeEnum.PersonObject: {
					return TranslateText('widgets.grid.colObjectCode');
				}
				case ConnectionTypeEnum.PersonKey: {
					return TranslateText('widgets.grid.colKeyCode');
				}
				case ConnectionTypeEnum.DeviceSimCard: {
					return TranslateText('widgets.grid.colIMSI');
				}
			}
		}

		if (column === 'displayExtraEntity2') {
			switch (this.props.connectionType) {
				case ConnectionTypeEnum.PersonKey: {
					return TranslateText('widgets.grid.colSerialNumber');
				}
				case ConnectionTypeEnum.DeviceSimCard: {
					return TranslateText('widgets.grid.colICCID');
				}
			}
		}
	}

	getColumns() {
		return [
			<Column
				key="displayNameEntity1"
				field="displayNameEntity1"
				className="widget-grid-column"
				header={this.getColumnHeaderText('displayNameEntity1')}
				style={{ width: 100 }}
			/>,
			<Column
				key="displayExtraEntity1"
				field="displayExtraEntity1"
				className="widget-grid-column"
				header={this.getColumnHeaderText('displayExtraEntity1')}
				style={{ width: 150 }}
			/>,
			<Column
				key="displayNameEntity2"
				field="displayNameEntity2"
				className="widget-grid-column"
				header={this.getColumnHeaderText('displayNameEntity2')}
				style={{ width: 100 }}
			/>,
			this.props.connectionType === ConnectionTypeEnum.PersonKey ||
			this.props.connectionType === ConnectionTypeEnum.DeviceSimCard ? (
				<Column
					key="displayExtraEntity2"
					field="displayExtraEntity2"
					className="widget-grid-column"
					header={this.getColumnHeaderText('displayExtraEntity2')}
					style={{ width: 100 }}
				/>
			) : null,
			<Column
				key="connectionStartDate"
				field="connectionStartDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colStartDate')}
				style={{ width: 200 }}
				body={(rowData: any) => {
					return FormatDate(new Date(rowData.connectionStartDate));
				}}
			/>,
			<Column
				key="connectionEndDate"
				field="connectionEndDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colEndDate')}
				style={{ width: 200 }}
				body={(rowData: any) => {
					return rowData.connectionEndDate ? FormatDate(new Date(rowData.connectionEndDate)) : null;
				}}
			/>,
		];
	}

	render() {
		return (
			<Dialog style={{ zIndex: 16000 }} open={this.state.showDialog} maxWidth={'md'}>
				<DialogTitle>{this.getHeaderText()}</DialogTitle>
				<DialogContent>
					<DialogContentText>{this.getDialogTextContent()}</DialogContentText>
					<GridWidgetOverview
						columns={this.getColumns()}
						data={this.state.conflictingConnections}
						total={this.state.conflictingConnections?.length ?? 0}
						paginator={false}
					/>
				</DialogContent>
				<DialogActions>
					{this.state.conflictsCanBeAutomaticallyFixed ? (
						<>
							<Button
								color={'primary'}
								onClick={() => {
									this.setState({ showDialog: false });
									this.props.userAnswearCallback(this.sender, true);
								}}
							>
								{TranslateText('common.buttonYes')}
							</Button>
							<Button
								onClick={() => {
									this.setState({ showDialog: false });
									this.props.userAnswearCallback(this.sender, false);
								}}
							>
								{TranslateText('common.buttonNo')}
							</Button>
						</>
					) : (
						<>
							<Button
								onClick={() => {
									this.setState({ showDialog: false });
									this.props.userAnswearCallback(this.sender, false);
								}}
							>
								{TranslateText('common.buttonOk')}
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConnectionConflictsNotifier;
