import InformationNotification from 'models/InformationNotification';
import IntegrationDto from 'models/IntegrationDto';
import { Person } from 'models/Person';

import Alert from '../models/Alert';
import Customer from '../models/Customer';
import Device from '../models/Device';
import EntityContextData from '../models/EntityContextData';
import EntityTypeEnum from '../models/EntityTypeEnum';
import Group from '../models/Group';
import Key from '../models/Key';
import Role from '../models/Role';
import SimCard from '../models/SimCard';
import Template from '../models/Template';
import TrackableObject from '../models/TrackableObject';

const EDITING_INSTANCE = 'EDITING_INSTANCE';
const TOGGLE_IS_BLOCKING = 'TOGGLE_IS_BLOCKING';

export interface LoadedEntityContext {
	entityContextData: EntityContextData;
	editingInstance: string;
	isBlocking: boolean;
	hasActiveGroupConnections: boolean;
}

const initialState: LoadedEntityContext = {
	entityContextData: new EntityContextData(),
	editingInstance: '',
	isBlocking: false,
	hasActiveGroupConnections: false,
};

export interface LoadedEntityContextDataActions {
	setEditingInstance: (editingInstance: string) => void;
	setIsBlocking: (isBlocking: boolean) => void;
	setLoadedCustomerContext: (loadedCustomer: Customer) => void;
	setLoadedSimCardContext: (loadedSimCard: SimCard) => void;
	setLoadedKeyContext: (loadedKey: Key) => void;
	setLoadedObjectContext: (loadedObject: TrackableObject) => void;
	setLoadedDeviceContext: (loadedDevice: Device) => void;
	setLoadedPersonContext: (loadedPerson: Person) => void;
	setLoadedGroupContext: (loadedGroup: Group) => void;
	setLoadedTemplateContext: (loadedTemplate: Template) => void;
	setLoadedRoleContext: (loadedRole: Role) => void;
	setLoadedAlertContext: (loadedAlert: Alert) => void;
	setLoadedInformationNotificationContext: (loadedNotification: InformationNotification) => void;
	setLoadedIntegrationContext: (loadedIntegration: IntegrationDto) => void;
}

export const loadedEntityContextActionCreators = {
	setEditingInstance: (editingInstance: string) => (dispatch: any) => {
		dispatch({ type: EDITING_INSTANCE, editingInstance });
	},
	setIsBlocking: (isBlocking: boolean) => (dispatch: any) => {
		dispatch({ type: TOGGLE_IS_BLOCKING, isBlocking });
	},
	setLoadedCustomerContext: (loadedCustomer: Customer) => (dispatch: any) => {
		const newCustomerContext = new EntityContextData();

		newCustomerContext.id = loadedCustomer?.id;
		newCustomerContext.customerId = loadedCustomer?.id;
		newCustomerContext.EntityType = EntityTypeEnum?.Customer;
		newCustomerContext.displayText = loadedCustomer?.name;
		newCustomerContext.entityDetails = loadedCustomer;

		dispatch({
			type: 'SET_LOADED_CUSTOMER_CONTEXT',
			context: newCustomerContext,
		});
	},
	setLoadedSimCardContext: (loadedSimCard: SimCard) => (dispatch: any) => {
		const newSimCardContext = new EntityContextData();

		newSimCardContext.id = loadedSimCard?.id;
		newSimCardContext.customerId = loadedSimCard?.customerId;
		newSimCardContext.EntityType = EntityTypeEnum?.SimCard;
		newSimCardContext.displayText = loadedSimCard?.imsi;
		newSimCardContext.entityDetails = loadedSimCard;
		dispatch({
			type: 'SET_LOADED_SIMCARD_CONTEXT',
			context: newSimCardContext,
		});
	},
	setLoadedKeyContext: (loadedKey: Key) => (dispatch: any) => {
		loadedEntityContextActionCreators.dispatchLoadedKeyContext(dispatch, loadedKey);
	},
	setLoadedObjectContext: (loadedObject: TrackableObject) => (dispatch: any) => {
		const newObjectContext = new EntityContextData();

		newObjectContext.id = loadedObject?.id;
		newObjectContext.customerId = loadedObject?.customerId;
		newObjectContext.EntityType = EntityTypeEnum?.Object;
		newObjectContext.displayText = loadedObject?.name;
		newObjectContext.entityDetails = loadedObject;

		dispatch({
			type: 'SET_LOADED_OBJECT_CONTEXT',
			context: newObjectContext,
		});
	},
	setLoadedDeviceContext: (loadedDevice: Device) => (dispatch: any) => {
		const newDeviceContext = new EntityContextData();

		newDeviceContext.id = loadedDevice?.id;
		newDeviceContext.customerId = loadedDevice?.customerId;
		newDeviceContext.EntityType = EntityTypeEnum?.Device;
		newDeviceContext.displayText = loadedDevice?.code;
		newDeviceContext.entityDetails = loadedDevice;

		dispatch({
			type: 'SET_LOADED_DEVICE_CONTEXT',
			context: newDeviceContext,
		});
	},
	setLoadedPersonContext: (loadedPerson: Person) => (dispatch: any) => {
		const newPersonContext = new EntityContextData();

		newPersonContext.id = loadedPerson?.id;
		newPersonContext.customerId = loadedPerson?.customerId;
		newPersonContext.EntityType = EntityTypeEnum?.Person;
		newPersonContext.displayText = loadedPerson?.firstName + ' ' + loadedPerson?.lastName;
		newPersonContext.isActive = loadedPerson?.active;
		newPersonContext.entityDetails = loadedPerson;

		dispatch({
			type: 'SET_LOADED_PERSON_CONTEXT',
			context: newPersonContext,
		});
	},
	setLoadedPersonContextActiveGroupsCount: (count: number) => (dispatch: any) => {
		dispatch({
			type: 'SET_LOADED_PERSON_ACTIVE_GROUPS_COUNT',
			context: count,
		});
	},
	setLoadedGroupContext: (loadedGroup: Group) => (dispatch: any) => {
		const newGroupContext = new EntityContextData();

		newGroupContext.id = loadedGroup?.id;
		newGroupContext.customerId = loadedGroup?.customerId;
		newGroupContext.EntityType = EntityTypeEnum?.Group;
		newGroupContext.displayText = loadedGroup?.name;
		newGroupContext.isActive = loadedGroup?.active;
		newGroupContext.entityDetails = loadedGroup;

		dispatch({
			type: 'SET_LOADED_GROUP_CONTEXT',
			context: newGroupContext,
		});
	},
	setLoadedTemplateContext: (loadedTemplate: Template) => (dispatch: any) => {
		const newTemplateContext = new EntityContextData();

		newTemplateContext.id = loadedTemplate?.id;
		newTemplateContext.customerId = loadedTemplate?.customerId;
		newTemplateContext.EntityType = EntityTypeEnum?.Template;
		newTemplateContext.displayText = loadedTemplate?.code;
		newTemplateContext.isActive = loadedTemplate?.active;
		newTemplateContext.entityDetails = loadedTemplate;

		dispatch({
			type: 'SET_LOADED_TEMPLATE_CONTEXT',
			context: newTemplateContext,
		});
	},
	setLoadedRoleContext: (loadedRole: Role) => (dispatch: any) => {
		const newRoleContext = new EntityContextData();

		newRoleContext.id = loadedRole?.id;
		newRoleContext.customerId = loadedRole?.customerId;
		newRoleContext.EntityType = EntityTypeEnum?.Role;
		newRoleContext.displayText = loadedRole?.name;
		newRoleContext.isActive = loadedRole?.active;
		newRoleContext.entityDetails = loadedRole;

		dispatch({
			type: 'SET_LOADED_ROLE_CONTEXT',
			context: newRoleContext,
		});
	},
	setLoadedAlertContext: (loadedAlert: Alert) => (dispatch: any) => {
		const newAlertContext = new EntityContextData();

		newAlertContext.id = loadedAlert?.id;
		newAlertContext.customerId = loadedAlert?.customerId;
		newAlertContext.EntityType = EntityTypeEnum?.Alert;
		newAlertContext.displayText = loadedAlert?.name;
		newAlertContext.entityDetails = loadedAlert;

		dispatch({
			type: 'SET_LOADED_ALERT_CONTEXT',
			context: newAlertContext,
		});
	},
	setLoadedInformationNotificationContext: (loadedNotification: InformationNotification) => (dispatch: any) => {
		const newNotificationContext = new EntityContextData();

		newNotificationContext.id = loadedNotification?.id;
		newNotificationContext.customerId = loadedNotification?.customerId;
		newNotificationContext.EntityType = EntityTypeEnum?.InformationNotification;
		newNotificationContext.displayText = loadedNotification?.name;
		newNotificationContext.isActive = loadedNotification?.active;
		newNotificationContext.entityDetails = loadedNotification;

		dispatch({
			type: 'SET_LOADED_INFORMATIONNOTIFICATION_CONTEXT',
			context: newNotificationContext,
		});
	},
	dispatchLoadedKeyContext: (dispatch: any, loadedKey: Key) => {
		const newKeyContext = new EntityContextData();

		newKeyContext.id = loadedKey?.id;
		newKeyContext.customerId = loadedKey?.customerId;
		newKeyContext.EntityType = EntityTypeEnum?.Key;
		newKeyContext.displayText = loadedKey?.code;
		newKeyContext.entityDetails = loadedKey;

		dispatch({
			type: 'SET_LOADED_KEY_CONTEXT',
			context: newKeyContext,
		});
	},
	setLoadedIntegrationContext: (loadedIntegration: IntegrationDto) => (dispatch: any) => {
		const newKeyContext = new EntityContextData();

		newKeyContext.id = loadedIntegration?.id;
		newKeyContext.customerId = loadedIntegration?.customerId;
		newKeyContext.EntityType = loadedIntegration ? EntityTypeEnum.Integration : null;
		newKeyContext.displayText = loadedIntegration?.code;
		newKeyContext.entityDetails = loadedIntegration;

		dispatch({
			type: 'SET_LOADED_INTEGRATION_CONTEXT',
			context: newKeyContext,
		});
	},
};

export const loadedEntityContextReducer = (state: LoadedEntityContext, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case 'SET_LOADED_CUSTOMER_CONTEXT':
		case 'SET_LOADED_SIMCARD_CONTEXT':
		case 'SET_LOADED_KEY_CONTEXT':
		case 'SET_LOADED_OBJECT_CONTEXT':
		case 'SET_LOADED_DEVICE_CONTEXT':
		case 'SET_LOADED_PERSON_CONTEXT':
		case 'SET_LOADED_TEMPLATE_CONTEXT':
		case 'SET_LOADED_GROUP_CONTEXT':
		case 'SET_LOADED_ROLE_CONTEXT':
		case 'SET_LOADED_ALERT_CONTEXT':
		case 'SET_LOADED_INFORMATIONNOTIFICATION_CONTEXT':
		case 'SET_LOADED_INTEGRATION_CONTEXT':
			return { ...state, entityContextData: action.context };
		case EDITING_INSTANCE:
			return {
				...state,
				editingInstance: action.editingInstance,
			};
		case TOGGLE_IS_BLOCKING:
			return {
				...state,
				isBlocking: action.isBlocking,
			};
		case 'SET_LOADED_PERSON_ACTIVE_GROUPS_COUNT':
			if (state.entityContextData.EntityType !== EntityTypeEnum.Person || !state.entityContextData.entityDetails)
				return { ...state };
			let _entityContextData = state.entityContextData;
			_entityContextData.entityDetails.hasGroupActiveConnections = (action.context as number) > 0;
			return {
				...state,
				entityContextData: _entityContextData,
			};
		default:
			return state;
	}
};
