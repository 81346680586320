import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SearchPointerIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 16 20">
			<path d="M7.9028 9.77731C6.81616 9.77731 5.9271 8.89735 5.9271 7.82185C5.9271 6.74634 6.81616 5.86638 7.9028 5.86638C8.98943 5.86638 9.8785 6.74634 9.8785 7.82185C9.8785 8.89735 8.98943 9.77731 7.9028 9.77731ZM13.8299 8.01739C13.8299 4.46823 11.2121 1.95546 7.9028 1.95546C4.5935 1.95546 1.9757 4.46823 1.9757 8.01739C1.9757 10.3053 3.90201 13.3362 7.9028 16.9538C11.9036 13.3362 13.8299 10.3053 13.8299 8.01739ZM7.9028 0C12.0518 0 15.8056 3.14829 15.8056 8.01739C15.8056 11.2635 13.168 15.1059 7.9028 19.5546C2.63756 15.1059 0 11.2635 0 8.01739C0 3.14829 3.75383 0 7.9028 0Z" />
		</SvgIcon>
	);
};

export default React.memo(SearchPointerIcon);
