import _ from 'lodash';

import { FieldRule, FieldRules, FieldsDependenciesDictionary, ValidationData, ValidationResult } from './interfaces';
import { addRuleValidationToWaitList } from './utils';

export class DependencyValidator {
	private fieldsDependenciesDictionary: FieldsDependenciesDictionary;
	private fieldRules: FieldRules;

	constructor(fieldRules: FieldRules) {
		this.fieldsDependenciesDictionary = this.initialiseFieldsDictionary(fieldRules);
		this.fieldRules = fieldRules;
	}

	private initialiseFieldsDictionary(fieldRules: FieldRules): FieldsDependenciesDictionary {
		const fieldsDictionary: FieldsDependenciesDictionary = {} as FieldsDependenciesDictionary;

		!!fieldRules &&
			Object.entries(fieldRules).forEach((fieldRule: [string, FieldRule]) => {
				const key: string = fieldRule[0];
				const rules: FieldRule = fieldRule[1];

				if (!rules.dependencies) {
					return;
				}

				rules.dependencies.forEach((dependency: string) => {
					if (Object.keys(fieldsDictionary).includes(dependency)) {
						Object.defineProperty(fieldsDictionary, dependency, {
							value: [...fieldsDictionary[dependency], key],
							configurable: true,
							enumerable: true,
						});

						return;
					}

					Object.defineProperty(fieldsDictionary, dependency, {
						value: [key],
						configurable: true,
						enumerable: true,
					});
				});
			});

		return fieldsDictionary;
	}

	async validateDependents(
		changedFieldName: string,
		result: ValidationResult,
		data: ValidationData,
		forceCheck: boolean,
		context: any
	): Promise<Promise<boolean>[]> {
		if (
			forceCheck ||
			!this.fieldsDependenciesDictionary ||
			!Object.keys(this.fieldsDependenciesDictionary).includes(changedFieldName)
		) {
			return [];
		}

		const promises = await Promise.all(
			this.fieldsDependenciesDictionary[changedFieldName].map((dependant: string) =>
				this.validateDependant(this.fieldRules, dependant, result, data, context)
			)
		);

		return _.flatten(promises);
	}

	private async validateDependant(
		fieldRules: FieldRules,
		fieldName: string,
		result: ValidationResult,
		data: ValidationData,
		context: any
	) {
		const rules = fieldRules[fieldName].rules;
		const waitList: Promise<boolean>[] = [];

		for (const ruleName in rules) {
			if (!rules.hasOwnProperty(ruleName) || rules[ruleName].disabled) {
				continue;
			}

			result[fieldName] = {};

			addRuleValidationToWaitList(rules[ruleName], ruleName, fieldName, waitList, result, data, context);
		}

		return waitList;
	}
}
