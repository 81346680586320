import { Box } from '@material-ui/core';
import React from 'react';

import { LogWidgetMessagesList } from './LogWidgetMessagesList';
import { useFetchMessages } from './hooks';
import { LogWidgetMessageFilters } from './LogWidgetMessageFilters';
import { LogWidgetPopup } from './LogWidgetPopup';
import { LogsGridProps } from './types';

const LogsGrid = ({ entityId, entityType, latestLogsDayUrl }: LogsGridProps): JSX.Element => {
	const {
		allRecordsLoaded,
		exportData,
		getAllMessages,
		getLastDayWithUTCMessagesAndReload,
		getLogsData,
		handleTripDownload,
		lastDayWithMessagesUTC,
		logsDay,
		logMessages,
		navigateBack,
		navigateForward,
		popupUnitId,
		popupContent,
		popupMessageReceiveTime,
		resetLogsGrid,
		resetSkipTotalMessages,
		showPopup,
		skip,
		today,
		setAllRecordsLoaded,
		setLogsDay,
		setFilterBy,
		setShowPopup,
		setTimezoneId,
		timezoneEnabled,
		timezoneId,
		timezoneList,
		totalLogMessages,
	} = useFetchMessages(entityId, entityType, latestLogsDayUrl);

	return (
		<Box className="e-panel-content">
			<LogWidgetPopup
				showPopup={showPopup}
				popupUnitId={popupUnitId}
				popupContent={popupContent}
				popupMessageReceiveTime={popupMessageReceiveTime}
				onCloseButtonClick={() => setShowPopup(false)}
			/>
			<LogWidgetMessageFilters
				getAllMessages={getAllMessages}
				lastDayWithMessagesUTC={lastDayWithMessagesUTC}
				logsDay={logsDay}
				navigateBack={navigateBack}
				navigateForward={navigateForward}
				skip={skip}
				timezoneEnabled={timezoneEnabled}
				timezoneId={timezoneId}
				timezoneList={timezoneList}
				today={today}
				totalLogMessages={totalLogMessages}
				exportData={exportData}
				getLastDayWithUTCMessagesAndReload={getLastDayWithUTCMessagesAndReload}
				resetLogsGrid={resetLogsGrid}
				resetSkipTotalMessages={resetSkipTotalMessages}
				setAllRecordsLoaded={setAllRecordsLoaded}
				setLogsDay={setLogsDay}
				setFilterBy={setFilterBy}
				setTimezoneId={setTimezoneId}
			/>
			<LogWidgetMessagesList
				allRecordsLoaded={allRecordsLoaded}
				getLogsData={getLogsData}
				handleTripDownload={handleTripDownload}
				logMessages={logMessages}
				totalLogMessages={totalLogMessages}
			/>
		</Box>
	);
};

export default React.memo(LogsGrid);
