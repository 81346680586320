import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LocationCategory } from '../Widgets/Views/CustomerLocationCategoriesView';
import { liveMapActionCreators } from '../../store/LiveMap';
import { historyStoreActionCreators } from '../../store/HistoryStore';

const useLocationCategoryArea = (isLiveMap: boolean) => {
	const dispatch = useDispatch();

	const selectCategory = useCallback((selectedCategory: LocationCategory, selectedCategories: LocationCategory[]) => {
		isLiveMap
			? dispatch(liveMapActionCreators.setSelectedCategoriesLive([...selectedCategories, selectedCategory]))
			: dispatch(
					historyStoreActionCreators.setSelectedCategoriesHistory([...selectedCategories, selectedCategory])
			  );
	}, []);

	const deselectCategory = useCallback(
		(deselectedCategory: LocationCategory, selectedCategories: LocationCategory[]) => {
			const newSelectedCategories = [...selectedCategories.filter((sc) => sc.id !== deselectedCategory.id)];
			isLiveMap
				? dispatch(liveMapActionCreators.setSelectedCategoriesLive(newSelectedCategories))
				: dispatch(historyStoreActionCreators.setSelectedCategoriesHistory(newSelectedCategories));
		},
		[]
	);

	const deselectAllCategories = useCallback(() => {
		isLiveMap
			? dispatch(liveMapActionCreators.setSelectedCategoriesLive([]))
			: dispatch(historyStoreActionCreators.setSelectedCategoriesHistory([]));
	}, []);

	const selectAllCategories = useCallback((selectedCategories: LocationCategory[]) => {
		isLiveMap
			? dispatch(liveMapActionCreators.setSelectedCategoriesLive(selectedCategories))
			: dispatch(historyStoreActionCreators.setSelectedCategoriesHistory(selectedCategories));
	}, []);

	return { deselectCategory, selectCategory, deselectAllCategories, selectAllCategories };
};

export { useLocationCategoryArea };
