import { TextField } from '@material-ui/core';
import CountrySelection from 'components/Common/CommonDataSelections/CountrySelection';
import LanguageSelection from 'components/Common/CommonDataSelections/LanguageSelection';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { Person } from 'models/Person';
import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

type Props = {
	readOnly: boolean;
	handleValueChange: (changedPerson: Person, effectiveChange?: boolean) => void;
	person: Person;
	validationResult: ValidationResult;
};

const PersonPersonalData = (props: Props) => {
	return (
		<form id="personalDataForm" noValidate={true}>
			<div className="view-section-wrapper">
				<div className="form-group">
					<MaterialTextField
						id="firstName"
						className="resize-font"
						label={TranslateText('fields.firstName')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="firstName"
						value={props.person?.firstName}
						handleValueChange={(value) => props.handleValueChange({ ...props.person, firstName: value })}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.firstName} />
				</div>
				<div className="form-group">
					<MaterialTextField
						id="lastName"
						className="resize-font"
						label={TranslateText('fields.lastName')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="lastName"
						value={props.person?.lastName}
						handleValueChange={(value) => props.handleValueChange({ ...props.person, lastName: value })}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.lastName} />
				</div>
				<div className="form-group">
					<MaterialTextField
						id="emailAddress"
						className="resize-font"
						label={TranslateText('fields.emailAddress')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="emailAddress"
						value={props.person?.emailAddress}
						handleValueChange={(value) => props.handleValueChange({ ...props.person, emailAddress: value })}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.emailAddress} />
				</div>
				<div className="form-group">
					<TextField
						id="phoneNumber"
						type="text"
						className="resize-font"
						fullWidth
						label={TranslateText('fields.phoneNumber')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="phoneNumber"
						value={props.person?.phoneNumber ?? ''}
						onChange={(args) => {
							props.handleValueChange({ ...props.person, phoneNumber: args.target.value });
						}}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.phoneNumber} />
				</div>
				<div className="form-group">
					<div className="control-pane">
						<div className="control-section">
							<div className="ddl-control-section">
								<LanguageSelection
									label={TranslateText('fields.language')}
									name="languageId"
									valueId={props.person?.languageId}
									disabled={props.readOnly}
									onChange={({ value }) =>
										props.handleValueChange({ ...props.person, languageId: value as string })
									}
								/>
							</div>
						</div>
					</div>
					<ValidationMessage result={props.validationResult?.languageId} />
				</div>
				<div className="form-group">
					<MaterialDatePicker
						name="birthDate"
						date={props.person?.birthDate}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true)}
						label={TranslateText('fields.birthDate')}
						showTime={false}
						disabled={props.readOnly}
						onDateChange={(date?: Date) => {
							props.handleValueChange({ ...props.person, birthDate: date });
						}}
						fullWidth={true}
					/>
					<ValidationMessage result={props.validationResult?.birthDate} />
				</div>
				<div className="form-group">
					<MaterialTextField
						id="birthName"
						className="resize-font"
						label={TranslateText('fields.birthName')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="birthName"
						value={props.person?.birthName}
						handleValueChange={(value) => props.handleValueChange({ ...props.person, birthName: value })}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.birthName} />
				</div>
				<div className="form-group">
					<MaterialTextField
						id="initials"
						className="resize-font"
						label={TranslateText('fields.initials')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="initials"
						value={props.person?.initials}
						handleValueChange={(value) => props.handleValueChange({ ...props.person, initials: value })}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.initials} />
				</div>
				<div className="form-group">
					<MaterialTextField
						id="socialSecurityNumber"
						className="resize-font"
						label={TranslateText('fields.socialSecurityNumber')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="socialSecurityNumber"
						value={props.person?.socialSecurityNumber}
						handleValueChange={(value) =>
							props.handleValueChange({ ...props.person, socialSecurityNumber: value })
						}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.socialSecurityNumber} />
				</div>
				<div>{TranslateText('common.homeAddress')}</div>
				<br />
				<div className="form-group">
					<MaterialTextField
						id="addressStreet"
						className="resize-font"
						label={TranslateText('fields.street')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="addressStreet"
						value={props.person?.homeAddress?.street}
						handleValueChange={(value) =>
							props.handleValueChange({
								...props.person,
								homeAddress: { ...props.person?.homeAddress, street: value },
							})
						}
						disabled={props.readOnly}
					/>
					<ValidationMessage
						result={props.validationResult && props.validationResult['homeAddress.street']}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						id="addressNumber"
						className="resize-font"
						label={TranslateText('fields.number')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="addressNumber"
						value={props.person?.homeAddress?.number}
						handleValueChange={(value) =>
							props.handleValueChange({
								...props.person,
								homeAddress: { ...props.person?.homeAddress, number: value },
							})
						}
						disabled={props.readOnly}
					/>
					<ValidationMessage
						result={props.validationResult && props.validationResult['homeAddress.number']}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						id="postalCode"
						className="resize-font"
						label={TranslateText('fields.postalCode')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="postalCode"
						value={props.person?.homeAddress?.postalCode}
						handleValueChange={(value) =>
							props.handleValueChange({
								...props.person,
								homeAddress: { ...props.person?.homeAddress, postalCode: value },
							})
						}
						disabled={props.readOnly}
					/>
					<ValidationMessage
						result={props.validationResult && props.validationResult['homeAddress.postalCode']}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						id="city"
						className="resize-font"
						label={TranslateText('fields.city')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="city"
						value={props.person?.homeAddress?.city}
						handleValueChange={(value) =>
							props.handleValueChange({
								...props.person,
								homeAddress: { ...props.person?.homeAddress, city: value },
							})
						}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult && props.validationResult['homeAddress.city']} />
				</div>
				<div className="form-group">
					<CountrySelection
						label={TranslateText('fields.country')}
						name="countryId"
						valueId={
							props.person?.homeAddress?.countryId
								? props.person?.homeAddress?.countryId.toString()
								: null
						}
						disabled={props.readOnly}
						onChange={({ value }) =>
							props.handleValueChange({
								...props.person,
								homeAddress: { ...props.person.homeAddress, countryId: value as string },
							})
						}
					/>
				</div>
			</div>
		</form>
	);
};

export default PersonPersonalData;
