import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import TemplateTypesEnum from 'models/TemplateTypesEnum';
import { DialogUtil } from 'components/Common/NotificationDialog';
import GlobalSettings from '../../GlobalSettings.json';
import Template from '../../models/Template';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import RenderForEnum from '../Views/RenderForEnum';
import TemplateView from '../Views/TemplateView';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';
import { DebouncedButton } from '../Common/DebouncedButton';
import { DEBOUNCE_TIME } from '../../Constants';
import TripTypesEnum from '../../models/TripTypesEnum';
import AlertListItemDto from '../Views/AlertListItemDto';
import TemplateUnitOfMeasureEnum from "../../models/TemplateUnitOfMeasureEnum";

type Props = OldWidgetProps & {
	notifyAboutUnsavedData: () => void;
	savedCallback: (sender: BaseWidget) => void;
	changeDataCallback: (hasChanges: boolean) => void;
	editingInstance: string;
	setEditingInstance: (editingInstance: string) => void;
	showEditButton: boolean;
	templateType: TemplateTypesEnum;
	tripTypes: TripTypesEnum;
	setDataCallback?: (value?: any) => void;
};

type State = {
	template: Template;
	widgedInEditMode: boolean;
	invalidForm: boolean;
	validTemplate: boolean;
	visible: boolean;
	initialTemplate: Template;
	disabled: boolean;
	hasReloaded: boolean;
};

class TemplateDefinitionWidget extends BaseWidget<Props, State> {
	templateViewRef: TemplateView;

	constructor(props: Props) {
		super(props);
		this.state = {
			template: new Template(),
			initialTemplate: new Template(),
			widgedInEditMode: false,
			invalidForm: false,
			validTemplate: true,
			visible: true,
			disabled: false,
			hasReloaded: false,
		};
	}
	public static defaultProps = {
		showEditButton: true,
	};

	async componentDidMount() {
		await this.fillTemplate(this.props.entityId);
		if (this.state.template.templateType === TemplateTypesEnum.Analog) {
			this.props?.setDataCallback({
				maxValue: this.state.template.maxValue as number,
				unitValue: this.state.template.unitOfMeasure as TemplateUnitOfMeasureEnum,
				hasChanged: false,
			});
		}
		this.closeEditPanelMode();
	}

	async reloadData() {
		await this.fillTemplate(this.props.entityId);
	}

	async componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.setState({ widgedInEditMode: false });
		}

		if (
			!this.state.hasReloaded &&
			this.state.widgedInEditMode &&
			this.state.template.templateType === TemplateTypesEnum.Analog
		) {
			this.setState({ hasReloaded: true });
			await this.reloadData();
		}

		if (this.state.hasReloaded && !this.state.widgedInEditMode) {
			this.setState({ hasReloaded: false });
		}
	}

	async fillTemplate(templateId: string) {
		const data = await ajaxUtil.get<Template>(
			`${GlobalSettings.sensorTemplatesMaintenanceApi}/${templateId}/${this.props.templateType}`
		);
		const initialData = { ...data };
		this.setState({
			...this.state,
			template: initialData,
			initialTemplate: initialData,
		});
		this.templateViewRef?.setEditRecord(initialData);
	}

	closeEditPanelMode() {
		if (this.state.widgedInEditMode) {
			this.toggleEditMode();
		}
	}

	async toggleEditPanel() {
		if (this.state.widgedInEditMode && this.hasUnsavedData) {
			if (await this.util.cancelConfirmation()) {
				this.templateViewRef.setEditRecord(this.state.initialTemplate);

				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);
				this.setState({
					template: this.state.initialTemplate,
				});

				this.toggleEditMode();
			}
		} else {
			this.toggleEditMode();
			this.templateViewRef.setEditRecord(this.state.template);
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgedInEditMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState(() => ({
			widgedInEditMode: currentValueFlipped,
			invalidForm: false,
		}));
		this.props?.setDataCallback({
			maxValue: this.state.template.maxValue as number,
			unitValue: this.state.template.unitOfMeasure as TemplateUnitOfMeasureEnum,
			hasChanged: false,
		});
		this.setState({ hasReloaded: false });
	}

	tryCloseWidget() {
		this.toggleEditPanel();
	}

	async save() {
		this.setState({ disabled: true });
		const isValid: boolean = await this.templateViewRef.validateBeforeSave();

		if (!isValid) {
			this.setState({
				invalidForm: true,
				disabled: false,
			});
			return;
		}
		const affectedAlerts = await this.templateViewRef.checkIfAlertsAreAffectedByEdit();
		if (affectedAlerts && affectedAlerts.length > 0) {
			const affectedItems = affectedAlerts.map((x: AlertListItemDto) => x.name + '</br>').join('');

			const response = await DialogUtil.confirm({
				content:
					affectedAlerts.length > 1
						? TranslateTextInterpolated('notificationMessages.templateEditAffectedAlerts', [affectedItems])
						: TranslateTextInterpolated('notificationMessages.templateEditAffectedAlert', [affectedItems]),
				title: TranslateText('notificationMessages.saveNotification'),
			});
			if (!response) {
				this.setState({ disabled: false });
				return;
			}
		}
		this.hasUnsavedData = false;
		this.props.changeDataCallback(false);

		let url = `${GlobalSettings.sensorTemplatesMaintenanceApi}/`;

		if (this.state.template.templateType === TemplateTypesEnum.Event) {
			url += 'UpdateEventTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Duration) {
			url += 'UpdateDurationTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Digital) {
			url += 'UpdateDigitalTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Analog) {
			url += 'UpdateAnalogTemplate';
		}

		const contextSensitiveAddPostModel = {
			AddModel: this.state.template,
			GlobalCustomer: this.state.template.customerId,
			AlertsToBeSetInactive: affectedAlerts ? affectedAlerts.map((x: AlertListItemDto) => x.id) : [],
			TemplateType: this.state.template.templateType,
		};

		ajaxUtil
			.put<string>(url, contextSensitiveAddPostModel)
			.then((response) => {
				this.props.savedCallback(this);
				if (response) {
					this.setState({
						initialTemplate: { ...this.state.template },
					});
					this.hasUnsavedData = false;
					this.props.changeDataCallback(false);
					this.toggleEditPanel();
					if (this.state.template.templateType === TemplateTypesEnum.Analog) {
						this.props?.setDataCallback({
							maxValue: this.state.template.maxValue as number,
							unitValue: this.state.template.unitOfMeasure as TemplateUnitOfMeasureEnum,
							hasChanged: true,
						});
					}
				}
				this.setState({ disabled: false });
			})
			.catch((error) => {
				this.setState({ disabled: false });
				console.log(error.message);
			});
	}

	setTemplate(valid: boolean, templateData: Template) {
		const hasUnsavedData = JSON.stringify(this.state.initialTemplate) !== JSON.stringify(templateData);

		this.setState((prevState, props) => ({
			...this.state,
			validTemplate: valid,
			template: templateData,
		}));

		this.setState((prevState, props) => ({
			...this.state,
			invalidForm: !prevState.validTemplate,
		}));

		this.hasUnsavedData = hasUnsavedData;
		this.props.changeDataCallback(hasUnsavedData);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex={'5'}
				data-minsizey={'4'}
			>
				<div className={'e-panel-container'}>
					<div className={'e-panel-header'}>
						<WidgetHeader
							caption={TranslateText('common.templateDefinition')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.widgedInEditMode}
							allowEditMode={this.props.allowEditMode || this.state.widgedInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel()}
						/>
					</div>
					<div className={'widget-view'}>
						<TemplateView
							informationWidget={false}
							readOnly={!this.state.widgedInEditMode}
							ref={(input) => {
								this.templateViewRef = input;
							}}
							setTemplate={this.state.widgedInEditMode ? this.setTemplate.bind(this) : null}
							renderFor={RenderForEnum.Widget}
							widgetState={this.state.widgedInEditMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							templateType={this.props.templateType}
							tripTypes={this.props.tripTypes}
						/>
					</div>
					{this.state.widgedInEditMode ? (
						<div className={'buttons-host'}>
							<Button
								className={'widget-button cancel-button'}
								onClick={() => {
									this.tryCloseWidget();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className={'widget-button save-button'}
								disabled={this.state.disabled || this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default TemplateDefinitionWidget;
