import './styles.scss';

import { LiveOverviewInfoCard } from 'components/RightSidebarMenu/LiveOverviewInfoCard';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { setStoreForHistoryRedirect } from 'utils/HistoryUtils';

import { LinearProgress } from '@material-ui/core';
import { Restore } from '@material-ui/icons';

import { extractTimeZone } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import GlobalSettings from '../../../../../GlobalSettings.json';
import { ApplicationState } from '../../../../../store';
import { TranslateText } from '../../../../../utils/Translations';
import { SecondToDurationDrivingTime } from '../../../../Common/DateTimeUtilFunctions';
import HistoryParkedTripCard from '../../../HistoryParkedTripCard';
import { useGetHistoryTrips } from './hooks';
import { HistoryTabProps } from './types';

const HistoryTab = (props: HistoryTabProps) => {
	const [redirect, setRedirect] = useState(false);

	const hasMap = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.map
			: state.currentSession.customer?.featuresSettings.map
	);
	const isMapActive = useSelector((s: ApplicationState) => s.historyStore.historyMapActive);
	const isHistoryMenuCollapsed = useSelector(
		(s: ApplicationState) => s.resizableEntity.resizableMenuHistoryMap.isCollapsed
	);

	const dispatch = useDispatch();
	const redirectToHistory = () => {
		setStoreForHistoryRedirect(
			props.tripData.id,
			new Date(),
			hasMap,
			isMapActive,
			isHistoryMenuCollapsed,
			dispatch
		);
		setRedirect(true);
	};

	const { tripHistory, loading, isFetched } = useGetHistoryTrips(
		props.hidden,
		props.tripData.id,
		props.tripData.isPerson
	);

	if (redirect) {
		return <Redirect to={GlobalSettings.route.history} />;
	}

	const detailContainerTitle =
		tripHistory.tripsSummary.hasNormalTrip && tripHistory.tripsSummary.hasNonTrip
			? `${TranslateText('tripDetailsSection.trips')}/${TranslateText('tripDetailsSection.addresses')}`
			: tripHistory.tripsSummary.hasNonTrip
			? TranslateText('tripDetailsSection.addresses')
			: tripHistory.tripsSummary.hasNormalTrip
			? TranslateText('tripDetailsSection.trips')
			: '';
	return (
		<div hidden={props.hidden} className="details-history-container">
			{loading ? <LinearProgress className="linear-progress" /> : null}
			<div style={!isFetched ? { visibility: 'hidden' } : {}} className="detail-container-title">
				{detailContainerTitle}
				{!props.tripData.isPerson && detailContainerTitle ? (
					<span className="time-offset">
						{' ' + extractTimeZone(props.tripData.objectNameWithTimeZoneOffset)}
					</span>
				) : null}
			</div>
			<div className="details-history-trips">
				<div className="trip-rows">
					{isFetched ? (
						tripHistory.tripRows.length ? (
							tripHistory.tripRows.map((tripRow) =>
								tripRow.isParked ? (
									<HistoryParkedTripCard key={tripRow.id + 'pt'} tripConfig={tripRow} fromLive />
								) : (
									<LiveOverviewInfoCard key={tripRow.id + 'ht'} trip={tripRow} />
								)
							)
						) : (
							<div className="no-data-available">{TranslateText('tripDetailsSection.noHistoryData')}</div>
						)
					) : null}
				</div>
			</div>
			<div className="history-footer">
				<div className="footer-item">
					{tripHistory.tripsSummary.hasNormalTrip ? (
						<div>
							<div className="footer-item-text">{TranslateText('tripDetailsSection.distance')}</div>
							<div className="footer-item-value">
								{tripHistory.tripsSummary.totalDistance.toFixed(1)} Km
							</div>
						</div>
					) : null}
				</div>
				<div className="footer-item">
					{tripHistory.tripsSummary.hasNormalTrip ? (
						<div>
							<div className="footer-item-text">{TranslateText('tripDetailsSection.totalTime')}</div>
							<div className="footer-item-value">
								{tripHistory.tripsSummary.totalTime !== 0
									? SecondToDurationDrivingTime(tripHistory.tripsSummary.totalTime)
									: '00:00'}
							</div>
						</div>
					) : null}
				</div>

				<div className="footer-item">
					<div className="footer-history-item" onClick={redirectToHistory}>
						<Restore className="history-button" />
						<div className="footer-history-item-text">{TranslateText('tripDetailsSection.history')}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default HistoryTab;
