import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SearchCustomIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 26 26">
			<path d="M18.1441 16H17.0149L16.6146 15.6141C18.182 13.7879 18.9809 11.4255 18.8436 9.02287C18.7063 6.62022 17.6435 4.36421 15.8783 2.72849C14.1131 1.09276 11.7828 0.204556 9.37669 0.250343C6.97056 0.296131 4.67573 1.27236 2.97404 2.97405C1.27234 4.67575 0.29607 6.97056 0.250282 9.37669C0.204495 11.7828 1.09271 14.1131 2.72843 15.8783C4.36416 17.6435 6.62016 18.7063 9.0228 18.8436C11.4254 18.9809 13.7879 18.182 15.6141 16.6146L16 17.0149V18.1441L23.1469 25.2767L25.2767 23.1469L18.1441 16ZM9.56773 16C8.29555 16 7.05196 15.6228 5.99418 14.916C4.9364 14.2092 4.11194 13.2046 3.6251 12.0293C3.13826 10.8539 3.01088 9.56062 3.25907 8.31289C3.50726 7.06516 4.11988 5.91904 5.01945 5.01947C5.91901 4.1199 7.06511 3.50729 8.31284 3.2591C9.56058 3.01091 10.8539 3.13829 12.0292 3.62513C13.2046 4.11197 14.2091 4.93642 14.9159 5.99419C15.6227 7.05197 16 8.29558 16 9.56776C16.0011 10.4128 15.8355 11.2497 15.5127 12.0306C15.1898 12.8115 14.7161 13.5211 14.1186 14.1186C13.5211 14.7161 12.8115 15.1898 12.0306 15.5127C11.2496 15.8355 10.4127 16.0011 9.56773 16Z" />
		</SvgIcon>
	);
};

export default React.memo(SearchCustomIcon);
