import { TextField } from '@material-ui/core';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import CountrySelection from 'components/Common/CommonDataSelections/CountrySelection';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import DefaultEntityEnum from 'models/DefaultEntityEnum';
import StartPageEnum from 'models/StartPageEnum';
import YesNoComboEnum from 'models/YesNoComboEnum';
import React, { useEffect, useState } from 'react';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { CustomerFeaturesState } from 'store';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import ajaxUtil from 'utils/Ajax';
import { getStartPages, StartPage } from 'utils/StartPageUtils';
import { TranslateText } from 'utils/Translations';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

interface PersonApplicationSettings {
	startPage: StartPageEnum;
	defaultEntity: DefaultEntityEnum;
	mapStartLocation: string;
	defaultLines: number;
	saveFleetSelection: boolean | null;
	saveTrackedFleetSelection: boolean | null;
	canSaveFleetSelection: boolean | null;
	canSaveTrackedFleetSelection: boolean | null;
}

type Props = WidgetViewDefault & {
	data: PersonApplicationSettings;
};

const getConstants = () => {
	return {
		defaultEntityEnumDataSource: [
			{
				id: FleetEntityTypeFilter.all,
				display: TranslateText('personView.defaultEntity.All'),
			},
			{
				id: FleetEntityTypeFilter.object,
				display: TranslateText(`personView.defaultEntity.${DefaultEntityEnum.Objects}`),
			},
			{
				id: FleetEntityTypeFilter.person,
				display: TranslateText('maintenanceOverview.drivers'),
			},
		],
		dropDownFields: {
			text: 'display',
			value: 'id',
		},
	};
};

const GetPersonApplicationSettingsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		defaultLines: {
			rules: {
				min: ValidatorFunctions.minNumber(10),
				max: ValidatorFunctions.maxNumber(999),
			},
		},
		startPage: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultEntity: {
			rules: { required: ValidatorFunctions.required() },
		},
		saveTrackedFleetSelection: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const applicationSettings = data as PersonApplicationSettings;
						return ValidatorFunctions.wrapInPromise(
							applicationSettings.saveFleetSelection === null ||
								applicationSettings.saveFleetSelection === undefined ||
								applicationSettings.saveTrackedFleetSelection === true ||
								applicationSettings.saveTrackedFleetSelection === false
						);
					},
				},
			},
		},
	};

	return fieldRules;
};

const PersonApplicationSettingsView = (props: Props) => {
	const [constants] = useState(getConstants);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetPersonApplicationSettingsValidationConfig() }, true));
	}, [props.entityId]);

	const [startPages, setStartPages] = useState<StartPage[]>([]);
	useEffect(() => {
		const url = `${GlobalSettings.personsMaintenanceApi}/featureSettings/${props.entityId}`;
		ajaxUtil.get<CustomerFeaturesState>(url).then((data) => {
			const pages = getStartPages(data);
			pages.unshift({
				id: StartPageEnum.Default,
				display: TranslateText('common.default'),
			});

			let startPage: StartPageEnum = (props.data as PersonApplicationSettings)?.startPage;
			if (!startPage || !pages.find((p) => p.id === startPage)) {
				if (pages.length === 1) {
					startPage = pages[0].id;
				} else {
					startPage = pages[1].id;
				}
				props.changeDataCallback({ ...props.data, startPage: startPage });
			}
			setStartPages(pages);
		});
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | number | boolean, key: keyof PersonApplicationSettings) => {
			const newState = { ...props.data };
			if (key === 'saveFleetSelection') {
				if (value === YesNoComboEnum.Yes) {
					newState.saveFleetSelection = true;
				} else if (value === YesNoComboEnum.No) {
					newState.saveFleetSelection = false;
				} else {
					newState.saveFleetSelection = null;
				}
				newState.saveTrackedFleetSelection = false;
			} else if (key === 'saveTrackedFleetSelection') {
				newState.saveTrackedFleetSelection = value as boolean;
			} else if (key === 'defaultLines') {
				newState.defaultLines = (value as number) || null;
			} else {
				(newState[key] as any) = value;
			}
			props.changeDataCallback(newState);
		},
	};

	return (
		<div className="view-section-wrapper">
			<div className="form-group">
				{!props.editMode && !props.data?.startPage ? (
					<TextField
						id="startPage"
						type="text"
						fullWidth
						label={TranslateText('fields.startPage')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="startPage"
						value={TranslateText('common.default')}
						disabled={!props.editMode}
					/>
				) : (
					<MaterialAutocomplete
						valueId={props.data.startPage}
						dataSource={startPages}
						name="startPage"
						disabled={!props.editMode}
						label={TranslateText('fields.startPage')}
						onChange={({ value }) => events.onValueChanged(value, 'startPage')}
					/>
				)}
				<ValidationMessage result={props.validationResult?.startPage} />
			</div>
			<div className="form-group">
				{!props.editMode && !props.data?.defaultEntity ? (
					<TextField
						id="defaultEntity"
						type="text"
						fullWidth
						label={TranslateText('fields.defaultEntity')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="defaultEntity"
						value={TranslateText('personView.defaultEntity.All')}
						disabled={!props.editMode}
					/>
				) : (
					<MaterialAutocomplete
						valueId={props.data.defaultEntity}
						dataSource={constants.defaultEntityEnumDataSource}
						name="defaultEntity"
						disabled={!props.editMode}
						label={TranslateText('fields.defaultEntity')}
						onChange={({ value }) => events.onValueChanged(value, 'defaultEntity')}
					/>
				)}
				<ValidationMessage result={props.validationResult?.defaultEntity} />
			</div>
			<div className="form-group">
				{!props.editMode && !props.data?.mapStartLocation ? (
					<TextField
						id="mapStartLocation"
						type="text"
						fullWidth
						label={TranslateText('fields.mapStartLocation')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="mapStartLocation"
						value={TranslateText('common.default')}
						disabled={!props.editMode}
					/>
				) : (
					<CountrySelection
						label={TranslateText('fields.mapStartLocation')}
						name="mapStartLocation"
						valueId={props.data.mapStartLocation}
						disabled={!props.editMode}
						onChange={({ value }) => events.onValueChanged(value, 'mapStartLocation')}
					/>
				)}
				<ValidationMessage result={props.validationResult?.mapStartLocation} />
			</div>
			<div className="form-group">
				{!props.editMode && !props.data?.defaultLines ? (
					<TextField
						id="defaultLines"
						type="text"
						fullWidth
						label={TranslateText('fields.defaultLines')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="defaultLines"
						value={TranslateText('common.default')}
						disabled={!props.editMode}
					/>
				) : (
					<TextField
						id="defaultLines"
						type="number"
						className="resize-font"
						fullWidth
						label={TranslateText('fields.defaultLines')}
						InputProps={{
							inputProps: {
								min: 10,
								max: 999,
								readOnly: !props.editMode,
								style: { fontSize: 12 },
							},
						}}
						name="defaultLines"
						value={props.data.defaultLines}
						style={{ width: 340 }}
						onChange={(args: any) => events.onValueChanged(args.target.value, 'defaultLines')}
						disabled={!props.editMode}
					/>
				)}
				<ValidationMessage result={props.validationResult?.defaultLines} />
			</div>
			<YesNoCombobox
				placeholder={TranslateText('fields.saveFleetSelection')}
				value={
					props.data.saveFleetSelection === null
						? YesNoComboEnum.Default
						: props.data.saveFleetSelection
						? YesNoComboEnum.Yes
						: YesNoComboEnum.No
				}
				change={(e) => events.onValueChanged(e, 'saveFleetSelection')}
				name={'saveFleetSelection'}
				readonly={!props.editMode || !props.data.canSaveFleetSelection}
				showDefault={true}
				validationResult={props.validationResult}
			/>
			{/*Only render the fleet selection Tracked options if the user has custom values*/}
			<YesNoCombobox
				placeholder={TranslateText('fields.saveTrackedFleetSelection')}
				hidden={props.data.saveFleetSelection === null}
				value={props.data.saveTrackedFleetSelection}
				change={(e) => events.onValueChanged(e, 'saveTrackedFleetSelection')}
				name={'saveTrackedFleetSelection'}
				readonly={!props.editMode || !props.data.saveFleetSelection || !props.data.canSaveFleetSelection}
				validationResult={props.validationResult}
			/>
		</div>
	);
};

export default PersonApplicationSettingsView;
