import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BookmarkAddedIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 20 21">
			<path d="M15.2423 20.3918L7.62122 17.1257L0 20.3918V2.97214C0.00169105 2.39516 0.231661 1.8423 0.639648 1.43431C1.04764 1.02632 1.60051 0.796369 2.17749 0.794678H9.79858C9.19201 1.60344 8.82272 2.56514 8.73193 3.57201C8.64114 4.57887 8.83243 5.59112 9.28455 6.49535C9.73666 7.39957 10.4317 8.16004 11.2916 8.69153C12.1516 9.22302 13.1426 9.50453 14.1536 9.50453C14.5193 9.50716 14.8843 9.47066 15.2423 9.39566V20.3918ZM13.9685 7.32707L10.8873 4.24596L12.4225 2.71085L13.9576 4.24596L17.8116 0.391846L19.3468 1.92696L13.9685 7.32707Z" />
		</SvgIcon>
	);
};

export default React.memo(BookmarkAddedIcon);
