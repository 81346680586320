import './LogWidget.scss';

import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import React, { useState } from 'react';
import { TranslateText } from 'utils/Translations';

import { OldWidgetProps } from '../Widget';
import LogsGrid from './LogsGrid/LogsGrid';

interface Props extends OldWidgetProps {
	latestLogsDayUrl: string;
}

const LogWidget = (props: Props) => {
	const [visible, setVisible] = useState(true);
	return (
		<div
			id={props.id}
			className={`e-panel oldwidget ${visible ? '' : 'hidden'}`}
			data-row={props.row}
			data-col={props.col}
			data-sizex={props.sizeX}
			data-sizey={props.sizeY}
			data-minsizex="10"
			data-minsizey="3"
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={TranslateText('common.deviceMessagesLog')}
					showEditButton={false}
					allowEditMode={false}
					removePanelCallback={() => setVisible(false)}
				/>
				<LogsGrid
					entityId={props.entityId}
					entityType={props.entityType}
					latestLogsDayUrl={props.latestLogsDayUrl}
				/>
			</div>
		</div>
	);
};
export default LogWidget;
