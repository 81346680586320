import { filterWorkingDays, getDefaultWorkingDays } from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import { WorkingDay } from 'models/WorkingDay';
import moment from 'moment';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { OutsideBusinessHours } from 'store/ReportStore';

import { OutsideBusinessHoursState } from './types';

export const getDefaultBusinessHoursParameters = (): OutsideBusinessHoursState => {
	return {
		includeTripsStartedBefore: true,
		includeTripsEndedAfter: true,
		workingDays: getDefaultWorkingDays(),
	};
};

export const filterSelectedOutsideBusinessHours = (params: OutsideBusinessHoursState): OutsideBusinessHours => {
	return {
		...params,
		workingDays: filterWorkingDays(params.workingDays),
	};
};

export const businessHoursDisplay = (workingDays: WorkingDay[]): string => {
	const result: string[] = [];
	if (workingDays && workingDays.length) {
		const tempDate = new Date();
		const defaultWorkingDays = getDefaultBusinessHoursParameters().workingDays;
		const timeFormat = DateTimeConversionUtil.syncFusionToMomentTimeFormat(DateTimeUtil.timeFormat(true));
		workingDays.forEach((workingDay) => {
			result.push(
				`${defaultWorkingDays.find((x) => x.dayOfWeek === workingDay.dayOfWeek)?.label} ${moment(
					new Date(
						tempDate.getFullYear(),
						tempDate.getMonth(),
						tempDate.getDay(),
						workingDay.startHour ?? 0,
						workingDay.startMinute ?? 0
					)
				).format(timeFormat)} - ${moment(
					new Date(
						tempDate.getFullYear(),
						tempDate.getMonth(),
						tempDate.getDay(),
						workingDay.endHour ?? 0,
						workingDay.endMinute ?? 0
					)
				).format(timeFormat)}`
			);
		});
	}
	return result.join(', ');
};
