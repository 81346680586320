import './styles.scss';

import { TreeSelectionIcon } from 'components/Common/Icons/TreeSelectionIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import React, { useEffect, useState } from 'react';
import { FleetEntityTypeFilter } from 'store/FleetSelection';

import { Button, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

import { TreeNodeProps } from './types';

const TreeNode = (props: TreeNodeProps): JSX.Element => {
	const selectedCount = props.selectedCount ?? props.items?.filter((node) => node.selected).length ?? 0;
	const text = props.childCount
		? `${props.text} (${props.selected ? props.childCount : selectedCount}/${props.childCount})`
		: props.text;

	const [expanded, setExpanded] = useState<boolean>(false);
	useEffect(() => {
		setExpanded(!!props.filterText || props.entityFilter !== FleetEntityTypeFilter.all || props.expanded);
	}, [props.expanded, props.entityFilter, props.filterText]);

	const { icon } = useTryGetSvgIconFromQueryCache(props.icon);

	const checked =
		props.parent?.selected ||
		props.selected ||
		(!props.id &&
			props.items?.every((node) => {
				return node.selected;
			}));

	return (
		<div hidden={props.hidden} className={'tree-node'} key={props.id}>
			<div
				className={`header ${!props.active ? 'inactive' : ''} ${
					!props.id || !props.canBeChecked ? 'disabled' : ''
				}`}
				title={text}
			>
				<span
					className={'node-identification tree-node-row'}
					onClick={() => {
						if (!props.canBeChecked) return;

						props.selectCallback(props);
					}}
				>
					<Checkbox
						className="selection-checkbox"
						color={'default'}
						checkedIcon={<CheckBoxOutlinedIcon />}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						icon={<CheckBoxOutlineBlankOutlinedIcon />}
						indeterminate={!checked && selectedCount > 0}
						checked={checked}
						disabled={!props.canBeChecked}
					/>
					<TreeSelectionIcon type={props.type} iconContent={icon.content} />
					<span className="selection-text">{text}</span>
				</span>
				{props.childCount && props.entityFilter === FleetEntityTypeFilter.all ? (
					<Button
						className="expand-button"
						onClick={() => {
							props.expandCallback(props);
						}}
					>
						{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</Button>
				) : null}
			</div>
			{expanded && props.items?.length ? (
				<div className={'children'}>
					{props.items.map((childNode) => (
						<TreeNode
							{...childNode}
							entityFilter={props.entityFilter}
							filterText={props.filterText}
							key={childNode.id}
							parent={props}
							selectCallback={props.selectCallback}
							expandCallback={props.expandCallback}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

export default React.memo(TreeNode);
