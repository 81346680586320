import './styles.scss';

import { Chip } from '@material-ui/core';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import React from 'react';
import { ReportObjectPersonChipProps } from './types';
import EntityTypeEnum from 'models/EntityTypeEnum';

const ReportObjectPersonChip = ({
	entityData,
	onDeleteCallback,
	personId,
	onlyCurrentDriver,
}: ReportObjectPersonChipProps): JSX.Element => {
	const { icon } = useTryGetSvgIconFromQueryCache(entityData.icon);

	return (
		<Chip
			className={entityData.active ? 'report-object-person-chip' : 'inactive-chip report-object-person-chip'}
			color="default"
			size="small"
			icon={
				entityData.entityType === EntityTypeEnum.Object ? (
					<SvgIcon icon={icon.content} color="#616161" size={20} onClick={(icon: string) => {}} />
				) : (
					<span className={`entity-icon-${entityData.entityType}`}></span>
				)
			}
			label={entityData.text}
			disabled={entityData.id === personId && onlyCurrentDriver}
			onDelete={() => {
				onDeleteCallback(entityData.id);
			}}
		/>
	);
};

export default ReportObjectPersonChip;
