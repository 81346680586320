import './searchResultFollowup.scss';

import { getDistance } from 'geolib';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { ITreeNode } from '../../components/SelectionTree/TreeNode/types';
import * as GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { StateFilterEnum } from '../../models/LiveMenuUtils';
import {
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from '../../shared/effects/useShowLastEntities';
import { ApplicationState } from '../../store';
import { LiveDataResultDto } from '../../store/LiveData';
import { liveMapActionCreators } from '../../store/LiveMap';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import FollowupItem from './FollowupItem';
import { SearchResult } from './SearchComponent';

export interface FollowupResult {
	id: string;
	entityType: EntityTypeEnum;
	objectName: string;
	hideObject: boolean;
	personName: string;
	hidePerson: boolean;
	lat: number;
	lng: number;
	state: StateFilterEnum;
	direction?: string;
	time?: number;
	distance?: number;
	polylines: { lat: number; lng: number }[][];
	bounds?: { sw: { lat: number; lng: number }; ne: { lat: number; lng: number } };
	loaded?: boolean;
}

export interface RouteResult {
	time?: number;
	distance?: number;
	polylines: { lat: number; lng: number }[][];
	bounds?: { sw: { lat: number; lng: number }; ne: { lat: number; lng: number } };
}

export interface Props {
	followupSearch: SearchResult;
}

const sortByTime = (a: FollowupResult, b: FollowupResult): number => {
	if (a.time === b.time) {
		const aName =
			a.entityType === EntityTypeEnum.Object ? a.objectName?.toLowerCase() : a.personName?.toLowerCase();
		const bName =
			b.entityType === EntityTypeEnum.Object ? b.objectName?.toLowerCase() : b.personName?.toLowerCase();

		return aName > bName ? 1 : aName < bName ? -1 : 0;
	} else if (a.time === null || !b.time === null) {
		return a.time === null ? -1 : 1;
	}

	return a.time - b.time;
};

const sortByDistance = (
	a: { distance: number; entity: LiveDataResultDto },
	b: { distance: number; entity: LiveDataResultDto }
): number => {
	if (a.distance === b.distance) {
		const aName =
			a.entity.entityType === EntityTypeEnum.Object
				? a.entity.objectName?.toLowerCase()
				: a.entity.personName?.toLowerCase();
		const bName =
			b.entity.entityType === EntityTypeEnum.Object
				? b.entity.objectName?.toLowerCase()
				: b.entity.personName?.toLowerCase();

		return aName > bName ? 1 : aName < bName ? -1 : 0;
	}

	return a.distance - b.distance;
};

const googleRouteTries = 20;

const getMappedBestCandidate = (
	liveData: LiveDataResultDto[],
	destination: SearchResult,
	showLastDriverOfParkedObject: boolean,
	showLastObjectOfParkedDriver: boolean,
	driverIdentification: boolean
) => {
	const liveDataList: { distance: number; entity: LiveDataResultDto }[] = [];
	for (let i = 0; i < liveData.length; i++) {
		if (liveData[i].lat && liveData[i].long) {
			liveDataList.push({
				distance: getDistance(
					{ lat: liveData[i].lat, lng: liveData[i].long },
					{ lat: destination.lat, lng: destination.lng }
				),
				entity: liveData[i],
			});
		}
	}

	liveDataList.sort(sortByDistance);

	const limit = liveDataList.length > 15 ? 14 : liveDataList.length - 1;
	const followupList: FollowupResult[] = [];

	for (let i = 0; i <= limit; i++) {
		const liveResult = liveDataList[i].entity;

		//driver
		let personName = '';
		let hidePerson = true;
		if (
			driverIdentification &&
			(liveResult.entityType !== EntityTypeEnum.Object ||
				liveResult.state !== StateFilterEnum.Parked ||
				(liveResult.state === StateFilterEnum.Parked && showLastDriverOfParkedObject))
		) {
			personName = liveResult.personName ?? '';
			hidePerson = false;
		}

		//object
		let objectName = '';
		let hideObject = true;
		if (
			liveResult.entityType === EntityTypeEnum.Object ||
			liveResult.state !== StateFilterEnum.Parked ||
			(liveResult.state === StateFilterEnum.Parked && showLastObjectOfParkedDriver)
		) {
			objectName = liveResult.objectName ?? '';
			hideObject = false;
		}

		followupList.push({
			id: liveResult.entityId,
			entityType: liveResult.entityType,
			objectName: objectName,
			hideObject: hideObject,
			personName: personName,
			hidePerson: hidePerson,
			lat: liveResult.lat,
			lng: liveResult.long,
			direction: liveResult.direction,
			state: liveResult.state,
			polylines: [],
		});
	}

	return followupList;
};

const getTrackedList = (trackedEntities: ITreeNode[]) => {
	const treeDataDictionary: { [key: string]: ITreeNode } = {};
	trackedEntities.forEach((item) => {
		if (item.type === EntityTypeEnum.Group) {
			if (item.items) {
				item.items.forEach((child) => {
					treeDataDictionary[child.id] = child;
				});
			}
		} else {
			treeDataDictionary[item.id] = item;
		}
	});
	return Object.getOwnPropertyNames(treeDataDictionary).map((id) => treeDataDictionary[id]);
};

const SearchResultFollowup = (props: Props) => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();

	const [followupResults, setFollowupResults] = useState<FollowupResult[]>([]);
	const [displayFooter, setDisplayFooter] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const selectedEntities = useSelector((s: ApplicationState) => s.fleetSelection.selectedEntities);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);

	const getRoute = useCallback(
		(
			directionsService: google.maps.DirectionsService,
			source: { lat: number; lng: number },
			destination: { lat: number; lng: number },
			numberOfTries: number
		) => {
			return new Promise<RouteResult | null>((resolve, reject) => {
				const request = {
					origin: source,
					destination: destination,
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.METRIC,
					provideRouteAlternatives: false,
				};

				directionsService.route(request, function(response, status) {
					if (status === google.maps.DirectionsStatus.OK) {
						if (response.routes.length && response.routes[0].legs.length) {
							const directionData = response.routes[0].legs[0];
							const lines: { lat: number; lng: number }[][] = [];
							directionData.steps.forEach((step) => {
								const line: { lat: number; lng: number }[] = [];
								if (step.path.length) {
									step.path.forEach((path) => {
										line.push({
											lat: path.lat(),
											lng: path.lng(),
										});
									});
									lines.push(line);
								}
							});

							resolve({
								time:
									directionData.duration && directionData.duration.value >= 0
										? Math.round(directionData.duration.value / 60) * 60
										: null,
								distance: directionData.distance?.value,
								polylines: lines,
								bounds: {
									sw: {
										lat: response.routes[0].bounds.getSouthWest().lat(),
										lng: response.routes[0].bounds.getSouthWest().lng(),
									},
									ne: {
										lat: response.routes[0].bounds.getNorthEast().lat(),
										lng: response.routes[0].bounds.getNorthEast().lng(),
									},
								},
							});
						} else {
							resolve(null);
						}
					} else if (
						status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT &&
						numberOfTries < googleRouteTries
					) {
						//try again to retrive the route
						numberOfTries++;

						setTimeout(() => {
							getRoute(directionsService, source, destination, numberOfTries)
								.then((data) => {
									resolve(data);
								})
								.catch(() => {
									resolve(null);
								});
						}, 200 * numberOfTries);
					} else {
						resolve(null);
					}
				});
			});
		},
		[]
	);

	const gettrackedEntities = () => {
		const selection = getTrackedList(selectedEntities);

		if (selection && selection.length) {
			ajaxUtil
				.post<LiveDataResultDto[]>(
					`${GlobalSettings.fleetSelection}/GetLiveData/?customerId=${customerId}`,
					selection
				)
				.then((liveData) => {
					const followupList = getMappedBestCandidate(
						liveData,
						props.followupSearch,
						showLastDriverOfParkedObject,
						showLastObjectOfParkedDriver,
						driverIdentification
					);

					if (followupList.length) {
						setFollowupResults(followupList);
						setDisplayFooter(followupList.length > 10);
						setIsLoading(false);

						const directionsService = new google.maps.DirectionsService();

						followupList.forEach((followup, index) => {
							setTimeout(() => {
								getRoute(
									directionsService,
									{ lat: followup.lat, lng: followup.lng },
									{
										lat: props.followupSearch.lat,
										lng: props.followupSearch.lng,
									},
									0
								)
									.then((data) => {
										setFollowupResults((prevResults) => {
											const index = prevResults.findIndex((x) => x.id === followup.id);
											if (index >= 0) {
												const item = prevResults[index];
												item.loaded = true;
												if (data) {
													item.time = data.time;
													item.distance = data.distance;
													item.polylines = data.polylines;
													item.bounds = data.bounds;
												}
												prevResults[index] = { ...item };
											}

											prevResults.sort(sortByTime);

											return [...prevResults];
										});
									})
									.catch();
							}, 100 * index);
						});
					} else {
						setIsLoading(false);
					}
				})
				.catch(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setSelectedFollowup(null);
		setFollowupResults([]);
		setIsLoading(true);

		if (props.followupSearch && props.followupSearch.loaded) {
			if (props.followupSearch.lng && props.followupSearch.lng) {
				gettrackedEntities();
			} else {
				setIsLoading(false);
			}
		}
	}, [props.followupSearch]);

	const history = useHistory();
	const dispatch = useDispatch();
	const [selectedFollowup, setSelectedFollowup] = useState<FollowupResult>(null);
	useEffect(() => {
		if (selectedFollowup) {
			dispatch(
				liveMapActionCreators.setRouteLinesWithEntity(
					{
						lines: selectedFollowup.polylines,
						bounds: selectedFollowup.bounds,
					},
					selectedFollowup.id
				)
			);

			if (history.location.pathname?.toLowerCase() !== GlobalSettings.route.live) {
				history.push(GlobalSettings.route.live);
			}
		}
	}, [selectedFollowup]);

	return (
		<div
			id="search-followup-results"
			className="search-followup"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (e.nativeEvent?.stopImmediatePropagation) {
					e.nativeEvent.stopImmediatePropagation();
				}
			}}
		>
			<div className="title">
				<span className="material-icons-outlined icon">place</span>
				<span className="text">{props.followupSearch?.text}</span>
			</div>
			<div className="content">
				{isLoading ? (
					<div className="loading-container">
						<CircularProgress size={20} color="secondary" />
					</div>
				) : followupResults.length ? (
					followupResults.map((item, index) => (
						<FollowupItem
							key={item.id}
							item={item}
							index={index}
							selectedFollowupId={selectedFollowup?.id}
							onFollowupSelected={setSelectedFollowup}
							driverIdentification={driverIdentification}
						/>
					))
				) : (
					<div className="no-result">{TranslateText('topBar.noResults')}</div>
				)}
			</div>
			{displayFooter ? <div className="footer">{TranslateText('topBar.closestOnDistanceLimit')}</div> : null}
		</div>
	);
};

export default SearchResultFollowup;
