import './gridOverview.scss';

import { History, LocationState } from 'history';
import { DataTable, DataTableRowClickEventParams } from 'primereact/datatable';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { gridActionCreators } from 'store/GridOverview';
import { AddEntityResult } from 'models/AddEntityResult';
import { useEffectWithNoMount } from 'utils/hooks';
import { TemplateOverviewDto } from 'components/TemplatesOverview/TemplatesOverview';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimConfig, ClaimUtil } from '../../authorization/ClaimUtil';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import AddWizard from '../AddWizard';
import OverviewSearchBar from '../BaseOverview/OverviewSearchBar';
import { PageResult } from '../Common/Pagination';
import NewAddWizard from '../NewAddWizard';
import ObjectView from '../NewAddWizard/Views/Object/ObjectView';
import GroupView from '../NewAddWizard/Views/Group/GroupView';
import DeviceView from '../NewAddWizard/Views/Device/DeviceView';
import LocationView from '../NewAddWizard/Views/Location/LocationView';
import DriverView from '../NewAddWizard/Views/Driver/DriverView';
import * as GlobalSettings from '../../GlobalSettings.json';
import AddressView from '../NewAddWizard/Views/Address/AddressView';
import { unsavedDataStoreActionCreators } from '../../store/UnsavedDataStore';
import TemplateView from '../NewAddWizard/Views/Template/TemplateView';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';

export interface GridEntity {
	id: string;
}

interface Props {
	history: History<LocationState>;
}

export interface Entity {
	id: string;
}

interface Props {
	history: History<LocationState>;
	getGridDataUrl: string;
	showAddConfigClaim?: ClaimConfig[];
	entityType: EntityTypeEnum;
	headerText: string;
	disabledAddButton: boolean;
	columns: ReactNode[];
	filter: string;
	editedRowSaved?: () => Promise<void>;
	newAddWizard?: boolean;
	disabledImportButton: boolean;
	showImportConfigClaim?: ClaimConfig[];
	disableExportButton: boolean;
	showExportConfigClaim?: ClaimConfig[];
}

export const GridOverview = (props: Props) => {
	const dispatch = useDispatch();

	const take = useSelector((s: ApplicationState) => s.currentSession.defaultLines || 10);
	const filteredCustomerId = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer?.id);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const storeSkip = useSelector((s: ApplicationState) => s.gridOverview[props.entityType].gridPage);
	const currentCustomer = useSelector((s: ApplicationState) => s.currentSession.customerLevel);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const fromMenu = useSelector((s: ApplicationState) => s.gridOverview[props.entityType].fromMenu);
	const unsavedData = useSelector((s: ApplicationState) => s.unsavedDataStore.unsavedData);
	const gridSelectedEntityId = useSelector(
		(s: ApplicationState) => s.gridOverview[props.entityType].gridSelectedEntityId
	);

	const [gridData, setGridData] = useState([] as Entity[]);
	const [selectionId, setSelectionId] = useState(null);
	const [selection, setSelection] = useState(null);
	const [total, setTotal] = useState(0);
	const [showAddWizard, setShowAddWizard] = useState(false);
	const gridDivRef = useRef(null);

	useEffect(() => {
		if (fromMenu) {
			dispatch(gridActionCreators.updateGridFromMenu(props.entityType, false));
			dispatch(gridActionCreators.updateGridSelectedEntityId(props.entityType, ''));
			reloadGridData(0);
		} else {
			if (gridSelectedEntityId) {
				dispatch(gridActionCreators.updateGridSelectedEntityId(props.entityType, ''));
				setSelectionId(gridSelectedEntityId);
			}
			reloadGridData(storeSkip);
		}
	}, []);

	useEffect(() => {
		if (showAddWizard) {
			dispatch(availableCustomersActions.toggleSelection(false));
		} else {
			dispatch(availableCustomersActions.toggleSelection(true));
		}
	}, [showAddWizard]);

	const reloadGridData = useCallback(
		(skip: number) => {
			if (skip !== storeSkip) {
				dispatch(gridActionCreators.updateGridPage(props.entityType, skip));
			}
			ajaxUtil
				.post<PageResult<Entity>>(
					props.getGridDataUrl,
					{
						skip: skip,
						take: take,
						filterText: props.filter,
						customerId: filteredCustomerId,
					},
					{ showLoading: false }
				)
				.then((result) => {
					setGridData(result.data);
					setTotal(result.total);
				});
		},
		[storeSkip, take, props.filter, filteredCustomerId]
	);

	useEffectWithNoMount(() => {
		if (props.entityType !== EntityTypeEnum.Translation) {
			reloadGridData(0);
			scrollToTop();
		}
	}, [filteredCustomerId]);

	useEffectWithNoMount(() => {
		reloadGridData(storeSkip);
	}, [props.filter]);

	useEffect(() => {
		const selectedRow = gridData.find((i) => i.id === selectionId);
		if (!selectedRow) {
			scrollToTop();
		}
		setSelection(selectedRow);
	}, [gridData, selectionId]);

	const entityAddedCallback = useCallback(
		(response) => {
			setShowAddWizard(false);
			const entityResult = response as AddEntityResult;
			const newSkip = Math.floor(entityResult.insertIndex / take) * take;
			reloadGridData(newSkip);
			setSelectionId(response.id);
		},
		[
			storeSkip,
			take,
			props.entityType,
			currentCustomer,
			reloadGridData,
			gridActionCreators.updateGridPage,
			dispatch,
			setSelectionId,
			setShowAddWizard,
		]
	);

	const scrollToTop = useCallback(() => {
		if (gridDivRef.current) {
			gridDivRef.current.scrollTop = 0;
		}
	}, [gridDivRef]);

	const getAddWizardComponents = (entityType: EntityTypeEnum, formData: any) => {
		switch (entityType) {
			case EntityTypeEnum.Object:
				return [
					{
						title: TranslateText('common.object'),
						isRequired: true,
						view: ObjectView,
					},
					{
						title: TranslateText('common.group'),
						isRequired: true,
						view: GroupView,
						disableScroll: true,
					},
					{
						title: TranslateText('common.device'),
						isRequired: false,
						view: DeviceView,
						claims: [
							{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
							{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
						],
					},
					driverIdentification && formData.objectFunction !== ObjectFunctionEnum.Asset
						? {
								title: TranslateText('common.driver'),
								isRequired: false,
								view: DriverView,
								claims: [
									{ claim: ClaimType.Drivers, values: [ClaimValue.edit] },
									{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
								],
						  }
						: null,
				];
			case EntityTypeEnum.Location:
				return [
					{
						title: TranslateText('common.location'),
						isRequired: true,
						view: LocationView,
					},
					{
						title: TranslateText('fields.map'),
						isRequired: true,
						view: AddressView,
					},
				];
			case EntityTypeEnum.Template:
				return [
					{
						title: TranslateText('common.template'),
						isRequired: true,
						view: TemplateView,
					},
				];
		}
	};

	const getAddEntityUrl = (entityType: EntityTypeEnum) => {
		switch (entityType) {
			case EntityTypeEnum.Object:
				return GlobalSettings.objectsMaintenanceApi + '/addObjectFromWizard';
			case EntityTypeEnum.Location:
				return GlobalSettings.locationsMaintenanceApi + '/addLocation';
		}
	};

	const onRowDoubleClick = (e: DataTableRowClickEventParams): void => {
		if (props.entityType === EntityTypeEnum.Translation) {
			return;
		}

		if (props.entityType === EntityTypeEnum.Template) {
			const data = e.data as TemplateOverviewDto;
			props.history.push(`/${props.entityType.toLowerCase()}s/${data.id}/${data.templateType}`);
			return;
		}

		props.history.push(`/${props.entityType.toLowerCase()}s/${e.data.id}`);
	};

	return (
		<div className="overview-content">
			<OverviewSearchBar
				headerText={props.headerText}
				statusDataSource={[]}
				actionDataSource={[]}
				toggleAddWizardCallback={() => {
					if (unsavedData) {
						dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(true));
						dispatch(
							unsavedDataStoreActionCreators.setPromptCallback(() => {
								setShowAddWizard(true);
							})
						);
						return;
					}
					setShowAddWizard(true);
				}}
				showAddButton={ClaimUtil.validateClaimList(user, props.showAddConfigClaim)}
				addWizardMode={props.entityType}
				disableAddButton={props.disabledAddButton}
				disableImportButton={props.disabledImportButton}
				showImportButton={props.showImportConfigClaim && ClaimUtil.validateClaimList(user, props.showImportConfigClaim)}
				disableExportButton={props.disableExportButton}
				showExportButton={props.showExportConfigClaim && ClaimUtil.validateClaimList(user, props.showExportConfigClaim)}
			/>
			{showAddWizard ? (
				props.newAddWizard ? (
					<NewAddWizard
						wizardEntityType={props.entityType}
						toggleAddWizardCallback={() => {
							setShowAddWizard(false);
						}}
						entityAddedCallback={entityAddedCallback}
						getAddWizardComponents={getAddWizardComponents}
						postUrl={getAddEntityUrl(props.entityType)}
						resizableDefaultHeight={props.entityType === EntityTypeEnum.Location ? 520 : undefined}
					/>
				) : (
					<AddWizard
						history={props.history}
						entityAddedCallback={entityAddedCallback}
						toggleAddWizardCallback={() => {
							setShowAddWizard(false);
						}}
						addWizardMode={props.entityType}
					/>
				)
			) : null}
			<div className={'flex-grid-container'} ref={gridDivRef}>
				<DataTable
					emptyMessage={TranslateText('common.noData')}
					className={'overview'}
					value={gridData}
					totalRecords={total}
					rows={take}
					paginator={true}
					lazy={true}
					first={storeSkip}
					onSelectionChange={(e) => {
						setSelectionId(e.value.id);
					}}
					onRowDoubleClick={onRowDoubleClick}
					selection={selection}
					selectionMode={'single'}
					dataKey={'id'}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
					currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
					onPage={(e) => {
						if (e.first !== storeSkip) {
							reloadGridData(e.first);
							scrollToTop();
						}
					}}
					editMode={'row'}
					onRowEditSave={() => {
						if (props.editedRowSaved) {
							props.editedRowSaved().then(() => {
								reloadGridData(storeSkip);
							});
						}
					}}
				>
					{props.columns}
				</DataTable>
			</div>
		</div>
	);
};
