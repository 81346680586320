import React from 'react';

import { DetailCardItemProps } from './types';
import { Tooltip } from '@material-ui/core';

const OverviewHistoryTabCard = ({
	title,
	materialIconsKey,
	children,
	cardStyle,
	cardClass = '',
	cardTextClass = '',
}: DetailCardItemProps): JSX.Element => {
	return (
		<div style={cardStyle} className={`overview-history-tab-card ${cardClass}`}>
			<div className="card-item-content">
				<span className="card-item-title">{title}</span>
				{materialIconsKey ? <span className="material-icons watermark-icon">{materialIconsKey}</span> : null}
				<Tooltip title={typeof children === 'string' ? children : ''} style={{ padding: '0 6px' }}>
					<span className={`card-text ${cardTextClass}`}>{children}</span>
				</Tooltip>
			</div>
		</div>
	);
};
export default OverviewHistoryTabCard;
