import {ListItem} from 'components/Widgets/DeviceTemplateWidget/DeviceTemplateWidget';
import EntityTypeEnum from 'models/EntityTypeEnum';
import * as React from 'react';
import {OutsideBusinessHours} from 'store/ReportStore';

import ReportPeriodsEnum, {ReportPeriodType} from '../../../models/ReportPeriodsEnum';
import TemplateData from '../../../models/TemplateData';
import {FormatDate} from '../../../utils/DateUtils';
import {TranslateText} from '../../../utils/Translations';
import {businessHoursDisplay} from '../OutsideBusinessHoursParameters/OutsideBusinessHoursUtils';
import {LocationPositionType, SummaryType} from './ReportParameters';
import Validator from "../../../shared/validation/Validator";
import {ValidatorFunctions} from "../../../shared/validation/ValidatorFunctions";
import {ValidationRulesUtil} from "../../../shared/validation/utils";
import {ReportsSettingsState} from "../../../store";
import PrivacySettingsEnum from "../../../models/PrivacySettingsEnum";
import TripTypesEnum from "../../../models/TripTypesEnum";
import EntityData from "../../../models/EntityData";

interface StepsLookupProps {
	previousTemplateDataType: TemplateData;
	setValidationsSteps: (data: number) => void;
	unsavedDataCallback: (unsavedData: boolean, suspendUnsavedData: boolean) => void;
	reportsSettings: ReportsSettingsState;
	validationsSteps: number[];
	driverIdentification: boolean;
	assetTracking: boolean;
	clearValidations: () => void;
	customerPrivacyLevel: PrivacySettingsEnum;
	userCanSeePrivateData: boolean;
	userIsDriver: boolean;
	customerTripTypes:  TripTypesEnum;
	includePrivateData: boolean;
	tripType: TripTypesEnum;
	navigateToCallback: (stepNumber: number) => void;
	selectedTemplateName: string;
	outsideBusinessHours: OutsideBusinessHours;
	selectedTemplateCategory: string;
	currentStep: number;
	reportName: string;
	periodType: ReportPeriodType;
	customPeriodInterval: string[] | Date[];
	selectedObjectsNames: string;
	selectedAssetsNames: string;
	selectedPersonsNames: string;
	selectedGroupsNames: string;
	setTripTypeParameter: string;
	setTripDistanceParameter: string;
	customerTrackTypeSpecification: boolean;
	templateEntityType: EntityTypeEnum;
	templateDataType: TemplateData;
	getIncludePrivateData: () => JSX.Element;
	getIncludeMaxSpeed: () => JSX.Element;
	getCalculateVisitLastTripOfDayInReport: () => JSX.Element;
	templates: ListItem[];
	locations: ListItem[];
	objectTypes: ListItem[];
	fuelTypes: ListItem[];
	selectedtemplates: string[];
	selectedLocations: string[];
	selectedSummaries: SummaryType[];
	selectedObjectTypes: string[];
	selectedFuelTypes: string[];
	getIncludeDevicesWithoutObjectConnection: () => JSX.Element;
	selectedEntities: EntityData[];
	locationType: LocationPositionType;
}
class StepsLookup extends React.PureComponent<StepsLookupProps, {previousTemplateDataType: TemplateData} > {
	validator: Validator;

	constructor(props: StepsLookupProps) {
		super(props);
		this.state = {
			previousTemplateDataType: this.props.previousTemplateDataType,
		}
		this.setUpValidations();
	}
	setUpValidations() {
		this.validator = new Validator({
			fieldRules: {
				reportName: {
					rules: {
						validationReportName: {
							message: "reportName",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								if (!object.reportName || !(object.reportName.trim().length > 0)) {
									this.props.setValidationsSteps(2);
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
						regex: ValidatorFunctions.regex(new RegExp('(?!^ +$)^.+$')),
					},
				},
				periodType: {
					rules: {
						validationPeriodType: {
							message: "periodType",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								if (object.periodType === undefined || object.periodType === null) {
									this.props.setValidationsSteps(3);
									return ValidatorFunctions.wrapInPromise(false);
								}

								const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
									object.periodType.value
								);
								if (object.templateDataType === TemplateData.DeviceExport ||
									periodType === ReportPeriodsEnum.CustomPeriod) {
									return ValidatorFunctions.wrapInPromise(true);
								}

								const value = ValidationRulesUtil.numberRange(periodType.periodLength, 1, this.getMaxPeriodOfDaysInReport());
								if(value === false) {
									this.props.setValidationsSteps(3);
								}
								return ValidatorFunctions.wrapInPromise(value);
							},
						},
					},
				},
				customPeriodSelection: {
					rules: {
						validationPeriodEmpty: {
							message: "customPeriodInterval",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
									object.periodType.value
								);
								if (object.templateDataType === TemplateData.DeviceExport ||
									periodType !== ReportPeriodsEnum.CustomPeriod) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								const value = !this.isCustomPeriodSelectedAndEmpty(
									object.customPeriodInterval[0] as Date,
									object.customPeriodInterval[1] as Date
								);
								if(value === false) {
									this.props.setValidationsSteps(3);
								}
								return ValidatorFunctions.wrapInPromise(value);
							},
						},
						validationPeriodLength: {
							message: "customPeriodInterval",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
									object.periodType.value
								);
								if (periodType !== ReportPeriodsEnum.CustomPeriod) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								const value = this.differenceInDaysValidation(object.customPeriodInterval[0] as Date, object.customPeriodInterval[1] as Date);
								if(value === false)
									this.props.setValidationsSteps(3);
								return ValidatorFunctions.wrapInPromise(value);
							},
						},
						validationMaxPeriod: {
							message: "customPeriodInterval",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
									object.periodType.value
								);
								if (periodType !== ReportPeriodsEnum.CustomPeriod) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								const value = this.maxPeriodForYears(object.customPeriodInterval[0] as Date, object.customPeriodInterval[1] as Date);
								if(value === false)
									this.props.setValidationsSteps(3);
								return ValidatorFunctions.wrapInPromise(value);
							},
						}
					},
				},
				selectedEntities: {
					rules: {
						validationLimitOfEntities: {
							message: "selectedEntities",
							validationFunction: (data) => {

								const object = data as StepsLookupProps;
								if((!(object.templateDataType === TemplateData.DeviceExport) && !(object.selectedEntities.length > 0)) ||
									((object.templateDataType === TemplateData.DriverPosition ||
											object.templateDataType === TemplateData.ObjectPosition) &&
										(object.selectedEntities.length > 10 ||
											object.selectedEntities.find((x) => x.entityType === EntityTypeEnum.Group)) !== undefined)) {
									this.props.setValidationsSteps(4);
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
					},
				},
				parameters: {
					rules: {
						validationParameters: {
							message: "parameters",
							validationFunction: (data) => {
								const object = data as StepsLookupProps;
								if(this.canIncludePrivateData() &&
									object.templateDataType !== TemplateData.Location &&
									object.templateDataType !== TemplateData.MileageCO2 &&
									object.templateDataType !== TemplateData.DeviceExport &&
									((object.tripType === TripTypesEnum.None &&
											object.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData) ||
										(!object.includePrivateData &&
											this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData &&
											object.tripType === TripTypesEnum.None))) {
									this.props.setValidationsSteps(5);
									return ValidatorFunctions.wrapInPromise(false);
								}

								if(object.customerTrackTypeSpecification &&
									object.templateDataType !== TemplateData.ObjectCurrentMileage &&
									object.templateDataType !== TemplateData.CurrentObjectPosition &&
									object.templateDataType !== TemplateData.CumulativeRunningHours &&
									object.templateDataType !== TemplateData.DurationSummary &&
									object.templateDataType !== TemplateData.ObjectRunningHours &&
									object.templateDataType !== TemplateData.PersonRunningHours &&
									object.templateDataType !== TemplateData.Location &&
									object.templateDataType !== TemplateData.MileageCO2 &&
									object.templateDataType !== TemplateData.DeviceExport &&
									((object.tripType === TripTypesEnum.None &&
											this.props.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData) ||
										(!object.includePrivateData &&
											this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData &&
											object.tripType === TripTypesEnum.None)))
								{
									this.props.setValidationsSteps(5);
									return  ValidatorFunctions.wrapInPromise(false);
								}

								return ValidatorFunctions.wrapInPromise(true);
							},
						},
					},
				},
			},
		});
	}

	forceRevalidation() {
		this.validator
			.validate(this.props)
			.then((result) => {
				this.props.unsavedDataCallback(true, !result.formResult);
				if(result.formResult)
					this.props.clearValidations();
			});
	}

	getMaxPeriodOfDaysInReport(): number {
		if(this.props.templateDataType === TemplateData.ObjectPosition ||
			this.props.templateDataType === TemplateData.DriverPosition ||
			this.props.templateDataType === TemplateData.ObjectRawMessage) {
			// limit the reporting period for position reports to a maximum of one month (7 days)
			return 7;
		}

		if (this.props.templateDataType === TemplateData.ObjectRegistration ||
			this.props.templateDataType === TemplateData.DriverRegistration) {
			// limit the reporting period for position reports to a maximum of one month (31 days)
			return 31;
		}
		if (this.props.templateDataType === TemplateData.MileageCO2) {
			// override the reporting period for this report to one year (365 days)
			return 365;
		}
		if(this.props.templateDataType === TemplateData.DurationSummary) {
			// override the reporting period for this report to one year (366 days)
			return 366;
		}
		return this.props.reportsSettings?.maxPeriodOfDaysInReport ?? 62;
	}

	differenceInDaysValidation(start: Date, end: Date) {
		// Take the difference between the dates and divide by milliseconds per day.
		// Round to nearest whole number to deal with DST.
		if(this.props.templateDataType === TemplateData.MileageCO2)
			return true;
		if (start && end) {
			const newStart = new Date(start);
			const newEnd = new Date(end);
			const time =
				(newStart.getTime() - newEnd.getTime()) / (1000 * 60 * 60 * 24);
			const days = Math.ceil(Math.abs(time));
			return days <= this.getMaxPeriodOfDaysInReport();
		}
		return false;
	}
	maxPeriodForYears(start: Date, end: Date): boolean {
		// limit for 1 full calendar year
		const newStart = new Date(start);
		const newEnd = new Date(end);
		if(this.props.templateDataType === TemplateData.MileageCO2)
			return newStart.getFullYear() === newEnd.getFullYear();
		return true;
	}
	canIncludePrivateData(): boolean {
		return (
			//first condition
			((this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData || !(this.props.customerTripTypes & TripTypesEnum.Private)) &&
				(this.props.userIsDriver || this.props.userCanSeePrivateData)) ||
			//second condition
			(this.props.customerTrackTypeSpecification && this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData)
		);
	}
	isPreviousStepVisible(referenceStep: number, currentStep: number): boolean {
		return referenceStep < currentStep;
	}
	isCustomPeriodSelectedAndEmpty(start: Date, end: Date): boolean {
		return start === null || end === null;
	}
	onlyUnique(value: any, index: number, array: any[]) {
		return array.indexOf(value) === index;
	}

	render() {
		return (
			<div>
				<div
					id="stepOne"
					className="wizard-page"
					style={{ marginBottom: 20, cursor: 'pointer' }}
					onClick={() => this.props.navigateToCallback(1)}
				>
					<div
						className="e-text"
						style={{
							fontSize: '16px',
							fontWeight: 'bold',
							marginTop: '10px',
							color: this.props.validationsSteps.some((x) => x === 1) ? '#f44336' : 'black'
						}}
					>
						{'1.' + TranslateText('reports.chooseReport')}
					</div>
					<div>
						<span className="e-text" style={{ fontWeight: 'bold' }}>
							{TranslateText('reports.reportTemplate') + ': '}
						</span>
						<span>{TranslateText(this.props.selectedTemplateName)}</span>
						<span className="e-text" style={{ fontWeight: 'bold' }}>
							{' ' + TranslateText('reports.templateCategory') + ': '}
						</span>
						<span>{TranslateText(this.props.selectedTemplateCategory)}</span>
					</div>
				</div>
				{this.isPreviousStepVisible(2, this.props.currentStep) ? (
					<div
						id="stepTwo"
						className="wizard-page"
						style={{ marginBottom: 20, cursor: 'pointer' }}
						onClick={() => this.props.navigateToCallback(2)}
					>
						<div
							className="e-text"
							style={{
								fontSize: '16px',
								fontWeight: 'bold',
								marginTop: '10px',
								color: this.props.validationsSteps.some((x) => x === 2) ? '#f44336' : 'black'
							}}
						>
							{'2.' + TranslateText('reports.basicInformation')}
						</div>
						<div>
							<span className="e-text" style={{ fontWeight: 'bold' }}>
								{TranslateText('reports.reportName') + ': '}
							</span>
							<span>{TranslateText(this.props.reportName)}</span>
						</div>
					</div>
				) : null}
				{this.isPreviousStepVisible(3, this.props.currentStep) ? (
					<div
						id="stepThree"
						className="wizard-page"
						style={{ marginBottom: 20, cursor: 'pointer' }}
						onClick={() => this.props.navigateToCallback(3)}
					>
						<div
							className="e-text"
							style={{
								fontSize: '16px',
								fontWeight: 'bold',
								marginTop: '10px',
								color:
									this.props.templateDataType === TemplateData.DeviceExport
										? '#bdbdbd'
										: this.props.validationsSteps.some((x) => x === 3) ? '#f44336' : 'black',
							}}
						>
							{'3.' + TranslateText('reports.period')}
						</div>
						<div
							hidden={
								this.props.templateDataType === TemplateData.DeviceExport
							}
						>
							<span>{TranslateText('reports.periods.' + this.props.periodType.value) + ''}</span>
							{this.props.periodType === ReportPeriodsEnum.CustomPeriod ? (
								<span>
									{': ' +
										FormatDate(this.props.customPeriodInterval[0]) +
										' - ' +
										FormatDate(this.props.customPeriodInterval[1])}
								</span>
							) : null}
						</div>
					</div>
				) : null}
				{this.isPreviousStepVisible(4, this.props.currentStep) ? (
					<div
						id="stepFour"
						className="wizard-page"
						style={{ marginBottom: 20, cursor: 'pointer' }}
						onClick={() => this.props.navigateToCallback(4)}
					>
						<div
							className="e-text"
							style={{
								fontSize: '16px',
								fontWeight: 'bold',
								marginTop: '10px',
								color:
									this.props.templateDataType === TemplateData.DeviceExport
										? '#bdbdbd'
										: this.props.validationsSteps.some((x) => x === 4) ? '#f44336' : 'black',
							}}
						>
							{'4.' +
								(this.props.templateEntityType === EntityTypeEnum.Object
									? TranslateText('reports.objects')
									: this.props.templateEntityType === EntityTypeEnum.PersonReport
									? TranslateText('reports.persons')
									: TranslateText('reports.drivers'))}
						</div>
						<div
							hidden={
								this.props.templateDataType === TemplateData.DeviceExport
							}
						>
							<span
								hidden={this.props.selectedObjectsNames === ''}
								className="e-text"
								style={{ fontWeight: 'bold' }}
							>
								{TranslateText('reports.selectedObjects') + ': '}
							</span>
							<span>{this.props.selectedObjectsNames}</span>

							<span
								hidden={this.props.selectedAssetsNames === ''}
								className="e-text"
								style={{ fontWeight: 'bold' }}
							>
								{' ' + TranslateText('reports.selectedAssets') + ': '}
							</span>
							<span>{this.props.selectedAssetsNames}</span>

							<span
								hidden={this.props.selectedPersonsNames === ''}
								className="e-text"
								style={{ fontWeight: 'bold' }}
							>
								{' ' +
									(this.props.templateEntityType === EntityTypeEnum.PersonReport
										? TranslateText('reports.selectedPersons')
										: TranslateText('reports.selectedDrivers')) +
									': '}
							</span>
							<span>{this.props.selectedPersonsNames}</span>

							<span
								hidden={this.props.selectedGroupsNames === ''}
								className="e-text"
								style={{ fontWeight: 'bold' }}
							>
								{' ' + TranslateText('reports.selectedGroups') + ': '}
							</span>
							<span>{this.props.selectedGroupsNames}</span>
						</div>
					</div>
				) : null}
				{this.isPreviousStepVisible(5, this.props.currentStep) ? (
					<div
						id="stepFive"
						className="wizard-page"
						style={{ marginBottom: 20, cursor: 'pointer' }}
						onClick={() => this.props.navigateToCallback(5)}
					>
						<div
							className="e-text"
							style={{
								fontSize: '16px',
								fontWeight: 'bold',
								marginTop: '10px',
								color:
									this.props.templateDataType === TemplateData.ObjectRawMessage ||
									this.props.templateDataType === TemplateData.ObjectCurrentMileage ||
									this.props.templateDataType === TemplateData.CurrentObjectPosition ||
									this.props.templateDataType === TemplateData.LoginReason ||
									this.props.templateDataType === TemplateData.DriverRegistration ||
									this.props.templateDataType === TemplateData.ObjectRegistration
										? '#bdbdbd'
										: this.props.validationsSteps.some((x) => x === 5) ? '#f44336' : 'black',
							}}
						>
							{'5.' + TranslateText('reports.parameters')}
						</div>
						<div
							hidden={
								this.props.templateDataType === TemplateData.ObjectCurrentMileage ||
								this.props.templateDataType === TemplateData.CurrentObjectPosition ||
								this.props.templateDataType === TemplateData.ObjectRawMessage ||
								this.props.templateDataType === TemplateData.CumulativeRunningHours ||
								this.props.templateDataType === TemplateData.DurationSummary ||
								this.props.templateDataType === TemplateData.ObjectRunningHours ||
								this.props.templateDataType === TemplateData.PersonRunningHours ||
								this.props.templateDataType === TemplateData.LoginReason ||
								this.props.templateDataType === TemplateData.Location ||
								this.props.templateDataType === TemplateData.DriverRegistration ||
								this.props.templateDataType === TemplateData.ObjectRegistration ||
								this.props.templateDataType === TemplateData.MileageCO2 ||
								this.props.templateDataType === TemplateData.DeviceExport ||
								this.props.templateDataType === TemplateData.ObjectCounting
							}
						>
							{this.props.getIncludePrivateData()}
							{this.props.customerTrackTypeSpecification ? (
								<span>
									<span className="e-text" style={{ fontWeight: 'bold' }}>
										{TranslateText('reports.parameter.tripType') + ': '}
									</span>
									<span>{this.props.setTripTypeParameter}</span>
								</span>
							) : null}
							{this.props.templateDataType !== TemplateData.ObjectCurrentMileage &&
								this.props.templateDataType !== TemplateData.CurrentObjectPosition &&
								this.props.templateDataType !== TemplateData.ObjectPosition &&
								this.props.templateDataType !== TemplateData.DriverPosition &&
								this.props.templateDataType !== TemplateData.ObjectMileage &&
								this.props.templateDataType !== TemplateData.DriverMileage && (
									<span>
										<span className="e-text" style={{ fontWeight: 'bold' }}>
											{' ' + TranslateText('reports.parameter.tripDistance') + ': '}
										</span>
										<span>{this.props.setTripDistanceParameter + 'km'}</span>
									</span>
								)}
							{(this.props.templateDataType === TemplateData.Trips ||
								this.props.templateDataType === TemplateData.ObjectWithoutDriver ||
								this.props.templateDataType === TemplateData.OutsideBusinessHours) &&
								this.props.getIncludeMaxSpeed()}
							{(this.props.templateDataType === TemplateData.Visits ||
								this.props.templateDataType === TemplateData.Trips ||
								this.props.templateDataType === TemplateData.ObjectWithoutDriver) &&
								this.props.getCalculateVisitLastTripOfDayInReport()}
						</div>
						<div
							hidden={
								this.props.templateDataType !== TemplateData.DeviceExport
							}
						>
							{this.props.getIncludeDevicesWithoutObjectConnection()}
						</div>
						<div
							hidden={
								this.props.templateDataType !== TemplateData.CumulativeRunningHours &&
								this.props.templateDataType !== TemplateData.DurationSummary &&
								this.props.templateDataType !== TemplateData.ObjectRunningHours &&
								this.props.templateDataType !== TemplateData.PersonRunningHours &&
								this.props.templateDataType !== TemplateData.ObjectCounting
							}
						>
							<span>
								<span className="e-text" style={{ fontWeight: 'bold' }}>
									{TranslateText('common.templates') + ': '}
								</span>
								<span>
									{this.props.templates
										.filter((t) => this.props.selectedtemplates.includes(t.id))
										.map((t) => (t.id === 'All' ? TranslateText('common.all') : t.display))
										.filter(this.onlyUnique)
										.join(', ')}
								</span>
							</span>
							{this.props.templateDataType === TemplateData.CumulativeRunningHours && (
								<>
									<span className="e-text" style={{ fontWeight: 'bold' }}>
										{' ' + TranslateText('reports.parameter.summary') + ': '}
									</span>
									<span>
										{this.props.selectedSummaries
											.map((st) => TranslateText(`common.${SummaryType[st]}`))
											.join(', ')}
									</span>
								</>
							)}
						</div>
						<div hidden={this.props.templateDataType !== TemplateData.Location}>
							<span>
								<span className="e-text" style={{ fontWeight: 'bold' }}>
									{TranslateText('common.locations') + ': '}
								</span>
								<span>
									{this.props.locations
										.filter((t) => this.props.selectedLocations.includes(t.id))
										.map((t) => (t.id === 'All' ? TranslateText('common.all') : t.display))
										.filter(this.onlyUnique)
										.join(', ')}
								</span>
							</span>
						</div>
						<div hidden={this.props.templateDataType !== TemplateData.Location}>
							<span>
								<span className="e-text" style={{ fontWeight: 'bold' }}>
									{TranslateText('common.positionType') + ': '}
								</span>
								<span>
									{this.props.locationType === LocationPositionType.In ? TranslateText('common.in') : TranslateText('common.out')}
								</span>
							</span>
						</div>
						<div hidden={this.props.templateDataType !== TemplateData.MileageCO2}>
							<span>
								<span className="e-text" style={{ fontWeight: 'bold' }}>
									{TranslateText('fields.objectType') + ': '}
								</span>
								<span>
									{this.props.objectTypes
										.filter((t) => this.props.selectedObjectTypes.includes(t.id))
										.map((t) => (t.id === 'All' ? TranslateText('common.all') : t.display))
										.filter(this.onlyUnique)
										.join(', ')}
								</span>
							</span>
						</div>
						<div hidden={this.props.templateDataType !== TemplateData.MileageCO2}>
							<span>
								<span className="e-text" style={{ fontWeight: 'bold' }}>
									{TranslateText('fields.fuelType') + ': '}
								</span>
								<span>
									{this.props.fuelTypes
										.filter((t) => this.props.selectedFuelTypes.includes(t.id))
										.map((t) => (t.id === 'All' ? TranslateText('common.all') : t.display))
										.filter(this.onlyUnique)
										.join(', ')}
								</span>
							</span>
						</div>

						{this.props.templateDataType === TemplateData.OutsideBusinessHours ? (
							<div>
								<span style={{ display: 'flex', flexDirection: 'row' }}>
									<span className="e-text" style={{ fontWeight: 'bold', marginRight: 4 }}>
										{TranslateText('reports.parameter.businessHours') + ': '}
									</span>
									<span style={{ display: 'flex', flexDirection: 'column' }}>
										<span>
											{businessHoursDisplay(this.props.outsideBusinessHours?.workingDays)}
										</span>
										<span>
											{`${TranslateText(
												'reports.parameter.tripsStartedBeforeBusinessHoursAre'
											)} ${
												this.props.outsideBusinessHours?.includeTripsStartedBefore
													? TranslateText('reports.parameter.tripsOutsideBusinessHours')
													: TranslateText('reports.parameter.tripsInsideBusinessHours')
											}.`}
										</span>
										<span>
											{`${TranslateText(
												'reports.parameter.tripsFinishedOutsideBusinessHoursAre'
											)} ${
												this.props.outsideBusinessHours?.includeTripsEndedAfter
													? TranslateText('reports.parameter.tripsOutsideBusinessHours')
													: TranslateText('reports.parameter.tripsInsideBusinessHours')
											}.`}
										</span>
									</span>
								</span>
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}

export default React.forwardRef((props: StepsLookupProps, ref: any) => <StepsLookup {...props} ref={ref} />);;
