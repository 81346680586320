import { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import EventFrequencyEnum from 'models/EventFrequencyEnum';
import { TranslateText } from 'utils/Translations';

export const getAlertFrequencyDatasource = (): AutocompleteItem[] => {
	return [
		{
			display: TranslateText('eventFrequency.onceFrequency'),
			id: EventFrequencyEnum.Once,
		},
		{
			display: TranslateText('eventFrequency.alwaysFrequency'),
			id: EventFrequencyEnum.Always,
		},
		{
			display: TranslateText('eventFrequency.onceAt5MinutesFrequency'),
			id: EventFrequencyEnum.OnceAt5Minutes,
		},
		{
			display: TranslateText('eventFrequency.onceAnHourFrequency'),
			id: EventFrequencyEnum.OnceAnHour,
		},
		{
			display: TranslateText('eventFrequency.onceADayFrequency'),
			id: EventFrequencyEnum.OnceADay,
		},
		{
			display: TranslateText('eventFrequency.onceAWeekFrequency'),
			id: EventFrequencyEnum.OnceAWeek,
		},
		{
			display: TranslateText('eventFrequency.onceAMonthFrequency'),
			id: EventFrequencyEnum.OnceAMonth,
		},
	];
};
