import './style.scss';

import { TextField } from '@material-ui/core';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { getDefaultPeriodSelectionInReportDataSource } from 'Constants';
import React, { useEffect, useState } from 'react';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

import { WidgetViewDefault } from './Widget';
import YesNoCombobox from './components/YesNoCombobox';

//extend with custom props
type Props = WidgetViewDefault & {
	data: PersonReportSettingsState;
	//Any other custom props
};

export interface PersonReportSettingsState {
	maxPeriodOfDaysInReport: number;
	defaultPeriodSelectionInReport: string;
	defaultOutputSelectionInReport: string;
	defaultMinimalTripInReport: number;
	calculateVisitLastTripOfDayInReport: boolean | null;
}

export const PersonReportSettingsViewValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		maxPeriodOfDaysInReport: {
			rules: {
				required: ValidatorFunctions.required(),
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyIntegerDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				min: ValidatorFunctions.minNumber(1),
				max: ValidatorFunctions.maxNumber(366),
			},
		},
		defaultPeriodSelectionInReport: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultOutputSelectionInReport: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultMinimalTripInReport: {
			rules: { required: ValidatorFunctions.required(), min: ValidatorFunctions.minNumber(0.1) },
		},
	};

	return fieldRules;
};

const PersonReportSettingsView = (props: Props) => {
	const [defaultPeriodSelectionInReportDataSource] = useState(getDefaultPeriodSelectionInReportDataSource);
	const defaultOutputSelectionInReportDataSource = [
		{ id: 'pdf', display: TranslateText('reports.formatType.pdf') },
		{ id: 'csv', display: TranslateText('reports.formatType.csv') },
		{ id: 'xlsx', display: TranslateText('reports.formatType.xlsx') },
	];
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: PersonReportSettingsViewValidationConfig() }));
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | number | boolean | null, key: keyof PersonReportSettingsState) => {
			const newState = { ...props.data };
			(newState[key] as string | number | boolean | null) = value;

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className="view-section-wrapper">
			<div className="form-group">
				<TextField
					id="maxPeriodOfDaysInReport"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.maxPeriodOfDaysInReport')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="maxPeriodOfDaysInReport"
					value={props.data.maxPeriodOfDaysInReport}
					onChange={(e) => events.onValueChanged(e.target.value, 'maxPeriodOfDaysInReport')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.maxPeriodOfDaysInReport} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultPeriodSelectionInReport?.toLowerCase()}
					dataSource={defaultPeriodSelectionInReportDataSource}
					name="defaultPeriodSelectionInReport"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultPeriodSelectionInReport')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultPeriodSelectionInReport')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultPeriodSelectionInReport} />
			</div>
			<div className="form-group">
				<TextField
					id="defaultMinimalTripInReport"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.defaultMinimalTripInReport')}
					InputProps={{
						inputProps: {
							min: 0.1,
							readOnly: !props.editMode,
							style: { fontSize: 12 },
						},
					}}
					name="defaultMinimalTripInReport"
					value={props.data.defaultMinimalTripInReport}
					onChange={(e) => events.onValueChanged(e.target.value, 'defaultMinimalTripInReport')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.defaultMinimalTripInReport} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultOutputSelectionInReport}
					dataSource={defaultOutputSelectionInReportDataSource}
					name="defaultOutputSelectionInReport"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultOutputSelectionInReport')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultOutputSelectionInReport')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultOutputSelectionInReport} />
			</div>
			<div className="form-group">
				<YesNoCombobox
					change={(value) => events.onValueChanged(value as boolean, 'calculateVisitLastTripOfDayInReport')}
					name={'calculateVisitLastTripOfDayInReport'}
					placeholder={TranslateText('fields.calculateVisitLastTripOfDay')}
					value={props.data.calculateVisitLastTripOfDayInReport}
					validationResult={props.validationResult}
					showDefaultTrueFalse
					readonly={!props.editMode}
				/>
			</div>
		</div>
	);
};

export default PersonReportSettingsView;
