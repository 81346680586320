import { User } from 'oidc-client';

import ClaimType from '../authorization/ClaimType';
import GlobalSettings from '../GlobalSettings.json';

export const getDriverOrPersonRoute = (
	user: User,
	driverIdentification: boolean,
	driver: boolean,
	entityId: string
): string => {
	if (driver && user.profile[ClaimType.Drivers] && driverIdentification) {
		return `${GlobalSettings.route.drivers}/${entityId}`;
	}

	if (user.profile[ClaimType.Persons]) {
		return `${GlobalSettings.route.persons}/${entityId}`;
	}

	return null;
};
