import './SettingsMergeView.scss';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MergeSetting from '../../../models/MergeSetting';
import { TranslateText } from '../../../utils/Translations';

type Props = {
	MergeSettings: MergeSetting[];
	open: boolean;
	onResponse: (value: string, settingName: string) => void;
	userButtonAnswearCallback: (response: boolean) => void;
};
const SettingsMergeView = (props: Props) => {
	return (
		<Dialog
			className="settings-merge-dialog"
			onClose={() => props.userButtonAnswearCallback(false)}
			open={props.open}
			fullWidth
			disableBackdropClick
		>
			<DialogTitle>{'Move to customer merge settings'}</DialogTitle>
			<DialogContent className="settings-merge-dialog-content">
				{props.MergeSettings.filter((setting) => setting.mergeRequired).map((value, i) => (
					<RadioGroup key={i} row onChange={(e) => props.onResponse(e.target.value, value.id)}>
						<span>
							<div>{value.settingMessage}</div>
						</span>
						<span>
							<span>{TranslateText('common.yes')}</span>
							<Radio aria-label="Yes" value={'yes'} checked={value.applyCustomerSettingValue}></Radio>
							<span>{TranslateText('common.no')}</span>
							<Radio
								aria-label="No"
								value={'no'}
								checked={value.applyCustomerSettingValue !== null && !value.applyCustomerSettingValue}
							></Radio>
						</span>
					</RadioGroup>
				))}
			</DialogContent>
			<DialogActions>
				<>
					<Button
						color={'primary'}
						disabled={props.MergeSettings.some((x) => x.applyCustomerSettingValue === null)}
						onClick={() => props.userButtonAnswearCallback(true)}
					>
						{TranslateText('common.buttonApply')}
					</Button>
					<Button onClick={() => props.userButtonAnswearCallback(false)}>
						{TranslateText('common.buttonCancel')}
					</Button>
				</>
			</DialogActions>
		</Dialog>
	);
};

export default SettingsMergeView;
