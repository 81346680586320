import StartPageEnum from '../models/StartPageEnum';
import { CustomerFeaturesState } from '../store';
import { TranslateText } from '../utils/Translations';

export interface StartPage {
	id: StartPageEnum;
	display: string;
}

export function getStartPages(features: CustomerFeaturesState): StartPage[] {
	const pages: StartPage[] = [];
	if (features) {
		if (features.live) {
			pages.push({
				id: StartPageEnum.Live,
				display: TranslateText(`customerOverview.startPage.${StartPageEnum.Live}`),
			});
		}
		if (features.dashboard) {
			pages.push({
				id: StartPageEnum.Dashboard,
				display: TranslateText(`customerOverview.startPage.${StartPageEnum.Dashboard}`),
			});
		}
		if (features.history) {
			pages.push({
				id: StartPageEnum.History,
				display: TranslateText(`customerOverview.startPage.${StartPageEnum.History}`),
			});
		}
		if (features.reports) {
			pages.push({
				id: StartPageEnum.Reports,
				display: TranslateText(`customerOverview.startPage.${StartPageEnum.Reports}`),
			});
		}
	}
	return pages;
}

export function getApplicationStartPage(features: CustomerFeaturesState, savedStartPage: string): string {
	if (features) {
		if (savedStartPage === StartPageEnum.WhitePage || features[savedStartPage.toLowerCase()]) {
			return savedStartPage;
		}
		if (features.live) {
			return StartPageEnum.Live;
		}
		if (features.dashboard) {
			return StartPageEnum.Dashboard;
		}
		if (features.history) {
			return StartPageEnum.History;
		}
		if (features.reports) {
			return StartPageEnum.Reports;
		}
	}

	return StartPageEnum.WhitePage;
}
