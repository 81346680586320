import { Column } from 'primereact/column';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { GridWidgetOverview } from '../GridOverview/GridWidgetOverview';
import { IButtonWidgetView } from './ButtonWidget/ButtonWidget';

//extend with custom props
type Props = IButtonWidgetView;

const SimCardCustomerView = (props: Props) => {
	const customerId = useSelector((s: ApplicationState) => s.currentSession.customerId);

	useEffect(() => {
		props.setUrl(
			`${GlobalSettings.simCardsMaintenanceApi}/customerHistory?simCardId=${props.entityId}&&customerId=${customerId}`
		);
	}, [props.entityId]);

	const columnLinkTemplate = (e: { customerCode: string; customerId: string }) => {
		return (
			<a title={e.customerCode} href={`${GlobalSettings.route.customers}/${e.customerId}`}>
				{e.customerCode}
			</a>
		);
	};

	const columns = [
		<Column
			key="customerCode"
			field="customerCode"
			className="widget-grid-column"
			header={TranslateText('widgets.grid.colCode')}
			style={{ width: '90px' }}
			body={props.allowEditMode && !props.editMode ? columnLinkTemplate : null}
		/>,
		<Column
			key="customerName"
			field="customerName"
			className="widget-grid-column"
			header={TranslateText('widgets.grid.colName')}
			style={{ width: '100px' }}
		/>,
		<Column
			key="connectionStartDate"
			field="connectionStartDate"
			className="widget-grid-column"
			header={TranslateText('widgets.grid.colStartDate')}
			style={{ width: '130px' }}
			body={(rowData: any) => {
				return FormatDate(new Date(rowData.connectionStartDate));
			}}
		/>,
		<Column
			key="connectionEndDate"
			field="connectionEndDate"
			className="widget-grid-column"
			header={TranslateText('widgets.grid.colEndDate')}
			style={{ width: '130px' }}
			body={(rowData: any) => {
				return rowData.connectionEndDate ? FormatDate(new Date(rowData.connectionEndDate)) : null;
			}}
		/>,
	];

	return (
		<GridWidgetOverview
			columns={columns}
			data={(props.data as any).history}
			total={(props.data as any).history?.length}
			paginator={true}
		/>
	);
};

export default SimCardCustomerView;
