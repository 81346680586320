import IdIdentifiable from './IdIdentifiable';

class Key extends IdIdentifiable {
	constructor(defaultProductionDate?: Date, customerId?: string) {
		super();
		this.productionDate = defaultProductionDate ? defaultProductionDate : null;
		this.customerId = customerId ? customerId : '';
		this.active = true;
	}

	code: string = '';
	customerId: string = '';
	customerName: string = '';
	personId: string = '';
	personName: string = '';
	productionDate: Date;
	serialNumber: string = '';
	userId: string = '';
	userName: string = '';
	active: boolean;
}

export default Key;
