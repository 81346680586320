import './topbar.scss';

import { NotificationsMenu } from 'components/NotificationsMenu';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as EasyTrackLogoTopBar } from '../../../assets/icons/EasyTrackLogoTopBar.svg';
import ClaimType from '../../../authorization/ClaimType';
import { ClaimUtil } from '../../../authorization/ClaimUtil';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';
import { ApplicationState } from '../../../store';
import { CustomerSelection } from '../../CustomerSelection/CustomerSelection';
import AccountMenu from '../../HamburgerMenu/AccountMenu';
import SearchComponent from '../../SearchComponent/SearchComponent';

const RietveldTopBar = () => {
	const currentCustomerLevel = useSelector((s: ApplicationState) => s.currentSession.customerLevel);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const customerFeaturesSettings = useSelector((s: ApplicationState) => s.currentSession.customer.featuresSettings);

	return (
		<span className={'rietveld-top-bar'}>
			<span className={'search-logo-area'}>
				<span className="header-logo">
					<EasyTrackLogoTopBar />
				</span>
				<span className="topbar-separator" />
				<div className="topbar-middle">
					{customerFeaturesSettings.map &&
					((ClaimUtil.validateHasClaim(user, ClaimType.Live) && customerFeaturesSettings.live) ||
						(ClaimUtil.validateHasClaim(user, ClaimType.History) && customerFeaturesSettings.history)) ? (
						<SearchComponent />
					) : (
						<div /> //keep impersonation bar always on the right side
					)}
					{currentCustomerLevel === CustomerLevelEnum.Reseller ||
					currentCustomerLevel === CustomerLevelEnum.OEM ? (
						<span className="customer-container">
							<CustomerSelection />
						</span>
					) : null}
				</div>

				<NotificationsMenu />
			</span>
			<span className={'notifications-profile-area'}>
				<span className={'profile-area'}>
					<span className="topbar-separator-third" />
					<AccountMenu />
				</span>
			</span>
		</span>
	);
};

export default RietveldTopBar;
