import { ResizableConstants } from '../shared/constants/ResizableConstants';

const SET_LIVE_RESIZABLE = 'SET_LIVE_RESIZABLE';
const SET_HISTORY_MAP_RESIZABLE = 'SET_HISTORY_MAP_RESIZABLE';
const SET_HISTORY_GRID_RESIZABLE = 'SET_HISTORY_GRID_RESIZABLE';

export enum ResizableType {
	Live = 1,
	HistoryMap = 2,
	HistoryGrid = 3,
}

export interface ResizableItem {
	width: number;
	isCollapsed: boolean;
	isExpanded: boolean;
}

export interface ResizableEntityState {
	resizableMenuLive: ResizableItem;
	resizableMenuHistoryMap: ResizableItem;
	resizableMenuHistoryGrid: ResizableItem;
}

const initialState: ResizableEntityState = {
	resizableMenuLive: {
		width: ResizableConstants.defaultWidth,
		isCollapsed: false,
		isExpanded: false,
	},
	resizableMenuHistoryMap: {
		width: ResizableConstants.defaultWidth,
		isCollapsed: false,
		isExpanded: false,
	},
	resizableMenuHistoryGrid: {
		width: ResizableConstants.defaultWidth,
		isCollapsed: true,
		isExpanded: false,
	},
};

export const resizableEntityActionCreators = {
	setCollapsed: (type: ResizableType, collapsed: boolean) => (dispatch: any) => {
		switch (type) {
			case ResizableType.Live:
				dispatch({
					type: SET_LIVE_RESIZABLE,
					payload: { isCollapsed: !collapsed },
				});
				break;
			case ResizableType.HistoryMap:
				dispatch({
					type: SET_HISTORY_MAP_RESIZABLE,
					payload: { isCollapsed: !collapsed },
				});
				break;
			case ResizableType.HistoryGrid:
				dispatch({
					type: SET_HISTORY_GRID_RESIZABLE,
					payload: { isCollapsed: !collapsed },
				});
				break;
		}
	},
	setExpanded: (type: ResizableType, expanded: boolean) => (dispatch: any) => {
		switch (type) {
			case ResizableType.Live:
				dispatch({
					type: SET_LIVE_RESIZABLE,
					payload: { isExpanded: expanded },
				});
				break;
			case ResizableType.HistoryMap:
				dispatch({
					type: SET_HISTORY_MAP_RESIZABLE,
					payload: { isExpanded: expanded },
				});
				break;
			case ResizableType.HistoryGrid:
				dispatch({
					type: SET_HISTORY_GRID_RESIZABLE,
					payload: { isExpanded: expanded },
				});
				break;
		}
	},
	setWidth: (type: ResizableType, width: number) => (dispatch: any) => {
		switch (type) {
			case ResizableType.Live:
				dispatch({
					type: SET_LIVE_RESIZABLE,
					payload: { width: width },
				});
				break;
			case ResizableType.HistoryMap:
				dispatch({
					type: SET_HISTORY_MAP_RESIZABLE,
					payload: { width: width },
				});
				break;
			case ResizableType.HistoryGrid:
				dispatch({
					type: SET_HISTORY_GRID_RESIZABLE,
					payload: { width: width },
				});
				break;
		}
	},
};

export const resizableEntityReducer = (state: ResizableEntityState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_LIVE_RESIZABLE:
			return {
				...state,
				resizableMenuLive: { ...state.resizableMenuLive, ...action.payload },
			};
		case SET_HISTORY_MAP_RESIZABLE:
			return {
				...state,
				resizableMenuHistoryMap: { ...state.resizableMenuHistoryMap, ...action.payload },
			};
		case SET_HISTORY_GRID_RESIZABLE:
			return {
				...state,
				resizableMenuHistoryGrid: { ...state.resizableMenuHistoryGrid, ...action.payload },
			};
		default:
			return state;
	}
};
