import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProcessedTripDto } from 'store/HistoryStore';
import { mapStartCoordinates } from 'utils/MapUtils';

import { InfoBox, Polyline } from '@react-google-maps/api';

import { ApplicationState } from '../../../store';
import { LatLng } from '../../../store/LiveMap';
import EndTripMarker from '../../LiveMap/EndTripMarker';
import StartTripMarker from '../../LiveMap/StartTripMarker';
import TripNumberMarker from '../../LiveMap/TripNumberMarker';
import { extendObjectNameWithTimeZoneShortFormat } from '../../ObjectNameWithTimeZoneOffset/Utils';
import { HistoryInfoWindow, VisitInfoWindow } from '../TripInfoWindows';
import { calculateArrowPlacement, calculateDistances, getMidpoint } from './RietveldTripUtils';
import { ArrowDisplay, DirectionArrow, RietveldTripProps } from './types';

const setArrows = (
	trip: ProcessedTripDto,
	setFirstArrow: (arrow: ArrowDisplay) => void,
	setSecondArrow: (arrow: ArrowDisplay) => void
) => {
	const directionArrow: DirectionArrow = {
		path:
			'M 4 18 l 8.5 -6 L 4 6 v 12 z m 1 -11 v 10 l 7 -5 L 12 12 z m 1 1 v 8 l 5 -4 L 11 12 z m 1 1 v 6 l 3 -3 L 10 12 z m 1 2 v 2 l 1 -1 L 9 12 z m 5 -5 v 12 l 8.5 -6 L 21.5 12 z m 1 1 v 10 l 7 -5 L 21 12 z m 1 1 v 8 l 5 -4 L 20 12 z m 1 1 v 6 l 3 -3 L 19 12 z m 1 2 v 2 l 1 -1 L 18 12 z',
		strokeColor: trip.tripColor,
		strokeWeight: 1,
		scale: 1,
		anchor: new google.maps.Point(0, 11.5),
		rotation: 270,
	};

	const half = calculateDistances(trip?.path);
	const percentageToHalf = half[0];
	const halfIndex = half[1];

	setFirstArrow(calculateArrowPlacement(trip?.path.slice(0, halfIndex + 1), true, percentageToHalf, directionArrow));
	setSecondArrow(calculateArrowPlacement(trip?.path.slice(halfIndex), false, percentageToHalf, directionArrow));
};

const RietveldTrip = (props: RietveldTripProps) => {
	const nextTripHighlighted = useSelector((state: ApplicationState) =>
		state.historyStore.highlightedTripIds.some((tripId) => props.nextTrip?.idWithParent === tripId)
	);
	const startCountryBoundaries = useSelector((s: ApplicationState) => s.currentSession.startCountryBoundaries);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const arrowsSet = useRef(false);
	const [firstArrow, setFirstArrow] = useState<ArrowDisplay | null>(null);
	const [secondArrow, setSecondArrow] = useState<ArrowDisplay | null>(null);

	const [tripCenterPosition, setTripCenterPosition] = useState<LatLng | null>(null);

	useEffect(() => {
		if (props.highlight && !arrowsSet.current) {
			arrowsSet.current = true;
			setArrows(props.trip, setFirstArrow, setSecondArrow);
		}
	}, [props.highlight]);

	useEffect(() => {
		if (props.highlight) {
			arrowsSet.current = true;
			setArrows(props.trip, setFirstArrow, setSecondArrow);
		} else {
			arrowsSet.current = false;
		}

		setTripCenterPosition(getMidpoint(props.trip.path));
	}, [props.trip?.path]);

	useEffect(() => {
		if (props.renderForTableView) {
			if (props.trip.path?.length) {
				const bounds = new window.google.maps.LatLngBounds();
				props.trip.path.map((x) => {
					bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
				});
				props.map && props.map.fitBounds(bounds);
			} else {
				if (
					startCountryBoundaries[0].lat &&
					startCountryBoundaries[0].lng &&
					startCountryBoundaries[1].lat &&
					startCountryBoundaries[1].lng
				) {
					props.map &&
						props.map.fitBounds(
							new google.maps.LatLngBounds(startCountryBoundaries[0], startCountryBoundaries[1])
						);
				} else {
					props.map &&
						props.map.fitBounds(
							new google.maps.LatLngBounds(mapStartCoordinates[0], mapStartCoordinates[1])
						);
				}
			}
		}
	}, [props.map]);

	return (
		<>
			<Polyline
				key={`poly-${props.trip.idWithParent}`}
				options={{
					path: props.trip?.path,
					strokeWeight: props.highlight ? 5 : 2,
					strokeColor: props.trip.tripColor,
					strokeOpacity: 1,
					zIndex: props.highlight ? 1000 : 500,
					icons: props.highlight && props.trip ? [firstArrow, secondArrow] : null,
				}}
			/>
			{tripCenterPosition ? (
				<TripNumberMarker
					key={`trip-number-${props.trip.idWithParent}`}
					tripIndex={props.trip.zIndex}
					trip={props.trip}
					highlight={props.highlight}
					tripCenterPosition={tripCenterPosition}
					renderForTableView={props.renderForTableView}
				/>
			) : null}

			<StartTripMarker
				key={`start-${props.trip.idWithParent}`}
				tripIndex={props.trip.zIndex}
				trip={props.trip}
				prevConnectedVisible={props.prevConnectedVisible}
				previousTrip={props.previousTrip}
				map={props.map}
				renderForTableView={props.renderForTableView}
				higherZIndex={props.highlight || nextTripHighlighted || props.showPreviousVisitDetailsOnMap}
			/>
			<EndTripMarker
				key={`end-${props.trip.idWithParent}`}
				tripIndex={props.trip.zIndex}
				trip={props.trip}
				nextConnectedVisible={props.nextConnectedVisible}
				renderForTableView={props.renderForTableView}
				higherZIndex={props.highlight || nextTripHighlighted}
			/>
			{props.showTripDetailsOnMap && tripCenterPosition ? (
				<InfoBox
					key={`info-${props.trip.idWithParent}-history`}
					position={tripCenterPosition}
					options={{
						pixelOffset: props.offset,
						zIndex: 1000, //always on top
						closeBoxMargin: '0px',
						closeBoxURL: '',
						disableAutoPan: true,
					}}
				>
					<HistoryInfoWindow
						trip={{
							...props.trip,
							objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneShortFormat(
								props.trip.objectName,
								props.trip.timeZoneMinutesOffset,
								customerTimezone !== props.trip.timezoneId
							),
						}}
					/>
				</InfoBox>
			) : null}
			{props.showVisitDetailsOnMap ? (
				<InfoBox
					key={`info-${props.trip.idWithParent}-visit`}
					position={props.trip.end}
					options={{
						pixelOffset: props.offset,
						zIndex: 1000, //always on top
						closeBoxMargin: '0px',
						closeBoxURL: '',
					}}
				>
					<VisitInfoWindow
						trip={{
							...props.trip,
							objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneShortFormat(
								props.trip.objectName,
								props.trip.timeZoneMinutesOffset,
								customerTimezone !== props.trip.timezoneId
							),
						}}
						nextTrip={props.nextTrip}
						isEnd={!(props.trip.nextConnectedTripId && props.nextConnectedVisible)}
						mouseInCallback={props.mouseInCallback}
						positionCoord={props.trip.end}
					/>
				</InfoBox>
			) : null}
			{props.showPreviousVisitDetailsOnMap ? (
				<InfoBox
					key={`info-${props.trip.idWithParent}-previous-visit`}
					position={props.trip.start}
					options={{
						pixelOffset: props.offset,
						zIndex: 1000, //always on top
						closeBoxMargin: '0px',
						closeBoxURL: '',
					}}
				>
					<VisitInfoWindow
						trip={{
							...props?.previousTrip,
							personName: props.trip?.personName,
							objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneShortFormat(
								props.trip.objectName,
								props.trip.timeZoneMinutesOffset,
								customerTimezone !== props.trip.timezoneId
							),
						}}
						nextTrip={props.trip}
						isStart
						mouseInCallback={props.mouseInCallback}
						positionCoord={props.trip.start}
						useNextTripStartAddress
					/>
				</InfoBox>
			) : null}
		</>
	);
};
RietveldTrip.defaultProps = {
	highlight: false,
};

export default React.memo(RietveldTrip);
