import IdIdentifiable from './IdIdentifiable';

class TemplateState extends IdIdentifiable {
	constructor() {
		super();
		this.id = null;
		this.name = '';
		this.templateAnalogId = null;
		this.labelColor = '';
		this.iconName = null;
		this.maxValue = null;
		this.active = true;
		this.rank = 0;
	}

	id: string;
	name: string;
	templateAnalogId?: string;
	labelColor: string;
	iconName: string;
	maxValue?: number;
	rank: number;
	active: boolean;
}

export default TemplateState;
