import './styles.scss';

import { noop } from 'Constants';
import { BlobIconDictionary, SvgBlobIcon } from 'models/BlobIcon';
import React, { useCallback, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useToggle } from 'utils/hooks';

import { Divider, IconButton, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { iconNoImage } from '../../utils/Icons';
import { IconPickerContainer } from './IconPickerContainer';
import { SvgIcon } from './SvgIcon';
import { IconPickerProps } from './types';

const IconPicker = ({
	defaultIcon,
	defaultIconName,
	containers,
	onIconSelected,
	color = 'black',
	size = 24,
	readOnly = false,
	allowNoImage,
	isSelected,
}: IconPickerProps): JSX.Element => {
	const iconPickerSelectorRef = useRef<HTMLDivElement>();

	const [showContainer, setShowContainer, setShowContainerFalse] = useToggle();

	const queryClient = useQueryClient();
	const blobIcons = queryClient.getQueryData<BlobIconDictionary>(['application-icons']);
	const svgIcons: SvgBlobIcon[] = useMemo((): SvgBlobIcon[] => {
		const svgBlobIcons: SvgBlobIcon[] = [];
		if (blobIcons) {
			Object.keys(blobIcons).filter((key: string) => {
				if (containers.includes(key)) {
					svgBlobIcons.push(...blobIcons[key]);
				}
			});
		}
		return svgBlobIcons;
	}, [containers, blobIcons]);

	const defaultSvgIconOnClick = useCallback(noop, []);
	const iconPickerSvgIconOnClick = useCallback(
		(iconName: string, content: string, iconSet: string) => {
			setShowContainerFalse();
			onIconSelected(iconName, content, iconSet);
		},
		[onIconSelected]
	);

	const setNoIcon = () => {
		onIconSelected(iconNoImage.name, iconNoImage.content, iconNoImage.iconSet);
	};

	return (
		<>
			<div ref={iconPickerSelectorRef} className="icon-picker-selector">
				<SvgIcon
					icon={defaultIcon}
					tooltip={defaultIconName}
					color={color}
					size={size}
					onClick={defaultSvgIconOnClick}
				/>
				<Divider flexItem orientation="vertical" />
				<IconButton
					data-testid="icon-picker-chevron-test-id"
					className="icon-picker-chevron"
					disableRipple
					disabled={readOnly}
					onClick={setShowContainer}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
				{allowNoImage !== undefined && allowNoImage && isSelected && (
					<>
						<Divider flexItem orientation="vertical" />
						<IconButton
							data-testid="icon-picker-chevron-test-id"
							className="icon-picker-chevron"
							disableRipple
							disabled={readOnly}
							onClick={setNoIcon}
						>
							<Close />
						</IconButton>
					</>
				)}
			</div>
			<Popover
				open={showContainer}
				anchorEl={iconPickerSelectorRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				data-testid="icon-picker-container-test-id"
				disableScrollLock={true}
				onClose={setShowContainerFalse}
			>
				<IconPickerContainer svgIcons={svgIcons} color={color} size={size} onClick={iconPickerSvgIconOnClick} />
			</Popover>
		</>
	);
};

export default IconPicker;
