import AlertTypesEnum from 'models/AlertTypesEnum';
import React from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import Alert from '../../../models/Alert';
import { TranslateText } from '../../../utils/Translations';
import { useAlertNotificationClaims } from './hooks';

interface Props {
	alert: Alert;
	readOnly: boolean;
	setAlert: (alert: Alert) => void;
}

const AlertNotificationType = (props: Props): JSX.Element => {
	const { alertClaims } = useAlertNotificationClaims();

	const handleChange = (name: keyof Alert, checked: boolean): void => {
		const newAlert = {
			...props.alert,
		};
		newAlert[name] = checked;

		//TO DO: usePopup will be implemented in the future
		// if (name === 'useNotification') {
		// 	newAlert.usePopup = checked;
		// }
		// if (name === 'usePopup') {
		// 	newAlert.useNotification = checked;
		// }

		props.setAlert(newAlert);
	};

	return (
		<>
			<FormGroup>
				{alertClaims.notificationAlert && (
					<FormControlLabel
						control={
							<Checkbox
								color={'secondary'}
								disabled={!alertClaims.notificationAlert}
								value={props.alert.useNotification}
								checked={props.alert.useNotification}
								name={'useNotification'}
								readOnly={props.readOnly}
								onChange={(e) => {
									handleChange('useNotification', e.target.checked);
								}}
							/>
						}
						label={TranslateText('fields.notification')}
					/>
				)}
				{/* TO DO: usePopup will be implemented in the future */}
				{/* <FormControlLabel
					control={
						<Checkbox
							color={'secondary'}
							value={props.alert.usePopup}
							checked={props.alert.usePopup}
							name={'usePopup'}
							readOnly={props.readOnly}
							onChange={(e) => {
								handleChange('usePopup', e.target.checked);
							}}
						/>
					}
					label={TranslateText('fields.popup')}
				/> */}
			</FormGroup>
			<FormGroup>
				{alertClaims.mailAlert && (
					<FormControlLabel
						control={
							<Checkbox
								color={'secondary'}
								disabled={!alertClaims.mailAlert}
								value={props.alert.useEmail}
								checked={props.alert.useEmail}
								name={'useEmail'}
								readOnly={props.readOnly}
								onChange={(e) => {
									handleChange('useEmail', e.target.checked);
								}}
							/>
						}
						label={TranslateText('fields.email')}
					/>
				)}
				{alertClaims.smsAlert && (
					<FormControlLabel
						control={
							<Checkbox
								color={'secondary'}
								disabled={!alertClaims.smsAlert}
								value={props.alert.useSms}
								checked={props.alert.useSms}
								name={'useSms'}
								readOnly={props.readOnly}
								onChange={(e) => {
									handleChange('useSms', e.target.checked);
								}}
							/>
						}
						label={TranslateText('fields.sms')}
					/>
				)}
				{alertClaims.whatsAppAlert && (
					<FormControlLabel
						control={
							<Checkbox
								color={'secondary'}
								disabled={!alertClaims.whatsAppAlert}
								value={props.alert.useWhatsApp}
								checked={props.alert.useWhatsApp}
								name={'useWhatsApp'}
								readOnly={props.readOnly}
								onChange={(e) => {
									handleChange('useWhatsApp', e.target.checked);
								}}
							/>
						}
						label={TranslateText('fields.whatsApp')}
					/>
				)}
			</FormGroup>
		</>
	);
};
export { AlertNotificationType };
