import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { TranslateText } from 'utils/Translations';

import { RelatedTimeCheckboxProps } from './types';

const RelatedTimeCheckbox = ({
	checked,
	handleOnRelatedTimeCheckboxChange,
	hideLabel,
	disabled,
}: RelatedTimeCheckboxProps): JSX.Element => {
	return (
		<FormGroup className={'alert-duration-related-time-checkbox'}>
			<FormControlLabel
				control={
					<Checkbox
						color={'secondary'}
						checked={checked}
						name={'relatedToTime'}
						disabled={disabled}
						onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
							handleOnRelatedTimeCheckboxChange(checked)
						}
					/>
				}
				label={
					!hideLabel ? <span style={{ fontSize: 14 }}>{TranslateText('fields.relatedToTime')}</span> : null
				}
				style={{ marginLeft: 0 }}
			/>
		</FormGroup>
	);
};

export default RelatedTimeCheckbox;
