import './customerBlockSettingsView.scss';

import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionDialog from 'components/TreeSelectionDialog/TreeSelectionDialog';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import * as GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import LoginReason from 'models/LoginReason';
import React, { useCallback, useEffect, useState } from 'react';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, TextField } from '@material-ui/core';

import { WidgetViewDefault } from '../Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: BlockSettings;
	initialData: BlockSettings;
	//Any other custom props
};

const getConstants = () => {
	return {
		yesNoDataSource: [
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
		reasonDataSource: [
			{
				id: 0,
				display: TranslateText(`fields.loginReason.${LoginReason.Administrative}`),
			},
			{
				id: 1,
				display: TranslateText(`fields.loginReason.${LoginReason.Technical}`),
			},
			{
				id: 2,
				display: TranslateText(`fields.loginReason.${LoginReason.EndOfPilot}`),
			},
		],
		defaultValues: {
			showMessage: false,
			loginFromDate: new Date(),
			reason: 0, //LoginReason.Administrative,
			message: TranslateText('fields.loginMessage'),
			roles: [{ id: 'all', text: TranslateText('common.all'), type: 'Group', active: true } as ITreeNode], //virtual all
			blockLogin: false,
			blockFromDate: new Date(),
		} as BlockSettings,
		customerCodeUnique: true,
		fieldsConfig: { text: 'display', value: 'id' },
	};
};

interface BlockSettings {
	showMessage: boolean;
	loginFromDate: Date;
	reason: number;
	roles: ITreeNode[];
	message: string;
	blockLogin: boolean;
	blockFromDate: Date;
	customerLevel: CustomerLevelEnum;
}

const CustomerBlockSettingsViewValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		showMessage: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		loginFromDate: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const blockSettings = data as BlockSettings;
						return ValidatorFunctions.wrapInPromise(
							!blockSettings.showMessage || !!blockSettings.loginFromDate
						);
					},
				},
			},
		},
		reason: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const blockSettings = data as BlockSettings;
						return ValidatorFunctions.wrapInPromise(
							!blockSettings.showMessage || !!blockSettings.reason || blockSettings.reason === 0
						);
					},
				},
			},
		},
		message: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const blockSettings = data as BlockSettings;
						return ValidatorFunctions.wrapInPromise(!blockSettings.showMessage || !!blockSettings.message);
					},
				},
				maxLength: ValidatorFunctions.maxLength(500),
			},
		},
		blockLogin: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		blockFromDate: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const blockSettings = data as BlockSettings;
						return ValidatorFunctions.wrapInPromise(
							!blockSettings.blockLogin || !!blockSettings.blockFromDate
						);
					},
				},
			},
		},
	};

	return fieldRules;
};

const CustomerBlockSettingsView = (props: Props) => {
	const [constants] = useState(getConstants);
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		const serverData = (props.initialData as BlockSettings) || constants.defaultValues;
		const newState = {
			showMessage: serverData.showMessage ?? constants.defaultValues.showMessage,
			loginFromDate:
				serverData.loginFromDate && serverData.showMessage
					? serverData.loginFromDate
					: constants.defaultValues.loginFromDate,
			reason: serverData.reason,
			roles: serverData.roles.length
				? serverData.roles.map((r: ITreeNode) => {
						if (r.id === 'all') {
							r.text = TranslateText(r.text);
						}
						return r;
				  })
				: [],
			message: serverData.message ?? constants.defaultValues.message,
			blockLogin: serverData.blockLogin ?? constants.defaultValues.blockLogin,
			blockFromDate:
				serverData.blockFromDate && serverData.blockLogin
					? serverData.blockFromDate
					: constants.defaultValues.blockFromDate,
			customerLevel: serverData.customerLevel,
		};

		props.changeDataCallback(newState, false);
	}, [props.initialData]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: CustomerBlockSettingsViewValidationConfig() }, true));
	}, [props.entityId]);

	const openSelectionDialog = useCallback(() => {
		setShowDialog(true);
	}, []);

	const closeCallback = useCallback(() => {
		setShowDialog(false);
	}, []);

	const saveSelectionCallback = useCallback(
		(selection: ITreeNode[]) => {
			setShowDialog(false);

			const newState = { ...props.data };
			if (selection.some((r: ITreeNode) => r.id === 'all')) {
				newState.roles = [];
			} else {
				newState.roles = selection;
			}

			props.changeDataCallback(newState);
		},
		[props.data, props.changeDataCallback]
	);

	const retrieveRolesData = useCallback(
		(filter: { filterText: string }) => {
			return ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.rolesMaintenanceApi}/GetSelectionData`, {
				customerId: props.entityId,
				filterText: filter.filterText.trim(),
			});
		},
		[props.entityId]
	);

	const handleValueChange = useCallback(
		async (value: boolean, key: 'showMessage' | 'blockLogin') => {
			const newState = { ...props.data };
			newState[key] = value as boolean;
			if (!value) {
				//set default value for empty required fields
				if (!newState.loginFromDate) {
					newState.loginFromDate = constants.defaultValues.loginFromDate;
				}
				if (!newState.reason) {
					newState.reason = constants.defaultValues.reason;
				}
				if (!newState.message) {
					newState.message = constants.defaultValues.message;
				}
				if (!newState.blockFromDate) {
					newState.blockFromDate = constants.defaultValues.blockFromDate;
				}
			}

			props.changeDataCallback(newState);
		},
		[props.data, props.changeDataCallback]
	);

	const handleStringValueChange = useCallback(
		async (value: string, key: 'message') => {
			const newState = { ...props.data };
			newState[key] = value as string;

			props.changeDataCallback(newState);
		},
		[props.data, props.changeDataCallback]
	);

	const handleLoginReasonChange = useCallback(
		async (value: number) => {
			const newState = { ...props.data };
			newState['reason'] = value as number;

			props.changeDataCallback(newState);
		},
		[props.data, props.changeDataCallback]
	);

	const handleDatePickerChange = useCallback(
		async (value: Date, key: 'loginFromDate' | 'blockFromDate') => {
			const newState = { ...props.data };
			newState[key] = value;

			props.changeDataCallback(newState);
		},
		[props.data, props.changeDataCallback]
	);

	const [expandedSection, setExpandedSection] = useState<number>(0);

	return (
		<Accordion
			scrollToTop={props.scrollToTop}
			expandedCallback={(index) => {
				setExpandedSection(index);
			}}
		>
			<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.loginMessage')}>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={props.data.showMessage}
						dataSource={constants.yesNoDataSource}
						name="showMessage"
						disabled={!props.editMode}
						label={TranslateText('common.showMessage')}
						onChange={({ value }) => handleValueChange(value as boolean, 'showMessage')}
						disableClearable
					/>
					<ValidationMessage result={props.validationResult?.showMessage} />
				</div>
				<div className="form-group">
					<div className={props.editMode && props.data.showMessage ? '' : 'e-disabled disable-cursor'}>
						<MaterialDatePicker
							name="loginFromDate"
							date={props.data.loginFromDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
								DateTimeUtil.dateTimeFormat(),
								false
							)}
							label={TranslateText('common.from')}
							showTime={true}
							disabled={!props.editMode || !props.data.showMessage}
							onDateChange={(date: Date) => {
								handleDatePickerChange(date, 'loginFromDate');
							}}
							fullWidth={true}
						/>
					</div>
					<ValidationMessage result={props.validationResult?.loginFromDate} />
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={props.data.reason}
						dataSource={constants.reasonDataSource}
						name="reason"
						disabled={!props.editMode || !props.data.showMessage}
						label={TranslateText('common.reason')}
						onChange={({ value }) => handleLoginReasonChange(value as number)}
						disableClearable
					/>
					<ValidationMessage result={props.validationResult?.reason} />
				</div>
				<div className="flex">
					<TextField
						id="roles"
						type="text"
						className="grow resize-font"
						fullWidth
						label={TranslateText('common.roles')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="roles"
						value={
							props.data.roles.length
								? props.data.roles.map((x: ITreeNode) => x.text).join(', ')
								: TranslateText('common.all')
						}
						disabled={!(props.editMode && props.data.showMessage)}
					/>
					{props.editMode && props.data.showMessage ? (
						<Button className="shrink widget-button edit-icon" onClick={openSelectionDialog} />
					) : null}
				</div>
				<TreeSelectionDialog
					title={TranslateText('common.roles')}
					filterPlaceholder={TranslateText('fleetSelection.searchRolePlaceholder')}
					visible={showDialog}
					initialSelection={props.data.roles.length ? props.data.roles : constants.defaultValues.roles}
					displayInactive={false}
					closeCallback={closeCallback}
					saveCallback={saveSelectionCallback}
					retrieveData={retrieveRolesData}
				/>
				<div className="form-group">
					<TextField
						id="message"
						type="text"
						className="resize-font"
						fullWidth
						label={TranslateText('common.message')}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
						name="message"
						value={props.data.message}
						onChange={(e) => {
							if (e.target) {
								handleStringValueChange(e.target.value as string, 'message');
							}
						}}
						disabled={!(props.editMode && props.data.showMessage)}
						multiline={true}
					/>
					<ValidationMessage result={props.validationResult?.message} />
				</div>
			</AccordionSection>
			<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.block')}>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={props.data.blockLogin}
						dataSource={constants.yesNoDataSource}
						name="blockLogin"
						disabled={!props.editMode || props.data.customerLevel === CustomerLevelEnum.OEM}
						label={TranslateText('common.blockLogin')}
						onChange={({ value }) => handleValueChange(value as boolean, 'blockLogin')}
						disableClearable
					/>
					<ValidationMessage result={props.validationResult?.blockLogin} />
				</div>
				<div className="form-group">
					<div
						className={
							props.editMode &&
							props.data.blockLogin &&
							props.data.customerLevel !== CustomerLevelEnum.OEM
								? ''
								: 'e-disabled disable-cursor'
						}
					>
						<MaterialDatePicker
							name="blockFromDate"
							date={props.data.blockFromDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
								DateTimeUtil.dateTimeFormat(),
								false
							)}
							label={TranslateText('common.from')}
							showTime={true}
							disabled={
								!props.editMode ||
								!props.data.blockLogin ||
								props.data.customerLevel === CustomerLevelEnum.OEM
							}
							onDateChange={(date: Date) => {
								handleDatePickerChange(date, 'blockFromDate');
							}}
							fullWidth={true}
						/>
					</div>
					<ValidationMessage result={props.validationResult?.blockFromDate} />
				</div>
			</AccordionSection>
		</Accordion>
	);
};

export default CustomerBlockSettingsView;
