import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SearchOpenClose = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 12 8">
			<path d="M6 7.41003L12 1.41003L10.59 3.03943e-05L6 4.58003L1.41003 2.95918e-05L5.24537e-07 1.41003L6 7.41003Z" />
		</SvgIcon>
	);
};

export default React.memo(SearchOpenClose);
