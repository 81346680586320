import {FleetSelectionClientModel} from 'components/FleetSelectionArea/FleetSelectionArea';
import {Action, Dispatch} from 'redux';
import ajaxUtil from 'utils/Ajax';

import {ITreeNode} from '../components/SelectionTree/TreeNode/types';
import * as GlobalSettings from '../GlobalSettings.json';
import {IPayloadAction} from './FilteredEntities';

const FLEET_SELECTION_SET_ENTITIES = 'FLEET_SELECTION_SET_ENTITIES';
const FLEET_SELECTION_SET_TEMP_ENTITIES = 'FLEET_SELECTION_SET_TEMP_ENTITIES';
const FLEET_SELECTION_SET_TRACKED_UNFILTERED_ENTITIES = 'FLEET_SELECTION_SET_TRACKED_UNFILTERED_ENTITIES';
const FLEET_SELECTION_CLEAR_TRACKED_ENTITIES = 'FLEET_SELECTION_CLEAR_TRACKED_ENTITIES';
const SET_FLEET_SELECTION_SHOW_INACTIVE = 'SET_FLEET_SELECTION_SHOW_INACTIVE';
const FLEET_SELECTION_SET_EXPANDED_GROUPS = 'FLEET_SELECTION_SET_EXPANDED_GROUPS';
const ADD_SELECTED_AND_TRACKED_ENTITY = 'ADD_SELECTED_AND_TRACKED_ENTITY';
const SET_RECHECK_TIMESTAMP = 'SET_RECHECK_TIMESTAMP';

export function CheckEntityNotTracked(
	entity: ITreeNode,
	selectedEntities: ITreeNode[],
	trackedEntities: ITreeNode[]
): Boolean {
	const selectedEntityOtherGroups = selectedEntities.filter(
		(se) =>
			(se.id === entity.id && se.parentId !== entity.parentId && se.selected) ||
			se.items?.some((it) => it.id === entity.id && it.parentId !== entity.parentId && it.selected)
	);
	const result = selectedEntityOtherGroups.some((se) =>
		trackedEntities.every((te) => te.id !== se.id || te.parentId !== se.parentId)
	);

	return result;
}

export enum FleetEntityTypeFilter {
	all,
	object,
	person,
	driver,
	asset,
}

export interface IFleetSelectionState {
	selectedEntities: ITreeNode[];
	tempSelectedEntities: ITreeNode[];
	trackedEntities: ITreeNode[];
	showInactive: boolean;
	expandedGroups: string[];
	recheckTimestamp: string;
}

export const initialState: IFleetSelectionState = {
	selectedEntities: [],
	tempSelectedEntities: [],
	trackedEntities: [],
	showInactive: false,
	expandedGroups: [],
	recheckTimestamp: '',
};

export const fleetSelectionActionCreator = {
	setRecheckTimestamp: (recheckTimestamp: string) => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({
			type: SET_RECHECK_TIMESTAMP,
			payload: recheckTimestamp,
		});
	},
	setSelectedEntities: (selectedEntities: ITreeNode[]) => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({
			type: FLEET_SELECTION_SET_ENTITIES,
			payload: selectedEntities,
		});
	},
	clearTrackedEntities: () => (dispatch: Dispatch<Action>) => {
		dispatch({
			type: FLEET_SELECTION_CLEAR_TRACKED_ENTITIES,
		});
	},
};

export const FleetSelectionDispatcher = {
	refreshFleetSelection: (
		dispatch: Dispatch<IPayloadAction>,
		previousState: IFleetSelectionState,
		showPersons: boolean
	) => {
		ajaxUtil
			.post<FleetSelectionClientModel>(
				`${GlobalSettings.fleetSelection}/RefreshSelectedGroups?showPersons=` + showPersons,
				{
					selectedEntities: previousState.selectedEntities,
					trackedEntities: previousState.trackedEntities,
					showInactive: previousState.showInactive,
				} as FleetSelectionClientModel
			)
			.then((data) => {
				if (data != null) {
					//update tracked groups items
					let updatedTrackedEntities = previousState.trackedEntities
						.filter((tr) => tr.type === 'Group')
						.map((tr) => {
							const foundGroup = data.trackedEntities.find(
								(te) => te != null && te.id === tr.id && te.type === 'Group'
							);

							if (foundGroup) {
								const addedEntities = foundGroup.items
									.filter((t) => tr.items.every((p) => p.id !== t.id))
									.filter(
										(t) =>
											!CheckEntityNotTracked(
												t,
												data.selectedEntities,
												previousState.trackedEntities
											)
									)
									.map((gi) => {
										gi.selected = true;
										return gi;
									});

								const updatedItems = [
									...tr.items.filter((it) => foundGroup.items.some((fgi) => fgi.id === it.id)),
									...addedEntities,
								];

								return { ...tr, items: updatedItems, childCount: updatedItems.length };
							}

							return tr;
						});

					//update the individual items from selected groups
					const unTrackedGroups = previousState.selectedEntities.filter(
						(se) =>
							se.type === 'Group' &&
							previousState.trackedEntities
								.filter((te) => te.type === 'Group')
								.every((te) => te.id != se.id)
					);
					if (unTrackedGroups.length > 0) {
						//all group selected items (from groups not tracked)
						const receivedGroupEntities = data.selectedEntities
							.filter((se) => se.type === 'Group' && unTrackedGroups.some((ug) => ug.id === se.id))
							.map((t) => {
								return t.items;
							})
							.reduce((se, el) => se.concat(el), [] as ITreeNode[]);

						//filter existing (remove items from groups, if any)
						const existingGroupEntities = unTrackedGroups
							.map((t) => {
								return t.items.filter((i) => receivedGroupEntities.some((ri) => ri.id === i.id));
							})
							.reduce((se, el) => se.concat(el), [] as ITreeNode[]);

						//new added entities, add to tracked
						const addedEntities = receivedGroupEntities
							.filter((t) => existingGroupEntities.every((p) => p.id !== t.id))
							.filter(
								(t) => !CheckEntityNotTracked(t, data.selectedEntities, previousState.trackedEntities)
							)
							.map((e) => {
								e.selected = true;
								return e;
							});

						const existingTracked = previousState.trackedEntities.filter((tr) =>
							existingGroupEntities.some((se) => se.id === tr.id)
						);

						updatedTrackedEntities = [...updatedTrackedEntities, ...existingTracked, ...addedEntities];
					}

					const singleEntities = previousState.trackedEntities.filter(
						(tr) =>
							tr.type !== 'Group' &&
							data.selectedEntities.filter((se) => se.type !== 'Group').some((se) => se.id === tr.id)
					);

					updatedTrackedEntities = [...updatedTrackedEntities, ...singleEntities];

					dispatch({
						type: FLEET_SELECTION_SET_ENTITIES,
						payload: data.selectedEntities,
					});

					dispatch({
						type: FLEET_SELECTION_SET_TRACKED_UNFILTERED_ENTITIES,
						payload: updatedTrackedEntities,
					});
				}
			});
	},
	setSelectedEntities: (dispatch: Dispatch<IPayloadAction>, selectedEntities: ITreeNode[]) => {
		dispatch({
			type: FLEET_SELECTION_SET_ENTITIES,
			payload: selectedEntities,
		});
	},
	setTempSelectedEntities: (dispatch: Dispatch<IPayloadAction>, selectedEntities: ITreeNode[]) => {
		dispatch({
			type: FLEET_SELECTION_SET_TEMP_ENTITIES,
			payload: selectedEntities,
		});
	},
	setTrackedUnfilteredEntities: (dispatch: Dispatch<IPayloadAction>, selectedEntities: ITreeNode[]) => {
		dispatch({
			type: FLEET_SELECTION_SET_TRACKED_UNFILTERED_ENTITIES,
			payload: selectedEntities,
		});
	},
	setFleetSelectionShowInactive: (dispatch: Dispatch, showInactive: boolean): void => {
		dispatch({
			type: SET_FLEET_SELECTION_SHOW_INACTIVE,
			payload: showInactive,
		});
	},
	setExpandedGroups: (dispatch: Dispatch, expandedGroup: string[]) => {
		dispatch({
			type: FLEET_SELECTION_SET_EXPANDED_GROUPS,
			payload: expandedGroup,
		});
	},
	addSelectedAndTrackedEntity: (dispatch: Dispatch<IPayloadAction>, entity: ITreeNode) => {
		dispatch({
			type: ADD_SELECTED_AND_TRACKED_ENTITY,
			payload: entity,
		});
	},
};

export const filterUtil = {
	hiddenByText: (text: string, filterText: string, items: ITreeNode[]): boolean => {
		if (!filterText) {
			return false;
		}

		if (filterUtil.textIncludes(text, filterText)) {
			return false;
		}

		//Children have text
		if (items && items.some((i) => filterUtil.textIncludes(i.text, filterText))) {
			return false;
		}
		//Filter out
		return true;
	},
	textIncludes: (text: string, search: string): boolean => {
		return text && search && text.toLowerCase().includes(search.toLowerCase());
	},

	equalType: (type: string, entityFilter: FleetEntityTypeFilter): boolean => {
		if (entityFilter === FleetEntityTypeFilter.person || entityFilter === FleetEntityTypeFilter.driver) {
			//Handle special drivers = persons
			return [
				FleetEntityTypeFilter[FleetEntityTypeFilter.person],
				FleetEntityTypeFilter[FleetEntityTypeFilter.driver],
			].some((t) => t.toString().toLowerCase() === type?.toLowerCase());
		}
		return !!type && type.toLowerCase() === FleetEntityTypeFilter[entityFilter].toLowerCase();
	},
	hiddenByType: (type: string, filterType: FleetEntityTypeFilter, items: ITreeNode[]) => {
		if (filterType === FleetEntityTypeFilter.all) {
			return false;
		}
		if (filterUtil.equalType(type, filterType)) {
			return false;
		}

		//children with that type
		if (items && items.some((i) => filterUtil.equalType(i.type, filterType))) {
			return false;
		}

		//Filter out
		return true;
	},
	filterTreeNodes: (entities: ITreeNode[], filterText: string, entityType: FleetEntityTypeFilter): ITreeNode[] => {
		return entities.filter(
			(e) =>
				!filterUtil.hiddenByText(e.text, filterText, e.items) &&
				!filterUtil.hiddenByType(e.type, entityType, e.items)
		);
	},
};

export const fleetSelectionReducer = (state: IFleetSelectionState, action: { type: string; payload: any }) => {
	state = state || initialState;
	switch (action.type) {
		case FLEET_SELECTION_SET_ENTITIES:
			return {
				...state,
				selectedEntities: action.payload,
			};
		case FLEET_SELECTION_SET_TEMP_ENTITIES:
			return {
				...state,
				tempSelectedEntities: action.payload,
			};
		case FLEET_SELECTION_SET_TRACKED_UNFILTERED_ENTITIES:
			return {
				...state,
				trackedEntities: action.payload,
			};
		case FLEET_SELECTION_CLEAR_TRACKED_ENTITIES:
			return {
				...initialState,
			};
		case SET_FLEET_SELECTION_SHOW_INACTIVE:
			return {
				...state,
				showInactive: action.payload,
			};
		case FLEET_SELECTION_SET_EXPANDED_GROUPS:
			const newExpandedGroups = action.payload as string[];
			return {
				...state,
				expandedGroups: newExpandedGroups.filter((value, index, array) => array.indexOf(value) === index),
			};
		case ADD_SELECTED_AND_TRACKED_ENTITY:
			const entity = action.payload as ITreeNode;

			const newSelectedEntities = [...state.selectedEntities];
			if (state.selectedEntities.findIndex((x) => x.id === entity.id) === -1) {
				newSelectedEntities.push(entity);
				newSelectedEntities.sort((a: ITreeNode, b: ITreeNode) => {
					return a.text.localeCompare(b.text);
				});
			}

			const newTempSelectedEntities = [...state.tempSelectedEntities];
			if (state.tempSelectedEntities.findIndex((x) => x.id === entity.id) === -1) {
				newTempSelectedEntities.push(entity);
				newTempSelectedEntities.sort((a: ITreeNode, b: ITreeNode) => {
					return a.text.localeCompare(b.text);
				});
			}

			const newTrackedEntities = [...state.trackedEntities];
			if (state.trackedEntities.findIndex((x) => x.id === entity.id) === -1) {
				newTrackedEntities.push(entity);
				newTrackedEntities.sort((a: ITreeNode, b: ITreeNode) => {
					return a.text.localeCompare(b.text);
				});
			}

			return {
				...state,
				selectedEntities: newSelectedEntities,
				trackedEntities: newTrackedEntities,
				tempSelectedEntities: newTempSelectedEntities,
			};
		case SET_RECHECK_TIMESTAMP:
			return {
				...state,
				recheckTimestamp: action.payload,
			};
		default:
			return state;
	}
};
