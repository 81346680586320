import React from 'react';
import { MARKER_URL } from '../../Constants';
import { MarkerConfig } from './RietveldMap';

interface Props {
	markerConfig: MarkerConfig;
}
const LiveIcon = (props: Props) => {
	return (
		<div className={'cluster-status-icon-container'}>
			{MARKER_URL[props.markerConfig.liveData.state] ? (
				<img
					className={'cluster-status-icon'}
					alt=""
					src={`${
						MARKER_URL[props.markerConfig.liveData.state]
					}#${props.markerConfig.liveData.direction?.toLowerCase()}`}
				/>
			) : null}
		</div>
	);
};

export default LiveIcon;
