import './PersonSettingsProfile.scss';

import { Button, Card, CardContent, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import AutoTextField from 'components/AutoTextfield/AutoTextField';
import CountrySelection from 'components/Common/CommonDataSelections/CountrySelection';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import { Person } from 'models/Person';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { FieldRules, ValidationResult } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { userDataActionCreators } from 'store/UserData';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

const PersonSettingsProfileValidationConfig = (): FieldRules => {
	return {
		firstName: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		lastName: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		emailAddress: {
			rules: {
				email: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.email(),
					TranslateText('fieldsValidations.invalidEmailFormat')
				),
				maxLength: ValidatorFunctions.maxLength(40),
			},
		},
		phoneNumber: {
			rules: {
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				maxLength: ValidatorFunctions.maxLength(20),
			},
		},
	};
};

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

const areTheSame = (initialPerson: Person, changedPerson: Person) => {
	if (JSON.stringify(initialPerson) === JSON.stringify(changedPerson)) {
		return true;
	}
	return false;
};

const PersonSettingsProfile = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanEditProfile = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Profile,
		values: [ClaimValue.editProfile],
	});

	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const usernameAbbreviation = useSelector(
		(state: ApplicationState) => state.currentSession.userFirstAndLastNameAbbreviation
	);
	const aspNetUserId = useSelector((state: ApplicationState) => state.currentSession.aspNetUserId);

	const dispatch = useDispatch();

	const [initialPerson, setInitialPerson] = useState<Person>(null);
	const [person, setPerson] = useState<Person>(null);
	const [isValid, setIsValid] = useState(false);
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);
	const [validator, setValidator] = useState(
		new Validator({ fieldRules: PersonSettingsProfileValidationConfig() }, true)
	);
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (!props.unsavedData && isChanged) {
			props.setUnsavedData(true);
			window.onbeforeunload = () => {
				return 'Unsaved data';
			};
		}
		if (person) {
			validator
				.validate({
					firstName: person.firstName,
					lastName: person.lastName,
					emailAddress: person.emailAddress,
					phoneNumber: person.phoneNumber,
				})
				.then((result) => {
					setValidationResult(result.validationResult);
					setIsValid(result.formResult && isChanged);
				});
		}
	}, [person]);

	useEffect(() => {
		const url = `${GlobalSettings.profileMaintenanceApi}/${personId}/bypersonid`;
		ajaxUtil.get<Person>(url).then((data) => {
			const result = data ?? new Person();
			setPerson(result);
			setInitialPerson(result);
		});
	}, []);

	const handleValueChange = (changedPerson: Person) => {
		if (areTheSame(initialPerson, changedPerson)) {
			setIsChanged(false);
		} else if (!isChanged) {
			setIsChanged(true);
		}
		setPerson(changedPerson);
	};

	const updatePerson = (updatedPerson: Person) => {
		ajaxUtil.put(GlobalSettings.profileMaintenanceApi, updatedPerson).then(async () => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
			bindActionCreators(userDataActionCreators, dispatch).getUserData(aspNetUserId);
		});
	};

	return (
		<div style={{ marginTop: '20px' }} className={'person-settings-profile'}>
			<h5 className={'profile-settings'}>{TranslateText('personSettingsProfile.profile')}</h5>
			<Grid container spacing={4}>
				<Grid item md={4}>
					<Card className={'personal-data-card'}>
						<CardContent>
							<div>
								<Typography variant="h6" className={'data-title'}>
									{TranslateText('personSettingsProfile.personalData')}
								</Typography>
								<div className={'form-control-content'}>
									<FormControl className={'margin-bottom-7'}>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.firstName}
											onChange={(value) => handleValueChange({ ...person, firstName: value })}
											label={TranslateText('fields.firstName')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
										<ValidationMessage result={validationResult?.firstName} />
									</FormControl>
									<FormControl className={'margin-bottom-7'}>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.lastName}
											onChange={(value) => handleValueChange({ ...person, lastName: value })}
											label={TranslateText('fields.lastName')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
										<ValidationMessage result={validationResult?.lastName} />
									</FormControl>
									<FormControl className={'margin-bottom-7'}>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.birthName}
											onChange={(value) => handleValueChange({ ...person, birthName: value })}
											label={TranslateText('fields.birthName')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
									</FormControl>
									<FormControl className={'margin-bottom-7'}>
										<MaterialDatePicker
											disabled={!userCanEditProfile}
											className={'datepicker-settings'}
											name="birthDate"
											date={person?.birthDate}
											format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
												DateTimeUtil.dateFormat(),
												true
											)}
											label={TranslateText('fields.birthDate')}
											showTime={false}
											onDateChange={(date?: Date) =>
												handleValueChange({ ...person, birthDate: date })
											}
											fullWidth={true}
										/>
									</FormControl>
									<FormControl className={'margin-bottom-7'}>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.initials}
											onChange={(value) => handleValueChange({ ...person, initials: value })}
											label={TranslateText('fields.initials')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
									</FormControl>
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item md={5}>
					<Card className={'home-street-card'}>
						<CardContent>
							<div>
								<Typography variant="h6" className={'data-title'}>
									{TranslateText('personSettingsProfile.homeAddress')}
								</Typography>
								<div className={'form-control-content'}>
									<div className={'mozilla-display'}>
										<FormControl>
											<AutoTextField
												disabled={!userCanEditProfile}
												value={person?.homeAddress?.street}
												onChange={(value) =>
													handleValueChange({
														...person,
														homeAddress: { ...person?.homeAddress, street: value },
													})
												}
												label={TranslateText('fields.street')}
												type={'text'}
												hasStyles
												style={{ fontSize: 12, marginRight: '20px', fontFamily: 'Ubuntu' }}
												showClearButton={false}
												debounceDuration={500}
											/>
										</FormControl>
										<FormControl>
											<AutoTextField
												disabled={!userCanEditProfile}
												value={person?.homeAddress?.number}
												onChange={(value) =>
													handleValueChange({
														...person,
														homeAddress: { ...person?.homeAddress, number: value },
													})
												}
												label={TranslateText('fields.number')}
												type={'text'}
												hasStyles
												style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
												showClearButton={false}
												debounceDuration={500}
											/>
										</FormControl>
									</div>
									<div className={'mozilla-display margin-top-7'}>
										<FormControl>
											<AutoTextField
												disabled={!userCanEditProfile}
												value={person?.homeAddress?.postalCode}
												onChange={(value) =>
													handleValueChange({
														...person,
														homeAddress: { ...person?.homeAddress, postalCode: value },
													})
												}
												label={TranslateText('fields.postalCode')}
												type={'text'}
												hasStyles
												style={{ fontSize: 12, marginRight: '20px', fontFamily: 'Ubuntu' }}
												showClearButton={false}
												debounceDuration={500}
											/>
										</FormControl>
										<FormControl>
											<AutoTextField
												disabled={!userCanEditProfile}
												value={person?.homeAddress?.city}
												onChange={(value) =>
													handleValueChange({
														...person,
														homeAddress: { ...person?.homeAddress, city: value },
													})
												}
												label={TranslateText('fields.city')}
												type={'text'}
												hasStyles
												style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
												showClearButton={false}
												debounceDuration={500}
											/>
										</FormControl>
									</div>
									<FormControl className={'country-selection-form'}>
										<CountrySelection
											disabled={!userCanEditProfile}
											label={TranslateText('fields.country')}
											name="countryId"
											valueId={person?.homeAddress?.countryId?.toString()}
											onChange={({ value }) =>
												handleValueChange({
													...person,
													homeAddress: {
														...person.homeAddress,
														countryId: value as string,
													},
												})
											}
											className={'country-selection'}
										/>
									</FormControl>
								</div>
								<div>
									<span className="circle-profile-menu">
										<span className={'circle-profile-menu-name'}>{usernameAbbreviation}</span>
									</span>
									<Button className={'change-button'} hidden>
										{TranslateText('personSettingsProfile.change')}
									</Button>
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Divider className={'divider'} />
				<Grid item md={4} className={'contact-information'}>
					<Card className={'contact-information-card'}>
						<CardContent>
							<div>
								<Typography variant="h6" className={'data-title'}>
									{TranslateText('personSettingsProfile.contactInformation')}
								</Typography>
								<div className={'form-control-content'}>
									<FormControl className={'margin-bottom-7'}>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.emailAddress}
											onChange={(value) => handleValueChange({ ...person, emailAddress: value })}
											label={TranslateText('fields.emailAddress')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
										<ValidationMessage result={validationResult?.emailAddress} />
									</FormControl>
									<FormControl>
										<AutoTextField
											disabled={!userCanEditProfile}
											value={person?.phoneNumber}
											onChange={(value) => handleValueChange({ ...person, phoneNumber: value })}
											label={TranslateText('fields.phoneNumber')}
											type={'text'}
											fullWidth
											hasStyles
											style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
											showClearButton={false}
											debounceDuration={500}
										/>
										<ValidationMessage result={validationResult?.phoneNumber} />
									</FormControl>
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item md={5} className={'privacy-data'}>
					<Card className={'privacy-data-card'}>
						<CardContent>
							<div>
								<Typography variant="h6" className={'data-title'}>
									{TranslateText('personSettingsProfile.privacyData')}
								</Typography>
								<div className={'form-control-content'}>
									<FormControl className={'margin-bottom-7'}>
										<TextField
											value={person?.socialSecurityNumber}
											label={TranslateText('fields.socialSecurityNumber')}
											type={'text'}
											fullWidth
											inputProps={{
												style: { fontSize: 12, fontFamily: 'Ubuntu' },
												readOnly: true,
											}}
											disabled={true}
										/>
									</FormControl>
									<FormControl>
										<TextField
											value={person?.tachoId ?? ''}
											label={TranslateText('personSettingsProfile.driverCardNumber')}
											type={'text'}
											fullWidth
											inputProps={{
												style: { fontSize: 12, fontFamily: 'Ubuntu' },
												readOnly: true,
											}}
											disabled={true}
										/>
									</FormControl>
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Divider className={'divider'} />
			</Grid>
			{userCanEditProfile && (
				<Button
					style={{
						backgroundColor: !isValid ? '#999999' : '#006837',
					}}
					className={'save-button'}
					disabled={!isValid}
					onClick={() => updatePerson(person)}
				>
					{TranslateText('common.buttonSave')}
				</Button>
			)}
			{isValid && (
				<span className={'info-text'}>{TranslateText('personSettingsWindow.changedAppliedAfterLogin')}</span>
			)}
		</div>
	);
};

export default PersonSettingsProfile;
