import './customerSelection.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MAP_SELECTED_ENTITY } from 'store/LiveMap';

import { Chip, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { ApplicationState, IAvailableCustomer } from '../../store';
import { availableCustomersActions } from '../../store/AvailableCustomers';
import { fleetSelectionActionCreator, FleetSelectionDispatcher } from '../../store/FleetSelection';
import { globalCustomerActionCreators } from '../../store/GlobalCustomer';
import { historyFilterActionCreators } from '../../store/HistoryFilter';
import { unsavedDataStoreActionCreators } from '../../store/UnsavedDataStore';
import { TranslateText } from '../../utils/Translations';
import { getSelectedCustomer } from './CustomerUtils';

export const CustomerSelection = () => {
	const dispatch = useDispatch();

	const globalCustomer = useSelector((state: ApplicationState) => state.globalCustomer.filteredCustomer);
	const customers = useSelector((state: ApplicationState) => state.availableCustomers.data);
	const newCustomerAdded = useSelector((state: ApplicationState) => state.availableCustomers.hasNewCustomerAdded);
	const customerChangedName = useSelector(
		(state: ApplicationState) => state.availableCustomers.hasCustomerWithChangedName
	);
	const selectionEnabled = useSelector((state: ApplicationState) => state.availableCustomers.selectionEnabled);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);

	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);

	const autocompleteRef = useRef(null);
	const [selectedCustomer, setSelectedCustomer] = useState<IAvailableCustomer[]>([]);
	const [selectedCustomerTree, setSelectedCustomerTree] = useState<{ id: string; name: string; active: boolean }[]>(
		[]
	);
	const [filteredCustomers, setFilteredCustomers] = useState<IAvailableCustomer[]>([]);
	const [placeholder, setPlaceholder] = useState<string>(TranslateText('maintenanceOverview.searchBar.searchOn'));
	const [autocompleteFocus, setAutocompleteFocus] = useState<boolean>(false);
	const [changeReason, setChangeReason] = useState<string>('');
	const [searchFocus, setSearchFocus] = useState<boolean>(false);
	//get customers
	useEffect(() => {
		availableCustomersActions.fetch(dispatch);
	}, []);
	//get customers
	useEffect(() => {
		if (newCustomerAdded) {
			availableCustomersActions.fetch(dispatch);
			dispatch(availableCustomersActions.hasNewCustomer(false));
		}
	}, [newCustomerAdded]);

	//get customers
	useEffect(() => {
		if (customerChangedName) {
			availableCustomersActions.fetch(dispatch);
			dispatch(availableCustomersActions.customerChangedName(false));
		}
	}, [customerChangedName]);
	//set dropdown selected customer and filtered customers
	useEffect(() => {
		if (globalCustomer && globalCustomer.customersTree && customers) {
			const newFilteredCustomer = customers.filter((c) => c.parentId && c.parentId === globalCustomer.id);
			setPlaceholder(
				newFilteredCustomer.length >= 1 ? TranslateText('maintenanceOverview.searchBar.searchOn') : ''
			);
			const newSelectedCustomer = customers.find((x) => x.value === globalCustomer.id);
			if (newSelectedCustomer) {
				if (
					!!changeReason?.length &&
					(!!filteredCustomers?.length || !(globalCustomer?.level === CustomerLevelEnum.Default)) &&
					autocompleteFocus
				) {
					autocompleteRef.current.click();
				}
				setSelectedCustomer([newSelectedCustomer]);
				setSelectedCustomerTree(globalCustomer.customersTree);
				setFilteredCustomers(newFilteredCustomer);
			} else {
				setSelectedCustomer([]);
				setSelectedCustomerTree([]);
				setFilteredCustomers(newFilteredCustomer);
			}
		} else {
			setSelectedCustomer([]);
			setSelectedCustomerTree([]);
			setFilteredCustomers(customers);
			setPlaceholder(TranslateText('maintenanceOverview.searchBar.searchOn'));
		}
	}, [globalCustomer, customers]);

	//dropdown customers actions
	const onCustomerRemove = (customerId: string) => {
		const customer = customers.find((x) => x.value === customerId);
		let newCustomer: IAvailableCustomer = null;
		if (customer.parentId) {
			newCustomer = customers.find((x) => x.value === customer.parentId);
		}
		changeStoreCustomer(selectedCustomer.length ? selectedCustomer[0] : null, newCustomer, customers);
	};
	const onCustomerChange = (
		event: React.ChangeEvent<{}>,
		value: (string | IAvailableCustomer)[],
		reason: AutocompleteChangeReason
	) => {
		if (reason === 'select-option') {
			let newCustomer: IAvailableCustomer = null;
			if (value && value.length) {
				newCustomer = value[value.length - 1] as IAvailableCustomer;
			}
			changeStoreCustomer(selectedCustomer.length ? selectedCustomer[0] : null, newCustomer, customers);
		} else if (reason === 'clear') {
			changeStoreCustomer(selectedCustomer.length ? selectedCustomer[0] : null, null, customers);
			dispatch({
				type: SET_MAP_SELECTED_ENTITY,
				payload: null,
			});
		}
		localStorage.removeItem('liveData');
	};
	const changeStoreCustomer = useCallback(
		(
			currentCustomer: IAvailableCustomer | null,
			newCustomer: IAvailableCustomer | null,
			customersList: IAvailableCustomer[]
		) => {
			if (currentCustomer) {
				dispatch(globalCustomerActionCreators.removeCustomer(currentCustomer.value));
			}
			if (newCustomer) {
				dispatch(globalCustomerActionCreators.addCustomer(getSelectedCustomer(newCustomer, customersList)));
			}
			FleetSelectionDispatcher.setFleetSelectionShowInactive(dispatch, false);
			dispatch(fleetSelectionActionCreator.clearTrackedEntities());
			dispatch(historyFilterActionCreators.setTrackTypeFilter(TripTypesEnum.None));
		},
		[]
	);
	useEffect(() => {
		if (changeReason === 'delete' || changeReason === 'select-option' || changeReason === 'clear') {
			setAutocompleteFocus(true);
			autocompleteRef.current.click();
		}
	}, [changeReason, selectedCustomerTree.length]);
	//selected template
	const renderTags = () => {
		if (selectedCustomer && selectedCustomerTree.length) {
			return (
				<>
					{selectedCustomerTree.map((item, index) => {
						return (
							<>
								<Tooltip key={index} title={item.name}>
									<Chip
										className={`chip-impersonated-count-${selectedCustomerTree.length} ${
											!item.active ? 'inactive-customer' : ''
										}`}
										variant="outlined"
										size="small"
										label={item.name}
										onDelete={() => {
											validateUnsavedChanges(() => onCustomerRemove(item.id));
											setAutocompleteFocus(true);
											autocompleteRef.current.click();
											setChangeReason('delete');
										}}
										disabled={!selectionEnabled || unsavedData}
									/>
								</Tooltip>
							</>
						);
					})}
				</>
			);
		}

		return null;
	};
	const validateUnsavedChanges = (callbackFn: () => void) => {
		if (!tripUnsavedData && !unsavedData) {
			callbackFn();
		} else {
			dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(true));
			dispatch(unsavedDataStoreActionCreators.setPromptCallback(() => callbackFn()));
		}
	};
	return (
		<div className={'customer-selection'}>
			<Autocomplete
				open={
					autocompleteFocus &&
					(!!filteredCustomers?.length || globalCustomer?.level !== CustomerLevelEnum.Default)
				}
				onFocus={() => {
					setAutocompleteFocus(true);
					setSearchFocus(true);
				}}
				onOpen={() => setAutocompleteFocus(true)}
				onClose={() => {
					setAutocompleteFocus(false);
				}}
				onBlur={() => {
					setSearchFocus(false);
					setAutocompleteFocus(false);
					if (!filteredCustomers?.length) {
						setChangeReason('');
					}
				}}
				innerRef={autocompleteRef}
				blurOnSelect={true}
				id="customer-autocomplete"
				className={
					selectedCustomer?.length || autocompleteFocus || searchFocus
						? !!filteredCustomers?.length ||
						  (globalCustomer?.level !== CustomerLevelEnum.Default && changeReason !== 'select-option')
							? 'active'
							: 'active removeFocus'
						: ''
				}
				multiple
				autoHighlight
				fullWidth
				value={selectedCustomer}
				onChange={(event, value, reason) => {
					if (reason === 'clear' || reason === 'select-option') {
						setChangeReason(reason);
					} else {
						setChangeReason('');
					}
					validateUnsavedChanges(() => onCustomerChange(event, value, reason));
				}}
				options={filteredCustomers}
				getOptionLabel={(option) => option.text}
				renderOption={(option: IAvailableCustomer) => (
					<span style={{ color: !option.active ? 'lightgrey' : 'black' }}>{option.text}</span>
				)}
				renderTags={renderTags}
				renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder} />}
				disabled={!selectionEnabled || unsavedData || !customers || customers.length < 1}
			/>
		</div>
	);
};
export default CustomerSelection;
