import './TreeSelectionDialog.scss';

import SelectionUtil from 'components/SelectionDialog/SelectionUtil';
import TreeSelectionDialogContent from 'components/TreeSelectionDialogContent/TreeSelectionDialogContent';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Typography } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import { ApplicationState } from '../../store';
import { FleetEntityTypeFilter } from '../../store/FleetSelection';
import { TranslateText } from '../../utils/Translations';
import { ITreeSelectionUtil } from '../../utils/TreeSelection/ITreeSelectionUtil';
import { DebouncedButton } from '../Common/DebouncedButton';
import RadioButtonContainer, { ButtonConfig } from '../RadioButtonContainer';
import { ITreeNode } from '../SelectionTree/TreeNode/types';

export interface SelectionFilter {
	customerId: string;
	showInactive: boolean;
	filterText: string;
	selectedFilter: FleetEntityTypeFilter;
}

interface Props {
	visible: boolean;
	initialSelection: ITreeNode[];
	filterButtons?: ButtonConfig[];
	title?: string;
	filterPlaceholder?: string;
	selectionUtil?: ITreeSelectionUtil;
	displayInactive?: boolean;
	showInactive: boolean;
	enableClientFilter?: boolean;
	closeCallback: () => void;
	saveCallback: (data: ITreeNode[]) => void;
	retrieveData?: (filter: SelectionFilter) => Promise<ITreeNode[]>;
	checkShouldReload?: () => boolean;
}

const TreeSelectionDialog = (props: Props) => {
	const customerId = useSelector((state: ApplicationState) =>
		state.loadedEntityContext?.entityContextData?.customerId
			? state.loadedEntityContext?.entityContextData?.customerId
			: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);

	const [localShowInactive, setLocalShowInactive] = useState(props.showInactive);
	const [selectedFilter, setSelectedFilter] = useState<FleetEntityTypeFilter | null>(() => {
		if (props.filterButtons.some((x) => x.id === FleetEntityTypeFilter.all)) {
			return FleetEntityTypeFilter.all;
		} else if (props.filterButtons.length) {
			return props.filterButtons[0].id;
		}
		return null;
	});
	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [clientTreeData, setClientTreeData] = useState([] as ITreeNode[]);
	const [treeData, setTreeData] = useState([] as ITreeNode[]);
	const [selectedItems, setSelectedItems] = useState([...props.initialSelection]);
	const [expanded, setExpanded] = useState([] as string[]);

	useEffect(() => {
		if (props.visible) {
			const filter: SelectionFilter = {
				customerId: customerId,
				showInactive: localShowInactive,
				filterText: filterText.trim(),
				selectedFilter: selectedFilter,
			};
			if (props.enableClientFilter) {
				if (clientTreeData.length > 0 && (!props.checkShouldReload || !props.checkShouldReload())) {
					let filteredData = cloneDeep(clientTreeData);
					filteredData = filteredData.filter((node) => {
						if (node.items) {
							if (filterText && filterText.trim() != '') {
								node.items = node.items.filter((t) =>
									TranslateText(t.text)
										.toLowerCase()
										.includes(filterText.trim().toLowerCase())
								);
								node.childCount = node.items.length;
							}
							return node.childCount > 0;
						}
						return false;
					});
					setTreeData([]);
					props.selectionUtil.prepareTreeData(filteredData, selectedItems, expanded, setTreeData);
					updateExpandedState(filterText, filteredData);
				} else {
					props
						.retrieveData(filter)
						.then((data) => {
							setTreeData([]);
							setClientTreeData(data);
							props.selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
							updateExpandedState(filterText, data);
						})
						.catch((e) => {
							setTreeData([]);
						});
				}
			} else {
				props
					.retrieveData(filter)
					.then((data) => {
						setTreeData([]);
						props.selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
						updateExpandedState(filterText, data);
					})
					.catch((e) => {
						setTreeData([]);
					});
			}
		}
	}, [props.visible, filterText, localShowInactive, selectedFilter]);

	const updateExpandedState = (filterText: string, data: ITreeNode[]) => {
		if (filterText && filterText.trim().length > 0) {
			setExpanded(data.map((d) => d.id));
		} else if (!filterText || previousFilterText !== filterText) {
			setExpanded([]);
		}
		setPreviousFilterText(filterText?.trim());
	};

	useEffect(() => {
		//reset filters when closing dialog
		if (!props.visible) {
			setFilterText('');
			setExpanded([]);
		}
	}, [props.visible]);

	useEffect(() => {
		if (props.visible) {
			//Update tree when selection changes
			props.selectionUtil.prepareTreeData(treeData, selectedItems, expanded, setTreeData);
		}
	}, [selectedItems, expanded]);

	useEffect(() => {
		//Set tree data for initial selection, or re-opening the dialog
		setSelectedItems(props.initialSelection);
	}, [props.initialSelection]);

	const saveClicked = useCallback(() => {
		props.saveCallback(selectedItems);
	}, [selectedItems]);

	const cancelClicked = useCallback(() => {
		setLocalShowInactive(props.showInactive ?? false);
		setSelectedItems([...props.initialSelection]);
		props.closeCallback();
	}, [selectedItems]);

	//expand callback
	const expandCallback = useCallback(
		(node: ITreeNode) => {
			props.selectionUtil.expandCallback(node, expanded, setExpanded);
		},
		[expanded]
	);

	//select callback
	const selectCallback = useCallback(
		(node: ITreeNode) => {
			if (node.canBeChecked) {
				props.selectionUtil.select(node, treeData, setTreeData, selectedItems, setSelectedItems);
			}
		},
		[treeData, selectedItems]
	);

	//clear selection
	const clearSelection = useCallback(async () => {
		await props.selectionUtil.clearSelection(treeData, setTreeData, setSelectedItems);
	}, [treeData, selectedItems]);

	//remove selected item
	const removeSelectedItem = useCallback(
		(node: ITreeNode) => {
			props.selectionUtil.deselectNode(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	//select all
	const selectAll = useCallback(async () => {
		props.selectionUtil.selectAll(treeData, setTreeData, setSelectedItems);
	}, [treeData]);

	useEffect(() => {
		if (!props.showInactive) {
			setSelectedItems(selectedItems.filter((t) => t.active));
		}
	}, [props.showInactive]);

	return (
		<div className={'selection-dialog-container'}>
			<Dialog onEscapeKeyDown={cancelClicked} fullWidth className={'selection-dialog'} open={props.visible}>
				<DialogContent>
					<span className={'e-dlg-header'}>{props.title ? props.title : TranslateText('common.filter')}</span>
					<div className="filter">
						<div className="buttons">
							{props.filterButtons.length ? (
								<RadioButtonContainer
									buttons={props.filterButtons}
									visible={props.visible}
									selectedButton={selectedFilter}
									buttonSelectCallback={setSelectedFilter}
								/>
							) : null}
						</div>
						{props.displayInactive ? (
							<FormControlLabel
								control={
									<Checkbox
										name="showInactive"
										color={'primary'}
										checked={localShowInactive}
										onChange={() => setLocalShowInactive((prev) => !prev)}
									/>
								}
								label={
									<Typography style={{ fontSize: 12 }}>
										{TranslateText('fleetSelection.showInactive')}
									</Typography>
								}
							/>
						) : null}
					</div>
					<TreeSelectionDialogContent
						treeData={treeData}
						selectedItems={selectedItems}
						changeFilterCallback={setFilterText}
						selectAll={selectAll}
						selectCallback={selectCallback}
						removeSelectedItem={removeSelectedItem}
						clearSelection={clearSelection}
						expandCallback={expandCallback}
						filterText={filterText}
						filterPlaceholder={props.filterPlaceholder}
					/>
					<div className={'buttons-container'}>
						<Button onClick={cancelClicked}>{TranslateText('common.buttonCancel')}</Button>
						<DebouncedButton onClick={saveClicked} debounceTime={DEBOUNCE_TIME}>
							{TranslateText('common.buttonSave')}
						</DebouncedButton>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};
TreeSelectionDialog.defaultProps = {
	visible: true,
	displayInactive: true,
	showInactive: false,
	selectionUtil: SelectionUtil,
	filterButtons: [],
};
export default TreeSelectionDialog;
