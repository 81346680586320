import './rightSidebarMenu.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiveDataResultDto } from 'store/LiveData';

import { StateFilterDataSource, StateFilterEnum } from '../../models/LiveMenuUtils';
import {
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from '../../shared/effects/useShowLastEntities';
import { ApplicationState } from '../../store';
import { LiveDataFilterDispatcher } from '../../store/LiveDataFilter';
import { TranslateText } from '../../utils/Translations';
import AutoTextField from '../AutoTextfield/AutoTextField';
import { FleetSelectionButton } from '../FleetSelectionButton';
import StateIconLiveOverview from './StateIconLiveOverview';
import { LiveDataFilterUtil } from './UseLiveFilterService';

const FleetOverviewFilter = () => {
	const driverIdentification = useSelector(
		(state: ApplicationState) => state.currentSession.customer.featuresSettings.driverIdentification
	);

	const [icons, setIcons] = useState<{ id: StateFilterEnum; count: number }[]>([]);
	const liveData = useSelector((s: ApplicationState) => s.liveData.liveEntitiesUnfiltered);
	const trackedEntities = useSelector((s: ApplicationState) => s.fleetSelection.trackedEntities);
	const { text, stateType, entityType } = useSelector((s: ApplicationState) => s.liveDataFilter);

	const [trackedLiveData, setTrackedLiveData] = useState<LiveDataResultDto[]>(() =>
		liveData.filter((item) =>
			trackedEntities.some((te) => item.entityId === te.id || te.items?.some((i) => i.id === item.entityId))
		)
	);

	useEffect(() => {
		//filter live data by tracked entities
		const trackedLiveData = liveData.filter((item) =>
			trackedEntities.some((te) => item.entityId === te.id || te.items?.some((i) => i.id === item.entityId))
		);
		setTrackedLiveData(trackedLiveData);
	}, [liveData, trackedEntities]);

	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();
	const dispatch = useDispatch();

	useEffect(() => {
		setIcons(
			StateFilterDataSource.map((id) => ({
				id: id,
				count: LiveDataFilterUtil.filter(
					trackedLiveData,
					text,
					id,
					entityType,
					showLastDriverOfParkedObject,
					showLastObjectOfParkedDriver
				).length,
			}))
		);
	}, [trackedLiveData, text, entityType, showLastDriverOfParkedObject, showLastObjectOfParkedDriver]);

	const searchTextChanged = (value: string) => {
		LiveDataFilterDispatcher.setFilterText(dispatch, value);
	};

	return (
		<>
			<div className={'search'}>
				<AutoTextField
					placeHolder={TranslateText('fleetSelection.searchEntitiesPlaceholderLiveOverview')}
					onChange={searchTextChanged}
					debounceDuration={100}
					value={text}
					showClearButton={true}
				/>
				<span style={{ marginLeft: 10, marginTop: 3, marginRight: 2 }}>
					<FleetSelectionButton />
				</span>
			</div>
			<div className="filter-buttons">
				{icons.map((value, index) => (
					<div key={index} className="image-filters">
						<div
							className={`image-icon ${
								(value.id === StateFilterEnum.All
								? value.id === stateType
								: (value.id & stateType) === value.id && stateType !== StateFilterEnum.All)
									? 'selected'
									: ''
							}`}
							onClick={() => {
								let newValue;
								if (stateType === StateFilterEnum.All) {
									newValue = value.id === StateFilterEnum.All ? StateFilterEnum.None : value.id;
								} else {
									if (value.id === StateFilterEnum.All) {
										if (stateType !== StateFilterEnum.All) {
											newValue = StateFilterEnum.All;
										} else {
											newValue = StateFilterEnum.None;
										}
									} else {
										let newState = stateType ^ value.id;
										if (newState === StateFilterEnum.None) {
											newState = StateFilterEnum.All;
										}
										newValue = newState;
									}
								}
								LiveDataFilterDispatcher.setFilterState(dispatch, newValue);
							}}
						>
							<StateIconLiveOverview stateType={value.id} />
						</div>
						<span className={StateFilterEnum[value.id] + '-number'}>{value.count}</span>
					</div>
				))}
			</div>
		</>
	);
};
export default FleetOverviewFilter;
