import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

import { enableRipple } from '@syncfusion/ej2-base';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import EasyTrackFeature from '../../authorization/EasyTrackFeature';
import CustomerImportWidget from '../../components/Widgets/CustomerWidget/CustomerImportWidget';
import GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import ButtonWidget from '../Widgets/ButtonWidget';
import AddLocationCategoryButton from '../Widgets/ButtonWidget/AddLocationCategoryButton';
import CustomerDateTimeView from '../Widgets/CustomerDateTimeView';
import CustomerFeaturesView from '../Widgets/CustomerFeaturesView';
import CustomerHistorySettingsView from '../Widgets/CustomerHistorySettingsView';
import CustomerInformationView from '../Widgets/CustomerInformationView';
import CustomerLiveSettingsView from '../Widgets/CustomerLiveSettingsView';
import CustomerReportSettingsView from '../Widgets/CustomerReportSettingsView';
import CustomerVisualSettingsView from '../Widgets/CustomerVisualSettingsView';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import FleetOverviewSettingsView from '../Widgets/FleetOverviewSettingsView';
import GeocodingCacheView from '../Widgets/GeocodingCacheView';
import MessageServiceSettingsView from '../Widgets/MessageServiceSettingsView';
import CustomerBlockSettingsView from '../Widgets/Views/CustomerBlockSettingsView';
import CustomerEntityDefaultsView from '../Widgets/Views/CustomerEntityDefaultsView';
import CustomerLocationCategoriesView from '../Widgets/Views/CustomerLocationCategoriesView';
import Widget from '../Widgets/Widget';

enableRipple(true);

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type Props = WidgetDashboardTransferableProps & MatchProps & {};
const pathToOverview = '/customers';
const CustomerDetails = (props: Props) => {
	const [currentTitle, setCurrentTitle] = useState<string>('');
	const [reloadInfo, setReloadInfo] = useState<boolean>(false);
	const [reloadReportSettings, setReloadReportSettings] = useState(false);
	const [reloadEntityDefaultSettings, setReloadEntityDefaultSettings] = useState(false);
	const [customerLevel, setCustomerLevel] = useState<CustomerLevelEnum | null>(null);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const showEditButton = useSelector((s: ApplicationState) =>
		s.oidc.user?.profile[ClaimType.Customers].includes(ClaimValue.edit)
	);
	const customerDetail = useSelector((s: ApplicationState) => s.loadedEntityContext.entityContextData.entityDetails);
	const dispatch = useDispatch();

	useEffect(() => {
		const url = `${GlobalSettings.customersMaintenanceApi}/${props.match.params.id}`;
		ajaxUtil.get(url).then((data: any) => {
			setCustomerLevel(data?.customerType);
		});
	}, [props.match?.params?.id]);
	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedCustomerContext(null));
		};
	}, []);

	const events = {
		handleDataSaved: () => {
			setReloadInfo(true);
			setReloadReportSettings(true);
			setReloadEntityDefaultSettings(true);
		},
		handleDisplayedCallback: () => {
			setReloadInfo(false);
		},
		handleReportDisplayedCallback: () => {
			setReloadReportSettings(false);
		},
		handleEntityDisplayCallback: () => {
			setReloadEntityDefaultSettings(false);
		}
	};


	return customerLevel !== null ? (
		<WidgetDashboard
			goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.Customer}s`)}
			history={props.history}
			currentRecordId={props.match.params.id}
			goBackToCallback={() => {
				props.history.push(pathToOverview, { id: props.match.params.id });
			}}
			urlPath={pathToOverview}
			breadcrumbText={currentTitle ? `${TranslateText('common.customer')} > ${currentTitle}` : ''}
			entityType={EntityTypeEnum.Customer}
		>
			<Widget
				position={{
					row: 0,
					col: 0,
					sizeX: 5,
					sizeY: 6,
				}}
				displayedCallback={events.handleDisplayedCallback}
				forceReload={reloadInfo}
				url={`${GlobalSettings.customersMaintenanceApi}`}
				widgetTitle={TranslateText('customerOverview.information')}
				viewComponent={CustomerInformationView}
				setDashboardTitleCallback={(title: string) => setCurrentTitle(title)}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 0,
					col: 5,
					sizeX: 5,
					sizeY: 5,
				}}
				url={`${GlobalSettings.customersMaintenanceApi}/geocoding`}
				widgetTitle={TranslateText('customerOverview.reverseGeocoding')}
				viewComponent={GeocodingCacheView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 0,
					col: 10,
					sizeX: 5,
					sizeY: 5,
				}}
				url={`${GlobalSettings.customersMaintenanceApi}/features`}
				widgetTitle={TranslateText('customerOverview.features')}
				viewComponent={CustomerFeaturesView}
				savedCallback={events.handleDataSaved}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 0,
					col: 15,
					sizeX: 5,
					sizeY: 5,
				}}
				url={`${GlobalSettings.customersMaintenanceApi}/reportSettings`}
				widgetTitle={TranslateText('customerOverview.reportSettings')}
				viewComponent={CustomerReportSettingsView}
				forceReload={reloadReportSettings}
				displayedCallback={events.handleReportDisplayedCallback}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 6,
					col: 0,
					sizeX: 5,
					sizeY: 4,
				}}
				widgetTitle={TranslateText('customerOverview.visualSettings')}
				url={`${GlobalSettings.customersMaintenanceApi}/visualSettings`}
				viewComponent={CustomerVisualSettingsView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 5,
					col: 5,
					sizeX: 5,
					sizeY: 5,
				}}
				url={`${GlobalSettings.customersMaintenanceApi}/dateTimeSettings`}
				widgetTitle={TranslateText('customerOverview.dateTimeSettings')}
				viewComponent={CustomerDateTimeView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 5,
					col: 10,
					sizeX: 5,
					sizeY: 5,
				}}
				widgetTitle={TranslateText('customerOverview.fleetOverviewSettings')}
				url={`${GlobalSettings.customersMaintenanceApi}/fleetOverviewSettings`}
				viewComponent={FleetOverviewSettingsView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 5,
					col: 15,
					sizeX: 5,
					sizeY: 5,
				}}
				url={`${GlobalSettings.customersMaintenanceApi}/entityDefaults`}
				widgetTitle={TranslateText('customerOverview.entityDefaults')}
				viewComponent={CustomerEntityDefaultsView}
				forceReload={reloadEntityDefaultSettings}
				displayedCallback={events.handleEntityDisplayCallback}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 10,
					col: 0,
					sizeX: 5,
					sizeY: 5,
				}}
				widgetTitle={TranslateText('customerOverview.liveSettings')}
				url={`${GlobalSettings.customersMaintenanceApi}/liveSettings`}
				viewComponent={CustomerLiveSettingsView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 10,
					col: 5,
					sizeX: 5,
					sizeY: 5,
				}}
				widgetTitle={TranslateText('customerOverview.historySettings')}
				url={`${GlobalSettings.customersMaintenanceApi}/historySettings`}
				viewComponent={CustomerHistorySettingsView}
				showEditButton={showEditButton}
			/>
			<Widget
				position={{
					row: 10,
					col: 10,
					sizeX: 5,
					sizeY: 5,
				}}
				widgetTitle={TranslateText('common.blockSettings')}
				url={`${GlobalSettings.customersMaintenanceApi}/blockSettings`}
				viewComponent={CustomerBlockSettingsView}
				showEditButton={showEditButton}
			/>

			<ProtectedContainer claimConfig={[{ claim: ClaimType.Import, values: [ClaimValue.edit] }]}>
				<CustomerImportWidget
					position={{
						row: 10,
						col: 10,
						sizeX: 5,
						sizeY: 5,
					}}
					widgetTitle={TranslateText('dataImport.btnImport')}
					showEditButton={ClaimUtil.validateClaimList(user, [
						{
							claim: ClaimType.Import,
							values: [ClaimValue.edit],
						},
						{
							claim: ClaimType.Customers,
							values: [ClaimValue.edit],
						},
					])}
				/>
			</ProtectedContainer>
			{customerLevel === CustomerLevelEnum.Default ? (
				<ProtectedContainer feature={EasyTrackFeature.Locations}>
					<ButtonWidget
						hidden={!customerDetail?.featuresSettings?.locations}
						position={{
							row: 15,
							col: 0,
							sizeX: 5,
							sizeY: 5,
						}}
						allowEditMode={true}
						entityId={props.match.params.id}
						widgetTitle={TranslateText('category.locationCategories')}
						viewComponent={CustomerLocationCategoriesView}
						buttons={[AddLocationCategoryButton]}
						showEditButton={showEditButton}
					/>
				</ProtectedContainer>
			) : (
				<></>
			)}
			{customerLevel === CustomerLevelEnum.OEM || customerLevel === CustomerLevelEnum.Reseller ? (
				<Widget
					position={{
						row: 10,
						col: 15,
						sizeX: 5,
						sizeY: 5,
					}}
					url={`${GlobalSettings.customersMaintenanceApi}/messagingSettings`}
					widgetTitle={TranslateText('customerOverview.messageServiceSettings')}
					viewComponent={MessageServiceSettingsView}
					showEditButton={showEditButton}
				/>
			) : (
				<></>
			)}
		</WidgetDashboard>
	) : (
		<></>
	);
};

export default CustomerDetails;
