import './newAddWizard.scss';

import { NumberSize, Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import { getRequestFilters } from 'utils/GridOverviewUtils';

import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { ClaimConfig, ClaimUtil } from '../../authorization/ClaimUtil';
import { DEBOUNCE_TIME } from '../../Constants';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import { DebouncedButton } from '../Common/DebouncedButton';

export type AddWizardComponent = {
	title: string;
	view: (props: any) => JSX.Element;
	isRequired: boolean;
	props?: { [key: string]: any }[];
	disableScroll?: boolean;
	claims?: ClaimConfig[];
	enabled?: boolean;
};

export interface AddWizardComponentProps {
	wizardEntityType?: EntityTypeEnum;
	setValidationCallback: (isValid: boolean) => void;
	onChangeCallback: (data: any) => void;
	visible: boolean;
	setUrlCallback?: (url: string) => void;
	getDataCallback?: () => any;
	setDataCallback?: (data: any) => void;
	refComponent?: any;
}

interface IValidation {
	title: string;
	value: boolean;
	allowValue?: boolean | null;
}

interface Props {
	toggleAddWizardCallback: (show: boolean) => void;
	wizardEntityType: EntityTypeEnum;
	getAddWizardComponents: (entityType: EntityTypeEnum, formData: any) => AddWizardComponent[];
	postUrl: string;
	entityAddedCallback: (newEntity: object) => void;
	resizableDefaultHeight?: number;
}

const NewAddWizard = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const [formData, setFormData] = useState<any>({});
	const [components, setComponents] = useState<AddWizardComponent[]>(
		props
			.getAddWizardComponents(props.wizardEntityType, formData)
			.filter(
				(component) => !!component && (!component.claims || ClaimUtil.validateClaimList(user, component.claims))
			)
	);

	const [resizableHeight, setResizableHeight] = useState<number>(props.resizableDefaultHeight ?? 411);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [unsavedData, setUnsavedData] = useState(false);
	const [notifyAboutUnsavedData, setNotifyAboutUnsavedData] = useState(false);

	//used for triggering rerender
	const [validationTimestamp, setValidationTimestamp] = useState(0);
	const validations = useRef<IValidation[]>([]);

	const [url, setUrl] = useState<string>(props.postUrl);
	const [genericData, setGenericData] = useState<any>({});
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(unsavedDataStoreActionCreators.setUnsavedData(unsavedData));
	}, [unsavedData]);

	const containerRef = useRef(null);

	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const gridFilters = useSelector((s: ApplicationState) => s.gridOverview[props.wizardEntityType].gridFilters);

	useEffect(() => {
		if (props.getAddWizardComponents) {
			const newComponents = props
				.getAddWizardComponents(props.wizardEntityType, formData)
				.filter(
					(component) =>
						!!component && (!component.claims || ClaimUtil.validateClaimList(user, component.claims))
				);

			const newValidations: IValidation[] = [];
			newComponents.map((component) => {
				let validation = validations.current.find((x) => x.title === component.title);
				if (!validation) {
					validation = {
						title: component.title,
						value: !component.isRequired,
						allowValue: null,
					};
				}
				newValidations.push(validation);
			});

			validations.current = newValidations;
			setValidationTimestamp(new Date().getTime());
			setComponents(newComponents);
		}
	}, [formData]);

	const tryNavigate = (step: number) => {
		let canNavigate = true;
		for (let i = 0; i < components.length; i++) {
			if (i >= step) {
				break;
			}
			if (!validations.current[i]?.value || !validations.current[i]?.allowValue) {
				canNavigate = false;
				break;
			}
		}
		if (canNavigate) {
			setCurrentStep(step);
		}
	};
	const tryCloseAddWizard = () => {
		if (unsavedData === true) {
			setNotifyAboutUnsavedData(true);
		} else {
			props.toggleAddWizardCallback(false);
		}
	};
	const handleUnsavedDataResponse = (response: boolean) => {
		if (response) {
			dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
			setUnsavedData(false);
			setNotifyAboutUnsavedData(false);
			props.toggleAddWizardCallback(false);
		}
		setNotifyAboutUnsavedData(false);
	};

	const validationCallback = (isValid: boolean, componentIndex: number, title: string) => {
		const index = validations.current.findIndex((x) => x.title === title);
		if (index >= 0) {
			validations.current[index].value = isValid;
			validations.current[index].allowValue = isValid;
			setValidationTimestamp(new Date().getTime());
		}
	};

	const saveComponentDataCallback = (data: any) => {
		const newFormData = { ...formData, ...data };
		setFormData(newFormData);
		setUnsavedData(true);
	};
	const saveFormCallback = async () => {
		const data = await ajaxUtil.post<object>(url, {
			AddModel: {
				...formData,
			},
			GlobalCustomer: customerId,
			FilterText: '',
			...getRequestFilters(gridFilters),
		});

		dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
		setUnsavedData(false);
		props.entityAddedCallback(data);
	};

	const disableSaveButton = () => {
		let validation = true;
		for (let i = 0; i <= currentStep; i++) {
			if (!validations.current[i]?.value) {
				validation = false;
			}
		}
		if (validation) {
			for (let i = currentStep + 1; i < components.length; i++) {
				if (components[i].isRequired) {
					validation = false;
				}
			}
		}
		return !validations.current.every((v) => v.value) && !validation;
	};

	const getNextEnabledStep = (currentStep: number) => {
		let nextStep = currentStep + 1;
		while (nextStep < components.length && components[nextStep].enabled === false) {
			nextStep++;
		}
		return nextStep < components.length ? nextStep : null;
	};

	return (
		<>
			<Resizable
				size={{
					width: '100%',
					height: resizableHeight,
				}}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				minHeight={200}
				maxHeight={600}
				className={'wizard-resizable'}
				onResizeStop={(event: Event, direction: Direction, ref: HTMLElement, dimension: NumberSize) => {
					setResizableHeight(resizableHeight + dimension.height);
				}}
			>
				<div className={'wizard-container'}>
					<div className={'wizard-header'}>
						<div className={'wizard-title'}>
							{TranslateText('maintenanceOverview.searchBar.add' + props.wizardEntityType)}
						</div>
						<div className={'header-end-content'}>
							{components.length > 1 && (
								<div className={'wizard-steps'}>
									{components.map((component, index) => {
										return (
											<div
												key={component.title}
												className={`breadcrumb-step ${
													index === currentStep ? 'selected' : ''
												} ${index < currentStep ? 'completed' : ''}
												${component.enabled === false ? 'disabled' : ''}`}
												onClick={() => {
													if (component.enabled !== false) tryNavigate(index);
												}}
											>
												{index + 1 + '. ' + component.title}
											</div>
										);
									})}
								</div>
							)}
							<Button className={'wizard-close-button'} onClick={tryCloseAddWizard} size={'small'}>
								<Close />
							</Button>
						</div>
					</div>
					<div className={'separator'} />
					<div className={'view-container'} ref={containerRef}>
						{components.map((component, index) => (
							<div
								className={`component-container ${component.disableScroll ? 'no-scroll' : ''}`}
								key={component.title}
								hidden={currentStep !== index || component.enabled === false}
							>
								<component.view
									wizardEntityType={props.wizardEntityType}
									onChangeCallback={saveComponentDataCallback}
									setValidationCallback={(isValid: boolean) =>
										validationCallback(
											isValid || component.enabled === false,
											index,
											component.title
										)
									}
									setUrlCallback={(url: string) => setUrl(url)}
									setDataCallback={(generic: any) => setGenericData({ ...genericData, ...generic })}
									getDataCallback={() => genericData}
									refComponent={containerRef}
									visible={currentStep === index}
									{...component.props}
									{...formData}
								/>
							</div>
						))}
					</div>
					<div className={'wizard-footer'}>
						<div className={'left-container'}>
							<Button className={'button cancel-button'} onClick={tryCloseAddWizard}>
								{TranslateText('common.buttonCancel')}
							</Button>
						</div>
						<div className={'right-container'}>
							{currentStep < components.filter((c) => c.enabled !== false).length - 1 ? (
								<DebouncedButton
									style={{ marginRight: 35 }}
									className={'button save-button'}
									disabled={disableSaveButton()}
									onClick={saveFormCallback}
									debounceTime={DEBOUNCE_TIME}
								>
									{TranslateText('common.buttonSave')}
								</DebouncedButton>
							) : null}
							{currentStep < components.filter((c) => c.enabled !== false).length - 1 ? (
								<Button
									className={'button next-button'}
									disabled={!validations.current[currentStep]?.value}
									onClick={() => {
										const nextStep = getNextEnabledStep(currentStep);
										if (nextStep !== null) {
											tryNavigate(nextStep);
										}
									}}
								>
									{`${TranslateText('common.buttonNext')} (${
										getNextEnabledStep(currentStep) !== null
											? components[getNextEnabledStep(currentStep)].title
											: ''
									})`}
								</Button>
							) : (
								<DebouncedButton
									className={'button save-button'}
									disabled={disableSaveButton()}
									onClick={saveFormCallback}
									debounceTime={DEBOUNCE_TIME}
								>
									{TranslateText('common.buttonSave')}
								</DebouncedButton>
							)}
						</div>
					</div>
				</div>
			</Resizable>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.cancel')}
				handleUserResponse={handleUnsavedDataResponse}
				displayDialog={notifyAboutUnsavedData}
			/>
		</>
	);
};

export default NewAddWizard;
