import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { LocationAreaTypeEnum } from 'components/LocationsOverview/LocationsOverview';
import Location from 'models/Location';
import { useEffect, useRef, useState } from 'react';

import { PolygonDefinition } from './types';

export const getDefaultPolygonPath = (
	latitude: number,
	longitude: number,
	initialPolygon?: PolygonDefinition
): google.maps.LatLng[] => {
	//same center, return initial path
	if (
		initialPolygon &&
		initialPolygon.center?.lat === latitude &&
		initialPolygon.center?.lng === longitude &&
		initialPolygon.path
	) {
		return initialPolygon.path.map((x) => new google.maps.LatLng(x.lat, x.lng));
	} else if (initialPolygon && initialPolygon.center && initialPolygon.path) {
		//different center, return initial path adjusted to new center
		const latDiff = latitude - initialPolygon.center.lat;
		const lngDiff = longitude - initialPolygon.center.lng;
		return initialPolygon.path.map((x) => new google.maps.LatLng(x.lat + latDiff, x.lng + lngDiff));
	} else {
		//return default triangle
		const center = new google.maps.LatLng(latitude, longitude);
		const p1 = google.maps.geometry.spherical.computeOffset(center, 50, 0);
		const p2 = google.maps.geometry.spherical.computeOffset(center, 50, 120);
		const p3 = google.maps.geometry.spherical.computeOffset(center, 50, -120);
		return [p1, p2, p3];
	}
};

export const getMaxDistanceFromPoint = (centerLat: number, centerlng: number, points: google.maps.LatLng[]): number => {
	let maxDistance = 0;
	const center = new google.maps.LatLng(centerLat, centerlng);
	points.forEach((point) => {
		const distance = google.maps.geometry.spherical.computeDistanceBetween(center, point);
		if (distance > maxDistance) {
			maxDistance = distance;
		}
	});

	return Math.ceil(maxDistance);
};

const getIconPath = (iconContent: string | undefined) => {
	let newPath: string | undefined = undefined;
	if (iconContent) {
		const parser = new DOMParser();
		const wrapper = parser.parseFromString(iconContent, 'image/svg+xml');
		newPath = wrapper?.querySelector('path')?.getAttribute('d');
	}
	return newPath;
};

export const usePolygon = (
	isLoaded: boolean,
	location: Location,
	onChangeCallback: (location: Location) => void,
	initialPolygon?: PolygonDefinition
) => {
	const showRemovePopup = useRef(false);
	const [removePoint, setRemovePoint] = useState<{ lat: number; lng: number; vertex: number } | null>(null);
	const [polygonPath, setPolygonPath] = useState<{
		polygon: google.maps.MVCArray<google.maps.LatLng>;
		hasChanges?: boolean;
	} | null>(null);
	const oldCenter = useRef<{ latitude: number; longitude: number } | null>(null);

	useEffect(() => {
		setRemovePoint(null);
	}, [polygonPath]);

	//should not run first time(polygonInitialized), only when location latitude/longitude is changed
	useEffect(() => {
		if (
			location.areaType === LocationAreaTypeEnum.Polygon &&
			polygonInitialized.current &&
			location.latitude &&
			location.longitude
		) {
			if (
				oldCenter.current &&
				polygonPath?.polygon &&
				(location.latitude !== oldCenter.current.latitude || location.longitude !== oldCenter.current.longitude)
			) {
				//the center is changed
				const newLocation = { ...location };

				const latDiff = newLocation.latitude - oldCenter.current.latitude;
				const lngDiff = newLocation.longitude - oldCenter.current.longitude;
				const oldPolygonPoints = polygonPath.polygon.getArray().map((x) => {
					return {
						lat: x.lat() + latDiff,
						lng: x.lng() + lngDiff,
					};
				});

				const latLngArray: google.maps.LatLng[] = [];
				newLocation.polygonPath = [];
				oldPolygonPoints.forEach((pathPoint) => {
					newLocation.polygonPath.push(pathPoint);
					latLngArray.push(new google.maps.LatLng(pathPoint.lat, pathPoint.lng));
				});

				newLocation.radius = getMaxDistanceFromPoint(newLocation.latitude, newLocation.longitude, latLngArray);

				initPolygonPath(latLngArray, polygonPath.hasChanges);
				onChangeCallback(newLocation);
			} else if (!oldCenter.current) {
				//add new polygon, the first time the center is set
				resetPolygon();
			}
		}

		if (location.latitude && location.longitude) {
			oldCenter.current = { latitude: location.latitude, longitude: location.longitude };
		}
	}, [location.latitude, location.longitude]);

	//run only first time (polygonInitialized)
	const polygonInitialized = useRef(false);
	useEffect(() => {
		if (!polygonInitialized.current && isLoaded) {
			polygonInitialized.current = true;

			if (location.areaType === LocationAreaTypeEnum.Polygon && location.polygonPath) {
				initPolygonPath(location.polygonPath.map((x) => new google.maps.LatLng(x.lat, x.lng)));
			}
		}
	}, [isLoaded, location.polygonPath]);

	//use ref to use the function inside poligon array change events
	const updatePolygonPathState = (pathPoints: google.maps.LatLng[]) => {
		const newLocation = { ...location };
		newLocation.polygonPath = [];
		pathPoints.forEach((pathPoint) => {
			newLocation.polygonPath.push({ lat: pathPoint.lat(), lng: pathPoint.lng() });
		});
		newLocation.radius = getMaxDistanceFromPoint(newLocation.latitude, newLocation.longitude, pathPoints);
		onChangeCallback(newLocation);
		setPolygonPath({ ...polygonPath, hasChanges: true });
	};
	const updatePolygonPathStateRef = useRef<(array: google.maps.LatLng[]) => void>();
	updatePolygonPathStateRef.current = updatePolygonPathState;

	//init polygon
	const initPolygonPath = (points: google.maps.LatLng[], hasChanges?: boolean) => {
		const polygon = new google.maps.MVCArray<google.maps.LatLng>();
		points.forEach((point) => {
			polygon.push(point);
		});

		//add events(not to trigger them) after the points are added
		polygon.addListener('insert_at', () => {
			showRemovePopup.current = false;
			setRemovePoint(null);
			updatePolygonPathStateRef.current && updatePolygonPathStateRef.current(polygon.getArray());
		});
		polygon.addListener('remove_at', () => {
			showRemovePopup.current = false;
			setRemovePoint(null);
			updatePolygonPathStateRef.current && updatePolygonPathStateRef.current(polygon.getArray());
		});
		polygon.addListener('set_at', () => {
			showRemovePopup.current = false;
			setRemovePoint(null);
			updatePolygonPathStateRef.current && updatePolygonPathStateRef.current(polygon.getArray());
		});
		setPolygonPath({ polygon: polygon, hasChanges: hasChanges });
	};

	//reset polygon
	const resetPolygon = () => {
		if (location.latitude && location.longitude) {
			const newLocation = { ...location };
			newLocation.polygonPath = [];

			const pathPoints = getDefaultPolygonPath(location.latitude, location.longitude, initialPolygon);
			pathPoints.forEach((pathPoint) => {
				newLocation.polygonPath.push({ lat: pathPoint.lat(), lng: pathPoint.lng() });
			});

			newLocation.radius = getMaxDistanceFromPoint(newLocation.latitude, newLocation.longitude, pathPoints);

			initPolygonPath(pathPoints);
			onChangeCallback(newLocation);
		}
	};

	return { polygonPath, initPolygonPath, resetPolygon, showRemovePopup, removePoint, setRemovePoint };
};

export const useGetIconPath = (location: Location) => {
	const { icon } = useTryGetSvgIconFromQueryCache(location.iconName, 'locations/pin');
	const [iconPath, setIconPath] = useState<string | undefined>(() => getIconPath(icon?.content));
	useEffect(() => {
		setIconPath(getIconPath(icon?.content));
	}, [icon]);

	return iconPath;
};
