import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import AddInGroupsSelector from 'components/Common/AddInGroupsSelector/AddInGroupsSelector';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import { Person } from 'models/Person';
import { User } from 'oidc-client';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay/CustomerNameDisplay';
import { ValidationResult } from 'shared/validation/interfaces';
import { ApplicationState, CustomerEntityDefaultsState } from 'store';
import { TranslateText } from 'utils/Translations';

type Props = {
	user: User;
	readOnly: boolean;
	defaultCustomerId: string;
	entityDefaultsSettings: CustomerEntityDefaultsState;
	handleValueChange: (changedPerson: Person, effectiveChange?: boolean) => void;
	person: Person;
	validationResult: ValidationResult;
	editableObject: boolean;
	objectsData?: AutocompleteItem[];
	showAddToGroupsFied: boolean;
	disabledDriver?: boolean;
	disabledActive?: boolean;
	isLoading: boolean;
	renderForInformationWidget?: boolean;
};

const PersonSystemInformation = (props: Props) => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const currentLocation = useLocation();
	const customerLevel = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel
	);
	const [isExternalCodeFirstTouched, setIsExternalCodeFirstTouched] = useState(false);

	const getCustomerId = () => {
		return !!props?.defaultCustomerId?.length ? props.defaultCustomerId : props.person.customerId;
	};

	return (
		<form id="personViewSystemInformationForm" noValidate={true}>
			<div className="view-section-wrapper">
				<div className="form-group">
					<TextField
						id="person-code"
						type="text"
						className="resize-font"
						fullWidth
						label={TranslateText('fields.code')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="code"
						value={props.person.code}
						onChange={(args) => {
							props.handleValueChange({ ...props.person, code: args.target.value });
						}}
						onBlur={(args) => {
							if (!props.renderForInformationWidget) {
								props.handleValueChange(
									props.entityDefaultsSettings.externalCodeSameCode && !isExternalCodeFirstTouched
										? {
												...props.person,
												code: args.target.value,
												externalCode: args.target.value,
										  }
										: { ...props.person, code: args.target.value }
								);
							}
						}}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.code} />
				</div>
				<div className="form-group">
					<TextField
						id="externalCode"
						type="text"
						className="resize-font"
						fullWidth
						label={TranslateText('fields.externalCode')}
						inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
						name="externalCode"
						value={props.person.externalCode ?? ''}
						onChange={(args) => {
							props.handleValueChange({ ...props.person, externalCode: args.target.value });
						}}
						onBlur={(args) =>
							args.target.value
								? setIsExternalCodeFirstTouched(true)
								: setIsExternalCodeFirstTouched(false)
						}
						onFocus={() => setIsExternalCodeFirstTouched(true)}
						disabled={props.readOnly}
					/>
					<ValidationMessage result={props.validationResult?.externalCode} />
				</div>
				<CustomerNameDisplay customerId={getCustomerId()} />
				{driverIdentification && (
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="driver"
									color={'primary'}
									checked={props.person.driver ?? false}
									readOnly={props.readOnly}
									onChange={(args: any) =>
										props.handleValueChange({
											...props.person,
											driver: args.target.checked,
											objectId: null,
										})
									}
									disabled={
										(props.person.driver && currentLocation.pathname.startsWith('/drivers')) ||
										props.readOnly ||
										props.disabledDriver ||
										customerLevel !== CustomerLevelEnum.Default ||
										props.person.hasObjectActiveConnections ||
										props.person.hasKeyActiveConnections ||
										props.person.hasGroupActiveConnections
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.driver')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				)}
				<div
					className="form-group"
					hidden={
						!ClaimUtil.validateClaimList(props.user, [
							{ claim: ClaimType.Objects, values: [ClaimValue.view] },
							{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
						])
					}
				>
					{props.editableObject ? (
						<>
							<MaterialAutocomplete
								valueId={props.person.objectId}
								dataSource={props.objectsData}
								name="objectId"
								disabled={
									props.readOnly ||
									!props.person.driver ||
									props.person.customerType !== CustomerLevelEnum.Default ||
									!ClaimUtil.validateClaimList(props.user, [
										{
											claim: ClaimType.Objects,
											values: [ClaimValue.edit, ClaimValue.editRestricted],
										},
										{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
									]) ||
									props.isLoading
								}
								label={TranslateText('fields.connectedObject')}
								onChange={({ value }) => {
									props.handleValueChange({
										...props.person,
										objectId: value as string,
									});
								}}
							/>
							<ValidationMessage result={props.validationResult?.objectId} />
						</>
					) : (
						<TextField
							id="objectId"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.connectedObject')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="objectId"
							value={props.person.objectName ?? ''}
							disabled={true}
							size="small"
						/>
					)}
				</div>
				{props.showAddToGroupsFied ? (
					<div className="form-group">
						<AddInGroupsSelector
							name="addInGroups"
							selectedGroups={props.person.addInGroups}
							onChange={(groups) => {
								props.handleValueChange({ ...props.person, addInGroups: groups });
							}}
							disabled={!props.person.driver}
							defaultCustomerId={getCustomerId()}
						/>
						{props.person.driver ? (
							<ValidationMessage result={props.validationResult?.addInGroups} />
						) : null}
					</div>
				) : null}
				<div className="form-group">
					<FormControlLabel
						control={
							<Checkbox
								name="activatedScreensaver"
								color={'primary'}
								checked={props.person.activatedScreensaver}
								readOnly={props.readOnly}
								onChange={(args: any) =>
									props.handleValueChange({
										...props.person,
										activatedScreensaver: args.target.checked,
									})
								}
								disabled={props.readOnly}
							/>
						}
						label={
							<Typography style={{ fontSize: 12, marginRight: 5 }}>
								{TranslateText('fields.activatedScreensaver')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0 }}
					/>
				</div>
				<div className="form-group">
					<FormControlLabel
						control={
							<Checkbox
								name="active"
								color={'primary'}
								checked={props.person.active}
								readOnly={props.readOnly}
								onChange={(args: any) =>
									props.handleValueChange({ ...props.person, active: args.target.checked })
								}
								disabled={props.readOnly || props.disabledActive}
							/>
						}
						label={
							<Typography style={{ fontSize: 12, marginRight: 5 }}>
								{TranslateText('fields.active')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0 }}
					/>
				</div>
			</div>
		</form>
	);
};
PersonSystemInformation.defaultProps = {
	showAddToGroupsFied: false,
};
export default PersonSystemInformation;
