import './informationNotificationView.scss'

import { useSelector } from "react-redux";
import {ChangeEvent, useEffect, useState } from "react";
import React from "react";

import {AddWizardComponentProps} from "../../NewAddWizard";
import {ApplicationState} from "../../../../store";
import {FieldRule, FieldRules, ValidationResult} from "../../../../shared/validation/interfaces";
import Validator from "../../../../shared/validation/Validator";
import {ValidatorFunctions} from "../../../../shared/validation/ValidatorFunctions";
import {TranslateText, TranslateTextInterpolated} from "../../../../utils/Translations";
import InformationNotification from "../../../../models/InformationNotification";
import MaterialTextField from "../../../../shared/components/MaterialTextField/MaterialTextField";
import {Button, Checkbox, FormControlLabel, IconButton, Typography } from "@material-ui/core";
import MaterialAutocomplete from "../../../Common/Autocomplete/MaterialAutocomplete";
import DateTimeUtil, {DateTimeConversionUtil} from "../../../../shared/datetime/DateTimeUtil";
import MaterialDatePicker from "../../../Common/DateTime/MaterialDatePicker";
import GlobalSettings from "../../../../GlobalSettings.json";
import ajaxUtil from "../../../../utils/Ajax";
import {Add, ArrowDownward, ArrowUpward, Clear, Warning } from '@material-ui/icons';
import LoginReasonNotification from "../../../../models/LoginReasonNotification";
import {LOGIN_REASON} from "../../../../Constants";
import CustomerNameDisplay from "../../../../shared/components/CustomerNameDisplay";


export interface InformationNotificationTypesDto {
	id: string;
	name: string;
}
export interface LanguageDto{
	id: string;
	code: string;
	active: boolean;
	name?: string;
}

const InformationNotificationView = (props: AddWizardComponentProps) => {

	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);

	const personId = useSelector((s: ApplicationState) => s.currentSession.personId);

	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [informationNotificationTypes, setInformationNotificationTypes] = useState([]);
	const [informationNotification, setInformationNotification] = useState<InformationNotification>(
		new InformationNotification(defaultCustomerId, personId));
	const [languages, setLanguages] = useState<LanguageDto[]>([]);
	const [languageSelected, setLanguageSelected] = useState<string>('');
	const [scrollY,setScrollY] = useState(70);
	const [startDateTimeDefault, setStartDateTimeDefault] = useState<Date>(informationNotification.startDateTime);
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	const validator: Validator = new Validator({
		fieldRules: {
			name: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(200),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
				}
			},
			description: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(200),
				}
			},
			type: {
				rules: {
					required: ValidatorFunctions.required(),
				}
			},
			startDateTime: {
				rules: {
					graterThanEndDate: {
						message: TranslateText('fieldsValidations.startDateGreaterThanEndDate'),
						validationFunction: (data) => {
							const newNotification = data as InformationNotification;
							const startDateTime = newNotification.startDateTime;
							const endDateTime = newNotification.endDateTime;
							startDateTime.setSeconds(0,0);
							if(endDateTime !== null)
								endDateTime.setSeconds(0,0);
							return ValidatorFunctions.wrapInPromise(
								endDateTime === null ||
								startDateTime <= endDateTime
							);
						},
					}
				},
			},
			endDateTime: {
				rules: {
					validDate: {
						message: TranslateText('fieldsValidations.endDateLowerOrEqualThanStartDate'),
						validationFunction: (data) => {
							const newNotification = data as InformationNotification;
							const startDateTime = newNotification.startDateTime;
							const endDateTime = newNotification.endDateTime;
							startDateTime.setSeconds(0,0);
							if(endDateTime !== null)
								endDateTime.setSeconds(0,0);
							return ValidatorFunctions.wrapInPromise(
								newNotification.endDateTime == null ||
								endDateTime > startDateTime
							);
						},
					},
				},
			},
		}
	});
	const getRules = () => {
		let rules = {} as FieldRules;
		let size = informationNotification.loginReasons[languageSelected]?.length;
		for(let i = 0; i < languages.length; i++)
		{
			for(let j = 0; j < size; j++)
			{
				rules[`name${languages[i]?.id}${j}`] = {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data: any) => {
								const notificationData = data as InformationNotification;
								return ValidatorFunctions.wrapInPromise(
									notificationData.type !== LOGIN_REASON
									|| !languages[i]?.active
									|| notificationData.loginReasons[languages[i]?.id][j]?.name !== ''
									|| notificationData.loginReasons[languages[i]?.id][j]?.generic
								);
							},
						},
						maxLengthConditionally: {
							message: TranslateTextInterpolated('fieldsValidations.maxLength',['200']),
							validationFunction: (data: any) => {
								const notificationData = data as InformationNotification;
								return ValidatorFunctions.wrapInPromise(
									notificationData.type !== LOGIN_REASON
									|| !languages[i]?.active
									|| notificationData.loginReasons[languages[i]?.id][j]?.name.length <= 200
									|| notificationData.loginReasons[languages[i]?.id][j]?.generic
								);
							},
						}
					},
				} as FieldRule;
			}
		}
		return rules;
	}
	validator.addRules(getRules());
	const getTypes = () => {
		let array: { display: string; id: string; }[] = [];
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/informationNotificationTypesForAdd/${defaultCustomerId}`;
		ajaxUtil.get<InformationNotificationTypesDto[]>(url).then((result) => {
			const types = [...result] as InformationNotificationTypesDto[];
			for(let i=0; i < types.length; i++)
				array.push({
					display: TranslateText(`notificationTypes.${types[i]?.name}`),
					id: types[i]?.id,
				});
			setInformationNotificationTypes(array);
		});
	}

	const getLanguages = () => {
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/getLanguages`;
		ajaxUtil.get<LanguageDto[]>(url).then((result) => {
			setLanguageSelected(result.find(x => x.code.toLowerCase() === loginInLanguage.toLowerCase())?.id);
			setLanguages(result);
		});
	}

	const getGenericsLoginReasons = () => {
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/getGenericsLoginReasons`;
		ajaxUtil.get<Record<string, LoginReasonNotification[]>>(url).then((result) => {
			setInformationNotification({...informationNotification, loginReasons: {...result}});
		});
	}

	useEffect(() => {
		validator.validate(informationNotification).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
		});
		props.setUrlCallback(getUrlAdd());
		getGenericsLoginReasons();
		getLanguages();
		getTypes();
	}, []);


	useEffect(() => {
		props.setUrlCallback(getUrlAdd());
	},[informationNotification.type]);

	useEffect(() => {
		validator.validate(informationNotification).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(informationNotification);
			}
		});
	}, [informationNotification]);

	const handleValueChange = (value: unknown, statePropName: keyof InformationNotification) => {
		const newInformationNotification = { ...informationNotification };

		if(statePropName === 'startDateTime') {
			setStartDateTimeDefault(value as Date);
			newInformationNotification[statePropName] = value as Date;
		}
		else if (statePropName === 'endDateTime' && value instanceof Date) {
			let endDate = value as Date;
			endDate.setSeconds(0,0);

			if (startDateTimeDefault !== null) {
				let startDate = startDateTimeDefault;
				startDate.setSeconds(0,0);
				if (+startDate === +endDate) {
					endDate.setMinutes(endDate.getMinutes() + 1);
				}
			}
			newInformationNotification[statePropName] = endDate;
			newInformationNotification.startDateTime = new Date(startDateTimeDefault);
		}
		else if(statePropName === "type"){
			if(value === LOGIN_REASON) {
				newInformationNotification.urgent = true;
				newInformationNotification.driver = true;
			}
			newInformationNotification[statePropName] = value as string;
		}
		else {
			newInformationNotification[statePropName] = value;
		}

		setInformationNotification(newInformationNotification);
		if(typeof value === "string" && value === '') {
			setUnsavedData(unsavedData);
		} else {
			setUnsavedData(true);
		}
	}


	const clickLanguage = (id: string) => {
		setLanguageSelected(id);
	}

	const handleValueChangeLoginReason = (value: boolean | string, index: number, statePropName: keyof LoginReasonNotification) => {
		let map = {...informationNotification.loginReasons};
		if(statePropName === 'name')
		{
			Object.keys(map).forEach(languageId => {
				let notifications: LoginReasonNotification[] = map[languageId];
				if(languageId === languageSelected)
				{
					notifications[index][statePropName] = value as string;
					notifications[index].translated = true;
				}
				else
				{
					if(notifications[index][statePropName] === undefined || notifications[index][statePropName] ==='')
						notifications[index][statePropName] = value as string;
				}
			});
		}
		else
		{
			Object.keys(map).forEach(languageId => {
				let notifications: LoginReasonNotification[] = map[languageId];
				notifications[index][statePropName] = value;
			});
		}
		setInformationNotification({...informationNotification, loginReasons: map})
	}

	const handleValueChangeButtonsLoginReason = (index: number, name: string) => {
		let map = {...informationNotification.loginReasons};
		if(name === 'clear')
		{
			Object.keys(map).forEach(languageId => {
				let array: LoginReasonNotification[] = map[languageId];
				array = array.filter((x, i) => i !== index);
				map[languageId] = array;
			});
			if(map[languageSelected]?.length < 13)
				setScrollY(scrollY + 3);
		}
		else if (name === 'arrowUp')
		{
			if (index > 0) {
				Object.keys(map).forEach(languageId => {
					let array: LoginReasonNotification[] = map[languageId];
					let element = array.splice(index, 1)[0];
					array.splice(index - 1, 0, element);
					map[languageId] = array;
				});
			}
		}
		else
		{
				Object.keys(map).forEach(languageId => {
					let array: LoginReasonNotification[] = map[languageId];
					if (index < map[languageId].length - 1) {
						let element = array.splice(index, 1)[0];
						array.splice(index + 1, 0, element);
						map[languageId] = array;
					}
				});
		}
		setInformationNotification({...informationNotification, loginReasons: map})
	}
	const addNewLoginNotification = () => {
		let  map = {...informationNotification.loginReasons};
		let newNotification = new LoginReasonNotification('', true, false);
		Object.keys(map).forEach(languageId => {
			 map[languageId].push({...newNotification, translated:languageSelected === languageId});
		});
		setInformationNotification({...informationNotification, loginReasons: map})
		if(map[languageSelected]?.length < 13) {
			props.refComponent.current.scrollBy(0, scrollY);
			setScrollY(scrollY - 3);
		}
	}
	const getUrlAdd = () : string  => {
		let addUrl = `${GlobalSettings.informationNotificationsMaintenanceApi}/`;

		switch (informationNotification.type) {
			case  LOGIN_REASON:
				addUrl += 'AddLoginReasonNotification';
				break;
			default:
				break;
		}
		return addUrl;
	}

	const loginReason = () => {
		return(
			<div className={'login information-notification-view-container'}>
				<div className={'login-row disable-scroll'}
					 style={informationNotification.loginReasons[languageSelected]?.length > 10
						 ? {overflowY: 'scroll', height: '460px', width:'100%'}
						 : {width:'100%'}}
					>
					<table>
						{languageSelected !=='' && informationNotification?.loginReasons[languageSelected.toLowerCase()]?.map((loginReason, index) => (
							<tr key={index} className={'table-row'}>
								<td className={'login-column'} style={{minWidth: 30, paddingRight: 0}}>
									{loginReason.translated ? null : <Warning fontSize={'small'}/>}
								</td>
								<td className={'login-column'} style={{paddingLeft: 0}}>
									<Checkbox
										name="active"
										checked={loginReason.active}
										readOnly={false}
										onChange={(args: ChangeEvent<HTMLInputElement>) =>
											handleValueChangeLoginReason(args.target.checked, index, 'active')
										}
										disabled={false}
									/>
								</td>
								<td className={'login-column'}
									style={index === (informationNotification?.loginReasons[languageSelected]?.length - 1)
										? {paddingRight:'15px', width: 250, paddingBottom:'15px'}
										: {paddingRight:'15px', width: 250}}>
									{loginReason.generic
										? <Typography style={loginReason.translated ? {fontSize: 10} : {fontSize: 10, fontStyle: 'italic'}}>
												{loginReason.name}
									      </Typography>
										: <MaterialTextField
											autoFocus={ index === (informationNotification?.loginReasons[languageSelected]?.length - 1)}
											isForNewAddWizard={true}
											id="loginName"
											className="resize-font"
											label={null}
											inputProps={{ style: loginReason.translated ? {fontSize: 10} : {fontSize: 10, fontStyle: 'italic'} }}
											name="description"
											value={loginReason.name?.trimStart()}
											handleValueChange={(value) => handleValueChangeLoginReason(value, index, 'name')}
											disabled={loginReason.generic}
											multiline={true}
											validationResult={validationResult?.[`name${languageSelected}${index}`]}
										/>
									}
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="clear-btn"
										disabled={loginReason.generic}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'clear')
										}}
									>
										<Clear fontSize={'small'} />
									</IconButton>
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="arrow-up-btn"
										hidden={false}
										disabled={index === 0}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'arrowUp')
										}}
									>
										<ArrowUpward fontSize={'small'} />
									</IconButton>
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="arrow-down-btn"
										hidden={false}
										disabled={index === informationNotification?.loginReasons[languageSelected].length - 1}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'arrowDown')
										}}
									>
										<ArrowDownward fontSize={'small'} />
									</IconButton>
								</td>
							</tr>
						))}
					</table>
				</div>
				<div className={'login-row'} style={{width:'100%'}}>
					<Button
						className={'btn-add'}
						variant="outlined"
						startIcon={<Add />}
						onClick={addNewLoginNotification}
					>
						{TranslateText('fields.addReason')}
					</Button>
				</div>
			</div>
		);
	};

	return(
		<div className={'information-notification-view-container'}>
			<div className={'information-notification-column'} style={{width: "33.33%"}}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.systemInformation')}</div>
					<form id="informationNotificationViewSystemInformationForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialTextField
									autoFocus={true}
									isForNewAddWizard={true}
									id="informationNotificationName"
									className="resize-font"
									label={TranslateText('fields.name')}
									inputProps={{ style: { fontSize: 10 } }}
									name="name"
									value={informationNotification.name?.trimStart()}
									handleValueChange={(value) => handleValueChange(value, 'name')}
									disabled={false}
									validationResult={validationResult?.name}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="description"
									className="resize-font"
									label={TranslateText('fields.description')}
									inputProps={{ style: { fontSize: 10 } }}
									name="description"
									value={informationNotification.description?.trimStart()}
									handleValueChange={(value) => handleValueChange(value, 'description')}
									disabled={false}
									multiline={true}
									validationResult={validationResult?.description}
								/>
							</div>
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									valueId={informationNotification?.type}
									disabled={false}
									dataSource={informationNotificationTypes}
									name="typeOfNotification"
									label={TranslateText('fields.informationNotification.typeOfNotification')}
									onChange={({ value }) => handleValueChange(value as string, 'type')}
									validationResult={validationResult?.type}
								/>
							</div>
							<div className="form-group">
								<table>
									<tr>
										<td style={{paddingBottom:"16px"}}>
											<Typography style={{ fontSize: 10, marginRight: 5 }}>
												{TranslateText('fields.informationNotification.urgent')}
											</Typography>
										</td>
										<td style={{paddingBottom:"16px"}}>
											<Checkbox
												name="urgent"
												color={'primary'}
												checked={informationNotification.urgent}
												readOnly={informationNotification.type === LOGIN_REASON}
												onChange={(args: ChangeEvent<HTMLInputElement>) =>
													handleValueChange(args.target.checked, 'urgent')
												}
												disabled={informationNotification.type === LOGIN_REASON}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<Typography style={{ fontSize: 10, marginRight: 5 }}>
												{TranslateText('fields.driver')}
											</Typography>
										</td>
										<td>
											<Checkbox
												name="driver"
												color={'primary'}
												checked={informationNotification.driver}
												readOnly={informationNotification.type === LOGIN_REASON}
												onChange={(args: ChangeEvent<HTMLInputElement>) =>
													handleValueChange(args.target.checked, 'driver')
												}
												disabled={informationNotification.type === LOGIN_REASON}
											/>
										</td>
									</tr>
								</table>
							</div>
							<div className="form-group">
								<MaterialDatePicker
									isForNewAddWizard={true}
									name="startDateTime"
									showTime={true}
									date={informationNotification?.startDateTime}
									label={TranslateText('fields.informationNotification.startDateTime')}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeRemoveSeconds(), true)}
									onDateChange={(date: Date) => handleValueChange(date, 'startDateTime')}
									fullWidth={false}
									disabled={false}
									width={240}
									hiddenCloseButton={true}
									validationResult={validationResult?.startDateTime}
								/>
							</div>
							<div className="form-group">
								<MaterialDatePicker
									isForNewAddWizard={true}
									name="endDateTime"
									showTime={true}
									date={informationNotification?.endDateTime}
									label={TranslateText('fields.informationNotification.endDateTime')}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeRemoveSeconds(), true)}
									onDateChange={(date: Date) => handleValueChange(date, 'endDateTime')}
									fullWidth={false}
									disabled={false}
									width={240}
									validationResult={validationResult?.endDateTime}
								/>
							</div>
							<div className="form-group">
								<FormControlLabel
									control={
										<Checkbox
											name="active"
											color={'primary'}
											checked={informationNotification.active}
											readOnly={false}
											onChange={(args: ChangeEvent<HTMLInputElement>) =>
												handleValueChange(args.target.checked, 'active')
											}
											disabled={false}
										/>
									}
									label={
										<Typography style={{ fontSize: 10, marginRight: 5 }}>
											{TranslateText('fields.active')}
										</Typography>
									}
									labelPlacement="start"
									style={{ margin: 0 }}
								/>
							</div>
							<CustomerNameDisplay
								customerId={!!defaultCustomerId?.length ? defaultCustomerId : informationNotification.customerId}
								isForNewAddWizard={true}
							/>
						</div>
					</form>
				</div>
			</div>
			<div className={'information-notification-column'} style={{width: "66.66%"}}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.informationNotificationData')}</div>
					<form id="informationNotificationViewSystemInformationForm" noValidate={true}>
						<div className="information-notification-view-container">
							<div className={'language information-notification-column'} style={{width: "15%"}}>
								<div className={'column-content'}>
									<div className={'buttons-container'} >
										{languages.map((language) => (
											language.active
											 ? <Button
													className="language-button"
													key={language.id}
													onClick={() => clickLanguage(language.id)}
													style={language.id == languageSelected ? {backgroundColor: '#cccccc'} : {backgroundColor: ' #f5f5f5'}}
												>
													{language.code.toUpperCase()}
												</Button>
											 : null

										))}
									</div>
								</div>
							</div>
							<div className={'language information-notification-column'} style={{width: "85%"}}>
								{informationNotification.type === LOGIN_REASON
								? loginReason()
								: null
								}
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);

}
export default InformationNotificationView;
