import { History, LocationState } from 'history';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import { getRequestFilters } from 'utils/GridOverviewUtils';

import { Button, Tab, Tabs } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import { DEBOUNCE_TIME } from '../../Constants';
import * as GlobalSettings from '../../GlobalSettings.json';
import Alert from '../../models/Alert';
import AlertTypesEnum from '../../models/AlertTypesEnum';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import Customer from '../../models/Customer';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import Device from '../../models/Device';
import DevicePropertiesEnum from '../../models/DevicePropertiesEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import FilterFieldTypeEnum from '../../models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from '../../models/FilterOperatorTypeEnum';
import Group from '../../models/Group';
import Key from '../../models/Key';
import { Person } from '../../models/Person';
import Role from '../../models/Role';
import SimCard from '../../models/SimCard';
import Template from '../../models/Template';
import TrackableObject from '../../models/TrackableObject';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { addWizardActionCreators } from '../../store/AddWizard';
import { availableCustomersActions } from '../../store/AvailableCustomers';
import { userDataActionCreators } from '../../store/UserData';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseView from '../BaseView';
import { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import { DebouncedButton } from '../Common/DebouncedButton';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import AlertView from '../Views/AlertView';
import CustomerView from '../Views/CustomerView';
import DeviceView from '../Views/DeviceView';
import GroupView from '../Views/GroupView';
import KeyView from '../Views/KeyView';
import ObjectView from '../Views/ObjectView';
import AddPersonView from '../Views/Person/AddPersonView';
import RenderForEnum from '../Views/RenderForEnum';
import RoleView from '../Views/RoleView';
import SimCardView from '../Views/SimCardView';
import TemplateView from '../Views/TemplateView';
import WidgetStateEnum from '../Views/WidgetStateEnum';

function mapStateToProps(state: ApplicationState) {
	return {
		access_token: state.oidc.user.access_token,
		customerId: state.currentSession.customerId,
		customerPrivateTrip: state.currentSession.customer.featuresSettings.privacySettings,
		customerTrackTypeSpecification: state.currentSession.customer.featuresSettings.trackTypeSpecification,
		aspNetUserId: state.currentSession.aspNetUserId,
		userFirstAndLastName: state.currentSession.userFirstAndLastName,
		currentCustomerLevel: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterTextObject: state.globalCustomer.filterText,
		user: state.oidc.user,
		gridFilters: state.gridOverview,
		tripTypes: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: state.currentSession.customer.featuresSettings.tripTypes,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators(
		{
			...addWizardActionCreators,
			...userDataActionCreators,
			...availableCustomersActions,
			...unsavedDataStoreActionCreators,
		},
		dispatch
	);
}

const connector = connect(mapStateToProps, mapDispatchToProps);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	toggleAddWizardCallback: (show: boolean) => void;
	entityAddedCallback: (newEntity: object) => void;
	history: History<LocationState>;
	addWizardMode: EntityTypeEnum;
};

type State = {
	person: Person;
	validPerson: boolean;
	group: Group;
	validGroup: boolean;
	customer: Customer;
	validCustomer: boolean;
	device: Device;
	validDevice: boolean;
	object: TrackableObject;
	validObject: boolean;
	simCard: SimCard;
	validSimCard: boolean;
	invalidForm: boolean;
	unsavedData: boolean;
	notifyAboutUnsavedData: boolean;
	key: Key;
	validKey: boolean;
	template: Template;
	validTemplate: boolean;
	validStep: boolean;
	currentStep: number;
	maxStepCount: number;
	inViewWizard: boolean;
	validAlert: boolean;
	alert: Alert;
	role: Role;
	validRole: boolean;
	connectionConflictsNotifierRef?: ConnectionConflictsNotifier;
	devices: AutocompleteItem[];
	objectsData: AutocompleteItem[];
};

class AddWizard extends React.PureComponent<Props, State> {
	groupViewRef: any;
	customerViewRef: any;
	deviceViewRef: any;
	objectViewRef: any;
	simCardViewRef: SimCardView;
	keyViewRef: any;
	templateViewRef: any;
	currentView: BaseView;

	constructor(props: Props) {
		super(props);

		this.state = {
			person: null,
			validPerson: false,
			group: null,
			validGroup: false,
			customer: null,
			validCustomer: false,
			device: null,
			validDevice: false,
			object: null,
			validObject: false,
			simCard: null,
			validSimCard: false,
			invalidForm: true,
			unsavedData: false,
			notifyAboutUnsavedData: false,
			key: null,
			validKey: false,
			validStep: false,
			currentStep: 1,
			template: null,
			validTemplate: false,
			maxStepCount: 1,
			inViewWizard: false,
			validAlert: false,
			alert: null,
			role: null,
			validRole: false,
			connectionConflictsNotifierRef: null,
			devices: [],
			objectsData: [],
		};

		this.prepareLists();
	}

	componentDidMount() {
		this.props.toggleSelection(false);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (
			this.props.globalCustomer?.id !== prevProps.globalCustomer?.id ||
			this.props.customerId !== prevProps.customerId
		) {
			this.fetchDevicesList();
		}

		if (this.state.unsavedData !== prevState.unsavedData) {
			this.props.setUnsavedData(this.state.unsavedData);
		}
	}

	//define here the new tabs and put them in the correct order
	renderForMode(mode: EntityTypeEnum) {
		switch (mode) {
			case EntityTypeEnum.Customer: {
				return this.renderCustomer();
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				return this.renderPerson();
			}
			case EntityTypeEnum.Device: {
				return this.renderDevice();
			}
			case EntityTypeEnum.Object: {
				return this.renderObject();
			}
			case EntityTypeEnum.SimCard: {
				return this.renderSimCard();
			}
			case EntityTypeEnum.Key: {
				return this.renderKey();
			}
			case EntityTypeEnum.Group: {
				return this.renderGroup();
			}
			case EntityTypeEnum.Alert: {
				return this.renderAlert();
			}
			case EntityTypeEnum.Template: {
				return this.renderTemplate();
			}
			case EntityTypeEnum.Role: {
				return this.renderRole();
			}
		}
	}

	public setPerson(valid: boolean, personData: Person) {
		this.setState({
			person: personData,
			validPerson: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	private setPersonValidation(inProgress: boolean) {
		this.setState({
			validPerson: this.state.validPerson && !inProgress,
			invalidForm: this.state.invalidForm || inProgress,
		});
	}

	public setGroup(valid: boolean, groupData: Group) {
		this.setState({
			group: groupData,
			validGroup: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setRole(valid: boolean, roleData: Role) {
		this.setState({
			role: roleData,
			validRole: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setDevice(valid: boolean, deviceData: Device) {
		this.setState({
			device: deviceData,
			validDevice: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setCustomer(valid: boolean, customerData: Customer) {
		this.setState({
			customer: customerData,
			validCustomer: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setObject(valid: boolean, objectData: TrackableObject, unsavedData: boolean) {
		this.setState({
			object: objectData,
			validObject: valid,
			unsavedData: unsavedData,
			invalidForm: !valid,
		});
	}

	public setSimCard(valid: boolean, simCardData: SimCard) {
		this.setState({
			simCard: simCardData,
			validSimCard: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setKey(valid: boolean, keyData: Key, unsavedData: boolean) {
		this.setState({
			key: keyData,
			validKey: valid,
			unsavedData: unsavedData,
			invalidForm: !valid,
		});
	}

	public setTemplate(valid: boolean, templateData: Template) {
		this.setState({
			template: templateData,
			validTemplate: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	public setAlert(valid: boolean, alertData: Alert) {
		this.setState({
			alert: alertData,
			validAlert: valid,
			unsavedData: true,
			invalidForm: !valid,
		});
	}

	// listsDataSources

	prepareLists() {
		switch (this.props.addWizardMode) {
			case EntityTypeEnum.Person:
			case EntityTypeEnum.Driver: {
				this.fetchObjectsList();
				break;
			}
			case EntityTypeEnum.Object: {
				this.fetchDevicesList();
				break;
			}
			case EntityTypeEnum.SimCard: {
				this.fetchDevicesList();
				break;
			}
		}
	}

	fetchDevicesList() {
		if (ClaimUtil.validateClaim(this.props.user, { claim: ClaimType.Devices, values: [ClaimValue.edit] })) {
			const filter = {
				fieldType: FilterFieldTypeEnum.customerId,
				operator: FilterOperatorTypeEnum.equal,
				fieldValue: this.getDefaultCustomerId(),
				display: DevicePropertiesEnum.Code,
			};
			ajaxUtil.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, filter).then((data) => {
				this.setState({
					devices: data,
				});
			});
		}
	}

	fetchObjectsList() {
		const filter = {
			fieldType: FilterFieldTypeEnum.customerId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: this.getDefaultCustomerId(),
		};

		ajaxUtil
			.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Objects?filter=' + JSON.stringify(filter))
			.then((data) => {
				this.setState({
					objectsData: data,
				});
			});
	}

	getDefaultCustomerId(): string {
		return this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId;
	}

	getDefaultCustomerTrackTypeSpecificationOn(): boolean {
		return this.props.globalCustomer
			? this.props.globalCustomer.featuresSettings.trackTypeSpecification
			: this.props.customerTrackTypeSpecification;
	}

	getSelectedCustomerId(): string {
		return this.props.globalCustomer ? this.props.globalCustomer.id : null;
	}

	getSelectedCustomerLevel(): CustomerLevelEnum {
		return this.props.globalCustomer ? this.props.globalCustomer.level : null;
	}

	allowCustomerChange(): boolean {
		//return this.props.currentCustomerLevel !== CustomerLevelEnum.Default;
		//PBI 5500 states that As a user I can't change the customer anymore in the add wizard
		return false;
	}

	public renderDevice() {
		return (
			<DeviceView
				widgetState={WidgetStateEnum.Edit}
				setDevice={this.setDevice.bind(this)}
				renderFor={RenderForEnum.Wizard}
				ref={(input) => {
					this.deviceViewRef = input;
				}}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
			/>
		);
	}

	public renderPerson() {
		return (
			<AddPersonView
				readOnly={false}
				forceDriver={this.props.addWizardMode === EntityTypeEnum.Driver}
				maintenanceApi={
					this.props.addWizardMode === EntityTypeEnum.Driver
						? GlobalSettings.driversMaintenanceApi
						: GlobalSettings.personsMaintenanceApi
				}
				objectsData={this.state.objectsData}
				defaultCustomerId={this.getDefaultCustomerId()}
				setPerson={this.setPerson.bind(this)}
				setValidationStatus={this.setPersonValidation.bind(this)}
			/>
		);
	}

	public renderGroup() {
		return (
			<GroupView
				widgetState={WidgetStateEnum.Edit}
				ref={(input) => {
					this.groupViewRef = input;
				}}
				getAccessTokenCallback={this.getAccessToken.bind(this)}
				setGroup={this.setGroup.bind(this)}
				renderFor={RenderForEnum.Wizard}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
			/>
		);
	}

	public renderCustomer() {
		return (
			<CustomerView
				renderFor={RenderForEnum.Wizard}
				widgetState={WidgetStateEnum.Edit}
				ref={(input) => {
					this.customerViewRef = input;
				}}
				setCustomer={this.setCustomer.bind(this)}
			/>
		);
	}

	public renderObject() {
		return (
			<ObjectView
				widgetState={WidgetStateEnum.None}
				renderFor={RenderForEnum.Wizard}
				devicesData={this.state.devices}
				ref={(input) => {
					this.objectViewRef = input;
				}}
				setObject={this.setObject.bind(this)}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
			/>
		);
	}

	public renderSimCard() {
		return (
			<SimCardView
				renderFor={RenderForEnum.Wizard}
				devicesData={this.state.devices}
				ref={(input) => {
					this.simCardViewRef = input;
				}}
				setSimCard={this.setSimCard.bind(this)}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
				enableConnectedDeviceField={ClaimUtil.validateClaimList(this.props.user, [
					{
						claim: ClaimType.Devices,
						values: [ClaimValue.edit],
					},
					{
						claim: ClaimType.SimDeviceConnection,
						values: [ClaimValue.edit],
					},
				])}
			/>
		);
	}

	public renderKey() {
		return (
			<KeyView
				userFirstAndLastName={this.props.userFirstAndLastName}
				widgetState={WidgetStateEnum.Edit}
				renderFor={RenderForEnum.Wizard}
				ref={(input) => {
					this.keyViewRef = input;
				}}
				setKey={this.setKey.bind(this)}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
			/>
		);
	}

	public renderTemplate() {
		return (
			<TemplateView
				widgetState={WidgetStateEnum.Edit}
				renderFor={RenderForEnum.Wizard}
				ref={(input) => {
					this.templateViewRef = input;
				}}
				setTemplate={this.setTemplate.bind(this)}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={false}
				allowTrackTypeSpecification={this.getDefaultCustomerTrackTypeSpecificationOn()}
				tripTypes={this.props.tripTypes}
			/>
		);
	}

	public renderAlert() {
		return (
			<AlertView
				widgetState={WidgetStateEnum.Edit}
				ref={(scope) => {
					this.currentView = scope;
				}}
				getAccessTokenCallback={this.getAccessToken.bind(this)}
				setAlert={this.setAlert.bind(this)}
				currentStep={this.state.currentStep}
				setMaxStepCount={this.handleSetMaxStep.bind(this)}
				notifyNavigationCallback={this.handleNavigationNotification.bind(this)}
				renderFor={RenderForEnum.Wizard}
				readOnly={false}
				defaultCustomerId={this.getDefaultCustomerId()}
				allowCustomerChange={this.allowCustomerChange()}
				allowTrackTypeSpecification={this.getDefaultCustomerTrackTypeSpecificationOn()}
				disableNavigation={this.disableAlertNavigation.bind(this)}
			/>
		);
	}

	private disableAlertNavigation() {
		this.setState({
			invalidForm: true,
			validAlert: false,
		});
	}

	public renderRole() {
		return (
			<RoleView
				widgetState={WidgetStateEnum.Edit}
				resetUnsavedFlagCallback={this.resetUnsavedDataFlag.bind(this)}
				ref={(scope) => {
					this.currentView = scope;
				}}
				getAccessTokenCallback={this.getAccessToken.bind(this)}
				setRole={this.setRole.bind(this)}
				currentStep={this.state.currentStep}
				setMaxStepCount={this.handleSetMaxStep.bind(this)}
				notifyNavigationCallback={this.handleNavigationNotification.bind(this)}
				renderFor={RenderForEnum.Wizard}
				readOnly={false}
				selectedCustomerId={this.getSelectedCustomerId()}
				selectedCustomerLevel={this.getSelectedCustomerLevel()}
				currentUserCustomerId={this.props.customerId}
				currentUserCustomerLevel={this.props.currentCustomerLevel}
				enableReportsEdit={ClaimUtil.validateClaim(this.props.user, {
					claim: ClaimType.Reporting,
					values: [ClaimValue.edit],
				})}
			/>
		);
	}

	tryCloseAddWizard() {
		if (this.state.unsavedData === true) {
			this.setState({ notifyAboutUnsavedData: true });
		} else {
			this.props.toggleAddWizardCallback(false);
		}
	}

	handleUnsavedDataResponse(response: boolean) {
		if (response) {
			this.resetUnsavedDataFlag();
			this.props.toggleAddWizardCallback(false);
		}
		this.setState({ notifyAboutUnsavedData: false });
	}

	handleSetMaxStep(maxStepCount: number) {
		this.setState({
			maxStepCount: maxStepCount,
			inViewWizard: true,
		});
	}

	handleNavigationNotification(valid: boolean, currentStep: number) {
		this.setState({
			validStep: valid,
			currentStep: currentStep,
			invalidForm: !valid,
		});
	}

	handleConfictingConnections(sender: ConnectionsConflictResolver, confirm: boolean) {
		const proposedConnectionCanBeSaved = sender.conflictsCanBeAutomaticallyFixed && confirm;
		setTimeout(() => {
			sender.handleConflictingConnections(proposedConnectionCanBeSaved);
		}, 500);
	}

	getAccessToken(): string {
		return this.props.access_token;
	}

	getEntityTypeForConnectionNotifier(): ConnectionTypeEnum {
		switch (this.props.addWizardMode) {
			case EntityTypeEnum.Person: {
				return ConnectionTypeEnum.PersonObject;
			}
			case EntityTypeEnum.Device: {
				return ConnectionTypeEnum.DeviceObject;
			}
			case EntityTypeEnum.Object: {
				return ConnectionTypeEnum.DeviceObject;
			}
			case EntityTypeEnum.Key: {
				return ConnectionTypeEnum.PersonKey;
			}
			default: {
				return ConnectionTypeEnum.CustomerDevice;
			}
		}
	}

	getHeader(): string {
		if (
			this.props.addWizardMode === EntityTypeEnum.Customer &&
			this.props.currentCustomerLevel === CustomerLevelEnum.OEM
		) {
			return TranslateText('addWizard.addReseller');
		}
		return TranslateText(`addWizard.add${this.props.addWizardMode}`);
	}

	render() {
		return (
			<div id="addwizard">
				<NotificationPrompt
					title={TranslateText('common.titleUnsavedData')}
					message={TranslateText('notificationMessages.cancel')}
					handleUserResponse={this.handleUnsavedDataResponse.bind(this)}
					displayDialog={this.state.notifyAboutUnsavedData}
				/>
				<ConnectionConflictsNotifier
					ref={(scope) => {
						this.setState({ connectionConflictsNotifierRef: scope });
					}}
					connectionType={this.getEntityTypeForConnectionNotifier()}
					visible={false}
					userAnswearCallback={this.handleConfictingConnections.bind(this)}
				/>
				<div className="add-header">
					{this.getHeader()}
					<Button
						className="e-flat close-button"
						onClick={() => {
							this.tryCloseAddWizard();
						}}
					>
						{TranslateText('common.buttonClose')} X
					</Button>
				</div>
				<hr className="line" />
				<Tabs value={0}>
					<Tab value={0} label={TranslateText(`common.${this.props.addWizardMode.toLowerCase()}`)} />
				</Tabs>
				<div style={{ height: '400px' }}>{this.renderForMode(this.props.addWizardMode)}</div>

				<div className="bottom-navigation">
					<Button
						className="wizard-button cancel-button"
						onClick={() => {
							this.tryCloseAddWizard();
						}}
					>
						{TranslateText('common.buttonCancel')}
					</Button>

					<DebouncedButton
						className="save-button wizard-button"
						disabled={this.state.invalidForm}
						onClick={() => {
							this.save();
						}}
						debounceTime={DEBOUNCE_TIME}
					>
						{TranslateText('common.buttonSave')}
					</DebouncedButton>
					<Button
						className="next-button wizard-button"
						disabled={this.state.currentStep === this.state.maxStepCount || !this.state.validStep}
						onClick={() => this.goToNexTabPage()}
					>
						{TranslateText('common.buttonNext')}
					</Button>
				</div>
				<hr className="line" />
			</div>
		);
	}

	goToNexTabPage() {
		if (this.state.inViewWizard) {
			this.setState({
				currentStep: this.state.currentStep + 1,
			});
		}
	}

	save() {
		switch (this.props.addWizardMode) {
			case EntityTypeEnum.Person: {
				this.savePerson();
				break;
			}
			case EntityTypeEnum.Driver: {
				this.saveDriver();
				break;
			}
			case EntityTypeEnum.Group: {
				this.saveGroup();
				break;
			}
			case EntityTypeEnum.Customer: {
				this.saveCustomer();
				break;
			}
			case EntityTypeEnum.Device: {
				this.saveDevice();
				break;
			}
			case EntityTypeEnum.Object: {
				this.saveObject();
				break;
			}
			case EntityTypeEnum.SimCard: {
				this.saveSimCard();
				break;
			}
			case EntityTypeEnum.Key: {
				this.saveKey();
				break;
			}
			case EntityTypeEnum.Template: {
				this.saveTemplate();
				break;
			}
			case EntityTypeEnum.Alert: {
				this.saveAlert();
				break;
			}
			case EntityTypeEnum.Role: {
				this.saveRole();
				break;
			}
		}

		this.resetUnsavedDataFlag();
	}

	async saveObject() {
		const validationResult = await this.objectViewRef.validateBeforeSave();

		if (!validationResult) {
			this.setState({ invalidForm: true });
			return;
		}

		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Object].gridFilters);

		const data = await ajaxUtil.post<object>(GlobalSettings.objectsMaintenanceApi, {
			AddModel: {
				...this.state.object,
				timezoneId: this.state.object.timezoneId ? this.state.object.timezoneId : null,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});

		this.props.entityAddedCallback(data);
	}

	async saveSimCard() {
		if (!(await this.simCardViewRef.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}
		const { sortField, sortOrder } = getRequestFilters(this.props.gridFilters[EntityTypeEnum.SimCard].gridFilters);

		const response = await ajaxUtil.post(GlobalSettings.simCardsMaintenanceApi, {
			AddModel: {
				imsi: this.state.simCard.imsi.trim(),
				iccid: this.state.simCard.iccid.trim(),
				phoneNumber: this.state.simCard.phoneNumber.trim(),
				pinCode: this.state.simCard.pinCode.trim(),
				provider: this.state.simCard.provider.trim(),
				customerId: this.state.simCard.customerId,
				deviceId: this.state.simCard.deviceId,
				active: true, //a new created sim card should be always active
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.simcards,
			SortField: sortField,
			SortOrder: sortOrder,
		});

		this.props.entityAddedCallback(response);
		this.props.toggleAddWizardCallback(false);
	}

	async saveDevice() {
		if (!(await this.deviceViewRef.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}

		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Device].gridFilters);

		const response = await ajaxUtil.post(GlobalSettings.devicesMaintenanceApi, {
			AddModel: {
				code: this.state.device.code,
				customerId: this.state.device.customerId,
				deviceTypeId: this.state.device.deviceTypeId,
				productionDate: this.state.device.productionDate,
				serialNumber: this.state.device.serialNumber,
				firmwareVersion: this.state.device.firmwareVersion,
				hardwareVersionNumber: this.state.device.hardwareVersionNumber,
				certificateNumber: this.state.device.certificateNumber,
				authenticationType: this.state.device.authenticationType,
				disconnectDriverAfterIgnitionOff: this.state.device.disconnectDriverAfterIgnitionOff,
				disconnectDriverAfterIgnitionOffTimeOutSeconds: this.state.device
					.disconnectDriverAfterIgnitionOffTimeOutSeconds,
				deviceNeedToBeSyncedWithEd2: this.state.device.deviceNeedToBeSyncedWithEd2,
				deviceShouldHaveEd2MediaSupport: this.state.device.deviceShouldHaveEd2MediaSupport,
				isTripDevice: this.state.device.isTripDevice,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});

		this.props.entityAddedCallback(response);
		this.props.toggleAddWizardCallback(false);
	}

	async saveCustomer() {
		if (!(await this.customerViewRef.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}

		const response = await ajaxUtil.post(GlobalSettings.customersMaintenanceApi, {
			AddModel: {
				code: this.state.customer.code,
				active: this.state.customer.active,
				name: this.state.customer.name,
				description: this.state.customer.description,
				languageId: this.state.customer.languageId,
				timezoneId: this.state.customer.timezoneId,
				startCountryId: this.state.customer.startCountryId,
				startPage: this.state.customer.startPage,
				isTest: this.state.customer.isTest,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
		});

		if (response) {
			this.props.hasNewCustomer(true);
		}

		this.props.entityAddedCallback(response);
		this.props.toggleAddWizardCallback(false);
	}

	async saveKey() {
		if (!(await this.keyViewRef.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}

		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Key].gridFilters);

		const response = await ajaxUtil.post(GlobalSettings.keysMaintenanceApi, {
			AddModel: {
				code: this.state.key.code,
				customerId: this.state.key.customerId,
				personId: this.state.key.personId ? this.state.key.personId : null,
				userId: this.props.aspNetUserId,
				serialNumber: this.state.key.serialNumber,
				productionDate: this.state.key.productionDate,
				active: this.state.key.active,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.keys,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});
		this.props.entityAddedCallback(response);
	}

	async saveAlert() {
		if (this.state.invalidForm) {
			return;
		}

		const url = GlobalSettings.alertsApi;
		const newAlert = {
			code: this.state.alert.code,
			name: this.state.alert.name,
			customerId: this.state.alert.customerId,
			alertRecipients: (this.state.alert.alertRecipients as any[]).map(({ entityId, entityType }) => ({
				entityId,
				entityType,
			})),
			trackedEntities: (this.state.alert.trackedEntities as any[]).map(({ entityId, entityType }) => ({
				entityId,
				entityType,
			})),
			alertType: this.state.alert.eventTypeId,
			eventFrequency: this.state.alert.eventFrequency,
			entityType: this.state.alert.entityType,
			useNotification: this.state.alert.useNotification,
			usePopup: this.state.alert.usePopup,
			useEmail: this.state.alert.useEmail,
			useSms: this.state.alert.useSms,
			useWhatsApp: this.state.alert.useWhatsApp,
			notificationTemplate: this.state.alert.notificationContent,
			popupTemplate: this.state.alert.popupContent,
			mailTemplate: this.state.alert.mailContent,
			smsTemplate: this.state.alert.smsContent,
			whatsAppTemplate: this.state.alert.whatsAppContent,
			privateMileageAlertMetadata:
				this.state.alert.eventTypeId === AlertTypesEnum.PrivateMileage
					? this.state.alert.PrivateMileageAlertMetadata
					: null,
			durationExceededAlertMetadata:
				this.state.alert.eventTypeId === AlertTypesEnum.DurationExceeded
					? {
							...this.state.alert.DurationExceededAlertMetadata,
							duration: this.state.alert.DurationExceededAlertMetadata.relatedToTime
								? this.state.alert.DurationExceededAlertMetadata.duration
								: null,
							durationCounter: !this.state.alert.DurationExceededAlertMetadata.relatedToTime
								? this.state.alert.DurationExceededAlertMetadata.durationCounter
								: null,
					  }
					: null,
			analogAlertMetadata:
				this.state.alert.eventTypeId === AlertTypesEnum.Analog ? this.state.alert.analogAlertMetadata : null,
			digitalAlertMetadata:
				this.state.alert.eventTypeId === AlertTypesEnum.Digital ? this.state.alert.digitalAlertMetadata : null,
			userId: this.props.aspNetUserId,
			conditionType: 1,
			active: true,
		};

		if (newAlert.privateMileageAlertMetadata) {
			switch (newAlert.privateMileageAlertMetadata.preThresholds) {
				case 0: {
					newAlert.privateMileageAlertMetadata.firstPreThresholdAlert = 0;
					newAlert.privateMileageAlertMetadata.secondPreThresholdAlert = 0;
					newAlert.privateMileageAlertMetadata.thirdPreThresholdAlert = 0;
					break;
				}
				case 1: {
					newAlert.privateMileageAlertMetadata.secondPreThresholdAlert = 0;
					newAlert.privateMileageAlertMetadata.thirdPreThresholdAlert = 0;
					break;
				}
				case 2: {
					newAlert.privateMileageAlertMetadata.thirdPreThresholdAlert = 0;
					break;
				}
			}
		}

		const { sortField, sortOrder, flagOrder } = getRequestFilters(
			this.props.gridFilters[EntityTypeEnum.Alert].gridFilters
		);

		const response = await ajaxUtil.post(url, {
			AddModel: newAlert,
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: sortField,
			SortOrder: sortOrder,
			FlagOrder: flagOrder,
		});

		this.props.entityAddedCallback(response);
	}

	async saveRole() {
		if (!(await this.currentView.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}
		const reportCategories = this.state.role.reports
			.filter((r) => r.entityType === EntityTypeEnum.ReportCategory)
			.map((r) => {
				return r.entityId;
			});
		const reports = this.state.role.reports
			.filter((r) => r.entityType === EntityTypeEnum.Report)
			.map((r) => {
				return r.entityId;
			});
		const claimWithValues: string[] = [];
		this.state.role.roleClaims.forEach((roleClaim) => {
			claimWithValues.push(`${roleClaim.claim}/${roleClaim.values}`);
		});
		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Role].gridFilters);

		const response = await ajaxUtil.post(GlobalSettings.rolesMaintenanceApi, {
			AddModel: {
				code: this.state.role.code,
				name: this.state.role.name,
				customerId: this.state.role.customerId,
				description: this.state.role.description,
				active: true,
				generic: this.state.role.generic,
				claims: [],
				claimWithValues: claimWithValues,
				reportIds: reports,
				reportCategories: reportCategories,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});
		this.props.entityAddedCallback(response);
	}

	async saveTemplate() {
		if (!(await this.templateViewRef.validateBeforeSave())) {
			this.setState({ invalidForm: true });
			return;
		}

		let addUrl = `${GlobalSettings.sensorTemplatesMaintenanceApi}/`;

		switch (this.state.template.templateType) {
			case TemplateTypesEnum.Event:
				addUrl += 'AddEventTemplate';
				break;
			case TemplateTypesEnum.Duration:
				addUrl += 'AddDurationTemplate';
				break;
			case TemplateTypesEnum.Digital:
				addUrl += 'AddDigitalTemplate';
				break;
			default:
				break;
		}

		const { sortField, sortOrder, flagOrder } = getRequestFilters(
			this.props.gridFilters[EntityTypeEnum.Template].gridFilters
		);

		const response = await ajaxUtil.post(addUrl, {
			AddModel: {
				code: this.state.template.code.trim(),
				name: this.state.template.name.trim(),
				description: this.state.template.description.trim(),
				rank: 0,
				active: true,
				...this.state.template,
			},
			TemplateType: this.state.template.templateType,
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: sortField,
			SortOrder: sortOrder,
			FlagOrder: flagOrder,
		});

		this.props.entityAddedCallback(response);
		this.props.toggleAddWizardCallback(false);
	}

	resetUnsavedDataFlag() {
		this.props.setUnsavedData(false);
		this.setState({
			unsavedData: false,
		});
	}

	async savePerson() {
		if (!this.state.validPerson) {
			this.setState({ invalidForm: true });
			return;
		}

		const url = GlobalSettings.personsMaintenanceApi;
		const newPerson = this.state.person;
		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Person].gridFilters);

		const response = await ajaxUtil.post(url, {
			AddModel: newPerson,
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});

		this.props.entityAddedCallback(response);
	}

	async saveDriver() {
		if (!this.state.validPerson) {
			this.setState({ invalidForm: true });
			return;
		}

		const url = GlobalSettings.driversMaintenanceApi;
		const newDriver = this.state.person;
		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Driver].gridFilters);

		const response = await ajaxUtil.post(url, {
			AddModel: newDriver,
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});
		this.props.entityAddedCallback(response);
	}

	async saveGroup() {
		if (!this.groupViewRef.validateBeforeSave()) {
			this.setState({ invalidForm: true });
			return;
		}

		const gridFilters = getRequestFilters(this.props.gridFilters[EntityTypeEnum.Group].gridFilters);

		const response = await ajaxUtil.post(GlobalSettings.groupsMaintenanceApi, {
			AddModel: {
				code: this.state.group.code,
				customerId: this.state.group.customerId,
				name: this.state.group.name,
				description: this.state.group.description,
				active: this.state.group.active,
			},
			GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
			FilterText: this.props.filterTextObject.devices,
			SortField: gridFilters.sortField,
			SortOrder: gridFilters.sortOrder,
		});
		this.props.entityAddedCallback(response);
	}
}

export default connector(AddWizard);
