import { IButtonWidgetView } from 'components/Widgets/ButtonWidget/ButtonWidget';
import * as GlobalSettings from 'GlobalSettings.json';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Icon } from '@material-ui/core';

import { IntegrationGroup } from './types';

const IntegrationGroupsView = (props: IButtonWidgetView) => {
	const customerId = useSelector(
		(state: ApplicationState) => state.loadedEntityContext?.entityContextData?.customerId
	);

	useEffect(() => {
		props.setUrl(`${GlobalSettings.integrationsMaintenanceApi}/${props.entityId}/groups`);
	}, []);

	const terminateConnection = useCallback(
		async (rowData: IntegrationGroup) => {
			if (rowData) {
				await ajaxUtil.put(`${GlobalSettings.integrationsMaintenanceApi}/updateIntegrationGroups`, {
					customerId: customerId,
					apiIntegrationId: props.entityId,
					removedGroups: [rowData.id],
				});

				props.reloadDataCallback();
			}
		},
		[props.entityId, customerId, props.reloadDataCallback]
	);

	return (
		<DataTable
			value={props.data as IntegrationGroup[]}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: IntegrationGroup) =>
					props.editMode ? (
						<span className={rowData.active ? '' : 'inactiveEntity'}>{rowData.name}</span>
					) : (
						<Link
							className={rowData.active ? '' : ' inactiveEntity'}
							to={`${GlobalSettings.route.groups}/${rowData.id}`}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: IntegrationGroup) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default IntegrationGroupsView;
