import { Log, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { createUserManager } from 'redux-oidc';

Log.logger = console;
Log.level = Log.DEBUG;

const userManagerConfig: UserManagerSettings = {
	client_id: 'easytrack-portal',
	redirect_uri: process.env.REACT_APP_BASE_URL + '/signin-callback',
	response_type: 'token id_token',
	scope: 'openid profile api',
	authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
	silent_redirect_uri: process.env.REACT_APP_BASE_URL + '/signin-silent-renew',
	post_logout_redirect_uri: process.env.REACT_APP_BASE_URL + '/signout-callback-oidc',
	automaticSilentRenew: true,
	revokeAccessTokenOnSignout: true,
	filterProtocolClaims: false,
	loadUserInfo: true,
	userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userService: { userManager: UserManager } = {
	userManager: null,
};

export default userService;

//UserManager must be initialize first
export const initUserManager = () => {
	userService.userManager = createUserManager(userManagerConfig);
};
