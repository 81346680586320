import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldValidationResult } from 'shared/validation/interfaces';
import { ApplicationState } from 'store';
import { commonDataActionCreators } from 'store/CommonData';

import MaterialAutocomplete from '../Autocomplete/MaterialAutocomplete';

interface Props {
	valueId?: string;
	name?: string;
	label?: string;
	disabled?: boolean;
	errorMsgContainerId?: string;
	className?: string;
	onChange: (autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => void;
	isForNewAddWizard?: boolean;
	validationResult?: FieldValidationResult;
}

const TimezoneSelection = (props: Props) => {
	const timezonesFetched = useSelector((state: ApplicationState) => state.commonData.timezonesFetched);
	const timezonesDataSource = useSelector((state: ApplicationState) => state.commonData.timezonesDataSource);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!timezonesFetched) {
			dispatch(commonDataActionCreators.fetchTimezones());
		}
	}, []);

	return (
		<MaterialAutocomplete
			{...props}
			dataSource={timezonesDataSource}
			isForNewAddWizard={props.isForNewAddWizard}
			validationResult={props.validationResult}
		/>
	);
};

export default TimezoneSelection;
