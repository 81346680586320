import './InformationNotificationLanguageView.scss'

import { Button } from "@material-ui/core";
import React, {useEffect, useState } from "react";
import {WidgetViewDefault} from "../Widget";
import InformationNotification from "../../../models/InformationNotification";
import GlobalSettings from "../../../GlobalSettings.json";
import ajaxUtil from "../../../utils/Ajax";
import {LanguageDto} from "../../NewAddWizard/Views/InformationNotification/InformationNotificationView";
import NotificationLoginReasonView from "../NotificationLoginReasonView";
import {LOGIN_REASON} from "../../../Constants";
import {ApplicationState} from "../../../store";
import { useSelector } from 'react-redux';


type Props = WidgetViewDefault & {
	data: InformationNotification;
	initialData: InformationNotification;
};

const InformationNotificationLanguageView=(props: Props) => {

	const [languageSelected, setLanguageSelected] = useState<string>('');
	const [languages, setLanguages] = useState<LanguageDto[]>([]);
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	useEffect(() => {
		getLanguages()
	},[]);
	const getLanguages = () => {
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/getLanguages`;
		ajaxUtil.get<LanguageDto[]>(url).then((result) => {
			setLanguageSelected(result.find(x => x.code.toLowerCase() === loginInLanguage.toLowerCase())?.id);
			setLanguages(result);
		});
	}
	const clickLanguage = (id: string) => {
		setLanguageSelected(id);
	}
	return(
		<div className="language-view-container">
			<div className={'language-notification-column'} style={{width: "25%"}}>
				<div className={'column-content'}>
					<div className={'buttons-container'} style={{height: '100%'}}>
						{languages?.map((language) => (
							language.active
							? <Button
								className="language-button"
								key={language.id}
								onClick={() => clickLanguage(language.id)}
								style={language.id == languageSelected ? {backgroundColor: '#cccccc'} : {backgroundColor: ' #f5f5f5'}}
							  >
								{language.code.toUpperCase()}
							  </Button>
							: null
						))}
					</div>
				</div>
			</div>
			<div className={'language-notification-column'} style={{width: "75%"}}>
				{props.getDataCallback() === LOGIN_REASON
				? <NotificationLoginReasonView
					{...props}
					languageSelected={languageSelected}
					languages={languages}
				  />
				: null
				}
			</div>
		</div>
	);
}

export default  InformationNotificationLanguageView;
