import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from 'components/GridOverview/Columns/MultiSelectionColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import IconSelectionColumn from '../GridOverview/Columns/IconSelectionColumn/IconSelectionColumn';
import { GridEntity } from '../GridOverview/GridOverview';
import { SvgIconRenderer } from '../SvgIconRenderer';

interface Props {
	history: History<LocationState>;
}

export enum LocationAccessTypeEnum {
	None = 0,
	Global = 1,
	Personal = 2,
	Private = 3,
	GlobalMap = 6,
}

export enum LocationAreaTypeEnum {
	Circle = 0,
	Polygon = 1,
}

export enum LocationIconTypeEnum {
	Cleaning,
	Factory,
	GasStation,
	HomePin,
	Home,
	Pin,
	Shopping,
	Star,
	Warehouse,
}

type IconName =
	| 'locations/cleaning'
	| 'locations/factory'
	| 'locations/gas-station'
	| 'locations/home-pin'
	| 'locations/home'
	| 'locations/pin'
	| 'locations/shopping'
	| 'locations/star'
	| 'locations/warehouse';

const iconMapping: Record<LocationIconTypeEnum, IconName> = {
	[LocationIconTypeEnum.Cleaning]: 'locations/cleaning',
	[LocationIconTypeEnum.Factory]: 'locations/factory',
	[LocationIconTypeEnum.GasStation]: 'locations/gas-station',
	[LocationIconTypeEnum.HomePin]: 'locations/home-pin',
	[LocationIconTypeEnum.Home]: 'locations/home',
	[LocationIconTypeEnum.Pin]: 'locations/pin',
	[LocationIconTypeEnum.Shopping]: 'locations/shopping',
	[LocationIconTypeEnum.Star]: 'locations/star',
	[LocationIconTypeEnum.Warehouse]: 'locations/warehouse',
};

export interface LocationDto extends GridEntity {
	iconName: IconName;
	iconType: LocationIconTypeEnum;
	iconColor: string;
	accessType: LocationAccessTypeEnum;
	code: string;
	name: string;
	category: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	active: boolean;
	longitude: number;
	latitude: number;
}

export const getSvgIcon = (option: MultiSelectionColumnOption) => {
	const optionCode: LocationIconTypeEnum = +option.code;
	const iconName = iconMapping[optionCode];
	return (
		<span title={iconName?.replace('locations/', '')}>
			<SvgIconRenderer size={19} iconName={iconName} iconColor={'black'} />
		</span>
	);
};
const fieldNames = {
	iconType: 'iconType',
	accessType: 'accessType',
	code: 'code',
	name: 'name',
	category: 'category',
	address: 'address',
	postalCode: 'postalCode',
	country: 'country',
	longitude: 'longitude',
	latitude: 'latitude',
	city: 'city',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.iconType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.category]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.address]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.postalCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.country]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.city]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.accessType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.longitude]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.latitude]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

const LocationsOverview = (props: Props) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);

	const [filtersDisabled, setFiltersDisabled] = useState(false);

	useEffect(() => {
		setFiltersDisabled(unsavedData);
	}, [unsavedData]);

	const locationTypeSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: '',
				code: LocationAccessTypeEnum.None.toString(),
				hidden: true,
			},
			{
				name: TranslateText('locations.global'),
				code: LocationAccessTypeEnum.Global.toString(),
			},
			// {
			// 	name: TranslateText('locations.personal'),
			// 	code: LocationAccessTypeEnum.Personal.toString(),
			// },
			// {
			// 	name: TranslateText('locations.private'),
			// 	code: LocationAccessTypeEnum.Private.toString(),
			// },
			{
				name: TranslateText('locations.globalMap'),
				code: LocationAccessTypeEnum.GlobalMap.toString(),
			},
		],
		[]
	);

	const iconNameSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() =>
			(Object.keys(LocationIconTypeEnum).filter((el) => isNaN(Number(el))) as Array<
				keyof typeof LocationIconTypeEnum
			>).map((key) => ({
				name: iconMapping[LocationIconTypeEnum[key]].replace('locations/', ''),
				code: LocationIconTypeEnum[key].toString(),
			})),
		[]
	);

	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.accessType]: computeFlagOrderingBasedOnTranslation(locationTypeSelectOptions),
			[fieldNames.iconType]: computeFlagOrderingBasedOnTranslation(iconNameSelectOptions),
		}),
		[]
	);

	const columns = [
		IconSelectionColumn({
			fieldName: fieldNames.iconType,
			header: TranslateText('maintenanceOverview.grid.colIcon'),
			sortable: true,
			filterable: true,
			body: (rowData: LocationDto) => {
				return (
					<span title={rowData.iconName.replace('locations/', '')}>
						<SvgIconRenderer iconName={rowData.iconName} iconColor={rowData.iconColor} />
					</span>
				);
			},
			optionItemTemplate: (option) => getSvgIcon(option),
			selectOptions: iconNameSelectOptions,
			showHeaderTooltip: false,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.accessType,
			header: TranslateText('maintenanceOverview.grid.colType'),
			sortable: true,
			filterable: true,
			body: (rowData: LocationDto) => {
				const locationType =
					rowData.accessType === LocationAccessTypeEnum.GlobalMap
						? TranslateText(`locations.globalMap`)
						: TranslateText(`locations.${LocationAccessTypeEnum[rowData.accessType].toLowerCase()}`);
				return <span title={locationType}>{locationType}</span>;
			},
			selectOptions: locationTypeSelectOptions,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.category,
			header: TranslateText('maintenanceOverview.grid.colCategory'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.address,
			header: TranslateText('maintenanceOverview.grid.colAddress'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.postalCode,
			header: TranslateText('maintenanceOverview.grid.colPostalCode'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.city,
			header: TranslateText('maintenanceOverview.grid.colCity'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		TextColumn({
			fieldName: fieldNames.country,
			header: TranslateText('maintenanceOverview.grid.colCountry'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
			filterDisabled: filtersDisabled,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.locationsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.locations')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default || unsavedData}
			showAddConfigClaim={[
				{
					claim: ClaimType.Locations,
					values: [ClaimValue.edit],
				},
			]}
			addEntityUrl={GlobalSettings.locationsMaintenanceApi + '/addLocation'}
			entityType={EntityTypeEnum.Location}
			columnFlagOrder={columnFlagOrdering}
			useNewAddWizard
			inlineEdit={true}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
			showImportConfigClaim={[
				{
					claim: ClaimType.Locations,
					values: [ClaimValue.edit],
				},
				{
					claim: ClaimType.Import,
					values: [ClaimValue.locations]
				}
			]}
			showExportConfigClaim={[
				{
					claim: ClaimType.Locations,
					values: [ClaimValue.view],
				},
				{
					claim: ClaimType.Export,
					values: [ClaimValue.locations]
				}
			]}
		/>
	);
};

export default LocationsOverview;
