import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { getAlertFrequencyDatasource } from 'components/Views/AlertsConstants';
import React, { useMemo } from 'react';

import { EventFrequencyProps } from './types';

const EventFrequency = ({ initialValue, name, onChangeHandler, disabled = false }: EventFrequencyProps) => {
	const eventFrequency = useMemo((): AutocompleteItem[] => getAlertFrequencyDatasource(), []);

	return (
		<MaterialAutocomplete
			name={name}
			disabled={disabled}
			className="event-frequency-autocomplete"
			valueId={initialValue}
			dataSource={eventFrequency}
			onChange={(arg) => onChangeHandler(+arg.value)}
			fullWidth={false}
			disableClearable
		/>
	);
};

export default EventFrequency;
