import BaseConnection from './BaseConnection';

class PersonKeyConnection extends BaseConnection {
	constructor(copyOf?: PersonKeyConnection) {
		if (copyOf) {
			super(copyOf);
			this.personId = copyOf.personId;
			this.personCode = copyOf.personCode;
			this.personName = copyOf.personName;
			this.keyId = copyOf.keyId;
			this.keyCode = copyOf.keyCode;
		} else {
			super(null);
		}
	}

	public personId: string;
	public personCode: string;
	public personName: string;
	public keyId: string;
	public keyCode: string;
}

export default PersonKeyConnection;
