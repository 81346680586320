import './custom.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import { ConfigurationGroupsOverview } from 'components/ConfigurationGroupsOverview';
import InformationNotificationsOverview from 'components/InformationNotificationsOverview';
import IntegrationDetails from 'components/IntegrationDetails';
import IntegrationsOverview from 'components/IntegrationsOverview';
import ObjectsOverview from 'components/ObjectsOverview';
import { ConnectedRouter } from 'connected-react-router';
import { Location } from 'history';
import { getAccessToken } from 'index';
import { BlobIconDictionary } from 'models/BlobIcon';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { processSilentRenew } from 'redux-oidc';
import NotificationPromptWrapper from 'shared/components/UserPrompt/NotificationPromptWrapper';

import ClaimType from './authorization/ClaimType';
import EasyTrackFeature from './authorization/EasyTrackFeature';
import AlertDetails from './components/AlertDetails';
import { AlertOverview } from './components/AlertsOverview';
import CustomerDetails from './components/CustomerDetails';
import CustomersOverview from './components/CustomersOverview';
import DeviceDetails from './components/DeviceDetails';
import { DeviceLogsOverview } from './components/DeviceLogsOverview';
import DevicesOverview from './components/DevicesOverview';
import DriverDetails from './components/DriverDetails/DriverDetails';
import DriversOverview from './components/DriversOverview';
import FeatureUnavailable from './components/FeatureUnavailable';
import GroupDetails from './components/GroupsDetails';
import { GroupsOverview } from './components/GroupsOverview/GroupsOverview';
import HelpOverview from './components/Help/HelpOverview';
import HistoryOverview from './components/HistoryOverview';
import Home from './components/Home';
import InformationNotificationDetails from './components/InformationNotificationDetails';
import KeyDetails from './components/KeyDetails';
import { KeysOverview } from './components/KeysOverview/KeysOverview';
import Layout from './components/Layout/Layout';
import RietveldMenu from './components/Layout/SideBar/RietveldMenu';
import { LiveMap } from './components/LiveMap';
import LocationsOverview from './components/LocationsOverview';
import Impersonate from './components/Login/Impersonate';
import Login from './components/Login/Login';
import SigninCallbackPage from './components/Login/SigninCallbackPage';
import Logout from './components/Logout/Logout';
import NotificationEngine from './components/NotificationEngine/NotificationEngine';
import ObjectDetails from './components/ObjectDetails';
import PersonDetails from './components/PersonDetails';
import { PersonOverview } from './components/PersonsOverview/PersonsOverview';
import ReportsOverview from './components/ReportsOverview';
import RoleDetails from './components/RoleDetails';
import { RoleOverview } from './components/RolesOverview';
import SimCardDetail from './components/SimCardsDetail';
import SimCardsOverview from './components/SimCardsOverview';
import TemplateDetails from './components/TemplateDetails';
import TemplatesOverview from './components/TemplatesOverview';
import ToolsPage from './components/Tools';
import TranslationsOverview from './components/TranslationsOverview';
import WhitePage from './components/WhitePage';
import * as GlobalSettings from './GlobalSettings.json';
import { FetchStatus } from './models/FetchStatus';
import ProtectedRoute from './ProtectedRoute';
import DateTimeUtil from './shared/datetime/DateTimeUtil';
import { ApplicationState, CurrentSession, CustomerFeaturesState } from './store';
import { translationsActionCreators } from './store/Translations';
import { userDataActionCreators } from './store/UserData';
import ajaxUtil from './utils/Ajax';
import { getApplicationStartPage } from './utils/StartPageUtils';

interface AppModuleProps {
	user: string;
	userExpired: boolean;
	dispatch: Dispatch;
	location: Location;
	translationStatus: FetchStatus;
	currentSession: CurrentSession;
	history: any;
	language: string;
	featuresSettings: CustomerFeaturesState;
	driverIdentification: boolean;
}

const App = (props: AppModuleProps) => {
	const isSilentRenew = props.location.pathname === '/signin-silent-renew';

	const isAuthenticated =
		!!props.user && props.userExpired === false && !!props.currentSession?.customer?.featuresSettings;

	useQuery(
		['application-icons'],
		() => ajaxUtil.get<BlobIconDictionary>(`${GlobalSettings.globalDataApi}/blobIcons`),
		{
			enabled: isAuthenticated,
			staleTime: Infinity,
		}
	);

	const { isFetching: isFetchingTranslations, refetch: refetchTranslations } = useQuery(
		['application-translations'],
		() =>
			ajaxUtil.get<{ success: boolean; languagePreference: string }>(
				`${GlobalSettings.translationsApi}/getLanguage`
			),
		{
			onError: () => console.log('something went wrong getting language'),
			onSuccess(data) {
				if (!data.success || !data.languagePreference) {
					console.log('something went wrong getting language');
					return;
				}

				bindActionCreators(translationsActionCreators, props.dispatch).setTranslationsReady(
					getAccessToken(),
					data.languagePreference
				);
			},
			enabled: false,
		}
	);

	useEffect(() => {
		if (
			props.user &&
			props.userExpired === false &&
			props.language &&
			props.currentSession.dateTimeSettings &&
			!props.currentSession.dateTimeSettings.useLocalSettings &&
			!isSilentRenew
		) {
			DateTimeUtil.setMomentLocale(props.language, props.currentSession.dateTimeSettings.startingDay);
		}
	}, [props.user, props.userExpired, props.language, props.currentSession.dateTimeSettings]);

	useEffect(() => {
		if (
			props.user &&
			props.userExpired === false &&
			(props.translationStatus === FetchStatus.None || props.translationStatus === FetchStatus.Error) &&
			!isSilentRenew
		) {
			refetchTranslations();
		}
	}, [props.user, props.userExpired, props.translationStatus]);

	useEffect(() => {
		let logoutTimeout: null | ReturnType<typeof setTimeout> = null;

		if (
			props.user &&
			props.userExpired === false &&
			!isSilentRenew &&
			(!props.currentSession.customer ||
				(props.currentSession.customer && !props.currentSession.customer.featuresSettings))
		) {
			bindActionCreators(userDataActionCreators, props.dispatch).getUserData(props.user);

			if (
				props.currentSession.customer?.blockFromDate &&
				!isNaN(Date.parse(props.currentSession.customer.blockFromDate)) &&
				Date.parse(props.currentSession.customer.blockFromDate) > new Date().getTime()
			) {
				logoutTimeout = setTimeout(() => {
					props.history.push('/logout');
				}, Date.parse(props.currentSession.customer.blockFromDate) - new Date().getTime());
			}
		}

		return () => {
			if (logoutTimeout) {
				clearTimeout(logoutTimeout);
			}
		};
	}, [props.user, props.userExpired, props.currentSession]);

	//signin silent renew is called by oidc-client from iframe
	if (isSilentRenew) {
		processSilentRenew();
		return <></>;
	}

	if (isFetchingTranslations) {
		return <></>;
	}

	return (
		<div className="App">
			<ConnectedRouter history={props.history}>
				<>
					<NotificationPromptWrapper />
					{isAuthenticated && props.translationStatus === FetchStatus.Loaded ? (
						<>
							<NotificationEngine />
							<Switch>
								<ProtectedRoute
									key={props.location.pathname}
									path={GlobalSettings.route.help}
									component={HelpOverview}
								/>
								<ProtectedRoute
									key={props.location.pathname}
									path="/device-logs/:type/:entityId"
									claim={ClaimType.Devices}
									component={DeviceLogsOverview}
								/>
							</Switch>
							<Layout isAuthenticated={isAuthenticated}>
								<RietveldMenu />
								<Switch>
									<ProtectedRoute
										path={'/unauthorized'}
										component={() => (
											<FeatureUnavailable
												message={
													'Unauthorized to access this feature, contact system administrator.'
												}
											/>
										)}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.live}
										claim={ClaimType.Live}
										feature={EasyTrackFeature.Live}
										component={LiveMap}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.dashboard}
										claim={ClaimType.Dashboard}
										feature={EasyTrackFeature.Dashboard}
										component={Home}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.objectDetails}
										claim={ClaimType.Objects}
										component={ObjectDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.objects}
										claim={ClaimType.Objects}
										component={ObjectsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.templateDetails}
										claim={ClaimType.Templates}
										feature={EasyTrackFeature.Templates}
										component={TemplateDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.templates}
										claim={ClaimType.Templates}
										feature={EasyTrackFeature.Templates}
										component={TemplatesOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.history}
										claim={ClaimType.History}
										feature={EasyTrackFeature.History}
										component={HistoryOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.locations}
										claim={ClaimType.Locations}
										feature={EasyTrackFeature.Locations}
										component={LocationsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Drivers}
										feature={EasyTrackFeature.DriverIdentification}
										path={GlobalSettings.route.driverDetails}
										component={DriverDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.drivers}
										claim={ClaimType.Drivers}
										feature={EasyTrackFeature.DriverIdentification}
										component={DriversOverview}
									/>

									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Reporting}
										feature={EasyTrackFeature.Reports}
										path={GlobalSettings.route.reports}
										component={ReportsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.support}
										component={Home}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Persons}
										path={GlobalSettings.route.personDetails}
										component={PersonDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Persons}
										path={GlobalSettings.route.persons}
										component={PersonOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Devices}
										path={GlobalSettings.route.deviceDetails}
										component={DeviceDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Devices}
										path={GlobalSettings.route.devices}
										component={DevicesOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.SimCards}
										path={GlobalSettings.route.simcardDetails}
										component={SimCardDetail}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.SimCards}
										path={GlobalSettings.route.simcards}
										component={SimCardsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.groupDetails}
										claim={ClaimType.Groups}
										component={GroupDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Groups}
										path={GlobalSettings.route.groups}
										component={GroupsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.roleDetails}
										claim={ClaimType.Roles}
										component={RoleDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.roles}
										claim={ClaimType.Roles}
										component={RoleOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path="/addresses"
										claim={ClaimType.Locations}
										component={Home}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Keys}
										path={GlobalSettings.route.keyDetails}
										component={KeyDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Keys}
										path={GlobalSettings.route.keys}
										component={KeysOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Alerts}
										feature={EasyTrackFeature.Alerts}
										path={GlobalSettings.route.alertDetails}
										component={AlertDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Alerts}
										feature={EasyTrackFeature.Alerts}
										path={GlobalSettings.route.alerts}
										component={AlertOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Integration}
										feature={EasyTrackFeature.Integration}
										path={GlobalSettings.route.integrationDetails}
										component={IntegrationDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Integration}
										feature={EasyTrackFeature.Integration}
										path={GlobalSettings.route.integrations}
										component={IntegrationsOverview}
									/>
									{props.currentSession?.customer?.featuresSettings?.mediaFootage ? (
										<ProtectedRoute
											key={props.location.pathname}
											claim={ClaimType.ConfigurationGroup}
											feature={EasyTrackFeature.MediaFootage}
											path={GlobalSettings.route.configurationGroups}
											component={ConfigurationGroupsOverview}
										/>
									) : props.currentSession?.customer?.featuresSettings?.driverStyleScores ? (
										<ProtectedRoute
											key={props.location.pathname}
											claim={ClaimType.ConfigurationGroup}
											feature={EasyTrackFeature.DriverStyleScores}
											path={GlobalSettings.route.configurationGroups}
											component={ConfigurationGroupsOverview}
										/>
									) : (
										<ProtectedRoute
											key={props.location.pathname}
											claim={ClaimType.ConfigurationGroup}
											feature={EasyTrackFeature.DriverStyleScores}
											path={GlobalSettings.route.configurationGroups}
											component={ConfigurationGroupsOverview}
										/>
									)}
									<ProtectedRoute
										key={props.location.pathname}
										path={'/translations'}
										claim={ClaimType.Translations}
										component={TranslationsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Subscriptions}
										path="/subscription"
										component={Home}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Customers}
										path={GlobalSettings.route.customerDetails}
										component={CustomerDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.Customers}
										path={GlobalSettings.route.customers}
										component={CustomersOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.InformationNotificationManage}
										path={GlobalSettings.route.informationNotificationDetails}
										component={InformationNotificationDetails}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										claim={ClaimType.InformationNotificationManage}
										path={GlobalSettings.route.informationNotifications}
										component={InformationNotificationsOverview}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path="/configs"
										claim={ClaimType.Configs}
										component={Home}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.tools}
										claim={ClaimType.OemTools}
										component={ToolsPage}
									/>
									<ProtectedRoute
										key={props.location.pathname}
										path={GlobalSettings.route.whitePage}
										component={WhitePage}
									/>
									<Route exact path={'/'}>
										{!isAuthenticated ? null : (
											<Redirect
												to={`/${getApplicationStartPage(
													props.currentSession?.customer?.featuresSettings,
													props.currentSession?.startPage
												)}`}
											/>
										)}
									</Route>
								</Switch>
							</Layout>
						</>
					) : null}

					<Switch>
						<Route exact path="/logout" component={Logout} />
						<Route exact path="/signin-callback" component={SigninCallbackPage} />
						<Route exact path="/impersonate" component={Impersonate} />
						<Route exact path="/login" component={Login} />
						{!!props.user ? null : (
							<Route path="/">
								<Redirect to={'/login'} />
							</Route>
						)}
					</Switch>
				</>
			</ConnectedRouter>
		</div>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.oidc.user?.profile.sub,
		userExpired: state.oidc.user?.expired,
		location: state.router.location,
		translationStatus: state.translations.translationStatus,
		currentSession: state.currentSession,
		language: state.translations.language,
		featuresSettings: state?.currentSession?.customer?.featuresSettings,
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.driverIdentification
			: state.currentSession?.customer?.featuresSettings?.driverIdentification,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
