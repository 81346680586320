import React from 'react';
import Socket from '../Socket';
import { useLiveDataSocket } from './hooks';
import { LiveDataResponse } from './types';

const LiveDataSocket = (): JSX.Element => {
	const { socketMessage, setReceivedLiveData } = useLiveDataSocket();

	return (
		<Socket
			url={process.env.REACT_APP_LIVE_DATA_PATH}
			dataToSend={socketMessage}
			messageCallback={(data: unknown) => setReceivedLiveData(data as LiveDataResponse)}
		/>
	);
};

export default LiveDataSocket;
