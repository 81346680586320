import './styles.scss';

import SvgIconName from 'components/IconPicker/SvgIconName/SvgIconName';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';

import EntityTypeEnum from '../../../models/EntityTypeEnum';
import { StateFilterEnum } from '../../../models/LiveMenuUtils';
import {
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from '../../../shared/effects/useShowLastEntities';
import { ApplicationState } from '../../../store';
import { LiveDataResultDto } from '../../../store/LiveData';
import PrivateUserIcon from '../../Common/Icons/PrivateUserIcon';
import { useSensorTemplates } from '../hooks';
import { SensorTemplateEnum } from '../types';
import InfoWindowContent from './InfoWindowContent';

interface Props {
	data: LiveDataResultDto;
	selected?: boolean;
}

const LiveInfoWindow = (props: Props) => {
	const [connectionBroken, setConnectionBroken] = useState<boolean>(false);
	const [heartBeatPresent, setHeartBeatPresent] = useState<boolean>(false);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();

	const subtitle =
		props.data.entityType === EntityTypeEnum.Person &&
		((props.data.state === StateFilterEnum.Parked && showLastObjectOfParkedDriver) ||
			props.data.state !== StateFilterEnum.Parked)
			? props.data.objectName
			: driverIdentification &&
			  ((props.data.state === StateFilterEnum.Parked && showLastDriverOfParkedObject) ||
					props.data.state !== StateFilterEnum.Parked) &&
			  props.data.entityType !== EntityTypeEnum.Person
			? props.data.personName
			: null;

	useEffect(() => {
		const verifyIfConnectionIsBroken = () => {
			//check if the connection is broken, only if the time difference between the last seen timestamp and the keep alive timestamp is less or equal with 1 minute
			// otherwise it means that the keep alive timestamp is outdated
			if (
				(+new Date(props.data.lastSeen).getTime() - +new Date(props.data.lastKeepAlive).getTime()) /
					1000 /
					60 <=
				1
			) {
				const connectionBrokenResult =
					(new Date().getTime() - new Date(props.data.lastKeepAlive).getTime()) / 1000 / 60 > 5;
				if (connectionBrokenResult !== connectionBroken) {
					setConnectionBroken(connectionBrokenResult);
					setHeartBeatPresent(true);
				}
			} else if ((new Date().getTime() - new Date(props.data.lastSeen).getTime()) / 1000 / 60 > 5) {
				if (connectionBroken !== true) {
					setHeartBeatPresent(false);
					setConnectionBroken(true);
				}
			} else {
				setConnectionBroken(false);
			}
		};

		verifyIfConnectionIsBroken();

		const id = setInterval(() => {
			verifyIfConnectionIsBroken();
		}, 10000);
		return () => {
			clearInterval(id);
		};
	}, [props.data]);

	const { sensorTemplates, headerStyle } = useSensorTemplates(props.data, trackTypeSpecification);

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div
			onMouseEnter={() => {
				if (!isExpanded) {
					setIsExpanded(true);
				}
			}}
			onMouseLeave={() => {
				if (isExpanded) {
					setTimeout(() => {
						setIsExpanded(false);
					}, 50);
				}
			}}
			className="live-info-window"
		>
			<div className={'header'}>
				<div className="text right-radius" style={headerStyle}>
					<div className="title">
						{props.data.entityType === EntityTypeEnum.Person
							? props.data.personName
							: props.data.objectName}
					</div>
					{subtitle ? <div className={'subtitle'}>{subtitle}</div> : null}
				</div>

				{sensorTemplates.length
					? sensorTemplates.map((td) =>
							td.iconName ? (
								<div
									key={td.templateId}
									style={td.labelColor ? { color: td.labelColor } : {}}
									className="notification right-radius"
								>
									<SvgIconName
										iconName={td.iconName}
										color={td.labelColor ? td.labelColor : '#4d6d92'}
										size={12}
									/>
								</div>
							) : td.sensorTemplateType === SensorTemplateEnum.Event ? (
								<div
									key={td.templateId}
									style={td.labelColor ? { color: td.labelColor } : {}}
									className="notification right-radius"
								>
									<PrivateUserIcon
										style={{ color: td.labelColor ? td.labelColor : '#006837' }}
										fontSize={'inherit'}
									/>
								</div>
							) : null
					  )
					: null}

				{connectionBroken &&
				(props.data.entityType === EntityTypeEnum.Object ||
					(props.data.entityType === EntityTypeEnum.Person &&
						props.data.state !== StateFilterEnum.Parked)) ? (
					<div className="notification right-radius error">
						<PortableWifiOffIcon fontSize={'inherit'} />
					</div>
				) : null}
			</div>

			{isExpanded || props.selected ? (
				<InfoWindowContent
					data={props.data}
					sensorTemplates={sensorTemplates}
					trackTypeSpecification={trackTypeSpecification}
					connectionBroken={connectionBroken}
					heartBeatPresent={heartBeatPresent}
				/>
			) : null}
		</div>
	);
};

export default LiveInfoWindow;
