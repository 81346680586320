import { Person } from 'models/Person';
import { FieldRules, ValidationData } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';
import * as GlobalSettings from 'GlobalSettings.json';
import ajaxUtil from 'utils/Ajax';

const getEmptyPersonValidationRules = (): FieldRules => ({
	username: {
		rules: {
			requiredIfFieldNotNull: ValidatorFunctions.requiredIfFieldNotNull('password'),
			maxLength: ValidatorFunctions.maxLength(50),
			alphaNumeric: ValidatorFunctions.regexIfNotNull(
				ValidationRegex.onlyAlphaNumeric(),
				TranslateText('fieldsValidations.onlyalphanumeric')
			),
			unique: {
				message: TranslateText('fieldsValidations.uniqueValue'),
				validationFunction: (data: ValidationData, fieldName: string) => {
					const personData = data as Person;

					if (!data[fieldName]) {
						return ValidatorFunctions.wrapInPromise(true);
					}

					return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
						customerId: personData.customerId,
						editedEntityId: personData.id ? personData.id : null,
						value: data[fieldName],
						Field: 'username',
					});
				},
			},
		},
		dependencies: ['password'],
	},
	password: {
		rules: {
			requiredIfFieldNotNull: ValidatorFunctions.requiredIfFieldNotNull('username'),
			noEmptySpace: ValidatorFunctions.noEmptySpace(),
			maxLength: ValidatorFunctions.maxLength(50),
			minLength: ValidatorFunctions.minLength(6),
		},
		dependencies: ['username'],
	},
});

const getNotEmptyPersonValidationRules = (): FieldRules => ({
	username: {
		rules: {
			required: ValidatorFunctions.required(),
			maxLength: ValidatorFunctions.maxLength(50),
			alphaNumeric: ValidatorFunctions.regexIfNotNull(
				ValidationRegex.onlyAlphaNumeric(),
				TranslateText('fieldsValidations.onlyalphanumeric')
			),
			unique: {
				message: TranslateText('fieldsValidations.uniqueValue'),
				validationFunction: (data: ValidationData, fieldName: string) => {
					const personData = data as Person;

					if (!data[fieldName]) {
						return ValidatorFunctions.wrapInPromise(true);
					}

					return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
						customerId: personData.customerId,
						editedEntityId: personData.id ? personData.id : null,
						value: data[fieldName],
						Field: 'username',
					});
				},
			},
		},
	},
	password: {
		rules: {
			noEmptySpace: ValidatorFunctions.noEmptySpace(),
			maxLength: ValidatorFunctions.maxLength(50),
			minLength: ValidatorFunctions.minLength(6),
		},
	},
});

export { getEmptyPersonValidationRules, getNotEmptyPersonValidationRules };
