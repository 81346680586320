import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useEffect } from 'react';
import ThreeWaySwitchComponent from 'shared/components/ThreeWaySwitchComponent';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { DateTimeConstants } from 'utils/DateTimeConstants';
import { TranslateText } from 'utils/Translations';

import { TextField } from '@material-ui/core';

import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: PersonDateTimeSettings;
	initialData: PersonDateTimeSettings;
	//Any other custom props
};

export interface PersonDateTimeSettings {
	useLocalSettings?: boolean;
	dateFormat?: string;
	timeFormat?: string;
	calendarFormat?: number;
	startingDay?: number;
}

export const PersonDateTimeViewValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		dateFormat: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as PersonDateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings !== false || !!dateTimeSettings.dateFormat
						);
					},
				},
			},
		},
		timeFormat: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as PersonDateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings !== false || !!dateTimeSettings.timeFormat
						);
					},
				},
			},
		},
		calendarFormat: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as PersonDateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings !== false ||
								!!dateTimeSettings.calendarFormat ||
								dateTimeSettings.calendarFormat === 0
						);
					},
				},
			},
		},
		startingDay: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as PersonDateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings !== false ||
								!!dateTimeSettings.startingDay ||
								dateTimeSettings.startingDay === 0
						);
					},
				},
			},
		},
	};

	return fieldRules;
};

const PersonDateTimeView = (props: Props) => {
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: PersonDateTimeViewValidationConfig() }, true));
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | boolean, key: keyof PersonDateTimeSettings) => {
			const newState = { ...props.data };
			(newState[key] as string | boolean | number) = value;

			if (key === 'useLocalSettings') {
				if (newState.useLocalSettings === null || newState.useLocalSettings === undefined) {
					newState.dateFormat = null;
					newState.timeFormat = null;
					newState.calendarFormat = null;
					newState.startingDay = null;
				} else {
					newState.dateFormat = props.initialData.dateFormat;
					newState.timeFormat = props.initialData.timeFormat;
					newState.calendarFormat = props.initialData.calendarFormat;
					newState.startingDay = props.initialData.startingDay;
				}
			}

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper DateTime-form'}>
			<ThreeWaySwitchComponent
				id="useLocalSettings"
				name={'useLocalSettings'}
				placeholder={TranslateText('fields.useLocalSettings')}
				readonly={!props.editMode}
				enabled={props.editMode}
				value={props.data.useLocalSettings}
				change={(value: boolean | null) => events.onValueChanged(value, 'useLocalSettings')}
			/>
			{props.data.useLocalSettings === false ? (
				<div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data?.dateFormat}
							dataSource={DateTimeConstants.dateFormat}
							name="dateFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.dateFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'dateFormat')}
						/>
						<ValidationMessage result={props.validationResult?.dateFormat} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data?.timeFormat}
							dataSource={DateTimeConstants.timeFormat}
							name="timeFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.timeFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'timeFormat')}
						/>
						<ValidationMessage result={props.validationResult?.timeFormat} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data?.calendarFormat}
							dataSource={DateTimeConstants.calendarFormat()}
							name="calendarFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.calendarFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'calendarFormat')}
						/>
						<ValidationMessage result={props.validationResult?.calendarFormat} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data?.startingDay}
							dataSource={DateTimeConstants.startingDay()}
							name="startingDay"
							disabled={!props.editMode}
							label={TranslateText('fields.startingDay')}
							onChange={({ value }) => events.onValueChanged(value as string, 'startingDay')}
						/>
						<ValidationMessage result={props.validationResult?.startingDay} />
					</div>
				</div>
			) : (
				<div>
					<div className="form-group">
						<TextField
							id="dateFormat"
							type="text"
							fullWidth
							label={TranslateText('fields.dateFormat')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="dateFormat"
							value={
								props.data.useLocalSettings
									? TranslateText('fields.useLocalSettings')
									: TranslateText('customerOverview.useCustomerSettings')
							}
							disabled={!props.editMode}
						/>
					</div>
					<div className="form-group">
						<TextField
							id="timeFormat"
							type="text"
							fullWidth
							label={TranslateText('fields.timeFormat')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="timeFormat"
							value={
								props.data.useLocalSettings
									? TranslateText('fields.useLocalSettings')
									: TranslateText('customerOverview.useCustomerSettings')
							}
							disabled={!props.editMode}
						/>
					</div>
					<div className="form-group">
						<TextField
							id="calendarFormat"
							type="text"
							fullWidth
							label={TranslateText('fields.calendarFormat')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="calendarFormat"
							value={
								props.data.useLocalSettings
									? TranslateText('fields.useLocalSettings')
									: TranslateText('customerOverview.useCustomerSettings')
							}
							disabled={!props.editMode}
						/>
					</div>
					<div className="form-group">
						<TextField
							id="startingDay"
							type="text"
							fullWidth
							label={TranslateText('fields.startingDay')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="startingDay"
							value={
								props.data.useLocalSettings
									? TranslateText('fields.useLocalSettings')
									: TranslateText('customerOverview.useCustomerSettings')
							}
							disabled={!props.editMode}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default PersonDateTimeView;
