enum FilterDialogModeEnum {
	ObjectPerson = 'ObjectPerson',
	ObjectDriver = 'ObjectDriver',
	PersonDriver = 'PersonDriver',
	Groups = 'Groups',
	Rights = 'Rights',
	Reports = 'Reports',
	Role = 'Role',
	PersonDriverWithEmail = 'PersonDriverWithEmail',
	Object = 'Object',
	Driver = 'Driver',
}

export default FilterDialogModeEnum;
