import './styles.scss';

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { CircularProgress, Tooltip } from '@material-ui/core';

import SensorTypeEnum from '../../models/SensorTypeEnum';
import TemplateUnitOfMeasureEnum from '../../models/TemplateUnitOfMeasureEnum';
import DateTimeUtil, { DateTimeConversionUtil } from '../../shared/datetime/DateTimeUtil';
import { GetHourAndMinutes } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { getContrastColor } from '../../utils/TripUtils';
import { isDateToday } from '../ChartHistorySummary/ChartTooltip/ChartTooltip';
import { TripStateChange } from '../TemplateHistoryTab/TemplateHistoryTab';

interface TemplateStateChangesProps {
	states: TripStateChange[];
	showOnlyTime: boolean;
	containsDay: boolean;
	numberOfStates: number;
}
const TemplateStateChangeSummary = (props: TemplateStateChangesProps) => {
	const [showTooltip, setShowTooltip] = useState(true);
	const scrollTimeoutRef = useRef(null);

	const setHiddenWindowScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	const setNoneWindowScroll = () => {
		document.body.style.overflow = 'none';
	};
	const handleScroll = () => {
		setShowTooltip(false);
		setHiddenWindowScroll();
		clearTimeout(scrollTimeoutRef.current);
		scrollTimeoutRef.current = setTimeout(() => {
			setShowTooltip(true);
			setNoneWindowScroll();
		}, 100);
	};

	useEffect(() => {
		const container = document.querySelector('.state-scrollable');
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
			clearTimeout(scrollTimeoutRef.current);
		};
	}, []);

	return (
		<div className={'state-summary'}>
			<div className={'state-summary-row state-name'}>
				<Tooltip title={`${TranslateText('stateSummary.stateChanges')}`}>
					<span title={'stateChanges'} className={'state-summary-field'}>
						{TranslateText('stateSummary.stateChanges')}
					</span>
				</Tooltip>
			</div>
			<div
				className={'state-scrollable'}
				style={{ overflowY: props.numberOfStates > 6 ? 'scroll' : 'hidden', maxHeight: 145 }}
			>
				{props.states?.map((state, index) => (
					<div key={index} className={'state-summary-row'}>
						<span className={'state-summary-time'}>{GetHourAndMinutes(state.time, true)}</span>
						{!props.showOnlyTime ? (
							<span
								className={'state-summary-date'}
								style={
									!props.containsDay
										? { minWidth: 'calc(100% - 80%)', maxWidth: 'calc(100% - 80%)' }
										: { minWidth: 'calc(100% - 66.6%)', maxWidth: 'calc(100% - 66.6%)' }
								}
							>
								{!isDateToday(state.time) &&
									moment(state.time).format(
										DateTimeConversionUtil.syncFusionToMomentDateFormat(
											DateTimeUtil.dateFormat(),
											true
										)
									)}
							</span>
						) : null}
						<div
							style={{
								minWidth: props.showOnlyTime
									? 'calc(100% - 30%)'
									: !props.containsDay
									? 'calc(100% - 50%)'
									: 'calc(100% - 64%)',
								maxWidth: props.showOnlyTime
									? 'calc(100% - 30%)'
									: !props.containsDay
									? 'calc(100% - 50%)'
									: 'calc(100% - 64%)',
								display: 'flex',
							}}
						>
							<Tooltip title={showTooltip ? `${state.templateName} - ${state.name}` : ''}>
								<span className="state-summary-template-name">{state.templateName}</span>
							</Tooltip>
							<Tooltip title={showTooltip ? `${state.templateName} - ${state.name}` : ''}>
								<span style={{ paddingLeft: 3, paddingRight: 3 }}>{'-'}</span>
							</Tooltip>
							<Tooltip title={showTooltip ? `${state.templateName} - ${state.name}` : ''}>
								<span className="state-summary-state-name">{state.name}</span>
							</Tooltip>
						</div>

						<span
							className={'state-summary-value'}
							style={{
								backgroundColor: state.color ? state.color : '#4d6d92',
								color: getContrastColor(state.color ? state.color : '#4d6d92'),
							}}
						>
							{state.value}
							{state.sensorType === SensorTypeEnum.Analog
								? state.unitOfMeasure === TemplateUnitOfMeasureEnum.Percentage
									? ' %'
									: state.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature
									? ' ℃'
									: state.unitOfMeasure === TemplateUnitOfMeasureEnum.Voltage
									? ' V'
									: state.unitOfMeasure === TemplateUnitOfMeasureEnum.Distance
									? ' Km'
									: state.unitOfMeasure === TemplateUnitOfMeasureEnum.Time
									? ' m'
									: ''
								: ''}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default TemplateStateChangeSummary;
