import './buttonWidget.scss';

import React, { useState } from 'react';
import { TranslateText } from '../../../utils/Translations';
import { IActionButton } from './ButtonWidget';
import ClaimSelectionDialog from '../../ClaimSelectionDialog/ClaimSelectionDialog';
import { Button } from '@material-ui/core';

const AddClaimActionButton = (props: IActionButton) => {
	const [dialogVisible, setDialogVisible] = useState(false);
	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>
			<ClaimSelectionDialog
				entityId={props.entityId}
				visible={dialogVisible}
				currentSelection={props.currentSelection}
				closeCallback={(dataChanged) => {
					if (dataChanged) {
						props.actionExecuted(true);
					}
					setDialogVisible(false);
				}}
			/>
		</>
	);
};

export default AddClaimActionButton;
