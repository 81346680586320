import './styles.scss';
import React from 'react';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { TranslateMonth } from 'utils/Translations';
import { MonthTrendEnum } from 'models/MonthTrendEnum';
import { PreviousMonthTrend } from 'models/ObjectTemplatesAggregation';
import { GetTemplateHourTranslation } from 'utils/DateUtils';

const TemplateMonthTrend = ({ month, totalHours, monthTrend }: PreviousMonthTrend): JSX.Element => {
	return (
		<div className="template-trend">
			<span className="month">{TranslateMonth(month)}</span>
			<span>
				{totalHours == 1
					? <span className="hour">{totalHours} {GetTemplateHourTranslation(totalHours)}</span>
					: <span>{totalHours} {GetTemplateHourTranslation(totalHours)}</span>
				}
				{monthTrend !== MonthTrendEnum.None ? (
					<CallMadeIcon
						className={`${monthTrend === MonthTrendEnum.Asceneding ? 'good' : 'bad'}-trend-icon`}
					/>
				) : (
					<span className="flat-trend">&#8212;</span>
				)}
			</span>
		</div>
	);
};

export default TemplateMonthTrend;
