import './style.scss';
import './widget.scss';

import BaseWidget from 'components/BaseWidget';
import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import { PersonHistorySettingsDto } from 'components/PersonSettings/PersonHistorySettings/PersonHistorySettings';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState, CustomerHistorySettings } from 'store';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { userDataActionCreators } from 'store/UserData';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import { unsavedDataStoreActionCreators } from '../../store/UnsavedDataStore';
import { DebouncedButton } from '../Common/DebouncedButton';
import CustomerHistorySettingsView, { HistorySettings } from './CustomerHistorySettingsView';
import CustomerInformationView from './CustomerInformationView';
import CustomerLiveSettingsView, { LiveSettings } from './CustomerLiveSettingsView';
import PersonLiveSettingsView, { PersonLiveSettings } from './PersonLiveSettingsView';

const mapState = (state: ApplicationState) => {
	return { accessToken: state.oidc.user.access_token };
};

const connector = connect(mapState, null);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface OldWidgetProps extends IEditableWidget {
	row: string;
	col: string;
	sizeX: string;
	sizeY: string;
	id: string;
	entityId: string;
	entityType?: EntityTypeEnum;
	changeDataCallback?: (hasChanges: boolean) => void;
	getAccessTokenCallback?: () => string;
	displayCallback?: (...args: any | string[]) => void;
	notifyAboutUnsavedData?: (cancel: boolean) => void;
	savedCallback?: (sender?: BaseWidget) => void;
}

export interface IEditableWidget {
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	showEditButton?: boolean;
}

export interface ISaveConfirmation {
	callback: () => Promise<{ title: string; messages: string[] } | null>;
}

export interface WidgetProps extends PropsFromRedux, IEditableWidget {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	url?: string;
	loadChild?: boolean;
	widgetTitle: string;
	forceReload?: boolean;
	hidden?: boolean;

	changeDataCallback?: (hasChanges: boolean) => void;
	viewComponent: React.FC<WidgetView>;
	openAddDialogCallback?: (data: object | null) => void;
	savedCallback?: () => void;
	beforeSaveCallback?: () => boolean;
	setDashboardTitleCallback?: (title: string) => void;
	displayedCallback?: (...args: any | string[]) => void;
	getDataCallBack?: () => any;
	setDataCallback?: (value?: any) => void;
	getDataModelCallback?: () => any;
}

export interface WidgetViewDefault {
	entityId: string;
	editMode: boolean;
	validator: Validator;
	validationResult: ValidationResult;
	changeDataCallback: (data: object | null, changes?: boolean) => void;
	reloadDataCallback: () => void;
	setValidatorCallback: (validator: Validator) => Promise<void>;
	resetValidationsCallback: () => void;
	setDashboardTitleCallback?: (title: string) => void;
	displayCallback?: (...args: any | string[]) => void;
	getDataCallback?: () => any;
	setDataCallback?: (value?: any) => void;
	getDataModelCallback?: () => any;
	setUrl?: (url: string) => void;
	setShowSaveConfirmation: (confirmation: ISaveConfirmation | null) => void;
	scrollToTop?: () => void;
	dataHasChanged?: boolean;
}

export interface WidgetView extends WidgetViewDefault {
	data: any; // TODO - THIS SHOULD BE CHANGED TO A UNION TYPE
	initialData: any; // TODO - THIS SHOULD BE CHANGED TO A UNION TYPE
}

export interface CustomModel {
	name: string;
	typeArray: boolean;
}

const Widget = (props: WidgetProps) => {
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
	});

	const [localData, setLocalData] = useState({
		initialData: null as object | null | [],
		changedData: null as object | null | [],
	});

	const [popupConfirmation, setPopupConfirmation] = useState<{
		title: string;
		message?: string;
		messages?: string[];
		userResponse: (response: boolean) => void;
	} | null>();

	const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
	const dispatch = useDispatch();

	const [url, setUrl] = useState(props.url);
	useEffect(() => {
		setUrl(props.url);
	}, [props.url]);

	const [showSaveConfirmation, setShowSaveConfirmation] = useState<ISaveConfirmation | null>(null);

	useEffect(() => {
		events.loadData();
	}, [props.entityId, url]);

	useEffect(() => {
		if (props.forceReload) {
			events.loadData();
		}
	}, [props.forceReload]);

	const [formValidator, setFormValidator] = useState(null as Validator);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [formValid, setFormValid] = useState<boolean>(false);
	useEffect(() => {
		if (flags.editMode) {
			if (formValidator) {
				formValidator.validate(localData.changedData).then((result) => {
					setValidationResult(result.validationResult);
					setFormValid(result.formResult);
				});
			}
		} else {
			if (formValidator) {
				formValidator.clearValidation();
			}
			setValidationResult(null);
			setFormValid(false);
		}
	}, [localData.changedData, flags.editMode, formValidator]);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		props.changeDataCallback(flags.hasChanges);
	}, [flags.hasChanges]);

	const changeObjectToArray = (data: object) => {
		const newInitialData = [];
		const entries = Object.entries(data);
		for (const [_, value] of entries) {
			newInitialData.push(value);
		}
		return newInitialData;
	};

	const util = {
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode, hasChanges: false });
		},
		resetLocalChanges: () => {
			if (Array.isArray(localData.changedData)) {
				setLocalData({
					...localData,
					changedData: changeObjectToArray(localData.initialData),
				});
			} else {
				setLocalData({
					...localData,
					changedData: { ...localData.initialData },
				});
			}
		},
		cancelConfirmation: async () => {
			return new Promise<boolean>((resolve) => {
				setPopupConfirmation({
					title: TranslateText('common.titleUnsavedData'),
					message: TranslateText('notificationMessages.cancel'),
					userResponse: (response: boolean) => {
						resolve(response);
						setPopupConfirmation(null);
					},
				});
			});
		},
		saveConfirmation: async (title: string, messages: string[]) => {
			return new Promise<boolean>((resolve) => {
				setPopupConfirmation({
					title: title,
					messages: messages,
					userResponse: (response: boolean) => {
						resolve(response);
						setPopupConfirmation(null);
					},
				});
			});
		},
		resetValidationsCallback: () => {
			//setInvalidKeys([]);
		},
	};
	const getCustomModel = (data: any) => {
		if (props.getDataModelCallback !== undefined) {
			const model = props.getDataModelCallback() as CustomModel;
			if (model.typeArray) return { [model.name]: data as [] };
			return { [model.name]: data };
		}
		return {};
	};
	const resetUnsavedData = (): void => {
		dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
		setLocalData({ ...localData, changedData: localData.initialData });
	};

	const events = {
		loadData: () => {
			if (url) {
				ajaxUtil.get(`${url}/${props.entityId}`).then((data) => {
					setLocalData({
						changedData: data,
						initialData: data,
					});
				});
			}
			util.setEditMode(false);

			if (props.displayedCallback) {
				props.displayedCallback();
			}
			setDataHasChanged(!dataHasChanged);
		},
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.cancelConfirmation()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const newValue = !flags.editMode;
			if (!newValue && flags.hasChanges) {
				if (await util.cancelConfirmation()) {
					util.setEditMode(newValue);
					util.resetLocalChanges();
				}
			} else {
				util.setEditMode(newValue);
			}
		},
		changeDataCallback: (data: object | null | [], changes = true) => {
			setFormValid(false);
			setFlags((prevState) => {
				return { ...prevState, hasChanges: prevState.editMode && changes };
			});
			if (Array.isArray(data)) {
				setLocalData({
					...localData,
					changedData: data,
					initialData: changeObjectToArray(localData.initialData),
				});
			} else {
				setLocalData({ ...localData, changedData: data });
			}
		},
		cancelClicked: async () => {
			if (!flags.hasChanges) {
				util.setEditMode(false);
			} else if (await util.cancelConfirmation()) {
				util.setEditMode(false);
				util.resetLocalChanges();
			}
		},
		reloadDataCallback: () => {
			if (url) {
				ajaxUtil.get(`${url}/${props.entityId}`).then((data) => {
					setLocalData({
						...localData,
						initialData: data,
					});
				});
			}
		},
		saveClicked: async () => {
			const result = await formValidator.validate(localData.changedData);
			if (result.formResult) {
				if (showSaveConfirmation?.callback) {
					const confirmationMessage = await showSaveConfirmation.callback();
					if (
						confirmationMessage &&
						!(await util.saveConfirmation(confirmationMessage.title, confirmationMessage.messages))
					) {
						return;
					}
				}

				await ajaxUtil.put(
					url,
					{
						...localData.changedData,
						id: props.entityId,
						...(url.includes(GlobalSettings.customersMaintenanceApi) ? { customerId: props.entityId } : {}),
						...getCustomModel(localData.changedData),
					},
					{ callBack: resetUnsavedData }
				);

				if (props.viewComponent === CustomerInformationView) {
					dispatch(availableCustomersActions.customerChangedName(true));
					availableCustomersActions.fetch(dispatch);
				}
				if (Array.isArray(localData.initialData)) {
					setLocalData({
						...localData,
						initialData: (localData.changedData as []).slice(),
					});
				} else {
					setLocalData({
						...localData,
						initialData: { ...localData.initialData, ...localData.changedData },
					});
				}

				util.setEditMode(false);

				if (props.savedCallback) {
					props.savedCallback();
				}

				if (props.viewComponent === CustomerLiveSettingsView) {
					dispatch(userDataActionCreators.setCustomerLiveSettings(localData.changedData as LiveSettings));
				}

				if (props.viewComponent === PersonLiveSettingsView) {
					dispatch(userDataActionCreators.setPersonLiveSettings(localData.changedData as PersonLiveSettings));
				}

				if (props.viewComponent === CustomerHistorySettingsView) {
					const isForPerson = (localData.initialData as HistorySettings).isForPerson;
					if (isForPerson) {
						dispatch(
							userDataActionCreators.setPersonHistorySettings(
								localData.changedData as PersonHistorySettingsDto
							)
						);
					} else {
						dispatch(
							userDataActionCreators.setCustomerHistorySettings(
								localData.changedData as CustomerHistorySettings
							)
						);
					}
				}
			}
		},

		setValidatorCallback: async (validator: Validator) => {
			setFormValidator(validator);
			setValidationResult(null);
			setFormValid(false);
		},
	};

	const scrollElRef = useRef<HTMLDivElement>();
	const scrollToTop = useCallback(() => {
		if (scrollElRef.current) {
			scrollElRef.current.scrollTo({ top: 0 });
		}
	}, []);

	return (
		<div
			className={`e-panel widget ${flags.visible ? '' : 'hidden'}`}
			data-row={props.position.row}
			data-col={props.position.col}
			data-sizex={props.position.sizeX}
			data-sizey={props.position.sizeY}
			data-minsizex={props.position.sizeX}
			data-minsizey={props.position.sizeY}
			hidden={props.hidden}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>
				{popupConfirmation ? (
					<NotificationPrompt
						title={popupConfirmation.title}
						message={popupConfirmation.message}
						messages={popupConfirmation.messages}
						handleUserResponse={popupConfirmation.userResponse}
					/>
				) : null}
				<div ref={scrollElRef} className={'widget-view'}>
					{localData.changedData || props.loadChild ? ( //Only render if the data is loaded from the server
						<props.viewComponent
							editMode={flags.editMode}
							changeDataCallback={events.changeDataCallback}
							validator={formValidator}
							initialData={localData.initialData}
							data={localData.changedData}
							entityId={props.entityId}
							reloadDataCallback={events.reloadDataCallback}
							validationResult={validationResult}
							setDashboardTitleCallback={props.setDashboardTitleCallback}
							resetValidationsCallback={() => util.resetValidationsCallback()}
							setValidatorCallback={events.setValidatorCallback}
							getDataCallback={props.getDataCallBack}
							setDataCallback={props.setDataCallback}
							getDataModelCallback={props.getDataModelCallback}
							setUrl={setUrl}
							setShowSaveConfirmation={setShowSaveConfirmation}
							scrollToTop={scrollToTop}
							dataHasChanged={dataHasChanged}
						/>
					) : null}
				</div>
				{flags.editMode ? (
					<div className="buttons-host">
						<Button className="widget-button cancel-button" onClick={events.cancelClicked}>
							{TranslateText('common.buttonCancel')}
						</Button>
						<DebouncedButton
							className="widget-button save-button"
							onClick={() => {
								events.saveClicked();
							}}
							disabled={!formValid}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.buttonSave')}
						</DebouncedButton>
					</div>
				) : null}
			</div>
		</div>
	);
};

Widget.defaultProps = {
	showEditButton: true,
};

export default connector(Widget);
