import './RadioButtonContainer.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import FilterEntityType from '../../models/FilterEntityType';

export interface ButtonConfig {
	id: number;
	title: string;
}

interface Props {
	visible: boolean;
	buttons: ButtonConfig[];
	selectedButton: number;
	buttonSelectCallback: (id: number) => void;
}

const RadioButtonContainer = (props: Props) => {
	return (
		<div className="radio-button-container">
			{props.visible
				? props.buttons.map((b, index) => {
						const active = props.selectedButton === b.id || (!props.selectedButton && index === 0);
						return (
							<Button
								key={b.id}
								className={active ? 'btn-fleet-type selected' : 'btn-fleet-type'}
								onClick={() => {
									props.buttonSelectCallback(b.id);
								}}
							>
								{b.title}
							</Button>
						);
				  })
				: null}
		</div>
	);
};
RadioButtonContainer.defaultProps = {
	selectedButton: FilterEntityType.All,
	visible: true,
};

export default RadioButtonContainer;
