import { Dispatch } from 'redux';

import { LocationCategory } from '../components/Widgets/Views/CustomerLocationCategoriesView';
import { RowData } from '../models/LiveMenuUtils';
import { MapOptions } from '../models/MapOptions';
import { ProcessedTripDto } from './HistoryStore';

const ADD_DIRECTIONS_TO_MAP = 'ADD_DIRECTIONS_TO_MAP';
const REMOVE_DIRECTIONS_FROM_MAP = 'REMOVE_DIRECTIONS_FROM_MAP';
export const TOGGLE_TRIP_DETAILS = 'TOGGLE_TRIP_DETAILS';
export const SET_MAP_LOADING = 'SET_MAP_LOADING';
export const SET_MAP_SELECTED_ENTITY = 'SET_MAP_SELECTED_ENTITY';
export const SET_FOCUSED_ENTITY_ID = 'SET_FOCUSED_ENTITY_ID';
export const TOGGLE_FOCUSED_ENTITY_POINT = 'TOGGLE_FOCUSED_ENTITY_POINT';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';
export const SET_LIVE_MAP_OPTIONS = 'SET_LIVE_MAP_OPTIONS';
export const SET_PIN_MARKER = 'SET_PIN_MARKER';
export const SET_ROUTE_LINES = 'SET_ROUTE_LINES';
export const SET_SELECTED_LOCATION_CATEGORIES_LIVE = 'SET_SELECTED_LOCATION_CATEGORIES_LIVE';
export const SET_LOCATION_CATEGORIES = 'SET_LOCATION_CATEGORIES';

export interface LatLng {
	lat: number;
	lng: number;
}

export interface LiveMapState {
	tripDetails: RowData;
	directions?: any;
	loading: boolean;
	selectedEntityId?: string;
	focusedEntityPoint: LatLng | null;
	focusedEntityId?: string;
	liveMapOptions: MapOptions;
	pinMarker: LatLng | null;
	mapCategories: LocationCategory[];
	liveSelectedCategories: LocationCategory[];
	showCategoriesLive: boolean;
	routeLines: { lines: LatLng[][]; bounds: { sw: LatLng; ne: LatLng } };
}

const initialState: LiveMapState = {
	tripDetails: {} as RowData,
	loading: false,
	focusedEntityPoint: null,
	liveMapOptions: {
		mapBounds: null,
		keepInView: true,
		mapType: 'roadmap',
		showTraffic: { showTraffic: true, firstRender: true },
		streetView: null,
		showCategories: false,
		showTemplates: false,
	},
	mapCategories: [],
	liveSelectedCategories: [],
	showCategoriesLive: false,
	pinMarker: null,
	routeLines: null,
};

export interface LiveMapActionSignature {
	toggleTripDetails: (tripDetails: any) => void;
	setHistoryTrips: (trips: ProcessedTripDto[]) => void;
}

export const liveMapActionCreators = {
	toggleTripDetails: (tripDetails: RowData) => (dispatch: any) => {
		dispatch({
			type: TOGGLE_TRIP_DETAILS,
			payload: tripDetails,
		});
	},
	toggleFocusedEntity: (entityId: string, point: LatLng | null) => (dispatch: any) => {
		dispatch({
			type: TOGGLE_FOCUSED_ENTITY_POINT,
			payload: { entityId, point },
		});
	},
	setSelectedCategoriesLive: (locationCategories: LocationCategory[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_SELECTED_LOCATION_CATEGORIES_LIVE,
			payload: locationCategories,
		});
	},
	setMapCategories: (locationCategories: LocationCategory[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_LOCATION_CATEGORIES,
			payload: locationCategories.sort((a, b) => (a.name > b.name ? 1 : -1)),
		});
	},
	setPinMarker: (point: LatLng | null) => (dispatch: any) => {
		dispatch({
			type: SET_PIN_MARKER,
			payload: { pinMarker: point, point: point },
		});
	},
	setRouteLinesWithEntity: (
		routeLines: { lines: LatLng[][]; bounds: { sw: LatLng; ne: LatLng } },
		selectedEntityId?: string
	) => (dispatch: any) => {
		dispatch({
			type: SET_ROUTE_LINES,
			payload: routeLines,
		});
		dispatch({
			type: SET_MAP_SELECTED_ENTITY,
			payload: selectedEntityId,
		});
	},
	setRouteLines: (routeLines: { lines: LatLng[][]; bounds: { sw: LatLng; ne: LatLng } }) => (dispatch: any) => {
		dispatch({
			type: SET_ROUTE_LINES,
			payload: routeLines,
		});
	},
};

export const liveMapReducer = (state: LiveMapState, action: any) => {
	state = state || initialState;
	let directionsCopy;
	switch (action.type) {
		case ADD_DIRECTIONS_TO_MAP:
			directionsCopy = state.directions.slice();
			directionsCopy.push(action.directions);
			return {
				...state,
				directions: directionsCopy,
			};
		case REMOVE_DIRECTIONS_FROM_MAP:
			directionsCopy = state.directions.slice().filter((d: any) => d.id != action.id);
			return {
				...state,
				directions: directionsCopy,
			};
		case TOGGLE_TRIP_DETAILS:
			return {
				...state,
				tripDetails: action.payload,
			};
		case SET_MAP_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_MAP_SELECTED_ENTITY: {
			return {
				...state,
				selectedEntityId: action.payload,
			};
		}
		case SET_SELECTED_LOCATION_CATEGORIES_LIVE:
			return {
				...state,
				liveSelectedCategories: action.payload,
			};
		case SET_LOCATION_CATEGORIES:
			return {
				...state,
				mapCategories: action.payload,
			};
		case SET_FOCUSED_ENTITY_ID: {
			return {
				...state,
				focusedEntityId: action.payload,
			};
		}

		case SET_LIVE_MAP_OPTIONS: {
			return {
				...state,
				liveMapOptions: action.payload,
			};
		}
		case TOGGLE_FOCUSED_ENTITY_POINT: {
			return {
				...state,
				selectedEntityId: action.payload.entityId,
				focusedEntityPoint: action.payload.point,
			};
		}
		case SET_PIN_MARKER: {
			return {
				...state,
				pinMarker: action.payload.point,
				focusedEntityPoint: action.payload.point,
			};
		}
		case SET_ROUTE_LINES: {
			return {
				...state,
				routeLines: action.payload,
			};
		}
		default:
			return state;
	}
};
