import './styles.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import React from 'react';

import { Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import { noop } from 'Constants';

const PersonObject = (props: {
	name: string;
	person: boolean;
	iconContent?: string;
	objectNameWithTimeZone: string;
}) => {
	return (
		<Box component="span" className="person-object-container">
			{props.person ? (
				<PersonIcon />
			) : (
				<SvgIcon icon={props.iconContent} color="#212529" size={20} onClick={(icon: string) => noop} />
			)}
			{props.person ? (
				<Box component="span" className="person-object-name">
					{props.name}
				</Box>
			) : (
				<Box component="span" className="person-object-name">
					<ObjectNameWithTimeZoneOffset
						objectNameWithTimeZone={props.objectNameWithTimeZone}
						useToolTip={false}
					/>
				</Box>
			)}
		</Box>
	);
};
export default PersonObject;
