import './customerLiveSettings.scss';

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import LocationOnMapEnum from 'models/LocationOnMapEnum';
import { ApplicationState } from 'store';
import EntityGroupingEnum from '../../models/EntityGroupingEnum';
import ShowEntityIconEnum from '../../models/ShowEntityIconEnum';
import Validator from '../../shared/validation/Validator';
import { TranslateText } from '../../utils/Translations';
import { WidgetViewDefault } from './Widget';
import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import YesNoCombobox from './components/YesNoCombobox';

type Props = WidgetViewDefault & {
	data: PersonLiveSettings;
};

export interface PersonLiveSettings {
	showLabels: boolean;
	trafficInformation: boolean;
	entityGrouping: EntityGroupingEnum;
	showEntityIcon: ShowEntityIconEnum;
	useLiveLocationFields: boolean;
	useLiveLocationOnMap: LocationOnMapEnum;
}

type ChangeValue = EntityGroupingEnum | ShowEntityIconEnum | LocationOnMapEnum | boolean;

type ChangeKey =
	| 'showLabels'
	| 'trafficInformation'
	| 'entityGrouping'
	| 'showEntityIcon'
	| 'useLiveLocationFields'
	| 'useLiveLocationOnMap';

export const getFieldsDatasources = () => {
	return {
		entityGrouping: [
			{ id: null, display: TranslateText('common.default') },
			{ id: 0, display: TranslateText('customerOverview.grouping.noGrouping') },
			{ id: 1, display: TranslateText('customerOverview.grouping.circle') },
		],
		showEntityIcon: [
			{ id: null, display: TranslateText('common.default') },
			{ id: 0, display: TranslateText('customerOverview.entityIcon.dot') },
			{ id: 1, display: TranslateText('customerOverview.entityIcon.icon') },
		],
		useLiveLocationOnMap: [
			{ id: null, display: TranslateText('common.default') },
			{ id: 0, display: TranslateText('customerOverview.locationOnMap.dontShow') },
			{ id: 1, display: TranslateText('customerOverview.locationOnMap.icon') },
			{ id: 2, display: TranslateText('customerOverview.locationOnMap.iconLabel') },
		],
	};
};

const PersonLiveSettingsView = (props: Props) => {
	const [fieldsDataSources] = useState(getFieldsDatasources);
	const customerLocationsFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.locations
			: s.currentSession.customer?.featuresSettings.locations
	);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: null }));
	}, [props.entityId]);

	useEffect(() => {
		if (!customerLocationsFeature) {
			const newState = { ...props.data };
			newState.useLiveLocationFields = true;
			newState.useLiveLocationOnMap = LocationOnMapEnum.Icon;
			props.changeDataCallback(newState);
		}
	}, [customerLocationsFeature]);

	const events = {
		onValueChanged: async (value: ChangeValue, key: ChangeKey) => {
			const newState = { ...props.data };
			(newState[key] as ChangeValue) = value;

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper live-form'}>
			<YesNoCombobox
				name={'showLabels'}
				value={props.data.showLabels}
				placeholder={TranslateText('fields.showLabels')}
				change={(value) => events.onValueChanged(value as boolean | null, 'showLabels')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
				showDefaultTrueFalse
			/>
			<YesNoCombobox
				name={'trafficInformation'}
				value={props.data.trafficInformation}
				placeholder={TranslateText('fields.trafficInformation')}
				change={(value) => events.onValueChanged(value as boolean | null, 'trafficInformation')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
				showDefaultTrueFalse
			/>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.entityGrouping}
					dataSource={fieldsDataSources.entityGrouping}
					name="entityGrouping"
					disabled={!props.editMode}
					label={TranslateText('fields.entityGrouping')}
					onChange={({ value }) => events.onValueChanged(value as EntityGroupingEnum, 'entityGrouping')}
					disableClearable
				/>
				<div className="e-text">{TranslateText('fields.groupingExample')}</div>
				<div
					id="noGrouping"
					className={
						props.data.entityGrouping === EntityGroupingEnum.NoGrouping ? 'no-grouping' : 'circle-grouping'
					}
				/>
			</div>
			{/* hidden based on https://dev.azure.com/RietveldTruckRendementService/EasyTrack2.0/_workitems/edit/26861
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.showEntityIcon}
					dataSource={fieldsDataSources.showEntityIcon}
					name="showEntityIcon"
					disabled={!props.editMode}
					label={TranslateText('fields.showEntityIcon')}
					onChange={({ value }) => events.onValueChanged(value as ShowEntityIconEnum, 'showEntityIcon')}
					disableClearable
				/>
			</div>
			*/}
			{customerLocationsFeature ? (
				<>
					<YesNoCombobox
						name={'useLiveLocationFields'}
						value={props.data.useLiveLocationFields}
						placeholder={TranslateText('fields.useLocationFields')}
						change={(value) => events.onValueChanged(value as boolean | null, 'useLiveLocationFields')}
						readonly={!props.editMode}
						validationResult={props.validationResult}
						showDefaultTrueFalse
					/>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data.useLiveLocationOnMap}
							dataSource={fieldsDataSources.useLiveLocationOnMap}
							name="useLiveLocationOnMap"
							disabled={!props.editMode}
							label={TranslateText('fields.useLocationOnMap')}
							onChange={({ value }) =>
								events.onValueChanged(value as LocationOnMapEnum, 'useLiveLocationOnMap')
							}
							disableClearable
						/>
					</div>
				</>
			) : null}
		</div>
	);
};

export default PersonLiveSettingsView;
