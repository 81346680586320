import './locationInlineView.scss';

import { DebouncedButton } from 'components/Common/DebouncedButton';
import { PolygonDefinition } from 'components/LocationsAreaMap/types';
import { LocationAreaTypeEnum } from 'components/LocationsOverview/LocationsOverview';
import AddressView from 'components/NewAddWizard/Views/Address/AddressView';
import LocationView from 'components/NewAddWizard/Views/Location/LocationView';
import { DEBOUNCE_TIME } from 'Constants';
import * as GlobalSettings from 'GlobalSettings.json';
import Location from 'models/Location';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { ApplicationState } from 'store';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, CircularProgress } from '@material-ui/core';

interface Props {
	onChangeCallback: (data: object) => void;
	selectedEntity: object;
	selectedEntityId: string;
	entityData: object;
	saveCallback: (entityData: object) => void;
	clearSelectedEntity: (force?: boolean) => void;
	isReadonly: boolean;
	inlineLoading: boolean;
	setInlineLoading: (isLoading: boolean) => void;
}

const LocationInlineView = (props: Props) => {
	const dispatch = useDispatch();

	const [disabled, setDisabled] = useState<boolean>(false);
	const [isLocationValid, setIsLocationValid] = useState<boolean>(true);
	const [isAddressValid, setIsAddressValid] = useState<boolean>(true);
	const [editedEntity, setEditedEntity] = useState({ ...props.selectedEntity });
	const [unsavedDataState, setUnsavedDataState] = useState(false);

	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);

	const [initialPolygon, setInitialPolygon] = useState<PolygonDefinition | null>(() => {
		const location = props.selectedEntity as Location;
		if (
			location?.areaType === LocationAreaTypeEnum.Polygon &&
			location.polygonPath &&
			location.polygonPath.length >= 3 &&
			location.latitude &&
			location.longitude
		) {
			return {
				center: { lat: location.latitude, lng: location.longitude },
				path: location.polygonPath,
			};
		}
		return null;
	});
	const onChangeCallback = useCallback(
		(data: any) => {
			setEditedEntity((prevState) => ({ ...prevState, ...data }));
		},
		[props.entityData]
	);

	const setUnsavedDataCallback = (data: boolean) => {
		setUnsavedDataState(data);
	};

	useEffect(() => {
		if (unsavedDataState) {
			props.onChangeCallback(editedEntity);
			setUnsavedDataState(false);
		}
	}, [editedEntity]);

	const saveLocation = () => {
		setDisabled(true);
		props.setInlineLoading(true);
		ajaxUtil
			.put(`${GlobalSettings.locationsMaintenanceApi}/updateLocation`, {
				...editedEntity,
			})
			.then((result) => {
				setDisabled(false);
				props.setInlineLoading(false);

				if (result) {
					dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
					ajaxUtil
						.get<Location>(`${GlobalSettings.locationsMaintenanceApi}/${props.selectedEntityId}`)
						.then((location) => {
							props.saveCallback(location);
							if (location.areaType === LocationAreaTypeEnum.Polygon) {
								setInitialPolygon({
									center: { lat: location.latitude, lng: location.longitude },
									path: location.polygonPath,
								});
							} else {
								setInitialPolygon(null);
							}
						});
				}
			})
			.catch(() => {
				setDisabled(false);
				props.setInlineLoading(false);
			});
	};

	const saveButtonDisabled = disabled || !isLocationValid || !isAddressValid || !unsavedData;

	return (
		<ReactScrollIntoViewIfNeeded active={Object.keys(props.entityData).length > 0}>
			{props.inlineLoading ? <CircularProgress className="location-inline-loading" /> : null}

			<div style={props.inlineLoading ? { opacity: 0.2 } : {}} className={'view-container'}>
				<div className={'location-inline-view-container'}>
					<div className={'location-container'}>
						<LocationView
							setValidationCallback={(isValid) => setIsLocationValid(isValid)}
							onChangeCallback={onChangeCallback}
							visible={true}
							location={props.selectedEntity as Location}
							readOnly={props.isReadonly}
							setUnsavedDataCallback={setUnsavedDataCallback}
							isForDialog={false}
						/>
					</div>
					<div className={'address-container'}>
						<AddressView
							setValidationCallback={(isValid) => setIsAddressValid(isValid)}
							onChangeCallback={onChangeCallback}
							visible={true}
							location={props.selectedEntity as Location}
							initialPolygon={initialPolygon}
							readOnly={props.isReadonly}
							setUnsavedDataCallback={setUnsavedDataCallback}
							isForDialog={false}
						/>
					</div>
				</div>
				{!props.isReadonly && (
					<div className={'footer right-container'}>
						<Button
							style={{ marginRight: 35 }}
							className={'button cancel-button'}
							onClick={() => props.clearSelectedEntity()}
						>
							{TranslateText('common.buttonCancel')}
						</Button>
						<DebouncedButton
							className={'button save-button'}
							disabled={saveButtonDisabled}
							onClick={saveLocation}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.buttonSave')}
						</DebouncedButton>
					</div>
				)}
			</div>
		</ReactScrollIntoViewIfNeeded>
	);
};

export default LocationInlineView;
