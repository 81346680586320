import './styles.scss'
import MaterialAutocomplete, {AutocompleteItem} from "../../../../Common/Autocomplete/MaterialAutocomplete";
import {TranslateText} from "../../../../../utils/Translations";
import {ValidationMessage} from "../../../../ValidationMessage/ValidationMessage";
import TowingEventTypesEnum from "../../../../../models/TowingEventTypesEnum";
import React from "react";
import {Divider, Grid } from "@material-ui/core";
import {TowingAlertDataProps} from "./types";

const TowingAlertData = (props: TowingAlertDataProps) => {
	const getTowingEventTypeDataSource = (): AutocompleteItem[] => {
		return [
			{
				display: TranslateText('towingEventTypes.both'),
				id: TowingEventTypesEnum.Both,
			},
			{
				display: TranslateText('towingEventTypes.start'),
				id: TowingEventTypesEnum.Start,
			},
			{
				display: TranslateText('towingEventTypes.stop'),
				id: TowingEventTypesEnum.Stop,
			},
		];
	};
	const onTowingTriggerEventChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		const newTriggerEvent = arg.value as TowingEventTypesEnum
		const newTowingAlertMetadata = {
			...props.towingAlertMetadata,
			towingTriggerEvent: newTriggerEvent,
		};

		props.handleTowingAlertMetadataChange(newTowingAlertMetadata);
	};

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('alert.towingState')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'towingTriggerEvent'}
					className="towing-alert-event-autocomplete"
					valueId={props.towingAlertMetadata.towingTriggerEvent}
					dataSource={getTowingEventTypeDataSource()}
					onChange={onTowingTriggerEventChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
					disabledItems
				/>
				{props.validationResult?.validationResult?.towingTriggerEvent && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.towingTriggerEvent} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

export default TowingAlertData;
