import './PersonLiveSettings.scss';

import {
	Button,
	Card,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { getConstants } from 'components/Widgets/CustomerLiveSettingsView';
import GlobalSettings from 'GlobalSettings.json';
import EntityGroupingEnum from 'models/EntityGroupingEnum';
import LocationOnMapEnum from 'models/LocationOnMapEnum';
import ShowEntityIconEnum from 'models/ShowEntityIconEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldRules, ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

interface PersonLiveSettingsDto {
	personId: string;
	trafficInformation: boolean;
	entityGrouping: EntityGroupingEnum;
	showEntityIcon: ShowEntityIconEnum;
	useLiveLocationFields: boolean;
	useLiveLocationOnMap: LocationOnMapEnum;
	showLabels: boolean;
}

type ChangeValue = EntityGroupingEnum | ShowEntityIconEnum | LocationOnMapEnum | boolean;

const GetPersonLiveSettingsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		trafficInformation: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		entityGrouping: {
			rules: { required: ValidatorFunctions.required() },
		},
		showEntityIcon: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
	};

	return fieldRules;
};

const areTheSame = (initialData: PersonLiveSettingsDto, data: PersonLiveSettingsDto) => {
	if (JSON.stringify(initialData) === JSON.stringify(data)) {
		return true;
	}
	return false;
};

const PersonLiveSettings = (props: Props) => {
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const customerLocationsFeature = useSelector(
		(s: ApplicationState) => s.currentSession.customer?.featuresSettings.locations
	);
	const [initialData, setInitialData] = useState<PersonLiveSettingsDto>(null);
	const [data, setData] = useState<PersonLiveSettingsDto>(null);
	const [isValid, setIsValid] = useState<boolean>(true);
	const [constants] = useState({
		...getConstants(),
		yesNoDefaultDataSource: [
			{ id: null, display: TranslateText('common.default') },
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
		useLiveLocationOnMap: [
			{ id: null, display: TranslateText('common.default') },
			{ id: 0, display: TranslateText('customerOverview.locationOnMap.dontShow') },
			{ id: 1, display: TranslateText('customerOverview.locationOnMap.icon') },
			{ id: 2, display: TranslateText('customerOverview.locationOnMap.iconLabel') },
		],
	});
	const [isChanged, setIsChanged] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [validator] = useState(new Validator({ fieldRules: GetPersonLiveSettingsValidationConfig() }, true));
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);

	const events = {
		onValueChanged: async (value: ChangeValue, key: keyof PersonLiveSettingsDto) => {
			const newData = { ...data };
			(newData[key] as string | boolean | number) = value;
			setData(newData);
			if (areTheSame(initialData, newData)) {
				setIsChanged(false);
			} else if (!isChanged) {
				setIsChanged(true);
			}
		},
	};

	useEffect(() => {
		const url = `${GlobalSettings.profileMaintenanceApi}/liveSettings/${personId}`;
		ajaxUtil.get<PersonLiveSettingsDto>(url).then((data: PersonLiveSettingsDto) => {
			setData(data);
			setInitialData(data);

			validator
				.validate({
					trafficInformation: data?.trafficInformation,
					entityGrouping: data?.entityGrouping,
					showEntityIcon: data?.showEntityIcon,
				})
				.then((result) => {
					setValidationResult(result.validationResult);
					setIsValid(result.formResult && isChanged);
				});

			setIsLoaded(true);
		});
	}, []);

	useEffect(() => {
		if (!props.unsavedData && isChanged) {
			props.setUnsavedData(true);
			window.onbeforeunload = () => {
				return 'Unsaved data';
			};
		} else if (!isChanged && props.unsavedData) {
			props.setUnsavedData(false);
			window.onbeforeunload = null;
		}

		validator
			.validate({
				trafficInformation: data?.trafficInformation,
				entityGrouping: data?.entityGrouping,
				showEntityIcon: data?.showEntityIcon,
			})
			.then((result) => {
				setValidationResult(result.validationResult);
				setIsValid(result.formResult && isChanged);
			});
	}, [data]);

	const saveLiveSettings = () => {
		const url = `${GlobalSettings.profileMaintenanceApi}/liveSettings/`;
		ajaxUtil.put(url, data).then(() => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
		});
	};

	return (
		isLoaded && (
			<Grid item md={12} style={{ marginTop: '50px' }} className={'person-live-settings-grid'}>
				<Card>
					<CardContent>
						<div>
							<Typography variant="h5" className={'title'}>
								{TranslateText('personSettingsWindow.liveSettings')}
							</Typography>
							<div className={'traffic-information-div'}>
								<div className={'traffic-information-container'}>
									<div className={'entity-notation-settings'}>
										<Typography variant="h6" className={'traffic-information-title'}>
											{TranslateText('personLiveSettings.trafficInformation')}
										</Typography>
										<span className={'notation-description traffic-info'}>
											{TranslateText('personLiveSettings.trafficInformationDescription')}
										</span>
									</div>
									<div className="toggle">
										{data?.trafficInformation ? (
											<ToggleOn
												style={{ color: 'green' }}
												className={'toggle-icon'}
												onClick={() => events.onValueChanged(false, 'trafficInformation')}
											/>
										) : (
											<ToggleOff
												className={'toggle-icon'}
												onClick={() => events.onValueChanged(true, 'trafficInformation')}
											/>
										)}
										<ValidationMessage result={validationResult?.trafficInformation} />
									</div>
								</div>
								<Divider className={'first-divider'} />
								<div className={'entity-notation-container'}>
									<div className={'entity-notation-settings'}>
										<Typography variant="h6" className={'entity-grouping-title'}>
											{TranslateText('personLiveSettings.entityGrouping')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personLiveSettings.entityGroupingDescription')}
										</span>
									</div>
									<div style={{ marginTop: '15px' }}>
										<FormControl component="fieldset" className={'justify-content-center'}>
											<RadioGroup row aria-label="position" name="position">
												<FormControlLabel
													value={data?.entityGrouping}
													control={<Radio size="small" color="default" />}
													label={TranslateText('personLiveSettings.noGrouping')}
													labelPlacement="top"
													checked={data?.entityGrouping === EntityGroupingEnum.NoGrouping}
													className={'radio-option no-grouping'}
													onChange={() =>
														events.onValueChanged(
															EntityGroupingEnum.NoGrouping,
															'entityGrouping'
														)
													}
												/>
												<FormControlLabel
													value={data?.entityGrouping}
													control={<Radio size="small" color="default" />}
													label={
														<span className={'radio-circle-label'}>
															{TranslateText('personLiveSettings.constantForCircle')}
														</span>
													}
													labelPlacement="top"
													checked={data?.entityGrouping === EntityGroupingEnum.Circle}
													className={'radio-option'}
													onChange={() =>
														events.onValueChanged(
															EntityGroupingEnum.Circle,
															'entityGrouping'
														)
													}
												/>
											</RadioGroup>
										</FormControl>
										<ValidationMessage result={validationResult?.entityGrouping} />
									</div>
								</div>
								<Divider className={'middle-divider'} />
								{/* hidden based on https://dev.azure.com/RietveldTruckRendementService/EasyTrack2.0/_workitems/edit/26861
								<div className={'entity-notation-container'}>
									<div className={'entity-notation-settings'}>
										<Typography variant="h6" className={'entity-icon-title'}>
											{TranslateText('personLiveSettings.entityIcon')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personLiveSettings.entityIconDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										<MaterialAutocomplete
											valueId={data?.showEntityIcon}
											dataSource={constants.showEntityIcon}
											name="timeFormat"
											onChange={({ value }) => events.onValueChanged(value, 'showEntityIcon')}
											className={'autocomplete-option'}
											disableClearable
										/>
										<ValidationMessage result={validationResult?.showEntityIcon} />
									</div>
								</div>
								<Divider className={'middle-divider'} />
								*/}
								<div className={'setting-container'}>
									<div className={'setting-title-container'}>
										<Typography variant="h6" className={'setting-title'}>
											{TranslateText('fields.showLabels')}
										</Typography>
										<span className={'setting-description'}>
											{TranslateText('personLiveSettings.showLabelsDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										<MaterialAutocomplete
											valueId={data.showLabels}
											dataSource={constants.yesNoDefaultDataSource}
											name="showLabels"
											onChange={({ value }) =>
												events.onValueChanged(value as boolean, 'showLabels')
											}
											className={'autocomplete-option'}
											disableClearable
										/>
									</div>
								</div>
								<Divider className={'middle-divider'} />
								{customerLocationsFeature ? (
									<>
										<div className={'setting-container'}>
											<div className={'setting-title-container'}>
												<Typography variant="h6" className={'setting-title'}>
													{TranslateText('fields.useLocationFields')}
												</Typography>
												<span className={'setting-description'}>
													{TranslateText(
														'personHistorySettings.useLocationFieldsDescription'
													)}
												</span>
											</div>
											<div className="form-group form-group-settings">
												<MaterialAutocomplete
													valueId={data.useLiveLocationFields}
													dataSource={constants.yesNoDefaultDataSource}
													name="useLiveLocationFields"
													onChange={({ value }) =>
														events.onValueChanged(value as boolean, 'useLiveLocationFields')
													}
													className={'autocomplete-option'}
													disableClearable
												/>
											</div>
										</div>
										<Divider className={'middle-divider'} />
										<div className={'setting-container'}>
											<div className={'setting-title-container'}>
												<Typography variant="h6" className={'setting-title'}>
													{TranslateText('fields.useLocationOnMap')}
												</Typography>
												<span className={'setting-description'}>
													{TranslateText('personHistorySettings.useLocationOnMapDescription')}
												</span>
											</div>
											<div className="form-group form-group-settings">
												<MaterialAutocomplete
													valueId={data.useLiveLocationOnMap}
													dataSource={constants.useLiveLocationOnMap}
													name="useLiveLocationOnMap"
													onChange={({ value }) =>
														events.onValueChanged(
															value as LocationOnMapEnum,
															'useLiveLocationOnMap'
														)
													}
													className={'autocomplete-option'}
													disableClearable
												/>
											</div>
										</div>
										<Divider className={'middle-divider'} />
									</>
								) : null}
							</div>
							<Button
								style={{
									backgroundColor: !isValid ? '#999999' : '#006837',
								}}
								className={'save-button'}
								disabled={!isValid}
								onClick={saveLiveSettings}
							>
								{TranslateText('common.buttonSave')}
							</Button>
							{isValid && (
								<span className={'info-text'}>
									{TranslateText('personSettingsWindow.changedAppliedAfterLogin')}
								</span>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		)
	);
};

export default PersonLiveSettings;
