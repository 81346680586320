import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';

type IdentityRole = {
	active: boolean;
	filterText: string;
	name: React.ReactNode;
	id: string;
	systemRole: boolean;
	generic: boolean;
	customerId: string;
	roleScope: CustomerLevelEnum;
};

const PersonRolesView = (props: IButtonWidgetView) => {
	const customerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);
	const customerId = useSelector((state: ApplicationState) => state.currentSession.customerId);
	const impersonatedCustomerId = useSelector((state: ApplicationState) => state.globalCustomer?.filteredCustomer?.id);
	const terminateConnection = useCallback(
		async (roleId: string) => {
			if (roleId) {
				await ajaxUtil.delete(
					`${GlobalSettings.personsMaintenanceApi}/${props.entityId}/terminatePersonRoleConnection/${roleId}`
				);
				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);
	return (
		<DataTable
			value={props.data as IdentityRole[]}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				header={TranslateText('widgets.grid.colName')}
				className="widget-grid-column"
				body={(rowData: IdentityRole) =>
					props.editMode ||
					rowData.systemRole ||
					(rowData.roleScope < customerLevel &&
						customerId !== rowData.customerId &&
						impersonatedCustomerId !== rowData.customerId) ? (
						<span title={rowData.filterText} className={rowData.active ? '' : 'inactiveEntity'}>
							{rowData.name}
						</span>
					) : (
						<Link
							title={rowData.filterText}
							className={rowData.active ? '' : ' inactiveEntity'}
							to={`${GlobalSettings.route.roles}/${rowData.id}`}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: IdentityRole) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData.id)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default PersonRolesView;
