import '../TreeSelectionDialog.scss';

import SelectionUtil from 'components/SelectionDialog/SelectionUtil';
import TreeSelectionDialogContent from 'components/TreeSelectionDialogContent/TreeSelectionDialogContent';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Typography } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../Constants';
import { ApplicationState } from '../../../store';
import { FleetEntityTypeFilter } from '../../../store/FleetSelection';
import { TranslateText } from '../../../utils/Translations';
import { ITreeSelectionUtil } from '../../../utils/TreeSelection/ITreeSelectionUtil';
import { DebouncedButton } from '../../Common/DebouncedButton';
import RadioButtonContainer, { ButtonConfig } from '../../RadioButtonContainer';
import { ITreeNode } from '../../SelectionTree/TreeNode/types';

export interface SelectionFilter {
	customerId: string;
	showInactive: boolean;
	filterText: string;
	selectedFilter: FleetEntityTypeFilter;
}

interface Props {
	visible: boolean;
	initialSelection: ITreeNode[];
	filterButtons?: ButtonConfig[];
	title?: string;
	filterPlaceholder?: string;
	selectionUtil?: ITreeSelectionUtil;
	displayInactive?: boolean;
	showInactive: boolean;
	closeCallback: () => void;
	saveCallback: (data: ITreeNode[]) => void;
	retrieveData?: (filter: SelectionFilter) => Promise<ITreeNode[]>;
	clearOnCheckInactive?: boolean;
}

const getSelectedFilter = (filterButtons?: ButtonConfig[]) => {
	if (filterButtons.some((x) => x.id === FleetEntityTypeFilter.all)) {
		return FleetEntityTypeFilter.all;
	}

	if (filterButtons && filterButtons.length > 0) {
		return filterButtons[0].id;
	}

	return null;
};

const TreeSelectionGroupDialog = (props: Props) => {
	const customerId = useSelector((state: ApplicationState) =>
		state.loadedEntityContext?.entityContextData?.customerId
			? state.loadedEntityContext?.entityContextData?.customerId
			: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);

	const [localShowInactive, setLocalShowInactive] = useState(props.showInactive);

	const [selectedFilter, setSelectedFilter] = useState<FleetEntityTypeFilter | null>(() =>
		getSelectedFilter(props.filterButtons)
	);
	useEffect(() => {
		setSelectedFilter(getSelectedFilter(props.filterButtons));
	}, [props.filterButtons]);

	const [filterText, setFilterText] = useState('');
	const [treeData, setTreeData] = useState([] as ITreeNode[]);
	const [selectedItems, setSelectedItems] = useState([...props.initialSelection]);
	const [expanded, setExpanded] = useState([] as string[]);
	const [previousFilterText, setPreviousFilterText] = useState('');

	useEffect(() => {
		const retrieveData = async () => {
			const filter: SelectionFilter = {
				customerId: customerId,
				showInactive: localShowInactive,
				filterText: filterText.trim(),
				selectedFilter: selectedFilter,
			};

			try {
				//reset tree data
				setTreeData([]);

				const data: ITreeNode[] = await props.retrieveData(filter);

				props.selectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
				updateExpandedState(filterText, data);

				if (localShowInactive) {
					const tempSelectedItems: ITreeNode[] = _.cloneDeep(selectedItems);

					tempSelectedItems.forEach((tempSelectedItem: ITreeNode) => {
						if (tempSelectedItem.type !== 'Group') {
							return;
						}

						const retrievedGroup = data.find((t: ITreeNode) => t.id === tempSelectedItem.id);
						if (retrievedGroup) {
							tempSelectedItem.items = [...retrievedGroup.items];
						}
					});
					setSelectedItems([...tempSelectedItems]);
				}
			} catch (err) {
				console.error(err);
				setTreeData([]);
			}
		};

		if (!props.visible) {
			return;
		}

		retrieveData();
	}, [props.visible, filterText, localShowInactive, selectedFilter]);

	const updateExpandedState = (filterText: string, data: ITreeNode[]) => {
		if (filterText && filterText.trim().length > 0) {
			setExpanded(data.map((d) => d.id));
		} else if (!filterText || previousFilterText !== filterText) {
			setExpanded([]);
		}
		setPreviousFilterText(filterText?.trim());
	};

	useEffect(() => {
		//reset filters when closing dialog
		if (!props.visible) {
			setFilterText('');
			setExpanded([]);
		}
	}, [props.visible]);

	useEffect(() => {
		if (props.visible) {
			//Update tree when selection changes
			props.selectionUtil.prepareTreeData(treeData, selectedItems, expanded, setTreeData);
		}
	}, [props.visible, selectedItems, expanded]);

	useEffect(() => {
		//Set tree data for initial selection, or re-opening the dialog
		setSelectedItems(props.initialSelection);
	}, [props.initialSelection]);

	const saveClicked = useCallback(() => {
		props.saveCallback(selectedItems);
	}, [selectedItems]);

	const cancelClicked = useCallback(() => {
		setLocalShowInactive(props.showInactive ?? false);
		setSelectedItems([...props.initialSelection]);
		props.closeCallback();
	}, [selectedItems]);

	//expand callback
	const expandCallback = useCallback(
		(node: ITreeNode) => props.selectionUtil.expandCallback(node, expanded, setExpanded),
		[expanded]
	);

	//select callback
	const selectCallback = useCallback(
		(node: ITreeNode) => {
			if (!node.canBeChecked) {
				return;
			}

			const tempSelectedItems = _.cloneDeep(selectedItems);
			if (!localShowInactive) {
				tempSelectedItems.forEach((selectedItem) => {
					if (selectedItem.items?.length > 0) {
						selectedItem.items = selectedItem.items.filter((item) => item.active);
					}
				});
			}

			props.selectionUtil.select(node, treeData, setTreeData, tempSelectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	//clear selection
	const clearSelection = useCallback(async () => {
		await props.selectionUtil.clearSelection(treeData, setTreeData, setSelectedItems);
	}, [treeData, selectedItems]);

	//remove selected item
	const removeSelectedItem = useCallback(
		(node: ITreeNode) => {
			props.selectionUtil.deselectNode(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	//select all
	const selectAll = useCallback(() => {
		props.selectionUtil.selectAll(treeData, setTreeData, setSelectedItems);
	}, [treeData]);

	useEffect(() => {
		if (!props.showInactive && props.clearOnCheckInactive) {
			setSelectedItems(selectedItems.filter((t) => t.active));
		}
	}, [props.showInactive]);

	return (
		<div className={'selection-dialog-container'}>
			<Dialog onEscapeKeyDown={cancelClicked} fullWidth className={'selection-dialog'} open={props.visible}>
				<DialogContent>
					<span className={'e-dlg-header'}>{props.title ? props.title : TranslateText('common.filter')}</span>
					<div className="filter">
						<div className="buttons">
							{props.filterButtons && props.filterButtons.length > 0 && (
								<RadioButtonContainer
									buttons={props.filterButtons}
									visible={props.visible}
									selectedButton={selectedFilter}
									buttonSelectCallback={setSelectedFilter}
								/>
							)}
						</div>
						{props.displayInactive && (
							<FormControlLabel
								control={
									<Checkbox
										name="showInactive"
										color={'primary'}
										checked={localShowInactive}
										onChange={() => setLocalShowInactive((prev) => !prev)}
									/>
								}
								label={
									<Typography style={{ fontSize: 12 }}>
										{TranslateText('fleetSelection.showInactive')}
									</Typography>
								}
							/>
						)}
					</div>
					<TreeSelectionDialogContent
						treeData={treeData}
						selectedItems={selectedItems}
						changeFilterCallback={setFilterText}
						selectAll={selectAll}
						selectCallback={selectCallback}
						removeSelectedItem={removeSelectedItem}
						clearSelection={clearSelection}
						expandCallback={expandCallback}
						filterText={filterText}
						filterPlaceholder={props.filterPlaceholder}
					/>
					<div className={'buttons-container'}>
						<Button onClick={cancelClicked}>{TranslateText('common.buttonCancel')}</Button>
						<DebouncedButton onClick={saveClicked} debounceTime={DEBOUNCE_TIME}>
							{TranslateText('common.buttonSave')}
						</DebouncedButton>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

TreeSelectionGroupDialog.defaultProps = {
	visible: true,
	displayInactive: true,
	showInactive: false,
	clearOnCheckInactive: true,
	selectionUtil: SelectionUtil,
	filterButtons: [],
};

export default TreeSelectionGroupDialog;
