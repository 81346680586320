import './styles.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import { extractObjectName } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import React from 'react';

import { Checkbox, Tooltip } from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';

import DateTimeUtil from '../../../shared/datetime/DateTimeUtil';
import { TripGrouping } from '../../../store/HistoryStore';
import { TranslateText } from '../../../utils/Translations';
import HourglassBottomOutlined from '../../Common/Icons/HourglassBottomOutlined';
import { HistoryTripGroupSummaryProps } from './types';

const HistoryTripGroupSummary = (props: HistoryTripGroupSummaryProps) => {
	const { icon } = useTryGetSvgIconFromQueryCache(props.iconType);

	const isDriver = props.groupingType === TripGrouping.driverName || props.iconType === 'driver';

	const isObject =
		props.iconType !== 'driver' &&
		(props.groupingType === TripGrouping.objectName || props.groupingType === TripGrouping.objectOrDriver);

	return (
		<>
			{isDriver ? (
				<AccountBox className="type-icon" />
			) : !!props.iconType ? (
				<SvgIcon icon={icon.content} color="#FFFFFF" size={20} onClick={() => {}} />
			) : null}
			{isObject ? (
				<Tooltip
					PopperProps={{
						disablePortal: true,
						popperOptions: {
							positionFixed: true,
						},
					}}
					title={extractObjectName(props.displayName)}
					placement={'bottom-start'}
				>
					<span className="history-group-title">
						<ObjectNameWithTimeZoneOffset
							objectNameWithTimeZone={props.displayName}
							useToolTip={false}
						/>
					</span>
				</Tooltip>
			) : (
				<Tooltip
					PopperProps={{
						disablePortal: true,
						popperOptions: {
							positionFixed: true,
						},
					}}
					title={
						props.groupingType === TripGrouping.driverName ||
						props.groupingType === TripGrouping.objectOrDriver
							? props.displayName
							: ''
					}
					placement={'bottom-start'}
				>
					<span className="history-group-title">{props.displayName}</span>
				</Tooltip>
			)}
			<span className="trip-group-info">
				<span className="group-info-text">
					{props.tripsNumber} {TranslateText('tripDetailsSection.trips')}
				</span>
				{!props.allNonTrips ? (
					<>
						<span>
							<HourglassBottomOutlined className="group-info-icon" />
							<span className="group-info-text">
								{DateTimeUtil.calculateTimePassed(props.totalDrivingTime, new Date(), false)}
							</span>
						</span>
						<span>
							<PinDropOutlinedIcon className="group-info-icon" />
							<span className="group-info-text">{`${props.totalDistance.toFixed(1)} km`}</span>
						</span>
					</>
				) : null}
			</span>
			<Checkbox
				color={'default'}
				className="selection-checkbox"
				checkedIcon={<CheckBoxOutlinedIcon />}
				indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
				icon={<CheckBoxOutlineBlankOutlinedIcon />}
				onClick={(e) => {
					e.stopPropagation();
				}}
				onChange={(e) => {
					props.groupVisibilityCallbackRef.current &&
						props.groupVisibilityCallbackRef.current(e.target.checked);
				}}
				indeterminate={(!props.allVisible && props.someVisible) || false}
				checked={props.allVisible || false}
			/>
		</>
	);
};

export default React.memo(HistoryTripGroupSummary);
