import './fleetOverviewCard.scss';

import { ReactComponent as DrivingSvgImage } from 'assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as IdleSvgImage } from 'assets/icons/Idle.svg';
import { ReactComponent as ParkedSvgImage } from 'assets/icons/Parked.svg';
import PrivateUserIcon from 'components/Common/Icons/PrivateUserIcon';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { useSensorTemplates } from 'components/LiveMap/hooks';
import { SensorTemplateEnum, SensorTemplates } from 'components/LiveMap/types';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import { noop } from 'Constants';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { RowData, StateFilterEnum } from 'models/LiveMenuUtils';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeCounter from 'shared/components/TimeCounter/TimeCounter';
import { ResizableConstants } from 'shared/constants/ResizableConstants';
import {
	useLocationsLive,
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { LiveDataResultDto } from 'store/LiveData';
import { liveMapActionCreators, SET_MAP_SELECTED_ENTITY } from 'store/LiveMap';
import { FormatDateWithReferenceDate } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';
import SpeedIcon from '@material-ui/icons/Speed';

import SvgIconName from '../IconPicker/SvgIconName/SvgIconName';
import { AddressRow } from './AddressRow';

type Props = {
	liveData: LiveDataResultDto;
	resizableExpanded: boolean;
};

const getRowData = (liveData: LiveDataResultDto): RowData => {
	const rowData: RowData = {
		...liveData,
		id: liveData.entityId,
		isPerson: liveData.entityType === EntityTypeEnum.Person,
		entityName: liveData.entityType === EntityTypeEnum.Person ? liveData.personName : liveData.objectName,
		connectedToEntityName:
			liveData.entityType === EntityTypeEnum.Person ? liveData.objectName : liveData.personName,
		driverName: liveData.personName,
		address: liveData.address,
		objectStatus: '',
		fromPersonId: liveData.entityType === EntityTypeEnum.Person,
	};

	switch (liveData.state) {
		case StateFilterEnum.Unknown:
			rowData.objectStatus = TranslateText('objects.objectType.Unknown');
			break;
		case StateFilterEnum.Driving:
			rowData.objectStatus = TranslateText('liveExtendedMenuOverallTab.driving');
			break;
		case StateFilterEnum.Parked:
			rowData.objectStatus = TranslateText('liveExtendedMenuOverallTab.parked');
			break;
		case StateFilterEnum.Idle:
			rowData.objectStatus = TranslateText('liveExtendedMenuOverallTab.idle');
			break;
		case StateFilterEnum.Alert:
			rowData.objectStatus = TranslateText('liveExtendedMenuOverallTab.alarm');
			break;
		default:
			break;
	}

	return rowData;
};

const FleetOverviewCard = ({ liveData, resizableExpanded }: Props) => {
	const dispatch = useDispatch();
	const [connectionBroken, setConnectionBroken] = useState<boolean>(false);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const tripDetails = useSelector((s: ApplicationState) => s.liveMap.tripDetails);
	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();
	const showLiveLocations = useLocationsLive();
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const resizableMenuWidth = useSelector((state: ApplicationState) => state.resizableEntity.resizableMenuLive.width);
	const selectedEntityId = useSelector((state: ApplicationState) => state.liveMap.selectedEntityId);

	const { icon } = useTryGetSvgIconFromQueryCache(liveData.objectIcon);

	const objectDoubleClick = (liveData: LiveDataResultDto) => {
		const rowData = getRowData(liveData);
		if (tripDetails) {
			if (tripDetails.id === rowData.id) {
				dispatch(liveMapActionCreators.toggleTripDetails(null));
			} else {
				dispatch(liveMapActionCreators.toggleTripDetails(rowData));
			}
		} else {
			dispatch(liveMapActionCreators.toggleTripDetails(rowData));
		}
	};

	useEffect(() => {
		const verifyIfConnectionIsBroken = () => {
			//check if the connection is broken, only if the time difference between the last seen timestamp and the keep alive timestamp is less or equal with 1 minute
			// otherwise it means that the keep alive timestamp is outdated
			const lastSeenInMinutes = +new Date(liveData.lastSeen) / 1000 / 60;
			const lastKeepAliveTimeStampInMinutes = +new Date(liveData.lastKeepAlive) / 1000 / 60;
			const todayInMinutes = +new Date() / 1000 / 60;
			if (liveData.lastSeen) {
				if (liveData.lastKeepAlive && lastSeenInMinutes - lastKeepAliveTimeStampInMinutes <= 1) {
					const connectionBrokenResult = todayInMinutes - lastKeepAliveTimeStampInMinutes > 5;
					if (connectionBrokenResult !== connectionBroken) {
						setConnectionBroken(connectionBrokenResult);
					}
				} else if (todayInMinutes - lastSeenInMinutes > 5) {
					if (!connectionBroken) {
						setConnectionBroken(true);
					}
				} else {
					setConnectionBroken(false);
				}
			} else {
				setConnectionBroken(false);
			}
		};

		verifyIfConnectionIsBroken();

		if (tripDetails?.id === liveData.entityId) {
			const newRowData = getRowData(liveData);
			dispatch(liveMapActionCreators.toggleTripDetails(newRowData));
		}
		const id = setInterval(() => {
			verifyIfConnectionIsBroken();
		}, 10000);
		return () => {
			clearInterval(id);
		};
	}, [liveData]);

	const selectLiveData = useCallback((changedSelectedId: string) => {
		dispatch({
			type: SET_MAP_SELECTED_ENTITY,
			payload: changedSelectedId,
		});
	}, []);

	const stateStart = liveData.stateStart
		? moment(liveData.stateStart)
				.utcOffset(liveData.timeZoneMinutesOffset)
				.toString()
		: null;

	const { sensorTemplates } = useSensorTemplates(liveData, trackTypeSpecification);

	return (
		<div
			className={`data-details-row trip-row-item fleetoverview-row-item  ${
				liveData.entityId === selectedEntityId ? 'selected' : ''
			}`}
			onClick={() => selectLiveData(liveData.entityId)}
			onDoubleClick={() => objectDoubleClick(liveData)}
		>
			<div className="card-row">
				<div className={'card-row-content'} style={{ width: '100%' }}>
					<div className="row-details-overflow one-line-display">
						{liveData.entityType === EntityTypeEnum.Person ? (
							<div className="object-information-container">
								{resizableMenuWidth !== ResizableConstants.defaultWidth ? (
									<div className="person-icon-container">
										<PersonIcon className="entity-type-icon" />
									</div>
								) : (
									<PersonIcon className="entity-type-icon" />
								)}
								<Tooltip title={liveData.personName}>
									<div
										className={`card-text person-name ${
											resizableExpanded ? 'resizable-expanded' : ''
										}`}
									>
										{liveData.personName}
									</div>
								</Tooltip>
							</div>
						) : (
							<div className="object-information-container">
								<div className="entity-type-icon-object">
									<SvgIcon icon={icon.content} color="#656565" size={18} onClick={noop} />
								</div>
								<Tooltip title={liveData.objectName}>
									<div
										className={`card-text object-name ${
											resizableExpanded ? 'resizable-expanded' : ''
										}`}
									>
										<ObjectNameWithTimeZoneOffset
											objectNameWithTimeZone={liveData.objectNameWithTimeZoneOffset}
											useToolTip={false}
										/>
									</div>
								</Tooltip>
							</div>
						)}
					</div>
					{((liveData.state === StateFilterEnum.Parked && showLastDriverOfParkedObject) ||
						liveData.state !== StateFilterEnum.Parked) &&
						driverIdentification &&
						liveData.entityType !== EntityTypeEnum.Person &&
						liveData.personName && (
							<div
								className={`row-details-overflow object-information-container ${
									resizableExpanded ? 'extended-menu-driver' : ''
								}`}
							>
								{resizableExpanded ? (
									<div className="person-icon-container">
										<PersonIcon className="entity-type-icon" />
									</div>
								) : (
									<PersonIcon className="entity-type-icon" />
								)}
								<Tooltip title={liveData.personName}>
									<div className="card-text person-name">{liveData.personName}</div>
								</Tooltip>
							</div>
						)}
					{((liveData.state === StateFilterEnum.Parked && showLastObjectOfParkedDriver) ||
						liveData.state !== StateFilterEnum.Parked) &&
						liveData.entityType === EntityTypeEnum.Person &&
						liveData.objectName && (
							<div
								className={`row-details-overflow object-information-container ${
									resizableExpanded ? 'extended-menu-driver' : ''
								}`}
							>
								<div className="entity-type-icon-object">
									<SvgIcon icon={icon.content} color="#656565" size={18} onClick={noop} />
								</div>
								<Tooltip title={liveData.objectName}>
									<div className="card-text object-name">
										<ObjectNameWithTimeZoneOffset
											objectNameWithTimeZone={liveData.objectNameWithTimeZoneOffset}
											useToolTip={false}
										/>
									</div>
								</Tooltip>
							</div>
						)}
					<div className="object-address-container">
						{liveData.state !== StateFilterEnum.None && liveData.state !== StateFilterEnum.Unknown && (
							<div className="object-status">
								{liveData.state === StateFilterEnum.Driving ? (
									<DrivingSvgImage
										className={`driving-img object-status-icon direction-icon direction-${liveData.direction?.toLowerCase()}`}
									/>
								) : null}
								{liveData.state === StateFilterEnum.Parked ? (
									<ParkedSvgImage className="parked-img object-status-icon" />
								) : null}
								{liveData.state === StateFilterEnum.Idle ? (
									<IdleSvgImage className="idle-img object-status-icon" />
								) : null}
							</div>
						)}
						<div className="card-text">
							{!!liveData ? (
								<AddressRow
									displayOnlyAddress
									locationName={showLiveLocations ? liveData.locationName : undefined}
									address={liveData.address}
								/>
							) : null}
							<div className="row-details-status">
								<div className="row-column-item">
									<span
										hidden={liveData.stateStart === null}
										className={'duration-icon-' + liveData.state}
									/>
									<TimeCounter
										hidden={liveData.stateStart === null}
										referenceDate={new Date(liveData.stateStart)}
										refreshRateInMilliseconds={2000}
										showSeconds={false}
									/>
									<span hidden={liveData.stateStart === null}>
										&nbsp;(
										{FormatDateWithReferenceDate(stateStart, new Date(), true)})
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="notification info right-positioned-icon">
				{liveData.state !== StateFilterEnum.Unknown &&
					sensorTemplates?.map((templateData: SensorTemplates) =>
						templateData.iconName ? (
							<SvgIconName
								key={templateData.templateId}
								iconName={templateData.iconName}
								color={templateData.labelColor ? templateData.labelColor : '#4d6d92'}
								size={16}
								displayInlineBlock
								tooltip={`${templateData.templateName} ${templateData.stateName}`}
							/>
						) : templateData.sensorTemplateType === SensorTemplateEnum.Event ? (
							<PrivateUserIcon
								key={templateData.templateId}
								style={{ fill: templateData.labelColor ? templateData.labelColor : '#006837' }}
								className="linked-template-icon"
							/>
						) : null
					)}
				{connectionBroken &&
				(liveData.entityType === EntityTypeEnum.Object ||
					(liveData.entityType === EntityTypeEnum.Person && liveData.state !== StateFilterEnum.Parked)) ? (
					<PortableWifiOffIcon className="connection-broken-icon" />
				) : null}
			</div>
			{liveData.state === StateFilterEnum.Driving || liveData.state === StateFilterEnum.Idle ? (
				<div className="row-column-item bottom-right-positioned-icon">
					<SpeedIcon className="speed-icon" />
					<div className="card-text speed-text">{parseInt(liveData.speed).toFixed(0)}</div>
					<div className="card-text kilometer-text">Km/h</div>
				</div>
			) : null}
		</div>
	);
};

export default FleetOverviewCard;
