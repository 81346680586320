export const SET_SHOW_INACTIVE_AUTHORIZATION = 'SET_SHOW_INACTIVE_AUTHORIZATION';
export const SET_SHOW_INACTIVE_MEMBERS = 'SET_SHOW_INACTIVE_MEMBERS';

export interface GroupMembersSelectionStore {
	showInactiveAuthorization: boolean;
	showInactiveMembers: boolean;
}

export interface GroupMembersSelectionActionSignature {
	setShowInactiveAuthorization: (showInactive: boolean) => void;
	setShowInactiveMembers: (showInactive: boolean) => void;
}

const initialState: GroupMembersSelectionStore = {
	showInactiveAuthorization: false,
	showInactiveMembers: false,
};

export const groupMembersSelectionActionCreators = {
	setShowInactiveAuthorization: (showInactiveAuthorization: boolean) => (dispatch: any) => {
		dispatch({ type: SET_SHOW_INACTIVE_AUTHORIZATION, showInactiveAuthorization });
	},
	setShowInactiveMembers: (showInactiveMembers: boolean) => (dispatch: any) => {
		dispatch({ type: SET_SHOW_INACTIVE_MEMBERS, showInactiveMembers });
	},
};

export const groupMembersSelectionReducer = (state: GroupMembersSelectionStore, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case SET_SHOW_INACTIVE_AUTHORIZATION: {
			return {
				...state,
				showInactiveAuthorization: action.showInactiveAuthorization,
			};
		}
		case SET_SHOW_INACTIVE_MEMBERS: {
			return {
				...state,
				showInactiveMembers: action.showInactiveMembers,
			};
		}
		default: {
			return state;
		}
	}
};
