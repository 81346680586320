const predefinedColors = [
	'#000000',
	'#003366',
	'#000080',
	'#333333',
	'#800000',
	'#FF6600',
	'#808000',
	'#008000',
	'#0000FF',
	'#666699',
	'#FF0000',
	'#339966',
	'#FF00FF',
	'#00CCFF',
	'#999999',
	'#993366',
];

export { predefinedColors };
