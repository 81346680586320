import {setInterval} from 'timers';
import {Column} from 'primereact/column';
import {DataTable, DataTablePFSEvent} from 'primereact/datatable';
import * as React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {Button, Icon, IconButton} from '@material-ui/core';

import {getCurrentSession} from '../..';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import ReportPeriodsEnum from '../../models/ReportPeriodsEnum';
import TemplateData from '../../models/TemplateData';
import {ApplicationState} from '../../store';
import ajaxUtil from '../../utils/Ajax';
import {FormatDate} from '../../utils/DateUtils';
import {TranslateText} from '../../utils/Translations';
import {DialogUtil} from '../Common/NotificationDialog/NotificationDialog';
import {PageResult} from '../Common/Pagination';
import {GeneratedReportDto} from './GeneratedReportDto';
import {DebouncedButton} from "../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../Constants";
import {FileDownload} from './FileDownload';


const mapStateToProps = (state: ApplicationState) => {
	return {
		userId: state.currentSession.aspNetUserId,
		cleanupMostRecentReports: state.currentSession.reportsSettings.cleanupMostRecentReports,
	};
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
	focus: boolean;
	take: number;
	customerId: string;
	driverIdentification: boolean;
};

type State = {
	reports: GeneratedReportDto[];
	total: number;
	skip: number;
	currentReportId: string;
	gridRefreshInterval: any;
	delay: number;
	disableDownload: string[];
	disableFavorite: string[];
};

class RecentReportsOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			reports: [],
			total: 0,
			skip: 0,
			currentReportId: '',
			gridRefreshInterval: null,
			delay: 5000,
			disableDownload: [],
			disableFavorite: [],
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			(prevProps.focus !== this.props.focus ||
				prevProps.customerId !== this.props.customerId ||
				prevState.skip !== this.state.skip) &&
			this.props.focus
		) {
			this.fetchData(true);
		}
		if (this.props.focus && prevProps.focus !== this.props.focus) {
			this.setState({ gridRefreshInterval: setInterval(() => this.fetchData(), this.state.delay) });
		}
		if (!this.props.focus && prevProps.focus !== this.props.focus) {
			this.state.gridRefreshInterval?.close();
			clearInterval(this.state.gridRefreshInterval);
		}
	}

	componentWillUnmount() {
		if (this.state.gridRefreshInterval) {
			this.state.gridRefreshInterval.close();
			clearInterval(this.state.gridRefreshInterval);
		}
	}

	private currentSessionId = getCurrentSession()?.currentSessionId ?? '00000000-0000-0000-0000-000000000000';
	private getUrl: string =
		GlobalSettings.reportingApi + '/recentGeneratedReports?currentSessionId=' + this.currentSessionId;
	private timeGeneration = 5 * 60000; //5 minutes

	private handleUserResponse(response: boolean) {
		if (response) {
			this.deleteRecentReport(this.state.currentReportId);
		}
	}

	openDeleteReport(id: string) {
		this.setState({
			currentReportId: id,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleConfirmAction'),
			content: TranslateText('notificationMessages.deleteRecentGeneratedReport'),
		}).then(this.handleUserResponse.bind(this));
	}

	toggleFavoriteReport(reportId: string) {
		this.setState({disableFavorite: [...this.state.disableFavorite,reportId]});
		const url = GlobalSettings.reportingApi + '/addFavoriteReport';

		ajaxUtil
			.put<boolean>(url, { id: reportId })
			.then((data) => {
				this.fetchData(false, reportId);
			}).catch((err) => {
			this.setState({disableFavorite: [...this.state.disableFavorite.map((s) => s != reportId && s)]});
			console.log(err);
		});
	}

	base64ToArrayBuffer(base64: any) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	downloadReport(reportId: string) {
		this.setState({disableDownload: [...this.state.disableDownload,reportId]});
		const url = GlobalSettings.reportingApi + '/downloadGeneratedReport/' + reportId;
		ajaxUtil.get(url).then((data) => {
			const reportDownload = data as FileDownload;
			const bytes = this.base64ToArrayBuffer(reportDownload.content);
			const url = window.URL.createObjectURL(new Blob([bytes]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', reportDownload.fileName);
			document.body.appendChild(link);
			link.click();
			this.setState({disableDownload: [...this.state.disableDownload.map((s) => s != reportId && s)]});
		}).catch((err) => {
			this.setState({disableDownload: [...this.state.disableDownload.map((s) => s != reportId && s)]});
			console.log(err);
		});
	}

	deleteRecentReport(reportId: string) {
		const url = GlobalSettings.reportingApi + '/deleteRecentGeneratedReport/' + reportId;
		ajaxUtil.delete(url).then((data) => {
			this.fetchData(true);
		});
	}

	formatReportPeriod(periodType: string, customPeriodInterval: Date[]) {
		if(periodType != null) {
			if (periodType.toLowerCase() === ReportPeriodsEnum.CustomPeriod.value) {
				return (
					TranslateText('reports.periods.' + periodType.toLowerCase()) +
					': ' +
					FormatDate(customPeriodInterval[0]) +
					' - ' +
					FormatDate(customPeriodInterval[1])
				);
			} else {
				return TranslateText('reports.periods.' + periodType.toLowerCase());
			}
		}
		return null;
	}

	verifyDisabledButton(id: string, array: string[]): boolean {
		return array.some((s) => s == id);
	}

	fetchData(forceReload = false, rowDataId: string = null) {
		const body = {
			take: this.props.take,
			skip: this.state.skip,
			customerId: this.props.customerId,
			cleanupMostRecentReports: this.props.cleanupMostRecentReports,
		};
		ajaxUtil.post<PageResult<GeneratedReportDto>>(this.getUrl, body).then((result) => {
			const newData = result.data.map((d) => {
				return { id: d.id, status: d.status, favorite: d.favorite };
			});
			const oldData = this.state.reports.map((r) => {
				return { id: r.id, status: r.status, favorite: r.favorite };
			});

			if (forceReload) {
				this.setState({
					reports: result.data,
					total: result.total,
				});
			} else if (
				!newData.every((newItem) =>
					oldData.some(
						(oldItem) =>
							oldItem.id === newItem.id &&
							oldItem.status === newItem.status &&
							oldItem.favorite === newItem.favorite
					)
				)
			) {
				this.setState({
					reports: result.data,
					total: result.total,
				});
			}
			if(rowDataId != null)
			{
				let currentReport = result.data.find((s) => s.id == rowDataId);
				if(currentReport.favorite)
				{
					this.setState({disableFavorite: [...this.state.disableFavorite.map((s) => s != rowDataId && s)]});
				}
			}
		}).catch((err) => {
			if(rowDataId != null)
			{
				this.setState({disableFavorite: [...this.state.disableFavorite.map((s) => s != rowDataId && s)]});
			}
			console.log(err);
		});
	}

	runReport(reportId: string) {
		const url = GlobalSettings.reportingApi + '/rerunReport/' + reportId;
		ajaxUtil.post(url, { reportId });
	}

	render() {
		return (
			<div className="report-content-container">
				<div className="flex-grid-container">
					<DataTable
						emptyMessage={TranslateText('common.noData')}
						className={'overview'}
						value={this.state.reports}
						totalRecords={this.state.total}
						rows={this.props.take}
						paginator={true}
						lazy={true}
						first={this.state.skip}
						dataKey={'id'}
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
						currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
						onPage={(e: DataTablePFSEvent) => {
							this.setState({
								skip: e.first,
							});
						}}
					>
						<Column
							field="reportName"
							header={TranslateText('maintenanceOverview.grid.colReportName')}
							style={{ maxWidth: '150px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return <span title={rowData.reportName}>{rowData.reportName}</span>;
							}}
						/>
						<Column
							field="templateCategoryType"
							header={TranslateText('maintenanceOverview.grid.colReportType')}
							style={{ width: '140px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return TranslateText(rowData.templateCategoryType);
							}}
						/>
						<Column
							field="outputType"
							header={TranslateText('maintenanceOverview.grid.colOutputType')}
							style={{ width: '80px', textAlign: 'left' }}
						/>
						<Column
							field="selectedGroups"
							header={TranslateText('maintenanceOverview.grid.colSelectedGroups')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="selectedEntities"
							header={TranslateText('maintenanceOverview.grid.colSelectedEntities')}
							style={{ width: '80px', textAlign: 'left' }}
						/>
						<Column
							field="periodType.customPeriodInterval"
							header={TranslateText('maintenanceOverview.grid.colRequestedPeriod')}
							style={{ width: '100px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return this.formatReportPeriod(rowData.periodType, rowData.customPeriodInterval);
							}}
						/>
						<Column
							field="generatedDateTime"
							header={TranslateText('maintenanceOverview.grid.colDateTime')}
							style={{ width: '180px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return FormatDate(new Date(rowData.generatedDateTime));
							}}
						/>
						<Column
							field="download"
							header={TranslateText('maintenanceOverview.grid.colDownload')}
							style={{ width: '80px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) =>
								rowData.status === 'Error' ? (
									<Button
										className="report-grid-button-error"
										disableElevation
										variant="contained"
										onClick={() => this.runReport(rowData.id)}
									>
										<Icon>sync_problem</Icon>
									</Button>
								) : rowData.status === 'InProgress' &&
								  Date.now() - new Date(rowData.generatedDateTime).getTime() > this.timeGeneration ? (
									<Button
										className="report-grid-button"
										disableElevation
										variant="contained"
										disabled
										onClick={() => this.downloadReport(rowData.id)}
									>
										<Icon>hourglass_empty</Icon>
									</Button>
								) : (
									<DebouncedButton
										className="report-grid-button"
										disableElevation
										variant="contained"
										disabled={this.verifyDisabledButton(rowData.id, this.state.disableDownload) || rowData.status === 'InProgress'}
										onClick={() => this.downloadReport(rowData.id)}
										debounceTime={DEBOUNCE_TIME}
									>
										<Icon>download</Icon>
									</DebouncedButton>
								)
							}
						/>
						<Column
							field="remove"
							header={TranslateText('maintenanceOverview.grid.colRemove')}
							style={{ width: '80px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) => (
								<Button
									className="report-grid-button"
									disableElevation
									variant="contained"
									disabled={rowData.status === 'InProgress'}
									onClick={() => this.openDeleteReport(rowData.id)}
								>
									<Icon>close</Icon>
								</Button>
							)}
						/>
						<Column
							field="favorite"
							header={TranslateText('maintenanceOverview.grid.colFavorite')}
							style={{ width: '80px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) =>
								rowData.requestedByUserId === this.props.userId && (
									<IconButton
										className="report-grid-icon-button-favorite"
										size="small"
										hidden={rowData.dataType === TemplateData.LocationExport ||
											rowData.dataType === TemplateData.ObjectExport
									}
										disabled={
											this.verifyDisabledButton(rowData.id, this.state.disableFavorite) ||
											rowData.status === 'InProgress' ||
											rowData.favorite ||
											(!this.props.driverIdentification &&
												(rowData.entityType === EntityTypeEnum.Person ||
													rowData.dataType === TemplateData.ObjectWithoutDriver))
										}
										onClick={
											!this.props.driverIdentification &&
											(rowData.entityType === EntityTypeEnum.Person ||
												rowData.dataType === TemplateData.ObjectWithoutDriver)
												? null
												: () => this.toggleFavoriteReport(rowData.id)
										}
									>
										{rowData.favorite &&
										!(
											!this.props.driverIdentification &&
											(rowData.entityType === EntityTypeEnum.Person ||
												rowData.dataType === TemplateData.ObjectWithoutDriver)
										) ? (
											<Icon>favorite</Icon>
										) : (
											<Icon>favorite_border</Icon>
										)}
									</IconButton>
								)
							}
						/>
					</DataTable>
				</div>
			</div>
		);
	}
}

export default connector(RecentReportsOverview);
