import '../../ExtendedMenu.scss';

import { Box, Icon, IconButton, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import React from 'react';

import { LiveDetailsTabsHeaderProps } from './types';

const LiveDetailsTabsHeader = ({ tripDetails, onClosedButtonClicked }: LiveDetailsTabsHeaderProps): JSX.Element => {
	const driverIdentification: boolean = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const { icon } = useTryGetSvgIconFromQueryCache(tripDetails.objectIcon);

	return (
		<Box className="details-header">
			<Box className="title-header">
				{tripDetails.isPerson && driverIdentification ? (
					<PersonIcon />
				) : (
					<SvgIcon icon={icon.content} color="#FFFFFF" size={24} onClick={() => {}} />
				)}
				<Tooltip title={tripDetails.entityName}>
					<Box className="details-header-title">{tripDetails.entityName}</Box>
				</Tooltip>
			</Box>
			<Box className="details-close-container ">
				<IconButton onClick={onClosedButtonClicked} className="details-close-icon">
					<Icon>close</Icon>
				</IconButton>
			</Box>
		</Box>
	);
};

export default LiveDetailsTabsHeader;
