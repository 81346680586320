import './oldWidget.scss';

import TemplateTypesEnum from 'models/TemplateTypesEnum';
import React from 'react';

import { Button } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import Template from '../../models/Template';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import { DebouncedButton } from '../Common/DebouncedButton';
import { DialogUtil } from '../Common/NotificationDialog';
import AlertListItemDto from '../Views/AlertListItemDto';
import RenderForEnum from '../Views/RenderForEnum';
import TemplateView from '../Views/TemplateView';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';

type Props = OldWidgetProps & {
	displayCallback: (template: Template) => void;
	notifyAboutUnsavedData: () => void;
	savedCallback: (sender: BaseWidget) => void;
	changeDataCallback: (hasChanges: boolean) => void;
	editingInstance: string;
	setEditingInstance: (editingInstance: string) => void;
	showEditButton: boolean;
	customerId: string;
	templateType: TemplateTypesEnum;
};

type State = {
	template: Template;
	widgedInEditMode: boolean;
	invalidForm: boolean;
	validTemplate: boolean;
	visible: boolean;
	initialTemplate: Template;
	disabled: boolean;
};

class TemplateInformationWidget extends BaseWidget<Props, State> {
	templateViewRef: TemplateView;

	constructor(props: Props) {
		super(props);
		this.state = {
			template: new Template(),
			initialTemplate: new Template(),
			widgedInEditMode: false,
			invalidForm: false,
			validTemplate: true,
			visible: true,
			disabled: false,
		};
	}

	public static defaultProps = {
		showEditButton: true,
	};

	componentDidMount() {
		this.fillTemplate(this.props.entityId);
		this.closeEditPanelMode();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.setState({ widgedInEditMode: false });
		}
	}

	async reloadData() {
		await this.fillTemplate(this.props.entityId);
	}

	fillTemplate(templateId: string) {
		// const data = await ajaxUtil.get<Template>(
		// 	`${GlobalSettings.sensorTemplatesMaintenanceApi}/${templateId}/${this.props.templateType}`
		// );
		// const initialData = { ...data, templateType: this.props.templateType };
		// this.setState({
		// 	...this.state,
		// 	template: initialData,
		// 	initialTemplate: initialData,
		// });
		// this.templateViewRef.setEditRecord(initialData);
		// this.props.displayCallback(initialData);
		// this.setState({disabled:false});
		ajaxUtil
			.get<Template>(`${GlobalSettings.sensorTemplatesMaintenanceApi}/${templateId}/${this.props.templateType}`)
			.then((data) => {
				const initialData = { ...data };
				this.setState({
					...this.state,
					template: initialData,
					initialTemplate: initialData,
				});
				this.templateViewRef.setEditRecord(initialData);
				this.props.displayCallback(initialData);
				this.setState({ disabled: false });
			})
			.catch((error) => {
				this.setState({ disabled: false });
				console.log(error.message);
			});
	}

	closeEditPanelMode() {
		if (this.state.widgedInEditMode) {
			this.toggleEditMode();
		}
	}

	tryCloseWidget() {
		this.toggleEditPanel();
	}

	async toggleEditPanel() {
		if (this.state.widgedInEditMode && this.hasUnsavedData) {
			if (await this.util.cancelConfirmation()) {
				this.templateViewRef.setEditRecord(this.state.initialTemplate);

				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);
				this.setState({
					template: this.state.initialTemplate,
				});

				this.toggleEditMode();
			}
		} else {
			this.toggleEditMode();
			this.templateViewRef.setEditRecord(this.state.template);
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgedInEditMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState(() => ({
			widgedInEditMode: currentValueFlipped,
			invalidForm: false,
		}));
	}

	async save() {
		this.setState({ disabled: true });
		const isValid: boolean = await this.templateViewRef.validateBeforeSave();
		if (!isValid) {
			this.setState({
				invalidForm: true,
				disabled: false,
			});
			return;
		}

		const affectedAlerts = await this.templateViewRef.checkIfAlertsAreAffectedByEdit();
		if (affectedAlerts && affectedAlerts.length > 0) {
			const affectedItems = affectedAlerts.map((x: AlertListItemDto) => x.name + '</br>').join('');

			const response = await DialogUtil.confirm({
				content:
					affectedAlerts.length > 1
						? TranslateTextInterpolated('notificationMessages.templateEditAffectedAlerts', [affectedItems])
						: TranslateTextInterpolated('notificationMessages.templateEditAffectedAlert', [affectedItems]),
				title: TranslateText('notificationMessages.saveNotification'),
			});
			if (!response) {
				this.setState({ disabled: false });
				return;
			}
		}

		this.hasUnsavedData = false;
		this.props.changeDataCallback(false);

		let url = `${GlobalSettings.sensorTemplatesMaintenanceApi}/`;

		if (this.state.template.templateType === TemplateTypesEnum.Event) {
			url += 'UpdateEventTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Duration) {
			url += 'UpdateDurationTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Digital) {
			url += 'UpdateDigitalTemplate';
		} else if (this.state.template.templateType === TemplateTypesEnum.Analog) {
			url += 'UpdateAnalogTemplate';
		}

		const contextSensitiveAddPostModel = {
			AddModel: this.state.template,
			GlobalCustomer: this.props.customerId,
			AlertsToBeSetInactive: affectedAlerts ? affectedAlerts.map((x: AlertListItemDto) => x.id) : [],
			TemplateType: this.state.template.templateType,
		};
		contextSensitiveAddPostModel.AddModel.code = this.state.template.code.trim();
		contextSensitiveAddPostModel.AddModel.name = this.state.template.name.trim();
		contextSensitiveAddPostModel.AddModel.description = this.state.template.description?.trim();

		ajaxUtil
			.put<string>(url, contextSensitiveAddPostModel)
			.then((response) => {
				this.props.savedCallback(this);
				this.fillTemplate(response);
				if (response) {
					this.hasUnsavedData = false;
					this.props.changeDataCallback(false);
					this.toggleEditPanel();
				}
			})
			.catch((error) => {
				this.setState({ disabled: false });
				console.log(error);
			});
	}

	setTemplate(valid: boolean, templateData: Template) {
		this.setState((prevState, props) => ({
			...this.state,
			validTemplate: valid,
			template: templateData,
		}));

		this.setState((prevState, props) => ({
			...this.state,
			invalidForm: !prevState.validTemplate,
		}));
		this.hasUnsavedData = true;
		this.props.changeDataCallback(true);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex={'4'}
				data-minsizey={'4'}
			>
				<div className={'e-panel-container'}>
					<div className={'e-panel-header'}>
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.widgedInEditMode}
							allowEditMode={this.props.allowEditMode || this.state.widgedInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel()}
						/>
					</div>
					<div className={'widget-view'}>
						<TemplateView
							informationWidget={true}
							readOnly={!this.state.widgedInEditMode}
							ref={(input) => {
								this.templateViewRef = input;
							}}
							setTemplate={this.state.widgedInEditMode ? this.setTemplate.bind(this) : null}
							renderFor={RenderForEnum.Widget}
							widgetState={this.state.widgedInEditMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							templateType={this.props.templateType}
						/>
					</div>
					{this.state.widgedInEditMode ? (
						<div className={'buttons-host'}>
							<Button
								className={'widget-button cancel-button'}
								onClick={() => {
									this.tryCloseWidget();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className={'widget-button save-button'}
								disabled={this.state.disabled || this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default TemplateInformationWidget;
