import { LocationAccessTypeEnum, LocationAreaTypeEnum } from '../components/LocationsOverview/LocationsOverview';
import IdIdentifiable from './IdIdentifiable';

class Location extends IdIdentifiable {
	constructor(customerId?: string, startDate?: Date) {
		super();
		this.customerId = customerId ? customerId : '';
		this.startDate = startDate ? startDate : new Date();
	}

	public code = '';
	public customerId = '';
	public customerName = '';
	public name = '';
	public categoryName = '';
	public categoryId: string | null = null;
	public accessType = LocationAccessTypeEnum.GlobalMap;
	public iconName = 'locations/pin';
	public iconColor = '#ff0000';
	public startDate = new Date();
	public endDate: Date = null;
	public active = true;
	public address = '';
	public shortAddress = '';
	public latitude: number | undefined = undefined;
	public longitude: number | undefined = undefined;
	public postalCode = '';
	public locality = '';
	public country = '';
	public radius = 50;
	public areaType: LocationAreaTypeEnum = LocationAreaTypeEnum.Circle;
	public description = '';
	public polygonPath: { lat: number; lng: number }[] = [];
}

export default Location;
