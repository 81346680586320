import CO2CalculationTypeEnum from '../../models/CO2CalculationTypeEnum';
import { TranslateText } from '../../utils/Translations';

const CO2CalculationTypeDataSource = {
	dataSource: () => [
		{
			id: CO2CalculationTypeEnum.DistanceWLTP,
			display: TranslateText('objects.co2CalculationType.DistanceWLTP'),
			disabled: false,
		},
		{
			id: CO2CalculationTypeEnum.DistanceNEDC,
			display: TranslateText('objects.co2CalculationType.DistanceNEDC'),
			disabled: false,
		},
		{
			id: CO2CalculationTypeEnum.DistanceManual,
			display: TranslateText('objects.co2CalculationType.DistanceManual'),
			disabled: false,
		},
		{
			id: CO2CalculationTypeEnum.DistanceTypeAndFuel,
			display: TranslateText('objects.co2CalculationType.DistanceTypeAndFuel'),
			disabled: false,
		},
		{
			id: CO2CalculationTypeEnum.FuelUsage,
			display: TranslateText('objects.co2CalculationType.FuelUsage'),
			disabled: true,
		},
		{
			id: CO2CalculationTypeEnum.EasyDrive2,
			display: TranslateText('objects.co2CalculationType.EasyDrive2'),
			disabled: true,
		},
		{
			id: CO2CalculationTypeEnum.EasyDrive2,
			display: TranslateText('objects.co2CalculationType.EasyDrive2'),
			disabled: true,
		},
		{
			id: CO2CalculationTypeEnum.NotApplicable,
			display: TranslateText('objects.co2CalculationType.NotApplicable'),
			disabled: false,
		},
	],
};

export default CO2CalculationTypeDataSource;
