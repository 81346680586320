import { Build_Number } from '../buildVariables';

//check build number and clear browser data (local storage, session storage and cookies) if it is different
export const checkBuildNumber = () => {
	const cookieValue = getCookie('Build_Number');

	if (cookieValue !== Build_Number) {
		clearAllCookies();
		localStorage.clear();
		sessionStorage.clear();

		setCookie('Build_Number', Build_Number);
	}
};

export const getCookie = (name: string) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts
			.pop()
			.split(';')
			.shift();
	}

	return null;
};

export const setCookie = (name: string, value: string, days?: number) => {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}

	document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const clearAllCookies = () => {
	const allCookies = document.cookie.split(';');
	for (let i = 0; i < allCookies.length; i++) {
		document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
	}
};
