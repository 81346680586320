import './alertDialog.scss';

import { History, LocationState, UnregisterCallback } from 'history';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { unsavedDataStoreActionCreators } from '../../../store/UnsavedDataStore';
import { TranslateText } from '../../../utils/Translations';

interface Props {
	unsavedData?: boolean | undefined;
	history: History<LocationState>;
}

const NavigationPrompt = (props: Props) => {
	//used for navigation change
	const [nextLocation, setNextLocation] = useState<string | null>(null);
	const unregisterCallback = useRef<UnregisterCallback | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.unsavedData) {
			unregisterCallback.current = props.history.block((location: any) => {
				setNextLocation(location);
				return false;
			});
		}

		return () => {
			if (unregisterCallback.current) {
				unregisterCallback.current();
			}
		};
	}, [props.unsavedData]);

	//used for tab/window close or page refresh
	const handleBeforeunload = useCallback((evt: BeforeUnloadEvent) => {
		evt.preventDefault();
		evt.returnValue = false;

		return false;
	}, []);
	useEffect(() => {
		if (props.unsavedData) {
			window.addEventListener('beforeunload', handleBeforeunload);
		}

		return () => {
			if (props.unsavedData) {
				window.removeEventListener('beforeunload', handleBeforeunload);
			}
		};
	}, [props.unsavedData]);

	return nextLocation ? (
		<Dialog className="alert-dialog" open={true}>
			<DialogTitle>{TranslateText('common.titleUnsavedData')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{TranslateText('notificationMessages.navigateAway')}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					color="primary"
					onClick={() => {
						if (unregisterCallback.current) {
							unregisterCallback.current();
							unregisterCallback.current = null;
						}
						props.history.push(nextLocation);
						setNextLocation(null);
						dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(false));
						dispatch(unsavedDataStoreActionCreators.setPromptCallback(null));
						dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
					}}
				>
					{TranslateText('common.buttonOk')}
				</Button>
				<Button
					size="small"
					onClick={() => {
						setNextLocation(null);
						dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(false));
					}}
				>
					{TranslateText('common.buttonCancel')}
				</Button>
			</DialogActions>
		</Dialog>
	) : (
		<></>
	);
};

export default NavigationPrompt;
