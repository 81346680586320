import EntityTypeEnum from 'models/EntityTypeEnum';
import { useCallback } from 'react';
import ajaxUtil from 'utils/Ajax';

import GlobalSettings from '../../../../GlobalSettings.json';
import { AlertEntity } from './types';

const useTerminateConnection = (
	alertDataId: string,
	trackedEntities: AlertEntity[],
	updateUrl: string,
	reloadDataCallback: () => void
) => {
	const terminateConnectionCallback = useCallback(
		async (rowData: AlertEntity) => {
			if (rowData) {
				const entityToBeRemoved: AlertEntity = trackedEntities.find(
					(entity: AlertEntity) => entity.entityId === rowData.entityId
				);

				await ajaxUtil.delete(
					`${updateUrl}/${alertDataId}/entities/${entityToBeRemoved.entityId}?entityType=${entityToBeRemoved.entityType}`
				);

				reloadDataCallback();
			}
		},
		[alertDataId, trackedEntities, updateUrl, reloadDataCallback]
	);

	return terminateConnectionCallback;
};

const useGetEntityLink = () => {
	const getLinkCallback = (entityId: string, entityType: EntityTypeEnum) => {
		const baseUrl =
			entityType === 'Driver'
				? GlobalSettings.route.drivers
				: entityType === 'Object'
				? GlobalSettings.route.objects
				: GlobalSettings.route.groups;
		return `${baseUrl}/${entityId}`;
	};

	return getLinkCallback;
};

export { useGetEntityLink, useTerminateConnection };
