import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { gridActionCreators } from 'store/GridOverview';
import ajaxUtil from 'utils/Ajax';
import { getRequestFilters } from 'utils/GridOverviewUtils';
import { DetailsNavigationBarProps, NavigationResult } from './types';

const useDetailNavigation = (props: DetailsNavigationBarProps) => {
	const dispatch = useDispatch();

	const [navigation, setNavigation] = useState<NavigationResult | null>(null);
	const take = useSelector((s: ApplicationState) => s.currentSession.defaultLines || 10);
	const skip = useSelector((s: ApplicationState) => s.gridOverview[props.entityType].gridPage);
	useEffect(() => {
		ajaxUtil
			.post<NavigationResult>(`${props.navigationUrl}`, {
				entityId: props.currentRecordId,
				customerId: props.filter.customerId,
				filterText: props.filter.filterText,
				...getRequestFilters(props.filter.gridFilters),
			})
			.then((navigation) => {
				setNavigation(navigation);
				dispatch(gridActionCreators.updateGridSelectedEntityId(props.entityType, props.currentRecordId));
				const newSkip = Math.floor(navigation.index / take) * take;
				if (newSkip !== skip) {
					dispatch(gridActionCreators.updateGridPage(props.entityType, newSkip));
				}
			});
	}, [props.currentRecordId]);

	const navigate = useCallback(
		(entityId: string, entityType: string) => {
			if (!entityId) {
				return;
			}
			props.history.push(`${props.detailUrl}/${entityId}${entityType ? `/${entityType}` : ''}`);
		},
		[props.history, props.detailUrl]
	);

	return { navigation, navigate };
};

export { useDetailNavigation };
