import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { DEBOUNCE_TIME } from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import Device from '../../models/Device';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import { DebouncedButton } from '../Common/DebouncedButton';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import DeviceView from '../Views/DeviceView';
import RenderForEnum from '../Views/RenderForEnum';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';

interface Props extends OldWidgetProps {
	forceReload: boolean;
}

type State = {
	deviceId: string;
	widgedInEditMode: boolean;
	invalidForm: boolean;
	validDevice: boolean;
	device: Device;
	initialDevice: Device;
	conflictsCanBeAutomaticallyFixed: boolean;
	conflictingConnections: any;
	visible: boolean;
};

class DeviceInformationWidget extends BaseWidget<Props, State> {
	deviceViewObj: any;
	connectionConflictsNotifierRef: ConnectionConflictsNotifier;
	scrollElRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);

		this.state = {
			deviceId: '',
			widgedInEditMode: false,
			invalidForm: false,
			validDevice: true,
			device: new Device(new Date()),
			initialDevice: new Device(new Date()),
			conflictsCanBeAutomaticallyFixed: false,
			conflictingConnections: null,
			visible: true,
		};

		this.scrollElRef = React.createRef<HTMLDivElement>();
	}

	componentDidMount() {
		this.fetchDevice(this.props.entityId);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			//this.closeEditPanelMode();
			this.fetchDevice(this.props.entityId);
		}
		if (prevProps.forceReload !== this.props.forceReload && this.props.forceReload) {
			this.fetchDevice(this.props.entityId);
		}
	}

	reloadData() {
		this.fetchDevice(this.props.entityId);
	}

	fetchDevice(deviceId: string) {
		const url = `${GlobalSettings.devicesMaintenanceApi}/${deviceId}`;
		ajaxUtil.get<Device>(url).then((obj) => {
			this.setState({
				deviceId: obj.id,
				device: obj,
				initialDevice: obj,
			});
			if (this.deviceViewObj) {
				this.deviceViewObj.setEditRecord(obj);
			}
			this.props.displayCallback(obj);
		});
	}

	closeEditPanelMode() {
		if (this.state.widgedInEditMode) {
			this.toggleEditMode();
		}
	}

	async toggleEditPanel() {
		if (this.state.widgedInEditMode && this.hasUnsavedData) {
			if (await this.util.cancelConfirmation()) {
				this.deviceViewObj.setEditRecord(this.state.initialDevice);

				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);
				this.setState({
					device: this.state.initialDevice,
				});

				this.toggleEditMode();
			}
		} else {
			this.toggleEditMode();
			this.deviceViewObj.setEditRecord(this.state.device);
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgedInEditMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState(() => ({
			widgedInEditMode: currentValueFlipped,
			invalidForm: false,
		}));
	}

	setDevice(valid: boolean, deviceData: Device) {
		this.setState({
			validDevice: valid,
			device: deviceData,
			invalidForm: !valid,
		});

		this.hasUnsavedData = true;
		this.props.changeDataCallback(true);
	}

	handleConfictingConnections(sender: ConnectionsConflictResolver, proposedConnectionCanBeSaved: boolean) {
		setTimeout(() => {
			sender.handleConflictingConnections(proposedConnectionCanBeSaved);
		}, 500);
	}

	save() {
		this.deviceViewObj.validateBeforeSave().then((valid: boolean) => {
			if (!valid) {
				this.setState({
					invalidForm: true,
				});
				return;
			} else {
				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);

				const url = GlobalSettings.devicesMaintenanceApi;
				const updatedDevice = this.state.device;

				ajaxUtil
					.put(url, updatedDevice)
					.then((data) => {
						if (data) {
							this.hasUnsavedData = false;
							this.props.changeDataCallback(false);
							this.toggleEditPanel();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		});
	}

	scrollToTop() {
		if (this.scrollElRef?.current?.scrollTo) {
			this.scrollElRef.current.scrollTo({ top: 0 });
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="5"
			>
				<ConnectionConflictsNotifier
					ref={(scope) => {
						this.connectionConflictsNotifierRef = scope;
					}}
					connectionType={ConnectionTypeEnum.DeviceObject}
					visible={false}
					userAnswearCallback={this.handleConfictingConnections.bind(this)}
				/>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={true}
							editMode={this.state.widgedInEditMode}
							editClaimConfig={[{ claim: ClaimType.Devices, values: [ClaimValue.edit] }]}
							allowEditMode={this.props.allowEditMode || this.state.widgedInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel()}
						/>
					</div>
					<div ref={this.scrollElRef} className="widget-view">
						<DeviceView
							widgetState={this.state.widgedInEditMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							renderFor={RenderForEnum.Widget}
							readOnly={!this.state.widgedInEditMode}
							ref={(input) => {
								this.deviceViewObj = input;
							}}
							setDevice={this.state.widgedInEditMode ? this.setDevice.bind(this) : null}
							scrollToTop={this.scrollToTop.bind(this)}
						/>
					</div>
					{this.state.widgedInEditMode ? (
						<div className="buttons-host">
							<Button className="widget-button cancel-button" onClick={() => this.toggleEditPanel()}>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default DeviceInformationWidget;
