import TemplateEventActionEnum from 'models/TemplateEventActionEnum';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import TemplateUnitOfMeasureEnum from 'models/TemplateUnitOfMeasureEnum';

import { TranslateText } from './Translations';

export const TemplateDefaults = {
	getTemplateTypes: () => {
		return [
			{
				id: TemplateTypesEnum.Event,
				display: TranslateText('fields.templateTypeEvent'),
			},
			{
				id: TemplateTypesEnum.Analog,
				display: TranslateText('fields.templateTypeAnalog'),
			},
			{
				id: TemplateTypesEnum.Duration,
				display: TranslateText('fields.templateTypeDuration'),
			},
			{
				id: TemplateTypesEnum.Digital,
				display: TranslateText('fields.templateTypeDigital'),
			},
		];
	},
	getEventActionList: () => {
		return [
			{
				id: TemplateEventActionEnum.PrivateBusiness,
				display: TranslateText('fields.template.privateMileage'),
			},
		];
	},
	getStateLowHigh: () => {
		return [
			{
				id: false,
				display: TranslateText('fields.template.stateLow'),
			},
			{
				id: true,
				display: TranslateText('fields.template.stateHigh'),
			},
		];
	},
	getUnitValues: () => {
		return [
			{
				id: TemplateUnitOfMeasureEnum.Percentage,
				display: TranslateText('fields.template.unitPercentage'),
			},
			{
				id: TemplateUnitOfMeasureEnum.Temperature,
				display: TranslateText('fields.template.unitTemperature'),
			},
			{
				id: TemplateUnitOfMeasureEnum.Voltage,
				display: TranslateText('fields.template.unitVoltage'),
			},
			{
				id: TemplateUnitOfMeasureEnum.Distance,
				display: TranslateText('fields.template.unitDistance'),
			},
			{
				id: TemplateUnitOfMeasureEnum.Time,
				display: TranslateText('fields.template.unitTime'),
			},
		];
	},
	getStateNames: () => {
		return [
			{
				display: TranslateText('fields.template.stateLow'),
			},
			{
				display: TranslateText('fields.template.stateNormal'),
			},
			{
				display: TranslateText('fields.template.stateHigh'),
			},
			{
				display: TranslateText('fields.template.state4'),
			},
			{
				display: TranslateText('fields.template.state5'),
			},
		];
	},
};
