import './templateView.scss';
import '../../../Common/RemoveSpinnerOptionInput.scss'

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {iconNoImage} from 'utils/Icons';
import {TemplateDefaults} from 'utils/TemplateUtils';

import {Button, InputLabel} from '@material-ui/core';

import * as GlobalSettings from '../../../../GlobalSettings.json';
import Template from '../../../../models/Template';
import TemplateEventActionEnum from '../../../../models/TemplateEventActionEnum';
import TemplateState from '../../../../models/TemplateState';
import TemplateTypesEnum from '../../../../models/TemplateTypesEnum';
import TemplateUnitOfMeasureEnum from '../../../../models/TemplateUnitOfMeasureEnum';
import TripTypesEnum from '../../../../models/TripTypesEnum';
import CustomerNameDisplay from '../../../../shared/components/CustomerNameDisplay';
import MaterialTextField from '../../../../shared/components/MaterialTextField/MaterialTextField';
import {FieldRule, FieldRules, ValidationResult} from '../../../../shared/validation/interfaces';
import Validator from '../../../../shared/validation/Validator';
import {ValidatorFunctions} from '../../../../shared/validation/ValidatorFunctions';
import {ApplicationState} from '../../../../store';
import ajaxUtil from '../../../../utils/Ajax';
import {TranslateText, TranslateTextInterpolated} from '../../../../utils/Translations';
import {ColorPicker} from '../../../ColorPicker';
import MaterialAutocomplete, {AutocompleteItem} from '../../../Common/Autocomplete/MaterialAutocomplete';
import IconPickerWrapper from '../../../IconPicker/IconPickerWrapper/IconPickerWrapper';
import YesNoCombobox from '../../../Widgets/components/YesNoCombobox';
import { AddWizardComponentProps } from '../../NewAddWizard';
import {handleKeyDown} from "../../../Common/RemoveSpinnerOptionInput";

interface TemplateDefinitionDto extends AutocompleteItem {
	relevantValue: boolean;
	tripColor: string;
	labelColor: string;
	rank: number;
	templateType: TemplateTypesEnum;
	countRelevantState: boolean;
	durationCounter: boolean;
	showOnMap: boolean;
	relevantStateName: string;
	relevantLabelColor: string;
	relevantIconName: string;
	nonRelevantStateName: string;
	nonRelevantLabelColor: string;
	nonRelevantIconName: string;
	maxValue: number;
	minValue: number;
	unitOfMeasure: TemplateUnitOfMeasureEnum;
	stateDefinition: number;
	templateAnalogStates: TemplateState[];
	numberOfStates: number;
	graphColor: string;
}
const TemplateView = (props: AddWizardComponentProps) => {
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const tripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const featuresTemplates = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.templates
			: s.currentSession.customer.featuresSettings.templates
	);

	const [genericTemplateId, setGenericTemplateId] = useState<string>('');
	const [genericTemplatesList, setGenericTemplateList] = useState<TemplateDefinitionDto[]>();
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const stateDefinitionsValues = (start: number, end: number) => {
		const result = [];
		for (let i = start; i <= end; i++) {
			result.push({
				id: i,
				display: i.toString(),
			});
		}
		return result;
	};

	let templateTypes = TemplateDefaults.getTemplateTypes();
	const eventActionList = TemplateDefaults.getEventActionList();
	const stateLowHigh = TemplateDefaults.getStateLowHigh();
	const unitValues = TemplateDefaults.getUnitValues();
	const defaultValueStateName = TemplateDefaults.getStateNames();

	const getActionList = () => {
		return !!(tripTypes & TripTypesEnum.Private)
			? eventActionList
			: eventActionList.filter((s) => s.id !== TemplateEventActionEnum.PrivateBusiness);
	};
	const getTemplateTypes = () => {
		if(!featuresTemplates) {
			templateTypes = templateTypes.filter((t) => t.id === TemplateTypesEnum.Event);
		}

		if (!trackTypeSpecification || getActionList().length === 0) {
			templateTypes = templateTypes.filter((t) => t.id !== TemplateTypesEnum.Event);
		}
		return templateTypes;
	};

	const [template, setTemplate] = useState<Template>(
		new Template(
			defaultCustomerId,
			getTemplateTypes().length > 0 ? getTemplateTypes()[0].id : null,
			!!(tripTypes & TripTypesEnum.Private) ? TemplateEventActionEnum.PrivateBusiness : null,
			[] as TemplateState[]
		)
	);

	const [initialTemplate, setInitialTemplate] = useState<Template>(template);
	const validator: Validator = new Validator({
		fieldRules: {
			code: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(100),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data) => {
							const templateData = data as Template;
							if (!templateData.code.trim()) {
								return ValidatorFunctions.wrapInPromise(true);
							}
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/TemplateFields`, {
								customerId: templateData.customerId,
								value: templateData.code,
								Field: 'code',
								editedEntityId: templateData.id ? templateData.id : null,
							});
						},
					},
				},
			},
			name: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(300),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
				},
			},
			description: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(500),
				},
			},
			templateType: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			rank: {
				rules: {
					required: ValidatorFunctions.required(),
					minNumber: ValidatorFunctions.minNumber(0),
					maxNumber: ValidatorFunctions.maxNumber(99),
				},
			},
			//EventTypeFormValidator
			eventAction: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Event ||
									templateData.eventAction === TemplateEventActionEnum.PrivateBusiness
							);
						},
					},
				},
			},
			relevantValue: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(templateData.relevantValue !== null);
						},
					},
				},
			},
			//DigitalTypeFormValidator
			countRelevantState: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Digital ||
									(templateData.countRelevantState !== null &&
										templateData.countRelevantState !== undefined)
							);
						},
					},
				},
			},
			durationCounter: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Digital ||
									(templateData.durationCounter !== null &&
										templateData.durationCounter !== undefined)
							);
						},
					},
				},
			},
			showOnMap: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Digital ||
									(templateData.showOnMap !== null && templateData.showOnMap !== undefined)
							);
						},
					},
				},
			},
			relevantStateName: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Digital ||
									!!templateData.relevantStateName?.trim()
							);
						},
					},
					maxLength: ValidatorFunctions.maxLength(10),
				},
			},
			nonRelevantStateName: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Digital ||
									!!templateData.nonRelevantStateName?.trim()
							);
						},
					},
					maxLength: ValidatorFunctions.maxLength(10),
				},
			},
			//AnalogTypeFormValidator
			maxValue: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									(templateData.maxValue !== null &&
										templateData.maxValue !== undefined &&
										templateData.maxValue.toString() !== '')
							);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.minValue') + 0,
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									templateData.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature ||
									Number(templateData?.maxValue) >= 0
							);
						},
					},
					maxNumber: ValidatorFunctions.maxNumber(999999),
				},
			},
			minValue: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									(templateData.minValue !== null &&
										templateData.minValue !== undefined &&
										templateData.minValue.toString() !== '')
							);
						},
					},
					lowerThanMax: {
						message: TranslateText('fieldsValidations.templateLowerMinValue'),
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									Number(templateData?.minValue) < Number(templateData?.maxValue)
							);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.minValue') + 0,
						validationFunction: (data) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									templateData.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature ||
									Number(templateData?.minValue) >= 0
							);
						},
					},
					maxNumber: ValidatorFunctions.maxNumber(999999),
				},
			},
			unitOfMeasure: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			numberOfStates: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
		},
	});
	const getRules = () => {
		const rules = {} as FieldRules;
		for (let i = 0; i < 5; i++) {
			rules[`name${i}`] = {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i + 1 > templateData.numberOfStates ||
									templateData.templateAnalogStates[i]?.name !== ''
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', ['10']),
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i + 1 > templateData.numberOfStates ||
									templateData.templateAnalogStates[i]?.name.length <= 10
							);
						},
					},
				},
			} as FieldRule;
			rules[`stateMaxValue${i}`] = {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i > 0 ||
									i + 1 > templateData.numberOfStates ||
									(templateData.templateAnalogStates[i]?.maxValue !== null &&
										templateData.templateAnalogStates[i]?.maxValue.toString() !== '')
							);
						},
					},
					minNumberConditionally: {
						message: TranslateText('fieldsValidations.minValue') + 0,
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i > 1 ||
									(i === 1 && templateData.templateAnalogStates[i]?.maxValue === null) ||
									i + 1 > templateData.numberOfStates ||
									templateData.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature ||
									templateData.templateAnalogStates[i]?.maxValue >= 0
							);
						},
					},
					maxNumberConditionally: {
						message: TranslateText('fieldsValidations.maxValue') + 999999,
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i > 1 ||
									(i === 1 && templateData.templateAnalogStates[i]?.maxValue === null) ||
									i + 1 > templateData.numberOfStates ||
									templateData.templateAnalogStates[i]?.maxValue <= 999999
							);
						},
					},
					higherThanPrevious: {
						message: TranslateText('fieldsValidations.higherThanPrevious'),
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									i > 1 ||
									i === 0 ||
									(i === 1 && templateData.templateAnalogStates[i]?.maxValue === null) ||
									i + 1 > templateData.numberOfStates ||
									templateData.templateAnalogStates[i - 1]?.maxValue === null ||
									templateData.templateAnalogStates[i - 1]?.maxValue.toString() === '' ||
									Number(templateData.templateAnalogStates[i]?.maxValue) >
										Number(templateData.templateAnalogStates[i - 1]?.maxValue)
							);
						},
					},
					lowerThanMaxValueTemplate: {
						message: TranslateText('fieldsValidations.templateLowerMaxStateValue'),
						validationFunction: (data: any) => {
							const templateData = data as Template;
							return ValidatorFunctions.wrapInPromise(
								templateData.templateType !== TemplateTypesEnum.Analog ||
									(i === 1 && templateData.templateAnalogStates[i]?.maxValue === null) ||
									i + 1 > templateData.numberOfStates ||
									templateData.templateAnalogStates[i]?.maxValue === null ||
									templateData.templateAnalogStates[i]?.maxValue.toString() === '' ||
									Number(templateData.templateAnalogStates[i]?.maxValue) <=
										Number(templateData.maxValue)
							);
						},
					},
				},
			} as FieldRule;
		}
		return rules;
	};
	validator.addRules(getRules());

	const getGenericTemplatesList = async (templateType: TemplateTypesEnum) => {
		if (templateType !== null) {
			const url = `${GlobalSettings.listsApi}/GenericTemplates?templateType=${templateType.toString()}`;
			const data = await ajaxUtil.get<TemplateDefinitionDto[]>(url);
			setGenericTemplateList(data);
			setGenericTemplateId('');
		}
	};

	useEffect(() => {
		setTemplate({ ...template, rank: template.rank ?? 0 });
		setInitialTemplate({ ...template, rank: template.rank ?? 0 });
		validator.validate(template).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
		});
		const genericTemplate = async () => {
			await getGenericTemplatesList(template.templateType);
		};

		genericTemplate();
		props.setUrlCallback(getUrlAdd());
	}, []);

	useEffect(() => {
		validator.validate(template).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(template);
			}
		});
	}, [template]);

	useEffect(() => {
		const genericTemplate = async () => {
			await getGenericTemplatesList(template.templateType);
		};
		genericTemplate();
		props.setUrlCallback(getUrlAdd());
		if (template.templateType === TemplateTypesEnum.Analog) {
			const array = [] as TemplateState[];
			for (let i = 0; i < template.numberOfStates; i++) {
				array.push(new TemplateState());
				array[i].name = defaultValueStateName[i].display;
			}
			setTemplate({ ...template, templateAnalogStates: array });
		}
	}, [template.templateType]);

	useEffect(() => {
		const array = newStates(template.numberOfStates);
		setTemplate({ ...template, templateAnalogStates: array });
	}, [template.numberOfStates]);
	const newStates = (x: number): TemplateState[] => {
		if (x < template.templateAnalogStates.length) {
			return template.templateAnalogStates.slice(0, x);
		} else {
			const array = [...template.templateAnalogStates];
			for (let i = array.length; i < x; i++) {
				array.push(new TemplateState());
			}
			for (let i = 0; i < x; i++) {
				if (array[i].name === '') {
					array[i].name = defaultValueStateName[i].display;
				}
			}
			return array;
		}
	};
	const handleValueChangeMaterial = (
		value: boolean | number | string | TemplateTypesEnum | TemplateEventActionEnum,
		statePropName: keyof Template
	) => {
		if (statePropName === 'templateType') {
			const tempTemplate = { ...template };

			if (value === TemplateTypesEnum.Event) {
				tempTemplate.relevantValue = false;
			} else {
				tempTemplate.relevantValue = true;
			}

			tempTemplate.templateType = value as TemplateTypesEnum;
			setInitialTemplate(tempTemplate);

			setTemplate(tempTemplate);
			setGenericTemplateId('');
			setUnsavedData(true);
		} else if (statePropName === 'generic') {
			if (value) {
				const tempTemplate = { ...template };
				const genericTemplate = genericTemplatesList.find((temp) => temp.id === value);

				tempTemplate.relevantValue = genericTemplate.relevantValue;
				tempTemplate.tripColor = genericTemplate.tripColor;
				tempTemplate.labelColor = genericTemplate.labelColor;
				tempTemplate.rank = genericTemplate.rank;

				if (genericTemplate.templateType === TemplateTypesEnum.Digital) {
					tempTemplate.countRelevantState = genericTemplate.countRelevantState;
					tempTemplate.durationCounter = genericTemplate.durationCounter;
					tempTemplate.showOnMap = genericTemplate.showOnMap;
					tempTemplate.relevantStateName = genericTemplate.relevantStateName;
					tempTemplate.relevantLabelColor = genericTemplate.relevantLabelColor;
					tempTemplate.relevantIconName = genericTemplate.relevantIconName;
					tempTemplate.nonRelevantStateName = genericTemplate.nonRelevantStateName;
					tempTemplate.nonRelevantLabelColor = genericTemplate.nonRelevantLabelColor;
					tempTemplate.nonRelevantIconName = genericTemplate.nonRelevantIconName;
					tempTemplate.graphColor = genericTemplate.graphColor;
				}

				if (genericTemplate.templateType === TemplateTypesEnum.Analog) {
					tempTemplate.durationCounter = genericTemplate.durationCounter;
					tempTemplate.maxValue = genericTemplate.maxValue;
					tempTemplate.minValue = genericTemplate.minValue;
					tempTemplate.graphColor = genericTemplate.graphColor;
					tempTemplate.unitOfMeasure = genericTemplate.unitOfMeasure;
					tempTemplate.numberOfStates = genericTemplate.numberOfStates;
					const genericTemplateStates = genericTemplate.templateAnalogStates.sort((x, y) => x.rank - y.rank);
					tempTemplate.templateAnalogStates = [...genericTemplateStates];
				}
				setTemplate(tempTemplate);
				setGenericTemplateId(value as string);
				setUnsavedData(true);
			} else {
				setTemplate({
					...initialTemplate,
					code: template.code,
					name: template.name,
					description: template.description,
				});
				setGenericTemplateId(value as string);
				setUnsavedData(true);
			}
		} else {
			const tempTemplate = { ...template };
			tempTemplate[statePropName] = value;
			setTemplate(tempTemplate);
			setUnsavedData(true);
			setGenericTemplateId('');
		}
	};

	const handleStateValueChange = async (
		index: number,
		value: boolean | number | string | TemplateTypesEnum | TemplateEventActionEnum,
		statePropName: keyof TemplateState
	) => {
		if (genericTemplateId !== '') {
			const genericTemplate = genericTemplatesList.find((temp) => temp.id === genericTemplateId);
			if (value !== genericTemplate?.templateAnalogStates.sort((x, y) => x.rank - y.rank)[index][statePropName]) {
				setGenericTemplateId('');
				await getGenericTemplatesList(template.templateType);
			}
		}
		const statesInit = [...template.templateAnalogStates];
		statesInit[index][statePropName] = value;
		setTemplate({ ...template, templateAnalogStates: statesInit });
		setUnsavedData(true);
	};
	const handleStateFieldsChange = (value: boolean) => {
		const tempTemplate = { ...template };
		tempTemplate.relevantValue = value;

		setTemplate(tempTemplate);
		setUnsavedData(true);
		setGenericTemplateId('');
	};
	const handleColorChange = (color: string, statePropName: string) => {
		if (
			(statePropName === 'tripColor' && template.tripColor === color) ||
			(statePropName === 'labelColor' && template.labelColor === color) ||
			(statePropName === 'graphColor' && template.graphColor === color)
		) {
			return;
		}
		const tempTemplate = { ...template };
		if (statePropName === 'tripColor') {
			tempTemplate.tripColor = color;
		} else if (statePropName === 'labelColor') {
			tempTemplate.labelColor = color;
		} else if (statePropName === 'graphColor'){
			tempTemplate.graphColor = color;
		}
		setTemplate(tempTemplate);
		setUnsavedData(true);
		setGenericTemplateId('');
	};
	const selectDefaultTripColor = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		handleColorChange('', 'tripColor');
	};

	const handleUnitFieldsChange = (value: TemplateUnitOfMeasureEnum) => {
		const tempTemplate = { ...template };
		tempTemplate.unitOfMeasure = value;

		setTemplate(tempTemplate);
		setUnsavedData(true);
		setGenericTemplateId('');
	};
	const getUrlAdd = (): string => {
		let addUrl = `${GlobalSettings.sensorTemplatesMaintenanceApi}/`;

		switch (template.templateType) {
			case TemplateTypesEnum.Event:
				addUrl += 'AddEventTemplate';
				break;
			case TemplateTypesEnum.Duration:
				addUrl += 'AddDurationTemplate';
				break;
			case TemplateTypesEnum.Digital:
				addUrl += 'AddDigitalTemplate';
				break;
			case TemplateTypesEnum.Analog:
				addUrl += 'AddAnalogTemplate';
				break;
			default:
				break;
		}
		return addUrl;
	};

	const getRank = () => {
		return (
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={true}
					id="templateRank"
					type="number"
					className="resize-font noSpinner"
					label={TranslateText('fields.template.rank')}
					inputProps={{ style: { fontSize: 10 } }}
					name="rank"
					value={template?.rank}
					handleValueChange={(value) => handleValueChangeMaterial(value, 'rank')}
					disabled={false}
					validationResult={validationResult?.rank}
					hasDefaultValue={true}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		);
	};
	const eventTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.eventAction}
						disabled={false}
						dataSource={getActionList()}
						name="eventAction"
						label={TranslateText('fields.action')}
						onChange={({ value }) => handleValueChangeMaterial(value as string, 'eventAction')}
						validationResult={validationResult?.eventAction}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.relevantValue}
						dataSource={stateLowHigh}
						name="relevantValue"
						disabled={false}
						label={TranslateText('fields.template.privateState')}
						onChange={({ value }) => handleStateFieldsChange(value as boolean)}
						validationResult={validationResult?.relevantValue}
					/>
				</div>
				{getRank()}
				<div className="form-group display-flex">
					<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
						{TranslateText('fields.template.tripColor')}
					</InputLabel>
					<ColorPicker
						initialColor={template.tripColor ? template.tripColor : ''}
						onColorChangedHandler={(color: string) => handleColorChange(color, 'tripColor')}
						disabled={false}
					/>
					<span style={{ float: 'right', marginLeft: 'auto' }}>
						<Button disabled={false} hidden={false} onClick={(e) => selectDefaultTripColor(e)}>
							{TranslateText('fields.default')}
						</Button>
					</span>
				</div>
				<div className="form-group display-flex">
					<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
						{TranslateText('fields.template.labelColor')}
					</InputLabel>
					<ColorPicker
						initialColor={template.labelColor ? template.labelColor : ''}
						onColorChangedHandler={(color: string) => handleColorChange(color, 'labelColor')}
						disabled={false}
					/>
				</div>
			</div>
		);
	};

	const digitalTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.relevantValue}
						dataSource={stateLowHigh}
						name="relevantValue"
						disabled={false}
						label={TranslateText('fields.template.relevantState')}
						onChange={({ value }) => handleStateFieldsChange(value as boolean)}
						validationResult={validationResult?.relevantValue}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						isForNewAddWizard={true}
						value={template.countRelevantState}
						name={'countRelevantState'}
						readonly={false}
						placeholder={TranslateText('fields.template.countRelevantState')}
						change={(value) => handleValueChangeMaterial(value as boolean, 'countRelevantState')}
						validationResult={validationResult}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						isForNewAddWizard={true}
						value={template.durationCounter}
						name={'durationCounter'}
						readonly={false}
						placeholder={TranslateText('fields.template.durationCounter')}
						change={(value) => handleValueChangeMaterial(value as boolean, 'durationCounter')}
						validationResult={validationResult}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						isForNewAddWizard={true}
						value={template.showOnMap}
						name={'showOnMap'}
						readonly={false}
						placeholder={TranslateText('fields.template.showOnMap')}
						change={(value) => handleValueChangeMaterial(value as boolean, 'showOnMap')}
						validationResult={validationResult}
					/>
				</div>
				{getRank()}
				<div className="form-group display-flex">
					<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
						{TranslateText('fields.template.graphColor')}
					</InputLabel>
					<ColorPicker
						initialColor={template.graphColor ? template.graphColor : ''}
						onColorChangedHandler={(color: string) => handleColorChange(color, 'graphColor')}
						disabled={false}
					/>
				</div>
				{template.showOnMap && (
					<div className="form-group display-flex">
						<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
							{TranslateText('fields.template.tripColor')}
						</InputLabel>
						<ColorPicker
							initialColor={template.tripColor ? template.tripColor : ''}
							onColorChangedHandler={(color: string) => handleColorChange(color, 'tripColor')}
							disabled={false}
						/>
						<span style={{ float: 'right', marginLeft: 'auto' }}>
							<Button disabled={false} hidden={false} onClick={(e) => selectDefaultTripColor(e)}>
								{TranslateText('fields.default')}
							</Button>
						</span>
					</div>
				)}
				<div className={'relevant-state-fields'}>
					<div style={{ opacity: 1 }}>{TranslateText('fields.template.relevantState')}</div>
					<div style={{ marginLeft: 10 }}>
						<div className="form-group">
							<MaterialTextField
								isForNewAddWizard={true}
								id="relevantStateName"
								className="resize-font"
								label={TranslateText('fields.template.stateName')}
								inputProps={{ style: { fontSize: 10 } }}
								name="relevantStateName"
								value={template.relevantStateName}
								handleValueChange={(value) => handleValueChangeMaterial(value, 'relevantStateName')}
								disabled={false}
								validationResult={validationResult?.relevantStateName}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelFleetTagColor')}
							</InputLabel>
							<ColorPicker
								initialColor={template.relevantLabelColor ?? ''}
								onColorChangedHandler={(color: string) =>
									handleValueChangeMaterial(color, 'relevantLabelColor')
								}
								disabled={false}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelFleetTagIconName')}
							</InputLabel>
							<div style={{ marginLeft: 8 }}>
								<IconPickerWrapper
									iconName={template.relevantIconName}
									onIconSelected={(name, content, iconSet) =>
										handleValueChangeMaterial(
											iconNoImage.name === name ? null : `${iconSet}/${name}`,
											'relevantIconName'
										)
									}
									readOnly={false}
									containers={['sensor-templates']}
									allowNoImage={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={'relevant-state-fields'}>
					<div style={{ opacity: 1 }}>{TranslateText('fields.template.nonRelevantState')}</div>
					<div style={{ marginLeft: 10 }}>
						<div className="form-group">
							<MaterialTextField
								isForNewAddWizard={true}
								id="nonRelevantStateName"
								className="resize-font"
								label={TranslateText('fields.template.stateName')}
								inputProps={{ style: { fontSize: 10 } }}
								name="nonRelevantStateName"
								value={template.nonRelevantStateName}
								handleValueChange={(value) => handleValueChangeMaterial(value, 'nonRelevantStateName')}
								disabled={false}
								validationResult={validationResult?.nonRelevantStateName}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelFleetTagColor')}
							</InputLabel>
							<ColorPicker
								initialColor={template.nonRelevantLabelColor ?? ''}
								onColorChangedHandler={(color: string) =>
									handleValueChangeMaterial(color, 'nonRelevantLabelColor')
								}
								disabled={false}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelFleetTagIconName')}
							</InputLabel>
							<div style={{ marginLeft: 8 }}>
								<IconPickerWrapper
									iconName={template.nonRelevantIconName}
									onIconSelected={(name, content, iconSet) =>
										handleValueChangeMaterial(
											iconNoImage.name === name ? null : `${iconSet}/${name}`,
											'nonRelevantIconName'
										)
									}
									readOnly={false}
									containers={['sensor-templates']}
									allowNoImage={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const analogTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<YesNoCombobox
						isForNewAddWizard={true}
						value={template.durationCounter}
						name={'durationCounter'}
						readonly={false}
						placeholder={TranslateText('fields.template.durationCounter')}
						change={(value) => handleValueChangeMaterial(value as boolean, 'durationCounter')}
						validationResult={validationResult}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.unitOfMeasure}
						dataSource={unitValues}
						name="unit"
						disabled={false}
						label={TranslateText('fields.template.unit')}
						onChange={({ value }) => handleUnitFieldsChange(value as TemplateUnitOfMeasureEnum)}
						validationResult={validationResult?.unitOfMeasure}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={true}
						id="templateMaxValue"
						type="number"
						className="resize-font noSpinner"
						label={TranslateText('fields.template.maxValue')}
						inputProps={{ style: { fontSize: 10 } }}
						name="maxValue"
						value={template?.maxValue}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'maxValue')}
						disabled={false}
						hasDefaultValue={false}
						validationResult={validationResult?.maxValue}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={true}
						id="templateMinValue"
						type="number"
						className="resize-font noSpinner"
						label={TranslateText('fields.template.minValue')}
						inputProps={{ style: { fontSize: 10 } }}
						name="minValue"
						value={template?.minValue}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'minValue')}
						disabled={false}
						hasDefaultValue={false}
						validationResult={validationResult?.minValue}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
					/>
				</div>
				{getRank()}
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.numberOfStates}
						dataSource={stateDefinitionsValues(2, 5)}
						name="numberOfStates"
						disabled={false}
						label={TranslateText('fields.template.numberOfStates')}
						onChange={({ value }) => handleValueChangeMaterial(value as number, 'numberOfStates')}
						validationResult={validationResult?.numberOfStates}
					/>
				</div>
				<div className="form-group display-flex">
					<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
						{TranslateText('fields.template.graphColor')}
					</InputLabel>
					<ColorPicker
						initialColor={template.graphColor ? template.graphColor : ''}
						onColorChangedHandler={(color: string) => handleColorChange(color, 'graphColor')}
						disabled={false}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						isForNewAddWizard={true}
						value={template.showOnMap}
						name={'showOnMap'}
						readonly={false}
						placeholder={TranslateText('fields.template.showOnMap')}
						change={(value) => handleValueChangeMaterial(value as boolean, 'showOnMap')}
						validationResult={validationResult}
					/>
				</div>
			</div>
		);
	};

	const statesAnalogType = () => {
		return (
			<div className={'state-view-scrollable'}>
				{template.templateAnalogStates.map((state, index: number) => (
					<div className={'state-view-container'} key={index}>
						<div className="form-group">
							<MaterialTextField
								isForNewAddWizard={true}
								id="stateName"
								className="resize-font"
								label={TranslateText('fields.template.stateName')}
								inputProps={{ style: { fontSize: 10 } }}
								name="code"
								value={state?.name}
								handleValueChange={(value) => handleStateValueChange(index, value, 'name')}
								disabled={false}
								validationResult={validationResult?.[`name${index}`]}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelStateTagColor')}
							</InputLabel>
							<ColorPicker
								initialColor={state.labelColor ?? ''}
								onColorChangedHandler={(color: string) =>
									handleStateValueChange(index, color, 'labelColor')
								}
								disabled={false}
							/>
						</div>
						<div className="form-group display-flex">
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.template.labelStateTagIconName')}
							</InputLabel>
							<div style={{ marginLeft: 8 }}>
								<IconPickerWrapper
									iconName={state.iconName}
									onIconSelected={(name, content, iconSet) =>
										handleStateValueChange(
											index,
											iconNoImage.name === name ? null : `${iconSet}/${name}`,
											'iconName'
										)
									}
									readOnly={false}
									containers={['sensor-templates']}
									allowNoImage={true}
								/>
							</div>
						</div>
						<div className="form-group">
							<MaterialTextField
								isForNewAddWizard={true}
								id="templateStateMaxValue"
								type="number"
								className="resize-font noSpinner"
								label={TranslateText('fields.template.maxValue')}
								inputProps={{ style: { fontSize: 10 } }}
								name="maxValue"
								value={state.maxValue}
								handleValueChange={(value) =>
									handleStateValueChange(index, value ? parseFloat(value) : null, 'maxValue')
								}
								disabled={false}
								validationResult={validationResult?.[`stateMaxValue${index}`]}
								onKeyDown={handleKeyDown}
								onWheel={(e: any) => e.target.blur()}
							/>
						</div>
					</div>
				))}
			</div>
		);
	};

	const durationTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={template.relevantValue}
						dataSource={stateLowHigh}
						name="relevantValue"
						disabled={false}
						label={TranslateText('fields.template.relevantState')}
						onChange={({ value }) => handleStateFieldsChange(value as boolean)}
						validationResult={validationResult?.relevantValue}
					/>
				</div>
				{getRank()}
			</div>
		);
	};

	return (
		<div className={'template-view-container'}>
			<div
				className={'template-column'}
				style={template.templateType === TemplateTypesEnum.Analog ? { width: '33.33%' } : { width: '50%' }}
			>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.systemInformation')}</div>
					<form id="templateViewSystemInformationForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialTextField
									autoFocus={true}
									isForNewAddWizard={true}
									id="templateCode"
									className="resize-font"
									label={TranslateText('fields.code')}
									inputProps={{ style: { fontSize: 10 } }}
									name="code"
									value={template.code?.trimStart()}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'code')}
									disabled={false}
									validationResult={validationResult?.code}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="name"
									className="resize-font"
									label={TranslateText('fields.name')}
									inputProps={{ style: { fontSize: 10 } }}
									name="name"
									value={template.name?.trimStart()}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'name')}
									disabled={false}
									validationResult={validationResult?.name}
								/>
							</div>
							<CustomerNameDisplay
								customerId={!!defaultCustomerId?.length ? defaultCustomerId : template.customerId}
								isForNewAddWizard={true}
							/>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="description"
									className="resize-font"
									label={TranslateText('fields.description')}
									inputProps={{ style: { fontSize: 10 } }}
									name="description"
									value={template.description?.trimStart()}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'description')}
									disabled={false}
									multiline={true}
									validationResult={validationResult?.description}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div
				className={'template-column'}
				style={template.templateType === TemplateTypesEnum.Analog ? { width: '33.33%' } : { width: '50%' }}
			>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.templateData')}</div>
					<form id="templateDataForm" noValidate={true}>
						<div className="form-group">
							<MaterialAutocomplete
								isForNewAddWizard={true}
								valueId={template.templateType}
								dataSource={getTemplateTypes()}
								name="templateType"
								disabled={false}
								label={TranslateText('fields.type')}
								onChange={({ value }) =>
									handleValueChangeMaterial(value as TemplateTypesEnum, 'templateType')
								}
								validationResult={validationResult?.templateType}
							/>
						</div>
						<div className="form-group">
							<MaterialAutocomplete
								isForNewAddWizard={true}
								valueId={genericTemplateId?.toString()}
								dataSource={genericTemplatesList}
								name="generic"
								disabled={!template.templateType}
								label={TranslateText('fields.generic')}
								onChange={({ value }) => handleValueChangeMaterial(value as string, 'generic')}
							/>
						</div>
						<hr className="line" />
						{template.templateType === TemplateTypesEnum.Event
							? eventTypeData()
							: template.templateType === TemplateTypesEnum.Digital
							? digitalTypeData()
							: template.templateType === TemplateTypesEnum.Analog
							? analogTypeData()
							: template.templateType === TemplateTypesEnum.Duration
							? durationTypeData()
							: null}
					</form>
				</div>
			</div>
			{template.templateType === TemplateTypesEnum.Analog && (
				<div className={'template-column'} style={{ width: '33.33%' }}>
					<div className={'column-content'}>
						<div className={'column-title'}>{TranslateText('common.templateStateDefinition')}</div>
						<form id="templateDataForm" noValidate={true}>
							{statesAnalogType()}
						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default TemplateView;
