enum FilterFieldTypeEnum {
	customerId = 'CustomerId',
	objectId = 'ObjectId',
	personId = 'PersonId',
	deviceId = 'DeviceId',
	keyId = 'KeyId',
	simCardId = 'SimCardId',
}

export default FilterFieldTypeEnum;
