import './styles.scss';

import React, { useEffect, useRef, useState } from 'react';

import { InfoBox, useGoogleMap } from '@react-google-maps/api';

import { LocationLabel, LocationsLabelsProps } from './types';
import { updateLocationLabels } from './utils';

const LocationsLabels = (props: LocationsLabelsProps): JSX.Element => {
	const map = useGoogleMap();

	//use ref array with ids to not remove and add bounds_changed on every change
	const locationsLabelsIds = useRef<string[]>([]);
	const [locationsLabels, setLocationsLabels] = useState<LocationLabel[]>([]);

	useEffect(() => {
		let boundsChangedListener: google.maps.MapsEventListener | null = null;
		if (map) {
			//change location labels when deselect category or change bounds
			boundsChangedListener = google.maps.event.addListener(map, 'bounds_changed', () => {
				updateLocationLabels(map, props.locations, locationsLabelsIds, setLocationsLabels);
			});
			updateLocationLabels(map, props.locations, locationsLabelsIds, setLocationsLabels);
		}
		return () => {
			if (boundsChangedListener) {
				google.maps.event.removeListener(boundsChangedListener);
			}
		};
	}, [map, props.locations]);

	return (
		<>
			{locationsLabels.length
				? locationsLabels.map((ll) => (
						<InfoBox
							key={`location-label-${ll.id}`}
							position={ll}
							options={{
								disableAutoPan: true,
								boxClass: 'locations-labels',
								closeBoxURL: '',
								closeBoxMargin: '0px',
								pixelOffset: new google.maps.Size(7, -14, 'px', 'px'),
							}}
						>
							<div className="location-label-content">{ll.name}</div>
						</InfoBox>
				  ))
				: null}
		</>
	);
};

export default LocationsLabels;
