import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button as MaterialButton } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import DateTimeUtil, { DateTimeConversionUtil } from '../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { DurationCorrectionDialog } from '../DurationCorrectionDialog';
import { DurationCorrectionRowData } from '../DurationCorrectionDialog/types';
import { GridWidgetOverview } from '../GridOverview/GridWidgetOverview';
import MileageCorrectionDialog from '../MileageCorrectionDialog/MileageCorrectionDialog';
import RadioButtonContainer, { ButtonConfig } from '../RadioButtonContainer';
import { IButtonWidgetView } from './ButtonWidget/ButtonWidget';

//extend with custom props
type Props = IButtonWidgetView;

export interface MileageCorrectionRowData {
	id: string;
	requestDate: Date | string;
	oldMileage: number;
	newMileage: number;
	personName: string;
}

enum CorrectionType {
	Driver = 1,
	Mileage = 2,
	OperationHours = 3,
	Duration = 4,
}

const CorrectionsView = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [isDialogEditMode, setIsDialogEditMode] = useState(false);
	const [editRowData, setEditRowData] = useState<MileageCorrectionRowData | DurationCorrectionRowData>();
	const timezonesDataSource = useSelector((state: ApplicationState) => state.commonData.timezonesDataSource);
	const timeZone = timezonesDataSource.find((t) => t.id == (props.getDataCallback() as string));
	const templatesFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.templates
			: s.currentSession.customer.featuresSettings.templates
	);
	const [correctionType, setCorrectionType] = useState<CorrectionType>(
		ClaimUtil.validateHasClaim(user, ClaimType.MileageCorrection)
			? CorrectionType.Mileage
			: ClaimUtil.validateHasClaim(user, ClaimType.DurationCorrection) && templatesFeature
			? CorrectionType.Duration
			: null
	);

	useEffect(() => {
		switch (correctionType) {
			case CorrectionType.Mileage:
				props.setUrl(`${GlobalSettings.objectsMaintenanceApi}/mileageCorrectionRequests/${props.entityId}`);
				break;
			case CorrectionType.Duration:
				props.setUrl(`${GlobalSettings.objectsMaintenanceApi}/durationCorrectionRequests/${props.entityId}`);
				break;
			default:
				break;
		}
	}, [correctionType]);

	const buttons = {
		getButtonConfig: () => {
			const buttons = [] as ButtonConfig[];
			// if (ClaimUtil.validateHasClaim(user, ClaimType.Persons) && driverIdentification) {
			// 	buttons.push({
			// 		title: TranslateText('common.driver'),
			// 		id: CorrectionType.Driver,
			// 	});
			// }
			if (ClaimUtil.validateHasClaim(user, ClaimType.MileageCorrection)) {
				buttons.push({
					title: TranslateText('common.mileage'),
					id: CorrectionType.Mileage,
				});
			}
			if (ClaimUtil.validateHasClaim(user, ClaimType.DurationCorrection) && templatesFeature) {
				buttons.push({
					title: TranslateText('common.duration'),
					id: CorrectionType.Duration,
				});
			}
			return buttons;
		},
	};

	const mileageCorrectionEvents = {
		addMileageCorrection: async (newCorrection: MileageCorrectionRowData) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/${props.entityId}/addMileageCorrection`, {
				newMileage: newCorrection.newMileage,
				requestDate: addNewRequestDataFormat(newCorrection.requestDate as string),
			});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
		updateMileageCorrection: async (data: MileageCorrectionRowData) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/updateMileageCorrection`, {
				newMileage: data.newMileage,
				id: data.id,
			});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
		closeDialog: () => {
			setDialogVisible(false);
		},
		removeCorrection: async (correctionId: string) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/removeMileageCorrection/${correctionId}`, {});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
	};

	const durationCorrectionEvents = {
		addDurationCorrection: async (newCorrection: DurationCorrectionRowData) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/${props.entityId}/addDurationCorrection`, {
				newDuration: newCorrection.newDuration,
				templateId: newCorrection.templateId,
				sensorType: newCorrection.sensorType,
				requestDate: newCorrection.requestDate,
			});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
		updateDurationCorrection: async (data: DurationCorrectionRowData) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/updateDurationCorrection`, {
				id: data.id,
				newDuration: data.newDuration,
				templateId: data.templateId,
				sensorType: data.sensorType,
				requestDate: data.requestDate,
			});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
		closeDialog: () => {
			setDialogVisible(false);
		},
		removeCorrection: async (correctionId: string) => {
			await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/removeDurationCorrection/${correctionId}`, {});
			setDialogVisible(false);
			props.reloadDataCallback();
		},
	};

	const addNewRequestDataFormat = (date: string): string => {
		const regex = /(\w+\/\w+)/;
		const nameTimeZone = timeZone.display.match(regex)[1].toString();
		const abrTimeZone = moment.tz(nameTimeZone).format('zZ');

		const dateMatch = moment(date).format('YYYY-MM-DD');
		const timeMatch = moment(date).format('HH:mm:ss');
		const timeZoneMatch = abrTimeZone.match(/([+-]\d{2}:\d{2})/);

		const dateTimeString = `${dateMatch}T${timeMatch}${timeZoneMatch[1]}`;
		const dateObject = new Date(dateTimeString);
		return dateObject.toISOString();
	};

	const activationDateFormat = (date: string = null): string => {
		const regex = /(\w+\/\w+)/;
		const name = timeZone.display.match(regex)[1].toString();
		if (date !== null) {
			return moment(date)
				.tz(name)
				.format(DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeFormat(), true));
		}
		return moment()
			.tz(name)
			.format('YYYY-MM-DD HH:mm:ss');
	};
	const getMileageCorrectionColumns = () => {
		return [
			<Column
				key="requestDate"
				field="requestDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colDateTime')}
				style={{ width: 150 }}
				body={(rowData: any) => {
					return activationDateFormat(rowData.requestDate);
				}}
			/>,
			<Column
				key="timeZone"
				field="timeZone"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colTimeZone')}
				style={{ width: 150 }}
				body={() => {
					return timeZone?.display;
				}}
			/>,
			<Column
				key="oldMileage"
				field="oldMileage"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colOldMileage')}
				style={{ width: 120 }}
			/>,
			<Column
				key="newMileage"
				field="newMileage"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colNewMileage')}
				style={{ width: 120 }}
			/>,
			ClaimUtil.validateHasClaim(user, ClaimType.Persons) ? (
				<Column
					key="personName"
					field="personName"
					className="widget-grid-column"
					header={TranslateText('widgets.grid.colPerson')}
					style={{ width: 180 }}
				/>
			) : null,
			props.editMode &&
			ClaimUtil.validateClaim(user, {
				claim: ClaimType.MileageCorrection,
				values: [ClaimValue.edit],
			}) ? (
				<Column
					key="buttons"
					className="widget-grid-column"
					style={{ width: '100px' }}
					body={(rowData: any) => (
						<Button
							icon="e-edit e-icons"
							className="p-button-rounded p-button-text p-button-plain"
							onClick={() => {
								setEditRowData(rowData as MileageCorrectionRowData);
								setIsDialogEditMode(true);
								setDialogVisible(true);
							}}
						/>
					)}
				/>
			) : null,
		];
	};

	const getDurationCorrectionColumns = () => {
		return [
			<Column
				key="requestDate"
				field="requestDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colDateTime')}
				style={{ width: 150 }}
				body={(rowData: any) => {
					return FormatDate(new Date(rowData.requestDate));
				}}
			/>,
			<Column
				key="templateName"
				field="templateName"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colTemplateName')}
				style={{ width: 150 }}
			/>,
			<Column
				key="oldDuration"
				field="oldDuration"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colOldDuration')}
				style={{ width: 120 }}
				body={(rowData: DurationCorrectionRowData) => {
					const hoursAndMinutes = DateTimeUtil.fromMinutesToHoursAndMinutes(rowData?.oldDuration);
					return `${hoursAndMinutes.hours}:${
						hoursAndMinutes.minutes < 10 ? '0' + hoursAndMinutes.minutes : hoursAndMinutes.minutes
					}`;
				}}
			/>,
			<Column
				key="newDuration"
				field="newDuration"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colNewDuration')}
				style={{ width: 120 }}
				body={(rowData: DurationCorrectionRowData) => {
					const hoursAndMinutes = DateTimeUtil.fromMinutesToHoursAndMinutes(rowData?.newDuration);
					return `${hoursAndMinutes.hours}:${
						hoursAndMinutes.minutes < 10 ? '0' + hoursAndMinutes.minutes : hoursAndMinutes.minutes
					}`;
				}}
			/>,
			ClaimUtil.validateHasClaim(user, ClaimType.Persons) ? (
				<Column
					key="personName"
					field="personName"
					className="widget-grid-column"
					header={TranslateText('widgets.grid.colPerson')}
					style={{ width: 180 }}
				/>
			) : null,
			props.editMode &&
			ClaimUtil.validateClaim(user, {
				claim: ClaimType.DurationCorrection,
				values: [ClaimValue.edit],
			}) ? (
				<Column
					key="buttons"
					className="widget-grid-column"
					style={{ width: '100px' }}
					body={(rowData: any) => (
						<Button
							icon="e-edit e-icons"
							className="p-button-rounded p-button-text p-button-plain"
							onClick={() => {
								setEditRowData(rowData as DurationCorrectionRowData);
								setIsDialogEditMode(true);
								setDialogVisible(true);
							}}
						/>
					)}
				/>
			) : null,
		];
	};

	const size = () => {
		if (Array.isArray(props.data)) {
			return props.data.length;
		}
		return Object.keys(props.data).length;
	};

	const verifyHasEditClaim = (correctionType: CorrectionType) => {
		switch (correctionType) {
			case CorrectionType.Mileage:
				return ClaimUtil.validateClaim(user, { claim: ClaimType.MileageCorrection, values: [ClaimValue.edit] });
			case CorrectionType.Duration:
				return ClaimUtil.validateClaim(user, {
					claim: ClaimType.DurationCorrection,
					values: [ClaimValue.edit],
				});
			default:
				return false;
		}
	};

	return (
		<>
			<RadioButtonContainer
				buttons={buttons.getButtonConfig()}
				selectedButton={correctionType}
				buttonSelectCallback={setCorrectionType}
			/>

			<Box
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}
				height={'100%'}
				className={'corrections-table-container'}
			>
				{correctionType === CorrectionType.Mileage ? (
					<>
						<GridWidgetOverview
							columns={getMileageCorrectionColumns()}
							data={props.data}
							total={size() ?? 0}
							paginator={true}
						/>
						{dialogVisible && (
							<MileageCorrectionDialog
								visible={dialogVisible}
								isEditMode={isDialogEditMode}
								initialData={isDialogEditMode ? (editRowData as MileageCorrectionRowData) : null}
								dialogTitle={
									isDialogEditMode
										? TranslateText('widgets.editMileageCorrection')
										: TranslateText('widgets.mileageCorrection')
								}
								closeCallback={mileageCorrectionEvents.closeDialog}
								saveCallback={
									isDialogEditMode
										? mileageCorrectionEvents.updateMileageCorrection
										: mileageCorrectionEvents.addMileageCorrection
								}
								removeCallback={mileageCorrectionEvents.removeCorrection}
								changeDataCallback={props.changeDataCallback.bind(this)}
								timeZone={timeZone}
								activationDate={activationDateFormat}
							/>
						)}
					</>
				) : null}
				{correctionType === CorrectionType.Duration ? (
					<>
						<GridWidgetOverview
							columns={getDurationCorrectionColumns()}
							data={props.data}
							total={size() ?? 0}
							paginator={true}
						/>
						{dialogVisible && (
							<DurationCorrectionDialog
								visible={dialogVisible}
								isEditMode={isDialogEditMode}
								initialData={isDialogEditMode ? (editRowData as DurationCorrectionRowData) : null}
								dialogTitle={
									isDialogEditMode
										? TranslateText('widgets.editDurationCorrection')
										: TranslateText('widgets.durationCorrection')
								}
								closeCallback={durationCorrectionEvents.closeDialog}
								saveCallback={
									isDialogEditMode
										? durationCorrectionEvents.updateDurationCorrection
										: durationCorrectionEvents.addDurationCorrection
								}
								removeCallback={durationCorrectionEvents.removeCorrection}
								changeDataCallback={props.changeDataCallback.bind(this)}
								entityId={props.entityId}
							/>
						)}
					</>
				) : null}
				{props.editMode && verifyHasEditClaim(correctionType) && (
					<div id={'buttons-host'} className={'buttons-host'}>
						<MaterialButton
							className="widget-button add-button"
							onClick={() => {
								setIsDialogEditMode((editMode) =>
									((result) => {
										setDialogVisible(true);
										return result;
									})(false)
								);
							}}
						>
							{TranslateText('common.buttonNew')}
						</MaterialButton>
					</div>
				)}
			</Box>
		</>
	);
};

export default CorrectionsView;
