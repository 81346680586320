import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { enableRipple } from '@syncfusion/ej2-base';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import ObjectGroupsView from '../../components/Widgets/Views/ObjectGroupsView';
import * as GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import IdIdentifiable from '../../models/IdIdentifiable';
import TrackableObject from '../../models/TrackableObject';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { GetSelectedCustomer } from '../../utils/CustomerUtils';
import { TranslateText } from '../../utils/Translations';
import BaseDetails from '../BaseDetails';
import { BaseDetailsProps, BaseDetailsState } from '../BaseDetails/BaseDetails';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import DetailsNavigationBar from '../DetailsNavigationBar/DetailsNavigationBar';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import ButtonWidget from '../Widgets/ButtonWidget';
import AddGroupToObjectActionButton from '../Widgets/ButtonWidget/AddGroupToObjectActionButton';
import CorrectionsView from '../Widgets/CorrectionsView';
import CustomerWidget from '../Widgets/CustomerWidget';
import DeviceObjectWidget from '../Widgets/DeviceObjectWidget';
import LogWidget from '../Widgets/LogWidget';
import ObjectInformationWidget from '../Widgets/ObjectInformationWidget';
import PersonObjectWidget from '../Widgets/PersonObjectWidget';
import StateWidget from '../Widgets/StateWidget';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';

enableRipple(true);

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		editingInstance: state.loadedEntityContext.editingInstance,
		currentSession: state.currentSession,
		filteredCustomerId: state.globalCustomer.filteredCustomer?.id,
		filterText: state.globalCustomer.filterText.objects,
		gridFilters: state.gridOverview[EntityTypeEnum.Object].gridFilters,
		user: state.oidc.user,
		loggedCustomerLevel: state.currentSession.customerLevel,
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification,
		entityDetails: state.loadedEntityContext?.entityContextData?.entityDetails,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
		setUnsavedDataInStore: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

type MatchProps = {
	match: { params: { [key: string]: string } };
};

const connector = connect(mapState, mapDispatchToProps);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type ObjectDetailsProps = BaseDetailsProps & PropsFromRedux & MatchProps & {};

interface IObjectDetailsState extends BaseDetailsState {
	correctionWidgetVisible: boolean;
}

class ObjectDetails extends BaseDetails<ObjectDetailsProps, IObjectDetailsState> {
	constructor(props: ObjectDetailsProps) {
		super(props);

		this.state = {
			...this.state,
			currentRecordId: props.match.params.id,
			correctionWidgetVisible: false,
		};
	}

	connectionConflictsNotifierRef: ConnectionConflictsNotifier;
	objectInformationWidgetObj: ObjectInformationWidget;
	stateWidgetObj: any;

	componentDidUpdate(prevProps: ObjectDetailsProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.setState({
				currentRecordId: this.props.match.params.id,
			});
		}
	}

	componentWillUnmount() {
		this.loadedWidgets = [];
		this.props.loadedEntityContextActions.setEditingInstance(null);
		this.props.loadedEntityContextActions.setLoadedObjectContext(null);
	}

	getloadedEntityCustomerId(): string {
		return GetSelectedCustomer().id;
	}

	displayCallback(object: TrackableObject) {
		this.setState({
			breadcrumbDisplay: TranslateText('common.objects') + ' > ' + object.name,
			reloadInfo: false,
		});
		this.props.loadedEntityContextActions.setLoadedObjectContext(object);

		this.setState({
			correctionWidgetVisible: object.editableMileage,
		});
	}

	protected fillWidgets(recordId: string) {
		// Put Here the code to update the contained widgets ( e.g.  fillData methods of the widgets)
		this.objectInformationWidgetObj.fillObject(recordId);
		this.stateWidgetObj.fillData(recordId);
	}

	protected fillInformationWidget(recordId: string) {
		this.objectInformationWidgetObj.fillObject(recordId);
	}

	protected closeEditModeOnInformationWidget() {
		this.objectInformationWidgetObj.closeEditPanelMode();
	}

	handleConfictingConnections(sender: any, confirm: boolean) {
		sender.handleConflictingConnections(confirm);
	}

	handleDataSaved() {
		this.setState({ reloadInfo: true });
	}

	protected handleGoBackTo(show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) {
		this.props.history.push('/objects', { id: syncRecord.id });
	}

	render() {
		this.loadedWidgets = [];
		return (
			<div className="content-container">
				<NotificationPrompt
					title={TranslateText('common.titleUnsavedData')}
					message={TranslateText('notificationMessages.cancel')}
					handleUserResponse={this.handleUnsavedDataResponse.bind(this)}
					displayDialog={this.state.notifyAboutUnsavedData}
				/>
				<DetailsNavigationBar
					history={this.props.history}
					currentRecordId={this.state.currentRecordId}
					goBackText={TranslateText('detailsScreen.navigationBar.backToObjects')}
					goBackToCallback={this.handleGoBackTo.bind(this)}
					breadcrumbsText={this.state.breadcrumbDisplay}
					navigationUrl={`${GlobalSettings.objectsMaintenanceApi}/navigation`}
					detailUrl={GlobalSettings.route.objects}
					filter={{
						customerId: this.props.filteredCustomerId,
						filterText: this.props.filterText,
						gridFilters: this.props.gridFilters,
					}}
					entityType={EntityTypeEnum.Object}
				/>
				<ConnectionConflictsNotifier
					ref={(scope) => {
						this.connectionConflictsNotifierRef = scope;
					}}
					connectionType={ConnectionTypeEnum.DeviceObject}
					visible={false}
					userAnswearCallback={this.handleConfictingConnections.bind(this)}
				/>
				<Scrollbar
					style={{
						maxHeight: 'calc(100vh - 110px)',
						minHeight: 'calc(100vh - 110px)',
					}}
					native={true}
				>
					<DashboardLayoutComponent
						immediateRender={true}
						draggableHandle=".dragicon"
						mediaQuery={'max-width: 1100px'}
						allowResizing={true}
						allowFloating={true}
						allowPushing={true}
						columns={20}
						cellAspectRatio={95 / 100}
						ref={(scope) => {
							this.dashboardObj = scope;
						}}
						id="predefine_dashboard"
						cellSpacing={this.cellSpacing}
					>
						<ObjectInformationWidget
							customerId={this.props.currentSession.customerId}
							row="0"
							col="0"
							sizeX="4"
							sizeY="6"
							savedCallback={this.handleSavedCallback.bind(this)}
							id={'objectInformation'}
							notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
							displayCallback={this.displayCallback.bind(this)}
							changeDataCallback={this.changeDataCallback.bind(this)}
							entityId={this.state.currentRecordId}
							ref={(scope) => {
								this.objectInformationWidgetObj = scope;
							}}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							forceReload={this.state.reloadInfo}
							loadDevices={ClaimUtil.validateHasClaim(this.props.user, ClaimType.Devices)}
						/>
						<ProtectedContainer
							claimConfig={[
								{ claim: ClaimType.Devices, values: [ClaimValue.view] },
								{ claim: ClaimType.Objects, values: [ClaimValue.view] },
								{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
							]}
						>
							<DeviceObjectWidget
								row="0"
								col="4"
								sizeX="11"
								sizeY="3"
								conflictingConnectionNotifier={this.connectionConflictsNotifierRef}
								id={'deviceHistory'}
								entityType={EntityTypeEnum.Object}
								entityId={this.state.currentRecordId}
								allowEditMode={!this.state.widgetEditing}
								editModeCallback={this.editModeCallback.bind(this)}
								changeDataCallback={this.changeDataCallback.bind(this)}
								savedCallback={this.handleDataSaved.bind(this)}
							/>
						</ProtectedContainer>
						<ProtectedContainer
							optionalClaimList={[ClaimType.Persons, ClaimType.Drivers]}
							claimConfig={[
								{ claim: ClaimType.Objects, values: [ClaimValue.view] },
								{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
							]}
						>
							<PersonObjectWidget
								hidden={
									!(
										this.props.driverIdentification &&
										this.props.entityDetails?.objectFunction === ObjectFunctionEnum.Full
									)
								}
								row="6"
								col="7"
								sizeX="8"
								sizeY="3"
								conflictingConnectionNotifier={this.connectionConflictsNotifierRef}
								savedCallback={this.handleSavedCallback.bind(this)}
								changeDataCallback={this.changeDataCallback.bind(this)}
								id={'personHistory'}
								entityType={EntityTypeEnum.Object}
								entityId={this.state.currentRecordId}
								allowEditMode={!this.state.widgetEditing}
								ref={(scope) => {
									this.AddLoadedWidget(scope);
								}}
								editModeCallback={this.editModeCallback.bind(this)}
								user={this.props.user}
								driverIdentification={this.props.driverIdentification}
								loggedCustomerLevel={this.props.loggedCustomerLevel}
							/>
						</ProtectedContainer>
						<ProtectedContainer
							optionalClaimList={[ClaimType.MileageCorrection, ClaimType.DurationCorrection]}
						>
							<ButtonWidget
								position={{
									row: 3,
									col: 4,
									sizeX: 11,
									sizeY: 3,
								}}
								url={null}
								exitEditModeOnUrlChange={false}
								entityId={this.state.currentRecordId}
								editClaimConfig={[
									{ claim: ClaimType.Objects, values: [ClaimValue.edit, ClaimValue.editRestricted] },
								]}
								optionalEditClaimConfig={[
									{ claim: ClaimType.MileageCorrection, values: [ClaimValue.edit] },
									{ claim: ClaimType.DurationCorrection, values: [ClaimValue.edit] },
								]}
								widgetTitle={TranslateText('common.correction')}
								viewComponent={CorrectionsView}
								buttons={[]}
								allowEditMode={!this.state.widgetEditing}
								editModeCallback={this.editModeCallback.bind(this)}
								changeDataCallback={this.changeDataCallback.bind(this)}
								className={this.state.correctionWidgetVisible ? '' : 'hidden'}
								getDataCallback={() => this.objectInformationWidgetObj?.state.timeZoneIdCorrections}
							/>
						</ProtectedContainer>
						<ProtectedContainer claimList={[ClaimType.Objects, ClaimType.Customers]}>
							<CustomerWidget
								row="6"
								col="0"
								sizeX="7"
								sizeY="3"
								id={'customerConnections'}
								entityId={this.state.currentRecordId}
								entityType={EntityTypeEnum.Object}
								showEditButton={false}
							/>
						</ProtectedContainer>

						{this.props.currentSession.customerLevel === CustomerLevelEnum.OEM ||
						this.props.currentSession.customerLevel === CustomerLevelEnum.Reseller ? (
							<ProtectedContainer
								optionalClaimList={[ClaimType.Persons, ClaimType.Drivers]}
								claimList={[ClaimType.Objects, ClaimType.Devices, ClaimType.SimCards]}
							>
								<StateWidget
									row="0"
									col="15"
									sizeX="4"
									sizeY="5"
									entityId={this.state.currentRecordId}
									renderFor={EntityTypeEnum.Object}
									id={'stateWidget'}
									getAccessTokenCallback={() => this.props.access_token}
									ref={(scope) => {
										this.stateWidgetObj = scope;
										this.AddLoadedWidget(scope);
									}}
									isAsset={this.props.entityDetails?.objectFunction === ObjectFunctionEnum.Asset}
								/>
							</ProtectedContainer>
						) : null}

						<ProtectedContainer claimList={[ClaimType.Objects, ClaimType.Groups]}>
							<ButtonWidget
								position={{
									row: 6,
									col: 15,
									sizeX: 4,
									sizeY: 4,
								}}
								allowEditMode={!this.state.widgetEditing}
								editModeCallback={this.editModeCallback.bind(this)}
								entityId={this.state.currentRecordId}
								widgetTitle={TranslateText('common.objectGroups')}
								changeDataCallback={this.changeDataCallback.bind(this)}
								viewComponent={ObjectGroupsView}
								buttons={[AddGroupToObjectActionButton]}
								showEditButton={ClaimUtil.validateClaimList(this.props.user, [
									{ claim: ClaimType.Objects, values: [ClaimValue.edit, ClaimValue.editRestricted] },
									{ claim: ClaimType.Groups, values: [ClaimValue.edit] },
								])}
							/>
						</ProtectedContainer>
						<ProtectedContainer claimList={[ClaimType.Objects, ClaimType.Devices]}>
							<LogWidget
								row="10"
								col="0"
								sizeX="19"
								sizeY="6"
								id={'deviceMessagesLog'}
								entityType={EntityTypeEnum.Object}
								entityId={this.state.currentRecordId}
								latestLogsDayUrl={`${GlobalSettings.objectsMaintenanceApi}/LatestLogsDay/${this.state.currentRecordId}`}
							/>
						</ProtectedContainer>
					</DashboardLayoutComponent>
				</Scrollbar>
			</div>
		);
	}
}

export default connector(ObjectDetails);
