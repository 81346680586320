import '../../style.scss';
import './styles.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import { getEntityLink } from 'utils/AlertUtils';
import { renderEntityIcon } from 'utils/Icons';
import { TranslateText } from 'utils/Translations';

import { Box, Icon } from '@material-ui/core';

import { AlertEntity } from '../AlertEntitiesView/types';
import { useTerminateConnection } from './hooks';
import { AlertData, AlertRecipient, AlertRecipientsViewProps } from './types';

const AlertRecipientsView = (props: AlertRecipientsViewProps) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);
	const alertData = useRef<AlertData>();
	alertData.current = props.data as AlertData;

	useEffect(() => {
		props.setUrl(props.getUrl);
	}, []);

	const terminateConnectionCallback = useTerminateConnection();

	const renderEntityName = (rowData: AlertEntity) => {
		const link = getEntityLink(
			user,
			rowData.entityId,
			rowData.entityType,
			featuresSettings.driverIdentification,
			featuresSettings.assetTracking
		);

		return link ? (
			<Link className={!rowData.active ? 'inactiveEntity' : ''} to={link}>
				{rowData.entityName}
			</Link>
		) : (
			<span>{rowData.entityName}</span>
		);
	};

	return (
		<DataTable
			value={alertData.current.alertRecipients}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: AlertRecipient) => (
					<Box key={rowData.entityId} className="data-colName">
						{renderEntityIcon(rowData.entityType)}
						{props.editMode ? (
							<Box component="span" className={!rowData.active ? 'inactiveEntity' : ''}>
								{rowData.entityName}
							</Box>
						) : (
							renderEntityName(rowData)
						)}
					</Box>
				)}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: AlertRecipient) =>
					props.editMode && (
						<Icon
							className="clear-icon"
							onClick={() =>
								terminateConnectionCallback(
									alertData.current.id,
									alertData.current.alertRecipients,
									props.updateUrl,
									props.reloadDataCallback,
									rowData
								)
							}
						>
							clear
						</Icon>
					)
				}
			/>
		</DataTable>
	);
};

export default AlertRecipientsView;
