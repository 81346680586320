import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';

export interface ObjectGroup {
	id: string;
	name: string;
	code: string;
	description: string;
	active: boolean;
	customerId: string;
	connectionId: string;
	createdDatetime: Date;
	modifiedDatetime: Date;
}

const ObjectGroupsView = (props: IButtonWidgetView) => {
	useEffect(() => {
		props.setUrl(`${GlobalSettings.objectsMaintenanceApi}/${props.entityId}/groups`);
	}, []);

	const terminateConnection = useCallback(
		async (rowData: ObjectGroup) => {
			if (rowData) {
				await ajaxUtil.post(`${GlobalSettings.objectsMaintenanceApi}/updateObjectGroupConnection`, {
					removedConnections: [
						{
							id: rowData.connectionId,
							objectId: props.entityId,
							groupId: rowData.id,
						},
					],
				});

				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);

	return (
		<DataTable
			value={props.data as ObjectGroup[]}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: ObjectGroup) =>
					props.editMode ? (
						<span className={rowData.active ? '' : 'inactiveEntity'}>{rowData.name}</span>
					) : (
						<Link
							className={rowData.active ? '' : ' inactiveEntity'}
							to={`${GlobalSettings.route.groups}/${rowData.id}`}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: ObjectGroup) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default ObjectGroupsView;
