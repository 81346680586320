import './buttonWidget.scss';

import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import GlobalSettings from '../../../GlobalSettings.json';
import EntityTypeEnum from '../../../models/EntityTypeEnum';
import FilterEntityType from '../../../models/FilterEntityType';
import { ApplicationState } from '../../../store';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { ITreeNode } from '../../SelectionTree/TreeNode/types';
import TreeSelectionDialog from '../../TreeSelectionDialog';
import { SelectionFilter } from '../../TreeSelectionDialog/TreeSelectionDialog';
import { IActionButton } from './ButtonWidget';

const mapState = (state: ApplicationState) => {
	return {
		customerId: state.currentSession.customer.id,
		accessToken: state.oidc.user.access_token,
	};
};
const connector = connect(mapState, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IActionButton;

const sortData = (reportTemplatesCollection: ITreeNode[]) => {
	reportTemplatesCollection.forEach((reportTemplates: ITreeNode) => {
		if (reportTemplates.items && reportTemplates.items.length > 0) {
			reportTemplates.items.sort((a: ITreeNode, b: ITreeNode) => {
				const first = TranslateText(a.text);
				const second = TranslateText(b.text);
				return first.localeCompare(second);
			});
		}
	});

	return reportTemplatesCollection.sort((a: ITreeNode, b: ITreeNode) => {
		a.text = TranslateText(a.text);
		b.text = TranslateText(b.text);
		return a.text.localeCompare(b.text);
	});
};

const AddReportActionButton = (props: Props) => {
	const [dialogVisible, setDialogVisible] = useState(false);
	const [selectedItems, setSelectedItems] = useState([] as ITreeNode[]);

	useEffect(() => {
		ajaxUtil
			.post(`${GlobalSettings.rolesMaintenanceApi}/${props.entityId}/GetRoleTemplates`, {
				reportIds: props.currentSelection.map((item) => item.itemId),
			})
			.then((data) => {
				const responseData = data as ITreeNode[];
				setSelectedItems(responseData.map((d: ITreeNode) => ({ ...d, active: true })));
			});
	}, [props.entityId, props.currentSelection]);
	const events = {
		saveEntities: async (data: ITreeNode[]) => {
			await ajaxUtil.post(`${GlobalSettings.rolesMaintenanceApi}/${props.entityId}/updateRoleReportClaims`, {
				reportIds: data.filter((node) => node.type === EntityTypeEnum.Report.toString()).map((node) => node.id),
				reportCategories: data
					.filter((node) => node.type === EntityTypeEnum.ReportCategory.toString())
					.map((node) => node.id),
			});
			setDialogVisible(false);
			props.actionExecuted(true);
		},
		closeDialog: () => {
			setDialogVisible(false);
		},
	};

	const retrieveData = useCallback((filter: SelectionFilter) => {
		return ajaxUtil
			.get<ITreeNode[]>(`${GlobalSettings.rolesMaintenanceApi}/GetReportCategoriesTree`)
			.then((data: ITreeNode[]) => sortData(data));
	}, []);

	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>
			<TreeSelectionDialog
				visible={dialogVisible}
				displayInactive={false}
				enableClientFilter={true}
				initialSelection={selectedItems}
				title={TranslateText(`common.add${FilterEntityType[FilterEntityType.Report]}`)}
				filterPlaceholder={TranslateText('fleetSelection.searchReportsPlaceholder')}
				retrieveData={retrieveData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveEntities}
			/>
		</>
	);
};

export default connector(AddReportActionButton);
