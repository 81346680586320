import { LiveSettings } from '../components/Widgets/CustomerLiveSettingsView';
import * as GlobalSettings from '../GlobalSettings.json';
import CustomerLevelEnum from '../models/CustomerLevelEnum';
import {
	CustomerEntityDefaultsState,
	CustomerFeaturesState,
	CustomerHistorySettings,
	FleetOverviewSettings,
	ReportsSettingsState,
} from './index';

const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
const CHANGE_FILTER_TEXT = 'CHANGE_FILTER_TEXT';
const SET_CURRENT_CUSTOMER_FEATURES = 'SET_CURRENT_CUSTOMER_FEATURES';
export interface CustomerTree {
	id: string;
	name: string;
	active: boolean;
}

export type SelectedCustomer = {
	id: string;
	active: boolean;
	code: string;
	level: CustomerLevelEnum;
	timeZone: string;
	featuresSettings: CustomerFeaturesState;
	reportsSettings: ReportsSettingsState;
	entityDefaultsSettings: CustomerEntityDefaultsState;
	fleetOverviewSettings: FleetOverviewSettings;
	customersTree?: CustomerTree[];
	customerLiveSettings: LiveSettings;
	historySettings: CustomerHistorySettings;
	startCountryId: number;
};

type TextFilterKeys =
	| 'objects'
	| 'templates'
	| 'devices'
	| 'simcards'
	| 'translations'
	| 'alerts'
	| 'customers'
	| 'groups'
	| 'keys'
	| 'persons'
	| 'roles'
	| 'drivers'
	| 'informationnotification'
	| 'integrations'
	| 'configurationgroups'
	| string;

export interface GlobalCustomerState {
	filteredCustomer: SelectedCustomer | null;
	filterText: {
		[key in TextFilterKeys]: string;
	};
}

const initialState: GlobalCustomerState = {
	filteredCustomer: null,
	filterText: {
		objects: '',
		templates: '',
		devices: '',
		simcards: '',
		translations: '',
		alerts: '',
		customers: '',
		groups: '',
		keys: '',
		persons: '',
		drivers: '',
		roles: '',
		locations: '',
		informationnotifications: '',
		integrations: '',
		configurationgroups: '',
	},
};

export interface GlobalCustomerActionSignature {
	addCustomer: (customer: SelectedCustomer) => void;
	removeCustomer: (id: string) => void;
	changeFilterText: (filterText: string, filterType: string) => void;
	setCustomerFeatures: (features: any) => void;
}

export const globalCustomerActionCreators = {
	addCustomer: (customer: SelectedCustomer) => (dispatch: any) => {
		dispatch({
			type: ADD_CUSTOMER,

			customer,
		});
	},
	removeCustomer: (id: string) => (dispatch: any) => {
		dispatch({ type: REMOVE_CUSTOMER, id });
	},
	changeFilterText: (filterText: string, filterType: string) => (dispatch: any) => {
		dispatch({ type: CHANGE_FILTER_TEXT, filterType, filterText });
	},
	setCustomerFeatures: (features: any) => (dispatch: any) => {
		dispatch({
			type: SET_CURRENT_CUSTOMER_FEATURES,
			features
		});
	},
};

export const globalCustomerReducer = (state: GlobalCustomerState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case ADD_CUSTOMER:
			//customer selection should be cleared after logout; see pbi 1453
			//this might be needed in future pbi to persist user choices over sessions
			//saveCustomerPreference(currentCustomers.join(','), action.access_token);
			return {
				...state,

				filteredCustomer: action.customer,
			};
		case REMOVE_CUSTOMER:
			//customer selection should be cleared after logout; see pbi 1453
			//this might be needed in future pbi to persist user choices over sessions
			//saveCustomerPreference(newState.join(','), action.access_token);
			return {
				...state,
				filteredCustomer: null,
			};
		case CHANGE_FILTER_TEXT:
			if (action.filterType) {
				state.filterText[action.filterType] = action.filterText;
			}

			return {
				...state,
			};
		case SET_CURRENT_CUSTOMER_FEATURES:
			let tempState = {...state};
			if(tempState?.filteredCustomer) {
				tempState.filteredCustomer.featuresSettings = {
					...tempState.filteredCustomer.featuresSettings,
					...action?.features
				};
			}
			return tempState;
		default:
			return state;
	}
};

function saveCustomerPreference(globalCustomers: string, access_token: string) {
	fetch(GlobalSettings.customersMaintenanceApi + '/saveCustomerPreference', {
		headers: new Headers({
			Authorization: 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify({ globalCustomer: globalCustomers }),
		method: 'POST',
	}).catch((e) => {
		console.log('Error occured saving customer preferences', e);
	});
}
