import parse from 'html-react-parser';
import React from 'react';

import { Tooltip } from '@material-ui/core';

import { useStyleSvgIcon } from './hooks';
import { SvgIconProps } from './types';

const SvgIcon = ({ icon, color, size, onClick, displayInlineBlock, tooltip }: SvgIconProps): JSX.Element => {
	const { styleSvgIcon } = useStyleSvgIcon();

	return (
		<Tooltip title={tooltip ?? ''}>
			<div
				data-testid="svg-icon-test"
				style={{ display: displayInlineBlock ? 'inline-block' : 'flex' }}
				onClick={() => onClick(icon)}
			>
				{parse(styleSvgIcon(icon, color, size))}
			</div>
		</Tooltip>
	);
};

export default React.memo(SvgIcon);
