import './styles.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { SvgBlobIcon } from 'models/BlobIcon';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { User } from 'oidc-client';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import { getEntityLink } from 'utils/AlertUtils';

import { Box } from '@material-ui/core';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { AlertEntityProps, Entity } from './types';

const AlertEntity = ({
	entityId,
	entityName,
	entityType,
	active,
	icon = '',
	editMode = false,
}: AlertEntityProps): JSX.Element => {
	const user: User = useSelector((s: ApplicationState) => s.oidc.user);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);

	const { icon: svgIcon } = useTryGetSvgIconFromQueryCache(icon);

	const renderIcon = (entityType: EntityTypeEnum, icon: SvgBlobIcon): JSX.Element =>
		entityType === 'Driver' ? (
			<PersonIcon />
		) : entityType === 'Object' || entityType === 'Asset' ? (
			<SvgIcon icon={svgIcon.content} color="#495057" size={24} onClick={(icon: string) => {}} />
		) : (
			<CategoryOutlinedIcon />
		);

	const renderEntityName = (entity: Entity): JSX.Element => {
		const link: string = getEntityLink(
			user,
			entity.entityId,
			entity.entityType,
			featuresSettings.driverIdentification,
			featuresSettings.assetTracking
		);

		return link ? (
			<Link className={!entity.active ? 'inactiveEntity' : ''} to={link}>
				{entity.entityName}
			</Link>
		) : (
			<Box component="span">{entity.entityName}</Box>
		);
	};

	return (
		<Box className="data-colName">
			{renderIcon(entityType, svgIcon)}
			{editMode ? (
				<Box component="span" className={!active ? 'inactiveEntity' : ''}>
					{entityName}
				</Box>
			) : (
				renderEntityName({
					entityId,
					entityName,
					entityType,
					active,
					icon,
				})
			)}
		</Box>
	);
};

export default AlertEntity;
