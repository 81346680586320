import React from 'react';
import { ReactComponent as DrivingStateSideBarSvgIcon } from '../../assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as ParkedStateSideBarSvgIcon } from '../../assets/icons/ParkedStateSideBar.svg';
import { ReactComponent as IdleStateSideBarSvgIcon } from '../../assets/icons/IdleStateSideBar.svg';
import { ReactComponent as UnknownSvgIcon } from '../../assets/icons/Unknown.svg';
import { ReactComponent as AlarmSvgIcon } from '../../assets/icons/AlertTriggered.svg';
import { ReactComponent as AllSvgIcon } from '../../assets/icons/All.svg';
import { StateFilterEnum } from '../../models/LiveMenuUtils';

interface Props {
	stateType: StateFilterEnum;
}

const StateIconLiveOverview = ({ stateType }: Props) => {
	switch (stateType) {
		case StateFilterEnum.All:
			return <AllSvgIcon />;
		case StateFilterEnum.Driving:
			return <DrivingStateSideBarSvgIcon />;
		case StateFilterEnum.Parked:
			return <ParkedStateSideBarSvgIcon />;
		case StateFilterEnum.Idle:
			return <IdleStateSideBarSvgIcon />;
		case StateFilterEnum.Unknown:
			return <UnknownSvgIcon />;
		case StateFilterEnum.Alert:
			return <AlarmSvgIcon />;
		case StateFilterEnum.None:
		default:
			return <></>;
	}
};

export default StateIconLiveOverview;
