import { FleetSelectionClientModel } from 'components/FleetSelectionArea/FleetSelectionArea';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import * as GlobalSettings from 'GlobalSettings.json';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import { Dispatch } from 'redux';
import { FleetSelectionDispatcher } from 'store/FleetSelection';
import { rightSideBarCreators } from 'store/RightSideBar';

import ajaxUtil from './Ajax';

export const getAndSetSavedFleetSelection = (
	customerId: string,
	driverIdentification: boolean,
	assetTracking: boolean,
	saveTrackedFleetSelection: boolean,
	showInactive: boolean,
	selectedEntities: ITreeNode[],
	trackedEntities: ITreeNode[],
	dispatch: Dispatch<any>
): Promise<void> => {
	return ajaxUtil
		.post<FleetSelectionClientModel>(`${GlobalSettings.fleetSelection}/Get`, {
			customerId: customerId,
			showPersons: driverIdentification,
			objectFunction: assetTracking ? ObjectFunctionFilter.All : ObjectFunctionFilter.Full,
			showObjects: true,
			showInactive: showInactive,
			filterText: '',
		})
		.then((data) => {
			const tracked = saveTrackedFleetSelection ? data.trackedEntities : trackedEntities;
			const newTrackedEntities = tracked.map((t) => {
				const foundGroup = data.selectedEntities.find((se) => se.id === t.id && se.type === 'Group');
				return foundGroup ? { ...t, items: foundGroup.items } : t;
			});
			FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, newTrackedEntities);
			FleetSelectionDispatcher.setFleetSelectionShowInactive(dispatch, data.showInactive);
			FleetSelectionDispatcher.setSelectedEntities(dispatch, data.selectedEntities);
			FleetSelectionDispatcher.setTempSelectedEntities(dispatch, data.selectedEntities);

			if (data.selectedEntities.length > 0 && selectedEntities.length === 0) {
				const groupsToBeExpanded = data.selectedEntities.filter(({ type }) => type === 'Group');
				if (groupsToBeExpanded.length > 0) {
					const groupIds = groupsToBeExpanded.map(({ id }) => id);
					FleetSelectionDispatcher.setExpandedGroups(dispatch, groupIds);
				}

				if (newTrackedEntities.length > 0) {
					dispatch(rightSideBarCreators.updateLiveAndHistoryOption(1, 1));
				}
			}
		});
};

export const setSelectionForDriverWithNoAuth = (data: ITreeNode[], dispatch: Dispatch<any>): void => {
	if (data) {
		for (let index = 0; index < data.length; index++) {
			if (data[index].id === null && data[index].text === 'All') {
				data[index].items[0].selected = true;
				FleetSelectionDispatcher.setSelectedEntities(dispatch, [data[index].items[0]]);
				FleetSelectionDispatcher.setTempSelectedEntities(dispatch, [data[index].items[0]]);
				FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, [data[index].items[0]]);
				break;
			}
		}
	}
};
