import './styles.scss';

import { SvgBlobIcon } from 'models/BlobIcon';
import React, { useState } from 'react';
import { TranslateText } from 'utils/Translations';

import { Box, Grid, InputBase } from '@material-ui/core';

import { SvgIcon } from '../SvgIcon';
import { IconPickerContainerProps } from './types';

const IconPickerContainer = ({ svgIcons, color, size, onClick }: IconPickerContainerProps): JSX.Element => {
	const [searchText, setSearchText] = useState<string>('');

	const filteredSvgIcons: JSX.Element[] = svgIcons
		.filter((svgIcon: SvgBlobIcon) => (!!searchText ? svgIcon.name.includes(searchText) : svgIcon))
		.map(
			(svgIcon: SvgBlobIcon): JSX.Element => (
				<Grid key={svgIcon.name} item>
					<Box className="icon-picker-container-grid-item-icon">
						<SvgIcon
							icon={svgIcon.content}
							tooltip={svgIcon.name}
							color={color}
							size={size}
							onClick={(icon: string) => onClick(svgIcon.name, svgIcon.content, svgIcon.iconSet)}
						/>
					</Box>
				</Grid>
			)
		);

	return (
		<Box className="icon-picker-container">
			<Box className="icon-picker-container-input-container">
				<InputBase
					data-testid="icon-picker-container-input-base-test-id"
					className="icon-picker-container-input-search"
					value={searchText}
					placeholder={TranslateText('iconPicker.searchMessage')}
					onChange={(e) => setSearchText(e.target.value)}
				/>
			</Box>
			<Box className="icon-picker-container-icons-container">
				<Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
					{filteredSvgIcons}
				</Grid>
			</Box>
		</Box>
	);
};

export default React.memo(IconPickerContainer);
