import { Box } from '@material-ui/core';
import { LogWidgetInput } from 'components/Widgets/LogWidgetInput';
import React from 'react';

import { MessageSearchBoxProps } from './types';

const MessageSearchBox = ({ onChange }: MessageSearchBoxProps): JSX.Element => {
	return (
		<>
			<Box className="search-icon icon" />
			<LogWidgetInput debounceTime={600} onChangeHandler={onChange} />
		</>
	);
};

export default MessageSearchBox;
