import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import * as React from 'react';
import Validator from 'shared/validation/Validator';

import { TextField } from '@material-ui/core';

import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';
import { ValidationResult } from 'shared/validation/interfaces';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';

class BasicReportInformation extends React.PureComponent<
	{
		filledBasicInformationCallback: (reportName: string, reportNotes: string) => void;
		setReportName: string;
		setReportNotes: string;
	},
	{ reportName: string; reportNotes: string; validationResult?: ValidationResult }
> {
	validator: Validator;

	constructor(props: any) {
		super(props);
		this.state = {
			reportName: '',
			reportNotes: '',
			validationResult: null,
		};

		this.setUpValidations();
	}

	setUpValidations() {
		this.validator = new Validator({
			fieldRules: {
				reportName: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: {
							message: TranslateTextInterpolated('fieldsValidations.maxLength', ['200']),
							validationFunction: (data) => {
								if (data.reportName?.length > 200) {
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
						regex: ValidatorFunctions.regex(new RegExp('(?!^ +$)^.+$')),
					},
				},
				reportNotes: {
					rules: {
						maxLength: {
							message: TranslateText('fieldsValidations.maxLength500'),
							validationFunction: (data) => {
								if (data.reportNotes?.length > 500) {
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
					},
				},
			},
		});
	}

	handleValueChange(arg: any, statePropName: string) {
		this.setState(
			{
				...this.state,
				[statePropName]: arg.target.value,
			},
			() => {
				this.props.filledBasicInformationCallback(this.state.reportName, this.state.reportNotes);
				this.validator
					.validate({ reportName: this.state.reportName, reportNotes: this.state.reportNotes })
					.then((result) => {
						this.setState({
							validationResult: result.validationResult,
						});
					});
			}
		);
	}

	setPreviouslyFilledData() {
		this.setState(
			{
				...this.state,
				reportName: this.props.setReportName,
				reportNotes: this.props.setReportNotes,
			},
			() => this.props.filledBasicInformationCallback(this.state.reportName, this.state.reportNotes)
		);
	}

	componentDidMount() {
		this.setUpValidations();
		setTimeout(() => {
			this.setPreviouslyFilledData();
			this.validator
				.validate({ reportName: this.state.reportName, reportNotes: this.state.reportNotes })
				.then((result) => {
					this.setState({
						validationResult: result.validationResult,
					});
				});
		}, 100);
	}

	render() {
		return (
			<div className="categories-container">
				<div className="e-text active-step-title">{'2.' + TranslateText('reports.basicInformation')}</div>
				<div className="e-text">{TranslateText('reports.basicInformationDescription')}</div>

				<form id="basicReportInformationForm" noValidate={true} style={{ marginTop: 10 }}>
					<div className="form-group grid">
						<TextField
							id="reportName"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.reportName')}
							inputProps={{ style: { fontSize: 12 } }}
							name="reportName"
							value={this.state.reportName}
							onChange={(args) => this.handleValueChange(args, 'reportName')}
							style={{ width: 500 }}
						/>
						<ValidationMessage result={this.state.validationResult?.reportName} />
					</div>
					<div className="form-group grid">
						<TextField
							id="reportNotes"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.reportNotes')}
							inputProps={{ style: { fontSize: 12 } }}
							name="reportNotes"
							value={this.state.reportNotes}
							onChange={(args) => this.handleValueChange(args, 'reportNotes')}
							multiline={true}
							style={{ width: 500 }}
						/>
						<ValidationMessage result={this.state.validationResult?.reportNotes} />
					</div>
				</form>
			</div>
		);
	}
}

export default BasicReportInformation;
