import moment from 'moment';
import { preciseDateDiff } from 'utils/PreciseDateDiff';
import { TranslateText } from 'utils/Translations';

export function dateFormat(date: Date) {
	// for MM/dd/yyyy hh:mm:ss a

	// this has an extra comma
	// const formatter = new Intl.DateTimeFormat("en-US", {
	//     month: '2-digit',
	//     day: '2-digit',
	//     year: 'numeric',
	//     hour: '2-digit',
	//     minute: '2-digit',
	//     second: '2-digit',
	//     hour12: true
	// });
	// return formatter.format(date)

	return (
		date.toLocaleDateString('en', { month: '2-digit', day: '2-digit', year: 'numeric' }) +
		' ' +
		date.toLocaleTimeString('en', {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: true,
		})
	);
}

export const SecondToDurationDrivingTime = (totalSeconds: number) => {
	const days = Math.floor(totalSeconds / (60 * 60 * 24));
	const hours = Math.floor((totalSeconds - days * 60 * 60 * 24) / (60 * 60));
	const minutes = Math.floor((totalSeconds - days * 60 * 60 * 24 - hours * (60 * 60)) / 60);
	return `${totalSeconds < 0 ? '-' : ''}${hours > 0 ? (hours > 10 ? `${hours}:` : `0${hours}:`) : '00:'}${
		hours > 0 || minutes > 0 ? (minutes > 10 ? `${minutes}` : `0${minutes}`) : ''
	}`;
};

export function secondsToIntervalString(totalSeconds: number) {
	const days = Math.floor(totalSeconds / (60 * 60 * 24));
	const hours = Math.floor((totalSeconds - days * 60 * 60 * 24) / (60 * 60));
	const minutes = Math.floor((totalSeconds - days * 60 * 60 * 24 - hours * (60 * 60)) / 60);
	const seconds = totalSeconds - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60;
	return `${totalSeconds < 0 ? '-' : ''}${days > 0 ? `${padLeftWith0ToString(days)}d:` : ''}${
		days > 0 || hours > 0 ? `${padLeftWith0ToString(hours)}h:` : ''
	}${days > 0 || hours > 0 || minutes > 0 ? `${padLeftWith0ToString(minutes)}m:` : ''}${
		days > 0 || hours > 0 || minutes > 0 || seconds > 0 ? `${padLeftWith0ToString(seconds)}s` : ''
	}`;
}

export function secondsDifferenceTime(
	start: Date | string | null,
	end: Date | string | null,
	timeZoneMinutesOffset: number
) {
	const m1 = start ? moment(start) : moment(new Date()).add(timeZoneMinutesOffset, 'minutes');
	const m2 = end ? moment(end) : moment(new Date()).add(timeZoneMinutesOffset, 'minutes');

	return m2.diff(m1, 'seconds');
}

export function intervalDifferenceTime(
	start: Date | string | null,
	end: Date | string | null,
	ignoreBrowserUtc = false,
	timeZoneOffset = 0
) {
	const dateDiff = preciseDateDiff(start, end, ignoreBrowserUtc, timeZoneOffset);
	if (dateDiff.years) {
		return `${dateDiff.years}${TranslateText('common.yearAbbreviation')}:${dateDiff.months}${TranslateText(
			'common.monthAbbreviation'
		)}:${dateDiff.days}${TranslateText('common.dayAbbreviation')}`;
	} else if (dateDiff.months) {
		return `${dateDiff.months}${TranslateText('common.monthAbbreviation')}:${dateDiff.days}${TranslateText(
			'common.dayAbbreviation'
		)}:${dateDiff.hours}${TranslateText('common.hourAbbreviation')}`;
	} else if (dateDiff.days) {
		return `${dateDiff.days}${TranslateText('common.dayAbbreviation')}:${dateDiff.hours}${TranslateText(
			'common.hourAbbreviation'
		)}:${dateDiff.minutes}${TranslateText('common.minuteAbbreviation')}`;
	} else if (dateDiff.hours || dateDiff.minutes) {
		return `${dateDiff.hours}${TranslateText('common.hourAbbreviation')}:${dateDiff.minutes}${TranslateText(
			'common.minuteAbbreviation'
		)}`;
	} else {
		return `<1${TranslateText('common.minuteAbbreviation')}`;
	}
}

export function secondsToIntervalStringVisitTime(totalSeconds: number) {
	if (totalSeconds < 60) {
		return `<1${TranslateText('common.minuteAbbreviation')}`;
	}
	const days = Math.floor(totalSeconds / (60 * 60 * 24));
	const hours = Math.floor((totalSeconds - days * 60 * 60 * 24) / (60 * 60));
	const minutes = Math.floor((totalSeconds - days * 60 * 60 * 24 - hours * (60 * 60)) / 60);
	return `${totalSeconds < 0 ? '-' : ''}${days > 0 ? `${days}${TranslateText('common.dayAbbreviation')}:` : ''}${
		hours >= 0
			? `${hours}${TranslateText('common.hourAbbreviation')}:`
			: `0${TranslateText('common.hourAbbreviation')}:`
	}${
		minutes >= 0
			? `${minutes}${TranslateText('common.minuteAbbreviation')}`
			: `0${TranslateText('common.minuteAbbreviation')}`
	}`;
}

function padLeftWith0ToString(number: number, length = 2) {
	let pad = '';
	for (let i = 0; i < length; i++) {
		pad += '0';
	}
	return pad.substring(0, pad.length - number.toString().length) + number.toString();
}

export function daysHoursMinutesFromMiliseconds(t: number, excludeSeconds = true) {
	let cd = 24 * 60 * 60 * 1000,
		ch = 60 * 60 * 1000,
		d = Math.floor(t / cd),
		h = Math.floor((t - d * cd) / ch),
		m = Math.floor((t - d * cd - h * ch) / 60000),
		pad = function(n: number) {
			return n < 10 ? '0' + n : n;
		};
	if (m === 60) {
		h++;
		m = 0;
	}
	if (h === 24) {
		d++;
		h = 0;
	}

	if (excludeSeconds) {
		return [pad(h), pad(m)].join(':');
	}

	return [d, pad(h), pad(m)].join(':');
}

export const oneDayInMiliseconds: number = 24 * 60 * 60 * 1000;

const timeUtil = {
	getDiff: (from: Date, to: Date): string => {
		return moment.utc(moment(to).diff(moment(from))).format('HH:mm:ss');
	},
	getDiffInSeconds: (from: Date, to: Date): string => {
		return moment(to)
			.diff(moment(from), 'seconds')
			.toString();
	},
	getDiffInSecondsNumber: (from: Date, to: Date): number => {
		return moment(to).diff(moment(from), 'seconds');
	},
};
export default timeUtil;
