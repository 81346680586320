import './PrefixTextField.scss';

import React, { useCallback, useEffect, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

type Props = {
	id?: string;
	name: string;
	label: string;
	className: string;
	value?: string;
	applyPrefix: boolean;
	prefixValue?: string;
	onChange: (value: string) => void;
	disabled?: boolean;
};

const PrefixTextField = (props: Props) => {
	const recheckInputValue = useCallback(() => {
		if (props.applyPrefix) {
			if (props.value && props.value.startsWith(props.prefixValue)) {
				return props.value.slice(props.prefixValue.length, props.value.length);
			} else {
				return props.value ? props.value : '';
			}
		} else {
			return props.value ? props.value : '';
		}
	}, [props.value, props.applyPrefix, props.prefixValue]);

	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>(() => {
		return recheckInputValue();
	});
	useEffect(() => {
		setInputValue(recheckInputValue());
	}, [props.value, props.applyPrefix, props.prefixValue]);

	return (
		<TextField
			id={props.id}
			value={inputValue}
			name={props.name}
			className={`prefixTextField ${props.className}`}
			label={props.label}
			fullWidth={true}
			disabled={props.disabled}
			InputProps={
				props.applyPrefix
					? {
							startAdornment: <InputAdornment position="start">{props.prefixValue}</InputAdornment>,
					  }
					: {}
			}
			onChange={(args) => {
				setInputValue(args.target.value);
				setUnsavedData(true);
			}}
			onBlur={(args) => {
				if (unsavedData) {
					const newInputValue = args.target.value?.trim();
					setInputValue(newInputValue);

					if (props.applyPrefix && newInputValue) {
						props.onChange(props.prefixValue + newInputValue);
					} else {
						props.onChange(newInputValue);
					}
				}
			}}
		/>
	);
};

export default PrefixTextField;
