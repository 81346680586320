import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { TranslateText } from 'utils/Translations';
import { LogWidgetPopupProps } from './types';

const LogWidgetPopup = ({
	showPopup,
	popupUnitId,
	popupContent,
	popupMessageReceiveTime,
	onCloseButtonClick,
}: LogWidgetPopupProps): JSX.Element => {
	return (
		<Dialog open={showPopup} maxWidth={false} scroll="paper" className="log-widget-popup">
			<Box className="title-container">
				<DialogTitle>{`${TranslateText('widgets.grid.colUnit')}: ${popupUnitId}`}</DialogTitle>
				<DialogTitle>{`${TranslateText(
					'widgets.grid.colMessageReceivedTime'
				)}: ${popupMessageReceiveTime}`}</DialogTitle>
			</Box>

			<DialogContent dividers className="dialog-content">
				<Typography className="typography">{popupContent}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCloseButtonClick}>{TranslateText('common.buttonClose')}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LogWidgetPopup;
