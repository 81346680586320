import './SwitchComponent.scss';

import React from 'react';

import { enableRipple } from '@syncfusion/ej2-base/src/animation';
import { ChangeEventArgs, SwitchComponent as SwitchComponentSyncFusion } from '@syncfusion/ej2-react-buttons';

enableRipple(false);

type Props = {
	id: string;
	name?: string;
	readonly?: boolean;
	enabled?: boolean;
	placeholder: string;
	value: boolean;
	change: (e: ChangeEventArgs) => void;
};
const SwitchComponent = (props: Props) => {
	return (
		<div className={`switchComponent e-control-wrapper ${props.readonly ? 'e-disabled' : ''}`}>
			<label htmlFor={props.id}>{props.placeholder}</label>
			<SwitchComponentSyncFusion
				immediateRender={true}
				className="bar-color"
				id={props.id}
				name={props.name}
				disabled={!props.enabled}
				checked={props.value}
				change={(e) => props.change(e)}
			></SwitchComponentSyncFusion>
		</div>
	);
};

SwitchComponent.defaultProps = {
	readonly: true,
	enabled: false,
};

export default SwitchComponent;
