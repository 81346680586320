import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

import { enableRipple } from '@syncfusion/ej2-base';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import Role from '../../models/Role';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import AddClaimActionButton from '../Widgets/ButtonWidget/AddClaimActionButton';
import AddReportActionButton from '../Widgets/ButtonWidget/AddReportActionButton';
import ButtonWidget from '../Widgets/ButtonWidget/ButtonWidget';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import RoleInformationWidget from '../Widgets/RoleInformationWidget';
import RoleReportsView from '../Widgets/RoleReportsView';
import RoleRightsView from '../Widgets/RoleRightsView';

enableRipple(true);

type MatchProps = {
	match: { params: { [key: string]: string } };
};

//Extract type from connector
type Props = WidgetDashboardTransferableProps & MatchProps & {};
const pathToOverview = '/roles';

const RoleDetails = (props: Props) => {
	const access_token = useSelector((state: ApplicationState) => state.oidc.user.access_token);
	const currentUserCustomerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);
	const filterText = useSelector((state: ApplicationState) => state.globalCustomer.filterText.roles);
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	const [currentTitle, setCurrentTitle] = useState<string>('');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedRoleContext(null));
		};
	}, []);

	const events = {
		handleDisplayCallBack: (role: Role) => {
			setCurrentTitle(role.name);
			dispatch(loadedEntityContextActionCreators.setLoadedRoleContext(role));
		},
	};

	return (
		<>
			<WidgetDashboard
				goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.Role}s`)}
				history={props.history}
				currentRecordId={props.match.params.id}
				goBackToCallback={() => props.history.push(pathToOverview, { id: props.match.params.id })}
				urlPath={pathToOverview}
				filterText={filterText}
				breadcrumbText={currentTitle ? `${TranslateText('common.role')} > ${currentTitle}` : ''}
				entityType={EntityTypeEnum.Role}
			>
				<RoleInformationWidget
					id="roleInformation"
					row="0"
					col="0"
					sizeX="4"
					sizeY="5"
					entityId={props.match.params.id}
					displayCallback={events.handleDisplayCallBack}
					getAccessTokenCallback={() => access_token}
					currentUserCustomerLevel={currentUserCustomerLevel}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Roles,
						values: [ClaimValue.edit],
					})}
				/>
				<ButtonWidget
					position={{
						row: 0,
						col: 4,
						sizeX: 6,
						sizeY: 4,
					}}
					url={`${GlobalSettings.rolesMaintenanceApi}/claims`}
					entityId={props.match.params.id}
					widgetTitle={TranslateText('common.claims')}
					viewComponent={RoleRightsView}
					buttons={[AddClaimActionButton]}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Roles,
						values: [ClaimValue.edit],
					})}
				/>
				<ProtectedContainer claimConfig={[{ claim: ClaimType.Reporting, values: [ClaimValue.edit] }]}>
					<ButtonWidget
						position={{
							row: 0,
							col: 10,
							sizeX: 6,
							sizeY: 4,
						}}
						entityId={props.match.params.id}
						url={`${GlobalSettings.rolesMaintenanceApi}/reports`}
						widgetTitle={TranslateText('common.reports')}
						viewComponent={RoleReportsView}
						buttons={[AddReportActionButton]}
						showEditButton={ClaimUtil.validateClaim(user, {
							claim: ClaimType.Roles,
							values: [ClaimValue.edit],
						})}
					/>
				</ProtectedContainer>
			</WidgetDashboard>
		</>
	);
};

export default RoleDetails;
