import GlobalSettings from 'GlobalSettings.json';
import AuthenticationTypeEnum from 'models/AuthenticationTypeEnum';
import Device from 'models/Device';
import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import AuthenticationTypeDataSource from 'shared/staticDataSources/AuthenticationTypeDataSource';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { filteredEntitiesActionCreators } from 'store/FilteredEntities';
import { historyFilterActionCreators } from 'store/HistoryFilter';
import { historyStoreActionCreators } from 'store/HistoryStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import {
	Button,
	Checkbox,
	Fade,
	FormControlLabel,
	Paper,
	Popper,
	PopperPlacementType,
	Typography,
} from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete, { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

interface EasyDriveSettingsDto {
	useDriverStyleScores: boolean;
	useMediaFootage: boolean;
	deviceNeedToBeSyncedWithEd2: boolean;
	deviceShouldHaveEd2MediaSupport: boolean;
}
interface DeviceTypeItem extends AutocompleteItem {
	deviceFamily: string;
}

const mapState = (state: ApplicationState) => {
	return {
		isKeypadDeviceFromCustomer: state.currentSession.customer.featuresSettings.keypad,
		globalCustomer: state.globalCustomer.filteredCustomer,
		availableCustomers: state.availableCustomers.data,
		entityDefaultsSettings: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.entityDefaultsSettings
			: state.currentSession.customer.entityDefaultsSettings,
	};
};

const mapDispatch = (dispatch: Dispatch) => {
	return {
		actions: {
			filteredEntitiesActions: bindActionCreators(filteredEntitiesActionCreators, dispatch),
			historyFilterActions: bindActionCreators(historyFilterActionCreators, dispatch),
			historyStoreActions: bindActionCreators(historyStoreActionCreators, dispatch),
			availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		},
	};
};

const connector = connect(mapState, mapDispatch, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	readOnly: boolean;
	renderFor: RenderForEnum;
	setDevice?: (valid: boolean, device: Device) => void;
	scrollToTop?: () => void;
	widgetState: WidgetStateEnum;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
};

interface State {
	device: Device;
	expandedSection: number;
	unsavedData: boolean;
	validationResult?: ValidationResult;
	devicesTypesData: DeviceTypeItem[];
	customerAuthenticationType: AuthenticationTypeEnum;
	easyDriveSettings: EasyDriveSettingsDto;
	nonTripChangeNotificationPopupPlacement: PopperPlacementType;
	nonTripChangeNotificationAnchorEl: HTMLInputElement | null;
	openNonTripChangeNotification: boolean;
	consentNonTripChange: boolean;
}

class DeviceView extends BaseView<Props, State> {
	initialDevice: Device;
	validator: Validator;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
	};

	inputFontSize = 10;
	defaultDataSource = [
		{ id: true, display: TranslateText('common.yes') },
		{ id: false, display: TranslateText('common.no') },
	];

	expandedItemIndex: number;

	constructor(props: Props) {
		super(props);

		this.state = {
			device: this.createNewDevice(),
			expandedSection: 0,
			unsavedData: false,
			validationResult: null,
			devicesTypesData: [],
			customerAuthenticationType: this.props.entityDefaultsSettings?.defaultDeviceAuthenticationType,
			easyDriveSettings: null,
			nonTripChangeNotificationPopupPlacement: 'top-end',
			nonTripChangeNotificationAnchorEl: null,
			openNonTripChangeNotification: false,
			consentNonTripChange: false,
		};

		this.validator = new Validator({
			fieldRules: {
				//hardwareInformationFormValidatorModel
				deviceTypeId: {
					rules: { required: ValidatorFunctions.required() },
				},
				productionDate: {
					rules: { required: ValidatorFunctions.required() },
				},
				authenticationType: {
					rules: { required: ValidatorFunctions.required() },
				},
				serialNumber: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(20),
					},
				},
				firmwareVersion: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(200),
					},
				},
				hardwareVersionNumber: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(200),
					},
				},
				certificateNumber: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(200),
					},
				},
				disconnectDriverAfterIgnitionOff: {
					rules: {
						required: ValidatorFunctions.required(),
					},
				},
				disconnectDriverAfterIgnitionOffTimeOutSeconds: {
					rules: {
						required: ValidatorFunctions.required(),
						minNumber: ValidatorFunctions.minNumber(0),
						maxNumber: ValidatorFunctions.maxNumber(31557600), // 1 year in seconds
					},
				},
				isTripDeviceChangeAgreementRequired: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const deviceData = data as Device;
								return ValidatorFunctions.wrapInPromise(
									this.props.renderFor !== RenderForEnum.Widget ||
										!deviceData?.isTripDeviceChangeAgreementRequired
								);
							},
						},
					},
				},
			},
		});

		if (this.props.renderFor === RenderForEnum.Wizard || this.props.renderFor === RenderForEnum.ConnectDialog) {
			this.validator.addRules({
				//systemInformationFormValidatorModel
				code: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(24),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
						unique: {
							message: TranslateText('fieldsValidations.uniqueValue'),
							validationFunction: (data, fieldName) => {
								const deviceData = data as Device;
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/DeviceFields`, {
									value: deviceData.code,
									Field: 'code',
								});
							},
						},
					},
				},
			});
		}
	}

	componentDidMount() {
		this.props.actions.availableCustomersActions.toggleSelection(false);

		ajaxUtil.get<DeviceTypeItem[]>(`${GlobalSettings.listsApi}/DeviceTypes`).then((data) => {
			this.setState({
				devicesTypesData: data,
			});
		});

		if (
			this.props.renderFor === RenderForEnum.Wizard ||
			(this.props.renderFor === RenderForEnum.Widget && !this.props.readOnly)
		) {
			this.prepareView();

			//initial validation
			this.validator.validate(this.state.device).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
			this.validator.clearValidation();
			this.setState({ validationResult: null });
			this.handleIsTripDeviceChagenConsentClick(false);
		}

		if (prevProps.defaultCustomerId !== this.props.defaultCustomerId) {
			const tempDevice = { ...this.state.device };
			tempDevice.customerId = this.props.defaultCustomerId;
			this.setState({ device: tempDevice });
		}

		if (
			this.props.widgetState !== WidgetStateEnum.Edit &&
			((this.state.device.customerId &&
				!this.state?.easyDriveSettings &&
				this.state?.easyDriveSettings === null) ||
				(prevState.device.customerId && prevState.device.customerId !== this.state.device.customerId))
		) {
			this.getEasyDriveSettings(this.state.device.customerId);
		}
		if (
			this.props.renderFor !== RenderForEnum.Wizard &&
			this.useEasyDrive() &&
			prevState.device.deviceFamily !== this.state.device.deviceFamily
		) {
			if (
				(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
					this.state.device.deviceFamily?.toLowerCase() === 'qanall') &&
				this.state.expandedSection === 1
			) {
				if (this.props.renderFor !== RenderForEnum.ConnectDialog) {
					this.setState({
						expandedSection: this.state.expandedSection + 1,
					});
				}
			} else if (
				this.state.device.deviceFamily?.toLowerCase() !== 'solo' &&
				this.state.device.deviceFamily?.toLowerCase() !== 'qanall' &&
				this.state.expandedSection === 2
			) {
				{
					this.setState({
						expandedSection: this.state.expandedSection - 1,
					});
				}
			}
		}

		if (
			this.props.renderFor === RenderForEnum.Wizard &&
			this.useEasyDrive() &&
			prevState.device.deviceFamily !== this.state.device.deviceFamily
		) {
			if (
				(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
					this.state.device.deviceFamily?.toLowerCase() === 'qanall') &&
				this.state.expandedSection < 1 &&
				prevState.device.deviceFamily?.toLowerCase() !== 'solo' &&
				prevState.device.deviceFamily?.toLowerCase() !== 'qanall'
			) {
				{
					this.setState({
						expandedSection: this.state.expandedSection + 1,
					});
				}
			} else if (
				this.state.device.deviceFamily?.toLowerCase() !== 'solo' &&
				this.state.device.deviceFamily?.toLowerCase() !== 'qanall' &&
				this.state.expandedSection === 1
			) {
				this.setState({
					expandedSection: this.state.expandedSection - 1,
				});
			}
		}
		if (this.state.device !== prevState.device) {
			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				this.validator.validate(this.state.device).then((result) => {
					this.setState(
						{
							validationResult: result.validationResult,
						},
						() => {
							if (this.state.unsavedData) {
								if (this.props.setDevice) {
									this.props.setDevice(result.formResult, this.state.device);
								}
								this.setState({ unsavedData: false });
							}
						}
					);
				});
			}
		}
	}

	setEditRecord(device: Device) {
		this.setState({
			device: device,
			unsavedData: false,
		});
		this.initialDevice = device;
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.device).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	clearView() {
		this.validator.clearValidation();
		this.setState({
			device: this.createNewDevice(),
			expandedSection: 0,
			validationResult: null,
		});
	}

	prepareView() {
		document.getElementById('deviceCode').focus();
	}

	public focusDefaultInput() {
		setTimeout(() => {
			document.getElementById('deviceCode').focus();
		}, 400);
	}

	public setAuthenticationType(authenticationType: AuthenticationTypeEnum) {
		const updatedDevice = { ...this.state.device };
		updatedDevice.authenticationType = authenticationType;
		this.setState({
			device: updatedDevice,
			customerAuthenticationType: authenticationType,
		});
	}

	private createNewDevice() {
		const newDevice = new Device(new Date(), this.props.defaultCustomerId);
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.renderFor === RenderForEnum.ConnectDialog) {
			newDevice.authenticationType =
				this.state?.customerAuthenticationType ??
				this.props.entityDefaultsSettings.defaultDeviceAuthenticationType;
			newDevice.disconnectDriverAfterIgnitionOff = this.props.entityDefaultsSettings?.disconnectDriverAfterIgnitionOff;
			newDevice.disconnectDriverAfterIgnitionOffTimeOutSeconds = this.props.entityDefaultsSettings?.disconnectDriverAfterIgnitionOffTimeOutSeconds;
			newDevice.deviceNeedToBeSyncedWithEd2 = this.props.entityDefaultsSettings?.defaultDeviceNeedToBeSyncedWithEd2;
			newDevice.deviceShouldHaveEd2MediaSupport = this.props.entityDefaultsSettings?.defaultDeviceShouldHaveEd2MediaSupport;
		}
		this.getEasyDriveSettings(this.props.defaultCustomerId);
		return newDevice;
	}

	private getEasyDriveSettings(customerId: string) {
		if (customerId) {
			ajaxUtil
				.get<EasyDriveSettingsDto>(
					`${GlobalSettings.devicesMaintenanceApi}/getEasyDriveSettingsForCustomer/${customerId}`
				)
				.then((data) => {
					this.setState({
						easyDriveSettings: data,
					});
				});
		}
	}

	private handleCheckboxValue(args: any, key: string) {
		const tempDevice = { ...this.state.device };
		const lastValue = tempDevice[key as any];
		tempDevice[key as any] = args.target.checked;
		this.setState({
			device: tempDevice,
			unsavedData: tempDevice[key as any] !== lastValue ? true : this.state.unsavedData,
		});
	}

	private handleValueChange(args: Date | string | boolean | number | AuthenticationTypeEnum, key: string) {
		const tempDevice = { ...this.state.device };
		const lastValue = tempDevice[key as any];
		tempDevice[key as any] = args;

		if (key === 'deviceTypeId') {
			let dataTypeOption = null;
			if (args) {
				dataTypeOption = this.state.devicesTypesData.find((x) => x.id === args);
			}
			if (
				tempDevice.deviceFamily?.toLowerCase() !== 'solo' &&
				tempDevice.deviceFamily?.toLowerCase() !== 'qanall'
			) {
				tempDevice.deviceNeedToBeSyncedWithEd2 = this.props.entityDefaultsSettings?.defaultDeviceNeedToBeSyncedWithEd2;
				tempDevice.deviceShouldHaveEd2MediaSupport = this.props.entityDefaultsSettings?.defaultDeviceShouldHaveEd2MediaSupport;
			}
			tempDevice['deviceFamily'] = dataTypeOption?.deviceFamily;
			tempDevice.isTripDevice =
				tempDevice.deviceFamily?.toLowerCase() !== 'ovintosat' &&
				tempDevice.deviceFamily?.toLowerCase() !== 'bumblebee' &&
				tempDevice.deviceFamily?.toLowerCase() !== 'hereontrack';
		}
		if (key === 'authenticationType') {
			if (args === AuthenticationTypeEnum.None || args === AuthenticationTypeEnum.Fixed) {
				tempDevice.disconnectDriverAfterIgnitionOff = this.props.entityDefaultsSettings?.disconnectDriverAfterIgnitionOff;
				tempDevice.disconnectDriverAfterIgnitionOffTimeOutSeconds = this.props.entityDefaultsSettings?.disconnectDriverAfterIgnitionOffTimeOutSeconds;
			}
		}
		if (
			key === 'disconnectDriverAfterIgnitionOff' &&
			args === false &&
			!tempDevice.disconnectDriverAfterIgnitionOffTimeOutSeconds
		) {
			tempDevice.disconnectDriverAfterIgnitionOffTimeOutSeconds = this.props.entityDefaultsSettings?.disconnectDriverAfterIgnitionOffTimeOutSeconds;
		}
		this.setState({
			device: tempDevice,
			unsavedData: tempDevice[key as any] !== lastValue ? true : this.state.unsavedData,
		});
	}

	private useEasyDrive() {
		return this.state?.easyDriveSettings?.useDriverStyleScores || this.state?.easyDriveSettings?.useMediaFootage;
	}

	private fillSystemInformation() {
		return (
			<form id="deviceViewSystemInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<MaterialTextField
							id="deviceCode"
							type="text"
							fullWidth
							label={TranslateText('fields.code')}
							inputProps={{
								readOnly: this.props.readOnly && this.props.renderFor === RenderForEnum.Widget,
								style: { fontSize: 12 },
							}}
							name="deviceCode"
							className="auto-deviceCode resize-font"
							value={this.state.device.code}
							handleValueChange={(args: any) => this.handleValueChange(args, 'code')}
							disabled={!(!this.props.readOnly && this.props.renderFor !== RenderForEnum.Widget)}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									event.preventDefault();
									return false;
								}
							}}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<CustomerNameDisplay customerId={this.state.device.customerId} />
				</div>
			</form>
		);
	}

	private fillED2Settings() {
		return (
			<form id="deviceViewED2SettingsForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group" style={{ marginTop: 15 }}>
						<FormControlLabel
							control={
								<Checkbox
									name="deviceNeedToBeSyncedWithEd2"
									color={'primary'}
									checked={this.state.device.deviceNeedToBeSyncedWithEd2}
									onChange={(args: any) =>
										this.handleCheckboxValue(args, 'deviceNeedToBeSyncedWithEd2')
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.deviceNeedToBeSyncedWithEd2')}
								</Typography>
							}
							disabled={this.props.readOnly}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="deviceShouldHaveEd2MediaSupport"
									color={'primary'}
									checked={this.state.device.deviceShouldHaveEd2MediaSupport}
									onChange={(args: any) =>
										this.handleCheckboxValue(args, 'deviceShouldHaveEd2MediaSupport')
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.deviceShouldHaveEd2MediaSupport')}
								</Typography>
							}
							disabled={this.props.readOnly}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				</div>
			</form>
		);
	}
	private handleIsTripDeviceChagenConsentClick(agreement: boolean) {
		const tempDevice = { ...this.state.device };
		tempDevice.isTripDeviceChangeConsent = agreement;
		if (agreement) {
			tempDevice.isTripDevice = !this.state.device.isTripDevice;
		}
		tempDevice.isTripDeviceChangeAgreementRequired = false;
		this.setState({
			openNonTripChangeNotification: false,
			consentNonTripChange: false,
			device: tempDevice,
			unsavedData: true,
		});
	}

	private handleIsTripDeviceChange(event: ChangeEvent<HTMLInputElement>) {
		if (this.props.renderFor === RenderForEnum.Widget) {
			this.setState({ nonTripChangeNotificationAnchorEl: event.target }, () => {
				this.setState({
					openNonTripChangeNotification: true,
				});
			});
			this.handleValueChange(true, 'isTripDeviceChangeAgreementRequired');
		} else {
			this.handleCheckboxValue(event, 'isTripDevice');
		}
	}
	private fillHardwareInformation() {
		return (
			<form id="deviceViewHardwareInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.device.deviceTypeId}
							dataSource={this.state.devicesTypesData}
							name="deviceTypeId"
							disabled={this.props.readOnly}
							label={TranslateText('fields.deviceType')}
							onChange={(args) => this.handleValueChange(args.value, 'deviceTypeId')}
						/>
						<ValidationMessage result={this.state.validationResult?.deviceTypeId} />
					</div>
					<div className="form-group">
						<MaterialDatePicker
							name="activationDate"
							date={this.state.device.productionDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(this.dateformat, true)}
							label={TranslateText('fields.productionDate')}
							showTime={false}
							disabled={this.props.readOnly}
							onDateChange={(date?: Date) => {
								this.handleValueChange(date, 'productionDate');
							}}
							fullWidth={true}
						/>
						<ValidationMessage result={this.state.validationResult?.productionDate} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.device.authenticationType}
							dataSource={AuthenticationTypeDataSource.dataSource()}
							name="authenticationType"
							disabled={this.props.readOnly}
							label={TranslateText('fields.authenticationType')}
							onChange={(args) => this.handleValueChange(args.value, 'authenticationType')}
						/>
						<ValidationMessage result={this.state.validationResult?.authenticationType} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.device.disconnectDriverAfterIgnitionOff}
							dataSource={this.defaultDataSource}
							name="disconnectDriverAfterIgnitionOff"
							disabled={
								this.props.readOnly ||
								this.state.device.authenticationType === AuthenticationTypeEnum.None ||
								this.state.device.authenticationType === AuthenticationTypeEnum.Fixed
							}
							label={TranslateText('fields.disconnectDriverAfterIgnitionOff')}
							onChange={(args) => this.handleValueChange(args.value, 'disconnectDriverAfterIgnitionOff')}
						/>
						<ValidationMessage result={this.state.validationResult?.disconnectDriverAfterIgnitionOff} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="disconnectDriverAfterIgnitionOffTimeOutSeconds"
							type="number"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.disconnectDriverAfterIgnitionOffTimeOutSeconds')}
							inputProps={{ style: { fontSize: 12 }, min: 0 }}
							name="disconnectDriverAfterIgnitionOffTimeOutSeconds"
							value={this.state.device.disconnectDriverAfterIgnitionOffTimeOutSeconds}
							handleValueChange={(args) =>
								this.handleValueChange(args, 'disconnectDriverAfterIgnitionOffTimeOutSeconds')
							}
							disabled={
								this.props.readOnly ||
								this.state.device.authenticationType === AuthenticationTypeEnum.None ||
								this.state.device.authenticationType === AuthenticationTypeEnum.Fixed ||
								!this.state.device.disconnectDriverAfterIgnitionOff
							}
						/>
						<ValidationMessage
							result={this.state.validationResult?.disconnectDriverAfterIgnitionOffTimeOutSeconds}
						/>
					</div>
					<div className="form-group">
						<MaterialTextField
							id="serialNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.serialNumber')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="serialNumber"
							value={this.state.device.serialNumber}
							handleValueChange={(args: any) => this.handleValueChange(args, 'serialNumber')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.serialNumber} />
						<MaterialTextField
							id="firmwareVersion"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.firmwareVersion')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="firmwareVersion"
							value={this.state.device.firmwareVersion}
							handleValueChange={(args: any) => this.handleValueChange(args, 'firmwareVersion')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.firmwareVersion} />
						<MaterialTextField
							id="hardwareVersionNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.hardwareVersionNumber')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="hardwareVersionNumber"
							value={this.state.device.hardwareVersionNumber}
							handleValueChange={(args: any) => this.handleValueChange(args, 'hardwareVersionNumber')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.hardwareVersionNumber} />
						<MaterialTextField
							id="certificateNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.certificateNumber')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="certificateNumber"
							value={this.state.device.certificateNumber}
							handleValueChange={(args: any) => this.handleValueChange(args, 'certificateNumber')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.certificateNumber} />
						<div className="form-group">
							<FormControlLabel
								control={
									<Checkbox
										name="isTripDevice"
										color={'default'}
										className="selection-checkbox"
										checkedIcon={<CheckBoxOutlinedIcon />}
										indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
										icon={<CheckBoxOutlineBlankOutlinedIcon />}
										checked={this.state.device.isTripDevice}
										onChange={(args: any) => this.handleIsTripDeviceChange(args)}
										size="small"
										disabled={this.props.readOnly}
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.isTripDevice')}
									</Typography>
								}
								labelPlacement="start"
								style={
									this.state.device.deviceFamily?.toLowerCase() !== 'solo'
										? { marginTop: 12, marginLeft: 0, marginRight: 0, marginBottom: 2 }
										: { marginTop: 20, marginLeft: 0, marginRight: 0, marginBottom: -2 }
								}
							/>
							<Popper
								style={{ maxWidth: 410, zIndex: 1000 }}
								open={this.state.openNonTripChangeNotification}
								disablePortal={true}
								anchorEl={this.state.nonTripChangeNotificationAnchorEl}
								placement={this.state.nonTripChangeNotificationPopupPlacement}
								modifiers={[
									{
										name: 'flip',
										enabled: true,
										options: {
											altBoundary: true,
											rootBoundary: 'document',
											padding: 0,
										},
									},
									{
										name: 'arrow',
										enabled: true,
										options: {
											element: this.state.nonTripChangeNotificationAnchorEl,
										},
									},
								]}
								transition
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Paper elevation={24}>
											<Typography
												style={{
													fontSize: this.inputFontSize,
													color: !this.props.readOnly ? 'black' : 'grey',
													marginLeft: 10,
													marginRight: 10,
													maxWidth: 450,
												}}
											>
												{this.state.device?.isTripDevice
													? TranslateText('fields.notifyChangeFromTripDeviceToNonTripDevice')
													: TranslateText('fields.notifyChangeFromNonTripDeviceToTripDevice')}
											</Typography>
											<FormControlLabel
												control={
													<Checkbox
														name="active"
														color={'primary'}
														checked={this.state.consentNonTripChange}
														onChange={(event) =>
															this.setState({
																consentNonTripChange: event.target.checked,
															})
														}
													/>
												}
												label={
													<Typography
														style={{
															fontSize: this.inputFontSize,
															marginRight: 5,
															color: !this.props.readOnly ? 'black' : 'grey',
															marginLeft: 10,
														}}
													>
														{TranslateText('fields.changeTripDeviceFlagConsent')}
													</Typography>
												}
												labelPlacement="start"
												style={{ margin: 0 }}
												disabled={this.props.readOnly}
											/>
											<div>
												<Button
													style={{
														fontSize: this.inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => this.handleIsTripDeviceChagenConsentClick(false)}
												>
													{TranslateText('common.buttonNo')}
												</Button>
												<Button
													disabled={!this.state.consentNonTripChange}
													style={{
														fontSize: this.inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => this.handleIsTripDeviceChagenConsentClick(true)}
												>
													{TranslateText('common.buttonYes')}
												</Button>
											</div>
										</Paper>
									</Fade>
								)}
							</Popper>
						</div>
					</div>

					{(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
						this.state.device.deviceFamily?.toLowerCase() === 'qanall') && (
						<div className="form-group">
							<FormControlLabel
								control={
									<Checkbox
										name="isCanFmsActive"
										color={'default'}
										className="selection-checkbox"
										checkedIcon={<CheckBoxOutlinedIcon />}
										indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
										icon={<CheckBoxOutlineBlankOutlinedIcon />}
										checked={this.state.device.isCanFmsActive}
										size="small"
										disabled
										readOnly
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.canBus')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0 }}
							/>
						</div>
					)}
				</div>
			</form>
		);
	}

	render() {
		return this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget();
	}
	private renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '49%' }}>
					<div style={{ width: '100%', height: '100%' }}>
						<Accordion
							className={
								this.useEasyDrive() &&
								(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
									this.state.device.deviceFamily?.toLowerCase() === 'qanall')
									? 'accordion-ed2-active'
									: ''
							}
							unExpandedCallback={() => {
								this.setState({
									expandedSection:
										this.state.expandedSection + 1 > 1 ? 0 : this.state.expandedSection + 1,
								});
							}}
						>
							<AccordionSection
								className={
									this.useEasyDrive() &&
									(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
										this.state.device.deviceFamily?.toLowerCase() === 'qanall')
										? this.state.expandedSection === 0
											? 'accordion-section-add-expanded-device-info'
											: 'accordion-section-add'
										: ''
								}
								header={TranslateText('common.systemInformation')}
								expanded={this.state.expandedSection === 0}
							>
								{this.fillSystemInformation()}
							</AccordionSection>
							{this.useEasyDrive() &&
							(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
								this.state.device.deviceFamily?.toLowerCase() === 'qanall') ? (
								<AccordionSection
									className={
										this.state.expandedSection === 1
											? 'accordion-section-add-expanded'
											: 'accordion-section-add'
									}
									header={TranslateText('common.ed2Settings')}
									expanded={this.state.expandedSection === 1}
								>
									{this.fillED2Settings()}
								</AccordionSection>
							) : null}
						</Accordion>
					</div>
				</div>
				<div className="column" style={{ width: '50%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.hardwareInformation')}>
							{
								<Scrollbar native={true} className="view-section-scroll">
									{this.fillHardwareInformation()}
								</Scrollbar>
							}
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}
	private renderForWidget() {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<Accordion
					scrollToTop={this.props.scrollToTop}
					expandedCallback={(index) => {
						this.setState({
							expandedSection: index,
						});
					}}
				>
					<AccordionSection
						className="auto-system-info"
						header={TranslateText('common.systemInformation')}
						expanded={this.state.expandedSection === 0}
					>
						{this.fillSystemInformation()}
					</AccordionSection>
					{this.useEasyDrive() &&
					(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
						this.state.device.deviceFamily?.toLowerCase() === 'qanall') ? (
						<AccordionSection
							className="auto-ed2Settings-info"
							header={TranslateText('common.ed2Settings')}
							expanded={this.state.expandedSection === 1}
						>
							{this.fillED2Settings()}
						</AccordionSection>
					) : null}
					<AccordionSection
						className="auto-hardware-info"
						header={TranslateText('common.hardwareInformation')}
						expanded={
							this.useEasyDrive() &&
							(this.state.device.deviceFamily?.toLowerCase() === 'solo' ||
								this.state.device.deviceFamily?.toLowerCase() === 'qanall')
								? this.state.expandedSection === 2
								: this.state.expandedSection === 1
						}
					>
						{this.fillHardwareInformation()}
					</AccordionSection>
				</Accordion>
			</div>
		);
	}
}

export default connector(DeviceView);
