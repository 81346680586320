import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const ViewListCustomIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 20 16">
			<path d="M0.32666 0.598145V15.5981H19.6123V0.598145H0.32666ZM4.6123 2.741V4.88387H2.46948V2.741H4.6123ZM2.46948 9.16959V7.02672H4.6123V9.16959H2.46948ZM2.46948 11.3124H4.6123V13.4553H2.46948V11.3124ZM17.4695 13.4553H6.75525V11.3124H17.4695V13.4553ZM17.4695 9.16959H6.75525V7.02672H17.4695V9.16959ZM17.4695 4.88387H6.75525V2.741H17.4695V4.88387Z" />
		</SvgIcon>
	);
};

export default React.memo(ViewListCustomIcon);
