export function newGuid() {
	return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

export function newHashValue(len = 40) {
	const arr = new Uint8Array(len / 2);
	window.crypto.getRandomValues(arr);
	return Array.from(arr, byteToHex).join('');
}

function byteToHex(byte: number) {
	return ('0' + byte.toString(16)).slice(-2);
}
