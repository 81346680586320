import '../oldWidget.scss';

import { Button } from '@material-ui/core';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import BaseWidget from 'components/BaseWidget';
import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { GridWidgetOverview } from 'components/GridOverview/GridWidgetOverview';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import DeviceSimCardConnection from 'models/DeviceSimCardConnection';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { Column } from 'primereact/column';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { FormatDate } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { OldWidgetProps } from '../Widget';
import AddConnection from './AddConnection';

const mapState = (state: ApplicationState) => {
	return { user: state.oidc.user };
};

const connector = connect(mapState, null, null, { forwardRef: true });
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OldWidgetProps &
	PropsFromRedux & {
		changeDataCallback?: (hasChanges: boolean) => void;
		entityType: EntityTypeEnum;
		conflictingConnectionNotifier: ConnectionConflictsNotifier;
		forceReload?: boolean;
		delayedCallback?: () => void;
	};

type State = {
	editRowId: string;
	editRow: boolean;
	connections: DeviceSimCardConnection[];
	canDisconnect: boolean;
	widgetInEditMode: boolean;
	rowInEdit: boolean;
	showDialog: boolean;
	currentEntityMode: boolean;
	visible: boolean;
	connectionStartDate: Date;
	connectionEndDate: Date;
	selectedRowData: DeviceSimCardConnection;
	validationResult: ValidationResult;
	disableUpdateButton: boolean;
};

class DeviceSimCardWidget extends BaseWidget<Props, State> {
	public requestedConnection: DeviceSimCardConnection;
	public currentEntityId: string;
	conflictingConnections: DeviceSimCardConnection[];
	conflictsCanBeAutomaticallyFixed: boolean;

	constructor(props: Props) {
		super(props);

		this.state = {
			editRowId: null,
			editRow: false,
			connections: null,
			canDisconnect: false,
			widgetInEditMode: false,
			rowInEdit: false,
			showDialog: false,
			currentEntityMode: false,
			visible: true,
			connectionStartDate: null,
			connectionEndDate: null,
			selectedRowData: null,
			validationResult: null,
			disableUpdateButton: false,
		};
	}

	componentDidMount() {
		this.getData(this.props.entityId, true);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
			this.getData(this.props.entityId, true);
		}
		if (prevProps.forceReload !== this.props.forceReload && this.props.forceReload) {
			this.getData(this.props.entityId, true);
		}
	}

	resetDates() {
		this.setState({
			connectionStartDate: null,
			connectionEndDate: null,
			rowInEdit: false,
			editRowId: null,
		});
	}

	closeEditPanelMode() {
		if (this.state.widgetInEditMode) {
			this.setHasUnsavedData(false);
			this.toggleEditMode();
		}
	}

	async getData(entityId: string, forceReload = false) {
		if (forceReload || this.currentEntityId !== entityId) {
			this.currentEntityId = entityId;

			let url = '';
			switch (this.props.entityType) {
				case EntityTypeEnum.Device:
					url += GlobalSettings.devicesMaintenanceApi + '/simcards/' + this.props.entityId;
					break;
				case EntityTypeEnum.SimCard:
					url += GlobalSettings.simCardsMaintenanceApi + '/devices/' + this.props.entityId;
					break;
			}

			return await ajaxUtil.get(url).then((data: any) => {
				this.setState({
					connections: data,
				});
				if (this.props.delayedCallback) {
					this.props.delayedCallback();
				}
			});
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgetInEditMode;
		this.props.editModeCallback(currentValueFlipped);

		if (!currentValueFlipped) {
			this.setState({
				rowInEdit: false,
			});
			this.setHasUnsavedData(false);
		}

		this.setState({
			widgetInEditMode: currentValueFlipped,
		});
	}

	fixConflictsAndUpdateConnection() {
		if (!this.requestedConnection) {
			return;
		}

		//prepare data for update
		const deviceSimCardConnection = {
			id: this.requestedConnection.id,
			deviceId: this.requestedConnection.entity1Id,
			simCardId: this.requestedConnection.entity2Id,
			connectionStartDate: this.requestedConnection.connectionStartDate,
			connectionEndDate: this.requestedConnection.connectionEndDate,
			resolveConflictsAutomatically: this.requestedConnection.resolveConflictsAutomatically,
		} as DeviceSimCardConnection;

		const currentEntityId = this.props.entityId;

		const url = GlobalSettings.connectionsApi + '/UpdateDeviceSimCardConnection';

		ajaxUtil.post(url, { value: deviceSimCardConnection }).then(() => {
			this.getData(currentEntityId, true);
		});

		this.setState({ rowInEdit: false });
		this.setHasUnsavedData(false);
		this.handleDataSaved();
	}

	handleConflictingConnections(confirm: boolean) {
		if (this.conflictsCanBeAutomaticallyFixed && confirm) {
			this.requestedConnection.resolveConflictsAutomatically = true;
			this.fixConflictsAndUpdateConnection();
		} else {
			this.handleDataSaved();
		}
	}

	handleDisconnectButtonState(canDisconnect: boolean): boolean {
		if (this.state.rowInEdit) {
			return false;
		}
		if (
			this.state.selectedRowData &&
			this.state.selectedRowData.connectionEndDate &&
			new Date(this.state.selectedRowData.connectionEndDate) < new Date()
		) {
			return false;
		}
		if (this.state.selectedRowData && new Date(this.state.selectedRowData.connectionStartDate) > new Date()) {
			return false;
		}
		return canDisconnect;
	}

	terminateSelectedConnection() {
		if (this.state.selectedRowData) {
			this.setState({ canDisconnect: false });
			const url =
				GlobalSettings.connectionsApi +
				'/TerminateDeviceSimCardConnection/' +
				(this.state.selectedRowData as DeviceSimCardConnection).id;

			const currentEntityId = this.props.entityId;

			ajaxUtil.post(url, null).then((data) => {
				if (data) {
					this.getData(currentEntityId, true);
					this.handleDataSaved();
				}
			});
		}
	}

	isConnectionCurrent(connectionStartDate: Date, connectionEndDate: Date): boolean {
		if (!connectionEndDate) {
			return true;
		}
		const referenceDate = new Date();
		return new Date(connectionStartDate) <= referenceDate && new Date(connectionEndDate) >= referenceDate;
	}

	handleRowSelected(connectionStartDate: Date, connectionEndDate: Date) {
		if (!this.isConnectionCurrent(connectionStartDate, connectionEndDate)) {
			this.setState({ canDisconnect: false });
		} else {
			this.setState({ canDisconnect: true });
		}
	}

	openAddConnectionWidget(currentEntityMode: boolean) {
		this.setState({
			showDialog: true,
			currentEntityMode: currentEntityMode,
		});
	}

	addConnectionDialogClosed() {
		this.setState({
			showDialog: false,
		});
	}

	setHasUnsavedData(hasUnsavedData: boolean) {
		this.hasUnsavedData = hasUnsavedData;
		this.props.changeDataCallback(hasUnsavedData);
	}

	handleDataSaved() {
		this.getData(this.props.entityId, true);
		if (this.props.savedCallback != null) {
			this.props.savedCallback();
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="5"
				data-minsizey="3"
			>
				<AddConnection
					savedCallback={() => this.handleDataSaved()}
					entityId={this.props.entityId}
					dialogCloseCallback={() => this.addConnectionDialogClosed()}
					currentEntityMode={this.state.currentEntityMode}
					showDialog={this.state.showDialog}
					renderFor={this.props.entityType}
					visible={false}
					forceReload={this.props.forceReload}
				/>
				<div className="e-panel-container">
					<WidgetHeader
						caption={
							this.props.entityType === EntityTypeEnum.Device
								? TranslateText('common.simCardHistory')
								: TranslateText('common.deviceHistory')
						}
						showEditButton={
							!this.state.rowInEdit &&
							ClaimUtil.validateClaimList(this.props.user, [
								{ claim: ClaimType.SimCards, values: [ClaimValue.edit] },
								{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
								{ claim: ClaimType.SimDeviceConnection, values: [ClaimValue.edit] },
							])
						}
						editMode={this.state.widgetInEditMode}
						allowEditMode={this.props.allowEditMode || this.state.widgetInEditMode}
						removePanelCallback={() => this.setState({ visible: false })}
						editCallback={() => this.toggleEditMode()}
					/>
					<div className="e-panel-content">
						<div id="gridHost" className="grid-host">
							<GridWidgetOverview
								columns={[
									<Column
										key={
											this.props.entityType === EntityTypeEnum.Device
												? 'simCardImsi'
												: 'deviceCode'
										}
										field={
											this.props.entityType === EntityTypeEnum.Device
												? 'simCardImsi'
												: 'deviceCode'
										}
										className="widget-grid-column"
										header={
											this.props.entityType === EntityTypeEnum.Device
												? TranslateText('widgets.grid.colIMSI')
												: TranslateText('widgets.grid.colCode')
										}
										style={{ width: 120 }}
										body={(rowData: any) => {
											const value =
												this.props.entityType === EntityTypeEnum.Device
													? rowData.simCardImsi
													: rowData.deviceCode;
											return this.state.widgetInEditMode ? (
												value
											) : (
												<Link
													to={
														this.props.entityType === EntityTypeEnum.Device
															? `${GlobalSettings.route.simcards}/${rowData.simCardId}`
															: `${GlobalSettings.route.devices}/${rowData.deviceId}`
													}
												>
													{value}
												</Link>
											);
										}}
									/>,
									<Column
										key={
											this.props.entityType === EntityTypeEnum.Device
												? 'simCardIccid'
												: 'deviceSerialNumber'
										}
										field={
											this.props.entityType === EntityTypeEnum.Device
												? 'simCardIccid'
												: 'deviceSerialNumber'
										}
										className="widget-grid-column"
										header={
											this.props.entityType === EntityTypeEnum.Device
												? TranslateText('widgets.grid.colICCID')
												: TranslateText('widgets.grid.colSerialNumber')
										}
										style={{ width: 150 }}
									/>,
									this.props.entityType === EntityTypeEnum.Device && (
										<Column
											key="phoneNumber"
											field="phoneNumber"
											className="widget-grid-column"
											header={TranslateText('widgets.grid.colPhoneNumber')}
											style={{ width: 150 }}
										/>
									),
									<Column
										key="connectionStartDate"
										field="connectionStartDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colStartDate')}
										style={{ width: 190 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerStart"
														showTime={true}
														key="datePickerStart"
														date={this.state.connectionStartDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId && rowData.id === this.state.editRowId && (
														<ValidationMessage
															result={this.state.validationResult?.connectionStartDate}
														/>
													)}
												</div>
											) : (
												FormatDate(new Date(rowData.connectionStartDate))
											)
										}
									/>,
									<Column
										key="connectionEndDate"
										field="connectionEndDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colEndDate')}
										style={{ width: 190 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerEnd"
														showTime={true}
														key="datePickerEnd"
														date={this.state.connectionEndDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId &&
														rowData.id === this.state.editRowId &&
														this.state.connectionEndDate && (
															<ValidationMessage
																result={this.state.validationResult?.connectionEndDate}
															/>
														)}
												</div>
											) : rowData.connectionEndDate ? (
												FormatDate(new Date(rowData.connectionEndDate))
											) : null
										}
									/>,
								]}
								data={this.state.connections}
								total={this.state.connections?.length}
								paginator={true}
								selectedRowCallback={(rowData) => {
									this.handleRowSelected(rowData.connectionStartDate, rowData.connectionEndDate);
									this.setState({ selectedRowData: rowData });
								}}
							/>
						</div>
					</div>
					{this.state.widgetInEditMode ? (
						<div id="buttonsHost" className="buttons-host">
							<div className="left-side-buttons">
								<Button
									className="widget-button new-connection"
									disabled={this.state.rowInEdit}
									onClick={() => this.openAddConnectionWidget(false)}
								>
									{TranslateText('common.new')}
								</Button>
								<Button
									className="widget-button disconnect"
									disabled={!this.handleDisconnectButtonState(this.state.canDisconnect)}
									onClick={() => this.terminateSelectedConnection()}
								>
									{TranslateText('common.disconnect')}
								</Button>
							</div>
							<div className="right-side-buttons">
								<Button
									className="widget-button link"
									disabled={this.state.rowInEdit}
									onClick={() => this.openAddConnectionWidget(true)}
								>
									{TranslateText('common.link')}{' '}
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default connector(DeviceSimCardWidget);
