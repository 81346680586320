import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AnalogTemplateItem from 'models/AnalogTemplateItem';
import React, { useEffect, useRef, useState } from 'react';
import ajaxUtil from 'utils/Ajax';
import { getAnalogTriggerConditions } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import { Divider, Grid } from '@material-ui/core';

import MainPowerEventTypesEnum from 'models/MainPowerEventTypesEnum';
import { MainPowerAlertDataProps } from './types';

const MainPowerAlertData = (props: MainPowerAlertDataProps) => {
	const getMainPowerEventTypeDataSource = (): AutocompleteItem[] => {
		return [
			{
				display: TranslateText('mainPowerEventTypes.both'),
				id: MainPowerEventTypesEnum.Both,
			},
			{
				display: TranslateText('mainPowerEventTypes.connected'),
				id: MainPowerEventTypesEnum.Connected,
			},
			{
				display: TranslateText('mainPowerEventTypes.disconnected'),
				id: MainPowerEventTypesEnum.Disconnected,
			},
		];
	};

	//analog template change
	const onMainPowerTriggerEventChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		const newTriggerEvent = arg.value as MainPowerEventTypesEnum;
		const newAnalogAlertMetadata = {
			...props.mainPowerAlertMetadata,
			mainPowerTriggerEvent: newTriggerEvent,
		};

		props.handleMainPowerAlertMetadataChange(newAnalogAlertMetadata);
	};

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('alert.mainPowerState')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'mainPowerTriggerEvent'}
					className="main-power-alert-event-autocomplete"
					valueId={props.mainPowerAlertMetadata.mainPowerTriggerEvent}
					dataSource={getMainPowerEventTypeDataSource()}
					onChange={onMainPowerTriggerEventChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
					disabledItems
				/>
				{props.validationResult?.validationResult?.mainPowerTriggerEvent && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.mainPowerTriggerEvent} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

export default MainPowerAlertData;
