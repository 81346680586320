import './styles.scss';

import ListSelectionView from 'components/ListSelectionView';
import { ListSelectionItem } from 'components/ListSelectionView/types';
import { LocationDto } from 'components/LocationsOverview/LocationsOverview';
import React, { useEffect, useState } from 'react';
import { getLocationColumns, locationInitialFilters } from 'utils/ListSelection/LocationsSelectionUtils';
import { TranslateText } from 'utils/Translations';

import GlobalSettings from '../../../../GlobalSettings.json';
import { LocationsViewProps } from './types';

const LocationsView = (props: LocationsViewProps) => {
	const columns = getLocationColumns();

	const [selection, setSelection] = useState<ListSelectionItem[]>([]);

	useEffect(() => {
		if (props.visible) {
			if (props.geofenceAlertMetadata?.locations) {
				setSelection(props.geofenceAlertMetadata.locations);
				props.setValidationCallback(props.geofenceAlertMetadata.locations.length > 0);
			} else {
				setSelection([]);
				props.setValidationCallback(false);
			}
		}
	}, [props.visible]);

	const updateAlertSelection = (newSelection: ListSelectionItem[]) => {
		setSelection(newSelection);

		const newgeofenceAlertMetadata = { ...props.geofenceAlertMetadata, locations: newSelection };
		props.setDataCallback({
			geofenceAlertMetadata: newgeofenceAlertMetadata,
		});
		props.onChangeCallback({
			geofenceAlertMetadata: newgeofenceAlertMetadata,
		});
		props.setValidationCallback(newSelection.length > 0);
	};

	const onSelectionToggle = (rowData: object, selected: boolean) => {
		const locatation = rowData as LocationDto;

		if (selected && !selection.some((x) => x.id === locatation.id)) {
			updateAlertSelection([
				...selection,
				{
					id: locatation.id,
					name: `${locatation.category ?? ''} ${locatation.name ?? ''}`,
					active: locatation.active,
				},
			]);
		} else if (!selected) {
			updateAlertSelection(selection.filter((x) => x.id !== locatation.id));
		}
	};

	const removeSelection = (item: ListSelectionItem) => {
		updateAlertSelection(selection.filter((x) => x.id !== item.id));
	};

	const removeAllSelection = () => {
		updateAlertSelection([]);
	};

	return (
		<div className="locations-view-container">
			<ListSelectionView
				filterPlaceholder={TranslateText('geofence.searchLocation')}
				selection={selection}
				onSelectionToggle={onSelectionToggle}
				removeSelection={removeSelection}
				removeAllSelection={removeAllSelection}
				columns={columns}
				initialFilters={locationInitialFilters}
				getGridDataUrl={`${GlobalSettings.locationsMaintenanceApi}/getPage`}
			/>
		</div>
	);
};

export default LocationsView;
