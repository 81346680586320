import { History, LocationState } from 'history';
import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import userService from '../../auth';

interface Props {
	history: History<LocationState>;
}

const Impersonate = (props: Props) => {
	useEffect(() => {
		const token = new URLSearchParams(props.history.location.search).get('token');
		userService.userManager.signinRedirect({ extraQueryParams: { impersonationToken: token } });
	}, []);

	return <CircularProgress className="loading-component" />;
};

export default Impersonate;
