import './styles.scss';

import { PageResult } from 'components/Common/Pagination';
import _ from 'lodash';
import { GridFilters } from 'models/GridOverview';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { getActiveFilter, getRequestFilters } from 'utils/GridOverviewUtils';
import { TranslateText } from 'utils/Translations';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import { GridListSelectionProps } from './types';

interface Entity {
	id: string;
}

const GridListSelection = (props: GridListSelectionProps) => {
	const [total, setTotal] = useState(0);
	const [take] = useState(10);
	const [skip, setSkip] = useState(0);

	const filteredCustomerId = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer?.id);

	const [gridData, setGridData] = useState([] as Entity[]);

	const [filters, setFilters] = useState<GridFilters>(() => {
		return _.cloneDeep(props.initialFilters);
	});

	const reloadGridData = (skip: number) => {
		const { sortOrder, sortField, fieldFilters, flagOrder, includeTime } = getRequestFilters(filters);
		ajaxUtil
			.post<PageResult<Entity>>(
				props.getGridDataUrl,
				{
					skip: skip,
					take: take,
					filterText: props.filterText,
					customerId: filteredCustomerId,
					sortOrder,
					sortField,
					flagOrder,
					includeTime,
					fieldFilters: props.showInactive ? fieldFilters : [...fieldFilters, getActiveFilter()],
				},
				{ showLoading: false }
			)
			.then((result) => {
				setGridData(result.data);
				setTotal(result.total);
			});
	};

	const onFilterChange = ({ sortField, sortOrder, filters }: DataTablePFSEvent) => {
		const newFilters: GridFilters = {
			sortField,
			sortOrder,
			filters,
		};
		setFilters(newFilters);
	};

	useEffect(() => {
		reloadGridData(skip);
	}, [skip, filters, props.filterText, props.showInactive]);

	const rowClass = useCallback((data: { active: boolean }) => {
		return data?.active ? '' : 'inactive-row';
	}, []);

	return (
		<div className={'grid-list-selection'}>
			<DataTable
				emptyMessage={TranslateText('common.noData')}
				className={'new-grid-overview grid-list-overview'}
				value={gridData}
				totalRecords={total}
				dataKey={'id'}
				rows={take}
				first={skip}
				rowClassName={rowClass}
				currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
				onPage={(e) => {
					setSkip(e.first);
				}}
				onRowClick={(e) => {
					const rowData = e?.data as Entity;
					if (rowData) {
						props.onSelectionToggle(rowData, !props.selection.some((x) => x.id === rowData.id));
					}
				}}
				filters={filters.filters}
				filterDisplay="row"
				onFilter={onFilterChange}
				resizableColumns
				columnResizeMode="fit"
				responsiveLayout="scroll"
				scrollable
				scrollDirection="vertical"
				scrollHeight="flex"
				removableSort
				sortField={filters.sortField}
				sortOrder={filters.sortOrder}
				onSort={onFilterChange}
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				lazy
			>
				<Column
					style={{ maxWidth: 30 }}
					body={(rowData: Entity) => {
						const selected = props.selection?.some((x) => x.id === rowData.id);
						return (
							<Checkbox
								color={'default'}
								className={'selection-checkbox'}
								checkedIcon={<CheckBoxOutlinedIcon />}
								icon={<CheckBoxOutlineBlankOutlinedIcon />}
								checked={selected}
								onChange={(e) => props.onSelectionToggle(rowData, e.target.checked)}
							/>
						);
					}}
				/>
				{props.columns}
			</DataTable>
		</div>
	);
};

export default GridListSelection;
