import { PRODUCTION_ENVIRONMENT } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import Customer from 'models/Customer';
import StartPageEnum from 'models/StartPageEnum';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { ApplicationState } from '../../store';
import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import CountrySelection from '../Common/CommonDataSelections/CountrySelection';
import LanguageSelection from '../Common/CommonDataSelections/LanguageSelection';
import TimezoneSelection from '../Common/CommonDataSelections/TimezoneSelection';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

interface CustomerViewProperties {
	setCustomer?: (valid: boolean, customer: Customer) => void;
	renderFor: RenderForEnum;
	readOnly: boolean;
	widgetState: WidgetStateEnum;
}

interface CustomerViewState {
	customer: Customer;
	clearView: boolean;
	expandedIndex: number;
	validationResult?: ValidationResult;
	unsavedData: boolean;
}

function mapStateToProps(state: ApplicationState) {
	return {
		customerLevel: state.globalCustomer?.filteredCustomer
			? state.availableCustomers.data.find(
					(c) => c.code.toUpperCase() === state.globalCustomer.filteredCustomer.code.toUpperCase()
			  ).level
			: state.currentSession.customerLevel,
		customerActive: state.globalCustomer?.filteredCustomer
			? state.availableCustomers.data.find(
					(c) => c.code.toUpperCase() === state.globalCustomer.filteredCustomer.code.toUpperCase()
			  ).active
			: state.currentSession.customer.active,
		currentCustomerLevel: state.currentSession.customerLevel,
	};
}

const connector = connect(mapStateToProps, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & CustomerViewProperties;

class CustomerView extends BaseView<Props, CustomerViewState> {
	public static defaultProps = {
		readOnly: false,
	};

	defaultActive = !(this.props.customerLevel === CustomerLevelEnum.Reseller && !this.props.customerActive);
	defaultStartCountryId = 528;
	defaultStartPage: StartPageEnum = StartPageEnum.Live;
	startPageDataSource = [
		{
			id: StartPageEnum.Live,
			display: TranslateText(`customerOverview.startPage.${StartPageEnum.Live}`),
		},
		{
			id: StartPageEnum.Dashboard,
			display: TranslateText(`customerOverview.startPage.${StartPageEnum.Dashboard}`),
		},
		{
			id: StartPageEnum.History,
			display: TranslateText(`customerOverview.startPage.${StartPageEnum.History}`),
		},
		{
			id: StartPageEnum.Reports,
			display: TranslateText(`customerOverview.startPage.${StartPageEnum.Reports}`),
		},
		{
			id: StartPageEnum.WhitePage,
			display: TranslateText(`customerOverview.startPage.${StartPageEnum.WhitePage}`),
		},
	];

	validator: Validator;

	constructor(props: any) {
		super(props);

		this.state = {
			customer: new Customer(
				this.defaultLanguageId,
				this.defaultTimezoneId,
				this.defaultStartPage,
				this.defaultStartCountryId,
				this.defaultActive
			),
			clearView: false,
			expandedIndex: 0,
			unsavedData: false,
		};

		this.validator = new Validator({
			fieldRules: {
				//system information
				code: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(40),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
						unique: {
							message: TranslateText('fieldsValidations.uniqueValue'),
							validationFunction: (data) => {
								const customerData = data as Customer;
								if (!customerData.code) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/CustomerFields`, {
									value: customerData.code,
									Field: 'code',
									editedEntityId: customerData.id ? customerData.id : null,
								});
							},
						},
					},
				},
				//data form
				name: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(200),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
					},
				},
				description: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(200),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
					},
				},
				languageId: {
					rules: { required: ValidatorFunctions.required() },
				},
				timezoneId: {
					rules: { required: ValidatorFunctions.required() },
				},
				startCountryId: {
					rules: { required: ValidatorFunctions.required() },
				},
				startPage: {
					rules: { required: ValidatorFunctions.required() },
				},
			},
		});
	}

	componentDidMount() {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			document.getElementById('customerCode').focus();
			//initial validation
			this.validator.validate(this.state.customer).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<CustomerViewProperties>, prevState: Readonly<CustomerViewState>) {
		if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
			this.validator.clearValidation();
			this.setState({ validationResult: null });
		}

		if (this.state.customer !== prevState.customer) {
			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				this.validator.validate(this.state.customer).then((result) => {
					this.setState({
						validationResult: result.validationResult,
					});

					if (this.state.unsavedData) {
						this.setCustomer(result.formResult, this.state.customer);
					}
				});
			}
		}
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.customer).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	setCustomer(valid: boolean, customer: Customer) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			this.props.setCustomer(valid, customer);
		}
	}

	handleValueChange(value: number | string | StartPageEnum, statePropName: string) {
		const tempCustomer = { ...this.state.customer };
		tempCustomer[statePropName] = value;
		this.setState({
			customer: tempCustomer,
			unsavedData: true,
		});
	}

	fillSystemInformation() {
		return (
			<form id="customerViewSystemInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="customerCode"
							type="text"
							fullWidth
							className="resize-font"
							label={TranslateText('fields.code')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="customerCode"
							value={this.state.customer.code}
							onChange={(args: any) => {
								if (args.target) {
									this.handleValueChange(args.target.value, 'code');
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
						<div>
							<FormControlLabel
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={this.state.customer.active}
										disabled={true}
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.active')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0, marginTop: 5 }}
							/>
						</div>
						{this.props.currentCustomerLevel === CustomerLevelEnum.OEM &&
							process.env.REACT_APP_ENV !== PRODUCTION_ENVIRONMENT && (
								<div>
									<FormControlLabel
										control={
											<Checkbox
												name="isTest"
												color={'primary'}
												checked={this.state.customer.isTest}
												disabled={this.props.readOnly}
												onChange={(args: any) => {
													if (args.target) {
														this.handleValueChange(args.target.checked, 'isTest');
													}
												}}
											/>
										}
										label={
											<Typography style={{ fontSize: 12, marginRight: 5 }}>
												{TranslateText('fields.isTest')}
											</Typography>
										}
										labelPlacement="start"
										style={{ margin: 0, marginTop: 10 }}
									/>
								</div>
							)}
					</div>
				</div>
			</form>
		);
	}

	fillCustomerData() {
		return (
			<form id="customerViewDataForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="customerName"
							type="text"
							fullWidth
							className="resize-font"
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="customerName"
							value={this.state.customer.name}
							onChange={(args: any) => {
								if (args.target) {
									this.handleValueChange(args.target.value, 'name');
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.name} />
					</div>
					<div className="form-group">
						<TextField
							id="customerDescription"
							type="text"
							fullWidth
							className="resize-font"
							label={TranslateText('fields.description')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="customerDescription"
							value={this.state.customer.description}
							onChange={(args: any) => {
								if (args.target) {
									this.handleValueChange(args.target.value, 'description');
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.description} />
					</div>
					<div className="form-group">
						<LanguageSelection
							label={TranslateText('fields.language')}
							name="customerLanguageId"
							valueId={this.state.customer.languageId}
							disabled={this.props.readOnly}
							onChange={({ value }) => this.handleValueChange(value as string, 'languageId')}
						/>
						<ValidationMessage result={this.state.validationResult?.languageId} />
					</div>
					<div className="form-group">
						<TimezoneSelection
							label={TranslateText('fields.timezone')}
							name="customerTimeZoneId"
							valueId={this.state.customer.timezoneId}
							disabled={this.props.readOnly}
							onChange={({ value }) => this.handleValueChange(value as string, 'timezoneId')}
						/>
						<ValidationMessage result={this.state.validationResult?.timezoneId} />
					</div>
					<div className="form-group">
						<CountrySelection
							label={TranslateText('fields.startCountryCode')}
							name="startCountryId"
							valueId={
								this.state.customer.startCountryId ? this.state.customer.startCountryId.toString() : ''
							}
							disabled={this.props.readOnly}
							onChange={({ value }) => {
								const newValue = value ? Number(value) : null;
								this.handleValueChange(newValue as number, 'startCountryId');
							}}
						/>
						<ValidationMessage result={this.state.validationResult?.startCountryId} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.customer.startPage}
							dataSource={this.startPageDataSource}
							name="startPage"
							disabled={this.props.readOnly}
							label={TranslateText('fields.startPage')}
							onChange={({ value }) => this.handleValueChange(value as StartPageEnum, 'startPage')}
						/>
						<ValidationMessage result={this.state.validationResult?.startPage} />
					</div>
				</div>
			</form>
		);
	}

	render() {
		if (this.state.clearView) {
			return null;
		}

		return this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget();
	}

	renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '49%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillSystemInformation()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
				<div className="column" style={{ width: '50%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.customerData')}>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillCustomerData()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}

	renderForWidget() {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<Accordion
					expandedCallback={(index: number) => {
						this.setState({
							expandedIndex: index,
						});
					}}
				>
					<AccordionSection
						expanded={this.state.expandedIndex === 0}
						header={TranslateText('common.systemInformation')}
					>
						<Scrollbar native={true} className="view-section-scroll">
							{this.fillSystemInformation()}
						</Scrollbar>
					</AccordionSection>
					<AccordionSection
						expanded={this.state.expandedIndex === 1}
						header={TranslateText('common.customerData')}
					>
						<Scrollbar native={true} className="view-section-scroll">
							{this.fillCustomerData()}
						</Scrollbar>
					</AccordionSection>
				</Accordion>
			</div>
		);
	}
}

export default connector(CustomerView);
