import '../../style.scss';
import './styles.scss';

import {
	filterWorkingDays,
	getDefaultWorkingDays,
	mapSavedWorkingDays,
} from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';

import { TimeScheduleView } from '../TimeScheduleView';
import { getTimeScheduleValidationRules } from '../TimeScheduleView/TimeScheduleUtils';
import { GeofenceTimeScheduleDto } from '../TimeScheduleView/types';
import { AlertTimeScheduleViewProps } from './types';

const AlertTimeScheduleView = (props: AlertTimeScheduleViewProps) => {
	const defaultTimeSchedule = useMemo(() => getDefaultWorkingDays(true), []);
	const dateTimeSettings = useSelector((s: ApplicationState) => s.currentSession.dateTimeSettings);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: getTimeScheduleValidationRules() }));
	}, [props.entityId]);

	const [formData, setFormData] = useState<GeofenceTimeScheduleDto>(() => {
		const startingDay = dateTimeSettings.startingDay >= 0 ? dateTimeSettings.startingDay : 0;
		return {
			timeSchedule: mapSavedWorkingDays(startingDay, defaultTimeSchedule, props.data.timeSchedule),
			monitoringPeriod: props.data.monitoringPeriod,
		};
	});

	useEffect(() => {
		const startingDay = dateTimeSettings.startingDay >= 0 ? dateTimeSettings.startingDay : 0;
		setFormData({
			timeSchedule: mapSavedWorkingDays(startingDay, formData.timeSchedule, props.data.timeSchedule),
			monitoringPeriod: props.data.monitoringPeriod,
		});
	}, [props.data]);

	const updateData = (newFormData: GeofenceTimeScheduleDto) => {
		props.changeDataCallback({
			timeSchedule: filterWorkingDays(newFormData.timeSchedule),
			monitoringPeriod: newFormData.monitoringPeriod,
		});
	};

	return (
		<TimeScheduleView
			className="alert-time-schedule-wiew"
			disabled={!props.editMode}
			data={formData}
			updateDataCallback={updateData}
			validationResult={props.validationResult}
		/>
	);
};

export default AlertTimeScheduleView;
