import './validationmessage.scss';

import { InputLabel } from '@material-ui/core';
import { FieldValidationResult } from 'shared/validation/interfaces';
import React from 'react';

interface Props {
	result: FieldValidationResult;
	isForNewAddWizard?: boolean;
	className?: string;
}

const ValidationMessage = (props: Props) => {
	if (props.result) {
		const key = Object.keys(props.result).find((r) => !props.result[r].valid);
		if (key) {
			return !props.isForNewAddWizard ? (
				<label
					className={`${props.className ? props.className : ''} ${
						props.result[key].error ? 'validation-error' : 'validation-warning'
					}`}
				>
					{props.result[key].message}
				</label>
			) : (
				<InputLabel
					className={
						props.result[key].error ? 'validation-error font-size-10' : 'validation-warning font-size-10'
					}
				>
					{props.result[key].message}
				</InputLabel>
			);
		}
	}
	return <></>;
};
export { ValidationMessage };
