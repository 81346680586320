import './fleetSelectionDialog.scss';
import '../SelectionDialog/SelectionDialog.scss';

import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeConfigurationUtil from 'utils/TreeSelection/TreeConfigurationUtil';

import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Typography } from '@material-ui/core';

import * as GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import { FleetEntityTypeFilter } from '../../store/FleetSelection';
import { rightSideBarCreators } from '../../store/RightSideBar';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import RadioButtonContainer, { ButtonConfig } from '../RadioButtonContainer';
import SelectionUtil from '../SelectionDialog/SelectionUtil';
import SelectionDialogContent from '../SelectionDialogContent/SelectionDialogContent';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import { NumberConstants } from '../Widgets/CustomerFeaturesView';

interface Props {
	visible: boolean;
	closeCallback: (data: ITreeNode[]) => void;
	initialSelection: ITreeNode[];
	saveCallback: (data: ITreeNode[], showInactive: boolean) => void;
}

export const FleetSelectionDialogButtonHelper = {
	getButtonConfig: (driverIdentification: boolean, assetTracking: boolean): ButtonConfig[] => {
		const result: ButtonConfig[] = [];

		if (driverIdentification || assetTracking) {
			result.push({
				title: TranslateText('fleetSelection.all'),
				id: FleetEntityTypeFilter.all,
			});
		}
		result.push({
			title: TranslateText('fleetSelection.objects'),
			id: FleetEntityTypeFilter.object,
		});
		if (assetTracking) {
			result.push({
				title: TranslateText('fleetSelection.assets'),
				id: FleetEntityTypeFilter.asset,
			});
		}
		if (driverIdentification) {
			result.push({
				title: TranslateText('fleetSelection.drivers'),
				id: FleetEntityTypeFilter.person,
			});
		}

		return result;
	},
};

const FleetSelectionDialog = (props: Props) => {
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer.featuresSettings
	);

	const showInactive = useSelector((state: ApplicationState) => state.fleetSelection.showInactive);
	const [localShowInactive, setLocalShowInactive] = useState(showInactive);
	useEffect(() => {
		setLocalShowInactive(showInactive);
	}, [showInactive]);

	const defaultValue = useSelector((state: ApplicationState) => state.currentSession.defaultEntity);
	const [selectedFilter, setSelectedFilter] = useState<FleetEntityTypeFilter>(defaultValue);
	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [treeData, setTreeData] = useState([] as ITreeNode[]);
	const [selectedItems, setSelectedItems] = useState([...props.initialSelection]);
	const [expanded, setExpanded] = useState([] as string[]);
	const buttonConfig = FleetSelectionDialogButtonHelper.getButtonConfig(
		featuresSettings.driverIdentification,
		featuresSettings.assetTracking
	);
	const [dataIsLoading, setDataIsLoading] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		//Fetch data when filter changes
		if (props.visible) {
			setDataIsLoading(true);
			ajaxUtil
				.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetFleetSelectionData`, {
					customerId: customerId,
					showPersons: TreeConfigurationUtil.getShowPersons(
						selectedFilter,
						featuresSettings.driverIdentification
					),
					personType: PersonTypeFilterEnum.Driver,
					objectFunction: TreeConfigurationUtil.getObjectFunctionFilter(
						selectedFilter,
						featuresSettings.assetTracking
					),
					showObjects: TreeConfigurationUtil.getShowObjects(selectedFilter),
					showInactive: localShowInactive,
					filterText: filterText.trim(),
				})
				.then((data) => {
					//reset tree data
					setTreeData([]);
					//add translation to All group
					if (data) {
						for (let index = 0; index < data.length; index++) {
							if (data[index].id === null && data[index].text === 'All') {
								data[index].text = TranslateText('common.all');
								break;
							}
						}
					}
					updateExpandedState(filterText, data);

					SelectionUtil.prepareTreeData(data, selectedItems, expanded, setTreeData);
					SelectionUtil.prepareInactiveEntities(localShowInactive, data, selectedItems, setSelectedItems);
				})
				.catch((e) => {
					setTreeData([]);
				})
				.finally(() => {
					setDataIsLoading(false);
				});
		}
	}, [props.visible, filterText, localShowInactive, selectedFilter]);

	useEffect(() => {
		//reset filters when closing dialog
		if (!props.visible) {
			setSelectedFilter(defaultValue);
			setFilterText('');
			setExpanded([]);
		}
	}, [props.visible]);

	useEffect(() => {
		if (props.visible) {
			//Update tree when selection changes
			SelectionUtil.prepareTreeData(treeData, selectedItems, expanded, setTreeData);
		}
	}, [selectedItems, expanded]);

	useEffect(() => {
		//Set tree data for initial selection, or re-opening the dialog
		setSelectedItems(props.initialSelection);
	}, [props.initialSelection]);

	const saveClicked = useCallback(() => {
		if (selectedItems.length) {
			dispatch(
				rightSideBarCreators.updateLiveAndHistoryOption(
					NumberConstants.rightSideBarOverviewOption,
					NumberConstants.rightSideBarOverviewOption
				)
			);
		}
		props.saveCallback(selectedItems, localShowInactive);
	}, [selectedItems]);

	const cancelClicked = useCallback(() => {
		setLocalShowInactive(showInactive);
		props.closeCallback([...props.initialSelection]);
	}, [selectedItems]);

	//expand callback
	const expandCallback = useCallback(
		(node: ITreeNode) => {
			SelectionUtil.expandCallback(node, expanded, setExpanded);
		},
		[expanded]
	);

	//select callback
	const selectCallback = useCallback(
		(node: ITreeNode) => {
			SelectionUtil.select(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	//clear selection
	const clearSelection = useCallback(async () => {
		await SelectionUtil.clearSelection(treeData, setTreeData, setSelectedItems);
	}, [treeData, selectedItems]);

	//remove selected item
	const removeSelectedItem = useCallback(
		(node: ITreeNode) => {
			SelectionUtil.deselectNode(node, treeData, setTreeData, selectedItems, setSelectedItems);
		},
		[treeData, selectedItems]
	);

	//select all
	const selectAll = useCallback(async () => {
		SelectionUtil.selectAll(treeData, setTreeData, setSelectedItems);
	}, [treeData]);

	const updateExpandedState = (filterText: string, data: ITreeNode[]) => {
		if (filterText && filterText.trim().length > 0) {
			setExpanded(data.map((d) => d.id));
		} else if (!filterText || previousFilterText !== filterText) {
			setExpanded([]);
		}
		setPreviousFilterText(filterText?.trim());
	};

	return (
		<div className={'selection-dialog-container'}>
			<Dialog onEscapeKeyDown={cancelClicked} fullWidth className={'selection-dialog'} open={props.visible}>
				<DialogContent>
					<span className={'e-dlg-header'}>{TranslateText('common.filter')}</span>
					<div className={'filter'}>
						<div className={'buttons'}>
							<RadioButtonContainer
								buttons={buttonConfig}
								visible={props.visible}
								selectedButton={selectedFilter}
								buttonSelectCallback={setSelectedFilter}
							/>
						</div>
						<FormControlLabel
							control={
								<Checkbox
									name="showInactive"
									color={'primary'}
									checked={localShowInactive}
									onChange={() => setLocalShowInactive((prev) => !prev)}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginLeft: 5 }}>
									{TranslateText('fleetSelection.showInactive')}
								</Typography>
							}
						/>
					</div>
					<SelectionDialogContent
						treeData={treeData}
						selectedItems={selectedItems}
						changeFilterCallback={setFilterText}
						selectAll={selectAll}
						selectCallback={selectCallback}
						removeSelectedItem={removeSelectedItem}
						clearSelection={clearSelection}
						expandCallback={expandCallback}
						filterText={filterText}
						filterPlaceholder={TranslateText('fleetSelection.searchEntitiesPlaceholder')}
						dataIsLoading={dataIsLoading}
					/>
					<div className={'buttons-container'}>
						<Button onClick={cancelClicked}>{TranslateText('common.buttonCancel')}</Button>
						<Button onClick={saveClicked}>{TranslateText('common.buttonSave')}</Button>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};
FleetSelectionDialog.defaultProps = {
	visible: true,
};
export default FleetSelectionDialog;
