import { ITreeNode } from '../components/SelectionTree/TreeNode/types';
import ClaimValuePair from './ClaimValuePair';
import CustomerLevelEnum from './CustomerLevelEnum';
import IdIdentifiable from './IdIdentifiable';
import SelectionItem from './SelectionItem';

class Role extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.customerId = customerId;
		this.name = '';
		this.templateDescription = '';
	}

	code: string;
	customerId: string;
	customerType: CustomerLevelEnum;
	templateId: string;
	active = true;
	name: string;
	description: string;
	templateDescription: string;
	usersCount: string;
	reportsCount: string;
	generic: boolean;
	roleClaims: ClaimValuePair[] = [];
	reports: SelectionItem[] = [];
	reportsNodes: ITreeNode[] = [];
}

export default Role;
