import './PersonHistorySettings.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';

import LocationOnMapEnum from 'models/LocationOnMapEnum';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { userDataActionCreators } from 'store/UserData';
import GlobalSettings from '../../../GlobalSettings.json';

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

export interface PersonHistorySettingsDto {
	maxHistoryTime: number;
	useHistoryLocationFields: boolean;
	useHistoryLocationOnMap: LocationOnMapEnum;
	showTemplateStateOnMap: boolean;
}

type ChangeValue = boolean | LocationOnMapEnum;

const areTheSame = (initialData: PersonHistorySettingsDto, changedData: PersonHistorySettingsDto) => {
	if (JSON.stringify(initialData) === JSON.stringify(changedData)) {
		return true;
	}
	return false;
};

const getConstants = () => {
	return {
		useHistoryLocationFields: [
			{ id: null, display: TranslateText('common.default') },
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
		useHistoryLocationOnMap: [
			{ id: null, display: TranslateText('common.default') },
			{ id: 0, display: TranslateText('customerOverview.locationOnMap.dontShow') },
			{ id: 1, display: TranslateText('customerOverview.locationOnMap.icon') },
			{ id: 2, display: TranslateText('customerOverview.locationOnMap.iconLabel') },
		],
		showTemplateStateOnMap: [
			{ id: null, display: TranslateText('common.default') },
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
	};
};

const PersonHistorySettings = (props: Props) => {
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);

	const [initialData, setInitialData] = useState<PersonHistorySettingsDto>(null);
	const [data, setData] = useState<PersonHistorySettingsDto>(null);
	const [constants] = useState(getConstants());
	const [isChanged, setIsChanged] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const dispatch = useDispatch();

	const events = {
		onValueChanged: async (value: ChangeValue, key: keyof PersonHistorySettingsDto) => {
			const newData = { ...data };
			(newData[key] as string | boolean | number) = value;

			setData(newData);

			if (areTheSame(initialData, newData)) {
				setIsChanged(false);
			} else if (!isChanged) {
				setIsChanged(true);
			}
		},
	};

	useEffect(() => {
		const url = `${GlobalSettings.profileMaintenanceApi}/historySettings/${personId}`;
		ajaxUtil.get<PersonHistorySettingsDto>(url).then((data: PersonHistorySettingsDto) => {
			setData(data);
			setInitialData(data);
			setIsLoaded(true);
		});
	}, []);

	useEffect(() => {
		if (!props.unsavedData && isChanged) {
			props.setUnsavedData(true);
			window.onbeforeunload = () => {
				return 'Unsaved data';
			};
		} else if (!isChanged && props.unsavedData) {
			props.setUnsavedData(false);
			window.onbeforeunload = null;
		}
	}, [data]);

	const saveHistorySettings = () => {
		const url = `${GlobalSettings.profileMaintenanceApi}/historySettings/`;
		ajaxUtil.put(url, data).then(() => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
		});
		dispatch(userDataActionCreators.setPersonHistorySettings(data));
	};

	return (
		isLoaded && (
			<Grid item md={12} className={'person-history-settings-grid'}>
				<Card>
					<CardContent>
						<div>
							<Typography variant="h5" className={'title'}>
								{TranslateText('customerOverview.historySettings')}
							</Typography>
							<div className={'person-history-settings-container'}>
								<div className={'setting-container'}>
									<div className={'setting-title-container'}>
										<Typography variant="h6" className={'setting-title'}>
											{TranslateText('fields.useLocationFields')}
										</Typography>
										<span className={'setting-description'}>
											{TranslateText('personHistorySettings.useLocationFieldsDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										<MaterialAutocomplete
											valueId={data.useHistoryLocationFields}
											dataSource={constants.useHistoryLocationFields}
											name="useHistoryLocationFields"
											onChange={({ value }) =>
												events.onValueChanged(value as boolean, 'useHistoryLocationFields')
											}
											className={'autocomplete-option'}
											disableClearable
										/>
									</div>
								</div>
								<Divider className={'middle-divider'} />
								<div className={'setting-container'}>
									<div className={'setting-title-container'}>
										<Typography variant="h6" className={'setting-title'}>
											{TranslateText('fields.useLocationOnMap')}
										</Typography>
										<span className={'setting-description'}>
											{TranslateText('personHistorySettings.useLocationOnMapDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										<MaterialAutocomplete
											valueId={data.useHistoryLocationOnMap}
											dataSource={constants.useHistoryLocationOnMap}
											name="useHistoryLocationOnMap"
											onChange={({ value }) =>
												events.onValueChanged(
													value as LocationOnMapEnum,
													'useHistoryLocationOnMap'
												)
											}
											className={'autocomplete-option'}
											disableClearable
										/>
									</div>
								</div>
								<Divider className={'middle-divider'} />
								<div className={'setting-container'}>
									<div className={'setting-title-container'}>
										<Typography variant="h6" className={'setting-title'}>
											{TranslateText('fields.showTemplateStateOnMap')}
										</Typography>
										<span className={'setting-description'}>
											{TranslateText('personHistorySettings.showTemplateStateOnMap')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										<MaterialAutocomplete
											valueId={data.showTemplateStateOnMap}
											dataSource={constants.showTemplateStateOnMap}
											name="showTemplateStateOnMap"
											onChange={({ value }) =>
												events.onValueChanged(
													value as boolean,
													'showTemplateStateOnMap'
												)
											}
											className={'autocomplete-option'}
											disableClearable
										/>
									</div>
								</div>
							</div>
							<Button
								className={`save-button ${isChanged ? 'enabled' : ''}`}
								disabled={!isChanged}
								onClick={saveHistorySettings}
							>
								{TranslateText('common.buttonSave')}
							</Button>
							{isChanged && (
								<span className={'info-text'}>
									{TranslateText('personSettingsWindow.changedAppliedAfterLogin')}
								</span>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		)
	);
};

export default PersonHistorySettings;
