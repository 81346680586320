import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { ColorPicker } from 'components/ColorPicker';
import AddInGroupsSelector from 'components/Common/AddInGroupsSelector/AddInGroupsSelector';
import { NL_COUNTRY_ID } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import FuelTypeEnum from 'models/FuelTypeEnum';
import { RDWVehicleDataDto } from 'models/RDWVehicleDataDto';
import TrackableObject from 'models/TrackableObject';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import FuelTypeDataSource from 'shared/staticDataSources/FuelTypeDataSource';
import ObjectTypeDataSource from 'shared/staticDataSources/ObjectTypeDataSource';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState, IAvailableCustomer } from 'store';
import { availableCustomersActions } from 'store/AvailableCustomers';
import ajaxUtil from 'utils/Ajax';
import { GetSelectedCustomer } from 'utils/CustomerUtils';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import CO2CalculationTypeEnum from '../../models/CO2CalculationTypeEnum';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';
import ObjectTypeEnum from '../../models/ObjectTypeEnum';
import CO2CalculationTypeDataSource from '../../shared/staticDataSources/CO2CalculationTypeDataSource';
import ObjectAssetTypeDatasource from '../../shared/staticDataSources/ObjectAssetTypeDatasource';
import ObjectFunctionDataSource from '../../shared/staticDataSources/ObjectFunctionDataSource';
import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete, { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import TimezoneSelection from '../Common/CommonDataSelections/TimezoneSelection';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import { handleKeyDown } from '../Common/RemoveSpinnerOptionInput';
import {
	ConflictConfirmation,
	ConnectionsConflictResult,
	Props as ConflictConfirmationProps,
} from '../ConnectionConflictsNotifier/ConflictConfirmation';
import IconPickerWrapper from '../IconPicker/IconPickerWrapper/IconPickerWrapper';
import { ImagePicker } from '../ImagePicker';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import { CustomerObjectSettings } from '../Widgets/DeviceObjectWidget/AddConnection';
import RenderForEnum from './RenderForEnum';
import UsedFromEnum from './UsedFromEnum';
import WidgetStateEnum from './WidgetStateEnum';

export enum UsedFrom {
	Object = 0,
	Device = 1,
	Person = 2,
}

const mapState = (state: ApplicationState) => {
	return {
		entityDefaultsSettings: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.entityDefaultsSettings
			: state.currentSession.customer.entityDefaultsSettings,
		defaultCustomerTimezone: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.timeZone
			: state.currentSession.customer.timezoneId,
		user: state.oidc.user,
		assetTracking: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking,
		entityCustomer: state.loadedEntityContext?.entityContextData?.customerId,
		availableCustomers: state.availableCustomers.data,
	};
};

const mapDispatch = (dispatch: Dispatch) => {
	return {
		actions: {
			availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		},
	};
};

const connector = connect(mapState, mapDispatch, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	setObject?: (valid: boolean, object: TrackableObject, unsavedData: boolean) => void;
	scrollToTop?: () => void;
	devicesData: AutocompleteItem[];
	renderFor: RenderForEnum;
	usedFrom: UsedFromEnum;
	readOnly: boolean;
	widgetState: WidgetStateEnum;
	defaultCustomerId: string;
	defaultCustomerSettings?: CustomerObjectSettings;
	allowCustomerChange: boolean;
	connectionStartDateFromConnectDialog?: Date;
	connectCurrentData?: TrackableObject;
};

interface State {
	object: TrackableObject;
	unsavedData: boolean;
	connectedSimIMSI?: string;
	lastMessageReceived?: Date;
	lastMessageWithValidGPS?: Date;
	selectedIndexInfoWidget: number;
	validationResult?: ValidationResult;
	conflictConfirmationProps: ConflictConfirmationProps;
	isExternalCodeFirstTouched: boolean;
	isLoading: boolean;
	rdwDataFetching: boolean;
	customerCountryId: number;
}

class ObjectView extends BaseView<Props, State> {
	initialObject: TrackableObject;
	validator: Validator;
	isAsset: boolean;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
		usedFrom: UsedFromEnum.Object,
		connectionStartDateFromConnectDialog: null as Date,
		connectCurrentData: null as TrackableObject,
		defaultCustomerSettings: null as CustomerObjectSettings,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			unsavedData: false,
			object: new TrackableObject(props.defaultCustomerId),
			connectedSimIMSI: '',
			lastMessageReceived: null,
			lastMessageWithValidGPS: null,
			selectedIndexInfoWidget: 0,
			conflictConfirmationProps: {
				visible: false,
				confirmCallback: null,
				canSolveConflicts: true,
				conflictData: [],
				connectionType: ConnectionTypeEnum.DeviceObject,
			},
			isExternalCodeFirstTouched: false,
			isLoading: false,
			rdwDataFetching: false,
			customerCountryId: this.GetAvailableCustomerInfo(props.entityCustomer ?? GetSelectedCustomer().id)
				?.startCountryId,
		};

		this.validator = new Validator(
			{
				fieldRules: {
					code: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(24),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: (data, fieldName) => {
									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
										customerId: this.state.object.customerId,
										value: data[fieldName],
										Field: fieldName,
										editedEntityId: !!this.state.object.id ? this.state.object.id : null,
									});
								},
							},
						},
					},
					externalCode: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(24),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: (data, fieldName) => {
									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
										customerId: this.state.object.customerId,
										value: data[fieldName],
										Field: fieldName,
										editedEntityId: !!this.state.object.id ? this.state.object.id : null,
									});
								},
							},
						},
					},
					timezoneId: {
						rules: {
							required: ValidatorFunctions.required(),
						},
					},
					licensePlate: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(20),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							warningUnique: {
								message: TranslateText('fieldsValidations.warningValueNotUnique'),
								isWarning: true,
								validationFunction: (data, fieldName) => {
									if (!data[fieldName]) {
										return ValidatorFunctions.wrapInPromise(true);
									}

									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
										customerId: this.state.object.customerId,
										value: data[fieldName],
										Field: 'licensePlate',
										editedEntityId: this.state.object?.id || null,
									});
								},
							},
						},
					},
					name: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(50),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
						},
					},
					vin: {
						rules: {
							maxLength: ValidatorFunctions.maxLength(30),
						},
					},
					brand: {
						rules: { maxLength: ValidatorFunctions.maxLength(100) },
					},
					model: {
						rules: { maxLength: ValidatorFunctions.maxLength(100) },
					},
					mileage: {
						rules: !this.props.readOnly &&
							(this.props.renderFor === RenderForEnum.Wizard ||
								this.props.renderFor === RenderForEnum.ConnectDialog) && {
								required: ValidatorFunctions.requiredIfFieldHasValue(
									'objectFunction',
									ObjectFunctionEnum.Full
								),
								maxLength: ValidatorFunctions.maxLength(10),
							},
					},
					toleranceTimeOutDistance: {
						rules: {
							required: ValidatorFunctions.required(),
							minLimit: {
								message: TranslateText('fieldsValidations.minValue') + '50',
								validationFunction: (data, fieldName) => {
									if (data[fieldName] == -1) {
										return ValidatorFunctions.wrapInPromise(true);
									} else {
										return ValidatorFunctions.wrapInPromise(
											(!data[fieldName] && data[fieldName] !== 0) || data[fieldName] >= 50
										);
									}
								},
							},
							maxNumber: ValidatorFunctions.maxNumber(250),
						},
					},
					timeOutEndOfTrip: {
						rules: {
							required: ValidatorFunctions.requiredIfFieldHasValue(
								'objectFunction',
								ObjectFunctionEnum.Full
							),
							minLimit: {
								message: TranslateText('fieldsValidations.minValue') + '15',
								validationFunction: (data, fieldName) => {
									if (data[fieldName] == -1) {
										return ValidatorFunctions.wrapInPromise(true);
									} else {
										return ValidatorFunctions.wrapInPromise(
											(!data[fieldName] && data[fieldName] !== 0) || data[fieldName] >= 15
										);
									}
								},
							},
							maxNumber: ValidatorFunctions.maxNumber(300),
						},
					},
					deviceId: {
						rules: {
							anyConflicts: {
								message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
									TranslateText('connections.device'),
								]),
								validationFunction: (data, fieldName) => {
									return this.checkDeviceConnectionConflicts(data[fieldName]);
								},
								disabled: !ClaimUtil.validateHasClaim(this.props.user, ClaimType.Devices),
							},
						},
					},
					addInGroups: {
						rules: {
							warningNotEmpty: {
								message: TranslateText('fieldsValidations.addInGroupsWarning'),
								validationFunction: (data) => {
									const objectData = data as TrackableObject;
									return ValidatorFunctions.wrapInPromise(objectData.addInGroups?.length > 0);
								},
								disabled:
									this.props.renderFor === RenderForEnum.Widget ||
									!ClaimUtil.validateClaim(this.props.user, {
										claim: ClaimType.Groups,
										values: [ClaimValue.edit],
									}),
								isWarning: true,
							},
						},
					},
					fuelType: {
						rules: { required: ValidatorFunctions.required() },
					},
					objectType: {
						rules: { required: ValidatorFunctions.required() },
					},
					co2CalculationType: {
						rules: { required: ValidatorFunctions.required() },
					},
					co2Manual: {
						rules: {
							required: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const objectData = data as TrackableObject;
									if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
										return ValidatorFunctions.wrapInPromise(true);
									return ValidatorFunctions.wrapInPromise(
										objectData.co2Manual !== null && objectData.co2Manual !== undefined
									);
								},
							},
							maxValue: {
								message: TranslateText('fieldsValidations.maxValue') + '9999',
								validationFunction: (data) => {
									const objectData = data as TrackableObject;
									if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
										return ValidatorFunctions.wrapInPromise(true);
									return ValidatorFunctions.wrapInPromise(
										objectData.co2Manual === null ||
											objectData.co2Manual === undefined ||
											objectData.co2Manual < 10000
									);
								},
							},
							minValue: {
								message: TranslateText('fieldsValidations.minValue') + '0',
								validationFunction: (data) => {
									const objectData = data as TrackableObject;
									if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
										return ValidatorFunctions.wrapInPromise(true);
									return ValidatorFunctions.wrapInPromise(
										objectData.co2Manual === null ||
											objectData.co2Manual === undefined ||
											objectData.co2Manual >= 0
									);
								},
							},
						},
					},
				},
			},
			true
		);
	}

	public GetAvailableCustomerInfo(customerId: string): IAvailableCustomer {
		return this.props.availableCustomers.find((x) => x.value === customerId);
	}
	public focusDefaultInput() {
		setTimeout(() => {
			const focusElement = document.getElementById('object-code');
			if (focusElement) {
				focusElement.focus();
			}
		}, 400);
	}

	handleIconChange(iconName: string, content: string, iconSet: string) {
		this.handleValueChangeMaterial(`${iconSet}/${iconName}`, 'icon');
	}

	componentDidMount() {
		this.props.actions.availableCustomersActions.toggleSelection(false);
		if (this.props.renderFor === RenderForEnum.Wizard) {
			const defaultSettings = this.getDefaultSettings();

			if (defaultSettings) {
				this.setState({
					object: { ...this.state.object, ...defaultSettings },
				});
			}
		}
		if (this.props.renderFor === RenderForEnum.ConnectDialog) {
			if (this.props.connectCurrentData) {
				//remove deviceId - to force user to validate the device for the new date
				if (this.props.connectCurrentData.deviceId) {
					this.props.connectCurrentData.deviceId = null;
				}

				this.setState({
					object: this.props.connectCurrentData,
				});
			}
		}

		if (this.state.object?.deviceId) {
			this.getConnectedDeviceMetadata(this.state.object.deviceId);
		}

		if (this.props.widgetState === WidgetStateEnum.Edit || this.props.renderFor === RenderForEnum.Wizard) {
			//initial validation
			this.validator.validate(this.state.object).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
			this.validator.clearValidation();
			this.setState({ validationResult: null });
		}
		if (prevProps.entityCustomer !== this.props.entityCustomer) {
			this.setState({
				customerCountryId: this.GetAvailableCustomerInfo(this.props.entityCustomer ?? GetSelectedCustomer().id)
					?.startCountryId,
			});
		}
		if (this.state.object.objectFunction !== prevState.object.objectFunction) {
			this.setState({
				object: {
					...this.state.object,
					mileage: null,
					timeOutEndOfTrip: !this.isAsset ? this.props.entityDefaultsSettings?.timeOutEndOfTrip ?? 180 : -1,
					objectType: ObjectTypeEnum.Unknown,
					icon: !this.isAsset ? 'objects/car' : 'objects-asset/handyman',
				},
			});
			return;
		}

		if (this.state.object !== prevState.object) {
			if (this.state.object?.deviceId !== prevState.object?.deviceId) {
				this.getConnectedDeviceMetadata(this.state.object.deviceId);
			}

			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				this.validator.validate(this.state.object).then((result) => {
					this.setState({
						validationResult: result.validationResult,
					});

					if (this.state.unsavedData) {
						this.setEntity(result.formResult, this.state.object);
						this.setState({ unsavedData: false });
					}
				});
			}
		}

		if (
			this.props.entityDefaultsSettings !== prevProps.entityDefaultsSettings &&
			this.props.renderFor === RenderForEnum.ConnectDialog
		) {
			const defaultSettings = {
				toleranceTimeOutDistance: this.props.defaultCustomerSettings.toleranceTimeOutDistance,
				timeOutEndOfTrip: this.props.defaultCustomerSettings.timeOutEndOfTrip,
				co2CalculationType: this.props.defaultCustomerSettings.co2CalculationType,
			};

			if (defaultSettings) {
				this.setState({
					object: { ...this.state.object, ...defaultSettings },
				});
			}
		}
	}

	public setEditRecord(object: TrackableObject) {
		this.setState({
			object: object,
			unsavedData: false,
			customerCountryId: this.GetAvailableCustomerInfo(this.props.entityCustomer ?? GetSelectedCustomer().id)
				?.startCountryId,
		});
		this.initialObject = object;
	}

	public clearView() {
		this.setState({
			object: new TrackableObject(this.props.defaultCustomerId, this.props.defaultCustomerTimezone),
			selectedIndexInfoWidget: 0,
		});
	}

	public prepareViewWithData(customerId: string, deviceId?: string, deviceCode?: string) {
		let newObject = { ...this.state.object };
		newObject.customerId = customerId;
		newObject.deviceId = deviceId || newObject.deviceId;
		newObject.deviceCode = deviceCode || newObject.deviceCode;

		const defaultSettings = this.getDefaultSettings();
		if (defaultSettings) {
			newObject = { ...newObject, ...defaultSettings };
		}

		this.setState({ object: newObject, unsavedData: false });

		ajaxUtil
			.get<{ timezoneId: string }>(`${GlobalSettings.customersMaintenanceApi}/${customerId}`)
			.then(({ timezoneId }) => {
				this.setState({
					object: { ...this.state.object, timezoneId },
				});
			});
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.object).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	setEntity(valid: boolean, object: TrackableObject) {
		if (this.props.widgetState === WidgetStateEnum.Edit || this.props.renderFor === RenderForEnum.Wizard) {
			this.props.setObject(valid, object, this.state.unsavedData);
		}
	}

	private getConnectedDeviceMetadata(deviceId: string) {
		if (!deviceId) {
			this.setState({
				connectedSimIMSI: null,
				lastMessageReceived: null,
				lastMessageWithValidGPS: null,
			});

			return;
		}

		if (ClaimUtil.validateHasClaim(this.props.user, ClaimType.Devices)) {
			ajaxUtil
				.get(`${GlobalSettings.devicesMaintenanceApi}/${deviceId}/devicemeta`)
				.then((response: any) => {
					this.setState({
						connectedSimIMSI: response.connectedSimImsi,
						lastMessageReceived: response.lastMessageReceived
							? new Date(response.lastMessageReceived)
							: null,
						lastMessageWithValidGPS: response.lastMessageWithValidGPS
							? new Date(response.lastMessageWithValidGPS)
							: null,
					});
				})
				.catch((error) => console.log(error));
		}
	}

	private getDefaultSettings() {
		let defaultSettings: {
			timeOutEndOfTrip: number;
			toleranceTimeOutDistance: number;
			co2CalculationType: CO2CalculationTypeEnum;
		} = null;
		if (this.props.renderFor === RenderForEnum.Wizard) {
			if (
				!this.state.object.toleranceTimeOutDistance ||
				!this.state.object.timeOutEndOfTrip ||
				!this.state.object.co2CalculationType
			) {
				defaultSettings = {
					toleranceTimeOutDistance: !this.state.object.toleranceTimeOutDistance
						? this.props.entityDefaultsSettings.toleranceTimeOutDistance
						: this.state.object.toleranceTimeOutDistance,
					timeOutEndOfTrip: !this.state.object.timeOutEndOfTrip
						? this.props.entityDefaultsSettings.timeOutEndOfTrip
						: this.state.object.timeOutEndOfTrip,
					co2CalculationType: !this.state.object.co2CalculationType
						? this.props.entityDefaultsSettings.co2CalculationType
						: this.state.object.co2CalculationType,
				};
			}
		} else if (this.props.renderFor === RenderForEnum.ConnectDialog) {
			if (!this.state.object.toleranceTimeOutDistance || !this.state.object.timeOutEndOfTrip) {
				defaultSettings = {
					toleranceTimeOutDistance: !this.state.object.toleranceTimeOutDistance
						? this.props.defaultCustomerSettings.toleranceTimeOutDistance
						: this.state.object.toleranceTimeOutDistance,
					timeOutEndOfTrip: !this.state.object.timeOutEndOfTrip
						? this.props.defaultCustomerSettings.timeOutEndOfTrip
						: this.state.object.timeOutEndOfTrip,
					co2CalculationType: !this.state.object.co2CalculationType
						? this.props.entityDefaultsSettings.co2CalculationType
						: this.state.object.co2CalculationType,
				};
			}
		}

		return defaultSettings;
	}

	private async checkDeviceConnectionConflicts(deviceId: string): Promise<boolean> {
		if (!deviceId) {
			return true;
		}
		if (this.isSelectedDeviceUnchanged(deviceId)) {
			return true;
		}

		const candidateConnection = {
			entity1Id: deviceId,
			entity2Id: this.state.object.id ? this.state.object.id : null,
			connectionStartDate: this.props.connectionStartDateFromConnectDialog
				? this.props.connectionStartDateFromConnectDialog
				: new Date(),
			connectionType: ConnectionTypeEnum.DeviceObject,
		};

		this.setState({ isLoading: true });
		const result = await ajaxUtil.post<ConnectionsConflictResult>(
			`${GlobalSettings.connectionsApi}/ValidateEntitiesCandidateConnection`,
			candidateConnection
		);
		this.setState({ isLoading: false });

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				this.setState({
					conflictConfirmationProps: {
						visible: true,
						canSolveConflicts: result.fixableConflict,
						connectionType: ConnectionTypeEnum.DeviceObject,
						conflictData: result.conflictingConnections,
						confirmCallback: (confirm) => {
							this.setState({
								conflictConfirmationProps: {
									...this.state.conflictConfirmationProps,
									visible: false,
								},
							});
							resolve(confirm);
						},
					},
				});
			});
		} else {
			return true;
		}
	}

	private isSelectedDeviceUnchanged(deviceId: string): boolean {
		return (
			this.props.readOnly ||
			this.props.renderFor === RenderForEnum.Widget ||
			(this.props.renderFor === RenderForEnum.ConnectDialog && this.props.usedFrom !== UsedFromEnum.Person) ||
			(this.initialObject !== undefined && deviceId === this.initialObject.deviceId)
		);
	}

	private async handleValueChange(arg: any, statePropName: string, isSyncfusion = true) {
		if (isSyncfusion && !arg.event) {
			return;
		}

		const newObject = { ...this.state.object };
		if (!arg.value) {
			newObject[statePropName] = arg;
		} else {
			newObject[statePropName] = arg.value;
		}

		if (statePropName === 'active') {
			newObject[statePropName] = !this.state.object.active;
		}

		this.setState({
			object: newObject,
			unsavedData: true,
		});
	}

	private handleValueChangeMaterial(value: any, statePropName: string) {
		const newObject = { ...this.state.object };
		const lastValue = newObject[statePropName];
		newObject[statePropName] = value;

		if (
			!this.state.isExternalCodeFirstTouched &&
			value &&
			!this.state.object.externalCode &&
			this.props.entityDefaultsSettings.externalCodeSameCode &&
			statePropName === 'code'
		) {
			newObject.externalCode = value;
		}

		this.setState({
			object: newObject,
			unsavedData: newObject[statePropName] !== lastValue ? true : this.state.unsavedData,
		});

		if (statePropName === 'licensePlate') {
			this.getRdwData(value);
		}

		if (
			statePropName === 'objectType' ||
			(statePropName === 'fuelType' && newObject.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
		) {
			this.setCO2CalculationMethod(newObject);
		}
	}

	private setCO2CalculationMethod(newObject: TrackableObject) {
		if (
			newObject.objectType === null ||
			[
				ObjectTypeEnum.Van,
				ObjectTypeEnum.Truck,
				ObjectTypeEnum.Car,
				ObjectTypeEnum.Crane,
				ObjectTypeEnum.Motorcycle,
				ObjectTypeEnum.Boat,
				ObjectTypeEnum.Bus,
				ObjectTypeEnum.Coach,
				ObjectTypeEnum.Moped,
				ObjectTypeEnum.Tractor,
			].indexOf(newObject.objectType) === -1
		) {
			newObject.co2CalculationType = CO2CalculationTypeEnum.NotApplicable;
			newObject.co2Manual = null;
			newObject.co2Nedc = null;
			newObject.co2Wltp = null;

			this.setState({
				object: newObject,
				unsavedData: true,
			});
			return;
		}
		if (newObject.objectType !== null && newObject.fuelType !== null) {
			ajaxUtil
				.get<RDWVehicleDataDto>(
					`${GlobalSettings.objectsMaintenanceApi}/QueryFuelObjectTypeValue/${newObject.customerId}/${newObject.objectType}/${newObject.fuelType}`
				)
				.then((data) => {
					if (data !== null) {
						newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceTypeAndFuel;
						newObject.co2Manual = null;
					} else {
						newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceManual;
					}

					this.setState({
						object: newObject,
						unsavedData: true,
					});
				});
		} else {
			newObject.co2CalculationType = CO2CalculationTypeEnum.NotApplicable;
			newObject.co2Wltp = null;
			newObject.co2Wltp = null;
			newObject.co2Manual = null;
			this.setState({
				object: newObject,
				unsavedData: true,
			});
		}
	}

	private getRdwData(licensePlate: string) {
		if (licensePlate && this.state.customerCountryId === NL_COUNTRY_ID) {
			this.setState({
				rdwDataFetching: true,
			});
			ajaxUtil
				.get<RDWVehicleDataDto>(`${GlobalSettings.objectsMaintenanceApi}/QueryRDW/${licensePlate}`)
				.then((data) => {
					if (data) {
						const newObject = { ...this.state.object };
						newObject.brand = data.brand;
						newObject.objectType = data.objectType;
						newObject.model = data.model;
						newObject.fuelType = data.fuelType;
						newObject.co2Nedc = data.co2Nedc;
						newObject.co2Wltp = data.co2Wltp;
						newObject.motExireDate = data.motExpireDate;
						newObject.motorInsuranceLiabilityAct = data.motorInsuranceLiabilityAct;
						newObject.tachographExpireDate = data.tachographExpireDate;
						if (newObject.co2Wltp) {
							newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceWLTP;
							newObject.co2Manual = null;
							this.setState({
								object: newObject,
								unsavedData: true,
							});
							return;
						} else if (newObject.co2Nedc) {
							newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceNEDC;
							newObject.co2Manual = null;
							this.setState({
								object: newObject,
								unsavedData: true,
							});
							return;
						} else {
							this.setCO2CalculationMethod(newObject);
						}
					} else {
						const newObject = { ...this.state.object };
						newObject.brand = '';
						newObject.model = '';
						newObject.co2Nedc = null;
						newObject.co2Wltp = null;
						newObject.motExireDate = null;
						newObject.motorInsuranceLiabilityAct = null;
						newObject.tachographExpireDate = null;
						this.setCO2CalculationMethod(newObject);
					}
				})
				.finally(() => {
					this.setState({
						rdwDataFetching: false,
					});
				});
		}
	}
	private handleCheckboxValue(args: any) {
		const newObject = { ...this.state.object };
		newObject.active = args.target.checked;
		this.setState({ object: newObject, unsavedData: true });
	}

	private getCustomerId() {
		return !!this.props?.defaultCustomerId?.length ? this.props.defaultCustomerId : this.state.object.customerId;
	}

	private fillTechnicalData() {
		return (
			<form id="technicalDataForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						{this.props.renderFor === RenderForEnum.Widget ||
						(this.props.renderFor === RenderForEnum.ConnectDialog &&
							this.props.usedFrom === UsedFromEnum.Device) ? (
							<ProtectedContainer claim={ClaimType.Devices}>
								<MaterialTextField
									id="deviceId"
									className="resize-font"
									label={TranslateText('fields.connectedDevice')}
									inputProps={{ readOnly: true, style: { fontSize: 12 } }}
									name="deviceId"
									value={this.state.object.deviceCode}
									disabled={true}
								/>
							</ProtectedContainer>
						) : (
							<ProtectedContainer
								claimConfig={[
									{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
									{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
								]}
							>
								<MaterialAutocomplete
									valueId={this.state.object.deviceId}
									dataSource={this.props.devicesData}
									name={'deviceId'}
									disabled={
										(this.props.readOnly &&
											!ClaimUtil.validateClaimList(this.props.user, [
												{
													claim: ClaimType.Devices,
													values: [ClaimValue.edit],
												},
												{
													claim: ClaimType.ObjectDeviceConnection,
													values: [ClaimValue.edit],
												},
											])) ||
										this.state.isLoading
									}
									onChange={({ value }) => {
										this.handleValueChangeMaterial(value, 'deviceId');
									}}
									label={TranslateText('fields.connectedDevice')}
								/>
								<ValidationMessage result={this.state.validationResult?.deviceId} />
							</ProtectedContainer>
						)}
					</div>
					<ProtectedContainer claim={ClaimType.SimCards}>
						<div className="form-group">
							<MaterialTextField
								id="connectedSIM"
								className="resize-font"
								label={TranslateText('fields.connectedSim')}
								inputProps={{ readOnly: true, style: { fontSize: 12 } }}
								name="connectedSIM"
								value={this.state.connectedSimIMSI}
								disabled={true}
							/>
						</div>
					</ProtectedContainer>
					{this.props.renderFor === RenderForEnum.Widget ? (
						<ProtectedContainer claim={ClaimType.Devices}>
							<div className="form-group">
								<MaterialDatePicker
									name="lastMessageReceived"
									date={this.state.lastMessageReceived}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									label={TranslateText('fields.lastMessageReceived')}
									showTime={true}
									disabled={true}
									fullWidth={true}
								/>
							</div>
							<div className="form-group">
								<MaterialDatePicker
									name="lastMessageWithValidGPS"
									date={this.state.lastMessageWithValidGPS}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									label={TranslateText('fields.lastMessageWithValidGPS')}
									showTime={true}
									disabled={true}
									fullWidth={true}
								/>
							</div>
						</ProtectedContainer>
					) : (
						<ProtectedContainer claimConfig={[{ claim: ClaimType.Devices, values: [ClaimValue.edit] }]}>
							<div className="form-group">
								<MaterialDatePicker
									name="lastMessageReceived"
									date={
										this.state.lastMessageReceived?.getFullYear() !== 1
											? this.state.lastMessageReceived
											: null
									}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									label={TranslateText('fields.lastMessageReceived')}
									showTime={true}
									disabled={true}
									fullWidth={true}
								/>
							</div>
							<div className="form-group">
								<MaterialDatePicker
									name="lastMessageWithValidGPS"
									date={
										this.state.lastMessageWithValidGPS?.getFullYear() !== 1
											? this.state.lastMessageWithValidGPS
											: null
									}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									label={TranslateText('fields.lastMessageWithValidGPS')}
									showTime={true}
									disabled={true}
									fullWidth={true}
								/>
							</div>
						</ProtectedContainer>
					)}
				</div>
			</form>
		);
	}

	private fillObjectPresentation() {
		return (
			<form id={'objectPresentationForm'} noValidate>
				<div className={'view-section-wrapper'}>
					<div className="form-group">
						<div>
							<label className={this.props.readOnly ? 'readOnlyDisplayLabel' : 'displayLabel'}>
								{TranslateText('fields.objectIcon')}
							</label>
						</div>
						<div>
							<label className="displayLabel">
								<IconPickerWrapper
									readOnly={this.props.readOnly}
									iconName={this.state.object.icon}
									onIconSelected={(iconName: string, content: string, iconSet: string) => {
										this.handleValueChangeMaterial(`${iconSet}/${iconName}`, 'icon');
									}}
									containers={!this.isAsset ? ['objects'] : ['objects-asset']}
									size={24}
								/>
							</label>
						</div>
					</div>
					<div className="form-group">
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
							{TranslateText('fields.template.tripColor')}
						</label>
						<ColorPicker
							initialColor={this.state.object.tripColor}
							onColorChangedHandler={(color: string) => this.handleValueChange(color, 'tripColor', false)}
							disabled={this.props.readOnly}
						/>
					</div>
					<div className={'form-group'}>
						<div>
							<label className={this.props.readOnly ? 'readOnlyDisplayLabel' : 'displayLabel'}>
								{TranslateText('fields.objectImage')}
							</label>
						</div>
						<ImagePicker
							percentageWidth={100}
							previewImage={this.state.object.base64Image}
							onImageEdit={(base64Image: string) =>
								this.handleValueChangeMaterial(base64Image, 'base64Image')
							}
							readOnly={
								!(
									!this.props.readOnly &&
									ClaimUtil.validateClaim(this.props.user, {
										claim: ClaimType.Objects,
										values: [ClaimValue.edit, ClaimValue.editRestricted],
									})
								)
							}
						/>
					</div>
				</div>
			</form>
		);
	}

	private fillObjectData() {
		return (
			<form id="objectDataForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<MaterialTextField
							id="licensePlate"
							className="resize-font"
							label={TranslateText('fields.licensePlate')}
							inputProps={{ style: { fontSize: 12 } }}
							name="licensePlate"
							value={this.state.object.licensePlate}
							disabled={
								!(
									!this.props.readOnly &&
									ClaimUtil.validateClaim(this.props.user, {
										claim: ClaimType.Objects,
										values: [ClaimValue.edit],
									})
								)
							}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'licensePlate')}
						/>
						<ValidationMessage result={this.state.validationResult?.licensePlate} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="objectName"
							className="resize-font"
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="objectName"
							value={this.state.object.name}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'name')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.name} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="objectVin"
							className="resize-font"
							label={TranslateText('fields.vin')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="objectVin"
							value={this.state.object.vin}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'vin')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.vin} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="objectBrand"
							className="resize-font"
							label={TranslateText('fields.brand')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="objectBrand"
							value={this.state.object.brand}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'brand')}
							disabled={this.props.readOnly || this.state.rdwDataFetching}
						/>
						<ValidationMessage result={this.state.validationResult?.brand} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="objectModel"
							className="resize-font"
							label={TranslateText('fields.model')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="objectModel"
							value={this.state.object.model}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'model')}
							disabled={this.props.readOnly || this.state.rdwDataFetching}
						/>
						<ValidationMessage result={this.state.validationResult?.model} />
					</div>
					{this.props.assetTracking && (
						<div className="form-group">
							<MaterialAutocomplete
								valueId={this.state.object.objectFunction}
								dataSource={ObjectFunctionDataSource.dataSource()}
								name="objectFunction"
								disabled={
									this.props.readOnly ||
									(this.props.renderFor === RenderForEnum.ConnectDialog &&
										this.props.usedFrom === UsedFromEnum.Person)
								}
								label={TranslateText('fields.objectFunction')}
								onChange={({ value }) => this.handleValueChangeMaterial(value, 'objectFunction')}
								disableClearable
							/>
							<ValidationMessage result={this.state.validationResult?.objectFunction} />
						</div>
					)}
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.object.fuelType}
							dataSource={FuelTypeDataSource.dataSource()}
							name="fuelType"
							disabled={this.props.readOnly || this.state.rdwDataFetching}
							label={TranslateText('fields.fuelType')}
							onChange={({ value }) => {
								const newObject = { ...this.state.object };
								newObject.fuelType = value as number;
								if (newObject.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual) {
									this.setCO2CalculationMethod(newObject);
								} else {
									this.setState({
										object: newObject,
										unsavedData: true,
									});
								}
							}}
						/>
						<ValidationMessage result={this.state.validationResult?.fuelType} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.object.objectType}
							dataSource={
								!this.isAsset
									? ObjectTypeDataSource.dataSource()
									: ObjectAssetTypeDatasource.dataSource()
							}
							name="objectType"
							disabled={this.props.readOnly || this.state.rdwDataFetching}
							label={TranslateText('fields.objectType')}
							onChange={({ value }) => {
								const newObject = { ...this.state.object };
								newObject.objectType = value as number;
								if (newObject.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual) {
									this.setCO2CalculationMethod(newObject);
								} else {
									this.setState({
										object: newObject,
										unsavedData: true,
									});
								}
							}}
						/>
						<ValidationMessage result={this.state.validationResult?.objectType} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={this.state.object.co2CalculationType}
							dataSource={CO2CalculationTypeDataSource.dataSource().filter((x) => !x.disabled)}
							name="co2CalculationType"
							disabled={this.props.readOnly}
							label={TranslateText('fields.co2CalculationType')}
							onChange={({ value }) => {
								const newObject = { ...this.state.object };
								if ((value as number) !== CO2CalculationTypeEnum.DistanceManual) {
									newObject.co2Manual = null;
								}
								newObject.co2CalculationType = value as number;
								this.setState({
									object: newObject,
									unsavedData: true,
								});
							}}
						/>
						<ValidationMessage result={this.state.validationResult?.co2CalculationType} />
					</div>
					{this.state.object.co2CalculationType === CO2CalculationTypeEnum.DistanceManual ? (
						<div className="form-group">
							<MaterialTextField
								type={'number'}
								id="co2manual"
								fullWidth
								label={TranslateText('fields.co2manual')}
								className="auto-mileage resize-font noSpinner"
								inputProps={{
									min: 0,
									style: { fontSize: 12 },
									onKeyPress: (event: any) => {
										if (event.key === '-' || event.key === 'e') {
											event.preventDefault();
										}
									},
								}}
								name="co2manual"
								value={!this.isAsset ? this.state.object.co2Manual : ''}
								handleValueChange={(value) =>
									this.handleValueChangeMaterial(value !== '' ? Math.round(value) : null, 'co2Manual')
								}
								disabled={this.props.readOnly}
								onKeyDown={handleKeyDown}
								onWheel={(e: any) => e.target.blur()}
							/>
							<ValidationMessage result={this.state.validationResult?.co2Manual} />
						</div>
					) : null}
					<div className="form-group">
						<MaterialTextField
							type={'number'}
							id="co2nedc"
							fullWidth
							label={TranslateText('fields.co2nedc')}
							className="auto-mileage resize-font"
							inputProps={{
								style: { fontSize: 12 },
							}}
							name="co2nedc"
							value={!this.isAsset ? this.state.object.co2Nedc : ''}
							handleValueChange={(value) =>
								this.handleValueChangeMaterial(value !== '' ? Math.round(value) : null, 'co2Nedc')
							}
							disabled={true}
						/>
						<ValidationMessage result={this.state.validationResult?.co2Nedc} />
					</div>
					<div className="form-group">
						<MaterialTextField
							type={'number'}
							id="co2wltp"
							fullWidth
							label={TranslateText('fields.co2wltp')}
							className="auto-mileage resize-font"
							inputProps={{
								style: { fontSize: 12 },
							}}
							name="co2wltp"
							value={!this.isAsset ? this.state.object.co2Wltp : ''}
							handleValueChange={(value) =>
								this.handleValueChangeMaterial(value !== '' ? Math.round(value) : null, 'co2Wltp')
							}
							disabled={true}
						/>
						<ValidationMessage result={this.state.validationResult?.co2Wltp} />
					</div>
					<div className="form-group">
						<MaterialTextField
							type={'number'}
							id="mileage"
							fullWidth
							label={TranslateText('fields.mileage')}
							className="auto-mileage resize-font noSpinner"
							inputProps={{
								style: { fontSize: 12 },
							}}
							name="mileage"
							value={!this.isAsset ? this.state.object.mileage : ''}
							handleValueChange={(value) =>
								this.handleValueChangeMaterial(value !== '' ? value : null, 'mileage')
							}
							disabled={
								!(
									!this.props.readOnly &&
									(this.props.renderFor === RenderForEnum.Wizard ||
										this.props.renderFor === RenderForEnum.ConnectDialog)
								) || this.isAsset
							}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={this.state.validationResult?.mileage} />
					</div>
					{ClaimUtil.validateClaim(this.props.user, {
						claim: ClaimType.Objects,
						values: [ClaimValue.edit],
					}) ? (
						<div className="form-group">
							<TextField
								id="toleranceTimeOutDistance"
								type="number"
								fullWidth
								label={TranslateText('fields.toleranceTimeOutDistance')}
								className="auto-tolerance-time-out-distance resize-font tolerance-time-out-distance"
								InputProps={{
									inputProps: {
										min: -1,
										style: { fontSize: 12 },
									},
								}}
								InputLabelProps={{
									style: { minWidth: 320 },
								}}
								name="toleranceTimeOutDistance"
								value={this.state.object.toleranceTimeOutDistance ?? ''}
								size="small"
								onChange={(args) =>
									this.handleValueChangeMaterial(args.target.value, 'toleranceTimeOutDistance')
								}
								disabled={this.props.readOnly}
							/>
							<ValidationMessage result={this.state.validationResult?.toleranceTimeOutDistance} />
						</div>
					) : null}
					{ClaimUtil.validateClaim(this.props.user, {
						claim: ClaimType.Objects,
						values: [ClaimValue.edit],
					}) ? (
						<div className="form-group">
							<MaterialTextField
								id="timeOutEndOfTrip"
								type="number"
								fullWidth
								label={TranslateText('fields.timeOutEndOfTrip')}
								className="auto-time-out-end-of-trip resize-font"
								inputProps={{
									style: { fontSize: 12 },
								}}
								name="timeOutEndOfTrip"
								value={!this.isAsset ? this.state.object.timeOutEndOfTrip : null}
								handleValueChange={(value) =>
									this.handleValueChangeMaterial(value !== '' ? value : null, 'timeOutEndOfTrip')
								}
								disabled={this.props.readOnly || this.isAsset}
							/>
							<ValidationMessage result={this.state.validationResult?.timeOutEndOfTrip} />
						</div>
					) : null}
					<div className="form-group">
						<MaterialDatePicker
							name="activationDate"
							date={this.state.object.activationDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(this.dateformat, true)}
							label={TranslateText('fields.activationDate')}
							showTime={false}
							disabled={
								this.props.readOnly ||
								!ClaimUtil.validateClaim(this.props.user, {
									claim: ClaimType.Objects,
									values: [ClaimValue.edit],
								})
							}
							onDateChange={(date?: Date) => {
								this.handleValueChange({ value: date }, 'activationDate', false);
							}}
							fullWidth={true}
						/>
					</div>
				</div>
			</form>
		);
	}

	private fillSystemInformation() {
		return (
			<form id="objectViewSystemInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<MaterialTextField
							id="object-code"
							className="resize-font"
							label={TranslateText('fields.code')}
							inputProps={{ style: { fontSize: 12 } }}
							name="code"
							value={this.state.object.code}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'code')}
							disabled={
								!(
									!this.props.readOnly &&
									ClaimUtil.validateClaim(this.props.user, {
										claim: ClaimType.Objects,
										values: [ClaimValue.edit],
									})
								)
							}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="externalCode"
							className="resize-font"
							label={TranslateText('fields.externalCode')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="externalCode"
							value={this.state.object.externalCode}
							handleValueChange={(value) => this.handleValueChangeMaterial(value, 'externalCode')}
							handleFocus={() => {
								if (
									!this.state.isExternalCodeFirstTouched &&
									this.state.object.code &&
									!this.state.object.externalCode &&
									this.props.entityDefaultsSettings.externalCodeSameCode
								) {
									const newObject = { ...this.state.object };
									newObject.externalCode = newObject.code;
									this.setState({
										object: newObject,
										isExternalCodeFirstTouched: true,
										unsavedData: true,
									});
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.externalCode} />
					</div>
					<CustomerNameDisplay customerId={this.getCustomerId()} />
					<div className="form-group">
						<div className="control-pane">
							<div className="control-section">
								<div className="ddl-control-section">
									<TimezoneSelection
										label={TranslateText('fields.timezone')}
										name="timezoneId"
										valueId={this.state.object.timezoneId}
										disabled={this.state.object.hasTrips || this.props.readOnly}
										onChange={(args) => this.handleValueChange(args, 'timezoneId', false)}
									/>
								</div>
							</div>
						</div>
						<ValidationMessage result={this.state.validationResult?.timezoneId} />
					</div>
					{this.props.renderFor !== RenderForEnum.Widget &&
					ClaimUtil.validateClaim(this.props.user, {
						claim: ClaimType.Groups,
						values: [ClaimValue.edit],
					}) ? (
						<div className="form-group">
							<AddInGroupsSelector
								name="addInGroups"
								selectedGroups={this.state.object.addInGroups}
								onChange={(groups) => {
									this.setState({
										object: { ...this.state.object, addInGroups: groups },
									});
								}}
								defaultCustomerId={this.getCustomerId()}
							/>
							<ValidationMessage result={this.state.validationResult?.addInGroups} />
						</div>
					) : null}
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'primary'}
									checked={this.state.object.active}
									onChange={(args: any) => this.handleCheckboxValue(args)}
									disabled={
										this.props.readOnly ||
										!ClaimUtil.validateClaim(this.props.user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit, ClaimValue.editRestricted],
										}) ||
										this.props.renderFor === RenderForEnum.ConnectDialog
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				</div>
			</form>
		);
	}

	render() {
		this.isAsset = this.state?.object?.objectFunction === ObjectFunctionEnum.Asset;

		return (
			<>
				<ConflictConfirmation {...this.state.conflictConfirmationProps} />
				{this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget()}
			</>
		);
	}

	private renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '33%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillSystemInformation()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
				<div className="column" style={{ width: '33%' }}>
					<Accordion>
						<AccordionSection
							expanded={true}
							header={TranslateText('common.objectData')}
							operationInProgress={this.state.rdwDataFetching}
						>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillObjectData()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
				{ClaimUtil.validateClaim(this.props.user, {
					claim: ClaimType.Objects,
					values: [ClaimValue.edit],
				}) ? (
					<div className="column" style={{ width: '33%' }}>
						<Accordion>
							<AccordionSection expanded={true} header={TranslateText('common.technicalData')}>
								<Scrollbar native={true} className="view-section-scroll">
									{this.fillTechnicalData()}
								</Scrollbar>
							</AccordionSection>
						</Accordion>
					</div>
				) : null}
			</div>
		);
	}

	private renderForWidget() {
		return (
			<Accordion
				scrollToTop={this.props.scrollToTop}
				expandedCallback={(index: number) => {
					this.setState({
						selectedIndexInfoWidget: index,
					});
				}}
			>
				<AccordionSection
					expanded={this.state.selectedIndexInfoWidget === 0}
					header={TranslateText('common.systemInformation')}
					className="auto-system-info"
				>
					{this.fillSystemInformation()}
				</AccordionSection>
				<AccordionSection
					expanded={this.state.selectedIndexInfoWidget === 1}
					header={TranslateText('common.objectData')}
					operationInProgress={this.state.rdwDataFetching}
					className="auto-object-data"
				>
					{this.fillObjectData()}
				</AccordionSection>
				<AccordionSection
					expanded={this.state.selectedIndexInfoWidget === 2}
					header={TranslateText('common.objectPresentation')}
					className="auto-object-data"
				>
					{this.fillObjectPresentation()}
				</AccordionSection>
				{ClaimUtil.validateClaim(this.props.user, {
					claim: ClaimType.Objects,
					values: [ClaimValue.edit],
				}) ? (
					<AccordionSection
						expanded={this.state.selectedIndexInfoWidget === 3}
						header={TranslateText('common.technicalData')}
						className="auto-technical-data"
					>
						{this.fillTechnicalData()}
					</AccordionSection>
				) : null}
			</Accordion>
		);
	}
}

export default connector(ObjectView);
