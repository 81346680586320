import AlertDigitalTriggerConditionEnum from './AlertDigitalTriggerConditionEnum';

class DigitalAlertMetadata {
	constructor() {
		this.templateDigitalId = null;
		this.triggerCondition = AlertDigitalTriggerConditionEnum.AnyStateChange;
	}
	public templateDigitalId: string;
	public triggerCondition?: AlertDigitalTriggerConditionEnum;
	[propName: string]: any;
}

export default DigitalAlertMetadata;
