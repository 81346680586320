import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TripIntermediateIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 16 16">
			<path d="M12.1697 9.71259H9.88397V5.68457H6.45538V9.71259H4.16968L8.16968 14.4119L12.1697 9.71259Z" />
			<path d="M4.16968 6.30063H6.45538V10.3286H9.88391V6.30063H12.1697L8.16968 1.60132L4.16968 6.30063Z" />
		</SvgIcon>
	);
};

export default React.memo(TripIntermediateIcon);
