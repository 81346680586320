import { useState } from 'react';

const useLogWidgetInput = (onChangeHandler: (value: string) => void) => {
	const [inputValue, setInputValue] = useState<string>('');

	const handleOnChange = (value: string) => {
		const trimmedValue = value.trim();
		if (trimmedValue.length > 300) {
			return;
		}

		setInputValue(value);
		if (trimmedValue.length < 2 && value !== '') {
			return;
		}

		onChangeHandler(trimmedValue);
	};

	return { inputValue, handleOnChange };
};

export { useLogWidgetInput };
