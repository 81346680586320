import './fleetSelectionArea.scss';

import EntityTypeEnum from 'models/EntityTypeEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { SET_TRIP_UNSAVED_DATA } from 'store/HistoryStore';
import { getAndSetSavedFleetSelection, setSelectionForDriverWithNoAuth } from 'utils/FleetSelectionUtils';
import { TranslateText } from 'utils/Translations';

import { LinearProgress } from '@material-ui/core';

import * as GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { ApplicationState } from '../../store';
import { filterUtil, FleetEntityTypeFilter, FleetSelectionDispatcher } from '../../store/FleetSelection';
import ajaxUtil from '../../utils/Ajax';
import SelectionUtil from '../SelectionDialog/SelectionUtil';
import SelectionTree from '../SelectionTree';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import FleetSelectionFilter from './FleetSelectionFilter';

export interface FleetSelectionClientModel {
	trackedEntities: ITreeNode[];
	selectedEntities: ITreeNode[];
	showInactive: boolean;
}

interface Props {
	hidden: boolean;
	isForLive?: boolean;
}

const FleetSelectionArea = (props: Props) => {
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const customerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);
	const saveFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveFleetSelection
	);
	const saveTrackedFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveTrackedFleetSelection
	);
	const { text, entityType } = useSelector((s: ApplicationState) => s.liveDataFilter);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);

	const fleetSelectionState = useSelector((state: ApplicationState) => state.fleetSelection);
	const isDriverWithNoAuthorization = useSelector(
		(state: ApplicationState) => state.currentSession.isDriverWithNoAuthorization
	);

	const [treeData, setTreeData] = useState([] as ITreeNode[]);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	const dispatch = useDispatch();

	const setSortedAndFiltredData = useCallback(
		(newTreeData: ITreeNode[]) => {
			//filter
			newTreeData.forEach((item) => {
				if (item.type === EntityTypeEnum.Group) {
					//filter groups
					const groupHiddenByText = !!text && !filterUtil.textIncludes(item.text, text);
					item.childCount = 0;
					item.selectedCount = 0;

					item.items.forEach((child) => {
						const hiddenByType =
							entityType !== FleetEntityTypeFilter.all && !filterUtil.equalType(child.type, entityType);
						const hiddenByText = !!text && !filterUtil.textIncludes(child.text, text);

						child.hidden = hiddenByType || (hiddenByText && groupHiddenByText);

						//calculate visible child count and selected child count
						if (!child.hidden) {
							item.childCount++;
							if (child.selected) {
								item.selectedCount++;
							}
						}
					});

					item.hidden = !item.items.some((x) => !x.hidden);
				} else {
					//filter items
					const hiddenByType =
						entityType !== FleetEntityTypeFilter.all && !filterUtil.equalType(item.type, entityType);
					const hiddenByText = !!text && !filterUtil.textIncludes(item.text, text);

					item.hidden = hiddenByType || hiddenByText;
				}
			});

			//sort
			sortData(newTreeData);

			//set
			setTreeData(newTreeData);
		},
		[text, entityType]
	);

	const sortData = useCallback((dataCollection: ITreeNode[]) => {
		dataCollection.forEach((data: ITreeNode) => {
			if (data.items && data.items.length > 0) {
				data.items.sort((a: ITreeNode, b: ITreeNode) => a.text.localeCompare(b.text));
			}
		});

		return dataCollection.sort((a: ITreeNode, b: ITreeNode) => {
			return a.text.localeCompare(b.text);
		});
	}, []);

	useEffect(() => {
		SelectionUtil.prepareTreeData(
			fleetSelectionState.selectedEntities,
			fleetSelectionState.trackedEntities,
			fleetSelectionState.expandedGroups,
			setSortedAndFiltredData
		);
	}, [fleetSelectionState.expandedGroups, fleetSelectionState.trackedEntities, fleetSelectionState.selectedEntities]);

	// this needs to be the second, in order to properly work
	useEffect(() => {
		if (!isDriverWithNoAuthorization && customerLevel === CustomerLevelEnum.Default && saveFleetSelection) {
			getAndSetSavedFleetSelection(
				customerId,
				featuresSettings.driverIdentification,
				featuresSettings.assetTracking,
				saveTrackedFleetSelection,
				fleetSelectionState.showInactive,
				fleetSelectionState.selectedEntities,
				fleetSelectionState.trackedEntities,
				dispatch
			);
		}
	}, [isDriverWithNoAuthorization]);

	useEffect(() => {
		if (isDriverWithNoAuthorization && featuresSettings.driverIdentification) {
			ajaxUtil
				.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetFleetSelectionData`, {
					customerId: customerId,
					showPersons: true,
					objectFunction: featuresSettings.assetTracking
						? ObjectFunctionFilter.All
						: ObjectFunctionFilter.Full,
					showObjects: false,
					showInactive: false,
					filterText: '',
				})
				.then((data) => {
					setSelectionForDriverWithNoAuth(data, dispatch);
				});
		}
	}, []);

	const saveTracked = useCallback(
		(tracked: ITreeNode[]) => {
			const data = tracked
				.filter((e) => e)
				.map((e) => {
					const { items, ...other } = e;
					return { ...other };
				});
			if (customerLevel === CustomerLevelEnum.Default) {
				ajaxUtil.post(`${GlobalSettings.fleetSelection}/SaveTracked`, data);
			}
			FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, tracked);
		},
		[dispatch]
	);

	const selectCallback = useCallback(
		(data: ITreeNode) => {
			SelectionUtil.select(
				data,
				treeData,
				setSortedAndFiltredData,
				fleetSelectionState.trackedEntities,
				(tracked) => {
					saveTracked(tracked);
				}
			);
		},
		[fleetSelectionState.trackedEntities, dispatch, treeData]
	);

	const expandCallback = useCallback(
		(node: ITreeNode) => {
			SelectionUtil.expandCallback(node, fleetSelectionState.expandedGroups, (data) =>
				FleetSelectionDispatcher.setExpandedGroups(dispatch, data)
			);
		},
		[fleetSelectionState.expandedGroups]
	);

	const selectAll = useCallback(() => {
		saveTracked([...treeData]);
	}, [treeData, dispatch]);

	const deselectAll = useCallback(() => {
		saveTracked([]);
	}, [dispatch]);

	useEffect(() => {
		if (treeData.length) {
			setSortedAndFiltredData([...treeData]);
		}
	}, [text, entityType]);

	const { data: isFetchingFleetSelectionData } = useQuery(['live', 'get-fleet-selection-data'], () => false);

	return (
		<>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.unsavedData')}
				handleUserResponse={(response) => {
					setShowNotificationPrompt(false);
					if (response) {
						dispatch({
							type: SET_TRIP_UNSAVED_DATA,
							payload: false,
						});
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			<div className={'fleet-selection-area'} hidden={props.hidden}>
				<FleetSelectionFilter selectionTree={treeData} selectAll={selectAll} deselectAll={deselectAll} />
				{isFetchingFleetSelectionData ? (
					<LinearProgress
						style={{
							backgroundColor: '#FFFFFF',
						}}
					/>
				) : (
					<SelectionTree
						data={treeData}
						filterText={text}
						entityFilter={entityType}
						selectCallback={selectCallback}
						expandCallback={expandCallback}
						showPlaceholder
						isForLive={props.isForLive}
					/>
				)}
			</div>
		</>
	);
};
FleetSelectionArea.defaultProps = {
	hidden: false,
	isForLive: false,
};
export default FleetSelectionArea;
