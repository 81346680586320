class PrivateMileageAlertMetadata {
	constructor() {
		this.thresholdValue = 500;
		this.preThresholds = 0;
		this.firstPreThresholdAlert = 0;
		this.secondPreThresholdAlert = 0;
		this.thirdPreThresholdAlert = 0;
	}
	public thresholdValue: number;
	public preThresholds: number;
	public firstPreThresholdAlert: number;
	public secondPreThresholdAlert: number;
	public thirdPreThresholdAlert: number;
	[propName: string]: any;
}

export default PrivateMileageAlertMetadata;
