class IdIdentifiable {
	constructor(id?: string) {
		this.id = id;
	}

	public id: string;

	[propName: string]: any;
}
export default IdIdentifiable;
