import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';
import { loadedEntityContextActionCreators } from '../../../store/LoadedEntityContextData';
import { useDispatch } from 'react-redux';

export interface PersonGroup {
	id: string;
	name: string;
	code: string;
	description: string;
	active: boolean;
	customerId: string;
	connectionId: string;
	createdDatetime: Date;
	modifiedDatetime: Date;
}

interface Props extends IButtonWidgetView {
	getUrl: string;
	updateUrl: string;
	navigateFromPersons?: boolean;
}

const PersonGroupsView = (props: Props) => {
	useEffect(() => {
		props.setUrl(props.getUrl);
	}, []);
	const dispatch = useDispatch();

	const terminateConnection = useCallback(
		async (rowData: PersonGroup) => {
			if (rowData) {
				await ajaxUtil.post(props.updateUrl, {
					removedConnections: [
						{
							id: rowData.connectionId,
							personId: props.entityId,
							groupId: rowData.id,
						},
					],
				});

				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);

	useEffect(() => {
		if(props.navigateFromPersons && props.data)
			dispatch(loadedEntityContextActionCreators.setLoadedPersonContextActiveGroupsCount(props.data.length));
	}, [props.data]);

	return (
		<DataTable
			value={props.data as PersonGroup[]}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: PersonGroup) =>
					props.editMode ? (
						<span className={rowData.active ? '' : 'inactiveEntity'}>{rowData.name}</span>
					) : (
						<Link
							className={rowData.active ? '' : ' inactiveEntity'}
							to={`${GlobalSettings.route.groups}/${rowData.id}`}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: PersonGroup) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default PersonGroupsView;
