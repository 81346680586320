import { ListSelectionDialog } from 'components/ListSelectionDialog';
import { ListSelectionItem } from 'components/ListSelectionView/types';
import React, { useEffect, useState } from 'react';
import { getLocationColumns, locationInitialFilters } from 'utils/ListSelection/LocationsSelectionUtils';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import GlobalSettings from '../../../../GlobalSettings.json';
import { useDialogEvents } from './hooks';
import { AddNewAlertLocationButtonProps } from './types';

const AddNewAlertLocationButton = (props: AddNewAlertLocationButtonProps): JSX.Element => {
	const columns = getLocationColumns();
	const [showDialog, setShowDialog] = useState(false);

	const [selection, setSelection] = useState<ListSelectionItem[]>([]);
	useEffect(() => {
		setSelection(props.currentSelection);
	}, [props.currentSelection]);

	const { onSaveClicked, onCancelClicked, onSelectionToggle, removeAllSelection, removeSelection } = useDialogEvents(
		props.entityId,
		props.currentSelection,
		selection,
		props.actionExecuted,
		setShowDialog,
		setSelection
	);

	return (
		<>
			<Button className="widget-button add-button" onClick={() => setShowDialog(true)}>
				{TranslateText('common.buttonAdd')}
			</Button>
			{showDialog ? (
				<ListSelectionDialog
					filterPlaceholder={TranslateText('geofence.searchLocation')}
					getGridDataUrl={`${GlobalSettings.locationsMaintenanceApi}/getPage?listViewFilter=true`}
					visible={showDialog}
					onCancelClicked={onCancelClicked}
					onSaveClicked={onSaveClicked}
					onSelectionToggle={onSelectionToggle}
					removeAllSelection={removeAllSelection}
					removeSelection={removeSelection}
					selection={selection}
					columns={columns}
					initialFilters={locationInitialFilters}
				/>
			) : null}
		</>
	);
};

export default AddNewAlertLocationButton;
