import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import GlobalSettings from 'GlobalSettings.json';
import { ListItemDto } from 'models/ListItemDto';
import React, { useEffect, useState } from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, Grid, makeStyles, Paper, Tooltip } from '@material-ui/core';
import FailIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
		gridGap: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
		marginBottom: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
}));

const ToolsPage = () => {
	const classes = useStyles();
	const [customerId, setCustomerId] = useState<string>(undefined);
	const [customersForRdwSync, setCustomersForRdwSync] = useState<AutocompleteItem[]>([]);
	const [rdwSynchRequestSuccessfull, setRdwSynchRequestSuccessfull] = useState<boolean>(null);

	useEffect(() => {
		ajaxUtil.get<AutocompleteItem[]>(`${GlobalSettings.toolsApi}/GetCustomersForRdwSync`).then((data) => {
			setCustomersForRdwSync(
				data
					.map((d) => ({ id: d.id, display: TranslateText(d.display), selected: false, disabled: false }))
					.sort((a, b) => (a.id as string).localeCompare(b.id as string))
			);
		});
	}, []);

	const sendSyncRequest = () => {
		setRdwSynchRequestSuccessfull(null);
		ajaxUtil
			.post<boolean>(`${GlobalSettings.toolsApi}/rdwsync/`, { customerId })
			.then((result) => {
				setRdwSynchRequestSuccessfull(result);
			})
			.catch(() => {
				setRdwSynchRequestSuccessfull(false);
			});
	};

	useEffect(() => {
		setRdwSynchRequestSuccessfull(null);
	}, [customerId]);

	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
			<Grid item direction="column" xs={12} spacing={3} justifyContent="flex-start" alignItems="flex-start">
				<Paper className={classes.paper}>
					<div style={{ marginBottom: '10px' }}>{TranslateText('tools.forceRdwSynchronization')} </div>
					<form id={'alertViewSystemInformationForm'} noValidate={true}>
						<Tooltip title={TranslateText('tools.forceRdwSynchronizationForCustomerDescription')}>
							<div
								className="form-group"
								style={{
									minWidth: '300px',
									maxWidth: '400px',
									maxHeight: '35px',
									minHeight: '35px',
									display: 'inline-block',
								}}
							>
								<MaterialAutocomplete
									isForNewAddWizard={true}
									valueId={customerId}
									dataSource={customersForRdwSync}
									name="customer"
									disabled={false}
									label={TranslateText('fields.customer')}
									onChange={(value: any) => {
										setCustomerId(value.value);
									}}
									disableClearable={false}
								/>
							</div>
						</Tooltip>
						<Button
							disabled={!customerId}
							style={{ display: 'inline-block', textTransform: 'none' }}
							onClick={() => sendSyncRequest()}
						>
							{TranslateText('tools.forceSynchronization')}
						</Button>
						{rdwSynchRequestSuccessfull !== null ? (
							rdwSynchRequestSuccessfull ? (
								<DoneIcon style={{ color: 'green' }}></DoneIcon>
							) : (
								<FailIcon style={{ color: 'red' }}></FailIcon>
							)
						) : null}
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default ToolsPage;
