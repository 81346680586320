import '../connectionWidget.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import BaseView from 'components/BaseView';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { DialogUtil } from 'components/Common/NotificationDialog/NotificationDialog';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import DeviceView from 'components/Views/DeviceView';
import RenderForEnum from 'components/Views/RenderForEnum';
import SimCardView from 'components/Views/SimCardView';
import WidgetStateEnum from 'components/Views/WidgetStateEnum';
import GlobalSettings from 'GlobalSettings.json';
import { AddEntityResult } from 'models/AddEntityResult';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import Device from 'models/Device';
import DevicePropertiesEnum from 'models/DevicePropertiesEnum';
import DeviceSimCardConnection from 'models/DeviceSimCardConnection';
import EntityTypeEnum from 'models/EntityTypeEnum';
import Filter from 'models/Filter';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import SimCard from 'models/SimCard';
import { User } from 'oidc-client';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState, CustomerEntityDefaultsState } from 'store';
import { SelectedCustomer } from 'store/GlobalCustomer';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Button, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../Constants';
import { DebouncedButton } from '../../Common/DebouncedButton';

//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	visible: boolean;
	renderFor: EntityTypeEnum;
	customerId: string;
	defaultCustomerId: string;
	globalCustomer: SelectedCustomer;
	filterTextDevice: any;
	showDialog: boolean;
	currentEntityMode: boolean;
	dialogCloseCallback: () => void;
	entityId: string;
	savedCallback: (sender: any) => void;
	isKeypadDeviceFromCustomer: boolean;
	displayText: string;
	user: User;
	forceReload?: boolean;
};

type State = {
	validDeviceSimCardConnection: boolean;
	invalidForm: boolean;
	invalidNewEntityForm: boolean;
	entityCustomerId: string;
	simCardDeviceConnectionStartDate: Date;
	selectedTabIndex: number;
	existingEntityTabInit: boolean;
	newEntityTabInit: boolean;
	devicesData: AutocompleteItem[];
	productsData: AutocompleteItem[];
	deviceSimCardConnection: DeviceSimCardConnection;
	newSimCardDeviceConnection: DeviceSimCardConnection;
	validationResult: ValidationResult;
	isLoading: boolean;
	isDialogOpen: boolean;
};

class AddConnection extends BaseView<Props, State> {
	conflictsCanBeAutomaticallyFixed = false;
	conflictsCanBeAutomaticallyFixedNewSimCard = false;
	conflictingConnections: DeviceSimCardConnection[];
	conflictinConnectionNewSimCard: DeviceSimCardConnection[];
	proposedConnectionCanBeSavedNewEntity: boolean;
	conflictingConnectionNotifierRef: ConnectionConflictsNotifier;
	conflictReceivedOnExistingEntity = false;
	newSimCard: SimCard;
	unsavedNewData = false;
	unsavedData = false;
	deviceViewObj: any;
	newDevice: Device = new Device(new Date());
	newEntityValidator: Validator;
	existingEntityValidator: Validator;
	simCardViewRef: SimCardView;

	customerId: string;
	dataFilter: Filter;

	constructor(props: any) {
		super(props);

		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Device
					? FilterFieldTypeEnum.deviceId
					: FilterFieldTypeEnum.simCardId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: props.entityId,
			display: DevicePropertiesEnum.Code,
		};

		const newSimCardDeviceConnection = new DeviceSimCardConnection();
		newSimCardDeviceConnection.connectionStartDate = new Date();
		this.state = {
			validDeviceSimCardConnection: false,
			invalidForm: true,
			invalidNewEntityForm: true,
			entityCustomerId: this.props.customerId,
			simCardDeviceConnectionStartDate: null as Date,
			selectedTabIndex: 0,
			existingEntityTabInit: false,
			newEntityTabInit: false,
			devicesData: [],
			productsData: [],
			deviceSimCardConnection: newSimCardDeviceConnection,
			newSimCardDeviceConnection: null,
			validationResult: null,
			isLoading: false,
			isDialogOpen: false,
		};

		this.setFormValidatorForExistingEntity();
		this.setFormValidatorForNewEntity();
	}

	componentDidMount() {
		this.entityChanged();
	}

	componentDidUpdate(prevProps: {
		showDialog: boolean;
		currentEntityMode: boolean;
		entityId: string;
		forceReload?: boolean;
	}) {
		if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog === true) {
			this.showDialog();
		}

		if (prevProps.entityId !== this.props.entityId) {
			this.entityChanged();
		}

		if (prevProps.forceReload !== this.props.forceReload && this.props.forceReload === true) {
			this.setEntityCustomerId();
		}
	}

	async entityChanged() {
		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Device
					? FilterFieldTypeEnum.deviceId
					: FilterFieldTypeEnum.simCardId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: this.props.entityId,
			excludeConnectedEntities:
				this.props.renderFor === EntityTypeEnum.Device || this.props.renderFor === EntityTypeEnum.SimCard,
			display: DevicePropertiesEnum.Code,
		};

		//recompute lists when entity has been changed
		this.prepareDevicesList();
		this.prepareProductsList();

		await this.setEntityCustomerId();
	}

	async setEntityCustomerId() {
		const url = GlobalSettings.connectionsApi + '/EntityCustomerId?filter=' + JSON.stringify(this.dataFilter);

		const id = await ajaxUtil.get<string>(url);
		const data = await ajaxUtil.get<CustomerEntityDefaultsState>(
			`${GlobalSettings.customersMaintenanceApi}/entityDefaults/${id}`
		);
		if (this.deviceViewObj) {
			this.deviceViewObj.setAuthenticationType(data.defaultDeviceAuthenticationType);
		}
		this.setState({
			entityCustomerId: id,
		});
	}

	setEntity(valid: boolean, deviceSimCardConnection: DeviceSimCardConnection, afterStatechange?: () => void) {
		this.setUnsavedData(true, this.unsavedNewData);

		if (afterStatechange) {
			this.setState(
				{
					invalidForm: !valid,
					deviceSimCardConnection: deviceSimCardConnection,
				},
				() => {
					afterStatechange();
				}
			);
		} else {
			this.setState({
				invalidForm: !valid,
				deviceSimCardConnection: deviceSimCardConnection,
			});
		}
	}

	getNewEntityDefault() {
		const newSimCardDeviceConnection = new DeviceSimCardConnection();
		newSimCardDeviceConnection.connectionStartDate = new Date();

		if (this.props.renderFor === EntityTypeEnum.Device) {
			newSimCardDeviceConnection.deviceId = this.props.entityId;
		} else {
			newSimCardDeviceConnection.simCardId = this.props.entityId;
		}

		newSimCardDeviceConnection.resolveConflictsAutomatically = false;

		return newSimCardDeviceConnection;
	}

	getExistingEntityDefault() {
		const newSimCardDeviceConnection = new DeviceSimCardConnection();
		newSimCardDeviceConnection.connectionStartDate = new Date();
		if (this.props.renderFor === EntityTypeEnum.Device) {
			newSimCardDeviceConnection.deviceId = this.props.entityId;
		} else {
			newSimCardDeviceConnection.simCardId = this.props.entityId;
		}

		return newSimCardDeviceConnection;
	}

	showDialog() {
		this.newSimCard = null;
		this.newDevice = null;
		this.setUnsavedData(false, false);

		this.proposedConnectionCanBeSavedNewEntity = true;
		this.proposedConnectionCanBeSaved = true;

		const tabIndex = this.props.currentEntityMode ? 0 : 1;
		this.setState(
			{
				isLoading: false,
				selectedTabIndex: tabIndex,
				deviceSimCardConnection: this.getExistingEntityDefault(),
				newSimCardDeviceConnection: this.getNewEntityDefault(),
				validationResult: null,
			},
			() => {
				this.existingEntityValidator.clearValidation();
				this.newEntityValidator.clearValidation();

				this.handleTabItemSelected(tabIndex);
			}
		);
		this.prepareDevicesList();
		this.prepareProductsList();
	}

	setFormValidatorForExistingEntity() {
		this.props.renderFor === EntityTypeEnum.Device
			? (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							simCardId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							deviceId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ));
	}

	setFormValidatorForNewEntity() {
		this.props.renderFor === EntityTypeEnum.Device
			? (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							deviceId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							simCardId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ));
	}

	async handleValueChange(value: string, statePropName: string) {
		const tempdeviceSimCardConnection = new DeviceSimCardConnection(this.state.deviceSimCardConnection);
		if (tempdeviceSimCardConnection !== null) {
			tempdeviceSimCardConnection.resolveConflictsAutomatically = null; //reset resolved conflict when entity changed
			if (statePropName === 'productId') {
				if (this.props.renderFor === EntityTypeEnum.Device) {
					tempdeviceSimCardConnection.simCardId = value;
				} else {
					tempdeviceSimCardConnection.deviceId = value;
				}
			}

			this.setEntity(false, tempdeviceSimCardConnection, () => {
				this.checkEntitiesConnectionConflicts(true).then(() => {
					this.setValidationStateForExisting();
				});
			});
		}
	}

	async checkEntitiesConnectionConflicts(
		existingEntity: boolean,
		connectionStartDate: Date = null
	): Promise<boolean> {
		let candidateConnection: any;
		switch (this.props.renderFor) {
			case EntityTypeEnum.Device: {
				if (existingEntity) {
					if (
						this.state.deviceSimCardConnection.simCardId === null ||
						this.state.deviceSimCardConnection.simCardId === undefined ||
						this.state.deviceSimCardConnection.simCardId === ''
					) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.props.entityId,
						entity2Id: this.state.deviceSimCardConnection.simCardId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.deviceSimCardConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.DeviceSimCard,
					};
				} else {
					if (!this.state.newSimCardDeviceConnection.deviceId) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.state.newSimCardDeviceConnection.deviceId,
						entity2Id: null,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newSimCardDeviceConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.DeviceSimCard,
					};
				}

				break;
			}
			case EntityTypeEnum.SimCard: {
				if (existingEntity) {
					if (
						this.state.deviceSimCardConnection.deviceId === null ||
						this.state.deviceSimCardConnection.deviceId === undefined ||
						this.state.deviceSimCardConnection.deviceId === ''
					) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.state.deviceSimCardConnection.deviceId,
						entity2Id: this.props.entityId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.deviceSimCardConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.DeviceSimCard,
					};
				} else {
					if (!this.state.newSimCardDeviceConnection.simCardId) {
						return true;
					}

					candidateConnection = {
						entity1Id: null,
						entity2Id: this.state.newSimCardDeviceConnection.simCardId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newSimCardDeviceConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.DeviceSimCard,
					};
				}

				break;
			}
		}

		const url = GlobalSettings.connectionsApi + '/ValidateEntitiesCandidateConnection';
		this.setState({ isLoading: true });
		return await ajaxUtil.post(url, candidateConnection).then((result: any) => {
			this.setState({ isLoading: false });
			if (existingEntity) {
				if (result.conflictPresent && !this.state.deviceSimCardConnection.resolveConflictsAutomatically) {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						result.conflictingConnections,
						result.fixableConflict
					);
					this.conflictReceivedOnExistingEntity = true;
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSaved = false;
					return false;
				} else {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSaved = true;
					return true;
				}
			} else {
				if (result.conflictPresent && !this.state.newSimCardDeviceConnection.resolveConflictsAutomatically) {
					this.conflictinConnectionNewSimCard = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewSimCard = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						this.conflictinConnectionNewSimCard,
						this.conflictsCanBeAutomaticallyFixedNewSimCard
					);
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSavedNewEntity = false;
					return false;
				} else {
					this.conflictinConnectionNewSimCard = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewSimCard = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSavedNewEntity = true;
					return true;
				}
			}
		});
	}

	prepareProductsList() {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Device: {
				const url =
					GlobalSettings.simCardsMaintenanceApi +
					'/simCardsForDevices?filter=' +
					JSON.stringify(this.dataFilter);

				ajaxUtil.get<AutocompleteItem[]>(url).then((data) => {
					this.setState({
						productsData: data,
					});
				});

				break;
			}
			case EntityTypeEnum.SimCard: {
				if (
					ClaimUtil.validateClaim(this.props.user, {
						claim: ClaimType.Devices,
						values: [ClaimValue.edit],
					})
				) {
					this.dataFilter.display = DevicePropertiesEnum.SerialNumber;
					ajaxUtil
						.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, this.dataFilter)
						.then((data) => {
							this.setState({
								productsData: data,
							});
						});
				}
				break;
			}
		}
	}

	getHeaderText(): string {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Device:
				return TranslateText('connections.simCardDeviceHeader');
			case EntityTypeEnum.SimCard:
				return TranslateText('connections.deviceSimCardHeader');
		}
	}

	connect(existingEntity: boolean) {
		let newConnection: any;
		switch (this.props.renderFor) {
			case EntityTypeEnum.Device: {
				if (existingEntity) {
					newConnection = {
						deviceId: this.props.entityId,
						simCardId: this.state.deviceSimCardConnection.simCardId,
						connectionStartDate: this.state.deviceSimCardConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						deviceId: this.props.entityId,
						simCardId: this.state.newSimCardDeviceConnection.simCardId,
						connectionStartDate: this.state.newSimCardDeviceConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newSimCardDeviceConnection
							.resolveConflictsAutomatically,
					};
				}
				break;
			}
			case EntityTypeEnum.SimCard: {
				if (existingEntity) {
					newConnection = {
						deviceId: this.state.deviceSimCardConnection.deviceId,
						simCardId: this.props.entityId,
						connectionStartDate: this.state.deviceSimCardConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						deviceId: this.state.newSimCardDeviceConnection.deviceId,
						simCardId: this.props.entityId,
						connectionStartDate: this.state.newSimCardDeviceConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newSimCardDeviceConnection
							.resolveConflictsAutomatically,
					};
				}
				break;
			}
		}

		const url = GlobalSettings.connectionsApi + '/CreateDeviceSimCardConnection';
		ajaxUtil
			.post(url, newConnection)
			.then(() => {
				this.cleanupData();
				//if connection is charged update available entities to include/exclude newly added entity(object or device)
				this.prepareProductsList();

				this.props.savedCallback(null);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
			});
	}

	tryCloseDialog() {
		if (this.unsavedData || this.unsavedNewData) {
			this.notifyAboutUnsavedData();
			return;
		}

		this.cleanupData();
	}

	onBeforeClose() {
		this.props.dialogCloseCallback();
	}

	notifyAboutUnsavedData() {
		if (this.state.isDialogOpen) return;

		this.setState({
			isDialogOpen: true,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then((response: boolean) => {
			this.setState({
				isDialogOpen: false,
			});
			if (response) {
				//yes was clicked
				this.cleanupData();
			}
		});
	}

	private cleanupData() {
		this.clearUnsavedData();

		this.setState(
			{
				selectedTabIndex: 0,
				existingEntityTabInit: false,
				newEntityTabInit: false,
				deviceSimCardConnection: this.getExistingEntityDefault(),
				newSimCardDeviceConnection: this.getNewEntityDefault(),
			},
			() => {
				if (this.simCardViewRef) {
					this.simCardViewRef.clearView();
				}

				if (this.deviceViewObj) {
					this.deviceViewObj.clearView();
				}

				this.props.dialogCloseCallback();
			}
		);
	}

	async validateAndConnect() {
		this.setState({ isLoading: true }, () => {
			this.checkEntitiesConnectionConflicts(true)
				.then((noConflicts) => {
					if (noConflicts) {
						this.connect(true);
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	clearUnsavedData() {
		this.unsavedData = false;
		this.unsavedNewData = false;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	setUnsavedData(unsavedData: boolean, unsavedNewData: boolean) {
		this.unsavedData = unsavedData;
		this.unsavedNewData = unsavedNewData;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	private handleConfictingConnections(sender: any, proposedConnectionCanBeSaved: boolean) {
		if (this.conflictReceivedOnExistingEntity) {
			this.proposedConnectionCanBeSaved = proposedConnectionCanBeSaved;
			const tempSimCardDeviceConnection = new DeviceSimCardConnection(this.state.deviceSimCardConnection);
			tempSimCardDeviceConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;
			this.conflictReceivedOnExistingEntity = false;

			this.setState({ deviceSimCardConnection: tempSimCardDeviceConnection }, () => {
				this.setValidationStateForExisting();
			});
		} else {
			this.proposedConnectionCanBeSavedNewEntity = proposedConnectionCanBeSaved;

			const newSimCardDeviceConnection = new DeviceSimCardConnection(this.state.newSimCardDeviceConnection);
			newSimCardDeviceConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;

			this.setState({ newSimCardDeviceConnection: newSimCardDeviceConnection }, () => {
				this.setValidationStateForNew();
			});
		}
	}

	contentCurrentEntity = () => {
		return (
			<>
				<div className="e-panel-content connection-dialog-form">
					<form id="poConnectionExistingEntityForm" noValidate={true}>
						<MaterialAutocomplete
							valueId={
								this.props.renderFor === EntityTypeEnum.Device
									? this.state.deviceSimCardConnection?.simCardId
									: this.state.deviceSimCardConnection?.deviceId
							}
							dataSource={this.state.productsData}
							name="poConnectionProductListId"
							disabled={this.state.isLoading}
							onChange={({ value }) => this.handleValueChange(value as string, 'productId')}
							className="material-autocomplete"
							label={
								this.props.renderFor === EntityTypeEnum.Device
									? TranslateText('connections.selectSimCardPlaceholder')
									: TranslateText('connections.selectDevicePlaceholder')
							}
						/>
					</form>
				</div>
				<div className="connection-handle-buttons">
					<div>
						<ValidationMessage
							result={
								this.props.renderFor === EntityTypeEnum.Device
									? this.state.validationResult?.simCardId
									: this.state.validationResult?.deviceId
							}
						/>
					</div>
					<div className="left-side-buttons">
						<Button
							className="auto-cancel-button"
							onClick={() => this.tryCloseDialog()}
							disabled={this.state.isLoading}
						>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-connect-button"
							onClick={async () => await this.validateAndConnect()}
							disabled={this.state.invalidForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.connect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	prepareDevicesList() {
		if (
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Devices,
				values: [ClaimValue.edit],
			})
		) {
			this.dataFilter.display = DevicePropertiesEnum.Code;
			ajaxUtil
				.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, this.dataFilter)
				.then((data) => {
					this.setState({
						devicesData: data,
					});
				});
		}
	}

	setSimCard(valid: boolean, simCardData: SimCard) {
		this.setUnsavedData(this.unsavedData, true);
		this.newSimCard = simCardData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setDevice(valid: boolean, deviceData: Device) {
		this.setUnsavedData(this.unsavedData, true);
		this.newDevice = deviceData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setValidationStateForNew() {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Device: {
				this.simCardViewRef.validateBeforeSave().then((embeddedFormValidationResult: any) => {
					this.newEntityValidator.validate(this.state.newSimCardDeviceConnection).then((result) => {
						this.setState({
							validationResult: result.validationResult,
							invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
						});
					});
				});
				break;
			}
			case EntityTypeEnum.SimCard: {
				this.deviceViewObj.validateBeforeSave().then((embeddedFormValidationResult: any) => {
					this.newEntityValidator.validate(this.state.newSimCardDeviceConnection).then((result) => {
						this.setState({
							validationResult: result.validationResult,
							invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
						});
					});
				});
				break;
			}
		}
	}

	setValidationStateForExisting() {
		this.existingEntityValidator.validate(this.state.deviceSimCardConnection).then((result) => {
			this.setState({
				validationResult: result.validationResult,
				invalidForm: !result.formResult,
			});
		});
	}

	saveNewSimCard() {
		const url = GlobalSettings.simCardsMaintenanceApi;
		const newSimCard = {
			imsi: this.newSimCard.imsi,
			iccid: this.newSimCard.iccid,
			phoneNumber: this.newSimCard.phoneNumber,
			pinCode: this.newSimCard.pinCode,
			provider: this.newSimCard.provider,
			customerId: this.state.entityCustomerId,
			deviceId: this.newSimCard.deviceId,
			deviceSimCardConnectionStartDate: this.state.newSimCardDeviceConnection?.connectionStartDate,
			active: true,
		};

		return ajaxUtil
			.post<AddEntityResult>(url, {
				AddModel: newSimCard,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText: this.props.filterTextDevice.devices,
			})
			.then((data) => {
				if (data && data.id) {
					return data.id;
				}

				this.clearUnsavedData();

				return null;
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return null;
			});
	}

	saveNewDevice() {
		const url = GlobalSettings.devicesMaintenanceApi;
		const newDevice = {
			...this.newDevice,
			customerId: this.state.entityCustomerId,
		};

		return ajaxUtil
			.post<AddEntityResult>(url, {
				AddModel: newDevice,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText: this.props.filterTextDevice.simCards,
			})
			.then((data) => {
				if (data && data.id) {
					return data.id;
				}

				return null;
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return null;
			});
	}

	async handleAddEntityAndConnect() {
		this.setState({ isLoading: true }, () => {
			// validate future connection
			this.checkEntitiesConnectionConflicts(false)
				.then((noConflicts) => {
					if (noConflicts) {
						switch (this.props.renderFor) {
							case EntityTypeEnum.Device: {
								this.saveNewSimCard().then((idOfNewSimCard) => {
									if (idOfNewSimCard) {
										this.newSimCard.id = idOfNewSimCard;
										const newSimCardDeviceConnection = new DeviceSimCardConnection(
											this.state.newSimCardDeviceConnection
										);
										newSimCardDeviceConnection.simCardId = idOfNewSimCard;
										this.setState(
											{ newSimCardDeviceConnection: newSimCardDeviceConnection },
											() => {
												//close dialog, connection is created together with new simcard
												this.cleanupData();
												this.props.savedCallback(null);
											}
										);
									}
								});
								break;
							}
							case EntityTypeEnum.SimCard: {
								this.saveNewDevice().then((idOfNewDevice) => {
									if (idOfNewDevice) {
										this.newDevice.id = idOfNewDevice;
										const newSimCardDeviceConnection = new DeviceSimCardConnection(
											this.state.newSimCardDeviceConnection
										);
										newSimCardDeviceConnection.deviceId = idOfNewDevice;
										this.setState(
											{ newSimCardDeviceConnection: newSimCardDeviceConnection },
											() => {
												this.connect(false);
											}
										);
									}
								});
								break;
							}
						}
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	contentNewEntity = () => {
		const tabData = {
			customerId: this.state.entityCustomerId,
		};
		return (
			<>
				<div className="e-panel-content connection-dialog-form">
					{this.props.renderFor === EntityTypeEnum.Device ? (
						<SimCardView
							ref={(scope) => (this.simCardViewRef = scope)}
							defaultCustomerId={tabData.customerId}
							setSimCard={this.setSimCard.bind(this)}
							devicesData={this.state.devicesData}
							renderFor={RenderForEnum.ConnectDialog}
							readOnly={false}
						/>
					) : (
						<DeviceView
							widgetState={WidgetStateEnum.Edit}
							ref={(scope) => (this.deviceViewObj = scope)}
							defaultCustomerId={tabData.customerId}
							setDevice={this.setDevice.bind(this)}
							renderFor={RenderForEnum.ConnectDialog}
							readOnly={false}
						/>
					)}
				</div>
				<div className="connection-handle-buttons">
					<div>
						<ValidationMessage
							result={
								this.props.renderFor === EntityTypeEnum.Device
									? this.state.validationResult?.deviceId
									: this.state.validationResult?.simCardId
							}
						/>
					</div>
					<div className="left-side-buttons">
						<Button className="auto-cancel-button" onClick={() => this.tryCloseDialog()}>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-add-and-connect-button"
							onClick={async () => await this.handleAddEntityAndConnect()}
							disabled={this.state.invalidNewEntityForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.addAndConnect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	render() {
		return (
			<Dialog
				onClose={() => this.tryCloseDialog()}
				className="connection-dialog add-connection-dialog"
				open={this.props.showDialog}
				fullWidth
				disableBackdropClick
			>
				<DialogTitle>{this.getHeaderText()}</DialogTitle>
				<DialogContent>
					<ConnectionConflictsNotifier
						ref={(scope) => {
							this.conflictingConnectionNotifierRef = scope;
						}}
						connectionType={ConnectionTypeEnum.DeviceSimCard}
						visible={false}
						userAnswearCallback={this.handleConfictingConnections.bind(this)}
					/>

					<Tabs
						className="connection-dialog-tab"
						value={this.state.selectedTabIndex}
						onChange={(e, newValue: number) => {
							this.setState({ selectedTabIndex: newValue }, () => {
								this.handleTabItemSelected(newValue);
							});
						}}
					>
						<Tab value={0} className="auto-current-sim-header" label={this.getTabHeaderExistingEntity()} />
						<Tab
							value={1}
							disabled={this.state.isLoading}
							className="auto-new-sim-header"
							label={this.getTabHeaderNewEntity()}
						/>
					</Tabs>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 0}>
						{this.contentCurrentEntity()}
					</div>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 1}>
						{this.contentNewEntity()}
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	getTabHeaderExistingEntity(): string {
		if (this.props.renderFor === EntityTypeEnum.Device) {
			return TranslateText('connections.currentSimCard');
		} else {
			return TranslateText('connections.currentDevice');
		}
	}

	getTabHeaderNewEntity(): string {
		if (this.props.renderFor === EntityTypeEnum.Device) {
			return TranslateText('connections.toNewSimCard');
		} else {
			return TranslateText('connections.toNewDevice');
		}
	}

	handleTabItemSelected(selectedIndex: number): void {
		if (selectedIndex === 0) {
			if (!this.state.existingEntityTabInit) {
				this.setState({ existingEntityTabInit: true });
			}

			//validate tab
			this.setValidationStateForExisting();
		} else if (selectedIndex === 1) {
			if (this.state.newEntityTabInit) {
				if (this.simCardViewRef) {
					this.simCardViewRef.focusDefaultInput();
				}
				if (this.deviceViewObj) {
					this.deviceViewObj.focusDefaultInput();
				}

				//validate tab
				this.setValidationStateForNew();
			} else {
				if (this.simCardViewRef) {
					this.simCardViewRef.prepareViewWithData(
						this.state.entityCustomerId,
						this.props.entityId,
						this.props.displayText
					);
				}

				if (this.deviceViewObj) {
					this.deviceViewObj.focusDefaultInput();
				}

				this.setState({ newEntityTabInit: true });

				//validate tab
				this.setValidationStateForNew();
			}
		}
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		customerId: state.loadedEntityContext.entityContextData.customerId,
		defaultCustomerId: state.currentSession.customerId,
		isKeypadDeviceFromCustomer: state.currentSession.customer.featuresSettings.keypad,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterTextDevice: state.globalCustomer.filterText,
		displayText: state.loadedEntityContext.entityContextData.displayText,
		user: state.oidc.user,
	};
}

function mapDispatch(dispatch: Dispatch) {
	return {
		unsavedDataActions: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

const connector = connect(mapStateToProps, mapDispatch, null, { forwardRef: true });

export default connector(AddConnection);
