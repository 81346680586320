import React, { ChangeEvent, useEffect } from "react";

import {WidgetViewDefault} from "./Widget";
import MaterialTextField from "../../shared/components/MaterialTextField/MaterialTextField";
import {TranslateText, TranslateTextInterpolated} from "../../utils/Translations";
import {Add, ArrowDownward, ArrowUpward, Clear, Warning } from "@material-ui/icons";
import {Button, Checkbox, IconButton, Typography } from "@material-ui/core";
import LoginReasonNotification from "../../models/LoginReasonNotification";
import {FieldRule, FieldRules} from "../../shared/validation/interfaces";
import {ValidatorFunctions} from "../../shared/validation/ValidatorFunctions";
import {LanguageDto} from "../NewAddWizard/Views/InformationNotification/InformationNotificationView";
import Validator from "../../shared/validation/Validator";

type Props = WidgetViewDefault & {
	data: Record<string, LoginReasonNotification[]>;
	initialData: Record<string, LoginReasonNotification[]>;
	languageSelected: string;
	languages: LanguageDto[]
};
const NotificationLoginReasonView = (props: Props) => {

	const getRules = () => {
		let rules = {} as FieldRules;
			let size = props.data[props.languageSelected]?.length;
			for (let i = 0; i < props.languages?.length; i++) {
				for (let j = 0; j < size; j++) {
					rules[`name${props.languages[i]?.id}${j}`] = {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data: any) => {
									const loginReasonData = data as Record<string, LoginReasonNotification[]>;
									return ValidatorFunctions.wrapInPromise(
										!props.languages[i]?.active
										|| loginReasonData[props.languages[i]?.id][j]?.name !== ''
										|| loginReasonData[props.languages[i]?.id][j]?.generic
									);
								},
							},
							maxLengthConditionally: {
								message: TranslateTextInterpolated('fieldsValidations.maxLength', ['200']),
								validationFunction: (data: any) => {
									const loginReasonData = data as Record<string, LoginReasonNotification[]>;
									return ValidatorFunctions.wrapInPromise(
										!props.languages[i]?.active
										|| loginReasonData[props.languages[i]?.id][j]?.name.length <= 200
										|| loginReasonData[props.languages[i]?.id][j]?.generic
									);
								},
							}
						},
					} as FieldRule;
				}
			}
		return rules;
	}

	useEffect(() => {
		const rules = getRules();
		if(rules) {
			props.setValidatorCallback(new Validator({fieldRules: rules}, true));
		}
	},[props.data[props.languageSelected]?.length, props.editMode]);

	useEffect(() => {
		const rules = getRules();
		if(rules) {
			props.setValidatorCallback(new Validator({fieldRules: rules}, true));
		}
	}, [props.entityId]);


	const handleValueChangeLoginReason = (value: boolean | string, index: number, statePropName: keyof LoginReasonNotification) => {
		const map = JSON.parse(JSON.stringify(props.data));
		if(statePropName === 'name')
		{
			Object.keys(map).forEach(languageId => {
				const notifications: LoginReasonNotification[] = map[languageId];
				if(languageId === props.languageSelected)
				{
					notifications[index][statePropName] = value as string;
					notifications[index].translated = true;
				}
				else
				{
					if(notifications[index][statePropName] === undefined || notifications[index][statePropName] ==='')
						notifications[index][statePropName] = value as string;
				}
			});
		}
		else
		{
			Object.keys(map).forEach(languageId => {
				const notifications: LoginReasonNotification[] = map[languageId];
				notifications[index][statePropName] = value;
			});
		}
		props.changeDataCallback(map);
	}

	const handleValueChangeButtonsLoginReason = (index: number, name: string) => {
		const map = JSON.parse(JSON.stringify(props.data));
		if(name === 'clear')
		{
			Object.keys(map).forEach(languageId => {
				let array: LoginReasonNotification[] = map[languageId];
				array = array.filter((x, i) => i !== index);
				map[languageId] = array;
			});
		}
		else if (name === 'arrowUp')
		{
			if (index > 0) {
				Object.keys(map).forEach(languageId => {
					let array: LoginReasonNotification[] = map[languageId];
					let element = array.splice(index, 1)[0];
					array.splice(index - 1, 0, element);
					map[languageId] = array;
				});
			}
		}
		else
		{
			Object.keys(map).forEach(languageId => {
				if (index < map[languageId].length - 1) {
					let array: LoginReasonNotification[] = map[languageId];
					let element = array.splice(index, 1)[0];
					array.splice(index + 1, 0, element);
					map[languageId] = array;
				}
			});
		}
		props.changeDataCallback(map);
	}
	const addNewLoginNotification = () => {
		const map = JSON.parse(JSON.stringify(props.data));
		let newNotification = new LoginReasonNotification('', true, false);
		Object.keys(map).forEach(languageId => {
			map[languageId].push({...newNotification, translated: props.languageSelected === languageId});
		});

		props.changeDataCallback(map);
	}

	return (
		<>
		{props.data !== undefined
			? <div className={'login information-notification-view-container'}>
				<div className={ props.editMode ? 'login-row' : 'login-row disable-scroll'} style={props.data[props.languageSelected]?.length > 9 ? {overflowY: 'scroll', height: '460px', width:'100%'} : {width:'100%'}}>
					<table>
						{props?.languageSelected !== '' && props.data[props.languageSelected.toLowerCase()]?.map((loginReason, index) => (
							<tr key={index} className={'table-row'}>
								<td className={'login-column'} style={{minWidth: 30, paddingRight: 0}}>
									{loginReason?.translated ? null :
										<Warning
											fontSize={'small'}
											style={{color: !props.editMode ? '#b0b0b0' : 'inherit'}}
										/>}
								</td>
								<td className={'login-column'} style={{paddingLeft: 0}}>
									<Checkbox
										name="active"
										checked={loginReason?.active}
										readOnly={!props.editMode}
										onChange={(args: ChangeEvent<HTMLInputElement>) =>
											handleValueChangeLoginReason(args.target.checked, index, 'active')
										}
										disabled={!props.editMode}
									/>
								</td>
								<td className={'login-column'}
									style={ index === (props.data[props.languageSelected]?.length - 1)
										? {paddingRight:'15px', width: 250, paddingBottom:'15px'}
										: {paddingRight:'15px', width: 250}}>
									{loginReason.generic
										? <Typography
											style={{
												color: !props.editMode ? '#b0b0b0' : 'inherit',
												fontSize: 10,
												fontStyle: loginReason.translated ? 'normal' : 'italic',
											}}
											>
											{loginReason.name}
										</Typography>
										: <MaterialTextField
											autoFocus={ index === (props.data[props.languageSelected]?.length - 1) }
											isForNewAddWizard={true}
											id="loginName"
											className="resize-font"
											label={null}
											inputProps={{
												style: loginReason.translated ? {fontSize: 10} : {
													fontSize: 10,
													fontStyle: 'italic'
												}
											}}
											name="description"
											value={loginReason.name?.trimStart()}
											handleValueChange={(value) => handleValueChangeLoginReason(value, index, 'name')}
											disabled={loginReason.generic || !props.editMode}
											multiline={true}
											validationResult={props.validationResult?.[`name${props.languageSelected}${index}`]}
										/>
									}
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="clear-btn"
										disabled={loginReason.generic || !props.editMode}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'clear')
										}}
									>
										<Clear fontSize={'small'}/>
									</IconButton>
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="arrow-up-btn"
										disabled={!props.editMode || index === 0}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'arrowUp')
										}}
									>
										<ArrowUpward fontSize={'small'}/>
									</IconButton>
								</td>
								<td className={'login-column btn-column'}>
									<IconButton
										className="arrow-down-btn"
										disabled={!props.editMode || index === props.data[props.languageSelected].length - 1}
										size={'small'}
										onClick={() => {
											handleValueChangeButtonsLoginReason(index, 'arrowDown')
										}}
									>
										<ArrowDownward fontSize={'small'}/>
									</IconButton>
								</td>
							</tr>
						))}
					</table>
				</div>
				<div className={'login-row'}
					 style={{width: '100%'}}>
					{props.editMode &&
					<Button
						className={'btn-add'}
						variant="outlined"
						disabled={!props.editMode}
						startIcon={<Add/>}
						onClick={addNewLoginNotification}
					>
						{TranslateText('fields.addReason')}
					</Button>
					}
				</div>
			</div>
			: null}
		</>
	);
}

export default NotificationLoginReasonView;
