import './overviewSearchBar.scss';

import { Button as ButtonPrimeReact } from 'primereact/button';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';

import {Box, Button, Icon, IconButton, Input, Modal } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ActionCreatorsSignature, addWizardActionCreators } from '../../store/AddWizard';
import { globalCustomerActionCreators, GlobalCustomerActionSignature } from '../../store/GlobalCustomer';
import { UnsavedDataActionCreatorsSignature, unsavedDataStoreActionCreators } from '../../store/UnsavedDataStore';
import { TranslateText } from '../../utils/Translations';

interface ActionDataSource {
	text: string;
	value: string;
}

interface StatusDataSource {
	text: string;
	value: string;
}

interface Props {
	addWizardActions: ActionCreatorsSignature;
	globalCustomerActions: GlobalCustomerActionSignature;
	unsavedDataActions: UnsavedDataActionCreatorsSignature;
	toggleAddWizardCallback: (show: boolean) => void;
	addWizardMode: EntityTypeEnum;
	headerText: string;
	actionDataSource: ActionDataSource[];
	statusDataSource: StatusDataSource[];
	notifications: number;
	disableAddButton: boolean;
	filterText: { [key: string]: string };
	searchOnly?: boolean;
	showAddButton: boolean;
	customerLevel: CustomerLevelEnum;
	clearFilters?: () => void;
	clearAllFiltersBtnDisabled?: boolean;
	unsavedData?: boolean;
	inlineUnsavedData?: boolean;
	topSearchDisabled?: boolean;
	showImportButton?: boolean;
	disableImportButton: boolean;
	importCallback?: () => void;
	showExportButton?: boolean;
	disableExportButton?: boolean;
	exportCallback?: () => void;
}

class OverviewSearchBar extends React.PureComponent<Props, {}> {
	allowCall = true;
	currentFilterTextInput: HTMLInputElement;
	callTimeout: NodeJS.Timeout;
	valueSearchBar: string = null;
	unsavedDataAuxiliary: boolean = null;
	clickClearButton: boolean;

	constructor(props: Props) {
		super(props);
	}

	// put here any optional prop
	public static defaultProps = {
		notifications: 0,
		disableAddButton: false,
		searchOnly: false,
		showAddButton: true,
	};

	componentDidMount() {
		if (this.currentFilterTextInput) {
			const targetFilterText = this.defineTargetFilterText();
			this.currentFilterTextInput.value = this.props.filterText[targetFilterText];
		}
	}
	componentDidUpdate() {
		if (
			this.unsavedDataAuxiliary !== null &&
			this.unsavedDataAuxiliary &&
			!this.props.unsavedData &&
			this.clickClearButton
		) {
			const targetFilterText = this.defineTargetFilterText();
			this.currentFilterTextInput.value = '';
			this.props.globalCustomerActions.changeFilterText('', targetFilterText);
			this.clickClearButton = false;
		}

		if (
			this.unsavedDataAuxiliary !== null &&
			this.unsavedDataAuxiliary &&
			!this.props.unsavedData &&
			this.currentFilterTextInput &&
			this.currentFilterTextInput.value.length >= 3 &&
			this.currentFilterTextInput.value.length !== this.props.filterText[this.defineTargetFilterText()].length
		) {
			this.props.globalCustomerActions.changeFilterText(
				this.currentFilterTextInput.value,
				this.defineTargetFilterText()
			);
		}
	}

	defineTargetFilterText() {
		switch (this.props.addWizardMode) {
			case EntityTypeEnum.Object:
				return 'objects';
			case EntityTypeEnum.Location:
				return 'locations';
			case EntityTypeEnum.Template:
				return 'templates';
			case EntityTypeEnum.Device:
				return 'devices';
			case EntityTypeEnum.SimCard:
				return 'simcards';
			case EntityTypeEnum.Translation:
				return 'translations';
			case EntityTypeEnum.Alert:
				return 'alerts';
			case EntityTypeEnum.Customer:
				return 'customers';
			case EntityTypeEnum.Driver:
				return 'drivers';
			case EntityTypeEnum.Group:
				return 'groups';
			case EntityTypeEnum.Key:
				return 'keys';
			case EntityTypeEnum.Person:
				return 'persons';
			case EntityTypeEnum.Role:
				return 'roles';
			case EntityTypeEnum.Report:
				return 'reports';
			case EntityTypeEnum.InformationNotification:
				return 'informationnotifications';
			case EntityTypeEnum.Integration:
				return 'integrations';
			case EntityTypeEnum.ConfigurationGroup:
				return 'configurationgroups';
			default:
				throw Error('Define target filter text');
		}
	}

	private getAddCaption(wizardMode: EntityTypeEnum): string {
		const entityType: string = wizardMode;
		if (entityType === EntityTypeEnum.Customer && this.props.customerLevel === CustomerLevelEnum.OEM) {
			return TranslateText('maintenanceOverview.searchBar.add' + 'Reseller');
		}
		return TranslateText('maintenanceOverview.searchBar.add' + wizardMode);
	}

	private getImportCaption(type: EntityTypeEnum): string {
		const entityType: string = type;
		return TranslateText('maintenanceOverview.searchBar.import' + entityType);
	}
	private getExportCaption(type: EntityTypeEnum): string {
		const entityType: string = type;
		return TranslateText('maintenanceOverview.searchBar.export' + entityType);
	}

	private filterTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
		if (this.props.unsavedData && this.props.inlineUnsavedData) {
			this.valueSearchBar = e.currentTarget.value;
			this.unsavedDataAuxiliary = this.props.unsavedData;
			this.props.unsavedDataActions.setShowNotificationPrompt(true);
		} else {
			const targetFilterText = this.defineTargetFilterText();
			// limit calls to max 1 per second
			if (this.allowCall && !this.callTimeout) {
				this.allowCall = false;
				this.currentFilterTextInput = e.currentTarget;
				// only change filter text if filter value is greater than 2 and clear if less than 3
				if (
					((e.currentTarget.value.trim().length > 0 && e.currentTarget.value.length > 2) ||
						e.currentTarget.value.length === 0) &&
					targetFilterText
				) {
					this.props.globalCustomerActions.changeFilterText(
						this.currentFilterTextInput.value,
						targetFilterText
					);
				} else if (
					e.currentTarget.value.length < 3 &&
					targetFilterText &&
					this.props.filterText[targetFilterText].length > 0
				) {
					this.props.globalCustomerActions.changeFilterText('', targetFilterText);
				}
			} else if (!this.allowCall && !this.callTimeout) {
				this.callTimeout = setTimeout(() => {
					if (
						this.currentFilterTextInput &&
						this.currentFilterTextInput.value.length !== this.props.filterText[targetFilterText].length &&
						this.currentFilterTextInput.value.length >= 3
					) {
						this.props.globalCustomerActions.changeFilterText(
							this.currentFilterTextInput.value,
							targetFilterText
						);
					} else if (!this.currentFilterTextInput || this.currentFilterTextInput.value.length < 3) {
						this.props.globalCustomerActions.changeFilterText('', targetFilterText);
					}
					this.callTimeout = null;
					this.allowCall = true;
				}, 1000);
			}
		}
	}

	render() {
		let targetFilterText = '';
		switch (this.props.addWizardMode) {
			case EntityTypeEnum.Object:
				targetFilterText = 'objects';
		}

		const clearFilterText = () => {
			if (this.props.unsavedData && this.props.inlineUnsavedData) {
				this.clickClearButton = true;
				this.unsavedDataAuxiliary = this.props.unsavedData;
				this.props.unsavedDataActions.setShowNotificationPrompt(true);
			} else {
				const targetFilterText = this.defineTargetFilterText();
				this.currentFilterTextInput.value = '';
				this.props.globalCustomerActions.changeFilterText('', targetFilterText);
			}
		};

		return (
			<div id="overview-search-bar" className="header-overview">
				<div className="overview-header">{this.props.headerText}</div>
				<ul className="search-bar">
					<div className={`search-icon icon ${this.props.topSearchDisabled ? 'disabled' : ''}`} />
					<div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 search-input">
						<Input
							id="searchTextInput"
							inputRef={(e) => (this.currentFilterTextInput = e)}
							onChange={this.filterTextChanged.bind(this)}
							placeholder={TranslateText('maintenanceOverview.searchBar.search')}
							defaultValue={this.props.filterText[targetFilterText]}
							endAdornment={
								<IconButton
									hidden={this.props.topSearchDisabled}
									size={'small'}
									onClick={() => clearFilterText()}
								>
									<Clear fontSize={'small'} />
								</IconButton>
							}
							disabled={this.props.topSearchDisabled}
						/>
					</div>
					<div className="search-bar-separator" />
					<div className="search-bar-separator-right" />
					<Button
						hidden={!this.props.showAddButton}
						disabled={this.props.disableAddButton}
						startIcon={<Icon>add</Icon>}
						className="add-button-custom"
						onClick={() => this.props.toggleAddWizardCallback(true)}
					>
						{this.getAddCaption(this.props.addWizardMode)}
					</Button>
					{<div className="search-bar-separator-right" hidden={!this.props.showImportButton}/>}
					<Button
						hidden={!this.props.showImportButton}
						disabled={this.props.disableImportButton}
						startIcon={<CloudUploadIcon />}
						className="import-button-custom"
						onClick={() => this.props.importCallback()}
					>
						{this.getImportCaption(this.props.addWizardMode)}
					</Button>
					<div className="search-bar-separator-right" hidden={!this.props.showExportButton} />
					<Button
						hidden={!this.props.showExportButton}
						disabled={this.props.disableExportButton}
						startIcon={<Icon>download</Icon>}
						className="export-button-custom"
						onClick={() => this.props.exportCallback()}
					>
						{this.getExportCaption(this.props.addWizardMode)}
					</Button>
					<div className="search-bar-separator-right" />
					<ButtonPrimeReact
						hidden={!this.props.clearFilters}
						disabled={this.props.clearAllFiltersBtnDisabled}
						type="button"
						icon="pi pi-filter-slash"
						label={TranslateText('maintenanceOverview.searchBar.clear')}
						className="clearButton p-button-outlined"
						onClick={() => this.props.clearFilters()}
					/>
				</ul>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		filterText: state.globalCustomer.filterText,
		customerLevel: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel,
		unsavedData: state.unsavedDataStore.unsavedData,
		inlineUnsavedData: state.unsavedDataStore.inlineUnsavedData,
	};
}

function mapDispatch(dispatch: Dispatch) {
	return {
		addWizardActions: bindActionCreators(addWizardActionCreators, dispatch),
		globalCustomerActions: bindActionCreators(globalCustomerActionCreators, dispatch),
		unsavedDataActions: bindActionCreators(unsavedDataStoreActionCreators, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatch)(OverviewSearchBar);
