import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { noop } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import AccessApiTokenResult from 'models/AccessApiTokenResult';
import { IntegrationEasyTrackMetadata } from 'models/IntegrationDto';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import {
	Button,
	Checkbox,
	Fade,
	FormControlLabel,
	IconButton,
	Paper,
	Popper,
	PopperPlacementType,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';

import ApiAvailableOperations from '../ApiAvailableOperations';
import { IntegrationConstants } from '../Utils';
import { IntegrationTypeProps } from './types';

const IntegrationType = ({
	integrationType,
	integrationAfasMetadata,
	integrationHereOnTrackMetadata,
	integrationEasyTrackMetadata,
	validationResult,
	handleValueChangeMaterial,
	editMode = true,
	isForNewAddWizard = true,
}: IntegrationTypeProps) => {
	const inputFontSize = useMemo(() => (isForNewAddWizard ? 10 : 12), [isForNewAddWizard]);

	const groupsLoaded = useRef(false);
	const [groupsList, setGroupsList] = useState<AutocompleteItem[]>([]);
	const [openPrivacyBypassAgreement, setOpenPrivacyBypassAgreement] = React.useState(false);
	const [openReverseGeocodingAgreement, setOpenReverseGeocodingAgreement] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
	const [consent, setConsent] = useState<boolean>(false);
	const [reverseGeocodingConsent, setReverseGeocodingConsent] = useState<boolean>(false);
	const [placement, setPlacement] = React.useState<PopperPlacementType>();
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	useEffect(() => {
		if (
			!groupsLoaded.current &&
			integrationType === IntegrationTypeEnum.HereOnTrack &&
			integrationHereOnTrackMetadata?.customerId
		) {
			//wait for the edit button to be press before loading group list
			if (
				!editMode &&
				integrationHereOnTrackMetadata?.hereOnTrackGroupId &&
				integrationHereOnTrackMetadata?.hereOnTrackGroupName
			) {
				setGroupsList([
					{
						id: integrationHereOnTrackMetadata.hereOnTrackGroupId,
						display: integrationHereOnTrackMetadata.hereOnTrackGroupName,
					},
				]);
			} else {
				ajaxUtil
					.get<AutocompleteItem[]>(
						`${GlobalSettings.integrationsMaintenanceApi}/getActiveGroupsList?customerId=${
							integrationHereOnTrackMetadata?.customerId
						}${
							integrationHereOnTrackMetadata?.hereOnTrackGroupId
								? '&selectedGroupId=' + integrationHereOnTrackMetadata.hereOnTrackGroupId
								: ''
						}`
					)
					.then((data) => {
						//if the selected group is not found between the group list add it as disabled
						if (
							integrationHereOnTrackMetadata?.hereOnTrackGroupId &&
							!data.some((x) => x.id === integrationHereOnTrackMetadata.hereOnTrackGroupId)
						) {
							const newData = [...data];
							newData.push({
								id: integrationHereOnTrackMetadata.hereOnTrackGroupId,
								display: integrationHereOnTrackMetadata.hereOnTrackGroupName,
								disabled: true,
							});

							setGroupsList(
								newData.sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0))
							);
						} else {
							setGroupsList(data);
						}
						groupsLoaded.current = true;
					});
			}
		}
	}, [integrationType, editMode, integrationHereOnTrackMetadata?.customerId]);

	useEffect(() => {
		if (!integrationEasyTrackMetadata?.apiKey && integrationType === IntegrationTypeEnum.EasyTrack) {
			//wait for the edit button to be press before performing the call

			if (!integrationEasyTrackMetadata?.apiKey) {
				GenerateAPIKey();
			}
		}

		if (
			(integrationType === IntegrationTypeEnum.EasyTrack &&
				!editMode &&
				openPrivacyBypassAgreement &&
				openReverseGeocodingAgreement) ||
			integrationType !== IntegrationTypeEnum.EasyTrack
		) {
			setConsent(false);
			setOpenPrivacyBypassAgreement(false);
			setReverseGeocodingConsent(false);
			setOpenReverseGeocodingAgreement(false);
		}
	}, [integrationType, editMode, integrationEasyTrackMetadata?.customerId]);

	const GenerateAPIKey = () => {
		ajaxUtil.get<AccessApiTokenResult>(`${GlobalSettings.globalDataApi}/GenerateAPIKey`).then((data) => {
			//if the selected group is not found between the group list add it as disabled

			handleValueChangeMaterial(
				{ ...integrationEasyTrackMetadata, apiKey: data.apiKey },
				'integrationEasyTrackMetadata'
			);
		});
	};

	const handleByPassPrivacyClick = (event: ChangeEvent<HTMLInputElement>) => {
		setAnchorEl(event.target);
		setOpenPrivacyBypassAgreement(true);

		handleValueChangeMaterial(
			{ ...integrationEasyTrackMetadata, bypassAgreementRequired: true },
			'integrationEasyTrackMetadata'
		);
	};

	const handleReverseGeocodingClick = (event: ChangeEvent<HTMLInputElement>) => {
		setAnchorEl(event.target);
		setOpenReverseGeocodingAgreement(true);

		handleValueChangeMaterial(
			{ ...integrationEasyTrackMetadata, geocodingAgreementRequired: true },
			'integrationEasyTrackMetadata'
		);
	};

	const handleBypasPrivacyConsentClick = (agreement: boolean) => {
		const changedMetadata = {
			...integrationEasyTrackMetadata,
			bypassAgreementRequired: false,
		};
		if (agreement) {
			changedMetadata.bypassCustomerPrivacySettings = agreement;
			handleValueChangeMaterial(changedMetadata, 'integrationEasyTrackMetadata');
		} else {
			handleValueChangeMaterial(changedMetadata, 'integrationEasyTrackMetadata');
		}
		setOpenPrivacyBypassAgreement(false);
		setConsent(false);
	};

	const handleGeocodingConsentClick = (agreement: boolean) => {
		const changedMetadata = {
			...integrationEasyTrackMetadata,
			geocodingAgreementRequired: false,
		};
		if (agreement) {
			changedMetadata.reverseGeocoding = agreement;
			handleValueChangeMaterial(changedMetadata, 'integrationEasyTrackMetadata');
		} else {
			handleValueChangeMaterial(changedMetadata, 'integrationEasyTrackMetadata');
		}
		setOpenReverseGeocodingAgreement(false);
		setReverseGeocodingConsent(false);
	};

	return (
		<div className="view-section-wrapper">
			{integrationType === IntegrationTypeEnum.Afas && integrationAfasMetadata ? (
				<>
					<div className="form-group">
						<MaterialTextField
							autoFocus={true}
							isForNewAddWizard={isForNewAddWizard}
							id="accessToken"
							className="resize-font"
							label={TranslateText('integrations.accessToken')}
							inputProps={{ style: { fontSize: inputFontSize } }}
							name="accessToken"
							value={integrationAfasMetadata.accessToken}
							handleValueChange={(value) => {
								handleValueChangeMaterial(
									{ ...integrationAfasMetadata, accessToken: value },
									'integrationAfasMetadata'
								);
							}}
							validationResult={validationResult?.afasAccessToken}
							disabled={!editMode}
						/>
					</div>
					<div className="form-group">
						<MaterialTextField
							autoFocus={true}
							isForNewAddWizard={isForNewAddWizard}
							id="baseUrl"
							className="resize-font"
							label={TranslateText('integrations.baseUrl')}
							inputProps={{ style: { fontSize: inputFontSize } }}
							name="baseUrl"
							value={integrationAfasMetadata.baseUrl}
							handleValueChange={(value) => {
								handleValueChangeMaterial(
									{ ...integrationAfasMetadata, baseUrl: value },
									'integrationAfasMetadata'
								);
							}}
							validationResult={validationResult?.afasBaseUrl}
							disabled={!editMode}
						/>
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							isForNewAddWizard={isForNewAddWizard}
							valueId={integrationAfasMetadata.scheduleCron}
							dataSource={[
								{
									id: IntegrationConstants.chronExpressionAfas,
									display: TranslateText('integrations.defaultChronExpressionAfas'),
								},
							]}
							className={'material-autocomplete no-margin'}
							name="scheduleCron"
							disabled={true}
							label={TranslateText('integrations.timeOfsynchronisation')}
							onChange={noop}
							disableClearable
						/>
					</div>
				</>
			) : null}

			{integrationType === IntegrationTypeEnum.HereOnTrack && integrationHereOnTrackMetadata ? (
				<>
					<div className="form-group">
						<MaterialTextField
							autoFocus={true}
							isForNewAddWizard={isForNewAddWizard}
							id="hereOnTrackApiKey"
							className="resize-font"
							label={TranslateText('integrations.hereOnTrackApiKey')}
							inputProps={{ style: { fontSize: inputFontSize } }}
							name="hereOnTrackApiKey"
							value={integrationHereOnTrackMetadata.hereOnTrackApiKey}
							handleValueChange={(value) => {
								handleValueChangeMaterial(
									{ ...integrationHereOnTrackMetadata, hereOnTrackApiKey: value },
									'integrationHereOnTrackMetadata'
								);
							}}
							validationResult={validationResult?.hereOnTrackApiKey}
							disabled={!editMode}
						/>
					</div>
					<div className="form-group">
						<MaterialTextField
							type="number"
							isForNewAddWizard={isForNewAddWizard}
							id="hereOnTrackRefreshIntervalMinutes"
							className="resize-font"
							label={TranslateText('integrations.refreshIntervalMinutes')}
							inputProps={{ style: { fontSize: inputFontSize } }}
							name="hereOnTrackRefreshIntervalMinutes"
							value={integrationHereOnTrackMetadata.hereOnTrackRefreshIntervalMinutes}
							handleValueChange={(value) => {
								handleValueChangeMaterial(
									{
										...integrationHereOnTrackMetadata,
										hereOnTrackRefreshIntervalMinutes: value,
									},
									'integrationHereOnTrackMetadata'
								);
							}}
							validationResult={validationResult?.hereOnTrackRefreshIntervalMinutes}
							disabled={!editMode}
						/>
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							isForNewAddWizard={isForNewAddWizard}
							valueId={integrationHereOnTrackMetadata.hereOnTrackGroupId}
							dataSource={groupsList}
							className={'material-autocomplete no-margin'}
							name="hereOnTrackGroupId"
							disabled={!editMode}
							label={TranslateText('integrations.defaultGroup')}
							onChange={({ value }) => {
								handleValueChangeMaterial(
									{
										...integrationHereOnTrackMetadata,
										hereOnTrackGroupId: value ? value.toString() : '',
									},
									'integrationHereOnTrackMetadata'
								);
							}}
							disabledItems
							hideDisabled
							validationResult={validationResult?.hereOnTrackGroupId}
						/>
						{!isForNewAddWizard && (
							<ValidationMessage
								result={validationResult?.hereOnTrackGroupId}
								isForNewAddWizard={false}
							/>
						)}
					</div>
				</>
			) : null}

			{integrationType === IntegrationTypeEnum.EasyTrack && integrationEasyTrackMetadata ? (
				<>
					<div className="form-group" style={{ display: 'flex' }}>
						<div style={{ width: '100%' }}>
							<MaterialTextField
								autoFocus={true}
								isForNewAddWizard={isForNewAddWizard}
								id="easyTrackApiToken"
								className="resize-font"
								label={TranslateText('integrations.easyTrackApiToken')}
								inputProps={{ style: { fontSize: inputFontSize }, readOnly: true }}
								name="easyTrackApiToken"
								value={integrationEasyTrackMetadata.apiKey}
								handleValueChange={(value) => {
									handleValueChangeMaterial(
										{ ...integrationEasyTrackMetadata, apiKey: value },
										'integrationEasyTrackMetadata'
									);
								}}
								validationResult={validationResult?.easyTrackApiToken}
								disabled={!editMode}
							/>
						</div>
						<Tooltip title={TranslateText('api.regenerateApiKey')}>
							<span>
								<IconButton
									aria-label="delete"
									size="small"
									disabled={!editMode}
									onClick={GenerateAPIKey}
								>
									<RefreshIcon />
								</IconButton>
							</span>
						</Tooltip>
					</div>
					<div className="form-group">
						<MaterialTextField
							autoFocus={true}
							isForNewAddWizard={isForNewAddWizard}
							id="accessTokenCallbackURL"
							className="resize-font"
							label={TranslateText('integrations.accessTokenCallbackURL')}
							inputProps={{ style: { fontSize: inputFontSize } }}
							name="accessTokenCallbackURL"
							value={integrationEasyTrackMetadata.accessTokenCallbackURL}
							handleValueChange={(value) => {
								handleValueChangeMaterial(
									{ ...integrationEasyTrackMetadata, accessTokenCallbackURL: value },
									'integrationEasyTrackMetadata'
								);
							}}
							validationResult={validationResult?.accessTokenCallbackURL}
							disabled={!editMode}
						/>
					</div>

					{integrationEasyTrackMetadata.bypassCustomerPrivacySettings !== undefined ? (
						<div className="form-group">
							<FormControlLabel
								disabled={!editMode || !ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips)}
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={integrationEasyTrackMetadata.bypassCustomerPrivacySettings}
										onChange={(event) => {
											if (event.target.checked) {
												handleByPassPrivacyClick(event);
											} else {
												handleValueChangeMaterial(
													{
														...integrationEasyTrackMetadata,
														bypassCustomerPrivacySettings: event.target.checked,
													},
													'integrationEasyTrackMetadata'
												);
											}
										}}
									/>
								}
								label={
									<Typography
										style={{
											fontSize: inputFontSize,
											marginRight: 5,
											color: editMode ? 'black' : 'grey',
										}}
									>
										{TranslateText('integrations.bypassCustomerPrivacySettings')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0 }}
								checked={integrationEasyTrackMetadata.bypassCustomerPrivacySettings}
							/>

							<Popper
								style={{ maxWidth: 295, zIndex: 1000 }}
								open={openPrivacyBypassAgreement}
								disablePortal={true}
								anchorEl={anchorEl}
								placement={placement}
								modifiers={[
									{
										name: 'flip',
										enabled: true,
										options: {
											altBoundary: true,
											rootBoundary: 'document',
											padding: 8,
										},
									},
									{
										name: 'arrow',
										enabled: true,
										options: {
											element: anchorEl,
										},
									},
								]}
								transition
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Paper elevation={24}>
											<Typography
												style={{
													fontSize: inputFontSize,
													color: editMode ? 'black' : 'grey',
													marginLeft: 10,
												}}
											>
												{TranslateText(
													'integrations.bypassCustomerPrivacySettingsConsentMessage'
												)}
											</Typography>
											<FormControlLabel
												control={
													<Checkbox
														name="active"
														color={'primary'}
														checked={consent}
														onChange={(event) => setConsent(event.target.checked)}
													/>
												}
												label={
													<Typography
														style={{
															fontSize: inputFontSize,
															marginRight: 5,
															color: editMode ? 'black' : 'grey',
															marginLeft: 10,
														}}
													>
														{TranslateText(
															'integrations.bypassCustomerPrivacySettingsConsent'
														)}
													</Typography>
												}
												labelPlacement="start"
												style={{ margin: 0 }}
												disabled={!editMode}
											/>
											<div>
												<Button
													style={{
														fontSize: inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => handleBypasPrivacyConsentClick(false)}
												>
													{TranslateText('common.buttonCancel')}
												</Button>
												<Button
													disabled={!consent}
													style={{
														fontSize: inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => handleBypasPrivacyConsentClick(true)}
												>
													{TranslateText('common.buttonAgree')}
												</Button>
											</div>
										</Paper>
									</Fade>
								)}
							</Popper>
						</div>
					) : null}
					{integrationEasyTrackMetadata.reverseGeocoding !== undefined ? (
						<div className="form-group">
							<FormControlLabel
								disabled={!editMode}
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={integrationEasyTrackMetadata.reverseGeocoding}
										onChange={(event) => {
											if (event.target.checked) {
												handleReverseGeocodingClick(event);
											} else {
												handleValueChangeMaterial(
													{
														...integrationEasyTrackMetadata,
														reverseGeocoding: event.target.checked,
													},
													'integrationEasyTrackMetadata'
												);
											}
										}}
									/>
								}
								label={
									<Typography
										style={{
											fontSize: inputFontSize,
											marginRight: 5,
											color: editMode ? 'black' : 'grey',
										}}
									>
										{TranslateText('integrations.enableReverseGeocoding')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0 }}
								checked={integrationEasyTrackMetadata.reverseGeocoding}
							/>

							<Popper
								style={{ maxWidth: 295, zIndex: 1000 }}
								open={openReverseGeocodingAgreement}
								disablePortal={true}
								anchorEl={anchorEl}
								placement={placement}
								modifiers={[
									{
										name: 'flip',
										enabled: true,
										options: {
											altBoundary: true,
											rootBoundary: 'document',
											padding: 8,
										},
									},
									{
										name: 'arrow',
										enabled: true,
										options: {
											element: anchorEl,
										},
									},
								]}
								transition
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Paper elevation={24}>
											<Typography
												style={{
													fontSize: inputFontSize,
													color: editMode ? 'black' : 'grey',
													marginLeft: 10,
												}}
											>
												{TranslateText('integrations.enableReverseGeocodingConsentMessage')}
											</Typography>
											<FormControlLabel
												control={
													<Checkbox
														name="active"
														color={'primary'}
														checked={reverseGeocodingConsent}
														onChange={(event) =>
															setReverseGeocodingConsent(event.target.checked)
														}
													/>
												}
												label={
													<Typography
														style={{
															fontSize: inputFontSize,
															marginRight: 5,
															color: editMode ? 'black' : 'grey',
															marginLeft: 10,
														}}
													>
														{TranslateText('integrations.reverseGeocodingSettingsConsent')}
													</Typography>
												}
												labelPlacement="start"
												style={{ margin: 0 }}
												disabled={!editMode}
											/>
											<div>
												<Button
													style={{
														fontSize: inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => handleGeocodingConsentClick(false)}
												>
													{TranslateText('common.buttonCancel')}
												</Button>
												<Button
													disabled={!reverseGeocodingConsent}
													style={{
														fontSize: inputFontSize,
														textTransform: 'none',
													}}
													onClick={() => handleGeocodingConsentClick(true)}
												>
													{TranslateText('common.buttonAgree')}
												</Button>
											</div>
										</Paper>
									</Fade>
								)}
							</Popper>
						</div>
					) : null}

					<div className="form-group">
						<ApiAvailableOperations
							editMode={editMode}
							isForNewAddWizard={isForNewAddWizard}
							integrationType={integrationType}
							handleValueChangeMaterial={handleValueChangeMaterial}
							validationResult={validationResult}
							integrationEasyTrackMetadata={integrationEasyTrackMetadata}
						/>
					</div>
				</>
			) : null}
		</div>
	);
};

export default IntegrationType;
