import AlertAnalogTriggerConditionEnum from './AlertAnalogTriggerConditionEnum';

class AnalogAlertMetadata {
	constructor() {
		this.templateAnalogId = null;
		this.templateAnalogStateId = null;
		this.triggerCondition = AlertAnalogTriggerConditionEnum.ToSpecificState;
	}
	public templateAnalogId: string;
	public templateAnalogStateId: string;
	public triggerCondition?: AlertAnalogTriggerConditionEnum;
	[propName: string]: any;
}

export default AnalogAlertMetadata;
