import './styles.scss';

import { MaterialTimePicker } from 'components/Common/MaterialTimePicker';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React from 'react';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { WorkingHoursScheduleProps } from './types';
import { validateWorkingDay } from './WorkingHoursUtils';

const WorkingHoursSchedule = (props: WorkingHoursScheduleProps): JSX.Element => {
	const format = DateTimeConversionUtil.syncFusionToMomentTimeFormat(DateTimeUtil.timeFormat(true));

	return (
		<div className={`working-hours-schedule ${props.disabled ? 'disabled' : ''} ${props.className ?? ''}`}>
			{props.title ? <div className="schedule-title">{props.title}</div> : null}
			<div className="schedule-container">
				<div className="schedule-row">
					<div className="day-checkbox"></div>
					<div className="times-container">
						<div className="times-selection-container">
							<span className="start-hour sub-title">{TranslateText('hoursSchedule.startTime')}</span>
							<span className="end-hour sub-title">{TranslateText('hoursSchedule.endTime')}</span>
						</div>
					</div>
				</div>

				{props.workingDays.map((wd) => (
					<div key={wd.dayOfWeek} className="schedule-row">
						<div className="day-checkbox">
							<FormControlLabel
								control={
									<Checkbox
										disabled={props.disabled}
										color={'primary'}
										checked={wd.checked}
										onChange={(_, checked) => {
											props.updateWorkingDay({ ...wd, checked: checked });
										}}
										size="small"
									/>
								}
								label={<Typography style={{ fontSize: 12, marginLeft: 5 }}>{wd.label}</Typography>}
							/>
						</div>
						<div className="times-container">
							<div className="times-selection-container">
								<div className="start-hour">
									{wd.checked ? (
										<MaterialTimePicker
											className="time-picker"
											disabled={props.disabled}
											hour={wd.startHour}
											minute={wd.startMinute}
											format={format}
											onChange={(hour, minute) => {
												props.updateWorkingDay({ ...wd, startHour: hour, startMinute: minute });
											}}
										/>
									) : null}
								</div>
								<div className="end-hour">
									{wd.checked ? (
										<MaterialTimePicker
											className="time-picker"
											disabled={props.disabled}
											hour={wd.endHour}
											minute={wd.endMinute}
											format={format}
											onChange={(hour, minute) => {
												props.updateWorkingDay({ ...wd, endHour: hour, endMinute: minute });
											}}
										/>
									) : null}
								</div>
							</div>
							{wd.checked && !validateWorkingDay(wd) ? (
								<div className="e-error">
									{TranslateText('hoursSchedule.endTimeNotGreaterStartTimeError')}
								</div>
							) : null}
						</div>
					</div>
				))}

				{!props.hideWarning ? (
					!props.workingDays.some((x) => x.checked) ? (
						<Alert className="alert-warning" severity="warning">
							{TranslateText('hoursSchedule.noDaySelectedWarning')}
						</Alert>
					) : null
				) : !!props.validationResult ? (
					<ValidationMessage result={props.validationResult} isForNewAddWizard={true} />
				) : null}
			</div>
		</div>
	);
};

export default WorkingHoursSchedule;
