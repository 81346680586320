import * as React from 'react';
import { History, LocationState } from 'history';
import { useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { ApplicationState } from '../../store';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { TranslateText } from '../../utils/Translations';
import { GridOverview } from '../GridOverview/GridOverview';
import GlobalSettings from '../../GlobalSettings.json';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { Checkbox } from 'primereact/checkbox';
import Customer from '../../models/Customer';
import TextColumn from '../GridOverview/Columns/TextColumn';
import { GridFilters, MatchMode, SortOrder } from '../../models/GridOverview';
import BooleanColumn from '../GridOverview/Columns/BooleanColumn';
import NewGridOverview from '../GridOverview/NewGridOverview';

interface Props {
	history: History<LocationState>;
}

const fieldNames = {
	code: 'code',
	name: 'name',
	description: 'description',
	language: 'language',
	timezone: 'timezone',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.language]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.timezone]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const CustomersOverview = (props: Props) => {
	const filter = useSelector((s: ApplicationState) => s.globalCustomer.filterText.customers);
	const globalCustomer = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer);
	const currentCustomerLevel = useSelector((s: ApplicationState) => s.currentSession.customerLevel);

	const customerAllowedToAdd = (): boolean => {
		const defaultCustomerLevel = globalCustomer ? globalCustomer.level : currentCustomerLevel;
		return defaultCustomerLevel === CustomerLevelEnum.Default;
	};

	const columns = [
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.language,
			header: TranslateText('maintenanceOverview.grid.colLanguage'),
			sortable: true,
			filterable: true,
			fixedWidth: true,
		}),
		TextColumn({
			fieldName: fieldNames.timezone,
			header: TranslateText('maintenanceOverview.grid.colTimeZone'),
			sortable: true,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={`${GlobalSettings.customersMaintenanceApi}`}
			headerText={TranslateText('maintenanceOverview.customers')}
			disableAddButton={customerAllowedToAdd()}
			showAddConfigClaim={[
				{
					claim: ClaimType.Customers,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Customer}
			addEntityUrl={GlobalSettings.customersMaintenanceApi}
			disableImportButton={currentCustomerLevel != CustomerLevelEnum.Default}
			disableExportButton={currentCustomerLevel !== CustomerLevelEnum.Default}
		/>
	);
};

export default CustomersOverview;
