import AlertTypesEnum from "../../../../../models/AlertTypesEnum";
import {ValidateResult} from "../../../../../shared/validation/interfaces";
import Validator from "../../../../../shared/validation/Validator";
import {TranslateText} from "../../../../../utils/Translations";
import {ValidatorFunctions} from "../../../../../shared/validation/ValidatorFunctions";
import TowingAlertMetadata from "../../../../../models/TowingAlertMetadata";
import {useEffect, useMemo, useState } from "react";
import TowingEventTypesEnum from "../../../../../models/TowingEventTypesEnum";

const useTowingAlerts = (alertType: AlertTypesEnum, towingAlertMetadata?: TowingAlertMetadata) => {
	const [validationResult, setValidationResult] = useState<ValidateResult>();
	const validator = useMemo(
		() =>
			alertType === AlertTypesEnum.Towing
				? new Validator(
					{
						fieldRules: {
							towingTriggerEvent: {
								rules: {
									requiredConditionally: {
										message: TranslateText('fieldsValidations.fieldRequired'),
										validationFunction: (data: any) => {
											const alertData = data as TowingAlertMetadata;
											return ValidatorFunctions.wrapInPromise(
												!!alertData.towingTriggerEvent ||
												alertData.towingTriggerEvent === TowingEventTypesEnum.Both
											);
										},
									},
								},
							},
						},
					},
					true
				)
				: new Validator({ fieldRules: {} }),
		[alertType]
	);

	useEffect(() => {
		if (alertType === AlertTypesEnum.Towing && towingAlertMetadata) {
			validator.validate(towingAlertMetadata).then((result) => {
				setValidationResult(result);
			});
		}
	}, [towingAlertMetadata]);

	return validationResult;
};

export { useTowingAlerts };
