import './styles.scss'

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';


import {TranslateText} from "../../utils/Translations";
import ChartTypeEnum from "../../models/ChartTypeEnum";
import {Button, Checkbox, CircularProgress, Input, ListItemText, MenuItem, Select} from '@material-ui/core';
import {CartesianGrid, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {
	HistoryGraphDto,
	SET_DETAILED_TRIPS_ON_MAP,
	SET_FOCUS_ON_CHANGE,
	SET_SELECTED_POINT_HISTORY_TRIP_CHART,
} from "../../store/HistoryStore";
import {FormatDate, GetHourAndMinutes} from "../../utils/DateUtils";
import ChartTooltip from "./ChartTooltip/ChartTooltip";
import {
	SpeedGraph,
	TemplateGraph,
	TemplateGraphAnalog,
	TemplateSelection
} from "../TemplateHistoryTab/TemplateHistoryTab";
import {noCurve} from "./ChartType/NoCurve";

interface ChartSelectionValue {
	id: string;
	description: string;
	checked: boolean;
	type: ChartTypeEnum;
}
interface  ChartHistorySummaryProps {
	speedGraph: SpeedGraph[];
	color: string;
	templateSelection: TemplateSelection[];
	templateValuesAnalog: Record<string, TemplateGraphAnalog[]>;
	templateValuesDigital: Record<string, TemplateGraph[]>;
	isNonTrip: boolean;
	isTripInProgress: boolean;
	latitudeForNonTrip?: number;
	longitudeForNonTrip?: number;
	setStateChangesCallback: (templates: string[]) => void;
	setPreviousChartTypesCallback: (data: any) => void;
	getPreviousChartTypesCallback: () => any;
	showStates: boolean;
	setShowStates: (data: boolean) => void;
}
interface ChartData {
	id?: string;
	tripId?: string;
	latitude?: number;
	longitude?: number;
	time?: string;
	chartDataType?: ChartTypeEnum;
	[key: string]: any;
}
interface DomainX {
	left: string | number;
	right: string | number;
}
interface DomainY{
	bottom: string | number;
	top: string | number;
}

interface ChartDataSet {
	data: any;
	key: string;
	color: string;
	type: ChartTypeEnum;
}
const ChartHistorySummary = (props: ChartHistorySummaryProps) => {

	const chartSelectOption = [
		{
			id: ChartTypeEnum.Speed.toString(),
			description: TranslateText('chartType.speed'),
			type: ChartTypeEnum.Speed,
			checked: true,
		}
	] as ChartSelectionValue[];

	const dispatch = useDispatch();
	const [chartTypes, setChartTypes] = useState([]);
	const [chartInitialData, setChartInitialData] = useState<ChartData[]>([]);
	const [chartSelectedTypes, setChartSelectedTypes] = useState<string[]>([]);
	const [previousChartSelectedTypes, setPreviousChartSelectedTypes] = useState<string[]>([]);

	const [mergedChartData, setMergedChartData] = useState<ChartData[]>([]);
	const [chartDataSet, setChartDataSet] = useState([]);
	const [chartKeys, setChartKeys] = useState<string[]>([]);

	const [areaLeft, setAreaLeft] = useState<string>('');
	const [areaRight, setAreaRight] = useState<string>('');
	const [domainX, setDomainX] = useState<DomainX>({left: 'dataMin', right: 'dataMax'});
	const [domainY, setDomainY] = useState<DomainY>({top: 'dataMax', bottom: 'dataMin'});
	const [zoomDisable, setZoomDisable] = useState<boolean>(true);
	const [activeLabel, setActiveLabel] = useState<string>('');
	const [chartKey, setChartKey] = useState(Date.now());
	const [showChart, setShowChart] = useState<boolean>(false);
	const [showCircle, setShowCircle] = useState<boolean>(true);

	useEffect(() => {
		if(props.showStates === true && showChart === true)
			setShowCircle(false);
		else
			setShowCircle(true);

	},[props.showStates, showChart]);

	useEffect(() => {
		setZoomDisable(true);
		setShowChart(false);
		resetChartProperties();
		let newChartSelection =
			(!props.isNonTrip
				? [...chartSelectOption]
				: []) as ChartSelectionValue[];
		props.templateSelection?.map((x) => {
			newChartSelection.push(
				{
					id: x.templateId,
					description: x.templateName,
					checked: false,
					type: x.type
				} as ChartSelectionValue
			);
		});
		if (newChartSelection?.length > 0 && !newChartSelection[0].checked)
			newChartSelection[0].checked = !newChartSelection[0].checked;
		let templatesIds = [] as string [];
		if (newChartSelection?.length > 0) {
			if (props.getPreviousChartTypesCallback && props.getPreviousChartTypesCallback().length > 0) {
				props.getPreviousChartTypesCallback().map((x: ChartSelectionValue) => {
					if (newChartSelection.some(q => q.id === x.id)) {
						let z = newChartSelection.find((q) => q.id === x.id);
						z.checked = x.checked;
					}
				});
			} else {
				props.setPreviousChartTypesCallback(newChartSelection);
			}
			newChartSelection.map((x) => {
				if (x.checked && (x.type === ChartTypeEnum.TemplateAnalog || x.type === ChartTypeEnum.TemplateDigital))
					templatesIds.push(x.id);
			});
			if (!props.showStates && !showChart) {
				if (templatesIds.length > 0)
					props.setStateChangesCallback(templatesIds);
				else
					props.setStateChangesCallback([]);
			}
			let newChartSelectedTypes = [] as string [];
			newChartSelection.forEach((x) => {
				if (x.checked)
					newChartSelectedTypes.push(x.id);
			});
			if (newChartSelectedTypes.length === 0 && newChartSelection && newChartSelection?.length > 0) {
				newChartSelection[0].checked = true;
				newChartSelectedTypes.push(newChartSelection[0].id);
			}

			setChartTypes(newChartSelection);
			setPreviousChartSelectedTypes(newChartSelectedTypes);
			changeDataSet(newChartSelection);
			setChartSelectedTypes(newChartSelectedTypes);
		}
	},[props.speedGraph, props.templateSelection, props.templateValuesAnalog, props.templateValuesDigital]);

	const mergeMultipleDatasets = (datasets: ChartDataSet[]) => {
		const timeSet = new Set();
		datasets.forEach((dataset: ChartDataSet) => {
			dataset.data.forEach((item: ChartData) => timeSet.add(item.time));
		});

		const times = Array.from(timeSet).sort((a, b) => new Date(a as string).getTime() - new Date(b as string).getTime()) as string[];

		const mergedData: ChartData[] = [];
		times.forEach((time: string) => {
			const entry: ChartData = { time };
			datasets.forEach((dataset: ChartDataSet) => {
				const dataPoint = dataset.data.find((item: ChartData) => item.time === time);
				if (dataPoint) {
					entry.id = dataPoint.id;
					entry.tripId = dataPoint.tripId;
					entry.latitude = dataPoint.latitude;
					entry.longitude = dataPoint.longitude;
					entry[dataset.key] = dataPoint[dataset.key];
					if(dataset.type === ChartTypeEnum.TemplateAnalog) {
						entry[`unitOfMeasure${dataPoint.id}`] = dataPoint.unitOfMeasure;
					}

					if(dataset.type === ChartTypeEnum.TemplateDigital) {
						entry[`digitalValueForTooltip${dataPoint.id}`] = dataPoint.digitalValueForTooltip;
						entry[`digitalNameForTooltip${dataPoint.id}`] = dataPoint.digitalNameForTooltip;
					}
				}
			});
			mergedData.push(entry);
		});
		setShowChart(true);
		return mergedData;
	};
	const getAxisYDomain = (from: number, to: number, refs: string[], offset: number): [number, number] => {
		const refData = mergedChartData.slice(from, to);
		let [bottom, top]: [number | null, number | null] = [null, null];
		refData.forEach((d: any) => {
			refs.forEach((ref: string) => {
				if (d[ref] > top) top = d[ref];
				if (d[ref] < bottom) bottom = d[ref];
			});
		});
		return [(bottom | 0) - offset, (top | 0) + offset];
	};

	const zoom = useCallback(() => {
		let tempAreaLeft = areaLeft;
		let tempAreaRight = areaRight;
		if (tempAreaLeft === tempAreaRight || tempAreaRight === '') {
			setAreaLeft('');
			setAreaRight('');
			return;
		}

		if (tempAreaLeft > tempAreaRight) [tempAreaLeft, tempAreaRight] = [tempAreaRight, tempAreaLeft];
		const from = mergedChartData.findIndex((x) => x.time === tempAreaLeft)
		const to = mergedChartData.findIndex((x) => x.time === tempAreaRight);
		if(mergedChartData.length < 3) {
			setAreaLeft('');
			setAreaRight('');
			return;
		}
		if(from <= to) {
			setMergedChartData(mergedChartData.slice(from, to +1));
			const [bottom, top] = getAxisYDomain(from, to + 1, chartKeys, 0);
			setAreaRight('');
			setAreaLeft('');
			setDomainX({
				left: tempAreaLeft,
				right: tempAreaRight,
			});
			if (bottom !== null && top !== null) {
				setDomainY({
					bottom: bottom,
					top: top
				});
			}
			setZoomDisable(false);
		}
	}, [mergedChartData, areaLeft, areaRight]);

	const zoomOut = useCallback(() => {
		setMergedChartData(chartInitialData.slice());
		resetChartProperties()
		setZoomDisable(true);
	}, [mergedChartData, areaLeft, areaRight, domainX, domainY]);

	const yAxisInterval = (mergedChartData: ChartData[]) => {
		let values = [] as number[];
		let growthValue = 1;
		mergedChartData.forEach((x: ChartData) => {
			chartKeys.forEach((y) => {
				if (x[y] !== undefined && x[y] !== null) {
					values.push(x[y]);
					if(y.includes("speed") || y.includes("analog"))
						growthValue = 5
				}
			});
		});
		const maxY = Math.max(...values);
		const minY = Math.min(...values);
		let upperBound = Math.ceil(maxY / growthValue) * growthValue;
		let lowerBound = Math.floor(minY / growthValue) * growthValue;
		let tickValues = [];
		lowerBound = lowerBound > 0 ? 0 : lowerBound;
		if(minY === 0 && maxY === 1) {
			growthValue = 1;
			upperBound = 1;
		}
		for (let i = lowerBound; i <= upperBound; i += growthValue) {
			tickValues.push(i);
		}
		return tickValues;
	}
	const formatXAxis = (tickItem : string) => {
		return GetHourAndMinutes(tickItem, true);
	};

	const onHover = useCallback((e: any) => {
		let newData = { } as HistoryGraphDto;
		if(e.activePayload && e.activePayload.length > 0)
		{
			e.activePayload.map((x: any) => {
					newData.tripId = x.payload.tripId;
					newData.tripColor = props.color;
					newData.latitude = x.payload.latitude;
					newData.longitude = x.payload.longitude;
			});

			if(props.isNonTrip)
			{
				newData.latitude = props.latitudeForNonTrip;
				newData.longitude = props.longitudeForNonTrip;
			}
		}
		if(Object.keys(newData).length === 0)
			newData = null;
		dispatch({
			type: SET_FOCUS_ON_CHANGE,
			payload: true,
		});
		dispatch(
			{
				type: SET_SELECTED_POINT_HISTORY_TRIP_CHART,
				payload: newData
			}
		);
		setActiveLabel(e.activeLabel);
	},[dispatch]);

	const onLeave = useCallback(() => {
		dispatch(
			{
				type: SET_SELECTED_POINT_HISTORY_TRIP_CHART,
				payload: null
			}
		);
		dispatch({
			type: SET_DETAILED_TRIPS_ON_MAP,
			payload: [],
		});
	},[]);

	const handleChangeChartType = (value: string[]) => {
		if(value.length === 0)
			return;
		let newChartSelectedTypes = [...value];
		setChartSelectedTypes(newChartSelectedTypes);
	};
	const handleOnClose = () => {
		if(areListsDifferent(chartSelectedTypes, previousChartSelectedTypes) === false)
			return;
		setShowChart(false);
		props.setShowStates(false);
		let newValue = [...chartTypes];
		newValue.map((x) => {
			x.checked = chartSelectedTypes.includes(x.id);
		});
		let templatesIds = [] as string [];
		if(newValue?.length > 0)
		{
			newValue.map((x) => {
				if(x.checked && (x.type === ChartTypeEnum.TemplateAnalog || x.type === ChartTypeEnum.TemplateDigital))
					templatesIds.push(x.id);
			});
			if(templatesIds.length > 0)
				props.setStateChangesCallback(templatesIds);
			else
				props.setStateChangesCallback([]);
		}
		props.setPreviousChartTypesCallback(newValue);
		changeDataSet(newValue);
		setChartTypes(newValue);
	}
	const handleOnOpen = () => {
		setPreviousChartSelectedTypes(chartSelectedTypes);
	}
	const changeDataSet = (chartTypes: ChartSelectionValue[]) => {
		let newSpeedChartData = [] as ChartData[];
		let newChartDataSet = [] as ChartDataSet [];
		let newChartKeys = [] as string[];
		let maxValue = -Infinity;
		let minValue = Infinity;
		let addToEnd = false;
		let dateForEnd: Date = null;
		let latitudeEnd: number = null;
		let longitudeEnd: number = null;
		if(chartTypes.some((x) => x.checked && x.type === ChartTypeEnum.Speed)) {
			props.speedGraph?.forEach((x) => {
				newSpeedChartData.push({
					id: x.id,
					tripId: x.tripId,
					latitude: x.latitude,
					longitude: x.longitude,
					speed: x.speed,
					time: x.timestamp,
					chartDataType: ChartTypeEnum.Speed
				})
				maxValue = x.speed > maxValue ? x.speed : maxValue;
				minValue = x.speed < minValue ? x.speed : minValue;
			});
			if(newSpeedChartData.length > 0)
			{
				const last = newSpeedChartData[newSpeedChartData.length - 1];
				addToEnd = true;
				dateForEnd = new Date(last.time);
				latitudeEnd = last.latitude;
				longitudeEnd = last.longitude;
			}

			if(!props.isTripInProgress && newSpeedChartData.length > 0 && newSpeedChartData[newSpeedChartData.length - 1].speed > 0){
				const last = newSpeedChartData[newSpeedChartData.length - 1];
				let dateLastSpeed = new Date(last.time);
				dateLastSpeed.setSeconds(dateLastSpeed.getSeconds() + 5);
				newSpeedChartData.push({
					id: last.id,
					tripId: last.tripId,
					latitude: last.latitude,
					longitude: last.longitude,
					speed: 0,
					time: dateLastSpeed.toUTCString(),
					chartDataType: ChartTypeEnum.Speed,
				});
				addToEnd = true;
				dateForEnd = dateLastSpeed;
				latitudeEnd = last.latitude;
				longitudeEnd = last.longitude;
			}

			newChartDataSet.push({
				data: newSpeedChartData,
				key: 'speed',
				color: props.color,
				type: ChartTypeEnum.Speed,
			});
			newChartKeys.push('speed');
		}

		if(chartTypes.some((x) => x.checked && x.type === ChartTypeEnum.TemplateAnalog))
		{
			chartTypes.forEach((x) => {
				if(x.checked && x.type === ChartTypeEnum.TemplateAnalog) {
					let newTemplatesValue = [] as ChartData[];
					if (props.templateValuesAnalog && props.templateValuesAnalog[x.id]) {
						props.templateValuesAnalog[x.id]?.forEach((t) => {
							newTemplatesValue.push({
								id: `analog${x.id}`,
								tripId: t.tripId,
								latitude: t.latitude,
								longitude: t.longitude,
								time: t.timestamp,
								chartDataType: ChartTypeEnum.TemplateAnalog,
								unitOfMeasure: t.unitOfMeasure,
								[`analog${x.id}`]: t.value
							});
							maxValue = t.value > maxValue ? t.value : maxValue;
							minValue = t.value < minValue ? t.value : minValue;
						});

						if(newTemplatesValue.length > 0 && addToEnd === true)
						{
							let value = newTemplatesValue[newTemplatesValue.length - 1];
							const date1 = dateForEnd;
							const date2 = new Date(value.time);
							const needToAddToTheEnd = props.templateSelection.find((x) => x.templateId == value.id.replace("analog", ""))?.needToAddToTheEnd;
							if(date1 > date2 && needToAddToTheEnd)
							{
								value.time = date1.toUTCString()
								value.latitude = latitudeEnd;
								value.longitude = longitudeEnd;
								newTemplatesValue.push(value);
							}
						}

						if (newTemplatesValue.length > 0) {
							newChartDataSet.push({
								data: newTemplatesValue,
								key: `analog${x.id}`,
								color: props.templateValuesAnalog[x.id][0]?.color ? props.templateValuesAnalog[x.id][0].color : '#4d6d92',
								type: ChartTypeEnum.TemplateAnalog
							});
							newChartKeys.push(`analog${x.id}`);
						}
					}
				}
			});
		}

		if(chartTypes.some((x) => x.checked && x.type === ChartTypeEnum.TemplateDigital))
		{
			maxValue = maxValue === -Infinity ? 1 : maxValue;
			minValue = minValue === Infinity ? 0 : minValue > 0 ? 0 : minValue;
			if(minValue === maxValue && maxValue < 1) {
				maxValue = 1;
			}
			chartTypes.forEach((x) => {
				if (x.checked && x.type === ChartTypeEnum.TemplateDigital) {
					let newTemplatesValue = [] as ChartData[];
					if (props.templateValuesDigital && props.templateValuesDigital[x.id]) {
						props.templateValuesDigital[x.id]?.forEach((t) => {
							newTemplatesValue.push({
								id: `digital${x.id}`,
								tripId: t.tripId,
								latitude: t.latitude,
								longitude: t.longitude,
								time: t.timestamp,
								chartDataType: ChartTypeEnum.TemplateDigital,
								[`digitalValueForTooltip`]: t.value,
								[`digitalNameForTooltip`]: x.description,
								[`digital${x.id}`]: t.value > 0 ? maxValue : minValue
							});
						});

						if(newTemplatesValue.length > 0 && addToEnd === true)
						{
							let value = newTemplatesValue[newTemplatesValue.length - 1];
							const date1 = dateForEnd;
							const date2 = new Date(value.time);
							const needToAddToTheEnd = props.templateSelection.find((x) => x.templateId == value.id.replace("digital", ""))?.needToAddToTheEnd;
							if(date1 > date2 && needToAddToTheEnd)
							{
								value.time = date1.toUTCString();
								value.latitude = latitudeEnd;
								value.longitude = longitudeEnd;
								newTemplatesValue.push(value);
							}
						}

						if (newTemplatesValue.length > 0) {
							newChartDataSet.push({
								data: newTemplatesValue,
								key: `digital${x.id}`,
								color: props.templateValuesDigital[x.id][0]?.color ? props.templateValuesDigital[x.id][0].color : '#4d6d92',
								type: ChartTypeEnum.TemplateDigital
							});
							newChartKeys.push(`digital${x.id}`);
						}
					}
				}
			});
		}

		resetChartProperties();
		setChartKeys(newChartKeys);
		setChartDataSet(newChartDataSet);
		const mergedData = mergeMultipleDatasets(newChartDataSet);
		setMergedChartData(mergedData);
		setChartInitialData(mergedData);
	}
	const resetChartProperties = () => {
		setAreaLeft('');
		setAreaRight('');
		setDomainX({
			left: 'dataMin',
			right: 'dataMax',
		});
		setDomainY({
			top: 'dataMax',
			bottom: 'dataMin',
		});
	};
	const areListsDifferent = (list1: string[], list2: string[]): boolean => {
		if (list1.length !== list2.length) {
			return true;
		}
		for (let i = 0; i < list1.length; i++) {
			if (list1[i] !== list2[i]) {
				return true;
			}
		}
		props.setShowStates(true);
		setShowChart(true);
		return false;
	}

	return(
		<div className={'chart-summary'}>
			<div className={'chart-summary-row'}>
				<Select
					value={chartSelectedTypes}
					input={<Input />}
					multiple
					style={{ width: '100%' }}
					onChange={({ target: { value } }) => {
						handleChangeChartType(value as string[]);
					}}
					onClose={handleOnClose}
					onOpen={handleOnOpen}
					renderValue={(value) => {
						return chartTypes
							.filter((v) => chartSelectedTypes.includes(v.id))
							.map((v) => v.description)
							.join(', ');
					}}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
					}}
				>
					{chartTypes.map((o) => (
						<MenuItem value={o.id}>
							<Checkbox checked={chartSelectedTypes.includes(o.id)} />
							<ListItemText primary={
								<div style={{ maxWidth: 365, whiteSpace: 'normal', paddingLeft: 15}}>
									{o.description}
								</div>
							} />
						</MenuItem>
					))}
				</Select>
			</div>
			{showCircle
				? <CircularProgress className="loading-component" />
				: <div className={'chart-summary-row'}>
					<Button
						className="btn-zoom-out"
						onClick={zoomOut}
						disabled={zoomDisable}
						hidden={mergedChartData.length === 0}
					>
						{TranslateText('common.buttonZoomOut')}
					</Button>
					{mergedChartData.length > 0?
						(<div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%', marginTop: 31 }}>
						<ResponsiveContainer width="100%" height={300}>
							<LineChart
								key={chartKey}
								width={800}
								height={300}
								data={mergedChartData}
								onMouseDown={(e: any) => setAreaLeft(() => e?.activeLabel)}
								onMouseMove={(e: any) => {
									if (e.activeLabel !== activeLabel) {
										onHover(e);
									}
									if (areaLeft) {
										setAreaRight(e?.activeLabel)
									}
								}}
								onMouseLeave={onLeave}
								onMouseUp={zoom}

								margin={{ left: -30, top: 5, right: 5 }}
							>
								<CartesianGrid strokeDasharray="4 4" vertical={false}/>
								<XAxis allowDataOverflow tickFormatter={formatXAxis} dataKey="time" domain={[domainX.left, domainX.right]} padding={{left: 10, right: 10}}/>
								<YAxis allowDataOverflow ticks={yAxisInterval(mergedChartData)} domain={[domainY.bottom, domainY.top]} type="number" padding={{top:20}} yAxisId="1"/>
								{chartDataSet.map(dataset => (
									<Line
										yAxisId="1"
										key={dataset.key}
										type={dataset.key.includes("digital")
											? 'stepAfter'
											: noCurve
										}
										dataKey={dataset.key}
										stroke={dataset.color}
										animationDuration={300}
										connectNulls={true}
									/>
								))}
								<Tooltip
									content={<ChartTooltip/> }
								/>
								{areaLeft && areaRight ? (
									<ReferenceArea yAxisId="1" x1={areaLeft} x2={areaRight} strokeOpacity={0.3} />
								) : null}
							</LineChart>
						</ResponsiveContainer>
						<div className={'chart-summary-row-date'}>
							<span className={'chart-summary-start-date'}>
								{FormatDate(mergedChartData[0]?.time, true, false, true)}
							</span>
							<span className={'chart-summary-end-date'}>
								{FormatDate(mergedChartData[mergedChartData.length - 1]?.time, true, false, true)}
							</span>
						</div>
					</div>)
						: (
							<div className={'no-data-available-chart'}>{TranslateText('chartSummary.noData')}</div>
						)}
				</div>
			}
		</div>
	);
}

export default ChartHistorySummary;
