import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Rule, ValidationData } from './interfaces';
import { getFieldValue, RESERVED_KEYWORDS, ValidationRulesUtil } from './utils';
import { ValidationRegex } from './ValidationRegex';

const ValidatorFunctions = {
	wrapInPromise: (result: boolean): Promise<boolean> => {
		return new Promise((resolve) => resolve(result));
	},
	required: (validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(
				!(fieldValue === null || fieldValue === undefined || fieldValue === '')
			);
		},
	}),
	requiredInEnum: (enumType: { [key: string]: string | number }, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(fieldValue in enumType);
		},
	}),
	requiredWithNull: (validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(!(fieldValue === undefined || fieldValue === ''));
		},
	}),
	maxLength: (maxValue: number, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateTextInterpolated('fieldsValidations.maxLength', [maxValue.toString()]),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(!fieldValue || fieldValue.toString().length <= maxValue);
		},
	}),
	minLength: (minValue: number, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateTextInterpolated('fieldsValidations.minLength', [minValue.toString()]),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(!fieldValue || fieldValue.toString().length >= minValue);
		},
	}),
	maxNumber: (maxValue: number, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.maxValue') + maxValue,
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName) as number;
			return ValidatorFunctions.wrapInPromise((!fieldValue && fieldValue !== 0) || fieldValue <= maxValue);
		},
	}),
	minNumber: (minValue: number, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.minValue') + minValue,
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName) as number;
			return ValidatorFunctions.wrapInPromise((!fieldValue && fieldValue !== 0) || fieldValue >= minValue);
		},
	}),
	noSameValue: (sameValue: number, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.sameValue') + sameValue,
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName) as number;
			return ValidatorFunctions.wrapInPromise((!fieldValue && fieldValue !== 0) || fieldValue === sameValue);
		},
	}),
	noEmptySpace: (validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			if (!fieldValue) {
				return ValidatorFunctions.wrapInPromise(true);
			}

			return ValidatorFunctions.wrapInPromise(ValidationRegex.noEmptySpace().test(fieldValue));
		},
	}),
	regex: (regex: RegExp, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			return ValidatorFunctions.wrapInPromise(regex.test(getFieldValue(data, fieldName)));
		},
	}),
	regexIfNotNull: (regex: RegExp, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(!fieldValue || regex.test(fieldValue));
		},
	}),
	regexNegated: (regex: RegExp, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			return ValidatorFunctions.wrapInPromise(!regex.test(getFieldValue(data, fieldName)));
		},
	}),
	validDate: (validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.invalidDateFormat'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);
			return ValidatorFunctions.wrapInPromise(!fieldValue || ValidationRulesUtil.date(fieldValue));
		},
	}),
	requiredIfFieldNotNull: (externalFieldName: string, validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			if (externalFieldName === fieldName) {
				return ValidatorFunctions.wrapInPromise(true);
			}

			const externalFieldValue = getFieldValue(data, externalFieldName);
			const fieldValue = getFieldValue(data, fieldName);

			if (externalFieldValue === null || externalFieldValue === undefined || externalFieldValue === '') {
				return ValidatorFunctions.wrapInPromise(true);
			}

			return ValidatorFunctions.wrapInPromise(
				!(fieldValue === null || fieldValue === undefined || fieldValue === '')
			);
		},
	}),
	requiredIfFieldHasValue: (
		externalFieldName: string,
		externalFieldExpectedValue: unknown,
		validationMessage?: string
	): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.fieldRequired'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			if (externalFieldName === fieldName) {
				return ValidatorFunctions.wrapInPromise(true);
			}

			const externalFieldValue = getFieldValue(data, externalFieldName);
			const fieldValue = getFieldValue(data, fieldName);

			if (externalFieldValue !== externalFieldExpectedValue) {
				return ValidatorFunctions.wrapInPromise(true);
			}

			return ValidatorFunctions.wrapInPromise(
				!(fieldValue === null || fieldValue === undefined || fieldValue === '')
			);
		},
	}),
	checkReservedKeywords: (validationMessage?: string): Rule => ({
		message: validationMessage || TranslateText('fieldsValidations.reservedKeywords'),
		validationFunction: (data: ValidationData, fieldName: string): Promise<boolean> => {
			const fieldValue = getFieldValue(data, fieldName);

			if (!fieldValue) {
				return ValidatorFunctions.wrapInPromise(true);
			}

			for (const reservedKeyword of RESERVED_KEYWORDS) {
				const regex = new RegExp(`.*(${reservedKeyword}).*`, 'i');
				if (fieldValue.toString().match(regex)) {
					return ValidatorFunctions.wrapInPromise(false);
				}
			}

			return ValidatorFunctions.wrapInPromise(true);
		},
	}),
};

export { ValidatorFunctions };
