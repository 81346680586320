import CommandTypeEnum from '../../../models/CommandTypeEnum';

export enum MileageType {
	CAN = 0,
	GPS = 1,
}

export interface LogMessage {
	id: string;
	unitId: string;
	message: string;
	event?: string;
	eventReason?: number;
	messsageReceivedDateTime?: Date;
	messsageObservationTime?: Date;
	messsageLastGpsFix?: Date;
	timezone: string;

	latitude?: number;
	longitude?: number;
	speed?: number;
	direction: string;
	altitude?: number;
	satelliteCount?: number;
	validatedGpsPosition?: boolean;

	mileageType?: MileageType;
	mileageValue?: number;
	driverKeyId: string;
	garminEnabled?: boolean;
	garminConnected?: boolean;

	ioData?: string;

	ignitionState: boolean;

	isTripStart?: boolean;
	isTripStop?: boolean;
	locationIsAssumed?: boolean;
	messageIsInvalid?: boolean;

	messageFileName: string;
	commandType?: CommandTypeEnum;

	//set on client side
	ioJSON?: Object;
	timeDiff: string;

	//used for excel export
	formattedDetails: string;
	formattedGPS: string;
	formattedMileage: string;
	formattedIO: string;
	formattedIgnition: string;
	formattedTrip: string;
}
