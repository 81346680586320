import Alert from 'models/Alert';
import { useMutation, useQuery } from 'react-query';
import ajaxUtil from 'utils/Ajax';

import * as GlobalSettings from '../../../../GlobalSettings.json';
import { UpdateAlertEvents } from './types';

const useAlertEventViewData = (entityId: string, onSuccessCallback: (alert: Alert) => void) => {
	const { isLoading, data: alert, refetch: refetchAlertEvents } = useQuery(
		['alert-event', entityId],
		() => ajaxUtil.get<Alert>(`${GlobalSettings.alertsApi}/${entityId}/information`),
		{
			onSuccess: (alert: Alert) => {
				onSuccessCallback(alert);
			},
		}
	);

	const { mutate: updateAlertEvents, isLoading: isUpdating } = useMutation(
		(updateAlertEvents: UpdateAlertEvents) =>
			ajaxUtil.put<UpdateAlertEvents>(`${GlobalSettings.alertsApi}/${entityId}/events`, updateAlertEvents),
		{
			onSuccess: () => {
				refetchAlertEvents();
			},
		}
	);

	return { isLoading, isUpdating, alert, updateAlertEvents };
};

export { useAlertEventViewData };
