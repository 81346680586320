import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import EntityTypeEnum from '../../../models/EntityTypeEnum';
import { ApplicationState } from '../../../store';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';

export interface GroupMemberAuthorization {
	id: string;
	name: string;
	memberId: string;
	groupId: string;
	active: boolean;
	type: EntityTypeEnum;
	connectionStartDate: Date;
	connectionEndDate?: Date;
}

const GroupAuthorizationView = (props: IButtonWidgetView) => {
	const { driverIdentification } = useSelector((state: ApplicationState) => ({
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification,
	}));

	useEffect(() => {
		props.setUrl(
			`${GlobalSettings.groupsMaintenanceApi}/${props.entityId}/persons/authorization?driverIdentification=${driverIdentification}`
		);
	}, [driverIdentification]);

	const terminateConnection = useCallback(
		async (rowData: GroupMemberAuthorization) => {
			if (rowData) {
				await ajaxUtil.post(
					`${GlobalSettings.groupsMaintenanceApi}/updateGroupPersonConnection/authorization`,
					{
						removedConnections: [
							{
								id: rowData.id,
								groupId: props.entityId,
								memberId: rowData.memberId,
								type: rowData.type,
							},
						],
					}
				);
				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);

	return (
		<DataTable
			value={(props.data as GroupMemberAuthorization[]).sort((a, b) => a.name.localeCompare(b.name))}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: GroupMemberAuthorization) =>
					props.editMode ? (
						<span className={rowData.active ? '' : 'inactiveEntity'}>{rowData.name}</span>
					) : (
						<Link
							className={rowData.active ? '' : ' inactiveEntity'}
							to={`${GlobalSettings.route.persons}/${rowData.memberId}`}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colMemberType')}
				body={(rowData: GroupMemberAuthorization) => (
					<span>{TranslateText(`fleetSelection.${rowData.type}`)}</span>
				)}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: GroupMemberAuthorization) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default GroupAuthorizationView;
