import '../../ExtendedMenu.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { SHOW_HISTORY_TRIP_DETAILS } from 'store/HistoryStore';
import { getContrastColor } from 'utils/TripUtils';

import { Box, Icon, IconButton, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import { HistoryDetailsTabsHeaderProps } from './types';

const HistoryDetailsTabsHeader = ({
	tripDetails,
	onClosedButtonClicked,
}: HistoryDetailsTabsHeaderProps): JSX.Element => {
	const dispatch = useDispatch();

	const tripUnsavedData: boolean = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const driverIdentification: boolean = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const { icon } = useTryGetSvgIconFromQueryCache(tripDetails.objectIcon);

	return (
		<div className="details-header">
			<div className="title-header">
				<Box
					bgcolor={tripDetails.tripColor}
					color={getContrastColor(tripDetails.tripColor)}
					className={`trip-number ${tripDetails.index >= 99 ? 'small' : ''}`}
				>
					{tripDetails.isInProgress ? '-' : tripDetails.index + 1}
				</Box>

				{tripDetails.isPerson && driverIdentification ? (
					<>
						<PersonIcon />
						<Tooltip title={tripDetails.personName}>
							<div className="details-header-title">{tripDetails.personName}</div>
						</Tooltip>
					</>
				) : (
					<>
						<SvgIcon icon={icon.content} color="#FFFFFF" size={24} onClick={() => {}} />
						<Tooltip title={tripDetails.objectName}>
							<div className="details-header-title">{tripDetails.objectName}</div>
						</Tooltip>
					</>
				)}
			</div>

			<div className="details-close-container ">
				<IconButton
					onClick={() => {
						if (tripUnsavedData) {
							onClosedButtonClicked();
						} else {
							dispatch({
								type: SHOW_HISTORY_TRIP_DETAILS,
								payload: null,
							});
						}
					}}
					className="details-close-icon"
				>
					<Icon>close</Icon>
				</IconButton>
			</div>
		</div>
	);
};

export default HistoryDetailsTabsHeader;
