import moment from 'moment';
import { Dispatch } from 'redux';

import { HistoryTimeRange } from '../components/HistoryOverview/HistoryTimeRange';
import TripTypesEnum from '../models/TripTypesEnum';

const SET_DATE_FILTER = 'SET_DATE_FILTER';
const SET_TRACKTYPE_FILTER = 'SET_TRACKTYPE_FILTER';
export interface HistoryDateFilter {
	from: Date;
	to: Date;
	historyTimeRange: HistoryTimeRange;
}

export interface HistoryFilter {
	dateFilter: HistoryDateFilter;
	trackType: TripTypesEnum;
}

const initialState: HistoryFilter = {
	dateFilter: {
		from: moment()
			.startOf('day')
			.toDate(),
		to: moment()
			.endOf('day')
			.toDate(),
		historyTimeRange: HistoryTimeRange.Day,
	},
	trackType: TripTypesEnum.None,
};

export const historyFilterActionCreators = {
	setDateFilter: (filter: HistoryDateFilter) => (dispatch: Dispatch) => {
		dispatch({ type: SET_DATE_FILTER, filter });
	},
	setTrackTypeFilter: (filter: TripTypesEnum) => (dispatch: Dispatch) => {
		dispatch({ type: SET_TRACKTYPE_FILTER, filter });
	},
};

export const historyFilterReducer = (state: HistoryFilter, action: any) => {
	state = state ?? initialState;

	switch (action.type) {
		case SET_DATE_FILTER:
			return {
				...state,
				dateFilter: action.filter,
			};
		case SET_TRACKTYPE_FILTER:
			return {
				...state,
				trackType: action.filter,
			};
		default:
			return state;
	}
};
