import React from 'react';

import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

import DateTimeUtil from '../../shared/datetime/DateTimeUtil';
import ValidationUtil from '../../shared/validation/utils';

const Constants = {
	defaultLanguageId: '8c010c80-5f85-4374-84e6-8af212188f4a', // default language Dutch
	defaultTimezoneId: '44cc441a-fac0-4418-ba0c-b97d88f04e64', // default timezone Europe/Amsterdam
};

export { Constants };

class BaseView<P = {}, S = {}, SS = any> extends React.PureComponent<P, S, SS> {
	noEmptySpaceRegEx = '(?!^ +$)^.+$';
	alphanumericUserNameRexEx = '^[a-zA-Z0-9_-]{1,50}$';
	defaultLanguageId: string = Constants.defaultLanguageId; // default language Dutch
	defaultTimezoneId: string = Constants.defaultTimezoneId; // default timezone Europe/Amsterdam
	dateformat: string = DateTimeUtil.dateFormat();
	protected formObjects: FormValidator[];
	formValidatorModel: FormValidatorModel;
	focusInput: TextBoxComponent;
	validationResult: boolean;
	proposedConnectionCanBeSaved: boolean;

	constructor(props: any) {
		super(props);

		this.formObjects = [];
		this.validationResult = true;
	}

	ValidateForms() {
		this.formObjects.forEach((v) => v.validate());
	}

	ClearValidations(data: any) {
		this.formObjects.forEach((v) => ValidationUtil.clearAllValidations(v, data, () => {}));
		this.ClearFormValidators();
	}

	ClearFormValidators() {
		this.formObjects = [];
	}

	RemoveFormValidator(formName: string) {
		const formIndex = this.formObjects.findIndex((x) => x.element.id === formName);
		if (formIndex > -1) {
			this.formObjects.splice(formIndex, 1);
		}
	}

	GetFormValidator(formName: string) {
		return this.formObjects.find((x) => x.element.id === formName);
	}

	AddFormValidator(newFormValidator: FormValidator) {
		//check first if a form validator with the same form id exists, if that is the case remove it first
		if (!newFormValidator || !newFormValidator.element) {
			return;
		}
		const result = this.formObjects.findIndex(
			(x) => newFormValidator.element && x.element.id === newFormValidator.element.id
		);

		if (result !== -1) {
			this.formObjects.splice(result, 1);
		}
		this.formObjects.push(newFormValidator);
	}

	guardedValidateForm(readOnly: boolean, fieldName?: string, formName?: string): boolean {
		if (readOnly) {
			return true;
		}

		if (formName) {
			const validator = this.formObjects.find((formValidator) => formValidator.element.id === formName);
			if (validator) {
				if (fieldName) {
					return validator.validate(fieldName);
				} else {
					return validator.validate();
				}
			}
			return false;
		}

		const validationResults: boolean[] = [];
		for (const form of this.formObjects.filter((x) => x && x.element)) {
			this.validationResult = form.validate(fieldName);
			validationResults.push(this.validationResult);
		}

		return validationResults.every((result) => result);
	}

	async validateBeforeSave(): Promise<boolean> {
		return (async () => {
			return this.guardedValidateForm(false);
		})();
	}

	public dropDownFields: object = {
		text: 'display',
		value: 'id',
	};

	refreshEntity() {
		// implement it in derived classes,
	}

	setEntity(validState: boolean, entity: any) {
		// implement it in derived classes, it should take place of setObject, setPerson, etc. methods.
	}

	clearView() {
		// implement it in derived classes
	}

	prepareView() {
		// implement it in derived classes
	}

	updateView(props: {}) {
		// implement it in derived classes
	}

	handleConflictingConnections(resolution: boolean) {
		this.proposedConnectionCanBeSaved = resolution;
		this.guardedValidateForm(false);
		this.refreshEntity();
	}
}

export default BaseView;
