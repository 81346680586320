import IdIdentifiable from './IdIdentifiable';
import StartPageEnum from './StartPageEnum';
import CustomerLevelEnum from './CustomerLevelEnum';

class Customer extends IdIdentifiable {
	constructor(
		defaultLanguageId?: string,
		defaultTimezoneId?: string,
		defaultStartPage?: StartPageEnum,
		defaultStartCountryId?: number,
		active?: boolean
	) {
		super();

		this.languageId = defaultLanguageId;
		this.timezoneId = defaultTimezoneId;
		this.startPage = defaultStartPage;
		this.startCountryId = defaultStartCountryId;
		this.active = active ?? true;
	}

	public code: string;
	public name: string;
	public description: string;
	public languageId: string;
	public startCountryId: number;
	public languageDisplay?: string;
	public timezoneId: string;
	public timezoneDisplay?: string;
	public startPage: StartPageEnum;
	public active: boolean;
	public canModifyActive: boolean;
	public customerType: CustomerLevelEnum;
	public isTest: boolean;
}

export default Customer;
