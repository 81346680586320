import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { ApplicationState } from '../../store';
import { SET_HELP_FILE_NAME } from '../../store/LeftSideBar';
import { StringConstants } from '../Widgets/CustomerFeaturesView';

export const knownHelpFiles = [
	'/live',
	'/history',
	'/locations',
	'/objects',
	'/persons',
	'/drivers',
	'/groups',
	'/reports',
	'/devices',
	'/keys',
	'/alerts',
	'/settings',
];
export const knownHelpClaimRoute: { [key: string]: string } = {
	live: 'live',
	history: 'history',
	locations: 'locations',
	objects: 'objects',
	persons: 'persons',
	drivers: 'drivers',
	groups: 'groups',
	reporting: 'reports',
	devices: 'devices',
	alerts: 'alerts',
	keys: 'keys',
};
export const knownHelpExtraFeaturesSettings: { [key: string]: string } = {
	drivers: 'driverIdentification',
};
export function getSupportedHelpPath(path: string): string {
	const helpPath = path.toLowerCase();
	return knownHelpFiles.find((localPath) => helpPath?.startsWith(localPath))?.replace('/', '');
}

const helpWindowSize = {
	width: 1300,
	height: 780,
};

export const openInNewTab = (url: string): void => {
	//open window centered on multiple monitor
	//http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
	const dualScreenLeft = window.screenLeft;
	const dualScreenTop = window.screenTop;
	const width = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
		? document.documentElement.clientWidth
		: screen.availWidth;
	const height = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
		? document.documentElement.clientHeight
		: screen.availHeight;
	const left = width / 2 - helpWindowSize.width / 2 + dualScreenLeft;
	const top = height / 2 - helpWindowSize.height / 2 + dualScreenTop;

	const newWindow = window.open(
		url,
		'_blank',
		'directories=no,titlebar=no,toolbar=no,location=no,status=no,scrollbars=no,resizable=no,menubar=no' +
			',width=' +
			helpWindowSize.width +
			',height=' +
			helpWindowSize.height +
			',left=' +
			left +
			',top=' +
			top
	);

	// Puts focus on the newWindow
	if (window.focus) {
		newWindow?.focus();
	}
};

const HelpButton = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const helpFileName = useSelector((state: ApplicationState) => state.leftSideBar.helpFileName);
	useEffect(() => {
		dispatch({ type: SET_HELP_FILE_NAME, payload: getSupportedHelpPath(pathname) });
	}, [pathname]);

	return (
		<>
			<IconButton
				hidden={!helpFileName}
				color={'primary'}
				onClick={() => {
					dispatch({ type: SET_HELP_FILE_NAME, payload: getSupportedHelpPath(pathname) });
					openInNewTab(StringConstants.helpRoute);
				}}
			>
				<Help />
			</IconButton>
		</>
	);
};

export default HelpButton;
