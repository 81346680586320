import IdIdentifiable from './IdIdentifiable';

export class StateData {
	public objectData: string;
	public deviceData: string;
	public person: string;
	public subscription: string;
	public simNumber: string;
	public messageContent: MessageContent = new MessageContent();
	public customerPrivateTrip: boolean;
	public customerTrackTypeSpecification: boolean;
}

export class MessageContent extends IdIdentifiable {
	public receivedDateTime: Date;
	public ignitionState: boolean;
	public satelliteCount: string;
	public validatedGpsPosition: boolean;
	public businessState: boolean;
	public location: string;
	public resultLastTest: string;
}
