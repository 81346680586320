import moment from 'moment-timezone';
import React from 'react';

import { Tooltip } from '@material-ui/core';

import PrivacySettingsEnum from '../../../../models/PrivacySettingsEnum';
import { HiddenTripDto, ProcessedTripDto } from '../../../../store/HistoryStore';
import { GetHourAndMinutes, GetHourMinutesAndDays, IsDifferentDay } from '../../../../utils/DateUtils';
import { secondsDifferenceTime, secondsToIntervalStringVisitTime } from '../../../Common/DateTimeUtilFunctions';
import ObjectNameWithTimeZoneOffset from '../../../ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';

export const getStartEndDates = (
	customerPrivacySettings: PrivacySettingsEnum,
	showPrivateData: boolean,
	nextTrip: ProcessedTripDto | HiddenTripDto,
	privateDataMask: string,
	trip: ProcessedTripDto | HiddenTripDto,
	personId: string
) => {
	if (
		(customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime ||
			customerPrivacySettings === PrivacySettingsEnum.HidePrivateData) &&
		!showPrivateData
	) {
		if (trip?.private && trip?.personId !== personId) {
			return (
				<span>
					{privateDataMask} {' - '}
					<span>{nextTrip ? GetHourMinutesAndDays(nextTrip.startTripTime, true) : ''}</span>
				</span>
			);
		}

		if (trip?.isNextTripPrivate && nextTrip?.personId !== personId) {
			return (
				<span>
					<span>{trip ? GetHourMinutesAndDays(trip?.endTripTime, true) : ''}</span>
					{' - '}
					{privateDataMask}
				</span>
			);
		}
	}
	return IsDifferentDay(
		moment(trip?.endTripTime).toDate(),
		nextTrip ? moment(nextTrip.startTripTime).toDate() : new Date()
	) ? (
		<span>
			{GetHourMinutesAndDays(trip?.endTripTime, true)} {' - '}
			<span>{nextTrip ? GetHourMinutesAndDays(nextTrip.startTripTime, true) : ''}</span>
		</span>
	) : (
		<span>
			{GetHourAndMinutes(trip?.endTripTime, true)} {' - '}
			<span>{nextTrip ? GetHourAndMinutes(nextTrip.startTripTime, true) : ''}</span>
		</span>
	);
};

export const getVisitDuration = (
	trip: ProcessedTripDto | HiddenTripDto,
	nextTrip: ProcessedTripDto | HiddenTripDto,
	showPrivateData: boolean,
	personId: string,
	customerPrivacySettings: PrivacySettingsEnum,
	privateDataMask: string
): string => {
	if (
		((trip?.isNextTripPrivate && nextTrip?.personId !== personId) ||
			(trip?.private && trip?.personId !== personId)) &&
		customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
		!showPrivateData
	) {
		return privateDataMask;
	}

	if (
		((trip?.isNextTripPrivate && nextTrip?.personId !== personId) ||
			(trip?.private && trip?.personId !== personId)) &&
		customerPrivacySettings === PrivacySettingsEnum.HidePrivateData &&
		!showPrivateData
	) {
		return null;
	}

	return secondsToIntervalStringVisitTime(
		secondsDifferenceTime(trip?.endTripTime, nextTrip?.startTripTime, trip.timeZoneMinutesOffset)
	);
};

export const getHiddenTripTitle = (
	trip: ProcessedTripDto | HiddenTripDto,
	nextTrip: ProcessedTripDto | HiddenTripDto,
	driverIdentification: boolean
) => {
	if (!trip) {
		return nextTrip.isPerson && driverIdentification ? (
			<Tooltip title={nextTrip?.personName}>
				<span>{nextTrip?.personName}</span>
			</Tooltip>
		) : (
			<ObjectNameWithTimeZoneOffset
				useToolTip
				objectNameWithTimeZone={trip.objectNameWithTimeZoneOffset}
				key={trip.objectNameWithTimeZoneOffset + trip.id}
			/>
		);
	}

	return trip?.isPerson && driverIdentification ? (
		<Tooltip title={trip?.personName}>
			<span>{trip?.personName}</span>
		</Tooltip>
	) : (
		<ObjectNameWithTimeZoneOffset
			useToolTip
			objectNameWithTimeZone={trip.objectNameWithTimeZoneOffset}
			key={trip.objectNameWithTimeZoneOffset + trip.id}
		/>
	);
};
