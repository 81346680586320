import { MultiSelectionColumnOption } from 'components/GridOverview/Columns/MultiSelectionColumn';
import { FieldFilter, GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import moment from 'moment';
import { DataTableFilterMetaData } from 'primereact/datatable';

export const capitalizeFirstLetter = (target: string): string => {
	if (!target) {
		return target;
	}
	return target.charAt(0).toUpperCase() + target.slice(1);
};

export const getRequestFilters = (
	gridFilters: GridFilters
): {
	sortField: string;
	sortOrder: SortOrder;
	flagOrder: string;
	fieldFilters: FieldFilter[];
	includeTime?: boolean;
} => {
	const fieldFilters: FieldFilter[] = [];
	if (gridFilters?.filters) {
		Object.keys(gridFilters.filters).forEach((key) => {
			const filter = (gridFilters.filters[key] as unknown) as DataTableFilterMetaData;
			const matchMode = filter.matchMode as MatchMode;
			let filterText = filter.value?.toString();
			if (filterText) {
				if (
					matchMode === MatchMode.DateAfter ||
					matchMode === MatchMode.DateBefore ||
					matchMode === MatchMode.DateIs ||
					matchMode === MatchMode.DateIsNot
				) {
					if (gridFilters.includeTime !== undefined && gridFilters.includeTime) {
						filterText = moment(filterText)
							.tz('UTC')
							.format('YYYY-MM-DD HH:mm');
					} else {
						filterText = moment(filterText)
							.startOf('day')
							.format('YYYY-MM-DD');
					}
				} else if (matchMode === MatchMode.In) {
					if (filter.value.length) {
						const filterValues = filter.value as MultiSelectionColumnOption[];
						filterText = filterValues.map((f: MultiSelectionColumnOption) => f.code).join(',');
					} else {
						filterText = filter.value.code;
					}
				}
				fieldFilters.push({ name: capitalizeFirstLetter(key), filter: filterText, matchMode });
			}
		});
	}

	return {
		sortOrder: gridFilters?.sortOrder,
		sortField: capitalizeFirstLetter(gridFilters?.sortField),
		flagOrder: gridFilters?.flagOrder,
		fieldFilters: fieldFilters,
		includeTime: gridFilters?.includeTime,
	};
};

export const getActiveFilter = (): FieldFilter => {
	return {
		name: 'Active',
		filter: 'true',
		matchMode: MatchMode.Equals,
	};
};
