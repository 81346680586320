import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Tooltip } from '@material-ui/core';

export type Row = {
	label?: string;
	icon?: any;
	content?: any;
	displayEllipsis?: boolean;
};

interface Props {
	title: string;
	rows: Row[];
	actionIcons?: any[];
}

const LiveExtendedMenuCard = (props: Props) => {
	const [rows, setRows] = useState<Row[]>([]);

	useEffect(() => {
		const notNullRows = props.rows.filter(
			(row) =>
				row.content !== null &&
				row.content !== undefined &&
				row.content.toString().length > 0 &&
				(row.icon || row.label)
		);
		setRows(notNullRows);
	}, [props.rows]);

	return (
		<>
			{(rows.length > 0 && rows.some((x) => x.content)) || props.actionIcons?.length > 0 ? (
				<div className={'extended-menu-card'}>
					<div style={{ flexGrow: 1, overflow: 'hidden' }}>
						<div className={'card-title'}>
							<span className={'title-content'}>{props.title}</span>
						</div>
						<div className={'card-content'}>
							{rows.map((row, index) => {
								return (
									<div key={index} className={'card-row display-flex'}>
										<Tooltip
											disableHoverListener={!row.displayEllipsis}
											title={row.displayEllipsis ? row.label : null}
										>
											<div className={`card-row-label ${!row.label ? 'icon' : ''}`}>
												{row.label ?? row.icon}
											</div>
										</Tooltip>
										<Tooltip title={row.icon ? row.content : ''}>
											<div
												className={`${
													row.label ? 'card-row-content' : 'card-row-close-content'
												} ${
													props.actionIcons && props.actionIcons.length > 0
														? 'with-icons'
														: ''
												}`}
											>
												{row.content}
											</div>
										</Tooltip>
									</div>
								);
							})}
						</div>
					</div>
					{props.actionIcons && props.actionIcons.length > 0 ? (
						<div className={'card-action-icons'}>
							{props.actionIcons.map((x) => {
								return (
									<div style={{paddingTop: x.paddingTop}}>
										{x.icon}
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			) : null}
		</>
	);
};

export default LiveExtendedMenuCard;
