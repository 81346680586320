import BaseConnection from './BaseConnection';

class ObjectPersonConnection extends BaseConnection {
	constructor(copyOf?: ObjectPersonConnection) {
		if (copyOf) {
			super(copyOf);
			this.objectId = copyOf.objectId;
			this.objectCode = copyOf.objectCode;
			this.personId = copyOf.personId;
			this.personCode = copyOf.personCode;
			this.personFirstName = copyOf.personFirstName;
			this.personLastName = copyOf.personLastName;
		} else {
			super(null);
		}
	}

	public objectId: string;
	public objectCode: string;
	public personId: string;
	public personCode: string;
	public personFirstName: string;
	public personLastName: string;
	public isDriver: boolean;
}

export default ObjectPersonConnection;
