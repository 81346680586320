import { useCallback, useRef } from 'react';

export const useClickAndDoubleClick = () => {
	const target = useRef<HTMLElement | null>(null);
	const timer = useRef<number | null>(null);

	const clickHandler = useCallback((currentTarget: HTMLElement, onClick: () => void, onDoubleClick: () => void) => {
		if (target.current === currentTarget) {
			if (timer.current) {
				clearTimeout(timer.current);
			}
			target.current = null;

			onDoubleClick();
		} else {
			target.current = currentTarget;
			timer.current = window.setTimeout(() => {
				if (timer.current) {
					clearTimeout(timer.current);
				}
				target.current = null;

				onClick();
			}, 400);
		}
	}, []);

	return clickHandler;
};
