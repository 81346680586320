import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { StringConstants } from '../Widgets/CustomerFeaturesView';
import RietveldTopBar from './TopBar';

interface Props {
	children?: React.ReactNode;
	isAuthenticated: boolean;
}

const Layout = (props: Props) => {
	const pathname = useLocation();
	return (
		!pathname.pathname.startsWith(StringConstants.helpRoute) &&
		!pathname.pathname.startsWith(StringConstants.deviceLogsRoute) && (
			<>
				{props.isAuthenticated ? (
					<>
						<RietveldTopBar />
						<div className="app-content">{props.children}</div>
					</>
				) : (
					<React.Fragment>
						<div>{props.children}</div>
					</React.Fragment>
				)}
			</>
		)
	);
};

export default Layout;
