import React from 'react';

import { Link } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import './MaterialCustomCard.scss';

interface Props {
	title: string;
	icon: any;
	description: string;
	tabText: string;
	changeTab: (value: number) => void;
	tabNumber: number;
}

const MaterialCustomCard = (props: Props) => {
	return (
		<Card className={'card'}>
			<CardContent className={'card-content'}>
				<div className={'card-content-container'}>
					<Typography variant="h5" className={'title'}>
						{props.title}
					</Typography>
					{props.icon}
					<Typography variant="body2" component="span" className={'description'}>
						{props.description}
					</Typography>
					<Link className={'link-option'} onClick={() => props.changeTab(props.tabNumber)}>
						{props.tabText}
					</Link>
				</div>
			</CardContent>
		</Card>
	);
};

export default MaterialCustomCard;
