import { useEffect, useRef } from 'react';

import { AlertNotification } from '../types';

export const useOnScreenView = (
	refEl: React.MutableRefObject<HTMLDivElement>,
	notification: AlertNotification,
	setNotificationAsSeen: (id: string) => void
) => {
	const viewTime = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		let observer: IntersectionObserver = null;

		if (!notification.isSeen) {
			observer = new IntersectionObserver(
				([entry]) => {
					viewTime.current && clearTimeout(viewTime.current);
					if (entry.isIntersecting) {
						viewTime.current = setTimeout(() => {
							setNotificationAsSeen(notification.id);
						}, 200);
					}
				},
				{
					threshold: 0.96,
				}
			);
			observer.observe(refEl.current);
		}

		return () => {
			observer?.disconnect();
			viewTime.current && clearTimeout(viewTime.current);
		};
	}, [notification.isSeen]);
};
