import './accordion.scss';

import React, { PropsWithChildren, useRef, useState } from 'react';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { CircularProgress } from '@material-ui/core';

import { AccordionSectionView } from './Accordion';

type Props = PropsWithChildren<AccordionSectionView>;

const AccordionSection = (props: Props) => {
	const [headerSelected, setSelected] = useState(false);

	return (
		<div className={`accordion-section ${props.className || ''} ${props.expanded ? 'expanded' : ''}`}>
			<div
				onClick={() => {
					props.toggleCallback(props.index, props.expanded);
				}}
				onFocus={() => setSelected(true)}
				onBlur={() => setSelected(false)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						props.toggleCallback(props.index, props.expanded);
					}
				}}
				className={headerSelected ? 'selected header' : 'header'}
				tabIndex={0}
			>
				{props.header}
				<>
					{props.operationInProgress && (
						<CircularProgress style={{ marginLeft: 5, marginTop: 12 }} size={12} color="secondary" />
					)}
				</>
			</div>
			<div className={'container'}>{props.children}</div>
		</div>
	);
};

export default AccordionSection;
