import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { enableRipple } from '@syncfusion/ej2-base';

import WidgetDashboard, {WidgetDashboardTransferableProps} from "../Widgets/Dashboard/WidgetDashboard";
import {availableCustomersActions} from "../../store/AvailableCustomers";
import {loadedEntityContextActionCreators} from "../../store/LoadedEntityContextData";
import InformationNotification from "../../models/InformationNotification";
import {TranslateText} from "../../utils/Translations";
import EntityTypeEnum from "../../models/EntityTypeEnum";
import GlobalSettings from "../../GlobalSettings.json";
import Widget, {CustomModel} from "../Widgets/Widget";
import {ApplicationState} from "../../store";
import ClaimType, {ClaimValue} from "../../authorization/ClaimType";
import NotificationInformationView from "../Widgets/NotificationInformationView";
import InformationNotificationLanguageView from "../Widgets/Views/InformationNotificationLanguageView";
import {LOGIN_REASON} from "../../Constants";


enableRipple(true);

type MatchProps = {
	match: { params: { [key: string]: string } };
};
type Props = WidgetDashboardTransferableProps & MatchProps & {};
const pathToOverview = '/informationNotifications';
const InformationNotificationDetails = (props: Props) => {

	const [currentTitle, setCurrentTitle] = useState<string>('');
	const [reloadInfo, setReloadInfo] = useState<boolean>(false);
	const showEditButton = useSelector((s: ApplicationState) =>
		s.oidc.user?.profile[ClaimType.InformationNotificationManage].includes(ClaimValue.edit)
	);
	const filterText = useSelector((state: ApplicationState) => state.globalCustomer.filterText.informationnotification);
	const [notificationType, setNotificationType] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedInformationNotificationContext(null));
		};
	}, []);

	const events = {
		handleDataSaved: () => {
			setReloadInfo(true);
		},
		handleDisplayedCallback: (notification: InformationNotification) => {
			setReloadInfo(false);
			setCurrentTitle(notification?.name);
			loadedEntityContextActionCreators.setLoadedInformationNotificationContext(notification);
		},
	};
	const setTitleWidget = () => {
		switch (notificationType) {
			case LOGIN_REASON:
				return TranslateText('informationNotificationOverview.content.loginReasons');
			default:
				return '';
		}
	}

	return(
		<WidgetDashboard
			goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.InformationNotification}s`)}
			history={props.history}
			currentRecordId={props.match.params.id}
			goBackToCallback={() => {
				props.history.push(pathToOverview, { id: props.match.params.id });
			}}
			urlPath={pathToOverview}
			breadcrumbText={currentTitle ? `${TranslateText('common.informationNotificationData')} > ${currentTitle}` : ''}
			filterText={filterText}
			entityType={EntityTypeEnum.InformationNotification}
		>
			<Widget
				position={{
					row: 0,
					col: 0,
					sizeX: 5,
					sizeY: 6,
				}}
				displayedCallback={events.handleDisplayedCallback}
				forceReload={reloadInfo}
				entityId={props.match.params.id}
				url={`${GlobalSettings.informationNotificationsMaintenanceApi}`}
				widgetTitle={TranslateText('informationNotificationOverview.information')}
				viewComponent={NotificationInformationView}
				setDashboardTitleCallback={(title: string) => setCurrentTitle(title)}
				showEditButton={showEditButton}
				setDataCallback={setNotificationType}
			/>

			 <Widget
				position={{
					row: 0,
					col: 5,
					sizeX: 7,
					sizeY: 6,
				}}
				displayedCallback={events.handleDisplayedCallback}
				forceReload={reloadInfo}
				entityId={props.match.params.id}
				url={`${GlobalSettings.informationNotificationsMaintenanceApi}/content`}
				widgetTitle={setTitleWidget()}
				viewComponent={InformationNotificationLanguageView}
				setDashboardTitleCallback={(title: string) => setCurrentTitle(title)}
				showEditButton={showEditButton}
				getDataCallBack={() => notificationType}
				getDataModelCallback={() =>  ({name: 'loginReasons', typeArray: false } as CustomModel)}
			/>
		</WidgetDashboard>
	);
}

export default InformationNotificationDetails;
