import './styles.scss';

import { Box, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { ThresholdProps } from './types';

const Threshold = ({
	id,
	name,
	initialValue,
	onChangeHandler,
	disabled = false,
	validationMessage,
}: ThresholdProps) => (
	<Box className="threshold-container">
		<TextField
			id={id}
			className="threshold"
			type="number"
			InputLabelProps={{
				shrink: true,
			}}
			InputProps={{
				inputProps: {
					min: 0,
				},
			}}
			name={name}
			value={initialValue.toString()}
			size="small"
			onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event.key === 'e' || event.key === '+' || event.key === '-') {
					event.preventDefault();
				}
			}}
			onChange={(arg: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeHandler(+arg.target.value)}
			disabled={disabled}
		/>
		<ValidationMessage result={validationMessage} />
	</Box>
);

export default Threshold;
