import './rightSidebarMenu.scss';

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import EntityTypeEnum from '../../models/EntityTypeEnum';
import {StateFilterEnum} from '../../models/LiveMenuUtils';
import {
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from '../../shared/effects/useShowLastEntities';
import {ApplicationState} from '../../store';
import {filterUtil, FleetEntityTypeFilter} from '../../store/FleetSelection';
import {LiveDataDispatcher, LiveDataResultDto} from '../../store/LiveData';
import ObjectFunctionEnum from "../../models/ObjectFunctionEnum";

const StateStringMapping: { [key: number]: StateFilterEnum } = {
	1: StateFilterEnum.Driving,
	4: StateFilterEnum.Idle,
	2: StateFilterEnum.Parked,
	8: StateFilterEnum.Unknown,
	16: StateFilterEnum.Alert,
};

export const LiveDataFilterUtil = {
	hiddenByText: (
		liveData: LiveDataResultDto,
		filterText: string,
		showLastDriverOfParkedObject: boolean,
		showLastObjectOfParkedDriver: boolean
	): boolean => {
		if (!filterText) {
			return false;
		}
		let text = `${liveData.objectName} ${liveData.personName}`;
		if (liveData.state === StateFilterEnum.Parked) {
			if (liveData.entityType === EntityTypeEnum.Object) {
				if (!showLastDriverOfParkedObject) {
					text = liveData.objectName;
				}
			} else {
				if (!showLastObjectOfParkedDriver) {
					text = liveData.personName;
				}
			}
		}
		return !filterUtil.textIncludes(text, filterText);
	},
	equalType: (type: string, entityFilter: FleetEntityTypeFilter, objectFunction?: ObjectFunctionEnum): boolean => {
		if(entityFilter === FleetEntityTypeFilter.object) {
			return type.toLowerCase() === EntityTypeEnum.Object.toLowerCase() && objectFunction === ObjectFunctionEnum.Full;
		}

		if (entityFilter === FleetEntityTypeFilter.asset && objectFunction !== null) {
			return type.toLowerCase() === EntityTypeEnum.Object.toLowerCase() && objectFunction === ObjectFunctionEnum.Asset;
		}

		if (entityFilter === FleetEntityTypeFilter.person || entityFilter === FleetEntityTypeFilter.driver) {
			//Handle special drivers = persons
			return [
				FleetEntityTypeFilter[FleetEntityTypeFilter.person],
				FleetEntityTypeFilter[FleetEntityTypeFilter.driver],
			].some((t) => t.toString().toLowerCase() === type?.toLowerCase());
		}

		return !!type && type.toLowerCase() === FleetEntityTypeFilter[entityFilter].toLowerCase();
	},
	hiddenByType: (liveData: LiveDataResultDto, filterType: FleetEntityTypeFilter) => {
		if (filterType === FleetEntityTypeFilter.all) {
			return false;
		}
		return !LiveDataFilterUtil.equalType(liveData.entityType, filterType, liveData.objectFunction);
	},
	hiddenByState: (livedata: LiveDataResultDto, state: StateFilterEnum) => {
		return !(StateStringMapping[livedata.state] & state);
	},
	filter: (
		data: LiveDataResultDto[],
		text: string,
		state: StateFilterEnum,
		entityType: FleetEntityTypeFilter,
		showLastDriverOfParkedObject: boolean,
		showLastObjectOfParkedDriver: boolean
	) => {
		return data.filter(
			(live) =>
				!LiveDataFilterUtil.hiddenByType(live, entityType) &&
				!LiveDataFilterUtil.hiddenByText(
					live,
					text,
					showLastDriverOfParkedObject,
					showLastObjectOfParkedDriver
				) &&
				!LiveDataFilterUtil.hiddenByState(live, state)
		);
	},
};

const useLiveFilterService = () => {
	const liveData = useSelector((s: ApplicationState) => s.liveData.liveEntitiesUnfiltered);
	const { text, stateType, entityType } = useSelector((s: ApplicationState) => s.liveDataFilter);
	const trackedEntities = useSelector((s: ApplicationState) => s.fleetSelection.trackedEntities);
	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();

	const dispatch = useDispatch();

	useEffect(() => {
		//filter live data by tracked entities
		const trackedLiveData = liveData.filter((item) =>
			trackedEntities.some((te) => item.entityId === te.id || te.items?.some((i) => i.id === item.entityId))
		);

		const newData = LiveDataFilterUtil.filter(
			trackedLiveData,
			text,
			stateType,
			entityType,
			showLastDriverOfParkedObject,
			showLastObjectOfParkedDriver
		);
		LiveDataDispatcher.setLiveEntities(dispatch, newData);
	}, [text, stateType, entityType, liveData, trackedEntities]);
};

export { useLiveFilterService };
