import ClaimType, {ClaimValue} from 'authorization/ClaimType';
import {ClaimUtil} from 'authorization/ClaimUtil';
import {AlertMessageTag} from 'components/AlertMessageTagsButton/types';
import AlertMessageViewer from 'components/Common/AlertMessageViewer/AlertMessageViewer';
import {ALERT_MAIL_SUBJECT_MAX_LENGTH} from 'Constants';
import {ContentState, convertToRaw, EditorState, Modifier} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import GlobalSettings from 'GlobalSettings.json';
import htmlToDraft from 'html-to-draftjs';
import EntityTypeEnum from 'models/EntityTypeEnum';
import MessageTargetEnum from 'models/MessageTargetEnum';
import MessageWrapper from 'models/MessageWrapper';
import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ApplicationState} from 'store';
import ajaxUtil from 'utils/Ajax';
import {convertToStringContentState} from 'utils/AlertUtils';
import {TranslateText, TranslateTextInterpolated} from 'utils/Translations';

import {Box, Button, InputLabel, List, ListItem, Switch, Tab, Tabs, TextField} from '@material-ui/core';
import {TabContext} from '@material-ui/lab';
import {TabComponent, TreeViewComponent} from '@syncfusion/ej2-react-navigations';

import MUIRichTextEditor from '../MuiRichTextEditor/MUIRichTextEditor';
import {TMUIRichTextEditorRef} from '../MuiRichTextEditor/types';
import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.oidc.user,
	};
}

interface AlertMessageVariable {
	key: string;
	description: string;
}

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	getAccessTokenCallback: () => string;
	renderForEntityType: EntityTypeEnum;
	renderForEntityTypeParams: Object[];
	useNotification: boolean;
	notificationContent?: MessageWrapper;
	usePopup: boolean;
	popupContent?: MessageWrapper;
	useEmail: boolean;
	emailContent?: MessageWrapper;
	useSms: boolean;
	smsContent?: MessageWrapper;
	useWhatsApp: boolean;
	whatsAppContent?: MessageWrapper;
	canRender: boolean;
	language?: string;
	useDefaultMessage: {
		notification: boolean;
		popup: boolean;
		mail: boolean;
		sms: boolean;
		whatsApp: boolean;
	};
	messageContentChangedCallback: (
		newContent: MessageWrapper,
		appliesTo: MessageTargetEnum,
		defaultContent: boolean
	) => void;
};

type State = {
	draggedVariableValue: string;
	showTestMessage: boolean;
	shouldDisplayNotificationError: boolean;
	shouldDisplayPopupError: boolean;
	shouldDisplaySmsError: boolean;
	shouldDisplayWhatsappError: boolean;
	shouldDisplayMailError: boolean;
	defaultNotificationContent: MessageWrapper;
	defaultPopupContent: MessageWrapper;
	defaultSmsContent: MessageWrapper;
	defaultMailContent: MessageWrapper;
	defaultWhatsappContent: MessageWrapper;
	currentMessageTab: MessageTargetEnum;
	currentMessageTabIndex: number;
	useDefaultNotificationMessage: boolean;
	defaultNotificationContentLoaded: boolean;
	useDefaultPopupMessage: boolean;
	defaultPopupContentLoaded: boolean;
	useDefaultSmsMessage: boolean;
	defaultSmsContentLoaded: boolean;
	useDefaultWhatsAppMessage: boolean;
	defaultWhatsAppContentLoaded: boolean;
	useDefaultMailMessage: boolean;
	defaultMailContentLoaded: boolean;
	alertMessageTags: AlertMessageVariable[];
};

class MessageEditor extends React.Component<Props, State> {
	tabComponentRef: TabComponent;
	previewRtfEditorRef: TMUIRichTextEditorRef;
	notificationEditorRef: TMUIRichTextEditorRef;
	popupEditorRef: TMUIRichTextEditorRef;
	smsEditorRef: TMUIRichTextEditorRef;
	whatsappEditorRef: TMUIRichTextEditorRef;
	mailEditorRef: TMUIRichTextEditorRef;
	mailSubjectRef: HTMLInputElement;

	constructor(props: Props) {
		super(props);
		this.state = {
			currentMessageTabIndex: 0,
			showTestMessage: false,
			draggedVariableValue: '',
			defaultNotificationContent: {
				...this.props.notificationContent,
				body: convertToStringContentState(this.props.notificationContent?.body),
			},
			defaultPopupContent: {
				...this.props.popupContent,
				body: convertToStringContentState(this.props.popupContent?.body),
			},
			defaultSmsContent: {
				...this.props.smsContent,
				body: convertToStringContentState(this.props.smsContent?.body),
			},
			defaultWhatsappContent: {
				...this.props.whatsAppContent,
				body: convertToStringContentState(this.props.whatsAppContent?.body),
			},
			defaultMailContent: {
				...this.props.emailContent,
				body: convertToStringContentState(this.props.emailContent?.body),
			},
			shouldDisplayNotificationError: false,
			shouldDisplayPopupError: false,
			shouldDisplaySmsError: false,
			shouldDisplayWhatsappError: false,
			shouldDisplayMailError: false,
			currentMessageTab: null,
			useDefaultNotificationMessage: true,
			defaultNotificationContentLoaded: false,
			useDefaultPopupMessage: true,
			defaultPopupContentLoaded: false,
			useDefaultMailMessage: true,
			defaultMailContentLoaded: false,
			useDefaultSmsMessage: true,
			defaultSmsContentLoaded: false,
			useDefaultWhatsAppMessage: true,
			defaultWhatsAppContentLoaded: false,
			alertMessageTags: [],
		};
	}

	variablesListRef: TreeViewComponent;

	variableComponent() {
		return (
			<Box
				className={"variables"}
				bgcolor={'lightgray'}
				width={'25%'}
				marginLeft={'10px'}
				style={{
					float: 'left',
				}}
			>
				<Box component={'span'} fontSize={'16px'} paddingTop={'6px'} paddingLeft={'10px'}>
					{TranslateText('messageEditor.variables')}
				</Box>
				<List style={{ maxHeight: '232px', overflow: 'auto' }}>
					{this.state.alertMessageTags &&
						this.state.alertMessageTags.map((data: AlertMessageVariable) => (
							<ListItem className={'variable-list-item'} key={data.key}>
								<span
									style={{
										height: '100%',
									}}
									draggable={true}
									onDragStart={(e) => {
										this.setState({
											draggedVariableValue: data.key,
										});
									}}
								>
									{TranslateText(data.description)}
								</span>
							</ListItem>
						))}
				</List>
			</Box>
		);
	}


	getDefaultMessage(force = false) {
		switch (this.props.renderForEntityType) {
			case EntityTypeEnum.Alert:
				this.getAlertDefaultMessage(force);
				break;

			default:
				break;
		}
	}

	getAlertDefaultMessage(force: boolean) {
		if (this.props.renderForEntityTypeParams === null ||
			this.props.renderForEntityTypeParams[0] === null ||
			this.props.renderForEntityTypeParams.length === 0 ||
			this.props.renderForEntityTypeParams[0] === 0) {
			return;
		}
		if (
			(this.state.currentMessageTab === MessageTargetEnum.Notification &&
				(!force || !this.state.useDefaultNotificationMessage) &&
				!this.state.useDefaultNotificationMessage) ||
			(this.state.currentMessageTab === MessageTargetEnum.Notification &&
				this.state.defaultNotificationContentLoaded)
		) {
			this.setState({
				defaultNotificationContentLoaded: false,
			});
		} else if (
			(this.state.currentMessageTab === MessageTargetEnum.Popup &&
				(!force || !this.state.useDefaultPopupMessage) &&
				!this.state.useDefaultPopupMessage) ||
			(this.state.currentMessageTab === MessageTargetEnum.Popup && this.state.defaultPopupContentLoaded)
		) {
			this.setState({ defaultPopupContentLoaded: false });
		} else if (
			(this.state.currentMessageTab === MessageTargetEnum.Mail &&
				(!force || !this.state.useDefaultMailMessage) &&
				!this.state.useDefaultMailMessage) ||
			(this.state.currentMessageTab === MessageTargetEnum.Mail && this.state.defaultMailContentLoaded)
		) {
			this.setState({ defaultMailContentLoaded: false });
		} else if (
			(this.state.currentMessageTab === MessageTargetEnum.Sms &&
				(!force || !this.state.useDefaultSmsMessage) &&
				!this.state.useDefaultSmsMessage) ||
			(this.state.currentMessageTab === MessageTargetEnum.Sms && this.state.defaultSmsContentLoaded)
		) {
			this.setState({ defaultSmsContentLoaded: false });
		} else if (
			(this.state.currentMessageTab === MessageTargetEnum.WhatsApp &&
				(!force || !this.state.useDefaultWhatsAppMessage) &&
				!this.state.useDefaultWhatsAppMessage) ||
			(this.state.currentMessageTab === MessageTargetEnum.WhatsApp && this.state.defaultWhatsAppContentLoaded)
		) {
			this.setState({
				defaultWhatsAppContentLoaded: false,
			});
		}

		const baseUrl =
			GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + this.props.renderForEntityTypeParams[0] + '/' ;

		if (force) {
			Object.keys(MessageTargetEnum).forEach((element) => {
				const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

				if (!this.props.renderForEntityTypeParams[0]) {
					return;
				}
				fetch(baseUrl + tempCurrentMessageTab + '/' + this.props.language, {
					method: 'get',
					headers: new Headers({
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
					}),
				}).then((response) => {
					if (!response.ok) {
						throw new Error(response.status.toString());
					}
					const contentType = response.headers.get('content-type');
					if (contentType && contentType.indexOf('application/json') !== -1) {
						return response.json().then((data) => {
							const sampleMarkup = data.body;
							const contentHTML = htmlToDraft(sampleMarkup);
							const state = ContentState.createFromBlockArray(
								contentHTML.contentBlocks,
								contentHTML.entityMap
							);
							const content = JSON.stringify(convertToRaw(state));
							switch (tempCurrentMessageTab) {
								case MessageTargetEnum.Notification:
									this.setState({
										defaultNotificationContent: {
											body: content,
											subject: '',
										},
										defaultNotificationContentLoaded: true,
									});
									this.props.messageContentChangedCallback(
										{
											body: data.body,
											subject: '',
										},
										MessageTargetEnum.Notification,
										this.state.useDefaultNotificationMessage
									);
									break;
								case MessageTargetEnum.Popup:
									this.setState({
										defaultPopupContent: {
											body: content,
											subject: '',
										},
										defaultPopupContentLoaded: true,
									});
									this.props.messageContentChangedCallback(
										{
											body: data.body,
											subject: '',
										},
										MessageTargetEnum.Popup,
										this.state.useDefaultPopupMessage
									);
									break;
								case MessageTargetEnum.Mail:
									this.setState({
										defaultMailContent: {
											body: content,
											subject: data.subject,
										},
										defaultMailContentLoaded: true,
									});
									if (this.mailSubjectRef) {
										this.mailSubjectRef.value = data.subject;
									}
									this.props.messageContentChangedCallback(
										{
											body: data.body,
											subject: data.subject,
										},
										MessageTargetEnum.Mail,
										this.state.useDefaultMailMessage
									);
									break;
								case MessageTargetEnum.Sms:
									this.setState({
										defaultSmsContent: {
											body: content,
											subject: '',
										},
										defaultSmsContentLoaded: true,
									});
									this.props.messageContentChangedCallback(
										{
											body: data.body,
											subject: '',
										},
										MessageTargetEnum.Sms,
										this.state.useDefaultSmsMessage
									);
									break;
								case MessageTargetEnum.WhatsApp:
									this.setState({
										defaultWhatsappContent: {
											body: content,
											subject: '',
										},
										defaultWhatsAppContentLoaded: true,
									});
									this.props.messageContentChangedCallback(
										{
											body: data.body,
											subject: '',
										},
										MessageTargetEnum.WhatsApp,
										this.state.useDefaultWhatsAppMessage
									);
									break;
								default:
									break;
							}
						});
					}
				});
			});
			return;
		}

		if (!this.state.currentMessageTab) {
			return;
		}
		fetch(baseUrl + this.state.currentMessageTab +'/'+ this.props.language, {
			method: 'get',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
			}),
		}).then((response) => {
			if (!response.ok) {
				throw new Error(response.status.toString());
			}

			const contentType = response.headers.get('content-type');
			if (contentType && contentType.indexOf('application/json') !== -1) {
				return response.json().then((data) => {
					const sampleMarkup = data.body;
					const contentHTML = htmlToDraft(sampleMarkup);
					const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
					const content = JSON.stringify(convertToRaw(state));
					switch (this.state.currentMessageTab) {
						case MessageTargetEnum.Notification:
							this.setState({
								defaultNotificationContent: {
									body: content,
									subject: '',
								},
								defaultNotificationContentLoaded: true,
							});
							this.props.messageContentChangedCallback(
								{
									body: data.body,
									subject: '',
								},
								MessageTargetEnum.Notification,
								this.state.useDefaultNotificationMessage
							);
							break;
						case MessageTargetEnum.Popup:
							this.setState({
								defaultPopupContent: {
									body: content,
									subject: '',
								},
								defaultPopupContentLoaded: true,
							});
							this.props.messageContentChangedCallback(
								{
									body: data.body,
									subject: '',
								},
								MessageTargetEnum.Popup,
								this.state.useDefaultPopupMessage
							);
							break;
						case MessageTargetEnum.Mail:
							this.setState({
								defaultMailContent: {
									body: content,
									subject: data.subject,
								},
								defaultMailContentLoaded: true,
							});
							if (this.mailSubjectRef) {
								this.mailSubjectRef.value = data.subject;
							}
							this.props.messageContentChangedCallback(
								{
									body: data.body,
									subject: data.subject,
								},
								MessageTargetEnum.Mail,
								this.state.useDefaultMailMessage
							);
							break;
						case MessageTargetEnum.Sms:
							this.setState({
								defaultSmsContent: {
									body: content,
									subject: '',
								},
								defaultSmsContentLoaded: true,
							});
							this.props.messageContentChangedCallback(
								{
									body: data.body,
									subject: '',
								},
								MessageTargetEnum.Sms,
								this.state.useDefaultSmsMessage
							);
							break;
						case MessageTargetEnum.WhatsApp:
							this.setState({
								defaultWhatsappContent: {
									body: content,
									subject: '',
								},
								defaultWhatsAppContentLoaded: true,
							});
							this.props.messageContentChangedCallback(
								{
									body: data.body,
									subject: '',
								},
								MessageTargetEnum.WhatsApp,
								this.state.useDefaultWhatsAppMessage
							);
							break;
						default:
							break;
					}
				});
			}
		});
	}

	getPreviewMessage() {
		const data: {
			subject: string;
			body: string;
			appliesTo: MessageTargetEnum;
			length: number;
		} = {
			subject: '',
			body: '',
			appliesTo: MessageTargetEnum.Notification,
			length: 0,
		};

		switch (this.state.currentMessageTab) {
			case MessageTargetEnum.Notification:
				data.body = this.props.notificationContent?.body;
				data.appliesTo = MessageTargetEnum.Notification;
				data.length = 500;
				break;
			case MessageTargetEnum.Popup:
				data.body = this.props.popupContent?.body;
				data.appliesTo = MessageTargetEnum.Popup;
				break;
			case MessageTargetEnum.Mail:
				data.subject = this.props.emailContent?.subject;
				data.body = this.props.emailContent?.body;
				data.appliesTo = MessageTargetEnum.Mail;
				data.length = 1500;
				break;
			case MessageTargetEnum.Sms:
				data.body = this.props.smsContent?.body;
				data.appliesTo = MessageTargetEnum.Sms;
				data.length = 156;
				break;
			case MessageTargetEnum.WhatsApp:
				data.body = this.props.whatsAppContent?.body;
				data.appliesTo = MessageTargetEnum.WhatsApp;
				data.length = 500;
				break;
			default:
				break;
		}

		return data;
	}

	handleNotificationPageContentChange(e: EditorState) {
		if (!e.getCurrentContent()) {
			return;
		}
		const htmlContent = new MessageWrapper();
		const codeBlock = convertToRaw(e.getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			htmlContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			htmlContent.body = draftToHtml(convertToRaw(e.getCurrentContent()));
			contentString = htmlContent.body;
		}
		this.props.messageContentChangedCallback(
			htmlContent,
			MessageTargetEnum.Notification,
			this.state.useDefaultNotificationMessage
		);

		this.setState({
			shouldDisplayNotificationError: this.notificationEditorRef && contentString.length <= size,
		});
	}

	renderNotificationPage() {
		return (
			<Box height={'202px'}>
				<MUIRichTextEditor
					ref={(ref) => {
						this.notificationEditorRef = ref;
					}}
					id={MessageTargetEnum.Notification}
					toolbar={true}
					defaultValue={this.state.defaultNotificationContent.body}
					onChange={this.handleNotificationPageContentChange.bind(this)}
					readOnly={this.state.useDefaultNotificationMessage}
					onDrop={(e: HTMLDivElement) => {
						setTimeout(() => {
							const editorState = this.notificationEditorRef.getEditorState();
							const currentContent = editorState.getCurrentContent();
							const currentSelection = editorState.getSelection();

							const newContentState = Modifier.replaceText(
								currentContent,
								currentSelection,
								this.state.draggedVariableValue + ' '
							);

							const newContent = new MessageWrapper();
							newContent.body = JSON.stringify(convertToRaw(newContentState));
							this.setState({
								defaultNotificationContent: newContent,
							});
						}, 100);
					}}
				/>
				<Box display="flex" justifyContent="left" marginTop={'10px'}>
					<InputLabel className={'validation-warning'}>
						{TranslateTextInterpolated('fieldsValidations.limitCharacters', [
							this.getPreviewMessage().length.toString(),
						])}
					</InputLabel>
				</Box>
			</Box>
		);
	}

	handlePopupPageContentChange(e: EditorState) {
		if (!e.getCurrentContent()) {
			return;
		}
		const htmlContent = new MessageWrapper();
		const codeBlock = convertToRaw(e.getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			htmlContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			htmlContent.body = draftToHtml(convertToRaw(e.getCurrentContent()));
			contentString = draftToHtml(convertToRaw(this.popupEditorRef.getEditorState().getCurrentContent()));
		}
		this.props.messageContentChangedCallback(
			htmlContent,
			MessageTargetEnum.Popup,
			this.state.useDefaultPopupMessage
		);
		this.setState({
			shouldDisplayPopupError:
				this.popupEditorRef &&
				contentString.length <= size,
		});
	}

	renderCommunicationPage(
		id: string,
		shouldDisplayError: boolean,
		renderCallback: () => JSX.Element,
		errorMessage = TranslateText('fieldsValidations.fieldRequired')
	) {
		return (
			<Box flex={1}>
				<Box id={id} paddingLeft={'0px'} height={'225px'}>
					{renderCallback()}
					{shouldDisplayError && (
						<Box className="e-error message-editor-error" id="errorMailContent" style={this.state.currentMessageTab !== MessageTargetEnum.Mail ? {marginTop: 35} : {}}>
							{errorMessage}
						</Box>
					)}
				</Box>
			</Box>
		);
	}

	renderPopupPage() {
		return (
			<Box height={'202px'}>
			<MUIRichTextEditor
				ref={(ref) => {
					this.popupEditorRef = ref;
				}}
				id={MessageTargetEnum.Popup}
				toolbar={true}
				defaultValue={this.state.defaultPopupContent.body}
				onChange={this.handlePopupPageContentChange.bind(this)}
				readOnly={this.state.useDefaultPopupMessage}
				onDrop={(e: HTMLDivElement) => {
					setTimeout(() => {
						const editorState = this.popupEditorRef.getEditorState();
						const currentContent = editorState.getCurrentContent();
						const currentSelection = editorState.getSelection();

						const newContentState = Modifier.replaceText(
							currentContent,
							currentSelection,
							this.state.draggedVariableValue + ' '
						);

						const newContent = new MessageWrapper();
						newContent.body = JSON.stringify(convertToRaw(newContentState));
						this.setState({
							defaultPopupContent: newContent,
						});
					}, 100);
				}}
			/>
			</Box>
		);
	}

	handleMailSubjectChange() {
		const newContent = new MessageWrapper();
		newContent.subject = this.mailSubjectRef.value;
		const codeBlock = convertToRaw(this.mailEditorRef.getEditorState().getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			newContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			newContent.body = this.mailEditorRef
				? draftToHtml(convertToRaw(this.mailEditorRef.getEditorState().getCurrentContent()))
				: '';
			contentString = newContent.body;
		}

		this.updateMailMessageStateOnChange(newContent, contentString, size);
	}

	handleMailPageContentChange(e: EditorState) {
		if (!e.getCurrentContent()) {
			return;
		}

		const newContent = new MessageWrapper();
		const codeBlock = convertToRaw(e.getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			newContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			newContent.body = draftToHtml(convertToRaw(e.getCurrentContent()));
			contentString = newContent.body;
		}
		newContent.subject = this.mailSubjectRef.value;

		this.updateMailMessageStateOnChange(newContent, contentString,size);
	}

	updateMailMessageStateOnChange(newContent: MessageWrapper, contentStringBody: string, size: number) {
		this.props.messageContentChangedCallback(newContent, MessageTargetEnum.Mail, this.state.useDefaultMailMessage);
		this.setState({
			shouldDisplayMailError:
				contentStringBody.length <= size ||
				newContent.subject.length === 0 ||
				newContent.subject.length > ALERT_MAIL_SUBJECT_MAX_LENGTH,
		});
	}
	updateMailMessageState(newContent: MessageWrapper) {
		this.props.messageContentChangedCallback(newContent, MessageTargetEnum.Mail, this.state.useDefaultMailMessage);
		this.setState({
			shouldDisplayMailError:
				newContent.body.length <= 8 ||
				newContent.subject.length === 0 ||
				newContent.subject.length > ALERT_MAIL_SUBJECT_MAX_LENGTH,
		});
	}

	renderMailPage() {
		return (
			<Box>
				<Box display={'flex'}>
					<Box component={'span'} fontWeight={'bold'} fontSize={14} paddingTop={'6px'} marginRight={'10px'}>
						{TranslateText('messageEditor.subject')}:
					</Box>
					<TextField
						inputRef={(ref) => {
							this.mailSubjectRef = ref;
						}}
						style={{ flex: '1', fontSize: '14px' }}
						disabled={this.state.useDefaultMailMessage}
						defaultValue={this.state.defaultMailContent?.subject ?? ''}
						className={'e-droppable'}
						id="messageSubjectEditor"
						onChange={this.handleMailSubjectChange.bind(this)}
						onDrop={() => {
							if (this.state.useDefaultMailMessage) {
								return;
							}

							const currentSelection = this.mailSubjectRef.selectionStart;
							const newContent = new MessageWrapper();
							newContent.subject =
								this.mailSubjectRef.value.substring(0, currentSelection) +
								this.state.draggedVariableValue +
								' ' +
								this.mailSubjectRef.value.substring(currentSelection);
							newContent.body = this.mailEditorRef
								? draftToHtml(convertToRaw(this.mailEditorRef.getEditorState().getCurrentContent()))
								: '';

							this.mailSubjectRef.value = newContent.subject;

							this.updateMailMessageState(newContent);
						}}
						onDragOver={(e) => {
							if (!this.state.useDefaultMailMessage) {
								e.preventDefault();
							}
						}}
					/>
				</Box>
				<Box height={'202px'}>
					<MUIRichTextEditor
						ref={(ref) => {
							this.mailEditorRef = ref;
						}}
						id={MessageTargetEnum.Mail}
						toolbar={true}
						defaultValue={this.state.defaultMailContent.body}
						onChange={this.handleMailPageContentChange.bind(this)}
						readOnly={this.state.useDefaultMailMessage}
						onDrop={(e: HTMLDivElement) => {
							setTimeout(() => {
								const editorState = this.mailEditorRef.getEditorState();
								const currentContent = editorState.getCurrentContent();
								const currentSelection = editorState.getSelection();

								const newContentState = Modifier.replaceText(
									currentContent,
									currentSelection,
									this.state.draggedVariableValue + ' '
								);

								const newContent = new MessageWrapper();
								newContent.body = JSON.stringify(convertToRaw(newContentState));
								newContent.subject = this.state.defaultMailContent.subject;
								this.setState({
									defaultMailContent: newContent,
								});
							}, 100);
						}}
					/>
				</Box>
				<Box display="flex" justifyContent="left" marginTop={'10px'}>
					<InputLabel className={'validation-warning'}>
						{TranslateTextInterpolated('fieldsValidations.limitCharacters', [
							this.getPreviewMessage().length.toString(),
						])}
					</InputLabel>
				</Box>
			</Box>
		);
	}

	handleSmsPageContentChange(e: EditorState) {
		if (!e.getCurrentContent()) {
			return;
		}
		const htmlContent = new MessageWrapper();
		const codeBlock = convertToRaw(e.getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			htmlContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			htmlContent.body = draftToHtml(convertToRaw(e.getCurrentContent()));
			contentString = draftToHtml(convertToRaw(this.smsEditorRef.getEditorState().getCurrentContent()));
		}
		this.props.messageContentChangedCallback(htmlContent, MessageTargetEnum.Sms, this.state.useDefaultSmsMessage);
		this.setState({
			shouldDisplaySmsError:
				this.smsEditorRef &&
				contentString.length <= size,
		});
	}

	renderSmsPage() {
		return (
			<Box height={'202px'}>
				<MUIRichTextEditor
					ref={(ref) => {
						this.smsEditorRef = ref;
					}}
					id={MessageTargetEnum.Sms}
					toolbar={true}
					defaultValue={this.state.defaultSmsContent.body}
					onChange={this.handleSmsPageContentChange.bind(this)}
					readOnly={this.state.useDefaultSmsMessage}
					onDrop={(e: HTMLDivElement) => {
						setTimeout(() => {
							const editorState = this.smsEditorRef.getEditorState();
							const currentContent = editorState.getCurrentContent();
							const currentSelection = editorState.getSelection();
							const newContentState = Modifier.replaceText(
								currentContent,
								currentSelection,
								this.state.draggedVariableValue + ' '
							);

							const newContent = new MessageWrapper();
							newContent.body = JSON.stringify(convertToRaw(newContentState));
							this.setState({
								defaultSmsContent: newContent,
							});
						}, 100);
					}}
				/>
				<Box display="flex" justifyContent="left" marginTop={'10px'}>
					<InputLabel className={'validation-warning'}>
						{TranslateTextInterpolated('fieldsValidations.limitCharacters', [
							this.getPreviewMessage().length.toString(),
						])}
					</InputLabel>
				</Box>
			</Box>
		);
	}

	handleWhatsAppPageContentChange(e: EditorState) {
		if (!e.getCurrentContent()) {
			return;
		}
		const htmlContent = new MessageWrapper();
		const codeBlock = convertToRaw(e.getCurrentContent());
		let contentString = '';
		let size = 8;
		if(codeBlock.blocks[0].type === 'code-block') {
			const htmlBlocks = codeBlock.blocks.map(this.convertBlockToHtml);
			contentString = codeBlock.blocks.map(x => x.text).join('');
			htmlContent.body = htmlBlocks.join('');
			size = 0;
		}
		else {
			htmlContent.body = draftToHtml(convertToRaw(e.getCurrentContent()));
			contentString = draftToHtml(convertToRaw(this.whatsappEditorRef.getEditorState().getCurrentContent()));
		}
		this.props.messageContentChangedCallback(
			htmlContent,
			MessageTargetEnum.WhatsApp,
			this.state.useDefaultWhatsAppMessage
		);
		this.setState({
			shouldDisplayWhatsappError:
				this.whatsappEditorRef &&
				contentString.length <= size,
		});
	}

	renderWhatsAppPage() {
		return (
			<Box height={'202px'}>
				<MUIRichTextEditor
					ref={(ref) => {
						this.whatsappEditorRef = ref;
					}}
					id={MessageTargetEnum.WhatsApp}
					toolbar={true}
					defaultValue={this.state.defaultWhatsappContent.body}
					onChange={this.handleWhatsAppPageContentChange.bind(this)}
					readOnly={this.state.useDefaultWhatsAppMessage}
					onDrop={(e: HTMLDivElement) => {
						setTimeout(() => {
							const editorState = this.whatsappEditorRef.getEditorState();
							const currentContent = editorState.getCurrentContent();
							const currentSelection = editorState.getSelection();

							const newContentState = Modifier.replaceText(
								currentContent,
								currentSelection,
								this.state.draggedVariableValue + ' '
							);

							const newContent = new MessageWrapper();
							newContent.body = JSON.stringify(convertToRaw(newContentState));
							this.setState({
								defaultWhatsappContent: newContent,
							});
						}, 100);
					}}
				/>
				<Box display="flex" justifyContent="left" marginTop={'10px'}>
					<InputLabel className={'validation-warning'}>
						{TranslateTextInterpolated('fieldsValidations.limitCharacters', [
							this.getPreviewMessage().length.toString(),
						])}
					</InputLabel>
				</Box>
			</Box>
		);
	}

	handleButtonToggle(e: any) {
		switch (this.state.currentMessageTab) {
			case MessageTargetEnum.Notification:
				this.setState({
					useDefaultNotificationMessage: !this.state.useDefaultNotificationMessage,
				});
				break;
			case MessageTargetEnum.Popup:
				this.setState({
					useDefaultPopupMessage: !this.state.useDefaultPopupMessage,
				});
				break;
			case MessageTargetEnum.Mail:
				this.setState({
					useDefaultMailMessage: !this.state.useDefaultMailMessage,
				});
				break;
			case MessageTargetEnum.Sms:
				this.setState({
					useDefaultSmsMessage: !this.state.useDefaultSmsMessage,
				});
				break;
			case MessageTargetEnum.WhatsApp:
				this.setState({
					useDefaultWhatsAppMessage: !this.state.useDefaultWhatsAppMessage,
				});
				break;
			default:
				break;
		}
		this.getDefaultMessage();
	}

	handleTabChange(e: any, value: number) {
		this.updateContents(this.state.currentMessageTabIndex);
		this.setState(
			{
				currentMessageTab: Object.values(MessageTargetEnum)[value] as MessageTargetEnum,
				currentMessageTabIndex: value,
			}
		);
	}

	convertBlockToHtml(block: any) {
		return `<pre><code>${block.text}</code></pre>`;
	}

	getDefaultToggleState() {
		switch (this.state.currentMessageTab) {
			case MessageTargetEnum.Notification:
				return !!this.state.useDefaultNotificationMessage;
			case MessageTargetEnum.Popup:
				return !!this.state.useDefaultPopupMessage;
			case MessageTargetEnum.Mail:
				return !!this.state.useDefaultMailMessage;
			case MessageTargetEnum.Sms:
				return !!this.state.useDefaultSmsMessage;
			case MessageTargetEnum.WhatsApp:
				return !!this.state.useDefaultWhatsAppMessage;
			default:
				return false;
		}
	}

	setInitialSelectedTab() {
		if (this.allowNotification()) {
			this.setState({
				currentMessageTab: MessageTargetEnum.Notification,
				currentMessageTabIndex: 0,
			});
			return;
		}

		if (this.allowPopup()) {
			this.setState({
				currentMessageTab: MessageTargetEnum.Popup,
				currentMessageTabIndex: 1,
			});
			return;
		}

		if (this.allowMail()) {
			this.setState({
				currentMessageTab: MessageTargetEnum.Mail,
				currentMessageTabIndex: 2,
			});
			return;
		}

		if (this.allowSms()) {
			this.setState({
				currentMessageTab: MessageTargetEnum.Sms,
				currentMessageTabIndex: 3,
			});
			return;
		}

		if (this.allowWhatsApp()) {
			this.setState({
				currentMessageTab: MessageTargetEnum.WhatsApp,
				currentMessageTabIndex: 4,
			});
			return;
		}
	}
	componentDidMount() {
		this.setInitialSelectedTab();
		setTimeout(() => {
			this.getDefaultMessage(true);
		}, 500);
	}
	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.renderForEntityTypeParams[0] !== this.props.renderForEntityTypeParams[0]) {
			this.setState(
				{
					useDefaultWhatsAppMessage: true,
					useDefaultSmsMessage: true,
					useDefaultMailMessage: true,
					useDefaultPopupMessage: true,
					useDefaultNotificationMessage: true,
				},
				() => {
					this.getDefaultMessage(true);
				}
			);

			const alertType = this.props.renderForEntityTypeParams[0] as string;
			if (alertType) {
				ajaxUtil
					.get<AlertMessageTag>(`${GlobalSettings.alertsApi}/getAlertTagGroup?alertType=${alertType}`)
					.then((data) => {
						const newAlertTags: AlertMessageVariable[] = [];
						if (data) {
							Object.keys(data).forEach((alertMessageTagKey: string) => {
								data[alertMessageTagKey].forEach((groupVariable: string) => {
									newAlertTags.push({
										key: groupVariable,
										description: TranslateText(`variable.${groupVariable}`),
									});
								});
							});
						}

						newAlertTags.sort((a, b) =>
							TranslateText(a.description).localeCompare(TranslateText(b.description))
						);

						this.setState({ alertMessageTags: newAlertTags });
					});
			}
		}

		if (
			(prevProps.useNotification !== this.props.useNotification ||
			prevProps.usePopup !== this.props.usePopup ||
			prevProps.useSms !== this.props.useSms ||
			prevProps.useEmail !== this.props.useEmail ||
			prevProps.useWhatsApp !== this.props.useWhatsApp)
		) {
			this.setInitialSelectedTab();
			setTimeout(() => {
				this.getDefaultMessage();
			}, 100);
		}
		if(prevProps.language !== this.props.language)
		{
			this.updateAllContents();
		}
	}

	updateContents(value: number) {
		switch (value){
			case 0:
				this.setState(
					{
						defaultNotificationContent: {
							...this.props.notificationContent,
							body: convertToStringContentState(this.props.notificationContent?.body),
					}
				});
				break;
			case 1:
				this.setState({
					defaultPopupContent: {
						...this.props.popupContent,
						body: convertToStringContentState(this.props.popupContent?.body),
					}
				});
				break;
			case 2:
				this.mailSubjectRef.value = this.props.emailContent.subject;
				this.setState({
					defaultMailContent: {
						...this.state.defaultMailContent, subject: this.mailSubjectRef.value
					},
				});
				this.setState({
					defaultMailContent: {
						...this.props.emailContent,
						body: convertToStringContentState(this.props.emailContent?.body),
					},
				});
				break;
			case 3:
				this.setState({
					defaultSmsContent: {
						...this.props.smsContent,
						body: convertToStringContentState(this.props.smsContent?.body),
					},
				});
				break;
			case  4:
				this.setState({
					defaultWhatsappContent: {
						...this.props.whatsAppContent,
						body: convertToStringContentState(this.props.whatsAppContent?.body),
					},
				});
				break;
		}
	}

	updateAllContents() {
		this.setState(
			{
				defaultNotificationContent: {
					...this.props.notificationContent,
					body: convertToStringContentState(this.props.notificationContent?.body),
				}
			});
		this.setState({
			defaultPopupContent: {
				...this.props.popupContent,
				body: convertToStringContentState(this.props.popupContent?.body),
			}
		});
		if(this.mailSubjectRef) {
			this.mailSubjectRef.value = this.props.emailContent?.subject;
			this.setState({
				defaultMailContent: {
					...this.state.defaultMailContent, subject: this.mailSubjectRef.value
				},
			});
		}
		this.setState({
			defaultMailContent: {
				...this.props.emailContent,
				body: convertToStringContentState(this.props.emailContent?.body),
			},
		});
		this.setState({
			defaultSmsContent: {
				...this.props.smsContent,
				body: convertToStringContentState(this.props.smsContent?.body),
			},
		});
		this.setState({
			defaultWhatsappContent: {
				...this.props.whatsAppContent,
				body: convertToStringContentState(this.props.whatsAppContent?.body),
			},
		});
	}
	allowNotification() {
		return (
			this.props.useNotification &&
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Alerts,
				values: [ClaimValue.notificationAlert],
			})
		);
	}

	allowPopup() {
		return this.props.usePopup;
	}

	allowMail() {
		return (
			this.props.useEmail &&
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Alerts,
				values: [ClaimValue.mailAlert],
			})
		);
	}

	allowSms() {
		return (
			this.props.useSms &&
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Alerts,
				values: [ClaimValue.smsAlert],
			})
		);
	}

	allowWhatsApp() {
		return (
			this.props.useWhatsApp &&
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Alerts,
				values: [ClaimValue.whatsAppAlert],
			})
		);
	}

	renderNotification() {
		return (
			this.allowNotification() &&
			this.state.currentMessageTabIndex === 0 &&
			this.renderCommunicationPage(
				MessageTargetEnum.Notification,
				this.state.shouldDisplayNotificationError,
				this.renderNotificationPage.bind(this)
			)
		);
	}

	renderPopup() {
		return (
			this.allowPopup() &&
			this.state.currentMessageTabIndex === 1 &&
			this.renderCommunicationPage(
				MessageTargetEnum.Popup,
				this.state.shouldDisplayPopupError,
				this.renderPopupPage.bind(this)
			)
		);
	}

	renderMail() {
		return (
			this.allowMail() &&
			this.state.currentMessageTabIndex === 2 &&
			this.renderCommunicationPage(
				MessageTargetEnum.Mail,
				this.state.shouldDisplayMailError,
				this.renderMailPage.bind(this),
				this.mailSubjectRef?.value?.length < ALERT_MAIL_SUBJECT_MAX_LENGTH
					? TranslateText('fieldsValidations.fieldRequired')
					: TranslateTextInterpolated('fieldsValidations.maxLengthMailSubject', [
							ALERT_MAIL_SUBJECT_MAX_LENGTH.toString(),
					  ])
			)
		);
	}

	renderSms() {
		return (
			this.allowSms() &&
			this.state.currentMessageTabIndex === 3 &&
			this.renderCommunicationPage(
				MessageTargetEnum.Sms,
				this.state.shouldDisplaySmsError,
				this.renderSmsPage.bind(this)
			)
		);
	}

	renderWhatsApp() {
		return (
			this.allowWhatsApp() &&
			this.state.currentMessageTabIndex === 4 &&
			this.renderCommunicationPage(
				MessageTargetEnum.WhatsApp,
				this.state.shouldDisplayWhatsappError,
				this.renderWhatsAppPage.bind(this)
			)
		);
	}

	render() {
		return this.props.canRender ? (
			<Box id="messageEditor" width={'100%'}>
				<Box className={"text-editor"} width={'74%'} style={{ float: 'left' }}>
					<label
						htmlFor="default"
						className="e-enabled"
						style={{ padding: '0px 110px 0px 0', marginBottom: 0 }}
					>
						{TranslateText('messageEditor.default')}:{' '}
					</label>
					<Switch onChange={this.handleButtonToggle.bind(this)} checked={this.getDefaultToggleState()} />
					<TabContext value={'0'}>
						<Box display={'flex'} flexGrow={1} bgcolor={'#FFF'} minHeight={'300px'}>
							<Tabs
								orientation={'vertical'}
								variant={'scrollable'}
								onChange={this.handleTabChange.bind(this)}
								value={this.state.currentMessageTabIndex}
								TabIndicatorProps={{ style: { display: 'none'} }}
								style={this.state.currentMessageTab !== MessageTargetEnum.Mail ? {minWidth: 50, paddingTop: 8} : {minWidth: 50, paddingTop: 38}}
							>
								<Tab
									hidden={!this.allowNotification()}
									icon={<ChatIcon />}
									style={{
										backgroundColor: this.state.currentMessageTabIndex === 0 ? '#cccccc' : 'inherit',
									}}
									className={'tab-button'}
								/>
								<Tab hidden={!this.allowPopup()}
									 label={TranslateText('messageEditor.popUp')}
									 className={'tab-button'}
									 style={{
										 backgroundColor: this.state.currentMessageTabIndex === 1 ? '#cccccc' : 'inherit'
									 }}
								/>
								<Tab hidden={!this.allowMail()}
									 icon={<EmailIcon />}
									 className={'tab-button'}
									 style={{
										 backgroundColor: this.state.currentMessageTabIndex === 2 ? '#cccccc' : 'inherit'
									 }}
								/>
								<Tab hidden={!this.allowSms()}
									 icon={ <SmsIcon />}
									 className={'tab-button'}
									 style={{
										 backgroundColor: this.state.currentMessageTabIndex === 3 ? '#cccccc' : 'inherit'
									 }}
								/>
								<Tab hidden={!this.allowWhatsApp()}
									 icon={<WhatsAppIcon />}
									 className={'tab-button'}
									 style={{
										 backgroundColor: this.state.currentMessageTabIndex === 4 ? '#cccccc' : 'inherit'
									 }}
								/>
							</Tabs>
							{this.renderNotification()}
							{this.renderPopup()}
							{this.renderMail()}
							{this.renderSms()}
							{this.renderWhatsApp()}
						</Box>
					</TabContext>
				</Box>
				{this.variableComponent()}

				{this.state.showTestMessage ? (
					<AlertMessageViewer
						showDialog={this.state.showTestMessage}
						setShowDialog={(showDialog) => {
							this.setState({ showTestMessage: showDialog });
						}}
						{...this.getPreviewMessage()}
					/>
				) : null}

				<Button
					className={'primary-button'}
					onClick={() => this.setState({ showTestMessage: true })}
					style={{
						float: 'right',
						marginTop: '10px',
						marginRight: '15px',
						textTransform: 'none',
					}}
				>
					{TranslateText('messageEditor.showTestMessage')}
				</Button>
			</Box>
		) : null;
	}

	select(e: any) {
		//prevent swipe with mouse
		if (e.isSwiped) {
			e.cancel = true;
		}
	}
}

export default connector(MessageEditor);
