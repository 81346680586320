import { getCustomer, getGlobalCustomer } from '../';
import CustomerLevelEnum from '../models/CustomerLevelEnum';
import { SelectedCustomer } from '../store/GlobalCustomer';

export const GetSelectedCustomer = (): SelectedCustomer => {
	const currentCustomer = getCustomer();
	if (!currentCustomer) {
		return null;
	}

	const customer: SelectedCustomer = {
		id: currentCustomer.id,
		active: currentCustomer.active,
		code: currentCustomer.code,
		level: currentCustomer.customerType as CustomerLevelEnum,
		timeZone: currentCustomer.timezoneId,
		featuresSettings: currentCustomer.featuresSettings,
		reportsSettings: currentCustomer.reportsSettings,
		entityDefaultsSettings: currentCustomer.entityDefaultsSettings,
		fleetOverviewSettings: currentCustomer.fleetOverviewSettings,
		historySettings: currentCustomer.customerHistorySettings,
		customerLiveSettings: currentCustomer.customerLiveSettings,
		startCountryId: currentCustomer.startCountryId,
	};

	if (customer.level !== CustomerLevelEnum.Default) {
		const globalCustomer = getGlobalCustomer();
		return globalCustomer.filteredCustomer ? globalCustomer.filteredCustomer : customer;
	}

	return customer;
};
