import TowingEventTypesEnum from "./TowingEventTypesEnum";

class MainPowerAlertMetadata {
	constructor() {
		this.towingTriggerEvent = TowingEventTypesEnum.Both;
	}
	public towingTriggerEvent?: TowingEventTypesEnum;
	[propName: string]: any;
}

export default MainPowerAlertMetadata;
