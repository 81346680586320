import { validateWorkingDay } from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import MonitoringPeriodEnum from 'models/MonitoringPeriodEnum';
import { WorkingDay } from 'models/WorkingDay';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

export const getTimeScheduleValidationRules = (): FieldRules => {
	const fieldRules: FieldRules = {
		monitoringPeriod: {
			rules: {
				required: ValidatorFunctions.requiredInEnum(MonitoringPeriodEnum),
			},
		},
		timeSchedule: {
			rules: {
				requiredSchedule: {
					message: TranslateText('hoursSchedule.noDaySelectedWarning'),
					validationFunction: (data: any) => {
						const timeScheduleData = data as { timeSchedule: WorkingDay[] };
						return ValidatorFunctions.wrapInPromise(
							timeScheduleData?.timeSchedule && timeScheduleData.timeSchedule.length > 0
						);
					},
				},
				endNotGreaterStart: {
					message: TranslateText('hoursSchedule.endTimeNotGreaterStartTimeError'),
					validationFunction: (data: any) => {
						const timeScheduleData = data as { timeSchedule: WorkingDay[] };
						return ValidatorFunctions.wrapInPromise(
							!timeScheduleData?.timeSchedule ||
								!timeScheduleData.timeSchedule.some((x) => !validateWorkingDay(x))
						);
					},
				},
			},
		},
	};
	return fieldRules;
};
