import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const FileUploadIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 24 24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
		</SvgIcon>
	);
};

export default React.memo(FileUploadIcon);
