export type SensorTemplates = {
	templateId: string;
	sortName: string;
	templateName: string;
	rank: number;
	stateName: string;
	labelColor: string;
	iconName?: string;
	sensorTemplateType: SensorTemplateEnum;
};

export enum SensorTemplateEnum {
	Event = 'Event',
	Digital = 'Digital',
	Analog = 'Analog',
}
