import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { secondsToIntervalStringVisitTime } from 'components/Common/DateTimeUtilFunctions';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import { TripTypeHelper } from 'models/TripTypesEnum';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { ProcessedTripDto } from 'store/HistoryStore';
import { FormatDate } from 'utils/DateUtils';
import { getTripVisitTime } from 'utils/TripUtils';

import { VisibleTripInfo } from './types';

const privateDataMask = '****';

const useTripInfo = (historyTrip: ProcessedTripDto) => {
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);
	const showLocationsHistory = useLocationsHistory();

	const getVisibleTripInfo = () => {
		const tripInfo = {} as VisibleTripInfo;
		tripInfo.id = historyTrip.id;
		tripInfo.isInProgress = historyTrip.isInProgress;
		tripInfo.isNonTrip = historyTrip.isNonTrip;
		tripInfo.direction = historyTrip.direction?.toLowerCase();
		tripInfo.color = historyTrip.tripColor;
		tripInfo.index = historyTrip.index;
		tripInfo.timeZoneMinutesOffset = historyTrip.timeZoneMinutesOffset;
		tripInfo.timezoneId = historyTrip.timezoneId;
		tripInfo.number = historyTrip.isInProgress ? '-' : (historyTrip.index + 1).toString();
		tripInfo.startTime =
			customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime && !historyTrip.startTripTime
				? privateDataMask
				: FormatDate(historyTrip.startTripTime, false, false, true);
		tripInfo.endTime = historyTrip.isInProgress
			? ''
			: customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime && !historyTrip.endTripTime
			? privateDataMask
			: FormatDate(historyTrip.endTripTime, false, false, true);
		tripInfo.objectName = historyTrip.objectName;
		tripInfo.startAddress = showLocationsHistory ? historyTrip.startLocationAddress : historyTrip.startAddress;
		tripInfo.visitTime = getTripVisitTime(historyTrip, customerPrivacySettings, personId, userCanSeePrivateData);
		tripInfo.personName = !historyTrip.isNonTrip ? historyTrip.personName : '';
		tripInfo.endAddress = !historyTrip.isNonTrip
			? showLocationsHistory
				? historyTrip.endLocationAddress
				: historyTrip.endAddress
			: '';

		if (!historyTrip.isNonTrip) {
			tripInfo.trackType = TripTypeHelper.toString(historyTrip.trackType);
			tripInfo.drivingTime =
				customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
				!historyTrip.endTripTime &&
				!historyTrip.startTripTime
					? privateDataMask
					: secondsToIntervalStringVisitTime(
							moment(historyTrip.endTripTime).diff(moment(historyTrip.startTripTime)) / 1000
					  );

			tripInfo.startMileage = Math.floor(historyTrip.startMileage).toString();
			tripInfo.endMileage = !historyTrip.isInProgress ? Math.floor(historyTrip.endMileage).toString() : '';
			tripInfo.distance = (historyTrip.endMileage - historyTrip.startMileage).toFixed(1);
			tripInfo.maxSpeed =
				!historyTrip.isInProgress && historyTrip.maxSpeed ? historyTrip.maxSpeed.toFixed(0) : '';
		} else {
			tripInfo.trackType = '';
			tripInfo.drivingTime = '';
			tripInfo.startMileage = '';
			tripInfo.endMileage = '';
			tripInfo.distance = '';
			tripInfo.maxSpeed = '';
		}

		return tripInfo;
	};

	const trip = getVisibleTripInfo();

	return { trip };
};

export { useTripInfo };
