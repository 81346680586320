import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { enableRipple } from '@syncfusion/ej2-base';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import { availableCustomersActions } from '../../store/AvailableCustomers';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import ButtonWidget from '../Widgets/ButtonWidget';
import AddGroupAuthorizationButton from '../Widgets/ButtonWidget/AddGroupAuthorizationButton';
import AddGroupMembersButton from '../Widgets/ButtonWidget/AddGroupMembersButton';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import GroupInformationView from '../Widgets/GroupInformationView';
import GroupAuthorizationView from '../Widgets/Views/GroupAuthorizationView';
import GroupMembersView from '../Widgets/Views/GroupMembersView';
import Widget from '../Widgets/Widget';

enableRipple(true);

const pathToOverview = '/groups';

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type Props = WidgetDashboardTransferableProps & MatchProps & {};

const GroupDetails = (props: Props) => {
	const [currentTitle, setCurrentTitle] = useState<string>('');

	const filterText = useSelector((state: ApplicationState) => state.globalCustomer.filterText.groups);
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedGroupContext(null));
		};
	}, []);

	return (
		<WidgetDashboard
			history={props.history}
			breadcrumbText={currentTitle ? `${TranslateText('common.group')} > ${currentTitle}` : ''}
			goBackText={TranslateText('detailsScreen.navigationBar.backToGroups')}
			currentRecordId={props.match.params.id}
			urlPath={pathToOverview}
			filterText={filterText}
			goBackToCallback={() => {
				props.history.push('/groups', { id: props.match.params.id });
			}}
			entityType={EntityTypeEnum.Group}
		>
			<Widget
				position={{
					row: 0,
					col: 0,
					sizeX: 4,
					sizeY: 5,
				}}
				entityId={props.match.params.id}
				url={`${GlobalSettings.groupsMaintenanceApi}`}
				widgetTitle={TranslateText('common.information')}
				viewComponent={GroupInformationView}
				setDashboardTitleCallback={(title: string) => setCurrentTitle(title)}
				showEditButton={ClaimUtil.validateClaim(user, {
					claim: ClaimType.Groups,
					values: [ClaimValue.edit],
				})}
			/>
			<ProtectedContainer optionalClaimList={[ClaimType.Objects, ClaimType.Drivers, ClaimType.Persons]}>
				<ButtonWidget
					position={{
						row: 0,
						col: 4,
						sizeX: 5,
						sizeY: 5,
					}}
					allowEditMode={true}
					entityId={props.match.params.id}
					widgetTitle={TranslateText('common.groupMembers')}
					viewComponent={GroupMembersView}
					buttons={[AddGroupMembersButton]}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Groups,
						values: [ClaimValue.edit],
					})}
				/>
			</ProtectedContainer>
			<ProtectedContainer
				claimConfig={[
					{ claim: ClaimType.Groups, values: [ClaimValue.edit] },
					{ claim: ClaimType.Persons, values: [ClaimValue.edit] },
				]}
			>
				<ButtonWidget
					position={{
						row: 0,
						col: 4,
						sizeX: 5,
						sizeY: 5,
					}}
					allowEditMode={true}
					entityId={props.match.params.id}
					widgetTitle={TranslateText('common.personGroupsAuthorization')}
					viewComponent={GroupAuthorizationView}
					buttons={[AddGroupAuthorizationButton]}
					showEditButton={ClaimUtil.validateClaims(user, [
						{
							claim: ClaimType.Groups,
							values: [ClaimValue.edit],
						},
						{
							claim: ClaimType.Persons,
							values: [ClaimValue.edit],
						},
					])}
				/>
			</ProtectedContainer>
		</WidgetDashboard>
	);
};

export default GroupDetails;
