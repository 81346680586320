import './AlertView.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { ALERT_MAIL_SUBJECT_MAX_LENGTH } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import Alert from 'models/Alert';
import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AlertDigitalTriggerConditionEnum from 'models/AlertDigitalTriggerConditionEnum';
import AlertEntityTypeEnum from 'models/AlertEntityTypeEnum';
import AlertTypesEnum from 'models/AlertTypesEnum';
import AnalogTemplateItem from 'models/AnalogTemplateItem';
import EntityTypeEnum from 'models/EntityTypeEnum';
import EventFrequencyEnum from 'models/EventFrequencyEnum';
import FilterEntityType from 'models/FilterEntityType';
import MessageTargetEnum from 'models/MessageTargetEnum';
import MessageWrapper from 'models/MessageWrapper';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import SelectionItem from 'models/SelectionItem';
import TripTypesEnum from 'models/TripTypesEnum';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { getAnalogTriggerConditions, getDigitalTriggerConditions } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import DurationExceededAlertMetadata from '../../models/DurationExceededAlert';
import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import MessageEditor from '../MessageEditor';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import TreeSelectionView from '../TreeSelectionView';
import { EntityTypeFilter, TreeViewFilter } from '../TreeSelectionView/TreeSelectionView';
import { AlertDurationExceeded } from './AlertDurationExceeded';
import { EvaluationMomentEnum } from './AlertDurationExceeded/TimeFrameEvaluation/EvaluationMomentDropdown/types';
import { getDurationForSelectedTimeFrame } from './AlertDurationExceeded/TimeFrameEvaluation/hooks';
import { AlertNotificationType } from './AlertNotificationTypes/AlertNotificationTypes';
import { getAlertFrequencyDatasource } from './AlertsConstants';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

const mapState = (state: ApplicationState) => {
	return {
		entityDefaultsSettings: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.entityDefaultsSettings
			: state.currentSession.customer.entityDefaultsSettings,
		availableCustomers: state.availableCustomers.data,
		featureSettings: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer.featuresSettings,
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification,
		assetTracking: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer.featuresSettings.assetTracking,
		user: state.oidc.user,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	renderFor: RenderForEnum;
	readOnly: boolean;
	notifyNavigationCallback?: (valid: boolean, currentStep: number) => void;
	setMaxStepCount?: (maxStepCount: number) => void;
	currentStep: number;
	getAccessTokenCallback: () => string;
	setAlert?: (valid: boolean, alert: Alert) => void;
	widgetState: WidgetStateEnum;
	informationWidget: boolean;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
	allowTrackTypeSpecification: boolean;
	alert?: Alert;
	driverIdentification: boolean;
	disableNavigation: () => void;
};

interface State {
	alert: Alert;
	currentStep: number;
	expandedAccordionSection: number;
	useDefaultMessage: {
		notification: boolean;
		popup: boolean;
		mail: boolean;
		sms: boolean;
		whatsApp: boolean;
	};
	alertEntityTypes: EntityTypeFilter[];
	validationResult?: ValidationResult;
	isLoaded: boolean;
	analogTemplates: AnalogTemplateItem[];
	analogTemplatesLoaded: boolean;
	digitalTemplates: AutocompleteItem[];
	digitalTemplatesLoaded: boolean;
	analogStatesTemplate: AutocompleteItem[];
}

export class AlertView extends BaseView<Props, State> {
	validator: Validator;
	personsStepRef: React.RefObject<{ forceRefetchData: () => Promise<void> }>;
	public static defaultProps = {
		readOnly: false,
		informationWidget: false,
		currentStep: 1,
		defaultCustomerId: '',
		allowCustomerChange: false,
		allowTrackTypeSpecification: true,
	};

	eventTypeDataSource = [
		{
			display: TranslateText('eventType.privateMileage'),
			id: AlertTypesEnum.PrivateMileage,
		},
		{
			display: TranslateText('eventType.ignition'),
			id: AlertTypesEnum.Ignition,
		},
		{
			display: TranslateText('eventType.unknownKey'),
			id: AlertTypesEnum.UnknownKey,
		},
		{
			display: TranslateText('eventType.unauthorizedDrive'),
			id: AlertTypesEnum.UnauthorizedDrive,
		},
		{
			display: TranslateText('eventType.drivingWithoutAuthentication'),
			id: AlertTypesEnum.DrivingWithoutAuthentication,
		},
		{
			display: TranslateText('eventType.durationExceeded'),
			id: AlertTypesEnum.DurationExceeded,
		},
		{
			display: TranslateText('eventType.analog'),
			id: AlertTypesEnum.Analog,
		},
		{
			display: TranslateText('eventType.digital'),
			id: AlertTypesEnum.Digital,
		},
	];

	analogTriggerConditionDataSource = getAnalogTriggerConditions();
	digitalTriggerConditionDataSource = getDigitalTriggerConditions();

	constructor(props: Props) {
		super(props);

		const newAlert = new Alert(this.props.defaultCustomerId);
		//TO DO: usePopup will be implemented in the future
		newAlert.usePopup = false;

		this.state = {
			alert: newAlert,
			currentStep: 1,
			expandedAccordionSection: 0,
			useDefaultMessage: {
				notification: true,
				popup: true,
				mail: true,
				sms: true,
				whatsApp: true,
			},
			alertEntityTypes: [],
			validationResult: null,
			isLoaded: false,
			analogTemplates: [],
			analogTemplatesLoaded: false,
			analogStatesTemplate: [],
			digitalTemplates: [],
			digitalTemplatesLoaded: false,
		};
		this.personsStepRef = React.createRef();

		this.setUpValidations();
	}

	setAlert(valid: boolean, alert: Alert) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			this.props.setAlert(valid, alert);
		}
	}

	setEditRecord(alert: Alert) {
		this.setState({
			alert,
		});
		this.setAlert(true, alert);
		this.refreshAccordionInstance();
	}

	refreshAccordionInstance() {
		this.setState({
			expandedAccordionSection: 0,
		});
	}

	//rethink this validation in order to get off ifs
	setUpValidations() {
		if (this.props.renderFor === RenderForEnum.Wizard) {
			this.validator = new Validator({
				fieldRules: {
					code: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: {
								message: TranslateText('fieldsValidations.maxLength24'),
								validationFunction: (data) => {
									if (data.code?.length > 24) {
										return ValidatorFunctions.wrapInPromise(false);
									}
									return ValidatorFunctions.wrapInPromise(true);
								},
							},
							regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: async (data) => {
									const result = await this.isAlertCodeUnique(data?.code);
									return ValidatorFunctions.wrapInPromise(result);
								},
							},
						},
					},
					name: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: {
								message: TranslateText('fieldsValidations.maxLength200'),
								validationFunction: (data) => {
									if (data.name?.length > 200) {
										return ValidatorFunctions.wrapInPromise(false);
									}
									return ValidatorFunctions.wrapInPromise(true);
								},
							},
							regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
						},
					},
					eventTypeId: {
						rules: { required: ValidatorFunctions.required() },
					},
					eventFrequency: {
						rules: { required: ValidatorFunctions.required() },
					},
					templateAnalogId: {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const alertData = data as Alert;
									return ValidatorFunctions.wrapInPromise(
										alertData.eventTypeId !== AlertTypesEnum.Analog ||
											!!alertData.analogAlertMetadata.templateAnalogId
									);
								},
							},
						},
					},
					analogTriggerCondition: {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const alertData = data as Alert;
									return ValidatorFunctions.wrapInPromise(
										alertData.eventTypeId !== AlertTypesEnum.Analog ||
											!!alertData.analogAlertMetadata.triggerCondition ||
											alertData.analogAlertMetadata.triggerCondition ===
												AlertAnalogTriggerConditionEnum.AnyStateChange
									);
								},
							},
						},
					},
					templateAnalogStateId: {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const alertData = data as Alert;
									return ValidatorFunctions.wrapInPromise(
										alertData.eventTypeId !== AlertTypesEnum.Analog ||
											(alertData.analogAlertMetadata.triggerCondition !==
												AlertAnalogTriggerConditionEnum.ToSpecificState &&
												alertData.analogAlertMetadata.triggerCondition !==
													AlertAnalogTriggerConditionEnum.FromSpecificState) ||
											!!alertData.analogAlertMetadata.templateAnalogStateId
									);
								},
							},
						},
					},
					templateDigitalId: {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const alertData = data as Alert;
									return ValidatorFunctions.wrapInPromise(
										alertData.eventTypeId !== AlertTypesEnum.Digital ||
											!!alertData.digitalAlertMetadata?.templateDigitalId
									);
								},
							},
						},
					},
					digitalTriggerCondition: {
						rules: {
							requiredConditionally: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const alertData = data as Alert;
									return ValidatorFunctions.wrapInPromise(
										alertData.eventTypeId !== AlertTypesEnum.Digital ||
											alertData.digitalAlertMetadata?.triggerCondition in
												AlertDigitalTriggerConditionEnum
									);
								},
							},
						},
					},
				},
			});

			if (this.state.alert.eventTypeId === AlertTypesEnum.PrivateMileage) {
				this.validator = new Validator({
					fieldRules: {
						code: {
							rules: {
								required: ValidatorFunctions.required(),
								maxLength: {
									message: TranslateText('fieldsValidations.maxLength24'),
									validationFunction: (data) => {
										if (data.code?.length > 24) {
											return ValidatorFunctions.wrapInPromise(false);
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
								unique: {
									message: TranslateText('fieldsValidations.uniqueValue'),
									validationFunction: async (data) => {
										const result = await this.isAlertCodeUnique(data?.code);
										return ValidatorFunctions.wrapInPromise(result);
									},
								},
							},
						},
						name: {
							rules: {
								required: ValidatorFunctions.required(),
								maxLength: {
									message: TranslateText('fieldsValidations.maxLength200'),
									validationFunction: (data) => {
										if (data.name?.length > 200) {
											return ValidatorFunctions.wrapInPromise(false);
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
							},
						},
						eventTypeId: {
							rules: { required: ValidatorFunctions.required() },
						},
						eventFrequency: {
							rules: { required: ValidatorFunctions.required() },
						},
						alertThreshold: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.thresholdValue) {
											return ValidatorFunctions.wrapInPromise(true);
										}
										return ValidatorFunctions.wrapInPromise(false);
									},
								},
							},
						},
						preTreshold: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (
											!data.PrivateMileageAlertMetadata.preThresholds &&
											data.PrivateMileageAlertMetadata.preThresholds !== 0
										) {
											return ValidatorFunctions.wrapInPromise(false);
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						firstPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 1) {
											if (
												!data.PrivateMileageAlertMetadata.firstPreThresholdAlert &&
												data.PrivateMileageAlertMetadata?.firstPreThresholdAlert !== 0
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}

										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.minValue') + 1,
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 1) {
											if (parseInt(data.PrivateMileageAlertMetadata.firstPreThresholdAlert) < 1) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 1) {
											if (
												parseInt(data.PrivateMileageAlertMetadata.firstPreThresholdAlert) > 99
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 1) {
											if (
												data.PrivateMileageAlertMetadata.preThresholds >= 2 &&
												parseFloat(data.PrivateMileageAlertMetadata.firstPreThresholdAlert) ===
													parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												data.PrivateMileageAlertMetadata.preThresholds === 3 &&
												parseFloat(data.PrivateMileageAlertMetadata.firstPreThresholdAlert) ===
													parseFloat(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						secondPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 2) {
											if (
												!data.PrivateMileageAlertMetadata.secondPreThresholdAlert &&
												data.PrivateMileageAlertMetadata?.secondPreThresholdAlert !== 0
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.prethresholdGreater'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 2) {
											if (
												parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert) <
												parseFloat(data.PrivateMileageAlertMetadata.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 2) {
											if (
												parseInt(data.PrivateMileageAlertMetadata.secondPreThresholdAlert) > 99
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds >= 2) {
											if (
												parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert) ===
												parseFloat(data.PrivateMileageAlertMetadata.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												data.PrivateMileageAlertMetadata.preThresholds === 3 &&
												parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert) ===
													parseFloat(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						thirdPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds === 3) {
											if (
												!data.PrivateMileageAlertMetadata.thirdPreThresholdAlert &&
												data.PrivateMileageAlertMetadata?.thirdPreThresholdAlert !== 0
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.prethresholdGreater'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds === 3) {
											if (
												parseFloat(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert) <
												parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds === 3) {
											if (
												parseInt(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert) > 99
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.PrivateMileageAlertMetadata.preThresholds === 3) {
											if (
												parseFloat(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert) ===
												parseFloat(data.PrivateMileageAlertMetadata.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												parseFloat(data.PrivateMileageAlertMetadata.thirdPreThresholdAlert) ===
												parseFloat(data.PrivateMileageAlertMetadata.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
					},
				});
			}
		}

		if (this.props.renderFor === RenderForEnum.Widget) {
			this.validator = new Validator({
				fieldRules: {
					code: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: {
								message: TranslateText('fieldsValidations.maxLength24'),
								validationFunction: (data) => {
									if (data.code?.length > 24) {
										return ValidatorFunctions.wrapInPromise(false);
									}
									return ValidatorFunctions.wrapInPromise(true);
								},
							},
							regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: async (data) => {
									const result = await this.isAlertCodeUnique(data?.code);
									return ValidatorFunctions.wrapInPromise(result);
								},
							},
						},
					},
					name: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: {
								message: TranslateText('fieldsValidations.maxLength200'),
								validationFunction: (data) => {
									if (data.name?.length > 200) {
										return ValidatorFunctions.wrapInPromise(false);
									}
									return ValidatorFunctions.wrapInPromise(true);
								},
							},
							regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
						},
					},
				},
			});
		}
	}

	setAndValidateAlert(newAlert: Alert, additionalState?: { [key in keyof State]?: State[key] }) {
		this.setState(
			(prevState) => {
				return { ...prevState, alert: newAlert, ...additionalState };
			},
			() => {
				this.setUpValidations();
				this.props.disableNavigation();
				this.validator.validate(this.state.alert).then((result) => {
					this.setState(
						{
							validationResult: result.validationResult,
						},
						() => {
							const formValid = result.formResult && this.isAlertValid();
							this.setAlert(formValid, newAlert);
							if (this.props.renderFor === RenderForEnum.Wizard) {
								this.props.notifyNavigationCallback(
									formValid && this.navigationEnabled(this.state.currentStep),
									this.state.currentStep
								);
							}
						}
					);
				});
			}
		);
	}

	handlePrivateMileageValuesChange(value: number | string, statePropName: string) {
		const tempAlert = { ...this.state.alert };
		tempAlert.PrivateMileageAlertMetadata[statePropName] = value;

		if (statePropName === 'preThresholds') {
			if (value === undefined || value === null) {
				tempAlert.preThresholds = 0;
				value = 0;
			}
			if (value === 0) {
				tempAlert.PrivateMileageAlertMetadata.firstPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.secondPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
			if (value === 1) {
				tempAlert.PrivateMileageAlertMetadata.secondPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
			if (value === 2) {
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
		}

		this.setAndValidateAlert(tempAlert);
	}

	handleValueChange(value: any, statePropName: keyof Alert) {
		const tempAlert = { ...this.state.alert };
		tempAlert[statePropName] = value;

		this.setAndValidateAlert(tempAlert);
	}

	async isAlertCodeUnique(code: string) {
		let isAlertCodeUnique = true;
		const url = GlobalSettings.validateApi + '/AlertFields';
		let data: { [k: string]: any };
		if (this.state.alert.id !== undefined) {
			data = {
				customerId: this.state.alert.customerId,
				value: code,
				Field: 'code',
				editedEntityId: this.state.alert.id,
			};
		} else {
			data = {
				customerId: this.state.alert.customerId,
				value: code,
				Field: 'code',
			};
		}

		await fetch(url, {
			method: 'post',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
			}),
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status.toString());
				} else {
					return response.json();
				}
			})
			.then((data) => {
				isAlertCodeUnique = data;
			});
		return isAlertCodeUnique;
	}

	fillSystemInformation() {
		return (
			<form id={'alertViewSystemInformationForm'} noValidate={true}>
				<div id="alert-info" className="view-section-wrapper">
					<div className="form-group">
						<MaterialTextField
							id="alertCode"
							className="resize-font"
							label={TranslateText('fields.code')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="alertCode"
							value={this.state.alert.code}
							handleValueChange={(value) => this.handleValueChange(value, 'code')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="name"
							className="resize-font"
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="name"
							value={this.state.alert.name}
							handleValueChange={(value) => this.handleValueChange(value, 'name')}
							disabled={this.props.readOnly}
						/>
						{!this.props.readOnly && !this.state.alert.name && (
							<ValidationMessage result={this.state.validationResult?.name} />
						)}
					</div>
					<CustomerNameDisplay customerId={this.state.alert.customerId} />
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									id="active"
									name="active"
									color={'primary'}
									checked={this.state.alert.active}
									readOnly={this.props.readOnly}
									onChange={() => {
										this.handleValueChange(!this.state.alert.active, 'active');
									}}
									disabled={
										this.props.renderFor === RenderForEnum.Wizard ? true : this.props.readOnly
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				</div>
			</form>
		);
	}

	systemInformation() {
		return (
			<Scrollbar native={true} className="view-section-scroll" style={{ height: '100%' }}>
				{this.fillSystemInformation()}
			</Scrollbar>
		);
	}

	setStyleClassBasedOnCurrentStep(stepNumber: number, currentStep: number): string {
		return currentStep === stepNumber ? 'addWizard-selected-wizard-step' : '';
	}

	goToStep(stepNumber: number) {
		if (this.state.currentStep > stepNumber || this.navigationEnabled(stepNumber - 1)) {
			this.setState(
				{
					currentStep: stepNumber,
				},
				() => {
					this.validator.validate(this.state.alert).then((result) => {
						this.setState(
							{
								validationResult: result.validationResult,
							},
							() => {
								const canNavigate = result.formResult && this.navigationEnabled(stepNumber);
								if (this.props.renderFor === RenderForEnum.Wizard) {
									this.props.notifyNavigationCallback(canNavigate, stepNumber);
								}
							}
						);
					});
				}
			);
		}
	}

	validateDurationExceeded(alert: Alert) {
		return (
			alert.eventTypeId !== AlertTypesEnum.DurationExceeded ||
			((!!alert.DurationExceededAlertMetadata.templateDurationId ||
				!!alert.DurationExceededAlertMetadata.templateDigitalId) &&
				((!alert.DurationExceededAlertMetadata.relatedToTime &&
					alert.DurationExceededAlertMetadata.durationCounter >= 1 &&
					alert.DurationExceededAlertMetadata.durationCounter <= 999999999) ||
					(alert.DurationExceededAlertMetadata.relatedToTime &&
						alert.DurationExceededAlertMetadata.duration >= 1 &&
						alert.DurationExceededAlertMetadata.duration <=
							getDurationForSelectedTimeFrame(alert.DurationExceededAlertMetadata.timeFrame))))
		);
	}

	validateAnalogAlert(alert: Alert) {
		return (
			alert.eventTypeId !== AlertTypesEnum.Analog ||
			(alert.analogAlertMetadata.templateAnalogId &&
				alert.analogAlertMetadata.triggerCondition >= 0 &&
				(alert.analogAlertMetadata.templateAnalogStateId ||
					alert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.AnyStateChange))
		);
	}

	validateDigitalAlert(alert: Alert) {
		return (
			alert.eventTypeId !== AlertTypesEnum.Digital ||
			(alert.digitalAlertMetadata.templateDigitalId &&
				alert.digitalAlertMetadata.triggerCondition in AlertDigitalTriggerConditionEnum)
		);
	}

	isAlertValid() {
		let step1State = true;
		step1State = !!this.state.alert.code && !!this.state.alert.name;
		if (this.props.renderFor === RenderForEnum.Wizard) {
			step1State =
				step1State &&
				!!this.state.alert.eventTypeId &&
				!!this.state.alert.entityType &&
				(!!this.state.alert.eventFrequency || this.state.alert.eventFrequency === EventFrequencyEnum.Once) &&
				this.validateDurationExceeded(this.state.alert) &&
				this.validateAnalogAlert(this.state.alert) &&
				this.validateDigitalAlert(this.state.alert) &&
				(this.state.alert.eventTypeId !== AlertTypesEnum.PrivateMileage ||
					(this.state.alert.eventTypeId === AlertTypesEnum.PrivateMileage &&
						!!this.state.alert.PrivateMileageAlertMetadata &&
						!!this.state.alert.PrivateMileageAlertMetadata.thresholdValue));
		}
		if (this.state.currentStep === 1) {
			return step1State;
		}
		let step2State = true;
		let step3State = true;
		if (this.props.renderFor === RenderForEnum.Wizard) {
			step2State = this.state.alert.trackedEntities && this.state.alert.trackedEntities.length >= 1;
			step3State = this.state.alert.alertRecipients && this.state.alert.alertRecipients.length >= 1;
		}

		if (this.state.currentStep === 2) {
			return step2State;
		}

		if (this.state.currentStep === 3) {
			return step3State;
		}

		const notificationContentRequired =
			this.state.alert.useNotification &&
			this.state.alert.notificationContent?.body &&
			!(this.state.alert.notificationContent?.body.length > 8);

		const popupContentRequired =
			this.state.alert.usePopup &&
			this.state.alert.popupContent?.body &&
			!(this.state.alert.popupContent?.body.length > 8);

		const mailContentRequired =
			this.state.alert.useEmail &&
			((this.state.alert.mailContent?.body && !(this.state.alert.mailContent?.body.length > 8)) ||
				!(this.state.alert.mailContent?.subject?.length > 0) ||
				!(this.state.alert.mailContent?.subject?.length <= ALERT_MAIL_SUBJECT_MAX_LENGTH));

		const smsContentRequired =
			this.state.alert.useSms &&
			this.state.alert.smsContent?.body &&
			!(this.state.alert.smsContent?.body.length > 8);

		const whatsAppContentRequired =
			this.state.alert.useWhatsApp &&
			this.state.alert.whatsAppContent?.body &&
			!(this.state.alert.whatsAppContent?.body.length > 8);

		const step4State = !(
			notificationContentRequired ||
			popupContentRequired ||
			mailContentRequired ||
			smsContentRequired ||
			whatsAppContentRequired
		);
		return step1State && step2State && step3State && step4State;
	}

	navigationEnabled(currentStep: number): boolean {
		let step1State = true;
		let step2State = true;
		let step3State = true;
		if (this.props.renderFor === RenderForEnum.Wizard) {
			step1State =
				!!this.state.alert.code &&
				!!this.state.alert.name &&
				!!this.state.alert.eventTypeId &&
				!!this.state.alert.entityType &&
				this.validateDurationExceeded(this.state.alert) &&
				this.validateAnalogAlert(this.state.alert) &&
				this.validateDigitalAlert(this.state.alert) &&
				(!!this.state.alert.eventFrequency || this.state.alert.eventFrequency === EventFrequencyEnum.Once) &&
				(this.state.alert.eventTypeId !== AlertTypesEnum.PrivateMileage ||
					(this.state.alert.eventTypeId === AlertTypesEnum.PrivateMileage &&
						!!this.state.alert.PrivateMileageAlertMetadata &&
						!!this.state.alert.PrivateMileageAlertMetadata.thresholdValue));
			step2State = this.state.alert.trackedEntities && this.state.alert.trackedEntities.length >= 1;
			step3State = this.state.alert.alertRecipients && this.state.alert.alertRecipients.length >= 1;
		}
		const step4State = true;

		switch (currentStep) {
			case 1:
				return step1State;
			case 2:
				return step1State && step2State;
			case 3:
				return step1State && step2State && step3State;
			case 4:
				return step1State && step2State && step3State && step4State;
			default:
				return false;
		}
	}

	async componentDidMount() {
		if (this.props.renderFor === RenderForEnum.Wizard && this.props.entityDefaultsSettings.autoGenerateAlertCode) {
			this.setUpValidations();
			const alert = this.state.alert;
			const url = `${GlobalSettings.alertsApi}/generateAlertCode/${this.state.alert.customerId}`;
			const id = await ajaxUtil.get(url);
			alert.code = id.toString();
			this.setState(
				{
					alert: alert,
				},
				() => {
					this.props.disableNavigation();
					this.validator.validate(this.state.alert).then((result) => {
						this.setState({
							validationResult: result.validationResult,
						});
					});
				}
			);
		}

		if (this.props.renderFor === RenderForEnum.Wizard) {
			this.props.setMaxStepCount(4);
			this.props.notifyNavigationCallback(false, 1);
		}

		this.setAlertNotificationTypes();

		this.setState({ isLoaded: true });
	}

	setAlertNotificationTypes(): void {
		const useNotification: boolean = ClaimUtil.validateClaim(this.props.user, {
			claim: ClaimType.Alerts,
			values: [ClaimValue.notificationAlert],
		});

		const useEmail: boolean = ClaimUtil.validateClaim(this.props.user, {
			claim: ClaimType.Alerts,
			values: [ClaimValue.mailAlert],
		});

		const useSms: boolean = ClaimUtil.validateClaim(this.props.user, {
			claim: ClaimType.Alerts,
			values: [ClaimValue.smsAlert],
		});

		const useWhatsApp: boolean = ClaimUtil.validateClaim(this.props.user, {
			claim: ClaimType.Alerts,
			values: [ClaimValue.whatsAppAlert],
		});

		const alert = { ...this.state.alert, useNotification, useEmail, useSms, useWhatsApp };
		this.setState({ alert });
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.props.currentStep !== prevProps.currentStep) {
			this.goToStep(this.props.currentStep);
		}

		if (
			this.personsStepRef.current &&
			(prevState.alert.useEmail !== this.state.alert.useEmail ||
				prevState.alert.useSms !== this.state.alert.useSms)
		) {
			this.personsStepRef.current.forceRefetchData();
		}
	}

	getAlertTypeDatasource(): any[] {
		let alertTypeDatasource = this.eventTypeDataSource;
		if (
			!this.props.allowTrackTypeSpecification ||
			!(this.props.featureSettings.tripTypes & TripTypesEnum.Private)
		) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) => alertType.id !== AlertTypesEnum.PrivateMileage
			);
		}
		if (!this.props.featureSettings.templates) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.id !== AlertTypesEnum.DurationExceeded &&
					alertType.id !== AlertTypesEnum.Analog &&
					alertType.id !== AlertTypesEnum.Digital
			);
		}
		if (!this.props.featureSettings.driverIdentification) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.id !== AlertTypesEnum.PrivateMileage &&
					alertType.id !== AlertTypesEnum.UnknownKey &&
					alertType.id !== AlertTypesEnum.UnauthorizedDrive &&
					alertType.id !== AlertTypesEnum.DrivingWithoutAuthentication
			);
		}
		return alertTypeDatasource;
	}

	async getAnalogTemplatesList() {
		const analogTemplates = await ajaxUtil.get<AnalogTemplateItem[]>(
			`${GlobalSettings.listsApi}/analogTemplatesList?customerId=${this.state.alert.customerId}`
		);
		this.setState((prev) => {
			return { ...prev, analogTemplates: analogTemplates, analogTemplatesLoaded: true };
		});
	}

	async getDigitalTemplatesList() {
		const digitalTemplates = await ajaxUtil.get<AutocompleteItem[]>(
			`${GlobalSettings.listsApi}/digitalTemplatesList?customerId=${this.state.alert.customerId}`
		);

		this.setState((prev) => {
			return { ...prev, digitalTemplates: digitalTemplates, digitalTemplatesLoaded: true };
		});
	}

	handleAddDriversFromEntities() {
		const tempAlert = { ...this.state.alert };

		const candidateDrivers = tempAlert.trackedEntities.filter((x: any) => x.entityType === EntityTypeEnum.Driver);

		candidateDrivers.forEach((element: any) => {
			if (tempAlert.alertRecipients.map((x: any) => x.id).indexOf(element.id) === -1) {
				tempAlert.alertRecipients.push(element);
			}
		});

		this.setAndValidateAlert(tempAlert);
	}

	handleDigitalTemplateChange(value: any) {
		const templateDigitalId = value as string;
		const tempAlert = { ...this.state.alert };
		tempAlert.digitalAlertMetadata.templateDigitalId = templateDigitalId;

		this.setAndValidateAlert(tempAlert);
	}
	handleDigitalTriggerConditionChange(value: any) {
		const triggerCondition = value as AlertDigitalTriggerConditionEnum;
		const tempAlert = { ...this.state.alert };
		tempAlert.digitalAlertMetadata.triggerCondition = triggerCondition;

		this.setAndValidateAlert(tempAlert);
	}

	handleAnalogTemplateStateChange(value: any) {
		const templateAnalogStateId = value as string;

		const tempAlert = { ...this.state.alert };
		tempAlert.analogAlertMetadata.templateAnalogStateId = templateAnalogStateId;

		this.setAndValidateAlert(tempAlert);
	}

	handleAnalogTemplateChange(value: any) {
		const templateAnalogId = value as string;

		const tempAlert = { ...this.state.alert };
		tempAlert.analogAlertMetadata.templateAnalogId = templateAnalogId;
		tempAlert.analogAlertMetadata.templateAnalogStateId = null;

		let analogStatesTemplate: AutocompleteItem[] = [];
		if (
			templateAnalogId &&
			(tempAlert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.ToSpecificState ||
				tempAlert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.FromSpecificState)
		) {
			const selectedAnalogTemplate = this.state.analogTemplates.find((x) => x.id === templateAnalogId);
			if (selectedAnalogTemplate?.templateStates?.length) {
				analogStatesTemplate = selectedAnalogTemplate.templateStates;
				tempAlert.analogAlertMetadata.templateAnalogStateId = selectedAnalogTemplate.templateStates[0]
					.id as string;
			}
		}

		this.setAndValidateAlert(tempAlert, { analogStatesTemplate: analogStatesTemplate });
	}

	handleAnalogTriggerConditionChange(value: any) {
		const stateChangeTrigger = value as AlertAnalogTriggerConditionEnum;
		const oldStateChangeTrigger = this.state.alert.analogAlertMetadata.triggerCondition;

		const tempAlert = { ...this.state.alert };
		tempAlert.analogAlertMetadata.triggerCondition = stateChangeTrigger;
		let analogStatesTemplate = [...this.state.analogStatesTemplate];

		if (stateChangeTrigger === AlertAnalogTriggerConditionEnum.AnyStateChange) {
			tempAlert.analogAlertMetadata.templateAnalogStateId = null;
			analogStatesTemplate = [];
		} else if (
			(stateChangeTrigger === AlertAnalogTriggerConditionEnum.ToSpecificState ||
				stateChangeTrigger === AlertAnalogTriggerConditionEnum.FromSpecificState) &&
			oldStateChangeTrigger !== AlertAnalogTriggerConditionEnum.ToSpecificState &&
			oldStateChangeTrigger !== AlertAnalogTriggerConditionEnum.FromSpecificState &&
			!!tempAlert.analogAlertMetadata.templateAnalogId
		) {
			const selectedAnalogTemplate = this.state.analogTemplates.find(
				(x) => x.id === tempAlert.analogAlertMetadata.templateAnalogId
			);
			if (selectedAnalogTemplate?.templateStates?.length) {
				analogStatesTemplate = selectedAnalogTemplate.templateStates;
				tempAlert.analogAlertMetadata.templateAnalogStateId = selectedAnalogTemplate.templateStates[0]
					.id as string;
			}
		}

		this.setAndValidateAlert(tempAlert, { analogStatesTemplate: analogStatesTemplate });
	}

	handleEventTypeChange(value: any) {
		const alertTypesValue = value as AlertTypesEnum;
		const oldAlertTypesValue = this.state.alert.eventTypeId;

		const tempAlert = { ...this.state.alert };
		tempAlert['eventTypeId'] = value;
		tempAlert['entityType'] = this.getAlertEntityType(alertTypesValue);

		//Analog
		if (alertTypesValue === AlertTypesEnum.Analog) {
			tempAlert.analogAlertMetadata.triggerCondition = AlertAnalogTriggerConditionEnum.ToSpecificState;
			if (!this.state.analogTemplatesLoaded) {
				this.getAnalogTemplatesList();
			}
		} else if (oldAlertTypesValue === AlertTypesEnum.Analog) {
			tempAlert.analogAlertMetadata.templateAnalogId = null;
			tempAlert.analogAlertMetadata.templateAnalogStateId = null;
			tempAlert.analogAlertMetadata.triggerCondition = null;
		}

		//Digital
		if (alertTypesValue === AlertTypesEnum.Digital) {
			tempAlert.digitalAlertMetadata.triggerCondition = AlertDigitalTriggerConditionEnum.AnyStateChange;
			if (!this.state.digitalTemplatesLoaded) {
				this.getDigitalTemplatesList();
			}
		} else if (oldAlertTypesValue === AlertTypesEnum.Digital) {
			tempAlert.digitalAlertMetadata.templateDigitalId = null;
			tempAlert.digitalAlertMetadata.triggerCondition = null;
		}

		this.setAndValidateAlert(tempAlert, { alertEntityTypes: this.getAlertEntityFilters(tempAlert['entityType']) });
	}

	getAlertEntityType(type: AlertTypesEnum): AlertEntityTypeEnum {
		switch (type) {
			case AlertTypesEnum.DrivingWithoutAuthentication:
			case AlertTypesEnum.Ignition:
			case AlertTypesEnum.UnauthorizedDrive:
			case AlertTypesEnum.UnknownKey:
			case AlertTypesEnum.DurationExceeded:
			case AlertTypesEnum.Analog:
			case AlertTypesEnum.Digital:
				return AlertEntityTypeEnum.Objects;
			case AlertTypesEnum.PrivateMileage:
				return AlertEntityTypeEnum.Drivers;
			case AlertTypesEnum.Unknown:
				return AlertEntityTypeEnum.NotEntityRelated;
			default:
				return AlertEntityTypeEnum.NotEntityRelated;
		}
	}

	handleEntitiesSelection(entities: any[]) {
		const tempAlert = this.state.alert;
		tempAlert.trackedEntities = [];
		if (entities !== null) {
			this.addEntitiesToAlert(entities, tempAlert);
		}

		this.setState({ alert: tempAlert }, () => {
			this.props.disableNavigation();
			this.validator.validate(this.state.alert).then((result) => {
				this.setState(
					{
						validationResult: result.validationResult,
					},
					() => {
						const formValid = result.formResult && this.isAlertValid();
						this.setAlert(formValid, tempAlert);
						if (this.props.renderFor === RenderForEnum.Wizard) {
							const canNavigate = formValid && this.navigationEnabled(this.state.currentStep);
							this.props.notifyNavigationCallback(canNavigate, this.state.currentStep);
						}
					}
				);
			});
		});
	}

	private addEntitiesToAlert(entities: ITreeNode[], tempAlert: Alert) {
		entities.forEach((item) => {
			const entity: SelectionItem = {
				entityId: item.id,
				entityType: item.type as EntityTypeEnum,
				id: item.id,
			};
			tempAlert.trackedEntities = [...tempAlert.trackedEntities, entity];
		});
	}

	handlePersonsSelections(entities: any[]) {
		const tempAlert = this.state.alert;
		tempAlert.alertRecipients = [];
		if (entities !== null) {
			this.addPersonsToAlert(entities, tempAlert);
		}
		this.setState({ alert: tempAlert }, () => {
			this.props.disableNavigation();
			this.validator.validate(this.state.alert).then((result) => {
				this.setState(
					{
						validationResult: result.validationResult,
					},
					() => {
						const formValid = result.formResult && this.isAlertValid();
						this.setAlert(formValid, tempAlert);
						if (this.props.renderFor === RenderForEnum.Wizard) {
							const canNavigate = formValid && this.navigationEnabled(this.state.currentStep);
							this.props.notifyNavigationCallback(canNavigate, this.state.currentStep);
						}
					}
				);
			});
		});
	}

	containsItem(node: ITreeNode) {
		const trackedEntities: SelectionItem[] = this.state.alert.trackedEntities as SelectionItem[];
		for (let i = 0; i < trackedEntities.length; i++) {
			if (trackedEntities[i].entityId === node.id) {
				return true;
			}
		}
		return false;
	}

	addDriversFromEntities(
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) {
		if (treeData) {
			let selectedEntities: ITreeNode[] = [];
			let selectedEntitiesIds: string[] = [];
			treeData.forEach((node) => {
				if (
					node.items.length === 0 &&
					this.containsItem(node) &&
					!selectedEntities.includes(node) &&
					!node.selected
				) {
					selectedEntities = [...selectedEntities, node];
					selectedEntitiesIds = [...selectedEntitiesIds, node.id];
				} else if (node.items.length > 0) {
					node.items.forEach((childNode) => {
						if (
							(this.containsItem(childNode) || this.containsItem(node)) &&
							!selectedEntitiesIds.includes(childNode.id) &&
							!childNode.selected
						) {
							selectedEntities = [...selectedEntities, childNode];
							selectedEntitiesIds = [...selectedEntitiesIds, childNode.id];
						}
					});
				}
			});

			setSelectedItems([...selectedItems, ...selectedEntities]);
		}
	}

	private addPersonsToAlert(entities: ITreeNode[], tempAlert: Alert) {
		entities.forEach((item) => {
			const person: SelectionItem = {
				entityId: item.id,
				entityType: item.type as EntityTypeEnum,
				id: item.id,
			};
			tempAlert.alertRecipients = [...tempAlert.alertRecipients, person];
		});
	}

	handleMessageContentChange(content: MessageWrapper, appliesTo: MessageTargetEnum, defaultContent: boolean) {
		const tempAlert = this.state.alert;
		const newDefaultMessageStatus = this.state.useDefaultMessage;
		switch (appliesTo) {
			case MessageTargetEnum.Notification:
				tempAlert.notificationContent = content;
				newDefaultMessageStatus.notification = defaultContent;
				break;
			case MessageTargetEnum.Popup:
				tempAlert.popupContent = content;
				newDefaultMessageStatus.popup = defaultContent;
				break;
			case MessageTargetEnum.Mail:
				tempAlert.mailContent = content;
				newDefaultMessageStatus.mail = defaultContent;
				break;
			case MessageTargetEnum.Sms:
				tempAlert.smsContent = content;
				newDefaultMessageStatus.sms = defaultContent;
				break;
			case MessageTargetEnum.WhatsApp:
				tempAlert.whatsAppContent = content;
				newDefaultMessageStatus.whatsApp = defaultContent;
				break;
			default:
				break;
		}
		this.setState({ alert: tempAlert }, () => {
			this.props.disableNavigation();
			this.validator.validate(this.state.alert).then((result) => {
				this.setState(
					{
						validationResult: result.validationResult,
					},
					() => {
						const formValid = result.formResult && this.isAlertValid();
						this.setAlert(formValid, tempAlert);
					}
				);
			});
		});
	}

	retrieveEntitiesData(filter: TreeViewFilter) {
		let showPersons = filter.showPersons || filter.showDrivers;
		let showObjects = filter.showObjects || filter.showAssets;
		let objectFunctionFilter = ObjectFunctionFilter.None;

		if (this.state.alert.entityType === AlertEntityTypeEnum.Drivers) {
			showObjects = false;
		} else if (this.state.alert.entityType === AlertEntityTypeEnum.Objects) {
			showPersons = false;
			objectFunctionFilter = this.getObjectFunctionFilter(filter.showObjects, filter.showAssets);
		} else {
			objectFunctionFilter = this.getObjectFunctionFilter(filter.showObjects, filter.showAssets);
		}

		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetAlertFleetSelectionData`, {
				customerId: filter.customerId,
				showPersons: showPersons,
				showObjects: showObjects,
				objectFunction: objectFunctionFilter,
				showInactive: filter.showInactive,
				filterText: filter.filterText.trim(),
			})
			.then((data) => this.prepareData(data));
	}

	getObjectFunctionFilter(showObjects: boolean, showAssets: boolean): ObjectFunctionFilter {
		if (this.props.assetTracking && showObjects && showAssets) {
			return ObjectFunctionFilter.All;
		} else if (this.props.assetTracking && showAssets) {
			return ObjectFunctionFilter.Asset;
		} else if (showObjects) {
			return ObjectFunctionFilter.Full;
		}

		return ObjectFunctionFilter.None;
	}

	retrievePersonsData(filter: TreeViewFilter) {
		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.alertsApi}/getAlertRecipients`, {
				customerId: filter.customerId,
				showInactive: filter.showInactive,
				filterText: filter.filterText.trim(),
				personType:
					filter.showPersons && filter.showDrivers
						? PersonTypeFilterEnum.All
						: filter.showPersons
						? PersonTypeFilterEnum.NonDriver
						: PersonTypeFilterEnum.Driver,
				hasAlertNotification: this.state.alert.useNotification,
				hasEmailNotification: this.state.alert.useEmail,
				hasSmsNotification: this.state.alert.useSms,
				hasWhatsAppNotification: this.state.alert.useWhatsApp,
			})
			.then((data) => this.prepareData(data));
	}

	prepareData(data: ITreeNode[]) {
		if (data) {
			for (let index = 0; index < data.length; index++) {
				if (data[index].id === null && data[index].text === 'All') {
					data[index].text = TranslateText('common.all');
					break;
				}
			}
		}

		return data;
	}

	getObjectsFilter(): EntityTypeFilter[] {
		if (this.props.assetTracking) {
			return [
				{
					filterEntityType: FilterEntityType.All,
					translationText: 'fleetSelection.all',
				},
				{
					filterEntityType: FilterEntityType.Object,
					translationText: 'fleetSelection.objects',
				},
				{
					filterEntityType: FilterEntityType.Asset,
					translationText: 'fleetSelection.assets',
				},
			];
		}

		return [
			{
				filterEntityType: FilterEntityType.Object,
				translationText: 'fleetSelection.objects',
			},
		];
	}

	getReceiversFilter(): EntityTypeFilter[] {
		if (
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Persons,
				values: [ClaimValue.view],
			})
		) {
			return this.personsFilters;
		}

		return [];
	}

	getAlertEntityFilters(entityType: AlertEntityTypeEnum): EntityTypeFilter[] {
		switch (entityType) {
			case AlertEntityTypeEnum.Objects:
				return this.getObjectsFilter();
			case AlertEntityTypeEnum.Drivers:
				return [
					{
						filterEntityType: FilterEntityType.Driver,
						translationText: 'fleetSelection.drivers',
					},
				] as EntityTypeFilter[];
			case AlertEntityTypeEnum.NotEntityRelated:
				return [] as EntityTypeFilter[];
			default:
				return [] as EntityTypeFilter[];
		}
	}

	personsFilters: EntityTypeFilter[] = [
		{
			filterEntityType: FilterEntityType.All,
			translationText: 'fleetSelection.all',
		},
		{
			filterEntityType: FilterEntityType.Person,
			translationText: 'fleetSelection.persons',
		},
		{
			filterEntityType: FilterEntityType.Driver,
			translationText: 'fleetSelection.drivers',
		},
	];

	render() {
		return (
			this.state.isLoaded &&
			(this.props.renderFor === RenderForEnum.Wizard ? (
				<div style={{ marginBottom: 0 }}>
					<ul
						id="addWizard-horizontal-list"
						className="e-text"
						style={{ cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'end' }}
					>
						<li
							onClick={() => this.goToStep(1)}
							className={this.setStyleClassBasedOnCurrentStep(1, this.state.currentStep)}
						>
							{'1.' + TranslateText('alert.alert')}
						</li>
						<ProtectedContainer claimList={[]}>
							<li
								onClick={() => this.goToStep(2)}
								className={this.setStyleClassBasedOnCurrentStep(2, this.state.currentStep)}
							>
								{'2.' + TranslateText('alert.entities')}
							</li>
						</ProtectedContainer>
						<ProtectedContainer claimList={[]}>
							<li
								onClick={() => this.goToStep(3)}
								className={this.setStyleClassBasedOnCurrentStep(3, this.state.currentStep)}
							>
								{'3.' + TranslateText('alert.persons')}
							</li>
						</ProtectedContainer>
						<li
							onClick={() => this.goToStep(4)}
							className={this.setStyleClassBasedOnCurrentStep(4, this.state.currentStep)}
						>
							{'4.' + TranslateText('alert.message')}
						</li>
					</ul>
					<div className="wizard-page">
						<div className="pannel" hidden={this.state.currentStep !== 1}>
							<div className="column" style={{ width: '50%' }}>
								<Accordion>
									<AccordionSection
										expanded={true}
										header={TranslateText('common.systemInformation')}
									>
										{this.systemInformation()}
									</AccordionSection>
								</Accordion>
							</div>
							<div className="column" style={{ width: '50%' }}>
								<Accordion>
									<AccordionSection expanded={true} header={TranslateText('common.eventData')}>
										<form id="alertViewEventDataForm" noValidate={true} style={{ marginTop: 5 }}>
											<div className="view-section-wrapper">
												<div className="form-group">
													<MaterialAutocomplete
														name="eventTypeId"
														className="material-autocomplete report-selection-component"
														valueId={this.state.alert.eventTypeId}
														dataSource={this.getAlertTypeDatasource()}
														onChange={(args: any) => this.handleEventTypeChange(args.value)}
														disabled={
															!(
																!this.props.readOnly &&
																this.props.renderFor === RenderForEnum.Wizard
															)
														}
														fullWidth={false}
														label={TranslateText('fields.type')}
													/>
													<ValidationMessage
														result={this.state.validationResult?.eventTypeId}
													/>
												</div>

												{this.state.alert.eventTypeId === AlertTypesEnum.Digital ? (
													<>
														<div className="form-group">
															<MaterialAutocomplete
																name="templateDigitalId"
																className="material-autocomplete report-selection-component"
																valueId={
																	this.state.alert.digitalAlertMetadata
																		.templateDigitalId
																}
																dataSource={this.state.digitalTemplates}
																onChange={(args: any) =>
																	this.handleDigitalTemplateChange(args.value)
																}
																disabled={
																	!(
																		!this.props.readOnly &&
																		this.props.renderFor === RenderForEnum.Wizard
																	)
																}
																fullWidth={false}
																label={TranslateText('common.template')}
															/>
															<ValidationMessage
																result={this.state.validationResult?.templateDigitalId}
															/>
														</div>
														<div className="form-group">
															<MaterialAutocomplete
																name="digitalTriggerCondition"
																className="material-autocomplete report-selection-component"
																valueId={
																	this.state.alert.digitalAlertMetadata
																		.triggerCondition
																}
																dataSource={this.digitalTriggerConditionDataSource}
																onChange={(args: any) =>
																	this.handleDigitalTriggerConditionChange(args.value)
																}
																disabled={
																	!(
																		!this.props.readOnly &&
																		this.props.renderFor === RenderForEnum.Wizard
																	)
																}
																fullWidth={false}
																label={TranslateText('alert.sendNotification')}
															/>
															<ValidationMessage
																result={
																	this.state.validationResult?.digitalTriggerCondition
																}
															/>
														</div>
													</>
												) : null}

												{this.state.alert.eventTypeId === AlertTypesEnum.Analog ? (
													<>
														<div className="form-group">
															<MaterialAutocomplete
																name="templateAnalogId"
																className="material-autocomplete report-selection-component"
																valueId={
																	this.state.alert.analogAlertMetadata
																		.templateAnalogId
																}
																dataSource={this.state.analogTemplates}
																onChange={(args: any) =>
																	this.handleAnalogTemplateChange(args.value)
																}
																disabled={
																	!(
																		!this.props.readOnly &&
																		this.props.renderFor === RenderForEnum.Wizard
																	)
																}
																fullWidth={false}
																label={TranslateText('common.template')}
															/>
															<ValidationMessage
																result={this.state.validationResult?.templateAnalogId}
															/>
														</div>

														<div className="form-group">
															<MaterialAutocomplete
																name="analogTriggerCondition"
																className="material-autocomplete report-selection-component"
																valueId={
																	this.state.alert.analogAlertMetadata
																		.triggerCondition
																}
																dataSource={this.analogTriggerConditionDataSource}
																onChange={(args: any) =>
																	this.handleAnalogTriggerConditionChange(args.value)
																}
																disabled={
																	!(
																		!this.props.readOnly &&
																		this.props.renderFor === RenderForEnum.Wizard
																	)
																}
																fullWidth={false}
																label={TranslateText('alert.sendNotification')}
															/>
															<ValidationMessage
																result={
																	this.state.validationResult?.analogTriggerCondition
																}
															/>
														</div>

														{this.state.alert.analogAlertMetadata.triggerCondition ===
															AlertAnalogTriggerConditionEnum.ToSpecificState ||
														this.state.alert.analogAlertMetadata.triggerCondition ===
															AlertAnalogTriggerConditionEnum.FromSpecificState ? (
															<div className="form-group">
																<MaterialAutocomplete
																	name="templateAnalogStateId"
																	className="material-autocomplete report-selection-component"
																	valueId={
																		this.state.alert.analogAlertMetadata
																			.templateAnalogStateId
																	}
																	dataSource={this.state.analogStatesTemplate}
																	onChange={(args: any) =>
																		this.handleAnalogTemplateStateChange(args.value)
																	}
																	disabled={
																		!(
																			!this.props.readOnly &&
																			this.props.renderFor ===
																				RenderForEnum.Wizard
																		)
																	}
																	fullWidth={false}
																	label={TranslateText('alert.state')}
																/>
																<ValidationMessage
																	result={
																		this.state.validationResult
																			?.templateAnalogStateId
																	}
																/>
															</div>
														) : null}
													</>
												) : null}

												<div className="form-group" id="dynamicTypeContent">
													<div
														hidden={
															this.state.alert.eventTypeId !==
															AlertTypesEnum.PrivateMileage
														}
													>
														<hr
															style={{
																color: '#cfd0d1',
																backgroundColor: '#cfd0d1',
																height: 0.1,
																borderColor: '#cfd0d1',
															}}
														/>

														<div className="form-group">
															<TextField
																id="alertThreshold"
																type="number"
																className="resize-font"
																fullWidth
																label={TranslateText('fields.threshold')}
																InputProps={{
																	inputProps: {
																		min: 0,
																		max: 999999999,
																		readOnly: this.props.readOnly,
																		style: { fontSize: 12 },
																	},
																}}
																name="alertThreshold"
																value={this.state.alert.PrivateMileageAlertMetadata.thresholdValue.toString()}
																onKeyPress={(
																	event: React.KeyboardEvent<HTMLInputElement>
																) => {
																	if (
																		event.key === 'e' ||
																		event.key === '+' ||
																		event.key === '-'
																	) {
																		event.preventDefault();
																	}
																}}
																onChange={(
																	args: React.ChangeEvent<
																		HTMLInputElement | HTMLTextAreaElement
																	>
																) =>
																	this.handlePrivateMileageValuesChange(
																		+args.target.value,
																		'thresholdValue'
																	)
																}
																disabled={this.props.readOnly}
															/>
															<ValidationMessage
																result={this.state.validationResult?.alertThreshold}
															/>
														</div>
														<div className="form-group">
															<MaterialAutocomplete
																name="preTreshold"
																className="material-autocomplete report-selection-component"
																valueId={
																	this.state.alert.PrivateMileageAlertMetadata
																		.preThresholds
																}
																dataSource={[
																	{ display: '0', id: 0 },
																	{ display: '1', id: 1 },
																	{ display: '2', id: 2 },
																	{ display: '3', id: 3 },
																]}
																onChange={(args: any) =>
																	this.handlePrivateMileageValuesChange(
																		args.value,
																		'preThresholds'
																	)
																}
																disabled={
																	!(
																		!this.props.readOnly &&
																		this.props.renderFor === RenderForEnum.Wizard
																	)
																}
																fullWidth={false}
																label={TranslateText('fields.preThresholds')}
																disableClearable
															/>
															<ValidationMessage
																result={this.state.validationResult?.preThreshold}
															/>
														</div>
														{this.state.alert.PrivateMileageAlertMetadata.preThresholds >=
														1 ? (
															<Box marginBottom={'0.5rem'}>
																<div>
																	<span
																		style={{
																			marginLeft: 20,
																			display: 'inline-block',
																			width: 100,
																		}}
																	>
																		{TranslateText('fields.firstAt') + ':'}{' '}
																	</span>
																	<div
																		className="form-group"
																		style={{
																			display: 'inline-grid',
																			marginBottom: 0,
																		}}
																	>
																		<TextField
																			id="firstPreThresholdAlert"
																			type="number"
																			InputLabelProps={{
																				shrink: true,
																			}}
																			InputProps={{
																				inputProps: {
																					min: 1,
																					max: 99,
																					style: { fontSize: 12 },
																				},
																			}}
																			name="firstPreThresholdAlert"
																			value={this.state.alert.PrivateMileageAlertMetadata.firstPreThresholdAlert.toString()}
																			size="small"
																			style={{ width: 200 }}
																			onKeyPress={(
																				event: React.KeyboardEvent<
																					HTMLInputElement
																				>
																			) => {
																				if (
																					event.key === 'e' ||
																					event.key === '+' ||
																					event.key === '-'
																				) {
																					event.preventDefault();
																				}
																			}}
																			onChange={(
																				args: React.ChangeEvent<
																					| HTMLInputElement
																					| HTMLTextAreaElement
																				>
																			) => {
																				this.handlePrivateMileageValuesChange(
																					+args.target.value,
																					'firstPreThresholdAlert'
																				);
																			}}
																			disabled={this.props.readOnly}
																		/>
																	</div>
																	<span
																		style={{
																			marginLeft: 10,
																			display: 'inline-block',
																		}}
																	>
																		{TranslateText('fields.percentOfThreshold')}
																	</span>
																</div>
																<ValidationMessage
																	className={'pre-threshold-validation'}
																	result={
																		this.state.validationResult
																			?.firstPreThresholdAlert
																	}
																/>
															</Box>
														) : null}

														{2 <=
														this.state.alert.PrivateMileageAlertMetadata.preThresholds ? (
															<Box marginBottom={'0.5rem'}>
																<div>
																	<span
																		style={{
																			marginLeft: 20,
																			display: 'inline-block',
																			width: 100,
																		}}
																	>
																		{TranslateText('fields.secondAt') + ':'}
																	</span>
																	<div
																		className="form-group"
																		style={{
																			display: 'inline-grid',
																			marginBottom: 0,
																		}}
																	>
																		<TextField
																			id="secondPreThresholdAlert"
																			type="number"
																			InputLabelProps={{
																				shrink: true,
																			}}
																			InputProps={{
																				inputProps: {
																					min: 0,
																					max: 100,
																					readOnly: this.props.readOnly,
																					style: { fontSize: 12 },
																				},
																			}}
																			name="secondPreThresholdAlert"
																			value={this.state.alert.PrivateMileageAlertMetadata.secondPreThresholdAlert.toString()}
																			size="small"
																			style={{ width: 200 }}
																			onKeyPress={(
																				event: React.KeyboardEvent<
																					HTMLInputElement
																				>
																			) => {
																				if (
																					event.key === 'e' ||
																					event.key === '+' ||
																					event.key === '-'
																				) {
																					event.preventDefault();
																				}
																			}}
																			onChange={(
																				args: React.ChangeEvent<
																					| HTMLInputElement
																					| HTMLTextAreaElement
																				>
																			) => {
																				this.handlePrivateMileageValuesChange(
																					+args.target.value,
																					'secondPreThresholdAlert'
																				);
																			}}
																			disabled={this.props.readOnly}
																		/>
																	</div>
																	<span
																		style={{
																			marginLeft: 10,
																			display: 'inline-block',
																		}}
																	>
																		{TranslateText('fields.percentOfThreshold')}
																	</span>
																</div>
																<ValidationMessage
																	className={'pre-threshold-validation'}
																	result={
																		this.state.validationResult
																			?.secondPreThresholdAlert
																	}
																/>
															</Box>
														) : null}

														{3 <=
														this.state.alert.PrivateMileageAlertMetadata.preThresholds ? (
															<Box marginBottom={'0.5rem'}>
																<div>
																	<span
																		style={{
																			marginLeft: 20,
																			display: 'inline-block',
																			width: 100,
																		}}
																	>
																		{`${TranslateText('fields.thirdAt')}:`}
																	</span>
																	<div
																		className="form-group"
																		style={{
																			display: 'inline-grid',
																			marginBottom: 0,
																		}}
																	>
																		<TextField
																			id="thirdPreThresholdAlert"
																			type="number"
																			InputLabelProps={{
																				shrink: true,
																			}}
																			InputProps={{
																				inputProps: {
																					min: 0,
																					max: 100,
																					readOnly: this.props.readOnly,
																					style: { fontSize: 12 },
																				},
																			}}
																			name="thirdPreThresholdAlert"
																			value={this.state.alert.PrivateMileageAlertMetadata.thirdPreThresholdAlert.toString()}
																			size="small"
																			style={{ width: 200 }}
																			onKeyPress={(
																				event: React.KeyboardEvent<
																					HTMLInputElement
																				>
																			) => {
																				if (
																					event.key === 'e' ||
																					event.key === '+' ||
																					event.key === '-'
																				) {
																					event.preventDefault();
																				}
																			}}
																			onChange={(
																				args: React.ChangeEvent<
																					| HTMLInputElement
																					| HTMLTextAreaElement
																				>
																			) => {
																				this.handlePrivateMileageValuesChange(
																					+args.target.value,
																					'thirdPreThresholdAlert'
																				);
																			}}
																			disabled={this.props.readOnly}
																		/>
																	</div>
																	<span
																		style={{
																			marginLeft: 10,
																			display: 'inline-block',
																		}}
																	>
																		{TranslateText('fields.percentOfThreshold')}
																	</span>
																</div>
																<ValidationMessage
																	className={'pre-threshold-validation'}
																	result={
																		this.state.validationResult
																			?.thirdPreThresholdAlert
																	}
																/>
															</Box>
														) : null}
														<hr
															style={{
																color: '#cfd0d1',
																backgroundColor: '#cfd0d1',
																height: 0.1,
																borderColor: '#cfd0d1',
															}}
														/>
													</div>
												</div>
												<div className="form-group" id="durationExceededContent">
													<div
														hidden={
															this.state.alert.eventTypeId !==
															AlertTypesEnum.DurationExceeded
														}
													>
														<hr
															style={{
																color: '#cfd0d1',
																backgroundColor: '#cfd0d1',
																height: 0.1,
																borderColor: '#cfd0d1',
															}}
														/>
														<AlertDurationExceeded
															durationExceededMetadata={
																this.state.alert.DurationExceededAlertMetadata
															}
															handleDurationExceededMetadataChange={(
																metadata: DurationExceededAlertMetadata
															) => {
																const newAlert = {
																	...this.state.alert,
																	DurationExceededAlertMetadata: metadata,
																};
																if (
																	newAlert.eventTypeId ===
																		AlertTypesEnum.DurationExceeded &&
																	newAlert.DurationExceededAlertMetadata
																		.relatedToTime &&
																	newAlert.DurationExceededAlertMetadata
																		.evaluationMoment ===
																		EvaluationMomentEnum.EndOfTimeFrame
																) {
																	newAlert['eventFrequency'] =
																		EventFrequencyEnum.Once;
																}
																this.setAndValidateAlert(newAlert);
															}}
														/>
													</div>
												</div>
												<div className="form-group">
													<MaterialAutocomplete
														name="eventFrequency"
														className="material-autocomplete"
														valueId={this.state.alert.eventFrequency}
														dataSource={getAlertFrequencyDatasource()}
														onChange={(args: any) =>
															this.handleValueChange(args.value, 'eventFrequency')
														}
														disabled={
															!(
																!this.props.readOnly &&
																this.props.renderFor === RenderForEnum.Wizard
															) ||
															(this.state.alert.eventTypeId ===
																AlertTypesEnum.DurationExceeded &&
																this.state.alert.DurationExceededAlertMetadata
																	.relatedToTime &&
																this.state.alert.DurationExceededAlertMetadata
																	.evaluationMoment ===
																	EvaluationMomentEnum.EndOfTimeFrame)
														}
														fullWidth={false}
														label={TranslateText('fields.frequencyType')}
													/>
													<ValidationMessage
														result={this.state.validationResult?.eventFrequency}
													/>
												</div>

												<AlertNotificationType
													alert={this.state.alert}
													setAlert={(a) => {
														this.setState(
															{
																alert: a,
															},
															() => {
																this.validator
																	.validate(this.state.alert)
																	.then((result) => {
																		this.setState(
																			{
																				validationResult:
																					result.validationResult,
																			},
																			() => {
																				const formValid =
																					result.formResult &&
																					this.isAlertValid();
																				this.setAlert(formValid, a);
																			}
																		);
																	});
															}
														);
													}}
													readOnly={this.props.readOnly}
												/>
											</div>
										</form>
									</AccordionSection>
								</Accordion>
							</div>
						</div>
						<div hidden={this.state.currentStep !== 2}>
							<TreeSelectionView
								buttonContainerVisible={
									this.state.alert.entityType === AlertEntityTypeEnum.Objects &&
									this.props.assetTracking
								}
								retrieveDataCallback={this.retrieveEntitiesData.bind(this)}
								selectionChanged={this.handleEntitiesSelection.bind(this)}
								enableClientFilter={false}
								filters={this.state.alertEntityTypes}
								filterPlaceholder={
									this.state.alert.entityType === AlertEntityTypeEnum.Drivers
										? TranslateText('fleetSelection.searchDriverPlaceholder')
										: this.props.assetTracking
										? TranslateText('fleetSelection.searchEntitiesPlaceholder')
										: TranslateText('fleetSelection.searchObjectPlaceholder')
								}
							/>
						</div>
						<div hidden={this.state.currentStep !== 3}>
							<TreeSelectionView
								ref={this.personsStepRef}
								buttonContainerVisible={true}
								retrieveDataCallback={this.retrievePersonsData.bind(this)}
								selectionChanged={this.handlePersonsSelections.bind(this)}
								enableClientFilter={false}
								filters={this.getReceiversFilter()}
								filterPlaceholder={TranslateText('fleetSelection.searchPersonDriverPlaceholder')}
								hasSecondButton={true}
								secondButtonText={TranslateText('fleetSelection.addDriversFromEntities')}
								secondButtonCallback={this.addDriversFromEntities.bind(this)}
							/>
						</div>
						<div hidden={this.state.currentStep !== 4}>
							<MessageEditor
								useDefaultMessage={this.state.useDefaultMessage}
								getAccessTokenCallback={this.props.getAccessTokenCallback}
								renderForEntityType={EntityTypeEnum.Alert}
								renderForEntityTypeParams={[this.state.alert.eventTypeId]}
								useNotification={this.state.alert.useNotification}
								usePopup={this.state.alert.usePopup}
								useEmail={this.state.alert.useEmail}
								useSms={this.state.alert.useSms}
								useWhatsApp={this.state.alert.useWhatsApp}
								notificationContent={this.state.alert.notificationContent}
								popupContent={this.state.alert.popupContent}
								emailContent={this.state.alert.mailContent}
								smsContent={this.state.alert.smsContent}
								whatsAppContent={this.state.alert.whatsAppContent}
								messageContentChangedCallback={this.handleMessageContentChange.bind(this)}
								canRender={true}
							/>
						</div>
					</div>
				</div>
			) : (
				<Accordion>
					<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
						{this.props.informationWidget ? this.fillSystemInformation() : null}
					</AccordionSection>
				</Accordion>
			))
		);
	}
}

export default connector(AlertView);
