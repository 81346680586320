import { TreeSelectionGroupDialog } from 'components/TreeSelectionDialog';
import { AlertData } from 'components/Widgets/Views/AlertEntitiesView/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import { useFilterPlaceholder, useInitialTreeData, useTreeDialogEvents } from './hooks';
import { AddEntityToAlertButtonProps } from './types';

const AddEntityToAlertButton = (props: AddEntityToAlertButtonProps): JSX.Element => {
	const [dialogVisible, setDialogVisible] = useState<boolean>(false);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);

	const {
		filterButtons,
		closeDialogCallback,
		showDialogCallback,
		saveSelectionCallback,
		retrieveDataCallback,
	} = useTreeDialogEvents(
		props.entityId,
		(props.currentSelection as AlertData).entityType,
		featuresSettings.driverIdentification,
		featuresSettings.assetTracking,
		(props.currentSelection as AlertData).trackedEntities,
		props.actionExecuted,
		setDialogVisible
	);

	const initialTreeData = useInitialTreeData(props.currentSelection as AlertData);
	const filterPlaceholder = useFilterPlaceholder(
		(props.currentSelection as AlertData).entityType,
		featuresSettings.assetTracking
	);

	return (
		<>
			<Button className="widget-button add-button" onClick={showDialogCallback}>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionGroupDialog
				title={TranslateText('common.addEntities')}
				filterPlaceholder={filterPlaceholder}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={closeDialogCallback}
				saveCallback={saveSelectionCallback}
				retrieveData={retrieveDataCallback}
				filterButtons={filterButtons}
			/>
		</>
	);
};

export default AddEntityToAlertButton;
