import './alertDialog.scss';

import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { TranslateText } from '../../../utils/Translations';

interface Props {
	title: string;
	message: string;
	messages?: string[];
	displayDialog: boolean;
	handleUserResponse: (response: boolean) => void;
	showCancelButton?: boolean;
}

const NotificationPrompt = (props: Props) => {
	return props.displayDialog ? (
		<Dialog className="alert-dialog" open={true}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.message}
					{props.messages ? (
						<span className="multiple-message">
							{props.messages.map((x, i) => (
								<span key={i} className="multiple-message-item">
									{x}
								</span>
							))}
						</span>
					) : null}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					color="primary"
					onClick={(e) => {
						e.stopPropagation();
						props.handleUserResponse(true);
					}}
				>
					{TranslateText('common.buttonOk')}
				</Button>
				{props.showCancelButton && (
					<Button
						size="small"
						onClick={(e) => {
							e.stopPropagation();
							props.handleUserResponse(false);
						}}
					>
						{TranslateText('common.buttonCancel')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	) : null;
};

NotificationPrompt.defaultProps = {
	displayDialog: true,
	showCancelButton: true,
};

export default NotificationPrompt;
