export enum EvaluationMomentEnum {
	Always = 0,
	EndOfTimeFrame = 1,
}

export enum TimeFrameEnum {
	Year = 0,
	Month = 1,
	Week = 2,
	Day = 3,
}

export type EvaluationMomentDropdownProps = {
	valueId: number;
	handleOnEvaluationMomentValueChange: (value: number) => void;
	hideLabel?: boolean;
	disabled?: boolean;
	isForNewAddWizard?: boolean;
};
