import { User } from 'oidc-client';

import { CustomerFeaturesState } from '../store';
import ClaimType from './ClaimType';

export enum ClaimValueOperator {
	or = 0,
	and = 1,
}

export interface ClaimConfig {
	claim: string;
	values?: string[];
	valueOperator?: ClaimValueOperator;
}

export const ClaimValueSeparator = ',';

const ClaimUtil = {
	validateFeature: (featureSettings: CustomerFeaturesState, feature: string) => {
		return !feature || (featureSettings && featureSettings[feature]);
	},

	validateFeatures: (featureSettings: CustomerFeaturesState, features: string[], featureCheckOptions: boolean) => {
		//If featureCheckOptions is true, check if all features are present in featureSettings
		//If featureCheckOptions is false, check if any of the features are present in featureSettings
		return featureCheckOptions
			? !features || (featureSettings && features.every((f) => featureSettings[f]))
			: !features || (featureSettings && features.some((f) => featureSettings[f]));
	},

	validateHasClaim(user: User, claim: string) {
		return ClaimUtil.validateClaim(user, { claim: claim });
	},
	validateHasAllClaims(user: User, claims: string[], values?: string[]) {
		return (
			!claims ||
			ClaimUtil.validateClaimList(
				user,
				claims.map((c) => ({ claim: c, values: values }))
			)
		);
	},
	validateClaims: (user: User, configs: ClaimConfig[]) => {
		if (!configs || !configs.length) {
			return true;
		}
		return configs.every((c) => ClaimUtil.validateClaim(user, c));
	},
	validateOptionalClaims: (user: User, configs: ClaimConfig[]) => {
		if (!configs || !configs.length) {
			return true;
		}
		return configs.some((c) => ClaimUtil.validateClaim(user, c));
	},
	validateClaim: (user: User, config: ClaimConfig) => {
		if (!config || !config.claim) {
			return true;
		}
		if (user && user.profile[config.claim]) {
			if (config.values && config.values.length) {
				const currentClaims = user.profile[config.claim].split(ClaimValueSeparator) as string[];
				if (!config.valueOperator) {
					config.valueOperator = ClaimValueOperator.or;
				}
				switch (config.valueOperator) {
					case ClaimValueOperator.and:
						return config.values.every((v) => currentClaims.some((c) => c === v));
					case ClaimValueOperator.or:
						return config.values.some((v) => currentClaims.some((c) => c === v));
					default:
						throw Error(`unknown claim operator ${config.valueOperator}`);
				}
			}
			return true;
		}
		return false;
	},
	validateClaimList: (user: User, configs: ClaimConfig[]) => {
		return !configs?.length || configs.every((v) => ClaimUtil.validateClaim(user, v));
	},
	validateOptionalClaimList: (user: User, configs: ClaimConfig[]) => {
		return !configs?.length || configs.some((v) => ClaimUtil.validateClaim(user, v));
	},
};

export { ClaimUtil };
