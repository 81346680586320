import './styles.scss';

import React from 'react';
import { Tooltip } from '@material-ui/core';
import { TripInfoWindowSubtitleProps } from './types';
import ObjectNameWithTimeZoneOffset from '../../../ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';

const TripInfoWindowSubtitle = ({ icon, name, isObject }: TripInfoWindowSubtitleProps): JSX.Element => (
	<span className="visit-info-window-subtitle-container">
		{icon}
		<span className="visit-info-window-subtitle-person-name">
			{isObject ? (
				<ObjectNameWithTimeZoneOffset useToolTip objectNameWithTimeZone={name} />
			) : (
				<Tooltip title={name}>
					<span>{name}</span>
				</Tooltip>
			)}
		</span>
	</span>
);

export default TripInfoWindowSubtitle;
