import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../store';
import { commonDataActionCreators } from '../../../store/CommonData';
import MaterialAutocomplete from '../Autocomplete/MaterialAutocomplete';

interface Props {
	valueId?: string;
	name?: string;
	label?: string;
	disabled?: boolean;
	errorMsgContainerId?: string;
	className?: string;
	onChange: (autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => void;
}

const LanguageSelection = (props: Props) => {
	const languagesFetched = useSelector((state: ApplicationState) => state.commonData.languagesFetched);
	const languagesDataSource = useSelector((state: ApplicationState) => state.commonData.languagesDataSource);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!languagesFetched) {
			dispatch(commonDataActionCreators.fetchLanguages());
		}
	}, []);

	return <MaterialAutocomplete {...props} dataSource={languagesDataSource} />;
};

export default LanguageSelection;
