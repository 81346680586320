import { DialogUtil } from 'components/Common/NotificationDialog/NotificationDialog';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { TranslateText } from 'utils/Translations';
import { ITreeSelectionUtil } from './ITreeSelectionUtil';

const GroupMemberSelectionUtil: ITreeSelectionUtil = {
	select: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		if (selectedNode.type === 'Group' || selectedNode.type === 'ReportCategory') {
			GroupMemberSelectionUtil.selectGroup(selectedNode, treeData, setTreeData, selectedItems, setSelectedItems);
		} else {
			GroupMemberSelectionUtil.selectItem(selectedNode, treeData, setTreeData, selectedItems, setSelectedItems);
		}
	},
	selectAll: (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		const selectedItems: ITreeNode[] = [];
		treeData.forEach((data: ITreeNode) => {
			if (data.id) {
				selectedItems.push(data);
				data.selected = true;
			} else if (data?.items.length) {
				data.items.forEach((d: ITreeNode) => {
					selectedItems.push(d);
					d.selected = true;
				});
				data.selected = false;
			}
		});
		setSelectedItems(selectedItems);
		setTreeData([...treeData]);
	},
	selectGroup: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		const node = treeData.find((value) => {
			return value.id === selectedNode.id;
		});
		const toAdd: ITreeNode[] = [];
		const toRemove: ITreeNode[] = [];

		//Calculate children selection based on previous children selection (group is not selectable and is used only to select/unselect all children)
		let childrenSelection = false;
		if (node.items && node.items.some((x) => !x.selected)) {
			childrenSelection = true;
		}

		node.selected = false;
		GroupMemberSelectionUtil.updateSelectionArray(node, toAdd, toRemove);

		if (node.items) {
			node.items = node.items.map((child) => {
				child.selected = childrenSelection;
				GroupMemberSelectionUtil.updateSelectionArray(child, toAdd, toRemove);
				return child;
			});
		}

		GroupMemberSelectionUtil.updateSelectedItems(toAdd, toRemove, selectedItems, setSelectedItems);
	},
	selectItem: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		let node: ITreeNode;
		const toAdd: ITreeNode[] = [];
		const toRemove: ITreeNode[] = [];
		const parentNode = treeData.find((value) => value.id === selectedNode.parentId);
		if (parentNode) {
			node = parentNode.items.find((value) => value.id === selectedNode.id);
			node.selected = parentNode.selected ? false : !node.selected; //Calculate selected value based on current selection or parent selection
			if (parentNode.selected && !node.selected && parentNode.items.length > 1) {
				parentNode.items = parentNode.items.map((childNode) => {
					childNode.selected = childNode.id !== node.id;
					GroupMemberSelectionUtil.updateSelectionArray(childNode, toAdd, toRemove);
					return childNode;
				});
			} else {
				GroupMemberSelectionUtil.updateSelectionArray(node, toAdd, toRemove);
			}
			parentNode.selected = false;
			GroupMemberSelectionUtil.updateSelectionArray(parentNode, toAdd, toRemove);
		} else {
			node = treeData.find((value) => {
				return value.id === selectedNode.id;
			});
			node.selected = !node.selected;
			GroupMemberSelectionUtil.updateSelectionArray(node, toAdd, toRemove);
		}

		GroupMemberSelectionUtil.updateSelectedItems(toAdd, toRemove, selectedItems, setSelectedItems);
	},
	updateSelectionArray: (node: ITreeNode, toAdd: ITreeNode[], toRemove: ITreeNode[]) => {
		if (node.selected) {
			toAdd.push(node);
		} else {
			toRemove.push(node);
		}
	},
	updateSelectedItems: (
		toAdd: ITreeNode[],
		toRemove: ITreeNode[],
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		toAdd.forEach((node) => {
			//Remove children of complete groups
			toRemove = [...toRemove, ...selectedItems.filter((selectedNode) => selectedNode.parentId === node.id)];
		});

		setSelectedItems([
			...selectedItems.filter(
				(item) => !toRemove.some((r) => r.id === item.id) && !toAdd.some((a) => a.id === item.id)
			),
			...toAdd,
		]);
	},

	deselectNode: (
		node: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		let update = false;
		const parentNode = treeData.find((n) => n.id === node.id);
		if (!parentNode) {
			treeData.forEach((n) => {
				if (n.items) {
					const itemNode = n.items.find((i) => i.id === node.id);
					if (itemNode) {
						itemNode.selected = false;
						update = true;
					}
				}
			});
		} else {
			parentNode.selected = false;
			update = true;
			if (parentNode.items) {
				parentNode.items = parentNode.items.map((i) => {
					i.selected = false;
					return i;
				});
			}
		}
		if (update) {
			setTreeData([...treeData]);
		}

		setSelectedItems([...selectedItems.filter((i) => i.id !== node.id)]);
	},

	clearSelection: async (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		if (
			await DialogUtil.confirm({
				content: TranslateText('selectionDialog.clearAllContent'),
				title: TranslateText('selectionDialog.clearAllTitle'),
			})
		) {
			const newData = [...treeData];
			newData.forEach((node) => {
				node.selected = false;
				if (node.items) {
					node.items.forEach((i) => {
						i.selected = false;
					});
				}
			});
			setTreeData(newData);
			setSelectedItems([]);
		}
	},
	prepareInactiveEntities: (
		showInactive: boolean,
		treeData: ITreeNode[],
		selectedItems: ITreeNode[],
		setSelectedItems?: (data: ITreeNode[]) => void
	) => {
		const newSelectedItems: ITreeNode[] = [];
		const nodes: ITreeNode[] = [];
		treeData.forEach((treeNode) => {
			treeNode.items.forEach((dIt) => {
				nodes.push(dIt);
			});
			selectedItems.forEach((selectedItem) => {
				if (selectedItem.id === treeNode.id) {
					newSelectedItems.push(treeNode);
				}
				nodes.forEach((dIt) => {
					if (dIt.id === selectedItem.id && !newSelectedItems.includes(selectedItem)) {
						newSelectedItems.push(selectedItem);
					}
				});
			});
		});
		setSelectedItems(newSelectedItems);
	},
	prepareTreeData: (
		treeData: ITreeNode[],
		selectedItems: ITreeNode[],
		expandedItems: string[],
		setTreeData: (data: ITreeNode[]) => void
	) => {
		const newTreeData: ITreeNode[] = treeData.map((tn) => ({ ...tn }));
		newTreeData.forEach((group) => {
			group.expanded = expandedItems.some((i) => i === group.id);
			group.items.forEach((item) => {
				item.selected = selectedItems.some((s) => s.id === item.id);
			});
			group.selected = selectedItems.some((s) => s.id === group.id);
		});
		setTreeData(newTreeData);
	},
	expandCallback: (node: ITreeNode, expanded: string[], setExpanded: (data: string[]) => void) => {
		if (expanded.includes(node.id)) {
			setExpanded([...expanded.filter((i) => i !== node.id)]);
		} else {
			setExpanded([...expanded, node.id]);
		}
	},
	find: (treeData: ITreeNode[], id: string): ITreeNode | null => {
		if (treeData) {
			for (let i = 0; i < treeData.length; i++) {
				if (treeData[i].id === id) {
					return treeData[i];
				}
				const child = GroupMemberSelectionUtil.find(treeData[i].items, id);
				if (child) {
					return child;
				}
			}
		}
		return null;
	},
	getSelection: (treeData: ITreeNode[]): ITreeNode[] => {
		let result: ITreeNode[] = [];
		treeData.forEach((n) => {
			if (n.selected) {
				result.push(n);
			} else {
				result = [...result, ...n.items.filter((i) => i.selected)];
			}
		});
		return result;
	},
};
export default GroupMemberSelectionUtil;
