import { changeSelectedCustomer } from 'components/CustomerSelection/CustomerUtils';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { ContentState } from 'draft-js';
import GlobalSettings from 'GlobalSettings.json';
import htmlToDraft from 'html-to-draftjs';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationState } from 'store';
import { FleetSelectionDispatcher } from 'store/FleetSelection';
import { SHOW_HISTORY_TRIP_DETAILS } from 'store/HistoryStore';
import { SET_FOCUSED_ENTITY_ID } from 'store/LiveMap';
import { resizableEntityActionCreators, ResizableType } from 'store/ResizableEntityState';
import { rightSideBarCreators } from 'store/RightSideBar';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { getAndSetSavedFleetSelection } from 'utils/FleetSelectionUtils';
import { setStoreForHistoryRedirect } from 'utils/HistoryUtils';
import { queryClientKeys } from 'utils/queryClientKeys';
import { TranslateText } from 'utils/Translations';

import { AlertNotification } from '../types';

const getPlainTextFromHtml = (htmlText: string) => {
	if (htmlText) {
		const draftContent = htmlToDraft(htmlText);
		const contentState = ContentState.createFromBlockArray(draftContent.contentBlocks, draftContent.entityMap);
		return contentState.getPlainText();
	}
	return '';
};

export const useAlertsNotification = () => {
	const showCustomer = useSelector(
		(s: ApplicationState) => s.currentSession.customerLevel !== CustomerLevelEnum.Default
	);

	//notifications
	const { data, isFetching: notificationsLoading } = useQuery(
		queryClientKeys.ALERTS_NOTIFICATION,
		async () => {
			const result = ajaxUtil
				.get<AlertNotification[]>(`${GlobalSettings.notificationsMaintenanceApi}/getActiveAlertsNotification`)
				.then((data) => {
					return data.map((x) => {
						return { ...x, plainTextMessage: getPlainTextFromHtml(x.message) };
					});
				});
			return result;
		},
		{
			staleTime: 180000, //3 min
			cacheTime: 180000, //3 min
			onSuccess: (data) => {
				queryClient.setQueryData(queryClientKeys.ALERTS_NOTIFICATION_NUMBER, {
					alertsNotificationNumber: data.filter((x) => !x.isSeen).length,
				});
			},
		}
	);

	//remove notification
	const queryClient: QueryClient = useQueryClient();
	const { mutate, isLoading: removeLoading } = useMutation(
		(requestData: { notificationIds: string[]; connectionId: string }) => {
			return ajaxUtil.post<{ success: boolean }>(
				`${GlobalSettings.notificationsMaintenanceApi}/removeAlertsNotification`,
				requestData
			);
		},
		{
			onSuccess: (data, requestData) => {
				if (data.success) {
					const alerts = queryClient.getQueryData<AlertNotification[]>(queryClientKeys.ALERTS_NOTIFICATION);
					const newAlerts = alerts.filter((x) => requestData.notificationIds.every((id) => id !== x.id));
					queryClient.setQueryData(queryClientKeys.ALERTS_NOTIFICATION, newAlerts);
					queryClient.setQueryData(queryClientKeys.ALERTS_NOTIFICATION_NUMBER, {
						alertsNotificationNumber: newAlerts.filter((x) => !x.isSeen).length,
					});
				}
			},
		}
	);

	const connectionId = useSelector((s: ApplicationState) => s.notificationsStore.connectionId);
	const removeAlertNotification = (alertIds: string[]) => {
		mutate({ notificationIds: alertIds, connectionId });
	};

	return { notifications: data, notificationsLoading, removeAlertNotification, removeLoading, showCustomer };
};

export const useAlertRedirect = (togglePopup: (open: boolean) => void, setRedirectUrl: (url: string) => void) => {
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const [notificationPrompt, setNotificationPrompt] = useState<{
		title: string;
		message: string;
		handleUserResponse: (response: boolean) => void;
		showCancelButton?: boolean;
	} | null>(null);

	const [redirectLoading, setRedirectLoading] = useState(false);

	const dispatch = useDispatch();

	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	const filteredCustomer = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer);
	const customers = useSelector((state: ApplicationState) => state.availableCustomers.data);

	const fleetSelectionState = useSelector((state: ApplicationState) => state.fleetSelection);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);

	const saveFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveFleetSelection
	);
	const saveTrackedFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveTrackedFleetSelection
	);

	const hasMap = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.map
			: state.currentSession.customer?.featuresSettings.map
	);
	const isMapActive = useSelector((s: ApplicationState) => s.historyStore.historyMapActive);
	const isHistoryMenuCollapsed = useSelector(
		(s: ApplicationState) => s.resizableEntity.resizableMenuHistoryMap.isCollapsed
	);
	const isHistoryGridMenuCollapsed = useSelector(
		(s: ApplicationState) => s.resizableEntity.resizableMenuHistoryGrid.isCollapsed
	);
	const isLiveMenuCollapsed = useSelector((s: ApplicationState) => s.resizableEntity.resizableMenuLive.isCollapsed);

	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);

	const history = useHistory();

	const liveRedirect = (entityId: string) => {
		dispatch(rightSideBarCreators.updateLiveAndHistoryOption(1, 1));
		if (isLiveMenuCollapsed) {
			dispatch(resizableEntityActionCreators.setCollapsed(ResizableType.Live, isLiveMenuCollapsed));
		}
		dispatch({
			type: SET_FOCUSED_ENTITY_ID,
			payload: entityId,
		});
		if (history.location.pathname.toLowerCase() !== GlobalSettings.route.live.toLowerCase()) {
			setRedirectUrl(GlobalSettings.route.live);
		}
		togglePopup(false);
	};
	const historyRedirect = (entityId: string, alertDate: Date | string) => {
		dispatch(rightSideBarCreators.updateLiveAndHistoryOption(1, 1));
		setStoreForHistoryRedirect(
			entityId,
			alertDate,
			hasMap,
			isMapActive,
			hasMap ? isHistoryMenuCollapsed : isHistoryGridMenuCollapsed,
			dispatch
		);

		if (history.location.pathname.toLowerCase() !== GlobalSettings.route.history.toLowerCase()) {
			setRedirectUrl(GlobalSettings.route.history);
		}
		togglePopup(false);
	};

	const notificationRedirect = async (
		newCustomerId: string,
		alertDate: Date | string,
		redirectTo: 'live' | 'history',
		objectId: string,
		personId: string
	) => {
		const redirect = async () => {
			setRedirectLoading(true);

			const entityId = objectId ? objectId : personId;
			const selectionEntity = fleetSelectionState.selectedEntities.find((x) => x.id === entityId);
			const trackedEntity = fleetSelectionState.trackedEntities.find((x) => x.id === entityId);

			//if we already have the entity selected check if the backend request is needed
			if (selectionEntity) {
				if (
					currentSession.customerLevel === CustomerLevelEnum.OEM ||
					currentSession.customerLevel === CustomerLevelEnum.Reseller ||
					(currentSession.customerLevel === CustomerLevelEnum.Default &&
						(!saveFleetSelection ||
							(saveFleetSelection && selectionEntity && !saveTrackedFleetSelection) ||
							(saveFleetSelection && selectionEntity && saveTrackedFleetSelection && trackedEntity)))
				) {
					//if it is selected but not tracked add it to tracked
					FleetSelectionDispatcher.addSelectedAndTrackedEntity(dispatch, selectionEntity);

					if (redirectTo === 'live') {
						liveRedirect(selectionEntity.id);
					} else {
						historyRedirect(selectionEntity.id, alertDate);
					}

					setRedirectLoading(false);
					return;
				}
			}

			const result = await ajaxUtil.post<ITreeNode | null>(
				`${GlobalSettings.fleetSelection}/AlertNotificationRedirect`,
				{
					entityId: objectId ? objectId : personId,
					entityType: objectId ? EntityTypeEnum.Object : EntityTypeEnum.Driver,
				}
			);

			if (result) {
				if (
					currentSession.customerLevel === CustomerLevelEnum.OEM ||
					currentSession.customerLevel === CustomerLevelEnum.Reseller
				) {
					if (filteredCustomer?.id !== newCustomerId) {
						const newCustomer = customers.find((x) => x.value === newCustomerId);
						if (newCustomer) {
							changeSelectedCustomer(newCustomer, customers, dispatch);
						}
					}

					FleetSelectionDispatcher.addSelectedAndTrackedEntity(dispatch, result);

					if (redirectTo === 'live') {
						liveRedirect(result.id);
					} else {
						historyRedirect(result.id, alertDate);
					}
				} else {
					if (saveFleetSelection) {
						const trackedEntities = [...fleetSelectionState.trackedEntities];

						if (!saveTrackedFleetSelection) {
							trackedEntities.push(result);
							trackedEntities.sort((a: ITreeNode, b: ITreeNode) => {
								return a.text.localeCompare(b.text);
							});
						}

						await getAndSetSavedFleetSelection(
							newCustomerId,
							featuresSettings.driverIdentification,
							featuresSettings.assetTracking,
							saveTrackedFleetSelection,
							fleetSelectionState.showInactive,
							fleetSelectionState.selectedEntities,
							trackedEntities,
							dispatch
						);
					} else {
						FleetSelectionDispatcher.addSelectedAndTrackedEntity(dispatch, result);
					}

					if (redirectTo === 'live') {
						liveRedirect(result.id);
					} else {
						historyRedirect(result.id, alertDate);
					}
				}
			} else {
				setNotificationPrompt({
					title: TranslateText('notifications.entityNotAvailableTitle'),
					message: TranslateText('notifications.entityNotAvailableMessage'),
					showCancelButton: false,
					handleUserResponse: () => {
						setNotificationPrompt(null);
					},
				});
			}

			setRedirectLoading(false);
		};

		if (!tripUnsavedData && !unsavedData) {
			redirect();
		} else {
			setNotificationPrompt({
				title: TranslateText('common.titleUnsavedData'),
				message: TranslateText('notificationMessages.unsavedData'),
				showCancelButton: true,
				handleUserResponse: (response) => {
					if (response) {
						if (tripUnsavedData) {
							dispatch({
								type: SHOW_HISTORY_TRIP_DETAILS,
								payload: null,
							});
						}
						if (unsavedData) {
							dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
						}

						setTimeout(() => {
							redirect();
						}, 0);
					}

					setNotificationPrompt(null);
				},
			});
		}
	};

	return { notificationRedirect, redirectLoading, notificationPrompt };
};
