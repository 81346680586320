enum TemplateUnitOfMeasureEnum {
	Unknown = 0,
	Percentage = 1,
	Temperature = 2,
	Voltage = 3,
	Humidity = 4,
	Pressure = 5,
	Distance = 6,
	Time = 7,
}

export default TemplateUnitOfMeasureEnum;
