export class UpdateObservable {
	subscribers: { id: string; callback: () => void }[];

	constructor() {
		this.subscribers = [];
	}

	public subscribe(id: string, callback: () => void): () => void {
		const subscriber = { id, callback };
		this.subscribers.push(subscriber);

		return () => this.unsubscribe(subscriber);
	}

	public unsubscribe(subscriber: { id: string; callback: () => void }) {
		const index = this.subscribers.findIndex((x) => x === subscriber);
		if (index >= 0) {
			this.subscribers.splice(index, 1);
		}
	}

	public publishAll() {
		this.subscribers.forEach((subscriber) => subscriber.callback && subscriber.callback());
	}

	public publishTo(id: string) {
		this.subscribers.forEach((subscriber) => {
			if (subscriber.id === id) {
				subscriber.callback && subscriber.callback();
			}
		});
	}
}
