import './historyTabHeader.scss';

import { Checkbox } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../../store';
import { TrackedEntityModel } from '../../HistoryTabs/types';

interface Props {
	details: TrackedEntityModel;
	isSelected: boolean;
	removeTab: () => void;
	selectCallback: () => void;
	checkCallback: (id: string, checked: boolean) => void;
	checked: boolean;
	firstSelectedTabId?: string;
}

const HistoryTabHeader = (props: Props) => {
	const tabRef = useRef<HTMLDivElement>(null);
	const isDriverWithNoAuthorization = useSelector(
		(state: ApplicationState) => state.currentSession.isDriverWithNoAuthorization
	);

	useEffect(() => {
		if (props.details.entityId === props.firstSelectedTabId && tabRef.current) {
			if ((tabRef.current as any).scrollIntoViewIfNeeded) {
				(tabRef.current as any).scrollIntoViewIfNeeded();
			} else if (tabRef.current.scrollIntoView) {
				tabRef.current.scrollIntoView();
			}
		}
	}, [props.firstSelectedTabId]);

	return (
		<div
			ref={tabRef}
			className={'tab-header-container' + (props.isSelected ? ' selected' : '')}
			onClick={props.selectCallback}
		>
			<div
				className={'tab-header pop-in-animation'}
				style={isDriverWithNoAuthorization ? { cursor: 'default' } : null}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					{!isDriverWithNoAuthorization && (
						<Checkbox
							color={'primary'}
							checked={props.checked}
							onChange={() => {
								props.checkCallback(props.details.entityId, !props.checked);
							}}
						/>
					)}
				</div>
				<div className={`e-list-icon inline-block ${props.details.entityType}`} />
				<span className="normal-text">{props.details.entityName}</span>
				{!isDriverWithNoAuthorization && (
					<i
						onClick={(e) => {
							e.stopPropagation();
							props.removeTab();
						}}
						className="material-icons with-hover remove-tab-icon"
					>
						close
					</i>
				)}
			</div>
		</div>
	);
};

export default HistoryTabHeader;
