import { PersonHistorySettingsDto } from 'components/PersonSettings/PersonHistorySettings/PersonHistorySettings';
import { LiveSettings } from 'components/Widgets/CustomerLiveSettingsView';
import { PersonLiveSettings } from 'components/Widgets/PersonLiveSettingsView';

import GlobalSettings from '../GlobalSettings.json';
import FilterEntityType from '../models/FilterEntityType';
import ajaxUtil from '../utils/Ajax';
import { FleetEntityTypeFilter } from './FleetSelection';
import { CurrentSession, CustomerHistorySettings } from './index';

const SET_USER = 'SET_USER';
const SET_GROUP_AUTHORISATION = 'SET_GROUP_AUTHORISATION';
const SET_CUSTOMER_LIVE_SETTINGS = 'SET_CUSTOMER_LIVE_SETTINGS';
const SET_PERSON_LIVE_SETTINGS = 'SET_PERSON_LIVE_SETTINGS';
const SET_CUSTOMER_HISTORY_SETTINGS = 'SET_CUSTOMER_HISTORY_SETTINGS';
const SET_PERSON_HISTORY_SETTINGS = 'SET_PERSON_HISTORY_SETTINGS';
const SET_CURRENT_CUSTOMER_FEATURES = 'SET_CURRENT_CUSTOMER_FEATURES';

const initialState: CurrentSession = {
	currentSessionId: null,
	aspNetUserId: null,
	personId: null,
	isDriver: false,
	isDriverWithNoAuthorization: false,
	hasGroupAuthorisation: false,
	groupAuthorisationLastCheck: null,
	userFirstAndLastName: null,
	email: null,
	userFirstAndLastNameAbbreviation: null,
	startCountryBoundaries: null,
	startCountryName: null,
	customerId: null,
	customer: null,
	customerLevel: null,
	defaultLines: 0,
	dateTimeSettings: null,
	reportsSettings: null,
	fleetOverviewSettings: null,
	customerLiveSettings: null,
	personLiveSettings: null,
	customerHistorySettings: null,
	personHistorySettings: null,
	startPage: null,
	mainMenuExpanded: true,
	defaultEntity: FleetEntityTypeFilter.all,
	customerTimeZoneMinutesOffset: 0,
};

export const userDataActionCreators = {
	getUserData: (userId: string) => async (dispatch: any) => {
		await ajaxUtil.get(`${GlobalSettings.personsMaintenanceApi}/${userId}`).then((obj: any) => {
			ajaxUtil.get(`${GlobalSettings.validateApi}/${obj.id}/hasGroupsAuthorization`).then((result: any) => {
				dispatch({
					...obj,
					type: SET_USER,
					personId: obj.id,
					isDriver: obj.driver,
					userFirstAndLastName: obj.firstName + ' ' + obj.lastName,
					userFirstAndLastNameAbbreviation: obj?.firstName[0] + obj?.lastName[0],
					isDriverWithNoAuthorization: obj.driver && !result,
					hasGroupAuthorisation: result,
				});
			});
		});
	},
	setGroupAuthorisation: (hasGroupAuthorisation: boolean) => async (dispatch: any) => {
		dispatch({
			type: SET_GROUP_AUTHORISATION,
			hasGroupAuthorisation: hasGroupAuthorisation,
		});
	},
	setCustomerFeatures: (features: any) => (dispatch: any) => {
		dispatch({
			type: SET_CURRENT_CUSTOMER_FEATURES,
			features
		});
	},
	setCustomerLiveSettings: (customerLiveSettings: LiveSettings) => (dispatch: any) => {
		dispatch({
			type: SET_CUSTOMER_LIVE_SETTINGS,
			customerLiveSettings,
		});
	},
	setPersonLiveSettings: (personLiveSettings: PersonLiveSettings) => (dispatch: any) => {
		dispatch({
			type: SET_PERSON_LIVE_SETTINGS,
			personLiveSettings,
		});
	},
	setCustomerHistorySettings: (customerHistorySettings: CustomerHistorySettings) => (dispatch: any) => {
		dispatch({
			type: SET_CUSTOMER_HISTORY_SETTINGS,
			customerHistorySettings,
		});
	},
	setPersonHistorySettings: (personHistorySettings: PersonHistorySettingsDto) => (dispatch: any) => {
		dispatch({
			type: SET_PERSON_HISTORY_SETTINGS,
			personHistorySettings,
		});
	},
};
export const CurrentSessionReducer = (state: CurrentSession, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				currentSessionId: action.currentSessionId,
				aspNetUserId: action.aspNetUserId,
				personId: action.personId,
				isDriver: action.isDriver,
				isDriverWithNoAuthorization: action.isDriverWithNoAuthorization,
				userFirstAndLastName: action.userFirstAndLastName,
				email: action.email,
				customerLiveSettings: action.customerLiveSettings,
				personLiveSettings: action.personLiveSettings,
				userFirstAndLastNameAbbreviation: action.userFirstAndLastNameAbbreviation,
				startCountryName: action.startCountryName,
				startCountryBoundaries: action.startCountryBoundaries,
				customerId: action.customerId,
				customer: action.customer,
				mainMenuExpanded: action.mainMenuExpanded,
				customerLevel: action.customerLevel,
				defaultLines: action.defaultLines,
				dateTimeSettings: action.dateTimeSettings,
				reportsSettings: action.reportsSettings,
				fleetOverviewSettings: action.fleetOverviewSettings,
				customerHistorySettings: action.customerHistorySettings,
				defaultEntity: action.defaultEntity as FilterEntityType,
				startPage: action.startPage,
				personHistorySettings: action.personHistorySettings,
				hasGroupAuthorisation: action.hasGroupAuthorisation,
				groupAuthorisationLastCheck: new Date(),
				customerTimeZoneMinutesOffset: action.customerTimeZoneMinutesOffset,
			};
		case SET_GROUP_AUTHORISATION:
			return {
				...state,
				isDriverWithNoAuthorization: state.isDriver && !action.hasGroupAuthorisation,
				hasGroupAuthorisation: action.hasGroupAuthorisation,
				groupAuthorisationLastCheck: new Date(),
			};
		case SET_CURRENT_CUSTOMER_FEATURES:
			let tempState = {...state};
			tempState.customer.featuresSettings = {
				...tempState.customer.featuresSettings,
				...action?.features
			};
			return tempState;
		case SET_CUSTOMER_LIVE_SETTINGS:
			state.customerLiveSettings = action.customerLiveSettings;
			return state;
		case SET_PERSON_LIVE_SETTINGS:
			state.personLiveSettings = action.personLiveSettings;
			return state;
		case SET_CUSTOMER_HISTORY_SETTINGS:
			state.customerHistorySettings = action.customerHistorySettings;
			return state;
		case SET_PERSON_HISTORY_SETTINGS:
			state.personHistorySettings.useHistoryLocationFields =
				action.personHistorySettings.useHistoryLocationFields;
			state.personHistorySettings.useHistoryLocationOnMap = action.personHistorySettings.useHistoryLocationOnMap;
			state.personHistorySettings.showTemplateStateOnMap = action.personHistorySettings.showTemplateStateOnMap;
			return state;
		default:
			return state;
	}
};
