import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import DevicesLogsColumn from 'components/GridOverview/Columns/DevicesLogsColumn';
import NumberColumn from 'components/GridOverview/Columns/NumberColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';
import MultiSelectionColumn, {
	ColumnFlagOrdering, computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from '../GridOverview/Columns/MultiSelectionColumn';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';

interface Props {
	history: History<LocationState>;
}

interface ObjectDto extends GridEntity {
	customerId: string;
	customer: string;
	active: boolean;
	deviceId: string;
	deviceCode: string;
	simCardIMSI: string;
	code: string;
	simCardId: string;
	activationDate: string;
	objectFunction: ObjectFunctionEnum;
	timeOutEndOfTrip: number;
	mileage: number;
}

const fieldNames = {
	name: 'name',
	customer: 'customer',
	code: 'code',
	externalCode: 'externalCode',
	licensePlate: 'licensePlate',
	mileage: 'mileage',
	timezone: 'timezone',
	active: 'active',
	deviceCode: 'deviceCode',
	simCardIMSI: 'simCardIMSI',
	simCardPhoneNumber: 'simCardPhoneNumber',
	timeOutEndOfTrip: 'timeOutEndOfTrip',
	toleranceTimeOutDistance: 'toleranceTimeOutDistance',
	objectFunction: 'objectFunction',
};


const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.customer]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.externalCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.licensePlate]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.deviceCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.simCardIMSI]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.simCardPhoneNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.timeOutEndOfTrip]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.toleranceTimeOutDistance]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.mileage]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.objectFunction]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const ObjectsOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const loggedInCustomerLevel = useSelector((s: ApplicationState) => s.currentSession.customerLevel);
	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);

	const objectFunctionSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: TranslateText('objects.objectFunction.Full'),
				code: ObjectFunctionEnum.Full.toString(),
			},
			{
				name: TranslateText('objects.objectFunction.Asset'),
				code: ObjectFunctionEnum.Asset.toString(),
			},
		],
		[]
	);

	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.objectFunction]: computeFlagOrderingBasedOnTranslation(objectFunctionSelectOptions),
		}),
		[]
	);

	const columns = [
		TextColumn({
			fieldName: fieldNames.customer,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (rowData: ObjectDto) => (
				<Link title={rowData.customer} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customer}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Customers,
				values: [ClaimValue.view],
			}),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.externalCode,
			header: TranslateText('maintenanceOverview.grid.colExternalCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.licensePlate,
			header: TranslateText('maintenanceOverview.grid.colLicensePlate'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),

		TextColumn({
			fieldName: fieldNames.timezone,
			header: TranslateText('maintenanceOverview.grid.colTimeZone'),
			sortable: true,
		}),
		TextColumn({
			fieldName: fieldNames.deviceCode,
			header: TranslateText('maintenanceOverview.grid.colDeviceCode'),
			sortable: true,
			filterable: true,
			body: (rowData: ObjectDto) => (
				<Link title={rowData.deviceCode} to={`${GlobalSettings.route.devices}/${rowData.deviceId}`}>
					{rowData.deviceCode}
				</Link>
			),
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Devices,
				values: [ClaimValue.view],
			}),
		}),
		DevicesLogsColumn({ entity: 'object' }),
		TextColumn({
			fieldName: fieldNames.simCardIMSI,
			header: TranslateText('maintenanceOverview.grid.colSimCardIMSI'),
			sortable: true,
			filterable: true,
			body: (rowData: ObjectDto) => (
				<Link title={rowData.simCardIMSI} to={`${GlobalSettings.route.simcards}/${rowData.simCardId}`}>
					{rowData.simCardIMSI}
				</Link>
			),
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.SimCards,
				values: [ClaimValue.view],
			}),
		}),
		TextColumn({
			fieldName: fieldNames.simCardPhoneNumber,
			header: TranslateText('maintenanceOverview.grid.colSimCardPhoneNumber'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.SimCards,
				values: [ClaimValue.view],
			}),
		}),
		NumberColumn({
			fieldName: fieldNames.mileage,
			header: TranslateText('maintenanceOverview.grid.colMileageKm'),
			sortable: true,
			filterable: true,
			maxFractionDigits: 1,
			defaultWidth: 155,
			body: (rowData: ObjectDto) => (
				<span>{rowData.objectFunction === ObjectFunctionEnum.Full ? rowData.mileage : ''}</span>
			),
		}),
		NumberColumn({
			fieldName: fieldNames.timeOutEndOfTrip,
			header: TranslateText('maintenanceOverview.grid.colTimeOutEndTripSec'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Objects,
				values: [ClaimValue.edit],
			}),
			defaultWidth: 95,
			body: (rowData: ObjectDto) => (
				<span>{rowData.objectFunction === ObjectFunctionEnum.Full ? rowData.timeOutEndOfTrip : ''}</span>
			),
		}),
		NumberColumn({
			fieldName: fieldNames.toleranceTimeOutDistance,
			header: TranslateText('maintenanceOverview.grid.colToleranceTimeOutDistanceMeter'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Objects,
				values: [ClaimValue.edit],
			}),
			defaultWidth: 95,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.objectFunction,
			header: TranslateText('maintenanceOverview.grid.colObjectFunction'),
			sortable: true,
			filterable: true,
			body: (rowData: ObjectDto) => {
				const enumValue = ObjectFunctionEnum[rowData.objectFunction];
				const objectFunction = TranslateText(`objects.objectFunction.${enumValue}`);
				return <span title={objectFunction}>{objectFunction}</span>;
			},
			selectOptions: objectFunctionSelectOptions,
			shown: assetTracking || loggedInCustomerLevel !== CustomerLevelEnum.Default,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.objectsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.objects')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default}
			showAddConfigClaim={[
				{
					claim: ClaimType.Objects,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Object}
			useNewAddWizard
			addEntityUrl={GlobalSettings.objectsMaintenanceApi + '/addObjectFromWizard'}
			columnFlagOrder={columnFlagOrdering}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={false}
			showExportConfigClaim={[
				{
					claim: ClaimType.Objects,
					values: [ClaimValue.view],
				},
				{
					claim: ClaimType.Export,
					values: [ClaimValue.objects]
				}
			]}
		/>
	);
};

export default ObjectsOverview;
