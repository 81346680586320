import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ApplicationState } from '../../../store';
import { unsavedDataStoreActionCreators } from '../../../store/UnsavedDataStore';
import { TranslateText } from '../../../utils/Translations';
import NavigationPrompt from './NavigationPrompt';
import NotificationPrompt from './NotificationPrompt';

const NotificationPromptWrapper = () => {
	const showNotificationPrompt = useSelector((s: ApplicationState) => s.unsavedDataStore.showNotificationPrompt);
	const promptCallback = useSelector((s: ApplicationState) => s.unsavedDataStore.promptCallback);
	const unsavedData = useSelector((s: ApplicationState) => s.unsavedDataStore.unsavedData);
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.unsavedData')}
				handleUserResponse={(response) => {
					dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(false));
					if (response) {
						promptCallback();
						dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
						dispatch(unsavedDataStoreActionCreators.setPromptCallback(null));
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			<NavigationPrompt unsavedData={unsavedData} history={history} />
		</>
	);
};

export default NotificationPromptWrapper;
