import './styles.scss';

import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, InputLabel, TextField } from '@material-ui/core';

import { MaterialTextFieldProps } from './types';

const MaterialTextField = (props: MaterialTextFieldProps): JSX.Element => {
	const recheckInputValue = useCallback(() => {
		return props.value != null ? props.value : '';
	}, [props.value]);

	const [inputValue, setInputValue] = useState<string>(() => {
		return recheckInputValue();
	});

	const [defaultValue, setDefaultValue] = useState<boolean>(false);
	useEffect(() => {
		setInputValue(recheckInputValue());
		if (props.value === '' || props.value === null) setDefaultValue(false);
		else setDefaultValue(true);
	}, [props.value]);

	const hasChanges = useRef<boolean>(false);
	useEffect(() => {
		if (!props.disabled) {
			hasChanges.current = false;
		}
	}, [props.disabled]);

	return (
		<>
			{props.isForNewAddWizard && (
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<InputLabel
						style={{
							color: props.disabled ? 'grey' : 'black',
							fontSize: defaultValue || props.value || inputValue ? 10 : 12,
							marginBottom: 4,
							transition: 'font-size 0.25s ease-in-out',
						}}
					>
						{props.label}
					</InputLabel>
					<Box marginLeft="10px">
						{!!props.validationResult && (
							<ValidationMessage result={props.validationResult} isForNewAddWizard={true} />
						)}
					</Box>
				</Box>
			)}
			<Box display="flex" alignItems="center">
				<TextField
					autoFocus={props.autoFocus}
					id={props.id}
					type={props.type ?? 'text'}
					className={`material-text-field ${props.className ? props.className : ''}`}
					fullWidth={props.fullWidth ?? true}
					label={props.isForNewAddWizard ? null : props.label}
					inputProps={props.inputProps}
					name={props.name}
					value={inputValue}
					onBlur={(args) => {
						const newInputValue = args.target.value?.trim();
						setInputValue(newInputValue);
						if (props.handleValueChange && hasChanges.current) {
							props.handleValueChange(newInputValue);
						}
					}}
					onChange={(args) => {
						setInputValue(args.target.value);
						hasChanges.current = true;
					}}
					onKeyPress={props.onKeyPress}
					onKeyDown={props.onKeyDown}
					onWheel={props.onWheel}
					disabled={props.disabled}
					onFocus={props?.handleFocus}
					multiline={props?.multiline}
					rows={props?.rows ?? undefined}
					variant={props?.variant ?? 'standard'}
					error={
						props.error ||
						(props.isForNewAddWizard &&
							!!props.validationResult &&
							!!Object.keys(props.validationResult).find((r) => !props.validationResult[r].valid))
					}
				/>
				{props.rightIcon && <Box marginLeft="auto">{props.rightIcon}</Box>}
			</Box>
			{!props.isForNewAddWizard && !!props.validationResult && (
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box>
						<ValidationMessage result={props.validationResult} isForNewAddWizard={false} />
					</Box>
				</Box>
			)}
		</>
	);
};

export default React.memo(MaterialTextField);
