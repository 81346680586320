import { DataTableFilterMeta } from 'primereact/datatable';

export enum SortOrder {
	Ascendent = 1,
	Descendent = -1,
	NoSort = 0,
}

export enum MatchMode {
	StartsWith = 'startsWith',
	Contains = 'contains',
	NotContains = 'notContains',
	EndsWith = 'endsWith',
	Equals = 'equals',
	NotEquals = 'notEquals',
	LessThan = 'lt',
	LessThanOrEqual = 'lte',
	GreaterThan = 'gt',
	GreaterThanOrEqual = 'gte',
	DateIs = 'dateIs',
	DateIsNot = 'dateIsNot',
	DateBefore = 'dateBefore',
	DateAfter = 'dateAfter',
	In = 'in',
}

export interface GridFilters {
	sortField: string;
	sortOrder: SortOrder;
	filters: DataTableFilterMeta;
	flagOrder?: string;
	includeTime?: boolean;
}

export interface FieldFilter {
	name: string;
	filter: string;
	matchMode: MatchMode;
}
