import './styles.scss';

import { ClaimUtil } from 'authorization/ClaimUtil';
import EasyTrackFeature from 'authorization/EasyTrackFeature';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import {
	extendObjectNameWithTimeZoneLongFormat,
	shouldNotDisplayTimeZone,
} from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { MARKER_URL } from 'Constants';
import FuelTypeEnum from 'models/FuelTypeEnum';
import { RowData } from 'models/LiveMenuUtils';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeCounter from 'shared/components/TimeCounter';
import {
	useLocationsLive,
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { FormatDate, FormatDateWithReferenceDate } from 'utils/DateUtils';
import { googleMapLibraries, googleMapScriptId } from 'utils/MapUtils';
import {
	getDisplayAnalogSensorValue,
	getDisplayAnalogSensorValueAndState,
	getDisplayDigitalSensorDuration
} from 'utils/SensorsUtils';
import { TranslateText } from 'utils/Translations';

import {Add, CenterFocusStrongOutlined, EditOutlined, Mail, Phone, Restore, Search } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { ReactComponent as DrivingSvgImage } from '../../../../../assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as IdleSvgImage } from '../../../../../assets/icons/Idle.svg';
import { ReactComponent as ParkedSvgImage } from '../../../../../assets/icons/Parked.svg';
import { ReactComponent as UnknownSvgIcon } from '../../../../../assets/icons/Unknown.svg';
import ClaimType, {ClaimValue} from '../../../../../authorization/ClaimType';
import GlobalSettings from '../../../../../GlobalSettings.json';
import { StateFilterEnum } from '../../../../../models/LiveMenuUtils';
import PrivacySettingsEnum from '../../../../../models/PrivacySettingsEnum';
import TripTypesEnum from '../../../../../models/TripTypesEnum';
import {LatLng, liveMapActionCreators} from '../../../../../store/LiveMap';
import ajaxUtil from '../../../../../utils/Ajax';
import { useTripFullTimeZoneInfo } from '../SummaryTabHistory/hooks';
import { ObjectTemplatesDuration } from '../types';
import { LiveExtendedMenuCard } from './LiveExtendedMenuCard';
import { Row } from './LiveExtendedMenuCard/LiveExtendedMenuCard';
import { IconButton } from '@material-ui/core';
import LocationDialog from "../../../../LocationPopup";
import {
	adjustDateForDialog,
	adjustDateToUtcForDialog,
	LocationDialogDataDto
} from "../../../../LocationPopup/LocationDialog";

type Props = {
	hidden: boolean;
	goToHistoryTab: () => void;
};

type TripDetails = RowData & {
	mileage: number;
};

const CONTROL_POSITION_RIGHT_BOTTOM = 9;

const mapOptions = {
	mapId: GlobalSettings.googleMapId,
	clickableIcons: false,
	gestureHandling: 'greedy',
	disableDoubleClickZoom: true,
	zoomControl: true,
	streetViewControl: true,
	zoomControlOptions: {
		position: CONTROL_POSITION_RIGHT_BOTTOM,
	},
	streetViewControlOptions: {
		position: CONTROL_POSITION_RIGHT_BOTTOM,
	},
} as google.maps.MapOptions;

const OverallTab = ({ hidden, goToHistoryTab }: Props) => {
	const tripDetails = useSelector((s: ApplicationState) => s.liveMap.tripDetails) as TripDetails;
	const userId = useSelector((s: ApplicationState) => s.currentSession.personId);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const customerPrivacySettings = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.privacySettings
			: s.currentSession.customer.featuresSettings.privacySettings
	);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const featuresSettings = useSelector(
		(state: ApplicationState) => state?.currentSession?.customer?.featuresSettings
	);
	const customerTimezoneId = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);
	const customerTimeZoneMinutesOffset = useSelector(
		(s: ApplicationState) => s.currentSession.customerTimeZoneMinutesOffset
	);

	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();
	const showLiveLocations = useLocationsLive();
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	const [stateRows, setStateRows] = useState<Row[]>([]);
	const [timeRows, setTimeRows] = useState<Row[]>([]);
	const [informationRows, setInformationRows] = useState<Row[]>([]);
	const [objectRows, setObjectRows] = useState<Row[]>([]);
	const [templateRows, setTemplateRows] = useState<Row[]>([]);
	const [driverRows, setDriverRows] = useState<Row[]>([]);
	const [totalPrivateMileage, setTotalPrivateMileage] = useState(0);
	const [locationDialogData, setLocationDialogData] = useState<LocationDialogDataDto>({
		display: false,
		isForAdd: false,
		selectedLocationId: null,
		latLng: null,
		startDate: null
	});

	const { isLoaded } = useJsApiLoader({
		id: googleMapScriptId,
		mapIds: [GlobalSettings.googleMapId],
		libraries: googleMapLibraries,
		version: '3',
		googleMapsClientId: GlobalSettings.googleMapId,
		googleMapsApiKey: GlobalSettings.googleMapsApiKey,
		language: loginInLanguage,
	});

	const { tripTimezone } = useTripFullTimeZoneInfo(tripDetails.timeZoneId, tripDetails.objectNameWithTimeZoneOffset);

	useEffect(() => {
		const url = `${GlobalSettings.globalDataApi}/driverPrivateMileageForCurrentYear/${tripDetails.id}`;
		ajaxUtil.get<number>(url).then((result: number) => {
			setTotalPrivateMileage(result);
		});
	}, [tripDetails]);

	useEffect(() => {
		setStateRows([
			{
				label: TranslateText('liveMap.address'),
				content: tripDetails.address,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.location'),
				content: showLiveLocations ? tripDetails.locationName : null,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.speed'),
				content:
					tripDetails.state === StateFilterEnum.Driving
						? parseInt(tripDetails.speed).toFixed(0) + ' Km/h'
						: null,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.heading'),
				content: tripDetails.state === StateFilterEnum.Driving ? tripDetails.direction : null,
			},

			{
				label: TranslateText('liveExtendedMenuOverallTab.tripType'),
				content:
					tripDetails.state === StateFilterEnum.Driving && trackTypeSpecification
						? TranslateText(`common.${TripTypesEnum[tripDetails.tripType].toLowerCase()}`)
						: null,
			},
		]);

		let timeRows: Row[] = [];

		if (
			!tripDetails.isTripDevice ||
			customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData ||
			tripDetails.id === userId ||
			user?.profile[ClaimType.ShowPrivateTrips]
		) {
			let since = null;
			if (tripDetails.since) {
				since = moment(tripDetails.since).utcOffset(tripDetails.timeZoneMinutesOffset);
				since = FormatDate(since.toString(), false, false, true);
			}

			let lastSeen = null;
			if (tripDetails.lastSeen) {
				lastSeen = moment(tripDetails.lastSeen).utcOffset(customerTimeZoneMinutesOffset);
				lastSeen = FormatDate(lastSeen.toString(), false, false, true);
				lastSeen = extendObjectNameWithTimeZoneLongFormat(
					lastSeen,
					customerTimeZoneMinutesOffset,
					!shouldNotDisplayTimeZone(customerTimezoneId, tripDetails.timeZoneId)
				);
			}

			const stateStart = moment(tripDetails.stateStart)
				.utcOffset(tripDetails.timeZoneMinutesOffset)
				.toString();

			timeRows = [
				{
					label: TranslateText(
						`liveExtendedMenuOverallTab.${StateFilterEnum[tripDetails.state].toLowerCase()}`
					),
					content:
						tripDetails.stateStart !== null ? (
							<>
								<TimeCounter
									hidden={false}
									referenceDate={new Date(tripDetails.stateStart)}
									refreshRateInMilliseconds={2000}
									showSeconds={false}
								/>
								&nbsp;(
								{FormatDateWithReferenceDate(stateStart, Date(), true)})
							</>
						) : null,
				},
				{
					label: TranslateText('liveExtendedMenuOverallTab.since'),
					content: since,
				},
			];
			if (!shouldNotDisplayTimeZone(customerTimezoneId, tripDetails.timeZoneId)) {
				timeRows.push({
					label: TranslateText('maintenanceOverview.grid.colTimeZone'),
					content: tripTimezone,
				});
			}
			timeRows.push({
				label: TranslateText('liveExtendedMenuOverallTab.lastSeen'),
				content: lastSeen && (
					<ObjectNameWithTimeZoneOffset objectNameWithTimeZone={lastSeen} useToolTip={false} />
				),
			});
		}

		setTimeRows(timeRows);

		if (driverIdentification && (tripDetails.state !== StateFilterEnum.Parked || showLastDriverOfParkedObject)) {
			setInformationRows([
				{
					icon: <PersonIcon />,
					content: tripDetails.personName ? tripDetails.personName : null,
				},
				{
					icon: <Phone />,
					content: tripDetails.phoneNumber ? (
						<a href={`tel:${tripDetails.phoneNumber}`}>{tripDetails.phoneNumber}</a>
					) : null,
				},
				{
					icon: <Mail />,
					content: tripDetails.email ? <a href={`mailto:${tripDetails.email}`}>{tripDetails.email}</a> : null,
				},
			]);
		} else if (informationRows.length) {
			setInformationRows([]);
		}

		setDriverRows([
			{
				icon: <PersonIcon />,
				content: tripDetails.personName,
			},
			{
				icon: <Phone />,
				content: tripDetails.phoneNumber ? (
					<a href={`tel:${tripDetails.phoneNumber}`}>{tripDetails.phoneNumber}</a>
				) : null,
			},
			{
				icon: <Mail />,
				content: tripDetails.email ? <a href={`mailto:${tripDetails.email}`}>{tripDetails.email}</a> : null,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.mileage'),
				content:
					tripDetails.state === StateFilterEnum.Parked && showLastObjectOfParkedDriver
						? tripDetails.mileage?.toFixed(1)
						: null,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.totalPrivateMileage'),
				content:
					customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData ||
					tripDetails.id === userId ||
					user?.profile[ClaimType.ShowPrivateTrips]
						? totalPrivateMileage
						: null,
			},
			{
				label: TranslateText('common.homeAddress'),
				content:
					customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData ||
					tripDetails.id === userId ||
					user?.profile[ClaimType.ShowPrivateTrips]
						? tripDetails.personAddress
						: null,
			},
		]);

		const objectRows = [
			{
				label: TranslateText('liveExtendedMenuOverallTab.mileage'),
				content: tripDetails.state === StateFilterEnum.Parked ? tripDetails.mileage?.toFixed(1) : null,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.objectType'),
				content: TranslateText(`objects.objectType.${ObjectTypeEnum[tripDetails.objectType]}`),
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.licensePlate'),
				content: tripDetails.licensePlate,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.vin'),
				content: tripDetails.vin,
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.brandAndModel'),
				content:
					tripDetails?.brand !== null && tripDetails?.model !== null
						? (tripDetails.brand + ' ' + tripDetails.model).trim()
						: '',
			},
			{
				label: TranslateText('liveExtendedMenuOverallTab.fuelType'),
				content: TranslateText(`objects.fuelType.${FuelTypeEnum[tripDetails.fuelType]}`),
			},
		];

		if (!tripDetails.isTripDevice && tripDetails?.sensorTemplatesData?.length) {
			setObjectRows(
				[
					...tripDetails.sensorTemplatesData.map(
						(otd: ObjectTemplatesDuration) =>
							({
								label: otd.templateName,
								displayEllipsis: true,
								content: Math.floor(otd.templateCorrectedValue / 60), //display template value in hours and truncate minutes
							} as Row)
					),
				].concat(objectRows)
			);
		} else {
			setObjectRows(objectRows);
		}

		if (!tripDetails.isPerson) {
			const templatesRows: Row[] = [];
			tripDetails.sensorAnalogTemplatesData?.forEach((t) => {
				templatesRows.push({
					label: t.templateName,
					content: getDisplayAnalogSensorValueAndState(t.sensorValue, t.unitOfMeasure, t.stateName),
					icon: t.iconName,
					displayEllipsis: true,
				});
			});

			tripDetails.sensorDigitalTemplatesData?.forEach((t) => {
				if(t.totalDuration !== null) {
					templatesRows.push({
						label: t.templateName,
						icon: t.iconName,
						displayEllipsis: true,
						content: getDisplayDigitalSensorDuration(t.sensorObservationValue !== null ? t.stateName : t.nonRelevantStateName, t.totalDuration, t.stateDuration, t.sensorObservationValue),
					});
				}
			});
			setTemplateRows(templatesRows);
		}
	}, [tripDetails, totalPrivateMileage, tripTimezone]);

	const getIcon = () => {
		switch (tripDetails.state) {
			case StateFilterEnum.Parked:
				return (
					<ParkedSvgImage key="parked" className="action-icon parked-img" />
				);
			case StateFilterEnum.Idle:
				return (
					<IdleSvgImage key="idle" className="action-icon idle-img" />
				);
			case StateFilterEnum.Driving:
				return (
					<DrivingSvgImage
					key="driving"
					className={`action-icon driving-img direction-icon direction-${tripDetails.direction?.toLowerCase() ?? 'unknown'}`}
				/>
				);
			default:
				return (<UnknownSvgIcon key="unknown" className="action-icon-mui" />);
		}
	};

	const canEditLocations = ClaimUtil.validateClaimList(user, [{
		claim: ClaimType.Locations,
		values: [ClaimValue.edit],
	}]);

	const actionIcons = [
		{ paddingTop: 0, icon: getIcon() },
		showLiveLocations && tripDetails.locationName === null && canEditLocations &&
		tripDetails.address != null && {
			paddingTop: 0,
			icon: (
				<IconButton
				className="action-icon-mui"
				size="small"
				onClick={() => setLocationDialogData({
					display: true,
					isForAdd: true,
					selectedLocationId: null,
					latLng: tripDetails.lat  && tripDetails.long
							? {lat: tripDetails.lat, lng: tripDetails.long} as LatLng
							: null,
					startDate: tripDetails.since
				})}
			>
				<Add className="location-icon"/>
			</IconButton>
			)
		},
		showLiveLocations && canEditLocations && tripDetails.locationName !== null &&
		tripDetails.locationId != null &&
		{ paddingTop: 15,
			icon: (
				<IconButton
				className="action-icon-mui"
				size="small"
				onClick={() => setLocationDialogData({
					display: true,
					isForAdd: false,
					selectedLocationId: tripDetails.locationId,
					latLng: null,
					startDate: null
				})}
		>
			<EditOutlined className="location-icon"/>
		</IconButton>
			)
		}
	];

	const dispatch = useDispatch();

	return (
		<div hidden={hidden} className="overall-tab">
			<div className={'live-menu-cards'}>
				<LiveExtendedMenuCard
					title={TranslateText('liveExtendedMenuOverallTab.state')}
					rows={stateRows}
					actionIcons={actionIcons}
				/>
				<LiveExtendedMenuCard title={TranslateText('liveExtendedMenuOverallTab.time')} rows={timeRows} />
				{!tripDetails.isPerson ? (
					<>
						<LiveExtendedMenuCard title={TranslateText('common.driver')} rows={informationRows} />
						<LiveExtendedMenuCard title={TranslateText('common.object')} rows={objectRows} />
						<LiveExtendedMenuCard title={TranslateText('liveExtendedMenuOverallTab.template')} rows={templateRows} />
					</>
				) : (
					<LiveExtendedMenuCard title={TranslateText('common.driver')} rows={driverRows} />
				)}
			</div>
			<div className={'map-container'}>
				{isLoaded && (
					<GoogleMap
						zoom={15}
						center={
							tripDetails?.lat && tripDetails?.long
								? { lat: tripDetails.lat, lng: tripDetails.long }
								: null
						}
						mapContainerStyle={{
							height: '234.66px',
						}}
						options={mapOptions}
						id={GlobalSettings.googleMapId}
						key={tripDetails.id}
					>
						{tripDetails?.lat && tripDetails?.long ? (
							<Marker
								key={tripDetails.id}
								position={{ lat: tripDetails.lat, lng: tripDetails.long }}
								zIndex={1001}
								icon={
									MARKER_URL[tripDetails.state]
										? {
												url: `${
													MARKER_URL[tripDetails.state]
												}#${tripDetails.direction?.toLowerCase()}`,
												scaledSize: new window.google.maps.Size(15, 15),
										  }
										: null
								}
							/>
						) : null}
					</GoogleMap>
				)}
				<div className="buttons-container">
					<div className="button-information disabled">
						<CenterFocusStrongOutlined className="follow-button" />
						<div>{TranslateText('liveExtendedMenuOverallTab.follow')}</div>
					</div>
					<div
						className="button-information"
						onClick={() => {
							dispatch(
								liveMapActionCreators.toggleFocusedEntity(tripDetails.entityId, {
									lat: tripDetails.lat,
									lng: tripDetails.long,
								})
							);
						}}
					>
						<Search className="focus-button" />
						<div>{TranslateText('liveExtendedMenuOverallTab.focus')}</div>
					</div>
					{ClaimUtil.validateHasClaim(user, ClaimType.History) &&
					ClaimUtil.validateFeature(featuresSettings, EasyTrackFeature.History) ? (
						<div className="button-information" onClick={goToHistoryTab}>
							<Restore className="history-button" />
							<div>{TranslateText('menu.history')}</div>
						</div>
					) : null}
				</div>
			</div>
			<LocationDialog
				visible={locationDialogData.display}
				closeCallback={() => {
					setLocationDialogData({
						display: false,
						isForAdd: false,
						selectedLocationId: tripDetails.locationId,
						latLng: null,
						startDate: null
					});
				}}
				selectedEntityId={locationDialogData.selectedLocationId}
				isForAdd={locationDialogData.isForAdd}
				latLngAddress={locationDialogData.latLng}
				startDateFromTrip={adjustDateForDialog(locationDialogData.startDate, tripDetails.timeZoneMinutesOffset)}
				startDateFromTripString={adjustDateToUtcForDialog(locationDialogData.startDate)}
			/>
		</div>
	);
};
export default OverallTab;
