import './styles.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GeofenceTypeEnum from 'models/GeofenceTypeEnum';
import React from 'react';
import { getGeofenceTypes } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import { Divider, Grid } from '@material-ui/core';

import { GeofenceAlertDataProps } from './types';

const GeofenceAlertData = (props: GeofenceAlertDataProps) => {
	const geofenceTypesDataSource = getGeofenceTypes();

	const onGeofenceTemplateChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		props.handleGeofenceAlertMetadataChange({
			...props.geofenceAlertMetadata,
			geofenceType: arg.value as GeofenceTypeEnum,
		});
	};

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('geofence.geofenceType')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'geofenceType'}
					className="geofence-alert-event-autocomplete"
					valueId={props.geofenceAlertMetadata.geofenceType}
					dataSource={geofenceTypesDataSource}
					onChange={onGeofenceTemplateChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
					disabledItems
				/>
				{props.validationResult?.validationResult?.geofenceType && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.geofenceType} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

export default GeofenceAlertData;
