import { Dispatch } from 'redux';

export const SET_UNSAVED_DATA = 'SET_UNSAVED_DATA';
export const SET_INLINE_UNSAVED_DATA = 'SET_INLINE_UNSAVED_DATA';
export const SET_SHOW_NOTIFICATION_PROMPT = 'SET_SHOW_NOTIFICATION_PROMPT';
export const SET_PROMPT_CALLBACK = 'SET_PROMPT_CALLBACK';

export interface UnsavedDataStoreState {
	unsavedData: boolean;
	inlineUnsavedData: boolean;
	showNotificationPrompt: boolean;
	promptCallback: () => void;
}

export interface UnsavedDataActionCreatorsSignature {
	setUnsavedData: (unsavedData: boolean) => void;
	setInlineUnsavedData: (unsavedData: boolean) => void;
	setShowNotificationPrompt: (showNotificationPrompt: boolean) => void;
	setPromptCallback: (promptCallback: () => void) => void;
}

const initialState: UnsavedDataStoreState = {
	unsavedData: false,
	inlineUnsavedData: false,
	showNotificationPrompt: false,
	promptCallback: null,
};

export const unsavedDataStoreActionCreators = {
	setUnsavedData: (unsavedData: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_UNSAVED_DATA,
			payload: unsavedData,
		});
	},
	setInlineUnsavedData: (unsavedData: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_INLINE_UNSAVED_DATA,
			payload: unsavedData,
		});
	},
	setShowNotificationPrompt: (showNotificationPrompt: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_SHOW_NOTIFICATION_PROMPT,
			payload: showNotificationPrompt,
		});
	},
	setPromptCallback: (promptCallback: () => void) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_PROMPT_CALLBACK,
			payload: promptCallback,
		});
	},
};
export const unsavedDataStoreReducer = (state: UnsavedDataStoreState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_UNSAVED_DATA:
			return {
				...state,
				unsavedData: action.payload,
				inlineUnsavedData: action.payload ? state.inlineUnsavedData : false,
			};
		case SET_SHOW_NOTIFICATION_PROMPT:
			return {
				...state,
				showNotificationPrompt: action.payload,
			};
		case SET_PROMPT_CALLBACK:
			return {
				...state,
				promptCallback: action.payload,
			};
		case SET_INLINE_UNSAVED_DATA:
			return {
				...state,
				unsavedData: action.payload,
				inlineUnsavedData: action.payload,
			};
		default:
			return state;
	}
};
