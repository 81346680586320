import BaseConnection from './BaseConnection';
import IdIdentifiable from './IdIdentifiable';

class ConnectionValidationResult extends IdIdentifiable {
	public conflictPresent: boolean;
	public requestedConnection: BaseConnection;
	public fixableConflict: boolean;
	public conflictingConnections: Array<BaseConnection>;
}

export default ConnectionValidationResult;
