import { ButtonConfig } from 'components/RadioButtonContainer';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { SelectionFilter } from 'components/TreeSelectionDialog/TreeSelectionDialog';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import ajaxUtil from 'utils/Ajax';
import TreeConfigurationUtil from 'utils/TreeSelection/TreeConfigurationUtil';

import * as GlobalSettings from '../../../../GlobalSettings.json';
import PersonTypeFilterEnum from '../../../../models/PersonTypeFilterEnum';
import { ReportSelectionAreaProps } from './types';

const useReportSelectionArea = (props: ReportSelectionAreaProps) => {
	const showInactiveRef = useRef<boolean>(props.includeInactive);
	showInactiveRef.current = props.includeInactive;

	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);

	const driverIdentification = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.featuresSettings.driverIdentification
	);

	const [filterButtons, setFilterButtons] = useState<ButtonConfig[]>([]);
	useEffect(() => {
		if (props.templateEntityType === EntityTypeEnum.Object && assetTracking) {
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.all,
					FleetEntityTypeFilter.object,
					FleetEntityTypeFilter.asset,
				])
			);
		} else if (props.templateEntityType === EntityTypeEnum.PersonReport && driverIdentification) {
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.all,
					FleetEntityTypeFilter.driver,
					FleetEntityTypeFilter.person,
				])
			);
		}
	}, [props.templateEntityType, assetTracking]);

	const retrieveDataAsyncCallback = async (filter: SelectionFilter): Promise<ITreeNode[]> => {
		showInactiveRef.current = filter.showInactive;

		return await ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.reportingApi}/GetReportSelectionData`, {
			customerId: filter.customerId,
			showPersons:
				props.templateEntityType === EntityTypeEnum.Person ||
				props.templateEntityType === EntityTypeEnum.PersonReport,
			showObjects: props.templateEntityType === EntityTypeEnum.Object,
			objectFunction:
				filter.selectedFilter in FleetEntityTypeFilter
					? TreeConfigurationUtil.getObjectFunctionFilter(filter.selectedFilter, assetTracking)
					: ObjectFunctionFilter.Full,
			showInactive: filter.showInactive,
			personType:
				props.templateEntityType === EntityTypeEnum.PersonReport
					? filter.selectedFilter === null || filter.selectedFilter === FleetEntityTypeFilter.all
						? PersonTypeFilterEnum.All
						: filter.selectedFilter === FleetEntityTypeFilter.person
						? PersonTypeFilterEnum.NonDriver
						: PersonTypeFilterEnum.Driver
					: PersonTypeFilterEnum.Driver,
			filterText: filter.filterText,
		});
	};

	const saveCallback = (tracked: ITreeNode[]): void => {
		props.includeInactiveCallback(showInactiveRef.current);

		props.saveCallback(tracked);
	};

	const closeCallback = () => {
		props.includeInactiveCallback(showInactiveRef.current);

		props.closeDialogCallback();
	};

	return {
		showInactive: showInactiveRef.current,
		retrieveDataAsyncCallback,
		saveCallback,
		closeCallback,
		filterButtons,
	};
};

export { useReportSelectionArea };
