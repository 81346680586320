import TextColumn from 'components/GridOverview/Columns/TextColumn';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import { TranslateText } from 'utils/Translations';

const fieldNames = {
	name: 'name',
	category: 'category',
	address: 'address',
};

export const locationInitialFilters: GridFilters = {
	sortField: fieldNames.name,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.category]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.address]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
	},
};

export const getLocationColumns = () => [
	TextColumn({
		fieldName: fieldNames.category,
		header: TranslateText('maintenanceOverview.grid.colCategory'),
		sortable: true,
		filterable: true,
		filterDisabled: false,
	}),
	TextColumn({
		fieldName: fieldNames.name,
		header: TranslateText('maintenanceOverview.grid.colName'),
		sortable: true,
		filterable: true,
		filterDisabled: false,
	}),
	TextColumn({
		fieldName: fieldNames.address,
		header: TranslateText('maintenanceOverview.grid.colAddress'),
		sortable: true,
		filterable: true,
		filterDisabled: false,
	}),
];
