import React from 'react';
import './styles.scss';
import { BsFunnelFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';

type FleetSelectionPlaceholderProps = {
	isForLive?: boolean;
};

const FleetSelectionPlaceholder = ({ isForLive }: FleetSelectionPlaceholderProps): JSX.Element => {
	const selectedEntities = useSelector((state: ApplicationState) => state.fleetSelection.selectedEntities);
	const liveExpanded = useSelector((state: ApplicationState) => !state.resizableEntity.resizableMenuLive.isCollapsed);
	const historyExpanded = useSelector((state: ApplicationState) =>
		state.historyStore.historyMapActive
			? !state.resizableEntity.resizableMenuHistoryMap.isCollapsed
			: !state.resizableEntity.resizableMenuHistoryGrid.isCollapsed
	);
	const isExpanded = (isForLive && liveExpanded) || (!isForLive && historyExpanded);

	return (
		selectedEntities.length === 0 &&
		isExpanded && (
			<div className={'fleet-selection-placeholder-container'}>
				<BsFunnelFill className={'fleet-selection-placeholder-icon'} fill={'#999999'} />
				<p className={'fleet-selection-placeholder-text'}>
					{TranslateText('fleetSelection.noEntitiesSelected')}
				</p>
			</div>
		)
	);
};

export default FleetSelectionPlaceholder;
