import './gridOverview.scss';

import { DataTable } from 'primereact/datatable';
import React, { ReactNode, useEffect, useState } from 'react';

import { TranslateText } from '../../utils/Translations';

interface Props {
	columns: ReactNode[];
	data: any;
	total: number;
	paginator: boolean;
	selectedRowCallback?: (rowData: any) => void;
}

export const GridWidgetOverview = (props: Props) => {
	const [selectionId, setSelectionId] = useState(null);
	const [selection, setSelection] = useState(null);

	useEffect(() => {
		if (selectionId) {
			const selectedRow = props.data.find((i: any) => i.id === selectionId);
			setSelection(selectedRow);
		}
	}, [selectionId]);

	return (
		<DataTable
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
			className={'gridWidgetOverview'}
			value={props.data}
			totalRecords={props.total}
			lazy={true}
			onSelectionChange={(e) => {
				setSelectionId(e.value.id);
				if (props.selectedRowCallback) {
					props.selectedRowCallback(e.value);
				}
			}}
			selection={selection}
			selectionMode={'single'}
			dataKey={'id'}
			currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
			editMode={'row'}
		>
			{props.columns}
		</DataTable>
	);
};
