export type ImagePickerProps = {
	previewImage: string;
	onImageEdit: (base64Image: string) => void;
	aspectRatio?: number;
	percentageWidth?: number;
	iconsRowPosition?: IconsRowPosition;
	readOnly?: boolean;
};

export enum IconsRowPosition {
	left,
	top,
	right,
	bottom,
}
