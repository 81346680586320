enum ObjectTypeEnum {
	Unknown = 0,
	Car = 1,
	Van = 2,
	Truck = 3,
	Trailer = 4,
	Crane = 5,
	Container = 6,
	Coach = 7,
	Person = 8,
	Bike = 9,
	Motorcycle = 10,
	Boat = 11,
	AirCompressor = 12,
	AirTool = 13,
	CuttingTool = 14,
	Drill = 15,
	Grinder = 16,
	LaserAndInstrument = 17,
	NailerAndStapler = 18,
	OutdoorPowerEquipment = 19,
	TubingTool = 20,
	HighPressureCleaner = 21,
	PowerTool = 22,
	HandTool = 23,
	Sander = 24,
	Chainsaw = 25,
	Screwdriver = 26,
	DewateringPump = 27,
	PowerGenerator = 28,
	DustManagement = 29,
	FallProtection = 30,
	Ladder = 31,
	Scaffolding = 32,
	Sandblaster = 33,
	GlassStand = 34,
	Battery = 35,
	WeldingTool = 36,
	Trolley = 37,
	LawnCare = 38,
	LiftAndHandling = 39,
	Tillage = 40,
	Vehicle = 41,
	VariousElectric = 42,
	VariousNonElectric = 43,
	Mower = 44,
	Machine = 45,
	Bus = 46,
	Moped = 47,
	Tractor = 48,
}

export default ObjectTypeEnum;
