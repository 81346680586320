import './styles.scss';

import { TimeScheduleView } from 'components/Widgets/Views/TimeScheduleView';
import { getTimeScheduleValidationRules } from 'components/Widgets/Views/TimeScheduleView/TimeScheduleUtils';
import { GeofenceTimeScheduleDto } from 'components/Widgets/Views/TimeScheduleView/types';
import {
	filterWorkingDays,
	getDefaultWorkingDays,
	mapSavedWorkingDays,
} from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import MonitoringPeriodEnum from 'models/MonitoringPeriodEnum';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';

import { AddAlertTimeScheduleProps } from './types';

const AddAlertTimeSchedule = (props: AddAlertTimeScheduleProps) => {
	const defaultTimeSchedule = useMemo(() => getDefaultWorkingDays(true), []);
	const dateTimeSettings = useSelector((s: ApplicationState) => s.currentSession.dateTimeSettings);

	const [validator] = useState<Validator>(() => {
		return new Validator({ fieldRules: getTimeScheduleValidationRules() });
	});
	const [validationResult, setValidationResult] = useState<ValidationResult>();

	const [formData, setFormData] = useState<GeofenceTimeScheduleDto>({
		timeSchedule: [],
		monitoringPeriod: MonitoringPeriodEnum.Inside,
	});

	useEffect(() => {
		if (props.visible) {
			const startingDay = dateTimeSettings.startingDay >= 0 ? dateTimeSettings.startingDay : 0;

			const newFormData = {
				timeSchedule: mapSavedWorkingDays(
					startingDay,
					defaultTimeSchedule,
					props.geofenceAlertMetadata.timeSchedule ? props.geofenceAlertMetadata.timeSchedule : []
				),
				monitoringPeriod: props.geofenceAlertMetadata.monitoringPeriod,
			};

			setFormData(newFormData);
			validator
				.validate({
					timeSchedule: props.geofenceAlertMetadata.timeSchedule,
					monitoringPeriod: props.geofenceAlertMetadata.monitoringPeriod,
				})
				.then((result) => {
					setValidationResult(result.validationResult);
					props.setValidationCallback(result.formResult);
				});
		}
	}, [props.visible]);

	const updateData = (newFormData: GeofenceTimeScheduleDto) => {
		setFormData(newFormData);

		const newgeofenceAlertMetadata = {
			...props.geofenceAlertMetadata,
			timeSchedule: filterWorkingDays(newFormData.timeSchedule),
			monitoringPeriod: newFormData.monitoringPeriod,
		};
		validator.validate(newgeofenceAlertMetadata).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
		});
		props.setDataCallback({
			geofenceAlertMetadata: newgeofenceAlertMetadata,
		});
		props.onChangeCallback({
			geofenceAlertMetadata: newgeofenceAlertMetadata,
		});
	};

	return (
		<TimeScheduleView
			className="add-alert-time-schedule"
			data={formData}
			updateDataCallback={updateData}
			validationResult={validationResult}
		/>
	);
};

export default AddAlertTimeSchedule;
