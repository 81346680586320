import './styles.scss';

import React from 'react';

import { Tooltip, withStyles } from '@material-ui/core';

import { useLocationAddressSizing } from '../../../utils/hooks';
import { privateDataMask } from '../../../utils/TripUtils';
import { AddressRowProps } from './types';

const AddressRow = ({
	locationName,
	address,
	icon,
	smallerMaxWidth,
	displayOnlyAddress,
}: AddressRowProps): JSX.Element => {
	const { locationRef, addressRef, locationAddressRef } = useLocationAddressSizing();
	const NoMaxWidthTooltip = withStyles({
		tooltip: {
			maxWidth: 'none',
		},
	})(Tooltip);

	const hasLocation = locationName && locationName.length && locationName !== privateDataMask;
	return !!address && (!!icon || displayOnlyAddress) ? (
		<NoMaxWidthTooltip
			PopperProps={{
				disablePortal: true,
				popperOptions: {
					positionFixed: true,
				},
			}}
			title={`${hasLocation ? locationName + ' - ' : ''}${address !== privateDataMask ? address : ''}`}
		>
			<span className="address-row">
				{!displayOnlyAddress ? <span className="address-icon">{icon}</span> : null}
				<div
					ref={locationAddressRef}
					className={`location-address-text ${!!icon ? '' : 'fullwidth'} ${
						smallerMaxWidth ? 'smaller-max-width' : ''
					}`}
				>
					{hasLocation ? (
						<span ref={locationRef} className={'location-text'}>
							{locationName}
						</span>
					) : null}
					{hasLocation ? <span className={'location-address-distributor'}>{'-'}</span> : null}
					<span ref={addressRef} className={'address-text'}>
						{address}
					</span>
				</div>
			</span>
		</NoMaxWidthTooltip>
	) : null;
};

AddressRow.defaultProps = { displayOnlyAddress: false };

export default AddressRow;
