enum EventFrequencyEnum {
	Once = 0,
	Always = 1,
	OnceAnHour = 2,
	OnceADay = 3,
	OnceAWeek = 4,
	OnceAMonth = 5,
	OnceAt5Minutes = 6,
}

export default EventFrequencyEnum;
