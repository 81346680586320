import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AnalogTemplateItem from 'models/AnalogTemplateItem';
import React, { useEffect, useRef, useState } from 'react';
import ajaxUtil from 'utils/Ajax';
import { getAnalogTriggerConditions } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import { Divider, Grid } from '@material-ui/core';

import { AnalogAlertDataProps } from './types';

const AnalogAlertData = (props: AnalogAlertDataProps) => {
	const [analogTriggerConditions] = useState(() => getAnalogTriggerConditions());
	const [analogTemplates, setAnalogTemplates] = useState<AnalogTemplateItem[]>([]);
	const [analogTemplateStates, setAnalogTemplateStates] = useState<AutocompleteItem[]>([]);

	//load analog templates
	const templatesLoaaded = useRef(false);
	useEffect(() => {
		if (!templatesLoaaded.current) {
			getAnalogTemplatesList(!props.isEditing ? props.analogAlertMetadata.templateAnalogId : null);
			templatesLoaaded.current = props.isEditing;
		}
	}, [props.isEditing]);
	const getAnalogTemplatesList = async (filterTemplateId?: string) => {
		const analogTemplates = await ajaxUtil.get<AnalogTemplateItem[]>(
			`${GlobalSettings.listsApi}/analogTemplatesList?customerId=${props.alertCustomerId}&selectedTemplateId=${props.analogAlertMetadata.templateAnalogId}` +
				(props.analogAlertMetadata.templateAnalogStateId
					? `&selectedTemplateStateId=${props.analogAlertMetadata.templateAnalogStateId}`
					: '') +
				(filterTemplateId ? `&filterTemplateId=${filterTemplateId}` : '')
		);

		setAnalogTemplates(analogTemplates);

		const selectedAnalogTemplate = analogTemplates.find((x) => x.id === props.analogAlertMetadata.templateAnalogId);
		if (selectedAnalogTemplate) {
			setAnalogTemplateStates(selectedAnalogTemplate.templateStates);
		}
	};

	//update template states list on template change
	useEffect(() => {
		const selectedTemplate = analogTemplates.find((x) => x.id === props.analogAlertMetadata.templateAnalogId);
		setAnalogTemplateStates(selectedTemplate ? selectedTemplate.templateStates : []);
	}, [props.analogAlertMetadata.templateAnalogId]);

	//analog template change
	const onAnalogTemplateChange = async (arg: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => {
		const templateAnalogId = arg.value as string;
		const newAnalogAlertMetadata = {
			...props.analogAlertMetadata,
			templateAnalogId: templateAnalogId,
		};

		if (newAnalogAlertMetadata.triggerCondition !== AlertAnalogTriggerConditionEnum.AnyStateChange) {
			const selectedTemplate = analogTemplates.find((x) => x.id === templateAnalogId);
			newAnalogAlertMetadata.templateAnalogStateId = selectedTemplate?.templateStates?.length
				? (selectedTemplate.templateStates[0].id as string)
				: null;
		}

		props.handleAnalogAlertMetadataChange(newAnalogAlertMetadata);
	};

	//trigger condition change
	const onTriggerConditionChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		const triggerCondition = arg.value as AlertAnalogTriggerConditionEnum;
		const newAnalogAlertMetadata = {
			...props.analogAlertMetadata,
			triggerCondition: triggerCondition,
		};

		if (triggerCondition === AlertAnalogTriggerConditionEnum.AnyStateChange) {
			newAnalogAlertMetadata.templateAnalogStateId = null;
			setAnalogTemplateStates([]);
		} else if (
			props.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.AnyStateChange //if previous triggerCondition was AnyStateChange select first state from template
		) {
			const selectedTemplate = analogTemplates.find((x) => x.id === newAnalogAlertMetadata.templateAnalogId);
			newAnalogAlertMetadata.templateAnalogStateId = selectedTemplate?.templateStates?.length
				? (selectedTemplate.templateStates[0].id as string)
				: null;
			setAnalogTemplateStates(selectedTemplate ? selectedTemplate.templateStates : []);
		}

		props.handleAnalogAlertMetadataChange(newAnalogAlertMetadata);
	};

	//analog template state change
	const onAnalogTemplateStateChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		props.handleAnalogAlertMetadataChange({
			...props.analogAlertMetadata,
			templateAnalogStateId: arg.value as string,
		});
	};

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('common.template')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'analogTemplate'}
					className="analog-alert-event-autocomplete"
					valueId={props.analogAlertMetadata.templateAnalogId}
					dataSource={analogTemplates}
					onChange={onAnalogTemplateChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
					disabledItems
				/>
				{props.validationResult?.validationResult?.templateAnalogId && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.templateAnalogId} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid className={'col'} item xs>
				{TranslateText('alert.sendNotification')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'triggerCondition'}
					className="analog-alert-event-autocomplete"
					valueId={props.analogAlertMetadata.triggerCondition}
					dataSource={analogTriggerConditions}
					onChange={onTriggerConditionChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
				/>
				{props.validationResult?.validationResult?.triggerCondition && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.triggerCondition} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>

			{props.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.ToSpecificState ||
			props.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.FromSpecificState ? (
				<>
					<Grid className={'col'} item xs>
						{TranslateText('alert.state')}
					</Grid>
					<Grid className={'vertical-divider'} item xs={1}>
						<Divider orientation={'vertical'} />
					</Grid>
					<Grid className={'col'} item xs>
						<MaterialAutocomplete
							name={'analogTemplateState'}
							className="analog-alert-event-autocomplete"
							valueId={props.analogAlertMetadata.templateAnalogStateId}
							dataSource={analogTemplateStates}
							onChange={onAnalogTemplateStateChange}
							disabled={!props.isEditing}
							fullWidth={false}
							disableClearable
							disabledItems
						/>
						{props.validationResult?.validationResult?.templateAnalogStateId && (
							<div className={'validation-error-container'}>
								<ValidationMessage
									result={props.validationResult.validationResult.templateAnalogStateId}
								/>
							</div>
						)}
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</>
			) : null}
		</>
	);
};

export default AnalogAlertData;
