import './styles.scss';

import { DebouncedInput } from 'components/Common/DebouncedInput';
import React from 'react';
import { TranslateText } from 'utils/Translations';
import { DurationCounterProps } from './types';
import { ValidationMessage } from '../../../ValidationMessage/ValidationMessage';
import { TextField } from '@material-ui/core';
import MaterialTextField from "../../../../shared/components/MaterialTextField/MaterialTextField";

const DurationCounter = ({
	counterValue,
	handleOnDurationCounterChange,
	validationResult,
	hideLabel,
	disabled,
	isForNewAddWizard = false,
}: DurationCounterProps): JSX.Element => {
	return (
		<>
			<MaterialTextField
				isForNewAddWizard={isForNewAddWizard}
				disabled={disabled}
				type="number"
				id="durationCounter"
				name="durationCounter"
				className="alert-duration-exceeded-duration-counter-resize-font"
				fullWidth
				label={!hideLabel ? TranslateText('fields.durationCounter') : null}
				inputProps={{
					style: {fontSize: isForNewAddWizard ? 10 : 12},
					inputProps: {
						min: 1,
						max: 999999999,
					},
				}}
				value={counterValue?.toString()}
				onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
					if (event.key === 'e' ||
						event.key === '+' ||
						event.key === '-' ||
						event.key === ',' ||
						event.key === '.') {
						event.preventDefault();
					}
				}}
				handleValueChange={(value) => {
					handleOnDurationCounterChange(+value);
				}}
				validationResult={isForNewAddWizard && !!validationResult && !validationResult.formResult ? validationResult?.validationResult?.durationCounter : null }
			/>
			{!!validationResult && !validationResult.formResult && !isForNewAddWizard && (
				<div className={'validation-error-container'}>
					<ValidationMessage result={validationResult.validationResult?.durationCounter} />
				</div>
			)}
		</>
	);
};

export default DurationCounter;
