import { TranslateText } from '../../utils/Translations';
import { LocationAccessTypeEnum } from '../../components/LocationsOverview/LocationsOverview';

const LocationAccessTypeDataSource = {
	dataSource: () => [
		// {
		// 	id: LocationAccessTypeEnum.None,
		// 	display: '',
		// },
		{
			id: LocationAccessTypeEnum.GlobalMap,
			display: TranslateText('locations.globalMap'),
		},
		{
			id: LocationAccessTypeEnum.Global,
			display: TranslateText('locations.global'),
		},
		// {
		// 	id: LocationAccessTypeEnum.Personal,
		// 	display: TranslateText('locations.personal'),
		// },
		// {
		// 	id: LocationAccessTypeEnum.Private,
		// 	display: TranslateText('locations.private'),
		// },
	],
};

export default LocationAccessTypeDataSource;
