import './ThreeWaySwitchComponent.scss';

import React from 'react';

import Slider from '@material-ui/core/Slider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { TranslateText } from '../../../utils/Translations';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 150,
			marginRight: 10,
		},
	})
);

const marks = () => [
	{
		value: 0,
		label: TranslateText('common.off'),
	},
	{
		value: 50,
		label: TranslateText('common.default'),
	},
	{
		value: 100,
		label: TranslateText('common.on'),
	},
];

const nextMarker = (value: boolean | null): boolean | null => {
	if (value === false) {
		return null;
	}
	if (value === null || value === undefined) {
		return true;
	}
	if (value === true) {
		return false;
	}
};

const valueToMarker = (value: boolean | null): number => {
	if (value === false) {
		return 0;
	}
	if (value === null || value === undefined) {
		return 50;
	}
	if (value === true) {
		return 100;
	}
};
const markerToValue = (value: number) => {
	if (value === 0) {
		return false;
	}
	if (value === 50) {
		return null;
	}
	if (value === 100) {
		return true;
	}
};

type Props = {
	id: string;
	name?: string;
	readonly?: boolean;
	enabled?: boolean;
	placeholder: string;
	value: boolean;
	change: (value: boolean | null) => void;
};
const ThreeWaySwitchComponent = (props: Props) => {
	const classes = useStyles();

	return (
		<div className={`switchComponent e-control-wrapper ${props.readonly ? 'e-disabled' : ''}`}>
			<label htmlFor={props.id}>{props.placeholder}</label>
			<div className={classes.root}>
				<Slider
					className="maxSize"
					id={props.id}
					name={props.name}
					disabled={!props.enabled}
					aria-labelledby="discrete-slider-restrict"
					step={null}
					marks={marks()}
					value={valueToMarker(props.value)}
					onChange={(e, value) => props.change(markerToValue(value as number))}
				/>
			</div>
		</div>
	);
};

ThreeWaySwitchComponent.defaultProps = {
	readonly: true,
	enabled: false,
};

export default ThreeWaySwitchComponent;
