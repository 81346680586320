import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import userService from '../../auth';
import { ApplicationStore } from '../../index';

const Login = () => {
	useEffect(() => {
		ApplicationStore.persistor.flush();
		ApplicationStore.persistor.pause();
		ApplicationStore.persistor.purge();
		localStorage.clear();
		userService.userManager.signinRedirect();
	}, []);

	return <CircularProgress className="loading-component" />;
};

export default Login;
