import './styles.scss';

import React from 'react';
import { getContrastColor } from 'utils/TripUtils';

import { Box } from '@material-ui/core';

import { TripNumberProps } from './types';

const TripNumber = ({ tripColor, tripIndex, isInProgress, containerClass = '' }: TripNumberProps): JSX.Element => {
	return (
		<Box component="span" className={`trip-number-component ${containerClass}`}>
			<Box
				bgcolor={tripColor}
				color={getContrastColor(tripColor)}
				className={`trip-number ${tripIndex >= 99 ? 'small' : ''}`}
			>
				{isInProgress ? '-' : tripIndex + 1}
			</Box>
		</Box>
	);
};

export default TripNumber;
