enum CO2CalculationTypeEnum {
	DistanceTypeAndFuel = 0,
	DistanceWLTP = 1,
	DistanceNEDC = 2,
	DistanceManual = 3,
	FuelUsage = 4,
	EasyDrive2 = 5,
	NotApplicable = 6,
}
export default CO2CalculationTypeEnum;
