import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { AlertData, AlertRecipient } from 'components/Widgets/Views/AlertRecipientsView/types';
import EntityTypeEnum from 'models/EntityTypeEnum';
import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import { groupMembersSelectionActionCreators } from 'store/GroupMembersSelectionStore';
import ajaxUtil from 'utils/Ajax';

import GlobalSettings from '../../../../GlobalSettings.json';

interface SelectionDataFilter {
	customerId: string;
	showInactive: boolean;
	filterText: string;
	selectedFilter: FleetEntityTypeFilter;
}

const useTreeDialogEvents = (
	alertId: string,
	currentSelection: AlertRecipient[],
	useNotification: boolean,
	useEmail: boolean,
	useSms: boolean,
	useWhatsApp: boolean,
	actionExecutedCallback: (value: boolean) => void,
	setDialogVisible: (value: boolean) => void
) => {
	const dispatch = useDispatch();

	const closeDialogCallback = useCallback((): void => {
		setDialogVisible(false);
	}, []);

	const showDialogCallback = useCallback((): void => {
		setDialogVisible(true);
	}, []);

	const saveSelectionCallback = async (selection: ITreeNode[]): Promise<void> => {
		const removedRecipients: AlertRecipient[] = [];
		const newRecipients: AlertRecipient[] = [];

		// get removed entries
		currentSelection?.forEach((selectedItem: AlertRecipient) => {
			if (!selection.some((item: ITreeNode) => item.id === selectedItem.entityId)) {
				removedRecipients.push({ ...selectedItem });
			}
		});

		//get added entries
		selection.forEach((item: ITreeNode) => {
			if (!currentSelection?.some((selectedItem: AlertRecipient) => item.id === selectedItem.entityId)) {
				newRecipients.push({
					entityId: item.id,
					entityName: item.text,
					entityType: EntityTypeEnum[item.type as keyof typeof EntityTypeEnum],
					active: item.active,
				});
			}
		});

		if (!!newRecipients || !!removedRecipients) {
			//update connections
			await ajaxUtil.put(`${GlobalSettings.alertsApi}/${alertId}/alertRecipients`, {
				newRecipients,
				removedRecipients,
			});
		}

		setDialogVisible(false);
		actionExecutedCallback(true);
	};

	const retrieveDataCallback = async (filter: SelectionDataFilter): Promise<ITreeNode[]> => {
		const selectedFilter: FleetEntityTypeFilter = filter.selectedFilter
			? filter.selectedFilter
			: FleetEntityTypeFilter.all;

		const showPersonsFilter: boolean = selectedFilter === FleetEntityTypeFilter.person;

		const showDriversFilter: boolean = selectedFilter === FleetEntityTypeFilter.driver;

		dispatch(groupMembersSelectionActionCreators.setShowInactiveMembers(filter.showInactive));

		const postResult = await ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.alertsApi}/getAlertRecipients`, {
			customerId: filter.customerId,
			showInactive: filter.showInactive,
			filterText: filter.filterText,
			personType: showPersonsFilter
				? PersonTypeFilterEnum.NonDriver
				: showDriversFilter
				? PersonTypeFilterEnum.Driver
				: PersonTypeFilterEnum.All,
			hasAlertNotification: useNotification,
			hasEmailNotification: useEmail,
			hasSmsNotification: useSms,
			hasWhatsAppNotification: useWhatsApp,
		});

		if (!postResult || postResult.length === 0) {
			return [];
		}

		return postResult;
	};

	return { closeDialogCallback, showDialogCallback, saveSelectionCallback, retrieveDataCallback };
};

const useInitialTreeData = (currentSelection: AlertData) => {
	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);

	useEffect(() => {
		setInitialTreeData(mapToTreeNode(currentSelection as AlertData));
	}, [currentSelection]);

	const mapToTreeNode = (alertData: AlertData): ITreeNode[] => {
		const treeNodeSelection: ITreeNode[] = [];
		const entityMemberList = alertData.alertRecipients;
		if (entityMemberList) {
			entityMemberList.forEach((item: AlertRecipient) => {
				treeNodeSelection.push({
					id: item.entityId,
					text: item.entityName,
					active: item.active,
					type: item.entityType,
					selected: true,
					items: [],
					childCount: 0,
					canBeChecked: true,
				});
			});
		}
		return treeNodeSelection;
	};

	return initialTreeData;
};

export { useInitialTreeData, useTreeDialogEvents };
