import { History, LocationState } from 'history';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';
import CustomerLevelEnum from "../../models/CustomerLevelEnum";

interface Props {
	history: History<LocationState>;
}

interface SimCardsOverviewDto extends GridEntity {
	customer: string;
	customerId: string;
	customerDisplay: string;
	deviceId: string;
	deviceCode: string;
	objectId: string;
	objectName: string;
	imsi: string;
	iccid: string;
	phoneNumber: string;
	pinCode: string;
	provider: string;
	canViewObject: boolean;
	active: boolean;
}
const fieldNames = {
	customer: 'customer',
	imsi: 'imsi',
	iccid: 'iccid',
	phoneNumber: 'phoneNumber',
	pinCode: 'pinCode',
	provider: 'provider',
	deviceCode: 'deviceCode',
	objectName: 'objectName',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: 'iccid',
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customer]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.imsi]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.iccid]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.phoneNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.pinCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.provider]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.deviceCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.objectName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const SimCardsOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const columns = [
		TextColumn({
			fieldName: fieldNames.customer,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			sortable: true,
			filterable: true,
			body: (rowData: SimCardsOverviewDto) => (
				<Link title={rowData.customer} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customer}
				</Link>
			),
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		TextColumn({
			fieldName: fieldNames.imsi,
			header: TranslateText('maintenanceOverview.grid.colIMSI'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.iccid,
			header: TranslateText('maintenanceOverview.grid.colICCID'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.phoneNumber,
			header: TranslateText('maintenanceOverview.grid.colSimCardPhoneNumber'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.pinCode,
			header: TranslateText('maintenanceOverview.grid.colPinCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.provider,
			header: TranslateText('maintenanceOverview.grid.colProvider'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.deviceCode,
			header: TranslateText('maintenanceOverview.grid.colDeviceCode'),
			sortable: true,
			filterable: true,
			body: (rowData: SimCardsOverviewDto) => (
				<Link title={rowData.deviceCode} to={`${GlobalSettings.route.devices}/${rowData.deviceId}`}>
					{rowData.deviceCode}
				</Link>
			),
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Devices),
		}),
		TextColumn({
			fieldName: fieldNames.objectName,
			header: TranslateText('maintenanceOverview.grid.colObjectName'),
			sortable: true,
			filterable: true,
			body: (rowData: SimCardsOverviewDto) =>
				rowData.canViewObject ? (
					<Link title={rowData.objectName} to={`${GlobalSettings.route.objects}/${rowData.objectId}`}>
						{rowData.objectName}
					</Link>
				) : (
					<span title={rowData.objectName}>{rowData.objectName}</span>
				),
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];
	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.simCardsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.simcards')}
			disableAddButton={false}
			showAddConfigClaim={[
				{
					claim: ClaimType.SimCards,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.SimCard}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};

export default SimCardsOverview;
