import { push } from 'connected-react-router';
import { User } from 'oidc-client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';

import CircularProgress from '@material-ui/core/CircularProgress';

import userService from '../../auth';
import * as GlobalSettings from '../../GlobalSettings.json';
import CustomerSettingsResponce from '../../models/CustomerSettingsResponce';
import ajaxUtil from '../../utils/Ajax';
import { getApplicationStartPage } from '../../utils/StartPageUtils';

const SigninCallbackPage = () => {
	const dispatch = useDispatch();

	return (
		<CallbackComponent
			userManager={userService.userManager}
			successCallback={async (a: User) => {
				const responce: CustomerSettingsResponce = await ajaxUtil.get(
					`${GlobalSettings.customersMaintenanceApi}/GetCustomerSettings`
				);
				const page = getApplicationStartPage(responce.features, responce.startPage);

				dispatch(push('/' + page));
			}}
			errorCallback={(error) => {
				console.error(error);
				dispatch(push('/'));
			}}
		>
			<CircularProgress className="loading-component" />
		</CallbackComponent>
	);
};

export default SigninCallbackPage;
