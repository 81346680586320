import './help.scss';
import '../Layout/TopBar/topbar.scss';

import Markdown from 'markdown-to-jsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EasyTrackLogoTopBar } from '../../assets/icons/EasyTrackLogoTopBar.svg';
import { ApplicationState, CustomerFeaturesState } from '../../store';
import { SET_HELP_FILE_NAME } from '../../store/LeftSideBar';
import { TranslateText } from '../../utils/Translations';
import RadioButtonContainer, { ButtonConfig } from '../RadioButtonContainer';
import { StringConstants } from '../Widgets/CustomerFeaturesView';
import { knownHelpClaimRoute, knownHelpExtraFeaturesSettings, knownHelpFiles } from './HelpButton';

const SelectionHelp = {
	live: 0,
	history: 1,
	locations: 2,
	objects: 3,
	persons: 4,
	drivers: 5,
	groups: 6,
	reports: 7,
	devices: 8,
	keys: 9,
	alerts: 10,
	settings: 11,
};
const settings = 'settings';
export const executeScroll = () => window.scrollTo({ top: 0, behavior: 'smooth' });

const switchSelection = (selection: number): string => {
	switch (selection) {
		case SelectionHelp.live: {
			return 'live';
		}
		case SelectionHelp.history: {
			return 'history';
		}
		case SelectionHelp.locations: {
			return 'locations';
		}
		case SelectionHelp.objects: {
			return 'objects';
		}
		case SelectionHelp.persons: {
			return 'persons';
		}
		case SelectionHelp.drivers: {
			return 'drivers';
		}
		case SelectionHelp.groups: {
			return 'groups';
		}
		case SelectionHelp.reports: {
			return 'reports';
		}
		case SelectionHelp.devices: {
			return 'devices';
		}
		case SelectionHelp.keys: {
			return 'keys';
		}
		case SelectionHelp.alerts: {
			return 'alerts';
		}
		case SelectionHelp.settings: {
			return 'settings';
		}
	}
};

export const checkFeatureSettings = (feature: string, customerFeatureSettings: CustomerFeaturesState): boolean => {
	if (feature === settings) {
		return true;
	}
	let result = (customerFeatureSettings[feature] as boolean) || !customerFeatureSettings.hasOwnProperty(feature);

	const extraFeature = knownHelpExtraFeaturesSettings[feature];
	if (extraFeature) {
		result =
			(result && (customerFeatureSettings[extraFeature] as boolean)) ||
			!customerFeatureSettings.hasOwnProperty(extraFeature);
	}

	return result;
};

const HelpOverview = () => {
	const dispatch = useDispatch();

	const helpFileName = useSelector((s: ApplicationState) => s.leftSideBar.helpFileName);
	const language = useSelector((s: ApplicationState) => s.translations.language);
	const userProfile = useSelector((s: ApplicationState) => s.oidc.user.profile);
	const customerFeatureSettings = useSelector((s: ApplicationState) => s?.currentSession?.customer?.featuresSettings);
	const [markdown, setMarkdown] = useState(null);
	const [availableButtons, setAvailableButtons] = useState<ButtonConfig[]>([]);

	const getClaims = (): string[] => {
		const claims: string[] = [];
		for (const name in userProfile) {
			if (
				userProfile.hasOwnProperty(name) &&
				name?.startsWith(StringConstants.easytrack) &&
				name?.split('.').length === 2
			) {
				claims.push(knownHelpClaimRoute[name?.substr(name?.indexOf('.') + 1).toLowerCase()]);
			}
		}
		claims.push(settings);
		return claims;
	};
	useEffect(() => {
		const claims = getClaims();
		if (
			helpFileName === settings ||
			claims.some(
				(cl) => cl !== undefined && cl === helpFileName && checkFeatureSettings(cl, customerFeatureSettings)
			)
		) {
			const availableTabs = claims.filter(
				(cl) => knownHelpFiles.includes('/' + cl) && checkFeatureSettings(cl, customerFeatureSettings)
			);

			const buttons: ButtonConfig[] = [
				availableTabs.some((acl) => acl === 'live') && {
					title: TranslateText('menu.live'),
					id: SelectionHelp.live,
				},
				availableTabs.some((acl) => acl === 'history') && {
					title: TranslateText('menu.history'),
					id: SelectionHelp.history,
				},
				availableTabs.some((acl) => acl === 'locations') && {
					title: TranslateText('menu.locations'),
					id: SelectionHelp.locations,
				},
				availableTabs.some((acl) => acl === 'objects') && {
					title: TranslateText('menu.objects'),
					id: SelectionHelp.objects,
				},
				availableTabs.some((acl) => acl === 'persons') && {
					title: TranslateText('menu.persons'),
					id: SelectionHelp.persons,
				},
				availableTabs.some((acl) => acl === 'drivers') && {
					title: TranslateText('menu.drivers'),
					id: SelectionHelp.drivers,
				},
				availableTabs.some((acl) => acl === 'groups') && {
					title: TranslateText('menu.groups'),
					id: SelectionHelp.groups,
				},
				availableTabs.some((acl) => acl === 'reports') && {
					title: TranslateText('menu.reports'),
					id: SelectionHelp.reports,
				},
				availableTabs.some((acl) => acl === 'devices') && {
					title: TranslateText('menu.configMenu.trackingSection.devices'),
					id: SelectionHelp.devices,
				},
				availableTabs.some((acl) => acl === 'keys') && {
					title: TranslateText('menu.configMenu.communitySection.keys'),
					id: SelectionHelp.keys,
				},
				availableTabs.some((acl) => acl === 'alerts') && {
					title: TranslateText('menu.configMenu.communitySection.alerts'),
					id: SelectionHelp.alerts,
				},
				{
					title: TranslateText('personSettingsWindow.settings'),
					id: SelectionHelp.settings,
				},
			];
			setAvailableButtons(buttons);
		}
	}, []);

	useEffect(() => {
		if (helpFileName) {
			fetch(`/help/${language}/${helpFileName}.md`).then(async (response) => {
				setMarkdown(await response.text());
			});
		}
	}, [helpFileName, language]);

	const setSelectionCallback = useCallback(
		(selection: number) => {
			const selectedValue = switchSelection(selection);
			dispatch({ type: SET_HELP_FILE_NAME, payload: selectedValue });
			executeScroll();
		},
		[helpFileName]
	);
	return (
		<div className={'help-dialog'}>
			<div style={{ minWidth: '1280px', minHeight: '700px' }}>
				<>
					<div className={'help-radio-button-container'}>
						<EasyTrackLogoTopBar />
						<span className={'help-title'}>{TranslateText('common.help')}</span>
						<span className="topbar-separator" />
						<RadioButtonContainer
							buttons={availableButtons.filter((bt) => bt.title)}
							selectedButton={Object.keys(SelectionHelp).indexOf(helpFileName)}
							buttonSelectCallback={setSelectionCallback}
						/>
					</div>
					<div className={'help-markdown'}>{markdown && <Markdown>{markdown}</Markdown>}</div>
				</>
			</div>
		</div>
	);
};
export default HelpOverview;
