import './addressView.scss';

import { ReactComponent as ArrowLeft } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';
import { ColorPicker } from 'components/ColorPicker';
import { IconPicker } from 'components/IconPicker';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import LocationsAreaMap from 'components/LocationsAreaMap';
import { PolygonDefinition } from 'components/LocationsAreaMap/types';
import { LocationAreaTypeEnum } from 'components/LocationsOverview/LocationsOverview';
import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import SearchComponent, { SearchResult } from 'components/SearchComponent/SearchComponent';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import Location from 'models/Location';
import React, { useEffect, useState } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { IconButton, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

import FieldRow from './FieldRow';
import {LatLng} from "../../../../store/LiveMap";

type Props = AddWizardComponentProps & {
	location?: Location;
	initialPolygon?: PolygonDefinition;
	readOnly: boolean;
	setUnsavedDataCallback?: (data: boolean) => void;
	isForDialog: boolean;
	latLngFromDialog?: LatLng;
};

interface Address {
	[propName: string]: string;
}

const AddressView = (props: Props) => {
	const [location, setLocation] = useState<Location>(props.location ? { ...props.location } : new Location(''));
	const [mapLocation, setMapLocation] = useState<Location>(props.location ? { ...props.location } : new Location(''));
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);
	const [clearSearchComponentResultTrigger, setClearSearchComponentResultTrigger] = useState<boolean>(false);
	const [initializeMap, setInitializeMap] = useState(false);

	const { icon } = useTryGetSvgIconFromQueryCache(location.iconName, 'locations/pin');

	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const validator: Validator = new Validator({
		fieldRules: {
			radius: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const location = data as Location;
							return ValidatorFunctions.wrapInPromise(
								location?.areaType !== LocationAreaTypeEnum.Circle || !!location?.radius
							);
						},
					},
					maxNumber: {
						message: TranslateText('fieldsValidations.maxValue') + 5000,
						validationFunction: (data: any) => {
							const location = data as Location;
							return ValidatorFunctions.wrapInPromise(
								location?.areaType !== LocationAreaTypeEnum.Circle ||
									(!!location?.radius && location.radius <= 5000)
							);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.minValue') + 1,
						validationFunction: (data: any) => {
							const location = data as Location;
							return ValidatorFunctions.wrapInPromise(
								location?.areaType !== LocationAreaTypeEnum.Circle ||
									(!!location?.radius && location.radius >= 1)
							);
						},
					},
				},
			},
			latitude: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			longitude: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			areaType: {
				rules: {
					required: ValidatorFunctions.requiredInEnum(LocationAreaTypeEnum),
				},
			},
			polygon: {
				rules: {
					maxDistance: {
						message: TranslateTextInterpolated('fieldsValidations.maxDistancePolygon', ['5000']),
						validationFunction: (data: any) => {
							const location = data as Location;
							return ValidatorFunctions.wrapInPromise(
								location?.areaType !== LocationAreaTypeEnum.Polygon ||
									(!!location?.radius && location.radius <= 5000)
							);
						},
					},
				},
			},
			location: {
				rules: {
					synchronization: {
						message: TranslateText('fieldsValidations.unsyncMapLocation'),
						validationFunction: (data: any, _, context) => {
							const location = data as Location;
							const mapLocation = context as { latitude: number; longitude: number };

							return ValidatorFunctions.wrapInPromise(
								location.latitude === mapLocation.latitude &&
									location.longitude === mapLocation.longitude
							);
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		if (!props.location) {
			return;
		}
		setLocation({ ...props.location });
		setMapLocation({ ...props.location });
	}, [props.location]);

	useEffect(() => {
		validator
			.validate(
				{
					latitude: location.latitude,
					longitude: location.longitude,
					shortAddress: location.shortAddress,
					address: location.address,
					locality: location.locality,
					country: location.country,
					postalCode: location.postalCode,
					iconColor: location.iconColor,
					radius: location.radius,
					areaType: location.areaType,
					iconName: location.iconName,
					polygonPath: location.polygonPath,
				},
				{ latitude: mapLocation.latitude, longitude: mapLocation.longitude }
			)
			.then((result) => {
				setValidationResult(result.validationResult);
				props.setValidationCallback(result.formResult);
			});
		props.onChangeCallback({
			latitude: location.latitude,
			longitude: location.longitude,
			shortAddress: location.shortAddress,
			address: location.address,
			locality: location.locality,
			country: location.country,
			postalCode: location.postalCode,
			iconColor: location.iconColor,
			radius: location.radius,
			areaType: location.areaType,
			iconName: location.iconName,
			polygonPath: location.polygonPath,
		});
	}, [location, mapLocation.latitude, mapLocation.longitude]);

	useEffect(() => {
		if (props.visible) {
			setInitializeMap(true);
		}
	}, [props.visible]);

	useEffect(() => {
		if(props.isForDialog && props.latLngFromDialog !== null)
		{
			setAddressFromMapCoordinates(props.latLngFromDialog?.lat, props.latLngFromDialog?.lng);
		}
	},[props.isForDialog, props.latLngFromDialog])
	const handleValueChange = (value: unknown, statePropName: string) => {
		const newLocation = { ...location };
		newLocation[statePropName] = value;

		if (statePropName === 'iconColor' || statePropName === 'iconName') {
			const newMapLocation: Location = { ...mapLocation };
			newMapLocation[statePropName] = value as string;

			setMapLocation(newMapLocation);
		}

		setLocation(newLocation);
		props.setUnsavedDataCallback(true);
	};

	const mapOnChangeCallback = (newLocation: Location) => {
		setMapLocation({ ...newLocation });
		setLocation({
			...location,
			radius: newLocation.radius,
			areaType: newLocation.areaType,
			iconColor: newLocation.iconColor,
			polygonPath: newLocation.polygonPath,
		});
		if (props.setUnsavedDataCallback) {
			props.setUnsavedDataCallback(true);
		}
	};

	const setNewLocation = (
		shortAddressObj: Address,
		longAddressObj: Address,
		results: google.maps.GeocoderResult[],
		lat: number,
		lng: number
	) => {
		const newLocation = { ...mapLocation };

		newLocation['latitude'] = lat;
		newLocation['longitude'] = lng;
		newLocation['address'] = (
			(shortAddressObj.route || shortAddressObj.street_number
				? (shortAddressObj.route ?? '') + ' ' + (shortAddressObj.street_number ?? '') + ', '
				: '') +
			(shortAddressObj.locality ? shortAddressObj.locality + ', ' : '') +
			' ' +
			(shortAddressObj.country ?? '')
		).trim();
		newLocation['shortAddress'] = (
			(shortAddressObj.route ?? '') +
			' ' +
			(shortAddressObj.street_number ?? '')
		).trim();
		newLocation['country'] = shortAddressObj.country ?? null;
		newLocation['locality'] = longAddressObj.locality ?? null;
		newLocation['postalCode'] =
			results[0].address_components.find((item) => item.types[0] === 'postal_code')?.long_name ?? null;

		setMapLocation({ ...newLocation });
		setLocation({ ...newLocation, iconName: location.iconName });
		if (props.setUnsavedDataCallback) {
			props.setUnsavedDataCallback(true);
		}
	};

	const setAddressCallback = async (result: SearchResult) => {
		const geocoder = new google.maps.Geocoder();
		const request = {
			placeId: result.id,
		};
		geocoder.geocode(request, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK && results.length) {
				setLatitude(null);
				setLongitude(null);

				const lat = results[0].geometry?.location?.lat();
				const lng = results[0].geometry?.location?.lng();

				const longAddressObj = getLongAddressObject(results[0]);
				const shortAddressObj = getShortAddressObject(results[0]);

				setNewLocation(shortAddressObj, longAddressObj, results, lat, lng);
			}
		});
	};

	const setAddressFromMapCoordinates = async (latitude: number, longitude: number) => {
		const geocoder = new google.maps.Geocoder();
		const request: google.maps.GeocoderRequest = {
			location: { lat: latitude, lng: longitude },
		};
		geocoder.geocode(request, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK && results.length) {
				setLatitude(null);
				setLongitude(null);
				setClearSearchComponentResultTrigger(!clearSearchComponentResultTrigger);

				const lat = latitude;
				const lng = longitude;

				const longAddressObj = getLongAddressObject(results[0]);
				const shortAddressObj = getShortAddressObject(results[0]);

				setNewLocation(shortAddressObj, longAddressObj, results, lat, lng);
			}
		});
	};

	const searchByLatLng = () => {
		if (
			latitude === null ||
			latitude.toString().trim().length === 0 ||
			longitude === null ||
			longitude.toString().trim().length === 0
		) {
			return;
		}

		const geocoder = new google.maps.Geocoder();
		const request: google.maps.GeocoderRequest = {
			location: { lat: parseFloat(latitude.toString()), lng: parseFloat(longitude.toString()) },
		};
		geocoder.geocode(request, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK && results.length) {
				setClearSearchComponentResultTrigger(!clearSearchComponentResultTrigger);

				const lat = results[0].geometry?.location?.lat();
				const lng = results[0].geometry?.location?.lng();

				const longAddressObj = getLongAddressObject(results[0]);
				const shortAddressObj = getShortAddressObject(results[0]);

				setNewLocation(shortAddressObj, longAddressObj, results, lat, lng);
			}
		});
	};

	function getLongAddressObject(object: google.maps.GeocoderResult) {
		const address: Address = {};
		const address_components = object.address_components;
		address_components.forEach((element) => {
			address[element.types[0]] = element.long_name;
		});
		return address;
	}

	function getShortAddressObject(object: google.maps.GeocoderResult) {
		const address: Address = {};
		const address_components = object.address_components;
		address_components.forEach((element) => {
			address[element.types[0]] = element.short_name;
		});
		return address;
	}

	const getAddress = () => {
		return location.shortAddress || location.postalCode || location.locality || location.country ? (
			<>
				{location.shortAddress ? location.shortAddress : ''}
				{location.shortAddress && <br />}
				{(location.postalCode || location.locality || location.country) &&
					(
						(location.postalCode ?? '') +
						' ' +
						(location.locality ?? '') +
						' ' +
						(location.country ?? '')
					).trim()}
			</>
		) : null;
	};

	const correctLatitude = () => {
		if (latitude !== null && !isNaN(latitude) && latitude >= -90 && latitude <= 90) {
			setLatitude(
				parseFloat(
					Number(latitude)
						.toFixed(6)
						.toString()
				)
			);
		} else {
			setLatitude(null);
		}
	};

	const correctLongitude = () => {
		if (longitude !== null && !isNaN(longitude) && longitude >= -180 && longitude <= 180) {
			setLongitude(
				parseFloat(
					Number(longitude)
						.toFixed(6)
						.toString()
				)
			);
		} else {
			setLongitude(null);
		}
	};

	const syncDataFromAddressToMap = () => {
		setMapLocation({ ...location });
		setLocation({ ...location });
		props?.setUnsavedDataCallback(true);
	};

	const syncDataFromMapToAddress = () => {
		setAddressFromMapCoordinates(mapLocation.latitude, mapLocation.longitude);
	};

	const handleIconChange = (iconName: string, content: string, iconSet: string) => {
		handleValueChange(`${iconSet}/${iconName}`, 'iconName');
		props.setUnsavedDataCallback(true);
	};

	return (
		<div className={`address-view-container ${props.isForDialog ? 'address-container-dialog' : ''}`}>
			<div className={`information-container ${props.location ? ' inline-view' : ''}`} style={props.isForDialog ? { minWidth: 426} : { }}>
				{!props.readOnly && (
					<>
						<div className={'field-row'}>
							<div className={'label-container'} style={{ paddingTop: 5 }}>
								{TranslateText('locations.search')}
							</div>
							<div className={'fields-container'}>
								<SearchComponent
									readonly={props.readOnly}
									isMaterial={true}
									selectResultCallback={setAddressCallback}
									clearResultTrigger={clearSearchComponentResultTrigger}
								/>
								<div className={'search-row'}>
									<div style={{ display: 'flex', alignItems: 'flex-start', flexGrow: 1 }}>
										<div className={'lat-lng-container'}>
											<span>{TranslateText('locations.shortLatitude')}</span>
											<TextField
												value={latitude ?? ''}
												onChange={(e) => setLatitude(e.target.value as any)}
												disabled={props.readOnly}
												type={'float'}
												style={{ margin: '0 15px' }}
												onBlur={() => correctLatitude()}
												inputProps={{
													step: 0.000001,
													min: -90,
													max: 90,
													style: { fontSize: 10, paddingTop: 0 },
												}}
											/>
										</div>
										<div className={'lat-lng-container'}>
											<span>{TranslateText('locations.shortLongitude')}</span>
											<TextField
												value={longitude ?? ''}
												onChange={(e) => {
													setLongitude(e.target.value as any);
												}}
												type={'float'}
												style={{ margin: '0 15px' }}
												onBlur={() => correctLongitude()}
												inputProps={{
													step: 0.000001,
													min: -180,
													max: 180,
													style: { fontSize: 10, paddingTop: 0 },
												}}
												disabled={props.readOnly}
											/>
										</div>
									</div>
									<IconButton
										className={'search-button'}
										onClick={searchByLatLng}
										disabled={latitude === null || longitude === null || props.readOnly}
									>
										<SearchOutlined
											style={{
												color:
													latitude !== null && longitude !== null && !props.readOnly
														? 'black'
														: 'grey',
											}}
										/>
									</IconButton>
								</div>
							</div>
						</div>
						<div style={{ height: 20, width: 1 }} />
					</>
				)}
				<FieldRow label={TranslateText('locations.address')} content={getAddress()} />
				<FieldRow label={TranslateText('locations.postalCode')} content={location.postalCode} />
				<FieldRow label={TranslateText('locations.locality')} content={location.locality} />
				<FieldRow label={TranslateText('locations.country')} content={location.country} />
				<FieldRow label={TranslateText('locations.latitude')} content={location.latitude?.toFixed(6)} />
				<FieldRow label={TranslateText('locations.longitude')} content={location.longitude?.toFixed(6)} />
				<div className={'field-row'}>
					<div className={'label-container'}>{TranslateText('locations.icon')}</div>
					<div className={'fields-container'}>
						<IconPicker
							defaultIcon={icon.content}
							defaultIconName={icon.name}
							onIconSelected={handleIconChange}
							containers={['locations']}
							readOnly={props.readOnly}
						/>
					</div>
				</div>
				<div className={'field-row'}>
					<div className={'label-container'}>{TranslateText('locations.color')}</div>
					<div className={'fields-container'}>
						<ColorPicker
							initialColor={location.iconColor ?? '#ff0000'}
							onColorChangedHandler={(color: string) => {
								if (color !== location.iconColor) {
									handleValueChange(color, 'iconColor');
								}
							}}
							disabled={props.readOnly}
						/>
					</div>
				</div>
				{validationResult?.location ? (
					<div className={'field-row'} style={props.isForDialog ? {paddingBottom: 0} : {}}>
						<ValidationMessage result={validationResult?.location} isForNewAddWizard={false} />
					</div>
				) : null}
			</div>
			<div className={'arrows-container'}>
				{!props.readOnly && (
					<div
						className={'arrow'}
						onClick={!props.readOnly && !!location.latitude ? syncDataFromAddressToMap : undefined}
					>
						<ArrowRight />
					</div>
				)}
				{!props.readOnly && (
					<div
						className={'arrow'}
						onClick={!props.readOnly && !!mapLocation.latitude ? syncDataFromMapToAddress : undefined}
					>
						<ArrowLeft />
					</div>
				)}
			</div>
			{initializeMap && (
				<LocationsAreaMap
					onChangeCallback={mapOnChangeCallback}
					validationResult={validationResult}
					location={mapLocation}
					initialPolygon={props.initialPolygon}
					readOnly={props.readOnly}
					icon={icon}
					isForDialog={props.isForDialog}
				/>
			)}
		</div>
	);
};

export default AddressView;
