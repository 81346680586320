import { Button } from '@material-ui/core';
import React from 'react';
import { useDebounceCallback } from 'utils/hooks';

import { DebouncedButtonProps } from './types';

const DebouncedButton = ({ debounceTime, onClick, ...rest }: DebouncedButtonProps): JSX.Element => {
	const debouncedCall = useDebounceCallback(onClick, debounceTime);

	return <Button data-testid="debounced-button-testid" onClick={debouncedCall} {...rest} />;
};

export default DebouncedButton;
