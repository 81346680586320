import '../../Common/RemoveSpinnerOptionInput.scss'

import React, { ChangeEvent, useEffect } from 'react';
import { iconNoImage } from 'utils/Icons';

import { TextField } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import TemplateEventActionEnum from '../../../models/TemplateEventActionEnum';
import TemplateState from '../../../models/TemplateState';
import TemplateTypesEnum from '../../../models/TemplateTypesEnum';
import { FieldRule, FieldRules } from '../../../shared/validation/interfaces';
import Validator from '../../../shared/validation/Validator';
import { ValidatorFunctions } from '../../../shared/validation/ValidatorFunctions';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from '../../../utils/Translations';
import { ColorPicker } from '../../ColorPicker';
import IconPickerWrapper from '../../IconPicker/IconPickerWrapper/IconPickerWrapper';
import { ValidationMessage } from '../../ValidationMessage/ValidationMessage';
import { WidgetViewDefault } from '../Widget';
import TemplateUnitOfMeasureEnum from "../../../models/TemplateUnitOfMeasureEnum";
import {handleKeyDown} from "../../Common/RemoveSpinnerOptionInput";

type Props = WidgetViewDefault & {
	data: TemplateState[];
	initialData: TemplateState[];
};
const TemplateStateDefinitionView = (props: Props) => {
	const getRules = () => {
		let rules = {} as FieldRules;
		for (let i = 0; i < 5; i++) {
			rules[`name${i}`] = {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i + 1 > templateState.length || templateState[i]?.name !== ''
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', ['10']),
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i + 1 > templateState.length || templateState[i]?.name.length <= 10
							);
						},
					},
				},
			} as FieldRule;
			rules[`stateMaxValue${i}`] = {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i > 0 ||
									i + 1 > templateState.length ||
									(templateState[i]?.maxValue !== null &&
										templateState[i]?.maxValue.toString() !== '')
							);
						},
					},
					minNumberConditionally: {
						message: TranslateText('fieldsValidations.minValue') + 0,
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i > 1 ||
								(i === 1 && templateState[i]?.maxValue === null) ||
								i + 1 > templateState.length ||
								props.getDataCallback()?.unitValue === TemplateUnitOfMeasureEnum.Temperature ||
								templateState[i]?.maxValue >= 0
							);
						},
					},
					maxNumberConditionally: {
						message: TranslateText('fieldsValidations.maxValue') + 999999,
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i > 1 ||
								(i === 1 && templateState[i]?.maxValue === null) ||
								i + 1 > templateState.length || templateState[i]?.maxValue <= 999999
							);
						},
					},
					higherThanPrevious: {
						message: TranslateText('fieldsValidations.higherThanPrevious'),
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								i > 1 ||
									i === 0 ||
									(i === 1 && templateState[i]?.maxValue === null) ||
									i + 1 > templateState.length ||
									templateState[i - 1]?.maxValue === null ||
									templateState[i - 1]?.maxValue.toString() === '' ||
									Number(templateState[i]?.maxValue) > Number(templateState[i - 1]?.maxValue)
							);
						},
					},
					lowerThanMaxValueTemplate: {
						message: TranslateText('fieldsValidations.templateLowerMaxStateValue'),
						validationFunction: (data: any) => {
							const templateState = data as TemplateState[];
							return ValidatorFunctions.wrapInPromise(
								(i === 1 && templateState[i]?.maxValue === null) ||
									i + 1 > templateState.length ||
									templateState[i]?.maxValue === null ||
									templateState[i]?.maxValue.toString() === '' ||
									Number(templateState[i]?.maxValue) <= Number(props.getDataCallback()?.maxValue)
							);
						},
					},
				},
			} as FieldRule;
		}
		return rules;
	};

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: getRules() }, true));
	}, [props.entityId]);

	useEffect(() => {
		if (!props.editMode && props.getDataCallback()?.hasChanged) {
			props.changeDataCallback([]);
			const url = `${GlobalSettings.sensorTemplatesMaintenanceApi}/stateDefinition/${props.entityId}`;
			ajaxUtil.get<TemplateState[]>(url).then((result) => {
				const newState = [...result] as TemplateState[];
				props.changeDataCallback(newState);
			});
			props.setDataCallback({
				maxValue: props.getDataCallback().maxValue as number,
				unitValue: props.getDataCallback().unitValue as TemplateUnitOfMeasureEnum,
				hasChanged: false,
			});
		}
	}, [props.entityId, props.getDataCallback]);

	useEffect(() => {
		if (arraysEqual(props.data, props.initialData) && !props.editMode) {
			const url = `${GlobalSettings.sensorTemplatesMaintenanceApi}/stateDefinition/${props.entityId}`;
			ajaxUtil.get<TemplateState[]>(url).then((result) => {
				const newState = [...result] as TemplateState[];
				props.changeDataCallback(newState);
			});
		}
	}, [props.editMode]);

	const handleStateValueChange = (
		index: number,
		value: boolean | number | string | TemplateTypesEnum | TemplateEventActionEnum,
		statePropName: keyof TemplateState
	) => {
		const newStates = [...props.data] as TemplateState[];
		newStates[index][statePropName] = value;
		props.changeDataCallback(newStates);
	};
	const arraysEqual = (a: any, b: any) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	};

	return (
		<>
			{props.data?.map((state, index: number) => (
				<div className={'state-view-container'} key={index}>
					<div className="form-group">
						<TextField
							id="stateName"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.template.stateName')}
							inputProps={{ style: { fontSize: 10 } }}
							name="name"
							value={state?.name}
							onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
								if (args.target) {
									handleStateValueChange(index, args.target.value, 'name');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.[`name${index}`]} />
					</div>
					<div className="form-group display-flex">
						<label style={{ opacity: !props.editMode ? 0.5 : 1 }}>
							{TranslateText('fields.template.labelStateTagColor')}
						</label>
						<ColorPicker
							initialColor={state.labelColor ?? ''}
							onColorChangedHandler={(color: string) =>
								handleStateValueChange(index, color, 'labelColor')
							}
							disabled={!props.editMode}
						/>
					</div>
					<div className="form-group" style={{ display: 'flex' }}>
						<label style={{ opacity: !props.editMode ? 0.5 : 1, marginTop: 'auto' }}>
							{TranslateText('fields.template.labelStateTagIconName')}
						</label>
						<div style={{ marginLeft: 8 }}>
							<IconPickerWrapper
								iconName={state.iconName}
								onIconSelected={(name, content, iconSet) =>
									handleStateValueChange(
										index,
										iconNoImage.name === name ? null : `${iconSet}/${name}`,
										'iconName'
									)
								}
								readOnly={!props.editMode}
								containers={['sensor-templates']}
								allowNoImage={true}
							/>
						</div>
					</div>
					<div className="form-group">
						<TextField
							id="templateStateMaxValue"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.template.maxValue')}
							inputProps={{ style: { fontSize: 10 } }}
							name="maxValue"
							value={state?.maxValue}
							onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
								if (args.target) {
									handleStateValueChange(
										index,
										args.target.value ? parseFloat(args.target.value) : null,
										'maxValue'
									);
								}
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
					</div>
					<ValidationMessage result={props.validationResult?.[`stateMaxValue${index}`]} />
				</div>
			))}
		</>
	);
};
export default TemplateStateDefinitionView;
