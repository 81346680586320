import { noop } from 'Constants';
import { BlobIconDictionary, SvgBlobIcon } from 'models/BlobIcon';
import React, { useMemo } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { iconNotAvailable } from 'utils/Icons';

import { SvgIcon } from '../SvgIcon';
import { SvgIconNameProps } from './types';

const SvgIconName = ({ iconName, color, size, displayInlineBlock, tooltip }: SvgIconNameProps): JSX.Element => {
	const queryClient: QueryClient = useQueryClient();
	const blobIcons: BlobIconDictionary = queryClient.getQueryData<BlobIconDictionary>(['application-icons']);

	const icon: SvgBlobIcon = useMemo((): SvgBlobIcon => {
		const svgBlobIcons: SvgBlobIcon[] = [];
		if (blobIcons) {
			Object.keys(blobIcons).filter((key: string) => {
				svgBlobIcons.push(...blobIcons[key]);
			});
		}
		const icon: SvgBlobIcon =
			svgBlobIcons.find((icon: SvgBlobIcon) => `${icon.iconSet}/${icon.name}` === iconName) ?? iconNotAvailable;

		return icon;
	}, [blobIcons, iconName]);

	return (
		<SvgIcon
			icon={icon.content}
			color={color}
			size={size}
			onClick={noop}
			displayInlineBlock={displayInlineBlock}
			tooltip={tooltip}
		/>
	);
};

export default SvgIconName;
