import './PersonSettingsDashboard.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import { Grid, Link, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { History, Lock, Map } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

import MaterialCustomCard from '../../../shared/components/MaterialCustomCard/MaterialCustomCard';
import { usePersonProfileSettingsAccess } from '../hooks';

interface Props {
	changeTab: (value: number) => void;
}

const PersonSettingsDashboard = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanEditApplicatinSettings = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Profile,
		values: [ClaimValue.editApplicationSettings],
	});
	const userCanEditProfile = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Profile,
		values: [ClaimValue.editProfile],
	});
	const username = useSelector((state: ApplicationState) => state.currentSession.userFirstAndLastName);
	const usernameAbbreviation = useSelector(
		(state: ApplicationState) => state.currentSession.userFirstAndLastNameAbbreviation
	);
	const email = useSelector((state: ApplicationState) => state.currentSession.email);

	const {
		canAccessHistorySettings,
		canAccessLiveSettings,
		canAccessReportsSettings,
	} = usePersonProfileSettingsAccess();

	return (
		<div style={{ marginTop: '80px', marginLeft: '40px' }} className="person-settings-dashboard">
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6} md={3}>
					<Card className={'account-card'}>
						<CardContent className={'account-card-content'}>
							<div className={'account-card-content-container'}>
								<span className="circle-profile-menu">
									<span className={'circle-profile-menu-name'}>{usernameAbbreviation}</span>
								</span>
								<div>
									<Typography variant="body2" className={'name'}>
										{username}
									</Typography>
									<TextField
										disabled={true}
										multiline
										inputProps={{
											style: {
												fontFamily: 'Ubuntu',
												fontSize: '12px',
												textAlign: 'center',
												color: 'black',
												lineHeight: '12px',
											},
										}}
										value={email}
									/>
								</div>
								<br />
								<Typography variant="body2" component="span" className={'account-description'}>
									{TranslateText('personSettingsDashboard.profileDescription')}
								</Typography>
								<Link className={'account-link-option'} onClick={() => props.changeTab(1)}>
									{userCanEditProfile
										? TranslateText('personSettingsDashboard.changeProfile')
										: TranslateText('personSettingsDashboard.viewProfile')}
								</Link>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6} md={3} className={'grid-card-content'}>
					<MaterialCustomCard
						title={TranslateText('personSettingsDashboard.password')}
						icon={<Lock className={'icon-customization'} />}
						description={TranslateText('personSettingsDashboard.passwordDescription')}
						tabText={TranslateText('personSettingsDashboard.changePassword')}
						changeTab={props.changeTab}
						tabNumber={6}
					/>
				</Grid>
				{canAccessLiveSettings() && (
					<Grid item xs={12} sm={6} md={3} className={'grid-card-content'}>
						<MaterialCustomCard
							title={TranslateText('personSettingsDashboard.liveSettings')}
							icon={<Map className={'icon-customization'} />}
							description={TranslateText('personSettingsDashboard.liveSettingsDescription')}
							tabText={TranslateText('personSettingsDashboard.changeLiveSettings')}
							changeTab={props.changeTab}
							tabNumber={2}
						/>
					</Grid>
				)}
				{canAccessHistorySettings() && (
					<Grid item xs={12} sm={6} md={3} className={'grid-card-content'}>
						<MaterialCustomCard
							title={TranslateText('personSettingsDashboard.historySettings')}
							icon={<History className={'icon-customization'} />}
							description={TranslateText('personSettingsDashboard.historySettingsDescription')}
							tabText={TranslateText('personSettingsDashboard.changeHistorySettings')}
							changeTab={props.changeTab}
							tabNumber={3}
						/>
					</Grid>
				)}
				{canAccessReportsSettings() && (
					<Grid item xs={12} sm={6} md={3} className={'grid-card-content'}>
						<MaterialCustomCard
							title={TranslateText('personSettingsDashboard.reportSettings')}
							icon={<AssessmentOutlinedIcon className={'icon-customization'} />}
							description={TranslateText('personSettingsDashboard.reportSettingsDescription')}
							tabText={TranslateText('personSettingsDashboard.changeReportSettings')}
							changeTab={props.changeTab}
							tabNumber={4}
						/>
					</Grid>
				)}
				{userCanEditApplicatinSettings && (
					<Grid item xs={12} sm={6} md={3} className={'grid-card-content'}>
						<MaterialCustomCard
							title={TranslateText('personSettingsDashboard.time')}
							icon={<AccessTimeIcon className={'icon-customization'} />}
							description={TranslateText('personSettingsDashboard.timeDescription')}
							tabText={TranslateText('personSettingsDashboard.changeTimeSettings')}
							changeTab={props.changeTab}
							tabNumber={5}
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default PersonSettingsDashboard;
