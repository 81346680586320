import './style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import { IButtonWidgetView } from './ButtonWidget/ButtonWidget';

interface ClaimValueRow {
	claim: string;
	values: string;
}

const RolesRightsView = (props: IButtonWidgetView) => {
	const server = {
		terminateConnection: (entry: ClaimValueRow) => {
			const urlApi = GlobalSettings.rolesMaintenanceApi + '/deleteRoleClaim/' + props.entityId;
			if (entry.claim && entry.claim !== '' && entry.values && entry.values !== '') {
				ajaxUtil.post(`${urlApi}`, { Claim: entry.claim, Values: entry.values }).then(() => {
					props.reloadDataCallback();
				});
			}
		},
	};

	return (
		<DataTable
			className="gridWidgetOverview"
			value={props.data as ClaimValueRow[]}
			emptyMessage={TranslateText('common.noData')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('common.claim')}
				body={(data: ClaimValueRow) => {
					return TranslateText(data.claim);
				}}
			/>
			<Column className="widget-grid-column" field={'values'} header={TranslateText('common.value')} />
			<Column
				className="icon-button-column"
				style={{ width: '40px' }}
				body={(data: ClaimValueRow) =>
					props.editMode ? (
						<Icon
							onClick={() => {
								server.terminateConnection(data);
							}}
						>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default RolesRightsView;
