import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MUIRichTextEditor from 'components/MuiRichTextEditor/MUIRichTextEditor';
import { ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import MessageTargetEnum from 'models/MessageTargetEnum';
import React, { useEffect, useState } from 'react';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import * as GlobalSettings from '../../../GlobalSettings.json';
import { AlertMessageViewerProps, PreviewAlertMessage } from './types';

const AlertMessageViewer = (props: AlertMessageViewerProps) => {
	const [filledContent, setFilledContent] = useState<{
		subject: string;
		editorBody: string;
		appliesTo: MessageTargetEnum;
	} | null>(null);

	useEffect(() => {
		const mutateMessage = async () => {
			const result: PreviewAlertMessage = await ajaxUtil.post<PreviewAlertMessage>(
				GlobalSettings.previewApi + '/previewAlertMessage',
				{
					subject: props.subject,
					body: props.body,
				}
			);

			const contentHTML = htmlToDraft(result.body);
			const state: ContentState = ContentState.createFromBlockArray(
				contentHTML.contentBlocks,
				contentHTML.entityMap
			);
			const previewBody: string = JSON.stringify(convertToRaw(state));

			setFilledContent({
				subject: result.subject,
				editorBody: previewBody,
				appliesTo: props.appliesTo,
			});
		};

		if (!props.showDialog) {
			return;
		}

		mutateMessage();
	}, [props.showDialog]);

	return (
		props.showDialog &&
		filledContent && (
			<Dialog open={true}>
				<DialogTitle>{TranslateText('messageEditor.messageViewer')}</DialogTitle>
				<DialogContent>
					<>
						{filledContent.appliesTo === MessageTargetEnum.Mail && (
							<>
								<Box
									id={'test'}
									component={'span'}
									display={'inline'}
									fontSize={14}
									fontWeight={'bold'}
									padding={'10px 0 0 0'}
								>
									{TranslateText('messageEditor.subject')}:{' '}
								</Box>
								<Box component="span" fontSize={14}>
									{filledContent.subject}
								</Box>
							</>
						)}

						<Box maxHeight={'300px'} marginTop={'10px'}>
							<MUIRichTextEditor
								id="previewRFT"
								readOnly={true}
								defaultValue={filledContent.editorBody}
								toolbar={false}
							/>
						</Box>
					</>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setFilledContent(null);
							props.setShowDialog(false);
						}}
					>
						{TranslateText('common.buttonClose')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	);
};

export default AlertMessageViewer;
