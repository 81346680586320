import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { ClaimConfig, ClaimUtil } from '../../../authorization/ClaimUtil';
import { ApplicationState } from '../../../store';

interface Props {
	claim?: string;
	claimList?: string[];
	optionalClaimList?: string[];
	claimConfig?: ClaimConfig[];
	optionalClaimConfig?: ClaimConfig[];
	feature?: string;
	features?: string[];
	featureCheckOptions?: boolean;
	children?: ReactNode;
}

const ProtectedContainer = ({
	claim,
	claimList,
	optionalClaimList,
	claimConfig,
	optionalClaimConfig,
	feature,
	features,
	featureCheckOptions,
	children,
	...otherProps
}: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const featuresSettings = useSelector((s: ApplicationState) => s?.currentSession?.customer?.featuresSettings);

	const validatedFeatures = features
		? ClaimUtil.validateFeatures(featuresSettings, features, featureCheckOptions)
		: ClaimUtil.validateFeature(featuresSettings, feature);

	if (
		validatedFeatures &&
		ClaimUtil.validateClaim(user, { claim }) &&
		ClaimUtil.validateClaimList(
			user,
			claimList?.map((c) => ({ claim: c }))
		) &&
		ClaimUtil.validateOptionalClaimList(
			user,
			optionalClaimList?.map((c) => ({ claim: c }))
		) &&
		ClaimUtil.validateClaims(user, claimConfig) &&
		ClaimUtil.validateOptionalClaims(user, optionalClaimConfig)
	) {
		const childrenWithProps = React.Children.map(children, (child, index) => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, {
					...child.props,
					key: index,
					...otherProps,
				});
			}
			return child;
		});
		return <>{childrenWithProps}</>;
	} else {
		return null;
	}
};

export default ProtectedContainer;
