import IdIdentifiable from './IdIdentifiable';

class SimCard extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.customerId = customerId;
	}
	active: boolean;
	customerId: string = null;
	customerDisplay: string = '';
	deviceId: string = null;
	deviceCode: string = '';
	objectName: string = '';
	imsi: string = '';
	iccid: string = '';
	phoneNumber: string = '';
	pinCode: string = '';
	provider: string = '';
}

export default SimCard;
