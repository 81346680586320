import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { L10n } from '@syncfusion/ej2-base';

import { FetchStatus } from '../models/FetchStatus';

const TRANSLATIONS_READY = 'TRANSLATIONS_READY';
const SET_TRANSLATION_STATUS = 'SET_LANGUAGE';

export const TEMP_LANGUAGE = 'en';

export interface TranslationState {
	language: '';
	translationStatus: FetchStatus;
}

const initialState: TranslationState = {
	language: '',
	translationStatus: FetchStatus.None,
};

export const translationsActionCreators = {
	setTranslationsReady: (accessToken: string, language: string) => (dispatch: any) => {
		dispatch({ type: SET_TRANSLATION_STATUS, translationStatus: FetchStatus.Loading });
		i18n.use(Backend)
			.use(initReactI18next)
			.use(I18nextBrowserLanguageDetector)
			.init({
				lng: language,
				fallbackLng: TEMP_LANGUAGE,
				interpolation: {
					escapeValue: false,
				},
				nsSeparator: false,
				backend: {
					loadPath: '/api/maintenance/translations/{{lng}}/{{ns}}',
					requestOptions: {
						mode: 'cors',
						credentials: 'same-origin',
						cache: 'default',
					},
					parse: function(data: any) {
						const translations: any = {};
						const jsonData = JSON.parse(data);
						const keys = Object.keys(jsonData.translations);
						for (let i = 0; i < keys.length; i++) {
							const keyParts = keys[i].split('.');
							const value = jsonData.translations[keys[i]];

							let parent = translations;
							for (let j = 0; j < keyParts.length; j++) {
								if (!parent.hasOwnProperty(keyParts[j])) {
									if (j == keyParts.length - 1) {
										parent[keyParts[j]] = value;
									} else {
										parent[keyParts[j]] = {};
										parent = parent[keyParts[j]];
									}
								} else {
									parent = parent[keyParts[j]];
								}
							}
						}

						const syncFusionTranslations: any = {};
						syncFusionTranslations[jsonData.sfLanguage] = {};
						const syncFusionKeys = Object.keys(jsonData.syncFusionTranslations);

						for (let i = 0; i < syncFusionKeys.length; i++) {
							const keyParts = syncFusionKeys[i].split('.');
							const value = jsonData.syncFusionTranslations[syncFusionKeys[i]];
							// let valueObject: any = {};

							let parent = syncFusionTranslations[jsonData.sfLanguage];
							for (let j = 0; j < keyParts.length; j++) {
								if (!parent.hasOwnProperty(keyParts[j])) {
									if (j == keyParts.length - 1) {
										parent[keyParts[j]] = value;
									} else {
										parent[keyParts[j]] = {};
										parent = parent[keyParts[j]];
									}
								} else {
									parent = parent[keyParts[j]];
								}
							}
						}

						L10n.load(syncFusionTranslations);
						return translations;
					},
					withCredentials: true,
					customHeaders: {
						Authorization: 'Bearer ' + accessToken,
					},
				},
			})
			.then(() => {
				dispatch({ type: TRANSLATIONS_READY, language: language, translationStatus: FetchStatus.Loaded });
			})
			.catch((reason) => {
				dispatch({ type: SET_TRANSLATION_STATUS, translationStatus: FetchStatus.Error });
			});
	},
};

export const translationsReducer = (state: any, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case TRANSLATIONS_READY:
			return {
				...state,
				language: action.language,
				translationStatus: action.translationStatus,
			};
		case SET_TRANSLATION_STATUS:
			return {
				...state,
				translationStatus: action.translationStatus,
			};
		default:
			return state;
	}
};
