import * as React from 'react';
import { TranslateText } from '../../utils/Translations';
import './FeatureUnavailable.scss';

interface Props {
	message?: string;
}

const FeatureUnavailable = (props: Props) => {
	return <div className="feature-unavailable"> {props.message || TranslateText('common.featureUnavailable')}</div>;
};

export default FeatureUnavailable;
