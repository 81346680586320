import './styles.scss';

import PrivateUserIcon from 'components/Common/Icons/PrivateUserIcon';
import SvgIconName from 'components/IconPicker/SvgIconName/SvgIconName';
import { SensorTemplateEnum } from 'components/LiveMap/types';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import {
	extendObjectNameWithTimeZoneLongFormat,
	shouldNotDisplayTimeZone,
} from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { useTripFullTimeZoneInfo } from 'components/RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/hooks';
import { NumberConstants } from 'components/Widgets/CustomerFeaturesView';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { StateFilterEnum } from 'models/LiveMenuUtils';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import TimeCounter from 'shared/components/TimeCounter';
import { useLocationsLive } from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { FormatDate } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';

import { InfoWindowContentProps } from './types';

const InfoWindowContent = (props: InfoWindowContentProps): JSX.Element => {
	const showLiveLocations = useLocationsLive();
	const { tripTimezone } = useTripFullTimeZoneInfo(props.data.timeZoneId, props.data.objectNameWithTimeZoneOffset);
	const customerTimezoneId = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);
	const customerTimeZoneMinutesOffset = useSelector(
		(s: ApplicationState) => s.currentSession.customerTimeZoneMinutesOffset
	);

	let since = null;
	if (props.data.since) {
		since = moment.utc(props.data.since).utcOffset(props.data.timeZoneMinutesOffset);
		since = FormatDate(since.toString(), false, false, true);
	}
	let lastSeen = null;
	if (props.data.lastSeen) {
		lastSeen = moment.utc(props.data.lastSeen).utcOffset(customerTimeZoneMinutesOffset);
		lastSeen = FormatDate(lastSeen.toString(), false, false, true);
		lastSeen = extendObjectNameWithTimeZoneLongFormat(
			lastSeen,
			customerTimeZoneMinutesOffset,
			!shouldNotDisplayTimeZone(customerTimezoneId, props.data.timeZoneId)
		);
	}

	return (
		<div className={'info-window-content'}>
			<>
				<div className="address" style={!showLiveLocations ? { paddingBottom: '6px' } : null}>
					{props.data?.address}
				</div>
				{showLiveLocations && (
					<div
						title={
							props.data?.locationName?.length > NumberConstants.maxLengthLocationNameEllipsis
								? props.data?.locationName
								: ''
						}
						className={
							props.data?.locationName?.length >= props.data?.address?.length &&
							props.data?.locationName?.length > NumberConstants.maxLengthLocationNameEllipsis
								? 'location-name very-long'
								: 'location-name'
						}
					>
						{props.data?.locationName}
					</div>
				)}
			</>
			<div className="grid-data-values">
				{!props.data.speed || props.data.state === StateFilterEnum.Parked ? null : (
					<>
						<span>{TranslateText('common.speed')}</span>
						<span className={'info-value'}>{`${parseInt(props.data.speed).toFixed(0)} km/h`}</span>
					</>
				)}
				{since === null ? null : (
					<>
						<span>{TranslateText('common.since')}</span>
						<span className={'info-value'}>{since}</span>
					</>
				)}
				{!shouldNotDisplayTimeZone(customerTimezoneId, props.data.timeZoneId) ? (
					<>
						<span>{TranslateText('maintenanceOverview.grid.colTimeZone')}</span>
						<span className={'info-value'}>{tripTimezone}</span>
					</>
				) : null}
				{lastSeen === null ? null : (
					<>
						<span>{TranslateText('common.lastSeen')}</span>
						<span className={'info-value'}>
							<ObjectNameWithTimeZoneOffset objectNameWithTimeZone={lastSeen} useToolTip={false} />
						</span>
					</>
				)}
			</div>

			{props.sensorTemplates?.length ? (
				<div className={'digital-sensors'}>
					<div className={'digital-sensors-column icon'}>
						{props.sensorTemplates.map((td) => (
							<div key={td.templateId} className="icon-container">
								{td.iconName ? (
									<SvgIconName
										iconName={td.iconName}
										color={td.labelColor ? td.labelColor : '#4d6d92'}
										size={16}
									/>
								) : td.sensorTemplateType === SensorTemplateEnum.Event ? (
									<span
										className="private-icon"
										style={{ fontSize: 14, color: td.labelColor ? td.labelColor : '#006837' }}
									>
										<PrivateUserIcon fontSize={'inherit'} />
									</span>
								) : null}
							</div>
						))}
					</div>
					<div className={'digital-sensors-column template-name'}>
						{props.sensorTemplates.map((td) => (
							<div
								key={td.templateId}
								title={td.templateName}
								style={{
									color: td.labelColor
										? td.labelColor
										: td.sensorTemplateType === SensorTemplateEnum.Event
										? '#006837'
										: '#4d6d92',
								}}
								className={'text'}
							>
								{td.templateName}
							</div>
						))}
					</div>
					<div className={'digital-sensors-column state-name'}>
						{props.sensorTemplates.map((td) => (
							<div
								key={td.templateId}
								title={td.stateName}
								style={{
									color: td.labelColor
										? td.labelColor
										: td.sensorTemplateType === SensorTemplateEnum.Event
										? '#006837'
										: '#4d6d92',
								}}
								className={'text'}
							>
								{td.stateName}
							</div>
						))}
					</div>
				</div>
			) : null}

			{props.connectionBroken &&
			(props.data.entityType === EntityTypeEnum.Object ||
				(props.data.entityType === EntityTypeEnum.Person && props.data.state !== StateFilterEnum.Parked)) ? (
				<div className={'live-icon-row  error'}>
					<span className="notification-icon">
						<PortableWifiOffIcon fontSize={'inherit'} />
					</span>
					<span className="notification-text">
						{TranslateText('common.noConnection')}
						<span>
							{' ('}
							<TimeCounter
								hidden={
									props.heartBeatPresent
										? props.data.lastKeepAlive === null
										: props.data.lastSeen === null
								}
								referenceDate={
									props.heartBeatPresent
										? new Date(props.data.lastKeepAlive)
										: new Date(props.data.lastSeen)
								}
								refreshRateInMilliseconds={2000}
								showSeconds={false}
							/>
							{')'}
						</span>
					</span>
				</div>
			) : null}
		</div>
	);
};

export default InfoWindowContent;
