import TripTypesEnum from 'models/TripTypesEnum';
import moment from 'moment-timezone';

import { HistoryDateFilter } from '../../store/HistoryFilter';
import { ProcessedTripDto, TripGrouping } from '../../store/HistoryStore';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { historyGridUtil, IGrouping } from '../HistoryOverview/HistoryOverviewGrid';
import { HistoryTimeRange } from '../HistoryOverview/HistoryTimeRange';
import { Group } from './HistorySideBar';

export const tripHistorySorting = {
	//sort by trip number asc, start trip date without time desc, trip is parked(true top), object name or person name asc
	sortAscForTripNumberGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.index === trip2.index) {
			const trip1Start = moment(trip1.startTrip);
			const trip2Start = moment(trip2.startTrip);
			if (trip1Start.isSame(trip2Start, 'day')) {
				const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
				const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;

				if (trip1EntityName === trip2EntityName) {
					return trip1.isParked ? 1 : -1;
				}

				return trip1EntityName > trip2EntityName ? 1 : -1;
			}
			return trip1Start < trip2Start ? 1 : -1;
		}

		return trip1.index > trip2.index ? 1 : -1;
	},
	//sort by trip number asc, start trip date without time desc, trip is parked(true top), object name or person name asc
	sortDescForTripNumberGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.index === trip2.index) {
			const trip1Start = moment(trip1.startTrip);
			const trip2Start = moment(trip2.startTrip);
			if (trip1Start.isSame(trip2Start, 'day')) {
				const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
				const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;

				if (trip1EntityName === trip2EntityName) {
					return trip1.isParked ? -1 : 1;
				}

				return trip1EntityName > trip2EntityName ? 1 : -1;
			}
			return trip1Start < trip2Start ? 1 : -1;
		}

		return trip1.index < trip2.index ? 1 : -1;
	},

	//sort by object name asc, trip start asc, trip is parked(true top)
	sortAscForObjectGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.objectName === trip2.objectName) {
			if (trip1.isParked !== trip2.isParked) {
				const trip1Start = moment(trip1.startTrip);
				const trip2Start = moment(trip2.startTrip);
				if (trip1Start.isSame(trip2Start, 'day')) {
					return trip1.isParked ? 1 : -1;
				}
			}

			return trip1.startTrip > trip2.startTrip ? 1 : -1;
		}
		return trip1.objectName > trip2.objectName ? 1 : -1;
	},
	//sort by object name asc, trip start desc, trip is parked(true top)
	sortDescForObjectGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.objectName === trip2.objectName) {
			if (trip1.isParked !== trip2.isParked) {
				const trip1Start = moment(trip1.startTrip);
				const trip2Start = moment(trip2.startTrip);
				if (trip1Start.isSame(trip2Start, 'day')) {
					return trip1.isParked ? -1 : 1;
				}
			}

			return trip1.startTrip < trip2.startTrip ? 1 : -1;
		}
		return trip1.objectName > trip2.objectName ? 1 : -1;
	},

	//sort by date without time asc, object name asc, trip is parked(true top), date with time asc
	sortAscForDateGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		const trip1Start = moment(trip1.startTrip);
		const trip2Start = moment(trip2.startTrip);
		if (trip1Start.isSame(trip2Start, 'day')) {
			const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
			const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;
			if (trip1EntityName === trip2EntityName) {
				if (trip1.isParked !== trip2.isParked) {
					return trip1.isParked ? 1 : -1;
				}

				return trip1Start > trip2Start ? 1 : -1;
			}
			return trip1EntityName > trip2EntityName ? 1 : -1;
		}
		return trip1Start > trip2Start ? 1 : -1;
	},
	//sort by date without time desc, object name asc, trip is parked(true top), date with time desc
	sortDescForDateGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		const trip1Start = moment(trip1.startTrip);
		const trip2Start = moment(trip2.startTrip);
		if (trip1Start.isSame(trip2Start, 'day')) {
			const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
			const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;
			if (trip1EntityName === trip2EntityName) {
				if (trip1.isParked !== trip2.isParked) {
					return trip1.isParked ? -1 : 1;
				}

				return trip1Start < trip2Start ? 1 : -1;
			}
			return trip1EntityName > trip2EntityName ? 1 : -1;
		}
		return trip1Start < trip2Start ? 1 : -1;
	},

	//sort by date without time asc, object name asc, trip is parked(true top), date with time asc
	sortAscForEndDateGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		const trip1End = moment(trip1.endTrip);
		const trip2End = moment(trip2.endTrip);
		if (trip1End.isSame(trip2End, 'day')) {
			const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
			const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;
			if (trip1EntityName === trip2EntityName) {
				if (trip1.isParked !== trip2.isParked) {
					return trip1.isParked ? 1 : -1;
				}

				return trip1End > trip2End ? 1 : -1;
			}
			return trip1EntityName > trip2EntityName ? 1 : -1;
		}
		return trip1End > trip2End ? 1 : -1;
	},
	//sort by date without time desc, object name asc, trip is parked(true top), date with time desc
	sortDescForEndDateGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		const trip1End = moment(trip1.endTrip);
		const trip2End = moment(trip2.endTrip);
		if (trip1End.isSame(trip2End, 'day')) {
			const trip1EntityName = trip1.isPerson ? trip1.personName : trip1.objectName;
			const trip2EntityName = trip2.isPerson ? trip2.personName : trip2.objectName;
			if (trip1EntityName === trip2EntityName) {
				if (trip1.isParked !== trip2.isParked) {
					return trip1.isParked ? -1 : 1;
				}

				return trip1End < trip2End ? 1 : -1;
			}
			return trip1EntityName > trip2EntityName ? 1 : -1;
		}
		return trip1End < trip2End ? 1 : -1;
	},

	//sort by person name asc, trip start asc
	sortAscForDriverGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.personName === trip2.personName) {
			if (trip1.isParked !== trip2.isParked) {
				const trip1Start = moment(trip1.startTrip);
				const trip2Start = moment(trip2.startTrip);
				if (trip1Start.isSame(trip2Start, 'day')) {
					return trip1.isParked ? 1 : -1;
				}
			}

			return trip1.startTrip > trip2.startTrip ? 1 : -1;
		}
		return trip1.personName > trip2.personName ? 1 : -1;
	},
	//sort by person name asc, trip start desc
	sortDescForDriverGrouping: (trip1: ProcessedTripDto, trip2: ProcessedTripDto): number => {
		if (trip1.personName === trip2.personName) {
			if (trip1.isParked !== trip2.isParked) {
				const trip1Start = moment(trip1.startTrip);
				const trip2Start = moment(trip2.startTrip);
				if (trip1Start.isSame(trip2Start, 'day')) {
					return trip1.isParked ? -1 : 1;
				}
			}

			return trip1.startTrip < trip2.startTrip ? 1 : -1;
		}
		return trip1.personName > trip2.personName ? 1 : -1;
	},
};

const getGroupName = (group: Group, groupBy: TripGrouping): string => {
	if (groupBy === TripGrouping.tripDate) {
		return group.name;
	}
	if (groupBy === TripGrouping.tripNumber) {
		return `${TranslateText('common.tripTitle')} ${group.name}`;
	}
	if (groupBy === TripGrouping.objectName) {
		return group.name || TranslateText('historyOverview.unknownObject');
	}
	if (groupBy === TripGrouping.driverName) {
		return group.name || TranslateText('historyOverview.unknownPerson');
	}
	return group.name;
};

const getMappedSingleGrouping = (groupedData: IGrouping<ProcessedTripDto[]>, firstGrouping: TripGrouping): Group[] => {
	const newGroupedTripsData: Group[] = [];
	Object.getOwnPropertyNames(groupedData).forEach((key) => {
		const firstGroup: Group = {
			name: key,
			displayName: null,
			groupInfo: {
				allVisible: true,
				someVisible: false,
				groupingType: firstGrouping,
				level: 1,
				tripsNumber: 0,
				totalDrivingTime: 0,
				totalDistance: 0,
				allNonTrips: true,
			},
			childGroups: [],
			trips: groupedData[key].data,
		};
		firstGroup.displayName = getGroupName(firstGroup, firstGrouping);

		if (firstGrouping === TripGrouping.objectOrDriver && groupedData[key].data.length) {
			//if the grouping is objectOrDriver set the the icon from first trip (all the trips from group should have the same value for isPerson)
			firstGroup.groupInfo.iconType = groupedData[key].data[0].isPerson
				? 'driver'
				: groupedData[key].data[0].objectIcon;
		}

		for (let i = 0; i < groupedData[key].data.length; i++) {
			const trip = groupedData[key].data[i];
			firstGroup.groupInfo.allVisible = firstGroup.groupInfo.allVisible && trip.visible;
			firstGroup.groupInfo.someVisible = firstGroup.groupInfo.someVisible || trip.visible;
			if (!trip.isParked) {
				firstGroup.groupInfo.tripsNumber += 1;
				if (!trip.isNonTrip) {
					firstGroup.groupInfo.totalDrivingTime += trip.tripDuration;
					firstGroup.groupInfo.totalDistance += trip.totalMileage;
					firstGroup.groupInfo.allNonTrips = false;
				}
			}
		}

		newGroupedTripsData.push(firstGroup);
	});

	return newGroupedTripsData;
};

const getMappedMultipleGrouping = (
	groupedData: IGrouping<IGrouping<ProcessedTripDto[]>>,
	firstGrouping: TripGrouping,
	secondGrouping: TripGrouping
): Group[] => {
	const newGroupedTripsData: Group[] = [];
	Object.getOwnPropertyNames(groupedData).forEach((firstGroupKey) => {
		const firstGroup: Group = {
			name: firstGroupKey,
			displayName: null,
			groupInfo: {
				allVisible: true,
				someVisible: false,
				groupingType: firstGrouping,
				level: 1,
				tripsNumber: 0,
				totalDrivingTime: 0,
				totalDistance: 0,
				allNonTrips: true,
			},
			childGroups: [],
			trips: [],
		};
		firstGroup.displayName = getGroupName(firstGroup, firstGrouping);

		const group = groupedData[firstGroupKey];
		Object.getOwnPropertyNames(group.data).forEach((secondGroupKey) => {
			const secondGroup: Group = {
				name: secondGroupKey,
				displayName: null,
				groupInfo: {
					allVisible: true,
					someVisible: false,
					groupingType: secondGrouping,
					level: 2,
					tripsNumber: 0,
					totalDrivingTime: 0,
					totalDistance: 0,
					allNonTrips: true,
				},
				childGroups: [],
				trips: group.data[secondGroupKey].data,
			};
			secondGroup.displayName = getGroupName(secondGroup, secondGrouping);
			if (secondGrouping === TripGrouping.objectOrDriver && group.data[secondGroupKey].data.length) {
				//if the grouping is objectOrDriver set the the icon from first trip (all the trips from group should have the same value for isPerson)
				secondGroup.groupInfo.iconType = group.data[secondGroupKey].data[0].isPerson
					? 'driver'
					: group.data[secondGroupKey].data[0].objectIcon;
			}

			for (let i = 0; i < group.data[secondGroupKey].data.length; i++) {
				const trip = group.data[secondGroupKey].data[i];
				secondGroup.groupInfo.allVisible = secondGroup.groupInfo.allVisible && trip.visible;
				secondGroup.groupInfo.someVisible = secondGroup.groupInfo.someVisible || trip.visible;
				if (!trip.isParked) {
					secondGroup.groupInfo.tripsNumber += 1;
					if (!trip.isNonTrip) {
						secondGroup.groupInfo.totalDrivingTime += trip.tripDuration;
						secondGroup.groupInfo.totalDistance += trip.totalMileage;
						secondGroup.groupInfo.allNonTrips = false;
					}
				}
			}

			firstGroup.childGroups.push(secondGroup);
			firstGroup.groupInfo.allVisible = firstGroup.groupInfo.allVisible && secondGroup.groupInfo.allVisible;
			firstGroup.groupInfo.someVisible = firstGroup.groupInfo.someVisible || secondGroup.groupInfo.someVisible;
			firstGroup.groupInfo.tripsNumber += secondGroup.groupInfo.tripsNumber;
			firstGroup.groupInfo.totalDrivingTime += secondGroup.groupInfo.totalDrivingTime;
			firstGroup.groupInfo.totalDistance += secondGroup.groupInfo.totalDistance;
			firstGroup.groupInfo.allNonTrips = firstGroup.groupInfo.allNonTrips && secondGroup.groupInfo.allNonTrips;
		});

		newGroupedTripsData.push(firstGroup);
	});

	return newGroupedTripsData;
};

export const getGrouping = (
	historyTrips: ProcessedTripDto[],
	groupBy: TripGrouping,
	ascending: boolean,
	entitiesNumber: number,
	dateFilter: HistoryDateFilter,
): Group[] => {
	let newGroupedTripsData: Group[] = [];
	if (groupBy === TripGrouping.tripNumber) {
		const sortedHistoryTrips = ascending
			? [...historyTrips].sort(tripHistorySorting.sortAscForTripNumberGrouping)
			: [...historyTrips].sort(tripHistorySorting.sortDescForTripNumberGrouping);

		if (entitiesNumber > 1) {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
				(trip) => `${trip.index + 1}: ${FormatDate(trip.startTrip, true, true, true)}`,
				(trip) => (trip.isPerson ? trip.personName : trip.objectNameWithTimeZoneOffset),
			]);
			newGroupedTripsData = getMappedMultipleGrouping(
				grouping as IGrouping<IGrouping<ProcessedTripDto[]>>,
				TripGrouping.tripNumber,
				TripGrouping.objectOrDriver
			);
		} else {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
				(trip) => `${trip.index + 1}: ${FormatDate(trip.startTrip, true, true, true)}`,
			]);
			newGroupedTripsData = getMappedSingleGrouping(
				grouping as IGrouping<ProcessedTripDto[]>,
				TripGrouping.tripNumber
			);
		}
	} else if (groupBy === TripGrouping.objectName) {
		//for object grouping keep only object trips if trips are duplicated(the same trip is displayed for both object of the trip and driver of the trip, if object and driver are part of merge selection)
		const filteredHistoryTrips: ProcessedTripDto[] = [];
		for (let i = 0; i < historyTrips.length; i++) {
			const existingIndex = filteredHistoryTrips.findIndex(
				(x) => x.id === historyTrips[i].id && x.isParked === historyTrips[i].isParked
			);
			if (existingIndex < 0) {
				filteredHistoryTrips.push(historyTrips[i]);
			} else if (!historyTrips[i].isPerson) {
				filteredHistoryTrips[existingIndex] = historyTrips[i];
			}
		}

		const sortedHistoryTrips = ascending
			? [...filteredHistoryTrips].sort(tripHistorySorting.sortAscForObjectGrouping)
			: [...filteredHistoryTrips].sort(tripHistorySorting.sortDescForObjectGrouping);

		//if only one day is selected don't group by day
		if (dateFilter.historyTimeRange === HistoryTimeRange.Day) {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
				(trip) => trip.objectNameWithTimeZoneOffset,
			]);

			newGroupedTripsData = getMappedSingleGrouping(
				grouping as IGrouping<ProcessedTripDto[]>,
				TripGrouping.objectName
			);
		} else {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
				(trip) => trip.objectNameWithTimeZoneOffset,
				(trip) => FormatDate(trip.startTrip, true, true, true),
			]);

			newGroupedTripsData = getMappedMultipleGrouping(
				grouping as IGrouping<IGrouping<ProcessedTripDto[]>>,
				TripGrouping.objectName,
				TripGrouping.tripDate
			);
		}
	} else if (groupBy === TripGrouping.tripDate) {
		const sortedHistoryTrips = ascending
			? [...historyTrips].sort(tripHistorySorting.sortAscForDateGrouping)
			: [...historyTrips].sort(tripHistorySorting.sortDescForDateGrouping);

		const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
			(trip) => FormatDate(new Date(trip.startTrip), true, true, true),
			(trip) => (trip.isPerson ? trip.personName : trip.objectNameWithTimeZoneOffset),
		]);

		newGroupedTripsData = getMappedMultipleGrouping(
			grouping as IGrouping<IGrouping<ProcessedTripDto[]>>,
			TripGrouping.tripDate,
			TripGrouping.objectOrDriver
		);
	} else if (groupBy === TripGrouping.driverName) {
		//for driver grouping keep only driver trips if trips are duplicated(the same trip is displayed for both object of the trip and driver of the trip, if object and driver are part of merge selection)
		const filteredHistoryTrips: ProcessedTripDto[] = [];
		for (let i = 0; i < historyTrips.length; i++) {
			const existingIndex = filteredHistoryTrips.findIndex(
				(x) => x.id === historyTrips[i].id && x.isParked === historyTrips[i].isParked
			);
			if (existingIndex < 0) {
				filteredHistoryTrips.push(historyTrips[i]);
			} else if (historyTrips[i].isPerson) {
				filteredHistoryTrips[existingIndex] = historyTrips[i];
			}
		}

		const sortedHistoryTrips = ascending
			? [...filteredHistoryTrips].sort(tripHistorySorting.sortAscForDriverGrouping)
			: [...filteredHistoryTrips].sort(tripHistorySorting.sortDescForDriverGrouping);

		//if only one day is selected don't group by day
		if (dateFilter.historyTimeRange === HistoryTimeRange.Day) {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [(trip) => trip.personName]);

			newGroupedTripsData = getMappedSingleGrouping(
				grouping as IGrouping<ProcessedTripDto[]>,
				TripGrouping.driverName
			);
		} else {
			const grouping = historyGridUtil.createGrouping(sortedHistoryTrips, [
				(trip) => trip.personName,
				(trip) => FormatDate(new Date(trip.startTrip), true, true, true),
			]);

			newGroupedTripsData = getMappedMultipleGrouping(
				grouping as IGrouping<IGrouping<ProcessedTripDto[]>>,
				TripGrouping.driverName,
				TripGrouping.tripDate
			);
		}
	}

	return newGroupedTripsData;
};

export const getNumberDays = (from: Date | string, to: Date | string, ignoreBrowserUtc = false) => {
	if (!from || !to) {
		return 0;
	}

	const fromM = ignoreBrowserUtc ? moment.utc(from) : moment(from);
	const toM = ignoreBrowserUtc ? moment.utc(to) : moment(to);

	return Math.abs(toM.diff(fromM, 'days'));
};

export const TripColorsHistoryMap: string[] = [
	'#B20000', //red
	'#118ab2', //blue
	'#006400', //dark green
	'#e67e00', //dark orange
	'#000000', //black
	'#808080', //gray
	'#7b4686', //dark purple
];

export const GetTripType = (trackType: TripTypesEnum): string => {
	return TranslateText('historyOverview.' + TripTypesEnum[trackType]?.toLowerCase());
};
