import './CustomerImportWidget.scss';

import React, { useEffect, useRef, useState } from 'react';

import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import * as GlobalSettings from '../../../GlobalSettings.json';
import NotificationPrompt from '../../../shared/components/UserPrompt/NotificationPrompt';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import WidgetHeader from '../../BaseWidget/WidgetHeader';

export interface IEditableWidget {
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	showEditButton?: boolean;
}

export interface WidgetProps extends IEditableWidget {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	url?: string;
	widgetTitle: string;
	forceReload?: boolean;

	changeDataCallback?: (hasChanges: boolean) => void;
}

const validateXlsxFormat = (name: string) => {
	return name.endsWith('.xlsx');
};

const CustomerImportWidget = (props: WidgetProps) => {
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
	});

	const [popupConfirmation, setPopupConfirmation] = useState<{
		title: string;
		message: string;
		userResponse: (response: boolean) => void;
	} | null>();

	const inputFileUpload = useRef<HTMLInputElement>(null);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [includeInactive, setIncludeInactive] = useState(false);
	const [importResult, setImportResult] = useState<{ message: string; success: boolean }>(null);
	const [validationMessage, setValidationMessage] = useState<string>(null);
	const [isImporting, setIsImporting] = useState(false);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		props.changeDataCallback(flags.hasChanges);
	}, [flags.hasChanges]);

	const util = {
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode, hasChanges: false });
		},
		resetLocalChanges: () => {
			setIncludeInactive(false);
			setSelectedFile(null);
			setImportResult(null);
			setValidationMessage(null);
			if (inputFileUpload.current) {
				inputFileUpload.current.value = null;
			}
		},
		cancelConfirmation: async () => {
			return new Promise<boolean>((resolve) => {
				setPopupConfirmation({
					title: TranslateText('common.titleUnsavedData'),
					message: TranslateText('notificationMessages.cancel'),
					userResponse: (response: boolean) => {
						resolve(response);
						setPopupConfirmation(null);
					},
				});
			});
		},
		importFile: async () => {
			const formData = new FormData();
			formData.append('File', selectedFile, selectedFile.name);
			setIsImporting(true);

			formData.append('IncludeInactiveEntities', includeInactive ? 'true' : 'false');
			const response = await ajaxUtil.post<{ errorMessage: string; success: boolean }>(
				`${GlobalSettings.customersMaintenanceApi}/import/${props.entityId}`,
				formData,
				{
					isFormData: true,
				}
			);

			setImportResult({
				success: response.success,
				message: response.success ? TranslateText('dataImport.successContent') : response.errorMessage,
			});
			if (response.success === false) {
				setValidationMessage('File has conflicts and should be reuploaded');
			}
			setIsImporting(false);
		},
		closeEditMode: async (success: boolean) => {
			if (success === true) {
				util.setEditMode(false);
				util.resetLocalChanges();
			}
			setImportResult(null);
		},
	};

	const events = {
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.cancelConfirmation()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const newValue = !flags.editMode;
			if (!newValue && flags.hasChanges) {
				if (await util.cancelConfirmation()) {
					util.setEditMode(newValue);
					util.resetLocalChanges();
				}
			} else {
				util.resetLocalChanges();
				util.setEditMode(newValue);
			}
		},
		cancelClicked: async () => {
			if (!flags.hasChanges) {
				util.setEditMode(false);
				util.resetLocalChanges();
			} else if (await util.cancelConfirmation()) {
				util.setEditMode(false);
				util.resetLocalChanges();
			}
		},
		onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => {
			if (!e?.target?.files[0]) {
				return;
			}
			if (e?.target?.files?.length && validateXlsxFormat(e?.target?.files[0].name)) {
				setSelectedFile(e.target.files[0]);
				setFlags({ ...flags, hasChanges: true });
				setValidationMessage('');
			} else {
				setImportResult({ message: TranslateText('dataImport.wrongFileExtension'), success: false });
			}
		},
		onInactiveChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			setIncludeInactive(event.target.checked);
		},
	};

	return (
		<div
			className={`e-panel widget ${flags.visible ? '' : 'hidden'}`}
			data-row={props.position.row}
			data-col={props.position.col}
			data-sizex={props.position.sizeX}
			data-sizey={props.position.sizeY}
			data-minsizex={props.position.sizeX}
			data-minsizey={props.position.sizeY}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>
				{popupConfirmation ? (
					<NotificationPrompt
						title={popupConfirmation.title}
						message={popupConfirmation.message}
						handleUserResponse={popupConfirmation.userResponse}
					/>
				) : null}
				<div className={`widget-view customer-import ${flags.editMode ? '' : 'readonly'}`}>
					<div className={'view-section-wrapper'}>
						<div className="form-group">
							<input
								ref={inputFileUpload}
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								style={{ display: 'none' }}
								id="input-file-upload"
								type="file"
								onChange={events.onFileChange}
							/>
							<label
								className="input-file-label"
								onClick={(e) => {
									if (!flags.editMode) {
										e.preventDefault();
										e.stopPropagation();
									}
								}}
								htmlFor="input-file-upload"
							>
								<Button
									size={'small'}
									disabled={!flags.editMode}
									variant="contained"
									color="primary"
									component="span"
									startIcon={<CloudUploadIcon />}
								>
									{TranslateText('dataImport.choose')}
								</Button>
							</label>
							<div className="selected-file-container">
								<span className="selected-file-name">
									{selectedFile ? selectedFile.name : TranslateText('dataImport.noFileSelected')}
								</span>
								{selectedFile ? (
									<IconButton
										onClick={() => {
											setSelectedFile(null);
											setValidationMessage('');
											setFlags({ ...flags, hasChanges: false });
											if (inputFileUpload.current) {
												inputFileUpload.current.value = null;
											}
										}}
										size={'small'}
									>
										<ClearIcon />
									</IconButton>
								) : null}
							</div>
							<div>
								<span className="error-validation-message">
									{validationMessage ? validationMessage : ''}
								</span>
							</div>
						</div>
						<div className="form-group">
							<FormControlLabel
								disabled={!flags.editMode}
								control={
									<Checkbox
										checked={includeInactive}
										onChange={events.onInactiveChange}
										name="inactiveEntities"
										color="primary"
									/>
								}
								label={TranslateText('dataImport.checkboxLabel')}
							/>
						</div>
					</div>
				</div>

				{flags.editMode ? (
					<div className="buttons-host">
						<Button className="widget-button" onClick={events.cancelClicked}>
							{TranslateText('common.buttonCancel')}
						</Button>
						<Button
							className="widget-button"
							onClick={() => util.importFile()}
							disabled={!selectedFile || isImporting || !!validationMessage}
						>
							{TranslateText('dataImport.btnImport')}
						</Button>
					</div>
				) : null}

				{importResult ? (
					<Dialog
						open={true}
						onClose={() => setImportResult(null)}
						scroll={'paper'}
						aria-labelledby="scroll-dialog-title"
						aria-describedby="scroll-dialog-description"
					>
						<DialogTitle id="scroll-dialog-title">
							{importResult.success
								? TranslateText('dataImport.popupTitleSuccess')
								: TranslateText('dataImport.popupTitleFail')}
						</DialogTitle>
						<DialogContent dividers>
							<pre>{importResult.message}</pre>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => util.closeEditMode(importResult.success)} color="primary">
								{TranslateText('common.buttonOk')}
							</Button>
						</DialogActions>
					</Dialog>
				) : null}
			</div>
		</div>
	);
};

CustomerImportWidget.defaultProps = {
	showEditButton: true,
};

export default CustomerImportWidget;
