import EntityTypeEnum from 'models/EntityTypeEnum';
import { GridFilters, SortOrder } from 'models/GridOverview';
import { Dispatch } from 'redux';

export type GridOverviewState = {
	[key in EntityTypeEnum | string]: {
		gridFilters: GridFilters;
		gridSelectedEntityId: string;
		gridPage: number;
		fromMenu: boolean;
		inlineGridRedirect?: boolean;
	};
};

const initialState: GridOverviewState = {
	[EntityTypeEnum.Object]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Device]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Key]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Driver]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Alert]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			flagOrder: '',
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Customer]: {
		gridFilters: {
			sortField: '',
			sortOrder: SortOrder.NoSort,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Group]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Location]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
			flagOrder: '',
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Person]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Role]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Report]: {
		gridFilters: {
			sortField: 'generatedDateTime',
			sortOrder: SortOrder.Descendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.SimCard]: {
		gridFilters: {
			sortField: 'iccid',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Template]: {
		gridFilters: {
			sortField: 'code',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Translation]: {
		gridFilters: {
			sortField: '',
			sortOrder: SortOrder.NoSort,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.InformationNotification]: {
		gridFilters: {
			sortField: 'startDateTime',
			sortOrder: SortOrder.Ascendent,
			filters: {},
			includeTime: true,
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.Integration]: {
		gridFilters: {
			sortField: 'name',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
	[EntityTypeEnum.ConfigurationGroup]: {
		gridFilters: {
			sortField: 'name',
			sortOrder: SortOrder.Ascendent,
			filters: {},
		},
		gridSelectedEntityId: '',
		gridPage: 0,
		fromMenu: false,
	},
};

enum GridActionType {
	UPDATE_GRID_FILTERS = 'UPDATE_GRID_FILTERS',
	UPDATE_GRID_ENTITY_ID = 'UPDATE_GRID_ENTITY_ID',
	UPDATE_GRID_PAGE = 'UPDATE_GRID_PAGE',
	UPDATE_GRID_FROM_MENU = 'UPDATE_GRID_FROM_MENU',
	UPDATE_INLINEGRID_REDIRECT = 'UPDATE_INLINEGRID_REDIRECT',
	CLEAR_INLINEGRID_REDIRECT = 'CLEAR_INLINEGRID_REDIRECT',
}

type GridAction = {
	type: GridActionType;
	gridEntity: EntityTypeEnum;
	filters?: GridFilters;
	entityId?: string;
	skip?: number;
	fromMenu?: boolean;
	inlineGridRedirect?: boolean;
};

export const gridActionCreators = {
	updateGridFilters: (gridEntity: EntityTypeEnum, filters: GridFilters | null) => (
		dispatch: Dispatch<GridAction>
	) => {
		dispatch({ type: GridActionType.UPDATE_GRID_FILTERS, gridEntity, filters });
	},
	updateGridSelectedEntityId: (gridEntity: EntityTypeEnum, entityId: string) => (dispatch: Dispatch<GridAction>) => {
		dispatch({ type: GridActionType.UPDATE_GRID_ENTITY_ID, gridEntity, entityId });
	},
	updateGridPage: (gridEntity: EntityTypeEnum, skip: number) => (dispatch: Dispatch<GridAction>) => {
		dispatch({ type: GridActionType.UPDATE_GRID_PAGE, gridEntity, skip });
	},
	updateGridFromMenu: (gridEntity: EntityTypeEnum, fromMenu: boolean) => (dispatch: Dispatch<GridAction>) => {
		dispatch({ type: GridActionType.UPDATE_GRID_FROM_MENU, gridEntity, fromMenu });
	},
	updateInlineGridRedirect: (gridEntity: EntityTypeEnum, entityId: string) => (dispatch: Dispatch<GridAction>) => {
		dispatch({
			type: GridActionType.UPDATE_INLINEGRID_REDIRECT,
			entityId,
			gridEntity,
		});
	},
	clearInlineGridRedirect: (gridEntity: EntityTypeEnum) => (dispatch: Dispatch<GridAction>) => {
		dispatch({
			type: GridActionType.CLEAR_INLINEGRID_REDIRECT,
			gridEntity,
		});
	},
};

export const gridReducer = (state: GridOverviewState, action: GridAction): GridOverviewState => {
	state = state || initialState;
	const stateKeys = Object.keys(state);
	const initialStateKeys = Object.keys(initialState);

	if (stateKeys.length !== initialStateKeys.length) {
		state = initialState;
	}

	const currentGrid = { ...state[action.gridEntity] };
	switch (action.type) {
		case GridActionType.UPDATE_GRID_FILTERS:
			currentGrid.gridFilters = action.filters;
			return {
				...state,
				[action.gridEntity]: currentGrid,
			};
		case GridActionType.UPDATE_GRID_ENTITY_ID:
			currentGrid.gridSelectedEntityId = action.entityId;
			return {
				...state,
				[action.gridEntity]: currentGrid,
			};
		case GridActionType.UPDATE_GRID_PAGE: {
			currentGrid.gridPage = action.skip;
			return {
				...state,
				[action.gridEntity]: currentGrid,
			};
		}
		case GridActionType.UPDATE_GRID_FROM_MENU: {
			currentGrid.fromMenu = action.fromMenu;
			return {
				...state,
				[action.gridEntity]: currentGrid,
			};
		}
		case GridActionType.UPDATE_INLINEGRID_REDIRECT: {
			return {
				...state,
				[action.gridEntity]: {
					...initialState[action.gridEntity],
					gridSelectedEntityId: action.entityId,
					inlineGridRedirect: true,
				},
			};
		}
		case GridActionType.CLEAR_INLINEGRID_REDIRECT: {
			return {
				...state,
				[action.gridEntity]: { ...currentGrid, inlineGridRedirect: false },
			};
		}
		default:
			return state;
	}
};
