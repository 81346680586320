import {OBJECT_DEFAULT_ICON} from '../Constants';
import FuelTypeEnum from './FuelTypeEnum';
import IdIdentifiable from './IdIdentifiable';
import ObjectFunctionEnum from './ObjectFunctionEnum';
import ObjectTypeEnum from './ObjectTypeEnum';
import CO2CalculationTypeEnum from "./CO2CalculationTypeEnum";

class TrackableObject extends IdIdentifiable {
	constructor(customerId?: string, timeZone?: string) {
		super();
		this.customerId = customerId ? customerId : '';
		this.timezoneId = timeZone ? timeZone : '';
	}

	public code = '';
	public externalCode = '';
	public customerId = '';
	public customerName = '';
	public licensePlate = '';
	public name = '';
	public vin = '';
	public icon = OBJECT_DEFAULT_ICON;
	public brand = '';
	public model = '';
	public fuelType: FuelTypeEnum = FuelTypeEnum.Unknown;
	public objectType: ObjectTypeEnum = ObjectTypeEnum.Unknown;
	public objectFunction: ObjectFunctionEnum = ObjectFunctionEnum.Full;
	public co2CalculationType: CO2CalculationTypeEnum = CO2CalculationTypeEnum.DistanceTypeAndFuel;
	public mileage: number = null;
	public activationDate: Date = new Date();
	public deviceId: string = null;
	public objectDeviceConnectionId: string;
	public deviceCode = '';
	public simCardIMSI = '';
	public simCardPhoneNumber = '';
	public timeOutEndOfTrip: number = null;
	public toleranceTimeOutDistance: number = null;
	public timezoneId = '';
	public timezoneDisplay = '';
	public active = true;
	public mileageCorrectionRequested = false;
	public editableMileage = true;
	public tripColor = '#00008B';
	public addInGroups: string[] = [];
	public hasTrips: boolean = false;
	public objectImageId: string = '';
	public base64Image: string = null;
	public co2Manual: number = null;
	public co2Nedc: number = null;
	public co2Wltp: number = null;
	public motExireDate: Date = null;
	public motorInsuranceLiabilityAct: boolean = null;
	public tachographExpireDate: Date = null;
}

export default TrackableObject;
