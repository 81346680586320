import CommandTypeEnum from 'models/CommandTypeEnum';
import DateTimeUtil from 'shared/datetime/DateTimeUtil';

import { FormatDateWithTimezone } from '../../../utils/DateUtils';
import { TranslateText } from '../../../utils/Translations';
import { LogMessage, MileageType } from './LogMessage';

export const getFormattedSrvDevTimeDiff = (
	messsageReceivedDateTime: string | Date,
	messsageObservationTime: string | Date
) => {
	if (messsageReceivedDateTime && messsageObservationTime) {
		const srvTime = new Date(messsageReceivedDateTime);
		const devTime = new Date(messsageObservationTime);

		if (srvTime.getTime() === devTime.getTime()) {
			return '0';
		} else if (srvTime.getTime() > devTime.getTime()) {
			return DateTimeUtil.calculateTimePassed(devTime, srvTime);
		} else if (srvTime.getTime() < devTime.getTime()) {
			return `-${DateTimeUtil.calculateTimePassed(srvTime, devTime)}`;
		}
	}

	return '-';
};

export const formatDetails = (item: LogMessage) => {
	const timezone = item.timezone;

	if (item.commandType) {
		//for commands (sent)
		let formattedDetails = `${TranslateText('widgets.grid.colUnit')}: ${item.unitId}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colMessageReceivedTime')}: ${
			item.messsageReceivedDateTime === null
				? '-'
				: `${FormatDateWithTimezone(item.messsageReceivedDateTime, timezone)}(${getFormattedSrvDevTimeDiff(
						item.messsageReceivedDateTime,
						item.messsageObservationTime
				  )})`
		}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colCommand')}: ${CommandTypeEnum[item.commandType]}`;

		item.formattedDetails = formattedDetails;
	} else {
		//for events (received)
		let formattedDetails = `${TranslateText('widgets.grid.colUnit')}: ${item.unitId}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colMessageReceivedTime')}: ${
			item.messsageReceivedDateTime === null
				? '-'
				: `${FormatDateWithTimezone(item.messsageReceivedDateTime, timezone)}(${getFormattedSrvDevTimeDiff(
						item.messsageReceivedDateTime,
						item.messsageObservationTime
				  )})`
		}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colMessageObservationTime')}: ${
			item.messsageObservationTime === null ? '-' : FormatDateWithTimezone(item.messsageObservationTime, timezone)
		}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colMessageLastGpsFix')}: ${
			item.messsageLastGpsFix === null
				? FormatDateWithTimezone(item.messsageObservationTime, timezone)
				: FormatDateWithTimezone(item.messsageLastGpsFix, timezone)
		}`;
		formattedDetails += `\n${TranslateText('widgets.grid.colEvent')}: ${item.event === null ? '-' : item.event}`;

		item.formattedDetails = formattedDetails;
	}
};

export const formatGPS = (item: LogMessage) => {
	if (item.commandType) {
	} else {
		let formattedGPS = `${TranslateText('widgets.grid.colLatitude')}: ${item.latitude}`;
		formattedGPS += `\n${TranslateText('widgets.grid.colLongitude')}: ${item.longitude}`;
		formattedGPS += `\n${TranslateText('widgets.grid.colSpeed')}: ${item.speed}`;
		formattedGPS += `\n${TranslateText('widgets.grid.colDirection')}: ${item.direction}`;
		if (item.altitude) {
			formattedGPS += `\n${TranslateText('widgets.grid.colAltitude')}: ${item.altitude}`;
		}
		if (item.satelliteCount) {
			formattedGPS += `\n${TranslateText('widgets.grid.colSatCount')}: ${item.satelliteCount}`;
		}
		formattedGPS += `\n${TranslateText('common.valid')}: ${
			item.validatedGpsPosition ? TranslateText('common.true') : TranslateText('common.false')
		}`;

		item.formattedGPS = formattedGPS;
	}
};

export const formatMileage = (item: LogMessage) => {
	if (item.commandType) {
		item.formattedMileage = '';
		return;
	}

	let formattedMileage = `${TranslateText('common.type')}: ${
		item.mileageType !== undefined ? MileageType[item.mileageType] : ''
	}`;
	formattedMileage += `\n${TranslateText('common.value')}: ${item.mileageValue}`;
	formattedMileage += `\n${TranslateText('widgets.grid.colKID')}: ${
		item.driverKeyId && /[^0]/.test(item.driverKeyId) ? item.driverKeyId : ''
	}`;
	if (item.garminEnabled) {
		formattedMileage += `\n${TranslateText('widgets.grid.colGarminConnected')}: ${
			item.garminConnected ? TranslateText('common.yes') : TranslateText('common.no')
		}`;
	}

	item.formattedMileage = formattedMileage;
};

export const formatIO = (item: LogMessage) => {
	if (item.commandType) {
		item.formattedIO = '';
		return;
	}

	let formattedIO = '';
	if (item.ioData) {
		const ioJSON = JSON.parse(item.ioData);
		if (ioJSON) {
			Object.entries(ioJSON).map(([key, value], index) => {
				if (index > 0) {
					formattedIO += '\n';
				}
				formattedIO += `${key}: ${value}`;
			});
		}
	}

	item.formattedIO = formattedIO;
};

export const formatIgnition = (item: LogMessage) => {
	if (item.commandType) {
		item.formattedIgnition = '';
		return;
	}

	item.formattedIgnition = item.ignitionState ? TranslateText('common.on') : TranslateText('common.off');
};

export const formatTrip = (item: LogMessage) => {
	if (item.commandType) {
		item.formattedTrip = '';
		return;
	}

	let formattedTrip = `${TranslateText('widgets.grid.colTripStart')}: ${
		item.isTripStart ? TranslateText('common.yes') : TranslateText('common.no')
	}`;
	formattedTrip += `\n${TranslateText('widgets.grid.colTripStop')}: ${
		item.isTripStop ? TranslateText('common.yes') : TranslateText('common.no')
	}`;
	formattedTrip += `\n${TranslateText('widgets.grid.colLocationIsAssumed')}: ${
		item.locationIsAssumed ? TranslateText('common.yes') : TranslateText('common.no')
	}`;
	formattedTrip += `\n${TranslateText('widgets.grid.colValidMessage')}: ${
		item.messageIsInvalid ? TranslateText('common.no') : TranslateText('common.yes')
	}`;

	item.formattedTrip = formattedTrip;
};
