import { ClaimUtil } from 'authorization/ClaimUtil';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';

const usePersonProfileSettingsAccess = () => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const live = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.live);
	const history = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.history);
	const reports = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.reports);
	const customerLocationsFeature = useSelector(
		(s: ApplicationState) => s.currentSession.customer?.featuresSettings.locations
	);
	const userCanEditApplicatinSettings = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Profile,
		values: [ClaimValue.editApplicationSettings],
	});

	const canAccessReportsSettings = (): boolean => {
		return (
			ClaimUtil.validateClaim(user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.edit],
			}) &&
			reports &&
			userCanEditApplicatinSettings
		);
	};

	const canAccessHistorySettings = (): boolean => {
		return (
			customerLocationsFeature &&
			ClaimUtil.validateHasClaim(user, ClaimType.History) &&
			history &&
			userCanEditApplicatinSettings
		);
	};

	const canAccessLiveSettings = (): boolean => {
		return ClaimUtil.validateHasClaim(user, ClaimType.Live) && live && userCanEditApplicatinSettings;
	};

	return { canAccessReportsSettings, canAccessHistorySettings, canAccessLiveSettings };
};

export { usePersonProfileSettingsAccess };
