import BaseConnection from './BaseConnection';

class ObjectDeviceConnection extends BaseConnection {
	constructor(copyOf?: ObjectDeviceConnection) {
		if (copyOf) {
			super(copyOf);
			this.objectId = copyOf.objectId;
			this.objectCode = copyOf.objectCode;
			this.deviceId = copyOf.deviceId;
			this.deviceCode = copyOf.deviceCode;
			this.deviceSerialNumber = copyOf.deviceSerialNumber;
		} else {
			super(null);
		}
	}
	public objectId: string;
	public objectCode: string;
	public deviceId: string;
	public deviceCode: string;
	public deviceSerialNumber: string;
}

export default ObjectDeviceConnection;
