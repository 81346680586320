import React from 'react';
import { useDispatch } from 'react-redux';

import { Marker } from '@react-google-maps/api';

import DrivingImage from '../../assets/icons/DrivingStateSideBar.svg';
import TripIntermediateImage from '../../assets/icons/TripIntermediate.svg';
import TripStopImage from '../../assets/icons/TripStop.svg';
import { historyStoreActionCreators, ProcessedTripDto } from '../../store/HistoryStore';

interface Props {
	trip: ProcessedTripDto;
	nextConnectedVisible?: boolean;
	renderForTableView?: boolean;
	tripIndex?: number;
	higherZIndex?: boolean;
}

const EndTripMarker = (props: Props) => {
	const dispatch = useDispatch();

	const handleVisitClick = (controlPressed: boolean) => {
		dispatch(historyStoreActionCreators.showDetailedVisitOnMap(props.trip.idWithParent, controlPressed));
	};

	if (!props.trip.visible) {
		return null;
	}

	if (props.trip.isInProgress) {
		return (
			<Marker
				zIndex={(props.higherZIndex ? 1000 : 100) + (props.tripIndex ?? 0)}
				position={props.trip.end}
				key={`last-${props.trip.idWithParent}`}
				options={{
					icon: {
						url: `${DrivingImage}#${props.trip.direction?.toLowerCase()}`,
						scaledSize: new google.maps.Size(19.5, 19.5),
						anchor: new google.maps.Point(12, 12),
					},
				}}
			/>
		);
	}

	let isEnd = true;
	if (props.trip.nextConnectedTripId && props.nextConnectedVisible) {
		isEnd = false;
	}

	return (
		<Marker
			zIndex={(props.higherZIndex ? 1000 : 100) + (props.tripIndex ?? 0)}
			onClick={
				!props.renderForTableView
					? (e) => {
							handleVisitClick((e.domEvent as MouseEvent).ctrlKey);
					  }
					: null
			}
			key={`last-${props.trip.idWithParent}`}
			position={props.trip.end}
			options={{
				icon: {
					url: isEnd ? TripStopImage : TripIntermediateImage,
					scaledSize: new google.maps.Size(21, 21),
					anchor: isEnd ? new google.maps.Point(12, 14) : new google.maps.Point(12, 12),
				},
			}}
		/>
	);
};

export default EndTripMarker;
