//netherlands
export const mapStartCoordinates = [
	{ lat: 50.7503019, lng: 3.3316001 },
	{ lat: 53.6316, lng: 7.2275102 },
];

export const googleMapScriptId = 'google-map-script';

export const googleMapLibraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = [
	'places',
	'geometry',
];
