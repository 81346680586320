import '../connectionWidget.scss';
import '../oldWidget.scss';

import BaseView from 'components/BaseView';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import CustomerConflictsNotifier from 'components/Common/CustomerConflictsNotifier';
import GenericConnectionsConflictResolver from 'components/Common/GenericConnectionsConflictResolver';
import {DialogUtil} from 'components/Common/NotificationDialog/NotificationDialog';
import {ValidationMessage} from 'components/ValidationMessage/ValidationMessage';
import * as GlobalSettings from 'GlobalSettings.json';
import AuthenticationTypeEnum from 'models/AuthenticationTypeEnum';
import BaseConnection from 'models/BaseConnection';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import ConnectionValidationResult from 'models/ConnectionValidationResult';
import CustomerDisplayPropertiesEnum from 'models/CustomerDisplayProperties';
import DeviceMergeableSettings from 'models/DeviceMergeableSettings';
import {DeviceMoveToCustomerSettingsMergeRequest} from 'models/DeviceMoveToCustomerSettingsMergeRequest';
import DriverDisconnectSettings from 'models/DriverDisconnectSettings';
import EntityTypeEnum from 'models/EntityTypeEnum';
import {MergeDeviceSettingsDto} from 'models/MergeDeviceSettingsDto';
import MergeSetting from 'models/MergeSetting';
import RelatedConnectionResolution from 'models/RelatedConnectionResolution';
import UpdateDeviceMergedSettingsDto from 'models/UpdateDeviceMergedSettingsDto';
import React from 'react';
import {connect} from 'react-redux';
import {ValidationResult} from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import {ValidatorFunctions} from 'shared/validation/ValidatorFunctions';
import {ApplicationState} from 'store';
import ajaxUtil from 'utils/Ajax';
import {TranslateText, TranslateTextInterpolated} from 'utils/Translations';

import {Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Typography} from '@material-ui/core';

import {DEBOUNCE_TIME} from '../../../Constants';
import {DebouncedButton} from '../../Common/DebouncedButton';
import SettingsMergeView from './SettingsMergeView';
import EasyDrive2Setting from "../../../models/EasyDrive2Setting";

class ConnectToView extends BaseView<
	{
		customerId: string;
		entityId: string;
		entityType: EntityTypeEnum;
		visible: boolean;
		showDialog: boolean;
		savedCallback: (sender: any) => void;
		dialogCloseCallback: () => void;
		currentUserCustomerLevel: number;
	},
	{
		customersList: any[];
		customerEntityConnection: BaseConnection;
		validationResult: ValidationResult;
		validForm: boolean;
		canIncludeSim: boolean;
		includeSim: boolean;
		canIncludeDevice: boolean;
		includeDevice: boolean;
		isDialogOpen: boolean;
		showMergeSettings: boolean;
		settingsToMerge: MergeSetting[];
		disabled: boolean;
	}
> {
	unsavedData: boolean;
	customerConflictsNotifierRef: CustomerConflictsNotifier;
	conflictContext: ConnectionValidationResult;
	formValidator: Validator;
	constructor(props: any) {
		super(props);

		const customerEntityConnection = new BaseConnection();
		customerEntityConnection.connectionStartDate = new Date();

		customerEntityConnection.includeSim = false;
		switch (props.entityType) {
			case EntityTypeEnum.Device: {
				customerEntityConnection.includeObject = false;
				break;
			}
			case EntityTypeEnum.Object: {
				customerEntityConnection.includeDevice = false;
				break;
			}
			case EntityTypeEnum.SimCard: {
				customerEntityConnection.includeObject = false;
				customerEntityConnection.includeDevice = false;
				break;
			}
		}

		this.state = {
			customersList: [],
			customerEntityConnection: customerEntityConnection,
			validationResult: null,
			validForm: false,
			canIncludeSim: false,
			includeSim: false,
			canIncludeDevice: false,
			includeDevice: false,
			isDialogOpen: false,
			showMergeSettings: false,
			settingsToMerge: [],
			disabled: false,
		};
	}

	componentDidMount() {
		this.setState({ customersList: this.getCustomersList(true) });
	}

	componentDidUpdate(prevProps: { showDialog: boolean }) {
		if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog === true) {
			this.showDialog();
		}
	}

	handleValueChange(arg: any, statePropName: string) {
		const tempCustomerEntityConnection: BaseConnection = new BaseConnection(this.state.customerEntityConnection);
		tempCustomerEntityConnection[statePropName] = arg.value;
		this.setState({ customerEntityConnection: tempCustomerEntityConnection }, () => {
			this.unsavedData = true;
			this.formValidator.validate(this.state.customerEntityConnection).then((result) => {
				this.setState({
					validationResult: result.validationResult,
					validForm: result.formResult,
				});
			});

			this.validateConnection(true);
		});
	}

	getCustomersList(force = false): any[] {
		if (this.state.customersList && this.state.customersList.length > 0 && !force) {
			return this.state.customersList;
		}

		const filter = {
			excludeConnectedEntities: true,
		};
		const url =
			GlobalSettings.listsApi +
			'/Customers?display=' +
			CustomerDisplayPropertiesEnum.NameAndCode +
			'&filter=' +
			JSON.stringify(filter) +
			'&entityType=' +
			this.props.entityType +
			'&entityId=' +
			this.props.entityId;
		let customersList: any[] = [];

		ajaxUtil.get<any[]>(url).then((data) => {
			customersList = data;
			this.setState({ customersList: data });
		});

		return customersList;
	}

	showDialog() {
		window.onbeforeunload = () => {
			if (this.unsavedData === true) {
				return 'Unsaved data';
			}
		};

		const customerEntityConnection = new BaseConnection();
		customerEntityConnection.connectionStartDate = new Date();

		customerEntityConnection.entity2Id = this.props.entityId;
		switch (this.props.entityType) {
			case EntityTypeEnum.Device: {
				customerEntityConnection.connectionType = ConnectionTypeEnum.CustomerDevice;
				break;
			}
			case EntityTypeEnum.Object: {
				customerEntityConnection.connectionType = ConnectionTypeEnum.CustomerObject;
				break;
			}
			case EntityTypeEnum.SimCard: {
				customerEntityConnection.connectionType = ConnectionTypeEnum.CustomerSimCard;
				break;
			}
		}

		this.setState({ customerEntityConnection: customerEntityConnection });

		this.setFormValidatorForNewEntity();
		if (this.formValidator) {
			this.formValidator.validate(this.state.customerEntityConnection).then((result) => {
				this.setState({
					validationResult: result.validationResult,
					validForm: result.formResult,
				});
			});
		}

		this.getCustomersList(true);

		this.unsavedData = false;
	}

	async validateDeviceSettingsToMergeType() {
		let mergeSettingsConflicts = false;

		if (!this.state.customerEntityConnection.entity1Id || !this.state.customerEntityConnection.entity2Id) {
			return;
		}

		let mergeDeviceSettingsRequest: DeviceMoveToCustomerSettingsMergeRequest = null;

		if (this.props.entityType === EntityTypeEnum.Device || this.state.includeDevice) {
			mergeDeviceSettingsRequest = new DeviceMoveToCustomerSettingsMergeRequest(
				this.state.customerEntityConnection.entity1Id,
				this.state.customerEntityConnection.entity2Id
			);
		}

		if (
			this.state.customerEntityConnection.relatedConnections &&
			this.state.customerEntityConnection.relatedConnections.length > 0
		) {
			this.state.customerEntityConnection.relatedConnections.forEach(
				(relatedConnection: RelatedConnectionResolution) => {
					if (
						relatedConnection.connection.connectionType === ConnectionTypeEnum.DeviceObject ||
						relatedConnection.connection.connectionType === ConnectionTypeEnum.DeviceSimCard
					) {
						mergeDeviceSettingsRequest = new DeviceMoveToCustomerSettingsMergeRequest(
							this.state.customerEntityConnection.entity1Id,
							relatedConnection.connection.entity1Id
						);
					}
				}
			);
		}

		if (mergeDeviceSettingsRequest) {
			await ajaxUtil
				.post<MergeDeviceSettingsDto>(
					`${GlobalSettings.connectionsApi}/checkMergeSettings`,
					mergeDeviceSettingsRequest
				)
				.then(async (mergeDeviceSettings: MergeDeviceSettingsDto) => {
					let tempCurrentMergeSettings: MergeSetting[] = [];

					if (
						!mergeDeviceSettings.authenticationSettings.customerFeaturesDriverIdentification &&
						mergeDeviceSettings.authenticationSettings.deviceAuthenticationType !==
							AuthenticationTypeEnum.None
					) {
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.AuthenticationType,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.authType', [
								TranslateText(
									`devices.authenticationType.${
										AuthenticationTypeEnum[
											mergeDeviceSettings.authenticationSettings.customerAuthenticationType
										]
									}`
								),
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: mergeDeviceSettings.authenticationSettings.customerAuthenticationType,
							orderId: 1
						});
						mergeSettingsConflicts = true;
					} else if (
						mergeDeviceSettings.authenticationSettings.deviceAuthenticationType !==
							mergeDeviceSettings.authenticationSettings.customerAuthenticationType &&
						mergeDeviceSettings.authenticationSettings.customerFeaturesDriverIdentification
					) {
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.AuthenticationType,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.authType', [
								TranslateText(
									`devices.authenticationType.${
										AuthenticationTypeEnum[
											mergeDeviceSettings.authenticationSettings.customerAuthenticationType
										]
									}`
								),
							]),
							applyCustomerSettingValue: null,
							mergeRequired: true,
							customerSettingValue: mergeDeviceSettings.authenticationSettings.customerAuthenticationType,
							orderId: 2,
						});
						mergeSettingsConflicts = true;
					}
					if(!mergeDeviceSettings.authenticationSettings.customerFeaturesDriverIdentification &&
						(mergeDeviceSettings.driverDisconnectSettings.deviceDisconnectDriverAfterIgnitionOff ||
							mergeDeviceSettings.driverDisconnectSettings.deviceDisconnectDriverAfterIgnitionOffTimeOutSeconds != 300))
					{
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.DriverDisconnect,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.driverDisconnect', [
								TranslateText(
									`common.${
										mergeDeviceSettings.driverDisconnectSettings
											.customerDisconnectDriverAfterIgnitionOff
											? 'yes'
											: 'no'
									}`
								),
								mergeDeviceSettings.driverDisconnectSettings.customerDisconnectDriverAfterIgnitionOffTimeOutSeconds.toString(),
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: new DriverDisconnectSettings(
								false,
								300
							),
							orderId: 3
						});
						mergeSettingsConflicts = true;
					}
					else if ((mergeDeviceSettings.driverDisconnectSettings.deviceDisconnectDriverAfterIgnitionOff !==
							mergeDeviceSettings.driverDisconnectSettings.customerDisconnectDriverAfterIgnitionOff ||
						mergeDeviceSettings.driverDisconnectSettings
							.deviceDisconnectDriverAfterIgnitionOffTimeOutSeconds !==
							mergeDeviceSettings.driverDisconnectSettings
								.customerDisconnectDriverAfterIgnitionOffTimeOutSeconds)
					) {
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.DriverDisconnect,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.driverDisconnect', [
								TranslateText(
									`common.${
										mergeDeviceSettings.driverDisconnectSettings
											.customerDisconnectDriverAfterIgnitionOff
											? 'yes'
											: 'no'
									}`
								),
								mergeDeviceSettings.driverDisconnectSettings.customerDisconnectDriverAfterIgnitionOffTimeOutSeconds.toString(),
							]),
							applyCustomerSettingValue: null,
							mergeRequired: true,
							customerSettingValue: new DriverDisconnectSettings(
								mergeDeviceSettings.driverDisconnectSettings.customerDisconnectDriverAfterIgnitionOff,
								mergeDeviceSettings.driverDisconnectSettings.customerDisconnectDriverAfterIgnitionOffTimeOutSeconds
							),
							orderId: 3
						});
						mergeSettingsConflicts = true;
					}

					if((!mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores &&
						!mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage)) {
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2Sync,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveSync', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceNeedToBeSyncedWithEd2
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: new EasyDrive2Setting(null),
							orderId: 4
						});
						mergeSettingsConflicts = true;
					}
					else if(
						(mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores ||
							mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage) &&
						(mergeDeviceSettings.easyDrive2Settings.deviceNeedToBeSyncedWithEd2 == null)
					){
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2Sync,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveSync', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceNeedToBeSyncedWithEd2
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: new EasyDrive2Setting(mergeDeviceSettings.easyDrive2Settings.customerDeviceNeedToBeSyncedWithEd2),
							orderId: 4
						});
						mergeSettingsConflicts = true;
					}
					else if(
						(mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores ||
						mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage) &&
						(mergeDeviceSettings.easyDrive2Settings.deviceNeedToBeSyncedWithEd2 !== null &&
							mergeDeviceSettings.easyDrive2Settings.deviceNeedToBeSyncedWithEd2 !==
							mergeDeviceSettings.easyDrive2Settings.customerDeviceNeedToBeSyncedWithEd2)
					){
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2Sync,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveSync', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceNeedToBeSyncedWithEd2
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: null,
							mergeRequired: true,
							customerSettingValue: new EasyDrive2Setting(mergeDeviceSettings.easyDrive2Settings.customerDeviceNeedToBeSyncedWithEd2),
							orderId: 4
						});
						mergeSettingsConflicts = true;
					}

					if(
						!mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores &&
							!mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage
					){
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2MediaSupport,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveMediaSupport', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceShouldHaveEd2MediaSupport
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: new EasyDrive2Setting(null),
							orderId: 5
						});
						mergeSettingsConflicts = true;
					}
					else  if(
						(mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores ||
							mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage) &&
						mergeDeviceSettings.easyDrive2Settings.deviceShouldHaveEd2MediaSupport == null
					){
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2MediaSupport,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveMediaSupport', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceShouldHaveEd2MediaSupport
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: true,
							mergeRequired: false,
							customerSettingValue: new EasyDrive2Setting(mergeDeviceSettings.easyDrive2Settings.customerDeviceShouldHaveEd2MediaSupport),
							orderId: 5
						});
						mergeSettingsConflicts = true;
					}
					else if(
						(mergeDeviceSettings.easyDrive2Settings.customerFeaturesDriverStyleScores ||
							mergeDeviceSettings.easyDrive2Settings.customerFeaturesMediaFootage) &&
						(mergeDeviceSettings.easyDrive2Settings.deviceShouldHaveEd2MediaSupport !== null &&
							mergeDeviceSettings.easyDrive2Settings.deviceShouldHaveEd2MediaSupport !==
							mergeDeviceSettings.easyDrive2Settings.customerDeviceShouldHaveEd2MediaSupport)
					){
						tempCurrentMergeSettings.push({
							id: DeviceMergeableSettings.EasyDrive2MediaSupport,
							targetEntityId: mergeDeviceSettings.deviceId,
							settingMessage: TranslateTextInterpolated('notificationMessages.easyDriveMediaSupport', [
								TranslateText(
									`common.${
										mergeDeviceSettings.easyDrive2Settings
											.customerDeviceShouldHaveEd2MediaSupport
											? 'yes'
											: 'no'
									}`
								)
							]),
							applyCustomerSettingValue: null,
							mergeRequired: true,
							customerSettingValue: new EasyDrive2Setting(mergeDeviceSettings.easyDrive2Settings.customerDeviceShouldHaveEd2MediaSupport),
							orderId: 5
						});
						mergeSettingsConflicts = true;
					}
					if (mergeSettingsConflicts) {
						if(!tempCurrentMergeSettings.some((x) => x.mergeRequired)) {
							this.setState(
								{
									settingsToMerge: tempCurrentMergeSettings
								},
								() => this.performMove()
							);
						}
						else {
							tempCurrentMergeSettings = tempCurrentMergeSettings.sort((a, b) => a.orderId - b.orderId);
							this.setState(
								{
									settingsToMerge: tempCurrentMergeSettings
								},
								() => this.showMergeNotification()
							);
						}
					}
				});
		}
		return mergeSettingsConflicts;
	}

	async validateConnection(noShow: boolean) {
		this.setState({ disabled: !noShow });
		this.clearConflictContext();
		if (this.state.customerEntityConnection.connectionStartDate === null) {
			return;
		}
		if (!this.state.customerEntityConnection.entity1Id || !this.state.customerEntityConnection.entity2Id) {
			this.toggleIncludeCheckboxes();
			return;
		}

		ajaxUtil
			.post<ConnectionValidationResult>(
				`${GlobalSettings.connectionsApi}/ValidateCustomerEntityCandidateConnection`,
				this.state.customerEntityConnection
			)
			.then(async (data) => {
				const tempConnectionValidationResult = new ConnectionValidationResult();
				tempConnectionValidationResult.id = data.id;
				tempConnectionValidationResult.conflictPresent = data.conflictPresent;
				tempConnectionValidationResult.fixableConflict = data.fixableConflict;
				tempConnectionValidationResult.conflictingConnections = [];
				tempConnectionValidationResult.requestedConnection = data.requestedConnection;

				if (data.conflictingConnections) {
					data.conflictingConnections.forEach((element) => {
						const tempElement = new BaseConnection(element);

						if (element.connectionType === ConnectionTypeEnum.DeviceObject) {
							switch (this.props.entityType) {
								case EntityTypeEnum.Device: {
									tempElement.connectionAction = TranslateText('connections.terminateConnection');
									break;
								}
								case EntityTypeEnum.Object: {
									tempElement.connectionAction = this.state.includeDevice
										? TranslateText('connections.moveToCustomer')
										: TranslateText('connections.terminateConnection');
									break;
								}
								case EntityTypeEnum.SimCard: {
									tempElement.connectionAction = TranslateText('connections.terminateConnection');
									break;
								}
							}
						}

						if (element.connectionType === ConnectionTypeEnum.DeviceSimCard) {
							tempElement.connectionAction =
								this.state.includeSim || this.state.includeDevice
									? TranslateText('connections.moveToCustomerWithNewConnection')
									: TranslateText('connections.terminateConnection');
						}

						if (
							this.props.entityType !== EntityTypeEnum.SimCard ||
							element.connectionType !== ConnectionTypeEnum.DeviceObject ||
							this.state.includeDevice
						) {
							tempConnectionValidationResult.conflictingConnections.push(tempElement);
						}
					});
				}
				this.conflictContext = tempConnectionValidationResult;

				if (noShow) {
					this.toggleIncludeCheckboxes();
				}

				if (data.conflictPresent && !noShow) {
					if (this.props.entityType === EntityTypeEnum.Object && !this.state.includeDevice) {
						const onlyDeviceConflicts = this.conflictContext;
						onlyDeviceConflicts.conflictingConnections = this.conflictContext.conflictingConnections.filter(
							(c) => c.connectionType !== ConnectionTypeEnum.DeviceSimCard
						);
						this.customerConflictsNotifierRef.setConflictingConnectionsContext(onlyDeviceConflicts);
					} else {
						this.customerConflictsNotifierRef.setConflictingConnectionsContext(this.conflictContext);
					}

					this.customerConflictsNotifierRef.showDialog();
				} else {
					if (!this.conflictContext.conflictPresent && !noShow) {
						await DialogUtil.confirm({
							title: TranslateText('connections.confirmDialogHeader'),
							content: this.getComfirmationDialogContent(),
						}).then(this.handleConfirmUserResponse.bind(this));
					}
				}
			});
	}

	clearConflictContext() {
		this.conflictContext = null;
	}

	toggleIncludeCheckboxes() {
		switch (this.props.entityType) {
			case EntityTypeEnum.Object: {
				if (this.conflictContext) {
					this.setState({
						canIncludeSim:
							this.conflictContext.conflictingConnections.filter(
								(x) => x.connectionType === ConnectionTypeEnum.DeviceSimCard
							).length > 0,
						canIncludeDevice:
							this.conflictContext.conflictingConnections.filter(
								(x) => x.connectionType === ConnectionTypeEnum.DeviceObject
							).length > 0,
					});
				} else {
					this.setState({
						canIncludeSim: false,
						canIncludeDevice: false,
						includeDevice: false,
					});
				}
				break;
			}
			case EntityTypeEnum.Device: {
				if (this.conflictContext) {
					this.setState({
						canIncludeSim:
							this.conflictContext.conflictingConnections.filter(
								(x) => x.connectionType === ConnectionTypeEnum.DeviceSimCard
							).length > 0,
					});
				} else {
					this.setState({
						canIncludeSim: false,
					});
				}
				this.setState({
					includeSim: this.state.canIncludeSim,
				});
				break;
			}
			case EntityTypeEnum.SimCard: {
				if (this.conflictContext) {
					this.setState({
						canIncludeDevice:
							this.conflictContext.conflictingConnections.filter(
								(x) => x.connectionType === ConnectionTypeEnum.DeviceSimCard
							).length > 0,
					});
				} else {
					this.setState({
						canIncludeDevice: false,
						includeDevice: false,
					});
				}
				this.setState({ includeDevice: this.state.canIncludeDevice });
				break;
			}
		}
	}

	getComfirmationDialogContent(): string {
		if (!this.conflictContext) {
			return '';
		}
		switch (this.conflictContext.requestedConnection.connectionType) {
			case ConnectionTypeEnum.CustomerDevice: {
				return TranslateTextInterpolated('connections.moveToConfirm', [
					TranslateText('connections.device'),
					TranslateText('connections.customer'),
				]);
			}
			case ConnectionTypeEnum.CustomerObject: {
				return TranslateTextInterpolated('connections.moveToConfirm', [
					TranslateText('connections.object'),
					TranslateText('connections.customer'),
				]);
			}
			case ConnectionTypeEnum.CustomerSimCard: {
				return TranslateTextInterpolated('connections.moveToConfirm', [
					TranslateText('connections.simcard'),
					TranslateText('connections.customer'),
				]);
			}
		}

		return '';
	}

	setFormValidatorForNewEntity() {
		this.formValidator = new Validator({
			fieldRules: {
				entity1Id: {
					rules: {
						required: ValidatorFunctions.required(),
					},
				},
			},
		});
	}

	tryCloseDialog() {
		if (this.unsavedData === true) {
			this.notifyAboutUnsavedData();
			return;
		}

		window.onbeforeunload = null;
		this.unsavedData = false;
		this.clearView();
		this.props.dialogCloseCallback();
	}

	notifyAboutUnsavedData() {
		if (this.state.isDialogOpen) {
			return;
		}

		this.setState({
			isDialogOpen: true,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then(this.handleUserResponse.bind(this));
	}

	private handleUserResponse(response: boolean) {
		this.setState({
			isDialogOpen: false,
		});

		if (response) {
			window.onbeforeunload = null;
			this.unsavedData = false;
			this.clearView();
			this.props.dialogCloseCallback();
			this.props.savedCallback(this);
		}
	}

	moveTo() {
		ajaxUtil
			.post(
				`${GlobalSettings.connectionsApi}/CreateCustomerEntityConnection`,
				this.state.customerEntityConnection
			)
			.then((data) => {
				if (data) {
					window.onbeforeunload = null;
					this.unsavedData = false;
					this.clearView();
					this.props.dialogCloseCallback();
					this.props.savedCallback(this);
				}
			});
	}

	async handleConfictingConnections(
		sender: GenericConnectionsConflictResolver,
		proposedConnectionCanBeSaved: boolean
	) {
		if (proposedConnectionCanBeSaved) {
			if (proposedConnectionCanBeSaved) {
				this.processConflictResponses();
				if (
					(this.state.validForm && this.props.entityType === EntityTypeEnum.Device) ||
					this.state.includeDevice
				) {
					await this.validateDeviceSettingsToMergeType().then(async (settingToMergePresent) => {
						if (!settingToMergePresent) {
							await this.performMove();
						}
					});
				} else {
					await this.performMove();
				}
			}
		}
	}

	processConflictResponses() {
		const tempCustomerEntityConnection = this.state.customerEntityConnection;

		tempCustomerEntityConnection.resolveConflictsAutomatically = true;
		if (!tempCustomerEntityConnection.relatedConnections) {
			tempCustomerEntityConnection.relatedConnections = [];
		}
		let deviceSimConnectionsList: BaseConnection[] = [];
		let deviceObjectConnectionsList: BaseConnection[] = [];
		let theRestOfConnections: BaseConnection[] = [];

		deviceSimConnectionsList = this.conflictContext.conflictingConnections.filter(
			(x) => x.connectionType === ConnectionTypeEnum.DeviceSimCard
		);

		deviceObjectConnectionsList = this.conflictContext.conflictingConnections.filter(
			(x) => x.connectionType === ConnectionTypeEnum.DeviceObject
		);

		theRestOfConnections = this.conflictContext.conflictingConnections.filter(
			(x) =>
				x.connectionType !== ConnectionTypeEnum.DeviceSimCard &&
				x.connectionType !== ConnectionTypeEnum.DeviceObject
		);

		// put back everything with the correct answear
		switch (this.props.entityType) {
			case EntityTypeEnum.Device: {
				deviceObjectConnectionsList.forEach((element) => {
					tempCustomerEntityConnection.relatedConnections.push({
						connection: element,
						move: false,
					});
				});
				deviceSimConnectionsList.forEach((element) => {
					tempCustomerEntityConnection.relatedConnections.push({
						connection: element,
						move: this.state.includeSim,
					});
				});
				break;
			}
			case EntityTypeEnum.Object: {
				deviceObjectConnectionsList.forEach((element) => {
					tempCustomerEntityConnection.relatedConnections.push({
						connection: element,
						move: this.state.includeDevice,
					});
				});
				if (this.state.includeDevice) {
					deviceSimConnectionsList.forEach((element) => {
						tempCustomerEntityConnection.relatedConnections.push({
							connection: element,
							move: this.state.includeSim,
						});
					});
				}
				break;
			}

			case EntityTypeEnum.SimCard: {
				deviceSimConnectionsList.forEach((element) => {
					tempCustomerEntityConnection.relatedConnections.push({
						connection: element,
						move: this.state.includeDevice,
					});
				});

				if (this.state.includeDevice) {
					deviceObjectConnectionsList.forEach((element) => {
						tempCustomerEntityConnection.relatedConnections.push({
							connection: element,
							move: false,
						});
					});
				}
				break;
			}
		}

		theRestOfConnections.forEach((element) => {
			tempCustomerEntityConnection.relatedConnections.push({
				connection: element,
				move: false,
			});
		});

		this.setState({ ...this.state, customerEntityConnection: tempCustomerEntityConnection });
	}

	async performMove() {
		if (
			(this.props.entityType === EntityTypeEnum.Device || this.state.includeDevice) &&
			this.state.settingsToMerge.length > 0
		) {
			await this.applyDeviceMergedSettings();
		}

		this.moveTo();
	}

	async applyDeviceMergedSettings() {
		const deviceSettingtoUpdate = new UpdateDeviceMergedSettingsDto();
		let updateDeviceSettings = false;
		this.state.settingsToMerge.forEach(async (settingToMerge) => {
			if (settingToMerge.applyCustomerSettingValue) {
				updateDeviceSettings = true;
				switch (settingToMerge.id as DeviceMergeableSettings) {
					case DeviceMergeableSettings.AuthenticationType: {
						deviceSettingtoUpdate.authenticationType = settingToMerge.customerSettingValue as AuthenticationTypeEnum;
						break;
					}
					case DeviceMergeableSettings.DriverDisconnect: {
						deviceSettingtoUpdate.driverDisconnectSettings = settingToMerge.customerSettingValue as DriverDisconnectSettings;
						break;
					}
					case DeviceMergeableSettings.EasyDrive2Sync: {
						updateDeviceSettings;
						deviceSettingtoUpdate.deviceNeedToBeSyncedWithEd2 = settingToMerge.customerSettingValue as EasyDrive2Setting;
						break;
					}
					case DeviceMergeableSettings.EasyDrive2MediaSupport: {
						deviceSettingtoUpdate.deviceShouldHaveEd2MediaSupport = settingToMerge.customerSettingValue as EasyDrive2Setting;
						break;
					}
					default: {
						break;
					}
				}
			}
		});
		if (updateDeviceSettings) {
			deviceSettingtoUpdate.deviceId = this.state.settingsToMerge[0].targetEntityId;
			await ajaxUtil.put(
				`${GlobalSettings.devicesMaintenanceApi}/updateDeviceMergedSettings`,
				deviceSettingtoUpdate
			);
		}
	}
	clearView() {
		setTimeout(() => {
			const customerEntityConnection = new BaseConnection();
			customerEntityConnection.connectionStartDate = new Date();
			this.setState({ ...this.state, customerEntityConnection, customersList: [], disabled: false });
		}, 100);
	}

	getSettingMergeResponse(value: string, settingId: string) {
		const valueBool = value === 'yes';
		let settings = this.state.settingsToMerge;
		const setting = settings.find((i) => i.id === settingId);
		setting.applyCustomerSettingValue = valueBool;
		settings = settings.filter((x) => x.id !== settingId);
		settings.push(setting);
		settings = settings.sort((a, b) => a.orderId - b.orderId);
		this.setState({
			settingsToMerge: settings,
		});
	}

	showMergeNotification() {
		this.setState({
			showMergeSettings: true,
		});
	}
	handleMergeSettingsNotificationUserResponse(response: boolean) {
		this.setState(
			{
				showMergeSettings: false,
			},
			async () => {
				if (!response) {
					this.clearConflictContext();
					const tempCustomerEntityConnection: BaseConnection = new BaseConnection(
						this.state.customerEntityConnection
					);
					tempCustomerEntityConnection.entity1Id = null;
					this.setState(
						{
							customerEntityConnection: tempCustomerEntityConnection,
						},
						() => {
							this.formValidator.validate(this.state.customerEntityConnection).then((result) => {
								this.setState({
									validationResult: result.validationResult,
									validForm: result.formResult,
								});
							});
						}
					);
				} else {
					await this.performMove();
				}
			}
		);
	}

	toggleCheckIncludeDevice(args: any) {
		this.setState({ includeDevice: args.target.checked }, () =>
			this.setState({ includeSim: this.state.includeDevice && this.state.canIncludeSim })
		);
	}

	async handleConfirmUserResponse(confirm: boolean) {
		if (confirm) {
			if ((this.state.validForm && this.props.entityType === EntityTypeEnum.Device) || this.state.includeDevice) {
				await this.validateDeviceSettingsToMergeType().then(async (settingToMergePresent) => {
					if (!settingToMergePresent) {
						await this.performMove();
					}
				});
			} else {
				await this.performMove();
			}
		}
	}

	render() {
		return (
			<Dialog
				className="connection-dialog"
				onClose={() => this.tryCloseDialog()}
				open={this.props.showDialog}
				fullWidth
				disableBackdropClick
			>
				<div className="dialog-min-height">
					<DialogTitle>{TranslateText('connections.moveToCustomer')}</DialogTitle>
					<DialogContent className="dialog-content">
						<SettingsMergeView
							open={this.state.showMergeSettings}
							MergeSettings={this.state.settingsToMerge}
							onResponse={(value, settingId) => this.getSettingMergeResponse(value, settingId)}
							userButtonAnswearCallback={(e) => this.handleMergeSettingsNotificationUserResponse(e)}
						></SettingsMergeView>
						<CustomerConflictsNotifier
							userAnswearCallback={this.handleConfictingConnections.bind(this)}
							ref={(scope) => {
								this.customerConflictsNotifierRef = scope;
							}}
						/>
						<div className="e-panel-container">
							<form id="connectToCustomerForm" noValidate={true}>
								<MaterialAutocomplete
									className="autocomplete-max-width material-autocomplete report-selection-component"
									valueId={this.state.customerEntityConnection.entity1Id}
									dataSource={this.state.customersList}
									label={TranslateText('connections.selectCustomer')}
									onChange={(args: any) => this.handleValueChange(args, 'entity1Id')}
									fullWidth={false}
								/>
								<ValidationMessage result={this.state.validationResult?.entity1Id} />

								{this.props.entityType === EntityTypeEnum.Device ? (
									<div>
										<div style={{ marginTop: '10px' }} className="left-side-group">
											<FormControlLabel
												control={
													<Checkbox
														key={this.state.customerEntityConnection.entity1Id}
														name="includeSim"
														className="auto-include-sim"
														color={'primary'}
														checked={this.state.includeSim}
														onChange={(args: any) =>
															this.setState({ includeSim: args.target.checked })
														}
														disabled={!this.state.canIncludeSim}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('connections.includeSim')}
													</Typography>
												}
											/>
										</div>
									</div>
								) : this.props.entityType === EntityTypeEnum.Object ? (
									<div>
										<div style={{ marginTop: '10px' }} className="left-side-group">
											<FormControlLabel
												control={
													<Checkbox
														name="includeDevice"
														className="auto-include-sim"
														color={'primary'}
														checked={this.state.includeDevice}
														onChange={(args) => this.toggleCheckIncludeDevice(args)}
														disabled={!this.state.canIncludeDevice}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('connections.includeDevice')}
													</Typography>
												}
											/>
										</div>
										<div style={{ marginTop: '10px' }} className="right-side-group">
											<FormControlLabel
												control={
													<Checkbox
														name="includeSim"
														className="auto-include-sim"
														color={'primary'}
														checked={this.state.includeSim}
														onChange={(args) =>
															this.setState({ includeSim: args.target.checked })
														}
														disabled={
															!(this.state.includeDevice && this.state.canIncludeSim)
														}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('connections.includeSim')}
													</Typography>
												}
											/>
										</div>
									</div>
								) : (
									<div>
										<div style={{ marginTop: '10px' }} className="left-side-group">
											<FormControlLabel
												control={
													<Checkbox
														key={this.state.customerEntityConnection.entity1Id}
														name="includeDevice"
														color={'primary'}
														checked={this.state.includeDevice}
														onChange={(args) => this.toggleCheckIncludeDevice(args)}
														disabled={!this.state.canIncludeDevice}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('connections.includeDevice')}
													</Typography>
												}
											/>
										</div>
									</div>
								)}
							</form>
						</div>
						<div id="buttonsHost" className="buttons-area">
							<div className="left-side-buttons">
								<Button
									className="widget-button auto-cancel-button"
									onClick={() => this.tryCloseDialog()}
								>
									{TranslateText('common.buttonCancel')}{' '}
								</Button>
							</div>
							<div className="right-side-buttons">
								<DebouncedButton
									className="widget-button auto-connect-button"
									onClick={() => this.validateConnection(false)}
									disabled={this.state.disabled || !this.state.validForm}
									debounceTime={DEBOUNCE_TIME}
								>
									{TranslateText('common.connect')}{' '}
								</DebouncedButton>
							</div>
						</div>
					</DialogContent>
				</div>
			</Dialog>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		customerId: state.loadedEntityContext.entityContextData.customerId,
		currentUserCustomerLevel: state.currentSession.customerLevel,
	};
}

export default connect(mapStateToProps, { forwardRef: true })(ConnectToView);
