import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import Key from 'models/Key';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete, { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import {
	ConflictConfirmation,
	ConnectionsConflictResult,
	Props as ConflictConfirmationProps,
} from '../ConnectionConflictsNotifier/ConflictConfirmation';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

const mapState = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	readOnly: boolean;
	renderFor: RenderForEnum;
	setKey?: (valid: boolean, key: Key, unsavedData: boolean) => void;
	userFirstAndLastName: string;
	widgetState: WidgetStateEnum;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
};

interface State {
	unsavedData: boolean;
	key: Key;
	personsDataSource: AutocompleteItem[];
	conflictConfirmationProps: ConflictConfirmationProps;
	validationResult?: ValidationResult;
	canConnectKeyPerson: boolean;
}

class KeyView extends BaseView<Props, State> {
	initialKey: Key;
	validator: Validator;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			unsavedData: false,
			key: new Key(
				this.props.renderFor === RenderForEnum.Widget ? null : new Date(),
				this.props.defaultCustomerId
			),
			personsDataSource: [],
			conflictConfirmationProps: {
				visible: false,
				confirmCallback: null,
				canSolveConflicts: true,
				conflictData: [],
				connectionType: ConnectionTypeEnum.PersonKey,
			},
			canConnectKeyPerson: this.canConnectKeyPerson(),
		};

		this.validator = new Validator({
			fieldRules: {
				code: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(200),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
						noOnlyZeros: ValidatorFunctions.regexNegated(
							ValidationRegex.onlyZeros(),
							TranslateText('fieldsValidations.keyWithOnlyZerosNotAllowed')
						),
						noWhiteSpacesInBetween: ValidatorFunctions.regexNegated(
							ValidationRegex.whiteSpacesInBetween(),
							TranslateText('fieldsValidations.noSpaceInBetween')
						),
						unique: {
							message: TranslateText('fieldsValidations.uniqueValue'),
							validationFunction: (data, fieldName) => {
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/KeyFields`, {
									customerId: this.state.key.customerId,
									value: data[fieldName],
									Field: 'code',
									editedEntityId: !!this.state.key.id ? this.state.key.id : null,
								});
							},
						},
					},
				},
				serialNumber: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(200),
					},
				},
				personId: {
					rules: {
						anyConflicts: {
							message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
								TranslateText('connections.person'),
							]),
							validationFunction: (data, fieldName) => {
								return this.checkPersonConnectionConflicts(data[fieldName]);
							},
							disabled: !ClaimUtil.validateHasClaim(this.props.user, ClaimType.Persons),
						},
					},
				},
				customerId: {
					rules: {
						required: {
							...ValidatorFunctions.required(),
							disabled: !ClaimUtil.validateHasClaim(this.props.user, ClaimType.Customers),
						},
					},
				},
			},
		});
	}

	componentDidMount() {
		if (
			this.props.renderFor === RenderForEnum.Wizard ||
			(this.props.renderFor === RenderForEnum.Widget && !this.props.readOnly)
		) {
			this.prepareView();

			//initial validation
			this.validator.validate(this.state.key).then((result) => {
				const key = { ...this.state.key };
				key.userName = this.props.userFirstAndLastName;
				this.setState({
					validationResult: result.validationResult,
					key: key,
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
			this.validator.clearValidation();
			this.setState({ validationResult: null });
		}

		if (this.state.key !== prevState.key) {
			if (this.state.key?.customerId !== prevState.key?.customerId) {
				this.setPersonsList(this.state.key.customerId);
			}

			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				this.validator.validate(this.state.key).then((result) => {
					this.setState({
						validationResult: result.validationResult,
					});

					if (this.state.unsavedData) {
						this.setKey(result.formResult, this.state.key);
					}
				});
			}
		}

		if (prevProps.user !== this.props.user) {
			this.setState({ canConnectKeyPerson: this.canConnectKeyPerson() });
		}
	}

	clearView() {
		this.setState({
			key: new Key(new Date(), this.props.defaultCustomerId),
		});
	}

	public prepareViewWithData(customerId: string, personId?: string, personName?: string) {
		if (this.state.key) {
			const key = { ...this.state.key };
			key.customerId = customerId;
			key.personId = personId ? personId : key.personId;
			key.personName = personName ? personName : key.personName;
			key.userName = this.props.userFirstAndLastName;

			this.setState({ key: key, unsavedData: false });
		}

		setTimeout(() => {
			document.getElementById('keyCode').focus();
		}, 400);
	}

	public focusDefaultInput() {
		setTimeout(() => {
			document.getElementById('keyCode').focus();
		}, 400);
	}

	setEditRecord(key: Key) {
		this.setState({ key: key, unsavedData: false });

		this.initialKey = key;
		this.setPersonsList(key.customerId);
	}

	async validateBeforeSave() {
		return this.validator.validate(this.state.key).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	setKey(valid: boolean, key: Key) {
		if (this.props.renderFor === RenderForEnum.Wizard) {
			key.userName = this.props.userFirstAndLastName;

			if (key.personId) {
				const selectedPerson = this.state.personsDataSource.find((p) => p.id === key.personId);
				if (selectedPerson) {
					key.personName = selectedPerson.display;
				}
			}
		}

		this.props.setKey(valid, key, this.state.unsavedData);
	}

	prepareView() {
		this.setPersonsList(this.state.key.customerId);

		document.getElementById('keyCode').focus();
	}

	private setPersonsList(customerId: string) {
		if (this.state.canConnectKeyPerson) {
			const filter = {
				fieldType: FilterFieldTypeEnum.customerId,
				operator: FilterOperatorTypeEnum.equal,
				fieldValue: customerId,
			};

			ajaxUtil
				.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Drivers?filter=' + JSON.stringify(filter))
				.then((data) => {
					this.setState({ personsDataSource: data });
				})
				.catch((error) => {
					console.log(error);
					this.setState({ personsDataSource: [] });
				});
		}
	}

	private async handleValueChange(args: any, statePropName: string) {
		const tempKey = { ...this.state.key };
		tempKey[statePropName] = args.value;

		if (statePropName === 'active') {
			tempKey[statePropName] = !this.state.key.active;
		}

		this.setState({
			key: tempKey,
			unsavedData: true,
		});
	}

	private handleCheckboxChange(args: any) {
		const tempKey = { ...this.state.key };
		tempKey.active = args.target.checked;

		this.setState({
			key: tempKey,
			unsavedData: true,
		});
	}

	private isSelectedPersonUnchanged(personId: string): boolean {
		return (
			this.props.readOnly ||
			this.props.renderFor === RenderForEnum.Widget ||
			this.props.renderFor === RenderForEnum.ConnectDialog ||
			(this.initialKey !== undefined && personId === this.initialKey.personId)
		);
	}

	private async checkPersonConnectionConflicts(personId: string): Promise<boolean> {
		if (personId === null || personId === undefined || personId === '') {
			return true;
		}
		if (this.isSelectedPersonUnchanged(personId)) {
			return true;
		}

		const candidateConnection = {
			entity1Id: personId,
			entity2Id: this.state.key.id ? this.state.key.id : null,
			connectionStartDate: new Date(),
			connectionType: ConnectionTypeEnum.PersonKey,
		};

		const result = await ajaxUtil.post<ConnectionsConflictResult>(
			`${GlobalSettings.connectionsApi}/ValidateEntitiesCandidateConnection`,
			candidateConnection
		);

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				this.setState({
					conflictConfirmationProps: {
						visible: true,
						canSolveConflicts: result.fixableConflict,
						connectionType: ConnectionTypeEnum.PersonKey,
						conflictData: result.conflictingConnections,
						confirmCallback: (confirm) => {
							this.setState({
								conflictConfirmationProps: {
									...this.state.conflictConfirmationProps,
									visible: false,
								},
							});
							resolve(confirm);
						},
					},
				});
			});
		} else {
			return true;
		}
	}

	private canConnectKeyPerson(): boolean {
		return (
			this.props.renderFor === RenderForEnum.Wizard &&
			ClaimUtil.validateClaimList(this.props.user, [
				{
					claim: ClaimType.KeyPersonConnection,
					values: [ClaimValue.edit],
				},
			]) &&
			ClaimUtil.validateOptionalClaimList(this.props.user, [
				{
					claim: ClaimType.Persons,
					values: [ClaimValue.edit],
				},
				{
					claim: ClaimType.Drivers,
					values: [ClaimValue.edit],
				},
			])
		);
	}

	fillInformation() {
		return (
			<form id="keyViewInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="keyCode"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.code')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="keyCode"
							value={this.state.key.code}
							onChange={(args: any) => this.handleValueChange(args.target, 'code')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<CustomerNameDisplay
						customerId={
							this.props.defaultCustomerId.length > 0
								? this.props.defaultCustomerId
								: this.state.key.customerId
						}
					/>
					<div
						className="form-group"
						hidden={!ClaimUtil.validateHasClaim(this.props.user, ClaimType.Customers)}
					>
						<ValidationMessage result={this.state.validationResult?.customerId} />
					</div>
					<div className="form-group">
						<TextField
							id="serialNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.serialNumber')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="serialNumber"
							value={this.state.key.serialNumber}
							onChange={(args: any) => this.handleValueChange(args.target, 'serialNumber')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.serialNumber} />
					</div>
					<div className="form-group">
						{this.props.renderFor === RenderForEnum.Wizard ? (
							this.state.canConnectKeyPerson ? (
								<>
									<MaterialAutocomplete
										valueId={this.state.key.personId}
										dataSource={this.state.personsDataSource}
										name={'personId'}
										disabled={this.props.readOnly}
										onChange={({ value }) => {
											this.handleValueChange({ value }, 'personId');
										}}
										label={TranslateText('fields.driver')}
									/>
									<ValidationMessage result={this.state.validationResult?.personId} />
								</>
							) : null
						) : (
							<ProtectedContainer optionalClaimList={[ClaimType.Persons, ClaimType.Drivers]}>
								<TextField
									id="personName"
									type="text"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.driver')}
									inputProps={{ readOnly: true, style: { fontSize: 12 } }}
									name="personName"
									value={this.state.key.personName}
									disabled={true}
								/>
								<ValidationMessage result={this.state.validationResult?.personId} />
							</ProtectedContainer>
						)}
					</div>
					<div className="form-group">
						<MaterialDatePicker
							name="productionDate"
							showTime={true}
							date={this.state.key.productionDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
								DateTimeUtil.dateTimeFormat(),
								true
							)}
							label={TranslateText('fields.productionDate')}
							disabled={true}
							fullWidth={true}
						/>
					</div>
					<div
						className="form-group"
						hidden={!ClaimUtil.validateHasClaim(this.props.user, ClaimType.Persons)}
					>
						<TextField
							id="userName"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.madeBy')}
							inputProps={{ readOnly: true, style: { fontSize: 12 } }}
							name="userName"
							value={this.state.key.userName}
							disabled={true}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'primary'}
									checked={this.state.key.active}
									readOnly={this.props.readOnly}
									onChange={(args: any) => this.handleCheckboxChange(args)}
									disabled={
										this.props.readOnly || this.props.renderFor === RenderForEnum.ConnectDialog
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				</div>
			</form>
		);
	}

	informationContent() {
		return (
			<Scrollbar native={true} className="view-section-scroll">
				{this.fillInformation()}
			</Scrollbar>
		);
	}

	render() {
		return (
			<>
				<ConflictConfirmation {...this.state.conflictConfirmationProps} />
				{this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget()}
			</>
		);
	}

	renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '49%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							{this.informationContent()}
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}

	renderForWidget() {
		return (
			<Accordion>
				<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
					{this.fillInformation()}
				</AccordionSection>
			</Accordion>
		);
	}
}

export default connector(KeyView);
