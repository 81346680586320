import './styles.scss';

import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { InfoBox } from '@react-google-maps/api';

import { RemovablePointProps } from './types';

const RemovablePoint = (props: RemovablePointProps) => {
	return (
		<InfoBox
			position={props}
			options={{
				pixelOffset: new google.maps.Size(-19, -48),
				zIndex: 1000, //always on top
				closeBoxMargin: '0px',
				closeBoxURL: '',
			}}
		>
			<div className="remove-info-box">
				<div onClick={props.onRemove} className="remove-icon">
					<CloseIcon style={{ width: 26, height: 26 }} />
				</div>
				<div className="arrow"></div>
			</div>
		</InfoBox>
	);
};

export default RemovablePoint;
