import './HistoryParkedTripCard.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import { noop } from 'Constants';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { Tooltip } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';

import { ReactComponent as DrivingSvgImage } from '../../assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as IdleSvgImage } from '../../assets/icons/Idle.svg';
import { ReactComponent as ParkedStateHistorySideBar } from '../../assets/icons/ParkedStateHistorySideBar.svg';
import ClaimType from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import { StateFilterEnum } from '../../models/LiveMenuUtils';
import PrivacySettingsEnum from '../../models/PrivacySettingsEnum';
import {
	useLocationsHistory,
	useShowLastDriverOfParkedObject,
	useShowLastObjectOfParkedDriver,
} from '../../shared/effects/useShowLastEntities';
import { ApplicationState } from '../../store';
import { ProcessedTripDto } from '../../store/HistoryStore';
import { FormatDate, GetHourAndMinutes } from '../../utils/DateUtils';
import { AddressRow } from './AddressRow';
import { useShouldScrollIntoView, useUpdateInViewCards } from './hooks';
import { HistoryTripCardPropsRef } from './types';
import { UpdateObservable } from './utils';

interface Props {
	tripConfig: ProcessedTripDto;
	fromLive?: boolean;
	ungrouped?: boolean;
	groupFinishedExpanding?: boolean;
	scrollTrip?: { idWithParent: string } | null;
	historyTripCardPropsRef?: React.MutableRefObject<HistoryTripCardPropsRef>;
	tripsObservable?: UpdateObservable;
}

const HistoryParkedTripCard = ({
	tripConfig,
	fromLive = false,
	ungrouped = false,
	groupFinishedExpanding = false,
	scrollTrip = null,
	historyTripCardPropsRef,
	tripsObservable,
}: Props) => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const liveEntityData = useSelector((s: ApplicationState) =>
		s.liveData.liveEntities.find((le) => le.entityId === tripConfig.parentId)
	);
	const [liveEntity, setLiveEntity] = useState(liveEntityData);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);
	const showLastDriverOfParkedObject = useShowLastDriverOfParkedObject();
	const showLastObjectOfParkedDriver = useShowLastObjectOfParkedDriver();
	const showLocationHistory = useLocationsHistory();
	useEffect(() => {
		if (liveEntityData?.state !== liveEntity?.state || liveEntity?.direction !== liveEntityData?.direction) {
			setLiveEntity(liveEntityData);
		}
	}, [liveEntityData]);

	const { icon } = useTryGetSvgIconFromQueryCache(tripConfig.objectIcon);

	const { refEl } = useUpdateInViewCards(tripConfig.idWithParent, tripsObservable, false);

	const shouldScrollIntoView = useShouldScrollIntoView(
		false,
		groupFinishedExpanding,
		tripConfig.idWithParent,
		scrollTrip,
		historyTripCardPropsRef
	);

	const getStateIcon = () => {
		const liveHistoryStatusDiff = moment(liveEntity?.lastSeen)
			.add(tripConfig.timeZoneMinutesOffset, 'minutes')
			.diff(moment(tripConfig?.endTripTime));

		if (
			!(
				!!liveEntity?.entityId?.length &&
				liveHistoryStatusDiff >= 0 &&
				liveEntity?.state !== StateFilterEnum.Parked
			)
		) {
			return <ParkedStateHistorySideBar />;
		}

		if (liveEntity?.state === StateFilterEnum.Driving) {
			return (
				<DrivingSvgImage
					className={`driving-img object-status-icon direction-icon direction-${liveEntity.direction?.toLowerCase()}`}
				/>
			);
		}

		if (liveEntity?.state === StateFilterEnum.Idle) {
			return <IdleSvgImage className="idle-img object-status-icon" />;
		}

		return null;
	};

	const privateDataMask = '****';

	return (
		(!tripConfig.private ||
			customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData ||
			(!tripConfig.private && customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime) ||
			userCanSeePrivateData ||
			tripConfig.personId === personId) && (
			<ReactScrollIntoViewIfNeeded
				active={shouldScrollIntoView}
				options={{ behavior: 'auto', scrollMode: 'if-needed', block: 'nearest' }}
			>
				<div ref={refEl} className={'history-parked-trip-card'}>
					<div className={`card-row ${fromLive ? 'live-card-row' : ''}`}>
						<div className={'driver-car-info'}>
							<span className={'timer-end-trip'}>
								<span id={'timer'}>
									<AccessTime className="access-time" />
								</span>
								<span id={'timer-info'}>
									{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
									!tripConfig.endTripTime
										? privateDataMask
										: `${FormatDate(tripConfig.endTripTime, true, true, true)} ${GetHourAndMinutes(
												tripConfig.endTripTime,
												true
										  )}`}
								</span>
							</span>
							<span className={'person-object-second-col'}>
								{!tripConfig.isPerson ? (
									<>
										{ungrouped ? (
											<span className="person-object-col">
												<span className="person-object-icon">
													<SvgIcon
														icon={icon.content}
														color="#212529"
														size={18}
														onClick={noop}
													/>
												</span>
												<Tooltip
													PopperProps={{
														disablePortal: true,
														popperOptions: {
															positionFixed: true,
														},
													}}
													title={tripConfig.objectName}
												>
													<span className="person-object-info">
														<ObjectNameWithTimeZoneOffset
															useToolTip={false}
															objectNameWithTimeZone={
																tripConfig.objectNameWithTimeZoneOffset
															}
														/>
													</span>
												</Tooltip>
											</span>
										) : null}

										{tripConfig.personName?.length &&
										driverIdentification &&
										(!tripConfig.isParked || showLastDriverOfParkedObject) ? (
											<span className="person-object-col">
												<span className="person-object-icon">
													<PersonIcon fontSize={'inherit'} />
												</span>
												<Tooltip
													PopperProps={{
														disablePortal: true,
														popperOptions: {
															positionFixed: true,
														},
													}}
													title={tripConfig.personName}
												>
													<span className="person-object-info">{tripConfig.personName}</span>
												</Tooltip>
											</span>
										) : null}
									</>
								) : (
									<>
										{ungrouped ? (
											<span className="person-object-col">
												<span className="person-object-icon">
													<PersonIcon fontSize={'inherit'} />
												</span>
												<Tooltip
													PopperProps={{
														disablePortal: true,
														popperOptions: {
															positionFixed: true,
														},
													}}
													title={tripConfig.personName}
												>
													<span className="person-object-info">{tripConfig.personName}</span>
												</Tooltip>
											</span>
										) : null}

										{!tripConfig.isParked || showLastObjectOfParkedDriver ? (
											<span className="person-object-col">
												<span className="person-object-icon">
													<SvgIcon
														icon={icon.content}
														color="#212529"
														size={18}
														onClick={noop}
													/>
												</span>
												<Tooltip
													PopperProps={{
														disablePortal: true,
														popperOptions: {
															positionFixed: true,
														},
													}}
													title={tripConfig.objectName}
												>
													<span className="person-object-info">
														<ObjectNameWithTimeZoneOffset
															useToolTip={false}
															objectNameWithTimeZone={
																tripConfig.objectNameWithTimeZoneOffset
															}
														/>
													</span>
												</Tooltip>
											</span>
										) : null}
									</>
								)}
							</span>
						</div>
						<div className={'location-info'}>
							<AddressRow
								icon={getStateIcon()}
								locationName={showLocationHistory ? tripConfig.endLocationName : undefined}
								address={tripConfig.endAddress}
							/>
						</div>
					</div>
				</div>
			</ReactScrollIntoViewIfNeeded>
		)
	);
};

export default HistoryParkedTripCard;
