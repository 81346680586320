import '../oldWidget.scss';

import { Column } from 'primereact/column';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../../authorization/ClaimType';
import GlobalSettings from '../../../GlobalSettings.json';
import Customer from '../../../models/Customer';
import EntityTypeEnum from '../../../models/EntityTypeEnum';
import ObjectDeviceConnection from '../../../models/ObjectDeviceConnection';
import { ApplicationState } from '../../../store';
import ajaxUtil from '../../../utils/Ajax';
import { FormatDate } from '../../../utils/DateUtils';
import { TranslateText } from '../../../utils/Translations';
import BaseWidget from '../../BaseWidget';
import WidgetHeader from '../../BaseWidget/WidgetHeader';
import { GridWidgetOverview } from '../../GridOverview/GridWidgetOverview';
import { OldWidgetProps } from '../Widget';
import ConnectToView from './ConnectToView';

const mapState = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
		customerId: state.currentSession.customerId,
	};
};

const connector = connect(mapState, null);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OldWidgetProps &
	PropsFromRedux & {
		entityType: EntityTypeEnum;
		connectionChangedCallback?: () => void;
	};

type State = {
	editRow: boolean;
	widgedInEditMode: boolean;
	showDialog: boolean;
	visible: boolean;
	customerGridDataSource: Customer[];
};

class CustomerWidget extends BaseWidget<Props, State> {
	public selectedRowData: ObjectDeviceConnection;
	public currentObjectId: string;
	public editConnectionEndDateValue: any;
	public loadedEntityId: string;

	constructor(props: Props) {
		super(props);
		this.state = {
			editRow: false,
			widgedInEditMode: false,
			showDialog: false,
			visible: true,
			customerGridDataSource: null,
		};
	}

	componentDidMount() {
		this.getData(this.props.entityId, true);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
			this.getData(this.props.entityId, true);
		}
	}

	closeEditPanelMode() {
		if (this.state.widgedInEditMode) {
			this.hasUnsavedData = false;
			this.toggleEditMode();
		}
	}

	getData(entityId: string, forceReload = false) {
		if (forceReload || !this.state.customerGridDataSource || this.loadedEntityId !== entityId) {
			this.loadedEntityId = entityId;
			let url = '';
			switch (this.props.entityType) {
				case EntityTypeEnum.Device:
					url = `${GlobalSettings.devicesMaintenanceApi}/customerconnections`;
					break;
				case EntityTypeEnum.Object:
					url = `${GlobalSettings.objectsMaintenanceApi}/customerconnections`;
					break;
			}
			ajaxUtil
				.post<Customer[]>(url, {
					entityId: entityId,
					customerId: this.props.customerId,
				})
				.then((result) => {
					this.setState({
						customerGridDataSource: result,
					});
				});
		}
	}

	reloadData() {
		this.getData(this.props.entityId, true);
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgedInEditMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState({
			widgedInEditMode: currentValueFlipped,
		});
	}

	connectToScreenClosed() {
		this.setState({
			showDialog: false,
		});
	}

	openConnectToScreen() {
		this.setState({
			showDialog: true,
		});
	}

	handleSavedCallback() {
		this.hasUnsavedData = false;
		this.closeEditPanelMode();
		if (this.props.connectionChangedCallback) {
			this.props.connectionChangedCallback();
		}
		this.getData(this.props.entityId, true);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="5"
				data-minsizey="3"
			>
				<ConnectToView
					entityId={this.props.entityId}
					entityType={this.props.entityType}
					dialogCloseCallback={() => this.connectToScreenClosed()}
					savedCallback={this.handleSavedCallback.bind(this)}
					showDialog={this.state.showDialog}
					visible={false}
				/>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.customer')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.widgedInEditMode}
							editClaimConfig={[
								{ claim: ClaimType.Customers, values: [ClaimValue.edit] },
								{ claim: ClaimType.Objects, values: [ClaimValue.edit, ClaimValue.editRestricted] },
								{ claim: ClaimType.SimCards, values: [ClaimValue.edit] },
								{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
							]}
							allowEditMode={this.props.allowEditMode || this.state.widgedInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditMode()}
						/>
					</div>
					<div className="e-panel-content">
						<div id="gridHost" className="grid-host auto-customer-widget">
							<GridWidgetOverview
								columns={[
									<Column
										key="customerCode"
										field="customerCode"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colCode')}
										style={{ width: 100 }}
										body={
											this.props.allowEditMode
												? (e: Customer) => (
														<Link
															title={e.customerCode}
															to={`${GlobalSettings.route.customers}/${e.customerId}`}
														>
															{e.customerCode}
														</Link>
												  )
												: null
										}
									/>,
									<Column
										key="customerName"
										field="customerName"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colName')}
										style={{ width: 200 }}
									/>,
									<Column
										key="connectionStartDate"
										field="connectionStartDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colStartDate')}
										style={{ width: 190 }}
										body={(rowData: Customer) => {
											return FormatDate(new Date(rowData.connectionStartDate));
										}}
									/>,
									<Column
										key="connectionEndDate"
										field="connectionEndDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colEndDate')}
										style={{ width: 190 }}
										body={(rowData: Customer) => {
											return rowData.connectionEndDate
												? FormatDate(new Date(rowData.connectionEndDate))
												: null;
										}}
									/>,
								]}
								data={this.state.customerGridDataSource}
								total={this.state.customerGridDataSource?.length}
								paginator={false}
							/>
						</div>
					</div>
					{this.state.widgedInEditMode ? (
						<div id="buttonsHost" className="buttons-host auto-customer-connect-button">
							<div className="left-side-buttons" />
							<div className="right-side-buttons">
								<Button className="widget-button" onClick={() => this.openConnectToScreen()}>
									{TranslateText('common.connect')}{' '}
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default connector(CustomerWidget);
