import { ReactComponent as DrivingSvgImage } from 'assets/icons/DrivingStateSideBar.svg';
import { TripNumber } from 'components/TripNumber';
import React from 'react';

import { HistoryTripStatusProps } from './types';

const HistoryTripStatus = ({ trip }: HistoryTripStatusProps): JSX.Element =>
	trip.isInProgress && !trip.isNonTrip ? (
		<DrivingSvgImage className={`driving-img object-status-icon direction-icon direction-${trip.direction}`} />
	) : (
		<TripNumber tripColor={trip.color} tripIndex={trip.index} isInProgress={trip.isInProgress} />
	);

export default HistoryTripStatus;
