import React from 'react';
import { Column, ColumnBodyType } from 'primereact/column';

type Props = {
	body: ColumnBodyType;
	leftPadding?: boolean;
	shown?: boolean;
	width: number;
	alignCenter?: boolean;
};

const ImageColumn = ({ body, leftPadding = true, shown = true, width, alignCenter = false }: Props) =>
	shown ? (
		<Column
			className={`${leftPadding ? 'leftPadding' : ''} ${alignCenter ? 'align-center' : ''}`}
			style={{ minWidth: width, maxWidth: width }}
			body={body}
			sortable={false}
			showClearButton={false}
			showFilterMenu={false}
			filter={false}
		/>
	) : null;

export default ImageColumn;
