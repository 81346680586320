import { getDistance } from 'geolib';
import { LatLng } from 'store/LiveMap';

import { DirectionArrow } from './types';

export const calculateMidpointBetweenTwoCoordinates = (point1: LatLng, point2: LatLng): LatLng => ({
	lat: (point1.lat + point2.lat) / 2,
	lng: (point1.lng + point2.lng) / 2,
});

export const calculateDistanceBetweenTwoPoints = (point1: LatLng, point2: LatLng): number => {
	const radius = 6371e3; //meters

	const lat1 = point1.lat;
	const lat2 = point2.lat;

	const lng1 = point1.lng;
	const lng2 = point2.lng;

	const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
	const φ2 = (lat2 * Math.PI) / 180;
	const Δφ = ((lat2 - lat1) * Math.PI) / 180;
	const Δλ = ((lng2 - lng1) * Math.PI) / 180;

	const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	return c * radius;
};

export const calculateTotalDistance = (paths: LatLng[]): number => {
	let middle = 0;

	for (let i = 0; i < paths.length - 1; i++) {
		middle += calculateDistanceBetweenTwoPoints(paths[i], paths[i + 1]);
	}

	return middle;
};

// accuracy 3 should be good enough - after that it starts exceeding calls
export const getMidpoint = (paths: LatLng[], accuracy = 3) => {
	if (!paths || paths.length < 2) {
		return;
	}

	if (paths.length === 2) {
		return calculateMidpointBetweenTwoCoordinates(paths[0], paths[1]);
	}

	const halfDistance: number = calculateTotalDistance(paths) / 2;

	if (paths.every((path: LatLng) => path.lat === paths[0].lat && path.lng === paths[0].lng)) {
		return paths[0];
	}

	return calculateMidpointRecursive(paths, halfDistance, 0, accuracy);
};

export const calculateMidpointRecursive = (
	paths: LatLng[],
	halfDistance: number,
	totalDistanceSoFar: number,
	accuracy: number
): LatLng => {
	for (let i = 0; i < paths.length - 1; i++) {
		const distance: number = calculateDistanceBetweenTwoPoints(paths[i], paths[i + 1]);

		if (
			distance + totalDistanceSoFar > halfDistance - halfDistance * Math.pow(10, -accuracy) &&
			halfDistance + halfDistance * Math.pow(10, -accuracy) > distance + totalDistanceSoFar
		) {
			return paths[i + 1];
		}

		if (halfDistance - totalDistanceSoFar - distance > 0) {
			totalDistanceSoFar += distance;
			continue;
		}

		const midpoint: LatLng = calculateMidpointBetweenTwoCoordinates(paths[i], paths[i + 1]);
		const lowerToMidpointDistance: number = calculateDistanceBetweenTwoPoints(paths[i], midpoint);
		const newPointArray = Array<LatLng>();

		if (lowerToMidpointDistance + totalDistanceSoFar < halfDistance) {
			newPointArray.push(midpoint, paths[i + 1]);
			totalDistanceSoFar += lowerToMidpointDistance;
		} else {
			newPointArray.push(paths[i], midpoint);
		}

		return calculateMidpointRecursive(newPointArray, halfDistance, totalDistanceSoFar, accuracy);
	}
};

export const isContinued = (a: LatLng, b: LatLng, c: LatLng) => {
	const ab = Math.sqrt(Math.pow(b.lat - a.lat, 2) + Math.pow(b.lng - a.lng, 2));
	const bc = Math.sqrt(Math.pow(c.lat - b.lat, 2) + Math.pow(c.lng - b.lng, 2));
	const ac = Math.sqrt(Math.pow(a.lat - c.lat, 2) + Math.pow(a.lng - c.lng, 2));
	const angle = Math.acos((bc * bc + ab * ab - ac * ac) / (2 * bc * ab));
	const result = Math.abs(angle);

	return result * 57.2958 >= 170;
};

export const calculateArrowPlacement = <IconSequence>(
	fullPath: LatLng[],
	isFirstHalf: boolean,
	percentageToHalf: number,
	directionArrow: DirectionArrow
) => {
	let offset = 0;
	let biggestStraightLine = 0;
	let distanceToStartIndex = 0;
	let totalDistance = [0];
	let distanceSinceStartIndex = 0;
	let startIndex = 0;
	let path: LatLng[] = [fullPath[0]];
	let lastIndex = 0;

	//remove duplicate points
	for (let j = 1; j < fullPath.length; j++) {
		if (fullPath[j].lat !== path[lastIndex].lat || fullPath[j].lng !== path[lastIndex].lng) {
			path = [...path, fullPath[j]];
			lastIndex++;
		}
	}

	for (let i = 0; i < lastIndex; i++) {
		if (i >= 1) {
			if (!isContinued(path[i - 1], path[i], path[i + 1])) {
				distanceSinceStartIndex = 0;
				startIndex = i;
			}
		} else {
			distanceSinceStartIndex = 0;
		}
		const distance = getDistance(path[i], path[i + 1]);
		distanceSinceStartIndex += distance;

		if (distanceSinceStartIndex > biggestStraightLine) {
			distanceToStartIndex = totalDistance[startIndex];
			biggestStraightLine = distanceSinceStartIndex;
		}

		totalDistance = [...totalDistance, totalDistance[totalDistance.length - 1] + distance];
	}

	offset =
		((distanceToStartIndex + biggestStraightLine / 2) / totalDistance[totalDistance.length - 1]) *
		(isFirstHalf ? percentageToHalf : 100);

	const icon = {
		icon: directionArrow,
		offset: `${isFirstHalf ? offset : (offset / 100) * (100 - percentageToHalf) + percentageToHalf}%`,
	};

	const offst = ~~icon.offset.slice(0, icon.offset.length - 1);

	//don't show the second arrow if the trip is too short
	if (offst > 98 || offst === 0) {
		icon.offset = '150%';
	}
	return icon;
};

export const calculateDistances = (path: LatLng[]) => {
	let distanceToHalf = 0;
	let distance = 0;
	let halfIndex = 0;
	for (let i = 0; i < path.length - 1; i++) {
		const currentDistance = getDistance(path[i], path[i + 1]);
		distance += currentDistance;
	}
	for (let i = 0; i < path.length - 1; i++) {
		const currentDistance = getDistance(path[i], path[i + 1]);
		distanceToHalf += currentDistance;
		halfIndex++;
		if (distanceToHalf >= distance * 0.4) break;
	}

	return [(distanceToHalf / distance) * 100, halfIndex];
};
