import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityGroupingEnum from 'models/EntityGroupingEnum';
import LabelLayoutEnum from 'models/LabelLayoutEnum';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import ShowEntityIconEnum from 'models/ShowEntityIconEnum';
import TripTypesEnum from 'models/TripTypesEnum';
import UpdateIntervalEnum from 'models/UpdateIntervalEnum';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import {globalCustomerActionCreators, globalCustomerReducer} from 'store/GlobalCustomer';
import { userDataActionCreators } from 'store/UserData';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { TextField } from '@material-ui/core';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: CustomerFeatures;
	initialData: CustomerFeatures;
	//Any other custom props
};
interface CustomerFeatures {
	id: string;
	map: boolean;
	live: boolean;
	history: boolean;
	dashboard: boolean;
	reports: boolean;
	alerts: boolean;
	geoFence: boolean;
	templates: boolean;
	locations: boolean;
	privacySettings: PrivacySettingsEnum;
	integration: boolean;
	driverIdentification: boolean;
	addUnknownId: boolean;
	whitelisting: boolean;
	speedViolations: boolean;
	outsideBusinessHours: boolean;
	trackTypeSpecification: boolean;
	commutingRegistration: boolean;
	driverStyleScores: boolean;
	mediaFootage: boolean;
	tripTypes: TripTypesEnum;
	periodSpeedViolations: number;
	validationPeriod: number;
	mileageCorrectionPeriod: number;
	communicationFailure: number;
	noGPSFixPeriod: number;
	assetTracking: boolean;
	rsm: boolean;
	virtualSwitch: boolean;
}

type ChangeValue = boolean | TripTypesEnum | number | string | PrivacySettingsEnum | null;
type ChangeKey =
	| 'map'
	| 'live'
	| 'history'
	| 'dashboard'
	| 'reports'
	| 'alerts'
	| 'geoFence'
	| 'templates'
	| 'locations'
	| 'privacySettings'
	| 'integration'
	| 'driverIdentification'
	| 'addUnknownId'
	| 'whitelisting'
	| 'speedViolations'
	| 'outsideBusinessHours'
	| 'trackTypeSpecification'
	| 'commutingRegistration'
	| 'driverStyleScores'
	| 'mediaFootage'
	| 'tripTypes'
	| 'periodSpeedViolations'
	| 'validationPeriod'
	| 'mileageCorrectionPeriod'
	| 'communicationFailure'
	| 'noGPSFixPeriod'
	| 'assetTracking'
	| 'rsm'
	| 'virtualSwitch';

export const NumberConstants = {
	maxLengthLocationNameEllipsis: 50,
	periodSpeedViolations: 5,
	validationPeriod: 7,
	mileageCorrectionPeriod: 30,
	communicationFailure: 48,
	noGPSFixPeriod: 72,
	deviceLogsTake: 20,
	rightSideBarFleetSelectionOption: 0,
	rightSideBarOverviewOption: 1,
};

export const StringConstants = {
	easytrack: 'easytrack',
	helpRoute: '/help',
	deviceLogsRoute: '/device-logs',
};

const getConstants = () => {
	return {
		tripTypes: [
			{
				id: TripTypesEnum.Business | TripTypesEnum.Private,
				display: TranslateText('common.businessAndPrivate'),
			},
			{
				id: TripTypesEnum.Business | TripTypesEnum.Commuting,
				display: TranslateText('common.businessAndCommuting'),
			},
			{
				id: TripTypesEnum.Business | TripTypesEnum.Private | TripTypesEnum.Commuting,
				display: TranslateText('common.businessCommutingAndPrivate'),
			},
		],
		privacySettingsTypes: [
			{
				id: PrivacySettingsEnum.ShowPrivateData,
				display: TranslateText('common.showPrivateData'),
			},
			{
				id: PrivacySettingsEnum.HidePrivateData,
				display: TranslateText('common.hidePrivateData'),
			},
			{
				id: PrivacySettingsEnum.HidePrivateLocationAndTime,
				display: TranslateText('common.hidePrivateLocationAndTime'),
			},
		],
		defaultValues: {
			map: true,
			live: true,
			history: true,
			dashboard: true,
			reports: true,
			alerts: false,
			geoFence: false,
			templates: false,
			locations: true,
			privacySettings: PrivacySettingsEnum.ShowPrivateData,
			integration: false,
			driverIdentification: true,
			addUnknownId: false,
			whitelisting: false,
			speedViolations: false,
			outsideBusinessHours: false,
			trackTypeSpecification: false,
			commutingRegistration: false,
			driverStyleScores: false,
			mediaFootage: false,
			tripTypes: TripTypesEnum.Business | TripTypesEnum.Private,
			periodSpeedViolations: NumberConstants.periodSpeedViolations,
			validationPeriod: NumberConstants.validationPeriod,
			mileageCorrectionPeriod: NumberConstants.mileageCorrectionPeriod,
			communicationFailure: NumberConstants.communicationFailure,
			noGPSFixPeriod: NumberConstants.noGPSFixPeriod,
			assetTracking: false,
			rsm: false,
			virtualSwitch: false
		} as CustomerFeatures,
	};
};

const GetCustomerFeaturesValidationConfig = (customerType: CustomerLevelEnum): FieldRules => {
	const fieldRules: FieldRules = {
		map: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		live: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		history: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		dashboard: {
			rules: { required: ValidatorFunctions.required() },
		},
		reports: {
			rules: { required: ValidatorFunctions.required() },
		},
		locations: {
			rules: { required: ValidatorFunctions.required() },
		},
		geoFence: {
			rules: { required: ValidatorFunctions.required() },
		},
		alerts: {
			rules: { required: ValidatorFunctions.required() },
		},
		templates: {
			rules: { required: ValidatorFunctions.required() },
		},
		privacySettings: {
			rules: { required: ValidatorFunctions.required() },
		},
		integration: {
			rules: { required: ValidatorFunctions.required() },
		},
		driverIdentification: {
			rules: { required: ValidatorFunctions.required() },
		},
		whitelisting: {
			rules: { required: ValidatorFunctions.required() },
		},
		addUnknownId: {
			rules: { required: ValidatorFunctions.required() },
		},
		speedViolations: {
			rules: { required: ValidatorFunctions.required() },
		},
		outsideBusinessHours: {
			rules: { required: ValidatorFunctions.required() },
		},
		trackTypeSpecification: {
			rules: { required: ValidatorFunctions.required() },
		},
		commutingRegistration: {
			rules: { required: ValidatorFunctions.required() },
		},
		driverStyleScores: {
			rules: { required: ValidatorFunctions.required() },
		},
		mediaFootage: {
			rules: { required: ValidatorFunctions.required() },
		},
		tripTypes: {
			rules: { required: ValidatorFunctions.required() },
		},
		assetTracking: {
			rules: { required: ValidatorFunctions.required() },
		},
		periodSpeedViolations: {
			rules: { minValue: ValidatorFunctions.minNumber(0), required: ValidatorFunctions.required() },
		},
		validationPeriod: {
			rules: { minValue: ValidatorFunctions.minNumber(0), required: ValidatorFunctions.required() },
		},
		mileageCorrectionPeriod: {
			rules: { minValue: ValidatorFunctions.minNumber(0), required: ValidatorFunctions.required() },
		},
		communicationFailure: {
			rules: { minValue: ValidatorFunctions.minNumber(0), required: ValidatorFunctions.required() },
		},
		noGPSFixPeriod: {
			rules: { minValue: ValidatorFunctions.minNumber(0), required: ValidatorFunctions.required() },
		},
		rsm: {
			rules: { required: ValidatorFunctions.required() },
		},
		virtualSwitch: {
			rules: { required: ValidatorFunctions.required() },
		}
	};

	if (customerType === CustomerLevelEnum.Default) {
		fieldRules.assetTracking = {
			rules: { required: ValidatorFunctions.required() },
		};
	}

	return fieldRules;
};

const CustomerFeaturesView = (props: Props) => {
	const filteredCustomer = useSelector((s: ApplicationState) => s.globalCustomer?.filteredCustomer);
	const [constants] = useState(getConstants);
	const [customerLevel, setCustomerLevel] = useState<CustomerLevelEnum | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const checkIntegrations = props.initialData.integration && !props.data.integration;
		const checkGeofenceAlerts = props.initialData.geoFence && !props.data.geoFence;

		if (checkIntegrations || checkGeofenceAlerts) {
			props.setShowSaveConfirmation({
				callback: () => {
					return new Promise((resolve) => {
						ajaxUtil
							.get<{ integrationsWarning: boolean; geofenceAlertsWarning: boolean }>(
								`${GlobalSettings.customersMaintenanceApi}/checkInactiveFeaturesEffect/${props.entityId}?checkIntegrations=${checkIntegrations}&checkGeofenceAlerts=${checkGeofenceAlerts}`
							)
							.then((result) => {
								const messages: string[] = [];
								if (result.integrationsWarning) {
									messages.push(TranslateText('integrations.messageIntegrationFeatureNo'));
								}
								if (result.geofenceAlertsWarning) {
									messages.push(TranslateText('geofence.messageGeofenceFeatureNo'));
								}

								if (messages.length) {
									resolve({
										title: TranslateText('common.titleSavedData'),
										messages: messages,
									});
								} else {
									resolve(null);
								}
							});
					});
				},
			});
		} else {
			props.setShowSaveConfirmation(null);
		}
	}, [
		props.entityId,
		props.data.integration,
		props.initialData.integration,
		props.data.geoFence,
		props.initialData.geoFence,
	]);

	useEffect(() => {
		ajaxUtil
			.get<{ customerType: CustomerLevelEnum }>(`${GlobalSettings.customersMaintenanceApi}/${props.entityId}`)
			.then((data) => {
				setCustomerLevel(data?.customerType);
				props.setValidatorCallback(
					new Validator({ fieldRules: GetCustomerFeaturesValidationConfig(data?.customerType) }, true)
				);
			});
	}, [props.entityId]);

	useEffect(() => {
		const features = {
			templates: props.initialData.templates,
			locations: props.initialData.locations
		};
		if (filteredCustomer !== null) {
			dispatch(globalCustomerActionCreators.setCustomerFeatures(features));
		} else {
			dispatch(userDataActionCreators.setCustomerFeatures(features));
		}
	}, [props.initialData.locations, props.initialData.templates]);

	const events = {
		onValueChanged: async (value: ChangeValue, key: ChangeKey) => {
			const newState = { ...props.data };
			(newState[key] as
				| EntityGroupingEnum
				| LabelLayoutEnum
				| ShowEntityIconEnum
				| UpdateIntervalEnum
				| PrivacySettingsEnum
				| boolean
				| string
				| null) = value;

			//re-set to default values in order to prevent validation issues
			if (key === 'driverIdentification' && !value) {
				newState['addUnknownId'] = constants.defaultValues.addUnknownId;
				newState['whitelisting'] = constants.defaultValues.whitelisting;
			}
			if (key === 'locations' && !value) {
				newState['geoFence'] = false;
			}
			if (key === 'trackTypeSpecification' && !value) {
				newState['tripTypes'] = constants.defaultValues.tripTypes;
				newState['virtualSwitch'] = constants.defaultValues.virtualSwitch;
			}

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper'}>
			<YesNoCombobox
				name={'map'}
				value={props.data.map}
				placeholder={TranslateText('fields.map')}
				change={(value) => events.onValueChanged(value as boolean, 'map')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'live'}
				value={props.data.live}
				placeholder={TranslateText('fields.live')}
				change={(value) => events.onValueChanged(value as boolean, 'live')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'history'}
				value={props.data.history}
				placeholder={TranslateText('fields.history')}
				change={(value) => events.onValueChanged(value as boolean, 'history')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'dashboard'}
				value={props.data.dashboard}
				placeholder={TranslateText('fields.dashboard')}
				change={(value) => events.onValueChanged(value as boolean, 'dashboard')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'reports'}
				value={props.data.reports}
				placeholder={TranslateText('fields.reports')}
				change={(value) => events.onValueChanged(value as boolean, 'reports')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'locations'}
				value={props.data.locations}
				placeholder={TranslateText('fields.locations')}
				change={(value) => events.onValueChanged(value as boolean, 'locations')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'geoFence'}
				value={props.data.geoFence}
				placeholder={TranslateText('fields.geoFence')}
				change={(value) => events.onValueChanged(value as boolean, 'geoFence')}
				readonly={!props.editMode || !props.data.locations}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'alerts'}
				value={props.data.alerts}
				placeholder={TranslateText('fields.alerts')}
				change={(value) => events.onValueChanged(value as boolean, 'alerts')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'templates'}
				value={props.data.templates}
				placeholder={TranslateText('fields.templates')}
				change={(value) => events.onValueChanged(value as boolean, 'templates')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'driverIdentification'}
				value={props.data.driverIdentification}
				placeholder={TranslateText('fields.driverIdentification')}
				change={(value) => events.onValueChanged(value as boolean, 'driverIdentification')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'whitelisting'}
				value={props.data.whitelisting}
				placeholder={TranslateText('fields.whitelisting')}
				change={(value) => events.onValueChanged(value as boolean, 'whitelisting')}
				readonly={!props.editMode || !props.data.driverIdentification}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'addUnknownId'}
				hidden={!props.data.driverIdentification}
				value={props.data.addUnknownId}
				placeholder={TranslateText('fields.addUnknownId')}
				change={(value) => events.onValueChanged(value as boolean, 'addUnknownId')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'speedViolations'}
				value={props.data.speedViolations}
				placeholder={TranslateText('fields.speedViolations')}
				change={(value) => events.onValueChanged(value as boolean, 'speedViolations')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'outsideBusinessHours'}
				value={props.data.outsideBusinessHours}
				placeholder={TranslateText('fields.outsideBusinessHours')}
				change={(value) => events.onValueChanged(value as boolean, 'outsideBusinessHours')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'trackTypeSpecification'}
				value={props.data.trackTypeSpecification}
				placeholder={TranslateText('fields.trackTypeSpecification')}
				change={(value) => events.onValueChanged(value as boolean, 'trackTypeSpecification')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'virtualSwitch'}
				value={props.data.virtualSwitch}
				placeholder={TranslateText('fields.virtualSwitch')}
				change={(value) => events.onValueChanged(value as boolean, 'virtualSwitch')}
				readonly={!props.editMode || !props.data.trackTypeSpecification}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'commutingRegistration'}
				value={props.data.commutingRegistration}
				placeholder={TranslateText('fields.commutingRegistration')}
				change={(value) => events.onValueChanged(value as boolean, 'commutingRegistration')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'driverStyleScores'}
				value={props.data.driverStyleScores}
				placeholder={TranslateText('fields.driverStyleScores')}
				change={(value) => events.onValueChanged(value as boolean, 'driverStyleScores')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'mediaFootage'}
				value={props.data.mediaFootage}
				placeholder={TranslateText('fields.mediaFootage')}
				change={(value) => events.onValueChanged(value as boolean, 'mediaFootage')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
			<div className="yes-no-combobox">
				<MaterialAutocomplete
					valueId={props.data.tripTypes}
					dataSource={constants.tripTypes}
					name="tripTypes"
					disabled={!props.editMode || !props.data.trackTypeSpecification}
					label={TranslateText('fields.tripTypes')}
					onChange={({ value }) => events.onValueChanged(value as LabelLayoutEnum, 'tripTypes')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.tripTypes} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.privacySettings}
					dataSource={constants.privacySettingsTypes}
					name="privacySettings"
					disabled={
						!props.editMode ||
						customerLevel === CustomerLevelEnum.OEM ||
						customerLevel === CustomerLevelEnum.Reseller
					}
					label={TranslateText('fields.privacySettings')}
					onChange={({ value }) => events.onValueChanged(value as PrivacySettingsEnum, 'privacySettings')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.privacySettings} />

				<YesNoCombobox
					name={'integration'}
					value={props.data.integration}
					placeholder={TranslateText('fields.integration')}
					change={(value) => events.onValueChanged(value as boolean, 'integration')}
					readonly={!props.editMode}
					validationResult={props.validationResult}
				/>
			</div>
			<div className="form-group">
				<TextField
					id="periodSpeedViolations"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.periodSpeedViolations')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="periodSpeedViolations"
					value={props.data.periodSpeedViolations}
					onChange={(e) => events.onValueChanged(e.target.value, 'periodSpeedViolations')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.periodSpeedViolations} />
			</div>
			<div className="form-group">
				<TextField
					id="validationPeriod"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.validationPeriod')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="validationPeriod"
					value={props.data.validationPeriod}
					onChange={(e) => events.onValueChanged(e.target.value, 'validationPeriod')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.validationPeriod} />
			</div>
			<div className="form-group">
				<TextField
					id="mileageCorrectionPeriod"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.mileageCorrectionPeriod')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="mileageCorrectionPeriod"
					value={props.data.mileageCorrectionPeriod}
					onChange={(e) => events.onValueChanged(e.target.value, 'mileageCorrectionPeriod')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.mileageCorrectionPeriod} />
			</div>
			<div className="form-group">
				<TextField
					id="communicationFailure"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.communicationFailure')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="communicationFailure"
					value={props.data.communicationFailure}
					onChange={(e) => events.onValueChanged(e.target.value, 'communicationFailure')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.communicationFailure} />
			</div>
			<div className="form-group">
				<TextField
					id="noGPSFixPeriod"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.noGPSFixPeriod')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="noGPSFixPeriod"
					value={props.data.noGPSFixPeriod}
					onChange={(e) => events.onValueChanged(e.target.value, 'noGPSFixPeriod')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.noGPSFixPeriod} />
			</div>
			{customerLevel === CustomerLevelEnum.Default ? (
				<>
					<YesNoCombobox
						name={'assetTracking'}
						value={props.data.assetTracking}
						placeholder={TranslateText('fields.assetTracking')}
						change={(value) => events.onValueChanged(value as boolean, 'assetTracking')}
						readonly={!props.editMode}
						validationResult={props.validationResult}
					/>
				</>
			) : null}
			<YesNoCombobox
				name={'rsm'}
				value={props.data.rsm}
				placeholder={TranslateText('fields.rsm')}
				change={(value) => events.onValueChanged(value as boolean, 'rsm')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
			/>
		</div>
	);
};

export default CustomerFeaturesView;
