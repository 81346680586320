import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { noop } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import AvailableAPIOperationDto from 'models/AvailableAPIOperationDto';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, ListItem, ListItemIcon, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { common } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';

import { IntegrationTypeProps } from '../IntegrationType/types';
import { IntegrationConstants } from '../Utils';

const ApiAvailableOperations = ({
	integrationType,
	integrationEasyTrackMetadata,
	validationResult,
	handleValueChangeMaterial,
	editMode = true,
	isForNewAddWizard = true,
}: IntegrationTypeProps) => {
	const inputFontSize = useMemo(() => (isForNewAddWizard ? 10 : 12), [isForNewAddWizard]);

	const groupsLoaded = useRef(false);
	const [apiOperationList, setapiOperationList] = useState<AvailableAPIOperationDto[]>([]);

	useEffect(() => {
		if (!groupsLoaded.current && integrationType === IntegrationTypeEnum.EasyTrack) {
			//wait for the edit button to be press before loading api list

			ajaxUtil
				.get<AvailableAPIOperationDto[]>(
					`${GlobalSettings.listsApi}/availableApiOperations?integrationType=${integrationType}`
				)
				.then((data) => {
					//if the selected group is not found between the group list add it as disabled
					setapiOperationList(data);

					groupsLoaded.current = true;
				});
		}
	}, [integrationType, editMode, integrationEasyTrackMetadata?.customerId]);

	const handleToggle = (value: string) => () => {
		if (!editMode) {
			return;
		}
		const currentIndex = integrationEasyTrackMetadata.allowedAPIOperationsIds.indexOf(value);
		const newChecked = [...integrationEasyTrackMetadata.allowedAPIOperationsIds];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		handleValueChangeMaterial(
			{ ...integrationEasyTrackMetadata, allowedAPIOperationsIds: newChecked },
			'integrationEasyTrackMetadata'
		);
	};

	const selectAllApiOperations = () => {
		const newSelection = apiOperationList.map((x) => x.operationId);
		handleValueChangeMaterial(
			{ ...integrationEasyTrackMetadata, allowedAPIOperationsIds: newSelection },
			'integrationEasyTrackMetadata'
		);
	};

	return (
		<div className="view-section-wrapper">
			{integrationType === IntegrationTypeEnum.EasyTrack &&
			integrationEasyTrackMetadata.allowedAPIOperationsIds ? (
				<>
					<div className="form-group">
						<div>
							<Typography
								style={{
									fontSize: '12px',
									fontWeight: '400',
									paddingBottom: '10px',
									color: isForNewAddWizard ? '#212529' : !editMode ? '#00000061' : '#0000008a',
								}}
							>
								{TranslateText('api.allowedApiOperations')}
							</Typography>
							<Button
								style={{ textTransform: 'none' }}
								onClick={() => selectAllApiOperations()}
								disabled={!editMode}
							>
								{TranslateText('common.selectAll')}
							</Button>
						</div>
						<List dense={true} style={{ maxHeight: '250px', overflow: 'auto' }}>
							{apiOperationList.map((apiOperation) => {
								const labelId = `checkbox-list-label-${apiOperation.operationId}`;

								return (
									<ListItem
										disabled={!editMode}
										key={apiOperation.operationId}
										id={apiOperation.operationId}
										onClick={handleToggle(apiOperation.operationId)}
									>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={integrationEasyTrackMetadata.allowedAPIOperationsIds.includes(
													apiOperation.operationId
												)}
												disabled={!editMode}
												tabIndex={-1}
												disableRipple
												inputProps={{ 'aria-labelledby': labelId }}
											/>
										</ListItemIcon>
										<Tooltip title={TranslateText(apiOperation.operationDescription)}>
											<ListItemText
												style={{ marginLeft: '-30px' }}
												id={labelId}
												primary={`${TranslateText(apiOperation.operationName)}`}
											/>
										</Tooltip>
									</ListItem>
								);
							})}
						</List>
					</div>
				</>
			) : null}
		</div>
	);
};

export default ApiAvailableOperations;
