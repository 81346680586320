import './styles.scss';

import { ReactComponent as TripStartIcon } from 'assets/icons/HistoryTripStart.svg';
import { ReactComponent as TripStopIcon } from 'assets/icons/HistoryTripStop.svg';
import { ReactComponent as IdleSvgImage } from 'assets/icons/Idle.svg';
import parkedSvg from 'assets/icons/Parked.svg';
import { ReactComponent as TripMiddleStop } from 'assets/icons/TripMiddleStop.svg';
import ClaimType from 'authorization/ClaimType';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { noop } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';

import PersonIcon from '@material-ui/icons/Person';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { TripInfoWindowSubtitle } from '../TripInfoWindowSubtitle';
import { VisitInfoWindowProps } from './types';
import { getHiddenTripTitle, getStartEndDates, getVisitDuration } from './utils';

const VisitInfoWindow = ({
	nextTrip,
	mouseInCallback,
	positionCoord,
	trip = undefined,
	isStart = false,
	isEnd = false,
	useNextTripStartAddress = false,
}: VisitInfoWindowProps): JSX.Element => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);

	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const showPrivateTrips = useSelector(
		(state: ApplicationState) => !!state.oidc.user?.profile[ClaimType.ShowPrivateTrips]
	);
	const showHistoryLocations = useLocationsHistory();
	const showPrivateData = customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData || showPrivateTrips;

	const privateDataMask = '****';

	const { icon } = useTryGetSvgIconFromQueryCache(trip ? trip.objectIcon : nextTrip.objectIcon);

	return (
		<div className={'visit-card-dialog'}>
			<div className="header">
				<div className="text">
					<div style={!trip?.personName?.length ? { width: '310px' } : { width: '170px' }} className="title">
						{trip?.isPerson || nextTrip?.isPerson ? (
							<PersonIcon className="person-object-icon" />
						) : (
							<SvgIcon icon={icon.content} color="#FFFFFF" size={14} onClick={noop} />
						)}
						<div className={'person-name'}>{getHiddenTripTitle(trip, nextTrip, driverIdentification)}</div>
					</div>
					{trip?.id ? (
						trip?.isPerson ? (
							<TripInfoWindowSubtitle
								isObject
								icon={
									<SvgIcon
										icon={icon.content}
										color="#FFFFFF"
										size={14}
										onClick={(icon: string) => {}}
									/>
								}
								name={trip?.objectNameWithTimeZoneOffset}
							/>
						) : driverIdentification && trip?.personName ? (
							<TripInfoWindowSubtitle
								isObject={false}
								icon={<PersonIcon className="person-object-icon" />}
								name={trip?.personName}
							/>
						) : null
					) : nextTrip.isPerson ? (
						<TripInfoWindowSubtitle
							isObject
							icon={
								<SvgIcon icon={icon.content} color="#FFFFFF" size={14} onClick={(icon: string) => {}} />
							}
							name={nextTrip?.objectName}
						/>
					) : driverIdentification && nextTrip.personName ? (
						<TripInfoWindowSubtitle
							isObject
							icon={<PersonIcon className="person-object-icon" />}
							name={nextTrip?.objectNameWithTimeZoneOffset}
						/>
					) : null}
				</div>
				{trip?.id?.length && (
					<div className={'state'}>
						{!!getVisitDuration(
							trip,
							nextTrip,
							showPrivateData,
							personId,
							customerPrivacySettings,
							privateDataMask
						) ? (
							<IdleSvgImage />
						) : null}
						<div className={'state-duration'}>
							{getVisitDuration(
								trip,
								nextTrip,
								showPrivateData,
								personId,
								customerPrivacySettings,
								privateDataMask
							)}
						</div>
					</div>
				)}
			</div>
			<div className="content">
				<div className={'map-container'}>
					<GoogleMap
						zoom={14}
						center={positionCoord}
						options={
							{
								mapId: GlobalSettings.googleMapId,
								clickableIcons: false,
								gestureHandling: 'greedy',
								disableDefaultUI: true,
								disableDoubleClickZoom: true,
								streetViewControl: true,
								streetViewControlOptions: {
									position: google.maps.ControlPosition.RIGHT_TOP,
								},
								zoomControl: true,
								zoomControlOptions: {
									position: google.maps.ControlPosition.LEFT_TOP,
									style: google.maps.ZoomControlStyle.SMALL,
								},
								mapTypeControl: true,
								tilt: 0,
								rotateControl: false,
							} as google.maps.MapOptions
						}
						onMouseOver={() => {
							mouseInCallback(true);
						}}
						onMouseOut={() => {
							mouseInCallback(false);
						}}
					>
						<Marker
							options={{
								icon: {
									url: parkedSvg,
									scaledSize: new google.maps.Size(16, 16),
									anchor: new google.maps.Point(7, 7),
								},
							}}
							position={positionCoord}
						/>
					</GoogleMap>
				</div>
				<div className={'visit-info'}>
					{trip?.id?.length && (
						<div className={'visit-row'}>
							{isEnd ? (
								<TripStopIcon className={'trip-stop-icon'} />
							) : isStart ? (
								<TripStartIcon className={'trip-start-icon'} />
							) : (
								<TripMiddleStop className={'trip-middle-icon'} />
							)}
							{getStartEndDates(
								customerPrivacySettings,
								showPrivateData,
								nextTrip,
								privateDataMask,
								trip,
								personId
							)}
						</div>
					)}
					<div className={'visit-row more-padding last-row'}>
						<div className={'visit-address'}>
							{!useNextTripStartAddress && trip
								? showHistoryLocations
									? trip?.endLocationAddress
									: trip?.endAddress
								: showHistoryLocations
								? nextTrip?.startLocationAddress
								: nextTrip?.startAddress}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VisitInfoWindow;
