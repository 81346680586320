import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ApplicationStore } from '../..';
import userService from '../../auth';

const Logout = () => {
	useEffect(() => {
		ApplicationStore.persistor.flush();
		ApplicationStore.persistor.pause();
		userService.userManager.signoutRedirect().then(() => {
			ApplicationStore.persistor.purge();
			localStorage.clear();
		});
	}, []);

	return <CircularProgress className="loading-component" />;
};

export default Logout;
