class DriverDisconnectSettings {
	constructor(
		disconnectDriverAfterIgnitionOffValue: boolean,
		disconnectDriverAfterIgnitionOffTimeOutSecondsValue: number
	) {
		this.disconnectDriverAfterIgnitionOff = disconnectDriverAfterIgnitionOffValue;
		this.disconnectDriverAfterIgnitionOffTimeOutSeconds = disconnectDriverAfterIgnitionOffTimeOutSecondsValue;
	}
	public disconnectDriverAfterIgnitionOff: boolean;
	public disconnectDriverAfterIgnitionOffTimeOutSeconds: number;
}

export default DriverDisconnectSettings;
