import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import Role from '../../models/Role';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import RenderForEnum from '../Views/RenderForEnum';
import RoleView from '../Views/RoleView';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';
import {DebouncedButton} from "../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../Constants";

interface Props extends OldWidgetProps {
	currentUserCustomerLevel: CustomerLevelEnum;
	showEditButton: boolean;
}

interface State {
	role: Role;
	initialRole: Role;
	editMode: boolean;
	invalidForm: boolean;
	validRole: boolean;
	visible: boolean;
	disabled: boolean;
}

class RoleInformationWidget extends BaseWidget<Props, State> {
	roleViewRef: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			role: new Role(),
			initialRole: new Role(),
			editMode: false,
			invalidForm: false,
			validRole: true,
			visible: true,
			disabled: false,
		};
	}

	public static defaultProps = {
		showEditButton: true,
	};

	componentDidMount() {
		this.fillRole(this.props.entityId);
		this.closeEditPanelMode();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
			this.fillRole(this.props.entityId);
		}
	}

	fillRole(roleId: string) {
		const url = `${GlobalSettings.rolesMaintenanceApi}/${roleId}`;
		ajaxUtil.get<Role>(url).then((data) => {
			this.setState({
				role: data,
				initialRole: data,
			});

			if (this.roleViewRef) {
				this.roleViewRef.setEditRecord(data);
			}
			this.props.displayCallback(data);
		});
	}

	getAccessToken(): string {
		return this.props.getAccessTokenCallback();
	}

	async toggleEditPanel() {
		if (!this.state.editMode || !this.hasUnsavedData) {
			this.toggleEditMode();
			this.props.displayCallback(this.state.role);
		} else if (await this.util.cancelConfirmation()) {
			this.roleViewRef.setEditRecord(this.state.initialRole);
			this.props.displayCallback(this.state.initialRole);
			this.toggleEditMode();
		}
	}

	toggleEditMode() {
		const newEdit = !this.state.editMode;
		this.setState({
			editMode: newEdit,
			invalidForm: false,
		});
		this.props.editModeCallback(newEdit);
		this.props.changeDataCallback(false);
		this.hasUnsavedData = false;
	}

	closeEditPanelMode() {
		if (this.state.editMode) {
			this.toggleEditMode();
		}
	}

	save() {
		this.setState({disabled:true});
		this.roleViewRef.validateBeforeSave().then((valid: boolean) => {
			if (!valid) {
				this.setState({
					invalidForm: true,
					disabled: false,
				});
				return;
			} else {
				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);

				const url = GlobalSettings.rolesMaintenanceApi;
				const updatedRole = this.state.role;

				ajaxUtil
					.put(url, updatedRole)
					.then((data) => {
						this.setState({disabled:false});
						if (data) {
							this.hasUnsavedData = false;
							this.props.changeDataCallback(false);
						}
						this.toggleEditPanel();
					})
					.catch((error) => {
						this.setState({disabled:false});
						console.log(error);
					});
			}
		});
	}

	setRole(valid: boolean, roleData: Role) {
		this.setState({
			validRole: valid,
			role: roleData,
			invalidForm: !valid,
		});

		this.hasUnsavedData = true;
		this.props.changeDataCallback(true);
	}

	setUnsavedData(unsavedData: boolean) {
		this.hasUnsavedData = unsavedData;
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="4"
			>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.editMode}
							allowEditMode={this.props.allowEditMode || this.state.editMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel()}
						/>
					</div>
					<div className="widget-view">
						<RoleView
							informationWidget={true}
							readOnly={!this.state.editMode}
							ref={(input) => {
								this.roleViewRef = input;
							}}
							currentStep={0}
							getAccessTokenCallback={this.getAccessToken.bind(this)}
							setRole={this.state.editMode ? this.setRole.bind(this) : null}
							renderFor={RenderForEnum.Widget}
							widgetState={this.state.editMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							currentUserCustomerLevel={this.props.currentUserCustomerLevel}
							setUnsavedData={this.setUnsavedData.bind(this)}
						/>
					</div>
					{this.state.editMode ? (
						<div className="buttons-host">
							<Button className="widget-button cancel-button" onClick={() => this.toggleEditPanel()}>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.disabled || this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default RoleInformationWidget;
