enum AlertTypesEnum {
	Unknown = 0,
	PrivateMileage = 1,
	Ignition = 2,
	UnknownKey = 3,
	UnauthorizedDrive = 4,
	DrivingWithoutAuthentication = 5,
	DurationExceeded = 6,
	Analog = 7,
	Digital = 8,
	Geofence = 9,
	MainPower = 10,
	Towing = 11,
}

export default AlertTypesEnum;
