import { DependencyValidator } from './DependencyValidator';
import { FieldRules, ValidateResult, ValidationData, ValidationResult, ValidatorProps } from './interfaces';
import { addRuleValidationToWaitList, getFieldValue } from './utils';

export default class Validator {
	public forceCheck: boolean;
	private fieldRules: FieldRules;
	private dependencyValidator: DependencyValidator;
	private previousData: ValidationData;
	private previousResult: ValidationResult;

	constructor(props: ValidatorProps, forceCheck = false) {
		this.fieldRules = props.fieldRules;
		this.forceCheck = forceCheck;

		this.dependencyValidator = new DependencyValidator(props.fieldRules);
	}

	setRules(rules: FieldRules) {
		this.fieldRules = rules;
	}

	addRules(rules: FieldRules) {
		this.fieldRules = { ...this.fieldRules, ...rules };
		this.dependencyValidator = new DependencyValidator(this.fieldRules);
	}

	private validateRules(
		fieldRules: FieldRules,
		fieldName: string,
		result: ValidationResult,
		data: ValidationData,
		context: any
	) {
		const rules = fieldRules[fieldName].rules;
		const waitList: Promise<boolean>[] = [];

		for (const ruleName in rules) {
			if (!rules.hasOwnProperty(ruleName) || rules[ruleName].disabled) {
				continue;
			}

			if (
				Object.keys(result[fieldName]).some(
					(key: string) => !result[fieldName][key].valid && result[fieldName][key].error
				)
			) {
				//Do not continue with validation if there is already an invalid result
				break;
			}

			addRuleValidationToWaitList(rules[ruleName], ruleName, fieldName, waitList, result, data, context);
		}

		return waitList;
	}

	async validate(data: ValidationData, context?: any): Promise<ValidateResult> {
		const result: ValidationResult = {} as ValidationResult;
		const waitList: Promise<boolean>[] = [];

		for (const fieldName in this.fieldRules) {
			const fieldValue = getFieldValue(data, fieldName);

			if (!this.fieldRules.hasOwnProperty(fieldName)) {
				continue;
			}

			if (
				!this.forceCheck &&
				this.previousData &&
				this.previousData[fieldName] === fieldValue &&
				this.previousResult &&
				this.previousResult[fieldName]
			) {
				result[fieldName] = this.previousResult[fieldName];
				continue;
			}
			result[fieldName] = {};

			waitList.push(...this.validateRules(this.fieldRules, fieldName, result, data, context));
		}

		for (const fieldName in this.fieldRules) {
			waitList.push(
				...(await this.dependencyValidator.validateDependents(
					fieldName,
					result,
					data,
					this.forceCheck,
					context
				))
			);
		}

		await Promise.all(waitList);

		this.previousData = data;
		this.previousResult = result;

		const formResult: boolean = this.getFormResult(result);

		return { validationResult: result, formResult };
	}

	clearValidation() {
		this.previousData = null;
		this.previousResult = null;
	}

	private getFormResult(result: ValidationResult): boolean {
		for (const fieldName in result) {
			const fieldResult = result[fieldName];
			for (const ruleName in fieldResult) {
				const ruleResult = fieldResult[ruleName];
				if (!ruleResult.valid && ruleResult.error) {
					return false;
				}
			}
		}

		return true;
	}
}
