import './rietveld-menu.scss';

import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Drawer, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../../authorization/ClaimType';
import { ClaimUtil } from '../../../authorization/ClaimUtil';
import EasyTrackFeature from '../../../authorization/EasyTrackFeature';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';
import { ApplicationState } from '../../../store';
import { SET_LEFT_BAR_STATE } from '../../../store/LeftSideBar';
import GoogleMaterialIcon from '../../Common/Icons/GoogleMaterialIcon';
import HelpButton, { getSupportedHelpPath } from '../../Help/HelpButton';
import ConfigMenu from './ConfigMenu';
import MainMenu from './MainMenu';

enum MenuType {
	None,
	Config,
}

export interface IMainMenuStyles {
	menuLayout: string;
	linkHighLighted: string;
	linkItemIcon: string;
	listItemText: string;
	config: string;
}

const RietveldMenu = () => {
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const featuresSettings = useSelector(
		(state: ApplicationState) => state?.currentSession?.customer?.featuresSettings
	);
	const driverIdentification = useSelector(
		(state: ApplicationState) => state.currentSession?.customer?.featuresSettings?.driverIdentification
	);
	const customerLevel = useSelector((s: ApplicationState) => s.currentSession?.customerLevel);

	const [showConfigTracking, setShowConfigTracking] = useState<boolean>(false);
	const [showConfigCommunity, setShowConfigCommunity] = useState<boolean>(false);
	const [showConfigBilling, setShowConfigBilling] = useState<boolean>(false);
	const [showInformationNotifications, setShowInformationNotifications] = useState<boolean>(false);
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	const [showDrivers, setShowDrivers] = useState<boolean>(false);
	const [menuType, setMenuType] = useState<MenuType>(MenuType.None);
	const leftBarState = useSelector((s: ApplicationState) => s.leftSideBar.leftBarState);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const helpPath = getSupportedHelpPath(pathname);
	const [mainMenuStyles, setMainMenuStyles] = useState({
		menuLayout: 'main-menu',
		linkHighLighted: 'link-style',
		linkItemIcon: 'list-item-icon',
		listItemText: 'list-item-text',
		config: 'configFromMainMenu',
	});

	useEffect(() => {
		if (!!currentSession?.personId?.length && user && leftBarState === null) {
			dispatch({
				type: SET_LEFT_BAR_STATE,
				payload: { isExpanded: currentSession.mainMenuExpanded },
			});
		}
	}, []);

	useEffect(() => {
		if (leftBarState?.isExpanded) {
			setMainMenuStyles({
				menuLayout: 'main-menu-expanded',
				linkHighLighted: 'link-style-expanded',
				linkItemIcon: 'list-item-icon-expanded',
				listItemText: 'list-item-text-expanded',
				config: 'configFromMainMenu-expanded',
			});
		} else {
			setMainMenuStyles({
				menuLayout: 'main-menu',
				linkHighLighted: 'link-style',
				linkItemIcon: 'list-item-icon',
				listItemText: 'list-item-text',
				config: 'configFromMainMenu',
			});
		}
	}, [leftBarState]);

	useEffect(() => {
		const showConfigTr =
			ClaimUtil.validateOptionalClaimList(user, [
				{ claim: ClaimType.Devices, values: [ClaimValue.view] },
				{ claim: ClaimType.SimCards, values: [ClaimValue.view] },
				{ claim: ClaimType.Objects, values: [ClaimValue.view] },
				{ claim: ClaimType.Configs, values: [ClaimValue.view] },
			]) ||
			(ClaimUtil.validateHasClaim(user, ClaimType.Templates) &&
				(ClaimUtil.validateFeature(featuresSettings, EasyTrackFeature.Templates) as boolean));
		const showConfigComm =
			ClaimUtil.validateOptionalClaimList(user, [
				{ claim: ClaimType.Groups, values: [ClaimValue.view] },
				{ claim: ClaimType.Persons, values: [ClaimValue.view] },
				{ claim: ClaimType.Roles, values: [ClaimValue.view] },
				{ claim: ClaimType.Keys, values: [ClaimValue.view] },
				{ claim: ClaimType.Translations, values: [ClaimValue.view] },
				{ claim: ClaimType.ConfigurationGroup, values: [ClaimValue.view] },
			]) ||
			(ClaimUtil.validateHasClaim(user, ClaimType.Locations) &&
				(ClaimUtil.validateFeature(featuresSettings, EasyTrackFeature.Locations) as boolean)) ||
			(ClaimUtil.validateHasClaim(user, ClaimType.Alerts) &&
				(ClaimUtil.validateFeature(featuresSettings, EasyTrackFeature.Alerts) as boolean)) ||
			(ClaimUtil.validateHasClaim(user, ClaimType.Integration) &&
				(ClaimUtil.validateFeature(featuresSettings, EasyTrackFeature.Integration) as boolean));
		const showConfigBill = ClaimUtil.validateOptionalClaimList(user, [
			{ claim: ClaimType.Subscriptions, values: [ClaimValue.view] },
			{ claim: ClaimType.Customers, values: [ClaimValue.view] },
			{ claim: ClaimType.InformationNotificationManage, values: [ClaimValue.view] },
		]);
		setShowConfigBilling(showConfigBill);
		setShowConfigCommunity(showConfigComm);
		setShowConfigTracking(showConfigTr);
		setShowDrivers(driverIdentification && ClaimUtil.validateHasClaim(user, ClaimType.Drivers));
		setShowInformationNotifications(
			customerLevel !== CustomerLevelEnum.Default &&
				ClaimUtil.validateHasClaim(user, ClaimType.InformationNotificationManage)
		);
	}, [user, driverIdentification, customerLevel]);
	return user && !!currentSession.personId?.length ? (
		<Drawer variant={'permanent'} className={'rietveld-menu'}>
			{menuType === MenuType.None ? (
				<>
					<MainMenu
						requestMenu={setMenuType}
						showDrivers={showDrivers}
						configAvailable={showConfigCommunity || showConfigBilling || showConfigTracking}
						mainMenuStyles={mainMenuStyles}
					/>

					<div className={`footer-area${leftBarState?.isExpanded ? '-expanded' : ''}`}>
						<ListItem>
							<ListItemIcon className={mainMenuStyles.linkItemIcon}>
								{helpPath && <HelpButton />}
							</ListItemIcon>
							<ListItemText className={mainMenuStyles.listItemText}>
								<Trans>{helpPath ? 'Support' : null}</Trans>
							</ListItemText>
						</ListItem>
						<div
							className={`arrows${leftBarState?.isExpanded ? '-expanded' : ''}`}
							onClick={() =>
								dispatch({
									type: SET_LEFT_BAR_STATE,
									payload: { isExpanded: !leftBarState?.isExpanded },
								})
							}
						>
							{!leftBarState?.isExpanded ? (
								<GoogleMaterialIcon iconName={'keyboard_double_arrow_right'} />
							) : (
								<GoogleMaterialIcon iconName={'keyboard_double_arrow_left'} />
							)}
						</div>
					</div>
				</>
			) : null}
			{menuType === MenuType.Config ? (
				<>
					<ConfigMenu
						requestMenu={setMenuType}
						showDrivers={showDrivers}
						showConfigBilling={showConfigBilling}
						showConfigCommunity={showConfigCommunity}
						showConfigTracking={showConfigTracking}
						showInformationNotifications={showInformationNotifications}
					/>

					<div className={'footer-area-config'}>
						<ListItem>
							<ListItemIcon className={mainMenuStyles.linkItemIcon}>
								{helpPath && <HelpButton />}
							</ListItemIcon>
							<ListItemText className={'list-item-text-expanded'}>
								<Trans>{helpPath ? 'Support' : null}</Trans>
							</ListItemText>
						</ListItem>
					</div>
				</>
			) : null}
		</Drawer>
	) : null;
};

export default RietveldMenu;
