import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import {
	HistoryGrouping,
	historyStoreActionCreators,
	ProcessedTripDto,
	SET_DETAILED_TRIPS_ON_MAP,
	SET_DETAILED_VISITS_ON_MAP,
	SET_PREVIOUS_DETAILED_VISITS_ON_MAP,
	SET_SELECTED_TRIPS_IN_OVERVIEW,
	SET_TRIP_UNSAVED_DATA,
	SHOW_HISTORY_TRIP_DETAILS,
} from 'store/HistoryStore';
import SelectionHelper from 'utils/SelectionHelper';

import { ApplicationState } from '../../store';
import { Group } from './HistorySideBar';
import { HistoryTripCardPropsRef, ProcessedTripCard, TripCardCommonProps } from './types';
import { UpdateObservable } from './utils';

export const useShouldScrollIntoView = (
	selected: boolean,
	groupFinisedhExpanding: boolean,
	idWithParent: string,
	scrollTrip: { idWithParent: string },
	historyTripCardPropsRef: React.MutableRefObject<HistoryTripCardPropsRef>
) => {
	const [shouldScrollIntoView, setShouldScrollIntoView] = useState<boolean>(false);

	//added state variable instead of props.selected to
	//trigger render when switching between map and grid
	useEffect(() => {
		setShouldScrollIntoView(
			groupFinisedhExpanding &&
				((selected && !historyTripCardPropsRef?.current?.isSelectedFromOverview) ||
					scrollTrip?.idWithParent === idWithParent)
		);
	}, [selected, groupFinisedhExpanding]);

	return shouldScrollIntoView;
};

export const useBatchDisplay = () => {
	const renderedGroups = useRef<{ name: string; rendering: boolean; render: () => void }[]>([]);
	const appendToBatchDisplay = useCallback((group: { name: string; render?: () => void }, add: boolean) => {
		if (add) {
			renderedGroups.current.push({
				name: group.name,
				rendering: false,
				render: group.render,
			});
		} else {
			const removeIndex = renderedGroups.current.findIndex((x) => x.name === group.name);
			if (removeIndex >= 0) {
				renderedGroups.current.splice(removeIndex, 1);
			}
		}

		const renderingCount = renderedGroups.current.filter((x) => x.rendering).length;
		let newRender = 3 - renderingCount;

		for (let i = 0; i < renderedGroups.current.length; i++) {
			const item = renderedGroups.current[i];
			if (!(newRender > 0)) {
				break;
			}
			if (item.rendering) {
				continue;
			}

			item.rendering = true;
			item.render();
			newRender--;
		}
	}, []);

	return appendToBatchDisplay;
};

export const useDelayedDisplay = (
	groupName: string,
	index: number,
	appendToBatchDisplay: (group: { name: string; render?: () => void }, add: boolean) => void
) => {
	const [display, setDisplay] = useState(!appendToBatchDisplay);
	useEffect(() => {
		let unmounted = false;
		if (appendToBatchDisplay) {
			appendToBatchDisplay(
				{
					name: groupName,
					render: () => {
						setTimeout(
							() => {
								if (!unmounted) {
									setDisplay(true);
								}
							},
							index < 3 ? 0 : 200
						);
					},
				},
				true
			);
		}

		return () => {
			unmounted = true;
			if (!display && appendToBatchDisplay) {
				appendToBatchDisplay(
					{
						name: groupName,
					},
					false
				);
			}
		};
	}, []);

	useEffect(() => {
		if (display && appendToBatchDisplay) {
			appendToBatchDisplay(
				{
					name: groupName,
				},
				false
			);
		}
	}, [display]);

	return display;
};

export const useTripsObservable = (groupTrips: ProcessedTripDto[], tripCardCommonProps: TripCardCommonProps) => {
	const tripsObservable = useMemo(() => new UpdateObservable(), []);

	const shouldUpdateIds = useRef<string[]>([]);
	const tripsRef = useRef<ProcessedTripCard[]>([]);
	const trips = useMemo<ProcessedTripCard[]>(() => {
		const newTrips: ProcessedTripCard[] = [];
		const newShouldUpdateIds: string[] = [];
		groupTrips.forEach((x) => {
			const key = x.isParked ? x.idWithParent + 'opt' : x.idWithParent + 'oht';
			const tripCard = tripsRef.current.find((tr) => tr.key === key);
			if (tripCard) {
				if (tripCard.currentTripTimestamp !== x.updateTimestamp) {
					newShouldUpdateIds.push(x.idWithParent);
				}

				tripCard.trip = x;
				tripCard.currentTripTimestamp = x.updateTimestamp;
				newTrips.push(tripCard);
			} else {
				newTrips.push({
					key: key,
					currentTripTimestamp: x.updateTimestamp,
					trip: x,
				});
			}
		});

		tripsRef.current = newTrips;
		shouldUpdateIds.current = newShouldUpdateIds;
		return newTrips;
	}, [groupTrips]);

	useEffect(() => {
		if (tripsObservable) {
			shouldUpdateIds.current.forEach((id) => {
				tripsObservable.publishTo(id);
			});
		}
	}, [trips]);

	const tripCardCommonPropsRef = useRef(tripCardCommonProps);
	tripCardCommonPropsRef.current = tripCardCommonProps;
	useEffect(() => {
		if (tripsObservable) {
			tripsObservable.publishAll();
		}
	}, [tripCardCommonProps]);

	return { trips, tripsObservable, tripCardCommonPropsRef };
};

export const useUpdateInViewCards = (
	tripId: string,
	tripsObservable?: UpdateObservable,
	emptyInitialRender: boolean = true
) => {
	const [updateTimestamp, setUpdateTimestamp] = useState('');
	const shouldUpdate = useRef(false);
	const isVisible = useRef(false);
	const initialRenderRef = useRef<boolean>(emptyInitialRender);

	const refEl = useRef<HTMLDivElement>();

	useEffect(() => {
		let observer: IntersectionObserver = null;

		if (refEl.current) {
			observer = new IntersectionObserver(
				([entry]) => {
					isVisible.current = entry.isIntersecting;
					if (entry.isIntersecting) {
						if (shouldUpdate.current || initialRenderRef.current) {
							setTimeout(() => {
								if (isVisible.current) {
									shouldUpdate.current = false;
									initialRenderRef.current = false;
									setUpdateTimestamp(new Date().getTime().toString());
								}
							}, 0);
						}
					}
				},
				{
					threshold: 0.01,
				}
			);
			observer.observe(refEl.current);
		}

		return () => {
			observer?.disconnect();
		};
	}, []);

	useEffect(() => {
		let unsubscribe: () => void | null = null;
		if (tripsObservable) {
			unsubscribe = tripsObservable.subscribe(tripId, () => {
				if (isVisible.current) {
					setUpdateTimestamp(new Date().getTime().toString());
				} else {
					shouldUpdate.current = true;
				}
			});
		}

		return () => {
			unsubscribe && unsubscribe();
		};
	}, [tripsObservable]);

	return { initialRender: initialRenderRef.current, refEl };
};

export const useHistoryTripsRefProps = (
	historyTrips: ProcessedTripDto[],
	historyGridTrips: ProcessedTripDto[],
	groupingOptions: HistoryGrouping,
	tripDetails: ProcessedTripDto | null,
	setShowNotificationPrompt: (obj: { callback: () => void }) => void
) => {
	const dispatch = useDispatch();

	const selectedTripsInOverview = useSelector((s: ApplicationState) => s.historyStore.selectedTripsInOverview);
	const dateFilter = useSelector((state: ApplicationState) => state.historyFilter.dateFilter);

	const groupVisibilityCallback = (group: Group, visible: boolean) => {
		const newTrips = [...historyTrips];
		const newgroupedTrips = [...historyGridTrips];
		let childGroups = [group];
		while (childGroups?.length) {
			let newChildGroups: Group[] = [];
			childGroups.forEach((childGroup) => {
				if (childGroup.trips?.length) {
					for (let i = 0; i < newTrips.length; i++) {
						if (childGroup.trips.some((x) => x.idWithParent === newTrips[i].idWithParent)) {
							newTrips[i].visible = visible;
							newTrips[i].updateTimestamp = new Date().getTime().toString();
						}
					}
					for (let i = 0; i < newgroupedTrips.length; i++) {
						if (childGroup.trips.some((x) => x.idWithParent === newgroupedTrips[i].idWithParent)) {
							newgroupedTrips[i].visible = visible;
						}
					}
				}

				if (childGroup.childGroups?.length) {
					newChildGroups = [...newChildGroups, ...childGroup.childGroups];
				}
			});
			childGroups = newChildGroups;
		}

		dispatch(historyStoreActionCreators.setHistoryTrips(newTrips));
		dispatch(historyStoreActionCreators.setHistoryGridTrips(newgroupedTrips));

		//should be dispatched after setHistoryTrips
		dispatch(historyStoreActionCreators.updateNotVisibleTrips(dateFilter));

		//change the array reference
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: [...selectedTripsInOverview.selectedTripsInOverviewIds],
				selectedFromOverview: true,
			},
		});
	};

	const groupVisibilityCallbackRef = useRef<(group: Group, visible: boolean) => void>();
	groupVisibilityCallbackRef.current = groupVisibilityCallback;

	const cardVisibilityChanged = (visible: boolean, trip: ProcessedTripDto) => {
		const newTrips = [...historyTrips];

		for (let i = 0; i < newTrips.length; i++) {
			const item = newTrips[i];
			if (trip.idWithParent === item.idWithParent) {
				item.visible = visible;
				item.updateTimestamp = new Date().getTime().toString();
			}
		}

		const newgroupedTrips = [...historyGridTrips];
		for (let i = 0; i < newgroupedTrips.length; i++) {
			if (trip.idWithParent === newgroupedTrips[i].idWithParent) {
				newgroupedTrips[i].visible = visible;
			}
		}

		dispatch(historyStoreActionCreators.setHistoryTrips(newTrips));
		dispatch(historyStoreActionCreators.setHistoryGridTrips(newgroupedTrips));

		//should be dispatched after setHistoryTrips
		dispatch(historyStoreActionCreators.updateNotVisibleTrips(dateFilter));

		//change the array reference
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: [...selectedTripsInOverview.selectedTripsInOverviewIds],
				selectedFromOverview: true,
			},
		});
	};
	const cardVisibilityChangedRef = useRef<(visible: boolean, trip: ProcessedTripDto) => void>();
	cardVisibilityChangedRef.current = cardVisibilityChanged;

	const selectionCallback = (trip: ProcessedTripDto, controlPressed: boolean) => {
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: SelectionHelper.createNewSelection(
					selectedTripsInOverview.selectedTripsInOverviewIds,
					trip.idWithParent,
					controlPressed
				),
				selectedFromOverview: true,
			},
		});
		if (!controlPressed) {
			dispatch({
				type: SET_DETAILED_TRIPS_ON_MAP,
				payload: [],
			});
			dispatch({
				type: SET_DETAILED_VISITS_ON_MAP,
				payload: [],
			});
			dispatch({
				type: SET_PREVIOUS_DETAILED_VISITS_ON_MAP,
				payload: [],
			});
		}
	};

	const selectionCallbackRef = useRef<(trip: ProcessedTripDto, controlPressed: boolean) => void>();
	selectionCallbackRef.current = selectionCallback;

	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const showTripDetailsCallback = useCallback(
		(tripConfig: ProcessedTripDto) => {
			const showTripDetails = () => {
				dispatch({
					type: SHOW_HISTORY_TRIP_DETAILS,
					payload: tripConfig,
				});
				dispatch({
					type: SET_TRIP_UNSAVED_DATA,
					payload: false,
				});
			};

			if (tripUnsavedData) {
				setShowNotificationPrompt({ callback: showTripDetails });
			} else {
				showTripDetails();
			}
		},
		[tripUnsavedData]
	);

	const historyTripCardPropsRef = useRef<HistoryTripCardPropsRef>();
	historyTripCardPropsRef.current = {
		isSelectedFromOverview: selectedTripsInOverview.selectedFromOverview,
		groupingOptions: groupingOptions,
		tripDetailsId: tripDetails?.idWithParent,
		showTripDetailsCallback: showTripDetailsCallback,
	};

	return { groupVisibilityCallbackRef, cardVisibilityChangedRef, selectionCallbackRef, historyTripCardPropsRef };
};

export const useTripCardCommonProps = () => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);

	const showHistoryLocations = useLocationsHistory();
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const currentPersonId = useSelector((state: ApplicationState) => state.currentSession.personId);

	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const isExpanded = useSelector((s: ApplicationState) => s.resizableEntity.resizableMenuHistoryMap.isExpanded);

	const firstRender = useRef(true);
	const [tripCardCommonProps, setTripCardCommonProps] = useState<TripCardCommonProps>(() => {
		return {
			driverIdentification,
			customerPrivacySettings,
			showHistoryLocations,
			trackTypeSpecification,
			currentPersonId,
			user,
			isExpanded,
		};
	});

	useEffect(() => {
		if (!firstRender.current) {
			setTripCardCommonProps({
				driverIdentification,
				customerPrivacySettings,
				showHistoryLocations,
				trackTypeSpecification,
				currentPersonId,
				user,
				isExpanded,
			});
		}
		firstRender.current = false;
	}, [
		driverIdentification,
		customerPrivacySettings,
		showHistoryLocations,
		trackTypeSpecification,
		currentPersonId,
		user,
		isExpanded,
	]);

	return tripCardCommonProps;
};
