import TripNumberMarker from 'components/LiveMap/TripNumberMarker';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { InfoBox } from '@react-google-maps/api';

import { ApplicationState } from '../../../store';
import { extendObjectNameWithTimeZoneShortFormat } from '../../ObjectNameWithTimeZoneOffset/Utils';
import { HistoryInfoWindow } from '../TripInfoWindows';
import { RietveldNonTripProps } from './types';

const RietveldNonTrip = (props: RietveldNonTripProps): JSX.Element => {
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	useEffect(() => {
		if (props.renderForTableView && props.map) {
			props.map.setCenter(new window.google.maps.LatLng(props.trip.start.lat, props.trip.start.lng));
			props.map.setZoom(18);
		}
	}, [props.map]);

	return (
		<>
			<TripNumberMarker
				key={`trip-number-${props.trip.idWithParent}`}
				trip={props.trip}
				highlight={props.highlight}
				tripCenterPosition={props.trip.start}
				renderForTableView={props.renderForTableView}
			/>
			{props.showTripDetailsOnMap ? (
				<InfoBox
					key={`info-${props.trip.id}-history`}
					position={props.trip.start}
					options={{
						pixelOffset: props.offset,
						zIndex: 1000, //always on top
						closeBoxMargin: '0px',
						closeBoxURL: '',
						disableAutoPan: true,
					}}
				>
					<HistoryInfoWindow
						trip={{
							...props.trip,
							objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneShortFormat(
								props.trip.objectName,
								props.trip.timeZoneMinutesOffset,
								customerTimezone !== props.trip.timezoneId
							),
						}}
					/>
				</InfoBox>
			) : null}
		</>
	);
};

RietveldNonTrip.defaultProps = {
	highlight: false,
};

export default RietveldNonTrip;
