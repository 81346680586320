import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import DevicesLogsColumn from 'components/GridOverview/Columns/DevicesLogsColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';
import CustomerLevelEnum from "../../models/CustomerLevelEnum";

interface Props {
	history: History<LocationState>;
}

interface DeviceOverviewDto extends GridEntity {
	code: string;
	customer: string;
	customerId: string;
	deviceType: boolean;
	serialNumber: string;
	isCanFmsActive: boolean;
	simcardImsi: string;
	phoneNumber: string;
	objectName: string;
	licensePlate: string;
	timeZone: string;
	active: boolean;
	simcardId: string;
	objectId: string;
	canViewObject: boolean;
}

const fieldNames = {
	customer: 'customer',
	code: 'code',
	deviceType: 'deviceType',
	serialNumber: 'serialNumber',
	simcardImsi: 'simcardImsi',
	phoneNumber: 'phoneNumber',
	objectName: 'objectName',
	licensePlate: 'licensePlate',
	timeZone: 'timeZone',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customer]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.deviceType]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.serialNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.simcardImsi]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.phoneNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.objectName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.licensePlate]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const DeviceOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const currentCustomerLevel = useSelector((s: ApplicationState) => s.currentSession.customerLevel);
	const columns = [
		TextColumn({
			fieldName: fieldNames.customer,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (rowData: DeviceOverviewDto) => (
				<Link title={rowData.customer} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customer}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.deviceType,
			header: TranslateText('maintenanceOverview.grid.colDeviceType'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.serialNumber,
			header: TranslateText('maintenanceOverview.grid.colSerialNumber'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.simcardImsi,
			header: TranslateText('maintenanceOverview.grid.colSimCardIMSI'),
			sortable: true,
			filterable: true,
			body: (rowData: DeviceOverviewDto) => (
				<Link title={rowData.simcardImsi} to={`${GlobalSettings.route.simcards}/${rowData.simcardId}`}>
					{rowData.simcardImsi}
				</Link>
			),
			shown: ClaimUtil.validateHasClaim(user, ClaimType.SimCards),
		}),
		TextColumn({
			fieldName: fieldNames.phoneNumber,
			header: TranslateText('maintenanceOverview.grid.colSimCardPhoneNumber'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.SimCards),
		}),
		TextColumn({
			fieldName: fieldNames.objectName,
			header: TranslateText('maintenanceOverview.grid.colObjectName'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
			body: (rowData: DeviceOverviewDto) =>
				rowData.canViewObject ? (
					<Link title={rowData.objectName} to={`${GlobalSettings.route.objects}/${rowData.objectId}`}>
						{rowData.objectName}
					</Link>
				) : (
					rowData.objectName
				),
		}),
		TextColumn({
			fieldName: fieldNames.licensePlate,
			header: TranslateText('maintenanceOverview.grid.colLicensePlate'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
		}),
		TextColumn({
			fieldName: fieldNames.timeZone,
			header: TranslateText('maintenanceOverview.grid.colTimeZone'),
			sortable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
		}),
		DevicesLogsColumn({ entity: 'device' }),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];
	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.devicesMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.devices')}
			disableAddButton={false}
			showAddConfigClaim={[
				{
					claim: ClaimType.Devices,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Device}
			disableImportButton={currentCustomerLevel !== CustomerLevelEnum.Default}
			disableExportButton={currentCustomerLevel !== CustomerLevelEnum.Default}
		/>
	);
};

export default DeviceOverview;
