import ClaimType from 'authorization/ClaimType';
import { extendObjectNameWithTimeZoneLongFormat } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { TripResponse } from 'components/RightSidebarMenu/HistorySideBar';
import { tripHistorySorting } from 'components/RightSidebarMenu/HistoryTripsUtils';
import EntityTypeEnum from 'models/EntityTypeEnum';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import TripTypesEnum from 'models/TripTypesEnum';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ProcessedTripDto } from 'store/HistoryStore';
import ajaxUtil from 'utils/Ajax';
import { queryClientKeys } from 'utils/queryClientKeys';

import GlobalSettings from '../../../../../GlobalSettings.json';
import { HistoryTabData } from './types';

export const useGetHistoryTrips = (isHidden: boolean, tripId: string, tripIsPerson: boolean) => {
	//load trips only when the tab become visible for the first time
	const [loadUserTrips, setLoadUserTrips] = useState<boolean>(false);
	useEffect(() => {
		if (!isHidden && !loadUserTrips) {
			setLoadUserTrips(true);
		}
	}, [isHidden]);

	const globalCustomer = useSelector((state: ApplicationState) => state.globalCustomer);
	const customer = useSelector((state: ApplicationState) => state.currentSession.customer);
	const customerId = useSelector((state: ApplicationState) => state.currentSession.customerId);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const isDriver = useSelector((state: ApplicationState) => state.currentSession.isDriver);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const { data, isLoading, isFetching, isFetched } = useQuery(
		[queryClientKeys.TODAY_HISTORY_TRIPS, tripId],
		() => {
			let tripType: TripTypesEnum = TripTypesEnum.Business | TripTypesEnum.Commuting | TripTypesEnum.Private;
			const tempCustomerFeatures =
				globalCustomer?.filteredCustomer?.featuresSettings || customer.featuresSettings;
			if (tempCustomerFeatures?.trackTypeSpecification) {
				tripType = tempCustomerFeatures?.tripTypes;
			}
			if (user?.profile[ClaimType.ShowPrivateTrips] || isDriver) {
				tripType |= TripTypesEnum.Private;
			} else if (
				tempCustomerFeatures?.privacySettings === PrivacySettingsEnum.ShowPrivateData &&
				!(tempCustomerFeatures.tripTypes & TripTypesEnum.Private)
			) {
				tripType &= ~TripTypesEnum.Private;
			}

			return ajaxUtil
				.post<TripResponse>(`${GlobalSettings.tripsApi}/get/?customerId=${customerId}`, {
					selectedEntities: [
						{
							id: tripId,
							type: tripIsPerson
								? EntityTypeEnum[EntityTypeEnum.Person]
								: EntityTypeEnum[EntityTypeEnum.Object],
						},
					],
					dateFilter: {
						from: moment(new Date())
							.startOf('days')
							.format('YYYY-MM-DDTHH:mm:ss'),
						to: moment(new Date())
							.endOf('days')
							.format('YYYY-MM-DDTHH:mm:ss'),
					},
					trackType: tripType,
				})
				.then(
					(response): HistoryTabData => {
						let totalDistance = 0;
						let totalTime = 0;
						let hasNonTrip = false;
						let hasNormalTrip = false;
						response.trips.forEach((trip) => {
							if (trip.isNonTrip) {
								hasNonTrip = true;
							} else if (!trip.isParked) {
								hasNormalTrip = true;
								totalDistance += trip.totalMileage;
								totalTime += trip.tripDuration;
							}
						});
						if (response.unGroupedTrips.length) {
							hasNormalTrip = true;
						}

						const tripRows = [
							...response.unGroupedTrips,
							...response.trips.sort(tripHistorySorting.sortDescForDateGrouping),
						];

						tripRows.forEach((trip: ProcessedTripDto) => {
							trip.objectNameWithTimeZoneOffset = extendObjectNameWithTimeZoneLongFormat(
								trip.objectName,
								trip.timeZoneMinutesOffset,
								customerTimezone !== trip.timezoneId
							);
						});

						return {
							tripRows,
							tripsSummary: { totalTime, totalDistance, hasNonTrip, hasNormalTrip },
						};
					}
				);
		},
		{
			enabled: loadUserTrips,
			cacheTime: 0,
			initialData: {
				tripRows: [],
				tripsSummary: {
					totalDistance: 0,
					totalTime: 0,
					hasNonTrip: false,
					hasNormalTrip: false,
				},
			},
		}
	);

	return { tripHistory: data, loading: isLoading || isFetching, isFetched };
};
