import '../ExtendedMenu.scss';

import ClaimType from 'authorization/ClaimType';
import EasyTrackFeature from 'authorization/EasyTrackFeature';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { liveMapActionCreators } from 'store/LiveMap';
import { SET_LIVE_DETAILS_TABS_SELECTION } from 'store/RightSideBar';

import { Box } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import GoogleMaterialIconOutlined from '../../../Common/Icons/GoogleMaterialIconOutlined';
import { HistoryTab } from '../Tabs/HistoryTab';
import { OverallTab } from '../Tabs/OverallTab';
import { LiveDetailsTabsHeader } from './LiveDetailsTabsHeader';
import { LiveDetailsTabsEnum } from './types';

const LiveDetailsTabs = (): JSX.Element => {
	const selectedTab = useSelector((s: ApplicationState) => s.rightSideBar.liveDetailsOption);
	const [availableTabs, setAvailableTabs] = useState<LiveDetailsTabsEnum[]>([]);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const tripDetails = useSelector((s: ApplicationState) => s.liveMap.tripDetails);

	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	const liveEntities = useSelector((state: ApplicationState) => state.liveData.liveEntities);
	const dispatch = useDispatch();

	const renderRightSideBarAvailableTabs = (availableTabs: LiveDetailsTabsEnum[]) =>
		availableTabs.map((tab: LiveDetailsTabsEnum) => {
			switch (tab) {
				case LiveDetailsTabsEnum.Overall:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'location_on'} />}
						/>
					);

				case LiveDetailsTabsEnum.History:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'query_builder'} />}
						/>
					);

				default:
					return;
			}
		});

	const renderTabs = (availableTabs: LiveDetailsTabsEnum[]) =>
		availableTabs.map((tab: LiveDetailsTabsEnum) => {
			switch (tab) {
				case LiveDetailsTabsEnum.Overall:
					return (
						<OverallTab
							key={'tab-header-' + tab}
							hidden={selectedTab !== tab}
							goToHistoryTab={() =>
								dispatch({
									type: SET_LIVE_DETAILS_TABS_SELECTION,
									payload: LiveDetailsTabsEnum.History,
								})
							}
						/>
					);
				case LiveDetailsTabsEnum.Time:
				case LiveDetailsTabsEnum.Fuel:
				case LiveDetailsTabsEnum.Settings:
				case LiveDetailsTabsEnum.Messages:
					return (
						<div key={'tab-header-' + tab} hidden={selectedTab !== tab}>
							<div />
						</div>
					);
				case LiveDetailsTabsEnum.History:
					return (
						<HistoryTab key={'tab-content-' + tab} hidden={selectedTab !== tab} tripData={tripDetails} />
					);
			}
		});

	useEffect(() => {
		const tabs: LiveDetailsTabsEnum[] = [LiveDetailsTabsEnum.Overall];
		if (
			currentSession?.customer?.featuresSettings &&
			currentSession?.customer?.featuresSettings[EasyTrackFeature.History] &&
			user?.profile[ClaimType.History]
		) {
			tabs.push(LiveDetailsTabsEnum.History);
		}
		tabs.push(
			...[
				LiveDetailsTabsEnum.Time,
				LiveDetailsTabsEnum.Fuel,
				LiveDetailsTabsEnum.Settings,
				LiveDetailsTabsEnum.Messages,
			]
		);
		setAvailableTabs(tabs);
	}, [user, currentSession]);

	useEffect(() => {
		if (!liveEntities.length || !liveEntities.some((le) => le.entityId === tripDetails?.id)) {
			dispatch(liveMapActionCreators.toggleTripDetails(null));
		}
	}, [liveEntities]);

	if (!tripDetails || Object.keys(tripDetails).length === 0) {
		return null;
	}

	return (
		<Box className="plus-details-container">
			<LiveDetailsTabsHeader
				tripDetails={tripDetails}
				onClosedButtonClicked={() => dispatch(liveMapActionCreators.toggleTripDetails(null))}
			/>

			<Tabs
				className="right-sidebar-tabs"
				value={selectedTab}
				onChange={(event: any, newValue: number) => {
					dispatch({
						type: SET_LIVE_DETAILS_TABS_SELECTION,
						payload: newValue,
					});
				}}
			>
				{renderRightSideBarAvailableTabs(availableTabs)}
			</Tabs>

			{renderTabs(availableTabs)}
		</Box>
	);
};
export default LiveDetailsTabs;
