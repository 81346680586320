import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../store';
import { commonDataActionCreators } from '../../../store/CommonData';
import MaterialAutocomplete from '../Autocomplete/MaterialAutocomplete';

interface Props {
	valueId?: string;
	name?: string;
	label?: string;
	disabled?: boolean;
	errorMsgContainerId?: string;
	className?: string;
	onChange: (autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => void;
}

const CountrySelection = (props: Props) => {
	const countriesFetched = useSelector((state: ApplicationState) => state.commonData.countriesFetched);
	const countriesDataSource = useSelector((state: ApplicationState) => state.commonData.countriesDataSource);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!countriesFetched) {
			dispatch(commonDataActionCreators.fetchCountries());
		}
	}, []);

	return <MaterialAutocomplete {...props} dataSource={countriesDataSource} />;
};

export default CountrySelection;
