import IdIdentifiable from './IdIdentifiable';
import TemplateEventActionEnum from './TemplateEventActionEnum';
import TemplateState from './TemplateState';
import TemplateTypesEnum from './TemplateTypesEnum';
import {TranslateText} from '../utils/Translations';
import TemplateUnitOfMeasureEnum from "./TemplateUnitOfMeasureEnum";

class Template extends IdIdentifiable {
	constructor(
		customerId?: string,
		type?: TemplateTypesEnum,
		eventAction?: TemplateEventActionEnum,
		states?: TemplateState[]
	) {
		super();
		this.customerId = customerId;
		this.templateType = type;
		this.eventAction = eventAction;
		this.templateAnalogStates = states;
		this.code = '';
		this.name = '';
		this.description = '';
		this.relevantValue = false;

		this.countRelevantState = false;
		this.durationCounter = true;
		this.showOnMap = false;
		this.relevantStateName = TranslateText('common.on');
		this.relevantIconName = 'sensor-templates/toggle-on';
		this.nonRelevantStateName = TranslateText('common.off');
		this.nonRelevantIconName = 'sensor-templates/toggle-off';
		this.rank = 0;
		this.maxValue = null;
		this.minValue = null;
		this.unitOfMeasure = TemplateUnitOfMeasureEnum.Percentage;
		this.numberOfStates = 3;
	}

	code: string;
	customerId: string;
	active = true;
	name: string;
	description: string;
	membersCount: string;
	rank: number;
	templateType: TemplateTypesEnum;

	// Multiple Templates
	relevantValue: boolean;
	tripColor: string;
	graphColor: string;

	// Event Template
	eventAction: TemplateEventActionEnum;
	labelColor: string;

	// Digital Template
	countRelevantState: boolean;
	durationCounter: boolean;
	showOnMap: boolean;
	relevantStateName: string;
	relevantLabelColor: string;
	relevantIconName: string;
	nonRelevantStateName: string;
	nonRelevantLabelColor: string;
	nonRelevantIconName: string;

	//Analog Template
	maxValue: number;
	minValue: number;
	unitOfMeasure: TemplateUnitOfMeasureEnum;
	numberOfStates: number;
	templateAnalogStates: TemplateState[];
}

export default Template;
