import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import { User } from 'oidc-client';

import ClaimType from '../../authorization/ClaimType';
import { ButtonConfig } from '../../components/RadioButtonContainer/RadioButtonContainer';
import { FleetEntityTypeFilter } from '../../store/FleetSelection';
import { TranslateText } from '../Translations';

const TreeConfigurationUtil = {
	getButtonConfig: (filterButtons?: FleetEntityTypeFilter[]): ButtonConfig[] => {
		const buttons: ButtonConfig[] = [];
		if (filterButtons) {
			filterButtons.forEach((button) => {
				switch (button) {
					case FleetEntityTypeFilter.all:
						buttons.push({
							title: TranslateText('fleetSelection.all'),
							id: FleetEntityTypeFilter.all,
						});
						break;
					case FleetEntityTypeFilter.driver:
						buttons.push({
							title: TranslateText('fleetSelection.drivers'),
							id: FleetEntityTypeFilter.driver,
						});
						break;
					case FleetEntityTypeFilter.person:
						buttons.push({
							title: TranslateText('fleetSelection.persons'),
							id: FleetEntityTypeFilter.person,
						});
						break;
					case FleetEntityTypeFilter.object:
						buttons.push({
							title: TranslateText('fleetSelection.objects'),
							id: FleetEntityTypeFilter.object,
						});
						break;
					case FleetEntityTypeFilter.asset:
						buttons.push({
							title: TranslateText('fleetSelection.assets'),
							id: FleetEntityTypeFilter.asset,
						});
						break;

					default:
						break;
				}
			});
		}
		return buttons;
	},

	getGroupMembersButtons: (driverIdentification: boolean, assetTracking: boolean, user: User): ButtonConfig[] => {
		const buttons: ButtonConfig[] = [];

		if (user.profile[ClaimType.Objects]) {
			buttons.push({
				title: TranslateText('fleetSelection.objects'),
				id: FleetEntityTypeFilter.object,
			});
		}
		if (assetTracking) {
			buttons.push({
				title: TranslateText('fleetSelection.assets'),
				id: FleetEntityTypeFilter.asset,
			});
		}
		if ((user.profile[ClaimType.Drivers] || user.profile[ClaimType.Persons]) && driverIdentification) {
			buttons.push({
				title: TranslateText('fleetSelection.drivers'),
				id: FleetEntityTypeFilter.driver,
			});
		}
		if (buttons.length > 1) {
			buttons.splice(0, 0, {
				title: TranslateText('fleetSelection.all'),
				id: FleetEntityTypeFilter.all,
			});
		}

		return buttons;
	},

	getObjectFunctionFilter: (
		selectedFilter: FleetEntityTypeFilter,
		assetTrackingFeatures: boolean
	): ObjectFunctionFilter => {
		let objectFunctionFilter = ObjectFunctionFilter.None;
		if (selectedFilter === FleetEntityTypeFilter.all) {
			objectFunctionFilter = assetTrackingFeatures ? ObjectFunctionFilter.All : ObjectFunctionFilter.Full;
		} else if (selectedFilter === FleetEntityTypeFilter.object) {
			objectFunctionFilter = ObjectFunctionFilter.Full;
		} else if (selectedFilter === FleetEntityTypeFilter.asset && assetTrackingFeatures) {
			objectFunctionFilter = ObjectFunctionFilter.Asset;
		}

		return objectFunctionFilter;
	},

	getShowObjects: (selectedFilter: FleetEntityTypeFilter): boolean => {
		return (
			selectedFilter === FleetEntityTypeFilter.all ||
			selectedFilter === FleetEntityTypeFilter.object ||
			selectedFilter === FleetEntityTypeFilter.asset
		);
	},

	getShowPersons: (selectedFilter: FleetEntityTypeFilter, driverIdentification: boolean): boolean => {
		return driverIdentification
			? selectedFilter === FleetEntityTypeFilter.all ||
					selectedFilter === FleetEntityTypeFilter.driver ||
					selectedFilter === FleetEntityTypeFilter.person
			: false;
	},
};

export default TreeConfigurationUtil;
