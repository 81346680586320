import './styles.scss';

import React from 'react';

import { AuditTripFieldsEnum, TripAuditChangesEnum } from '../../../../../../../store/HistoryStore';
import { TranslateText } from '../../../../../../../utils/Translations';
import { TripAudit } from '../../types';

interface Props {
	tripAudit: TripAudit;
	toolTipsMessage?: string;
}

const LastChangeDetails = (props: Props) => {
	const lastTripChangeDetails: {
		created: { field: AuditTripFieldsEnum; newValue: string; oldValue: string }[];
		updated: { field: AuditTripFieldsEnum; newValue: string; oldValue: string }[];
		lastChangedAction: TripAuditChangesEnum;
	} = {
		lastChangedAction: props.tripAudit?.lastChangedAction,
		created: props.tripAudit?.lastChangedValues?.filter(
			(lcv: { field: AuditTripFieldsEnum; newValue: string; oldValue: string }) =>
				lcv.newValue !== null && (lcv.oldValue === null || !lcv?.oldValue?.length)
		),
		updated: props.tripAudit?.lastChangedValues?.filter(
			(lcv: { field: AuditTripFieldsEnum; newValue: string; oldValue: string }) =>
				lcv.oldValue !== null && lcv?.oldValue?.length
		),
	};
	return (
		lastTripChangeDetails && (
			<div>
				{lastTripChangeDetails.lastChangedAction === TripAuditChangesEnum.Update ||
				lastTripChangeDetails.lastChangedAction === TripAuditChangesEnum.ReprocessUpdate ? (
					<div className={'last-change-details-toolTip'}>
						<div className={'last-change-details-area'}>
							{lastTripChangeDetails?.created?.map((c, index) => (
								<span
									key={index}
									className={`changed-row ${
										index !== lastTripChangeDetails?.created?.length - 1 ||
										!!lastTripChangeDetails?.updated?.length
											? 'with-border'
											: ''
									}`}
								>
									<span className={'property-field'}>
										{TranslateText(`tripDetailsSection.${c.field}`) + ' '}
									</span>
									<span className={'property-name'}>{TranslateText('added')}</span>
								</span>
							))}
						</div>
						<div className={'last-change-details-area'}>
							{lastTripChangeDetails?.updated?.map((u, index) => (
								<span
									key={index}
									className={`changed-row ${
										index !== lastTripChangeDetails?.updated?.length - 1 ? 'with-border' : ''
									}`}
								>
									<span className={'property-field'}>
										{TranslateText(`tripDetailsSection.${u.field}`) +
											' updated ' +
											TranslateText('from') +
											':'}
									</span>
									<span className={'property-name'}>
										{u.field.toString() === 'TrackType' || u.field.toString() === 'HasDetour'
											? TranslateText(`tripDetailsSection.${u.oldValue.toString().toLowerCase()}`)
											: u.oldValue}
									</span>
								</span>
							))}
						</div>
					</div>
				) : (
					<span>
						{props.toolTipsMessage
							? props.toolTipsMessage
							: lastTripChangeDetails.lastChangedAction === TripAuditChangesEnum.ReprocessCreate
							? TranslateText('tripDetailsSection.tripReprocessed')
							: TranslateText('tripDetailsSection.tripCreated')}
					</span>
				)}
			</div>
		)
	);
};

export default LastChangeDetails;
