import './buttonWidget.scss';

import React, { useEffect, useState } from 'react';

import { ClaimConfig } from '../../../authorization/ClaimUtil';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import WidgetHeader from '../../BaseWidget/WidgetHeader';
import { DialogUtil } from '../../Common/NotificationDialog/NotificationDialog';

//extend with custom props
type Props = {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	url?: string;
	exitEditModeOnUrlChange: boolean;
	widgetTitle: string;
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	changeDataCallback?: (hasChanges: boolean) => void;
	dataChangedEvent?: (hasChanges: boolean) => void;
	viewComponent: React.FC<IButtonWidgetView>;
	buttons: React.FC<IActionButton>[];
	editClaimConfig?: ClaimConfig[];
	optionalEditClaimConfig?: ClaimConfig[];
	showEditButton: boolean;
	className?: string;
	hidden?: boolean;
	getDataCallback?: () => any;
};

export interface IActionButton {
	entityId: string;
	currentSelection: any[];
	actionExecuted: (exitEditMode: boolean) => void;
	changeDataCallback?: (data: object | null, hasChanges: boolean) => void;
	editModeCallback?: (editMode: boolean) => void;
}

export interface IButtonWidgetView {
	entityId: string;
	editMode: boolean;
	allowEditMode?: boolean;
	data: any[];
	setUrl?: (url: string) => void; //This will set the url for data retrieval
	changeDataCallback?: (data: object | null, valid: boolean) => void; //This will inform the widget that that the view is dirty
	reloadDataCallback?: () => void; //This is needed when the view changes the data and it needs to be refreshed
	editModeCallback?: (editMode: boolean) => void; //This will inform the widget that the view is in edit mode
	selectionCallback?: (data: JSON) => void; //This sill inform the widget about the context data that should be passed to the buttons
	getDataCallback?: () => any;
}

const ButtonWidget = (props: Props) => {
	const [url, setUrl] = useState<string | null>(() => {
		if (props.url) {
			return `${props.url}/${props.entityId}`;
		}
		return null;
	});
	const [data, setData] = useState([]);
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
		viewEditMode: false,
	});

	useEffect(() => {
		if (props.url) {
			setUrl(`${props.url}/${props.entityId}`);
		}
	}, [props.url]);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		util.fetchData();
		if (props.exitEditModeOnUrlChange) {
			util.setEditMode(false);
		}
	}, [props.entityId, url]);

	useEffect(() => {
		props.changeDataCallback(flags.hasChanges);
	}, [flags.hasChanges]);

	const util = {
		fetchData: async () => {
			if (url) {
				setData(await ajaxUtil.get<[]>(url));
			}
		},
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode });
		},
		confirm: async () => {
			return await DialogUtil.confirm({
				title: TranslateText('common.titleUnsavedData'),
				content: TranslateText('notificationMessages.cancel'),
			});
		},
	};

	const events = {
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.confirm()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const editMode = !flags.editMode;
			if (!editMode && flags.hasChanges) {
				if (await util.confirm()) {
					util.setEditMode(editMode);
				}
			} else {
				util.setEditMode(editMode);
			}
		},
		changeDataCallback: (data: object | null, hasChanges: boolean) => {
			if (props.dataChangedEvent) {
				props.dataChangedEvent(hasChanges);
			}
			setFlags({ ...flags, hasChanges: hasChanges });
		},
		setDataCallback: (data: any[], exitEditMode: boolean) => {
			setData(data);
			if (exitEditMode) {
				util.setEditMode(false);
			}
		},
		editModeCallback: (editMode: boolean) => {
			setFlags({
				...flags,
				viewEditMode: editMode,
			});
		},
		actionExecuted: (exitEditMode: boolean) => {
			util.fetchData();
			if (exitEditMode) {
				util.setEditMode(false);
			}
		},
	};

	return (
		<div
			className={`e-panel widget button-widget ${flags.visible ? '' : 'hidden'} ${
				props.className ? props.className : ''
			}`}
			data-row={props.position.row}
			data-col={props.position.col}
			data-sizex={props.position.sizeX}
			data-sizey={props.position.sizeY}
			data-minsizex={props.position.sizeX}
			data-minsizey={props.position.sizeY}
			hidden={props.hidden}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editClaimConfig={props.editClaimConfig}
					optionalEditClaimConfig={props.optionalEditClaimConfig}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>

				<div className={'widget-view'}>
					<props.viewComponent
						editMode={flags.editMode}
						allowEditMode={props.allowEditMode || flags.editMode}
						changeDataCallback={events.changeDataCallback}
						entityId={props.entityId}
						editModeCallback={events.editModeCallback}
						reloadDataCallback={util.fetchData}
						data={data}
						setUrl={setUrl}
						getDataCallback={props.getDataCallback}
					/>
				</div>
				{flags.editMode ? (
					<div className={`buttons-host ${props.buttons.length === 0 ? 'hidden' : ''}`}>
						{props.buttons.map((ActionButton, index) => {
							return (
								<ActionButton
									key={index}
									entityId={props.entityId}
									actionExecuted={events.actionExecuted}
									currentSelection={data}
									changeDataCallback={events.changeDataCallback}
									editModeCallback={events.toggleEdit}
								/>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};

ButtonWidget.defaultProps = {
	showEditButton: true,
	exitEditModeOnUrlChange: true,
};

export default ButtonWidget;
