import './styles.scss';

import { ReactComponent as DrivingSvgImage } from 'assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as TripStartIcon } from 'assets/icons/HistoryTripStart.svg';
import { ReactComponent as TripStopIcon } from 'assets/icons/HistoryTripStop.svg';
import { ReactComponent as HourglassBottomImage } from 'assets/icons/HourglassBottom.svg';
import { ReactComponent as IdleSvgImage } from 'assets/icons/Idle.svg';
import { intervalDifferenceTime } from 'components/Common/DateTimeUtilFunctions';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import TripTypesEnum from 'models/TripTypesEnum';
import React from 'react';
import { useSelector } from 'react-redux';
import DateTimeUtil from 'shared/datetime/DateTimeUtil';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { GetHourAndMinutes } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { Speed } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';

import { Tooltip } from '@material-ui/core';
import { TripInfoWindowSubtitle } from '../TripInfoWindowSubtitle';
import { HistoryInfoWindowProps } from './types';
import ObjectNameWithTimeZoneOffset from '../../../ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import { secondsToIntervalStringVisitTime } from '../../../Common/DateTimeUtilFunctions';

const HistoryInfoWindow = ({ trip }: HistoryInfoWindowProps): JSX.Element => {
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const showHistoryLocations = useLocationsHistory();

	const { icon } = useTryGetSvgIconFromQueryCache(trip.objectIcon);

	const getTripType = (trackType: TripTypesEnum): string => {
		return TranslateText('historyOverview.' + TripTypesEnum[trackType]?.toLowerCase()).toUpperCase();
	};

	return (
		<div className="history-card-dialog">
			<div className="header">
				<div className="text">
					<div style={!trip?.personName?.length ? { width: '278px' } : { width: '139px' }} className="title">
						{trip?.isPerson ? (
							<PersonIcon className="person-object-icon" />
						) : (
							<SvgIcon icon={icon.content} color="#FFFFFF" size={14} onClick={(icon: string) => {}} />
						)}
						<div className={'person-name'}>
							{trip.isPerson && driverIdentification ? (
								<Tooltip title={trip.personName}>
									<span>{trip.personName}</span>
								</Tooltip>
							) : (
								<ObjectNameWithTimeZoneOffset
									useToolTip
									objectNameWithTimeZone={trip.objectNameWithTimeZoneOffset}
								/>
							)}
						</div>
					</div>
					{trip.isPerson ? (
						<TripInfoWindowSubtitle
							isObject
							icon={
								<SvgIcon icon={icon.content} color="#FFFFFF" size={14} onClick={(icon: string) => {}} />
							}
							name={trip?.objectNameWithTimeZoneOffset}
						/>
					) : driverIdentification && trip.personName ? (
						<TripInfoWindowSubtitle
							isObject={false}
							icon={<PersonIcon className="person-object-icon" />}
							name={trip?.personName}
						/>
					) : null}
				</div>
			</div>
			<div className="content">
				<div className="history-dialog-time-location">
					{!trip.isNonTrip ? (
						<>
							<div className="time-location-dialog-row firs-row">
								<TripStartIcon className="trips-start-icon" />
								<span className="date-span">{GetHourAndMinutes(trip.startTrip, true)}</span>
								<span className="address-span">
									{showHistoryLocations && trip.startLocationName?.length
										? trip.startLocationName
										: trip.startAddress}
								</span>
							</div>
							<div className="time-location-dialog-row">
								{trip.isInProgress ? (
									<div className="direction-icon-container">
										<DrivingSvgImage
											className={`direction-icon direction-${trip.direction?.toLowerCase()}`}
										/>
									</div>
								) : (
									<TripStopIcon className="trips-stop-icon" />
								)}
								<span className="date-span">{GetHourAndMinutes(trip.endTrip, true)}</span>
								<span className="address-span">
									{showHistoryLocations && trip.endLocationName?.length
										? trip.endLocationName
										: trip.endAddress}
								</span>
							</div>
						</>
					) : (
						<div className="time-location-dialog-row firs-row">
							<TripStopIcon className="non-trips-address-icon" />
							<span className="address-span">
								{showHistoryLocations && trip.startLocationName?.length
									? trip.startLocationName
									: trip.startAddress}
							</span>
						</div>
					)}
				</div>

				{!trip.isNonTrip ? (
					<div className="dialog-row">
						<span>
							<HourglassBottomImage className="hour-glass-icon" />
							{DateTimeUtil.calculateTimePassed(trip.tripDuration, new Date(), false)}
						</span>
						<span>
							<PinDropOutlinedIcon className="pin-icon" />
							{`${trip.totalMileage.toFixed(1)} Km`}
						</span>
						<span>
							<Speed className="speed-icon" />
							{`${trip.maxSpeed} Km/h`}
						</span>
						<div style={{ fontWeight: 'bold' }}>
							{trackTypeSpecification ? getTripType(trip.trackType) : null}
						</div>
					</div>
				) : (
					<div className="dialog-row visittime-dialog-row">
						<span>
							<IdleSvgImage className="visit-icon" />
							{secondsToIntervalStringVisitTime(trip.visitTime)}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default HistoryInfoWindow;
