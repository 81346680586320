import './styles.scss';
import '../../../../../Common/RemoveSpinnerOptionInput.scss'

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { GetTripType } from 'components/RightSidebarMenu/HistoryTripsUtils';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import MileageCorrectionStateEnum from 'models/MileageCorrectionStateEnum';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import TripTypesEnum from 'models/TripTypesEnum';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import DateTimeUtil from 'shared/datetime/DateTimeUtil';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import { FieldRules, ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import {
	historyStoreActionCreators,
	ProcessedTripDto,
	SET_TRIP_UNSAVED_DATA,
	TRIP_WITH_UPDATED_MILEAGE_CORRECTION,
} from 'store/HistoryStore';
import {LatLng, SET_MAP_LOADING} from 'store/LiveMap';
import ajaxUtil from 'utils/Ajax';
import { FormatDate } from 'utils/DateUtils';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import Cached from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { handleKeyDown} from '../../../../../Common/RemoveSpinnerOptionInput';
import { useTripFullTimeZoneInfo, useTripsNavigation } from '../hooks';
import { SummaryTabFooter } from '../SummaryTabFooter';
import { EditableTripDto, TripAudit } from '../types';
import { Add, EditOutlined } from '@material-ui/icons';
import LocationDialog from "../../../../../LocationPopup";
import {
	adjustDateForDialog,
	adjustDateToUtcForDialog,
	LocationDialogDataDto
} from "../../../../../LocationPopup/LocationDialog";

interface Props {
	hidden: boolean;
	tripDetails: ProcessedTripDto;
	renderForTable?: boolean;
	nextCallback?: (saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean) => void;
	previousCallback?: (saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean) => void;
	unsavedDataCallback?: (tripHasUnsavedData: boolean) => void;
}

interface EditableTrip {
	selectedDriverId: string;
	detourMileage: number;
	tripReference: string;
	tripDescription: string;
	detourReference: string;
	detourDescription: string;
	selectedTripType: TripTypesEnum;
	selectedDetourType: TripTypesEnum;
	selectedDetour: boolean;
	tripHasPendingEdits: boolean;
}

interface EditableTripDataSources {
	driversDataSource: AutocompleteItem[];
	tripTypeDataSource: AutocompleteItem[];
	detourDataSource: AutocompleteItem[];
}

export const privateDataMask = '****';

const  SummaryTabTrip = ({
	hidden,
	tripDetails,
	renderForTable,
	nextCallback,
	previousCallback,
	unsavedDataCallback,
}: Props) => {
	const dispatch = useDispatch();

	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const customerTripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const currentCustomerTripTypes = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.tripTypes
	);
	const historyTrips = useSelector((s: ApplicationState) => s.historyStore.historyTrips);
	const historyGridTrips = useSelector((s: ApplicationState) => s.historyStore.historyGridTrips);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const isDriver = useSelector((state: ApplicationState) => state.currentSession.isDriver);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const isFetchingTripsData = useSelector((state: ApplicationState) => state.liveMap.loading);
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const [defaultTripType, setDefaultTripType] = useState<TripTypesEnum>(TripTypesEnum.None);
	const [fieldsEnabled, setFieldsEnabled] = useState<boolean>(false);
	const [formValidator, setFormValidator] = useState(null as Validator);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [formIsValid, setFormIsValid] = useState<boolean>(false);
	const [mileageCorrectionStatus, setMileageCorrectionStatus] = useState<MileageCorrectionStateEnum>();
	const [locationDialogData, setLocationDialogData] = useState<LocationDialogDataDto>({
		display: false,
		isForAdd: false,
		selectedLocationId: null,
		latLng: null,
		startDate: null,
	});

	const [editableTripDataSources, setEditableTripDataSources] = useState<EditableTripDataSources>({
		driversDataSource: [],
		tripTypeDataSource: [],
		detourDataSource: [
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
	});

	const [showAddCorrectionEdit, setShowAddCorrectionEdit] = useState<boolean>(false);
	const [existingCorrection, setExistingCorrection] = useState<boolean>(
		tripDetails.endMileageCorrection !== null && tripDetails.endMileageCorrection !== tripDetails.endMileage
	);
	const [newCorrectionMileage, setNewCorrectionMileage] = useState<number>(
		tripDetails.endMileageCorrection !== null
			? parseFloat(tripDetails.endMileageCorrection.toFixed(1))
			: parseFloat(tripDetails.endMileage.toFixed(1))
	);
	const [initialCorrectionMileage, setInitialCorrectionMileage] = useState<number>(
		tripDetails.endMileageCorrection !== null
			? parseFloat(tripDetails.endMileageCorrection.toFixed(1))
			: parseFloat(tripDetails.endMileage.toFixed(1))
	);
	const [showProcessingCorrectionMessage, setShowProcessingCorrectionMessage] = useState<boolean>(false);

	const [editableTrip, setEditableTrip] = useState<EditableTrip>({
		selectedDriverId: tripDetails.personId,
		detourMileage: tripDetails.detourMileage ?? 0.1,
		tripReference: tripDetails.reference ?? '',
		tripDescription: tripDetails.description ?? '',
		detourReference: tripDetails.detourReference ?? '',
		detourDescription: tripDetails.detourDescription ?? '',
		selectedTripType: tripDetails.trackType,
		selectedDetourType: tripDetails.detourType,
		selectedDetour: !!tripDetails.tripDetourId,
		tripHasPendingEdits: tripDetails.tripHasPendingEdits,
	});

	const [initialEditableTrip, setInitialEditableTrip] = useState<EditableTrip>(null);
	const [tripHasUnsavedData, setTripHasUnsavedData] = useState<boolean>(false);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-extra-parens
	const [promptCallback, setPromptCallback] = React.useState<() => void>(null);
	const [lastChangedDateTime, setLastChangedDateTime] = useState<string>('');
	const [lastChangedByPerson, setLastChangedByPerson] = useState<{ personId: string; personName: string }>({
		personId: '',
		personName: '',
	});
	const [lastTripAuditChanges, setLastTripAuditChanges] = useState<TripAudit>(null);
	const [tripHasPendingEditsToApply, setTripHasPendingEditsToApply] = useState<boolean>(
		editableTrip.tripHasPendingEdits
	);

	const currentTripIdRef = useRef<string>();
	const showHistoryLocations = useLocationsHistory();
	const { tripTimezone } = useTripFullTimeZoneInfo(tripDetails.timezoneId, tripDetails.objectNameWithTimeZoneOffset);

	//if the component is unmounted clear the curent trip id (checked before setting responses of requests in progress)
	useEffect(() => {
		return () => {
			currentTripIdRef.current = '';
		};
	}, []);

	useEffect(() => {
		if (ClaimUtil.validateClaim(user, { claim: ClaimType.TripsEditor, values: [ClaimValue.edit] })) {
			const filter = {
				fieldType: FilterFieldTypeEnum.customerId,
				operator: FilterOperatorTypeEnum.equal,
				fieldValue: customerId,
			};

			ajaxUtil
				.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Drivers?filter=' + JSON.stringify(filter))
				.then((data) => {
					if (tripDetails.personId && !data.some((d) => d.id === tripDetails.personId)) {
						data.unshift({
							id: tripDetails.personId,
							display: `${tripDetails.personName} (${tripDetails.personCode})`,
						});
					}
					setEditableTripDataSources((oldValue) => ({ ...oldValue, driversDataSource: data }));
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	useEffect(() => {
		let tripType: TripTypesEnum = TripTypesEnum.Business | TripTypesEnum.Commuting | TripTypesEnum.Private;

		if (trackTypeSpecification) {
			tripType = customerTripTypes;
		}

		if (
			(customerPrivacySettings !== PrivacySettingsEnum.HidePrivateData ||
				user?.profile[ClaimType.ShowPrivateTrips] ||
				isDriver) &&
			currentCustomerTripTypes & TripTypesEnum.Private
		) {
			tripType |= TripTypesEnum.Private;
		} else {
			tripType &= ~TripTypesEnum.Private;
		}
		setDefaultTripType(tripType);
	}, [trackTypeSpecification, customerTripTypes, customerPrivacySettings, currentCustomerTripTypes]);

	useEffect(() => {
		const newDatasource: AutocompleteItem[] = [];
		if (defaultTripType & TripTypesEnum.Business) {
			newDatasource.push({
				display: TranslateText('historyOverview.business'),
				id: TripTypesEnum.Business,
			});
		}
		if (defaultTripType & TripTypesEnum.Private) {
			newDatasource.push({
				display: TranslateText('historyOverview.private'),
				id: TripTypesEnum.Private,
			});
		}
		if (defaultTripType & TripTypesEnum.Commuting) {
			newDatasource.push({
				display: TranslateText('historyOverview.commuting'),
				id: TripTypesEnum.Commuting,
			});
		}

		if (newDatasource.length && !tripDetails.detourType) {
			const foundTripType = newDatasource.find((d) => d.id !== editableTrip.selectedTripType);
			if (foundTripType) {
				editableTrip.selectedDetourType = foundTripType.id as TripTypesEnum;
				setEditableTrip(editableTrip);
				setInitialEditableTrip(editableTrip);
			}
		}
		setEditableTripDataSources((oldValue) => ({ ...oldValue, tripTypeDataSource: newDatasource }));
	}, [defaultTripType]);

	useEffect(() => {
		setShowAddCorrectionEdit(false);
		setTripHasUnsavedData(false);
		if (unsavedDataCallback) {
			unsavedDataCallback(false);
		}
		setShowProcessingCorrectionMessage(false);
		setLastTripAuditChanges(null);
		setFieldsEnabled(
			ClaimUtil.validateClaim(user, {
				claim: ClaimType.TripsEditor,
				values: [ClaimValue.edit],
			}) &&
				!tripDetails.isInProgress &&
				(tripDetails.trackType !== TripTypesEnum.Private ||
					(customerPrivacySettings !== PrivacySettingsEnum.HidePrivateLocationAndTime &&
						customerPrivacySettings !== PrivacySettingsEnum.HidePrivateData) ||
					tripDetails.personId === personId ||
					ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips))
		);
		const newEditableTrip: EditableTrip = {
			selectedDriverId: tripDetails.personId,
			detourMileage: tripDetails.detourMileage ?? 0.1,
			tripReference: tripDetails.reference ?? '',
			tripDescription: tripDetails.description ?? '',
			detourReference: tripDetails.detourReference ?? '',
			detourDescription: tripDetails.detourDescription ?? '',
			selectedTripType: tripDetails.trackType,
			selectedDetourType: tripDetails.detourType ?? tripDetails.trackType,
			selectedDetour: !!tripDetails.tripDetourId,
			tripHasPendingEdits: tripDetails.tripHasPendingEdits,
		};
		if (editableTripDataSources.tripTypeDataSource.length && !tripDetails.detourType) {
			const foundTripType = editableTripDataSources.tripTypeDataSource.find(
				(d) => d.id !== newEditableTrip.selectedTripType
			);
			if (foundTripType) {
				newEditableTrip.selectedDetourType = foundTripType.id as TripTypesEnum;
			}
		}

		setEditableTrip(newEditableTrip);
		setInitialEditableTrip(newEditableTrip);

		const maxDetourMileageValue = parseFloat(tripDetails.totalMileage.toFixed(1)) - 0.1;
		const fieldRules: FieldRules = {
			detourMileage: {
				rules: {
					detourDistanceRule: {
						message: `${
							maxDetourMileageValue > 0.1
								? TranslateText('tripDetailsSection.detourDistanceValidationMessage') +
								  ' ' +
								  maxDetourMileageValue.toFixed(1)
								: TranslateText('tripDetailsSection.uneditableDetourDistance')
						}`,
						validationFunction: ({ detourMileage, selectedDetour }) => {
							return ValidatorFunctions.wrapInPromise(
								!selectedDetour || (detourMileage >= 0.1 && detourMileage <= maxDetourMileageValue)
							);
						},
					},
				},
			},
			tripReference: {
				rules: {
					tripReferenceRule: ValidatorFunctions.maxLength(50),
				},
			},
			tripDescription: {
				rules: {
					tripDescriptionRule: ValidatorFunctions.maxLength(150),
				},
			},
			detourReference: {
				rules: {
					detourReferenceRule: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', ['50']),
						validationFunction: ({ detourReference, selectedDetour }) => {
							return ValidatorFunctions.wrapInPromise(
								!selectedDetour || !detourReference || detourReference.toString().length <= 50
							);
						},
					},
				},
			},
			detourDescription: {
				rules: {
					detourDescriptionRule: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', ['150']),
						validationFunction: ({ detourDescription, selectedDetour }) => {
							return ValidatorFunctions.wrapInPromise(
								!selectedDetour || !detourDescription || detourDescription.toString().length <= 150
							);
						},
					},
				},
			},
			newCorrectionMileage: {
				rules: {
					required: ValidatorFunctions.required(),
					newCorrectionMileageRule: ValidatorFunctions.regex(
						/^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,3}$)/,
						TranslateText('tripDetailsSection.newMileageCorrectionValidationMessage')
					),
				},
			},
		};

		setFormValidator(new Validator({ fieldRules }, true));
		setValidationResult(null);
		setExistingCorrection(
			tripDetails.endMileageCorrection !== null && tripDetails.endMileageCorrection !== tripDetails.endMileage
		);
		setNewCorrectionMileage(
			tripDetails.endMileageCorrection !== null
				? parseFloat(tripDetails.endMileageCorrection.toFixed(1))
				: parseFloat(tripDetails.endMileage.toFixed(1))
		);
		setInitialCorrectionMileage(
			tripDetails.endMileageCorrection !== null
				? parseFloat(tripDetails.endMileageCorrection.toFixed(1))
				: parseFloat(tripDetails.endMileage.toFixed(1))
		);
		setMileageCorrectionStatus(tripDetails.mileageCorrectionStatus ?? MileageCorrectionStateEnum.InProgress);

		ajaxUtil
			.get<TripAudit>(GlobalSettings.tripsApi + '/getLastTripAuditChanges/' + tripDetails.id)
			.then((tripAudit) => {
				if (tripDetails.id === currentTripIdRef.current) {
					if (tripAudit !== null) {
						setLastTripAuditChanges(tripAudit);
						setLastChangedDateTime(tripAudit.lastChangedDateTime);
						setLastChangedByPerson({
							personId: tripAudit.lastChangedByPersonId,
							personName: tripAudit.lastChangedByPersonFirstLastName,
						});
					} else {
						setLastChangedDateTime(tripDetails?.createdDateTime);
					}
				}
			});
	}, [tripDetails]);

	useEffect(() => {
		currentTripIdRef.current = tripDetails.id;
		if (!tripDetails.isInProgress && tripDetails.allowMileageCorrections) {
			getLastMileageCorrection();
		}
	}, [tripDetails.id]);

	useEffect(() => {
		if (defaultTripType !== 0 && !tripDetails.isInProgress) {
			refreshTripData(false);
		}
	}, [defaultTripType, tripDetails.id]);

	useEffect(() => {
		if (initialEditableTrip) {
			const unsavedData = JSON.stringify(editableTrip) !== JSON.stringify(initialEditableTrip);
			setTripHasUnsavedData(unsavedData);
			if (unsavedDataCallback) {
				unsavedDataCallback(JSON.stringify(editableTrip) !== JSON.stringify(initialEditableTrip));
			}
		}
		formValidator?.validate({ ...editableTrip, newCorrectionMileage }).then((result) => {
			setValidationResult(result.validationResult);
			setFormIsValid(result.formResult);
		});
	}, [editableTrip, newCorrectionMileage, initialEditableTrip]);

	useEffect(() => {
		if (
			tripUnsavedData !==
			(tripHasUnsavedData || (newCorrectionMileage !== null && newCorrectionMileage !== initialCorrectionMileage))
		) {
			dispatch({
				type: SET_TRIP_UNSAVED_DATA,
				payload:
					tripHasUnsavedData ||
					(newCorrectionMileage !== null && newCorrectionMileage !== initialCorrectionMileage),
			});
		}
	}, [tripUnsavedData, tripHasUnsavedData, newCorrectionMileage]);

	useEffect(() => {
		if (!tripUnsavedData && tripHasUnsavedData) {
			setEditableTrip(initialEditableTrip);
			setShowAddCorrectionEdit(false);
		}
	}, [tripUnsavedData, initialEditableTrip]);

	useEffect(() => {
		if (showAddCorrectionEdit) {
			getLastMileageCorrection();
		}
		setNewCorrectionMileage(initialCorrectionMileage);
	}, [showAddCorrectionEdit]);

	const { previousTrip, nextTrip, navigateToTrip } = useTripsNavigation(
		tripDetails,
		historyTrips,
		historyGridTrips,
		renderForTable,
		dispatch
	);

	const addMileageCorrection = async () => {
		setShowAddCorrectionEdit(false);
		setExistingCorrection(true);
		setInitialCorrectionMileage(newCorrectionMileage);
		setNewCorrectionMileage(newCorrectionMileage);
		setShowProcessingCorrectionMessage(true);
		setMileageCorrectionStatus(MileageCorrectionStateEnum.InProgress);

		if (tripUnsavedData && !tripHasUnsavedData) {
			dispatch({
				type: SET_TRIP_UNSAVED_DATA,
				payload: false,
			});
		}

		//update and then reload the trip details
		dispatch(
			historyStoreActionCreators.updateTripMileageCorrection(
				tripDetails.id,
				newCorrectionMileage,
				MileageCorrectionStateEnum.InProgress
			)
		);
		dispatch(historyStoreActionCreators.reloadHistoryTripDetails(tripDetails.idWithParent));

		dispatch({
			type: TRIP_WITH_UPDATED_MILEAGE_CORRECTION,
			payload: tripDetails.id,
		});

		const auditResult = await ajaxUtil.post<TripAudit>(`${GlobalSettings.tripsApi}/addMileageCorrection`, {
			tripId: tripDetails.id,
			objectId: tripDetails.objectId,
			newMileage: newCorrectionMileage,
		});
		if (auditResult) {
			setLastTripAuditChanges(auditResult);
			setLastChangedDateTime(auditResult.lastChangedDateTime);
			setLastChangedByPerson({
				personId: auditResult.lastChangedByPersonId,
				personName: auditResult.lastChangedByPersonFirstLastName,
			});
		}
	};

	const getLastMileageCorrection = async () => {
		const { mileage, status } = await ajaxUtil.get<{ mileage: number; status: MileageCorrectionStateEnum }>(
			`${GlobalSettings.tripsApi}/getLastMileageCorrection/${tripDetails.id}`
		);

		if (historyGridTrips.some((x) => x.id === currentTripIdRef.current)) {
			if (mileage !== null) {
				setInitialCorrectionMileage(mileage);
				setNewCorrectionMileage(mileage);
				setExistingCorrection(true);
				setMileageCorrectionStatus(status);

				//update and then reload the trip details
				dispatch(historyStoreActionCreators.updateTripMileageCorrection(tripDetails.id, mileage, status));
				dispatch(historyStoreActionCreators.reloadHistoryTripDetails(tripDetails.idWithParent));
			}

			if (showProcessingCorrectionMessage && status === MileageCorrectionStateEnum.Applied) {
				setShowProcessingCorrectionMessage(false);
			}
		}
	};

	const fetchTripData = async () => {
		const { tripData, auditResult } = await ajaxUtil.get<{
			tripData: EditableTripDto;
			auditResult: TripAudit;
		}>(`${GlobalSettings.tripsApi}/getTripDetails/${tripDetails.id}`);

		if (!currentTripIdRef.current || currentTripIdRef.current !== tripDetails?.id) {
			return;
		}

		setTripHasPendingEditsToApply(tripData.tripHasPendingEdits);

		const newEditableTrip: EditableTrip = {
			selectedDriverId: tripData.driverId,
			detourMileage: tripData.detourMileage ?? 0.1,
			tripReference: tripData.tripReference ?? '',
			tripDescription: tripData.tripDescription ?? '',
			detourReference: tripData.detourReference ?? '',
			detourDescription: tripData.detourDescription ?? '',
			selectedTripType: tripData.tripType,
			selectedDetourType:
				tripData.detourType ?? tripDetails.detourType ?? initialEditableTrip?.selectedDetourType,
			selectedDetour: tripData.hasDetour,
			tripHasPendingEdits: tripData.tripHasPendingEdits,
		};

		if (editableTripDataSources.tripTypeDataSource.length && !tripDetails.detourType) {
			const foundTripType = editableTripDataSources.tripTypeDataSource.find(
				(d) => d.id !== newEditableTrip.selectedTripType
			);
			if (foundTripType) {
				newEditableTrip.selectedDetourType = foundTripType.id as TripTypesEnum;
			}
		}

		setEditableTrip(newEditableTrip);
		setInitialEditableTrip(newEditableTrip);

		if (auditResult) {
			setLastTripAuditChanges(auditResult);
			setLastChangedDateTime(auditResult.lastChangedDateTime);
			setLastChangedByPerson({
				personId: auditResult.lastChangedByPersonId,
				personName: auditResult.lastChangedByPersonFirstLastName,
			});
		}

		dispatch(
			historyStoreActionCreators.updateEditedTrip(tripDetails.id, {
				...tripData,
				detourType: newEditableTrip.selectedDetourType,
				detourMileage: newEditableTrip.detourMileage,
			})
		);
		dispatch(historyStoreActionCreators.reloadHistoryTripDetails(tripDetails.idWithParent));

		setTripHasUnsavedData(false);
	};

	const refreshTripData = async (checkTripUnsavedData: boolean) => {
		if (checkTripUnsavedData && tripHasUnsavedData) {
			setPromptCallback(() => async () => {
				await fetchTripData().then(() => {
					setTripHasUnsavedData(false);
				});
			});
			setShowNotificationPrompt(true);
		} else {
			await fetchTripData().then(() => {
				setTripHasUnsavedData(false);
			});
		}
	};
	const saveTripDetails = async (refreshTrip = false) => {
		const { tripData, auditResult } = await ajaxUtil.post<{
			tripData: EditableTripDto;
			auditResult: TripAudit;
		}>(`${GlobalSettings.tripsApi}/updateTripDetails`, {
			id: tripDetails.id,
			personId: editableTrip.selectedDriverId,
			objectId: tripDetails.objectId,
			trackType: editableTrip.selectedTripType,
			tripReference: editableTrip.tripReference,
			tripDescription: editableTrip.tripDescription,
			hasDetour: editableTrip.selectedDetour,
			tripDetourReference: editableTrip.detourReference,
			tripDetourDescription: editableTrip.detourDescription,
			tripDetourType: editableTrip.selectedDetourType,
			tripDetourMileage: editableTrip.detourMileage,
		});

		//if driver is changed set editedTripDriver before tripDetails is updated
		const editedTripDriver =
			tripDetails.personId !== editableTrip.selectedDriverId
				? {
						id: tripDetails.id,
						parentId: tripDetails.parentId,
						oldDriverId: tripDetails.personId,
						newDriverId: editableTrip.selectedDriverId,
				  }
				: null;

		setTripHasPendingEditsToApply(tripData.tripHasPendingEdits);
		setInitialEditableTrip(editableTrip);
		if (auditResult) {
			setLastTripAuditChanges(auditResult);
			setLastChangedDateTime(auditResult.lastChangedDateTime);
			setLastChangedByPerson({
				personId: auditResult.lastChangedByPersonId,
				personName: auditResult.lastChangedByPersonFirstLastName,
			});
		}
		const fieldsRemainEnabled =
			fieldsEnabled &&
			(editableTrip.selectedTripType !== TripTypesEnum.Private ||
				(customerPrivacySettings !== PrivacySettingsEnum.HidePrivateLocationAndTime &&
					customerPrivacySettings !== PrivacySettingsEnum.HidePrivateData) ||
				editableTrip.selectedDriverId === personId ||
				ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips));
		setFieldsEnabled(fieldsRemainEnabled);

		const hidePrivateData =
			!fieldsRemainEnabled &&
			editableTrip.selectedTripType === TripTypesEnum.Private &&
			customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime;
		dispatch(historyStoreActionCreators.updateEditedTrip(tripDetails.id, tripData, hidePrivateData));

		if (refreshTrip) {
			navigateToTrip(historyGridTrips.find((x) => x.idWithParent === tripDetails.idWithParent && !x.isParked));
		}

		//if the driver is edited all the trips for old driver, new driver and object needs to be retrieved from server
		//timeout used for navigation to be finished(it will not happen otherwise as setEditedTripDriver will remove trips for edited entities)
		if (editedTripDriver) {
			dispatch({
				type: SET_MAP_LOADING,
				payload: true,
			});

			setTimeout(() => {
				dispatch(historyStoreActionCreators.setEditedTripDriver(editedTripDriver));
			}, 200);
		}
	};

	const navigateToPreviousTrip = async () => {
		if (!renderForTable) {
			if (
				tripHasUnsavedData &&
				(newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage)
			) {
				await saveTripDetails();
			}
			if (newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage) {
				navigateToTrip(previousTrip);
			} else {
				setPromptCallback(() => async () => {
					setNewCorrectionMileage(initialCorrectionMileage);
					if (tripHasUnsavedData) {
						await saveTripDetails();
					}

					navigateToTrip(previousTrip);
				});

				setShowNotificationPrompt(true);
			}
		} else {
			let shouldSaveTripDetails = tripHasUnsavedData;
			if (
				tripHasUnsavedData &&
				(newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage)
			) {
				await saveTripDetails();
				shouldSaveTripDetails = false;
			}
			if (previousCallback) {
				previousCallback(
					shouldSaveTripDetails ? saveTripDetails : null,
					newCorrectionMileage !== null && newCorrectionMileage !== initialCorrectionMileage
				);
			}
		}
	};

	const navigateToNextTrip = async () => {
		if (!renderForTable) {
			if (
				tripHasUnsavedData &&
				(newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage)
			) {
				await saveTripDetails();
			}
			if (newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage) {
				navigateToTrip(nextTrip);
			} else {
				setPromptCallback(() => async () => {
					setNewCorrectionMileage(initialCorrectionMileage);
					if (tripHasUnsavedData) {
						await saveTripDetails();
					}

					navigateToTrip(nextTrip);
				});
				setShowNotificationPrompt(true);
			}
		} else {
			let shouldSaveTripDetails = tripHasUnsavedData;
			if (
				tripHasUnsavedData &&
				(newCorrectionMileage === null || newCorrectionMileage === initialCorrectionMileage)
			) {
				await saveTripDetails();
				shouldSaveTripDetails = false;
			}
			if (nextCallback) {
				nextCallback(
					shouldSaveTripDetails ? saveTripDetails : null,
					newCorrectionMileage !== null && newCorrectionMileage !== initialCorrectionMileage
				);
			}
		}
	};
	const addCorrectionDiv = () => {
		return (
			<>
				<div className={`edit-km-input-button-container ${renderForTable ? 'table-style' : ''}`}>
					<TextField
						className={`edit-km-text-input ${newCorrectionMileage !== null ? 'white-backgorund' : ''} noSpinner`}
						type="number"
						variant="outlined"
						InputProps={{ inputProps: { min: 0 } }}
						value={newCorrectionMileage ?? ''}
						onChange={(event) => {
							const newValue = parseFloat(event.target.value);
							if (!isNaN(newValue)) {
								setNewCorrectionMileage(newValue);
							} else {
								setNewCorrectionMileage(null);
							}
						}}
						onKeyDown={handleKeyDown}
						onWheel={(event: any) => event.target.blur()}
					/>
					<span>km</span>
					<Button
						disabled={
							newCorrectionMileage === null ||
							newCorrectionMileage < 0 ||
							newCorrectionMileage === initialCorrectionMileage ||
							!validationResult?.newCorrectionMileage['newCorrectionMileageRule'].valid
						}
						className="edit-km-save-button"
						variant="contained"
						onClick={() => addMileageCorrection()}
					>
						{TranslateText('common.buttonSave')}
					</Button>
				</div>
			</>
		);
	};

	const displayModeClass = renderForTable ? 'render-for-table' : '';

	return (
		<div hidden={hidden} className={`summary-tab-trip ${displayModeClass}`}>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={
					tripHasUnsavedData &&
					newCorrectionMileage !== null &&
					newCorrectionMileage !== initialCorrectionMileage
						? TranslateText('notificationMessages.unsavedDataForMileageCorrection')
						: TranslateText('notificationMessages.unsavedData')
				}
				handleUserResponse={(response) => {
					setShowNotificationPrompt(false);
					if (response) {
						promptCallback();
						setPromptCallback(null);
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			{!renderForTable && (
				<div className={'summary-header'}>
					<span className={'trip-index'}>{`${TranslateText('common.tripTitle')} ${
						tripDetails.isInProgress ? '-' : tripDetails.index + 1
					}`}</span>
				</div>
			)}
			<div className={`info-area-summary ${displayModeClass}`}>
				<div className={'driver-object-tripType-summary'}>
					{tripDetails.isPerson ? (
						driverIdentification && tripDetails?.personName?.length ? (
							<span className={'history-details-summary-row entity-name'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('fleetSelection.Driver')}
								</span>
								<Tooltip title={tripDetails.personName}>
									<span className={`history-details-summary-value  ${displayModeClass}`}>
										{tripDetails.personName}
									</span>
								</Tooltip>
							</span>
						) : null
					) : (
						<>
							<span className={'history-details-summary-row entity-name'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('fleetSelection.Object')}
								</span>
								<Tooltip title={tripDetails.objectName}>
									<span className={`history-details-summary-value ${displayModeClass}`}>
										{tripDetails.objectName}
									</span>
								</Tooltip>
							</span>
							{customerTimezone !== tripDetails.timezoneId ? (
								<span className={'history-details-summary-row time-zone'}>
									<span className={`history-details-summary-field ${displayModeClass}`}>
										{TranslateText('maintenanceOverview.grid.colTimeZone')}
									</span>
									<span className={`history-details-summary-value ${displayModeClass}`}>
										{tripTimezone}
									</span>
								</span>
							) : null}
						</>
					)}
					{!tripDetails.isPerson ? (
						driverIdentification ? (
							<span className={'history-details-summary-row'}>
								<span
									className={`history-details-summary-field ${
										fieldsEnabled ? 'editable-filed-name' : ''
									}`}
								>
									{TranslateText('fleetSelection.Driver')}
								</span>
								<span className={`history-details-summary-value ${displayModeClass}`}>
									{fieldsEnabled ? (
										<MaterialAutocomplete
											className={`autocomplete-input wider ${displayModeClass}`}
											dataSource={editableTripDataSources.driversDataSource}
											valueId={editableTrip.selectedDriverId}
											onChange={({ value }) =>
												setEditableTrip((oldTrip) => ({
													...oldTrip,
													selectedDriverId: value as string,
												}))
											}
										/>
									) : (
										tripDetails.personName
									)}
								</span>
							</span>
						) : null
					) : (
						<>
							<span className={'history-details-summary-row entity-name'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('fleetSelection.Object')}
								</span>
								<Tooltip title={tripDetails.objectName}>
									<span className={`history-details-summary-value ${displayModeClass}`}>
										{tripDetails.objectName}
									</span>
								</Tooltip>
							</span>
							{customerTimezone !== tripDetails.timezoneId ? (
								<span className={'history-details-summary-row'}>
									<span className={`history-details-summary-field ${displayModeClass}`}>
										{TranslateText('maintenanceOverview.grid.colTimeZone')}
									</span>
									<span className={`history-details-summary-value ${displayModeClass}`}>
										{tripTimezone}
									</span>
								</span>
							) : null}
						</>
					)}
					{trackTypeSpecification ? (
						<span
							className={`history-details-summary-row ${
								fieldsEnabled && tripDetails.isPerson ? 'top-margin' : ''
							}`}
						>
							<span
								className={`history-details-summary-field ${
									fieldsEnabled ? 'editable-filed-name' : ''
								}`}
							>
								{' '}
								{TranslateText('reports.parameter.tripType')}
							</span>
							<span className={`history-details-summary-value ${displayModeClass}`}>
								{fieldsEnabled ? (
									<MaterialAutocomplete
										className={`autocomplete-input ${displayModeClass}`}
										dataSource={editableTripDataSources.tripTypeDataSource}
										valueId={
											editableTripDataSources.tripTypeDataSource.some(
												(d) => d.id === editableTrip.selectedTripType
											)
												? editableTrip.selectedTripType
												: editableTrip.selectedTripType === TripTypesEnum.Private
												? TranslateText('historyOverview.private')
												: editableTrip.selectedTripType === TripTypesEnum.Business
												? TranslateText('historyOverview.business')
												: TranslateText('historyOverview.commuting')
										}
										onChange={({ value }) => {
											const newSelectedTripType = value as TripTypesEnum;
											const newSelectedDetourType = editableTripDataSources.tripTypeDataSource.find(
												(d) => d.id !== newSelectedTripType
											);
											setEditableTrip((oldTrip) => ({
												...oldTrip,
												selectedTripType: newSelectedTripType,
												selectedDetourType: newSelectedDetourType
													? (newSelectedDetourType.id as TripTypesEnum)
													: null,
											}));
										}}
										filterSelectedOptions
										disableClearable
										allowNewValues
									/>
								) : (
									GetTripType(tripDetails.trackType)
								)}
							</span>
						</span>
					) : null}
				</div>

				{!renderForTable ? (
					<>
						<div className={'start-address-departure-summary'}>
							<div className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.departure')}
								</span>
								<span className={`history-details-summary-value ${displayModeClass}`}>
									{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
									!tripDetails.startTripTime
										? privateDataMask
										: FormatDate(tripDetails.startTripTime, false, false, true)}
								</span>
							</div>
							<div className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.startAddress')}
								</span>
								<Tooltip title={tripDetails.startAddress}>
									<div className={`history-details-summary-value ${displayModeClass}`}>
										{tripDetails.startAddress}
									</div>
								</Tooltip>
								{showHistoryLocations &&
								tripDetails?.startLocationName === null &&
									ClaimUtil.validateClaimList(user,[{
									claim: ClaimType.Locations,
									values: [ClaimValue.edit],
								}])
									? <IconButton
										className="action-icon-mui action-location-icon"
										size={'small'}
										onClick={() => {
											setLocationDialogData({
												display: true,
												isForAdd: true,
												selectedLocationId: null,
												latLng: tripDetails.start,
												startDate: tripDetails.startTrip
											});
										}}
									>
										<Add className="location-icon"/>
									</IconButton>
									: null
								}
							</div>
							{showHistoryLocations && tripDetails?.startLocationName?.length && (
								<div className={'history-details-summary-row'}>
									<span className={`history-details-summary-field ${displayModeClass}`}>
										{TranslateText('tripDetailsSection.startLocation')}
									</span>
									<Tooltip
										title={
											tripDetails.startAddress === privateDataMask
												? privateDataMask
												: tripDetails?.startLocationName
										}
									>
										<div className={`history-details-summary-value ${displayModeClass}`}>
											{tripDetails.startAddress === privateDataMask
												? privateDataMask
												: tripDetails?.startLocationName}
										</div>
									</Tooltip>
									{ClaimUtil.validateClaimList(user,[{
										claim: ClaimType.Locations,
										values: [ClaimValue.edit],
									}]) && tripDetails.startAddress !== privateDataMask &&
										tripDetails.startLocationId !== null
										?  <IconButton
											className="action-icon-mui action-location-icon"
											size={'small'}
											onClick={() => {
												setLocationDialogData({
													display: true,
													isForAdd: false,
													selectedLocationId: tripDetails.startLocationId,
													latLng: null,
													startDate: null
												});
											}}
										>
											<EditOutlinedIcon className="location-icon"/>
										</IconButton>
										: null
									}
								</div>
							)}
						</div>
						<div className={'end-address-arrival-summary'}>
							<div className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.arrival')}
								</span>

								<span className={`history-details-summary-value ${displayModeClass}`}>
									{tripDetails.isInProgress
										? ''
										: customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
										  !tripDetails.endTripTime
										? privateDataMask
										: FormatDate(tripDetails.endTripTime, false, false, true)}
								</span>
							</div>

							<div className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.endAddress')}
								</span>
								<Tooltip title={tripDetails.endAddress}>
									<div className={`history-details-summary-value ${displayModeClass}`}>
										{tripDetails.endAddress}
									</div>
								</Tooltip>
								{showHistoryLocations &&
									tripDetails?.endLocationName === null &&
									ClaimUtil.validateClaimList(user,[{
										claim: ClaimType.Locations,
										values: [ClaimValue.edit],
									}])
									? <IconButton
										className="action-icon-mui action-location-icon"
										size={'small'}
										onClick={() => {
											setLocationDialogData({
												display: true,
												isForAdd: true,
												selectedLocationId: null,
												latLng: tripDetails.end,
												startDate: tripDetails.endTrip
											});
										}}
									>
										<Add className="location-icon" />
									</IconButton>
									: null
								}
							</div>
							{showHistoryLocations && tripDetails?.endLocationName?.length && (
								<div className={'history-details-summary-row'}>
									<span className={`history-details-summary-field ${displayModeClass}`}>
										{TranslateText('tripDetailsSection.endLocation')}
									</span>
									<Tooltip
										title={
											tripDetails.endAddress === privateDataMask
												? privateDataMask
												: tripDetails?.endLocationName
										}
									>
										<div className={`history-details-summary-value ${displayModeClass}`}>
											{tripDetails.endAddress === privateDataMask
												? privateDataMask
												: tripDetails?.endLocationName}
										</div>
									</Tooltip>
									{ClaimUtil.validateClaimList(user,[{
										claim: ClaimType.Locations,
										values: [ClaimValue.edit],
									}]) && tripDetails.endAddress !== privateDataMask &&
										tripDetails.endLocationId !== null
										? <IconButton
											className="action-icon-mui action-location-icon"
											size={'small'}
											onClick={() => {
												setLocationDialogData({
													display: true,
													isForAdd: false,
													selectedLocationId: tripDetails.endLocationId,
													latLng: null,
													startDate: null
												});
											}}
										>
											<EditOutlinedIcon className="location-icon"/>
										</IconButton>
										: null
									}
								</div>
							)}
						</div>
					</>
				) : null}

				<div className={`driving-distance-speed-summary ${showAddCorrectionEdit ? 'edit-km-padding' : ''}`}>
					{!renderForTable && (
						<>
							<span className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('maintenanceOverview.grid.colDrivingTime')}
								</span>
								<span className={`history-details-summary-value ${displayModeClass}`}>
									{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
									// compare it to null because tripDuration is null when it has to be hidden
									tripDetails.tripDuration === null
										? privateDataMask
										: DateTimeUtil.calculateTimePassed(tripDetails.tripDuration, new Date(), false)
												.replace('m', ' m')
												.replace('h', ' h')}
								</span>
							</span>
							<span className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('maintenanceOverview.grid.colDistance')}
								</span>
								<span className={`history-details-summary-value ${displayModeClass}`}>
									{`${tripDetails.totalMileage.toFixed(1)} km`}
								</span>
							</span>
							<span className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.maxSpeed')}
								</span>
								<span className={`history-details-summary-value ${displayModeClass}`}>
									{' '}
									{`${tripDetails.maxSpeed} km/h`}
								</span>
							</span>
							<span className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('tripDetailsSection.averageSpeed')}
								</span>
								<span
									className={`history-details-summary-value ${displayModeClass}`}
								>{`${tripDetails.averageSpeed?.toFixed(1)} km/h`}</span>
							</span>
						</>
					)}
					{!tripDetails.isInProgress ? (
						<>
							<span className={'history-details-summary-row'}>
								<span className={`history-details-summary-field ${displayModeClass}`}>
									{TranslateText('reportHeaders.KmEnd')}
								</span>
								<span
									className={`history-details-summary-value correction-summary-value ${displayModeClass} ${
										ClaimUtil.validateClaim(user, {
											claim: ClaimType.TripsEditor,
											values: [ClaimValue.edit],
										}) &&
										!existingCorrection &&
										tripDetails.allowMileageCorrections
											? 'end-mileage'
											: ''
									} `}
								>
									{`${tripDetails.endMileage.toFixed(1)} km`}
								</span>
								<Button
									className="edit-button"
									hidden={
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.TripsEditor,
											values: [ClaimValue.edit],
										}) ||
										existingCorrection ||
										!tripDetails.allowMileageCorrections
									}
									disableRipple
									disabled={isFetchingTripsData}
									startIcon={
										<EditOutlinedIcon
											className={`edit-icon ${displayModeClass} ${
												showAddCorrectionEdit ? 'disabled-color' : ''
											}`}
										/>
									}
									onClick={() => setShowAddCorrectionEdit(!showAddCorrectionEdit)}
								/>
							</span>
						</>
					) : null}
					{currentTripIdRef.current === tripDetails.id && !tripDetails.isInProgress && existingCorrection ? (
						<>
							<span className={'history-details-summary-row left-margin'}>
								{showAddCorrectionEdit ? (
									<span className="correction-container">
										<div className={'edit-km-container existing-correction'}>
											<span className="edit-km-label">
												{mileageCorrectionStatus === MileageCorrectionStateEnum.Applied
													? TranslateText('tripDetailsSection.appliedCorrection')
													: TranslateText('tripDetailsSection.pendingCorrection')}
											</span>
											{addCorrectionDiv()}
										</div>
										<ValidationMessage result={validationResult?.newCorrectionMileage} />
									</span>
								) : (
									<>
										<span className={'history-details-summary-field correction-text'}>
											{mileageCorrectionStatus === MileageCorrectionStateEnum.Applied
												? TranslateText('tripDetailsSection.appliedCorrection')
												: TranslateText('tripDetailsSection.pendingCorrection')}
										</span>
										<span
											className={`history-details-summary-value correction-summary-value ${displayModeClass} ${
												!ClaimUtil.validateClaim(user, {
													claim: ClaimType.TripsEditor,
													values: [ClaimValue.edit],
												}) || !tripDetails.allowMileageCorrections
													? 'hidden-edit-button'
													: 'correction-value'
											}`}
										>
											{tripDetails.endMileageCorrection?.toFixed(1)} km
										</span>
									</>
								)}
								<Button
									className="edit-button"
									disableRipple
									hidden={
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.TripsEditor,
											values: [ClaimValue.edit],
										}) || !tripDetails.allowMileageCorrections
									}
									disabled={isFetchingTripsData}
									startIcon={
										<EditOutlinedIcon
											className={`edit-icon ${displayModeClass} ${
												showAddCorrectionEdit ? 'disabled-color existing-correction' : ''
											}`}
										/>
									}
									onClick={() => setShowAddCorrectionEdit(!showAddCorrectionEdit)}
								/>
							</span>
							{showProcessingCorrectionMessage ? (
								<span className={'history-details-summary-row left-margin'}>
									<span className={'history-details-summary-field pending-correction '}>
										{TranslateText('tripDetailsSection.processingCorrection')}
									</span>
								</span>
							) : null}
						</>
					) : null}
					{!tripDetails.isInProgress && showAddCorrectionEdit && !existingCorrection ? (
						<>
							<div className={'edit-km-container'}>
								<span className="edit-km-label">
									{TranslateText('tripDetailsSection.newCorrection')}
								</span>
								{addCorrectionDiv()}
							</div>
							<ValidationMessage result={validationResult?.newCorrectionMileage} />
						</>
					) : null}
				</div>
				{trackTypeSpecification && tripDetails?.startAddress !== privateDataMask ? (
					<div className={'track-type-specification-summary'}>
						<span className={'history-details-summary-row bottom-padding'}>
							<span className={`history-details-summary-field ${!fieldsEnabled ? 'disabled-color' : ''}`}>
								{TranslateText('tripDetailsSection.reference')}
							</span>
							<TextField
								name="tripReference"
								disabled={!fieldsEnabled}
								className={`history-details-summary-value text-input ${displayModeClass}  ${
									!fieldsEnabled
										? 'disabled-color'
										: editableTrip.tripReference
										? 'white-backgorund'
										: ''
								}`}
								variant="outlined"
								value={editableTrip.tripReference}
								onChange={({ target: { value } }) =>
									setEditableTrip((oldTrip) => ({
										...oldTrip,
										tripReference: value,
									}))
								}
							/>
						</span>
						<ValidationMessage
							className={displayModeClass}
							result={validationResult ? validationResult['tripReference'] : null}
						/>
						<span className={'history-details-summary-row bottom-padding'}>
							<span className={`history-details-summary-field ${!fieldsEnabled ? 'disabled-color' : ''}`}>
								{TranslateText('fields.description')}
							</span>
							<TextField
								name="tripDescription"
								disabled={!fieldsEnabled}
								className={`multi-line-text-input ${displayModeClass} ${
									!fieldsEnabled
										? 'disabled-color'
										: editableTrip.tripDescription
										? 'white-backgorund'
										: ''
								}
								`}
								variant="outlined"
								multiline
								rows={3}
								value={editableTrip.tripDescription}
								onChange={({ target: { value } }) =>
									setEditableTrip((oldTrip) => ({
										...oldTrip,
										tripDescription: value,
									}))
								}
							/>
						</span>
						<ValidationMessage
							className={displayModeClass}
							result={validationResult ? validationResult['tripDescription'] : null}
						/>
						<span className={'history-details-summary-row bottom-padding'}>
							<span
								className={`history-details-summary-field detour-field ${
									!fieldsEnabled ? 'disabled-color' : ''
								}`}
							>
								{TranslateText('tripDetailsSection.detour')}
							</span>
							<span className={`history-details-summary-value ${displayModeClass}`}>
								<MaterialAutocomplete
									disabled={!fieldsEnabled}
									className={`autocomplete-input ${displayModeClass} ${
										!fieldsEnabled ? 'disabled-color' : ''
									}`}
									disableClearable
									dataSource={editableTripDataSources.detourDataSource}
									valueId={editableTrip.selectedDetour}
									onChange={({ value }) => {
										const newValue = value as boolean;
										if (newValue) {
											setEditableTrip((oldTrip) => ({
												...oldTrip,
												selectedDetour: newValue,
											}));
										} else {
											setEditableTrip((oldTrip) => ({
												...oldTrip,
												selectedDetour: newValue,
												detourReference: '',
												detourDescription: '',
												selectedDetourType: editableTripDataSources.tripTypeDataSource.find(
													(d) => d.id !== editableTrip.selectedTripType
												).id as TripTypesEnum,
												detourMileage: 0.1,
											}));
										}
									}}
								/>
							</span>
						</span>

						{editableTrip.selectedDetour ? (
							<>
								<span className={'history-details-summary-row bottom-padding'}>
									<span
										className={`history-details-summary-field ${
											!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
										}`}
									>
										{TranslateText('tripDetailsSection.detourReference')}
									</span>
									<TextField
										name="detourReference"
										disabled={!fieldsEnabled || !editableTrip.selectedDetour}
										className={`history-details-summary-value text-input ${displayModeClass} ${
											!fieldsEnabled || !editableTrip.selectedDetour
												? 'disabled-color'
												: editableTrip.detourReference
												? 'white-backgorund'
												: ''
										}`}
										variant="outlined"
										value={editableTrip.detourReference}
										onChange={({ target: { value } }) =>
											setEditableTrip((oldTrip) => ({
												...oldTrip,
												detourReference: value,
											}))
										}
									/>
								</span>
								<ValidationMessage
									className={displayModeClass}
									result={validationResult ? validationResult['detourReference'] : null}
								/>
								<span className={'history-details-summary-row bottom-padding'}>
									<span
										className={`history-details-summary-field ${
											!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
										}`}
									>
										{TranslateText('tripDetailsSection.detourDescription')}
									</span>
									<TextField
										name="detourDescription"
										disabled={!fieldsEnabled || !editableTrip.selectedDetour}
										className={`multi-line-text-input ${displayModeClass} ${
											!fieldsEnabled || !editableTrip.selectedDetour
												? 'disabled-color'
												: editableTrip.detourDescription
												? 'white-backgorund'
												: ''
										}`}
										variant="outlined"
										multiline
										rows={3}
										value={editableTrip.detourDescription}
										onChange={({ target: { value } }) =>
											setEditableTrip((oldTrip) => ({
												...oldTrip,
												detourDescription: value,
											}))
										}
									/>
								</span>
								<ValidationMessage
									className={displayModeClass}
									result={validationResult ? validationResult['detourDescription'] : null}
								/>
								<span className={'history-details-summary-row bottom-padding'}>
									<span
										className={`history-details-summary-field detour-field ${
											!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
										}`}
									>
										{TranslateText('tripDetailsSection.detourType')}
									</span>
									<span className={`history-details-summary-value ${displayModeClass}`}>
										<MaterialAutocomplete
											disabled={!fieldsEnabled || !editableTrip.selectedDetour}
											className={`autocomplete-input ${displayModeClass} ${
												!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
											}`}
											dataSource={editableTripDataSources.tripTypeDataSource}
											filterSelectedOptions
											disableClearable
											valueId={
												editableTripDataSources.tripTypeDataSource.some(
													(d) => d.id === editableTrip.selectedDetourType
												)
													? editableTrip.selectedDetourType
													: editableTrip.selectedDetourType === TripTypesEnum.Private
													? TranslateText('historyOverview.private')
													: editableTrip.selectedDetourType === TripTypesEnum.Business
													? TranslateText('historyOverview.business')
													: TranslateText('historyOverview.commuting')
											}
											onChange={({ value }) =>
												setEditableTrip((oldTrip) => ({
													...oldTrip,
													selectedDetourType: value as TripTypesEnum,
												}))
											}
											allowNewValues
										/>
									</span>
								</span>
								<span className={'history-details-summary-row bottom-padding'}>
									<span
										className={`history-details-summary-field ${
											!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
										}`}
									>
										{TranslateText('tripDetailsSection.detourDistance')}
									</span>
									<div
										className={`history-details-summary-value ${displayModeClass} detour-distance-input-container`}
									>
										<TextField
											name="detourMileage"
											disabled={!fieldsEnabled || !editableTrip.selectedDetour}
											className={`detour-distance-input ${
												!fieldsEnabled || !editableTrip.selectedDetour
													? 'disabled-color'
													: editableTrip.detourMileage
													? 'white-backgorund'
													: ''
											}`}
											type="number"
											variant="outlined"
											InputProps={{ notched: true }}
											inputProps={{ min: 0.1 }}
											value={editableTrip.detourMileage}
											onBlur={() => {
												setEditableTrip((oldTrip) => ({
													...oldTrip,
													detourMileage: parseFloat(oldTrip.detourMileage.toFixed(3)),
												}));
											}}
											onChange={({ target: { value } }) =>
												setEditableTrip((oldTrip) => ({
													...oldTrip,
													detourMileage: parseFloat(value),
												}))
											}
										/>
										<div
											className={`${
												!fieldsEnabled || !editableTrip.selectedDetour ? 'disabled-color' : ''
											}`}
										>
											km
										</div>
									</div>
								</span>
								<div className="bottom-space-filler">
									<ValidationMessage
										className={displayModeClass}
										result={validationResult ? validationResult['detourMileage'] : null}
									/>
								</div>
							</>
						) : (
							<div className="bottom-space-filler"></div>
						)}
					</div>
				) : null}
			</div>
			{tripHasPendingEditsToApply ? (
				<>
					<div
						className={renderForTable ? 'pending-changes-container-table' : 'pending-changes-container-map'}
					>
						<span>{TranslateText('tripDetailsSection.tripHasPendingChanges')}</span>
						<IconButton size="small" onClick={() => refreshTripData(true)}>
							<Cached fontSize="small" />
						</IconButton>
					</div>
				</>
			) : null}
			<SummaryTabFooter
				renderForTable={renderForTable}
				tripHasUnsavedData={tripHasUnsavedData}
				isNonTrip={false}
				lastChangedDateTime={lastChangedDateTime}
				lastChangedByPerson={lastChangedByPerson}
				lastTripAuditChanges={lastTripAuditChanges}
				previousClick={
					(!renderForTable && !previousTrip) || (renderForTable && !previousCallback) || !formIsValid
						? null
						: navigateToPreviousTrip
				}
				nextClick={
					(!renderForTable && !nextTrip) || (renderForTable && !nextCallback) || !formIsValid
						? null
						: navigateToNextTrip
				}
				saveClick={!tripHasUnsavedData || !formIsValid ? null : () => saveTripDetails(true)}
				restoreClick={
					!tripHasUnsavedData
						? null
						: () => {
								setEditableTrip(initialEditableTrip);
						  }
				}
				useTripTimezone={false}
			/>
			<LocationDialog
				visible={locationDialogData.display}
				closeCallback={() => {
					setLocationDialogData({
						display: false,
						isForAdd: false,
						selectedLocationId: null,
						latLng: null,
						startDate: null
					});
				}}
				selectedEntityId={locationDialogData.selectedLocationId}
				isForAdd={locationDialogData.isForAdd}
				latLngAddress={locationDialogData.latLng}
				startDateFromTrip={adjustDateForDialog(locationDialogData.startDate)}
				startDateFromTripString={adjustDateToUtcForDialog(locationDialogData.startDate, tripDetails.timeZoneMinutesOffset)}
			/>
		</div>
	);
};

export default SummaryTabTrip;
