import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import EasyTrackFeature from 'authorization/EasyTrackFeature';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState, CurrentSession } from 'store';
import { fleetSelectionActionCreator } from 'store/FleetSelection';
import { SET_TRIP_UNSAVED_DATA } from 'store/HistoryStore';
import { notificationsActionCreators, NotificationsStatus } from 'store/NotificationsStore';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import { userDataActionCreators } from 'store/UserData';
import ajaxUtil from 'utils/Ajax';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import GlobalSettings from '../../GlobalSettings.json';

const clientEvents = {
	updateAlertsNotification: 'UpdateAlertsNotification',
	groupAuthorizationUpdate: 'GroupAuthorizationUpdate',
	logoutUser: 'LogoutUser',
};
const serverEvents = {
	checkForUpdates: 'CheckForUpdates',
};

const getRetryDelayInMilliseconds = (retryCount: number): number | null => {
	if (retryCount < 5) {
		return 3000;
	} else if (retryCount < 20) {
		return 10000;
	}
	return 30000;
};

const NotificationEngine = (): JSX.Element => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state: ApplicationState) => state.oidc.user.access_token);
	const notificationsStatus = useSelector((state: ApplicationState) => state.notificationsStore.notificationsStatus);
	const featuresSettings = useSelector((s: ApplicationState) => s.currentSession.customer.featuresSettings);
	const notificationConnection = useRef<HubConnection>();

	const currentSessionRef = useRef<CurrentSession>();
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	currentSessionRef.current = currentSession;

	//used for retry initial start failures (not coverd by withAutomaticReconnect)
	const [retryTimestamp, setRetryTimestamp] = useState(0);
	const [retryCount, setRetryCount] = useState(0);

	//when the access token is changed(ex. silent sign in) reset the connection
	useEffect(() => {
		if (notificationsStatus !== NotificationsStatus.NotSet) {
			dispatch(notificationsActionCreators.setNotificationStatus(NotificationsStatus.Disconnected));
			setRetryTimestamp(new Date().getTime());
		}
	}, [accessToken]);

	const isImpersonating = useRef(false);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	useEffect(() => {
		isImpersonating.current = ClaimUtil.validateHasClaim(user, ClaimType.Impersonation);
	}, [user]);

	useEffect(() => {
		//used to check if this connection was removed(connection events cannot be detached manually)
		let connectionRemoved = false;

		//connection
		const connection = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_NOTIFICATION_SERVICE}/hubs/notificationHub`, {
				accessTokenFactory: () => accessToken,
			})
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: (retryContext) =>
					getRetryDelayInMilliseconds(retryContext.previousRetryCount),
			})
			.build();

		//hub events
		if (featuresSettings[EasyTrackFeature.Alerts]) {
			connection.on(clientEvents.updateAlertsNotification, () => {
				if (!connectionRemoved) {
					dispatch(notificationsActionCreators.receivedAlertsNotification());
				}
			});
		}
		connection.on(clientEvents.groupAuthorizationUpdate, () => {
			ajaxUtil
				.get<boolean>(
					`${GlobalSettings.validateApi}/${currentSessionRef.current.personId}/hasGroupsAuthorization`
				)
				.then((result) => {
					dispatch(userDataActionCreators.setGroupAuthorisation(result));
					dispatch(fleetSelectionActionCreator.setRecheckTimestamp(new Date().getTime().toString()));
				});
		});

		connection.on(clientEvents.logoutUser, () => {
			if (!isImpersonating.current) {
				dispatch({
					type: SET_TRIP_UNSAVED_DATA,
					payload: false,
				});
				dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));

				//needs timeout in order for the unsaved state to be updated in store
				setTimeout(() => {
					window.location.href = '/logout';
				}, 500);
			}
		});

		//connection events
		connection.onclose((e) => {
			if (!connectionRemoved) {
				dispatch(notificationsActionCreators.setNotificationStatus(NotificationsStatus.Disconnected));

				setTimeout(() => {
					setRetryTimestamp(new Date().getTime());
				}, getRetryDelayInMilliseconds(0));
			}
		});
		connection.onreconnected((e) => {
			if (!connectionRemoved) {
				dispatch(
					notificationsActionCreators.setNotificationStatus(
						NotificationsStatus.Connected,
						connection.connectionId
					)
				);

				notificationConnection.current?.invoke(
					serverEvents.checkForUpdates,
					currentSessionRef.current.groupAuthorisationLastCheck
				);
			}
		});
		connection.onreconnecting((e) => {
			if (!connectionRemoved) {
				dispatch(notificationsActionCreators.setNotificationStatus(NotificationsStatus.Disconnected));
			}
		});

		//start connection
		connection
			.start()
			.then(() => {
				//save connection
				notificationConnection.current = connection;
				setRetryCount(0);
				dispatch(
					notificationsActionCreators.setNotificationStatus(
						NotificationsStatus.Connected,
						connection.connectionId
					)
				);

				notificationConnection.current?.invoke(
					serverEvents.checkForUpdates,
					currentSessionRef.current.groupAuthorisationLastCheck
				);
			})
			.catch(() => {
				if (!connectionRemoved) {
					dispatch(notificationsActionCreators.setNotificationStatus(NotificationsStatus.Disconnected));

					const newRetryCount = retryCount + 1;
					setRetryCount(newRetryCount);
					setTimeout(() => {
						setRetryTimestamp(new Date().getTime());
					}, getRetryDelayInMilliseconds(newRetryCount));
				}
			});

		return () => {
			connectionRemoved = true;
			notificationConnection.current && notificationConnection.current.stop();
			notificationConnection.current = null;
		};
	}, [retryTimestamp]);

	return null;
};

export default React.memo(NotificationEngine);
