import './driverView.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import {
	ConflictConfirmation,
	ConnectionsConflictResult,
	Props as ConflictConfirmationProps,
} from 'components/ConnectionConflictsNotifier/ConflictConfirmation';
import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import DevicePropertiesEnum from 'models/DevicePropertiesEnum';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

const DriverView = (props: AddWizardComponentProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [driversData, setDriversData] = useState([]);
	const [conflictConfirmationProps, setConflictConfirmationProps] = useState<ConflictConfirmationProps>({
		visible: false,
		confirmCallback: null,
		canSolveConflicts: true,
		conflictData: [],
		connectionType: ConnectionTypeEnum.DeviceObject,
	});
	const [personId, setPersonId] = useState<string | null>(null);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const validator: Validator = new Validator({
		fieldRules: {
			personId: {
				rules: {
					anyConflicts: {
						message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
							TranslateText('connections.driver'),
						]),
						validationFunction: (data, fieldName) => {
							return checkDriverConflicts(data[fieldName]);
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		const filter = {
			fieldType: FilterFieldTypeEnum.customerId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: defaultCustomerId,
			display: DevicePropertiesEnum.Code,
		};
		ajaxUtil
			.get<AutocompleteItem[]>(`${GlobalSettings.listsApi}/Drivers?filter=${JSON.stringify(filter)}`)
			.then((data) => {
				setDriversData(data);
			});
	}, []);

	useEffect(() => {
		if (!props.visible) return;

		validator.validate({ personId: personId }).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			props.onChangeCallback({ personId: personId });
		});
	}, [personId]);

	const checkDriverConflicts = async (personId: string): Promise<boolean> => {
		if (!personId) {
			return true;
		}

		const candidateConnection = {
			entity1Id: personId,
			entity2Id: undefined as string,
			connectionStartDate: new Date(),
			connectionType: ConnectionTypeEnum.PersonObject,
		};

		setIsLoading(true);
		const result = await ajaxUtil.post<ConnectionsConflictResult>(
			`${GlobalSettings.connectionsApi}/ValidateEntitiesCandidateConnection`,
			candidateConnection
		);
		setIsLoading(false);

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				setConflictConfirmationProps({
					visible: true,
					canSolveConflicts: result.fixableConflict,
					connectionType: ConnectionTypeEnum.PersonObject,
					conflictData: result.conflictingConnections,
					confirmCallback: (confirm: boolean) => {
						setConflictConfirmationProps({
							...conflictConfirmationProps,
							visible: false,
						});
						resolve(confirm);
					},
				});
			});
		} else {
			return true;
		}
	};

	return (
		<div className={'driver-view-container'}>
			<div className={'column-title'}>{TranslateText('common.driver')}</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={personId}
					dataSource={driversData}
					disabled={isLoading}
					onChange={({ value }) => setPersonId(value as string)}
					className={'material-autocomplete'}
					label={TranslateText('connections.selectDriverPlaceholder')}
					isForNewAddWizard={true}
					validationResult={validationResult?.personId}
				/>
			</div>
			<ConflictConfirmation {...conflictConfirmationProps} />
		</div>
	);
};

export default DriverView;
