import IdIdentifiable from './IdIdentifiable';

class Group extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.customerId = customerId;
	}

	code: string;
	customerId: string;
	active = true;
	name: string;
	description: string;
	membersCount: string;
	personsCount: number;
}

export default Group;
