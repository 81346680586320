import './HistoryOverviewGrid.scss';

import { tripHistorySorting } from 'components/RightSidebarMenu/HistoryTripsUtils';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTripVisitTime } from 'utils/TripUtils';
import XLSX from 'xlsx';

import { IconButton, LinearProgress, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import ClaimType from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import PrivacySettingsEnum from '../../models/PrivacySettingsEnum';
import { TripTypeHelper } from '../../models/TripTypesEnum';
import DateTimeUtil from '../../shared/datetime/DateTimeUtil';
import { useLocationsHistory } from '../../shared/effects/useShowLastEntities';
import { ApplicationState } from '../../store';
import { HISTORY_SET_SAVE_XLSX, ProcessedTripDto } from '../../store/HistoryStore';
import { CalculateMaxTotals, CalculateSumTotals } from '../../utils/DataDisplayUtil';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import timeUtil, { secondsToIntervalStringVisitTime } from '../Common/DateTimeUtilFunctions';
import { getShortTimeZoneFormat, shouldNotDisplayTimeZone } from '../ObjectNameWithTimeZoneOffset/Utils';
import HistoryGroupingTableBody from './HistoryGroupingTableBody';
import { HistoryTimeRange } from './HistoryTimeRange';

export interface IGrouping<T> {
	[key: string]: {
		expanded: boolean;
		data: T;
	};
}

export interface TableData<T1, T2> {
	colSpan: number;
	GetTotalsFromTrips: (data: T1[]) => T2;
	CalculateTotals: (data: T2[]) => T2;
	formatText: (total: T2) => string;
}

export interface TotalResult {
	show: boolean;
	total: number;
}

export enum SortingColumn {
	Departure,
	Arrival,
}

const privateDataMask = '****';

export type GroupedGridData = IGrouping<IGrouping<IGrouping<IGrouping<ProcessedTripDto[]>>>>;

const groupingData: TableData<ProcessedTripDto, TotalResult>[] = [
	{
		colSpan: 1,
		CalculateTotals: (drivingTimes: TotalResult[]): TotalResult => {
			const totals = drivingTimes.filter((x) => x.show).map((x) => x.total);
			return totals.length ? { show: true, total: CalculateSumTotals(totals) } : { show: false, total: 0 };
		},
		GetTotalsFromTrips: (trips: ProcessedTripDto[]): TotalResult => {
			let show = false;
			const driving = trips.map((t) => {
				show = !t.isNonTrip;
				return (!t.endTripTime && !t.startTripTime) || t.isNonTrip
					? 0
					: moment(t.endTripTime).diff(moment(t.startTripTime));
			});

			return {
				show: show,
				total: show ? CalculateSumTotals(driving) : 0,
			};
		},
		formatText: (totalResult: TotalResult): string => {
			return totalResult.show ? secondsToIntervalStringVisitTime(totalResult.total / 1000) : '';
		},
	},
	{
		colSpan: 3,
		CalculateTotals: (visitTimes: TotalResult[]): TotalResult => {
			const totals = visitTimes.filter((x) => x.show).map((x) => x.total);
			return totals.length ? { show: true, total: CalculateSumTotals(totals) } : { show: false, total: 0 };
		},
		GetTotalsFromTrips: (trips: ProcessedTripDto[]): TotalResult => {
			let show = false;
			const visits = trips.map((t) => {
				show = !t.isNonTrip;
				return !t.isInProgress || !t.isNonTrip ? t.visitTime : 0;
			});
			return {
				show: show,
				total: show ? CalculateSumTotals(visits) : 0,
			};
		},
		formatText: (totalResult: TotalResult): string => {
			if (!totalResult.show || totalResult.total === 0) {
				return '';
			}
			return secondsToIntervalStringVisitTime(totalResult.total);
		},
	},
	{
		colSpan: 1,
		CalculateTotals: (distances: TotalResult[]): TotalResult => {
			const totals = distances.filter((x) => x.show).map((x) => x.total);
			return totals.length ? { show: true, total: CalculateSumTotals(totals) } : { show: false, total: 0 };
		},
		GetTotalsFromTrips: (trips: ProcessedTripDto[]): TotalResult => {
			let show = false;
			const distances = trips.map((t) => {
				show = !t.isNonTrip;
				return !t.isNonTrip ? t.endMileage - t.startMileage : 0;
			});
			return {
				show: show,
				total: show ? CalculateSumTotals(distances) : 0,
			};
		},
		formatText: (totalResult: TotalResult): string => {
			return totalResult.show ? totalResult.total.toFixed(1) : '';
		},
	},
	{
		colSpan: 1,
		CalculateTotals: (maxSpeeds: TotalResult[]): TotalResult => {
			const totals = maxSpeeds.filter((x) => x.show).map((x) => x.total);
			return totals.length ? { show: true, total: CalculateMaxTotals(totals) } : { show: false, total: 0 };
		},
		GetTotalsFromTrips: (trips: ProcessedTripDto[]): TotalResult => {
			let show = false;
			const maxSpeeds = trips.map((t) => {
				show = !t.isNonTrip;
				return !t.isInProgress && !t.isNonTrip ? t.maxSpeed : 0;
			});
			return {
				show: show,
				total: show ? CalculateMaxTotals(maxSpeeds) : 0,
			};
		},
		formatText: (totalResult: TotalResult): string => {
			return totalResult.show ? totalResult.total.toFixed(0) : '';
		},
	},
];

export const getEndTime = (
	customerLevel: PrivacySettingsEnum,
	trip: ProcessedTripDto,
	customerTimezoneId: string
): string => {
	if (customerLevel === PrivacySettingsEnum.HidePrivateLocationAndTime && !trip.endTripTime) {
		return privateDataMask;
	}

	if (!shouldNotDisplayTimeZone(trip.timezoneId, customerTimezoneId)) {
		return (
			moment.utc(trip.endTripTime).format(DateTimeUtil.momentTimeFormatHoursMinutes()) +
			' ' +
			getShortTimeZoneFormat(trip.timeZoneMinutesOffset)
		);
	}

	return moment.utc(trip.endTripTime).format(DateTimeUtil.momentTimeFormatHoursMinutes());
};

export const getStartTime = (
	customerPrivacyLevel: PrivacySettingsEnum,
	trip: ProcessedTripDto,
	customerTimezoneId: string
): string => {
	if (customerPrivacyLevel === PrivacySettingsEnum.HidePrivateLocationAndTime && !trip.startTripTime) {
		return privateDataMask;
	}

	if (!shouldNotDisplayTimeZone(trip.timezoneId, customerTimezoneId)) {
		return (
			moment.utc(trip.startTripTime).format(DateTimeUtil.momentTimeFormatHoursMinutes()) +
			' ' +
			getShortTimeZoneFormat(trip.timeZoneMinutesOffset)
		);
	}

	return moment.utc(trip.startTripTime).format(DateTimeUtil.momentTimeFormatHoursMinutes());
};

export const historyGridUtil = {
	generateXlsx: (
		data: ProcessedTripDto[],
		driverIdentification: boolean,
		trackTypeSpecification: boolean,
		customerPrivacyLevel: PrivacySettingsEnum,
		ascendingDeparture: boolean,
		ascendingArrival: boolean,
		sortingColumn: SortingColumn,
		personId: string,
		userCanSeePrivateData: boolean,
		showLocationsHistory: boolean,
		customerTimezoneId: string,
	) => {
		const sheetName = TranslateText('common.historyTripsSheetName');
		const wb = XLSX.utils.book_new();
		wb.SheetNames = [sheetName];
		const sortedData = historyGridUtil.getSortedData(data, ascendingDeparture, ascendingArrival, sortingColumn);
		wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(
			sortedData.map((trip) => {
				const result: { [key: string]: string | boolean } = {};
				result[TranslateText('maintenanceOverview.grid.colStatus')] = trip.isInProgress
					? TranslateText('common.inProgress')
					: TranslateText('common.complete');
				result[TranslateText('maintenanceOverview.grid.colDeparture')] = FormatDate(
					new Date(trip.startTrip),
					true,
					true,
					true
				);
				result[TranslateText('maintenanceOverview.grid.colDepartureTime')] = getStartTime(
					customerPrivacyLevel,
					trip,
					customerTimezoneId
				);

				if (!trip.isInProgress) {
					result[TranslateText('maintenanceOverview.grid.colArrival')] = FormatDate(
						new Date(trip.endTrip),
						true,
						true,
						true
					);
					result[TranslateText('maintenanceOverview.grid.colArrivalTime')] = getEndTime(
						customerPrivacyLevel,
						trip,
						customerTimezoneId
					);
				} else {
					result[TranslateText('maintenanceOverview.grid.colArrival')] = '';
					result[TranslateText('maintenanceOverview.grid.colArrivalTime')] = '';
				}

				result[TranslateText('maintenanceOverview.grid.colObjectName')] = trip.objectName;
				if (driverIdentification) {
					result[TranslateText('maintenanceOverview.grid.colDriverName')] = !trip.isNonTrip
						? trip.personName
						: '';
				}
				result[TranslateText('maintenanceOverview.grid.colStartAddress')] = showLocationsHistory
					? trip?.startLocationAddress
					: trip?.startAddress;
				result[TranslateText('maintenanceOverview.grid.colEndAddress')] = trip.isNonTrip
					? ''
					: showLocationsHistory
					? trip?.endLocationAddress
					: trip?.endAddress;
				if (trackTypeSpecification) {
					result[TranslateText('maintenanceOverview.grid.colTrackType')] = trip.isNonTrip
						? ''
						: TripTypeHelper.toString(trip.trackType);
				}

				result[TranslateText('maintenanceOverview.grid.colDrivingTime')] = trip.isNonTrip
					? ''
					: customerPrivacyLevel === PrivacySettingsEnum.HidePrivateLocationAndTime &&
					  !trip.startTripTime &&
					  !trip.endTripTime
					? privateDataMask
					: secondsToIntervalStringVisitTime(
							moment(trip.endTripTime).diff(moment(trip.startTripTime)) / 1000
					  );
				result[TranslateText('maintenanceOverview.grid.colDrivingTimeInSeconds')] = trip.isNonTrip
					? ''
					: customerPrivacyLevel === PrivacySettingsEnum.HidePrivateLocationAndTime &&
					  !trip.startTripTime &&
					  !trip.endTripTime
					? privateDataMask
					: timeUtil.getDiffInSeconds(new Date(trip.startTripTime), new Date(trip.endTripTime));
				result[TranslateText('maintenanceOverview.grid.colVisit')] = getTripVisitTime(
					trip,
					customerPrivacyLevel,
					personId,
					userCanSeePrivateData,
					true
				);
				result[TranslateText('maintenanceOverview.grid.colVisitInSeconds')] = getTripVisitTime(
					trip,
					customerPrivacyLevel,
					personId,
					userCanSeePrivateData,
					false
				);
				result[TranslateText('maintenanceOverview.grid.colOdometerStart')] = trip.isNonTrip
					? ''
					: Math.floor(trip.startMileage).toString();

				result[TranslateText('maintenanceOverview.grid.colOdometerEnd')] = trip.isNonTrip
					? ''
					: !trip.isInProgress
					? Math.floor(trip.endMileage).toString()
					: '';
				result[TranslateText('maintenanceOverview.grid.colDistance')] = trip.isNonTrip
					? ''
					: (trip.endMileage - trip.startMileage).toFixed(1);
				result[TranslateText('maintenanceOverview.grid.colMaxSpeed')] = trip.isNonTrip
					? ''
					: !trip.isInProgress
					? trip.maxSpeed.toFixed(0)
					: '';

				return result;
			})
		);
		XLSX.writeFile(wb, TranslateText('common.historyTripsExportFileName') + '.xlsx');
	},
	crateOneLevelGrouping: <T,>(trips: T[], getKey: (trip: T) => string): IGrouping<T[]> => {
		return trips.reduce((grouping, trip) => {
			const key = getKey(trip);
			if (!grouping[key]) {
				grouping[key] = {
					expanded: true,
					data: [trip],
				};
			} else {
				grouping[key].data.push(trip);
			}
			return grouping;
		}, {} as IGrouping<T[]>);
	},

	createMultiLevelGrouping: <T,>(
		groupingLevel: number,
		datasource: T[],
		groupingKeys: ((trip: T) => string)[]
	): IGrouping<any> | T[] => {
		if (groupingLevel === groupingKeys.length) {
			return datasource;
		}
		const grouping = historyGridUtil.crateOneLevelGrouping<T>(datasource, groupingKeys[groupingLevel]);
		const result: IGrouping<any> = {};
		Object.keys(grouping).forEach((key) => {
			result[key] = {
				expanded: true,
				data: historyGridUtil.createMultiLevelGrouping<T>(groupingLevel + 1, grouping[key].data, groupingKeys),
			};
		});
		return result;
	},

	createGrouping: <T,>(data: T[], groupingKeys: ((trip: T) => string)[]): IGrouping<any> | T[] => {
		return historyGridUtil.createMultiLevelGrouping<T>(0, data, groupingKeys);
	},

	getSortedData: (
		data: ProcessedTripDto[],
		ascendingDeparture: boolean,
		ascendingArrival: boolean,
		sortingColumn: SortingColumn,
	): ProcessedTripDto[] => {
		let sortGridData: ProcessedTripDto[] = [];
		if (sortingColumn === SortingColumn.Departure) {
			sortGridData = ascendingDeparture
				? [...data].sort(tripHistorySorting.sortAscForDateGrouping)
				: [...data].sort(tripHistorySorting.sortDescForDateGrouping);
		}
		if (sortingColumn === SortingColumn.Arrival) {
			sortGridData = ascendingArrival
				? [...data].sort(tripHistorySorting.sortAscForEndDateGrouping)
				: [...data].sort(tripHistorySorting.sortDescForEndDateGrouping);
		}
		return sortGridData;
	},

	getGroupHeaderHeight: (driverIdentification: boolean, defaultCustomerTrackTypeSpecification: boolean): number => {
		//The initial span for the grouping name
		let colSpan = 8;
		//Remove 1 if the driver column is not visible
		if (!driverIdentification) {
			colSpan -= 1;
		}
		//Remove 1 if the track type column is not visible
		if (!defaultCustomerTrackTypeSpecification) {
			colSpan -= 1;
		}
		return colSpan;
	},
};

export const HistoryOverviewGrid = () => {
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer?.featuresSettings
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const defaultCustomerTrackTypeSpecification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.trackTypeSpecification
			: state.currentSession.customer?.featuresSettings?.trackTypeSpecification
	);
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);
	const filterCustomer = useSelector((state: ApplicationState) => state.globalCustomer.filteredCustomer);
	const selectedTabs = useSelector((state: ApplicationState) => state.historyStore.tripSelectedIds);
	const saveXlsx = useSelector((state: ApplicationState) => state.historyStore.saveXlsxFile);
	const historyGridTrips = useSelector((state: ApplicationState) => state.historyStore.historyGridTrips);
	const selectedTimeRange = useSelector((state: ApplicationState) => state.historyFilter.dateFilter.historyTimeRange);
	const tripsLoading = useSelector((state: ApplicationState) => state.liveMap.loading);

	const showLocationsHistory = useLocationsHistory();
	const [visibleTrips, setVisibleTrips] = useState<ProcessedTripDto[]>([]);
	const [ascendingDeparture, setAscendingDeparture] = useState(false);
	const [ascendingArrival, setAscendingArrival] = useState(false);
	const [sortingColumn, setSortingColumn] = useState(SortingColumn.Departure);
	const [groupData, setGroupData] = useState<GroupedGridData>({});
	const dispatch = useDispatch();
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const headerRowRef = useRef<HTMLElement>();
	const [linearProgressTopOffset, setLinearProgressTopOffset] = useState<number>(
		headerRowRef?.current?.offsetHeight ?? 22
	);
	const resizableExpanded = useSelector(
		(s: ApplicationState) => !s.resizableEntity.resizableMenuHistoryGrid.isCollapsed
	);
	const customerTimezoneId = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	useEffect(() => {
		setVisibleTrips(historyGridTrips.filter((t) => !t.isParked));
	}, [historyGridTrips]);

	useEffect(() => {
		//Clear data when switching impersonation
		if (!filterCustomer && visibleTrips) {
			setGroupData({});
		}
	}, [filterCustomer]);

	const resetLinearProgressTopOffset = () => {
		setTimeout(() => setLinearProgressTopOffset(headerRowRef?.current?.offsetHeight ?? 22), 0);
	};
	useEffect(() => {
		window.addEventListener('resize', resetLinearProgressTopOffset);
	}, []);

	useEffect(() => {
		resetLinearProgressTopOffset();
	}, [headerRowRef?.current?.offsetHeight, resizableExpanded]);

	useEffect(() => {
		if (visibleTrips && visibleTrips.length) {
			const sortGridData = historyGridUtil.getSortedData(
				visibleTrips,
				ascendingDeparture,
				ascendingArrival,
				sortingColumn
			);
			setGroupData(
				historyGridUtil.createGrouping(sortGridData, [
					(trip) => {
						if (selectedTimeRange === HistoryTimeRange.Custom) {
							return TranslateText('common.customTimeRangeHeader');
						} else if (selectedTimeRange === HistoryTimeRange.Week) {
							return `${moment().format('MMMM')}`;
						}
						return `${moment.utc(trip.startTrip).format('MMMM')}`;
					},
					(trip) => `${TranslateText('common.week')} ${moment.utc(trip.startTrip).format('ww')}`,
					(trip) => `${FormatDate(trip.startTrip, true, true, true)}`,
					(trip) => (trip.isPerson ? trip.personName : trip.objectName),
				]) as GroupedGridData
			);
		} else {
			setGroupData({});
		}
	}, [visibleTrips, ascendingDeparture, ascendingArrival, sortingColumn]);

	useEffect(() => {
		if (saveXlsx) {
			historyGridUtil.generateXlsx(
				visibleTrips,
				driverIdentification,
				defaultCustomerTrackTypeSpecification,
				customerPrivacySettings,
				ascendingDeparture,
				ascendingArrival,
				sortingColumn,
				personId,
				userCanSeePrivateData,
				showLocationsHistory,
				customerTimezoneId,
			);
			dispatch({
				type: HISTORY_SET_SAVE_XLSX,
				payload: false,
			});
		}
	}, [saveXlsx]);

	return (
		<div className={'flex-grid-container'}>
			<TableContainer className={'flex-frid-table-container'}>
				<Table stickyHeader={true} className={'grouped-trip-grid'} size={'small'}>
					<TableHead className={'grouped-trip-header'}>
						<TableRow innerRef={headerRowRef}>
							<TableCell width={30}>{TranslateText('maintenanceOverview.grid.colStatus')}</TableCell>
							<TableCell width={100} className={'grouped-trip-header-filter-col'}>
								<span>{TranslateText('maintenanceOverview.grid.colDeparture')}</span>
								<IconButton
									size={'small'}
									className={'sort-order-icon'}
									onClick={() => {
										setSortingColumn(SortingColumn.Departure);
										setAscendingDeparture(!ascendingDeparture);
									}}
								>
									{ascendingDeparture ? (
										<ExpandLess color={'primary'} />
									) : (
										<ExpandMore color={'primary'} />
									)}
								</IconButton>
							</TableCell>
							<TableCell width={100} className={'grouped-trip-header-filter-col'}>
								<span>{TranslateText('maintenanceOverview.grid.colArrival')}</span>
								<IconButton
									size={'small'}
									className={'sort-order-icon'}
									onClick={() => {
										setSortingColumn(SortingColumn.Arrival);
										setAscendingArrival(!ascendingArrival);
									}}
								>
									{ascendingArrival ? (
										<ExpandLess color={'primary'} />
									) : (
										<ExpandMore color={'primary'} />
									)}
								</IconButton>
							</TableCell>
							<TableCell width={75}>{TranslateText('maintenanceOverview.grid.colObjectName')}</TableCell>
							{driverIdentification && (
								<TableCell width={100}>
									{TranslateText('maintenanceOverview.grid.colDriverName')}
								</TableCell>
							)}
							<TableCell width={200}>
								{TranslateText('maintenanceOverview.grid.colStartAddress')}
							</TableCell>
							<TableCell width={200}>{TranslateText('maintenanceOverview.grid.colEndAddress')}</TableCell>
							{defaultCustomerTrackTypeSpecification && (
								<TableCell width={75}>
									{TranslateText('maintenanceOverview.grid.colTrackType')}
								</TableCell>
							)}
							<TableCell width={75}>{TranslateText('maintenanceOverview.grid.colDrivingTime')}</TableCell>
							<TableCell width={75}>{TranslateText('maintenanceOverview.grid.colVisit')}</TableCell>
							<TableCell width={50}>
								{TranslateText('maintenanceOverview.grid.colOdometerStart')}
							</TableCell>
							<TableCell width={50}>{TranslateText('maintenanceOverview.grid.colOdometerEnd')}</TableCell>
							<TableCell width={50}>{TranslateText('maintenanceOverview.grid.colDistance')}</TableCell>
							<TableCell width={70}>{TranslateText('maintenanceOverview.grid.colMaxSpeed')}</TableCell>
							<TableCell width={10} className={'grid-headers-empty-column'} />
						</TableRow>
						<TableRow>
							<TableCell
								colSpan={99}
								style={{
									top: linearProgressTopOffset,
									padding: 0,
									border: 'none',
									backgroundColor: 'white',
								}}
							>
								{tripsLoading ? (
									<LinearProgress
										style={{
											backgroundColor: '#FFFFFF',
											width: '100%',
										}}
									/>
								) : (
									<div style={{ height: 4 }} />
								)}
							</TableCell>
						</TableRow>
					</TableHead>
					<HistoryGroupingTableBody
						data={groupData}
						groupingData={groupingData}
						nameSpan={historyGridUtil.getGroupHeaderHeight(
							driverIdentification,
							defaultCustomerTrackTypeSpecification
						)}
						multipleEntitiesSelected={selectedTabs.length > 1}
						setData={setGroupData}
					/>
				</Table>
			</TableContainer>
		</div>
	);
};
