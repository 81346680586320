import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { TranslateText } from 'utils/Translations';

import { Box } from '@material-ui/core';

import { useLogWidgetMessagesList } from './hooks';
import { LogWidgetMessagesListFooter } from './LogWidgetMessagesListFooter';
import { LogWidgetMessagesListProps } from './types';

const LogWidgetMessagesList = ({
	totalLogMessages,
	logMessages,
	allRecordsLoaded,
	getLogsData,
	handleTripDownload,
}: LogWidgetMessagesListProps): JSX.Element => {
	const scrollRef: React.RefObject<HTMLElement> = useBottomScrollListener(
		() => {
			if (totalLogMessages !== 0) {
				getLogsData();
			}
		},
		{ triggerOnNoScroll: false, offset: 5 }
	);

	const {
		detailsColumn,
		gpsColumn,
		ignitionColumn,
		ioColumn,
		mileageColumn,
		tripColumn,
		tripDownloadColumn,
	} = useLogWidgetMessagesList(handleTripDownload);

	return (
		<div className="grid-host" ref={scrollRef as React.RefObject<HTMLDivElement>}>
			<Box component="span" id={'move-up-hook'} />
			<DataTable
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				emptyMessage={TranslateText('common.noData')}
				className={'messageGrid'}
				style={allRecordsLoaded && totalLogMessages > 4 ? { height: 'auto' } : { height: '100%' }}
				value={logMessages}
				selectionMode={'single'}
				dataKey={'id'}
				footer={() => (
					<LogWidgetMessagesListFooter totalLogMessages={totalLogMessages} logMessages={logMessages} />
				)}
				currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
				lazy
				virtualScrollerOptions={{ itemSize: 40 }}
			>
				<Column field="id" style={{ display: 'none' }} />
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colDetails')}
					style={{ textAlign: 'left' }}
					body={detailsColumn}
				/>
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colGPS')}
					style={{ textAlign: 'left' }}
					body={gpsColumn}
				/>
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colMileage')}
					style={{ textAlign: 'left', width: '150px' }}
					body={mileageColumn}
				/>
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colIO')}
					style={{ textAlign: 'left', width: '170px' }}
					body={ioColumn}
				/>
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colIgnition')}
					style={{ textAlign: 'left', width: '70px' }}
					body={ignitionColumn}
				/>
				<Column
					className="logs-grid-column"
					header={TranslateText('widgets.grid.colTrip')}
					style={{ textAlign: 'left' }}
					body={tripColumn}
				/>
				<Column
					className="logs-grid-column middle"
					header={TranslateText('widgets.grid.colJSON')}
					style={{ textAlign: 'left', width: '70px' }}
					body={tripDownloadColumn}
				/>
			</DataTable>
			<Box component="span" id={'move-down-hook'} />
		</div>
	);
};

export default LogWidgetMessagesList;
