import { Column } from 'primereact/column';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import ConnectionValidationResult from '../../models/ConnectionValidationResult';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { GridWidgetOverview } from '../GridOverview/GridWidgetOverview';
import GenericConnectionsConflictResolver from './GenericConnectionsConflictResolver';

class CustomerConflictsNotifier extends React.PureComponent<
	{
		userAnswearCallback: (sender: GenericConnectionsConflictResolver, confirm: boolean) => void;
	},
	{
		conflictContext: ConnectionValidationResult;
		showDialog: boolean;
	}
> {
	private sender: GenericConnectionsConflictResolver;

	constructor(props: any) {
		super(props);
		this.state = {
			conflictContext: new ConnectionValidationResult(),
			showDialog: false,
		};
	}

	setConflictingConnectionsContext(conflictContext: ConnectionValidationResult) {
		this.setState({ conflictContext: conflictContext });
	}

	showDialog(sender?: GenericConnectionsConflictResolver) {
		this.sender = sender;
		this.setState({ showDialog: true });
	}

	getDialogTextContent(): string {
		if (!this.state.conflictContext) {
			return '';
		}
		if (this.state.conflictContext.fixableConflict) {
			return TranslateText('connections.moveToCustomerConfirmWithDependencies');
		} else {
			return TranslateText('connections.moveToCustomerForbiden');
		}
	}

	getColumns() {
		return [
			<Column
				key="formatedDisplay"
				field="formatedDisplay"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colDescription')}
				style={{ width: 300 }}
			/>,
			<Column
				key="connectionStartDate"
				field="connectionStartDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colStartDate')}
				style={{ width: 150 }}
				body={(rowData: any) => {
					return FormatDate(new Date(rowData.connectionStartDate));
				}}
			/>,
			<Column
				key="connectionEndDate"
				field="connectionEndDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colEndDate')}
				style={{ width: 150 }}
				body={(rowData: any) => {
					return rowData.connectionEndDate ? FormatDate(new Date(rowData.connectionEndDate)) : null;
				}}
			/>,
			<Column
				key="connectionAction"
				field="connectionAction"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colAction')}
				style={{ width: 150, whiteSpace: 'normal' }}
			/>,
		];
	}

	render() {
		return (
			<Dialog
				open={this.state.showDialog}
				className="connection-dialog"
				fullWidth
				disableBackdropClick
				maxWidth="lg"
			>
				<DialogTitle>{TranslateText('connections.connectionConflicts')}</DialogTitle>
				<DialogContent>
					<div style={{ fontSize: '16px', marginBottom: '10px' }}>{this.getDialogTextContent()}</div>
					<GridWidgetOverview
						columns={this.getColumns()}
						data={this.state.conflictContext.conflictingConnections}
						total={this.state.conflictContext.conflictingConnections?.length ?? 0}
						paginator={false}
					/>
					<DialogActions>
						{this.state.conflictContext.fixableConflict ? (
							<>
								<Button
									color={'primary'}
									onClick={() => {
										this.setState({ showDialog: false });
										this.props.userAnswearCallback(this.sender, true);
									}}
								>
									{TranslateText('common.buttonYes')}
								</Button>
								<Button
									onClick={() => {
										this.setState({ showDialog: false });
										this.props.userAnswearCallback(this.sender, false);
									}}
								>
									{TranslateText('common.buttonNo')}
								</Button>
							</>
						) : (
							<>
								<Button
									onClick={() => {
										this.setState({ showDialog: false });
										this.props.userAnswearCallback(this.sender, false);
									}}
								>
									{TranslateText('common.buttonOk')}
								</Button>
							</>
						)}
					</DialogActions>
				</DialogContent>
			</Dialog>
		);
	}
}

export default CustomerConflictsNotifier;
