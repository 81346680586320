import { ClaimUtil } from 'authorization/ClaimUtil';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ClaimType, { ClaimValue, ClaimValueAlertTypes, ClaimValueTypes } from '../../../authorization/ClaimType';

const useAlertNotificationClaims = () => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const [alertClaims, setAlertClaims] = useState<ClaimValueAlertTypes>({
		mailAlert: true,
		notificationAlert: true,
		smsAlert: true,
		whatsAppAlert: true,
	});

	useEffect(() => {
		const tempClaims = { ...alertClaims };

		for (const alert of Object.keys(alertClaims)) {
			tempClaims[alert as keyof ClaimValueAlertTypes] = ClaimUtil.validateClaim(user, {
				claim: ClaimType.Alerts,
				values: [ClaimValue[alert as ClaimValueTypes]],
			});
		}
		setAlertClaims(tempClaims);
	}, [user]);

	return { alertClaims };
};

export { useAlertNotificationClaims };
