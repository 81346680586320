import ObjectTypeEnum from '../../models/ObjectTypeEnum';
import { TranslateText } from '../../utils/Translations';

const ObjectAssetTypeDatasource = {
	dataSource: () => [
		{
			id: ObjectTypeEnum.Unknown,
			display: TranslateText('objects.objectType.Unknown'),
		},
		{
			id: ObjectTypeEnum.AirCompressor,
			display: TranslateText('objects.objectType.AirCompressor'),
		},
		{
			id: ObjectTypeEnum.AirTool,
			display: TranslateText('objects.objectType.AirTool'),
		},
		{
			id: ObjectTypeEnum.CuttingTool,
			display: TranslateText('objects.objectType.CuttingTool'),
		},
		{
			id: ObjectTypeEnum.Drill,
			display: TranslateText('objects.objectType.Drill'),
		},
		{
			id: ObjectTypeEnum.Grinder,
			display: TranslateText('objects.objectType.Grinder'),
		},
		{
			id: ObjectTypeEnum.LaserAndInstrument,
			display: TranslateText('objects.objectType.LaserAndInstrument'),
		},
		{
			id: ObjectTypeEnum.NailerAndStapler,
			display: TranslateText('objects.objectType.NailerAndStapler'),
		},
		{
			id: ObjectTypeEnum.OutdoorPowerEquipment,
			display: TranslateText('objects.objectType.OutdoorPowerEquipment'),
		},
		{
			id: ObjectTypeEnum.TubingTool,
			display: TranslateText('objects.objectType.TubingTool'),
		},
		{
			id: ObjectTypeEnum.HighPressureCleaner,
			display: TranslateText('objects.objectType.HighPressureCleaner'),
		},
		{
			id: ObjectTypeEnum.PowerTool,
			display: TranslateText('objects.objectType.PowerTool'),
		},
		{
			id: ObjectTypeEnum.HandTool,
			display: TranslateText('objects.objectType.HandTool'),
		},
		{
			id: ObjectTypeEnum.Sander,
			display: TranslateText('objects.objectType.Sander'),
		},
		{
			id: ObjectTypeEnum.Chainsaw,
			display: TranslateText('objects.objectType.Chainsaw'),
		},
		{
			id: ObjectTypeEnum.Screwdriver,
			display: TranslateText('objects.objectType.Screwdriver'),
		},
		{
			id: ObjectTypeEnum.DewateringPump,
			display: TranslateText('objects.objectType.DewateringPump'),
		},
		{
			id: ObjectTypeEnum.PowerGenerator,
			display: TranslateText('objects.objectType.PowerGenerator'),
		},
		{
			id: ObjectTypeEnum.DustManagement,
			display: TranslateText('objects.objectType.DustManagement'),
		},
		{
			id: ObjectTypeEnum.FallProtection,
			display: TranslateText('objects.objectType.FallProtection'),
		},
		{
			id: ObjectTypeEnum.Ladder,
			display: TranslateText('objects.objectType.Ladder'),
		},
		{
			id: ObjectTypeEnum.Scaffolding,
			display: TranslateText('objects.objectType.Scaffolding'),
		},
		{
			id: ObjectTypeEnum.Sandblaster,
			display: TranslateText('objects.objectType.Sandblaster'),
		},
		{
			id: ObjectTypeEnum.GlassStand,
			display: TranslateText('objects.objectType.GlassStand'),
		},
		{
			id: ObjectTypeEnum.Battery,
			display: TranslateText('objects.objectType.Battery'),
		},
		{
			id: ObjectTypeEnum.WeldingTool,
			display: TranslateText('objects.objectType.WeldingTool'),
		},
		{
			id: ObjectTypeEnum.Trolley,
			display: TranslateText('objects.objectType.Trolley'),
		},
		{
			id: ObjectTypeEnum.LawnCare,
			display: TranslateText('objects.objectType.LawnCare'),
		},
		{
			id: ObjectTypeEnum.LiftAndHandling,
			display: TranslateText('objects.objectType.LiftAndHandling'),
		},
		{
			id: ObjectTypeEnum.Tillage,
			display: TranslateText('objects.objectType.Tillage'),
		},
		{
			id: ObjectTypeEnum.Trailer,
			display: TranslateText('objects.objectType.Trailer'),
		},
		{
			id: ObjectTypeEnum.Vehicle,
			display: TranslateText('objects.objectType.Vehicle'),
		},
		{
			id: ObjectTypeEnum.Container,
			display: TranslateText('objects.objectType.Container'),
		},
		{
			id: ObjectTypeEnum.VariousElectric,
			display: TranslateText('objects.objectType.VariousElectric'),
		},
		{
			id: ObjectTypeEnum.VariousNonElectric,
			display: TranslateText('objects.objectType.VariousNonElectric'),
		},
		{
			id: ObjectTypeEnum.Mower,
			display: TranslateText('objects.objectType.Mower'),
		},
	],
};

export default ObjectAssetTypeDatasource;
