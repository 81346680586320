import React from 'react';
import { Duration } from './Duration';
import { EvaluationMomentDropdown } from './EvaluationMomentDropdown';
import { useTimeFrameEvaluation } from './hooks';
import { TimeFrameDropdown } from './TimeFrameDropdown';
import { TimeFrameEvaluationProps } from './types';

const TimeFrameEvaluation = ({
	durationExceededMetadata,
	handleTimeFrameEvaluationChange,
	durationMaxValue,
	validateResult,
	isForNewAddWizard = false,
}: TimeFrameEvaluationProps): JSX.Element => {
	const {
		evaluationMomentId,
		handleOnEvaluationMomentChange,
		handleOnTimeFrameValueChange,
		handleOnDurationChange,
		timeFrameId,
		timeFrameDurationValue,
	} = useTimeFrameEvaluation(durationExceededMetadata, handleTimeFrameEvaluationChange);

	return (
		<>
			<TimeFrameDropdown
				isForNewAddWizard={isForNewAddWizard}
				valueId={timeFrameId}
				handleOnTimeFrameValueChange={handleOnTimeFrameValueChange}
			/>
			<Duration
				isForNewAddWizard={isForNewAddWizard}
				currentValue={timeFrameDurationValue}
				maxValue={durationMaxValue}
				validateResult={validateResult}
				handleOnDurationChange={handleOnDurationChange}
			/>
			<EvaluationMomentDropdown
				isForNewAddWizard={isForNewAddWizard}
				valueId={evaluationMomentId}
				handleOnEvaluationMomentValueChange={handleOnEvaluationMomentChange}
			/>
		</>
	);
};

export default TimeFrameEvaluation;
