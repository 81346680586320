import { Box, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';
import { TranslateText } from 'utils/Translations';
import { DebouncedInput } from 'components/Common/DebouncedInput';
import { LogWidgetInputProps } from './types';
import { useLogWidgetInput } from './hooks';

const LogWidgetInput = ({ debounceTime, onChangeHandler }: LogWidgetInputProps): JSX.Element => {
	const { inputValue, handleOnChange } = useLogWidgetInput(onChangeHandler);

	return (
		<Box className="search-input">
			<DebouncedInput
				debounceTime={debounceTime}
				InputProps={{
					endAdornment: !!inputValue && (
						<IconButton size={'small'} onClick={() => handleOnChange('')}>
							<Clear fontSize={'small'} />
						</IconButton>
					),
				}}
				fullWidth
				id="searchTextInput"
				maxLength={300}
				placeholder={TranslateText('maintenanceOverview.searchBar.search')}
				onChange={handleOnChange}
				value={inputValue}
			/>
		</Box>
	);
};

export default LogWidgetInput;
