import './styles.scss';
import React from 'react';
import { TranslateMonth, TranslateText } from 'utils/Translations';
import { ObjectTemplatesAggregation } from 'models/ObjectTemplatesAggregation';
import { TemplateMonthTrend } from 'components/TemplateMonthTrend';
import { GetTemplateHourTranslation } from 'utils/DateUtils';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

const TemplateSummary = ({
	templateName,
	totalCurrentLocation,
	totalCurrentYear,
	totalCurrentMonth,
	previousMonthTrends,
	endTripTime,
	timeZoneOffset,
}: ObjectTemplatesAggregation): JSX.Element => {
	const endTripTimeDate = endTripTime ? moment.utc(endTripTime) : moment.utc().utcOffset(timeZoneOffset);

	return (
		<div className={'template-summary'}>
			<div className={'template-summary-row template-name'}>
				<Tooltip title={templateName}>
					<span title={templateName} className={'template-summary-field'}>
						{templateName}
					</span>
				</Tooltip>
			</div>
			<div className={'template-summary-row'}>
				<span className={'template-summary-field'}>
					{TranslateText('objectTemplateSummary.totalCurrentLocation')}
				</span>
				{totalCurrentLocation == 1
					? <span className={'template-summary-value-hour'}>{totalCurrentLocation} {GetTemplateHourTranslation(totalCurrentLocation)}</span>
					: <span className={'template-summary-value'}>{totalCurrentLocation} {GetTemplateHourTranslation(totalCurrentLocation)}</span>
				}
			</div>
			<div className={'template-summary-row'}>
				<span className={'template-summary-field'}>
					{TranslateText('objectTemplateSummary.totalCurrentYear')} ({endTripTimeDate.year()})
				</span>
				{totalCurrentYear == 1
					? <span className={'template-summary-value-hour'}>{totalCurrentYear} {GetTemplateHourTranslation(totalCurrentYear)}</span>
					: <span className={'template-summary-value'}>{totalCurrentYear} {GetTemplateHourTranslation(totalCurrentYear)}</span>
				}
			</div>
			<div className={'template-summary-row'}>
				<span className={'template-summary-field'}>
					{TranslateText('objectTemplateSummary.totalCurrentMonth')} (
					{TranslateMonth(endTripTimeDate.month() + 1)})
				</span>
				{totalCurrentMonth == 1
					? <span className={'template-summary-value-hour'}>{totalCurrentMonth} {GetTemplateHourTranslation(totalCurrentMonth)}</span>
					: <span className={'template-summary-value'}>{totalCurrentMonth} {GetTemplateHourTranslation(totalCurrentMonth)}</span>
				}
			</div>
			{previousMonthTrends.map((d) => (
				<TemplateMonthTrend {...d} key={d.month} />
			))}
		</div>
	);
};

export default TemplateSummary;
