import React from 'react';

interface LabelContentItem {
	label: string,
	content: any,
}

const FieldRow = (props: LabelContentItem) => {
	return props.content ? (
		<div className={'field-row'}>
			<div className={'label-container'}>{props.label}</div>
			<div className={'fields-container'}>
				{props.content}
			</div>
		</div>
	) : null;
}

export default FieldRow;
