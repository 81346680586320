import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tooltip } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';

import RemoveDoneIcon from '../../components/Common/Icons/RemoveDoneIcon';
import { ApplicationState } from '../../store';
import { LiveDataFilterDispatcher } from '../../store/LiveDataFilter';
import { TranslateText } from '../../utils/Translations';
import AutoTextField from '../AutoTextfield/AutoTextField';
import { FleetSelectionButton } from '../FleetSelectionButton';
import { FleetSelectionDialogButtonHelper } from '../FleetSelectionDialog/FleetSelectionDialog';
import RadioButtonContainer from '../RadioButtonContainer';

interface Props {
	selectionTree: ITreeNode[];
	selectAll: () => void;
	deselectAll: () => void;
}

const FleetSelectionFilter = (props: Props) => {
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer.featuresSettings
	);
	const { text, entityType } = useSelector((state: ApplicationState) => state.liveDataFilter);
	const buttonConfig = FleetSelectionDialogButtonHelper.getButtonConfig(
		featuresSettings.driverIdentification,
		featuresSettings.assetTracking
	);
	const dispatch = useDispatch();
	const [allSelected, setAllSelected] = useState(false);

	useEffect(() => {
		setAllSelected(props.selectionTree.every((x) => x.selected));
	}, [props.selectionTree]);

	const searchTextChanged = (value: string) => {
		LiveDataFilterDispatcher.setFilterText(dispatch, value);
	};

	return (
		<div className="header">
			<div className="search">
				<AutoTextField
					placeHolder={TranslateText('fleetSelection.searchEntitiesPlaceholderLiveOverview')}
					onChange={searchTextChanged}
					debounceDuration={100}
					value={text}
					showClearButton={true}
				/>
				<FleetSelectionButton />
			</div>
			<div className="filter">
				{allSelected ? (
					<Tooltip title={TranslateText('fleetSelection.deselectAll')}>
						<Button className="quick-selection" onClick={props.deselectAll}>
							<RemoveDoneIcon />
						</Button>
					</Tooltip>
				) : (
					<Tooltip title={TranslateText('fleetSelection.selectAll')}>
						<Button className="quick-selection" onClick={props.selectAll}>
							<SelectAllIcon />
						</Button>
					</Tooltip>
				)}
				<RadioButtonContainer
					buttons={buttonConfig}
					selectedButton={entityType}
					buttonSelectCallback={(e) => {
						LiveDataFilterDispatcher.setFilterType(dispatch, e);
					}}
				/>
			</div>
		</div>
	);
};

export default FleetSelectionFilter;
