import './buttonWidget.scss';

import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button } from '@material-ui/core';

import EntityTypeEnum from '../../../models/EntityTypeEnum';
import { ApplicationState } from '../../../store';
import { TranslateText } from '../../../utils/Translations';
import ConnectToView from '../CustomerWidget/ConnectToView';
import { IActionButton } from './ButtonWidget';

const mapState = (state: ApplicationState) => {
	return {
		customerId: state.currentSession.customer.id,
	};
};
const connector = connect(mapState, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IActionButton;

const AddSimCardCustomerActionButton = (props: Props) => {
	const [dialogVisible, setDialogVisible] = useState(false);

	const events = {
		saveEntities: () => {
			setDialogVisible(false);
			props.actionExecuted(true);
			props.changeDataCallback(null, true);
			props.editModeCallback(false);
		},
		closeDialog: () => {
			setDialogVisible(false);
		},
	};

	return (
		<>
			<div className="right-side-buttons">
				<Button
					className="widget-button link"
					onClick={() => {
						setDialogVisible(true);
					}}
				>
					{TranslateText('common.connect')}
				</Button>
			</div>
			<span className={dialogVisible ? '' : 'invisible'}>
				<ConnectToView
					entityId={props.entityId}
					entityType={EntityTypeEnum.SimCard}
					dialogCloseCallback={() => events.closeDialog()}
					savedCallback={() => events.saveEntities()}
					showDialog={dialogVisible}
					visible={false}
				/>
			</span>
		</>
	);
};

export default connector(AddSimCardCustomerActionButton);
