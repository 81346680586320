import React, { useCallback, useEffect, useState } from "react";
import {HistoryTemplateDto, HistoryTripTemplateDto, ProcessedTripDto} from "../../store/HistoryStore";
import {MapPosition} from "./RietveldMap";
import {ApplicationState} from "../../store";
import { useSelector } from "react-redux";
import { Polyline } from "@react-google-maps/api";
import {privateDataMask} from "../../utils/TripUtils";
import {LatLng} from "../../store/LiveMap";

interface Props {
	tripConfig: ProcessedTripDto[];
	templates: HistoryTripTemplateDto[];
	isLiveMap: boolean;
	map: google.maps.Map;
	refresh: boolean;
}

interface TemplatePathDto {
	templateId: string;
	highlighted: boolean;
	color: string;
	path: LatLng[];
}

const RietveldHistoryTemplates = ({
		tripConfig,
		templates,
		isLiveMap,
		map,
		refresh
	}: Props) => {

	const highlightedTripIds = useSelector((state: ApplicationState) => state.historyStore.highlightedTripIds);
	const [paths, setPaths] = useState<TemplatePathDto[]>([]);
	const [numberOfPaths, setNumberOfPaths] = useState(0);
	const [strokeWeights, setStrokeWeights] = useState<Record<string, number>>(null);

	useEffect(() => {
		setPathsWithColors();
	}, [tripConfig, templates, refresh,highlightedTripIds]);

	const setPathsWithColors = () => {
		if (map && templates.length && tripConfig.length && !isLiveMap) {
			const bounds = new google.maps.LatLngBounds();

			let newPaths = [] as TemplatePathDto[];
			let count = 0;
			let newStrokeWeights = {} as Record<string, number>;
			for (let i = 0; i < tripConfig.length; i++) {
				const item = tripConfig[i];
				if (
					!item.visible ||
					item.isParked ||
					item?.startAddress === privateDataMask ||
					item?.endAddress === privateDataMask
				) {
					continue;
				}
				const tripTemplates = templates.find((x) => x.tripId === item.id)?.templates;
				if(tripTemplates) {
					for (let j = 0; j < tripTemplates.length; j++) {
						const templatePath = tripTemplates[j].templatePath;
						const templateId = tripTemplates[j].templateId;
						let newPath = [] as LatLng[];
						let color: string = null;
						for (let p = 0; p < templatePath.length; p++) {

							if ((p === 0 && templatePath[p].break) || (p > 0 && templatePath[p - 1].break) || (p>0 && templatePath[p-1].color !== templatePath[p].color)) {
								if (newPath.length > 0) {

									if (!newStrokeWeights.hasOwnProperty(templateId)) {
										count = count + 5;
										newStrokeWeights[templateId] = count;
									}
									if(p > 0 && templatePath[p-1].color !== templatePath[p].color)
										newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
									newPaths.push({templateId: templateId, highlighted: highlightedTripIds.some((historyTrip) => historyTrip === item.idWithParent) , color: color ?? '#4d6d92', path: [...newPath]});
									newPath = [];
								}
								if (templatePath[p].break === false) {
									color = templatePath[p].color;
									newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
								}
							} else {
								if (color === null)
									color = templatePath[p].color;
								newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
							}
						}
						if (newPath.length > 0) {
							newPaths.push({templateId: templateId, highlighted: highlightedTripIds.some((historyTrip) => historyTrip === item.idWithParent), color: color ?? '#4d6d92', path: [...newPath]});
							if (!newStrokeWeights.hasOwnProperty(templateId)) {
								count = count + 5;
								newStrokeWeights[templateId] = count;
							}
						}
					}
				}

				if(newPaths.length > 0)
				{
					newPaths.forEach((x) => x.path.forEach((p) => bounds.extend(p)))
				}
			}
			setPaths(newPaths);
			setStrokeWeights(newStrokeWeights);
			setNumberOfPaths(count);
		}
		else {
			setPaths([]);
			setStrokeWeights(null);
			setNumberOfPaths(0);
		}
	};
	const calculateStrokeWeight = (value: number, highlighted: boolean) => {
		if(highlighted)
			return 12 + numberOfPaths - value;
		return 8 + numberOfPaths - value;
	};
	return (
		<>
			{paths?.map((x, index) => (
				<Polyline
					key={`poly-${index}`}
					options={{
						path: x.path,
						strokeWeight: calculateStrokeWeight(strokeWeights[x.templateId], x.highlighted),
						strokeColor: x.color,
						strokeOpacity: strokeWeights[x.templateId],
						zIndex: strokeWeights[x.templateId],
						icons: null,
					}}
				/>
			))}
		</>
	);
}

export default RietveldHistoryTemplates;
