import EntityTypeEnum from 'models/EntityTypeEnum';
import TemplateData from 'models/TemplateData';
import * as React from 'react';

import { List, ListItem, ListItemText } from '@material-ui/core';

import { ReportTemplate } from '../../../store/ReportStore';
import { TranslateText } from '../../../utils/Translations';

interface Props {
	selectedTemplateCallback: (
		templateId: string,
		templateName: string,
		templateCategory: string,
		templateCategoryId: string,
		templateEntityType: EntityTypeEnum,
		templateDataType: TemplateData
	) => void;
	selectedTemplateId: string;
	selectedCategoryId: string;
	reportTemplates: ReportTemplate[][];
}

const ReportsTemplates = (props: Props) => {
	if (!props.reportTemplates) {
		return null;
	}
	return (
		<div className="categories-container">
			<div className="e-text active-step-title">{`1.${TranslateText('reports.chooseReport')}`}</div>
			{props.reportTemplates.map((category) => (
				<div id="categories-list" className="category-item" key={category[0].category}>
					<List component="nav">
						<ListItemText primary={TranslateText(category[0].category)} className="item-header" />
						{category.map((template) => (
							<ListItem
								id={template.id}
								key={template.id}
								button
								selected={
									props.selectedTemplateId === template.id &&
									props.selectedCategoryId === template.categoryId
								}
								onClick={() => {
									props.selectedTemplateCallback(
										template.id,
										template.name,
										TranslateText(template.category),
										template.categoryId,
										template.entityType,
										template.dataType
									);
								}}
								className="item-body"
							>
								<ListItemText primary={TranslateText(template.name)} />
								<ListItemText secondary={TranslateText(template.description)} />
							</ListItem>
						))}
					</List>
				</div>
			))}
		</div>
	);
};

export default ReportsTemplates;
