import './PersonReportSettings.scss';

import { Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@material-ui/core';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import {
	PersonReportSettingsState,
	PersonReportSettingsViewValidationConfig,
} from 'components/Widgets/PersonReportSettingsView';
import { getDefaultPeriodSelectionInReportDataSource } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';
import YesNoCombobox from '../../Widgets/components/YesNoCombobox';

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

const areTheSame = (initialData: PersonReportSettingsState, data: PersonReportSettingsState) => {
	if (JSON.stringify(initialData) === JSON.stringify(data)) {
		return true;
	}
	return false;
};

const PersonReportSettings = (props: Props) => {
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);

	const [data, setData] = useState<PersonReportSettingsState>([] as any);
	const [initialData, setInitialData] = useState<PersonReportSettingsState>([] as any);
	const [isValid, setIsValid] = useState<boolean>(true);
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const [validator, setValidator] = useState(
		new Validator({ fieldRules: PersonReportSettingsViewValidationConfig() }, true)
	);
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);
	const defaultOutputSelectionInReportDataSource = [
		{ id: 'pdf', display: TranslateText('reports.formatType.pdf') },
		{ id: 'csv', display: TranslateText('reports.formatType.csv') },
		{ id: 'xlsx', display: TranslateText('reports.formatType.xlsx') },
	];
	useEffect(() => {
		const url = `${GlobalSettings.profileMaintenanceApi}/reportSettings/${personId}`;
		ajaxUtil.get<PersonReportSettingsState>(url).then((result: PersonReportSettingsState) => {
			setData(result);
			setInitialData(result);
			setIsLoaded(true);
		});
	}, []);

	useEffect(() => {
		validator.validate(data).then((result) => {
			setValidationResult(result.validationResult);
			setIsValid(result.formResult && isChanged);
		});
	}, [data]);

	const events = {
		onValueChanged: async (value: string | number | boolean | null, key: keyof PersonReportSettingsState) => {
			const newState = { ...data };
			(newState[key] as string | number | boolean | null) = value;
			setData(newState);
			props.setUnsavedData(true);
			if (areTheSame(initialData, newState)) {
				setIsChanged(false);
			} else if (!isChanged) {
				setIsChanged(true);
				window.onbeforeunload = () => {
					return 'Unsaved data';
				};
			}
		},
	};

	const saveReportSettings = () => {
		const url = `${GlobalSettings.profileMaintenanceApi}/reportSettings/`;
		ajaxUtil.put(url, data).then(() => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
		});
	};

	return (
		isLoaded && (
			<Grid item md={12} style={{ marginTop: '50px' }} className={'person-report-settings-grid'}>
				<Card>
					<CardContent>
						<Typography variant="h5" className={'title'}>
							{TranslateText('personSettingsDashboard.reportSettings')}
						</Typography>
						<div className={'period-selection-div'}>
							<Divider className={'middle-divider'} />
							<div className={'entity-selection-container'}>
								<div className={'entity-selection-settings'}>
									<Typography variant="h6" className={'entity-title'}>
										{TranslateText('fields.defaultPeriodSelectionInReport')}
									</Typography>
									<span className={'entity-description'}>
										{TranslateText('personReportSettings.periodSelectionDescription')}
									</span>
								</div>
								<div className="form-group form-group-settings">
									<MaterialAutocomplete
										valueId={data.defaultPeriodSelectionInReport?.toLowerCase()}
										dataSource={getDefaultPeriodSelectionInReportDataSource()}
										name="defaultPeriodSelectionInReport"
										onChange={({ value }) =>
											events.onValueChanged(value as string, 'defaultPeriodSelectionInReport')
										}
										className={'autocomplete-report-option'}
										disableClearable
									/>
									<ValidationMessage result={validationResult?.defaultPeriodSelectionInReport} />
								</div>
							</div>
							<Divider className={'middle-divider'} />
							<div className={'entity-selection-container'}>
								<div className={'entity-selection-settings'}>
									<Typography variant="h6" className={'entity-title'}>
										{TranslateText('fields.defaultMinimalTripInReport')}
									</Typography>
									<span className={'entity-description'}>
										{TranslateText('personReportSettings.minimalTripDescription')}
									</span>
								</div>

								<div className="form-group form-group-settings">
									<TextField
										id="defaultMinimalTripInReport"
										type="number"
										className="trip-report-field"
										fullWidth
										InputProps={{
											inputProps: {
												min: 0.1,
											},
										}}
										name="defaultMinimalTripInReport"
										value={data.defaultMinimalTripInReport}
										onChange={(e) =>
											events.onValueChanged(e.target.value, 'defaultMinimalTripInReport')
										}
									/>
									<ValidationMessage result={validationResult?.defaultMinimalTripInReport} />
								</div>
							</div>
							<Divider className={'middle-divider'} />
							<div className={'entity-selection-container'}>
								<div className={'entity-selection-settings'}>
									<Typography variant="h6" className={'entity-title'}>
										{TranslateText('personReportSettings.defaultOutputFormat')}
									</Typography>
									<span className={'entity-description'}>
										{TranslateText('personReportSettings.defaultOutputDescription')}
									</span>
								</div>
								<div className="form-group form-group-settings">
									<MaterialAutocomplete
										valueId={data.defaultOutputSelectionInReport}
										dataSource={defaultOutputSelectionInReportDataSource}
										name="defaultOutputSelectionInReport"
										onChange={({ value }) =>
											events.onValueChanged(value as string, 'defaultOutputSelectionInReport')
										}
										className={'autocomplete-report-option'}
										disableClearable
									/>
									<ValidationMessage result={validationResult?.defaultOutputSelectionInReport} />
								</div>
							</div>
							<Divider className={'middle-divider'} />
							<div className={'entity-selection-container'}>
								<div className={'entity-selection-settings'}>
									<Typography variant="h6" className={'entity-title'}>
										{TranslateText('fields.calculateVisitLastTripOfDay')}
									</Typography>
									<span className={'entity-description'}>
										{TranslateText('personReportSettings.calculateVisitLastTripOfDayDescription')}
									</span>
								</div>
								<div className="form-group form-group-settings">
									<YesNoCombobox
										change={(value) =>
											events.onValueChanged(
												value as boolean,
												'calculateVisitLastTripOfDayInReport'
											)
										}
										name={'calculateVisitLastTripOfDayInReport'}
										value={data.calculateVisitLastTripOfDayInReport}
										validationResult={validationResult}
										showDefaultTrueFalse
										className={'autocomplete-report-option'}
									/>
								</div>
							</div>
							<Divider className={'middle-divider'} />
							<Button
								style={{
									backgroundColor: !isValid ? '#999999' : '#006837',
								}}
								className={'save-button'}
								disabled={!isValid}
								onClick={saveReportSettings}
							>
								{TranslateText('common.buttonSave')}
							</Button>
							{isValid && (
								<span className={'info-text'}>
									{TranslateText('personSettingsWindow.changedAppliedAfterLogin')}
								</span>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		)
	);
};

export default PersonReportSettings;
