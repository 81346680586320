import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import React, { useEffect, useState } from 'react';

import { EntityModel, TrackedEntityModel } from '../HistoryTabs/types';
import { HistoryTabHeader } from './HistoryTabHeader';
import { useFetchTrackedEntities } from './hooks';
import { HistoryTabHeaderWrapperProps } from './types';

const HistoryTabHeaderWrapper = ({
	entities,
	firstSelectedTabId,
	tripDetailsParentId,
	tripSelectedIds,
	tripGroupedIds,
	removeTab,
	selectCallback,
	checkBoxClickedCallback,
	validateCurrentTripData,
}: HistoryTabHeaderWrapperProps): JSX.Element => {
	const [trackedEntities, setTrackedEntities] = useState<EntityModel[]>();

	useEffect(() => {
		setTrackedEntities(
			entities
				.filter((elem) => !elem.hidden)
				.map(
					(entity: ITreeNode) =>
						({
							id: entity.id,
							entityType: entity.type,
						} as EntityModel)
				)
		);
	}, [entities]);

	const { trackedEntities: fetchedTrackedEntities, isLoading } = useFetchTrackedEntities(trackedEntities);

	return (
		<>
			{!isLoading &&
				fetchedTrackedEntities.map((t: TrackedEntityModel) => {
					const isSelected = tripSelectedIds.includes(t.entityId);
					const isChecked = tripGroupedIds.includes(t.entityId);
					return (
						<HistoryTabHeader
							key={t.entityId}
							details={t}
							isSelected={isSelected}
							checked={isChecked}
							firstSelectedTabId={firstSelectedTabId}
							removeTab={() => {
								if (!isSelected || t.entityId !== tripDetailsParentId) {
									removeTab(t.entityId);
								} else {
									validateCurrentTripData(() => removeTab(t.entityId));
								}
							}}
							selectCallback={() => {
								if (isSelected) {
									selectCallback(t.entityId);
								} else {
									validateCurrentTripData(() => selectCallback(t.entityId));
								}
							}}
							checkCallback={(id, checked) => {
								if (isSelected) {
									if (!isChecked) {
										checkBoxClickedCallback(id, checked);
									} else {
										if (id === tripDetailsParentId) {
											validateCurrentTripData(() => checkBoxClickedCallback(id, checked));
										} else {
											checkBoxClickedCallback(id, checked);
										}
									}
								} else {
									if (tripSelectedIds.includes(tripDetailsParentId)) {
										if (tripGroupedIds.includes(tripDetailsParentId)) {
											checkBoxClickedCallback(id, checked);
										} else {
											validateCurrentTripData(() => checkBoxClickedCallback(id, checked));
										}
									} else {
										validateCurrentTripData(() => checkBoxClickedCallback(id, checked));
									}
								}
							}}
						/>
					);
				})}
		</>
	);
};

export default HistoryTabHeaderWrapper;
