import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TableChartCustomIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 19 19">
			<path d="M7 7.41185H12V18.3918H7V7.41185ZM14 18.3918H17C17.53 18.3903 18.0377 18.1791 18.4125 17.8044C18.7872 17.4296 18.9984 16.9218 19 16.3918V7.39185H14V18.3918ZM17 0.391846H2C1.47004 0.393397 0.962139 0.604618 0.587402 0.979355C0.212665 1.35409 0.00155124 1.86189 0 2.39185V5.39185H19V2.39185C18.9984 1.86189 18.7872 1.35409 18.4125 0.979355C18.0377 0.604618 17.53 0.393397 17 0.391846ZM0 16.3918C0.00155124 16.9218 0.212665 17.4296 0.587402 17.8044C0.962139 18.1791 1.47004 18.3903 2 18.3918H5V7.39185H0V16.3918Z" />
		</SvgIcon>
	);
};

export default React.memo(TableChartCustomIcon);
