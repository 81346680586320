import './LocationDialog.scss';

import { DialogUtil } from 'components/Common/NotificationDialog';
import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TranslateText } from 'utils/Translations';

import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';

import TabPanel from "../PersonSettings/TabPanel";
import LocationSection from "./LocationSection/LocationSection";
import {LatLng} from "../../store/LiveMap";
import {unsavedDataStoreActionCreators} from "../../store/UnsavedDataStore";
import {ApplicationState} from "../../store";
import moment from 'moment';

export interface LocationDialogDataDto {
	display: boolean;
	isForAdd: boolean;
	selectedLocationId: string;
	latLng: LatLng;
	startDate?: string;
}


interface Props {
	visible: boolean;
	closeCallback: () => void;
	selectedEntityId?: string;
	isForAdd: boolean;
	latLngAddress?: LatLng;
	startDateFromTrip?: Date;
	startDateFromTripString?: string;
}

export const adjustDateForDialog = (dateTime: string, offsetMinutes: number = 0) => {

	const date = moment.utc(dateTime);
	const localDate = date.add(offsetMinutes, 'minutes');
	const formattedDate = localDate.format("YYYY-MM-DD HH:mm:ss");
	return new Date(formattedDate);
}

export const adjustDateToUtcForDialog = (dateTime: string, offsetMinutes: number = 0): string => {
	const date = moment(dateTime);
	if(offsetMinutes !== 0) {
		date.add(-offsetMinutes, 'minutes');
	}
	const dateUtc = date.utc();
	return dateUtc.toISOString();
};
const LocationDialog = (props: Props) => {

	const dispatch = useDispatch();
	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);

	const [value, setValue] = useState(0);
	const [isDialogDisplayed, setIsDialogDisplayed] = useState<boolean>(false);
	const [shoMessageDialog, setShowMessageDialog] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if(props.visible)
			setShowMessageDialog(false);
	},[props.visible]);
	const handleChange = (event: any, newValue: number) => {
		if (!isDialogDisplayed) {
			if (unsavedData && value !== newValue) {
				setIsDialogDisplayed(true);
				DialogUtil.confirm({
					title: TranslateText('common.titleUnsavedData'),
					content: TranslateText('notificationMessages.cancel'),
				}).then((response: boolean) => {
					if (response) {
						setValue(newValue);
						dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
						window.onbeforeunload = null;
					}
					setIsDialogDisplayed(false);
				});
			} else {
				setValue(newValue);
			}
		}
	};

	const closeDialog = () => {
		if (!isDialogDisplayed) {
			if (unsavedData) {
				setIsDialogDisplayed(true);
				DialogUtil.confirm({
					title: TranslateText('common.titleUnsavedData'),
					content: TranslateText('notificationMessages.cancel'),
				}).then((response: boolean) => {
					if (response) {
						dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
						props.closeCallback();
						setTimeout(() => setValue(0), 200);
						window.onbeforeunload = null;
					}
					setIsDialogDisplayed(false);
				});
			} else {
				props.closeCallback();
				setTimeout(() => setValue(0), 200);
			}
		}
	};

	const closeAfterSave = () => {
		props.closeCallback();
		setTimeout(() => setValue(0), 200);
	}

	return (
		<Dialog
			open={props.visible}
			fullWidth
			maxWidth={'lg'}
			className={`location-dialog paper ${shoMessageDialog ? 'small' : ''}`}
			onClose={closeDialog}
			disableBackdropClick
		>
			<Backdrop open={isLoading} style={{
				zIndex: 9999,
				color: '#e91e63',
				position: 'absolute'
			}}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{!shoMessageDialog ? <>
			<DialogContent>
				<div className={'location-dialog-content'}>
					<div className={'dialog'}>
						<div>
							<div>
								<ListItem className={'header'}>
									<ListItemIcon className={'location-info'}>
										<LocationOnIcon className={'account-circle-icon'} />
									</ListItemIcon>
									<ListItemText>{TranslateText('tripDetailsSection.locationDialog.locationSection')}</ListItemText>
								</ListItem>
							</div>
							<br />
							<br />
							<div className={'tabs-header'}>
								<Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange}>
									<Tab
										className={'tab tab-settings'}
										label={TranslateText('tripDetailsSection.locationDialog.locationSectionData')}
										icon={<MapIcon className={'location-map-icon tab-icon-color'} />}
									/>
								</Tabs>
							</div>
						</div>
					</div>
					<div className={'content-header'}>
						<TabPanel value={value} index={0}>
							<LocationSection
								visible={props.visible}
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={(value: boolean) => {
									dispatch(unsavedDataStoreActionCreators.setUnsavedData(value));
								}}
								closeDialog={closeDialog}
								closeAfterSave={closeAfterSave}
								selectedEntityId={props.selectedEntityId}
								isForAdd={props.isForAdd}
								setShowMessage={(value) => setShowMessageDialog(value)}
								setIsLoading={(value) => setIsLoading(value)}
								latLngAddress={props.latLngAddress}
								startDateFromTrip={props.startDateFromTrip}
								startDateFromTripString={props.startDateFromTripString}
							/>
						</TabPanel>

					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<IconButton
					className={'customized-close-button'}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						closeDialog();
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogActions>
			</>
			: <>
			<DialogTitle id="scroll-dialog-title">
				{TranslateText('tripDetailsSection.locationDialog.locationDialogTitle')}
			</DialogTitle>
					<DialogContent dividers>
					<Box className={'content-dialog'}>
				<p>{TranslateText('tripDetailsSection.locationDialog.locationMessage')}</p>
			</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					closeDialog();
				}} color="primary">
					{TranslateText('common.buttonOk')}
				</Button>
			</DialogActions>
			</>}
		</Dialog>
	);
};

export default LocationDialog;
