import './styles.scss';

import React, { useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useToggle } from 'utils/hooks';

import { Box, Popover } from '@material-ui/core';

import { predefinedColors } from './constants';
import { useColorPicker } from './hooks';
import { ColorPickerProps } from './types';

const ColorPicker = ({
	initialColor,
	onColorChangedHandler,
	size = '18px',
	disabled = false,
}: ColorPickerProps): JSX.Element => {
	const colorPickerSelectorRef = useRef<HTMLButtonElement>();

	const [showColorPicker, setShowColorPicker, setShowColorPickerFalse] = useToggle(false);
	const { color, setColor } = useColorPicker(initialColor, onColorChangedHandler);

	return (
		<Box className="color-picker-container">
			<button
				ref={colorPickerSelectorRef}
				data-testid="color-picker-button-test-id"
				className="color-box"
				onClick={() => {
					if (!disabled) {
						setShowColorPicker();
					}
				}}
				style={{ backgroundColor: color, width: size, height: size }}
				type="button"
			/>
			<Popover
				open={showColorPicker}
				anchorEl={colorPickerSelectorRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				className="picker"
				disableScrollLock={true}
				data-testid="color-picker-color-container-test-id"
				onClose={setShowColorPickerFalse}
			>
				<HexColorPicker color={color ? color : 'fff'} onChange={setColor} />

				<Box className="picker__swatches">
					{predefinedColors.map((presetColor: string) => (
						<button
							key={presetColor}
							className="picker__swatch"
							style={{ background: presetColor }}
							onClick={() => {
								setColor(presetColor);
								setShowColorPickerFalse();
							}}
						/>
					))}
				</Box>
			</Popover>
		</Box>
	);
};

export default ColorPicker;
