import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import SimCard from 'models/SimCard';
import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import { ValidationResult } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { FormControlLabel, TextField, Typography } from '@material-ui/core';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete, { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import { DebouncedCheckbox } from '../Common/DebouncedCheckbox';
import {
	ConflictConfirmation,
	ConnectionsConflictResult,
	Props as ConflictConfirmationProps,
} from '../ConnectionConflictsNotifier/ConflictConfirmation';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import RenderForEnum from './RenderForEnum';

interface Props {
	devicesData: AutocompleteItem[];
	readOnly: boolean;
	renderFor: RenderForEnum;
	setSimCard?: (valid: boolean, simCard: SimCard) => void;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
	enableConnectedDeviceField: boolean;
}

interface State {
	simCard: SimCard;
	validationResult?: ValidationResult;
	unsavedData: boolean;
	conflictConfirmationProps: ConflictConfirmationProps;
	isLoading: boolean;
}

class SimCardView extends BaseView<Props, State> {
	validator: Validator;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
		enableConnectedDeviceField: true,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			simCard: new SimCard(this.props.defaultCustomerId),
			validationResult: null,
			unsavedData: false,
			conflictConfirmationProps: {
				visible: false,
				confirmCallback: null,
				canSolveConflicts: true,
				conflictData: [],
				connectionType: ConnectionTypeEnum.DeviceSimCard,
			},
			isLoading: false,
		};

		this.validator = new Validator({
			fieldRules: {
				imsi: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(40),
						warningUnique: {
							message: TranslateText('fieldsValidations.warningValueNotUnique'),
							isWarning: true,
							validationFunction: (data) => {
								const simCardData = data as SimCard;
								if (!simCardData.imsi.trim()) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
									customerId: simCardData.customerId,
									value: simCardData.imsi,
									Field: 'imsi',
								});
							},
						},
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
					},
				},
				iccid: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(40),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
						unique: {
							message: TranslateText('fieldsValidations.uniqueValue'),
							validationFunction: (data) => {
								const simCardData = data as SimCard;
								if (!simCardData.iccid.trim()) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
									value: simCardData.iccid,
									Field: 'iccid',
								});
							},
						},
					},
				},
				phoneNumber: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(20),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
						warningUnique: {
							message: TranslateText('fieldsValidations.warningValueNotUnique'),
							isWarning: true,
							validationFunction: (data) => {
								const simCardData = data as SimCard;
								if (!simCardData.phoneNumber.trim()) {
									return ValidatorFunctions.wrapInPromise(true);
								}
								return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
									customerId: simCardData.customerId,
									value: simCardData.phoneNumber,
									Field: 'phoneNumber',
								});
							},
						},
					},
				},
				pinCode: {
					rules: {
						maxLength: ValidatorFunctions.maxLength(6),
						number: ValidatorFunctions.regexIfNotNull(
							ValidationRegex.onlyDigits(),
							TranslateText('fieldsValidations.onlyDigits')
						),
					},
				},
				provider: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: ValidatorFunctions.maxLength(100),
						noEmptySpace: ValidatorFunctions.noEmptySpace(),
					},
				},
				deviceId: {
					rules: {
						anyConflicts: {
							message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
								TranslateText('connections.device'),
							]),
							validationFunction: (data, fieldName) => {
								return this.checkDeviceConnectionConflicts(data[fieldName]);
							},
							disabled: props.renderFor === RenderForEnum.ConnectDialog,
						},
					},
				},
			},
		});
	}

	componentDidMount() {
		if (
			this.props.renderFor === RenderForEnum.Wizard ||
			(this.props.renderFor === RenderForEnum.Widget && !this.props.readOnly)
		) {
			this.prepareView();

			//initial validation
			this.validator.validate(this.state.simCard).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
			this.validator.clearValidation();
			this.setState({ validationResult: null });
		}

		if (this.state.simCard !== prevState.simCard) {
			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				this.validator.validate(this.state.simCard).then((result) => {
					this.setState({
						validationResult: result.validationResult,
					});

					if (this.state.unsavedData) {
						this.setSimCard(result.formResult, this.state.simCard);
					}
				});
			}
		}
	}

	prepareView() {
		document.getElementById('simCardImsi').focus();
	}

	prepareViewWithData(customerId: string, deviceId?: string, deviceCode?: string) {
		this.setState({
			simCard: {
				...this.state.simCard,
				customerId: customerId,
				deviceId: deviceId,
				deviceCode: deviceCode,
			},
		});

		setTimeout(() => {
			document.getElementById('simCardImsi').focus();
		}, 400);
	}

	public focusDefaultInput() {
		setTimeout(() => {
			document.getElementById('simCardImsi').focus();
		}, 400);
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.simCard).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	clearView() {
		this.validator.clearValidation();
		this.setState({
			simCard: new SimCard(this.props.defaultCustomerId),
			validationResult: null,
		});
	}

	private handleValueChange(args: any, statePropName: string) {
		if (!args) {
			return;
		}

		const tempsimCard = { ...this.state.simCard };
		tempsimCard[statePropName] = args.value;
		this.setState({
			simCard: tempsimCard,
			unsavedData: true,
		});
	}

	private setSimCard(valid: boolean, simCard: SimCard) {
		if (this.props.renderFor === RenderForEnum.Wizard || !this.props.readOnly) {
			this.props.setSimCard(valid, simCard);
		}
	}

	private async checkDeviceConnectionConflicts(deviceId: string): Promise<boolean> {
		if (!deviceId) {
			return true;
		}

		const candidateConnection = {
			entity1Id: deviceId,
			entity2Id: this.state.simCard.id ? this.state.simCard.id : null,
			connectionStartDate: new Date(),
			connectionType: ConnectionTypeEnum.DeviceSimCard,
		};

		this.setState({ isLoading: true });
		const result = await ajaxUtil.post<ConnectionsConflictResult>(
			`${GlobalSettings.connectionsApi}/ValidateEntitiesCandidateConnection`,
			candidateConnection
		);
		this.setState({ isLoading: false });

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				this.setState({
					conflictConfirmationProps: {
						visible: true,
						canSolveConflicts: result.fixableConflict,
						connectionType: ConnectionTypeEnum.DeviceSimCard,
						conflictData: result.conflictingConnections,
						confirmCallback: (confirm) => {
							this.setState({
								conflictConfirmationProps: {
									...this.state.conflictConfirmationProps,
									visible: false,
								},
							});
							resolve(confirm);
						},
					},
				});
			});
		} else {
			return true;
		}
	}

	fillSystemInformation() {
		return (
			<form id="simCardSystemInformationForm" className="auto-sim-card-system-information" noValidate={true}>
				<div className="view-section-wrapper">
					<CustomerNameDisplay
						customerId={
							!!this.props?.defaultCustomerId?.length
								? this.props.defaultCustomerId
								: this.state.simCard.customerId
						}
					/>
					<FormControlLabel
						control={
							<DebouncedCheckbox
								onChange={() => {}}
								name="showInactive"
								color={'primary'}
								checked
								disabled
								debounceTime={500}
							/>
						}
						label={
							<Typography style={{ fontSize: 12, marginRight: 5 }}>
								{TranslateText('fields.active')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0, marginBottom: 10 }}
					/>
				</div>
			</form>
		);
	}

	fillSimData() {
		return (
			<form id="simCardDataForm" className="auto-sim-card-data" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="simCardImsi"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.imsi')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="simCardImsi"
							value={this.state.simCard.imsi?.trimStart()}
							onChange={(args: any) => this.handleValueChange(args.target, 'imsi')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.imsi} />
					</div>
					<div className="form-group">
						<TextField
							id="simCardIccid"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.iccid')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="simCardIccid"
							value={this.state.simCard.iccid?.trimStart()}
							onChange={(args: any) => this.handleValueChange(args.target, 'iccid')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.iccid} />
					</div>
					<div className="form-group">
						<TextField
							id="simCardPhoneNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.phoneNumber')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="simCardPhoneNumber"
							value={this.state.simCard.phoneNumber?.trimStart()}
							onChange={(args: any) => this.handleValueChange(args.target, 'phoneNumber')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.phoneNumber} />
					</div>
					<div className="form-group">
						<TextField
							id="simCardPinCode"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.pinCode')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="simCardPinCode"
							value={this.state.simCard.pinCode?.trimStart()}
							onChange={(args: any) => this.handleValueChange(args.target, 'pinCode')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.pinCode} />
					</div>
					<div className="form-group">
						<TextField
							id="simCardProvider"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.provider')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="simCardProvider"
							value={this.state.simCard.provider?.trimStart()}
							onChange={(args: any) => this.handleValueChange(args.target, 'provider')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.provider} />
					</div>

					<div className="form-group">
						{this.props.renderFor === RenderForEnum.ConnectDialog ||
						this.props.renderFor === RenderForEnum.Widget ? (
							<TextField
								id="deviceId"
								className="auto-device-id"
								type="text"
								fullWidth
								label={TranslateText('fields.connectedDevice')}
								inputProps={{ readOnly: true, style: { fontSize: 12 } }}
								name="deviceId"
								value={this.state.simCard.deviceCode}
								disabled={true}
							/>
						) : this.props.enableConnectedDeviceField ? (
							<>
								<MaterialAutocomplete
									valueId={this.state.simCard.deviceId}
									dataSource={this.props.devicesData}
									name={'deviceId'}
									onChange={({ event, value }) => {
										this.handleValueChange({ value, event }, 'deviceId');
									}}
									disabled={this.state.isLoading}
									label={TranslateText('fields.connectedDevice')}
								/>
								<ValidationMessage result={this.state.validationResult?.deviceId} />
							</>
						) : null}
					</div>
				</div>
			</form>
		);
	}

	render() {
		return (
			<>
				<ConflictConfirmation {...this.state.conflictConfirmationProps} />
				{this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget()}
			</>
		);
	}

	renderForWizard() {
		return (
			<div className="wizard-panel">
				<div className="column">
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillSystemInformation()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
				<div className="column">
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.simData')}>
							<Scrollbar native={true} className="view-section-scroll">
								{this.fillSimData()}
							</Scrollbar>
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}

	renderForWidget() {
		return (
			<Accordion>
				<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
					{this.fillSystemInformation()}
				</AccordionSection>
				<AccordionSection expanded={true} header={TranslateText('common.simData')}>
					{this.fillSimData()}
				</AccordionSection>
			</Accordion>
		);
	}
}

export default SimCardView;
