import './searchComponent.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ClickAwayListener, Divider, IconButton, InputBase, Popper, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import * as GlobalSettings from '../../GlobalSettings.json';
import { liveMapActionCreators } from '../../store/LiveMap';
import { TranslateText } from '../../utils/Translations';
import SearchCustomIcon from '../Common/Icons/SearchCustomIcon';
import SearchOpenClose from '../Common/Icons/SearchOpenClose';
import SearchPointerIcon from '../Common/Icons/SearchPointerIcon';
import SearchResults from './SearchResults';

export enum SearchTypeEnum {
	Address = 0,
	Object = 1,
	Person = 2,
}

export interface SearchResult {
	id: string;
	text: string;
	lat?: number;
	lng?: number;
	loaded?: boolean;
}

interface Props {
	isMaterial?: boolean;
	readonly?: boolean;
	selectResultCallback?: (selectedResult: SearchResult) => void;
	clearResultTrigger?: boolean;
}

const SearchComponent = (props: Props) => {
	//search enable/disable
	const [isEnabled, setIsEnabled] = useState(false);
	const location = useLocation();
	useEffect(() => {
		setIsEnabled(
			location.pathname?.toLowerCase() === GlobalSettings.route.live ||
				location.pathname?.toLowerCase() === GlobalSettings.route.history
		);
	}, [location]);

	const [selectedResult, setSelectedResult] = useState<SearchResult>(null);
	const [isSearching, setIsSearching] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [searchType] = useState(SearchTypeEnum.Address);
	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

	const dispatch = useDispatch();
	const firstRender = useRef<boolean>(true);
	useEffect(() => {
		if (selectedResult && selectedResult.lat && selectedResult.lng) {
			if (!props.isMaterial) {
				dispatch(liveMapActionCreators.setPinMarker({ lat: selectedResult.lat, lng: selectedResult.lng }));
				dispatch(liveMapActionCreators.setRouteLinesWithEntity(null, null));
			} else {
				props.selectResultCallback(selectedResult);
			}
		} else if (selectedResult) {
			const geocoder = new google.maps.Geocoder();
			const request = {
				placeId: selectedResult.id,
			};
			geocoder.geocode(request, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK && results.length) {
					const lat = results[0].geometry?.location?.lat();
					const lng = results[0].geometry?.location?.lng();

					const selectedIndex = searchResults.findIndex((r) => r.id === selectedResult.id);
					searchResults[selectedIndex] = { ...searchResults[selectedIndex], lat, lng, loaded: true };
					//update location
					setSelectedResult({ ...selectedResult, lat, lng, loaded: true });
					setSearchResults([...searchResults]);

					//display pin on map
					if (!props.isMaterial) {
						dispatch(liveMapActionCreators.setPinMarker({ lat, lng }));
						dispatch(liveMapActionCreators.setRouteLinesWithEntity(null, null));
					}
				}
			});
		} else {
			if (!props.isMaterial) {
				dispatch(liveMapActionCreators.setPinMarker(null));

				//clear only the routes lines and marker (and not selected entity) at first render
				if (firstRender.current) {
					dispatch(liveMapActionCreators.setRouteLines(null));
				} else {
					dispatch(liveMapActionCreators.setRouteLinesWithEntity(null, null));
				}
			}
		}

		if (firstRender.current) {
			firstRender.current = false;
		}
	}, [selectedResult]);

	useEffect(() => {
		if (props.isMaterial) {
			setSelectedResult(null);
			setSearchText('');
		}
	}, [props.clearResultTrigger]);

	useEffect(() => {
		if (selectedResult && props.isMaterial) {
			setOpenResults(false);
		}
	}, [selectedResult]);

	const fetchSearchResults = useCallback(() => {
		//get map bounds
		let bounds: google.maps.LatLngBounds = null;
		if (!props.isMaterial) {
			const w = window as any;
			if (w.rietveldMap) {
				bounds = new google.maps.LatLngBounds(
					w.rietveldMap.getBounds().getSouthWest(),
					w.rietveldMap.getBounds().getNorthEast()
				);
			}
		}

		const autocompleteService = new google.maps.places.AutocompleteService();
		const request = {
			input: searchText,
			bounds,
		};
		autocompleteService.getPlacePredictions(request, (results, status) => {
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				const addresses: SearchResult[] = [];
				const maxIndex = results.length > 10 ? 9 : results.length - 1;
				for (let i = 0; i <= maxIndex; i++) {
					addresses.push({
						id: results[i].place_id,
						text: results[i].description,
					});
				}
				setSearchResults(addresses);
			}
			setIsSearching(false);
		});
	}, [searchText]);

	const onSelection = useCallback((selection: SearchResult) => {
		setSearchText(selection.text);
		setSelectedResult(selection);
	}, []);
	const clearSelection = useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();

			setSearchText('');
			setSelectedResult(null);
		},
		[isEnabled]
	);

	//search result open/close
	const [focused, setFocused] = useState(false);
	const anchorResults = useRef(null);
	const [openResults, setOpenResults] = useState(false);
	const performSearch = useCallback(() => {
		if (!selectedResult || props.isMaterial) {
			if (searchText) {
				setSearchResults([]);
				setOpenResults(true);
				setIsSearching(true);
				fetchSearchResults();
			}
		} else {
			setOpenResults(true);
		}
	}, [searchText, selectedResult]);

	const handleClose = useCallback((e) => {
		const path = e.path || (e.composedPath && e.composedPath());

		//check if the click came from the followup results popup
		if (path?.length) {
			for (let i = 0; i < path.length; i++) {
				const item = path[i] as HTMLElement;
				if (item && item.id === 'search-followup-results') {
					return;
				}
			}
		}

		setOpenResults(false);
	}, []);

	return (
		<>
			{props.isMaterial ? (
				<div className={'search-row'}>
					<TextField
						ref={anchorResults}
						inputProps={{
							style: { fontSize: 10, paddingTop: 0 },
							onFocus: () => {
								setFocused(true);
							},
							onBlur: () => {
								setFocused(false);
							},
						}}
						disabled={props.readonly}
						value={searchText}
						onChange={(e) => {
							setSearchText(e.target.value);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								performSearch();
							}
						}}
						fullWidth
					/>
					<IconButton
						className={'search-button'}
						onClick={performSearch}
						disabled={props.readonly || searchText.length === 0}
					>
						<SearchOutlined style={{ color: searchText.length === 0 ? 'grey' : 'black' }} />
					</IconButton>
				</div>
			) : (
				<div
					ref={anchorResults}
					className={`search-component ${
						openResults || focused || (isEnabled && searchText) ? 'active' : ''
					}`}
				>
					{selectedResult ? (
						<div
							onClick={() => {
								if (selectedResult && isEnabled) {
									setOpenResults(true);
								}
							}}
							className={`selected-result-container ${isEnabled ? 'enabled' : ''}`}
						>
							<div className="selected-text">{searchText}</div>
							<IconButton
								disabled={!isEnabled}
								size="small"
								className="remove-lock"
								onClick={clearSelection}
							>
								<CloseIcon />
							</IconButton>
						</div>
					) : (
						<InputBase
							value={searchText}
							disabled={!isEnabled}
							className={`input-search ${isEnabled ? 'enabled' : ''}`}
							placeholder={TranslateText('topBar.searchPlaceHolder')}
							inputProps={{
								onFocus: () => {
									setFocused(true);
								},
								onBlur: () => {
									setFocused(false);
								},
							}}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									performSearch();
								}
							}}
						/>
					)}

					<Divider orientation="vertical" />
					<IconButton disabled={true} className="search-type-selection" size="small">
						<SearchPointerIcon className="search-pointer" />
						<SearchOpenClose className="search-open-close" />
					</IconButton>
					<Divider orientation="vertical" />
					<IconButton disabled={!isEnabled} onClick={performSearch} className="search-button" size="small">
						<SearchCustomIcon />
					</IconButton>
				</div>
			)}

			<Popper
				className="search-results-popper"
				open={openResults}
				anchorEl={anchorResults.current}
				placement="bottom-start"
				style={{ zIndex: 10000 }}
			>
				<ClickAwayListener onClickAway={handleClose}>
					<div>
						<SearchResults
							isSearching={isSearching}
							searchType={searchType}
							searchResults={searchResults}
							selectedResult={selectedResult}
							onSelection={onSelection}
							isMaterial={props.isMaterial}
						/>
					</div>
				</ClickAwayListener>
			</Popper>
		</>
	);
};

export default SearchComponent;
