import './style.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionDialog from 'components/TreeSelectionDialog';
import { SelectionFilter } from 'components/TreeSelectionDialog/TreeSelectionDialog';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { getCleanupMostRecentReportsDataSource, getDefaultPeriodSelectionInReportDataSource } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import EntityTypeEnum from 'models/EntityTypeEnum';
import ReportCleanupRecentPeriodEnum from 'models/ReportCleanupRecentPeriodEnum';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button, TextField } from '@material-ui/core';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: CustomerReportSettings;
	//Any other custom props
};

interface CustomerReportSettings {
	maxPeriodOfDaysInReport: number;
	defaultPeriodSelectionInReport: string;
	defaultOutputSelectionInReport: string;
	defaultMinimalTripInReport: number;
	cleanupGeneratedReports: number;
	cleanupMostRecentReports: ReportCleanupRecentPeriodEnum;
	calculateVisitLastTripOfDayInReport: boolean;
	customerReportTemplates: ITreeNode[];
	customerReportCategories: ITreeNode[];
}

const sortData = (reportTemplatesCollection: ITreeNode[]) => {
	reportTemplatesCollection.forEach((reportTemplates: ITreeNode) => {
		if (reportTemplates.items && reportTemplates.items.length > 0) {
			reportTemplates.items.sort((a: ITreeNode, b: ITreeNode) => {
				const first = TranslateText(a.text);
				const second = TranslateText(b.text);
				return first.localeCompare(second);
			});
		}
	});

	return reportTemplatesCollection.sort((a: ITreeNode, b: ITreeNode) => {
		a.text = TranslateText(a.text);
		b.text = TranslateText(b.text);
		return a.text.localeCompare(b.text);
	});
};

const CustomerReportSettingsViewValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		maxPeriodOfDaysInReport: {
			rules: {
				required: ValidatorFunctions.required(),
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyIntegerDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				minValue: ValidatorFunctions.minNumber(1),
				maxValue: ValidatorFunctions.maxNumber(366),
			},
		},
		defaultPeriodSelectionInReport: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		defaultOutputSelectionInReport: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		defaultMinimalTripInReport: {
			rules: {
				required: ValidatorFunctions.required(),
				minValue: ValidatorFunctions.minNumber(0.1),
			},
		},
		cleanupGeneratedReports: {
			rules: {
				required: ValidatorFunctions.required(),
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyIntegerDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				minValue: ValidatorFunctions.minNumber(1),
				maxValue: ValidatorFunctions.maxNumber(365),
			},
		},
		cleanupMostRecentReports: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		calculateVisitLastTripOfDayInReport: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
	};

	return fieldRules;
};

const CustomerReportSettingsView = (props: Props) => {
	const [showFleetSelection, setShowFleetSelection] = useState(false);
	const [defaultPeriodSelectionInReportDataSource] = useState(getDefaultPeriodSelectionInReportDataSource);
	const [cleanupMostRecentReportsDataSourceValues] = useState(getCleanupMostRecentReportsDataSource);
	const customerId = useSelector((state: ApplicationState) => state.currentSession.customerId);
	const defaultOutputSelectionInReportDataSource = [
		{ id: 'pdf', display: TranslateText('reports.formatType.pdf') },
		{ id: 'csv', display: TranslateText('reports.formatType.csv') },
		{ id: 'xlsx', display: TranslateText('reports.formatType.xlsx') },
	];
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: CustomerReportSettingsViewValidationConfig() }));
	}, [props.entityId]);

	const reloadTreeData = useRef(false);
	useEffect(() => {
		reloadTreeData.current = true;
	}, [props.data.customerReportCategories]);

	const events = {
		onValueChanged: async (value: string | number | boolean, key: keyof CustomerReportSettings) => {
			const newState = { ...props.data };
			(newState[key] as any) = value;
			props.changeDataCallback(newState);
		},
		getSelectedEntities: () => {
			let arr: ITreeNode[] = [];
			arr = arr.concat(props.data.customerReportTemplates).concat(props.data.customerReportCategories);
			//const sortedArr = arr.sort((x, y) => x.text.localeCompare(y.text));
			return arr;
		},

		setSelectedEntitiesFromDialog: (selectedData: ITreeNode[]) => {
			const templates = selectedData.filter((x) => x.type == EntityTypeEnum.Report.toString());
			const categories = selectedData.filter((x) => x.type == EntityTypeEnum.ReportCategory.toString());

			props.changeDataCallback({
				...props.data,
				customerReportTemplates: templates,
				customerReportCategories: categories,
			});
			setShowFleetSelection(false);
		},
		openFleetSelection: () => {
			setShowFleetSelection(true);
		},
		closeFleetSelection: () => {
			setShowFleetSelection(false);
		},
		checkShouldReload: () => {
			return reloadTreeData.current;
		},
	};

	const retrieveData = useCallback((filter: SelectionFilter) => {
		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.customersMaintenanceApi}/getReportsTree`, {
				filterText: filter.filterText,
				customerId: customerId,
				editedCustomerId: props.entityId,
			})
			.then((data) => {
				reloadTreeData.current = false;
				return sortData(data);
			});
	}, []);

	return (
		<div className="view-section-wrapper">
			<div className="form-group">
				<TextField
					id="maxPeriodOfDaysInReport"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.maxPeriodOfDaysInReport')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="maxPeriodOfDaysInReport"
					value={props.data.maxPeriodOfDaysInReport}
					onChange={(e: any) => events.onValueChanged(e.target.value, 'maxPeriodOfDaysInReport')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.maxPeriodOfDaysInReport} />
			</div>
			<div className="form-group">
				<div className="flex">
					<TextField
						id="customerReportTemplates"
						type="text"
						fullWidth
						className="grow"
						label={TranslateText('fields.customerReportTemplates')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="customerReportTemplates"
						value={String(
							([].concat(
								...props.data.customerReportCategories.map((x: ITreeNode) => {
									return x.items;
								})
							) as ITreeNode[])
								.concat(props.data.customerReportTemplates)
								.filter((node, index, arr) => arr.findIndex((t) => t.id === node.id) === index).length
						)}
						disabled={!props.editMode}
					/>
					{props.editMode ? (
						<Button
							className="shrink widget-button edit-icon"
							onClick={() => events.openFleetSelection()}
						/>
					) : null}
				</div>
				<TreeSelectionDialog
					displayInactive={false}
					visible={showFleetSelection}
					enableClientFilter={true}
					initialSelection={events.getSelectedEntities()}
					title={TranslateText('reports.addReports')}
					filterPlaceholder={TranslateText('fleetSelection.searchReportsPlaceholder')}
					retrieveData={retrieveData}
					checkShouldReload={events.checkShouldReload}
					closeCallback={events.closeFleetSelection}
					saveCallback={events.setSelectedEntitiesFromDialog}
				/>
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultPeriodSelectionInReport?.toLowerCase()}
					dataSource={defaultPeriodSelectionInReportDataSource}
					name="defaultPeriodSelectionInReport"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultPeriodSelectionInReport')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultPeriodSelectionInReport')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultPeriodSelectionInReport} />
			</div>
			<div className="form-group">
				<TextField
					id="defaultMinimalTripInReport"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.defaultMinimalTripInReport')}
					InputProps={{
						inputProps: {
							min: 0.1,
							readOnly: !props.editMode,
							style: { fontSize: 12 },
						},
					}}
					name="defaultMinimalTripInReport"
					value={props.data.defaultMinimalTripInReport}
					onChange={(e: any) => events.onValueChanged(e.target.value, 'defaultMinimalTripInReport')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.defaultMinimalTripInReport} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultOutputSelectionInReport}
					dataSource={defaultOutputSelectionInReportDataSource}
					name="defaultOutputSelectionInReport"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultOutputSelectionInReport')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultOutputSelectionInReport')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultOutputSelectionInReport} />
			</div>
			<div className="form-group">
				<TextField
					id="cleanupGeneratedReports"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.cleanupGeneratedReports')}
					InputProps={{
						inputProps: {
							min: 1,
							readOnly: !props.editMode,
							style: { fontSize: 12 },
						},
					}}
					name="cleanupGeneratedReports"
					value={props.data.cleanupGeneratedReports}
					onChange={(e: any) => events.onValueChanged(e.target.value, 'cleanupGeneratedReports')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.cleanupGeneratedReports} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.cleanupMostRecentReports}
					dataSource={cleanupMostRecentReportsDataSourceValues}
					name="cleanupMostRecentReports"
					disabled={!props.editMode}
					label={TranslateText('fields.cleanupMostRecentReports')}
					onChange={({ value }) => events.onValueChanged(value as string, 'cleanupMostRecentReports')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.cleanupMostRecentReports} />
			</div>
			<div className="form-group">
				<YesNoCombobox
					change={(value) => events.onValueChanged(value as boolean, 'calculateVisitLastTripOfDayInReport')}
					name={'calculateVisitLastTripOfDayInReport'}
					placeholder={TranslateText('fields.calculateVisitLastTripOfDay')}
					value={props.data.calculateVisitLastTripOfDayInReport}
					validationResult={props.validationResult}
					readonly={!props.editMode}
				/>
			</div>
		</div>
	);
};

export default CustomerReportSettingsView;
