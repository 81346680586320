import DataExchangeTypeEnum from './DataExchangeTypeEnum';
import IdIdentifiable from './IdIdentifiable';
import IntegrationTypeEnum from './IntegrationTypeEnum';
import RemoteAccessTypeEnum from './RemoteAccessTypeEnum';

class IntegrationDto extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.customerId = customerId;

		this.name = '';
		this.integrationType = null;
		this.active = true;
		this.dataExchangeType = null;
		this.remoteAccess = null;
		this.remoteAccessCredentials = '';
		this.customerContactPersonName = '';
		this.customerContactPersonEmail = '';
		this.customerContactPersonPhone = '';
		this.supplierContactPersonName = '';
		this.supplierContactPersonEmail = '';
		this.supplierContactPersonPhone = '';
	}

	id: string;
	customerId: string;
	name: string;
	integrationType?: IntegrationTypeEnum;
	dataExchangeType?: DataExchangeTypeEnum;
	remoteAccessType?: RemoteAccessTypeEnum;
	remoteAccessCredentials: string;
	customerContactPersonName: string;
	customerContactPersonEmail: string;
	customerContactPersonPhone: string;
	supplierContactPersonName: string;
	supplierContactPersonEmail: string;
	supplierContactPersonPhone: string;
	active: boolean;
	integrationAfasMetadata?: IntegrationAfasMetadata;
	integrationHereOnTrackMetadata?: IntegrationHereOnTrackMetadata;
	integrationEasyTrackMetadata?: IntegrationEasyTrackMetadata;
}

export class IntegrationAfasMetadata {
	constructor(customerId?: string, chronExpressionAfas?: string) {
		this.customerId = customerId;
		this.scheduleCron = chronExpressionAfas;

		this.accessToken = '';
		this.baseUrl = '';
	}

	id: string;
	apiIntegrationId: string;
	customerId: string;
	accessToken: string;
	baseUrl: string;
	scheduleCron: string;
}

export class IntegrationHereOnTrackMetadata {
	constructor(customerId?: string, refreshInterval?: number) {
		this.customerId = customerId;

		this.hereOnTrackApiKey = '';
		this.hereOnTrackRefreshIntervalMinutes = refreshInterval;
		this.hereOnTrackGroupId = '';
		this.hereOnTrackGroupName = '';
	}

	id: string;
	apiIntegrationId: string;
	customerId: string;
	hereOnTrackApiKey: string;
	hereOnTrackRefreshIntervalMinutes?: number;
	hereOnTrackGroupId: string;
	hereOnTrackGroupName: string;
}

export class IntegrationEasyTrackMetadata {
	constructor(customerId?: string) {
		this.customerId = customerId;

		this.apiKey = '';
		this.accessTokenCallbackURL = '';
		this.allowedAPIOperationsIds = [];
		this.bypassCustomerPrivacySettings = false;
		this.bypassAgreementRequired = false;
		this.reverseGeocoding = false;
		this.geocodingAgreementRequired = false;
	}

	id: string;
	apiIntegrationId: string;
	customerId: string;
	apiKey: string;
	accessTokenCallbackURL: string;
	allowedAPIOperationsIds: string[];
	bypassCustomerPrivacySettings: boolean;
	bypassAgreementRequired: boolean;
	reverseGeocoding: boolean;
	geocodingAgreementRequired: boolean;
}

export default IntegrationDto;
