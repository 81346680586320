import './historyTimePicker.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TRIP_UNSAVED_DATA, SHOW_HISTORY_TRIP_DETAILS, UPDATE_HISTORY_TRIPS } from 'store/HistoryStore';

import { Button, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import DateTimeUtil, { DateTimeConversionUtil } from '../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../store';
import { historyFilterActionCreators } from '../../store/HistoryFilter';
import { TranslateText } from '../../utils/Translations';
import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import { HistoryTimeRange } from './HistoryTimeRange';

const util = {
	getUnitOfTime: (timeRange: HistoryTimeRange) => {
		switch (timeRange) {
			case HistoryTimeRange.Day:
			case HistoryTimeRange.Custom:
				return 'days';
			case HistoryTimeRange.WorkWeek:
			case HistoryTimeRange.Week:
				return 'weeks';
			case HistoryTimeRange.Month:
				return 'months';
			default:
				throw new Error('invalid time range');
		}
	},
	selectorDataSource: (maxDays: number) => {
		const dataSource = [
			{
				display: TranslateText('fields.historyIntervalSelector.day'),
				id: 0,
				selected: true,
			},

			{
				display: TranslateText('fields.historyIntervalSelector.custom'),
				id: 4,
			},
		];
		if (maxDays >= 7) {
			dataSource.splice(1, 0, {
				display: TranslateText('fields.historyIntervalSelector.week'),
				id: 2,
			});
		}
		if (maxDays >= 31) {
			dataSource.splice(2, 0, {
				display: TranslateText('fields.historyIntervalSelector.month'),
				id: 3,
			});
		}

		return dataSource;
	},
};

const HistoryTimePicker = () => {
	const dispatch = useDispatch();

	const { from, to, historyTimeRange: timeRange } = useSelector(
		(state: ApplicationState) => state.historyFilter.dateFilter
	);
	const maxHistoryTime = useSelector(
		(state: ApplicationState) => state.currentSession?.personHistorySettings?.maxHistoryTime ?? 31
	);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);

	const [canNavigateForward, setCanNavigateForward] = useState(true);
	const lastDayAllowed = moment().add(1, 'days');
	const maxDateStart = moment()
		.startOf('day')
		.toDate();
	const maxDateEnd = lastDayAllowed.endOf('day').toDate();
	const [daySelectorDatasource, setDaySelectorDataSource] = useState([]);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	const [promptCallback, setPromptCallback] = useState<() => void>(null);

	useEffect(() => {
		setDaySelectorDataSource(util.selectorDataSource(maxHistoryTime));
	}, [maxHistoryTime]);

	useEffect(() => {
		if (timeRange === HistoryTimeRange.Custom) {
			const differenceOfDays = moment(to).diff(moment(from), 'days');
			setCanNavigateForward(
				moment(to)
					.add(differenceOfDays, 'days')
					.startOf('day') <= lastDayAllowed.startOf('day') &&
					!moment(to)
						.startOf('day')
						.isSame(lastDayAllowed.startOf('day'))
			); //disable/enable right arrow from custom time picker when a custom selection was made
		} else {
			const dateReference = timeRange === HistoryTimeRange.Day ? from : to;
			setCanNavigateForward(moment(dateReference).startOf('day') < lastDayAllowed.startOf('day'));
		}
	}, [to]);

	const backCallback = () => {
		const newFrom = moment(from);
		const newTo = moment(to);

		if (timeRange === HistoryTimeRange.Month) {
			newFrom.subtract(1, 'months').startOf('month');
			newTo.subtract(1, 'months').endOf('month');
		} else if (timeRange !== HistoryTimeRange.Custom) {
			const option = util.getUnitOfTime(timeRange);
			newFrom.subtract(1, option);
			newTo.subtract(1, option);
		} else {
			//custom period
			const dayDiff = newTo.diff(newFrom, 'days') || 1;
			newFrom.subtract(dayDiff, 'days');
			newTo.subtract(dayDiff, 'days');
		}

		dispatch(
			historyFilterActionCreators.setDateFilter({
				from: newFrom.toDate(),
				to: newTo.toDate(),
				historyTimeRange: timeRange,
			})
		);
		resetHistoryData();
	};

	const resetHistoryData = () => {
		dispatch({
			type: UPDATE_HISTORY_TRIPS,
			payload: [],
		});
		dispatch({
			type: SHOW_HISTORY_TRIP_DETAILS,
			payload: null,
		});
	};

	const forwardCallback = () => {
		const newFrom = moment(from);
		const newTo = moment(to);

		if (timeRange === HistoryTimeRange.Month) {
			newFrom.add(1, 'months').startOf('month');
			newTo.add(1, 'months').endOf('month');
		} else if (timeRange !== HistoryTimeRange.Custom) {
			const option = util.getUnitOfTime(timeRange);
			newFrom.add(1, option);
			newTo.add(1, option);
		} else {
			//custom period
			const dayDiff = newTo.diff(newFrom, 'days') || 1;
			newFrom.add(dayDiff, 'days');
			newTo.add(dayDiff, 'days');
		}

		dispatch(
			historyFilterActionCreators.setDateFilter({
				from: newFrom.toDate(),
				to: newTo.toDate(),
				historyTimeRange: timeRange,
			})
		);

		resetHistoryData();
	};

	const timeRangeChangeHandler = (value: HistoryTimeRange) => {
		const option = util.getUnitOfTime(value);
		dispatch(
			historyFilterActionCreators.setDateFilter({
				from: moment()
					.startOf(option)
					.toDate(),
				to: moment()
					.endOf(option)
					.toDate(),
				historyTimeRange: value,
			})
		);
		resetHistoryData();
	};

	const fromChangeCallback = (value: Date) => {
		const newFrom = moment(value).diff(maxDateEnd) < 0 ? moment(value) : moment(maxDateEnd).startOf('month');
		let newTo = moment(to);

		if (timeRange === HistoryTimeRange.Day) {
			newFrom.startOf('day');
			newTo = moment(newFrom).endOf('day');
		} else if (timeRange === HistoryTimeRange.Week) {
			newFrom.startOf('week');
			newTo = moment(newFrom).endOf('week');
		} else if (timeRange === HistoryTimeRange.Month) {
			newFrom.startOf('month');
			newTo = moment(newFrom).endOf('month');
		} else if (timeRange === HistoryTimeRange.Custom) {
			if (newTo < newFrom) {
				newTo.add(newFrom.diff(from, 'seconds'), 'seconds');
			} else if (newTo.diff(newFrom, 'seconds') / 24 / 60 / 60 > maxHistoryTime) {
				newTo = moment(newFrom);
				newTo.add(maxHistoryTime, 'day').subtract(1, 'second');
			}

			if (newTo > lastDayAllowed) {
				newTo = lastDayAllowed;
				newTo.endOf('day');
			}
		}
		dispatch(
			historyFilterActionCreators.setDateFilter({
				from: newFrom.toDate(),
				to: newTo.toDate(),
				historyTimeRange: timeRange,
			})
		);
		resetHistoryData();
	};

	const toChangeCallback = (value: Date) => {
		const newTo = moment(value).diff(maxDateEnd) < 0 ? moment(value) : moment(maxDateEnd);
		let newFrom = moment(from);

		if (newTo < newFrom) {
			newFrom.add(newTo.diff(to, 'seconds'), 'seconds');
		} else if (newTo.diff(newFrom, 'seconds') / 24 / 60 / 60 > maxHistoryTime) {
			newFrom = moment(newTo);
			newFrom.add(-maxHistoryTime, 'day').add(1, 'second');
		}
		dispatch(
			historyFilterActionCreators.setDateFilter({
				from: newFrom.toDate(),
				to: newTo.toDate(),
				historyTimeRange: timeRange,
			})
		);
		resetHistoryData();
	};

	const validateCurrentTripData = (callbackFn: () => void) => {
		if (!tripUnsavedData) {
			callbackFn();
		} else {
			setShowNotificationPrompt(true);
			setPromptCallback(() => () => callbackFn());
		}
	};
	return (
		<div className={'history-time-picker'}>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.unsavedData')}
				handleUserResponse={(response) => {
					setShowNotificationPrompt(false);
					if (response) {
						dispatch({
							type: SET_TRIP_UNSAVED_DATA,
							payload: false,
						});
						promptCallback();
						setPromptCallback(null);
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			<MaterialAutocomplete
				dataSource={daySelectorDatasource}
				valueId={timeRange}
				disableClearable={true}
				fullWidth={false}
				onChange={(e) => validateCurrentTripData(() => timeRangeChangeHandler(+e.value))}
			/>
			<IconButton onClick={() => validateCurrentTripData(() => backCallback())} size={'small'}>
				<ArrowBack />
			</IconButton>
			{timeRange === HistoryTimeRange.Custom && (
				<span className="label-span">{TranslateText('historyOverview.from')}</span>
			)}

			<MaterialDatePicker
				name="datePickerElement"
				showTime={timeRange === HistoryTimeRange.Custom}
				date={from}
				format={
					timeRange === HistoryTimeRange.Custom
						? DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeFormat(), true)
						: DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true)
				}
				onDateChange={(date: Date) => validateCurrentTripData(() => fromChangeCallback(date))}
				maxDate={maxDateEnd}
				fullWidth={false}
				maxWidth={timeRange !== HistoryTimeRange.Day ? 300 : null}
				display={timeRange !== HistoryTimeRange.Custom ? timeRange : null}
				disabled={false}
				width={timeRange === HistoryTimeRange.Custom ? 220 : null}
				hiddenCloseButton={true}
			/>
			{timeRange === HistoryTimeRange.Custom && (
				<>
					<span className="label-span">{TranslateText('historyOverview.to')}</span>
					<MaterialDatePicker
						name="datePickerElement"
						showTime={true}
						date={to}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
							DateTimeUtil.dateTimeFormat(),
							true
						)}
						onDateChange={(date: Date) => validateCurrentTripData(() => toChangeCallback(date))}
						maxDate={maxDateEnd}
						fullWidth={false}
						width={220}
						hiddenCloseButton={true}
					/>
				</>
			)}
			<IconButton
				onClick={() => validateCurrentTripData(() => forwardCallback())}
				disabled={!canNavigateForward}
				size={'small'}
			>
				<ArrowForward />
			</IconButton>
			<Button
				size={'small'}
				hidden={timeRange === HistoryTimeRange.Custom}
				variant={'contained'}
				onClick={() => validateCurrentTripData(() => timeRangeChangeHandler(timeRange))}
			>
				{TranslateText(`historyOverview.current${HistoryTimeRange[timeRange]}`)}
			</Button>
		</div>
	);
};

export default HistoryTimePicker;
