import React, {useEffect, useMemo, useState } from "react";
import { History, LocationState } from 'history';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import {GridEntity} from "../GridOverview/GridOverview";
import {GridFilters, MatchMode, SortOrder} from "../../models/GridOverview";
import {ApplicationState} from "../../store";
import TextColumn from "../GridOverview/Columns/TextColumn";
import {TranslateText} from "../../utils/Translations";
import GlobalSettings from "../../GlobalSettings.json";
import {ClaimUtil} from "../../authorization/ClaimUtil";
import ClaimType, {ClaimValue} from "../../authorization/ClaimType";
import BooleanColumn from "../GridOverview/Columns/BooleanColumn";
import MultiSelectionColumn, {MultiSelectionColumnOption} from "../GridOverview/Columns/MultiSelectionColumn";
import DateColumn from "../GridOverview/Columns/DateColumn";
import CustomerLevelEnum from "../../models/CustomerLevelEnum";
import EntityTypeEnum from "../../models/EntityTypeEnum";
import NewGridOverview from "../GridOverview/NewGridOverview";
import ajaxUtil from "../../utils/Ajax";
import {FormatDate} from "../../utils/DateUtils";

interface Props {
	history: History<LocationState>;
}

interface InformationNotificationTypes {
	id: string;
	name: string;
}
export interface InformationNotificationDto extends GridEntity {
	customerId: string;
	customerName: string;
	language: string;
	name: string;
	description: string;
	title: string;
	urgent: boolean;
	driver: boolean;
	type: string;
	startDateTime: string;
	endDateTime?: string;
	active: boolean;
}

const fieldNames = {
	customerName: 'customerName',
	language: 'language',
	name: 'name',
	description: 'description',
	title: 'title',
	urgent: 'urgent',
	driver: 'driver',
	type: 'type',
	startDateTime: 'startDateTime',
	endDateTime: 'endDateTime',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.startDateTime,
	sortOrder: SortOrder.Ascendent,
	includeTime: true,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.language]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.title]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.urgent]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.driver]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.type]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.startDateTime]: {
			value: null,
			matchMode: MatchMode.DateIs,
		},
		[fieldNames.endDateTime]: {
			value: null,
			matchMode: MatchMode.DateIs,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};
const InformationNotificationsOverview = (props: Props) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);
	const [filtersDisabled, setFiltersDisabled] = useState<boolean>(false);
	const [informationNotificationTypeSelectionOption, setInformationNotificationTypeSelectionOption] = useState<MultiSelectionColumnOption[]>([]);


	useEffect(() => {
		setFiltersDisabled(unsavedData);
	}, [unsavedData]);

	useEffect(() => {
		getTypes()
	},[])

	const getTypes = () => {
		let array: { name: string; code: string; }[] = [];
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/informationNotificationTypes`;
		ajaxUtil.get<InformationNotificationTypes[]>(url).then((result) => {
			const types = [...result] as InformationNotificationTypes[];
			for(let i=0; i < types.length; i++)
				array.push({
					name : TranslateText(`notificationTypes.${types[i]?.name}`),
					code: types[i]?.id,
					});
			setInformationNotificationTypeSelectionOption(array)
		});
	}

	const formatDateCustom = (data: string | Date) => {
		return FormatDate(data, false,false,false);
	}

	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomerName'),
			body: (rowData: InformationNotificationDto) => (
				<Link title={rowData.customerName} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		TextColumn({
			fieldName: fieldNames.language,
			header: TranslateText('maintenanceOverview.grid.colLanguage'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.title,
			header: TranslateText('maintenanceOverview.grid.colTitle'),
			sortable: true,
			filterable: true,
		}),
		BooleanColumn({
			fieldName: fieldNames.urgent,
			header: TranslateText('maintenanceOverview.grid.colUrgent'),
			sortable: true,
			filterable: true,
		}),
		BooleanColumn({
			fieldName: fieldNames.driver,
			header: TranslateText('maintenanceOverview.grid.colDriver'),
			sortable: true,
			filterable: true,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.type,
			header: TranslateText('maintenanceOverview.grid.colType'),
			sortable: true,
			filterable: true,
			body: (rowData: InformationNotificationDto) => {
				const informationNotificationType = informationNotificationTypeSelectionOption.find((x) => x.code === rowData.type)?.name;
				return <span title={informationNotificationType}>{informationNotificationType}</span>;
			},
			selectOptions: informationNotificationTypeSelectionOption,
			filterDisabled: filtersDisabled,
		}),
		DateColumn({
			fieldName: fieldNames.startDateTime,
			header: TranslateText('maintenanceOverview.grid.colStartDateTime'),
			sortable: true,
			filterable: true,
			showTime: true,
			formatDateFunction: formatDateCustom,
		}),
		DateColumn({
			fieldName: fieldNames.endDateTime,
			header: TranslateText('maintenanceOverview.grid.colEndDateTime'),
			sortable: true,
			filterable: true,
			showTime: true,
			formatDateFunction: formatDateCustom,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];
	return(
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.informationNotificationsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.informationNotifications')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default}
			showAddConfigClaim={[
				{
					claim: ClaimType.InformationNotificationManage,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.InformationNotification}
			useNewAddWizard
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
}
export default InformationNotificationsOverview;
