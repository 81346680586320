import '../PersonDetails/PersonDetails.scss';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import { userDataActionCreators } from 'store/UserData';

import { enableRipple } from '@syncfusion/ej2-base';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import IdIdentifiable from '../../models/IdIdentifiable';
import { Person } from '../../models/Person';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { availableCustomersActions } from '../../store/AvailableCustomers';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseDetails from '../BaseDetails';
import { BaseDetailsProps, BaseDetailsState } from '../BaseDetails/BaseDetails';
import BaseWidget from '../BaseWidget';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import DetailsNavigationBar from '../DetailsNavigationBar/DetailsNavigationBar';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import { AddGroupMemberToDriver } from '../Widgets/ButtonWidget/AddGroupToPersonConnection';
import ButtonWidget from '../Widgets/ButtonWidget/ButtonWidget';
import KeyPersonWidget from '../Widgets/KeyPersonWidget';
import PersonInformationWidget from '../Widgets/PersonInformationWidget';
import PersonObjectWidget from '../Widgets/PersonObjectWidget';
import { DriverGroupMember } from '../Widgets/Views/PersonGroupsConnection';

enableRipple(true);

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		customerId: state.currentSession.customerId,
		filteredCustomerId: state.globalCustomer.filteredCustomer?.id,
		isActive: state.loadedEntityContext.entityContextData.isActive,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterText: state.globalCustomer.filterText.drivers,
		user: state.oidc.user,
		loggedCustomerLevel: state.currentSession.customerLevel,
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification,
		entityDetails: state.loadedEntityContext?.entityContextData?.entityDetails,
		aspNetUserId: state.currentSession.aspNetUserId,
		gridFilters: state.gridOverview[EntityTypeEnum.Driver].gridFilters,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
		availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		userDataActions: bindActionCreators(userDataActionCreators, dispatch),
		setUnsavedDataInStore: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
};

type MatchProps = {
	match: { params: { [key: string]: string } };
};

const connector = connect(mapState, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PersonDetailsProps = BaseDetailsProps & PropsFromRedux & MatchProps;

interface DriverDetailsState {
	isLoaded: boolean;
}

type State = BaseDetailsState & DriverDetailsState;

class DriverDetails extends BaseDetails<PersonDetailsProps, State> {
	constructor(props: PersonDetailsProps) {
		super(props);
		this.state = {
			...this.state,
			currentRecordId: props.match.params.id,
			isLoaded: false,
		};
		this.customerId = this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId;
	}

	personInformationWidgetRef: PersonInformationWidget;
	objectConnectionConflictsNotifierRef: ConnectionConflictsNotifier;
	keyConnectionConflictsNotifierRef: ConnectionConflictsNotifier;
	customerId: string;

	componentDidMount() {
		this.getData();
		this.props.availableCustomersActions.toggleSelection(false);
	}

	async getData() {
		const url = `${'/api/maintenance/drivers'}/${this.state.currentRecordId}/bypersonid`;
		await ajaxUtil.get<Person>(url).then((data) => {
			const result = data ? data : new Person();

			this.setState({
				breadcrumbDisplay:
					TranslateText('common.persons') +
					' > ' +
					result.firstName +
					' ' +
					result.lastName +
					' (' +
					result.code +
					')',
				reloadInfo: false,
			});
			this.props.loadedEntityContextActions.setLoadedPersonContext(result);
			this.setState({ isLoaded: true });
		});
	}

	componentWillUnmount() {
		this.loadedWidgets = [];
		this.props.loadedEntityContextActions.setLoadedPersonContext(null);
	}

	protected closeEditModeOnInformationWidget() {
		this.personInformationWidgetRef.closeEditPanelMode();
	}

	protected fillWidgets(recordId: string) {
		this.personInformationWidgetRef.fillPerson(recordId);
	}

	protected fillInformationWidget(recordId: string) {
		this.personInformationWidgetRef.fillPerson(recordId);
	}

	editModeCallback(editMode: boolean): void {
		this.setState({ widgetEditing: editMode });
	}

	handleConflictingConnections(sender: ConnectionsConflictResolver, confirm: boolean) {
		sender.handleConflictingConnections(confirm);
	}

	async handleDataSaved(sender: BaseWidget) {
		this.handleSavedCallback(sender);
		this.setState({ reloadInfo: true });
		await this.getData();
	}

	protected handleGoBackTo(show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) {
		this.props.history.push('/drivers', { id: syncRecord.id });
	}
	private setUpdatedPersonCallback(person: Person) {
		this.props.loadedEntityContextActions.setLoadedPersonContext(person);
	}
	render() {
		return (
			this.state.isLoaded && (
				<div className="content-container">
					<NotificationPrompt
						title={TranslateText('common.titleUnsavedData')}
						message={TranslateText('notificationMessages.cancel')}
						handleUserResponse={this.handleUnsavedDataResponse.bind(this)}
						displayDialog={this.state.notifyAboutUnsavedData}
					/>
					<DetailsNavigationBar
						history={this.props.history}
						currentRecordId={this.state.currentRecordId}
						goBackText={TranslateText('detailsScreen.navigationBar.backToDrivers')}
						goBackToCallback={this.handleGoBackTo.bind(this)}
						breadcrumbsText={this.state.breadcrumbDisplay}
						navigationUrl={`${GlobalSettings.driversMaintenanceApi}/navigation`}
						detailUrl={GlobalSettings.route.drivers}
						filter={{
							customerId: this.props.filteredCustomerId,
							filterText: this.props.filterText,
							gridFilters: this.props.gridFilters,
						}}
						entityType={EntityTypeEnum.Driver}
					/>
					<ConnectionConflictsNotifier
						ref={(scope) => {
							this.objectConnectionConflictsNotifierRef = scope;
						}}
						connectionType={ConnectionTypeEnum.PersonObject}
						visible={false}
						userAnswearCallback={this.handleConflictingConnections.bind(this)}
					/>
					<ConnectionConflictsNotifier
						ref={(scope) => {
							this.keyConnectionConflictsNotifierRef = scope;
						}}
						connectionType={ConnectionTypeEnum.PersonKey}
						visible={false}
						userAnswearCallback={this.handleConflictingConnections.bind(this)}
					/>
					<Scrollbar
						style={{
							maxHeight: 'calc(100vh - 110px)',
							minHeight: 'calc(100vh - 110px)',
						}}
						native={true}
					>
						{this.props.entityDetails && (
							<DashboardLayoutComponent
								immediateRender={true}
								draggableHandle=".dragicon"
								mediaQuery={'max-width: 1100px'}
								allowResizing={true}
								allowFloating={true}
								allowPushing={true}
								columns={20}
								cellAspectRatio={95 / 100}
								ref={(scope) => {
									this.dashboardObj = scope;
								}}
								id="predefine_dashboard"
								cellSpacing={this.cellSpacing}
							>
								<ProtectedContainer claim={ClaimType.Drivers}>
									<PersonInformationWidget
										person={this.props.entityDetails}
										id="personInformation"
										row="0"
										col="0"
										sizeX="4"
										sizeY="7"
										entityId={this.state.currentRecordId}
										customerId={this.customerId}
										allowEditMode={!this.state.widgetEditing}
										setUpdatedPersonCallback={(person: Person) =>
											this.setUpdatedPersonCallback(person)
										}
										editModeCallback={this.editModeCallback.bind(this)}
										savedCallback={this.handleSavedCallback.bind(this)}
										notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
										changeDataCallback={this.changeDataCallback.bind(this)}
										apiBaseUrl={GlobalSettings.driversMaintenanceApi}
										ref={(scope) => {
											this.personInformationWidgetRef = scope;
											this.AddLoadedWidget(scope);
										}}
										forceReload={this.state.reloadInfo}
										showEditButton={ClaimUtil.validateClaim(this.props.user, {
											claim: ClaimType.Drivers,
											values: [ClaimValue.edit],
										})}
										aspNetUserId={this.props.aspNetUserId}
										getUserData={this.props.userDataActions.getUserData}
									/>
								</ProtectedContainer>
								<ProtectedContainer claimList={[ClaimType.Drivers, ClaimType.Groups]}>
									<ButtonWidget
										position={{
											row: 0,
											col: 4,
											sizeX: 4,
											sizeY: 4,
										}}
										allowEditMode={!this.state.widgetEditing}
										editModeCallback={this.editModeCallback.bind(this)}
										entityId={this.state.currentRecordId}
										widgetTitle={TranslateText('common.personGroups')}
										changeDataCallback={this.changeDataCallback.bind(this)}
										viewComponent={DriverGroupMember}
										buttons={[AddGroupMemberToDriver]}
										showEditButton={ClaimUtil.validateClaimList(this.props.user, [
											{
												claim: ClaimType.Drivers,
												values: [ClaimValue.edit],
											},
											{
												claim: ClaimType.Groups,
												values: [ClaimValue.edit],
											},
										])}
									/>
								</ProtectedContainer>

								<ProtectedContainer
									claimConfig={[
										{ claim: ClaimType.Drivers, values: [ClaimValue.view] },
										{ claim: ClaimType.Objects, values: [ClaimValue.view] },
										{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
									]}
								>
									{this.props.driverIdentification && (
										<PersonObjectWidget
											row="4"
											col="4"
											sizeX="16"
											sizeY="3"
											allowEditMode={!this.state.widgetEditing}
											editModeCallback={this.editModeCallback.bind(this)}
											conflictingConnectionNotifier={this.objectConnectionConflictsNotifierRef}
											id={'objectHistory'}
											entityType={EntityTypeEnum.Person}
											entityId={this.state.currentRecordId}
											changeDataCallback={this.changeDataCallback.bind(this)}
											ref={(scope) => {
												this.AddLoadedWidget(scope);
											}}
											savedCallback={this.handleDataSaved.bind(this)}
											user={this.props.user}
											driverIdentification={this.props.driverIdentification}
											loggedCustomerLevel={this.props.loggedCustomerLevel}
										/>
									)}
								</ProtectedContainer>
								<ProtectedContainer
									claimConfig={[
										{ claim: ClaimType.Drivers, values: [ClaimValue.view] },
										{ claim: ClaimType.Keys, values: [ClaimValue.view] },
										{ claim: ClaimType.KeyPersonConnection, values: [ClaimValue.edit] },
									]}
								>
									{this.props.driverIdentification && (
										<KeyPersonWidget
											row="0"
											col="8"
											sizeX="12"
											sizeY="4"
											conflictingConnectionNotifier={this.keyConnectionConflictsNotifierRef}
											changeDataCallback={this.changeDataCallback.bind(this)}
											id={'personHistory'}
											entityType={EntityTypeEnum.Driver}
											entityId={this.state.currentRecordId}
											getAccessTokenCallback={() => this.props.access_token}
											allowEditMode={!this.state.widgetEditing}
											user={this.props.user}
											loggedCustomerLevel={this.props.loggedCustomerLevel}
											ref={(scope) => {
												this.AddLoadedWidget(scope);
											}}
											editModeCallback={this.editModeCallback.bind(this)}
											showEditButton={ClaimUtil.validateClaimList(this.props.user, [
												{
													claim: ClaimType.Drivers,
													values: [ClaimValue.edit],
												},
												{
													claim: ClaimType.Keys,
													values: [ClaimValue.edit],
												},
												{
													claim: ClaimType.KeyPersonConnection,
													values: [ClaimValue.edit],
												},
											])}
										/>
									)}
								</ProtectedContainer>
							</DashboardLayoutComponent>
						)}
					</Scrollbar>
				</div>
			)
		);
	}
}

export default connector(DriverDetails);
