import './style.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimType from '../../../authorization/ClaimType';
import { ClaimUtil } from '../../../authorization/ClaimUtil';
import { ApplicationState, IAvailableCustomer } from '../../../store';
import { TranslateText } from '../../../utils/Translations';
import { InputLabel, TextField } from '@material-ui/core';
import MaterialTextField from '../MaterialTextField/MaterialTextField';

type Props = {
	customerId: string;
	wrapInForm: boolean;
	isForNewAddWizard?: boolean;
};

const CustomerNameDisplay = (props: Props) => {
	//check access to customers
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const [hasCustomersAccess, setHasCustomersAccess] = useState(() =>
		ClaimUtil.validateHasClaim(user, ClaimType.Customers)
	);
	useEffect(() => {
		setHasCustomersAccess(ClaimUtil.validateHasClaim(user, ClaimType.Customers));
	}, [user]);

	//set customer name
	const [customerName, setCustomerName] = useState('');
	const availableCustomers = useSelector((state: ApplicationState) => state.availableCustomers.data);
	const currentCustomer = useSelector((state: ApplicationState) => state.currentSession.customer);
	useEffect(() => {
		const availableCustomer = availableCustomers.find((c: IAvailableCustomer) => c.value === props.customerId);
		const tempCustomerName = availableCustomer
			? `${availableCustomer.text} (${availableCustomer.code})`
			: currentCustomer.id === props.customerId
			? currentCustomer.nameAndCode
			: props.customerId;
		setCustomerName(tempCustomerName);
	}, [availableCustomers, props.customerId]);

	if (props.isForNewAddWizard) {
		return hasCustomersAccess ? (
			<div className={props.wrapInForm ? 'form-group' : ''}>
				<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4 }}>
					{TranslateText('fields.customer')}
				</InputLabel>
				<TextField
					type={'text'}
					fullWidth={true}
					inputProps={{ style: { fontSize: 10 } }}
					value={customerName}
					disabled={true}
				/>
			</div>
		) : null;
	}

	return hasCustomersAccess ? (
		<div className={props.wrapInForm ? 'form-group' : ''}>
			<div>
				<label className="customerDisplayLabel">{TranslateText('fields.customer')}</label>
			</div>
			<div>
				<label className="customerDisplayLabel">{customerName}</label>
			</div>
		</div>
	) : null;
};
CustomerNameDisplay.defaultProps = {
	wrapInForm: true,
};
export default CustomerNameDisplay;
