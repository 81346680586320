import { Column } from 'primereact/column';
import { default as React, useCallback, useEffect, useState } from 'react';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import { GridWidgetOverview } from '../GridOverview/GridWidgetOverview';
import { IButtonWidgetView } from './ButtonWidget/ButtonWidget';

interface IGridRowData {
	name: string;
	itemId: string;
}

const prepareGridData = (data: IGridRowData[]) => {
	data.forEach((role) => {
		role.name = TranslateText(role.name);
	});
	return (data as IGridRowData[]).sort((x, y) => x.name.localeCompare(y.name));
};

const RoleReportsView = (props: IButtonWidgetView) => {
	const [gridData, setGridData] = useState([]);

	const terminateConnectionCallback = useCallback(
		(data: IGridRowData) => {
			if (data.itemId) {
				ajaxUtil
					.post(`${GlobalSettings.rolesMaintenanceApi}/deleteRoleReportItem/${props.entityId}`, {
						ItemId: data.itemId,
					})
					.then(() => {
						props.reloadDataCallback();
					});
			}
		},
		[props.reloadDataCallback]
	);

	useEffect(() => {
		setGridData(prepareGridData(props.data));
	}, [props.data]);

	return (
		<GridWidgetOverview
			columns={[
				<Column
					key="name"
					field="name"
					className="widget-grid-column"
					header={TranslateText('widgets.grid.colName')}
					style={{ width: '250px', textAlign: 'left' }}
				/>,
				<Column
					key="disconnect"
					className="icon-button-column"
					body={(rowData: IGridRowData) =>
						props.editMode ? (
							<Icon
								onClick={() => {
									terminateConnectionCallback(rowData);
								}}
							>
								clear
							</Icon>
						) : null
					}
					style={{ textAlign: 'right' }}
				/>,
			]}
			data={gridData}
			total={gridData.length}
			paginator={true}
		/>
	);
};

export default RoleReportsView;
