import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import React, { useEffect, useState } from 'react';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

type Props = WidgetViewDefault & {
	data: FleetOverviewSettingsState;
};

interface FleetOverviewSettingsState {
	defaultOrder: number;
	defaultTagId: string;
	defaultTagName: string;
	showLastDriverOfParkedObject: boolean;
	showLastObjectOfParkedDriver: boolean;
	isForPerson: boolean | null;
}

const DefaultFleetOverviewValues = {
	defaultTagId: '4f94641b-8e1d-4cba-8066-3392b39111e0',
	defaultOrder: 0,
	defaultTagName: 'Default',
};

const getConstants = () => {
	return {
		defaultOrder: [
			{ id: 0, display: TranslateText('common.az') },
			{ id: 1, display: TranslateText('common.za') },
		],
		defaultTag: [{ id: DefaultFleetOverviewValues.defaultTagId, display: TranslateText('common.default') }],
		defaultValues: {
			defaultOrder: DefaultFleetOverviewValues.defaultOrder,
			defaultTagId: DefaultFleetOverviewValues.defaultTagId,
			defaultTagName: DefaultFleetOverviewValues.defaultTagName,
		} as FleetOverviewSettingsState,
		fieldsConfig: { text: 'display', value: 'id' },
	};
};

const GetFleetOverviewSettingsValidationConfig = (isForPerson?: boolean | null): FieldRules => {
	const fieldRules: FieldRules = {
		defaultOrder: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultTagId: {
			rules: { required: ValidatorFunctions.required() },
		},
		showLastDriverOfParkedObject: {
			rules: { required: isForPerson ? ValidatorFunctions.requiredWithNull() : ValidatorFunctions.required() },
		},
		showLastObjectOfParkedDriver: {
			rules: { required: isForPerson ? ValidatorFunctions.requiredWithNull() : ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const FleetOverviewSettingsView = (props: Props) => {
	const [constants] = useState(getConstants);
	const [defaultTagNamesData, setDefaultTagNamesData] = useState<AutocompleteItem[]>([]);

	useEffect(() => {
		ajaxUtil
			.get<AutocompleteItem[]>(`${GlobalSettings.fleetTagsMaintenanceApi}/fleetTagsNamesData/`)
			.then((data) => {
				//translate default data text  workaround until default tag name will be implemented
				setDefaultTagNamesData(
					data.map((item) => {
						return { id: item.id, display: TranslateText(`fields.${item.display.toLowerCase()}`) };
					})
				);
			});
		props.setValidatorCallback(
			new Validator({ fieldRules: GetFleetOverviewSettingsValidationConfig(props.data.isForPerson) })
		);
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | number | boolean, key: keyof FleetOverviewSettingsState) => {
			const newState = { ...props.data };
			(newState[key] as string | number | boolean | null) = value;
			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper'}>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultOrder}
					dataSource={constants.defaultOrder}
					name="defaultOrder"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultOrder')}
					onChange={({ value }) => events.onValueChanged(value as number, 'defaultOrder')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultOrder} />
			</div>
			<YesNoCombobox
				name={'showLastDriverOfParkedObject'}
				value={props.data.showLastDriverOfParkedObject}
				placeholder={TranslateText('fields.showLastDriverOfParkedObject')}
				change={(value) => events.onValueChanged(value as boolean | null, 'showLastDriverOfParkedObject')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
				showDefaultTrueFalse={props.data.isForPerson}
			/>
			<YesNoCombobox
				name={'showLastObjectOfParkedDriver'}
				value={props.data.showLastObjectOfParkedDriver}
				placeholder={TranslateText('fields.showLastObjectOfParkedDriver')}
				change={(value) => events.onValueChanged(value as boolean | null, 'showLastObjectOfParkedDriver')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
				showDefaultTrueFalse={props.data.isForPerson}
			/>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultTagId}
					dataSource={defaultTagNamesData}
					name="defaultOrder"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultTag')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultTagId')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultTagId} />
			</div>
			<div className="e-text e-disabled italic">{TranslateText('fields.tagExample')}</div>
		</div>
	);
};

export default FleetOverviewSettingsView;
