import { useEffect, useState } from 'react';
import { useDebounceCallback, useEffectWithNoMount } from 'utils/hooks';

const useColorPicker = (initialColor: string, onColorChangedHandler: (color: string) => void) => {
	const [color, setColor] = useState<string>(initialColor);

	const debouncedColorChangedHandler = useDebounceCallback(onColorChangedHandler, 100);

	useEffect(() => {
		if (initialColor !== color) {
			setColor(initialColor);
		}
	}, [initialColor]);

	useEffectWithNoMount(() => {
		debouncedColorChangedHandler(color);
	}, [color]);

	return { color, setColor };
};

export { useColorPicker };
