import './historyTripCard.scss';

import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import ObjectNameWithTimeZoneOffset from 'components/ObjectNameWithTimeZoneOffset/ObjectNameWithTimeZoneOffset';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { FormatDateWithReferenceDate, GetHourAndMinutes } from 'utils/DateUtils';
import { getTripVisitTime } from 'utils/TripUtils';

import { Checkbox } from '@material-ui/core';
import {
	CheckBoxOutlineBlankOutlined,
	CheckBoxOutlined,
	IndeterminateCheckBoxOutlined,
	PauseCircleFilled,
	Person,
	PinDropOutlined,
	Schedule,
} from '@material-ui/icons';

import { ReactComponent as DrivingSvgImage } from '../../assets/icons/DrivingStateSideBar.svg';
import ClaimType from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import FileDownloadIcon from '../../components/Common/Icons/FileDownloadIcon';
import FileUploadIcon from '../../components/Common/Icons/FileUploadIcon';
import HourglassBottomOutlined from '../../components/Common/Icons/HourglassBottomOutlined';
import TripIntermediateIcon from '../../components/Common/Icons/TripIntermediateIcon';
import PrivacySettingsEnum from '../../models/PrivacySettingsEnum';
import DateTimeUtil from '../../shared/datetime/DateTimeUtil';
import { TripGrouping } from '../../store/HistoryStore';
import { TripNumber } from '../TripNumber';
import { AddressRow } from './AddressRow';
import { GetTripType } from './HistoryTripsUtils';
import { useShouldScrollIntoView, useUpdateInViewCards } from './hooks';
import { HistoryTripCardPropsRef, ProcessedTripCard, TripCardCommonProps } from './types';
import { UpdateObservable } from './utils';

interface Props {
	tripConfig: ProcessedTripCard;
	selected: boolean;
	onVisibilityChanged: (value: boolean) => void;
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
	groupFinishedExpanding: boolean;
	scrollTrip: { idWithParent: string } | null;
	historyTripCardPropsRef: React.MutableRefObject<HistoryTripCardPropsRef>;
	tripCardCommonPropsRef: React.MutableRefObject<TripCardCommonProps>;
	tripsObservable?: UpdateObservable;
}

const HistoryTripCard = (props: Props) => {
	const tripConfig = props.tripConfig.trip;
	const mainGrouping = props.historyTripCardPropsRef.current.groupingOptions;

	const driverIdentification = props.tripCardCommonPropsRef.current.driverIdentification;
	const customerPrivacySettings = props.tripCardCommonPropsRef.current.customerPrivacySettings;
	const showHistoryLocations = props.tripCardCommonPropsRef.current.showHistoryLocations;
	const trackTypeSpecification = props.tripCardCommonPropsRef.current.trackTypeSpecification;
	const currentPersonId = props.tripCardCommonPropsRef.current.currentPersonId;
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(
		props.tripCardCommonPropsRef.current.user,
		ClaimType.ShowPrivateTrips
	);
	const isExpanded = props.tripCardCommonPropsRef.current.isExpanded;

	const privateDataMask = '****';

	const { icon } = useTryGetSvgIconFromQueryCache(tripConfig.objectIcon);

	useEffect(() => {
		if (
			props.historyTripCardPropsRef.current &&
			props.historyTripCardPropsRef.current.tripDetailsId === tripConfig.id &&
			props.historyTripCardPropsRef.current.showTripDetailsCallback
		) {
			props.historyTripCardPropsRef.current.showTripDetailsCallback(tripConfig);
		}
	}, []);

	const cardClicked = useRef(false);
	const onCardClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (!cardClicked.current) {
				cardClicked.current = true;
				setTimeout(() => {
					cardClicked.current = false;
				}, 400);
				props.onClick(e);
			}
		},
		[props.onClick]
	);

	const shouldScrollIntoView = useShouldScrollIntoView(
		props.selected,
		props.groupFinishedExpanding,
		tripConfig.idWithParent,
		props.scrollTrip,
		props.historyTripCardPropsRef
	);

	const getEndAddressIcon = (): React.ReactNode => {
		if (tripConfig.isInProgress) {
			return <DrivingSvgImage className={`direction-icon direction-${tripConfig.direction?.toLowerCase()}`} />;
		}

		if (!tripConfig.nextConnectedTripId) {
			return <FileDownloadIcon className={'end-address-icon'} />;
		}

		if (isExpanded) {
			return <FileDownloadIcon className={'start-address-icon'} style={{ color: '#ff931e' }} />;
		}

		return <TripIntermediateIcon className={'intermediate-address-icon'} />;
	};

	const getStartAddressIcon = (): React.ReactNode => {
		if (tripConfig.isNonTrip) {
			return <FileDownloadIcon className={'nontrip-address-icon'} />;
		}

		if (!tripConfig.prevConnectedTripId) {
			return <FileUploadIcon className={'start-address-icon'} />;
		}

		if (isExpanded) {
			return <FileUploadIcon className={'start-address-icon'} style={{ color: '#ff931e' }} />;
		}

		return null;
	};

	const { initialRender, refEl } = useUpdateInViewCards(tripConfig.idWithParent, props.tripsObservable);

	return (
		!(
			tripConfig.isInProgress &&
			tripConfig.private &&
			customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
			!(tripConfig?.personId === currentPersonId || userCanSeePrivateData)
		) && (
			<ReactScrollIntoViewIfNeeded
				active={shouldScrollIntoView}
				options={{ behavior: 'auto', scrollMode: 'if-needed', block: 'nearest' }}
				className="history-trip-card-scroll"
			>
				<div
					ref={refEl}
					style={
						initialRender
							? {
									width: '100%',
									height: tripConfig.isNonTrip
										? '43px'
										: isExpanded || (!isExpanded && tripConfig.prevConnectedTripId)
										? '67px'
										: '87px',
							  }
							: {}
					}
				>
					{!initialRender ? (
						<div
							className={`history-trip-card ${props.selected && tripConfig.visible ? 'selected' : ''} ${
								tripConfig.isInProgress ? 'in-progress-background' : ''
							}`}
							onDoubleClick={() => {
								props.historyTripCardPropsRef.current &&
									props.historyTripCardPropsRef.current.showTripDetailsCallback &&
									props.historyTripCardPropsRef.current.showTripDetailsCallback(tripConfig);
							}}
							onClick={onCardClick}
						>
							<TripNumber
								tripColor={tripConfig.tripColor}
								tripIndex={tripConfig.index}
								isInProgress={tripConfig.isInProgress}
								containerClass="trip-number-trip-card"
							/>

							<div className="card-row-container">
								{!tripConfig.isNonTrip &&
								(mainGrouping.grouping === TripGrouping.driverName ||
									(mainGrouping.grouping !== TripGrouping.objectName && tripConfig.isPerson) ||
									driverIdentification) ? (
									<div className="card-row">
										<span className="identity-container">
											{mainGrouping.grouping === TripGrouping.driverName ||
											(mainGrouping.grouping !== TripGrouping.objectName &&
												tripConfig.isPerson) ? (
												<SvgIcon
													icon={icon.content}
													color="#212529"
													size={20}
													onClick={(icon: string) => {}}
												/>
											) : driverIdentification ? (
												<Person className="identity-type-icon" />
											) : null}
											<span className="identity-name">
												{mainGrouping.grouping === TripGrouping.driverName ||
												(mainGrouping.grouping !== TripGrouping.objectName &&
													tripConfig.isPerson) ? (
													<ObjectNameWithTimeZoneOffset
														useToolTip
														objectNameWithTimeZone={tripConfig.objectNameWithTimeZoneOffset}
													/>
												) : driverIdentification ? (
													tripConfig.personName
												) : null}
											</span>
										</span>
									</div>
								) : null}

								<div
									className={`card-row trip-address-container ${
										tripConfig.isNonTrip ? 'nontrip-address' : ''
									} ${mainGrouping.ascending ? 'ascending' : ''}`}
								>
									<AddressRow
										locationName={showHistoryLocations ? tripConfig.startLocationName : undefined}
										address={tripConfig.startAddress}
										icon={getStartAddressIcon()}
									/>

									{!tripConfig.isNonTrip ? (
										<AddressRow
											locationName={showHistoryLocations ? tripConfig.endLocationName : undefined}
											address={tripConfig.endAddress}
											icon={getEndAddressIcon()}
										/>
									) : null}
								</div>
								<div className={'card-row card-row-details'}>
									{!tripConfig.isNonTrip ? (
										<>
											<div className="trip-details">
												<Schedule />
												{customerPrivacySettings ===
													PrivacySettingsEnum.HidePrivateLocationAndTime &&
												!tripConfig.startTripTime &&
												!tripConfig.endTripTime ? (
													privateDataMask
												) : (
													<span className="trip-time-period">
														<span>
															{GetHourAndMinutes(tripConfig.startTripTime, true)} {' - '}
														</span>
														<span className={tripConfig.isInProgress ? 'hidden-text' : ''}>
															{GetHourAndMinutes(tripConfig.endTripTime, true)}
														</span>
													</span>
												)}
											</div>
											<div className="trip-details trip-duration">
												<HourglassBottomOutlined />
												{customerPrivacySettings ===
													PrivacySettingsEnum.HidePrivateLocationAndTime &&
												// compare it to null because tripDuration is null when it has to be hidden
												tripConfig.tripDuration === null
													? privateDataMask
													: DateTimeUtil.calculateTimePassed(
															tripConfig.tripDuration,
															new Date(),
															false
													  )}
											</div>
											<div className="trip-details trip-distance">
												<PinDropOutlined />
												{`${tripConfig.totalMileage.toFixed(1)} km`}
											</div>
											<div className={'trip-details private-label'}>
												{trackTypeSpecification ? (
													<div>{GetTripType(tripConfig.trackType)}</div>
												) : null}
											</div>
										</>
									) : (
										<div className="trip-details nontrip-duration">
											<Schedule />
											<span className="trip-time-period">
												<span>
													{GetHourAndMinutes(tripConfig.startTripTime, true)} {' - '}
												</span>
												{!tripConfig.isInProgress ? (
													<span>
														{FormatDateWithReferenceDate(
															tripConfig.endTripTime,
															tripConfig.startTripTime,
															true
														)}
													</span>
												) : null}
											</span>
										</div>
									)}

									<div className="trip-details visit-time">
										{(!tripConfig.isInProgress || tripConfig.isNonTrip) &&
										(tripConfig.visitTime ||
											customerPrivacySettings ===
												PrivacySettingsEnum.HidePrivateLocationAndTime) ? (
											<>
												<PauseCircleFilled style={{ color: '#ffb23f' }} />
												{getTripVisitTime(
													tripConfig,
													customerPrivacySettings,
													currentPersonId,
													userCanSeePrivateData
												)}
											</>
										) : null}
									</div>
								</div>
							</div>
							<Checkbox
								color={'default'}
								className="history-trip-card-selection-checkbox selection-checkbox"
								checkedIcon={<CheckBoxOutlined />}
								indeterminateIcon={<IndeterminateCheckBoxOutlined />}
								icon={<CheckBoxOutlineBlankOutlined />}
								checked={tripConfig.visible || false}
								onClick={(e) => {
									e.stopPropagation();
								}}
								onChange={(e) => {
									props.onVisibilityChanged(e.target.checked);
								}}
							/>
						</div>
					) : null}
				</div>
			</ReactScrollIntoViewIfNeeded>
		)
	);
};

export default HistoryTripCard;
