import { Dispatch } from 'redux';

const SET_NOTIFICATION_STATUS = 'SET_NOTIFICATION_STATUS';
const RECEIVED_ALERTS_NOTIFICATION = 'RECEIVED_ALERTS_NOTIFICATION';

export enum NotificationsStatus {
	NotSet = 'NotSet',
	Disconnected = 'Disconnected',
	Connected = 'Connected',
}

export interface NotificationsState {
	connectionId?: string;
	notificationsStatus: NotificationsStatus;
	receivedAlertsNotification: { update: boolean };
}

const initialState: NotificationsState = {
	connectionId: null,
	notificationsStatus: NotificationsStatus.NotSet,
	receivedAlertsNotification: { update: false },
};

export interface NotificationsSignature {
	setNotificationStatus: (status: NotificationsStatus, connectionId?: string) => void;
	receivedAlertsNotification: () => void;
}

export const notificationsActionCreators: NotificationsSignature = {
	setNotificationStatus: (status: NotificationsStatus, connectionId?: string) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_NOTIFICATION_STATUS,
			payload: { status, connectionId },
		});
	},
	receivedAlertsNotification: () => (dispatch: Dispatch) => {
		dispatch({
			type: RECEIVED_ALERTS_NOTIFICATION,
			payload: { update: true },
		});
	},
};

export const notificationsReducer = (state: NotificationsState, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case SET_NOTIFICATION_STATUS:
			return {
				...state,
				notificationsStatus: action.payload.status,
				connectionId: action.payload.connectionId,
			};
		case RECEIVED_ALERTS_NOTIFICATION:
			return {
				...state,
				receivedAlertsNotification: action.payload,
			};
		default:
			return state;
	}
};
