import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionDialog from 'components/TreeSelectionDialog';
import { IActionButton } from 'components/Widgets/ButtonWidget/ButtonWidget';
import GlobalSettings from 'GlobalSettings.json';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import { IntegrationGroup } from '../IntegrationGroupsView/types';

const mapToTreeNode = (data: any[]): ITreeNode[] => {
	const treeNodeSelection: ITreeNode[] = [];
	const integrationGroupList = data as IntegrationGroup[];
	if (integrationGroupList) {
		integrationGroupList.forEach((item) => {
			treeNodeSelection.push({
				id: item.id,
				text: item.name,
				active: item.active,
				type: 'Group',
				selected: true,
				items: [],
				childCount: 0,
				canBeChecked: true,
			});
		});
	}
	return treeNodeSelection;
};

type Props = {} & IActionButton;

const AddGroupToIntegrationActionButton = (props: Props) => {
	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setInitialTreeData(mapToTreeNode(props.currentSelection));
	}, [props.currentSelection]);

	const customerId = useSelector((state: ApplicationState) =>
		state.loadedEntityContext?.entityContextData?.customerId
			? state.loadedEntityContext?.entityContextData?.customerId
			: null
	);

	const [dialogVisible, setDialogVisible] = useState(false);
	const events = {
		closeDialog: () => {
			setDialogVisible(false);
		},
		saveSelectionCallback: (selection: ITreeNode[]) => {
			const addedGroups: string[] = [];
			const removedGroups: string[] = [];
			const currentSelection = props.currentSelection as IntegrationGroup[];

			//get removed entries
			if (currentSelection) {
				currentSelection.forEach((item) => {
					if (!selection.some((x) => x.id === item.id)) {
						removedGroups.push(item.id);
					}
				});
			}

			//get added entries
			selection.forEach((item) => {
				if (!currentSelection || !currentSelection.some((x) => x.id === item.id)) {
					addedGroups.push(item.id);
				}
			});

			//update connections
			if (addedGroups.length || removedGroups.length) {
				ajaxUtil
					.put(`${GlobalSettings.integrationsMaintenanceApi}/updateIntegrationGroups`, {
						customerId: customerId,
						apiIntegrationId: props.entityId,
						addedGroups: addedGroups,
						removedGroups: removedGroups,
					})
					.then(() => {
						setDialogVisible(false);
						props.actionExecuted(true);
					});
			} else {
				setDialogVisible(false);
				props.actionExecuted(true);
			}
		},
		retrieveData: useCallback((filter: { customerId: string; showInactive: boolean; filterText: string }) => {
			return ajaxUtil.post<ITreeNode[]>(
				`${GlobalSettings.integrationsMaintenanceApi}/GetIntegrationGroupSelectionData`,
				{
					customerId: filter.customerId,
					showInactive: filter.showInactive,
					filterText: filter.filterText,
				}
			);
		}, []),
	};

	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionDialog
				title={TranslateText('integrations.restrictIntegrationToGroups')}
				filterPlaceholder={TranslateText('fleetSelection.searchGroupsPlaceholder')}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveSelectionCallback}
				retrieveData={events.retrieveData}
			/>
		</>
	);
};

export default AddGroupToIntegrationActionButton;
