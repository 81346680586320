import React from 'react';

import GlobalSettings from '../../../GlobalSettings.json';
import AddGroupToPersonActionButton from './AddGroupToPersonActionButton';
import { IActionButton } from './ButtonWidget';

export const AddGroupMemberToDriver = (props: IActionButton) => {
	return (
		<AddGroupToPersonActionButton
			{...props}
			updateUrl={`${GlobalSettings.driversMaintenanceApi}/updatePersonGroupConnection`}
			retrieveUrl={`${GlobalSettings.driversMaintenanceApi}/GetDriverGroupSelectionData`}
		/>
	);
};

export const AddGroupMemberToPerson = (props: IActionButton) => {
	return (
		<AddGroupToPersonActionButton
			{...props}
			updateUrl={`${GlobalSettings.personsMaintenanceApi}/updatePersonGroupConnection`}
			retrieveUrl={`${GlobalSettings.personsMaintenanceApi}/GetPersonGroupSelectionData`}
		/>
	);
};

export const AddGroupAuthToPerson = (props: IActionButton) => {
	return (
		<AddGroupToPersonActionButton
			{...props}
			updateUrl={`${GlobalSettings.personsMaintenanceApi}/updatePersonGroupConnection/authorization`}
			retrieveUrl={`${GlobalSettings.personsMaintenanceApi}/GetPersonGroupSelectionData`}
		/>
	);
};
