import './styles.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import ButtonWidget from 'components/Widgets/ButtonWidget';
import WidgetDashboard, { WidgetDashboardTransferableProps } from 'components/Widgets/Dashboard/WidgetDashboard';
import { AddGroupToIntegrationActionButton } from 'components/Widgets/Integrations/IntegrationGroups/AddGroupToIntegrationActionButton';
import { IntegrationGroupsView } from 'components/Widgets/Integrations/IntegrationGroups/IntegrationGroupsView';
import IntegrationInformationView from 'components/Widgets/Integrations/IntegrationInformationView/IntegrationInformationView';
import IntegrationTypeView from 'components/Widgets/Integrations/IntegrationTypeView';
import Widget from 'components/Widgets/Widget';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import { TranslateText } from 'utils/Translations';

import GlobalSettings from '../../GlobalSettings.json';

const pathToOverview = '/integrations';

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type Props = WidgetDashboardTransferableProps & MatchProps & {};

export const IntegrationDetails = (props: Props) => {
	const dispatch = useDispatch();

	const [integrationTypeTitle, setIntegrationTypeTitle] = useState<string>('');

	const [currentTitle, setCurrentTitle] = useState<string>('');
	const filterText = useSelector((state: ApplicationState) => state.globalCustomer.filterText.integrations);
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedIntegrationContext(null));
		};
	}, []);

	return (
		<div className="content-container">
			<WidgetDashboard
				history={props.history}
				breadcrumbText={currentTitle ? `${TranslateText('common.integration')} > ${currentTitle}` : ''}
				goBackText={TranslateText('detailsScreen.navigationBar.backToIntegrations')}
				currentRecordId={props.match.params.id}
				urlPath={pathToOverview}
				filterText={filterText}
				goBackToCallback={() => {
					props.history.push(pathToOverview, { id: props.match.params.id });
				}}
				entityType={EntityTypeEnum.Integration}
			>
				<Widget
					position={{
						row: 0,
						col: 0,
						sizeX: 4,
						sizeY: 5,
					}}
					entityId={props.match.params.id}
					url={`${GlobalSettings.integrationsMaintenanceApi}`}
					widgetTitle={TranslateText('common.information')}
					viewComponent={IntegrationInformationView}
					setDashboardTitleCallback={(title: string) => setCurrentTitle(title)}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Integration,
						values: [ClaimValue.edit],
					})}
				/>

				<Widget
					position={{
						row: 0,
						col: 4,
						sizeX: 4,
						sizeY: 5,
					}}
					loadChild
					entityId={props.match.params.id}
					widgetTitle={integrationTypeTitle}
					viewComponent={IntegrationTypeView}
					setDashboardTitleCallback={(title: string) => setIntegrationTypeTitle(title)}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Integration,
						values: [ClaimValue.edit],
					})}
				/>

				<ButtonWidget
					position={{
						row: 0,
						col: 8,
						sizeX: 4,
						sizeY: 5,
					}}
					entityId={props.match.params.id}
					widgetTitle={TranslateText('integrations.restrictToGroups')}
					viewComponent={IntegrationGroupsView}
					buttons={[AddGroupToIntegrationActionButton]}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Integration,
						values: [ClaimValue.edit],
					})}
				/>
			</WidgetDashboard>
		</div>
	);
};

export default IntegrationDetails;
