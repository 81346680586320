import '../oldWidget.scss';

import { Button } from '@material-ui/core';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import BaseWidget from 'components/BaseWidget';
import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { GridWidgetOverview } from 'components/GridOverview/GridWidgetOverview';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import ObjectDeviceConnection from 'models/ObjectDeviceConnection';
import { Column } from 'primereact/column';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { FormatDate } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { OldWidgetProps } from '../Widget';
import AddConnection from './AddConnection';

const mapState = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
		loggedCustomerLevel: state.currentSession.customerLevel,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OldWidgetProps &
	PropsFromRedux & {
		entityType: EntityTypeEnum;
		conflictingConnectionNotifier: ConnectionConflictsNotifier;
		forceReload?: boolean;
		delayedCallback?: () => void;
	};

type State = {
	editRowId: string;
	editRow: boolean;
	connections: ObjectDeviceConnection[];
	canDisconnect: boolean;
	widgetInEditMode: boolean;
	rowInEdit: boolean;
	showDialog: boolean;
	currentEntityMode: boolean;
	visible: boolean;
	customerLevel: CustomerLevelEnum;
	connectionStartDate: Date;
	connectionEndDate: Date;
	selectedRowData: ObjectDeviceConnection;
	validationResult: ValidationResult;
	disableUpdateButton: boolean;
	disableEditButton: boolean;
};

class DeviceObjectWidget extends BaseWidget<Props, State> {
	public requestedConnection: ObjectDeviceConnection;
	public currentEntityId: string;
	conflictingConnections: ObjectDeviceConnection[];
	conflictsCanBeAutomaticallyFixed: boolean;

	constructor(props: Props) {
		super(props);

		this.state = {
			editRowId: null,
			editRow: false,
			connections: null,
			canDisconnect: false,
			widgetInEditMode: false,
			rowInEdit: false,
			showDialog: false,
			currentEntityMode: false,
			visible: true,
			customerLevel: null,
			connectionStartDate: null,
			connectionEndDate: null,
			selectedRowData: null,
			validationResult: null,
			disableUpdateButton: false,
			//(for objects history) disable edit initialy for EndCustomer/Reseller, enable edit afterwards if no active connection with an inaccessible object is found
			disableEditButton:
				this.props.entityType === EntityTypeEnum.Device &&
				this.props.loggedCustomerLevel !== CustomerLevelEnum.OEM
					? true
					: false,
		};
	}

	componentDidMount() {
		this.getData(this.props.entityId, true);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
			this.getData(this.props.entityId, true);
		}
		if (prevProps.forceReload !== this.props.forceReload && this.props.forceReload) {
			this.getData(this.props.entityId, true);
		}
	}

	closeEditPanelMode() {
		if (this.state.widgetInEditMode) {
			this.toggleEditMode();
		}
	}

	handleDataSaved() {
		this.getData(this.props.entityId, true);
		if (this.props.savedCallback) {
			this.props.savedCallback();
		}
	}

	async getData(entityId: string, forceReload = false) {
		if (forceReload || this.currentEntityId !== entityId) {
			this.currentEntityId = entityId;

			let url = GlobalSettings.connectionsApi + '/GetObjectDeviceConnectionsHistory/' + entityId;
			switch (this.props.entityType) {
				case EntityTypeEnum.Object:
					url += '/object';
					break;
				case EntityTypeEnum.Device:
					url += '/device';
					break;
			}

			return await ajaxUtil.get(url).then((result: any) => {
				let disableEditButton = this.state.disableEditButton;

				//enable edit if no active connection with an inaccessible object is found
				if (
					this.props.entityType === EntityTypeEnum.Device &&
					this.props.loggedCustomerLevel !== CustomerLevelEnum.OEM &&
					(!result.data?.length || result.data[0].canViewObject || result.data[0].connectionEndDate)
				) {
					disableEditButton = false;
				}

				this.setState({
					disableEditButton: disableEditButton,
					connections: result.data,
					customerLevel: result.customerLevel,
				});
				if (this.props.delayedCallback) {
					this.props.delayedCallback();
				}
			});
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgetInEditMode;
		this.props.editModeCallback(currentValueFlipped);

		if (!currentValueFlipped) {
			this.setState({
				rowInEdit: false,
			});
		}

		this.setState({
			widgetInEditMode: currentValueFlipped,
		});
	}

	terminateSelectedConnection() {
		if (this.state.selectedRowData) {
			this.setState({ canDisconnect: false });
			const url =
				GlobalSettings.connectionsApi +
				'/TerminateObjectDeviceConnection/' +
				(this.state.selectedRowData as ObjectDeviceConnection).id;

			const currentEntityId = this.props.entityId;

			ajaxUtil.post(url, null).then((data) => {
				if (data) {
					this.getData(currentEntityId, true);
					this.handleDataSaved();
				}
			});
		}
	}

	fixConflictsAndUpdateConnection() {
		if (!this.requestedConnection) {
			return;
		}

		//prepare data for update
		const objectDeviceConnection = {
			id: this.requestedConnection.id,
			deviceId: this.requestedConnection.entity1Id,
			objectId: this.requestedConnection.entity2Id,
			connectionStartDate: this.requestedConnection.connectionStartDate,
			connectionEndDate: this.requestedConnection.connectionEndDate,
			resolveConflictsAutomatically: this.requestedConnection.resolveConflictsAutomatically,
		} as ObjectDeviceConnection;

		const currentEntityId = this.props.entityId;

		const url = GlobalSettings.connectionsApi + '/UpdateObjectDeviceConnection';

		ajaxUtil.post(url, { value: objectDeviceConnection }).then(() => {
			this.getData(currentEntityId, true);
		});
		this.setState({ rowInEdit: false });

		this.handleDataSaved();
	}

	handleConflictingConnections(confirm: boolean) {
		if (this.conflictsCanBeAutomaticallyFixed && confirm) {
			this.requestedConnection.resolveConflictsAutomatically = true;
			setTimeout(() => {
				this.fixConflictsAndUpdateConnection();
			}, 500);
		} else {
			this.reloadData();
		}
	}

	handleDisconnectButtonState(canDisconnect: boolean): boolean {
		if (this.state.rowInEdit) {
			return false;
		}
		if (
			this.state.selectedRowData &&
			this.state.selectedRowData.connectionEndDate &&
			new Date(this.state.selectedRowData.connectionEndDate) < new Date()
		) {
			return false;
		}
		if (this.state.selectedRowData && new Date(this.state.selectedRowData.connectionStartDate) > new Date()) {
			return false;
		}
		return canDisconnect;
	}

	isConnectionCurrent(connectionStartDate: Date, connectionEndDate: Date): boolean {
		if (!connectionEndDate) {
			return true;
		}
		const referenceDate = new Date();
		return new Date(connectionStartDate) <= referenceDate && new Date(connectionEndDate) >= referenceDate;
	}

	handleRowSelected(connectionStartDate: Date, connectionEndDate: Date) {
		if (!this.isConnectionCurrent(connectionStartDate, connectionEndDate)) {
			this.setState({ canDisconnect: false });
		} else {
			this.setState({ canDisconnect: true });
		}
	}

	openAddConnectionWidget(currentEntityMode: boolean) {
		this.setState({
			showDialog: true,
			currentEntityMode: currentEntityMode,
		});
	}

	addConnectionDialogClosed() {
		this.setState({
			showDialog: false,
		});
	}

	showNewConnection(): boolean {
		return (
			!this.state.rowInEdit &&
			(this.props.entityType !== EntityTypeEnum.Device ||
				(this.state.customerLevel === CustomerLevelEnum.Default &&
					ClaimUtil.validateClaim(this.props.user, {
						claim: ClaimType.Objects,
						values: [ClaimValue.edit],
					}))) &&
			(this.props.entityType !== EntityTypeEnum.Object ||
				ClaimUtil.validateClaim(this.props.user, {
					claim: ClaimType.Objects,
					values: [ClaimValue.edit, ClaimValue.editRestricted],
				}))
		);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="5"
				data-minsizey="3"
			>
				<AddConnection
					savedCallback={() => this.handleDataSaved()}
					entityId={this.props.entityId}
					dialogCloseCallback={() => this.addConnectionDialogClosed()}
					currentEntityMode={this.state.currentEntityMode}
					showDialog={this.state.showDialog}
					renderFor={this.props.entityType}
					forceReload={this.props.forceReload}
					visible={false}
				/>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={
								this.props.entityType === EntityTypeEnum.Object
									? TranslateText('common.deviceHistory')
									: TranslateText('common.objectHistory')
							}
							showEditButton={!this.state.rowInEdit && !this.state.disableEditButton}
							editMode={this.state.widgetInEditMode}
							editClaimConfig={[
								{ claim: ClaimType.Objects, values: [ClaimValue.edit, ClaimValue.editRestricted] },
								{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
								{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
							]}
							allowEditMode={this.props.allowEditMode || this.state.widgetInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditMode()}
						/>
					</div>
					<div className="e-panel-content">
						<div id="gridHost" className="grid-host auto-device-object-widget">
							<GridWidgetOverview
								columns={[
									<Column
										key={
											this.props.entityType === EntityTypeEnum.Object
												? 'deviceCode'
												: 'objectCode'
										}
										field={
											this.props.entityType === EntityTypeEnum.Object
												? 'deviceCode'
												: 'objectCode'
										}
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colCode')}
										style={{ width: 140 }}
										body={(rowData: any) => {
											const value =
												this.props.entityType === EntityTypeEnum.Object
													? rowData.deviceCode
													: rowData.objectCode;
											return this.state.widgetInEditMode ||
												(this.props.entityType === EntityTypeEnum.Device &&
													!rowData.canViewObject) ? (
												value
											) : (
												<Link
													to={
														this.props.entityType === EntityTypeEnum.Object
															? `${GlobalSettings.route.devices}/${rowData.deviceId}`
															: `${GlobalSettings.route.objects}/${rowData.objectId}`
													}
												>
													{value}
												</Link>
											);
										}}
									/>,
									<Column
										key={
											this.props.entityType === EntityTypeEnum.Object
												? 'deviceSerialNumber'
												: 'objectName'
										}
										field={
											this.props.entityType === EntityTypeEnum.Object
												? 'deviceSerialNumber'
												: 'objectName'
										}
										className="widget-grid-column"
										header={
											this.props.entityType === EntityTypeEnum.Object
												? TranslateText('widgets.grid.colSerialNumber')
												: TranslateText('widgets.grid.colName')
										}
										style={{ width: this.props.entityType === EntityTypeEnum.Object ? 200 : 150 }}
									/>,
									this.props.entityType === EntityTypeEnum.Device && (
										<Column
											key="licensePlate"
											field="licensePlate"
											className="widget-grid-column"
											header={TranslateText('widgets.grid.colLicensePlate')}
											style={{ width: 120 }}
										/>
									),
									<Column
										key="connectionStartDate"
										field="connectionStartDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colStartDate')}
										style={{ width: 230 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerStart"
														showTime={true}
														key="datePickerStart"
														date={this.state.connectionStartDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId && rowData.id === this.state.editRowId && (
														<ValidationMessage
															result={this.state.validationResult?.connectionStartDate}
														/>
													)}
												</div>
											) : (
												FormatDate(new Date(rowData.connectionStartDate))
											)
										}
									/>,
									<Column
										key="connectionEndDate"
										field="connectionEndDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colEndDate')}
										style={{ width: 230 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerEnd"
														showTime={true}
														key="datePickerEnd"
														date={this.state.connectionEndDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId &&
														rowData.id === this.state.editRowId &&
														this.state.connectionEndDate && (
															<ValidationMessage
																result={this.state.validationResult?.connectionEndDate}
															/>
														)}
												</div>
											) : rowData.connectionEndDate ? (
												FormatDate(new Date(rowData.connectionEndDate))
											) : null
										}
									/>,
								]}
								data={this.state.connections}
								total={this.state.connections?.length}
								paginator={true}
								selectedRowCallback={(rowData) => {
									this.handleRowSelected(rowData.connectionStartDate, rowData.connectionEndDate);
									this.setState({ selectedRowData: rowData });
								}}
							/>
						</div>
					</div>
					{this.state.widgetInEditMode ? (
						<div id="buttonsHost" className="buttons-host">
							<div className="left-side-buttons">
								{this.showNewConnection() ? (
									<Button
										className="widget-button new-connection"
										onClick={() => this.openAddConnectionWidget(false)}
									>
										{TranslateText('common.new')}
									</Button>
								) : null}
								<Button
									className="widget-button disconnect"
									disabled={!this.handleDisconnectButtonState(this.state.canDisconnect)}
									onClick={() => this.terminateSelectedConnection()}
								>
									{TranslateText('common.disconnect')}
								</Button>
							</div>
							<div className="right-side-buttons">
								<Button
									className="widget-button link"
									disabled={
										this.state.rowInEdit ||
										(this.props.entityType === EntityTypeEnum.Device &&
											this.state.customerLevel !== CustomerLevelEnum.Default)
									}
									onClick={() => this.openAddConnectionWidget(true)}
								>
									{TranslateText('common.link')}
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default connector(DeviceObjectWidget);
