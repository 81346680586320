import React from 'react';

import { DialogUtility } from '@syncfusion/ej2-popups';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TranslateText } from '../../../utils/Translations';

export interface ConfirmDialogConfig {
	title: string;
	content: string | HTMLElement;
}

export interface TripleChoiceDialogConfig {
	title: string;
	content: string | HTMLElement;
	buttonOptions: string[];
}

export const DialogUtil = {
	alert: (config: ConfirmDialogConfig): Promise<void> => {
		return new Promise<void>((resolve) => {
			const dialog = DialogUtility.alert({
				title: config.title,
				cssClass: 'confirm-dialog auto-confirm-dialog',
				content: config.content,
				okButton: {
					text: TranslateText('common.buttonOk'),
					click: () => {
						dialog.hide();
						resolve();
					},
					cssClass: 'auto-ok-button',
				},
				close: () => {
					resolve();
				},
				showCloseIcon: true,
				closeOnEscape: true,
			});
		});
	},
	confirm: (config: ConfirmDialogConfig, isYesAndNo?: boolean): Promise<boolean> => {
		return new Promise<boolean>((resolve) => {
			const dialog = DialogUtility.confirm({
				title: config.title,
				cssClass: 'confirm-dialog auto-confirm-dialog',
				content: config.content,
				okButton: {
					text: isYesAndNo ? TranslateText('common.buttonYes') : TranslateText('common.buttonOk'),
					click: () => {
						dialog.hide();
						resolve(true);
					},
					cssClass: 'auto-ok-button',
				},
				cancelButton: {
					text: isYesAndNo ? TranslateText('common.buttonNo') : TranslateText('common.buttonCancel'),
					click: () => {
						dialog.hide();
						resolve(false);
					},
					cssClass: isYesAndNo ? 'auto-ok-button' : 'auto-cancel-button',
				},
				close: () => {
					resolve(false);
				},
				showCloseIcon: true,
				closeOnEscape: true,
			});
		});
	},
	multipleChoices: (config: TripleChoiceDialogConfig, open: boolean, resolve: (number: number) => void): any => {
		return (
			<Dialog open={open}>
				<DialogTitle color={'primary'}>{config.title}</DialogTitle>
				<DialogContent>{config.content}</DialogContent>
				<DialogActions>
					{config.buttonOptions.map((buttonOption, index) => {
						return (
							<Button
								onClick={() => {
									resolve(index);
								}}
							>
								{buttonOption}
							</Button>
						);
					})}
				</DialogActions>
			</Dialog>
		);
	},
};
