import { TranslateText } from './Translations';

export const DateTimeConstants = {
	dateFormat: [
		{ id: 'yyyy-MM-dd', display: 'YYYY-MM-DD' },
		{ id: 'dd-MM-yyyy', display: 'DD-MM-YYYY' },
		{ id: 'dd MMM yyyy', display: 'DD Month YYYY' },
		{ id: 'dd MMM yy', display: 'DD Month YY' },
		{ id: 'dd-MM-yyyy (EEEE)', display: 'DD-MM-YYYY (DAY NAME)' },
		{ id: 'yyyy-MM-dd (EEEE)', display: 'YYYY-MM-DD (DAY NAME)' },
		{ id: 'MM-dd-yyyy (EEEE)', display: 'MM-DD-YYYY (DAY NAME)' },
	],
	timeFormat: [
		{ id: 'HH:mm:ss', display: 'HH:MM:SS 24H' },
		{ id: 'hh:mm:ss a', display: 'HH:MM:SS AM/PM' },
	],
	calendarFormat: () => [
		{ id: 0, display: TranslateText('fieldsDataSource.gregorian') },
		{ id: 1, display: TranslateText('fieldsDataSource.westernTraditional') },
		{ id: 2, display: TranslateText('fieldsDataSource.middleEastern') },
	],
	startingDay: () => [
		{ id: 0, display: TranslateText('fieldsDataSource.sunday') },
		{ id: 1, display: TranslateText('fieldsDataSource.monday') },
		{ id: 2, display: TranslateText('fieldsDataSource.tuesday') },
		{ id: 3, display: TranslateText('fieldsDataSource.wednesday') },
		{ id: 4, display: TranslateText('fieldsDataSource.thursday') },
		{ id: 5, display: TranslateText('fieldsDataSource.friday') },
		{ id: 6, display: TranslateText('fieldsDataSource.saturday') },
	],
	fieldsConfig: { text: 'display', value: 'id' },
};
