import React from 'react';
import { IconButton } from '@material-ui/core';
import { openInNewTab } from 'components/Help/HelpButton';
import { SimCard } from '@material-ui/icons';
import { StringConstants } from 'components/Widgets/CustomerFeaturesView';
import { TranslateText } from 'utils/Translations';
import ImageColumn from './ImageColumn';

type Props = {
	entity: string;
	showTooltip?: boolean;
};

const DevicesLogsColumn = ({ entity, showTooltip = true }: Props) =>
	ImageColumn({
		width: 30,
		body: ({ id }: { id: string }) => (
			<span title={showTooltip ? TranslateText('common.deviceMessagesLog') : ''}>
				<IconButton
					onClick={() => {
						openInNewTab(`${StringConstants.deviceLogsRoute}/${entity}/${id}`);
					}}
					size={'small'}
					className="grid-small-icon"
				>
					<SimCard className="sim-card" />
				</IconButton>
			</span>
		),
	});

export default DevicesLogsColumn;
