import { BlobIconDictionary } from 'models/BlobIcon';
import { LocationMapDto } from 'models/LocationMapDto';
import LocationOnMapEnum from 'models/LocationOnMapEnum';
import React, { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useLocationsOnMap } from 'shared/effects/useShowLastEntities';
import { getMarkerIcon } from 'utils/Icons';

import { Marker } from '@react-google-maps/api';

import { useSelector } from 'react-redux';
import LocationsLabels from '../LocationsLabels';
import { LocationsMarkersProps } from './types';
import { ApplicationState } from '../../store';

const LocationsMarkers = (props: LocationsMarkersProps): JSX.Element => {
	const applyLocationsOnMap = useLocationsOnMap(props.isLiveMap);

	const queryClient: QueryClient = useQueryClient();
	const blobIcons: BlobIconDictionary = queryClient.getQueryData<BlobIconDictionary>(['application-icons']);
	const [iconsUrl, setIconsUrl] = useState<{ [name: string]: string } | null>(null);
	const [filteredLocations, setFilteredLocations] = useState<LocationMapDto[]>([]);
	const liveSelectedCategories = useSelector((state: ApplicationState) => state.liveMap.liveSelectedCategories);
	const historySelectedCategories = useSelector(
		(state: ApplicationState) => state.historyStore.historySelectedCategories
	);
	useEffect(() => {
		if (blobIcons?.locations?.length) {
			const icons: { [name: string]: string } = {};
			blobIcons?.locations.forEach((bi) => {
				icons[`${bi.iconSet}/${bi.name}`] = bi.content;
			});
			setIconsUrl(icons);
		}
	}, [blobIcons]);

	useEffect(() => {
		if (props.locations?.length) {
			setFilteredLocations(
				props.isLiveMap
					? props.locations.filter((l: LocationMapDto) =>
							liveSelectedCategories.some((lsc) => lsc.id === l.category.id)
					  )
					: props.locations.filter((l: LocationMapDto) =>
							historySelectedCategories.some((hsc) => hsc.id === l.category.id)
					  )
			);
		}
	}, [props.locations, historySelectedCategories, liveSelectedCategories]);

	return props.locations?.length &&
		iconsUrl &&
		(applyLocationsOnMap === LocationOnMapEnum.Icon || applyLocationsOnMap === LocationOnMapEnum.IconLabel) ? (
		<>
			{applyLocationsOnMap === LocationOnMapEnum.IconLabel ? (
				<LocationsLabels locations={filteredLocations} />
			) : null}

			{filteredLocations.map((l: LocationMapDto) => (
				<Marker
					zIndex={1}
					key={`location-${l.id}`}
					position={{ lat: l.latitude, lng: l.longitude }}
					icon={{
						url: getMarkerIcon(l.iconName, l.iconColor, iconsUrl),
						scaledSize: new google.maps.Size(14, 14),
					}}
				/>
			))}
		</>
	) : null;
};

export default React.memo(LocationsMarkers);
