import * as React from 'react';
import {useMemo} from 'react';
import ClaimType, {ClaimValue} from 'authorization/ClaimType';
import {ClaimUtil} from 'authorization/ClaimUtil';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import GlobalSettings from 'GlobalSettings.json';
import {History, LocationState} from 'history';
import EntityTypeEnum from 'models/EntityTypeEnum';
import {GridFilters, MatchMode, SortOrder} from 'models/GridOverview';
import TemplateEventActionEnum from 'models/TemplateEventActionEnum';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ApplicationState} from 'store';
import {TranslateText} from 'utils/Translations';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from 'components/GridOverview/Columns/MultiSelectionColumn';
import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import NumberColumn from 'components/GridOverview/Columns/NumberColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import TripTypesEnum from "../../models/TripTypesEnum";
import CustomerLevelEnum from "../../models/CustomerLevelEnum";

export interface TemplateOverviewDto {
	id: string;
	active: boolean;
	code: string;
	description: string;
	name: string;
	customerId: string;
	relevantValue: boolean;
	customerName: string;
	eventAction: TemplateEventActionEnum;
	tripColor: string;
	labelColor: string;
	rank: number;
	numberDevicesInUse: string;
	templateType: TemplateTypesEnum;
	relevantValueType: TemplateRelevantValueEnum;
}

const fieldNames = {
	customerName: 'customerName',
	templateType: 'templateType',
	code: 'code',
	name: 'name',
	description: 'description',
	relevantValueType: 'relevantValueType',
	rank: 'rank',
	active: 'active',
	numberDevicesInUse: 'numberDevicesInUse',
};

const initialFilters: GridFilters = {
	sortField: 'code',
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.templateType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.relevantValueType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.rank]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.numberDevicesInUse]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
	},
};

enum TemplateRelevantValueEnum {
	Low,
	High,
	Empty = 2,
}

type TemplatesProps = {
	history: History<LocationState>;
};

export default function TemplatesOverview({ history }: TemplatesProps) {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const featuresTemplates = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.templates
			: s.currentSession.customer.featuresSettings.templates
	);
	const tripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);

	const templateTypeSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: '',
				code: TemplateTypesEnum.Unknown.toString(),
				hidden: true,
			},
			{
				name: TranslateText('fields.templateTypeEvent'),
				code: TemplateTypesEnum.Event.toString(),
			},
			{
				name: TranslateText('fields.templateTypeAnalog'),
				code: TemplateTypesEnum.Analog.toString(),
			},
			{
				name: TranslateText('fields.templateTypeDigital'),
				code: TemplateTypesEnum.Digital.toString(),
			},
			{
				name: TranslateText('fields.templateTypeDuration'),
				code: TemplateTypesEnum.Duration.toString(),
			},
		],
		[]
	);

	const templateRelevantValueSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: TranslateText('fields.template.stateHigh'),
				code: TemplateRelevantValueEnum.High.toString(),
			},
			{
				name: TranslateText('fields.template.stateLow'),
				code: TemplateRelevantValueEnum.Low.toString(),
			},
			{
				name: '',
				code: TemplateRelevantValueEnum.Empty.toString(),
				hidden: true,
			}
		],
		[]
	);

	const getTemplateTypeSelectionOptions = () => {
		let newTemplateTypeSelectionOptions = templateTypeSelectOptions;
		if(!featuresTemplates) {
			newTemplateTypeSelectionOptions = newTemplateTypeSelectionOptions.filter((t) => t.code === TemplateTypesEnum.Event.toString());
		}
		if(!trackTypeSpecification || !(tripTypes & TripTypesEnum.Private))
			newTemplateTypeSelectionOptions = newTemplateTypeSelectionOptions.filter((t) => t.code!== TemplateTypesEnum.Event.toString());

		return newTemplateTypeSelectionOptions;
	}

	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.templateType]: computeFlagOrderingBasedOnTranslation(templateTypeSelectOptions),
			[fieldNames.relevantValueType]: computeFlagOrderingBasedOnTranslation(templateRelevantValueSelectOptions),
		}),
		[]
	);
	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (template: TemplateOverviewDto) => (
				<Link title={template.customerName} to={`${GlobalSettings.route.customers}/${template.customerId}`}>
					{template.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.templateType,
			header: TranslateText('maintenanceOverview.grid.colTemplateType'),
			sortable: true,
			filterable: true,
			body: (template: TemplateOverviewDto) => {
				const templateType = TranslateText('fields.templateType' + TemplateTypesEnum[template.templateType]);
				return <span title={templateType}>{templateType}</span>;
			},
			selectOptions: getTemplateTypeSelectionOptions(),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.relevantValueType,
			header: TranslateText('maintenanceOverview.grid.colValue'),
			sortable: true,
			filterable: true,
			body: (template: TemplateOverviewDto) => {
				const relevantValue = template.templateType === TemplateTypesEnum.Analog
				? ''
				: template.relevantValue
						? TranslateText('fields.template.stateHigh')
						: TranslateText('fields.template.stateLow');
				return <span title={relevantValue}>{relevantValue}</span>;
			},
			selectOptions: templateRelevantValueSelectOptions,
		}),
		NumberColumn({
			fieldName: fieldNames.rank,
			header: TranslateText('maintenanceOverview.grid.colRank'),
			sortable: true,
			filterable: true,
			maxFractionDigits: 1,
			defaultWidth: 155,
		}),
		NumberColumn({
			fieldName: fieldNames.numberDevicesInUse,
			header: TranslateText('maintenanceOverview.grid.colNumberDevicesInUse'),
			sortable: true,
			filterable: true,
			maxFractionDigits: 1,
			defaultWidth: 200,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Devices),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.sensorTemplatesMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.templates')}
			disableAddButton={false}
			showAddConfigClaim={[
				{
					claim: ClaimType.Templates,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Template}
			useNewAddWizard
			columnFlagOrder={columnFlagOrdering}
			disableImportButton={true}
			disableExportButton={true}
		/>
	);
}
