import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { ApplicationState } from '../../store';

interface SocketProps {
	url: string;
	timeout: number;
	dataToSend: object | null;
	messageCallback: (data: unknown) => void;
}

const Socket = (props: SocketProps) => {
	const accessToken = useSelector((state: ApplicationState) => state.oidc.user.access_token);

	const webSocket = useRef(null);
	const [waitToConnect, setWaitToConnect] = useState(true);
	const [socketIsOpen, setSocketIsOpen] = useState(false);

	//connect
	useEffect(() => {
		if (!waitToConnect) {
			if (webSocket.current) {
				webSocket.current.close();
			}
			console.debug('[Socket] try to open.');
			const ws = new WebSocket(props.url, ['JwtBearer', accessToken]);
			ws.onopen = () => {
				console.debug('[Socket] is open.');
				setSocketIsOpen(true);
			};
			ws.onmessage = (e: any) => {
				if (e.data) {
					const message = JSON.parse(e.data);
					props.messageCallback(message);
				}
			};
			ws.onclose = (e: any) => {
				console.debug('[Socket] closed.');
				setSocketIsOpen(false);
				setWaitToConnect(true);
			};
			ws.onerror = (err: any) => {
				setSocketIsOpen(false);
				setWaitToConnect(true);
			};
			webSocket.current = ws;
		}
		return () => {
			if (webSocket.current) {
				webSocket.current.close();
			}
		};
	}, [props.url, waitToConnect]);

	//reconnect
	useEffect(() => {
		if (waitToConnect) {
			setTimeout(() => {
				setWaitToConnect(false);
			}, props.timeout);
		}
	}, [waitToConnect]);

	//send message
	useEffect(() => {
		if (socketIsOpen && props.dataToSend) {
			const message = JSON.stringify(props.dataToSend);
			webSocket.current.send(message);
		}
	}, [socketIsOpen, props.dataToSend]);

	return <></>;
};
Socket.defaultProps = {
	timeout: 2000,
};

export default Socket;
