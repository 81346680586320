import { MutableRefObject } from 'react';
import { LocationLabel } from './types';
import { MAP_ZOOM_SHOW_LOCATIONS_LABELS, MAX_LOCATIONS_LABELS_NUMBER } from '../../Constants';
import { LocationMapDto } from '../../models/LocationMapDto';

export const updateLocationLabels = (
	map: google.maps.Map,
	locations: LocationMapDto[],
	locationsLabelsIds: MutableRefObject<string[]>,
	setLocationsLabels: (locationLabel: LocationLabel[]) => void
) => {
	const newLocationsLabels: LocationLabel[] = [];

	if (map.getZoom() >= MAP_ZOOM_SHOW_LOCATIONS_LABELS) {
		const mapBounds = map.getBounds();

		for (let i = 0; i < locations.length; i++) {
			if (newLocationsLabels.length >= MAX_LOCATIONS_LABELS_NUMBER) {
				break;
			}

			const location = locations[i];
			if (mapBounds.contains({ lat: location.latitude, lng: location.longitude })) {
				newLocationsLabels.push({
					id: location.id,
					name: location.name,
					lat: location.latitude,
					lng: location.longitude,
				});
			}
		}
	}

	if (
		newLocationsLabels.length !== locationsLabelsIds.current.length ||
		newLocationsLabels.some((x) => !locationsLabelsIds.current.some((id) => id === x.id))
	) {
		setLocationsLabels(newLocationsLabels);
		locationsLabelsIds.current = newLocationsLabels.map((x) => x.id);
	}
};
