import './styles.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import React, { useMemo } from 'react';
import { TranslateText } from 'utils/Translations';

import { Divider, Grid } from '@material-ui/core';

import { usePrivateMileageAlertMetaData, useThresholFieldsdValidation } from './hooks';
import { PreThreshold } from './PreThreshold';
import { PreThresholdsProps } from './types';

const PreThresholds = ({
	initialValue,
	validationMessage,
	name,
	onChangeHandler,
	disabled = false,
}: PreThresholdsProps) => {
	const {
		privateMileageAlertMetaData,
		changePreThresholds,
		changeFirstPreThreshold,
		changeSecondPreThreshold,
		changeThirdPreThreshold,
	} = usePrivateMileageAlertMetaData(initialValue);

	const { validateThresholdFields } = useThresholFieldsdValidation();

	const preThresholdsDataSource = useMemo(
		() => [
			{ display: '0', id: 0 },
			{ display: '1', id: 1 },
			{ display: '2', id: 2 },
			{ display: '3', id: 3 },
		],
		[]
	);

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('fields.preThresholds')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'preThresholds col'} item xs>
				<MaterialAutocomplete
					name={name}
					className="preThresholds-autocomplete"
					dataSource={preThresholdsDataSource}
					valueId={initialValue.preThresholds}
					onChange={(arg) => {
						changePreThresholds(+arg.value);
						validateThresholdFields(privateMileageAlertMetaData.current, onChangeHandler);
					}}
					disabled={disabled}
					fullWidth={false}
					disableClearable
				/>
			</Grid>
			{initialValue.preThresholds > 0 && (
				<Grid className={'col'} item xs={12}>
					<PreThreshold
						id={'firstPreThreshold'}
						name={'firstPreThreshold'}
						initialValue={initialValue.firstPreThresholdAlert}
						validationMessage={validationMessage?.firstPreThresholdAlert}
						disabled={disabled}
						prefix={TranslateText('fields.firstAt') + ':'}
						suffix={TranslateText('fields.percentOfThreshold')}
						onChangeHandler={(arg: string | number) => {
							changeFirstPreThreshold(arg);
							validateThresholdFields(privateMileageAlertMetaData.current, onChangeHandler);
						}}
					/>
				</Grid>
			)}
			{initialValue.preThresholds > 1 && (
				<Grid className={'col'} item xs={12}>
					<PreThreshold
						id={'secondPreThreshold'}
						name={'secondPreThreshold'}
						initialValue={initialValue.secondPreThresholdAlert}
						validationMessage={validationMessage?.secondPreThresholdAlert}
						disabled={disabled}
						prefix={TranslateText('fields.secondAt') + ':'}
						suffix={TranslateText('fields.percentOfThreshold')}
						onChangeHandler={(arg: string | number) => {
							changeSecondPreThreshold(arg);
							validateThresholdFields(privateMileageAlertMetaData.current, onChangeHandler);
						}}
					/>
				</Grid>
			)}
			{initialValue.preThresholds > 2 && (
				<Grid className={'col'} item xs={12}>
					<PreThreshold
						id={'thirdPreThreshold'}
						name={'thirdPreThreshold'}
						initialValue={initialValue.thirdPreThresholdAlert}
						validationMessage={validationMessage?.thirdPreThresholdAlert}
						disabled={disabled}
						prefix={TranslateText('fields.thirdAt') + ':'}
						suffix={TranslateText('fields.percentOfThreshold')}
						onChangeHandler={(arg: string | number) => {
							changeThirdPreThreshold(arg);
							validateThresholdFields(privateMileageAlertMetaData.current, onChangeHandler);
						}}
					/>
				</Grid>
			)}
		</>
	);
};

export default PreThresholds;
