import { Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { AlertMessageTagsButton } from 'components/AlertMessageTagsButton';
import MUIRichTextEditor from 'components/MuiRichTextEditor/MUIRichTextEditor';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import MessageTargetEnum from 'models/MessageTargetEnum';
import React, { useRef } from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';

import { AlertMessageEditorProps } from './types';
import { useAlertTagsVariable } from './hooks';

const AlertMessageEditor = (props: AlertMessageEditorProps): JSX.Element => {
	const mailEditorRef = useRef();
	const textIdentifier = 'subject';

	const { handleAlertMessageTagsChange } = useAlertTagsVariable(
		props.alertMessage,
		props.alertType,
		textIdentifier,
		props.onChangeCallback
	);

	const handleMessageContentChange = (e: EditorState): void => {
		if (!e.getCurrentContent()) {
			return;
		}

		if (!props.alertMessage.editorChangeCheck) {
			const newBody: string = draftToHtml(convertToRaw(e.getCurrentContent()));
			props.onChangeCallback(
				{
					...props.alertMessage,
					editorChangeCheck: newBody,
				},
				false
			);
			return;
		}

		const newBody: string = draftToHtml(convertToRaw(e.getCurrentContent()));
		if (newBody !== props.alertMessage.editorChangeCheck) {
			props.onChangeCallback(
				{
					...props.alertMessage,
					body: newBody,
					editorChangeCheck: newBody,
				},
				true
			);
		}
	};

	return (
		<>
			{props.alertMessage.appliesTo === MessageTargetEnum.Mail ? (
				<>
					<Box className="form-group">
						<MaterialTextField
							isForNewAddWizard
							preserveCaretPosition
							id={textIdentifier}
							className="resize-font"
							label={TranslateText('messageEditor.subject')}
							inputProps={{ style: { fontSize: 10 } }}
							name={textIdentifier}
							value={props.alertMessage.subject}
							disabled={!props.editMode}
							handleValueChange={(value) => {
								props.onChangeCallback(
									{
										...props.alertMessage,
										subject: value,
									},
									true
								);
							}}
							rightIcon={
								<AlertMessageTagsButton
									disabled={!props.editMode}
									onChange={handleAlertMessageTagsChange}
								/>
							}
						/>
					</Box>
					<div style={{marginLeft:10}}>
					<ValidationMessage result={props.validationResult?.subject} isForNewAddWizard={true} />
					</div>
				</>
			) : null}

			<Box className="message-container">
				<Box>
					<label>{TranslateText('messageEditor.message')}</label>
				</Box>
				<MUIRichTextEditor
					ref={mailEditorRef}
					id="messageContent"
					toolbar
					defaultValue={props.alertMessage.editorBody}
					onChange={handleMessageContentChange}
					readOnly={!props.editMode}
					controls={['bold', 'italic', 'underline', 'variables']}
					customComponents={['alert-message-tags']}
				/>
			</Box>
			<ValidationMessage result={props.validationResult?.body} isForNewAddWizard={true} />
		</>
	);
};

export default AlertMessageEditor;
