import './styles.scss';

import EasyTrackFeature from 'authorization/EasyTrackFeature';
import { AlertsNotification } from 'components/AlertsNotification';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

const NotificationsMenu = (): JSX.Element => {
	const anchorEl = useRef<HTMLDivElement>(null);

	const featuresSettings = useSelector((s: ApplicationState) => s.currentSession.customer.featuresSettings);

	return !!featuresSettings[EasyTrackFeature.Alerts] ? (
		<>
			<span className="topbar-separator" />
			<div ref={anchorEl} className="notifications-menu">
				<AlertsNotification anchorEl={anchorEl} />
			</div>
		</>
	) : null;
};

export default NotificationsMenu;
