import './styles.scss';

import { ReactComponent as DrivingSvgImage } from 'assets/icons/DrivingStateLiveOverviewInfo.svg';
import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import React from 'react';
import { useSelector } from 'react-redux';
import DateTimeUtil from 'shared/datetime/DateTimeUtil';
import { ApplicationState } from 'store';
import { ProcessedTripDto } from 'store/HistoryStore';
import { FormatDateWithReferenceDate, GetHourAndMinutes } from 'utils/DateUtils';
import { getTripVisitTime } from 'utils/TripUtils';

import { Box } from '@material-ui/core';
import { AccessTime, PauseCircleFilledOutlined, PinDropOutlined } from '@material-ui/icons';

import moment from 'moment';
import FileDownloadIcon from '../../Common/Icons/FileDownloadIcon';
import FileUploadIcon from '../../Common/Icons/FileUploadIcon';
import HourglassBottomOutlined from '../../Common/Icons/HourglassBottomOutlined';
import TripIntermediateIcon from '../../Common/Icons/TripIntermediateIcon';
import { TripNumber } from '../../TripNumber';
import { AddressRow } from '../AddressRow';
import { GetTripType } from '../HistoryTripsUtils';
import { PersonObject } from './PersonObject';

interface Props {
	trip: ProcessedTripDto;
}

const privateDataMask = '****';

const LiveOverviewInfoCard = (props: Props) => {
	const customerTrackTypeSpecification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: state.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);

	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const currentPersonId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);

	const { icon } = useTryGetSvgIconFromQueryCache(props.trip.objectIcon);

	const getEndAddressIcon = (): React.ReactNode => {
		return props.trip.isInProgress ? (
			<DrivingSvgImage className={`direction-icon direction-${props.trip.direction?.toLowerCase()}`} />
		) : props.trip.nextConnectedTripId ? (
			<TripIntermediateIcon className={'intermediate-address-icon'} />
		) : (
			<FileDownloadIcon className={'end-address-icon'} />
		);
	};

	const getStartAddressIcon = (): React.ReactNode => {
		return props.trip.isNonTrip ? (
			<FileDownloadIcon className={'nontrip-address-icon'} />
		) : (
			<FileUploadIcon className={'start-address-icon'} />
		);
	};

	const showTrip =
		!(
			props.trip.isInProgress &&
			props.trip.private &&
			customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
			!(props.trip.personId === currentPersonId || userCanSeePrivateData)
		) || props.trip.isNonTrip;

	const showVisitTime =
		(!props.trip.isInProgress &&
			(props.trip.visitTime || customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime)) ||
		props.trip.isNonTrip;

	return (
		showTrip && (
			<Box className={`live-overview-info-card ${props.trip.isInProgress ? 'in-progress-background' : ''}`}>
				<TripNumber
					tripColor={props.trip.tripColor}
					tripIndex={props.trip.index}
					isInProgress={props.trip.isInProgress}
					containerClass="trip-number"
				/>

				<Box component="span" className={'card-data'}>
					{!props.trip.isNonTrip ? (
						<>
							{props.trip.isPerson ? (
								<PersonObject
									name={props.trip.objectName}
									person={false}
									iconContent={icon.content}
									objectNameWithTimeZone={props.trip.objectNameWithTimeZoneOffset}
								/>
							) : driverIdentification ? (
								<PersonObject name={props.trip.personName} person={true} objectNameWithTimeZone={''} />
							) : null}

							<AddressRow
								address={props.trip.endAddress}
								locationName={props.trip.endLocationName}
								icon={getEndAddressIcon()}
							/>
						</>
					) : null}

					{!props.trip.prevConnectedTripId || props.trip.isNonTrip ? (
						<AddressRow
							address={props.trip.startAddress}
							locationName={props.trip.startLocationName}
							icon={getStartAddressIcon()}
						/>
					) : null}

					<Box component="span" className="date-duration-distance-idle-row">
						{!props.trip.isNonTrip ? (
							<>
								<Box component="span" className="extended-trip-details date-col">
									<Box component="span" className="icon-wrapper first">
										<AccessTime />
									</Box>
									<Box component="span" className="icon-text">
										{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
										!props.trip.startTripTime &&
										!props.trip.endTripTime ? (
											privateDataMask
										) : (
											<Box component="span">
												{GetHourAndMinutes(props.trip.startTripTime, true)} {' - '}
												<Box
													component="span"
													className={props.trip.isInProgress ? 'hidden-text' : ''}
												>
													{GetHourAndMinutes(props.trip.endTripTime, true)}
												</Box>
											</Box>
										)}
									</Box>
								</Box>

								<Box component="span" className="extended-trip-details duration-col">
									<Box component="span" className="icon-wrapper">
										<HourglassBottomOutlined />
									</Box>
									<Box component="span" className="icon-text">
										{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
										// compare it to null because tripDuration is null when it has to be hidden
										props.trip.tripDuration === null
											? privateDataMask
											: DateTimeUtil.calculateTimePassed(
													props.trip.tripDuration,
													new Date(),
													false
											  )}
									</Box>
								</Box>

								<Box component="span" className="extended-trip-details distance-col">
									<Box component="span" className="icon-wrapper">
										<PinDropOutlined />
									</Box>
									<Box component="span" className="icon-text">
										{props.trip.totalMileage.toFixed(1)} km
									</Box>
								</Box>

								<Box component="span" className={'extended-trip-details trip-type'}>
									{customerTrackTypeSpecification ? GetTripType(props.trip.trackType) : null}
								</Box>
							</>
						) : (
							<>
								<Box component="span" className="extended-trip-details date-col">
									<Box component="span" className="icon-wrapper first">
										<AccessTime />
									</Box>
									<Box component="span" className="icon-text">
										<Box component="span">
											{FormatDateWithReferenceDate(
												props.trip.startTripTime,
												moment
													.utc()
													.utcOffset(props.trip.timeZoneMinutesOffset)
													.format(),
												true
											)}{' '}
											{' - '}
											<Box
												component="span"
												className={props.trip.isInProgress ? 'hidden-text' : ''}
											>
												{GetHourAndMinutes(props.trip.endTripTime, true)}
											</Box>
										</Box>
									</Box>
								</Box>
							</>
						)}

						<Box component="span" className="extended-trip-details idle-col">
							{showVisitTime ? (
								<>
									<Box component="span" className="icon-wrapper">
										<PauseCircleFilledOutlined />
									</Box>
									<Box component="span" className="icon-text">
										{getTripVisitTime(
											props.trip,
											customerPrivacySettings,
											currentPersonId,
											userCanSeePrivateData
										)}
									</Box>
								</>
							) : null}
						</Box>
					</Box>
				</Box>
			</Box>
		)
	);
};

export default LiveOverviewInfoCard;
