import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import moment from 'moment';
import React from 'react';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { TranslateText } from 'utils/Translations';

import { Box, Button, Icon, IconButton } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../../../Constants';
import { DebouncedButton } from '../../../../Common/DebouncedButton';
import { MessageSearchBox } from './MessageSearchBox';
import { LogWidgetMessageFiltersProps } from './types';

const LogWidgetMessageFilters = ({
	getAllMessages,
	lastDayWithMessagesUTC,
	logsDay,
	navigateBack,
	navigateForward,
	skip,
	timezoneEnabled,
	timezoneId,
	timezoneList,
	today,
	totalLogMessages,
	exportData,
	getLastDayWithUTCMessagesAndReload,
	resetLogsGrid,
	resetSkipTotalMessages,
	setAllRecordsLoaded,
	setLogsDay,
	setFilterBy,
	setTimezoneId,
}: LogWidgetMessageFiltersProps): JSX.Element => {
	const handleOnDateChange = (date?: Date) => {
		if (date) {
			setLogsDay(
				moment(date)
					.startOf('day')
					.toDate()
			);
		} else {
			setLogsDay(null);
		}
		resetSkipTotalMessages();
	};

	const handleOnToDayButtonClick = () => {
		const currentDay = new Date();
		currentDay.setHours(0, 0, 0, 0);
		setLogsDay(currentDay);
		resetSkipTotalMessages();
	};

	const handleOnArrowDownwardClick = () => {
		if (totalLogMessages > skip) {
			getAllMessages().then(() => {
				setTimeout(() => {
					document.getElementById('move-down-hook').scrollIntoView({ block: 'nearest', inline: 'nearest' });
				}, 100);
			});
		} else {
			setAllRecordsLoaded(true);
			setTimeout(() => {
				document.getElementById('move-down-hook').scrollIntoView({ block: 'nearest', inline: 'nearest' });
			}, 100);
		}
	};

	const handleOnArrowUpwardClick = () => {
		document.getElementById('move-up-hook').scrollIntoView({ block: 'nearest', inline: 'nearest' });
	};

	const handleOnRefreshClick = () => {
		//check if the last day with messages is the current day when reload the grid
		moment(
			moment(moment().toDate())
				.startOf('day')
				.toDate()
		).diff(
			moment(lastDayWithMessagesUTC)
				.startOf('day')
				.toDate(),
			'days'
		)
			? getLastDayWithUTCMessagesAndReload()
			: moment(lastDayWithMessagesUTC)?.diff(logsDay, 'days') // reload the grid or go to the last day with records
			? getLastDayWithUTCMessagesAndReload()
			: resetLogsGrid();
	};

	const handleOnSearchInputChange = (value: string) => {
		setFilterBy((oldValue: string) => {
			if (oldValue !== value) {
				resetSkipTotalMessages();
				return value;
			}

			return oldValue;
		});
	};

	return (
		<Box className="device-logs-filter">
			<MessageSearchBox onChange={handleOnSearchInputChange} />
			<div className="date-selector">
				<IconButton onClick={navigateBack} size="small">
					<Icon>chevron_left</Icon>
				</IconButton>
				<MaterialDatePicker
					disableCloseIcon
					maxDate={today}
					disableFuture={true}
					showTime={false}
					fullWidth={true}
					name="logsDay"
					date={logsDay}
					format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true)}
					onDateChange={(date?: Date) => handleOnDateChange(date)}
				/>
				<IconButton
					disabled={moment(lastDayWithMessagesUTC)?.diff(logsDay, 'days') === 0}
					onClick={navigateForward}
					size="small"
				>
					<Icon>chevron_right</Icon>
				</IconButton>
			</div>
			<Button onClick={handleOnToDayButtonClick} className="today-button-filter" size="small">
				{TranslateText('historyOverview.currentDay')}
			</Button>
			<MaterialAutocomplete
				dataSource={timezoneList}
				disableClearable
				disabled={!timezoneEnabled}
				name="timezoneSelector"
				onChange={({ value }) => {
					setTimezoneId(value as string);
					resetSkipTotalMessages();
				}}
				valueId={timezoneId}
			/>
			<IconButton onClick={handleOnRefreshClick} size="small">
				<Icon>refresh</Icon>
			</IconButton>
			<IconButton onClick={handleOnArrowDownwardClick} size="small">
				<Icon>arrow_downward</Icon>
			</IconButton>
			<IconButton onClick={handleOnArrowUpwardClick} size="small">
				<Icon>arrow_upward</Icon>
			</IconButton>
			<DebouncedButton onClick={exportData} size="small" debounceTime={DEBOUNCE_TIME}>
				<Icon>file_download</Icon>
			</DebouncedButton>
		</Box>
	);
};

export default LogWidgetMessageFilters;
