enum ConnectionTypeEnum {
	CustomerObject = 'CustomerObject',
	CustomerDevice = 'CustomerDevice',
	CustomerSimCard = 'CustomerSimCard',
	CustomerPerson = 'CustomerPerson',
	DeviceObject = 'DeviceObject',
	DeviceSimCard = 'DeviceSimCard',
	PersonKey = 'PersonKey',
	PersonObject = 'PersonObject',
}

export default ConnectionTypeEnum;
