import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as DrivingSvgImage } from '../../assets/icons/DrivingStateSideBar.svg';
import { ReactComponent as IdleSvgImage } from '../../assets/icons/Idle.svg';
import { ReactComponent as ParkedSvgImage } from '../../assets/icons/Parked.svg';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { StateFilterEnum } from '../../models/LiveMenuUtils';
import DateTimeUtil from '../../shared/datetime/DateTimeUtil';
import DistanceUtils from '../../shared/distance/DistanceUtils';
import { FollowupResult } from './SearchResultFollowup';

interface Props {
	item: FollowupResult;
	index: number;
	selectedFollowupId?: string;
	driverIdentification: boolean;
	onFollowupSelected: (item: FollowupResult) => void;
}

const maxDisplayedIndex = 9;

const FollowupItem = (props: Props) => {
	return (
		<div
			key={props.item.id}
			hidden={props.index > maxDisplayedIndex}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (e.nativeEvent?.stopImmediatePropagation) {
					e.nativeEvent.stopImmediatePropagation();
				}

				if (props.item.loaded) {
					props.onFollowupSelected(props.item);
				}
			}}
			className={`followup-item ${props.item.id === props.selectedFollowupId ? 'selected' : ''}`}
		>
			<div className="object-status-icon">
				{props.item.state === StateFilterEnum.Driving ? (
					<DrivingSvgImage
						className={`driving-img direction-icon direction-${props.item.direction?.toLowerCase()}`}
					/>
				) : props.item.state === StateFilterEnum.Parked ? (
					<ParkedSvgImage className="parked-img" />
				) : props.item.state === StateFilterEnum.Idle ? (
					<IdleSvgImage className="idle-img" />
				) : null}
			</div>
			{props.item.entityType === EntityTypeEnum.Object ? (
				<>
					<div className="object">
						<span hidden={props.item.hideObject} className="material-icons-outlined icon">
							local_shipping
						</span>
						<span hidden={props.item.hideObject} className="text">
							{props.item.objectName}
						</span>
					</div>
					{props.driverIdentification ? (
						<div className="driver">
							<span hidden={props.item.hidePerson} className="material-icons icon">
								person
							</span>
							<span hidden={props.item.hidePerson} className="text">
								{props.item.personName}
							</span>
						</div>
					) : null}
				</>
			) : (
				<>
					{props.driverIdentification ? (
						<div className="driver">
							<span hidden={props.item.hidePerson} className="material-icons icon">
								person
							</span>
							<span hidden={props.item.hidePerson} className="text">
								{props.item.personName}
							</span>
						</div>
					) : null}

					<div className="object">
						<span hidden={props.item.hideObject} className="material-icons-outlined icon">
							local_shipping
						</span>
						<span hidden={props.item.hideObject} className="text">
							{props.item.objectName}
						</span>
					</div>
				</>
			)}
			<div className="time">
				<span className="material-icons icon">hourglass_full</span>
				<span className="text">
					{props.item.loaded ? (
						props.item.time >= 0 ? (
							DateTimeUtil.hoursAndMinutesNonZero(props.item.time)
						) : null
					) : (
						<CircularProgress className={'inline-progress'} size={'small'} />
					)}
				</span>
			</div>
			<div className="distance">
				<span className="material-icons icon">navigation</span>
				<span className="text">
					{props.item.loaded ? (
						props.item.distance >= 0 ? (
							DistanceUtils.distanceInKM(props.item.distance)
						) : null
					) : (
						<CircularProgress className={'inline-progress'} size={'small'} />
					)}
				</span>
			</div>
		</div>
	);
};

export default React.memo(FollowupItem);
