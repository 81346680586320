import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import DateColumn from 'components/GridOverview/Columns/DateColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { getDriverOrPersonRoute } from '../../utils/DriverUtils';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';

interface KeysDto extends GridEntity {
	code: string;
	serialNumber: string;
	productionDate: string;
	customerName: string;
	personName: string;
	personId: string;
	madeByUserName: string;
	madeByUserId: string;
	isMadeBySystem: boolean;
	active: boolean;
	customerId: string;
	personDriver: boolean;
	canViewMadeBy: boolean;
	canViewPerson: boolean;
}

const fieldNames = {
	customerName: 'customerName',
	code: 'code',
	serialNumber: 'serialNumber',
	productionDate: 'productionDate',
	personName: 'personName',
	madeByUserName: 'madeByUserName',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.serialNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.productionDate]: {
			value: null,
			matchMode: MatchMode.DateIs,
		},

		[fieldNames.personName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.madeByUserName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

interface Props {
	history: History<LocationState>;
}

export const KeysOverview = (props: Props) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer?.filteredCustomer?.featuresSettings?.driverIdentification
			: s.currentSession?.customer?.featuresSettings?.driverIdentification
	);

	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomerName'),
			body: (rowData: KeysDto) => (
				<Link title={rowData.customerName} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.serialNumber,
			header: TranslateText('maintenanceOverview.grid.colSerialNumber'),
			sortable: true,
			filterable: true,
		}),
		DateColumn({
			fieldName: fieldNames.productionDate,
			header: TranslateText('maintenanceOverview.grid.colProductionDate'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.personName,
			header: TranslateText('maintenanceOverview.grid.colDriverName'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateOptionalClaimList(user, [
				{
					claim: ClaimType.Persons,
					values: [ClaimValue.view],
				},
				{
					claim: ClaimType.Drivers,
					values: [ClaimValue.view],
				},
			]),
			body: (rowData: KeysDto) =>
				rowData.canViewPerson ? (
					<Link
						title={rowData.personName}
						to={getDriverOrPersonRoute(user, driverIdentification, rowData.personDriver, rowData.personId)}
					>
						{rowData.personName}
					</Link>
				) : (
					<span title={rowData.personName}>{rowData.personName}</span>
				),
		}),
		TextColumn({
			fieldName: fieldNames.madeByUserName,
			header: TranslateText('maintenanceOverview.grid.colMadeByUserId'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Persons),
			body: (rowData: KeysDto) =>
				!rowData.isMadeBySystem && rowData.canViewMadeBy ? (
					<Link title={rowData.madeByUserName} to={`${GlobalSettings.route.persons}/${rowData.madeByUserId}`}>
						{rowData.madeByUserName}
					</Link>
				) : (
					<span title={rowData.madeByUserName}>{rowData.madeByUserName}</span>
				),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.keysMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.keys')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default}
			showAddConfigClaim={[
				{
					claim: ClaimType.Keys,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Key}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			showImportConfigClaim={[
				{
					claim: ClaimType.Keys,
					values: [ClaimValue.edit],
				},
				{
					claim: ClaimType.Import,
					values: [ClaimValue.keys]
				}
			]}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};
