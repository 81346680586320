import { TextField } from '@material-ui/core';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import LocationOnMapEnum from 'models/LocationOnMapEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

type Props = WidgetViewDefault & {
	data: HistorySettings;
};

export interface HistorySettings {
	maxHistoryTime: number;
	defaultHistoryTagId: string;
	useHistoryLocationFields: boolean;
	useHistoryLocationOnMap: LocationOnMapEnum;
	isForPerson: boolean | null;
	showTemplateStateOnMap: boolean;
}

const DefaultHistorySettingsValues = {
	defaultHistoryTagId: '4f94641b-8e1d-4cba-8066-3392b39111e3',
	maxHistoryTime: 182,
	useHistoryLocationFields: true,
	useHistoryLocationOnMap: LocationOnMapEnum.Icon,
	showTemplateStateOnMap: false,
};

type ChangeValue = string | number | boolean | LocationOnMapEnum;

const getConstants = () => {
	return {
		defaultTagsSource: [
			{ id: DefaultHistorySettingsValues.defaultHistoryTagId, display: TranslateText('common.default') },
		],
		defaultValues: {
			defaultHistoryTagId: DefaultHistorySettingsValues.defaultHistoryTagId,
			maxHistoryTime: DefaultHistorySettingsValues.maxHistoryTime,
			useHistoryLocationFields: true,
			useHistoryLocationOnMap: LocationOnMapEnum.Icon,
			showTemplateStateOnMap: DefaultHistorySettingsValues.showTemplateStateOnMap
		} as HistorySettings,
		fieldsConfig: { text: 'display', value: 'id' },
		useHistoryLocationFields: [
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
		useHistoryLocationOnMap: [
			{ id: 0, display: TranslateText('customerOverview.locationOnMap.dontShow') },
			{ id: 1, display: TranslateText('customerOverview.locationOnMap.icon') },
			{ id: 2, display: TranslateText('customerOverview.locationOnMap.iconLabel') },
		],
		showTemplateStateOnMap: [
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		]
	};
};

export const GetCustomerHistorySettingsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		maxHistoryTime: {
			rules: {
				required: ValidatorFunctions.required(),
				min: ValidatorFunctions.minNumber(1),
				max: ValidatorFunctions.maxNumber(31),
			},
		},
		defaultHistoryTagId: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		useHistoryLocationFields: {
			rules: {
				required: ValidatorFunctions.requiredWithNull(),
			},
		},
		useHistoryLocationOnMap: {
			rules: {
				required: ValidatorFunctions.requiredWithNull(),
			},
		},
		showTemplateStateOnMap: {
			rules: {
				required: ValidatorFunctions.requiredWithNull(),
			},
		},
	};

	return fieldRules;
};

const CustomerHistorySettingsView = (props: Props) => {
	const [constants, setConstants] = useState(getConstants);

	const customerLocationsFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.locations
			: s.currentSession.customer?.featuresSettings.locations
	);

	const customerTemplateFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.templates
			: s.currentSession.customer?.featuresSettings.templates
	);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetCustomerHistorySettingsValidationConfig() }));
		if (props.data.isForPerson) {
			setConstants({
				...constants,
				useHistoryLocationOnMap: [
					{ id: null, display: TranslateText('common.default') },
					...constants.useHistoryLocationOnMap,
				],
			});
		}
	}, [props.entityId]);

	useEffect(() => {
		if (!customerLocationsFeature) {
			const newState = { ...props.data };
			newState.useHistoryLocationFields = true;
			newState.useHistoryLocationOnMap = LocationOnMapEnum.Icon;
			props.changeDataCallback(newState);
		}
	}, [customerLocationsFeature]);

	const events = {
		onValueChanged: async (value: ChangeValue, key: keyof HistorySettings) => {
			const newState = { ...props.data };
			(newState[key] as ChangeValue) = value;

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper'}>
			<div className="form-group">
				<TextField
					id="maxHistoryTime"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.maxHistoryTime')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
					name="maxHistoryTime"
					value={props.data.maxHistoryTime}
					size="small"
					onChange={(e: any) => events.onValueChanged(e.target.value as number, 'maxHistoryTime')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.maxHistoryTime} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.defaultHistoryTagId}
					dataSource={constants.defaultTagsSource}
					name="defaultHistoryTagId"
					disabled={!props.editMode}
					label={TranslateText('fields.defaultHistoryTag')}
					onChange={({ value }) => events.onValueChanged(value as string, 'defaultHistoryTagId')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.defaultHistoryTagId} />
				<div className="e-text">{TranslateText('fields.historyTagExample')}</div>
			</div>
			{customerLocationsFeature ? (
				<>
					<YesNoCombobox
						name={'useHistoryLocationFields'}
						value={props.data.useHistoryLocationFields}
						placeholder={TranslateText('fields.useLocationFields')}
						change={(value) => events.onValueChanged(value as boolean | null, 'useHistoryLocationFields')}
						readonly={!props.editMode}
						validationResult={props.validationResult}
						showDefaultTrueFalse={props.data.isForPerson}
					/>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data.useHistoryLocationOnMap}
							dataSource={constants.useHistoryLocationOnMap}
							name="useLiveLocationOnMap"
							disabled={!props.editMode}
							label={TranslateText('fields.useLocationOnMap')}
							onChange={({ value }) =>
								events.onValueChanged(value as LocationOnMapEnum, 'useHistoryLocationOnMap')
							}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.useLiveLocationOnMap} />
					</div>
				</>
			) : null}
			{customerTemplateFeature ?
				(<YesNoCombobox
				name={'showTemplateStateOnMap'}
				value={props.data.showTemplateStateOnMap}
				placeholder={TranslateText('fields.showTemplateStateOnMap')}
				change={(value) => events.onValueChanged(value as boolean | null, 'showTemplateStateOnMap')}
				readonly={!props.editMode}
				validationResult={props.validationResult}
				showDefaultTrueFalse={props.data.isForPerson}
				/>)
			: null}
		</div>
	);
};

export default CustomerHistorySettingsView;
