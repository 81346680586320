import { TreeSelectionGroupDialog } from 'components/TreeSelectionDialog';
import React from 'react';
import { useId } from 'utils/hooks';

import { useReportSelectionArea } from './hooks';
import { ReportSelectionAreaProps } from './types';

const ReportSelectionArea = (props: ReportSelectionAreaProps): JSX.Element => {
	const {
		showInactive,
		retrieveDataAsyncCallback,
		saveCallback,
		closeCallback,
		filterButtons,
	} = useReportSelectionArea(props);

	const { id } = useId();

	return (
		<TreeSelectionGroupDialog
			key={id}
			visible={props.showDialog}
			initialSelection={props.selectedEntities}
			title={props.title}
			filterPlaceholder={props.filterPlaceholder}
			retrieveData={retrieveDataAsyncCallback}
			closeCallback={closeCallback}
			saveCallback={saveCallback}
			showInactive={showInactive}
			clearOnCheckInactive={false}
			filterButtons={filterButtons}
		/>
	);
};

export default ReportSelectionArea;
