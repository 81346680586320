import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import { Person } from 'models/Person';
import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { ValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

type Props = {
	readOnly: boolean;
	customerLevel: CustomerLevelEnum;
	handleValueChange: (changedPerson: Person, effectiveChange?: boolean) => void;
	person: Person;
	validationResult: ValidationResult;
};

const PersonLoginData = (props: Props): JSX.Element => {
	return (
		props.person && (
			<form id="loginDataForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<MaterialTextField
							id="username"
							className="resize-font"
							label={TranslateText('fields.username')}
							inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
							name="username"
							value={props.person.username}
							handleValueChange={(value) => props.handleValueChange({ ...props.person, username: value })}
							disabled={props.readOnly}
						/>
						<ValidationMessage result={props.validationResult?.username} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="password"
							className="resize-font"
							label={
								!props.readOnly ? TranslateText('fields.newPassword') : TranslateText('fields.password')
							}
							inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
							name="password"
							value={props.person.password}
							handleValueChange={(value) => props.handleValueChange({ ...props.person, password: value })}
							disabled={props.readOnly}
						/>
						<ValidationMessage result={props.validationResult?.password} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="loginCode"
							className="resize-font"
							label={TranslateText('fields.loginCode')}
							inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
							name="loginCode"
							value={props.person.loginCode}
							handleValueChange={(value) =>
								props.handleValueChange({ ...props.person, loginCode: value })
							}
							disabled={props.readOnly}
						/>
						<ValidationMessage result={props.validationResult?.loginCode} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="keypadCode"
							className="resize-font"
							label={TranslateText('fields.keypadCode')}
							inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
							name="keypadCode"
							value={props.person.keypadCode ? props.person.keypadCode : ''}
							handleValueChange={(value) =>
								props.handleValueChange({ ...props.person, keypadCode: value })
							}
							disabled={props.readOnly}
						/>
						<ValidationMessage result={props.validationResult?.keypadCode} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="tachoId"
							className="resize-font"
							label={TranslateText('fields.tachoId')}
							inputProps={{ readOnly: props.readOnly, style: { fontSize: 12 } }}
							name="tachoId"
							value={props.person.tachoId}
							handleValueChange={(value) => props.handleValueChange({ ...props.person, tachoId: value })}
							disabled={props.readOnly}
						/>
						<ValidationMessage result={props.validationResult?.tachoId} />
					</div>
				</div>
			</form>
		)
	);
};

export default PersonLoginData;
