import { TranslateText } from '../../utils/Translations';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';

const ObjectFunctionDataSource = {
	dataSource: () => [
		{
			id: ObjectFunctionEnum.Full,
			display: TranslateText('objects.objectFunction.Full'),
		},
		{
			id: ObjectFunctionEnum.Asset,
			display: TranslateText('objects.objectFunction.Asset'),
		},
	],
};

export default ObjectFunctionDataSource;
