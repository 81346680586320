import { Icon, IconButton } from '@material-ui/core';
import { Column, ColumnBodyType } from 'primereact/column';
import { MultiSelect, MultiSelectItemTemplateType } from 'primereact/multiselect';
import React from 'react';
import { TranslateText } from 'utils/Translations';

export type MultiSelectionColumnOption = {
	name: string;
	code: string | boolean | number;
	hidden?: boolean;
};

export type ColumnFlagOrdering = {
	[key in string]: string;
};

type Props = {
	fieldName: string;
	header: string;
	selectOptions: MultiSelectionColumnOption[];
	body?: ColumnBodyType;
	sortable?: boolean;
	filterable?: boolean;
	leftPadding?: boolean;
	shown?: boolean;
	showHeaderTooltip?: boolean;
	showFilterTooltip?: boolean;
	showContentTooltip?: boolean;
	optionItemTemplate?: MultiSelectItemTemplateType;
	filterDisabled?: boolean;
	defaultWidth?: number;
};

export const computeFlagOrderingBasedOnTranslation = (selectOptions: MultiSelectionColumnOption[]): string => {
	const sortedOptions = [...selectOptions].sort((a, b) => a.name.localeCompare(b.name));
	return selectOptions.map((o) => sortedOptions.indexOf(o)).join(',');
};

const MultiSelectionColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	selectOptions,
	leftPadding = true,
	shown = true,
	showHeaderTooltip = true,
	showFilterTooltip = true,
	showContentTooltip = true,
	optionItemTemplate,
	filterDisabled,
	defaultWidth,
}: Props) =>
	shown ? (
		<Column
			className={`sortIcon ${filterDisabled ? 'disabled' : ''} ${leftPadding ? 'leftPadding' : ''} minWidth`}
			key={fieldName}
			field={fieldName}
			style={
				defaultWidth !== undefined
					? {
							flexGrow: '1',
							flexShrink: '1',
							flexBasis: `${defaultWidth}px`,
					  }
					: null
			}
			header={() => (
				<span
					className={`p-column-title ${filterDisabled ? 'disabled' : ''}`}
					title={showHeaderTooltip ? header : ''}
				>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) => (
							<span title={showContentTooltip ? rowData[fieldName] : ''}>{rowData[fieldName]}</span>
					  )
			}
			sortable={sortable}
			showClearButton={false}
			showFilterMenu={false}
			filter={filterable}
			filterElement={(options) => {
				let filterTooltip = '';
				if (options.value) {
					const selection = [...options.value] as MultiSelectionColumnOption[];
					filterTooltip = selectOptions
						.filter((o) => !o.hidden && selection.some((s) => s.code === o.code))
						.map((o) => o.name)
						.join('\n');
				}

				return (
					<span className="p-input-icon-right" title={showFilterTooltip ? filterTooltip : ''}>
						<MultiSelect
							value={selectOptions?.length > 0 ? options.value : ''}
							options={selectOptions.filter((s) => !s.hidden)}
							onChange={(e) => options.filterApplyCallback(e.value)}
							optionLabel="name"
							maxSelectedLabels={1}
							className="multiSelect"
							selectedItemsLabel={TranslateText('maintenanceOverview.multipleItemsSelected')}
							panelClassName="multiSelectDropdown"
							itemTemplate={optionItemTemplate}
							disabled={filterDisabled}
						/>
						<IconButton
							hidden={!options.value.length || filterDisabled || !selectOptions?.length}
							onClick={() => options.filterApplyCallback([])}
							size="small"
							className="inputClearButton"
						>
							<Icon>close</Icon>
						</IconButton>
					</span>
				);
			}}
		/>
	) : null;

export default MultiSelectionColumn;
