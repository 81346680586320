import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { enableRipple } from '@syncfusion/ej2-base';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import Device from '../../models/Device';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import CustomerWidget from '../Widgets/CustomerWidget';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import DeviceInformationWidget from '../Widgets/DeviceInformationWidget';
import DeviceObjectWidget from '../Widgets/DeviceObjectWidget';
import DeviceSimCardWidget from '../Widgets/DeviceSimCardWidget';
import DeviceTemplateWidget from '../Widgets/DeviceTemplateWidget/DeviceTemplateWidget';
import LogWidget from '../Widgets/LogWidget';

enableRipple(true);

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		editingInstance: state.loadedEntityContext.editingInstance,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterText: state.globalCustomer.filterText.devices,
		user: state.oidc.user,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
	};
}

type MatchProps = {
	match: { params: { [key: string]: string } };
};

const connector = connect(mapState, mapDispatchToProps);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WidgetDashboardTransferableProps & MatchProps & PropsFromRedux & {};
const pathToOverview = '/devices';
let objectConnectionConflictsNotifierRef = null as ConnectionConflictsNotifier;
let simCardConnectionConflictsNotifierRef = null as ConnectionConflictsNotifier;

const DeviceDetails = (props: Props) => {
	const [currentTitle, setCurrentTitle] = useState<string>('');
	const [reloadSimInfo, setReloadSimInfo] = useState(false);
	const [reloadObjectInfo, setReloadObjectInfo] = useState(false);
	const [reloadInfo, setReloadInfo] = useState(false);

	const getBreadcrumb = () => {
		return currentTitle ? `${TranslateText('common.device')} > ${currentTitle}` : '';
	};
	useEffect(() => {
		return () => {
			props.loadedEntityContextActions.setLoadedDeviceContext(null);
		};
	}, []);
	const events = {
		handleConflictingConnections: (sender: any, confirm: boolean) => {
			sender.handleConflictingConnections(confirm);
		},
		handleDisplayCallBack: (device: Device) => {
			setCurrentTitle(device.code);
			setReloadInfo(false);

			props.loadedEntityContextActions.setLoadedDeviceContext(device);
		},
		handleSimDelayedCallback: () => {
			setReloadSimInfo(false);
		},
		handleObjectDelayedCallback: () => {
			setReloadObjectInfo(false);
		},
		connectionChangedCallback: () => {
			setReloadInfo(true);
			setReloadSimInfo(true);
			setReloadObjectInfo(true);
		},
	};
	return (
		<>
			<ConnectionConflictsNotifier
				ref={(scope) => {
					objectConnectionConflictsNotifierRef = scope;
				}}
				connectionType={ConnectionTypeEnum.DeviceObject}
				visible={false}
				userAnswearCallback={(s, c) => events.handleConflictingConnections(s, c)}
			/>
			<ConnectionConflictsNotifier
				ref={(scope) => {
					simCardConnectionConflictsNotifierRef = scope;
				}}
				connectionType={ConnectionTypeEnum.DeviceSimCard}
				visible={false}
				userAnswearCallback={(s, c) => events.handleConflictingConnections(s, c)}
			/>
			<WidgetDashboard
				goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.Device}s`)}
				history={props.history}
				currentRecordId={props.match.params.id}
				goBackToCallback={() => {
					props.history.push(pathToOverview, { id: props.match.params.id });
				}}
				urlPath={pathToOverview}
				breadcrumbText={getBreadcrumb()}
				filterText={props.filterText}
				entityType={EntityTypeEnum.Device}
			>
				<DeviceInformationWidget
					row="0"
					col="0"
					sizeX="5"
					sizeY="7"
					id={'deviceInformation'}
					entityId={props.match.params.id}
					displayCallback={events.handleDisplayCallBack}
					getAccessTokenCallback={() => props.access_token}
					forceReload={reloadInfo}
				/>
				<ProtectedContainer
					claimConfig={[
						{ claim: ClaimType.Devices, values: [ClaimValue.view] },
						{ claim: ClaimType.Objects, values: [ClaimValue.view] },
						{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
					]}
				>
					<DeviceObjectWidget
						row="0"
						col="5"
						sizeX="11"
						sizeY="3"
						id={'objectHistory'}
						entityId={props.match.params.id}
						conflictingConnectionNotifier={objectConnectionConflictsNotifierRef}
						forceReload={reloadObjectInfo}
						delayedCallback={events.handleObjectDelayedCallback}
						entityType={EntityTypeEnum.Device}
					/>
				</ProtectedContainer>
				<ProtectedContainer claimList={[ClaimType.Customers, ClaimType.Devices]}>
					<CustomerWidget
						row="4"
						col="5"
						sizeX="11"
						sizeY="4"
						id={'customerConnections'}
						entityId={props.match.params.id}
						entityType={EntityTypeEnum.Device}
						connectionChangedCallback={events.connectionChangedCallback}
						showEditButton={true}
					/>
				</ProtectedContainer>
				<ProtectedContainer claim={ClaimType.Devices}>
					<LogWidget
						row="8"
						col="0"
						sizeX="19"
						sizeY="6"
						id={'deviceMessagesLog'}
						entityType={EntityTypeEnum.Device}
						entityId={props.match.params.id}
						latestLogsDayUrl={`${GlobalSettings.devicesMaintenanceApi}/LatestLogsDay/${props.match.params.id}`}
					/>
				</ProtectedContainer>
				<ProtectedContainer
					claimConfig={[
						{ claim: ClaimType.Templates, values: [ClaimValue.view] },
						{ claim: ClaimType.Devices, values: [ClaimValue.view] },
						{ claim: ClaimType.DeviceSensorTemplateConnection, values: [ClaimValue.edit] },
					]}
				>
					<DeviceTemplateWidget
						row="9"
						col="0"
						sizeX="8"
						sizeY="3"
						id={'inputTemplateConnections'}
						entityId={props.match.params.id}
						getAccessTokenCallback={() => props.access_token}
						user={props.user}
					/>
				</ProtectedContainer>
				<ProtectedContainer
					claimConfig={[
						{ claim: ClaimType.Devices, values: [ClaimValue.view] },
						{ claim: ClaimType.SimCards, values: [ClaimValue.view] },
						{ claim: ClaimType.SimDeviceConnection, values: [ClaimValue.edit] },
					]}
				>
					<DeviceSimCardWidget
						row="9"
						col="8"
						sizeX="11"
						sizeY="3"
						conflictingConnectionNotifier={simCardConnectionConflictsNotifierRef}
						id={'simCardHistory'}
						entityType={EntityTypeEnum.Device}
						forceReload={reloadSimInfo}
						delayedCallback={events.handleSimDelayedCallback}
						entityId={props.match.params.id}
					/>
				</ProtectedContainer>
			</WidgetDashboard>
		</>
	);
};
export default connector(DeviceDetails);
