import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const HourglassBottomOutlined = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} viewBox="0 0 24 24">
			<g>
				<path d="M18,22l-0.01-6L14,12l3.99-4.01L18,2H6v6l4,4l-4,3.99V22H18z M8,7.5V4h8v3.5l-4,4L8,7.5z" />
			</g>
		</SvgIcon>
	);
};

export default React.memo(HourglassBottomOutlined);
