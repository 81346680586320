import { Input, TextField } from '@material-ui/core';
import React from 'react';
import { useDebouncedInput } from './hooks';

import { DebouncedInputProps } from './types';

const DebouncedInput = ({
	debounceTime,
	onChange,
	value,
	maxLength = 9999,
	...rest
}: DebouncedInputProps): JSX.Element => {
	const { value: inputValue, handleOnChange } = useDebouncedInput(value, debounceTime, onChange);

	return (
		<TextField
			variant="standard"
			data-testid="debounced-input-testid"
			onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
				handleOnChange(event.currentTarget.value)
			}
			inputProps={{
				maxLength,
			}}
			value={inputValue}
			{...rest}
		/>
	);
};

export default DebouncedInput;
