enum CommandTypeEnum {
	Unknown = 0,
	SirenOn = 1,
	ImmediateStatus = 2,

	UnitStateChange_Standby = 3,
	UnitStateChange_EmergencyMode = 4,
	UnitStateChange_Reset = 5,

	OutputStateChange_BlinkersOff = 6,
	OutputStateChange_BlinkersOn = 7,
	OutputStateChange_StandardImobilizerOff = 8,
	OutputStateChange_StandardImobilizerOn = 9,
	SirenOff = 10,
}

export default CommandTypeEnum;
