const timeZoneKey = 'utc:';

function getHoursAndMinutes(timeZoneOffset: number) {
	const hours = computeHoursFormat(Math.sign(timeZoneOffset) * Math.floor(Math.abs(timeZoneOffset / 60)));
	const minutes = timeZoneOffset % 60 !== 0 ? `:${Math.abs(timeZoneOffset % 60)}` : '';
	return { hours, minutes };
}

export const getLongTimeZoneFormat = (timeZoneOffset: number): string => {
	if (timeZoneOffset === 0) {
		return '(UTC+0)';
	}
	const { hours, minutes } = getHoursAndMinutes(timeZoneOffset);
	return `(UTC${hours}${minutes})`;
};

export const getShortTimeZoneFormat = (timeZoneOffset: number): string => {
	if (timeZoneOffset === 0) {
		return '(+0)';
	}
	const { hours, minutes } = getHoursAndMinutes(timeZoneOffset);
	return `(${hours}${minutes})`;
};

export const extendObjectNameWithTimeZoneShortFormat = (
	name: string,
	timeZoneOffset: number,
	shouldDisplayTimeZone: boolean
) => {
	if (!shouldDisplayTimeZone) {
		return name;
	}

	const timeZoneText = getShortTimeZoneFormat(timeZoneOffset);
	return `${name}${timeZoneKey}${timeZoneText}`;
};

export const extendObjectNameWithTimeZoneLongFormat = (
	name: string,
	timeZoneOffset: number,
	shouldDisplayTimeZone: boolean
) => {
	if (!shouldDisplayTimeZone) {
		return name;
	}

	const timeZoneText = getLongTimeZoneFormat(timeZoneOffset);
	return `${name}${timeZoneKey}${timeZoneText}`;
};

const computeHoursFormat = (hours: number): string => (hours >= 0 ? `+${hours}` : `${hours}`);

export const shouldNotDisplayTimeZone = (customerTimezoneId: string, objectTimezoneId: string) =>
	customerTimezoneId === objectTimezoneId;

export const extractObjectName = (objectNameWithTimeZone: string) => objectNameWithTimeZone?.split(timeZoneKey)[0];

export const extractTimeZone = (objectNameWithTimeZone: string) => objectNameWithTimeZone?.split(timeZoneKey)[1] ?? '';
