import './buttonWidget.scss';

import { Button } from '@material-ui/core';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionDialog from 'components/TreeSelectionDialog';
import React, { useCallback, useEffect, useState } from 'react';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { PersonGroup } from '../Views/PersonGroupsView';
import { IActionButton } from './ButtonWidget';

interface SelectionInfo {
	id?: string;
	personId: string;
	groupId: string;
}

const mapToTreeNode = (data: any[]): ITreeNode[] => {
	const treeNodeSelection: ITreeNode[] = [];
	const personGroupList = data as PersonGroup[];
	if (personGroupList) {
		personGroupList.forEach((item) => {
			treeNodeSelection.push({
				id: item.id,
				text: item.name,
				active: item.active,
				type: 'Group',
				selected: true,
				items: [],
				childCount: 0,
				canBeChecked: true,
			});
		});
	}
	return treeNodeSelection;
};

interface Props extends IActionButton {
	updateUrl: string;
	retrieveUrl: string;
}

const AddGroupToPersonActionButton = (props: Props) => {
	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setInitialTreeData(mapToTreeNode(props.currentSelection));
	}, [props.currentSelection]);

	const [dialogVisible, setDialogVisible] = useState(false);
	const events = {
		closeDialog: () => {
			setDialogVisible(false);
		},
		saveSelectionCallback: (selection: ITreeNode[]) => {
			const removedEntries: SelectionInfo[] = [];
			const addedEntries: SelectionInfo[] = [];
			const currentSelection = props.currentSelection as PersonGroup[];

			//get removed entries
			if (currentSelection) {
				currentSelection.forEach((item) => {
					if (!selection.some((x) => x.id === item.id)) {
						removedEntries.push({
							id: item.connectionId,
							personId: props.entityId,
							groupId: item.id,
						});
					}
				});
			}

			//get added entries
			selection.forEach((item) => {
				if (!currentSelection || !currentSelection.some((x) => x.id === item.id)) {
					addedEntries.push({
						personId: props.entityId,
						groupId: item.id,
					});
				}
			});

			//update connections
			if (addedEntries.length || removedEntries.length) {
				ajaxUtil
					.post(props.updateUrl, {
						newConnections: addedEntries,
						removedConnections: removedEntries,
					})
					.then(() => {
						setDialogVisible(false);
						props.actionExecuted(true);
					});
			} else {
				setDialogVisible(false);
				props.actionExecuted(true);
			}
		},
		retrieveData: useCallback((filter: { customerId: string; showInactive: boolean; filterText: string }) => {
			return ajaxUtil.post<ITreeNode[]>(props.retrieveUrl, {
				customerId: filter.customerId,
				showInactive: filter.showInactive,
				filterText: filter.filterText,
			});
		}, []),
	};

	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionDialog
				title={TranslateText('common.addPersonToGroup')}
				filterPlaceholder={TranslateText('fleetSelection.searchGroupsPlaceholder')}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveSelectionCallback}
				retrieveData={events.retrieveData}
			/>
		</>
	);
};

export default AddGroupToPersonActionButton;
