import './exportDialog.scss'

import React from "react";
import {History, LocationState} from 'history';

import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import {TranslateText} from "../../../utils/Translations";
import DateTimeUtil from "../../../shared/datetime/DateTimeUtil";
import EntityData from "../../../models/EntityData";
import {useSelector} from 'react-redux';
import {ApplicationState} from "../../../store";
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from '../../../GlobalSettings.json';
import ReportRenderFormatEnum from "../../../models/ReportRenderFormatEnum";


interface ExportDialogProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	entityType: EntityTypeEnum;
	importUrlData: string;
	history: History<LocationState>;
}
const ExportDialog = (props: ExportDialogProps) => {

	const reportData = useSelector((s: ApplicationState) => s.reportStore);
	const personId = useSelector((s: ApplicationState) => s.currentSession.personId);
	const customerId = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer?.id ?? s.currentSession.customerId);
	const userId =  useSelector((s: ApplicationState) => s.currentSession.aspNetUserId);
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);

	const events = {
		export: async (includeInactive: boolean) => {
			await createNewReportExport(includeInactive);
		},
		onClose: () => {
			props.setOpen(false);
		}
	}

	const createNewReportExport = async (includeInactive: boolean) => {
		const templateDataId = getTemplateData();
		if(templateDataId !== null) {
			const reportParameters = {
				reportPeriodType: reportData.reportPeriodType?.value,
				reportCustomPeriodInterval: [new Date(), new Date()],
				reportPersonIds: [] as EntityData[],
				reportObjectIds: [] as EntityData[],
				reportGroupIds: [] as EntityData[],
				tripTypeParameter: reportData.tripTypeParameter,
				tripDistanceParameter: reportData.tripDistanceParameter,
				formatType: ReportRenderFormatEnum.xlsx,
				separateReports: reportData.separateReports,
				scheduleFrequency: reportData.scheduleFrequency,
				scheduleDay: reportData.scheduleDay,
				scheduleTime: (reportData.scheduleTime ?? new Date()).toLocaleTimeString(),
				scheduleTimezoneId: reportData.scheduleTimezoneId,
				sendAsMail: reportData.sendAsMail,
				mailReceivers: reportData.receivers,
				dateFormat: DateTimeUtil.localDateFormat(),
				timeFormat: DateTimeUtil.localTimeFormat(),
				timeZone: reportData.timeZone,
				customerId: reportData.customerId ?? customerId,
				personId: reportData.personId ?? personId,
				includeInactive: includeInactive,
				includePrivateData: reportData.includePrivateData,
				includeMaxSpeed: reportData.includeMaxSpeed,
				calculateVisitLastTripOfDayInReport: reportData.calculateVisitLastTripOfDayInReport,
				templates: reportData.templates,
				locations: reportData.locations,
				objectTypes: reportData.objectTypes,
				fuelTypes: reportData.fuelTypes,
				selectedSummaries: reportData.selectedSummaries,
				outsideBusinessHours: reportData.outsideBusinessHours,
				includeDevicesWithoutObjectConnection: reportData.includeDevicesWithoutObjectConnection
			};
			reportParameters.reportPeriodType = null;
			const timezoneId = await getCustomerTimezoneIdWithoutImpersonation();
			reportParameters.scheduleTimezoneId = timezoneId ?? reportParameters.scheduleTimezoneId;
			const scheduleTimeZoneIdReport = timezoneId ?? reportData.scheduleTimezoneId;

			const report = {
				templateId: templateDataId,
				requestedByUserId: userId,
				oneTimeExecution: true,
				reportParameters: reportParameters,
				sessionId: currentSession?.currentSessionId ?? '00000000-0000-0000-0000-000000000000',
				customerId: reportData.customerId ?? customerId,
				scheduleTime: scheduleTimeZoneIdReport ?? new Date().toLocaleTimeString(),
				scheduleTimezoneId: scheduleTimeZoneIdReport,
				onlyForExport: true
			};

			ajaxUtil
				.post(`${GlobalSettings.reportingApi}/addExportReport`, report)
				.then(() => {
					props.history.push({
						pathname: '/reports',
						state: {
							selectedIndex: 4,
							resetGeneratedReportsFiltersTrigger: true
						}
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	const getTemplateData = () => {
		switch (props.entityType)
		{
			case EntityTypeEnum.Location:
				return '3d821110-f0cd-4463-a1bc-542aca3a27d4';
			case EntityTypeEnum.Object:
				return '409d704f-108c-4ab5-bd13-ec480b49c2e1'
		}
		return null;
	}
	async function getCustomerTimezoneIdWithoutImpersonation(): Promise<string> {

		if (!customerId) {
			return null;
		}
		const response = await ajaxUtil.get<{ timezoneId: string }>(`${GlobalSettings.customersMaintenanceApi}/${customerId}`);
		return response.timezoneId;
	}

	const getTitle = (type: EntityTypeEnum): string => {
		return TranslateText(`maintenanceOverview.export.${type.toLowerCase()}`);
	}

	return(
		<Dialog
			open={props.open}
			onClose={events.onClose}
			className={'export-dialog export-download-dialog'}
		>
			<DialogTitle id="parent-dialog-title">{getTitle(props?.entityType)}</DialogTitle>
			<DialogContent>
				<Box className={'content-dialog'}>
					<DialogContentText>
						{TranslateText('maintenanceOverview.export.description')}
					</DialogContentText>
				</Box>
			</DialogContent>
			<DialogActions className={'dialog-buttons-actions'}>
				<Button
					disabled={false}
					className={'left-button-custom-dialog'}
					onClick={() => events.export(false)}
					color="primary"
				>
					{TranslateText('maintenanceOverview.export.no')}
				</Button>
				<div style={{flex: '1 0 0'}}/>
				<Button
					disabled={false}
					className={'right-button-custom-dialog'}
					onClick={() => events.export(true)}
					color="primary"
					>
						{TranslateText('maintenanceOverview.export.yes')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ExportDialog;
