import './PersonTimeSettings.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { PersonDateTimeSettings, PersonDateTimeViewValidationConfig } from 'components/Widgets/PersonDateTimeView';
import GlobalSettings from 'GlobalSettings.json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { DateTimeConstants } from 'utils/DateTimeConstants';
import { TranslateText } from 'utils/Translations';

import {
	Button,
	Card,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@material-ui/core';

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

const textFieldStyle = {
	marginTop: '10px',
	marginBottom: '10px',
	paddingLeft: '50px',
	fontFamily: 'Ubuntu',
	fontSize: '16px',
	fontWeight: 600,
};

const areTheSame = (initialData: PersonDateTimeSettings, data: PersonDateTimeSettings) => {
	if (JSON.stringify(initialData) === JSON.stringify(data)) {
		return true;
	}
	return false;
};

const PersonTimeSettings = (props: Props) => {
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);

	const [initialData, setInitialData] = useState<PersonDateTimeSettings>([] as PersonDateTimeSettings);
	const [data, setData] = useState<PersonDateTimeSettings>([] as PersonDateTimeSettings);
	const [isValid, setIsValid] = useState<boolean>(true);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [defaultValue, setDefaultValue] = useState<string>('');
	const [isChanged, setIsChanged] = useState(false);

	const [validator, setValidator] = useState(
		new Validator({ fieldRules: PersonDateTimeViewValidationConfig() }, true)
	);
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);

	const events = {
		onValueChanged: async (value: string | boolean, key: keyof PersonDateTimeSettings) => {
			const newData = { ...data };
			(newData[key] as string | boolean | number) = value;
			setData(newData);
			if (areTheSame(initialData, data) && value === defaultValue) {
				setIsChanged(false);
			} else if (!isChanged) {
				setIsChanged(true);
			}
		},
	};

	useEffect(() => {
		const url = `${GlobalSettings.profileMaintenanceApi}/dateTimeSettings/${personId}`;
		ajaxUtil.get<PersonDateTimeSettings>(url).then((result: PersonDateTimeSettings) => {
			setData(result);
			setInitialData(result);

			if (result.useLocalSettings === null || result.useLocalSettings === undefined) {
				setDefaultValue('customerDefaults');
			} else if (data.useLocalSettings) {
				setDefaultValue('localSetting');
			} else {
				setDefaultValue('custom');
			}

			validator.validate(data).then((result) => {
				setValidationResult(result.validationResult);
				setIsValid(result.formResult && isChanged);
			});
		});
	}, []);

	useEffect(() => {
		if (!props.unsavedData && isChanged) {
			props.setUnsavedData(true);
			window.onbeforeunload = () => {
				return 'Unsaved data';
			};
		}
		validator.validate(data).then((result) => {
			setValidationResult(result.validationResult);
			setIsValid(result.formResult && isChanged);
		});
	}, [data]);

	const saveTimeSettings = () => {
		const url = `${GlobalSettings.profileMaintenanceApi}/dateTimeSettings/`;
		ajaxUtil.put(url, data).then(() => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
		});
	};

	return (
		defaultValue && (
			<Grid item md={12} style={{ marginTop: '13px' }} className={'person-time-settings-grid'}>
				<Card>
					<CardContent>
						<div>
							<Typography variant="h5" className={'title'}>
								{TranslateText('personTimeSettings.timeSettings')}
							</Typography>
							<div className={'time-settings-container'}>
								<div className={'time-settings-section'}>
									<Typography variant="h6" className={'time-settings-title'}>
										{TranslateText('personTimeSettings.timeSettings')}
									</Typography>
									<span className={'time-settings-description'}>
										{TranslateText('personTimeSettings.timeSettingsDescription')}
									</span>
								</div>
								<FormControl component="fieldset" className={'justify-content-center'}>
									<RadioGroup
										row
										aria-label="position"
										name="position"
										defaultValue={defaultValue}
										onChange={(event: any) => {
											const value = event.target.value;
											if (value) {
												if (value === 'custom') {
													events.onValueChanged(false, 'useLocalSettings');
													setIsDisabled(false);
												} else if (value === 'localSetting') {
													events.onValueChanged(true, 'useLocalSettings');
													setIsDisabled(true);
												} else if (value === 'customerDefaults') {
													events.onValueChanged(null, 'useLocalSettings');
													setIsDisabled(true);
												}
											}
										}}
									>
										<FormControlLabel
											value="localSetting"
											control={<Radio size="small" color="default" />}
											label={TranslateText('personTimeSettings.localSetting')}
											labelPlacement="top"
											checked={data.useLocalSettings === true}
											className={'radio-option'}
										/>
										<FormControlLabel
											value="customerDefaults"
											control={<Radio size="small" color="default" />}
											label={TranslateText('personTimeSettings.customerDefaults')}
											labelPlacement="top"
											checked={
												data.useLocalSettings === null || data.useLocalSettings === undefined
											}
											className={'radio-option'}
										/>
										<FormControlLabel
											value="custom"
											control={<Radio size="small" color="default" />}
											label={TranslateText('personTimeSettings.custom')}
											labelPlacement="top"
											checked={data.useLocalSettings === false}
											className={'radio-option'}
										/>
									</RadioGroup>
								</FormControl>
							</div>
							<div className={'date-notation-div'}>
								<Divider className={'first-divider'} />
								<div className={'entity-notation-container'}>
									<div
										className={'entity-notation-settings'}
										style={{
											color: isDisabled ? '#999999' : 'black',
										}}
									>
										<Typography variant="h6" className={'date-notation-title'}>
											{TranslateText('personTimeSettings.dateNotation')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personTimeSettings.dateNotationDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										{data.useLocalSettings === false ? (
											<>
												<MaterialAutocomplete
													valueId={data?.dateFormat}
													dataSource={DateTimeConstants.dateFormat}
													name="dateFormat"
													onChange={({ value }) =>
														events.onValueChanged(value as string, 'dateFormat')
													}
													disabled={isDisabled}
													className={'autocomplete-option'}
												/>
												<ValidationMessage result={validationResult?.dateFormat} />
											</>
										) : (
											<TextField
												id="dateFormat"
												type="text"
												fullWidth
												name="dateFormat"
												value={
													data.useLocalSettings
														? TranslateText('fields.useLocalSettings')
														: TranslateText('customerOverview.useCustomerSettings')
												}
												disabled={true}
												inputProps={{
													style: textFieldStyle,
												}}
											/>
										)}
									</div>
								</div>
								<Divider className={'middle-divider'} />
								<div className={'entity-notation-container'}>
									<div
										className={'entity-notation-settings'}
										style={{
											color: isDisabled ? '#999999' : 'black',
										}}
									>
										<Typography variant="h6" className={'time-notation-title'}>
											{TranslateText('personTimeSettings.timeNotation')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personTimeSettings.timeNotationDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										{data.useLocalSettings === false ? (
											<>
												<MaterialAutocomplete
													valueId={data?.timeFormat}
													dataSource={DateTimeConstants.timeFormat}
													name="timeFormat"
													onChange={({ value }) =>
														events.onValueChanged(value as string, 'timeFormat')
													}
													disabled={isDisabled}
													className={'autocomplete-option'}
												/>
												<ValidationMessage result={validationResult?.timeFormat} />
											</>
										) : (
											<TextField
												id="timeFormat"
												type="text"
												fullWidth
												name="timeFormat"
												value={
													data.useLocalSettings
														? TranslateText('fields.useLocalSettings')
														: TranslateText('customerOverview.useCustomerSettings')
												}
												disabled={true}
												inputProps={{
													style: textFieldStyle,
												}}
											/>
										)}
									</div>
								</div>
								<Divider className={'middle-divider'} />
								<div className={'entity-notation-container'}>
									<div
										className={'entity-notation-settings'}
										style={{
											color: isDisabled ? '#999999' : 'black',
										}}
									>
										<Typography variant="h6" className={'week-notation-title'}>
											{TranslateText('personTimeSettings.weekNumbering')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personTimeSettings.weekNumberingDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										{data.useLocalSettings === false ? (
											<>
												<MaterialAutocomplete
													valueId={data?.calendarFormat}
													dataSource={DateTimeConstants.calendarFormat()}
													name="calendarFormat"
													onChange={({ value }) =>
														events.onValueChanged(value as string, 'calendarFormat')
													}
													disabled={isDisabled}
													className={'autocomplete-option'}
												/>
												<ValidationMessage result={validationResult?.calendarFormat} />
											</>
										) : (
											<TextField
												id="calendarFormat"
												type="text"
												fullWidth
												name="calendarFormat"
												value={
													data.useLocalSettings
														? TranslateText('fields.useLocalSettings')
														: TranslateText('customerOverview.useCustomerSettings')
												}
												disabled={true}
												inputProps={{
													style: textFieldStyle,
												}}
											/>
										)}
									</div>
								</div>
								<Divider className={'middle-divider'} />
								<div className={'entity-notation-container'}>
									<div
										className={'entity-notation-settings'}
										style={{
											color: isDisabled ? '#999999' : 'black',
										}}
									>
										<Typography variant="h6" className={'day-title'}>
											{TranslateText('personTimeSettings.firstDayOfTheWeek')}
										</Typography>
										<span className={'notation-description'}>
											{TranslateText('personTimeSettings.firstDayDescription')}
										</span>
									</div>
									<div className="form-group form-group-settings">
										{data.useLocalSettings === false ? (
											<>
												<MaterialAutocomplete
													valueId={data?.startingDay}
													dataSource={DateTimeConstants.startingDay()}
													name="startingDay"
													onChange={({ value }) =>
														events.onValueChanged(value as string, 'startingDay')
													}
													disabled={isDisabled}
													className={'autocomplete-option'}
												/>
												<ValidationMessage result={validationResult?.startingDay} />
											</>
										) : (
											<TextField
												id="startingDay"
												type="text"
												fullWidth
												name="startingDay"
												value={
													data.useLocalSettings
														? TranslateText('fields.useLocalSettings')
														: TranslateText('customerOverview.useCustomerSettings')
												}
												disabled={true}
												inputProps={{
													style: textFieldStyle,
												}}
											/>
										)}
									</div>
								</div>
								<Divider className={'last-divider'} />
							</div>
							<Button
								style={{
									backgroundColor: !isValid ? '#999999' : '#006837',
								}}
								className={'save-button'}
								disabled={!isValid}
								onClick={saveTimeSettings}
							>
								{TranslateText('common.buttonSave')}
							</Button>
							{isValid && (
								<span className={'info-text'}>
									{TranslateText('personSettingsWindow.changedAppliedAfterLogin')}
								</span>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		)
	);
};

export default PersonTimeSettings;
