import { Action } from 'redux';
import { Dispatch } from 'react';
import { ITreeNode } from '../components/SelectionTree/TreeNode/types';

export interface FilteredEntities {
	filteredEntities: ITreeNode[];
}

const initialState: FilteredEntities = {
	filteredEntities: [],
};

export interface IFilteredEntitiesActionsSignature {
	setTrackedEntities: (entitiesData: {}) => void;
	removeTrackedId: (trackedId: string) => void;
}

export interface IPayloadAction extends Action {
	payload: any;
}

export const filteredEntitiesActionCreators = {
	setTrackedEntities: (entitiesData: {}) => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({ type: 'SET-TRACKED-ENTITIES', payload: entitiesData });
	},
	removeTrackedId: (trackedId: string) => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({ type: 'REMOVE-TRACKED-ID', payload: trackedId });
	},
};

export const filteredEntitiesReducer = (state: FilteredEntities, action: IPayloadAction) => {
	state = state || initialState;

	switch (action.type) {
		case 'SET-TRACKED-ENTITIES':
			return { ...state, filteredEntities: action.payload };
		case 'REMOVE-TRACKED-ID':
			return {
				...state,
				filteredEntities: state.filteredEntities.filter((fe: ITreeNode) => fe.id !== action.payload),
			};
		default:
			return state;
	}
};
