import React, { useEffect, useRef, useState } from 'react';

import { IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

type Props = {
	value: string;
	placeHolder?: string;
	onChange: (newValue: string) => void;
	debounceDuration: number;
	showClearButton?: boolean;
	label?: string;
	type?: string;
	fullWidth?: boolean;
	hasStyles?: boolean;
	style?: any;
	disabled?: boolean;
};

const AutoTextField = (props: Props) => {
	const [value, setValue] = useState<string>(props.value || '');
	const ref = useRef({
		lastData: props.value,
		timeoutId: 0,
		cancel: false,
		clear: () => {
			if (ref.current.timeoutId) {
				clearTimeout(ref.current.timeoutId);
			}
			ref.current.timeoutId = 0;
		},
	});

	useEffect(() => {
		ref.current.clear();
		ref.current.timeoutId = setTimeout(() => {
			if (value !== props.value) {
				props.onChange(value);
			}
		}, props.debounceDuration) as any;
	}, [value]);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	return (
		<TextField
			disabled={props.disabled}
			value={value}
			placeholder={props.placeHolder}
			onChange={(e) => {
				setValue(e.target.value);
			}}
			onKeyUp={(e) => {
				if (e.key === 'Enter') {
					ref.current.clear();
					props.onChange(value);
				}
			}}
			onBlur={() => {
				ref.current.clear();
				props.onChange(value);
			}}
			InputProps={{
				endAdornment: props.showClearButton ? (
					<IconButton
						className="close-btn"
						hidden={!value}
						size={'small'}
						onClick={() => {
							ref.current.clear();
							props.onChange('');
						}}
					>
						<Clear fontSize={'small'} />
					</IconButton>
				) : null,
				style: props?.hasStyles ? props?.style : null,
			}}
			label={props?.label}
			type={props?.type}
			fullWidth={props?.fullWidth}
		/>
	);
};
AutoTextField.defaultProps = {
	debounceDuration: 1000,
	disabled: false,
};

export default AutoTextField;
