import './styles.scss';
import React from 'react';
import { Button } from '@material-ui/core';
import { enableRipple } from '@syncfusion/ej2-base';
import { DetailsNavigationBarProps } from './types';
import { useDetailNavigation } from './hooks';

enableRipple(true);

const DetailsNavigationBar = (props: DetailsNavigationBarProps) => {
	const { navigation, navigate } = useDetailNavigation(props);
	return (
		<ul id="dashboardHeader" className="dashboard-header">
			<Button
				className="e-flat back-to-button back-to-icon"
				onClick={() => props.goBackToCallback(false, { id: props.currentRecordId }, true)}
			>
				{props.goBackText}
			</Button>
			<div className="search-bar-separator" />
			<div className="breadcrumb">{props.breadcrumbsText} </div>
			{navigation && navigation.index >= 0 ? (
				<div style={{ float: 'right' }}>
					<Button
						disabled={!navigation.previous}
						className="navigation-button navigation-before-button navigate-before"
						onClick={() => navigate(navigation.previous, navigation.previousEntityType)}
					/>
					<div className="record-position">{`${navigation.index + 1} / ${navigation.total}`}</div>
					<Button
						disabled={!navigation.next}
						className="navigation-button navigation-next-button navigate-next"
						onClick={() => navigate(navigation.next, navigation.nextEntityType)}
					/>
				</div>
			) : null}
		</ul>
	);
};

export default DetailsNavigationBar;
