import { Icon, IconButton } from '@material-ui/core';
import { Column, ColumnBodyType } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React from 'react';

type Props = {
	fieldName: string;
	header: string;
	body?: ColumnBodyType;
	sortable?: boolean;
	filterable?: boolean;
	leftPadding?: boolean;
	shown?: boolean;
	showHeaderTooltip?: boolean;
	showFilterTooltip?: boolean;
	showContentTooltip?: boolean;
	fixedWidth?: boolean;
	filterDisabled?: boolean;
	defaultWidth?: number;
};

const TextColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	leftPadding = true,
	shown = true,
	showHeaderTooltip = true,
	showFilterTooltip = true,
	showContentTooltip = true,
	fixedWidth = false,
	filterDisabled,
	defaultWidth,
}: Props) =>
	shown ? (
		<Column
			sortableDisabled={filterDisabled}
			className={`sortIcon ${leftPadding ? 'leftPadding' : ''} minWidth ${fixedWidth ? 'fixedWidth' : ''} ${
				filterDisabled ? 'disabled' : ''
			}`}
			style={
				defaultWidth !== undefined
					? {
							flexGrow: '1',
							flexShrink: '1',
							flexBasis: `${defaultWidth}px`,
					  }
					: null
			}
			key={fieldName}
			field={fieldName}
			header={() => (
				<span
					className={`p-column-title ${filterDisabled ? 'disabled' : ''}`}
					title={showHeaderTooltip ? header : ''}
				>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) => (
							<span title={showContentTooltip ? rowData[fieldName] : ''}>{rowData[fieldName]}</span>
					  )
			}
			sortable={sortable}
			showClearButton={false}
			showFilterMenu={false}
			filter={filterable}
			filterElement={(options) => {
				return (
					<span className="p-input-icon-right" title={showFilterTooltip ? options.value : ''}>
						<InputText
							value={options.value}
							onChange={(e) => options.filterApplyCallback(e.target.value)}
							disabled={filterDisabled}
						/>
						<IconButton
							hidden={!options.value || filterDisabled}
							onClick={() => options.filterApplyCallback('')}
							size="small"
							className="inputClearButton"
						>
							<Icon>close</Icon>
						</IconButton>
					</span>
				);
			}}
		/>
	) : null;

export default TextColumn;
