import './customerLiveSettings.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import EntityGroupingEnum from 'models/EntityGroupingEnum';
import LabelLayoutEnum from 'models/LabelLayoutEnum';
import LocationOnMapEnum from 'models/LocationOnMapEnum';
import ShowEntityIconEnum from 'models/ShowEntityIconEnum';
import UpdateIntervalEnum from 'models/UpdateIntervalEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

type Props = WidgetViewDefault & {
	data: LiveSettings;
};

export interface LiveSettings {
	showLabels: boolean;
	trafficInformation: boolean;
	showPOILocations: boolean;
	showHomeLocations: boolean;
	entityGrouping: EntityGroupingEnum;
	labelLayout: LabelLayoutEnum;
	showEntityIcon: ShowEntityIconEnum;
	updateInterval: UpdateIntervalEnum;
	useLiveLocationFields: boolean;
	useLiveLocationOnMap: LocationOnMapEnum;
}

type ChangeValue =
	| EntityGroupingEnum
	| LabelLayoutEnum
	| ShowEntityIconEnum
	| UpdateIntervalEnum
	| LocationOnMapEnum
	| boolean;

type ChangeKey =
	| 'showLabels'
	| 'trafficInformation'
	| 'showPOILocations'
	| 'showHomeLocations'
	| 'entityGrouping'
	| 'labelLayout'
	| 'showEntityIcon'
	| 'updateInterval'
	| 'useLiveLocationFields'
	| 'useLiveLocationOnMap';

const getYesNoDataSource = () => {
	return [
		{ id: true, display: TranslateText('common.yes') },
		{ id: false, display: TranslateText('common.no') },
	];
};

export const getConstants = () => {
	return {
		showLabels: getYesNoDataSource(),
		trafficInformation: getYesNoDataSource(),
		showPOILocations: getYesNoDataSource(),
		showHomeLocations: getYesNoDataSource(),
		useLiveLocationFields: getYesNoDataSource(),
		entityGrouping: [
			{ id: 0, display: TranslateText('customerOverview.grouping.noGrouping') },
			{ id: 1, display: TranslateText('customerOverview.grouping.circle') },
		],
		labelLayout: [
			{ id: 0, display: TranslateText('common.default') },
			{ id: 1, display: TranslateText('common.small') },
		],
		showEntityIcon: [
			{ id: 0, display: TranslateText('customerOverview.entityIcon.dot') },
			{ id: 1, display: TranslateText('customerOverview.entityIcon.icon') },
		],
		updateInterval: [
			{ id: 0, display: TranslateText('customerOverview.updateInterval.default') },
			{ id: 1, display: TranslateText('customerOverview.updateInterval.everyOneMinutes') },
			{ id: 2, display: TranslateText('customerOverview.updateInterval.everyTwoMinutes') },
			{ id: 3, display: TranslateText('customerOverview.updateInterval.everyFiveMinutes') },
		],
		useLiveLocationOnMap: [
			{ id: 0, display: TranslateText('customerOverview.locationOnMap.dontShow') },
			{ id: 1, display: TranslateText('customerOverview.locationOnMap.icon') },
			{ id: 2, display: TranslateText('customerOverview.locationOnMap.iconLabel') },
		],
		defaultValues: {
			showLabels: true,
			trafficInformation: false,
			showPOILocations: false,
			showHomeLocations: false,
			useLiveLocationFields: true,
			entityGrouping: EntityGroupingEnum.Circle,
			labelLayout: LabelLayoutEnum.Default,
			showEntityIcon: ShowEntityIconEnum.Dot,
			updateInterval: UpdateIntervalEnum.Default,
			useLiveLocationOnMap: LocationOnMapEnum.Icon,
		} as LiveSettings,
		fieldsConfig: { text: 'display', value: 'id' },
	};
};

const GetCustomerLiveSettingsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		showLabels: {
			rules: { required: ValidatorFunctions.required() },
		},
		trafficInformation: {
			rules: { required: ValidatorFunctions.required() },
		},
		showPOILocations: {
			rules: { required: ValidatorFunctions.required() },
		},
		showHomeLocations: {
			rules: { required: ValidatorFunctions.required() },
		},
		entityGrouping: {
			rules: { required: ValidatorFunctions.required() },
		},
		labelLayout: {
			rules: { required: ValidatorFunctions.required() },
		},
		showEntityIcon: {
			rules: { required: ValidatorFunctions.required() },
		},
		updateInterval: {
			rules: { required: ValidatorFunctions.required() },
		},
		useLiveLocationFields: {
			rules: {
				required: ValidatorFunctions.requiredWithNull(),
			},
		},
		useLiveLocationOnMap: {
			rules: {
				required: ValidatorFunctions.requiredWithNull(),
			},
		},
	};

	return fieldRules;
};

const CustomerLiveSettingsView = (props: Props) => {
	const [constants] = useState(getConstants);
	const customerLocationsFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.locations
			: s.currentSession.customer?.featuresSettings.locations
	);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetCustomerLiveSettingsValidationConfig() }));
	}, [props.entityId]);

	useEffect(() => {
		if (!customerLocationsFeature) {
			const newState = { ...props.data };
			newState.useLiveLocationFields = true;
			newState.useLiveLocationOnMap = LocationOnMapEnum.Icon;
			props.changeDataCallback(newState);
		}
	}, [customerLocationsFeature]);

	const events = {
		onValueChanged: async (value: ChangeValue, key: ChangeKey) => {
			const newState = { ...props.data };
			(newState[key] as ChangeValue) = value;

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper live-form'}>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.showLabels}
					dataSource={constants.showLabels}
					name="showLabels"
					disabled={!props.editMode}
					label={TranslateText('fields.showLabels')}
					onChange={({ value }) => events.onValueChanged(value as boolean, 'showLabels')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.showLabels} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.trafficInformation}
					dataSource={constants.trafficInformation}
					name="trafficInformation"
					disabled={!props.editMode}
					label={TranslateText('fields.trafficInformation')}
					onChange={({ value }) => events.onValueChanged(value as boolean, 'trafficInformation')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.trafficInformation} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.showPOILocations}
					dataSource={constants.showPOILocations}
					name="showPOILocations"
					disabled={!props.editMode}
					label={TranslateText('fields.showPOILocations')}
					onChange={({ value }) => events.onValueChanged(value as boolean, 'showPOILocations')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.showPOILocations} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.showHomeLocations}
					dataSource={constants.showHomeLocations}
					name="showHomeLocations"
					disabled={!props.editMode}
					label={TranslateText('fields.showHomeLocations')}
					onChange={({ value }) => events.onValueChanged(value as boolean, 'showHomeLocations')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.showHomeLocations} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.entityGrouping}
					dataSource={constants.entityGrouping}
					name="entityGrouping"
					disabled={!props.editMode}
					label={TranslateText('fields.entityGrouping')}
					onChange={({ value }) => events.onValueChanged(value as EntityGroupingEnum, 'entityGrouping')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.entityGrouping} />
				<div className="e-text">{TranslateText('fields.groupingExample')}</div>
				<div
					id="noGrouping"
					className={
						props.data.entityGrouping === EntityGroupingEnum.NoGrouping ? 'no-grouping' : 'circle-grouping'
					}
				/>
			</div>
			{/* hidden based on https://dev.azure.com/RietveldTruckRendementService/EasyTrack2.0/_workitems/edit/26861
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.labelLayout}
					dataSource={constants.labelLayout}
					name="labelLayout"
					disabled={!props.editMode}
					label={TranslateText('fields.labelLayout')}
					onChange={({ value }) => events.onValueChanged(value as LabelLayoutEnum, 'labelLayout')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.labelLayout} />
				<div className="e-text">{TranslateText('fields.labelExample')}</div>
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.showEntityIcon}
					dataSource={constants.showEntityIcon}
					name="showEntityIcon"
					disabled={!props.editMode}
					label={TranslateText('fields.showEntityIcon')}
					onChange={({ value }) => events.onValueChanged(value as ShowEntityIconEnum, 'showEntityIcon')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.showEntityIcon} />
			</div>
			*/}
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.updateInterval}
					dataSource={constants.updateInterval}
					name="updateInterval"
					disabled={!props.editMode}
					label={TranslateText('fields.updateInterval')}
					onChange={({ value }) => events.onValueChanged(value as UpdateIntervalEnum, 'updateInterval')}
					disableClearable
				/>
				<ValidationMessage result={props.validationResult?.updateInterval} />
			</div>
			{customerLocationsFeature ? (
				<>
					<YesNoCombobox
						name={'useLiveLocationFields'}
						value={props.data.useLiveLocationFields}
						placeholder={TranslateText('fields.useLocationFields')}
						change={(value) => events.onValueChanged(value as boolean | null, 'useLiveLocationFields')}
						readonly={!props.editMode}
						validationResult={props.validationResult}
					/>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data.useLiveLocationOnMap}
							dataSource={constants.useLiveLocationOnMap}
							name="useLiveLocationOnMap"
							disabled={!props.editMode}
							label={TranslateText('fields.useLocationOnMap')}
							onChange={({ value }) =>
								events.onValueChanged(value as LocationOnMapEnum, 'useLiveLocationOnMap')
							}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.useLiveLocationOnMap} />
					</div>
				</>
			) : null}
		</div>
	);
};

export default CustomerLiveSettingsView;
