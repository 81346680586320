import './objectNameWithTimeZoneOffset.scss';

import React from 'react';
import { Tooltip } from '@material-ui/core';
import { extractObjectName, extractTimeZone } from './Utils';

type Props = {
	objectNameWithTimeZone: string;
	useToolTip: boolean;
};

const ObjectNameWithTimeZoneOffset = ({ objectNameWithTimeZone, useToolTip }: Props) => {
	const objectName = extractObjectName(objectNameWithTimeZone);
	const timeZoneOffset = extractTimeZone(objectNameWithTimeZone);

	return (
		<span className="object-name-with-time-zone-offset">
			{useToolTip ? (
				<Tooltip title={objectName}>
					<span className="object-name">{objectName}</span>
				</Tooltip>
			) : (
				<span className="object-name">{objectName}</span>
			)}
			<span className="time-offset">{timeZoneOffset}</span>
		</span>
	);
};

export default React.memo(ObjectNameWithTimeZoneOffset);
