import './LocationSection.scss'

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import LocationView from "../../NewAddWizard/Views/Location/LocationView";
import Location from "../../../models/Location";
import AddressView from "../../NewAddWizard/Views/Address/AddressView";
import {PolygonDefinition} from "../../LocationsAreaMap/types";
import {LocationAreaTypeEnum} from "../../LocationsOverview/LocationsOverview";
import {TranslateText} from "../../../utils/Translations";
import {DebouncedButton} from "../../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../../Constants";
import { Button } from '@material-ui/core';
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from "../../../GlobalSettings.json";
import {ApplicationState} from "../../../store";
import {LatLng} from "../../../store/LiveMap";



interface Props {
	visible: boolean;
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
	closeDialog: () => void;
	closeAfterSave: () => void;
	selectedEntityId?: string;
	isForAdd: boolean;
	setShowMessage: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	latLngAddress?: LatLng;
	startDateFromTrip?: Date;
	startDateFromTripString?: string;
}
const LocationSection = (props: Props) => {

	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);

	const [selectedEntity, setSelectedEntity] = useState(null);
	const [entityData, setEntityData] = useState(null);
	const [isLocationValid, setIsLocationValid] = useState<boolean>(true);
	const [isAddressValid, setIsAddressValid] = useState<boolean>(true);
	const [disabled, setDisabled] = useState<boolean>(false);
	const [previousAreaType, setPreviousAreaType] = useState<LocationAreaTypeEnum>(null);
	const [showMessageChanges, setShowMessageChanges] = useState(false);
	const [startDateString, setStartDateString] = useState(props.startDateFromTripString);

	const [initialPolygon, setInitialPolygon] = useState<PolygonDefinition | null>(() => {
		const location = entityData as Location;
		if (
			location?.areaType === LocationAreaTypeEnum.Polygon &&
			location.polygonPath &&
			location.polygonPath.length >= 3 &&
			location.latitude &&
			location.longitude
		) {
			return {
				center: { lat: location.latitude, lng: location.longitude },
				path: location.polygonPath,
			};
		}
		return null;
	});

	const saveButtonDisabled =  disabled || !isLocationValid || !isAddressValid || !props.unsavedData;

	useEffect(() => {
		if(props.isForAdd === false && props.selectedEntityId !== null)
		{
			getLocation();
		}
	},[props.selectedEntityId])

	const onChangeCallback = useCallback(
		(data: any) => {
			setShowMessageChanges(true);
			setEntityData((prevState: any) => {
				if(prevState?.areaType !== data?.areaType)
					setPreviousAreaType(prevState?.areaType);
				return ({...prevState, ...data});
			});
		},
		[]
	);

	const clearSelectedEntity = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		props.closeDialog();
	};

	const saveFormCallback = (e: any) => {
		setDisabled(true);
		props.setIsLoading(true);
		ajaxUtil.post<object>(GlobalSettings.locationsMaintenanceApi + '/addLocation', {
			AddModel: {
				...entityData, startDateString: startDateString
			},
			GlobalCustomer: customerId,
			FilterText: ''
		})
			.then((result) => {
				setDisabled(false);
				props.setIsLoading(false);
				if(result) {
					props.setUnsavedData(false);
					e.preventDefault();
					e.stopPropagation();
					props.setShowMessage(true);
				}
			})
			.catch(() => {
				setDisabled(false);
				props.setIsLoading(false);
			});
	};

	const updateFormCallback = (e: any) => {
		setDisabled(true);
		props.setIsLoading(true);
		ajaxUtil
			.put(`${GlobalSettings.locationsMaintenanceApi}/updateLocation`, {
				...entityData,
			})
			.then((result) => {
				setDisabled(false);
				props.setIsLoading(false);
				if (result) {
					props.setUnsavedData(false);
					ajaxUtil
						.get<Location>(`${GlobalSettings.locationsMaintenanceApi}/${props.selectedEntityId}`)
						.then((location) => {
							setSelectedEntity(location);
							setEntityData(location);
							if (location.areaType === LocationAreaTypeEnum.Polygon) {
								setInitialPolygon({
									center: { lat: location.latitude, lng: location.longitude },
									path: location.polygonPath,
								});
							} else {
								setInitialPolygon(null);
							}
						});
				}
			})
			.catch(() => {
				setDisabled(false);
				props.setIsLoading(false);
			});
	}

	const clickOnSave = (e: any) => {
		if(props.isForAdd)
			saveFormCallback(e);
		else
			updateFormCallback(e);
	}

	const getLocation = () => {
		props.setIsLoading(true);
		ajaxUtil
			.get<object>(`${GlobalSettings.locationsMaintenanceApi}/${props.selectedEntityId}`)
			.then((data) => {
				const location = data as Location;
				setSelectedEntity(location);
				setEntityData(location);
				if (location.areaType === LocationAreaTypeEnum.Polygon) {
					setInitialPolygon({
						center: { lat: location.latitude, lng: location.longitude },
						path: location.polygonPath,
					});
				} else {
					setInitialPolygon(null);
				}
				props.setIsLoading(false);
				setPreviousAreaType(location.areaType);
				setShowMessageChanges(false);
			})
			.catch(() => {
				props.setIsLoading(false);
				setSelectedEntity(null);
				setEntityData({});
			});
	}

	return(
	<div>
		<div className={'view-container'}>
			{props.isForAdd ||
			((entityData?.areaType === LocationAreaTypeEnum.Polygon) || (previousAreaType === LocationAreaTypeEnum.Circle)) ||
			entityData?.areaType === LocationAreaTypeEnum.Circle
			 ? <div className={'location-view-container'}>
				<div className={'location-container'} style={{ marginRight: 50, minHeight: 270, marginTop: 17 }}>
					<LocationView
						setValidationCallback={(isValid) => setIsLocationValid(isValid)}
						onChangeCallback={onChangeCallback}
						visible={true}
						location={selectedEntity as Location}
						readOnly={false}
						setUnsavedDataCallback={props.setUnsavedData}
						isForDialog={true}
						startDateFromTrip={props.startDateFromTrip}
						setStartDateStringFromTripCallback={setStartDateString}
					/>
				</div>
				<div className={'address-container'} style={{minHeight: 415}}>
					<AddressView
						setValidationCallback={(isValid) => setIsAddressValid(isValid)}
						onChangeCallback={onChangeCallback}
						visible={true}
						location={selectedEntity}
						initialPolygon={initialPolygon}
						readOnly={false}
						setUnsavedDataCallback={props?.setUnsavedData}
						isForDialog={true}
						latLngFromDialog={props.latLngAddress}
					/>
				</div>
				<div className={'footer right-container'}>
					<Button
						style={{ marginRight: 35 }}
						className={'button cancel-button'}
						onClick={(e) => clearSelectedEntity(e)}
					>
						{TranslateText('common.buttonCancel')}
					</Button>
					{!props.isForAdd  && showMessageChanges &&  props.visible && (
						<span className={'info-text'}>{TranslateText('tripDetailsSection.locationDialog.locationMessage')}</span>
					)}
					<DebouncedButton
						className={'button save-button'}
						disabled={saveButtonDisabled}
						onClick={(e) => clickOnSave(e)}
						debounceTime={DEBOUNCE_TIME}
					>
						{TranslateText('common.buttonSave')}
					</DebouncedButton>
				</div>
			</div>
			: null}
		</div>
	</div>
	);
}
export default LocationSection;
