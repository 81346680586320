import './LiveMap.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { SET_FOCUS_ON_CHANGE } from 'store/HistoryStore';

import FeatureUnavailable from '../FeatureUnavailable';
import { HistoryDetailsTabs } from '../RightSidebarMenu/ExtendedMenu/HistoryDetailsTabs';
import RietveldMap from './RietveldMap';

const HistoryMap = () => {
	const hasMap = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.map
			: state.currentSession.customer?.featuresSettings.map
	);
	const historyTrips = useSelector((state: ApplicationState) => state.historyStore.historyTrips);
	const templates = useSelector((state: ApplicationState) => state.historyStore.templates);

	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			dispatch({
				type: SET_FOCUS_ON_CHANGE,
				payload: false,
			});
		};
	}, []);

	return (
		<div className={'map'}>
			{hasMap ? (
				<>
					<RietveldMap markers={[]} tripConfig={historyTrips} templates={templates} isLiveMap={false} />
					<HistoryDetailsTabs />
				</>
			) : (
				<FeatureUnavailable />
			)}
		</div>
	);
};

export default HistoryMap;
