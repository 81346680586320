import React from 'react';
import { IconButton } from '@material-ui/core';
import ajaxUtil from '../../utils/Ajax';
import GlobalSettings from '../../GlobalSettings.json';
import PrivateUserIcon from '../Common/Icons/PrivateUserIcon';
import { PersonsOverviewImpersonationProps } from './types';
import './styles.scss';

const ImpersonationButton = (props: PersonsOverviewImpersonationProps) => {
	return (
		<span key={props.rowData.id + 'impersonationButton'} className={'impersonation-button'}>
			<IconButton
				size="small"
				disabled={!props.rowData.canImpersonate}
				onClick={(event) => {
					event.stopPropagation();
					ajaxUtil
						.post<{ impersonationToken: string }>(
							`${GlobalSettings.personsMaintenanceApi}/${props.rowData.id}/GetImpersonationToken`,
							{}
						)
						.then((data) => {
							props.setDialogData({
								firstName: props.rowData.firstName,
								lastName: props.rowData.lastName,
								impersonationLink: `${process.env.REACT_APP_BASE_URL}/impersonate?token=${data.impersonationToken}`,
							});
						});
				}}
			>
				<PrivateUserIcon />
			</IconButton>
		</span>
	);
};

export default ImpersonationButton;
