import './style.scss';

import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import DateColumn from 'components/GridOverview/Columns/DateColumn';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from 'components/GridOverview/Columns/MultiSelectionColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import GlobalSettings from '../../GlobalSettings.json';
import AlertTypesEnum from '../../models/AlertTypesEnum';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { ApplicationState } from '../../store';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';
import { useAlertNotificationClaims } from '../Views/AlertNotificationTypes/hooks';

interface Props {
	history: History<LocationState>;
}

interface AlertOverviewDto extends GridEntity {
	code: string;
	name: string;
	type: AlertTypesEnum;
	lastTrigger?: Date;
	watchedEntitiesCount: string;
	recipientsCount: string;
	useNotification: boolean;
	usePopup: boolean;
	useEmail: boolean;
	useSms: boolean;
	useWhatsApp: boolean;
	active: boolean;
}

const fieldNames = {
	code: 'code',
	name: 'name',
	watchedEntitiesCount: 'watchedEntitiesCount',
	recipientsCount: 'recipientsCount',
	type: 'type',
	lastTrigger: 'lastTrigger',
	alertNotification: 'alertNotification',
	alertMail: 'alertMail',
	alertSms: 'alertSms',
	alertChat: 'alertChat',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.watchedEntitiesCount]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.recipientsCount]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.type]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.lastTrigger]: {
			value: null,
			matchMode: MatchMode.DateIs,
		},
		[fieldNames.alertNotification]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.alertMail]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.alertSms]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.alertChat]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

const activeColor = '#2daf4b';
const disabledColor = '#c6c6c6';

const AlertOverview = (props: Props) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);

	const tripTypes = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: state.currentSession.customer.featuresSettings.tripTypes
	);

	const allowTrackTypeSpecification = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: state.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const templates = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.templates
			: state.currentSession.customer.featuresSettings.templates
	);

	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const geoFence = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.geoFence
			: state.currentSession.customer.featuresSettings.geoFence
	);

	const { alertClaims } = useAlertNotificationClaims();

	const alertSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: '',
				code: AlertTypesEnum.Unknown.toString(),
				hidden: true,
			},
			{
				name: TranslateText('eventType.privateMileage'),
				code: AlertTypesEnum.PrivateMileage.toString(),
			},
			{
				name: TranslateText('eventType.ignition'),
				code: AlertTypesEnum.Ignition.toString(),
			},
			{
				name: TranslateText('eventType.unknownKey'),
				code: AlertTypesEnum.UnknownKey.toString(),
			},
			{
				name: TranslateText('eventType.unauthorizedDrive'),
				code: AlertTypesEnum.UnauthorizedDrive.toString(),
			},
			{
				name: TranslateText('eventType.drivingWithoutAuthentication'),
				code: AlertTypesEnum.DrivingWithoutAuthentication.toString(),
			},
			{
				name: TranslateText('eventType.durationExceeded'),
				code: AlertTypesEnum.DurationExceeded.toString(),
			},
			{
				name: TranslateText('eventType.analog'),
				code: AlertTypesEnum.Analog.toString(),
			},
			{
				name: TranslateText('eventType.digital'),
				code: AlertTypesEnum.Digital.toString(),
			},
			{
				name: TranslateText('eventType.geofence'),
				code: AlertTypesEnum.Geofence.toString(),
			},
			{
				name: TranslateText('eventType.mainPower'),
				code: AlertTypesEnum.MainPower.toString(),
			},
			{
				name: TranslateText('eventType.towing'),
				code: AlertTypesEnum.Towing.toString(),
			}
		],
		[]
	);

	const getAlertSelectOptions = () => {
		let alertTypeDatasource = alertSelectOptions;
		if (!allowTrackTypeSpecification || !(tripTypes & TripTypesEnum.Private)) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) => alertType.code !== AlertTypesEnum.PrivateMileage.toString()
			);
		}

		if (!templates) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.code !== AlertTypesEnum.DurationExceeded.toString() &&
					alertType.code !== AlertTypesEnum.Analog.toString() &&
					alertType.code !== AlertTypesEnum.Digital.toString()
			);
		}

		if (!driverIdentification) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.code !== AlertTypesEnum.PrivateMileage.toString() &&
					alertType.code !== AlertTypesEnum.UnknownKey.toString() &&
					alertType.code !== AlertTypesEnum.UnauthorizedDrive.toString() &&
					alertType.code !== AlertTypesEnum.DrivingWithoutAuthentication.toString()
			);
		}

		if (!geoFence) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) => alertType.code !== AlertTypesEnum.Geofence.toString()
			);
		}

		return alertTypeDatasource;
	};
	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.type]: computeFlagOrderingBasedOnTranslation(
				!!(tripTypes & TripTypesEnum.Private)
					? alertSelectOptions
					: alertSelectOptions.filter((x) => x.code != AlertTypesEnum.PrivateMileage.toString())
			),
		}),
		[]
	);

	const columns = [
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.watchedEntitiesCount,
			header: TranslateText('maintenanceOverview.grid.colConnectionsCount'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.recipientsCount,
			header: TranslateText('maintenanceOverview.grid.colPersons'),
			sortable: true,
			fixedWidth: true,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.type,
			header: TranslateText('maintenanceOverview.grid.colAlertEvent'),
			sortable: true,
			filterable: true,
			body: (alert: AlertOverviewDto) => {
				const alertType = TranslateText(`alertOverview.alertType.${AlertTypesEnum[alert.type]}`);
				return <span title={alertType}>{alertType}</span>;
			},
			selectOptions: getAlertSelectOptions(),
		}),
		DateColumn({
			fieldName: fieldNames.lastTrigger,
			header: TranslateText('maintenanceOverview.grid.colLastTrigger'),
			sortable: true,
			filterable: true,
			formatDateFunction: FormatDate,
		}),
		BooleanColumn({
			fieldName: fieldNames.alertNotification,
			header: TranslateText('maintenanceOverview.grid.colAlertNotification'),
			sortable: true,
			filterable: true,
			shown: alertClaims.notificationAlert,
			body: (alert: AlertOverviewDto) => (
				<Icon style={{ color: alert.useNotification ? activeColor : disabledColor }}>chat</Icon>
			),
		}),
		BooleanColumn({
			fieldName: fieldNames.alertMail,
			header: TranslateText('maintenanceOverview.grid.colAlertMail'),
			sortable: true,
			filterable: true,
			shown: alertClaims.mailAlert,
			body: (alert: AlertOverviewDto) => (
				<Icon style={{ color: alert.useEmail ? activeColor : disabledColor }}>mail_outline</Icon>
			),
		}),
		BooleanColumn({
			fieldName: fieldNames.alertSms,
			header: TranslateText('maintenanceOverview.grid.colAlertSms'),
			sortable: true,
			filterable: true,
			shown: alertClaims.smsAlert,
			body: (alert: AlertOverviewDto) => (
				<Icon style={{ color: alert.useSms ? activeColor : disabledColor }}>sms</Icon>
			),
		}),
		BooleanColumn({
			fieldName: fieldNames.alertChat,
			header: TranslateText('maintenanceOverview.grid.colAlertWhatsApp'),
			sortable: true,
			filterable: true,
			shown: alertClaims.whatsAppAlert,
			body: (alert: AlertOverviewDto) => (
				<Icon style={{ color: alert.useWhatsApp ? activeColor : disabledColor }}>whatsapp</Icon>
			),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			useNewAddWizard={true}
			addEntityUrl={GlobalSettings.alertsApi}
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.alertsApi}
			headerText={TranslateText('maintenanceOverview.alerts')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default}
			showAddConfigClaim={[
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Alert}
			columnFlagOrder={columnFlagOrdering}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};

export { AlertOverview };
