import React from 'react';

import { DateFormatOptions } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { IEditCell } from '@syncfusion/ej2-react-grids';

import { TranslateText } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog/NotificationDialog';

class BaseWidget<P = {}, S = {}, SS = any> extends React.PureComponent<P, S, SS> {
	instance: string;

	// add here common behavior, data for the widgets

	constructor(props: any) {
		super(props);

		this.id = props.id;
		this.instance = (Math.random() * 100000000000000000).toString();
	}

	editButtonRef: ButtonComponent;
	// uniquely identifies the widget instance
	public id: string;

	//signals to hosting components that is has data that may be lost
	public hasUnsavedData = false;

	public dateFormatOptions: DateFormatOptions = {
		type: 'dateTime',
		format: 'MM/dd/yyyy hh:mm:ss a',
	};
	public dateTimePickerParams: IEditCell = {
		params: { format: 'MM/dd/yyyy hh:mm:ss a' },
	};

	toggleEditButton(active: boolean) {
		if (this.editButtonRef !== undefined) {
			if (active && this.editButtonRef) {
				this.editButtonRef.cssClass += ' e-active';
			} else if (this.editButtonRef) {
				this.editButtonRef.cssClass = this.editButtonRef.cssClass.replace(' e-active', '');
			}
		}
	}

	closeEditPanelMode() {
		// override it in derived classes
	}

	reloadData() {
		// override it in derived classes
	}

	public util = {
		confirm: async (title: string, content: string) => {
			return await DialogUtil.confirm({
				title,
				content,
			});
		},
		cancelConfirmation: async () => {
			return await this.util.confirm(
				TranslateText('common.titleUnsavedData'),
				TranslateText('notificationMessages.cancel')
			);
		},
		saveConfirmation: async (
			title = TranslateText('common.titleSavedData'),
			content = TranslateText('notificationMessages.save')
		) => {
			return await this.util.confirm(title, content);
		},
	};
}

export default BaseWidget;
