import { TextField } from '@material-ui/core';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useEffect } from 'react';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

import YesNoCombobox from './components/YesNoCombobox';
import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: VisualSettings;
	//Any other custom props
};

interface VisualSettings {
	defaultLines: number;
	saveFleetSelection: boolean;
	saveTrackedFleetSelection: boolean;
	canSaveTrackedFleetSelection: boolean;
	canSaveFleetSelection: boolean;
}

const GetCustomerVisualSettingsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		defaultLines: {
			rules: {
				required: ValidatorFunctions.required(),
				minValue: ValidatorFunctions.minNumber(10),
				maxValue: ValidatorFunctions.maxNumber(999),
			},
		},
		saveFleetSelection: {
			rules: { required: ValidatorFunctions.required() },
		},
		saveTrackedFleetSelection: {
			rules: { required: ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const CustomerVisualSettingsView = (props: Props) => {
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetCustomerVisualSettingsValidationConfig() }));
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | number | boolean, key: keyof VisualSettings) => {
			const newState = { ...props.data };
			(newState[key] as any) = value;
			if (key === 'saveFleetSelection' && !value) {
				newState.saveTrackedFleetSelection = false;
			}

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className="view-section-wrapper">
			<div className="form-group">
				<TextField
					id="defaultLines"
					type="number"
					className="resize-font"
					fullWidth
					label={TranslateText('fields.defaultLines')}
					InputProps={{
						inputProps: {
							min: 10,
							max: 999,
							readOnly: !props.editMode,
							style: { fontSize: 12 },
						},
					}}
					name="defaultLines"
					value={props.data.defaultLines}
					onChange={(e: any) => events.onValueChanged(e.target.value, 'defaultLines')}
					disabled={!props.editMode}
				/>
				<ValidationMessage result={props.validationResult?.defaultLines} />
			</div>
			<YesNoCombobox
				name={'saveFleetSelection'}
				value={props.data.saveFleetSelection}
				placeholder={TranslateText('fields.saveFleetSelection')}
				change={(value) => events.onValueChanged(value as boolean, 'saveFleetSelection')}
				readonly={!props.editMode || !props.data.canSaveFleetSelection}
				validationResult={props.validationResult}
			/>
			<YesNoCombobox
				name={'saveTrackedFleetSelection'}
				value={props.data.saveTrackedFleetSelection}
				placeholder={TranslateText('fields.saveTrackedFleetSelection')}
				change={(value) => events.onValueChanged(value as boolean, 'saveTrackedFleetSelection')}
				readonly={!props.editMode || !props.data.saveFleetSelection || !props.data.canSaveTrackedFleetSelection}
				validationResult={props.validationResult}
			/>
		</div>
	);
};

export default CustomerVisualSettingsView;
