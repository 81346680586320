import './oldWidget.scss';

import { debounce } from 'lodash';
import React from 'react';

import { Button } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import { Person } from '../../models/Person';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import { DebouncedButton } from '../Common/DebouncedButton';
import PersonWidgetView from '../Views/Person/PersonWidgetView';
import { OldWidgetProps } from './Widget';

type Props = OldWidgetProps & {
	notifyAboutUnsavedData: (cancel: boolean) => void;
	savedCallback: (sender: BaseWidget) => void;
	changeDataCallback: (hasChanges: boolean) => void;
	setUpdatedPersonCallback?: (person: Person) => void;
	customerId: string;
	forceReload: boolean;
	apiBaseUrl: string;
	showEditButton: boolean;
	person: Person;
	aspNetUserId: string;
	getUserData: (userId: string) => void;
};

type State = {
	initialPerson: Person;
	person: Person;
	editMode: boolean;
	invalidForm: boolean;
	validPerson: boolean;
	visible: boolean;
};

class PersonInformationWidget extends BaseWidget<Props, State> {
	scrollElRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this.state = {
			initialPerson: new Person(),
			person: props.person,
			editMode: false,
			invalidForm: false,
			validPerson: true,
			visible: true,
		};

		this.scrollElRef = React.createRef<HTMLDivElement>();
	}
	public static defaultProps = {
		showEditButton: true,
		apiBaseUrl: GlobalSettings.personsMaintenanceApi,
	};

	componentDidMount() {
		this.closeEditPanelMode();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.person.id !== this.props.person.id || prevProps.person.objectId !== this.props.person.objectId) {
			this.setState({ editMode: false, person: this.props.person });
		}
	}

	toggleEditPanel(checkForUnsaved: boolean) {
		if (checkForUnsaved && this.state.editMode && this.hasUnsavedData === true) {
			this.props.notifyAboutUnsavedData(true);
		} else {
			this.toggleEditMode();
		}
	}

	toggleEditMode() {
		const newState = !this.state.editMode;
		this.props.editModeCallback(newState);
		this.setState(() => ({
			editMode: newState,
			invalidForm: false,
		}));
		setTimeout(() => {
			this.toggleEditButton(newState);
		}, 0);
	}

	closeEditPanelMode() {
		if (this.state.editMode) {
			this.toggleEditMode();
		}
	}

	tryCloseWidget() {
		this.toggleEditPanel(true);
	}
	// The debounce wait time needs to be bigger than the props.setPerson
	// timeout on PersonWidgetView so it can use the latest data
	save = debounce(() => {
		if (!this.state.validPerson) {
			this.setState({
				invalidForm: true,
			});
			return;
		} else {
			this.hasUnsavedData = false;
			this.props.changeDataCallback(false);

			const url = this.props.apiBaseUrl;
			const updatedPerson = this.state.person;
			this.props.setUpdatedPersonCallback(updatedPerson);
			ajaxUtil
				.put(url, updatedPerson)
				.then((response: any) => {
					this.props.savedCallback(this);
					return response;
				})
				.then((data) => {
					if (data) {
						this.hasUnsavedData = false;
						this.props.changeDataCallback(false);
						this.toggleEditPanel(false);
						this.props.getUserData(this.props.aspNetUserId);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, 1500);

	setPerson(valid: boolean, personData: Person) {
		this.setState({
			validPerson: valid,
			invalidForm: !valid,
		});
		if (this.state.editMode) {
			this.hasUnsavedData = true;
			this.props.changeDataCallback(true);
			this.setState({ person: personData });
		}
	}
	fillPerson(personId: string) {
		this.setState({
			person: this.props.person,
		});
	}
	setValidationStatus(valid: boolean) {
		this.setState({ validPerson: valid, invalidForm: !valid });
	}

	scrollToTop() {
		if (this.scrollElRef?.current?.scrollTo) {
			this.scrollElRef.current.scrollTo({ top: 0 });
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				hidden={!this.state.visible}
				className={'e-panel oldwidget'}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="4"
			>
				<div className="e-panel-container">
					<WidgetHeader
						caption={TranslateText('common.information')}
						showEditButton={this.props.showEditButton}
						allowEditMode={this.props.allowEditMode || this.state.editMode}
						editMode={this.state.editMode}
						removePanelCallback={() => this.setState({ visible: false })}
						editCallback={() => this.toggleEditPanel(true)}
					/>
					<div ref={this.scrollElRef} className="widget-view">
						<PersonWidgetView
							readOnly={!this.state.editMode}
							defaultCustomerId={this.props.customerId}
							person={this.state.person}
							setPerson={this.setPerson.bind(this)}
							setValidationStatus={this.setValidationStatus.bind(this)}
							scrollToTop={this.scrollToTop.bind(this)}
						/>
					</div>
					{this.state.editMode ? (
						<div className="buttons-host">
							<Button
								className="widget-button cancel-button"
								onClick={() => {
									this.tryCloseWidget();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.invalidForm}
								onClick={this.save}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default PersonInformationWidget;
