import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import * as GlobalSettings from 'GlobalSettings.json';
import Group from 'models/Group';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

const mapState = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	setGroup: (valid: boolean, group: Group) => void;
	getAccessTokenCallback: () => string;
	readOnly: boolean;
	renderFor: RenderForEnum;
	widgetState: WidgetStateEnum;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
};

interface State {
	group: Group;
	expandedItemIndex: number;
	validationResult?: ValidationResult;
}

class GroupView extends BaseView<Props, State> {
	validator: Validator;

	public static defaultProps = {
		readOnly: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			group: new Group(this.props.defaultCustomerId),
			expandedItemIndex: 0,
			validationResult: null,
		};

		this.setFormValidator();
	}

	handleValueChange(arg: any, statePropName: string) {
		this.performStateChange(arg.target.value, statePropName);
	}

	handleCheckBoxChange(arg: any, statePropName: string) {
		this.performStateChange(arg.target.checked, statePropName);
	}

	performStateChange(value: any, statePropName: string) {
		const tempGroup = { ...this.state.group };
		tempGroup[statePropName] = value;

		this.setState(
			{
				...this.state,
				group: tempGroup,
			},
			() => {
				this.validator.validate(this.state.group).then((result) => {
					this.setState(
						{
							validationResult: result.validationResult,
						},
						() => {
							this.setGroup(result.formResult, this.state.group);
						}
					);
				});
			}
		);
	}

	componentDidMount() {
		this.setFormValidator();
		this.validator.validate(this.state.group).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});
		});
		const code = document.getElementById('code');
		if (code) {
			code.focus();
		}
	}

	async isGroupCodeUnique(code: string) {
		let isCodeUnique = true;
		const url = GlobalSettings.validateApi + '/GroupFields';

		let data: { [k: string]: any };
		if (this.state.group.id !== undefined) {
			data = {
				customerId: this.state.group.customerId,
				editedEntityId: this.state.group.id,
				value: code,
				Field: 'code',
			};
		} else {
			data = {
				customerId: this.state.group.customerId,
				value: code,
				Field: 'code',
			};
		}

		await fetch(url, {
			method: 'post',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
			}),
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status.toString());
				} else {
					return response.json();
				}
			})
			.then((data) => {
				isCodeUnique = data;
			});
		return isCodeUnique;
	}

	setFormValidator() {
		this.validator = new Validator({
			fieldRules: {
				code: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: {
							message: TranslateText('fieldsValidations.maxLength40'),
							validationFunction: (data) => {
								if (data.code?.length > 40) {
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
						regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
						unique: {
							message: TranslateText('fieldsValidations.uniqueValue'),
							validationFunction: async (data) => {
								const result = await this.isGroupCodeUnique(data?.code);
								return ValidatorFunctions.wrapInPromise(result);
							},
						},
					},
				},
				name: {
					rules: {
						required: ValidatorFunctions.required(),
						maxLength: {
							message: TranslateText('fieldsValidations.maxLength40'),
							validationFunction: (data) => {
								if (data.name?.length > 40) {
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
						regex: ValidatorFunctions.regex(new RegExp(this.noEmptySpaceRegEx)),
					},
				},
				description: {
					rules: {
						maxLength: {
							message: TranslateText('fieldsValidations.maxLength200'),
							validationFunction: (data) => {
								if (data.description?.length > 200) {
									return ValidatorFunctions.wrapInPromise(false);
								}
								return ValidatorFunctions.wrapInPromise(true);
							},
						},
					},
				},
			},
		});
	}

	setGroup(valid: boolean, group: Group) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			this.props.setGroup(valid, group);
		}
	}

	public fillSystemInformation() {
		return (
			<form id="groupViewSystemInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="code"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.code')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="code"
							value={this.state.group.code}
							onChange={(args: any) => this.handleValueChange(args, 'code')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<CustomerNameDisplay
						customerId={
							this.props.defaultCustomerId.length > 0
								? this.props.defaultCustomerId
								: this.state.group.customerId
						}
					/>
				</div>
			</form>
		);
	}

	public fillGroupData() {
		return (
			<form id="groupDataForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							fullWidth
							id="name"
							className="resize-font"
							type="text"
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="name"
							value={this.state.group.name}
							onChange={(args: any) => this.handleValueChange(args, 'name')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.name} />
					</div>
					<div className="form-group">
						<TextField
							fullWidth
							id="description"
							type="text"
							className="resize-font"
							label={TranslateText('fields.description')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="description"
							value={this.state.group.description}
							onChange={(args: any) => this.handleValueChange(args, 'description')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.description} />
					</div>
					<div
						className="form-group"
						style={this.props.renderFor === RenderForEnum.Wizard ? { visibility: 'hidden' } : {}}
					>
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'default'}
									className="selection-checkbox"
									checked={this.state.group.active}
									size="small"
									disabled={this.props.readOnly}
									readOnly={this.props.readOnly}
									onChange={(args: any) => this.handleCheckBoxChange(args, 'active')}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
				</div>
			</form>
		);
	}

	render() {
		return this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget();
	}

	renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '33%' }}>
					<Accordion expandedCallback={() => this.setFormValidator()}>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							{this.fillSystemInformation()}
						</AccordionSection>
					</Accordion>
				</div>
				<div className="column" style={{ width: '33%' }}>
					<Accordion expandedCallback={() => this.setFormValidator()}>
						<AccordionSection expanded={true} header={TranslateText('common.groupData')}>
							{this.fillGroupData()}
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}

	renderForWidget() {
		return (
			<div style={{ width: '100%' }}>
				<Accordion
					expandedCallback={(index: number) => {
						this.setState({
							expandedItemIndex: index,
						});
						this.setFormValidator();
					}}
				>
					<AccordionSection
						expanded={this.state.expandedItemIndex === 0}
						header={TranslateText('common.systemInformation')}
					>
						{this.fillSystemInformation()}
					</AccordionSection>
					<AccordionSection
						expanded={this.state.expandedItemIndex === 1}
						header={TranslateText('common.groupData')}
					>
						{this.fillGroupData()}
					</AccordionSection>
				</Accordion>
			</div>
		);
	}
}
export default connector(GroupView);
