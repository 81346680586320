import './messageView.scss'

import React, {useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import {AddWizardComponentProps} from "../../NewAddWizard";
import EntityTypeEnum from "../../../../models/EntityTypeEnum";
import MessageEditor from "../../../MessageEditor/MessageEditor";
import {ApplicationState} from "../../../../store";
import MessageWrapper from "../../../../models/MessageWrapper";
import MessageTargetEnum from "../../../../models/MessageTargetEnum";
import {AlertsEntitiesData} from "../Alert/AlertView";
import {ALERT_MAIL_SUBJECT_MAX_LENGTH} from "../../../../Constants";
import { Button, Fade, IconButton, MenuItem, MenuList, Popover } from '@material-ui/core';
import {Add, Clear } from '@material-ui/icons';
import {TranslateText} from "../../../../utils/Translations";
import GlobalSettings from "../../../../GlobalSettings.json";
import ajaxUtil from "../../../../utils/Ajax";
import {LanguageDto} from "../InformationNotification/InformationNotificationView";
import AlertTemplate from "../../../../models/AlertTemplate";


export interface AlertLanguage{
	id: string;
	name: string;
	code: string;
	active: boolean;
	shown: boolean;
}
const MessageView = (props: AddWizardComponentProps) => {
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);

	const accessToken = useSelector((s: ApplicationState) => s.oidc.user.access_token);
	const [alertData, setAlertData] = useState<AlertsEntitiesData>(props.getDataCallback());
	const [languageSelected, setLanguageSelected] = useState<string>('');
	const [contents, setContents] = useState({
		notificationContent: null,
		popupContent: null,
		mailContent: null,
		smsContent: null,
		whatsAppContent: null,
	});
	const [useDefaultMessage, setUseDefaultMessage] = useState({
		notification: true,
		popup: false,
		mail: true,
		sms: true,
		whatsApp: true
	});
	const [languages, setLanguages] = useState<AlertLanguage[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [templates, setTemplates] = useState<Record<string, AlertTemplate>>({});
	const [isValid, setIsValid] = useState(true);
	const open = Boolean(anchorEl);
	const id = open ? 'fade-popover' : undefined;

	useEffect(() => {
		getLanguages();
	},[]);

	useEffect(() => {
		const data = props.getDataCallback() as AlertsEntitiesData;
		if(data !== null) {
			setAlertData(data);
		}
	},[props.getDataCallback]);

	useEffect(() => {
		if (!props.visible) return;
		const isValid = validationMessageContent(contents, alertData);
		if(!isValid)
			props.setValidationCallback(isValid);
		if(languages?.length > 0)
		{
			const first = languages.find((x) => x.active);
			if(!Object.keys(templates).includes(first.id))
			{
				const newAlertTemplate = new AlertTemplate();
				newAlertTemplate.notificationTemplate = contents.notificationContent;
				newAlertTemplate.smsTemplate = contents.smsContent;
				newAlertTemplate.mailTemplate = contents.mailContent;
				newAlertTemplate.popupTemplate = contents.popupContent;
				newAlertTemplate.whatsAppTemplate = contents.whatsAppContent;
				let tempTemplates = {...templates};
				tempTemplates[languageSelected] = {...newAlertTemplate};
				setTemplates(tempTemplates);
			}

		}
	}, [props.visible]);

	useEffect(() => {
		if (!props.visible) return;
		const isValid = validationMessageContent(contents, alertData);
		if(isValid)
			props.onChangeCallback({templates: templates});
		setIsValid(isValid);
	},[templates, props.visible]);

	useEffect(() => {
		if (alertData && languages) {
			const newTemplates = { ...templates };
			const promises = languages.map((language) => {
				if (language.shown) {
					return new Promise<void>((resolve) => {
						getDefaultMessagesWhenEventTypeIsChanged(language.id, newTemplates)
							.then(() => resolve());
					});
				}
				return Promise.resolve();
			});

			Promise.all(promises).then(() => {
				setTemplates(newTemplates);
			});
		}
	}, [props.getDataCallback()?.eventTypeId]);


	useEffect(() => {
		const isValid = validationMessageContent(contents, alertData);
		if(!isValid && props.visible)
		{
			props.setValidationCallback(isValid);
			setIsValid(isValid);
		}
	},[contents]);

	const validationMessageContent = (contents: any, alertData: AlertsEntitiesData) => {
		const regex = /(<pre>)|(<code>)|(<\/code>)|(<\/pre>)/g;
		let notificationBody = '';
		let sizeNotification = 8;
		if(alertData?.hasAlertNotification &&
			contents.notificationContent?.body &&
			contents.notificationContent?.body.match(regex)) {
			notificationBody = contents.notificationContent?.body.replace(regex,'');
			sizeNotification = 0;
		}
		else {
			notificationBody =  contents.notificationContent?.body;
		}
		const notificationContentRequired =
			alertData?.hasAlertNotification &&
			contents.notificationContent?.body &&
			!(notificationBody.length > sizeNotification);

		let popupBody = '';
		let sizePopup = 8;
		if(alertData?.hasPopup &&
			contents.popupContent?.body &&
			contents.popupContent?.body.match(regex)) {
			popupBody = contents.popupContent?.body.replace(regex, '');
			sizePopup = 0;
		}
		else {
			popupBody = contents.popupContent?.body;
		}
		const popupContentRequired =
			alertData?.hasPopup &&
			contents.popupContent?.body &&
			!(popupBody.length > sizePopup);

		let mailBody = '';
		let sizeMail = 8;
		if(alertData?.hasEmailNotification &&
			contents.mailContent?.body &&
			contents.mailContent?.body.match(regex)) {
			mailBody = contents.mailContent?.body.replace(regex, '');
			sizeMail = 0;
		}
		else {
			mailBody = contents.mailContent?.body;
		}
		const mailContentRequired =
			alertData?.hasEmailNotification &&
			((contents.mailContent?.body && !(mailBody.length > sizeMail)) ||
				!(contents.mailContent?.subject?.length > 0) ||
				!(contents.mailContent?.subject?.length <= ALERT_MAIL_SUBJECT_MAX_LENGTH));

		let smsBody = '';
		let sizeSms = 8;
		if(alertData?.hasSmsNotification &&
			contents.smsContent?.body &&
		contents.smsContent?.body.match(regex)) {
			smsBody = contents.smsContent?.body.replace(regex,'');
			sizeSms = 0;
		}
		else {
			smsBody = contents.smsContent?.body;
		}
		const smsContentRequired =
			alertData?.hasSmsNotification &&
			contents.smsContent?.body &&
			!(smsBody.length > sizeSms);

		let whatsAppBody = '';
		let sizeWhatsApp = 8;
		if(alertData?.hasWhatsAppNotification &&
			contents.whatsAppContent?.body &&
		contents.whatsAppContent?.body.match(regex)) {
			whatsAppBody = contents.whatsAppContent?.body.replace(regex, '');
			sizeWhatsApp = 0;
		}
		else {
			whatsAppBody = contents.whatsAppContent?.body;
		}
		const whatsAppContentRequired =
			alertData?.hasWhatsAppNotification &&
			contents.whatsAppContent?.body &&
			!(whatsAppBody.length > sizeWhatsApp);

		return !(
			notificationContentRequired ||
			popupContentRequired ||
			mailContentRequired ||
			smsContentRequired ||
			whatsAppContentRequired
		)

	}
	const handleMessageContentChange = (content: MessageWrapper, appliesTo: MessageTargetEnum, defaultContent: boolean) => {
		const tempContent = {...contents};
		const tempTemplates = {...templates};
		const newDefaultMessageStatus = {...useDefaultMessage};
		switch (appliesTo) {
			case MessageTargetEnum.Notification:
				tempContent.notificationContent = content;
				if(tempTemplates[languageSelected])
					tempTemplates[languageSelected].notificationTemplate = content;
				newDefaultMessageStatus.notification = defaultContent;
				break;
			case MessageTargetEnum.Popup:
				tempContent.popupContent = content;
				if(tempTemplates[languageSelected])
					tempTemplates[languageSelected].popupTemplate = content;
				newDefaultMessageStatus.popup = defaultContent;
				break;
			case MessageTargetEnum.Mail:
				tempContent.mailContent = content;
				if(tempTemplates[languageSelected])
					tempTemplates[languageSelected].mailTemplate = content;
				newDefaultMessageStatus.mail = defaultContent;
				break;
			case MessageTargetEnum.Sms:
				tempContent.smsContent = content;
				if(tempTemplates[languageSelected])
					tempTemplates[languageSelected].smsTemplate = content;
				newDefaultMessageStatus.sms = defaultContent;
				break;
			case MessageTargetEnum.WhatsApp:
				tempContent.whatsAppContent = content;
				if(tempTemplates[languageSelected])
					tempTemplates[languageSelected].whatsAppTemplate = content;
				newDefaultMessageStatus.whatsApp = defaultContent;
				break;
			default:
				break;
		}
		setContents(tempContent);
		setTemplates(tempTemplates);
	};

	const clickLanguage = (id: string) => {
		setLanguageSelected(id);
		const tempContent = {...contents};
		const templateLanguage = templates[id];
		tempContent.notificationContent = {...templateLanguage?.notificationTemplate};
		tempContent.mailContent = {...templateLanguage?.mailTemplate};
		tempContent.popupContent = {...templateLanguage?.popupTemplate};
		tempContent.smsContent = {...templateLanguage?.smsTemplate};
		tempContent.whatsAppContent = {...templateLanguage?.whatsAppTemplate};
		setContents(tempContent);
	}

	const clearLanguage = (id: string) => {
		let tempLanguages = [...languages];
		for (let i = 0; i < tempLanguages?.length; i++) {
			if (tempLanguages[i].id === id) {
				tempLanguages[i].shown = false;
				break;
			}
		}
		let first = tempLanguages.find((x) => x.shown);
		setLanguageSelected(first.id);
		setLanguages(tempLanguages);
		let temTemplates = {...templates};
		delete temTemplates[id];
		setTemplates(temTemplates);
	}

	const handleClickAdd = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (id: string) => {
		let tempLanguages = [...languages];
		for(let i = 0; i < tempLanguages?.length; i++)
		{
			if(tempLanguages[i].active && !tempLanguages[i].shown && tempLanguages[i].id === id)
			{
				tempLanguages[i].shown = true;
				break;
			}
		}
		setLanguages(tempLanguages);
		setAnchorEl(null);
		if(templates[id] === undefined) {
			defaultMessages(id);
		}
		else
		{
			const newContent = {...templates[id]} as AlertTemplate;
			setContents({
				notificationContent: {...newContent.notificationTemplate},
				popupContent: {...newContent.popupTemplate},
				smsContent: {...newContent.smsTemplate},
				whatsAppContent: {...newContent.whatsAppTemplate},
				mailContent: {...newContent.mailTemplate}
			});
		}
	};
	const handleCloseLanguages = () => {
		setAnchorEl(null);
	}

	const getLanguages = () => {
		let languages = [] as AlertLanguage[];
		const url = `${GlobalSettings.alertsApi}/getAlertLanguages/${defaultCustomerId}`;
		ajaxUtil.get<LanguageDto[]>(url).then((result) => {
			const languagesDto = [...result] as LanguageDto[];
			for(let i = 0; i < languagesDto.length; i++)
			{
				languages.push({
					id: languagesDto[i].id,
					code: languagesDto[i].code,
					active: languagesDto[i].active,
					name: languagesDto[i].name,
					shown: false,
				})
			}
			if(languages.length > 0)
			{
				languages[0].shown = true;
				setLanguages(languages);
				setLanguageSelected(languages[0].id);
			}
		});
	}

	const defaultMessages = (languageId: string) => {
		const entityType = props.getDataCallback()?.eventTypeId;
		let tempTemplates = {...templates};
		const languageCode = languages.find((x) => x.id === languageId)?.code;
		let alertTemplates = new AlertTemplate();
		const baseUrl =
			GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + entityType + '/';

		let promises = Object.keys(MessageTargetEnum).map((element) => {
			const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

			if (!entityType) {
				return Promise.resolve();
			}
			return ajaxUtil.get(baseUrl + tempCurrentMessageTab + '/' + languageCode).then((result: any) => {
				switch (tempCurrentMessageTab) {
					case MessageTargetEnum.Notification:
						alertTemplates.notificationTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Popup:
						alertTemplates.popupTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Mail:
						alertTemplates.mailTemplate = {
							body: result.body,
							subject: result.subject,
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Sms:
						alertTemplates.smsTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.WhatsApp:
						alertTemplates.whatsAppTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					default:
						break;
				}
			}).catch((err) => {
				console.log(err);
			});
		});

		Promise.all(promises).then(() => {
			tempTemplates[languageId] = JSON.parse(JSON.stringify(alertTemplates));
			setTemplates(tempTemplates);
		});
	}

	const getDefaultMessagesWhenEventTypeIsChanged = async (languageId: any, tempTemplates: any) => {
		const entityType = props.getDataCallback()?.eventTypeId;
		const languageCode = languages.find((x) => x.id === languageId)?.code;
		let alertTemplates = new AlertTemplate();
		const baseUrl =
			GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + entityType + '/';

		let promises = Object.keys(MessageTargetEnum).map((element) => {
			const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

			if (!entityType) {
				return Promise.resolve();
			}
			return ajaxUtil.get(baseUrl + tempCurrentMessageTab + '/' + languageCode).then((result: any) => {
				switch (tempCurrentMessageTab) {
					case MessageTargetEnum.Notification:
						alertTemplates.notificationTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Popup:
						alertTemplates.popupTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Mail:
						alertTemplates.mailTemplate = {
							body: result.body,
							subject: result.subject,
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Sms:
						alertTemplates.smsTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.WhatsApp:
						alertTemplates.whatsAppTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					default:
						break;
				}
			}).catch((err) => {
				console.log(err);
			});
		});
		await Promise.all(promises);
		tempTemplates[languageId] = JSON.parse(JSON.stringify(alertTemplates));

		if (languageId === languageSelected) {
			const newContent = JSON.parse(JSON.stringify(alertTemplates));
			setContents({
				notificationContent: { ...newContent.notificationTemplate },
				popupContent: { ...newContent.popupTemplate },
				smsContent: { ...newContent.smsTemplate },
				whatsAppContent: { ...newContent.whatsAppTemplate },
				mailContent: { ...newContent.mailTemplate },
			});
		}
	};


	return(
		<div className="messages-alert-view-container">
			<div className={'language messages-alert-column column1'} style={{width: "13%"}}>
				<div className={'column-content'}>
					<div className={'buttons-container'}  style={{minHeight: 270, minWidth: 140}}>
						{languages.map((language) => (
							language.active && language.shown
								? <div className="language-button-alert"
									   style={language.id == languageSelected ? {backgroundColor: '#cccccc'} : {backgroundColor: ' #f5f5f5'}}
								>
									<Button
									className="language-btn"
									key={language.id}
									disabled={!isValid && language.id !== languageSelected}
									onClick={() => clickLanguage(language.id)}
								    >
									{language.code.toUpperCase()}
									</Button>
									<IconButton
										className="clear-btn"
										disabled={!isValid}
										hidden={languages.filter((x) => x.shown)?.length <= 1 || language.id === languageSelected}
										size={'small'}
										onClick={() => {clearLanguage(language.id)}}
									>
										<Clear fontSize={'small'} />
									</IconButton>
							    </div>
								: null
						))}
					</div>
					<Button
						className={'add-btn'}
						variant="outlined"
						disabled={!languages.some((x) => x.active && !x.shown)}
						startIcon={<Add />}
						aria-describedby={id}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClickAdd}
					>
						{TranslateText('fields.addLanguage')}
					</Button>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleCloseLanguages}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						TransitionComponent={Fade}
					>
						<MenuList
							aria-labelledby="fade-button"
							style={{maxHeight: 150, minWidth: 130, overflow: 'auto'}}
						>
							{languages.map((language) => (
								language.active && !language.shown
									? <MenuItem onClick={() => handleClose(language.id)}>
										{TranslateText(`languages.${language.name}`)}
								      </MenuItem>
									: null
							))}

						</MenuList>
					</Popover>
				</div>
			</div>
			<div className={'language messages-alert-column column2'} style={{width: "87%", paddingLeft: 0, paddingRight: 0}}>
				<MessageEditor
					useDefaultMessage={useDefaultMessage}
					getAccessTokenCallback={() => accessToken}
					renderForEntityType={EntityTypeEnum.Alert}
					renderForEntityTypeParams={[alertData?.eventTypeId]}
					useNotification={alertData?.hasAlertNotification}
					usePopup={alertData?.hasPopup}
					useEmail={alertData?.hasEmailNotification}
					useSms={alertData?.hasSmsNotification}
					useWhatsApp={alertData?.hasWhatsAppNotification}
					notificationContent={contents?.notificationContent}
					popupContent={contents?.popupContent}
					emailContent={contents?.mailContent}
					smsContent={contents?.smsContent}
					whatsAppContent={contents?.whatsAppContent}
					messageContentChangedCallback={handleMessageContentChange}
					canRender={true}
					language={languages.find((x) => x.id === languageSelected)?.code}
				/>
			</div>
		</div>
	);
}
export default MessageView;
