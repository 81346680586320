import React, { useEffect } from 'react';

import { DurationCounter } from './DurationCounter';
import { useAlertDurationExceeded } from './hooks';
import { RelatedTimeCheckbox } from './RelatedTimeCheckbox';
import { TemplateDropdown } from './TemplateDropdown';
import { TimeFrameEvaluation } from './TimeFrameEvaluation';
import { AlertDurationExceededProps } from './types';
import SensorTypeEnum from '../../../models/SensorTypeEnum';

const AlertDurationExceeded = ({
	durationExceededMetadata,
	handleDurationExceededMetadataChange,
	setValidationCallback,
	isForNewAddWizard = false,
}: AlertDurationExceededProps): JSX.Element => {
	const {
		setRelatedToTimeChecked,
		setDurationCounterValue,
		handleTimeFrameEvaluationChange,
		relatedToTimeChecked,
		durationCounterValue,
		templateId,
		setTemplateId,
		validationResult,
		durationMaxValue,
		relatedToTimeCheckboxDisabled,
	} = useAlertDurationExceeded(durationExceededMetadata, handleDurationExceededMetadataChange);

	useEffect(() => {
		if(isForNewAddWizard && validationResult !== undefined && validationResult !== null)
			setValidationCallback(validationResult?.formResult)
	},[validationResult]);

	return (
		<>
			<TemplateDropdown
				isForNewAddWizard={isForNewAddWizard}
				valueId={templateId}
				handleOnTemplateValueChange={setTemplateId}
				validationResult={validationResult}
			/>
			<RelatedTimeCheckbox
				checked={relatedToTimeChecked}
				handleOnRelatedTimeCheckboxChange={setRelatedToTimeChecked}
				disabled={relatedToTimeCheckboxDisabled}
			/>
			{relatedToTimeChecked && (
				<TimeFrameEvaluation
					isForNewAddWizard={isForNewAddWizard}
					durationExceededMetadata={durationExceededMetadata}
					handleTimeFrameEvaluationChange={handleTimeFrameEvaluationChange}
					durationMaxValue={durationMaxValue}
					validateResult={validationResult}
				/>
			)}
			{!relatedToTimeChecked && (
				<DurationCounter
					isForNewAddWizard={isForNewAddWizard}
					counterValue={durationCounterValue}
					handleOnDurationCounterChange={setDurationCounterValue}
					validationResult={validationResult}
				/>
			)}
		</>
	);
};

export default React.memo(AlertDurationExceeded);
