import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import GlobalSettings from 'GlobalSettings.json';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import './styles.scss';
import { ValidationMessage } from '../../../ValidationMessage/ValidationMessage';
import SensorTypeEnum from '../../../../models/SensorTypeEnum';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';
import { TemplateDropdownProps } from './types';
import { TemplateListItem } from '../../../Widgets/DeviceTemplateWidget/DeviceTemplateWidget';

type TemplateAutocompleteItem = AutocompleteItem & {
	sensorType: SensorTypeEnum;
};

const TemplateDropdown = ({
	valueId,
	handleOnTemplateValueChange,
	validationResult,
	hideLabel,
	disabled,
	disableClearable = false,
	isForNewAddWizard = false,
}: TemplateDropdownProps): JSX.Element => {
	const [templatesData, setTemplatesData] = useState<TemplateAutocompleteItem[]>([]);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	useQuery(['alerts', 'duration-templates-data'], async () => {
		const urlDuration = `${GlobalSettings.listsApi}/durationTemplatesForCustomer?customerId=${customerId}`;
		const urlDigital = `${GlobalSettings.listsApi}/digitalTemplatesForCustomer?customerId=${customerId}&onlyDurationCounterActive=true`;
		const durationTemplates = await ajaxUtil.get<TemplateListItem[]>(urlDuration);
		const digitalTemplates = await ajaxUtil.get<TemplateListItem[]>(urlDigital);

		const data = [...durationTemplates, ...digitalTemplates];
		data.sort((t1, t2) => t1.display.localeCompare(t2.display));

		setTemplatesData(data);
	});

	return (
		<div className="form-group">
			<MaterialAutocomplete
				isForNewAddWizard={isForNewAddWizard}
				name="alertDurationTemplate"
				className="material-autocomplete alert-duration-template-dropdown"
				disabled={disabled}
				valueId={valueId}
				dataSource={templatesData}
				onChange={(autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => {
					const sensorType = templatesData.find(t => t.id === autocompleteArgs.value)?.sensorType;
					handleOnTemplateValueChange(autocompleteArgs.value as string, sensorType);
				}}
				fullWidth={false}
				label={!hideLabel ? TranslateText('common.template') : null}
				disableClearable={disableClearable}
				validationResult={isForNewAddWizard && !!validationResult && !validationResult.formResult ? validationResult?.validationResult?.templateId : null}
			/>
			{!!validationResult && !validationResult.formResult && !isForNewAddWizard && (
				<div className={'validation-error-container'}>
					<ValidationMessage result={validationResult.validationResult.templateId} />
				</div>
			)}
		</div>
	);
};

export default TemplateDropdown;
