import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import Key from '../../models/Key';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import KeyView from '../Views/KeyView';
import RenderForEnum from '../Views/RenderForEnum';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';
import {DebouncedButton} from "../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../Constants";

interface Props extends OldWidgetProps {
	displayCallback: (key: Key) => void;
	customerId: string;
	forceReload: number;
	showEditButton: boolean;
}

interface State {
	key: Key;
	initialKey: Key;
	editMode: boolean;
	invalidForm: boolean;
	validKey: boolean;
	visible: boolean;
	disabled: boolean;
}

class KeyInformationWidget extends BaseWidget<Props, State> {
	keyViewRef: any;
	connectionConflictsNotifierRef: ConnectionConflictsNotifier;

	constructor(props: Props) {
		super(props);
		this.state = {
			key: new Key(),
			initialKey: new Key(),
			editMode: false,
			invalidForm: false,
			validKey: true,
			visible: true,
			disabled: false,
		};
	}

	public static defaultProps = {
		showEditButton: true,
	};

	componentDidMount() {
		this.fillKey(this.props.entityId);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId || this.props.forceReload !== prevProps.forceReload) {
			this.closeEditPanelMode();
			this.fillKey(this.props.entityId);
		}
	}

	fillKey(keyId: string) {
		const url = `${GlobalSettings.keysMaintenanceApi}/${keyId}`;
		ajaxUtil.get(url, { redirectWhenUnauthorized: true }).then((data: any) => {
			this.setState({
				key: data,
				initialKey: data,
			});

			if (this.keyViewRef) {
				this.keyViewRef.setEditRecord(data);
				this.props.displayCallback(data);
			}
			this.props.displayCallback(data);
		});
	}

	async toggleEditPanel() {
		if (this.state.editMode && this.hasUnsavedData) {
			if (await this.util.cancelConfirmation()) {
				this.toggleEditMode();

				this.keyViewRef.setEditRecord(this.state.initialKey);
				this.props.displayCallback(this.state.initialKey);

				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);
				this.setState({
					key: this.state.initialKey,
				});
			}
		} else {
			this.toggleEditMode();
			this.keyViewRef.setEditRecord(this.state.key);
			this.props.displayCallback(this.state.key);
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.editMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState(() => ({
			editMode: currentValueFlipped,
			invalidForm: false,
		}));
	}

	closeEditPanelMode() {
		if (this.state.editMode) {
			this.toggleEditMode();
		}
	}

	save() {
		this.setState({disabled: true});
		this.keyViewRef.validateBeforeSave().then((valid: any) => {
			if (!valid) {
				this.setState({
					invalidForm: true,
					disabled: false,
				});
				return;
			} else {
				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);

				const url = GlobalSettings.keysMaintenanceApi;
				const updatedKey = {
					...this.state.key,
					personId: this.state.key.personId ? this.state.key.personId : null,
				};

				ajaxUtil
					.put(url, updatedKey)
					.then((data) => {
						this.setState({disabled: false});
						if (data) {
							this.hasUnsavedData = false;
							this.props.changeDataCallback(false);
							this.toggleEditPanel();
						}
					})
					.catch((error) => {
						this.setState({disabled: false});
						console.log(error);
					});
			}
		});
	}

	setKey(valid: boolean, keyData: Key, unsavedData: boolean) {
		this.setState({
			validKey: valid,
			key: keyData,
			invalidForm: !valid,
		});

		this.hasUnsavedData = unsavedData;
		this.props.changeDataCallback(true);
	}

	handleConfictingConnections(sender: ConnectionsConflictResolver, proposedConnectionCanBeSaved: boolean) {
		setTimeout(() => {
			sender.handleConflictingConnections(proposedConnectionCanBeSaved);
		}, 500);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="4"
			>
				<ConnectionConflictsNotifier
					ref={(scope) => {
						this.connectionConflictsNotifierRef = scope;
					}}
					visible={false}
					userAnswearCallback={this.handleConfictingConnections.bind(this)}
					connectionType={ConnectionTypeEnum.PersonKey}
				/>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.editMode}
							allowEditMode={this.props.allowEditMode || this.state.editMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel()}
						/>
					</div>
					<div className="widget-view">
						<KeyView
							userFirstAndLastName={this.state.key.userName}
							defaultCustomerId={this.state.key.customerId}
							renderFor={RenderForEnum.Widget}
							ref={(input) => {
								this.keyViewRef = input;
							}}
							setKey={this.state.editMode ? this.setKey.bind(this) : null}
							readOnly={!this.state.editMode}
							widgetState={this.state.editMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
						/>
					</div>
					{this.state.editMode ? (
						<div className="buttons-host">
							<Button
								className="widget-button cancel-button"
								onClick={() => {
									this.toggleEditPanel();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.disabled || this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default KeyInformationWidget;
