import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import React, { useEffect, useState } from 'react';
import SwitchComponent from 'shared/components/SwitchComponent';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';

import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: MessageServiceSettings;
};

enum MessageServiceProviderEnum {
	None = 0,
	MessageBird = 1,
	Twilio = 2,
}

interface MessageServiceSettings {
	defaultSmtp: boolean;
	server: string;
	port: number;
	username: string;
	password: string;

	defaultMessageService: boolean;
	messageServiceProvider: MessageServiceProviderEnum;
	apiKey: string;
}

type SmtpChangeValue = boolean | number | string | null;
type SmtpChangeKey = 'defaultSmtp' | 'server' | 'port' | 'username' | 'password';

type MessageServiceChangeValue = boolean | string | number;
type MessageServiceChangeKey = 'defaultMessageService' | 'apiKey' | 'messageServiceProvider';

const SmtpServiceValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		defaultSmtp: {
			rules: { required: ValidatorFunctions.required() },
		},
		server: {
			rules: { required: ValidatorFunctions.required() },
		},
		port: {
			rules: {
				required: ValidatorFunctions.required(),
				minValue: ValidatorFunctions.minNumber(1),
				maxValue: ValidatorFunctions.maxNumber(65536),
			},
		},
		username: {
			rules: { required: ValidatorFunctions.required() },
		},
		password: {
			rules: { required: ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const MessageServiceValidationConfig = (): FieldRules => {
	const fieldRules = {
		defaultMessageService: {
			rules: { required: ValidatorFunctions.required() },
		},
		messageServiceProvider: {
			rules: { required: ValidatorFunctions.required() },
		},
		apiKey: {
			rules: { required: ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const MessageServiceSettingsView = (props: Props) => {
	const [expandedSection, setExpandedSection] = useState<number>(0);
	const [customerLevel, setCustomerLevel] = useState<CustomerLevelEnum>();
	const [enableSmtpSettings, setEnableSmtpSettings] = useState<boolean>();
	const [enableMessageSettings, setEnableMessageSettings] = useState<boolean>(!props.data.defaultMessageService);

	useEffect(() => {
		const url = `${GlobalSettings.customersMaintenanceApi}/${props.entityId}`;
		ajaxUtil.get(url).then((data: any) => {
			setCustomerLevel(data.customerType);
			setupValidations(
				props.data.defaultSmtp && data.customerType !== CustomerLevelEnum.OEM,
				props.data.defaultMessageService && data.customerType !== CustomerLevelEnum.OEM
			);
		});
	}, [props.entityId]);

	useEffect(() => {
		setEnableSmtpSettings(!props.data.defaultSmtp || customerLevel === CustomerLevelEnum.OEM);
	}, [props.data.defaultSmtp, customerLevel]);

	useEffect(() => {
		events.handleMessageServiceValueChange(1, 'messageServiceProvider');
		setEnableMessageSettings(!props.data.defaultMessageService || customerLevel === CustomerLevelEnum.OEM);
	}, [props.data.defaultMessageService, customerLevel]);

	const events = {
		handleSmtpValueChange: async (value: SmtpChangeValue, key: SmtpChangeKey) => {
			const newState = { ...props.data };
			if (key === 'defaultSmtp') {
				newState[key] = value as boolean;
				setupValidations(
					(value as boolean) && customerLevel !== CustomerLevelEnum.OEM,
					props.data.defaultMessageService
				);
			} else if (key === 'port') {
				newState[key] = value as number;
			} else {
				newState[key] = value as string;
			}

			props.changeDataCallback(newState);
		},
		handleMessageServiceValueChange: async (value: MessageServiceChangeValue, key: MessageServiceChangeKey) => {
			const newState = { ...props.data };
			if (key === 'defaultMessageService') {
				newState[key] = value as boolean;
				setEnableMessageSettings(!value as boolean);
				setupValidations(props.data.defaultSmtp, (value as boolean) && customerLevel !== CustomerLevelEnum.OEM);
			} else if (key === 'messageServiceProvider') {
				newState[key] = value as number;
			} else {
				newState[key] = value as string;
			}

			props.changeDataCallback(newState);
		},
	};

	const setupValidations = async (smtpDefaultValue: boolean, messageServiceDefaultValue: boolean) => {
		if (smtpDefaultValue && messageServiceDefaultValue) {
			props.setValidatorCallback(
				new Validator({
					fieldRules: {
						defaultSmtp: {
							rules: { required: ValidatorFunctions.required() },
						},
						defaultMessageService: {
							rules: { required: ValidatorFunctions.required() },
						},
					},
				})
			);
		} else if (smtpDefaultValue) {
			props.setValidatorCallback(
				new Validator({
					fieldRules: {
						defaultSmtp: {
							rules: { required: ValidatorFunctions.required() },
						},
						...MessageServiceValidationConfig(),
					},
				})
			);
		} else if (messageServiceDefaultValue) {
			props.setValidatorCallback(
				new Validator({
					fieldRules: {
						defaultMessageService: {
							rules: { required: ValidatorFunctions.required() },
						},
						...SmtpServiceValidationConfig(),
					},
				})
			);
		} else {
			props.setValidatorCallback(
				new Validator({
					fieldRules: {
						...SmtpServiceValidationConfig(),
						...MessageServiceValidationConfig(),
					},
				})
			);
		}
	};

	return (
		<Accordion
			scrollToTop={props.scrollToTop}
			expandedCallback={(index) => {
				setExpandedSection(index);
			}}
		>
			<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.smtpMailServer')}>
				<div className="view-section-wrapper">
					{customerLevel !== CustomerLevelEnum.OEM ? (
						<div className="form-group">
							<SwitchComponent
								id={'defaultSmtp'}
								name={'defaultSmtp'}
								value={props.data.defaultSmtp}
								placeholder={TranslateText('fields.defaultSmtp')}
								change={(value) => events.handleSmtpValueChange(value.checked, 'defaultSmtp')}
								readonly={!props.editMode}
								enabled={props.editMode}
							/>
						</div>
					) : null}
					{enableSmtpSettings ? (
						<>
							<div className="form-group">
								<TextField
									id="server"
									type="text"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.server')}
									inputProps={{
										readOnly: !props.editMode || !enableSmtpSettings,
										style: { fontSize: 12 },
									}}
									name="server"
									value={props.data.server}
									onChange={(e) => {
										if (e.target) {
											events.handleSmtpValueChange(e.target.value, 'server');
										}
									}}
									disabled={!(props.editMode && enableSmtpSettings)}
								/>
								<ValidationMessage result={props.validationResult?.server} />
							</div>
							<div className="form-group">
								<TextField
									id="port"
									type="number"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.port')}
									inputProps={{
										readOnly: !props.editMode || !enableSmtpSettings,
										style: { fontSize: 12 },
									}}
									name="port"
									value={props.data.port > 0 ? props.data.port : null}
									onChange={(e: any) => {
										if (e.target) {
											events.handleSmtpValueChange(e.target.value, 'port');
										}
									}}
									disabled={!(props.editMode && enableSmtpSettings)}
								/>
								<ValidationMessage result={props.validationResult?.port} />
							</div>
							<div className="form-group">
								<TextField
									id="username"
									type="text"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.username')}
									inputProps={{
										readOnly: !props.editMode || !enableSmtpSettings,
										style: { fontSize: 12 },
									}}
									name="username"
									value={props.data.username}
									onChange={(e) => {
										if (e.target) {
											events.handleSmtpValueChange(e.target.value, 'username');
										}
									}}
									disabled={!(props.editMode && enableSmtpSettings)}
								/>
								<ValidationMessage result={props.validationResult?.username} />
							</div>
							<div className="form-group">
								<TextField
									id="password"
									type="password"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.password')}
									inputProps={{
										readOnly: !props.editMode || !enableSmtpSettings,
										style: { fontSize: 12 },
									}}
									name="password"
									value={props.data.password}
									onChange={(e) => {
										if (e.target) {
											events.handleSmtpValueChange(e.target.value, 'password');
										}
									}}
									disabled={!(props.editMode && enableSmtpSettings)}
								/>
								<ValidationMessage result={props.validationResult?.password} />
							</div>
						</>
					) : null}
				</div>
			</AccordionSection>
			<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.messageService')}>
				{customerLevel !== CustomerLevelEnum.OEM ? (
					<div className="form-group space-between">
						<span style={{ color: props.editMode ? 'black' : 'lightgray' }}>
							{TranslateText('fields.defaultMessageService')}
						</span>
						<Switch
							id={'defaultMessageService'}
							name={'defaultMessageService'}
							defaultChecked={!enableMessageSettings}
							onChange={(e) =>
								events.handleMessageServiceValueChange(e.target.checked, 'defaultMessageService')
							}
							// checked={props.data.defaultMessageService}
							placeholder={TranslateText('fields.defaultSmtp')}
							disabled={!props.editMode}
						/>
					</div>
				) : null}
				{enableMessageSettings ? (
					<>
						<div className={'form-group'}>
							<InputLabel style={{ fontSize: 12 }}>{TranslateText('fields.service')}</InputLabel>
							<Select
								readOnly={true}
								value={MessageServiceProviderEnum.MessageBird}
								defaultValue={MessageServiceProviderEnum.MessageBird}
								label={'Service provider'}
								style={{ fontSize: 12 }}
								onChange={() => {}}
								id="apiKey"
								type="text"
								className="resize-font"
								fullWidth
								disabled={!props.editMode && enableMessageSettings}
							>
								<MenuItem value={MessageServiceProviderEnum.MessageBird}>MessageBird</MenuItem>
							</Select>
						</div>
						{props.data.messageServiceProvider != MessageServiceProviderEnum.None ? (
							<>
								<div className={'form-group'}>
									<TextField
										id="apiKey"
										type="text"
										className="resize-font"
										fullWidth
										label={TranslateText('fields.apiKey')}
										inputProps={{
											readOnly: !props.editMode || !enableMessageSettings,
											style: { fontSize: 12 },
										}}
										name="server"
										value={props.data.apiKey}
										onChange={(e) => {
											if (e.target) {
												events.handleMessageServiceValueChange(e.target.value, 'apiKey');
											}
										}}
										disabled={!(props.editMode && enableMessageSettings)}
									/>
									<ValidationMessage result={props.validationResult?.apiKey} />
								</div>
							</>
						) : null}
					</>
				) : null}
			</AccordionSection>
		</Accordion>
	);
};

export default MessageServiceSettingsView;
