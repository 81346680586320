import React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { ProcessedTripDto } from '../../store/HistoryStore';
import HistoryParkedTripCard from './HistoryParkedTripCard';
import HistoryTripCard from './HistoryTripCard';
import { HistoryTripCardPropsRef, ProcessedTripCard, TripCardCommonProps } from './types';
import { UpdateObservable } from './utils';

interface Props {
	tripConfig: ProcessedTripCard;
	ungrouped?: boolean;
	groupFinishedExpanding: boolean;
	scrollTrip: { idWithParent: string | null } | null;

	cardVisibilityChanged: React.MutableRefObject<(visible: boolean, trip: ProcessedTripDto) => void>;
	selectionCallback: React.MutableRefObject<(trip: ProcessedTripDto, controlPressed: boolean) => void>;
	historyTripCardPropsRef: React.MutableRefObject<HistoryTripCardPropsRef>;
	tripCardCommonPropsRef: React.MutableRefObject<TripCardCommonProps>;
	tripsObservable?: UpdateObservable;
}

const HistoryOverviewTripCard = (props: Props) => {
	const isHighlighted = useSelector((s: ApplicationState) =>
		s.historyStore.highlightedTripIds?.some((st) => st === props.tripConfig.trip.idWithParent)
	);

	return (
		<>
			{props.tripConfig.trip.isParked ? (
				<HistoryParkedTripCard
					key={props.tripConfig.trip.idWithParent + 'pt'}
					tripConfig={props.tripConfig.trip}
					ungrouped={props.ungrouped}
					groupFinishedExpanding={props.groupFinishedExpanding}
					scrollTrip={props.scrollTrip}
					historyTripCardPropsRef={props.historyTripCardPropsRef}
					tripsObservable={props.tripsObservable}
				/>
			) : (
				<HistoryTripCard
					key={props.tripConfig.trip.idWithParent + 'ht'}
					tripConfig={props.tripConfig}
					selected={isHighlighted}
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						props.selectionCallback.current &&
							props.selectionCallback.current(props.tripConfig.trip, e.ctrlKey);
					}}
					onVisibilityChanged={(visible) => {
						props.cardVisibilityChanged.current &&
							props.cardVisibilityChanged.current(visible, props.tripConfig.trip);
					}}
					groupFinishedExpanding={props.groupFinishedExpanding}
					scrollTrip={props.scrollTrip}
					historyTripCardPropsRef={props.historyTripCardPropsRef}
					tripCardCommonPropsRef={props.tripCardCommonPropsRef}
					tripsObservable={props.tripsObservable}
				/>
			)}
		</>
	);
};

export default React.memo(HistoryOverviewTripCard);
