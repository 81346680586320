import './oldWidget.scss';

import React from 'react';
import { connect } from 'react-redux';

import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { StateData } from '../../models/StateData';
import DateTimeUtil, { DateTimeConversionUtil } from '../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import { OldWidgetProps } from './Widget';
import TripTypesEnum from '../../models/TripTypesEnum';

type Props = OldWidgetProps & {
	renderFor: EntityTypeEnum;
	driverIdentification: boolean;
	isAsset: boolean;
	tripTypes: TripTypesEnum;
};

type State = {
	stateData: StateData;
	visible: boolean;
};

class StateWidget extends BaseWidget<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			stateData: new StateData(),
			visible: true,
		};
	}

	fillData(entityId: string) {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				ajaxUtil.get<StateData>(`${GlobalSettings.objectsMaintenanceApi}/${entityId}/state`).then((data) => {
					this.setState({
						stateData: data ? data : new StateData(),
					});
				});
			}
		}
	}

	componentDidMount() {
		this.fillData(this.props.entityId);
	}

	reloadData() {
		this.fillData(this.props.entityId);
	}

	formatSatellitesData(): string {
		if (this.state.stateData.messageContent.validatedGpsPosition == undefined) {
			return '';
		}

		let validated = TranslateText('common.invalid');
		if (this.state.stateData.messageContent.validatedGpsPosition) {
			validated = TranslateText('common.valid');
		}
		const result = this.state.stateData.messageContent.satelliteCount + ' (' + validated + ')';
		return result;
	}

	formatIgnitionState(): string {
		if (this.state.stateData.messageContent.ignitionState == undefined) {
			return '';
		}

		let result = TranslateText('common.off');
		if (this.state.stateData.messageContent.ignitionState) {
			result = TranslateText('common.on');
		}
		return result;
	}

	formatBusiness(): string {
		if (this.state.stateData.messageContent.businessState == undefined) {
			return '';
		}

		return TranslateText('common.business');
	}

	render() {
		return (
			<div
				id="entityState"
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
			>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.status')}
							showEditButton={false}
							allowEditMode={true}
							removePanelCallback={() => this.setState({ visible: false })}
						/>
					</div>
					<div className="e-panel-content">
						<table className="StateTable">
							<tbody>
								<tr>
									<td>{TranslateText('fields.lastMessageReceived')}</td>
									<td>
										<MaterialDatePicker
											name="receivedDateTime"
											showTime={true}
											date={this.state.stateData.messageContent.receivedDateTime}
											format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
												DateTimeUtil.dateTimeFormat(),
												true
											)}
											disabled={true}
											fullWidth={true}
										/>
									</td>
								</tr>
								<tr>
									<td>{TranslateText('fields.ignitionState')}</td>
									<td>{this.formatIgnitionState()}</td>
								</tr>
								<tr>
									<td>{TranslateText('fields.numberOfSatellites')}</td>
									<td>{this.formatSatellitesData()}</td>
								</tr>
								{this.state.stateData.customerTrackTypeSpecification &&
								!!(this.props.tripTypes & TripTypesEnum.Private) ? (
									<tr>
										<td>{TranslateText('fields.privateBusinessSwitchState')}</td>
										<td>{this.formatBusiness()}</td>
									</tr>
								) : null}
								<tr>
									<td>{TranslateText('fields.currentLocation')}</td>
									<td>{this.state.stateData.messageContent.location}</td>
								</tr>
								{this.props.renderFor !== EntityTypeEnum.Object && (
									<tr>
										<td>{TranslateText('common.object')}</td>
										<td>{this.state.stateData.objectData}</td>
									</tr>
								)}
								{this.props.renderFor !== EntityTypeEnum.Device && (
									<tr>
										<td>{TranslateText('common.device')}</td>
										<td>{this.state.stateData.deviceData}</td>
									</tr>
								)}
								{this.props.renderFor !== EntityTypeEnum.Person &&
									this.props.driverIdentification &&
									!this.props.isAsset && (
										<tr>
											<td>{TranslateText('common.driver')}</td>
											<td>{this.state.stateData.person}</td>
										</tr>
									)}
								<tr>
									<td>{TranslateText('common.subscription')}</td>
									<td>{this.state.stateData.subscription}</td>
								</tr>
								<tr>
									<td>{TranslateText('common.simData')}</td>
									<td>{this.state.stateData.simNumber}</td>
								</tr>
								<tr>
									<td>{TranslateText('fields.resultLastTest')}</td>
									<td>{this.state.stateData.messageContent.resultLastTest}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		driverIdentification: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification,
		tripTypes: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: state.currentSession.customer.featuresSettings.tripTypes,
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(StateWidget);
