import './PersonSettingsPassword.scss';

import { Button, Card, CardContent, FormControl, Grid, Typography } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AutoTextField from 'components/AutoTextfield/AutoTextField';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldRules, ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

const PersonSettingsPasswordValidationConfig = (aspNetUserId: string): FieldRules => {
	const regex = /^(?=.*\d)*(?=.*[a-z])(?=.*[A-Z])(?=.*[,.?:;'""!@#$%^&*\\]).{10,}$/g;
	return {
		currentPassword: {
			rules: {
				required: ValidatorFunctions.required(),
				valid: {
					message: TranslateText('personSettingsPassword.currentPasswordValidation'),
					validationFunction: async (data) => {
						if (data.currentPassword && data.currentPassword.length > 0) {
							const url = `${GlobalSettings.profileMaintenanceApi}/verifyPassword`;
							const body = { userId: aspNetUserId, password: data.currentPassword };
							const result = (await ajaxUtil.post(url, body)) as boolean;
							return ValidatorFunctions.wrapInPromise(result);
						}
					},
				},
			},
		},
		newPassword: {
			rules: {
				required: ValidatorFunctions.required(),
				regex: {
					message: TranslateText('personSettingsPassword.regexPassword'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(new RegExp(regex).test(data.newPassword)),
				},
				valid: {
					message: TranslateText('personSettingsPassword.existingPasswordValidation'),
					validationFunction: async (data) => {
						if (data.newPassword && data.newPassword.length > 0) {
							const url = `${GlobalSettings.profileMaintenanceApi}/verifyPassword`;
							const body = { userId: aspNetUserId, password: data.newPassword };
							const result = !((await ajaxUtil.post(url, body)) as boolean);
							return ValidatorFunctions.wrapInPromise(result);
						}
					},
				},
			},
		},
		confirmNewPassword: {
			rules: {
				required: ValidatorFunctions.required(),
				valid: {
					message: TranslateText('personSettingsPassword.newPasswordValidation'),
					validationFunction: (data) => {
						if (data.newPassword !== data.confirmNewPassword) {
							return ValidatorFunctions.wrapInPromise(false);
						}
						return ValidatorFunctions.wrapInPromise(true);
					},
				},
			},
		},
	};
};

interface Props {
	changeTab: (value: number) => void;
	setUnsavedData: (value: boolean) => void;
	unsavedData: boolean;
}

const PersonSettingsPassword = (props: Props) => {
	const aspNetUserId = useSelector((s: ApplicationState) => s.currentSession.aspNetUserId);

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);
	const [validator, setValidator] = useState(
		new Validator({ fieldRules: PersonSettingsPasswordValidationConfig(aspNetUserId) }, true)
	);

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

	useEffect(() => {
		if (
			!props.unsavedData &&
			(currentPassword?.length > 0 || newPassword?.length > 0 || confirmNewPassword?.length > 0)
		) {
			props.setUnsavedData(true);
			window.onbeforeunload = () => {
				return 'Unsaved data';
			};
		}
		validator
			.validate({
				currentPassword: currentPassword,
				newPassword: newPassword,
				confirmNewPassword: confirmNewPassword,
			})
			.then((result) => {
				setValidationResult(result.validationResult);
				setIsValid(result.formResult);
			});
	}, [currentPassword, newPassword, confirmNewPassword]);

	const changePassword = () => {
		const url = `${GlobalSettings.profileMaintenanceApi}/changePassword`;
		const body = { userId: aspNetUserId, password: newPassword };
		ajaxUtil.post(url, body).then(() => {
			props.setUnsavedData(false);
			props.changeTab(0);
			window.onbeforeunload = null;
		});
	};

	return (
		<Grid item xs={4} sm={4} md={4}>
			<Card className={'card-password'}>
				<CardContent>
					<Typography variant="h5" className={'change-password'}>
						{TranslateText('personSettingsDashboard.changePassword')}
					</Typography>
					<div className={'password-form'}>
						<FormControl className={'margin-bottom'}>
							<div className={'password-field'}>
								<AutoTextField
									value={currentPassword}
									onChange={(value) => setCurrentPassword(value)}
									label={TranslateText('personSettingsPassword.currentPassword')}
									type={showCurrentPassword ? 'text' : 'password'}
									fullWidth
									hasStyles
									style={{ fontSize: 12 }}
									showClearButton={false}
									debounceDuration={500}
								/>
								{showCurrentPassword ? (
									<VisibilityOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowCurrentPassword(false)}
									/>
								) : (
									<VisibilityOffOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowCurrentPassword(true)}
									/>
								)}
							</div>
							<ValidationMessage result={validationResult?.currentPassword} />
						</FormControl>
						<FormControl>
							<div className={'password-field'}>
								<AutoTextField
									value={newPassword}
									onChange={(value) => setNewPassword(value)}
									label={TranslateText('personSettingsPassword.newPassword')}
									type={showNewPassword ? 'text' : 'password'}
									fullWidth
									hasStyles
									style={{ fontSize: 12 }}
									showClearButton={false}
									debounceDuration={500}
								/>
								{showNewPassword ? (
									<VisibilityOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowNewPassword(false)}
									/>
								) : (
									<VisibilityOffOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowNewPassword(true)}
									/>
								)}
							</div>
							<ValidationMessage result={validationResult?.newPassword} />
						</FormControl>
						<FormControl>
							<div className={'password-field'}>
								<AutoTextField
									value={confirmNewPassword}
									onChange={(value) => setConfirmNewPassword(value)}
									label={TranslateText('personSettingsPassword.confirmNewPassword')}
									type={showConfirmNewPassword ? 'text' : 'password'}
									fullWidth
									hasStyles
									style={{ fontSize: 12 }}
									showClearButton={false}
									debounceDuration={500}
								/>
								{showConfirmNewPassword ? (
									<VisibilityOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowConfirmNewPassword(false)}
									/>
								) : (
									<VisibilityOffOutlinedIcon
										className={'eye-icon'}
										onClick={() => setShowConfirmNewPassword(true)}
									/>
								)}
							</div>
							<ValidationMessage result={validationResult?.confirmNewPassword} />
						</FormControl>
						<Button
							style={{
								backgroundColor: !isValid ? '#999999' : '#006837',
							}}
							className={'save-button'}
							disabled={!isValid}
							onClick={changePassword}
						>
							{TranslateText('personSettingsPassword.savePassword')}
						</Button>
					</div>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default PersonSettingsPassword;
