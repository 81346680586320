import { ButtonConfig } from 'components/RadioButtonContainer';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { SelectionFilter } from 'components/TreeSelectionDialog/TreeSelectionDialog';
import { AlertData, AlertEntity } from 'components/Widgets/Views/AlertEntitiesView/types';
import AlertEntityTypeEnum from 'models/AlertEntityTypeEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';
import TreeConfigurationUtil from 'utils/TreeSelection/TreeConfigurationUtil';

import GlobalSettings from '../../../../GlobalSettings.json';
import { Entity } from '../../Views/AlertEntitiesView/AlertEntity/types';

const useTreeDialogEvents = (
	alertId: string,
	alertEntityType: AlertEntityTypeEnum,
	driverIdentification: boolean,
	assetTracking: boolean,
	currentSelection: AlertEntity[],
	actionExecutedCallback: (value: boolean) => void,
	setDialogVisible: (value: boolean) => void
) => {
	const closeDialogCallback = useCallback((): void => {
		setDialogVisible(false);
	}, []);

	const showDialogCallback = useCallback((): void => {
		setDialogVisible(true);
	}, []);

	const saveSelectionCallback = async (selection: ITreeNode[]): Promise<void> => {
		const removedEntities: AlertEntity[] = [];
		const newEntities: AlertEntity[] = [];

		// get removed entries
		currentSelection.forEach((selectedItem: AlertEntity) => {
			if (!selection.some((item: ITreeNode) => item.id === selectedItem.entityId)) {
				removedEntities.push({ ...selectedItem });
			}
		});

		//get added entries
		selection.forEach((item: ITreeNode) => {
			if (!currentSelection.some((selectedItem: AlertEntity) => item.id === selectedItem.entityId)) {
				newEntities.push({
					entityId: item.id,
					entityName: item.text,
					entityType: EntityTypeEnum[item.type as keyof typeof EntityTypeEnum],
					active: item.active,
					objectChildren: [],
					personChildren: [],
				});
			}
		});

		if (!!newEntities || !!removedEntities) {
			//update connections
			await ajaxUtil.put(`${GlobalSettings.alertsApi}/${alertId}/alertConnections`, {
				newEntities,
				removedEntities,
			});
		}

		setDialogVisible(false);
		actionExecutedCallback(true);
	};

	const retrieveDataCallback = async (filter: SelectionFilter): Promise<ITreeNode[]> => {
		const showPersons: boolean = driverIdentification && alertEntityType === AlertEntityTypeEnum.Drivers;
		const showObjects: boolean = alertEntityType === AlertEntityTypeEnum.Objects;

		const postResult = await ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.alertsApi}/getGroupPersonSelectionData`, {
			customerId: filter.customerId,
			showInactive: filter.showInactive,
			filterText: filter.filterText,
			objectFunction:
				filter?.selectedFilter in FleetEntityTypeFilter
					? TreeConfigurationUtil.getObjectFunctionFilter(filter.selectedFilter, assetTracking)
					: ObjectFunctionFilter.Full,
			showObjects,
			showPersons,
			personType: PersonTypeFilterEnum.Driver,
		});

		if (!postResult || postResult.length === 0) {
			return [];
		}

		return postResult;
	};

	const [filterButtons, setFilterButtons] = useState<ButtonConfig[]>([]);
	useEffect(() => {
		if (alertEntityType === AlertEntityTypeEnum.Objects && assetTracking) {
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.all,
					FleetEntityTypeFilter.object,
					FleetEntityTypeFilter.asset,
				])
			);
		}
	}, [alertEntityType, assetTracking]);

	return {
		filterButtons,
		closeDialogCallback,
		showDialogCallback,
		saveSelectionCallback,
		retrieveDataCallback,
	};
};

const useInitialTreeData = (currentSelection: AlertData) => {
	const mapToTreeNode = (alertData: AlertData): ITreeNode[] => {
		const treeNodeSelection: ITreeNode[] = [];
		const entityMemberList = alertData.trackedEntities;
		if (!entityMemberList) {
			return [];
		}

		entityMemberList.forEach((item: AlertEntity) => {
			if (item.entityType === EntityTypeEnum.Group) {
				const itemChildren: Entity[] =
					alertData.entityType === AlertEntityTypeEnum.Objects ? item.objectChildren : item.personChildren;

				treeNodeSelection.push({
					id: item.entityId,
					text: item.entityName,
					active: item.active,
					type: item.entityType,
					selected: true,
					items: itemChildren.map((item: Entity) => ({
						id: item.entityId,
						text: item.entityName,
						active: item.active,
						type: item.entityType,
						icon: item.icon,
						selected: true,
						canBeChecked: true,
						childCount: 0,
					})),
					childCount: itemChildren.length,
					canBeChecked: true,
				});

				return;
			}

			if (
				(alertData.entityType === AlertEntityTypeEnum.Objects &&
					(item.entityType === EntityTypeEnum.Object || item.entityType === EntityTypeEnum.Asset)) ||
				(alertData.entityType === AlertEntityTypeEnum.Drivers && item.entityType === EntityTypeEnum.Driver)
			) {
				treeNodeSelection.push({
					id: item.entityId,
					text: item.entityName,
					active: item.active,
					type: item.entityType,
					selected: true,
					icon: item.icon,
					items: [],
					childCount: 0,
					canBeChecked: true,
				});
			}
		});
		return treeNodeSelection;
	};

	return mapToTreeNode(currentSelection as AlertData);
};

const useFilterPlaceholder = (alertEntityType: AlertEntityTypeEnum, assetTracking: boolean) => {
	const filterPlaceholder = useRef<string>('');

	const user = useSelector((state: ApplicationState) => state.oidc.user);

	useEffect(() => {
		filterPlaceholder.current =
			alertEntityType === AlertEntityTypeEnum.Drivers
				? TranslateText('fleetSelection.searchDriverPlaceholder')
				: assetTracking
				? TranslateText('fleetSelection.searchEntitiesPlaceholder')
				: TranslateText('fleetSelection.searchObjectPlaceholder');
	}, [user]);

	return filterPlaceholder.current;
};

export { useFilterPlaceholder, useInitialTreeData, useTreeDialogEvents };
