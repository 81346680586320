import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import { Person } from 'models/Person';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import { getEmptyPersonValidationRules, getNotEmptyPersonValidationRules } from './constants';
import PersonLoginData from './Forms/PersonLoginData';
import PersonPersonalData from './Forms/PersonPersonalData';
import PersonSystemInformation from './Forms/PersonSystemInformation';
import { getCommonValidationRules } from './PersonUtils';

type Props = {
	setPerson: (valid: boolean, person: Person) => void;
	setValidationStatus: (inProgress: boolean) => void;
	scrollToTop?: () => void;
	readOnly: boolean;
	defaultCustomerId: string;
	person: Person;
};

const PersonWidgetView = (props: Props) => {
	const entityDefaultsSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.entityDefaultsSettings
			: state.currentSession.customer.entityDefaultsSettings
	);
	const customerLevel = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel
	);
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	const [newPersonSelectedAccordionSectionIndex, setNewPersonSelectedAccordionSectionIndex] = useState<number>(0);
	const [person, setPerson] = useState<Person>(props.person);
	const [validator, setValidator] = useState<Validator | null>(null);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);

	useEffect(() => {
		if (!props.readOnly) {
			setTimeout(() => {
				const focusElement = document.getElementById('person-code');
				if (focusElement) {
					focusElement.focus();
				}
			}, 400);
		}
	}, [props.readOnly]);

	//setup validator first time we are in edit mode
	useEffect(() => {
		if (!props.readOnly) {
			const formValidator = getCommonValidationRules(user);
			formValidator.addRules(
				!!person.username ? getNotEmptyPersonValidationRules() : getEmptyPersonValidationRules()
			);
			setValidator(formValidator);

			return;
		}

		setUnsavedData(false);
	}, [props.readOnly]);

	useEffect(() => {
		const setValidation = async () => {
			if (!validator) {
				return;
			}

			if (!props.readOnly) {
				const validationResult = await validator.validate(person);

				setValidationResult(validationResult.validationResult);

				if (unsavedData) {
					props.setPerson(validationResult.formResult, person);
					return;
				}

				props.setValidationStatus(validationResult.formResult);
				return;
			}

			validator.clearValidation();
			setValidationResult(null);
		};

		setValidation();
	}, [props.readOnly, validator, person]);

	useEffect(() => {
		setPerson(props.person);
	}, [props.person]);

	const handleValueChange = (changedPerson: Person) => {
		if (!changedPerson.username && !!person.username) {
			changedPerson.password = '';
		}

		setUnsavedData(true);
		setPerson(changedPerson);
	};

	return (
		<div>
			<Accordion
				scrollToTop={props.scrollToTop}
				expandedCallback={(index) => {
					setNewPersonSelectedAccordionSectionIndex(index);
				}}
			>
				<AccordionSection
					expanded={newPersonSelectedAccordionSectionIndex === 0}
					header={TranslateText('common.systemInformation')}
				>
					<PersonSystemInformation
						user={user}
						readOnly={props.readOnly}
						defaultCustomerId={person.customerId}
						entityDefaultsSettings={entityDefaultsSettings}
						handleValueChange={handleValueChange}
						person={person}
						validationResult={validationResult}
						editableObject={false}
						isLoading={false}
						renderForInformationWidget={true}
					/>
				</AccordionSection>
				<AccordionSection
					expanded={newPersonSelectedAccordionSectionIndex === 1}
					header={TranslateText('common.personalData')}
				>
					<PersonPersonalData
						readOnly={props.readOnly}
						handleValueChange={handleValueChange}
						person={person}
						validationResult={validationResult}
					/>
				</AccordionSection>
				<AccordionSection
					expanded={newPersonSelectedAccordionSectionIndex === 2}
					header={TranslateText('common.loginData')}
				>
					<PersonLoginData
						readOnly={props.readOnly}
						customerLevel={customerLevel}
						handleValueChange={handleValueChange}
						person={person}
						validationResult={validationResult}
					/>
				</AccordionSection>
			</Accordion>
		</div>
	);
};

export default PersonWidgetView;
