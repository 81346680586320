import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Marker } from '@react-google-maps/api';

import TripStartImage from '../../assets/icons/TripStart.svg';
import ClaimType from '../../authorization/ClaimType';
import PrivacySettingsEnum from '../../models/PrivacySettingsEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { ApplicationState } from '../../store';
import { HiddenTripDto, historyStoreActionCreators, ProcessedTripDto } from '../../store/HistoryStore';

interface Props {
	trip: ProcessedTripDto;
	prevConnectedVisible?: boolean;
	previousTrip: ProcessedTripDto | HiddenTripDto;
	map: google.maps.Map;
	renderForTableView?: boolean;
	tripIndex?: number;
	higherZIndex?: boolean;
}

const StartTripMarker = (props: Props) => {
	const dispatch = useDispatch();
	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);

	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const showPrivateTrips = useSelector(
		(state: ApplicationState) => !!state.oidc.user?.profile[ClaimType.ShowPrivateTrips]
	);
	const showPrivateData = customerPrivacySettings === PrivacySettingsEnum.ShowPrivateData || showPrivateTrips;

	const handleVisitClick = (controlPressed: boolean) => {
		dispatch(
			historyStoreActionCreators.showPreviousVisitOnMap(
				props.trip.idWithParent,
				controlPressed,
				props.previousTrip?.idWithParent
			)
		);
	};

	if (
		!props.trip.visible ||
		(props.trip.prevConnectedTripId &&
			props.prevConnectedVisible &&
			(showPrivateData ||
				props.previousTrip?.personId === personId ||
				props.previousTrip?.trackType !== TripTypesEnum.Private))
	) {
		return null;
	}

	return (
		<Marker
			zIndex={(props.higherZIndex ? 1000 : 100) + (props.tripIndex ?? 0)}
			onClick={
				!props.renderForTableView
					? (e) => {
							handleVisitClick((e.domEvent as MouseEvent).ctrlKey);
					  }
					: null
			}
			position={props.trip.start}
			key={`first-${props.trip.idWithParent}`}
			options={{
				icon: {
					url: TripStartImage,
					scaledSize: new google.maps.Size(21, 21),
					anchor: new google.maps.Point(12, 12),
				},
			}}
		/>
	);
};

export default StartTripMarker;
