import './accountMenu.scss';

import { knownHelpClaimRoute, knownHelpFiles, openInNewTab } from 'components/Help/HelpButton';
import { checkFeatureSettings } from 'components/Help/HelpOverview';
import { StringConstants } from 'components/Widgets/CustomerFeaturesView';
import { Profile } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SET_HELP_FILE_NAME } from 'store/LeftSideBar';

import { Menu, MenuItem } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState, CustomerFeaturesState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import PersonSettingsWindows from '../PersonSettings/PersonSettingsWindows';

const getClaims = (userProfile: Profile, customerFeatureSettings: CustomerFeaturesState): string[] => {
	const claims: string[] = [];
	for (const name in userProfile) {
		if (
			userProfile.hasOwnProperty(name) &&
			name?.startsWith(StringConstants.easytrack) &&
			name?.split('.').length === 2
		) {
			const route = knownHelpClaimRoute[name?.substr(name?.indexOf('.') + 1).toLowerCase()];
			if (route && checkFeatureSettings(route, customerFeatureSettings)) {
				claims.push(route);
			}
		}
	}
	return claims;
};

const getCorrectHelpPath = (path: string, claims: string[]): string => {
	const helpPath = path.toLowerCase();
	const help = knownHelpFiles.find((path) => helpPath?.startsWith(path))?.replace('/', '');
	if (!help && claims?.length > 0) {
		const firstTab = knownHelpFiles.find((khf) => claims.includes(khf.replace('/', '')))?.replace('/', '');
		return firstTab ?? knownHelpFiles[0]?.replace('/', '');
	}
	return help;
};

const AccountMenu = () => {
	const username = useSelector((state: ApplicationState) => state.currentSession.userFirstAndLastName);
	const customerName = useSelector((state: ApplicationState) => state.currentSession.customer?.name);
	const aspNetUserId = useSelector((state: ApplicationState) => state.currentSession.aspNetUserId);
	const usernameAbbreviation = useSelector(
		(state: ApplicationState) => state.currentSession.userFirstAndLastNameAbbreviation
	);
	const mainMenuExpanded = useSelector((s: ApplicationState) => s.leftSideBar.leftBarState?.isExpanded);
	const personId = useSelector((s: ApplicationState) => s.currentSession.personId);
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const [displaySettings, setDisplaySettings] = useState(false);

	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleUserClick = (aspNetUserId: string) => {
		const url = `${GlobalSettings.personsMaintenanceApi}/${aspNetUserId}`;
		ajaxUtil.get(url).then((data: any) => {
			if (data.id) {
				history.push('/persons/' + data.id);
			}
		});
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logoutUser = () => {
		ajaxUtil.post(`${GlobalSettings.globalDataApi}/${personId}/updatePersonMainMenuExpanded`, {
			mainMenuExpanded: mainMenuExpanded,
			personId: personId,
		});
		history.push('/logout');
	};

	const userProfile = useSelector((s: ApplicationState) => s.oidc.user.profile);
	const customerFeatureSettings = useSelector(
		(state: ApplicationState) => state.currentSession.customer.featuresSettings
	);

	const [displayHelp, setDisplayHelp] = useState(false);
	const [claims, setClaims] = useState([]);

	useEffect(() => {
		const claims = getClaims(userProfile, customerFeatureSettings);
		setClaims(claims);
		if (claims?.length > 0) {
			setDisplayHelp(true);
		}
	}, []);

	return (
		<div className="account-menu">
			<span className="user">
				<span className={'user-name'}>{username}</span>
				<span className={'user-customer'}> {customerName}</span>
			</span>
			<span className="circle-profile-menu" onClick={handleClick}>
				<span className={'circle-profile-menu-name'}>{usernameAbbreviation}</span>
			</span>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<div hidden={true}>
					<MenuItem onClick={() => handleUserClick(aspNetUserId)}>
						{TranslateText('menu.accountSection.profile')}
					</MenuItem>
				</div>
				<MenuItem
					onClick={() => {
						setDisplaySettings(true);
						handleClose();
					}}
				>
					{TranslateText('personSettingsWindow.settings')}
				</MenuItem>
				{displayHelp && (
					<MenuItem
						onClick={() => {
							dispatch({ type: SET_HELP_FILE_NAME, payload: getCorrectHelpPath(pathname, claims) });
							openInNewTab(StringConstants.helpRoute);
							handleClose();
						}}
					>
						{TranslateText('common.help')}
					</MenuItem>
				)}
				<MenuItem onClick={logoutUser}>{TranslateText('menu.accountSection.logout')}</MenuItem>
			</Menu>
			<PersonSettingsWindows
				visible={displaySettings}
				closeCallback={() => {
					setDisplaySettings(false);
				}}
			/>
		</div>
	);
};

export default AccountMenu;
