import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ProcessedTripDto } from 'store/HistoryStore';

import { LinearProgress } from '@material-ui/core';

import { FleetSelectionPlaceholder } from '../FleetSelectionPlaceholder';
import HistoryParkedTripCard from './HistoryParkedTripCard';
import { Group } from './HistorySideBar';
import HistoryTripGroup from './HistoryTripGroup';
import { useBatchDisplay, useTripCardCommonProps } from './hooks';
import { HistoryTripCardPropsRef } from './types';

const sortParkedTrips = (ungroupedTrips: ProcessedTripDto[]) => {
	return ungroupedTrips.sort((first, second) => {
		if (!first.isPerson && !second.isPerson) {
			return first.objectName.localeCompare(second.objectName);
		} else if (!first.isPerson && second.isPerson) {
			return first.objectName.localeCompare(second.personName);
		} else if (first.isPerson && !second.isPerson) {
			return first.personName.localeCompare(second.objectName);
		} else if (first.isPerson && second.isPerson) {
			return first.personName.localeCompare(second.personName);
		}
	});
};

interface Props {
	ungroupedTrips: ProcessedTripDto[];
	groupedTrips: Group[];
	groupVisibilityCallback: React.MutableRefObject<(group: Group, visible: boolean) => void>;
	cardVisibilityChanged: React.MutableRefObject<(visible: boolean, trip: ProcessedTripDto) => void>;
	selectionCallback: React.MutableRefObject<(trip: ProcessedTripDto, controlPressed: boolean) => void>;
	historyTripCardPropsRef: React.MutableRefObject<HistoryTripCardPropsRef>;
}

const HistoryTripContainer = (props: Props) => {
	const tripsLoading = useSelector((s: ApplicationState) => s.liveMap.loading);

	const appendToBatchDisplay = useBatchDisplay();

	const tripCardCommonProps = useTripCardCommonProps();

	return (
		<div className="trip-rows">
			{tripsLoading ? (
				<LinearProgress
					style={{
						backgroundColor: '#FFFFFF',
						marginLeft: '10px',
					}}
				/>
			) : (
				<>
					{sortParkedTrips(props.ungroupedTrips).map((parkedTrip: ProcessedTripDto) => (
						<HistoryParkedTripCard
							key={parkedTrip.id + 'upt'}
							tripConfig={parkedTrip}
							ungrouped
							groupFinishedExpanding
							scrollTrip={null}
							historyTripCardPropsRef={props.historyTripCardPropsRef}
						/>
					))}

					{props.groupedTrips.map((group: Group, index: number) => (
						<HistoryTripGroup
							appendToBatchDisplay={appendToBatchDisplay}
							index={index}
							key={group.name}
							group={group}
							groupVisibilityCallback={props.groupVisibilityCallback}
							selectionCallback={props.selectionCallback}
							cardVisibilityChanged={props.cardVisibilityChanged}
							parentGroupFinishedExpanding={true}
							scrollTrip={null}
							tripCardCommonProps={tripCardCommonProps}
							historyTripCardPropsRef={props.historyTripCardPropsRef}
						/>
					))}
					{<FleetSelectionPlaceholder />}
				</>
			)}
		</div>
	);
};

export default React.memo(HistoryTripContainer);
