import LocationPin from 'assets/icons/LocationPin.svg';
import LocationsMarkers from 'components/LocationsMarkers';
import { MARKER_URL } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityGroupingEnum from 'models/EntityGroupingEnum';
import { LocationMapDto } from 'models/LocationMapDto';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdLock, MdLockOpen } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	historyStoreActionCreators,
	HistoryTripTemplateDto,
	ProcessedTripDto,
	SET_HISTORY_MAP_OPTIONS
} from 'store/HistoryStore';
import { LiveDataResultDto } from 'store/LiveData';
import { LatLng, SET_LIVE_MAP_OPTIONS, SET_MAP_SELECTED_ENTITY } from 'store/LiveMap';
import ajaxUtil from 'utils/Ajax';
import { googleMapLibraries, googleMapScriptId, mapStartCoordinates } from 'utils/MapUtils';
import { TranslateText } from 'utils/Translations';
import { LocationOn, LocationOnOutlined } from '@material-ui/icons';
import {
	GoogleMap,
	InfoBox,
	Marker,
	MarkerClusterer,
	Polyline,
	TrafficLayer,
	useJsApiLoader,
} from '@react-google-maps/api';
import { Cluster, Clusterer, ClustererOptions, MarkerExtended } from '@react-google-maps/marker-clusterer';

import { useOnClickOutside } from '../../utils/hooks';
import LocationCategoryArea from '../LocationCategoryAreaOnMap/LocationCategoryArea';
import {
	useKeepInViewStateAndRef,
	useLocationsHistoryMap,
	useLocationsLiveMap,
	useMapCategories,
	useTemplatesHistoryMap
} from './hooks';
import LiveInfoClusterWindow from './LiveInfoClusterWindow';
import LiveInfoWindow from './LiveInfoWindow';
import RietveldHistoryTrips from './RietveldHistoryTrips';
import ChartMarker from "../ChartHistorySummary/ChartMarker/ChartMarker";
import GoogleMaterialIconOutlined from "../Common/Icons/GoogleMaterialIconOutlined";
import RietveldHistoryTemplates from "./RietveldHistoryTemplates";
import templateData from "../../models/TemplateData";

export interface MarkerConfig {
	position: LatLng;
	liveData: LiveDataResultDto;
	id: string;
}

const getPixelFromLatLng = (latLng: google.maps.LatLng, map: google.maps.Map) => {
	const projection = map.getProjection();
	return projection.fromLatLngToPoint(latLng);
};

const getClusterInfoBoxOptions = (position: google.maps.LatLng, map: google.maps.Map) => {
	const center = getPixelFromLatLng(map.getCenter(), map);
	const point = getPixelFromLatLng(position, map);
	if (point.y > center.y) {
		//bottom
		return {
			enableEventPropagation: true, //allow click on previous and next
			pixelOffset: new google.maps.Size(20, 12, 'px', 'px'),
			alignBottom: true,
			zIndex: 1000, //always on top
			closeBoxMargin: '0px',
			closeBoxURL: '',
			boxStyle: {
				top: 'unset',
			},
		};
	} else {
		//top
		return {
			enableEventPropagation: true, //allow click on previous and next
			pixelOffset: new google.maps.Size(20, -12, 'px', 'px'),
			alignBottom: false,
			zIndex: 1000, //always on top
			closeBoxMargin: '0px',
			closeBoxURL: '',
			boxStyle: {
				bottom: 'unset',
			},
		};
	}
};

const getSelectedClusterConfig = (currentCluster: ClusterMarkerConfig, clusters: Cluster[]) => {
	for (let i = 0; i < clusters.length; i++) {
		const cluster = clusters[i];

		if (currentCluster.markers.length === cluster.markers.length) {
			let sameMarkers = true;

			for (let j = 0; j < cluster.markers.length; j++) {
				const markerConfig = (cluster.markers[j] as any).markerConfig as MarkerConfig;
				if (!markerConfig || !currentCluster.markers.some((x) => x.id === markerConfig.id)) {
					sameMarkers = false;
					break;
				}
			}
			if (sameMarkers) {
				return {
					markers: cluster.markers.map((m: any) => m.markerConfig as MarkerConfig),
					position: cluster.center,
				};
			}
		}
	}

	return null;
};

const clusterStyles = {
	small: {
		width: 25,
		height: 25,
		textSize: 14,
		anchorIcon: [12.5, 12.5],
		textColor: '#fff',
	},
	medium: {
		width: 31,
		height: 31,
		textSize: 14,
		anchorIcon: [15.5, 15.5],
		textColor: '#fff',
	},
	large: {
		width: 39,
		height: 39,
		textSize: 18,
		anchorIcon: [19.5, 19.5],
		textColor: '#fff',
	},
};
const options = {
	clusterer: {
		maxZoom: null,
		gridSize: null,
		zoomOnClick: false,
		averageCenter: true,
		styles: [
			clusterStyles.small,
			clusterStyles.medium,
			clusterStyles.large,
			{
				...clusterStyles.small,
				className: 'selected',
			},
			{
				...clusterStyles.medium,
				className: 'selected',
			},
			{
				...clusterStyles.large,
				className: 'selected',
			},
		],
	} as ClustererOptions,
	map: {
		mapId: GlobalSettings.googleMapId,
		clickableIcons: false,
		gestureHandling: 'greedy',
		disableDoubleClickZoom: true,
		streetViewControl: true,
		tilt: 0,
		rotateControl: false,
	} as google.maps.MapOptions,
};

interface LiveMarkerOptions extends google.maps.MarkerOptions {
	markerConfig: MarkerConfig;
}

interface ClusterMarkerConfig {
	markers: MarkerConfig[];
	position: google.maps.LatLng;
	repaint?: boolean;
}

export interface MapPosition {
	bounds?: { mapBounds?: google.maps.LatLngBounds; offSet: boolean };
	center?: google.maps.LatLngLiteral;
}

export const TripColors = ['#fa6180', '#118ab2', '#97cc04', '#758afc', '#F45D01', '#00b1b1', '#d58100'];

export const TripIcons = {
	currentLocation:
		'M9.875,0.625C4.697,0.625,0.5,4.822,0.5,10s4.197,9.375,9.375,9.375S19.25,15.178,19.25,10S15.053,0.625,9.875,0.625',
	start:
		'M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z',
	end: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,17L17,12H14V8H10V12H7L12,17Z',
	visit:
		'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M6.5 9L10 5.5L13.5 9H11V13H9V9H6.5M17.5 15L14 18.5L10.5 15H13V11H15V15H17.5Z',
	tripNumber: 'M 3 2 H 22 V 22 H 3 Z',
};

const liveMaxZoomLevel = 13;
const historyMaxZoomLevel = 14;

interface Props {
	markers: MarkerConfig[];
	tripConfig: ProcessedTripDto[];
	templates: HistoryTripTemplateDto[];
	isLiveMap: boolean;
}

const RietveldMap = (props: Props) => {
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	const { isLoaded } = useJsApiLoader({
		id: googleMapScriptId,
		mapIds: [GlobalSettings.googleMapId],
		libraries: googleMapLibraries,
		version: '3',
		googleMapsClientId: GlobalSettings.googleMapId,
		googleMapsApiKey: GlobalSettings.googleMapsApiKey,
		language: loginInLanguage,
	});
	const dispatch = useDispatch();

	const flagsRef = useRef({ internalChange: false, mouseIn: false, hoverClusterInfo: false });
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [mapPosition, setMapPosition] = useState<MapPosition>({
		bounds: {
			mapBounds: null,
			offSet: false,
		},
	});
	const [liveInfoWindowOffset, setLiveInfoWindowOffset] = useState<google.maps.Size | null>(null);
	const [historyInfoWindowOffset, setHistoryInfoWindowOffset] = useState<google.maps.Size | null>(null);
	const [markerInfoWindow, setMarkerInfoWindow] = useState<MarkerConfig[]>([]);
	const [clusterInfoWindow, setClusterInfoWindow] = useState<ClusterMarkerConfig | null>(null);

	const [startBounds, setStartBounds] = useState<google.maps.LatLngBounds | null>(null);
	const [clusterer, setClusterer] = useState<Clusterer | null>(null);
	const filteredCustomer = useSelector((s: ApplicationState) => s.globalCustomer?.filteredCustomer);
	const filteredCustomerLevel = useSelector((s: ApplicationState) => s.globalCustomer?.filteredCustomer?.level);
	const customerLevel = useSelector((s: ApplicationState) => s.currentSession?.customerLevel);
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	const startCountryBoundaries = useSelector((s: ApplicationState) => s.currentSession.startCountryBoundaries);
	const showLabels = useSelector(
		(s: ApplicationState) =>
			s.currentSession.personLiveSettings?.showLabels ?? s.currentSession.customerLiveSettings?.showLabels
	);

	const [refreshTemplates, setRefreshTemplates] = useState<boolean>(null);
	const entityGrouping = useSelector(
		(s: ApplicationState) =>
			s.currentSession.personLiveSettings?.entityGrouping ?? s.currentSession.customerLiveSettings?.entityGrouping
	);
	const showTrafficSettings = useSelector(
		(s: ApplicationState) =>
			s.currentSession.personLiveSettings?.trafficInformation ??
			s.currentSession.customerLiveSettings?.trafficInformation
	);

	const extendedMenu = useSelector((state: ApplicationState) => !!state.liveMap.tripDetails);
	const selectedEntity = useSelector((state: ApplicationState) => state.liveMap.selectedEntityId);
	const focusedEntityPoint = useSelector((state: ApplicationState) => state.liveMap.focusedEntityPoint);
	const pinMarker = useSelector((state: ApplicationState) => state.liveMap.pinMarker);

	const mapOptions = useSelector((s: ApplicationState) =>
		props.isLiveMap ? s.liveMap.liveMapOptions : s.historyStore.historyMapOptions
	);

	const [showTraffic, setShowTraffic] = useState<boolean>(
		props.isLiveMap
			? mapOptions.showTraffic?.firstRender
				? showTrafficSettings
				: mapOptions.showTraffic?.showTraffic
			: mapOptions.showTraffic?.showTraffic
	);
	const mapBoundsRedux = useSelector((s: ApplicationState) =>
		props.isLiveMap ? s.liveMap.liveMapOptions.mapBounds : s.historyStore.historyMapOptions.mapBounds
	);

	//use both state and ref: state to detect changes and ref to have the new value immediately changed (if live markers are changed at the same time with focused entity, they will override focused entity bounds)
	const { keepInViewState, keepInViewRef, setKeepInView } = useKeepInViewStateAndRef(
		mapOptions.keepInView,
		props.isLiveMap
	);

	const routeLines = useSelector((state: ApplicationState) => state.liveMap.routeLines);

	const showLocationsLiveMap = useLocationsLiveMap();
	const showLocationsHistoryMap = useLocationsHistoryMap();
	const showTemplatesOnHistoryMap = useTemplatesHistoryMap();

	const mapCategories = useSelector((state: ApplicationState) => state.liveMap.mapCategories);
	const liveSelectedCategories = useSelector((state: ApplicationState) => state.liveMap.liveSelectedCategories);
	const historySelectedCategories = useSelector(
		(state: ApplicationState) => state.historyStore.historySelectedCategories
	);
	const { data: locations, refetch } = useQuery(
		['customer-locations', filteredCustomer?.id],
		() =>
			ajaxUtil.get<LocationMapDto[]>(
				`${GlobalSettings.locationsMaintenanceApi}/GetMapLocations/${
					filteredCustomer === null || filteredCustomer === undefined
						? currentSession?.customer?.id
						: filteredCustomer?.id
				}`
			),
		{
			enabled:
				(props.isLiveMap ? showLocationsLiveMap : showLocationsHistoryMap) &&
				(filteredCustomer === null
					? customerLevel === CustomerLevelEnum.Default
					: filteredCustomer?.level === CustomerLevelEnum.Default),
			staleTime: 120000,
		}
	);
	const [googleMapOptions, setGoogleMapOptions] = useState<google.maps.MapOptions>({
		mapId: GlobalSettings.googleMapId,
		clickableIcons: false,
		gestureHandling: 'greedy',
		disableDoubleClickZoom: true,
		streetViewControl: true,
		tilt: 0,
		rotateControl: false,
		mapTypeId: mapOptions.mapType,
	} as google.maps.MapOptions);

	const [showCategories, setShowCategories] = useState<boolean>(mapOptions.showCategories);
	const categoriesRef = useRef<HTMLDivElement>(null);
	const categoryButtonRef = useRef<HTMLDivElement>(null);
	useMapCategories(
		locations,
		mapCategories,
		liveSelectedCategories,
		historySelectedCategories,
		filteredCustomer === null ? currentSession.customerLevel : filteredCustomer?.level
	);
	const [showTemplates, setShowTemplates] = useState<boolean>()
	useOnClickOutside(() => setShowCategories(false), categoriesRef, categoryButtonRef); //close location categories pop-up when click outside
	const setCategoriesRefCallback = useCallback((ref: HTMLDivElement | null) => {
		categoriesRef.current = ref;
	}, []);
	useEffect(() => {
		if (props.isLiveMap && routeLines && routeLines.bounds && map) {
			setKeepInView(false);
			map.fitBounds(new google.maps.LatLngBounds(routeLines.bounds.sw, routeLines.bounds.ne));
		}
	}, [routeLines]);

	useEffect(() => {
		if (filteredCustomer && filteredCustomer.level === CustomerLevelEnum.Default) {
			refetch().catch((e) => console.log(e));
		}
	}, [filteredCustomer]);

	useEffect(() => {
		if (startBounds && !mapPosition.bounds?.mapBounds) {
			mapBoundsRedux
				? setMapPosition({ bounds: { mapBounds: mapBoundsRedux, offSet: false } })
				: setMapPosition({ bounds: { mapBounds: startBounds, offSet: true } });
		}
	}, [startBounds]);
	useEffect(() => {
		if (map && focusedEntityPoint && focusedEntityPoint.lat && focusedEntityPoint.lng) {
			setKeepInView(false);
			setMapPosition({ center: focusedEntityPoint });
		}
		if(map)
			setRefreshTemplates((prev) => !prev);
	}, [map, focusedEntityPoint]);

	const resetClusterWindow = useCallback((clusterData: ClusterMarkerConfig | null) => {
		dispatch({
			type: SET_MAP_SELECTED_ENTITY,
			payload: null,
		});
		setClusterInfoWindow(clusterData);
	}, []);

	useEffect(() => {
		if (props.isLiveMap) {
			if (selectedEntity && clusterer) {
				//expand cluster
				const cluster = clusterer.clusters.find(
					(c) =>
						c.markers.length > 1 &&
						c.markers.map((m: any) => m.markerConfig as MarkerConfig).some((m) => m.id === selectedEntity)
				);
				if (cluster) {
					//set the new selected cluster
					setClusterInfoWindow({
						markers: cluster.markers.map((m: any) => m.markerConfig as MarkerConfig),
						position: cluster.center,
						repaint: true,
					});
				}
			}
		}
	}, [selectedEntity, props.markers]);

	useEffect(() => {
		if (!clusterInfoWindow) {
			clusterer?.repaint();
		} else if (clusterInfoWindow.repaint) {
			clusterer?.repaint();
		}
	}, [clusterInfoWindow]);
	useEffect(() => {
		const w = window as any;
		w.rietveldMap = map; //This was added for testing purposes; it is also used in search component to get current map bounds(for search bias prediction)
		if (map && mapOptions.streetView) {
			map.getStreetView().setVisible(mapOptions.streetView.visible);
			map.getStreetView().setPosition(mapOptions.streetView.position);
			map.getStreetView().setPov(mapOptions.streetView.pov);
		}
	}, [map]);

	useEffect(() => {
		if (isLoaded) {
			if (
				startCountryBoundaries[0].lat &&
				startCountryBoundaries[0].lng &&
				startCountryBoundaries[1].lat &&
				startCountryBoundaries[1].lng
			) {
				setStartBounds(new google.maps.LatLngBounds(startCountryBoundaries[0], startCountryBoundaries[1]));
			} else {
				setStartBounds(new google.maps.LatLngBounds(mapStartCoordinates[0], mapStartCoordinates[1]));
			}
			setLiveInfoWindowOffset(new google.maps.Size(8.5, -8.3, 'px', 'px'));
			setHistoryInfoWindowOffset(new google.maps.Size(16, -8, 'px', 'px'));
		}
	}, [isLoaded]);

	useEffect(() => {
		if (map) {
			if (mapPosition.bounds?.mapBounds) {
				flagsRef.current.internalChange = true;

				if (mapPosition.bounds.offSet) {
					map.fitBounds(mapPosition.bounds.mapBounds);
					if (props.tripConfig.length > 0 && map.getZoom() > historyMaxZoomLevel) {
						map.setZoom(historyMaxZoomLevel);
					}
					if (props.tripConfig.length === 0 && map.getZoom() > liveMaxZoomLevel) {
						map.setZoom(liveMaxZoomLevel);
					}
				} else {
					//use 0 padding for fitBounds to have the same value on getBounds: "Supply 0 here to make a fitBounds idempotent on the result of getBounds"
					map.fitBounds(mapPosition.bounds.mapBounds, 0);
				}
			} else if (mapPosition.center) {
				//does not work without timeout if previously useEffect was run for mapPosition.bounds(only if this consecutive runs are rapid)
				setTimeout(() => {
					map.setCenter(mapPosition.center);
					map.setZoom(liveMaxZoomLevel);

					//position the focused entity point so its visble when the right menu is open (230px is half of the extended right menu)
					if (extendedMenu) {
						map.panBy(230, 0);
					}
				}, 0);
			}
		}
	}, [map, mapPosition]);

	useEffect(() => {
		if (map && props.isLiveMap && keepInViewRef.current && props.markers.length) {
			let hasLocationOnMap = false;
			const bounds = new google.maps.LatLngBounds();
			props.markers.forEach((marker) => {
				if (marker.position.lat && marker.position.lng) {
					hasLocationOnMap = true;
					bounds.extend(marker.position);
				}
			});
			if (hasLocationOnMap) {
				setMapPosition({ bounds: { mapBounds: bounds, offSet: true } });
			}
		}
	}, [keepInViewState, props.markers, map]);

	useEffect(() => {
		//if keepInView is off redraw clusters; if keepInView is on, fitBounds will trigger clusters redraw
		if (!keepInViewRef.current && clusterer) {
			clusterer.repaint();
		}
	}, [keepInViewState, props.markers]);

	useEffect(() => {
		if (entityGrouping !== EntityGroupingEnum.Circle) {
			if (showLabels) {
				setMarkerInfoWindow(props.markers);
			} else {
				setMarkerInfoWindow(props.markers.filter((m) => m.id === markerInfoWindow[0]?.id));
			}
		}
	}, [props.markers]);

	useEffect(() => {
		if (!showLabels) {
			const markerInfo = props.markers?.find((m) => m.position.lat && m.position.lng && m.id === selectedEntity);
			const selectedInfoWindow: MarkerConfig[] = [];
			if (markerInfo?.id) {
				selectedInfoWindow.push(markerInfo);
			}
			setMarkerInfoWindow(selectedInfoWindow);
		}
	}, [selectedEntity]);

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={{
				width: '100%',
				height: '100%',
			}}
			options={googleMapOptions}
			mapTypeId={googleMapOptions.mapTypeId}
			onDrag={() => setKeepInView(false)}
			id={GlobalSettings.googleMapId}
			key={'google-map'}
			onLoad={setMap}
			onMapTypeIdChanged={() => {
				if (map?.getMapTypeId() !== undefined && map?.getMapTypeId() !== googleMapOptions.mapTypeId)
					setGoogleMapOptions({ ...googleMapOptions, mapTypeId: map?.getMapTypeId() });
			}}
			onClick={() => {
				if (!flagsRef.current.hoverClusterInfo) {
					resetClusterWindow(null);
				}
			}}
			onUnmount={() => {
				const streetView = map
					? {
							visible: map.getStreetView().getVisible(),
							position: map.getStreetView().getPosition(),
							pov: map.getStreetView().getPov(),
					  }
					: null;

				props.isLiveMap
					? dispatch({
							type: SET_LIVE_MAP_OPTIONS,
							payload: {
								mapBounds: map?.getBounds(),
								mapType: map?.getMapTypeId(),
								keepInView: keepInViewRef.current,
								showTraffic: { showTraffic: showTraffic, firstRender: false },
								streetView: streetView,
								showCategories: showCategories,
								showTemplates: mapOptions.showTemplates,
							},
					  })
					: dispatch({
							type: SET_HISTORY_MAP_OPTIONS,
							payload: {
								mapBounds: map?.getBounds(),
								mapType: map?.getMapTypeId(),
								showTraffic: { showTraffic: showTraffic, firstRender: false },
								streetView: streetView,
								showCategories: showCategories,
								showTemplates: mapOptions.showTemplates,
							},
					  });
			}}
			onMouseOver={() => {
				//setTimeout prevents the onBoundsChanged event to trigger after onMouseOver when resizing the LiveMenu
				setTimeout(() => {
					flagsRef.current.mouseIn = true;
				}, 20);
			}}
			onMouseOut={() => {
				//setTimeout prevents the onMouseOut event to trigger after before onMouseIn
				setTimeout(() => {
					flagsRef.current.mouseIn = false;
				}, 20);
			}}
			onBoundsChanged={() => {
				if (props.isLiveMap) {
					//keepInView needs to change only from user actions
					//and not from an internal change such as map.setZoom or map.fitBounds
					if (flagsRef.current.internalChange) {
						flagsRef.current.internalChange = false;
						//since resizing the map triggers onBoundsChanged
						//and keepInView should not change when that happens
						//an extra condition is added for the mouse to be inside the map
					} else if (flagsRef.current.mouseIn) {
						setKeepInView(false);
					}
				}
			}}
		>
			{(filteredCustomerLevel === CustomerLevelEnum.Default ||
				currentSession?.customerLevel === CustomerLevelEnum.Default) &&
			((props.isLiveMap && showLocationsLiveMap) || (!props.isLiveMap && showLocationsHistoryMap)) ? (
				<div
					style={props.isLiveMap ? { right: 115 } : { right: 60 }}
					ref={categoryButtonRef}
					className={'location-categories-button'}
					onClick={() => {
						setShowCategories((prev) => !prev);
					}}
				>
					{showCategories ? (
						<LocationOnOutlined className={'locations-icon'} />
					) : (
						<LocationOn className={'locations-icon'} />
					)}
				</div>
			) : null}

			{(filteredCustomerLevel === CustomerLevelEnum.Default ||
				currentSession?.customerLevel === CustomerLevelEnum.Default) &&
				(!props.isLiveMap && showTemplatesOnHistoryMap) ? (
				<div
					style={{
						right: (showLocationsLiveMap === true || showLocationsHistoryMap === true) ? 115 : 60,
						color: !mapOptions.showTemplates ? '#a2a2a2' : 'black' ,
					}}
					className={'templates-history-button'}
					onClick={() => dispatch(historyStoreActionCreators.setShowTemplatesOnHistory(!mapOptions.showTemplates))}
				>
					{<GoogleMaterialIconOutlined iconName={'insights'} />}
				</div>
			) : null}

			{/*Templates*/}
			{mapOptions.showTemplates &&
				<RietveldHistoryTemplates
					tripConfig={props.tripConfig}
					templates={props.templates}
					isLiveMap={props.isLiveMap}
					map={map}
					refresh={refreshTemplates}
				/>}

			{showCategories ? (
				<LocationCategoryArea setRef={setCategoriesRefCallback} isLiveMap={props.isLiveMap} />
			) : null}
			<div
				className={showTraffic ? 'traffic-button selected' : 'traffic-button'}
				onClick={() => {
					setShowTraffic((prev) => !prev);
				}}
			>
				{TranslateText('liveMap.trafficLabel')}
			</div>
			{showTraffic ? <TrafficLayer /> : null}
			{props.isLiveMap && (
				<div
					className={'keep-in-view-button'}
					onClick={() => {
						setKeepInView(!keepInViewState);
					}}
				>
					{keepInViewState ? <MdLock /> : <MdLockOpen />}
				</div>
			)}

			{/* Locations */}
			{locations?.length &&
			((props.isLiveMap && showLocationsLiveMap) || (!props.isLiveMap && showLocationsHistoryMap)) ? (
				<LocationsMarkers locations={locations} isLiveMap={props.isLiveMap} />
			) : null}

			{/* Chart Point */}
			{(!props.isLiveMap && showLocationsHistoryMap) ?(
				<ChartMarker tripConfig={props.tripConfig} />
	        ) : null}


			{entityGrouping === EntityGroupingEnum.Circle && props.isLiveMap ? (
				/*Marker clusters*/
				<MarkerClusterer
					onLoad={setClusterer}
					options={options.clusterer}
					calculator={(markers: MarkerExtended[]) => {
						let index = 1;
						if (markers.length > 19) {
							index = 3;
						} else if (markers.length > 9) {
							index = 2;
						}

						//identify selected cluster using containing markers to apply different style
						if (clusterInfoWindow && clusterInfoWindow.markers.length === markers.length) {
							let sameMarkers = true;
							for (let i = 0; i < markers.length; i++) {
								const markerConfig = (markers[i] as any).markerConfig as MarkerConfig;
								if (!markerConfig || !clusterInfoWindow.markers.some((x) => x.id === markerConfig.id)) {
									sameMarkers = false;
									break;
								}
							}

							if (sameMarkers) {
								index += 3;
							}
						}

						return {
							text: markers.length.toString(),
							index: index,
							title: '',
						};
					}}
					onClick={(cluster) => {
						flagsRef.current.internalChange = true;
						if (cluster.markers.length && cluster.markers.length > 1) {
							if (clusterInfoWindow) {
								const selectedCluster = getSelectedClusterConfig(clusterInfoWindow, [cluster]);
								if (selectedCluster) {
									//if selected cluster is it is found in clusters list remove selection (click on same cluster)
									resetClusterWindow(null);
								} else {
									//set the new selected cluster
									resetClusterWindow({
										markers: cluster.markers.map((m: any) => m.markerConfig as MarkerConfig),
										position: cluster.center,
									});
								}
							} else {
								//set the new selected cluster
								resetClusterWindow({
									markers: cluster.markers.map((m: any) => m.markerConfig as MarkerConfig),
									position: cluster.center,
								});
							}

							clusterer?.repaint();
						}
					}}
					onClusteringEnd={(c) => {
						let markerClusters: Cluster[] = [];
						if (showLabels) {
							markerClusters = c.clusters.filter((c) => c.markers.length === 1);
						} else if (markerInfoWindow.length) {
							markerClusters = c.clusters.filter(
								(c) =>
									c.markers.length === 1 &&
									((c.markers[0] as any).markerConfig as MarkerConfig).id === markerInfoWindow[0].id
							);
						}
						setMarkerInfoWindow(
							markerClusters.map((c) => (c.markers[0] as any).markerConfig as MarkerConfig)
						);

						//update data for selected cluster if it is found in clusters list
						const clusters = c.clusters.filter((c) => c.markers.length > 1);
						let selectedCluster: ClusterMarkerConfig = null;

						if (selectedEntity) {
							for (let i = 0; i < clusters.length; i++) {
								const c = clusters[i];
								if (c.markers.some((m) => (m as any).markerConfig.id === selectedEntity)) {
									selectedCluster = {
										markers: c.markers.map((m: any) => m.markerConfig as MarkerConfig),
										position: c.center,
									};

									break;
								}
							}
						} else if (clusterInfoWindow && clusterInfoWindow.markers.length) {
							selectedCluster = getSelectedClusterConfig(clusterInfoWindow, clusters);
						}

						setClusterInfoWindow(selectedCluster);
					}}
				>
					{(cluster) => {
						return props.markers
							.filter((m) => m.position.lat && m.position.lng)
							.map((m) => (
								<Marker
									key={`marker${m.id}`}
									position={m.position}
									zIndex={m.id === selectedEntity ? 1001 : 1000}
									clusterer={cluster}
									options={{ markerConfig: m } as LiveMarkerOptions}
									icon={
										MARKER_URL[m.liveData.state]
											? {
													url: `${
														MARKER_URL[m.liveData.state]
													}#${m.liveData.direction?.toLowerCase()}`,
													scaledSize: new window.google.maps.Size(13, 13),
													anchor: new google.maps.Point(7, 7),
											  }
											: null
									}
									onClick={() => {
										if (!showLabels) {
											const selectedInfoWindow: MarkerConfig[] = [];
											if (!markerInfoWindow.length || markerInfoWindow[0].id !== m.id) {
												selectedInfoWindow.push(m);
											}
											setMarkerInfoWindow(selectedInfoWindow);
										}
									}}
								/>
							));
					}}
				</MarkerClusterer>
			) : (
				props.markers
					.filter((m) => m.position.lat && m.position.lng)
					.map((m) => (
						<Marker
							key={`marker${m.id}`}
							position={m.position}
							zIndex={m.id === selectedEntity ? 1001 : 1000}
							options={{ markerConfig: m } as LiveMarkerOptions}
							icon={
								MARKER_URL[m.liveData.state]
									? {
											url: `${
												MARKER_URL[m.liveData.state]
											}#${m.liveData.direction?.toLowerCase()}`,
											scaledSize: new window.google.maps.Size(13, 13),
											anchor: new google.maps.Point(7, 7),
									  }
									: null
							}
							onClick={() => {
								if (!showLabels) {
									const selectedInfoWindow: MarkerConfig[] = [];
									if (!markerInfoWindow.length || markerInfoWindow[0].id !== m.id) {
										selectedInfoWindow.push(m);
									}
									setMarkerInfoWindow(selectedInfoWindow);
								}
							}}
						/>
					))
			)}
			{/* Marker Info window */}
			{markerInfoWindow.map((m) => (
				<InfoBox
					key={`info-${m.id}`}
					position={m.position}
					options={
						m.id === selectedEntity
							? {
									pixelOffset: liveInfoWindowOffset,
									zIndex: 1001, //always on top
									closeBoxMargin: '0px',
									closeBoxURL: '',
									disableAutoPan: true,
							  }
							: {
									pixelOffset: liveInfoWindowOffset,
									zIndex: 1000, //always on top
									closeBoxMargin: '0px',
									closeBoxURL: '',
									disableAutoPan: true,
							  }
					}
				>
					<LiveInfoWindow data={m.liveData} selected={m.id === selectedEntity} />
				</InfoBox>
			))}
			{/* Cluster Info windows */}
			{clusterInfoWindow ? (
				<InfoBox
					key={`cluster-${clusterInfoWindow.position}`}
					position={clusterInfoWindow.position}
					options={getClusterInfoBoxOptions(clusterInfoWindow.position, map)}
				>
					<LiveInfoClusterWindow
						markers={clusterInfoWindow.markers}
						selectedEntity={selectedEntity}
						hoverClusterChanged={(hover) => {
							flagsRef.current.hoverClusterInfo = hover;
						}}
					/>
				</InfoBox>
			) : null}

			{/*Trips*/}
			<RietveldHistoryTrips
				tripConfig={props.tripConfig}
				isLiveMap={props.isLiveMap}
				map={map}
				flagsRef={flagsRef}
				historyInfoWindowOffset={historyInfoWindowOffset}
				setMapPosition={setMapPosition}
				setGoogleMapOptions={setGoogleMapOptions}
			/>

			{/*Route lines and marker*/}
			{pinMarker ? <Marker icon={{ url: LocationPin }} position={pinMarker} /> : null}
			{props.isLiveMap && routeLines
				? routeLines.lines.map((line, index) => (
						<Polyline
							key={`route-line${index}`}
							path={line}
							options={{
								strokeWeight: 4,
								strokeColor: showTraffic ? '#8e44ad' : '#f7521e',
							}}
						/>
				  ))
				: null}
		</GoogleMap>
	) : null;
};

RietveldMap.defaultProps = {
	markers: [],
	tripConfig: [],
} as Props;

export default RietveldMap;
