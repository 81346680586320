import { GridWidgetOverview } from 'components/GridOverview/GridWidgetOverview';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';

interface ConflictingConnection {
	id: string;
	entity1Id: string;
	entity2Id: string;
	displayNameEntity1: string;
	displayExtraEntity1: string;
	displayNameEntity2: string;
	displayExtraEntity2: string;
	connectionStartDate: string;
	connectionEndDate: string;
	connectionType: ConnectionTypeEnum;
}

export interface ConnectionsConflictResult {
	conflictPresent: boolean;
	fixableConflict: boolean;
	conflictingConnections: ConflictingConnection[];
}

export interface Props {
	visible: boolean;
	canSolveConflicts: boolean;
	conflictData: ConflictingConnection[];
	connectionType: ConnectionTypeEnum;
	confirmCallback: (confirm: boolean) => void;
}

const getTitle = (type: ConnectionTypeEnum) => {
	switch (type) {
		case ConnectionTypeEnum.DeviceObject: {
			return TranslateText('connections.objectDeviceConnectionConflict');
		}
		case ConnectionTypeEnum.PersonObject: {
			return TranslateText('connections.objectDriverConnectionConflict');
		}
		case ConnectionTypeEnum.PersonKey: {
			return TranslateText('connections.driverKeyConnectionConflict');
		}
		case ConnectionTypeEnum.DeviceSimCard: {
			return TranslateText('connections.deviceSimCardConnectionConflict');
		}
	}
};

const getColumnHeaderText = (column: string, connectionType: ConnectionTypeEnum): string => {
	if (column === 'displayNameEntity1')
		switch (connectionType) {
			case ConnectionTypeEnum.DeviceObject: {
				return TranslateText('widgets.grid.colDeviceCode');
			}
			case ConnectionTypeEnum.PersonObject: {
				return TranslateText('widgets.grid.colDriverCode');
			}
			case ConnectionTypeEnum.PersonKey: {
				return TranslateText('widgets.grid.colDriverCode');
			}
			case ConnectionTypeEnum.DeviceSimCard: {
				return TranslateText('widgets.grid.colDeviceCode');
			}
		}

	if (column === 'displayExtraEntity1')
		switch (connectionType) {
			case ConnectionTypeEnum.DeviceObject: {
				return TranslateText('widgets.grid.colSerialNumber');
			}
			case ConnectionTypeEnum.PersonObject: {
				return TranslateText('widgets.grid.colDriverName');
			}
			case ConnectionTypeEnum.PersonKey: {
				return TranslateText('widgets.grid.colDriverName');
			}
			case ConnectionTypeEnum.DeviceSimCard: {
				return TranslateText('widgets.grid.colSerialNumber');
			}
		}
	if (column === 'displayNameEntity2')
		switch (connectionType) {
			case ConnectionTypeEnum.DeviceObject: {
				return TranslateText('widgets.grid.colObjectCode');
			}
			case ConnectionTypeEnum.PersonObject: {
				return TranslateText('widgets.grid.colObjectCode');
			}
			case ConnectionTypeEnum.PersonKey: {
				return TranslateText('widgets.grid.colKeyCode');
			}
			case ConnectionTypeEnum.DeviceSimCard: {
				return TranslateText('widgets.grid.colIMSI');
			}
		}

	if (column === 'displayExtraEntity2')
		switch (connectionType) {
			case ConnectionTypeEnum.PersonKey: {
				return TranslateText('widgets.grid.colSerialNumber');
			}
			case ConnectionTypeEnum.DeviceSimCard: {
				return TranslateText('widgets.grid.colICCID');
			}
		}
};

const ConflictConfirmation = (props: Props) => {
	const [config, setConfig] = useState({
		columns: [],
		title: '',
	});

	useEffect(() => {
		const columns = [
			<Column
				key="displayNameEntity1"
				field="displayNameEntity1"
				className="widget-grid-column"
				header={getColumnHeaderText('displayNameEntity1', props.connectionType)}
				style={{ width: '150px' }}
			/>,
			<Column
				key="displayExtraEntity1"
				field="displayExtraEntity1"
				className="widget-grid-column"
				header={getColumnHeaderText('displayExtraEntity1', props.connectionType)}
				style={{ width: '200px' }}
			/>,
			<Column
				key="displayNameEntity2"
				field="displayNameEntity2"
				className="widget-grid-column"
				header={getColumnHeaderText('displayNameEntity2', props.connectionType)}
				style={{ width: '150px' }}
			/>,
			props.connectionType === ConnectionTypeEnum.PersonKey ||
			props.connectionType === ConnectionTypeEnum.DeviceSimCard ? (
				<Column
					key="displayExtraEntity2"
					field="displayExtraEntity2"
					className="widget-grid-column"
					header={getColumnHeaderText('displayExtraEntity2', props.connectionType)}
					style={{ width: '150px' }}
				/>
			) : null,
			<Column
				key="connectionStartDate"
				field="connectionStartDate"
				className="widget-grid-column"
				body={(conflictData: any) => {
					if (conflictData.connectionStartDate) {
						return FormatDate(conflictData.connectionStartDate);
					}
				}}
				header={TranslateText('widgets.grid.colStartDate')}
				style={{ width: '200px' }}
			/>,
			<Column
				key="connectionEndDate"
				field="connectionEndDate"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colEndDate')}
				style={{ width: '200px' }}
				body={(conflictData: any) => {
					if (conflictData.connectionEndDate) {
						return FormatDate(conflictData.connectionEndDate);
					}
				}}
			/>,
		];
		setConfig({
			title: getTitle(props.connectionType),
			columns: columns,
		});
	}, [props.connectionType]);

	return (
		<Dialog style={{ zIndex: 16000 }} open={props.visible} maxWidth={'md'}>
			<DialogTitle>{config.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.canSolveConflicts
						? TranslateText('connections.fixableConnectionConflict')
						: TranslateText('connections.nonfixableConnectionConflict')}
				</DialogContentText>
				<GridWidgetOverview
					columns={config.columns}
					data={props.conflictData}
					total={props.conflictData?.length ?? 0}
					paginator={false}
				/>
			</DialogContent>
			<DialogActions>
				<Button color={'primary'} hidden={!props.canSolveConflicts} onClick={() => props.confirmCallback(true)}>
					{TranslateText('common.buttonYes')}
				</Button>
				<Button hidden={!props.canSolveConflicts} onClick={() => props.confirmCallback(false)}>
					{TranslateText('common.buttonNo')}
				</Button>
				<Button hidden={props.canSolveConflicts} onClick={() => props.confirmCallback(false)}>
					{TranslateText('common.buttonOk')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export { ConflictConfirmation };
