import './LiveInfoClusterWindow.scss';

import React, { useCallback, useEffect, useState } from 'react';

import LiveClusterPaginator, { PageDirection } from './LiveClusterPaginator';
import LiveIcon from './LiveIcon';
import LiveInfoWindow from './LiveInfoWindow';
import { MarkerConfig } from './RietveldMap';

interface Props {
	markers: MarkerConfig[];
	hoverClusterChanged?: (hover: boolean) => void;
	selectedEntity?: string;
}

const PageTake = 20;

const compareDescendingUpdateDate = (m1: MarkerConfig, m2: MarkerConfig): number => {
	const m1UpdateDate = m1.liveData.since ? new Date(m1.liveData.since) : new Date(m1.liveData.lastUpdatedDateTime);
	const m2UpdateDate = m2.liveData.since ? new Date(m2.liveData.since) : new Date(m2.liveData.lastUpdatedDateTime);

	if ((m1UpdateDate && m2UpdateDate && m1UpdateDate < m2UpdateDate) || (m1UpdateDate && !m2UpdateDate)) {
		return 1;
	} else if ((m1UpdateDate && m2UpdateDate && m1UpdateDate > m2UpdateDate) || (!m1UpdateDate && m2UpdateDate)) {
		return -1;
	}

	return 0;
};

const LiveInfoClusterWindow = (props: Props) => {
	const [offset, setOffset] = useState<number>(0);
	const [hoverId, setHoverId] = useState<string>(null);
	const [orderedMarkers, setOrderedMarkers] = useState<MarkerConfig[]>([]);

	useEffect(() => {
		const orderedMarkers = props.markers.slice().sort(compareDescendingUpdateDate);
		setOrderedMarkers(orderedMarkers);
		setEnablePrevious(offset >= PageTake);
		setEnableNext(offset + PageTake < orderedMarkers.length);
	}, [props.markers]);

	useEffect(() => {
		if (props.selectedEntity) {
			const newOrderedMarkers = props.markers.slice().sort(compareDescendingUpdateDate);

			const selectedIndex = newOrderedMarkers.findIndex((m) => m.id === props.selectedEntity);
			const newOffset = selectedIndex - (selectedIndex % PageTake);

			setOffset(newOffset);
			setOrderedMarkers(newOrderedMarkers);
			setEnablePrevious(newOffset >= PageTake);
			setEnableNext(newOffset + PageTake < newOrderedMarkers.length);
		}
	}, [props.selectedEntity]);

	const [enablePrevious, setEnablePrevious] = useState<boolean>(false);
	const [enableNext, setEnableNext] = useState<boolean>(false);

	const setNewOffset = useCallback(
		(pageDirection: PageDirection) => {
			const newOffset = offset + pageDirection * PageTake;

			if (
				(pageDirection > PageDirection.none && newOffset < orderedMarkers.length) || //Next
				(pageDirection < PageDirection.none && newOffset >= 0) //Previous
			) {
				setOffset(newOffset);
				setEnablePrevious(newOffset >= PageTake);
				setEnableNext(newOffset + PageTake < orderedMarkers.length);
			}
		},
		[orderedMarkers, offset]
	);

	return orderedMarkers ? (
		<div
			onMouseLeave={() => {
				if (props.hoverClusterChanged) {
					props.hoverClusterChanged(false);
				}
			}}
			onMouseEnter={() => {
				if (props.hoverClusterChanged) {
					props.hoverClusterChanged(true);
				}
			}}
			className={'cluster-info-window'}
		>
			{orderedMarkers.slice(offset, offset + PageTake).map((m, index) => (
				<div
					key={`info-${m.id}`}
					className={'cluster-info-item'}
					onMouseLeave={() => {
						setHoverId(null);
					}}
				>
					<div
						className={`cluster-window-container ${hoverId === m.id ? 'mouse-in' : ''}`}
						onMouseEnter={() => {
							setHoverId(m.id);
						}}
					>
						<LiveIcon markerConfig={m} />
						<LiveInfoWindow data={m.liveData} selected={m.id === props.selectedEntity} />
					</div>
					{orderedMarkers?.length &&
					orderedMarkers.length > PageTake &&
					(index === PageTake - 1 || offset + index === orderedMarkers?.length - 1) ? (
						<LiveClusterPaginator
							enableNext={enableNext}
							enablePrevious={enablePrevious}
							requestNewPage={setNewOffset}
						/>
					) : null}
				</div>
			))}
		</div>
	) : (
		<></>
	);
};

export default LiveInfoClusterWindow;
