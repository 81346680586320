import React from 'react';

import { iconNoImage } from '../../../utils/Icons';
import IconPicker from '../IconPicker';
import { useTryGetSvgIconFromQueryCache } from '../SvgIcon/hooks';
import { IconPickerProps } from '../types';

export type IconPickerWrapperProps = Omit<IconPickerProps, 'defaultIcon'> & {
	iconName: string;
	allowNoImage?: boolean;
};

const IconPickerWrapper = (props: IconPickerWrapperProps): JSX.Element => {
	const { icon } = useTryGetSvgIconFromQueryCache(
		props.iconName || !props.allowNoImage ? props.iconName : `${iconNoImage.iconSet}/${iconNoImage.name}`
	);

	return (
		<IconPicker
			onIconSelected={props.onIconSelected}
			defaultIcon={icon.content}
			defaultIconName={icon.name}
			containers={props.containers}
			color={props.color}
			size={props.size}
			readOnly={props.readOnly}
			allowNoImage={props.allowNoImage}
			isSelected={icon.name !== iconNoImage.name}
		/>
	);
};

export default IconPickerWrapper;
