import './styles.scss';

import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import EntityData from 'models/EntityData';
import EntityTypeEnum from 'models/EntityTypeEnum';
import TemplateData from 'models/TemplateData';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Box, Button } from '@material-ui/core';

import GlobalSettings from '../../../../GlobalSettings.json';
import { ReportSelectionArea } from '../ReportSelectionArea';
import { ReportObjectPersonChip } from './ReportObjectPersonChip';
import { ReportObjectPersonProps } from './types';

const mapReportEntities = (selectedEntities: EntityData[]): ITreeNode[] => {
	const selectedTreeEntities: ITreeNode[] = [];
	selectedEntities.forEach((selectedEntity: EntityData) => {
		selectedTreeEntities.push({
			id: selectedEntity.id,
			text: selectedEntity.text,
			type: selectedEntity.entityType,
			childCount: selectedEntity.childCount,
			active: selectedEntity.active,
			selected: true,
			canBeChecked: true,
			icon: selectedEntity.icon,
			items: selectedEntity.items?.map((item: EntityData) => ({
				id: item.id,
				text: item.text,
				type: item.entityType,
				childCount: item.childCount,
				active: item.active,
				icon: item.icon,
				selected: true,
				canBeChecked: true,
			})),
		});
	});

	return selectedTreeEntities;
};

const ReportObjectPerson = (props: ReportObjectPersonProps): JSX.Element => {
	const personId = useSelector((state: ApplicationState) => state.currentSession.personId);
	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);
	const [showSelectionDialog, setShowSelectionDialog] = useState(false);
	const [filterPlaceholder, setFilterPlaceholder] = useState<string>('');
	const [actionTitle, setActionTitle] = useState<string>(() =>
		props.driverIdentification && props.templateEntityType === EntityTypeEnum.Person
			? TranslateText('reports.addDrivers')
			: props.templateEntityType === EntityTypeEnum.PersonReport
			? TranslateText('reports.addPersons')
			: assetTracking
			? TranslateText('reports.addEntities')
			: TranslateText('reports.addObjects')
	);
	const [treeSelection, setTreeSelection] = useState<ITreeNode[]>([]);

	const onlyCurrentDriver =
		props.receivedDataList.length === 1 && props.receivedDataList[0] === personId && !props.groupsList.length;
	const [allItemsSelected, setAllItemsSelected] = useState(true);

	useEffect(() => {
		setAllItemsSelected(
			props.receivedDataList.every((id) => treeSelection.some((node) => node.id === id)) &&
				props.groupsList.every((id) => treeSelection.some((node) => node.id === id))
		);
	}, [props.receivedDataList, props.groupsList, treeSelection]);

	useEffect(() => {
		if (props.driverIdentification && props.templateEntityType === EntityTypeEnum.Person) {
			setActionTitle(TranslateText('reports.addDrivers'));
			setFilterPlaceholder(TranslateText('fleetSelection.searchDriverPlaceholder'));

			return;
		} else if (assetTracking) {
			setActionTitle(TranslateText('reports.addEntities'));
			setFilterPlaceholder(TranslateText('fleetSelection.searchEntitiesPlaceholder'));

			return;
		}

		if (props.templateEntityType === EntityTypeEnum.PersonReport) {
			setActionTitle(TranslateText('reports.addPersons'));
			setFilterPlaceholder(TranslateText('fleetSelection.searchPersonPlaceholder'));

			return;
		}

		setActionTitle(TranslateText('reports.addObjects'));
		setFilterPlaceholder(TranslateText('fleetSelection.searchObjectPlaceholder'));
	}, [props.driverIdentification, assetTracking, props.templateEntityType]);

	useEffect(() => {
		if (
			props.templateEntityType === EntityTypeEnum.Person ||
			props.templateEntityType === EntityTypeEnum.PersonReport
		) {
			ajaxUtil
				.get<EntityData>(`${GlobalSettings.reportingApi}/getPersonWithNoAuthorization/${personId}`)
				.then((result) => {
					if (result) {
						if (!props.isEditMode) {
							const selectedEntities: EntityData[] = [];
							selectedEntities.push(result);
							props.filledObjectPersonCallback(selectedEntities);
						}
					}
				});
		}
	}, [personId, props.templateEntityType]);

	useEffect(() => {
		setTreeSelection(mapReportEntities(props.selectedReportEntities));
	}, [props.selectedReportEntities]);

	//open selection dialog
	const showSelectEntitiesDialog = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			props.unsavedDataCallback(true, true);
			setShowSelectionDialog(true);
		},
		[props.unsavedDataCallback]
	);

	//close selection dialog
	const closeSelectionDialog = useCallback(() => {
		props.unsavedDataCallback(true, false);
		setShowSelectionDialog(false);
	}, [props.unsavedDataCallback]);

	const includeInactiveCallback = useCallback(
		(includeInactive: boolean) => {
			props.includeInactiveChangedCallback(includeInactive);
		},
		[props.includeInactiveChangedCallback]
	);

	const renderSelectedEntities = (selectedReportEntities: EntityData[]): JSX.Element[] =>
		selectedReportEntities
			.sort((firstEntity: EntityData, secondEntity: EntityData): number =>
				firstEntity.text.localeCompare(secondEntity.text)
			)
			.map(
				(entityData: EntityData): JSX.Element => (
					<ReportObjectPersonChip
						key={entityData.id}
						entityData={entityData}
						personId={personId}
						onlyCurrentDriver={onlyCurrentDriver}
						onDeleteCallback={removeSelectedEntity}
					/>
				)
			);

	//remove entity
	const removeSelectedEntity = useCallback(
		(id: string): void => {
			props.filledObjectPersonCallback(props.selectedReportEntities.filter((item) => item.id !== id));
		},
		[props.selectedReportEntities, props.filledObjectPersonCallback]
	);

	//save selection
	const saveSelection = useCallback(
		(treeData: ITreeNode[]) => {
			const selectedEntities = treeData.map((data: ITreeNode) => {
				return {
					id: data.id,
					text: data.text,
					entityType: data.type as EntityTypeEnum,
					active: data.active,
					childCount: data.childCount,
					icon: data.icon,
					items: data.items?.map((item: ITreeNode) => ({
						id: item.id,
						text: item.text,
						entityType: item.type as EntityTypeEnum,
						active: item.active,
						childCount: 0,
						items: [],
					})),
				};
			});

			props.filledObjectPersonCallback(selectedEntities);
			setShowSelectionDialog(false);
		},
		[props.filledObjectPersonCallback]
	);

	return (
		<Box className="categories-container">
			<Box className="e-text active-step-title">
				{'4.' +
					(props.templateEntityType === EntityTypeEnum.Object
						? assetTracking
							? TranslateText('reports.chooseEntities')
							: TranslateText('reports.chooseObjects')
						: props.templateEntityType === EntityTypeEnum.PersonReport
						? TranslateText('reports.choosePersons')
						: TranslateText('reports.chooseDrivers'))}
			</Box>
			<Box className="e-text">
				{props.templateEntityType === EntityTypeEnum.Object
					? assetTracking
						? TranslateText('reports.entitiesDescription')
						: TranslateText('reports.objectsDescription')
					: props.templateEntityType === EntityTypeEnum.PersonReport
					? TranslateText('reports.personsDescription')
					: TranslateText('reports.driversDescription')}
			</Box>
			<form id="reportObjectPersonForm" noValidate={true}>
				<Box className="form-group">
					<Box className="control-pane">
						<Box className="control-section">
							<Box className="ddl-control-section">
								<Box className="entities-selections">
									{renderSelectedEntities(props.selectedReportEntities)}
									{!allItemsSelected && (
										<Button
											className={'add-entity-button addButtonIcon primary-button'}
											onClick={showSelectEntitiesDialog}
										>
											{actionTitle}
										</Button>
									)}
								</Box>
								{((props.templateDataType === TemplateData.DriverPosition ||
									props.templateDataType === TemplateData.ObjectPosition) &&
									(props.selectedReportEntities.length > 10 ||
										props.selectedReportEntities.find(
											(x) => x.entityType === EntityTypeEnum.Group
										)) && (
										<Box className="e-error" id="errorSelectedEntities">
											{TranslateText('fieldsValidations.reportTypeHasALimitOf10Entities')}
										</Box>
									)) ||
									(props.selectedReportEntities.length === 0 && (
										<Box className="e-error" id="errorSelectedEntities">
											{props.driverIdentification &&
											props.templateEntityType === EntityTypeEnum.Person
												? TranslateText('fieldsValidations.noDriversSelected')
												: props.templateEntityType === EntityTypeEnum.PersonReport
												? TranslateText('fieldsValidations.noPersonsSelected')
												: assetTracking
												? TranslateText('fieldsValidations.noEntitySelected')
												: TranslateText('fieldsValidations.noObjectsSelected')}
										</Box>
									))}
							</Box>
						</Box>
					</Box>
				</Box>
				<ReportSelectionArea
					selectedEntities={treeSelection}
					closeDialogCallback={closeSelectionDialog}
					saveCallback={saveSelection}
					showDialog={showSelectionDialog}
					templateEntityType={props.templateEntityType}
					title={actionTitle}
					filterPlaceholder={filterPlaceholder}
					includeInactive={props.includeInactive}
					includeInactiveCallback={includeInactiveCallback}
				/>
			</form>
		</Box>
	);
};
export default ReportObjectPerson;
