import { createTheme } from '@material-ui/core';
import pink from '@material-ui/core/colors/pink';

const MainTheme = createTheme({
	palette: {
		primary: {
			light: pink[100],
			main: pink[500],
			dark: pink[900],
			contrastText: '#fff',
		},
	},
});
export default MainTheme;
