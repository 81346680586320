import GlobalSettings from 'GlobalSettings.json';
import { useQuery } from 'react-query';
import ajaxUtil from 'utils/Ajax';
import { queryClientKeys } from 'utils/queryClientKeys';

import { ObjectAddressDurationDetails } from './types';

export const useGetDurationTemplatesValues = (tripId: string) => {
	const { data } = useQuery(
		[queryClientKeys.OBJECT_ADDRESS_DURATION_TEMPLATES, tripId],
		() => {
			return ajaxUtil.get<ObjectAddressDurationDetails[]>(
				`${GlobalSettings.tripsApi}/getObjectAddressDurationTemplates/${tripId}`
			);
		},
		{
			cacheTime: 0,
			initialData: [],
		}
	);

	return { durationTemplates: data };
};
