import React from 'react';
import ImageColumn from './ImageColumn';

type Props = {
	body: (data: any) => JSX.Element;
	shown?: boolean;
	width?: number;
};

const ButtonColumn = ({ body, shown = true, width = null }: Props) =>
	ImageColumn({
		width: width ?? 60,
		shown: shown,
		alignCenter: true,
		leftPadding: false,
		body: (rowData) => {
			return shown && <span>{body(rowData)}</span>;
		},
	});

export default ButtonColumn;
