import EntityTypeEnum from 'models/EntityTypeEnum';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { LiveDataResultDto } from 'store/LiveData';

import { LinearProgress } from '@material-ui/core';

import { ApplicationState } from '../../store';
import FleetOverviewCard from './FleetOverviewCard';
import { FleetSelectionPlaceholder } from '../FleetSelectionPlaceholder';

interface FleetOverviewContainerProps {
	resizableExpanded: boolean;
}

const FleetOverviewContainer = (props: FleetOverviewContainerProps): JSX.Element => {
	const liveEntities = useSelector((state: ApplicationState) => state.liveData.liveEntities)?.sort(
		compareLiveEntities
	);

	const { data: isFetchingFleetSelectionData } = useQuery(['live', 'get-fleet-selection-data'], () => false);

	function compareLiveEntities(a: LiveDataResultDto, b: LiveDataResultDto): number {
		if (a.entityType === EntityTypeEnum.Object && b.entityType === EntityTypeEnum.Object) {
			return a.objectName.localeCompare(b.objectName);
		}

		if (a.entityType === EntityTypeEnum.Object && b.entityType === EntityTypeEnum.Person) {
			return a.objectName.localeCompare(b.personName);
		}

		if (a.entityType === EntityTypeEnum.Person && b.entityType === EntityTypeEnum.Object) {
			return a.personName.localeCompare(b.objectName);
		}

		if (a.entityType === EntityTypeEnum.Person && b.entityType === EntityTypeEnum.Person) {
			return a.personName.localeCompare(b.personName);
		}

		return 0;
	}

	return (
		<div className={'live-data'}>
			{isFetchingFleetSelectionData ? (
				<LinearProgress
					style={{
						backgroundColor: '#FFFFFF',
					}}
				/>
			) : (
				<>
					<div className={'fleet-rows'}>
						{liveEntities.map((ld) => (
							<FleetOverviewCard
								key={ld.entityId}
								liveData={ld}
								resizableExpanded={props.resizableExpanded}
							/>
						))}
						<FleetSelectionPlaceholder isForLive />
					</div>
				</>
			)}
		</div>
	);
};

export default FleetOverviewContainer;
