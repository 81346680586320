import './widget.scss';

import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import ProtectedContainer from 'components/Layout/SideBar/ProtectedContainer';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import SimCard from 'models/SimCard';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: any;
	initialData: any;
};

const SimCardInformationViewValidationConfig = (): FieldRules => {
	return {
		imsi: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(40),
				warningUnique: {
					message: TranslateText('fieldsValidations.warningValueNotUnique'),
					isWarning: true,
					validationFunction: (data) => {
						const simCardData = data as SimCard;
						if (!simCardData.imsi.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
							customerId: simCardData.customerId,
							value: simCardData.imsi,
							field: 'imsi',
							editedEntityId: simCardData.id,
						});
					},
				},
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
			},
		},
		iccid: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(40),
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data) => {
						const simCardData = data as SimCard;
						if (!simCardData.iccid.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
							value: simCardData.iccid,
							field: 'iccid',
							editedEntityId: simCardData.id,
						});
					},
				},
			},
		},
		phoneNumber: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(20),
				warningUnique: {
					message: TranslateText('fieldsValidations.warningValueNotUnique'),
					isWarning: true,
					validationFunction: (data) => {
						const simCardData = data as SimCard;
						if (!simCardData.phoneNumber.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/SimCardFields`, {
							customerId: simCardData.customerId,
							value: simCardData.phoneNumber,
							Field: 'phoneNumber',
							editedEntityId: simCardData.id,
						});
					},
				},
			},
		},
		pinCode: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(6),
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
			},
		},
		provider: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(100),
			},
		},
	};
};

const SimCardInformationView = (props: Props) => {
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	useEffect(() => {
		if (props.initialData) {
			if (props.setDashboardTitleCallback) {
				props.setDashboardTitleCallback(props.initialData.imsi);
			}

			dispatch(
				loadedEntityContextActionCreators.setLoadedSimCardContext({ ...new SimCard(), ...props.initialData })
			);
		}
	}, [props.initialData]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: SimCardInformationViewValidationConfig() }));
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: any, key: any) => {
			const newState = { ...props.data };
			newState[key] = value;
			props.changeDataCallback(newState);
		},
	};

	const [expandedSection, setExpandedSection] = useState<number>(() =>
		ClaimUtil.validateHasClaim(user, ClaimType.Customers) || ClaimUtil.validateHasClaim(user, ClaimType.SimCards)
			? 0
			: 1
	);

	return (
		<Accordion
			scrollToTop={props.scrollToTop}
			expandedCallback={(index) => {
				setExpandedSection(index);
			}}
		>
			<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.systemInformation')}>
				<CustomerNameDisplay customerId={props.data.customerId} />
				<FormControlLabel
					control={
						<Checkbox
							name="active"
							color={'primary'}
							disabled={!props.editMode}
							checked={props.data.active}
							onChange={() => events.onValueChanged(!props.data.active, 'active')}
						/>
					}
					label={
						<Typography style={{ fontSize: 12, marginRight: 5 }}>
							{TranslateText('fields.active')}
						</Typography>
					}
					labelPlacement="start"
					style={{ margin: 0, marginBottom: 14 }}
				/>
			</AccordionSection>
			<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.simData')}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<TextField
							id="imsi"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.imsi')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="imsi"
							value={props.data.imsi?.trimStart()}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'imsi');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.imsi} />
					</div>
					<div className="form-group">
						<TextField
							id="iccid"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.iccid')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="iccid"
							value={props.data.iccid?.trimStart()}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'iccid');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.iccid} />
					</div>
					<div className="form-group">
						<TextField
							id="phoneNumber"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.phoneNumber')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="phoneNumber"
							value={props.data.phoneNumber?.trimStart()}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'phoneNumber');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.phoneNumber} />
					</div>
					<div className="form-group">
						<TextField
							id="pinCode"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.pinCode')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="pinCode"
							value={props.data.pinCode?.trimStart()}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'pinCode');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.pinCode} />
					</div>
					<div className="form-group">
						<TextField
							id="provider"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.provider')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="provider"
							value={props.data.provider?.trimStart()}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'provider');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.provider} />
					</div>
					<ProtectedContainer claim={ClaimType.Devices}>
						<div className="form-group">
							<TextField
								id="deviceCode"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.connectedDevice')}
								inputProps={{ readOnly: true, style: { fontSize: 12 } }}
								name="deviceCode"
								value={props.data.deviceCode?.trimStart()}
								disabled={true}
							/>
						</div>
					</ProtectedContainer>
				</div>
			</AccordionSection>
		</Accordion>
	);
};

export default SimCardInformationView;
