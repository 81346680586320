import '../../style.scss';
import './styles.scss';

import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { ListSelectionItem } from 'components/ListSelectionView/types';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import { globalCustomerActionCreators } from 'store/GlobalCustomer';
import { gridActionCreators } from 'store/GridOverview';
import { TranslateText } from 'utils/Translations';

import { Box, Icon } from '@material-ui/core';

import GlobalSettings from '../../../../GlobalSettings.json';
import { useTerminateConnection } from './hooks';
import { AlertLocationsViewProps } from './types';

const AlertLocationsView = (props: AlertLocationsViewProps) => {
	const dispatch = useDispatch();

	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const terminateConnectionCallback = useTerminateConnection(props.entityId, props.reloadDataCallback);

	const renderEntityName = (rowData: ListSelectionItem) => {
		const useLink = ClaimUtil.validateHasClaim(user, ClaimType.Locations);
		return useLink ? (
			<Link
				className={!rowData.active ? 'inactiveEntity' : ''}
				to={`${GlobalSettings.route.locations}`}
				onMouseDown={() => {
					globalCustomerActionCreators.changeFilterText('', 'locations');
					dispatch(gridActionCreators.updateInlineGridRedirect(EntityTypeEnum.Location, rowData.id));
				}}
			>
				{rowData.name}
			</Link>
		) : (
			<span>{rowData.name}</span>
		);
	};

	return (
		<DataTable
			value={props.data}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: ListSelectionItem) => (
					<Box key={rowData.id} className="data-colName">
						{props.editMode ? (
							<Box component="span" className={!rowData.active ? 'inactiveEntity' : ''}>
								{rowData.name}
							</Box>
						) : (
							renderEntityName(rowData)
						)}
					</Box>
				)}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: ListSelectionItem) => (
					<Icon
						style={props.editMode ? {} : { visibility: 'hidden' }}
						className="clear-icon"
						onClick={() => {
							terminateConnectionCallback(rowData.id);
						}}
					>
						clear
					</Icon>
				)}
			/>
		</DataTable>
	);
};

export default AlertLocationsView;
