import { noop } from 'Constants';
import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useToggle = (initialState = false): [boolean, () => void, () => void] => {
	const [state, setState] = useState<boolean>(initialState);

	const toggle = useCallback(() => setState((state: boolean) => !state), []);
	const toggleFalse = useCallback(() => setState(false), []);

	return [state, toggle, toggleFalse];
};

const useToggleWithoutInit = (): [boolean | undefined, () => void, () => void] => {
	const [state, setState] = useState<boolean | undefined>(undefined);

	const toggle = useCallback(() => setState((state: boolean) => !state), []);
	const toggleFalse = useCallback(() => setState(false), []);

	return [state, toggle, toggleFalse];
};

type CallOptions = {
	leading?: boolean;
	trailing?: boolean;
};

type Options = CallOptions & {
	maxWait?: number;
};

type ControlFunctions = {
	cancel: () => void;
	flush: () => void;
	isPending: () => boolean;
};

type DebouncedState<T extends (...args: unknown[]) => ReturnType<T>> = ControlFunctions & {
	(...args: Parameters<T>): ReturnType<T> | undefined;
};

const useDebounceCallback = <T extends (...args: any[]) => ReturnType<T>>(
	func: T,
	wait?: number,
	options?: Options
): DebouncedState<T> => {
	const lastCallTime = useRef(null);
	const lastInvokeTime = useRef(0);
	const timerId = useRef(null);
	const lastArgs = useRef<unknown[]>([]);
	const lastThis = useRef<unknown>();
	const result = useRef<ReturnType<T>>();
	const funcRef = useRef(func);
	const mounted = useRef(true);

	funcRef.current = func;

	if (typeof func !== 'function') {
		throw new TypeError('Expected a function');
	}

	wait = +wait || 0;
	options = options || {};

	const leading = !!options.leading;
	const trailing = 'trailing' in options ? !!options.trailing : true; // `true` by default
	const maxing = 'maxWait' in options;
	const maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : null;

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	const debounced = useMemo(() => {
		const invokeFunc = (time: number) => {
			const args = lastArgs.current;
			const thisArg = lastThis.current;

			lastArgs.current = lastThis.current = null;
			lastInvokeTime.current = time;
			return (result.current = funcRef.current.apply(thisArg, args));
		};

		const startTimer = (pendingFunc: () => void, wait: number) => {
			timerId.current = setTimeout(pendingFunc, wait);
		};

		const shouldInvoke = (time: number) => {
			if (!mounted.current) {
				return false;
			}

			const timeSinceLastCall = time - lastCallTime.current;
			const timeSinceLastInvoke = time - lastInvokeTime.current;

			return (
				!lastCallTime.current ||
				timeSinceLastCall >= wait ||
				timeSinceLastCall < 0 ||
				(maxing && timeSinceLastInvoke >= maxWait)
			);
		};

		const trailingEdge = (time: number) => {
			timerId.current = null;

			if (trailing && lastArgs.current) {
				return invokeFunc(time);
			}
			lastArgs.current = lastThis.current = null;
			return result.current;
		};

		const timerExpired = () => {
			const time = Date.now();
			if (shouldInvoke(time)) {
				return trailingEdge(time);
			}

			if (!mounted.current) {
				return;
			}
			// Remaining wait calculation
			const timeSinceLastCall = time - lastCallTime.current;
			const timeSinceLastInvoke = time - lastInvokeTime.current;
			const timeWaiting = wait - timeSinceLastCall;
			const remainingWait = maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;

			// Restart the timer
			startTimer(timerExpired, remainingWait);
		};

		const func: DebouncedState<T> = (...args: Parameters<T>): ReturnType<T> => {
			const time = Date.now();
			const isInvoking = shouldInvoke(time);

			lastArgs.current = args;
			lastThis.current = this;
			lastCallTime.current = time;

			if (isInvoking) {
				if (!timerId.current && mounted.current) {
					lastInvokeTime.current = lastCallTime.current;
					startTimer(timerExpired, wait);
					return leading ? invokeFunc(lastCallTime.current) : result.current;
				}
				if (maxing) {
					startTimer(timerExpired, wait);
					return invokeFunc(lastCallTime.current);
				}
			}
			if (!timerId.current) {
				startTimer(timerExpired, wait);
			}
			return result.current;
		};

		func.cancel = () => {
			if (timerId.current) {
				clearTimeout(timerId.current);
			}
			lastInvokeTime.current = 0;
			lastArgs.current = lastCallTime.current = lastThis.current = timerId.current = null;
		};

		func.isPending = () => {
			return !!timerId.current;
		};

		func.flush = () => {
			return !timerId.current ? result.current : trailingEdge(Date.now());
		};

		return func;
	}, [leading, maxing, wait, maxWait, trailing]);

	return debounced;
};

const useDebounceEffect = <T extends (...args: any[]) => ReturnType<T>>(
	func: T,
	wait?: number,
	options?: Options,
	deps?: DependencyList
): void => {
	useEffect(() => {
		useDebounceCallback(func, wait, options);
	}, [deps]);
};

const useOnClickOutside = (
	handler: (event: MouseEvent | TouchEvent) => void,
	...refs: React.MutableRefObject<any>[]
) => {
	useEffect(() => {
		const listener = (event: TouchEvent | MouseEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			for (const ref of refs) {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
			}

			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [refs, handler]);
};

export type HTMLElementCoordinates = {
	left: number;
	top: number;
};

const useCoords = (htmlElement: HTMLElement) => {
	const [coords, setCoords] = useState<HTMLElementCoordinates>({ top: 0, left: 0 }); // takes current button coordinates

	const updateCoords = (element: HTMLElement): void => {
		if (!element) {
			return;
		}

		const rect = element.getBoundingClientRect();
		setCoords({
			left: rect.x + rect.width / 2,
			top: rect.y + window.scrollY,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', () => updateCoords(htmlElement));
		Array.from(document.getElementsByTagName('*')).map((element: Element) =>
			element.addEventListener('scroll', () => updateCoords(htmlElement))
		);
		return () => {
			window.removeEventListener('resize', () => updateCoords(htmlElement));
			Array.from(document.getElementsByTagName('*')).map((element: Element) =>
				element.removeEventListener('scroll', () => updateCoords(htmlElement))
			);
		};
	}, [htmlElement]);

	return { coords, updateCoords };
};

const useId = () => {
	const idRef = useRef<number>();
	idRef.current = Math.random() * 10000000000;

	return { id: idRef.current };
};

type NonEmptyArray<T> = [T, ...T[]];

const useEffectWithNoMount = (
	callBack: () => void,
	dependencies: NonEmptyArray<unknown>,
	cleanUp: () => void = noop
) => {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}
		callBack();
		return () => cleanUp();
	}, dependencies);
};

const useLocationAddressSizing = () => {
	const locationRef = useRef(null);
	const addressRef = useRef(null);
	const locationAddressRef = useRef(null);

	useEffect(() => {
		if (!locationAddressRef.current) return;

		if (!!locationRef.current) {
			locationRef.current.style.overflow = 'initial';
			locationRef.current.style.position = 'absolute';
			locationRef.current.style.flexShrink = '';
			locationRef.current.style.flex = '';
			if (locationRef.current.offsetWidth > locationAddressRef.current.offsetWidth / 2) {
				locationRef.current.style.overflow = 'hidden';
			} else {
				locationRef.current.style.flexShrink = '0';
			}
			locationRef.current.style.position = 'relative';
		}

		if (!!addressRef.current) {
			addressRef.current.style.overflow = 'initial';
			addressRef.current.style.position = 'absolute';
			addressRef.current.style.flexShrink = '';
			addressRef.current.style.flex = '';
			if (addressRef.current.offsetWidth > locationAddressRef.current.offsetWidth / 2) {
				addressRef.current.style.overflow = 'hidden';
			} else {
				addressRef.current.style.flexShrink = '0';
			}
			addressRef.current.style.position = 'relative';
		}

		if (locationRef.current?.style.overflow === 'hidden' && addressRef.current?.style.overflow === 'hidden') {
			addressRef.current.style.flex = 1;
			locationRef.current.style.flex = 1;
		}
	}, [addressRef.current, locationRef.current]);

	return {
		locationRef,
		addressRef,
		locationAddressRef,
	};
};

type OnUpdateCallback<T> = (s: T) => void;
type SetStateUpdaterCallback<T> = (s: T) => T;
type SetStateAction<T> = (newState: T | SetStateUpdaterCallback<T>, callback?: OnUpdateCallback<T>) => void;

export function useCustomState<T>(init: T): [T, SetStateAction<T>];
export function useCustomState<T = undefined>(init?: T): [T | undefined, SetStateAction<T | undefined>];
export function useCustomState<T>(init: T): [T, SetStateAction<T>] {
	const [state, setState] = useState<T>(init);
	const cbRef = useRef<OnUpdateCallback<T>>();

	const setCustomState: SetStateAction<T> = (newState, callback?): void => {
		cbRef.current = callback;
		setState(newState);
	};

	useEffect(() => {
		if (cbRef.current) {
			cbRef.current(state);
		}
		cbRef.current = undefined;
	}, [state]);

	return [state, setCustomState];
}

export {
	useCoords,
	useDebounceCallback,
	useDebounceEffect,
	useId,
	useOnClickOutside,
	useToggle,
	useToggleWithoutInit,
	useEffectWithNoMount,
	useLocationAddressSizing,
};
