import React, { useEffect, useState } from 'react';

import { TranslateText } from '../../utils/Translations';

export enum PageDirection {
	previous = -1,
	none = 0,
	next = 1,
}
interface Props {
	enablePrevious: boolean;
	enableNext: boolean;
	requestNewPage: (direction: PageDirection) => void;
}
const LiveClusterPaginator = (props: Props) => {
	return (
		<div className="cluster-paginator">
			<span
				style={props.enablePrevious ? {} : { display: 'none' }}
				onClick={(event) => {
					event.stopPropagation();
					props.requestNewPage(PageDirection.previous);
				}}
				className="left"
			>
				{TranslateText('common.buttonPrevious')}
			</span>
			<span className="center" />
			<span
				style={props.enableNext ? {} : { display: 'none' }}
				onClick={(event) => {
					event.stopPropagation();
					props.requestNewPage(PageDirection.next);
				}}
				className="right"
			>
				{TranslateText('common.buttonNext')}
			</span>
		</div>
	);
};

export default LiveClusterPaginator;
