import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ImagePickerEditorProps } from './types';
import { TranslateText } from '../../../utils/Translations';
import './styles.scss';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 95,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

const ImagePickerEditor = ({ initialImage, onEditingComplete, onCancel, aspectRatio }: ImagePickerEditorProps) => {
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PercentCrop>();
	const [isCropValid, setIsCropValid] = useState<boolean>(true);

	const imgRef = useRef<HTMLImageElement>(null);

	const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
		const { width, height } = e.currentTarget;
		setCrop(centerAspectCrop(width, height, aspectRatio));
	};

	const saveNewImage = async () => {
		if (!isCropValid) {
			return;
		}

		const canvas = document.createElement('canvas');

		const width = imgRef.current.naturalWidth;
		const height = imgRef.current.naturalHeight;

		const crop: PixelCrop = {
			x: (width * completedCrop.x) / 100,
			y: (height * completedCrop.y) / 100,
			width: (width * completedCrop.width) / 100,
			height: (height * completedCrop.height) / 100,
			unit: 'px',
		};

		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(imgRef.current, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);
		const base64Image = canvas.toDataURL('image/jpeg', 0.8);

		onEditingComplete(base64Image);
	};

	useEffect(() => {
		if (completedCrop?.width < 1 || completedCrop?.height < 1) {
			setIsCropValid(false);
			return;
		}

		setIsCropValid(true);
	}, [completedCrop]);

	return (
		<Dialog open={true} maxWidth={'xl'}>
			<DialogTitle>{TranslateText('fields.editImage')}</DialogTitle>
			<DialogContent className={'image-editor-dialog-content'}>
				<ReactCrop
					className={'image-editor-container'}
					crop={crop}
					onChange={(_, percentCrop) => setCrop(percentCrop)}
					onComplete={(_, c) => setCompletedCrop(c)}
					aspect={aspectRatio}
				>
					<img ref={imgRef} src={initialImage} onLoad={onImageLoad} />
				</ReactCrop>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>{TranslateText('common.buttonCancel')}</Button>
				<Button disabled={!isCropValid} onClick={saveNewImage}>
					{TranslateText('common.buttonSave')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImagePickerEditor;
