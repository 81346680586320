import './oldWidget.scss';

import AlertMessagesDetails, { EditAlertMessage } from 'components/AlertMessagesDetails/AlertMessagesDetails';
import React, { useEffect, useRef, useState } from 'react';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { convertToStringContentState } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import WidgetHeader from '../BaseWidget/WidgetHeader';
import MessageTargetEnum from "../../models/MessageTargetEnum";

type Props = {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	widgetTitle: string;
	changeDataCallback?: (hasChanges: boolean) => void;
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	showEditButton?: boolean;
};

export interface MessageValidation {
	appliesTo: MessageTargetEnum,
	formValid: boolean,
}
const AlertMessagesWidget = (props: Props) => {
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
	});

	const [editedAlertMessage, setEditedAlertMessage] = useState<EditAlertMessage | null>(null);
	const [alertMessages, setAlertMessages] = useState<EditAlertMessage[]>([]);
	const resetRef = useRef<{ forceResetData: () => Promise<void> }>(null);

	const [popupConfirmation, setPopupConfirmation] = useState<{
		title: string;
		message: string;
		userResponse: (response: boolean) => void;
	} | null>();

	const [formValidator, setFormValidator] = useState(null as Validator);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [formValid, setFormValid] = useState<boolean>(false);
	const [formValidations, setFormValidations] = useState<MessageValidation[]>([]);

	useEffect(() => {
		if (flags.editMode) {
			if (formValidator && editedAlertMessage) {
				formValidator.validate(editedAlertMessage).then((result) => {
					setValidationResult(result.validationResult);
					if(formValidations.length > 0) {
						setFormValid(result.formResult && formValidations.every(x => x.formValid));
					}
					else {
						setFormValid(result.formResult);
					}
					const newFormValidations = [...formValidations];
					for(let i=0; i<newFormValidations.length; i++) {
						if (newFormValidations[i].appliesTo === editedAlertMessage.appliesTo) {
							newFormValidations[i].formValid = result.formResult;
						}
					}
					setFormValidations(newFormValidations);
				});
			}
		} else {
			if (formValidator) {
				formValidator.clearValidation();
			}
			setValidationResult(null);
			setFormValid(false);
		}
	}, [editedAlertMessage, flags.editMode, formValidator]);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		props.changeDataCallback(flags.hasChanges);
	}, [flags.hasChanges]);

	const util = {
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode, hasChanges: false });
		},
		resetLocalChanges: async () => {
			const alertMessage = alertMessages.find((x) => x.appliesTo === editedAlertMessage.appliesTo);
			if (alertMessage) {
				setEditedAlertMessage({
					...alertMessage,
					editorBody: convertToStringContentState(alertMessage.body),
					editorChangeCheck: null,
				});
			}
			await resetRef.current.forceResetData();
		},
		cancelConfirmation: async () => {
			return new Promise<boolean>((resolve) => {
				setPopupConfirmation({
					title: TranslateText('common.titleUnsavedData'),
					message: TranslateText('notificationMessages.cancel'),
					userResponse: (response: boolean) => {
						resolve(response);
						setPopupConfirmation(null);
					},
				});
			});
		},
		checkChanges: async () => {
			if (!flags.hasChanges || (await util.cancelConfirmation())) {
				return true;
			}

			return false;
		},
		validateForm: async () => {
			return formValidator.validate(editedAlertMessage).then((result) => {
				setValidationResult(result.validationResult);
				setFormValid(result.formResult);

				return result.formResult;
			});
		},
	};

	const events = {
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.cancelConfirmation()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const newValue = !flags.editMode;
			if (!newValue && flags.hasChanges) {
				if (await util.cancelConfirmation()) {
					util.setEditMode(newValue);
					await util.resetLocalChanges();
				}
			} else {
				util.setEditMode(newValue);
			}
		},
		changeDataCallback: (data: object | null, changes = true) => {
			setFormValid(false);
			setFlags((prevState) => {
				return { ...prevState, hasChanges: prevState.editMode && changes };
			});
		},
		cancelClicked: async () => {
			if (!flags.hasChanges) {
				util.setEditMode(false);
				return true;
			}

			if (await util.cancelConfirmation()) {
				util.setEditMode(false);
				await util.resetLocalChanges();
				return true;
			}

			return false;
		},
		setValidatorCallback: (validator: Validator) => {
			setFormValidator(validator);
			setValidationResult(null);
			setFormValid(false);
		},
		setHasChanges: (hasChanges: boolean) => {
			setFlags({ ...flags, hasChanges: hasChanges });
		},
	};

	return (
		<div
			className={`e-panel widget ${flags.visible ? '' : 'hidden'}`}
			data-row={props.position.row}
			data-col={props.position.col}
			data-sizex={props.position.sizeX}
			data-sizey={props.position.sizeY}
			data-minsizex={props.position.sizeX}
			data-minsizey={props.position.sizeY}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>

				{popupConfirmation ? (
					<NotificationPrompt
						title={popupConfirmation.title}
						message={popupConfirmation.message}
						handleUserResponse={popupConfirmation.userResponse}
					/>
				) : null}

				<div className={'widget-view alert-messages-widget'}>
					<AlertMessagesDetails
						ref={resetRef}
						entityId={props.entityId}
						editMode={flags.editMode}
						hasChanges={flags.hasChanges}
						editedAlertMessage={editedAlertMessage}
						alertMessages={alertMessages}
						formValid={formValid}
						validationResult={validationResult}
						cancelClicked={events.cancelClicked}
						setHasChanges={events.setHasChanges}
						setEditMode={util.setEditMode}
						checkChanges={util.checkChanges}
						setEditedAlertMessage={setEditedAlertMessage}
						setAlertMessages={setAlertMessages}
						validateForm={util.validateForm}
						setValidatorCallback={events.setValidatorCallback}
						formValidations={formValidations}
						setValidationsCallback={setFormValidations
					}
					/>
				</div>
			</div>
		</div>
	);
};

export default AlertMessagesWidget;
