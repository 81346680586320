import AlertEntityTypeEnum from './AlertEntityTypeEnum';
import AlertTypesEnum from './AlertTypesEnum';
import AnalogAlertMetadata from './AnalogAlertMetadata';
import DigitalAlertMetadata from './DigitalAlertMetadata';
import DurationExceededAlertMetadata from './DurationExceededAlert';
import EventFrequencyEnum from './EventFrequencyEnum';
import GeofenceAlertMetadata from './GeofenceAlertMetadata';
import IdIdentifiable from './IdIdentifiable';
import MainPowerAlertMetadata from './MainPowerAlertMetadata';
import MessageWrapper from './MessageWrapper';
import PrivateMileageAlertMetadata from './PrivateMileageAlert';
import AlertTemplate from "./AlertTemplate";
import TowingAlertMetadata from "./TowingAlertMetadata";

class Alert extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.alertType = AlertTypesEnum.Unknown;
		this.customerId = customerId;
		this.trackedEntities = [];
		this.alertRecipients = [];
		this.useNotification = true;
		this.usePopup = false;
		this.useSms = true;
		this.useEmail = true;
		this.useWhatsApp = true;
		this.active = true;
		this.eventFrequency = EventFrequencyEnum.Always;
		this.notificationContent = new MessageWrapper();
		this.popupContent = new MessageWrapper();
		this.mailContent = new MessageWrapper();
		this.smsContent = new MessageWrapper();
		this.whatsAppContent = new MessageWrapper();
		this.PrivateMileageAlertMetadata = new PrivateMileageAlertMetadata();
		this.DurationExceededAlertMetadata = new DurationExceededAlertMetadata();
		this.analogAlertMetadata = new AnalogAlertMetadata();
		this.digitalAlertMetadata = new DigitalAlertMetadata();
		this.mainPowerAlertMetadata = new MainPowerAlertMetadata();
		this.geofenceAlertMetadata = new GeofenceAlertMetadata();

		this.towingAlertMetadata = new TowingAlertMetadata();
		this.notificationTemplate = new MessageWrapper();
		this.popupTemplate = new MessageWrapper();
		this.mailTemplate = new MessageWrapper();
		this.smsTemplate = new MessageWrapper();
		this.whatsAppTemplate = new MessageWrapper();
	}

	public active: boolean;
	public code: string;
	public name: string;
	public customerId: string;
	public alertType: AlertTypesEnum;
	public eventTypeId: AlertTypesEnum;
	public entityType: AlertEntityTypeEnum;
	public trackedEntities: Object[];
	public alertRecipients: Object[];
	public useNotification: boolean;
	public usePopup: boolean;
	public useEmail: boolean;
	public useSms: boolean;
	public useWhatsApp: boolean;
	public eventFrequency: EventFrequencyEnum;
	public notificationContent: MessageWrapper;
	public popupContent: MessageWrapper;
	public mailContent: MessageWrapper;
	public smsContent: MessageWrapper;
	public whatsAppContent: MessageWrapper;
	public PrivateMileageAlertMetadata: PrivateMileageAlertMetadata;
	public DurationExceededAlertMetadata: DurationExceededAlertMetadata;
	public analogAlertMetadata: AnalogAlertMetadata;
	public digitalAlertMetadata: DigitalAlertMetadata;
	public mainPowerAlertMetadata: MainPowerAlertMetadata;
	public geofenceAlertMetadata: GeofenceAlertMetadata;
	public towingAlertMetadata: TowingAlertMetadata;

	public notificationTemplate: MessageWrapper;
	public popupTemplate: MessageWrapper;
	public mailTemplate: MessageWrapper;
	public smsTemplate: MessageWrapper;
	public whatsAppTemplate: MessageWrapper;

	//templates
	templates:  Record<string, AlertTemplate> = {};
}

export default Alert;
