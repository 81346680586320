import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
	to: string;
	className?: string;
	children?: ReactNode | ReactNode[];
	onClick?: () => void;
}
const LinkHighlighted = (props: Props) => {
	const location = useLocation();
	const [highlight, setHighlight] = useState<boolean>(false);
	useEffect(() => {
		if (location && location.pathname) {
			setHighlight(location.pathname.toLowerCase().startsWith(props.to.toLowerCase()));
		}
	}, [location]);
	return (
		<Link to={props.to} className={`${props.className}${highlight ? '-highlight' : ''}`} onClick={props.onClick}>
			{props.children}
		</Link>
	);
};

export default LinkHighlighted;
