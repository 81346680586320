import '../LogWidget/LogWidget.scss';
import './InputTemplateConnectionDialog.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import DeviceSensorTemplateConnections from 'models/DeviceSensorTemplateConnections';
import SensorTypeEnum from 'models/SensorTypeEnum';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ValidationResult } from 'shared/validation/interfaces';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import { DEBOUNCE_TIME } from '../../../Constants';
import GlobalSettings from '../../../GlobalSettings.json';
import MaterialTextField from '../../../shared/components/MaterialTextField/MaterialTextField';
import DateTimeUtil, { DateTimeConversionUtil } from '../../../shared/datetime/DateTimeUtil';
import Validator from '../../../shared/validation/Validator';
import { TranslateText } from '../../../utils/Translations';
import BaseView from '../../BaseView';
import MaterialDatePicker from '../../Common/DateTime/MaterialDatePicker';
import { DebouncedButton } from '../../Common/DebouncedButton';
import { DialogUtil } from '../../Common/NotificationDialog';
import { ValidationMessage } from '../../ValidationMessage/ValidationMessage';
import { DialogMode, TemplateListItem } from './DeviceTemplateWidget';

function mapDispatch(dispatch: Dispatch) {
	return {
		unsavedDataActions: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

const connector = connect(null, mapDispatch, null, { forwardRef: true });
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	getAccessTokenCallback: () => string;
	inputTemplateData: DeviceSensorTemplateConnections;
	deviceId: string;
	templatesData: TemplateListItem[];
	savedCallback: () => void;
	closedCallback: () => void;
	deviceType: SensorTypeEnum;
	connectedObjectId?: string;
};

type State = {
	invalidForm: boolean;
	mode: DialogMode;
	dateTime: Date;
	validationResult: ValidationResult;
	visible: boolean;
	templateId: string;
	startValue?: number;
	countRelevantStateStartValue?: number;
	newHoursDuration: number;
	newMinutesDuration: number;
};

interface NewConnection {
	connectionId?: string;
	deviceId: string;
	sensorId: string;
	templateId: string;
	sensorType: SensorTypeEnum;
	connectionStartDate: Date;
	startValue?: number;
	connectedObjectId?: string;
	countRelevantStateStartValue?: number;
}

class InputTemplateConnectionDialog extends BaseView<Props, State> {
	private unsavedData = false;
	formValidator: Validator;

	constructor(props: Props) {
		super(props);

		this.state = {
			invalidForm: false,
			mode: DialogMode.None,
			dateTime: new Date(),
			validationResult: null,
			visible: false,
			templateId: this.props.inputTemplateData.templateId,
			startValue: null,
			countRelevantStateStartValue: null,
			newHoursDuration: null,
			newMinutesDuration: null,
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.inputTemplateData.templateId !== this.props.inputTemplateData.templateId ||
			prevProps.inputTemplateData.sensorId !== this.props.inputTemplateData.sensorId
		) {
			this.setState(
				{
					templateId: this.props.inputTemplateData.templateId,
				},
				() => this.validateForm()
			);
		}
	}

	setUnsavedData(unsavedData: boolean) {
		this.unsavedData = unsavedData;
		this.props.unsavedDataActions(this.unsavedData);
	}

	private handleUserResponse(response: boolean) {
		if (response) {
			this.setUnsavedData(false);
			this.props.closedCallback();
		}
	}

	handleValueChange(value: string) {
		this.setState(
			{
				templateId: value,
			},
			() => {
				this.setState(
					{
						startValue: null,
						countRelevantStateStartValue: null,
						newHoursDuration: null,
						newMinutesDuration: null,
					},
					() => this.validateForm()
				);
			}
		);
	}

	handleValueChangeStartValue(
		value: string,
		propName: 'countRelevantStateStartValue' | 'newHoursDuration' | 'newMinutesDuration'
	) {
		const newState = { ...this.state, [propName]: !!value ? parseInt(value) : null };

		if (propName === 'newHoursDuration' || propName === 'newMinutesDuration') {
			newState.startValue = newState.newHoursDuration * 60 + newState.newMinutesDuration;
		}

		this.setState(newState, () => this.validateForm());
	}

	validateForm() {
		this.setUnsavedData(this.state.templateId !== this.props.inputTemplateData.templateId);

		const newDateTime = new Date();
		this.formValidator.validate(this.state).then((result) => {
			this.setState({
				validationResult: result.validationResult,
				invalidForm: !result.formResult,
				dateTime: newDateTime,
			});
		});
	}

	setUpHeader(): string {
		if (this.state.mode === DialogMode.Edit) {
			return TranslateText('widgets.editTemplate');
		} else {
			return TranslateText('widgets.connectTemplate');
		}
	}

	setFormValidatorForNewConnection() {
		this.formValidator = new Validator({
			fieldRules: {
				templateId: {
					rules: {
						required: ValidatorFunctions.required(),
					},
				},
				newHoursDuration: {
					rules: {
						minNumber: ValidatorFunctions.minNumber(0),
						maxNumber: ValidatorFunctions.maxNumber(9999999),
					},
				},
				newMinutesDuration: {
					rules: {
						minNumber: ValidatorFunctions.minNumber(0),
						maxNumber: ValidatorFunctions.maxNumber(59),
					},
				},
				// countRelevantStateStartValue: {
				// 	rules: {
				// 		maxLength: ValidatorFunctions.maxLength(7),
				// 		minNumber: ValidatorFunctions.minNumber(0),
				// 		maxNumber: ValidatorFunctions.maxNumber(9999999),
				// 	},
				// },
			},
		});
	}

	showDialog(mode: DialogMode) {
		if (this.props.deviceType === SensorTypeEnum.Duration || this.props.deviceType === SensorTypeEnum.Digital) {
			this.setState({
				startValue: null,
				newHoursDuration: null,
				newMinutesDuration: null,
			});
		}
		if (mode !== DialogMode.Edit) {
			this.setState({ ...this.state, mode: mode, invalidForm: true, visible: true });
		} else {
			this.setState({ ...this.state, mode: mode, visible: true });
		}

		this.setFormValidatorForNewConnection();
		this.validateForm();

		this.setUnsavedData(false);
	}

	closeDialog() {
		if (this.props.deviceType === SensorTypeEnum.Duration || this.props.deviceType === SensorTypeEnum.Digital) {
			this.setState(
				{
					startValue: null,
					newHoursDuration: null,
					newMinutesDuration: null,
				},
				() => this.validateForm()
			);
		}
		if (this.unsavedData === true) {
			this.notifyAboutUnsavedData();
			return;
		}
		this.setUnsavedData(false);

		this.setState({ visible: false });
		this.props.savedCallback();
	}

	notifyAboutUnsavedData() {
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then(this.handleUserResponse.bind(this));
	}

	saveConnection() {
		let newConnection: NewConnection;

		const baseValidationResult = super.validateBeforeSave();
		if (!baseValidationResult) {
			this.setState({ ...this.state, invalidForm: true });
			return;
		}

		const template = this.props.templatesData.find((t) => t.id === this.state.templateId);
		if (this.props.inputTemplateData.connectionId !== null) {
			newConnection = {
				connectionId: this.props.inputTemplateData.connectionId,
				sensorId: this.props.inputTemplateData.sensorId,
				deviceId: this.props.inputTemplateData.deviceId,
				sensorType: SensorTypeEnum[template.sensorType] as any,
				templateId: this.state.templateId,
				connectionStartDate: this.state.dateTime,
				startValue: this.state.startValue,
				connectedObjectId: this.props.connectedObjectId,
				countRelevantStateStartValue: this.state.countRelevantStateStartValue,
			};
		} else {
			newConnection = {
				sensorId: this.props.inputTemplateData.sensorId,
				deviceId: this.props.inputTemplateData.deviceId,
				sensorType: SensorTypeEnum[template.sensorType] as any,
				templateId: this.state.templateId,
				connectionStartDate: this.state.dateTime,
				startValue: this.state.startValue,
				connectedObjectId: this.props.connectedObjectId,
				countRelevantStateStartValue: this.state.countRelevantStateStartValue,
			};
		}

		const url = GlobalSettings.connectionsApi + '/CreateDeviceSensorTemplateConnection';
		fetch(url, {
			method: 'post',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
			}),
			body: JSON.stringify(newConnection),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status.toString());
				} else {
					return response.json();
				}
			})
			.then((data) => {
				this.setUnsavedData(false);
				this.setState({ visible: false });
				this.props.savedCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	disconnect() {
		this.setState({ templateId: null });
		const url =
			GlobalSettings.connectionsApi +
			'/TerminateDeviceSensorTemplateConnection/' +
			this.props.inputTemplateData.connectionId;
		fetch(url, {
			method: 'post',
			headers: new Headers({
				Authorization: 'Bearer ' + this.props.getAccessTokenCallback(),
			}),
		}).then((data) => {
			data.json().then((data) => {
				if (data) {
					this.setUnsavedData(false);
					this.setState({ visible: false });
					setTimeout(() => {
						this.props.savedCallback();
					}, 500);
				}
			});
		});
	}

	showStartValue() {
		let result = false;

		if (this.props.deviceType === SensorTypeEnum.Duration) {
			result =
				this.props.connectedObjectId !== null &&
				(this.state.mode !== DialogMode.Edit ||
					(this.state.mode === DialogMode.Edit &&
						this.state.templateId !== this.props.inputTemplateData.templateId &&
						this.unsavedData));
		} else if ((this.props.deviceType & SensorTypeEnum.Digital) === SensorTypeEnum.Digital) {
			result =
				this.state.templateId &&
				(this.props.templatesData.find((t) => t.id === this.state?.templateId)?.sensorType as any) ===
					'Digital' &&
				this.props.templatesData.find((t) => t.id === this.state.templateId).durationCounter &&
				this.props.connectedObjectId !== null &&
				(this.state.mode !== DialogMode.Edit ||
					(this.state.mode === DialogMode.Edit &&
						this.state.templateId !== this.props.inputTemplateData.templateId &&
						this.unsavedData));
		}

		return result;
	}

	getSensorTranslation() {
		const sensorTranslation = TranslateText(`widgets.sensors.${this.props.inputTemplateData.sensorCode}`);
		return sensorTranslation !== `widgets.sensors.${this.props.inputTemplateData.sensorCode}`
			? sensorTranslation
			: this.props.inputTemplateData.sensorCode;
	}

	render() {
		return (
			<Dialog
				onClose={() => this.closeDialog()}
				open={this.state.visible}
				onEnter={() => this.setState({ dateTime: new Date() })}
				fullWidth
				disableBackdropClick
			>
				<div className="multirow-dialog-min-height">
					<DialogTitle>{this.setUpHeader()}</DialogTitle>
					<DialogContent>
						<div className="e-panel-container">
							<form
								className="input-template-connection-dialog"
								id="connectInputToTemplateForm"
								noValidate={true}
							>
								<div>
									<div style={{ width: 160, display: 'inline-block' }}>
										{TranslateText('widgets.input')}
									</div>
									<div style={{ width: 160, display: 'inline-block' }}>
										{this.getSensorTranslation()}
									</div>
								</div>
								<div style={{ display: 'flex', marginTop: 10 }}>
									<div style={{ width: 160, display: 'inline-block' }}>
										<div>{TranslateText('widgets.template')}</div>
									</div>
									<div style={{ width: 400 }}>
										<MaterialAutocomplete
											className="material-autocomplete report-selection-component"
											valueId={this.state.templateId}
											dataSource={this.props.templatesData}
											onChange={({ value }) => this.handleValueChange(value as string)}
											fullWidth={true}
											disableClearable
										/>
									</div>
								</div>
								<ValidationMessage result={this.state.validationResult?.templateId} />

								{/* {this.state.templateId &&
									(this.props.templatesData.find(
										(t: TemplateListItem) => t.id === this.state?.templateId
									)?.sensorType as any) === 'Digital' &&
									this.props.templatesData.find((t) => t.id === this.state.templateId)
										.countRelevantState &&
									this.props.connectedObjectId != null &&
									(this.state.mode !== DialogMode.Edit ||
										(this.state.mode === DialogMode.Edit &&
											this.state.templateId !== this.props.inputTemplateData.templateId &&
											this.unsavedData)) && (
										<>
											<div style={{ display: 'flex', marginTop: 10 }}>
												<div style={{ width: 160, display: 'inline-block' }}>
													{TranslateText('widgets.countRelevantStateStartValue')}
												</div>
												<div style={{ width: '400px' }}>
													<MaterialTextField
														isForNewAddWizard={false}
														id="countRelevantStateStartValue"
														type="number"
														fullWidth
														label={null}
														className="auto-startValue resize-font"
														inputProps={{
															min: 0,
															style: { fontSize: 13, padding: '20px 0px 7px' },
														}}
														name="startValue"
														value={this.state.countRelevantStateStartValue}
														handleValueChange={(value) =>
															this.handleValueChangeStartValue(
																value,
																'countRelevantStateStartValue'
															)
														}
														disabled={
															this.state.mode === DialogMode.Edit &&
															!this.unsavedData &&
															this.props.connectedObjectId != null
														}
													/>
												</div>
											</div>
											<ValidationMessage
												result={this.state.validationResult?.countRelevantStateStartValue}
											/>
										</>
									)} */}

								{this.showStartValue() ? (
									<div className="start-value-container">
										<div className="start-value-title">
											{this.props.deviceType === SensorTypeEnum.Duration
												? TranslateText('widgets.startValue')
												: (this.props.deviceType & SensorTypeEnum.Digital) ===
												  SensorTypeEnum.Digital
												? TranslateText('widgets.durationCounterStartValue')
												: null}
										</div>
										<div className="start-value-content">
											<div className="start-value-hours">
												<MaterialTextField
													isForNewAddWizard={false}
													id="newHoursDuration"
													type="number"
													fullWidth
													label={TranslateText('common.hours')}
													className="auto-startValue resize-font"
													inputProps={{
														min: 0,
														style: {
															fontSize: 13,
															padding: '20px 0px 7px',
															backgroundColor: red,
														},
													}}
													name="newHoursDuration"
													value={this.state.newHoursDuration}
													handleValueChange={(value) =>
														this.handleValueChangeStartValue(value, 'newHoursDuration')
													}
													disabled={
														this.state.mode === DialogMode.Edit &&
														!this.unsavedData &&
														this.props.connectedObjectId !== null
													}
												/>
												<ValidationMessage
													result={this.state.validationResult?.newHoursDuration}
												/>
											</div>
											<div className="start-value-minutes">
												<MaterialTextField
													isForNewAddWizard={false}
													id="newMinutesDuration"
													type="number"
													fullWidth
													label={TranslateText('common.minutes')}
													className="auto-startValue resize-font"
													inputProps={{
														min: 0,
														style: {
															fontSize: 13,
															padding: '20px 0px 7px',
															backgroundColor: red,
														},
													}}
													name="newMinutesDuration"
													value={this.state.newMinutesDuration}
													handleValueChange={(value) =>
														this.handleValueChangeStartValue(value, 'newMinutesDuration')
													}
													disabled={
														this.state.mode === DialogMode.Edit &&
														!this.unsavedData &&
														this.props.connectedObjectId !== null
													}
												/>
												<ValidationMessage
													result={this.state.validationResult?.newMinutesDuration}
												/>
											</div>
										</div>
									</div>
								) : null}

								<div style={{ marginTop: 10 }}>
									<div style={{ width: 160, display: 'inline-block' }}>
										{TranslateText('widgets.dateTime')}
									</div>
									<MaterialDatePicker
										name="connectionStartDate"
										showTime={true}
										date={this.state.dateTime}
										format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
											DateTimeUtil.dateTimeFormat(),
											true
										)}
										fullWidth={true}
										disabled={true}
									/>
								</div>
							</form>
						</div>
						<div id="buttonsHost" className="input-template-connection-buttons-area">
							<Button
								className="widget-button left-align-button cancel-button"
								onClick={() => this.closeDialog()}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							{this.state.mode === DialogMode.Edit ? (
								<div className="right-side-buttons">
									<Button
										disabled={this.props.inputTemplateData.connectionId === null}
										className="widget-button"
										onClick={() => this.disconnect()}
									>
										{TranslateText('common.buttonDisconnect')}
									</Button>
									<DebouncedButton
										disabled={this.state.invalidForm || !this.unsavedData}
										className="widget-button"
										onClick={() => this.saveConnection()}
										debounceTime={DEBOUNCE_TIME}
									>
										{TranslateText('common.buttonSave')}
									</DebouncedButton>
								</div>
							) : (
								<div className="right-side-buttons">
									<DebouncedButton
										disabled={this.state.invalidForm || !this.unsavedData}
										className="widget-button"
										onClick={() => this.saveConnection()}
										debounceTime={DEBOUNCE_TIME}
									>
										{TranslateText('common.buttonLink')}
									</DebouncedButton>
								</div>
							)}
						</div>
					</DialogContent>
				</div>
			</Dialog>
		);
	}
}

export default connector(InputTemplateConnectionDialog);
