import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimConfig, ClaimUtil } from 'authorization/ClaimUtil';
import AddWizard from 'components/AddWizard';
import OverviewSearchBar from 'components/BaseOverview/OverviewSearchBar';
import { PageResult } from 'components/Common/Pagination';
import { NavigationResult } from 'components/DetailsNavigationBar/types';
import NewAddWizard from 'components/NewAddWizard';
import AddAlertTimeSchedule from 'components/NewAddWizard/Views/AddAlertTimeSchedule';
import AddressView from 'components/NewAddWizard/Views/Address/AddressView';
import AlertView from 'components/NewAddWizard/Views/Alert/AlertView';
import DeviceView from 'components/NewAddWizard/Views/Device/DeviceView';
import DriverView from 'components/NewAddWizard/Views/Driver/DriverView';
import EntitiesView from 'components/NewAddWizard/Views/Entities/EntitiesView';
import GroupView from 'components/NewAddWizard/Views/Group/GroupView';
import IntegrationsView from 'components/NewAddWizard/Views/Integration';
import LocationView from 'components/NewAddWizard/Views/Location/LocationView';
import LocationsView from 'components/NewAddWizard/Views/Locations';
import MessageView from 'components/NewAddWizard/Views/Message/MessageView';
import ObjectView from 'components/NewAddWizard/Views/Object/ObjectView';
import PersonsView from 'components/NewAddWizard/Views/Persons/PersonsView';
import { TemplateOverviewDto } from 'components/TemplatesOverview/TemplatesOverview';
import _ from 'lodash';
import { AddEntityResult } from 'models/AddEntityResult';
import Alert from 'models/Alert';
import AlertTypesEnum from 'models/AlertTypesEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { GridFilters, MatchMode } from 'models/GridOverview';
import { addLocale } from 'primereact/api';
import { DataTable, DataTablePFSEvent, DataTableRowClickEventParams } from 'primereact/datatable';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationState } from 'store';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { gridActionCreators } from 'store/GridOverview';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { getRequestFilters } from 'utils/GridOverviewUtils';
import { useEffectWithNoMount } from 'utils/hooks';
import { TranslateText } from 'utils/Translations';
import ObjectFunctionEnum from '../../models/ObjectFunctionEnum';
import { InformationNotificationDto } from '../InformationNotificationsOverview/InformationNotificationsOverview';
import InformationNotificationView from '../NewAddWizard/Views/InformationNotification/InformationNotificationView';
import TemplateView from '../NewAddWizard/Views/Template/TemplateView';
import { ColumnFlagOrdering } from './Columns/MultiSelectionColumn';
import LocationInlineView from './InlineViews';
import {ImportDialog} from "./Import";
import {ExportDialog} from "./Export";

const setTranslations = () =>
	addLocale('en', {
		[MatchMode.Contains]: TranslateText('maintenanceOverview.filterOption.contains'),
		[MatchMode.Equals]: TranslateText('maintenanceOverview.filterOption.equals'),
		[MatchMode.NotEquals]: TranslateText('maintenanceOverview.filterOption.notEquals'),
		[MatchMode.LessThan]: TranslateText('maintenanceOverview.filterOption.lessThan'),
		[MatchMode.LessThanOrEqual]: TranslateText('maintenanceOverview.filterOption.lessThanOrEqual'),
		[MatchMode.GreaterThan]: TranslateText('maintenanceOverview.filterOption.greaterThan'),
		[MatchMode.GreaterThanOrEqual]: TranslateText('maintenanceOverview.filterOption.greaterThanOrEqual'),
		[MatchMode.DateIs]: TranslateText('maintenanceOverview.filterOption.dateIs'),
		[MatchMode.DateIsNot]: TranslateText('maintenanceOverview.filterOption.dateIsNot'),
		[MatchMode.DateBefore]: TranslateText('maintenanceOverview.filterOption.dateBefore'),
		[MatchMode.DateAfter]: TranslateText('maintenanceOverview.filterOption.dateAfter'),
		noFilter: TranslateText('maintenanceOverview.filterOption.noFilter'),
		dayNames: [
			TranslateText('calendarLocales.dayNames.sunday'),
			TranslateText('calendarLocales.dayNames.monday'),
			TranslateText('calendarLocales.dayNames.tuesday'),
			TranslateText('calendarLocales.dayNames.wednesday'),
			TranslateText('calendarLocales.dayNames.thursday'),
			TranslateText('calendarLocales.dayNames.friday'),
			TranslateText('calendarLocales.dayNames.saturday'),
		],
		monthNamesShort: [
			TranslateText('calendarLocales.monthNamesShort.january'),
			TranslateText('calendarLocales.monthNamesShort.february'),
			TranslateText('calendarLocales.monthNamesShort.march'),
			TranslateText('calendarLocales.monthNamesShort.april'),
			TranslateText('calendarLocales.monthNamesShort.may'),
			TranslateText('calendarLocales.monthNamesShort.june'),
			TranslateText('calendarLocales.monthNamesShort.july'),
			TranslateText('calendarLocales.monthNamesShort.august'),
			TranslateText('calendarLocales.monthNamesShort.september'),
			TranslateText('calendarLocales.monthNamesShort.october'),
			TranslateText('calendarLocales.monthNamesShort.november'),
			TranslateText('calendarLocales.monthNamesShort.december'),
		],
	});

interface Props {
	columns: ReactNode[];
	initialFilters: GridFilters;
	getGridDataUrl: string;
	headerText: string;
	disableAddButton: boolean;
	showAddConfigClaim?: ClaimConfig[];
	entityType: EntityTypeEnum;
	addEntityUrl?: string;
	useNewAddWizard?: boolean;
	columnFlagOrder?: ColumnFlagOrdering;
	inlineEdit?: boolean;
	hideOverviewSearchbar?: boolean;
	addGetPageToUrl?: boolean;
	fetchDataTrigger?: boolean;
	refreshInterval?: number | null;
	resetFiltersTrigger?: boolean;
	setDataScrollCallback?: (data: any) => void;
	disableImportButton: boolean;
	showImportConfigClaim?: ClaimConfig[];
	disableExportButton: boolean;
	showExportConfigClaim?: ClaimConfig[];
}

interface Entity {
	id: string;
}

export const NewGridOverview = ({
	headerText,
	initialFilters,
	columns,
	getGridDataUrl,
	disableAddButton,
	showAddConfigClaim,
	entityType,
	addEntityUrl,
	useNewAddWizard,
	columnFlagOrder,
	inlineEdit,
	hideOverviewSearchbar = false,
	addGetPageToUrl = true,
	fetchDataTrigger,
	refreshInterval = null,
	resetFiltersTrigger,
	setDataScrollCallback,
	disableImportButton,
	showImportConfigClaim,
	disableExportButton,
	showExportConfigClaim
}: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const filterText = useSelector(
		(s: ApplicationState) => s.globalCustomer.filterText[`${entityType.toLocaleLowerCase()}s`]
	);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const sessionCustomerLevel = useSelector((s: ApplicationState) => s.currentSession.customerLevel);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const gridFilters = useSelector((s: ApplicationState) => s.gridOverview[entityType].gridFilters);
	const take = useSelector((s: ApplicationState) => s.currentSession.defaultLines || 10);
	const filteredCustomerId = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer?.id);
	const impersonatedCustomerId = useSelector(
		(s: ApplicationState) => s.globalCustomer.filteredCustomer?.id ?? s.currentSession.customerId
	);
	const storeSkip = useSelector((s: ApplicationState) => s.gridOverview[entityType].gridPage);
	const fromMenu = useSelector((s: ApplicationState) => s.gridOverview[entityType].fromMenu);
	const gridSelectedEntityId = useSelector((s: ApplicationState) => s.gridOverview[entityType].gridSelectedEntityId);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const [openDialogImport, setOpenDialogImport] = useState(false);
	const [openDialogExport, setOpenDialogExport] = useState(false);
	//redirect to grid overview with inline edit
	const inlineGridRedirect = useSelector((s: ApplicationState) => s.gridOverview[entityType].inlineGridRedirect);
	const inlineRedirectId = useRef<string>(null);
	useEffect(() => {
		if (!inlineGridRedirect && inlineRedirectId.current) {
			reloadGridData(storeSkip, () => {
				onInlineRowDoubleClick(inlineRedirectId.current);
				inlineRedirectId.current = null;
			});
		}

		if (inlineGridRedirect && gridSelectedEntityId) {
			inlineRedirectId.current = gridSelectedEntityId;

			ajaxUtil
				.post<NavigationResult>(`${getGridDataUrl}/navigation`, {
					entityId: gridSelectedEntityId,
					customerId: filteredCustomerId,
					filterText: filterText,
					...getRequestFilters(filters),
				})
				.then((navigation) => {
					const newSkip = Math.floor(navigation.index / take) * take;
					dispatch(gridActionCreators.updateGridPage(entityType, newSkip));
					dispatch(gridActionCreators.clearInlineGridRedirect(entityType));
				});
		} else if (inlineGridRedirect) {
			dispatch(gridActionCreators.clearInlineGridRedirect(entityType));
		}
	}, [inlineGridRedirect]);

	const gridDivRef = useRef(null);
	const gridRef = useRef(null);
	const manualSelection = useRef(false);
	const entityAdded = useRef(false);
	const hasMounted = useRef(false);

	const [showAddWizard, setShowAddWizard] = useState(false);
	const [gridData, setGridData] = useState([] as Entity[]);
	const [selectionId, setSelectionId] = useState(null);
	const [selection, setSelection] = useState(null);
	const [total, setTotal] = useState(0);
	const [filters, setFilters] = useState<GridFilters>(() => {
		const newFilters = _.cloneDeep(initialFilters);

		if (gridFilters) {
			if (gridFilters.sortOrder) {
				newFilters.sortOrder = gridFilters.sortOrder;
			}
			if (gridFilters.sortField) {
				newFilters.sortField = gridFilters.sortField;
			}
			if (gridFilters.flagOrder) {
				newFilters.flagOrder = gridFilters.flagOrder;
			}
			if (gridFilters.includeTime !== undefined) {
				newFilters.includeTime = gridFilters.includeTime;
			}
			if (gridFilters.filters) {
				for (const key in gridFilters.filters) {
					newFilters.filters[key] = gridFilters.filters[key];
				}
			}
		}

		return newFilters;
	});

	useEffect(() => {
		setTranslations();
		if (fromMenu) {
			dispatch(gridActionCreators.updateGridFromMenu(entityType, false));
			dispatch(gridActionCreators.updateGridSelectedEntityId(entityType, ''));
			reloadGridData(0);
		} else {
			if (gridSelectedEntityId) {
				dispatch(gridActionCreators.updateGridSelectedEntityId(entityType, ''));
				setSelectionId(gridSelectedEntityId);
			}
			reloadGridData(storeSkip);
		}

		if (gridRef.current && !!setDataScrollCallback) {
			gridRef.current.wrapper.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (gridRef.current && !!setDataScrollCallback) {
				gridRef.current.wrapper.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	useEffect(() => {
		if (showAddWizard || openDialogImport) {
			clearSelectedEntity(true);
			dispatch(availableCustomersActions.toggleSelection(false));
		} else {
			dispatch(availableCustomersActions.toggleSelection(true));
		}
	}, [showAddWizard, openDialogImport]);

	useEffectWithNoMount(() => {
		reloadGridData(storeSkip);
	}, [filterText]);

	useEffectWithNoMount(() => {
		dispatch(gridActionCreators.updateGridFilters(entityType, filters));
		if (entityAdded.current) {
			return;
		}
		setSelectionId(null);
		reloadGridData(0);
	}, [filters]);

	useEffect(() => {
		const selectedRow = gridData.find((i) => i.id === selectionId);
		if (!selectedRow) {
			scrollToPosition();
			if (inlineEdit) {
				setEntityData({});
				dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
				setSelectedEntityId(null);
			}
		}
		setSelection(selectedRow);
	}, [gridData, selectionId]);

	useEffect(() => {
		if (!showAddWizard) {
			return;
		}

		if (!manualSelection.current) {
			if (selection) {
				const selectedElement = gridRef.current.table.getElementsByClassName('p-highlight p-selectable-row')[0];
				if (selectedElement) {
					const selectedRowIndex = gridData.findIndex((i) => i.id === selection.id);
					const elementHeight = selectedElement.scrollHeight;
					scrollToPosition(selectedRowIndex * elementHeight);
				}
			}
		} else {
			manualSelection.current = false;
		}
	}, [selection]);

	useEffectWithNoMount(() => {
		reloadGridData(0);
		scrollToPosition();
	}, [filteredCustomerId]);

	useEffect(() => {
		if (fetchDataTrigger !== undefined) {
			reloadGridData(storeSkip);
		}
	}, [fetchDataTrigger]);

	useEffect(() => {
		if (!hasMounted.current) {
			hasMounted.current = true;
			return;
		}

		if (resetFiltersTrigger !== undefined) {
			setSelectionId(null);
			setFilters(initialFilters);
		}
	}, [resetFiltersTrigger]);

	const fetchData = async () => {
		await reloadGridData(storeSkip);
	};

	useEffect(() => {
		if (refreshInterval) {
			const intervalId = setInterval(fetchData, refreshInterval, []);
			return () => {
				clearInterval(intervalId);
			};
		}
	}, [storeSkip, take, filterText, filteredCustomerId, filters, refreshInterval]);

	const reloadGridData = useCallback(
		(skip: number, callBack?: () => void) => {
			if (!inlineGridRedirect) {
				if (skip !== storeSkip) {
					dispatch(gridActionCreators.updateGridPage(entityType, skip));
				}
				const { sortOrder, sortField, fieldFilters, flagOrder, includeTime } = getRequestFilters(filters);
				ajaxUtil
					.post<PageResult<Entity>>(
						`${getGridDataUrl}${addGetPageToUrl ? '/getPage' : ''}`,
						{
							skip: skip,
							take: take,
							filterText: filterText,
							customerId: filteredCustomerId,
							sortOrder,
							sortField,
							flagOrder,
							includeTime,
							fieldFilters: entityAdded.current ? [] : fieldFilters,
							...(entityType === EntityTypeEnum.Report && {
								loggedInCustomerLevel: sessionCustomerLevel,
								customerId: impersonatedCustomerId,
								getAllGeneratedReports: ClaimUtil.validateClaim(user, {
									claim: ClaimType.Reporting,
									values: [ClaimValue.editGeneratedReports],
								}),
							}),
						},
						{ showLoading: false }
					)
					.then((result) => {
						entityAdded.current = false;
						callBack && callBack();
						setGridData(result.data);
						setTotal(result.total);
					});
			}
		},
		[storeSkip, take, filterText, filteredCustomerId, filters, refreshInterval, inlineGridRedirect]
	);

	const entityAddedCallback = useCallback(
		(response: object) => {
			setShowAddWizard(false);
			const entityResult = response as AddEntityResult;
			entityAdded.current = true;
			const newSkip = Math.floor(entityResult.insertIndex / take) * take;
			reloadGridData(newSkip);
			dispatch(gridActionCreators.updateGridSelectedEntityId(entityType, entityResult.id));
			setSelectionId(entityResult.id);
			setFilters((oldFilters) => ({ ...oldFilters, filters: initialFilters.filters }));
		},
		[
			storeSkip,
			take,
			customerLevel,
			reloadGridData,
			gridActionCreators.updateGridPage,
			dispatch,
			setSelectionId,
			setShowAddWizard,
			filters,
		]
	);

	const scrollToPosition = useCallback((position = 0) => {
		if (gridRef.current.wrapper) {
			gridRef.current.wrapper.scrollTop = position;
		}
	}, []);

	const getAddWizardComponents = (entityType: EntityTypeEnum, formData: any) => {
		switch (entityType) {
			case EntityTypeEnum.Alert:
				const alert = formData as Alert;

				return [
					{
						title: TranslateText('common.alert'),
						isRequired: true,
						view: AlertView,
					},
					{
						title: TranslateText('common.entities'),
						isRequired: false,
						view: EntitiesView,
						disableScroll: true,
					},
					alert?.alertType === AlertTypesEnum.Geofence
						? {
								title: TranslateText('common.locations'),
								isRequired: false,
								view: LocationsView,
								disableScroll: true,
						  }
						: null,
					{
						title: TranslateText('common.persons'),
						isRequired: false,
						view: PersonsView,
						disableScroll: true,
					},
					alert?.alertType === AlertTypesEnum.Geofence
						? {
								title: TranslateText('common.timeSchedule'),
								isRequired: false,
								view: AddAlertTimeSchedule,
								disableScroll: true,
						  }
						: null,
					{
						title: TranslateText('common.message'),
						isRequired: false,
						view: MessageView,
						disableScroll: true,
					},
				];
			case EntityTypeEnum.Object:
				return [
					{
						title: TranslateText('common.object'),
						isRequired: true,
						view: ObjectView,
					},
					{
						title: TranslateText('common.group'),
						isRequired: true,
						view: GroupView,
						disableScroll: true,
					},
					{
						title: TranslateText('common.device'),
						isRequired: false,
						view: DeviceView,
						claims: [
							{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
							{ claim: ClaimType.ObjectDeviceConnection, values: [ClaimValue.edit] },
						],
					},
					driverIdentification
						? {
								title: TranslateText('common.driver'),
								isRequired: false,
								view: DriverView,
								claims: [
									{ claim: ClaimType.Drivers, values: [ClaimValue.edit] },
									{ claim: ClaimType.ObjectPersonConnection, values: [ClaimValue.edit] },
								],
								enabled: formData.objectFunction !== ObjectFunctionEnum.Asset,
						  }
						: null,
				];
			case EntityTypeEnum.Location:
				return [
					{
						title: TranslateText('common.location'),
						isRequired: true,
						view: LocationView,
					},
					{
						title: TranslateText('fields.map'),
						isRequired: true,
						view: AddressView,
					},
				];
			case EntityTypeEnum.Template:
				return [
					{
						title: TranslateText('common.template'),
						isRequired: true,
						view: TemplateView,
					},
				];
			case EntityTypeEnum.InformationNotification:
				return [
					{
						title: TranslateText('common.informationNotification'),
						isRequired: true,
						view: InformationNotificationView,
					},
				];
			case EntityTypeEnum.Integration:
				return [
					{
						title: TranslateText('common.integration'),
						isRequired: true,
						view: IntegrationsView,
					},
					{
						title: TranslateText('integrations.restrictToGroups'),
						isRequired: false,
						view: GroupView,
						disableScroll: true,
					},
				];
		}
	};
	const getAddWizardScreen = () => {
		if (!showAddWizard) {
			return null;
		}

		if (useNewAddWizard) {
			return (
				<NewAddWizard
					wizardEntityType={entityType}
					toggleAddWizardCallback={() => {
						setShowAddWizard(false);
					}}
					entityAddedCallback={entityAddedCallback}
					getAddWizardComponents={getAddWizardComponents}
					postUrl={addEntityUrl}
					resizableDefaultHeight={entityType === EntityTypeEnum.Alert ? 431 : undefined}
				/>
			);
		}

		return (
			<AddWizard
				history={history}
				entityAddedCallback={entityAddedCallback}
				toggleAddWizardCallback={() => {
					setShowAddWizard(false);
				}}
				addWizardMode={entityType}
			/>
		);
	};

	const onFilterChange = ({ sortField, sortOrder, filters }: DataTablePFSEvent) => {
		if (inlineEdit) {
			setEntityData({});
			dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
			setSelectedEntityId(null);
		}

		const newFilters: GridFilters = {
			sortField,
			sortOrder,
			filters,
		};
		newFilters.includeTime = gridFilters.includeTime;
		if (columnFlagOrder && columnFlagOrder[sortField]) {
			newFilters.flagOrder = columnFlagOrder[sortField];
		}
		setFilters(newFilters);
	};

	const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
	const [selectedEntity, setSelectedEntity] = useState<object>(null);
	const [entityData, setEntityData] = useState<object>({});

	const unsavedData = useSelector((state: ApplicationState) => state.unsavedDataStore.unsavedData);
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	useEffect(() => {
		setSelectedEntityId(null);
		setEntityData({});
	}, [defaultCustomerId]);

	const onInlinePageChanged = (e: DataTablePFSEvent) => {
		if (unsavedData) {
			dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(true));
			dispatch(
				unsavedDataStoreActionCreators.setPromptCallback(() => {
					setEntityData({});
					setSelectedEntityId(null);

					reloadGridData(e.first);
					scrollToPosition();
				})
			);
			return;
		}

		setEntityData({});
		setSelectedEntityId(null);
		dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));

		reloadGridData(e.first);
		scrollToPosition();
	};

	useEffect(() => {
		if (unsavedData && selectedEntity !== null) {
			dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
		}
		dispatch(
			unsavedDataStoreActionCreators.setPromptCallback(() => {
				setEntityData({});
				setSelectedEntityId(null);
				setSelectedEntity(null);
			})
		);
	}, [selectedEntityId]);

	const clearSelectedEntity = (force = false) => {
		if (unsavedData && !force) {
			dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(true));
			dispatch(
				unsavedDataStoreActionCreators.setPromptCallback(() => {
					setEntityData({});
					setSelectedEntityId(null);
					setSelectedEntity(null);
				})
			);
			return;
		}
		setSelectedEntityId(null);
		setSelectedEntity(null);
		setEntityData({});
		dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
	};

	const [inlineLoading, setInlineLoading] = useState(false);
	const inlineEditExpansion = () => {
		if (!selectedEntity) {
			return null;
		}
		return (
			<LocationInlineView
				key={(selectedEntity as { id: string }).id}
				selectedEntity={selectedEntity}
				selectedEntityId={(selectedEntity as { id: string }).id}
				entityData={entityData}
				saveCallback={(entityData: object) => {
					setSelectedEntity(entityData);
					reloadGridData(storeSkip);
				}}
				onChangeCallback={onChangeCallback}
				clearSelectedEntity={clearSelectedEntity}
				isReadonly={!ClaimUtil.validateClaimList(user, showAddConfigClaim)}
				inlineLoading={inlineLoading}
				setInlineLoading={setInlineLoading}
			/>
		);
	};

	const onInlineRowDoubleClick = (inlineEditId: string) => {
		if (showAddWizard) {
			return;
		}
		if (unsavedData) {
			dispatch(unsavedDataStoreActionCreators.setShowNotificationPrompt(true));
			dispatch(
				unsavedDataStoreActionCreators.setPromptCallback(() => {
					setEntityData({});
					if (inlineEditId !== selectedEntityId) {
						setSelectedEntityId(inlineEditId);
					} else {
						setSelectedEntityId(null);
					}
				})
			);
			return;
		}

		setEntityData({});
		dispatch(unsavedDataStoreActionCreators.setInlineUnsavedData(false));
		if (inlineEditId !== selectedEntityId) {
			setSelectedEntityId(inlineEditId);
		} else {
			setSelectedEntityId(null);
		}
	};

	const onChangeCallback = (data: object) => {
		const newEntityData = { ...entityData, ...data };
		setEntityData(newEntityData);

		dispatch(
			unsavedDataStoreActionCreators.setInlineUnsavedData(
				unsavedData || JSON.stringify(selectedEntity) !== JSON.stringify(newEntityData)
			)
		);
	};

	useEffect(() => {
		if (!selectedEntityId) {
			setSelectedEntity(null);
			return;
		}
		setInlineLoading(true);
		ajaxUtil
			.get<object>(`${getGridDataUrl}/${selectedEntityId}`)
			.then((data) => {
				setInlineLoading(false);
				setSelectedEntity(data);
				setEntityData(data);
			})
			.catch(() => {
				setInlineLoading(false);
				setSelectedEntity(null);
				setEntityData({});
			});
	}, [selectedEntityId]);
	const handleScroll = () => {
		setDataScrollCallback(gridRef.current.wrapper.scrollTop);
	};
	const onRowDoubleClick = (e: DataTableRowClickEventParams): void => {
		if (entityType === EntityTypeEnum.Report) {
			return;
		}

		if (entityType === EntityTypeEnum.Template) {
			const data = e.data as TemplateOverviewDto;
			history.push(`/${entityType.toLowerCase()}s/${data.id}/${data.templateType}`);
			return;
		}

		if (entityType === EntityTypeEnum.InformationNotification) {
			const data = e.data as InformationNotificationDto;
			const path = entityType.charAt(0).toLowerCase() + entityType.slice(1);
			history.push(`/${path}s/${data.id}`);
			return;
		}

		if (inlineEdit) {
			onInlineRowDoubleClick(e.data.id);
			return;
		}
		history.push(`/${entityType.toLowerCase()}s/${e.data.id}`);
	};
	const getDialogForImport = () => {
		return(
			<ImportDialog
				open={openDialogImport}
				setOpen={(value: boolean) => setOpenDialogImport(value)}
				setOnClose={(value: boolean) => {
					if (value === true)
						setFilters(initialFilters);
						reloadGridData(storeSkip);
				}}
				entityType={entityType}
				importUrlData={getGridDataUrl}
			/>
		);
	}

	const getDialogForExport = () => {
		return (
			<ExportDialog
				open={openDialogExport}
				setOpen={(value: boolean) => setOpenDialogExport(value)}
				entityType={entityType}
				importUrlData={getGridDataUrl}
				history={history}
			/>
		);
	}

	return (
		<div className="new-overview-content">
			{!hideOverviewSearchbar && (
				<OverviewSearchBar
					headerText={headerText}
					statusDataSource={[]}
					actionDataSource={[]}
					toggleAddWizardCallback={() => {
						setSelection(null);
						setShowAddWizard(true);
					}}
					showAddButton={ClaimUtil.validateClaimList(user, showAddConfigClaim)}
					addWizardMode={entityType}
					disableAddButton={disableAddButton || unsavedData}
					clearFilters={() => setFilters(initialFilters)}
					clearAllFiltersBtnDisabled={_.isEqual(filters, initialFilters) || unsavedData}
					topSearchDisabled={unsavedData}
					disableImportButton={disableImportButton || unsavedData}
					showImportButton={showImportConfigClaim && ClaimUtil.validateClaimList(user, showImportConfigClaim)}
					importCallback={() => setOpenDialogImport(true)}
					disableExportButton={disableExportButton || unsavedData || total === 0}
					showExportButton={
					showExportConfigClaim &&
						ClaimUtil.validateClaimList(user, showExportConfigClaim) &&
						ClaimUtil.validateClaimList(user,[{
							claim: ClaimType.Reporting,
							values: [ClaimValue.view],
					}])}
					exportCallback={() => setOpenDialogExport(true)}
				/>
			)}
			{getAddWizardScreen()}
			{getDialogForImport()}
			{getDialogForExport()}
			<div className={'flex-grid-container'} ref={gridDivRef}>
				<DataTable
					ref={gridRef}
					emptyMessage={TranslateText('common.noData')}
					className={'new-grid-overview'}
					value={gridData}
					totalRecords={total}
					dataKey={'id'}
					rows={take}
					onRowDoubleClick={onRowDoubleClick}
					first={storeSkip}
					selection={selection}
					selectionMode={'single'}
					onSelectionChange={(e) => {
						if (!showAddWizard) {
							manualSelection.current = true;
							setSelectionId(e.value.id);
						}
					}}
					currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
					onPage={(e) => {
						if (e.first !== storeSkip) {
							if (inlineEdit) {
								onInlinePageChanged(e);
								return;
							}
							reloadGridData(e.first);
						}
					}}
					filters={filters.filters}
					filterDisplay="row"
					onFilter={onFilterChange}
					resizableColumns
					columnResizeMode="fit"
					responsiveLayout="scroll"
					scrollable
					scrollDirection="vertical"
					scrollHeight="flex"
					removableSort
					sortField={filters.sortField}
					sortOrder={filters.sortOrder}
					onSort={onFilterChange}
					paginator
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
					lazy
					editMode={'row'}
					rowExpansionTemplate={inlineEdit ? inlineEditExpansion : null}
					expandedRows={
						selectedEntityId
							? {
									[selectedEntityId]: true,
							  }
							: {}
					}
				>
					{columns}
				</DataTable>
			</div>
		</div>
	);
};
export default NewGridOverview;
