import './styles.scss';

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationData, ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from '../Common/DateTime/MaterialDatePicker';
import { DebouncedButton } from '../Common/DebouncedButton';
import { DialogUtil } from '../Common/NotificationDialog';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import { useDurationCorrectionDialog } from './hooks';
import { DurationCorrectionDialogProps, DurationCorrectionEditRow, DurationCorrectionRowData } from './types';

const DurationCorrectionDialog = ({
	visible = true,
	initialData = {
		id: null,
		requestDate: new Date(),
		templateId: null,
		sensorType: null,
		oldDuration: null,
		newDuration: null,
		personName: null,
	},
	...props
}: DurationCorrectionDialogProps): JSX.Element => {
	const [correctionData, setCorrectionData] = useState<DurationCorrectionEditRow>({
		id: null,
		requestDate: new Date(),
		templateId: null,
		sensorType: null,
		oldDuration: null,
		newDuration: null,
		newHoursDuration: null,
		newMinutesDuration: null,
		personName: null,
	});

	const [formValidator, setFormValidator] = useState(null as Validator);
	const [validForm, setValidForm] = useState<boolean>(false);
	const [unsavedData, setUnsavedData] = useState(false);
	const [validationResult, setValidationResult] = useState<ValidationResult>();
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { isDateUniqueRef, templatesData } = useDurationCorrectionDialog(props.entityId);

	useLayoutEffect(() => {
		const hoursAndMinutes = DateTimeUtil.fromMinutesToHoursAndMinutes(initialData?.newDuration);

		//Set data for edit mode
		setCorrectionData({
			...initialData,
			newHoursDuration: hoursAndMinutes.hours,
			newMinutesDuration: hoursAndMinutes.minutes,
		});
	}, [initialData]);

	useEffect(() => {
		if (visible) {
			const validator = new Validator(
				{
					fieldRules: {
						requestDate: {
							rules: {
								required: ValidatorFunctions.required(),
								validDate: {
									message: TranslateText('fieldsValidations.dateInFuture'),
									validationFunction: (data) => {
										const newData = data as DurationCorrectionRowData;
										return ValidatorFunctions.wrapInPromise(
											new Date(newData.requestDate) <= new Date()
										);
									},
								},
								validUniqueDate: {
									message: TranslateText('fieldsValidations.uniqueDate'),
									validationFunction: (data: ValidationData) => {
										const newData = data as DurationCorrectionRowData;
										if (!props.isEditMode && newData.templateId && isDateUniqueRef.current) {
											return isDateUniqueRef.current(
												newData.templateId,
												newData.sensorType,
												newData.requestDate
											);
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						templateId: {
							rules: {
								required: ValidatorFunctions.required(),
							},
						},
						newHoursDuration: {
							rules: {
								required: ValidatorFunctions.required(),
								minNumber: ValidatorFunctions.minNumber(0),
								maxNumber: ValidatorFunctions.maxNumber(9999999),
							},
						},
						newMinutesDuration: {
							rules: {
								required: ValidatorFunctions.required(),
								minNumber: ValidatorFunctions.minNumber(0),
								maxNumber: ValidatorFunctions.maxNumber(59),
							},
						},
					},
				},
				true
			);
			setFormValidator(validator);
		}
		if (!props.isEditMode) {
			const initialData: DurationCorrectionEditRow = {
				id: null,
				requestDate: new Date(),
				templateId: null,
				sensorType: null,
				oldDuration: null,
				newDuration: null,
				newHoursDuration: null,
				newMinutesDuration: null,
				personName: null,
			};
			setCorrectionData(initialData);
		}
	}, [visible]);

	useEffect(() => {
		if (formValidator) {
			setValidationResult(null);
			formValidator.validate(correctionData).then((result) => {
				setValidationResult(result.validationResult);
				setValidForm(result.formResult);
			});
		}
	}, [formValidator]);

	useEffect(() => {
		if (!formValidator) {
			return;
		}
		formValidator.validate(correctionData).then((result) => {
			setValidationResult(result.validationResult);
			setValidForm(result.formResult);
		});
	}, [correctionData]);

	const events = {
		onValueChanged: async (value: number | Date | string, key: keyof DurationCorrectionEditRow) => {
			const newState: DurationCorrectionEditRow = { ...correctionData };
			(newState[key] as number | Date | string) = value;

			if (key === 'newHoursDuration' || key === 'newMinutesDuration') {
				newState.newDuration = newState.newHoursDuration * 60 + newState.newMinutesDuration;
			}

			setCorrectionData(newState);
			if (
				newState.requestDate !== initialData?.requestDate ||
				newState.newDuration !== initialData?.newDuration
			) {
				setUnsavedData(true);
				props.changeDataCallback(correctionData, true);
			}
		},
	};

	const saveClicked = useCallback(async () => {
		formValidator.validate(correctionData).then((result) => {
			if (result.formResult) {
				props.saveCallback(correctionData);
				setUnsavedData(false);
				props.changeDataCallback(correctionData, false);
			}
			setValidForm(result.formResult);
		});
	}, [correctionData, props.isEditMode, props.saveCallback, props.changeDataCallback]);

	const okClick = () => {
		setUnsavedData(false);
		props.closeCallback();
		if (!props.isEditMode) {
			const initialData: DurationCorrectionEditRow = {
				id: null,
				requestDate: new Date(),
				templateId: null,
				sensorType: null,
				oldDuration: null,
				newDuration: null,
				newHoursDuration: null,
				newMinutesDuration: null,
				personName: null,
			};
			setCorrectionData(initialData);
		} else {
			const hoursAndMinutes = DateTimeUtil.fromMinutesToHoursAndMinutes(initialData.newDuration);
			setCorrectionData({
				...initialData,
				newHoursDuration: hoursAndMinutes.hours,
				newMinutesDuration: hoursAndMinutes.minutes,
			});
		}
		props.changeDataCallback(correctionData, false);
	};

	const notifyAboutUnsavedData = async () => {
		if (isDialogOpen) {
			return;
		}

		setIsDialogOpen(true);
		const response = await DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		});

		setIsDialogOpen(false);
		if (response) {
			okClick();
		}
	};

	const tryCloseWizard = () => {
		if (unsavedData === true) {
			notifyAboutUnsavedData();
			return;
		}

		props.closeCallback();
		if (props.isEditMode) {
			const hoursAndMinutes = DateTimeUtil.fromMinutesToHoursAndMinutes(initialData.newDuration);
			setCorrectionData({
				...initialData,
				newHoursDuration: hoursAndMinutes.hours,
				newMinutesDuration: hoursAndMinutes.minutes,
			});
			return;
		}

		const data: DurationCorrectionEditRow = {
			id: null,
			requestDate: new Date(),
			templateId: null,
			sensorType: null,
			oldDuration: null,
			newDuration: null,
			newHoursDuration: null,
			newMinutesDuration: null,
			personName: null,
		};
		setCorrectionData(data);
	};

	const removeClicked = useCallback(() => {
		props.removeCallback(initialData.id);
	}, [initialData]);

	return visible ? (
		<Dialog
			onClose={() => tryCloseWizard()}
			className="correction-dialog"
			open={visible}
			fullWidth
			disableBackdropClick
		>
			<DialogTitle>{TranslateText(props.dialogTitle)}</DialogTitle>
			<DialogContent className="dialog-content">
				<div className="content correctionForm">
					<div className="form-group">
						<MaterialDatePicker
							name="requestDate"
							showTime
							date={correctionData?.requestDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
								DateTimeUtil.dateTimeFormat(),
								true
							)}
							disableFuture
							label={TranslateText('fields.activationDate')}
							disabled={props.isEditMode}
							onDateChange={(date?: Date) => {
								events.onValueChanged(date, 'requestDate');
							}}
							fullWidth
						/>
						<ValidationMessage result={validationResult?.requestDate} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							label={TranslateText('widgets.template')}
							className="autocomplete-props material-autocomplete report-selection-component"
							valueId={correctionData?.templateId}
							dataSource={templatesData}
							onChange={(args) =>
								setCorrectionData(
									(oldCorrectionData: DurationCorrectionEditRow): DurationCorrectionEditRow => {
										return {
											...oldCorrectionData,
											templateId: args.value as string,
											sensorType: (args.value as string)
												? templatesData.find((x) => x.id === args.value)?.sensorType
												: null,
										};
									}
								)
							}
							fullWidth
							disabled={!!initialData}
						/>
						<ValidationMessage result={validationResult?.templateId} />
					</div>
					<div className="form-group">
						<div className="correction-duration-title" style={{ fontSize: '13px' }}>
							{TranslateText('widgets.grid.colNewDuration')}
						</div>
						<div className="correction-duration-container">
							<div className="new-hours-duration">
								<TextField
									id="new-hours-duration"
									type="number"
									className="resize-font"
									fullWidth
									label={TranslateText('common.hours')}
									InputProps={{
										inputProps: {
											min: 0,
											maxLength: 5,
											style: { fontSize: 12 },
											inputMode: 'numeric',
										},
									}}
									name="newHoursDuration"
									value={correctionData.newHoursDuration}
									size="small"
									onChange={(e) => {
										if (e.target.value) {
											events.onValueChanged(parseInt(e.target.value), 'newHoursDuration');
										} else {
											events.onValueChanged(e.target.value, 'newHoursDuration');
										}
									}}
								/>
								<ValidationMessage result={validationResult?.newHoursDuration} />
							</div>
							<div className="new-minutes-duration">
								<TextField
									id="new-minutes-duration"
									type="number"
									className="resize-font"
									fullWidth
									label={TranslateText('common.minutes')}
									InputProps={{
										inputProps: {
											min: 0,
											maxLength: 2,
											style: { fontSize: 12 },
											inputMode: 'numeric',
										},
									}}
									name="newMinutesDuration"
									value={correctionData.newMinutesDuration}
									size="small"
									onChange={(e) => {
										if (e.target.value) {
											events.onValueChanged(parseInt(e.target.value), 'newMinutesDuration');
										} else {
											events.onValueChanged(e.target.value, 'newMinutesDuration');
										}
									}}
								/>
								<ValidationMessage result={validationResult?.newMinutesDuration} />
							</div>
						</div>
					</div>
				</div>
				<div className={'buttons-container'}>
					<Button onClick={() => tryCloseWizard()}>{TranslateText('common.buttonCancel')}</Button>
					{props.isEditMode ? (
						<Button onClick={removeClicked}>{TranslateText('common.buttonRemove')}</Button>
					) : null}
					<DebouncedButton
						onClick={saveClicked}
						disabled={!validForm || !unsavedData}
						debounceTime={DEBOUNCE_TIME}
					>
						{TranslateText('common.buttonSave')}
					</DebouncedButton>
				</div>
			</DialogContent>
		</Dialog>
	) : null;
};

export default DurationCorrectionDialog;
