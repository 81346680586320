export const SET_LIVE_OPTION = 'SET_LIVE_OPTION';
export const SET_HISTORY_MAP_OPTION = 'SET_HISTORY_MAP_OPTION';
export const SET_LIVE_DETAILS_TABS_SELECTION = 'SET_LIVE_DETAILS_TABS_SELECTION';
export const SET_HISTORY_DETAILS_TABS_SELECTION = 'SET_HISTORY_DETAILS_TABS_SELECTION';
export const SET_HISTORY_GRID_OPTION = 'SET_HISTORY_GRID_OPTION';
export interface RightSideBar {
	showRightBar: boolean;
	liveOption: number;
	historyMapOption: number;
	historyGridOption: number;
	liveDetailsOption: number;
	historyDetailsOption: number;
}

const initialState: RightSideBar = {
	showRightBar: true,
	liveOption: 0,
	historyMapOption: 0,
	historyGridOption: 0,
	liveDetailsOption: 0,
	historyDetailsOption: 0,
};

export const rightSideBarCreators = {
	updateLiveAndHistoryOption: (liveOption: number, historyMapOption: number) => (dispatch: any) => {
		dispatch({
			type: SET_LIVE_OPTION,
			payload: liveOption,
		});
		dispatch({
			type: SET_HISTORY_MAP_OPTION,
			payload: historyMapOption,
		});
	},
};

export const rightSideBarReducer = (state: RightSideBar, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_LIVE_DETAILS_TABS_SELECTION:
			return {
				...state,
				liveDetailsOption: action.payload,
			};
		case SET_HISTORY_DETAILS_TABS_SELECTION:
			return {
				...state,
				historyDetailsOption: action.payload,
			};
		case SET_LIVE_OPTION:
			return {
				...state,
				liveOption: action.payload,
			};

		case SET_HISTORY_MAP_OPTION:
			return {
				...state,
				historyMapOption: action.payload,
			};
		case SET_HISTORY_GRID_OPTION:
			return {
				...state,
				historyGridOption: action.payload,
			};
		default:
			return state;
	}
};
