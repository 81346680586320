import { oneDayInMiliseconds } from 'components/Common/DateTimeUtilFunctions';
import { PageResult } from 'components/Common/Pagination';
import { NumberConstants } from 'components/Widgets/CustomerFeaturesView';
import * as GlobalSettings from 'GlobalSettings.json';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import ajaxUtil from 'utils/Ajax';
import { FormatDateWithTimezone, GetTimezoneOffset } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';
import xlsx from 'xlsx';

import { LogMessage } from '../LogMessage';
import {
	formatDetails,
	formatGPS,
	formatIgnition,
	formatIO,
	formatMileage,
	formatTrip,
	getFormattedSrvDevTimeDiff,
} from '../LogWidgetUtils';
import { AllLogsMessagesMutation, LogsMessagesMutation, TimeZoneItem } from './types';

const useFetchMessages = (entityId: string, entityType: EntityTypeEnum, latestLogsDayUrl: string) => {
	const [logMessages, setLogMessages] = useState<LogMessage[]>([]);
	const [timezoneList, setTimezoneList] = useState<TimeZoneItem[]>([]);
	const [totalLogMessages, setTotalLogMessages] = useState<number>(0);
	const [timezoneId, setTimezoneId] = useState<string>(null);
	const [timezoneEnabled, setTimezoneEnabled] = useState<boolean>(true);
	const [lastDayWithMessagesUTC, setLastDayWithMessagesUTC] = useState<Date>(null);
	const [logsDay, setLogsDay] = useState<Date>(null);
	const [skip, setSkip] = useState<number>(0);
	const [showPopup, setShowPopup] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<string>('');
	const [popupUnitId, setPopupUnitId] = useState<string>('');
	const [allRecordsLoaded, setAllRecordsLoaded] = useState<boolean>(false);
	const [popupMessageReceiveTime, setPopupMessageReceiveTime] = useState<string>('-');
	const [filterBy, setFilterBy] = useState<string>('');
	const today: Date = new Date();

	const { mutate: getLogsMessages, mutateAsync: getLogsMessagesAsync } = useMutation(
		(request: LogsMessagesMutation) =>
			ajaxUtil.post<PageResult<LogMessage>>(request.url, {
				entityId,
				timezoneId,
				skip: request.skip,
				take: request.take,
				filterBy: request.filterBy,
				logsDay: request.logsDay,
			}),
		{
			onSuccess: (result: PageResult<LogMessage>, variables: LogsMessagesMutation) => {
				if (!!variables.disableOnSuccessCallback) {
					return;
				}
				setTotalLogMessages(result.total);

				result.data.forEach((item: LogMessage) => {
					if (item.ioData) {
						item.ioJSON = JSON.parse(item.ioData);
					}
					item.timeDiff = getFormattedSrvDevTimeDiff(
						item.messsageReceivedDateTime,
						item.messsageObservationTime
					);
				});

				const newLogMessages = variables.getAll ? [...result.data] : [...logMessages, ...result.data];
				setLogMessages(newLogMessages);

				if (variables.take > 0) {
					setSkip((oldSkip: number) => {
						return oldSkip + NumberConstants.deviceLogsTake;
					});
				} else {
					setSkip(result.total + NumberConstants.deviceLogsTake);
				}

				if (variables.getAll || newLogMessages.length >= result.total) {
					setAllRecordsLoaded(true);
				}
			},
		}
	);

	const { mutateAsync: getAllLogMessagesAsync } = useMutation(
		(request: AllLogsMessagesMutation) =>
			ajaxUtil.post<LogMessage[]>(`${GlobalSettings.messagesApi}/getAllLogsMessages`, {
				entityId,
				entityType,
				timezoneId,
				...request,
			}),
		{
			onSuccess: (result: LogMessage[]) => {
				const exportMessages: any[] = [];
				result.forEach((logMessage: LogMessage) => {
					formatDetails(logMessage);
					formatGPS(logMessage);
					formatMileage(logMessage);
					formatIO(logMessage);
					formatIgnition(logMessage);
					formatTrip(logMessage);
					exportMessages.push({
						[TranslateText('widgets.grid.colDetails')]: logMessage.formattedDetails,
						[TranslateText('widgets.grid.colGPS')]: logMessage.formattedGPS,
						[TranslateText('widgets.grid.colMileage')]: logMessage.formattedMileage,
						[TranslateText('widgets.grid.colIO')]: logMessage.formattedIO,
						[TranslateText('widgets.grid.colIgnition')]: logMessage.formattedIgnition,
						[TranslateText('widgets.grid.colTrip')]: logMessage.formattedTrip,
						[TranslateText('widgets.grid.colMessage')]: logMessage.message,
					});
				});

				const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportMessages);
				const workbook: xlsx.WorkBook = xlsx.utils.book_new();

				xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
				xlsx.writeFile(workbook, 'Export.xlsx');
			},
		}
	);

	const skipInProgress = useRef<number[]>([]);

	const getLogsData = async (): Promise<void> => {
		if (!(logsDay && (skip < totalLogMessages || (totalLogMessages === 0 && totalLogMessages === skip)))) {
			return;
		}

		if (skipInProgress.current.some((x) => x === skip)) {
			return;
		} else {
			skipInProgress.current.push(skip);
		}

		try {
			//call the server only when all messages are not received
			const result: PageResult<LogMessage> = await getMessages();

			setTotalLogMessages(result.total);
			result.data.forEach((item: LogMessage) => {
				//set IO data
				if (item.ioData) {
					item.ioJSON = JSON.parse(item.ioData);
				}

				//set time diff
				item.timeDiff = getFormattedSrvDevTimeDiff(item.messsageReceivedDateTime, item.messsageObservationTime);
			});

			if ([...logMessages, ...result.data]?.length === totalLogMessages && totalLogMessages) {
				setAllRecordsLoaded(true);
			}

			setLogMessages([...logMessages, ...result.data]);
			setSkip(() => {
				//wait 200 milliseconds, default debounce debounce from bottom scroll listener
				setTimeout(() => {
					skipInProgress.current = skipInProgress.current.filter((x) => x !== skip);
				}, 200);
				return [...logMessages, ...result.data]?.length;
			});
		} catch (_) {
			skipInProgress.current = skipInProgress.current.filter((x) => x !== skip);
		}
	};

	const getMessages = async (disableOnSuccessCallback = false): Promise<PageResult<LogMessage>> => {
		const selectedTimezone = timezoneList.find((x) => x.id === timezoneId);
		const day =
			logsDay?.getTime() -
			logsDay?.getTimezoneOffset() * 60000 -
			GetTimezoneOffset(logsDay, selectedTimezone.name) * 60000;

		let url = `${GlobalSettings.messagesApi}/getLogsMessages`;
		switch (entityType) {
			case EntityTypeEnum.Device: {
				url += '/device';
				break;
			}
			case EntityTypeEnum.Object: {
				url += '/object';
				break;
			}
			default: {
				throw Error('Invalid entity type');
			}
		}

		return await getLogsMessagesAsync({
			disableOnSuccessCallback,
			logsDay: day,
			skip,
			take: NumberConstants.deviceLogsTake,
			url,
			filterBy,
		});
	};

	const handleTripDownload = (rowData: LogMessage, data: string): void => {
		setPopupContent(JSON.stringify(data, null, 4));
		setPopupUnitId(rowData.unitId);
		setShowPopup(true);

		if (rowData.messsageReceivedDateTime !== null) {
			setPopupMessageReceiveTime(FormatDateWithTimezone(rowData.messsageReceivedDateTime, rowData.timezone));
			return;
		}

		setPopupMessageReceiveTime('-');
	};

	useEffect(() => {
		resetSkipTotalMessages();

		//get default logsDay
		getLastDayWithUTCMessagesAndReload();
		//get timezones
		let url = GlobalSettings.listsApi;
		switch (entityType) {
			case EntityTypeEnum.Device: {
				url += '/TimezonesForDevice?deviceId=' + entityId;
				break;
			}
			case EntityTypeEnum.Object: {
				url += '/TimezonesForObject?objectId=' + entityId;
				break;
			}
			default: {
				throw Error('Invalid entity type');
			}
		}
		ajaxUtil.get<TimeZoneItem[]>(url).then((data) => {
			const selectedTimezone = data.find((x) => x.selected);
			setTimezoneList(data);
			setTimezoneEnabled(data?.length > 1);
			if (selectedTimezone) {
				setTimezoneId(selectedTimezone.id as string);
			}
		});
	}, [entityId]);

	useEffect(() => {
		//logsDay initial set
		if (!lastDayWithMessagesUTC || !timezoneId || logsDay) {
			return;
		}

		const selectedTimezone = timezoneList.find((x) => x.id === timezoneId);
		const dateWithTimezone = new Date(
			lastDayWithMessagesUTC.getTime() + GetTimezoneOffset(lastDayWithMessagesUTC, selectedTimezone.name) * 60000
		);
		setLogsDay(new Date(dateWithTimezone.getFullYear(), dateWithTimezone.getMonth(), dateWithTimezone.getDate()));
	}, [lastDayWithMessagesUTC, timezoneId]);

	useEffect(() => {
		if (!logsDay || !timezoneId) {
			return;
		}
		getLogsData();
	}, [logsDay, timezoneId, filterBy]);

	const navigateBack = () => {
		setLogsDay(new Date(logsDay.getTime() - oneDayInMiliseconds));
		resetSkipTotalMessages();
	};

	const navigateForward = () => {
		let moveTo = new Date(logsDay.getTime() + oneDayInMiliseconds);
		if (moveTo.getTime() > today.getTime()) {
			moveTo = today;
		}
		setLogsDay(moveTo);
		resetSkipTotalMessages();
	};

	const resetSkipTotalMessages = (): void => {
		setTotalLogMessages(0);
		setSkip(0);
		setLogMessages([]);
		setAllRecordsLoaded(false);
	};

	const getLastDayWithUTCMessagesAndReload = async (): Promise<void> => {
		resetSkipTotalMessages();
		setLastDayWithMessagesUTC(null);
		setLogsDay(null);

		const logsDay: any = await ajaxUtil.get(latestLogsDayUrl);
		setLastDayWithMessagesUTC(new Date(logsDay));
	};

	const resetLogsGrid = async (): Promise<void> => {
		const result: PageResult<LogMessage> = await getMessages(true);

		if (result.total === totalLogMessages) {
			document.getElementById('move-up-hook').scrollIntoView({ block: 'nearest', inline: 'nearest' });
			return;
		}

		getLastDayWithUTCMessagesAndReload();
	};

	const exportData = async () => {
		const selectedTimezone = timezoneList.find((x) => x.id === timezoneId);
		const day =
			logsDay.getTime() -
			logsDay.getTimezoneOffset() * 60000 -
			GetTimezoneOffset(logsDay, selectedTimezone.name) * 60000;

		await getAllLogMessagesAsync({
			logsDay: day,
		});
	};

	const getAllMessages = async (): Promise<void> => {
		const selectedTimezone: TimeZoneItem = timezoneList.find(
			(timezone: TimeZoneItem) => timezone.id === timezoneId
		);

		const day: number =
			logsDay.getTime() -
			logsDay.getTimezoneOffset() * 60000 -
			GetTimezoneOffset(logsDay, selectedTimezone.name) * 60000;

		let url = `${GlobalSettings.messagesApi}/getLogsMessages`;
		switch (entityType) {
			case EntityTypeEnum.Device: {
				url += '/device';
				break;
			}
			case EntityTypeEnum.Object: {
				url += '/object';
				break;
			}
			default: {
				throw Error('Invalid entity type');
			}
		}

		await getLogsMessagesAsync({
			logsDay: day,
			skip,
			take: 0,
			filterBy,
			url,
			getAll: true,
		});
	};

	return {
		allRecordsLoaded,
		exportData,
		getAllMessages,
		getLastDayWithUTCMessagesAndReload,
		getLogsData,
		handleTripDownload,
		lastDayWithMessagesUTC,
		logsDay,
		logMessages,
		navigateBack,
		navigateForward,
		popupUnitId,
		popupContent,
		popupMessageReceiveTime,
		resetLogsGrid,
		resetSkipTotalMessages,
		showPopup,
		skip,
		today,
		setAllRecordsLoaded,
		setLogsDay,
		setFilterBy,
		setShowPopup,
		setTimezoneId,
		timezoneEnabled,
		timezoneId,
		timezoneList,
		totalLogMessages,
	};
};

export { useFetchMessages };
