import '../connectionWidget.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import BaseView from 'components/BaseView';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { DialogUtil } from 'components/Common/NotificationDialog/NotificationDialog';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import ObjectView from 'components/Views/ObjectView';
import PersonView from 'components/Views/PersonView';
import RenderForEnum from 'components/Views/RenderForEnum';
import UsedFromEnum from 'components/Views/UsedFromEnum';
import WidgetStateEnum from 'components/Views/WidgetStateEnum';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import DevicePropertiesEnum from 'models/DevicePropertiesEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import Filter from 'models/Filter';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import ObjectPersonConnection from 'models/ObjectPersonConnection';
import { Person } from 'models/Person';
import TrackableObject from 'models/TrackableObject';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Button, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../Constants';
import { DebouncedButton } from '../../Common/DebouncedButton';
import { CustomerObjectSettings } from '../DeviceObjectWidget/AddConnection';

const mapStateToProps = (state: ApplicationState) => {
	return {
		customerId: state.loadedEntityContext.entityContextData.customerId,
		toleranceTimeOutDistance: state.currentSession.customer.entityDefaultsSettings.toleranceTimeOutDistance,
		timeOutEndOfTrip: state.currentSession.customer.entityDefaultsSettings.timeOutEndOfTrip,
		externalCodeSameCode: state.currentSession.customer.entityDefaultsSettings.externalCodeSameCode,
		autoGeneratePersonCode: state.currentSession.customer.entityDefaultsSettings.autoGeneratePersonCode,
		co2CalculationType: state.currentSession.customer.entityDefaultsSettings.co2CalculationType,
		defaultCustomerId: state.currentSession.customerId,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterTextObject: state.globalCustomer.filterText,
		accessToken: state.oidc.user.access_token,
		displayText: state.loadedEntityContext.entityContextData.displayText,
		user: state.oidc.user,
	};
};

function mapDispatch(dispatch: Dispatch) {
	return {
		unsavedDataActions: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

export type CustomerPersonSettings = {
	autoGeneratePersonCode: boolean;
	externalCodeSameCode: boolean;
};

const connector = connect(mapStateToProps, mapDispatch, null, { forwardRef: true });
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	visible: boolean;
	renderFor: EntityTypeEnum;
	showDialog: boolean;
	currentEntityMode: boolean;
	dialogCloseCallback: () => void;
	entityId: string;
	savedCallback: (sender: any) => void;
	enableAddNewConnection: boolean;
};

type State = {
	invalidForm: boolean;
	invalidNewEntityForm: boolean;
	entityCustomerId: string;
	customerObjectSettings: CustomerObjectSettings;
	customerPersonSettings: CustomerPersonSettings;
	personObjectConnectionStartDate: Date;
	selectedTabIndex: number;
	existingEntityTabInit: boolean;
	newEntityTabInit: boolean;
	devicesData: AutocompleteItem[];
	objectsData: AutocompleteItem[];
	productsData: AutocompleteItem[];
	personObjectConnection: ObjectPersonConnection;
	newPersonObjectConnection: ObjectPersonConnection;
	validationResult: ValidationResult;
	isLoading: boolean;
	isDialogOpen: boolean;
};

class AddConnection extends BaseView<Props, State> {
	conflictsCanBeAutomaticallyFixed = false;
	conflictsCanBeAutomaticallyFixedNewPerson = false;
	conflictingConnections: ObjectPersonConnection[];
	conflictinConnectionNewPerson: ObjectPersonConnection[];
	proposedConnectionCanBeSavedNewEntity: boolean;
	conflictingConnectionNotifierRef: ConnectionConflictsNotifier;
	conflictReceivedOnExistingEntity = false;
	newPerson: Person;
	unsavedNewData = false;
	unsavedData = false;
	objectViewObj: any;
	personViewRef: any;
	newObject: TrackableObject = new TrackableObject();
	newEntityValidator: Validator;
	existingEntityValidator: Validator;
	customerId: string;
	dataFilter: Filter;

	constructor(props: Props) {
		super(props);

		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Object
					? FilterFieldTypeEnum.objectId
					: FilterFieldTypeEnum.personId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: props.entityId,
			display: DevicePropertiesEnum.Code,
		};

		const newPersonObjectConnection = new ObjectPersonConnection();
		newPersonObjectConnection.connectionStartDate = new Date();
		this.state = {
			invalidForm: true,
			invalidNewEntityForm: true,
			entityCustomerId: this.props.customerId,
			personObjectConnectionStartDate: null as Date,
			customerObjectSettings: {
				toleranceTimeOutDistance: this.props.toleranceTimeOutDistance,
				timeOutEndOfTrip: this.props.timeOutEndOfTrip,
				externalCodeSameCode: this.props.externalCodeSameCode,
				co2CalculationType: this.props.co2CalculationType,
			},
			customerPersonSettings: {
				autoGeneratePersonCode: this.props.autoGeneratePersonCode,
				externalCodeSameCode: this.props.externalCodeSameCode,
			},
			selectedTabIndex: 0,
			existingEntityTabInit: false,
			newEntityTabInit: false,
			devicesData: [],
			productsData: [],
			objectsData: [],
			personObjectConnection: newPersonObjectConnection,
			newPersonObjectConnection: null,
			validationResult: null,
			isLoading: false,
			isDialogOpen: false,
		};

		this.setFormValidatorForExistingEntity();
		this.setFormValidatorForNewEntity();
	}

	componentDidMount() {
		this.entityChanged();
	}

	componentDidUpdate(prevProps: { showDialog: boolean; currentEntityMode: boolean; entityId: string }) {
		if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog === true) {
			this.showDialog();
		}

		if (prevProps.entityId !== this.props.entityId) {
			this.entityChanged();
		}
	}

	async entityChanged() {
		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Object
					? FilterFieldTypeEnum.objectId
					: FilterFieldTypeEnum.personId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: this.props.entityId,
			excludeConnectedEntities:
				this.props.renderFor === EntityTypeEnum.Object || this.props.renderFor === EntityTypeEnum.Person,
			display: DevicePropertiesEnum.Code,
		};

		//recompute lists when entity has been changed
		if (ClaimUtil.validateHasClaim(this.props.user, ClaimType.Devices)) {
			this.prepareDevicesList();
		}
		this.prepareProductsList();
		this.getObjectsList();

		await this.setEntityCustomerId().then(async () => {
			if (this.state.entityCustomerId) {
				await ajaxUtil
					.get(
						`${GlobalSettings.customersMaintenanceApi}/CustomerObjectSettings/${this.state.entityCustomerId}`
					)
					.then((result: any) => {
						this.setState({
							customerObjectSettings: {
								toleranceTimeOutDistance: result.toleranceTimeOutDistance,
								timeOutEndOfTrip: result.timeOutEndOfTrip,
								externalCodeSameCode: result.externalCodeSameCode,
								co2CalculationType: result.co2CalculationType,
							},
						});
					});

				await ajaxUtil
					.get<CustomerPersonSettings>(
						`${GlobalSettings.customersMaintenanceApi}/CustomerPersonSettings/${this.state.entityCustomerId}`
					)
					.then((result) => {
						this.setState({
							customerPersonSettings: {
								autoGeneratePersonCode: result.autoGeneratePersonCode,
								externalCodeSameCode: result.externalCodeSameCode,
							},
						});
					});
			}
		});
	}

	async setEntityCustomerId() {
		const url = GlobalSettings.connectionsApi + '/EntityCustomerId?filter=' + JSON.stringify(this.dataFilter);

		await ajaxUtil.get(url).then((id: any) => {
			this.setState({ entityCustomerId: String(id) });
		});
	}

	getObjectsList() {
		const url = GlobalSettings.listsApi + '/Objects?filter=' + JSON.stringify(this.dataFilter);
		ajaxUtil.get<AutocompleteItem[]>(url).then((objects) => {
			this.setState({ objectsData: objects });
		});
	}

	setEntity(valid: boolean, objectPersonConnection: ObjectPersonConnection, afterStatechange?: () => void) {
		this.setUnsavedData(true, this.unsavedNewData);

		if (afterStatechange) {
			this.setState(
				{
					invalidForm: !valid,
					personObjectConnection: objectPersonConnection,
				},
				() => {
					afterStatechange();
				}
			);
		} else {
			this.setState({
				invalidForm: !valid,
				personObjectConnection: objectPersonConnection,
			});
		}
	}

	getNewEntityDefault() {
		const newPersonObjectConnection = new ObjectPersonConnection();
		newPersonObjectConnection.connectionStartDate = new Date();

		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				newPersonObjectConnection.objectId = this.props.entityId;
				break;
			}
			case EntityTypeEnum.Person: {
				newPersonObjectConnection.personId = this.props.entityId;
				break;
			}
		}

		newPersonObjectConnection.resolveConflictsAutomatically = false;

		return newPersonObjectConnection;
	}

	getExistingEntityDefault() {
		const newPersonObjectConnection = new ObjectPersonConnection();
		newPersonObjectConnection.connectionStartDate = new Date();
		if (this.props.renderFor === EntityTypeEnum.Object) {
			newPersonObjectConnection.objectId = this.props.entityId;
		} else {
			newPersonObjectConnection.personId = this.props.entityId;
		}

		return newPersonObjectConnection;
	}

	showDialog() {
		this.newPerson = null;
		this.newObject = null;
		this.setUnsavedData(false, false);

		this.proposedConnectionCanBeSavedNewEntity = true;
		this.proposedConnectionCanBeSaved = true;

		const tabIndex = this.props.currentEntityMode ? 0 : 1;
		this.setState(
			{
				isLoading: false,
				selectedTabIndex: tabIndex,
				personObjectConnection: this.getExistingEntityDefault(),
				newPersonObjectConnection: this.getNewEntityDefault(),
				validationResult: null,
			},
			() => {
				this.existingEntityValidator.clearValidation();
				this.newEntityValidator.clearValidation();

				this.handleTabItemSelected(tabIndex);
			}
		);
		this.prepareProductsList();
		this.getObjectsList();
	}

	setFormValidatorForExistingEntity() {
		this.props.renderFor === EntityTypeEnum.Object
			? (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							personId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							objectId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.driver'),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ));
	}

	setFormValidatorForNewEntity() {
		this.props.renderFor === EntityTypeEnum.Object
			? (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							objectId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							personId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ));
	}

	async handleValueChange(value: string, statePropName: string) {
		const tempobjectPersonConnection = new ObjectPersonConnection(this.state.personObjectConnection);
		if (tempobjectPersonConnection !== null) {
			tempobjectPersonConnection.resolveConflictsAutomatically = null; //reset resolved conflict when entity changed
			if (statePropName === 'productId') {
				if (this.props.renderFor === EntityTypeEnum.Object) {
					tempobjectPersonConnection.personId = value;
				} else {
					tempobjectPersonConnection.objectId = value;
				}
			}

			this.setEntity(false, tempobjectPersonConnection, () => {
				this.checkEntitiesConnectionConflicts(true).then(() => {
					this.setValidationStateForExisting();
				});
			});
		}
	}

	async checkEntitiesConnectionConflicts(
		existingEntity: boolean,
		connectionStartDate: Date = null
	): Promise<boolean> {
		let candidateConnection: any;

		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				if (existingEntity) {
					if (
						this.state.personObjectConnection.personId === null ||
						this.state.personObjectConnection.personId === undefined ||
						this.state.personObjectConnection.personId === ''
					) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.state.personObjectConnection.personId,
						entity2Id: this.props.entityId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.personObjectConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonObject,
					};
				} else {
					if (!this.state.newPersonObjectConnection.objectId) {
						return true;
					}
					candidateConnection = {
						entity1Id: null,
						entity2Id: this.state.newPersonObjectConnection.objectId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newPersonObjectConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonObject,
					};
				}

				break;
			}
			case EntityTypeEnum.Person: {
				if (existingEntity) {
					if (
						this.state.personObjectConnection.objectId === null ||
						this.state.personObjectConnection.objectId === undefined ||
						this.state.personObjectConnection.objectId === ''
					) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.props.entityId,
						entity2Id: this.state.personObjectConnection.objectId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.personObjectConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonObject,
					};
				} else {
					if (!this.state.newPersonObjectConnection.personId) {
						return true;
					}

					candidateConnection = {
						entity1Id: this.state.newPersonObjectConnection.personId,
						entity2Id: null,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newPersonObjectConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonObject,
					};
				}

				break;
			}
		}

		const url = GlobalSettings.connectionsApi + '/ValidateEntitiesCandidateConnection';
		this.setState({ isLoading: true });
		return await ajaxUtil.post(url, candidateConnection).then((result: any) => {
			this.setState({ isLoading: false });
			if (existingEntity) {
				if (result.conflictPresent && !this.state.personObjectConnection.resolveConflictsAutomatically) {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						result.conflictingConnections,
						result.fixableConflict
					);
					this.conflictReceivedOnExistingEntity = true;
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSaved = false;
					return false;
				} else {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSaved = true;
					return true;
				}
			} else {
				if (result.conflictPresent && !this.state.newPersonObjectConnection.resolveConflictsAutomatically) {
					this.conflictinConnectionNewPerson = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewPerson = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						this.conflictinConnectionNewPerson,
						this.conflictsCanBeAutomaticallyFixedNewPerson
					);
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSavedNewEntity = false;
					return false;
				} else {
					this.conflictinConnectionNewPerson = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewPerson = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSavedNewEntity = true;
					return true;
				}
			}
		});
	}

	prepareProductsList() {
		let url = '';
		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				url = GlobalSettings.listsApi + '/Drivers?filter=' + JSON.stringify(this.dataFilter);
				break;
			}
			case EntityTypeEnum.Person: {
				url = GlobalSettings.listsApi + '/Objects?filter=' + JSON.stringify(this.dataFilter);
				break;
			}
		}

		ajaxUtil.get<AutocompleteItem[]>(url).then((data) => {
			this.setState({
				productsData: data,
			});
		});
	}

	getHeaderText(): string {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Object:
				return TranslateText('connections.personObjectHeader');
			case EntityTypeEnum.Person:
				return TranslateText('connections.objectDriverHeader');
		}
	}

	connect(existingEntity: boolean) {
		let newConnection: any;

		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				if (existingEntity) {
					newConnection = {
						objectId: this.props.entityId,
						personId: this.state.personObjectConnection.personId,
						connectionStartDate: this.state.personObjectConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						objectId: this.props.entityId,
						personId: this.state.newPersonObjectConnection.personId,
						connectionStartDate: this.state.newPersonObjectConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newPersonObjectConnection
							.resolveConflictsAutomatically,
					};
				}
				break;
			}
			case EntityTypeEnum.Person: {
				if (existingEntity) {
					newConnection = {
						objectId: this.state.personObjectConnection.objectId,
						personId: this.props.entityId,
						connectionStartDate: this.state.personObjectConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						objectId: this.state.newPersonObjectConnection.objectId,
						personId: this.props.entityId,
						connectionStartDate: this.state.newPersonObjectConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newPersonObjectConnection
							.resolveConflictsAutomatically,
					};
				}
				break;
			}
		}

		const url = GlobalSettings.connectionsApi + '/CreateObjectPersonConnection';
		ajaxUtil
			.post(url, newConnection)
			.then(() => {
				this.cleanupData();
				//if connection is charged update available entities to include/exclude newly added entity(object or person)
				this.prepareProductsList();

				this.props.savedCallback(null);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
			});
	}

	tryCloseDialog() {
		if (this.unsavedData || this.unsavedNewData) {
			this.notifyAboutUnsavedData();
			return;
		}

		this.cleanupData();
	}

	onBeforeClose() {
		this.props.dialogCloseCallback();
	}

	notifyAboutUnsavedData() {
		if (this.state.isDialogOpen) return;

		this.setState({
			isDialogOpen: true,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then((response: boolean) => {
			this.setState({
				isDialogOpen: false,
			});
			if (response) {
				//yes was clicked
				this.cleanupData();
			}
		});
	}

	private cleanupData() {
		this.clearUnsavedData();

		this.setState(
			{
				selectedTabIndex: 0,
				existingEntityTabInit: false,
				newEntityTabInit: false,
				personObjectConnection: this.getExistingEntityDefault(),
				newPersonObjectConnection: this.getNewEntityDefault(),
			},
			() => {
				if (this.personViewRef) {
					this.personViewRef.clearView();
				}

				if (this.objectViewObj) {
					this.objectViewObj.clearView();
				}

				this.props.dialogCloseCallback();
			}
		);
	}

	async validateAndConnect() {
		this.setState({ isLoading: true }, () => {
			this.checkEntitiesConnectionConflicts(true)
				.then((noConflicts) => {
					if (noConflicts) {
						this.connect(true);
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	clearUnsavedData() {
		this.unsavedData = false;
		this.unsavedNewData = false;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	setUnsavedData(unsavedData: boolean, unsavedNewData: boolean) {
		this.unsavedData = unsavedData;
		this.unsavedNewData = unsavedNewData;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	private handleConfictingConnections(sender: any, proposedConnectionCanBeSaved: boolean) {
		if (this.conflictReceivedOnExistingEntity) {
			this.proposedConnectionCanBeSaved = proposedConnectionCanBeSaved;
			const tempPersonObjectConnection = new ObjectPersonConnection(this.state.personObjectConnection);
			tempPersonObjectConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;
			this.conflictReceivedOnExistingEntity = false;

			this.setState({ personObjectConnection: tempPersonObjectConnection }, () => {
				this.setValidationStateForExisting();
			});
		} else {
			this.proposedConnectionCanBeSavedNewEntity = proposedConnectionCanBeSaved;

			const newObjectPersonConnection = new ObjectPersonConnection(this.state.newPersonObjectConnection);
			newObjectPersonConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;

			this.setState({ newPersonObjectConnection: newObjectPersonConnection }, () => {
				this.setValidationStateForNew();
			});
		}
	}

	contentCurrentEntity = () => {
		return (
			<>
				<div className="e-panel-content connection-dialog-form">
					<form id="poConnectionExistingEntityForm" noValidate={true}>
						<MaterialAutocomplete
							valueId={
								this.props.renderFor === EntityTypeEnum.Object
									? this.state.personObjectConnection?.personId
									: this.state.personObjectConnection?.objectId
							}
							dataSource={this.state.productsData}
							name="poConnectionProductListId"
							disabled={this.state.isLoading}
							onChange={({ value }) => this.handleValueChange(value as string, 'productId')}
							className="material-autocomplete"
							label={
								this.props.renderFor === EntityTypeEnum.Object
									? TranslateText('connections.selectDriverPlaceholder')
									: TranslateText('connections.selectObjectPlaceholder')
							}
						/>
					</form>
				</div>
				<div className="connection-handle-buttons">
					<div>
						<ValidationMessage
							result={
								this.props.renderFor === EntityTypeEnum.Object
									? this.state.validationResult?.personId
									: this.state.validationResult?.objectId
							}
						/>
					</div>
					<div className="left-side-buttons">
						<Button
							className="auto-current-cancel-button"
							onClick={() => this.tryCloseDialog()}
							disabled={this.state.isLoading}
						>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-connect-button"
							onClick={async () => await this.validateAndConnect()}
							disabled={this.state.invalidForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.connect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	prepareDevicesList() {
		if (
			this.props.renderFor === EntityTypeEnum.Person &&
			ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Devices,
				values: [ClaimValue.edit],
			})
		) {
			this.dataFilter.display = DevicePropertiesEnum.Code;
			ajaxUtil
				.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, this.dataFilter)
				.then((data) => {
					this.setState({
						devicesData: data,
					});
				});
		}
	}

	setPerson(personData: Person, unsavedData: boolean) {
		this.setUnsavedData(this.unsavedData, unsavedData);
		this.newPerson = personData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setObject(valid: boolean, objectData: TrackableObject, unsavedData: boolean) {
		this.setUnsavedData(this.unsavedData, unsavedData);
		this.newObject = objectData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setValidationStateForNew() {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Object: {
				this.personViewRef?.validateBeforeSave().then((embeddedFormValidationResult: any) => {
					this.newEntityValidator?.validate(this.state.newPersonObjectConnection).then((result) => {
						this.setState({
							validationResult: result.validationResult,
							invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
						});
					});
				});
				break;
			}
			case EntityTypeEnum.Person: {
				this.objectViewObj?.validateBeforeSave().then((embeddedFormValidationResult: any) => {
					this.newEntityValidator?.validate(this.state.newPersonObjectConnection).then((result) => {
						this.setState({
							validationResult: result.validationResult,
							invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
						});
					});
				});
				break;
			}
		}
	}

	setValidationStateForExisting() {
		this.existingEntityValidator.validate(this.state.personObjectConnection).then((result) => {
			this.setState({
				validationResult: result.validationResult,
				invalidForm: !result.formResult,
			});
		});
	}

	saveNewPerson() {
		const url = GlobalSettings.driversMaintenanceApi;
		const newPerson = {
			code: this.newPerson.code,
			externalCode: this.newPerson.externalCode,
			customerId: this.state.entityCustomerId,
			firstName: this.newPerson.firstName,
			lastName: this.newPerson.lastName,
			emailAddress: this.newPerson.emailAddress,
			phoneNumber: this.newPerson.phoneNumber,
			languageId: this.newPerson.languageId,
			birthDate: new Date(this.newPerson.birthDate),
			birthName: this.newPerson.birthName,
			initials: this.newPerson.initials,
			socialSecurityNumber: this.newPerson.socialSecurityNumber,
			homeAddress: this.newPerson.homeAddress,
			username: this.newPerson.username,
			password: this.newPerson.password,
			activatedScreensaver: this.newPerson.activatedScreensaver,
			active: this.newPerson.active,
			driver: this.newPerson.driver,
			languagePreference: this.newPerson.languagePreference,
			loginCode: this.newPerson.loginCode,
			keypadCode: this.newPerson.keypadCode,
			tachoId: this.newPerson.tachoId,
			objectId: this.newPerson.objectId,
			objectPersonConnectionStartDate: this.state.newPersonObjectConnection?.connectionStartDate,
			addInGroups: this.newPerson.addInGroups,
		};

		return ajaxUtil
			.post(url, {
				AddModel: newPerson,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText: this.props.filterTextObject.objects,
			})
			.then((data: any) => {
				if (data) {
					return data.id;
				}

				this.clearUnsavedData();

				return '';
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return '';
			});
	}

	saveNewObject() {
		const url = GlobalSettings.objectsMaintenanceApi;
		const newObject = {
			...this.newObject,
			customerId: this.state.entityCustomerId,
			timezoneId: this.newObject.timezoneId ? this.newObject.timezoneId : null,
			personId: this.props.entityId,
			personObjectConnectionStartDate: this.state.newPersonObjectConnection?.connectionStartDate,
		};

		return ajaxUtil
			.post(url, {
				AddModel: newObject,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText: this.props.filterTextObject.persons,
			})
			.then((data: any) => {
				if (data) {
					return data.id;
				}

				return '';
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return '';
			});
	}

	async handleAddEntityAndConnect() {
		this.setState({ isLoading: true }, () => {
			// validate future connection
			this.checkEntitiesConnectionConflicts(false)
				.then((noConflicts) => {
					if (noConflicts) {
						switch (this.props.renderFor) {
							case EntityTypeEnum.Object: {
								this.saveNewPerson().then((idOfNewPerson) => {
									if (idOfNewPerson !== '') {
										this.newPerson.id = idOfNewPerson;
										const newPersonObjectConnection = new ObjectPersonConnection(
											this.state.newPersonObjectConnection
										);
										newPersonObjectConnection.personId = idOfNewPerson;
										this.setState({ newPersonObjectConnection: newPersonObjectConnection }, () => {
											//close dialog, connection is created together with new person
											this.cleanupData();
											this.props.savedCallback(null);
										});
									}
								});
								break;
							}
							case EntityTypeEnum.Person: {
								this.saveNewObject().then((idOfNewObject) => {
									if (idOfNewObject !== '') {
										this.newObject.id = idOfNewObject;
										const newPersonObjectConnection = new ObjectPersonConnection(
											this.state.newPersonObjectConnection
										);
										newPersonObjectConnection.objectId = idOfNewObject;
										this.setState({ newPersonObjectConnection: newPersonObjectConnection }, () => {
											//close dialog, connection is created together with new object
											this.cleanupData();
											this.props.savedCallback(null);
										});
									}
								});
								break;
							}
						}
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	contentNewEntity = (data: { tabData: any; connectionStartDate: Date }) => {
		return (
			<>
				<div className="e-panel-content connection-dialog-form">
					{this.props.renderFor === EntityTypeEnum.Object ? (
						<PersonView
							widgetState={WidgetStateEnum.Edit}
							ref={(scope) => (this.personViewRef = scope)}
							defaultCustomerId={data.tabData.customerId}
							defaultCustomerSettings={data.tabData.customerPersonSettings}
							setPerson={this.setPerson.bind(this)}
							objectsData={this.state.objectsData}
							renderFor={RenderForEnum.ConnectDialog}
							usedFrom={UsedFromEnum.Object}
							readOnly={false}
						/>
					) : (
						<ObjectView
							defaultCustomerId={data.tabData.customerId}
							defaultCustomerSettings={data.tabData.customerObjectSettings}
							widgetState={WidgetStateEnum.Edit}
							renderFor={RenderForEnum.ConnectDialog}
							usedFrom={UsedFromEnum.Person}
							readOnly={false}
							devicesData={this.state.devicesData}
							ref={(input) => {
								this.objectViewObj = input;
							}}
							setObject={this.setObject.bind(this)}
							connectionStartDateFromConnectDialog={data.connectionStartDate}
							connectCurrentData={this.newObject}
						/>
					)}
				</div>
				<div className="connection-handle-buttons">
					<div>
						<ValidationMessage
							result={
								this.props.renderFor === EntityTypeEnum.Object
									? this.state.validationResult?.objectId
									: this.state.validationResult?.personId
							}
						/>
					</div>
					<div className="left-side-buttons">
						<Button
							className="auto-new-cancel-button"
							onClick={() => this.tryCloseDialog()}
							disabled={this.state.isLoading}
						>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-add-and-connect-button"
							onClick={async () => await this.handleAddEntityAndConnect()}
							disabled={this.state.invalidNewEntityForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.addAndConnect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	render() {
		const tabNewData = {
			customerId: this.state.entityCustomerId,
			customerObjectSettings: this.state.customerObjectSettings,
			customerPersonSettings: this.state.customerPersonSettings,
		};

		return (
			<Dialog
				onClose={() => this.tryCloseDialog()}
				className="connection-dialog add-connection-dialog"
				open={this.props.showDialog}
				fullWidth
				disableBackdropClick
			>
				<DialogTitle>{this.getHeaderText()}</DialogTitle>
				<DialogContent>
					<ConnectionConflictsNotifier
						ref={(scope) => {
							this.conflictingConnectionNotifierRef = scope;
						}}
						connectionType={ConnectionTypeEnum.PersonObject}
						visible={false}
						userAnswearCallback={this.handleConfictingConnections.bind(this)}
					/>

					<Tabs
						className="connection-dialog-tab"
						value={this.state.selectedTabIndex}
						onChange={(e, newValue: number) => {
							this.setState({ selectedTabIndex: newValue }, () => {
								this.handleTabItemSelected(newValue);
							});
						}}
					>
						<Tab value={0} className="auto-existing-entity" label={this.getTabHeaderExistingEntity()} />
						<Tab
							value={1}
							disabled={this.state.isLoading}
							className="auto-new-entity"
							label={this.getTabHeaderNewEntity()}
							hidden={!this.props.enableAddNewConnection}
						/>
					</Tabs>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 0}>
						{this.contentCurrentEntity()}
					</div>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 1}>
						{this.contentNewEntity({
							tabData: tabNewData,
							connectionStartDate: this.state.personObjectConnectionStartDate,
						})}
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	getTabHeaderExistingEntity(): string {
		if (this.props.renderFor === EntityTypeEnum.Object) {
			return TranslateText('connections.currentDriver');
		} else {
			return TranslateText('connections.currentObject');
		}
	}

	getTabHeaderNewEntity(): string {
		if (this.props.renderFor === EntityTypeEnum.Object) {
			return TranslateText('connections.toNewDriver');
		} else {
			return TranslateText('connections.toNewObject');
		}
	}

	handleTabItemSelected(selectedIndex: number): void {
		if (selectedIndex === 0) {
			if (!this.state.existingEntityTabInit) {
				this.setState({ existingEntityTabInit: true });
			}

			//validate tab
			this.setValidationStateForExisting();
		} else if (selectedIndex === 1) {
			if (this.state.newEntityTabInit) {
				if (this.personViewRef) {
					this.personViewRef.focusDefaultInput();
				}
				if (this.objectViewObj) {
					this.objectViewObj.focusDefaultInput();
				}

				//validate tab
				this.setValidationStateForNew();
			} else {
				if (this.personViewRef) {
					this.personViewRef.prepareViewWithData(
						this.state.entityCustomerId,
						this.props.entityId,
						this.props.displayText
					);
				}
				if (this.objectViewObj) {
					this.objectViewObj.prepareViewWithData(this.state.entityCustomerId);
				}

				this.setState({ newEntityTabInit: true });

				//validate tab
				this.setValidationStateForNew();
			}
		}
	}
}

export default connector(AddConnection);
