import './MaterialDatePicker.scss';
import 'moment/min/locales';

import MomentUtils from '@date-io/moment';
import { InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { DatePicker, DateTimePicker, Day, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { HistoryTimeRange } from 'components/HistoryOverview/HistoryTimeRange';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import moment from 'moment';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { FieldValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

const MaterialDatePicker = (props: {
	id?: string;
	name?: string;
	date?: Date | string;
	className?: string;
	format: string;
	label?: string;
	errorMsgContainerId?: string;
	disabled?: boolean;
	disableCloseIcon?: boolean;
	onDateChange?: (date?: Date) => void;
	onPopupClosed?: () => void;
	maxDate?: Date;
	minDate?: Date;
	disableFuture?: boolean;
	showTime: boolean;
	fullWidth: boolean;
	maxWidth?: number;
	display?: HistoryTimeRange;
	width?: number;
	hiddenCloseButton?: boolean;
	isForNewAddWizard?: boolean;
	validationResult?: FieldValidationResult;
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	//set display date in input
	const [displayDate, setDisplayDate] = useState<string>('');
	const [displayDateChanged, setDisplayDateChanged] = useState<boolean>(false);

	useEffect(() => {
		props.display
			? setDisplayDate(props.date ? determineText() : '')
			: setDisplayDate(props.date ? moment(props.date).format(props.format) : '');
	}, [props.date, props.format]);

	const determineText = () => {
		let text = '';
		const dateMoment = moment(props.date);
		if (props.display === HistoryTimeRange.WorkWeek) {
			text = TranslateText('fields.workWeekNo') + dateMoment.isoWeek();
		} else if (props.display === HistoryTimeRange.Week) {
			text = `${TranslateText('fields.weekNo')}${dateMoment.isoWeek()} (${dateMoment.toDate().getFullYear()})`;
		} else if (props.display === HistoryTimeRange.Month) {
			text = `${dateMoment.format('MMMM')} (${dateMoment.format('YYYY')})`;
		}
		return text;
	};

	const renderWrappedWeekDay = (
		date: moment.Moment,
		selectedDate: moment.Moment,
		dayInCurrentMonth: boolean,
		dayComponent: JSX.Element
	) => {
		return (
			<div
				onClick={() => {
					if (dayInCurrentMonth && date && date.isValid() && !dayComponent?.props.disabled) {
						setAnchorEl(null);
					}
				}}
			>
				{date.day() === moment.localeData().firstDayOfWeek() && <div className={'week'}>{date.isoWeek()}</div>}
				<Day
					disabled={dayComponent?.props.disabled}
					current={date.isSame(moment(), 'day')}
					hidden={!dayInCurrentMonth}
					selected={date.isSame(selectedDate, 'day')}
				>
					{date.date()}
				</Day>
			</div>
		);
	};

	const renderWrappedWeekDayForWeek = (
		date: moment.Moment,
		selectedDate: moment.Moment,
		dayInCurrentMonth: boolean,
		dayComponent: JSX.Element
	) => {
		let containerClass = 'selectable-week-day';
		if (date.week() === selectedDate.week()) {
			containerClass += ' selected-week-day';
		}
		if (!dayInCurrentMonth && !dayComponent?.props.disabled) {
			containerClass += ' different-month-day';
		}

		return (
			<div
				className={containerClass}
				onClick={() => {
					if (date && date.isValid() && !dayComponent?.props.disabled) {
						setAnchorEl(null);
						if (!dayInCurrentMonth) {
							props.onDateChange(date.toDate());
						}
					}
				}}
			>
				{date.day() === moment.localeData().firstDayOfWeek() && (
					<div className={dayComponent?.props.disabled ? 'selectable-week disabled' : 'selectable-week'}>
						{date.isoWeek()}
					</div>
				)}
				<Day disabled={dayComponent?.props.disabled} current={date.isSame(moment(), 'day')} selected={false}>
					{date.date()}
				</Day>
			</div>
		);
	};

	const isDateInInterval = (newDate: Date) => {
		return (
			(!props.minDate || newDate >= new Date(props.minDate.setHours(0, 0, 0, 0))) &&
			(!props.maxDate || newDate <= new Date(props.maxDate.setHours(23, 59, 59, 999)))
		);
	};

	return (
		<>
			{props.isForNewAddWizard && (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<InputLabel
						style={{
							color: 'black',
							fontSize: displayDate ? 10 : 12,
							marginBottom: 4,
							transition: 'font-size 0.25s ease-in-out',
						}}
					>
						{props.label}
					</InputLabel>
					{!!props.validationResult && (
						<ValidationMessage result={props.validationResult} isForNewAddWizard={true} />
					)}
				</div>
			)}
			<TextField
				disabled={props.disabled}
				className={`date-picker-input ${props.className}`}
				style={{ maxWidth: props.maxWidth ? props.maxWidth : null, width: props.width ? props.width : null }}
				id={props.id}
				name={props.name}
				fullWidth={props.fullWidth}
				value={displayDate}
				error={
					props.isForNewAddWizard &&
					!!props.validationResult &&
					!!Object.keys(props.validationResult).find((r) => !props.validationResult[r].valid)
				}
				label={props.isForNewAddWizard ? null : props.label}
				onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					setDisplayDate(event.target.value);
					setDisplayDateChanged(true);
				}}
				onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
					if (props.display === HistoryTimeRange.Month || props.display === HistoryTimeRange.Week) {
						setAnchorEl(event?.currentTarget);
					}
				}}
				onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					if (displayDate && displayDateChanged) {
						const newDate = moment(displayDate, props.format, true);
						if (newDate && newDate.isValid() && isDateInInterval(newDate.toDate())) {
							props.onDateChange(newDate.toDate());
						} else {
							props.display
								? setDisplayDate(props.date ? determineText() : '')
								: setDisplayDate(props.date ? moment(props.date).format(props.format) : '');
						}
					} else {
						props.display
							? setDisplayDate(props.date ? determineText() : '')
							: setDisplayDate(props.date ? moment(props.date).format(props.format) : '');
					}
				}}
				InputProps={{
					readOnly: props.display === HistoryTimeRange.Month || props.display === HistoryTimeRange.Week,
					endAdornment: (
						<InputAdornment position="end">
							<>
								{props.date && !props.disableCloseIcon ? (
									<IconButton
										hidden={props.disabled || props.hiddenCloseButton}
										disabled={props.disabled}
										onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
											props.onDateChange(null);
										}}
										size="small"
									>
										<Icon>close</Icon>
									</IconButton>
								) : null}
								<IconButton
									hidden={props.disabled}
									disabled={props.disabled}
									onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
										setAnchorEl(event.currentTarget);
									}}
									size="small"
								>
									<Icon>today</Icon>
								</IconButton>
							</>
						</InputAdornment>
					),
				}}
			/>
			<Popover
				className="date-picker-control"
				open={Boolean(anchorEl)}
				onClose={() => {
					setAnchorEl(null);
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						{props.showTime ? (
							<DateTimePicker
								className="date-picker-control"
								autoOk={false}
								variant="static"
								format={props.format}
								ampm={!props.format.includes('H')}
								openTo="date"
								views={['year', 'month', 'date', 'hours', 'minutes']}
								value={props.date}
								onChange={(args: MaterialUiPickersDate) => {
									args.second(props.date ? new Date(props.date).getSeconds() : 0);
									props.onDateChange(args.toDate());
								}}
								renderDay={renderWrappedWeekDay}
								maxDate={props.maxDate}
								minDate={props.minDate}
								disableFuture={props.disableFuture}
							/>
						) : (
							<DatePicker
								className="date-picker-control"
								autoOk={false}
								variant="static"
								format={props.format}
								openTo={props.display === HistoryTimeRange.Month ? 'month' : 'date'}
								views={
									props.display === HistoryTimeRange.Month
										? ['year', 'month']
										: ['year', 'month', 'date']
								}
								value={props.date}
								onChange={(args: MaterialUiPickersDate) => {
									props.onDateChange(args.toDate());
								}}
								renderDay={
									props.display === HistoryTimeRange.Week
										? renderWrappedWeekDayForWeek
										: renderWrappedWeekDay
								}
								onMonthChange={
									props.display === HistoryTimeRange.Month
										? (date: MaterialUiPickersDate) => {
												setAnchorEl(null);
										  }
										: null
								}
								maxDate={props.maxDate}
								minDate={props.minDate}
								disableFuture={props.disableFuture}
							/>
						)}
					</MuiPickersUtilsProvider>
					{(!props.minDate || new Date() > props.minDate) && (!props.maxDate || new Date() < props.maxDate) && (
						<div className="picker-footer-container">
							<Button
								onClick={() => {
									props.onDateChange(new Date());
									setAnchorEl(null);
								}}
								className="today-button"
							>
								{TranslateText('common.today')}
							</Button>
						</div>
					)}
				</>
			</Popover>
		</>
	);
};

export default MaterialDatePicker;
