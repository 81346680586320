import './styles.scss';

import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { GetTripType } from 'components/RightSidebarMenu/HistoryTripsUtils';
import { User } from 'oidc-client';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getContrastColor, getTripVisitTime } from 'utils/TripUtils';

import PrivacySettingsEnum from '../../../../../models/PrivacySettingsEnum';
import DateTimeUtil from '../../../../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../../../../store';
import { ProcessedTripDto } from '../../../../../store/HistoryStore';
import { FormatDate, GetHourAndMinutes, isSameDay } from '../../../../../utils/DateUtils';
import { TranslateText } from '../../../../../utils/Translations';
import { OverviewHistoryTabCard } from './OverviewHistoryTabCard';

type Props = {
	tripDetails: ProcessedTripDto;
	hidden: boolean;
};

const privateDataMask = '****';

const OverviewHistoryTab = (props: Props) => {
	//get Customer Track Type
	const globalCustomer = useSelector((state: ApplicationState) => state.globalCustomer.filteredCustomer);
	const customerTrackTypeSpecification = useSelector(
		(state: ApplicationState) => state.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);

	const getDefaultCustomerTrackTypeSpecificationOn = useCallback((): boolean => {
		return globalCustomer ? globalCustomer.featuresSettings.trackTypeSpecification : customerTrackTypeSpecification;
	}, [globalCustomer, customerTrackTypeSpecification]);

	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const personId = useSelector((s: ApplicationState) => s.currentSession.personId);
	const user: User = useSelector((state: ApplicationState) => state.oidc.user);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);

	return (
		<div hidden={props.hidden} className="summary-container">
			<div className="detail-card-grid">
				<OverviewHistoryTabCard
					title={
						props.tripDetails.isNonTrip
							? TranslateText('tripDetailsSection.addressNumber')
							: TranslateText('tripDetailsSection.trip')
					}
					cardClass="trip-card"
					cardStyle={{
						backgroundColor: props.tripDetails.tripColor,
						color: getContrastColor(props.tripDetails.tripColor),
					}}
				>
					{props.tripDetails.isInProgress ? '-' : props.tripDetails.index + 1}
				</OverviewHistoryTabCard>

				{props.tripDetails.isPerson ? (
					<OverviewHistoryTabCard
						title={TranslateText('common.object')}
						cardClass="object-card"
						materialIconsKey="directions_car"
					>
						{props.tripDetails.objectName}
					</OverviewHistoryTabCard>
				) : driverIdentification && !props.tripDetails.isNonTrip ? (
					<OverviewHistoryTabCard
						title={TranslateText('common.driver')}
						cardClass="driver-card"
						materialIconsKey="person"
					>
						{props.tripDetails.personName}
					</OverviewHistoryTabCard>
				) : null}

				<OverviewHistoryTabCard
					title={TranslateText('tripDetailsSection.startTime')}
					cardClass="time-card"
					cardTextClass={
						!isSameDay(
							props.tripDetails.startTripTime,
							props.tripDetails.endTripTime,
							true,
							props.tripDetails.timeZoneMinutesOffset
						)
							? 'different-day'
							: ''
					}
					materialIconsKey="history"
				>
					{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
					!props.tripDetails.startTripTime ? (
						<span>{privateDataMask}</span>
					) : (
						<>
							{!isSameDay(
								props.tripDetails.startTripTime,
								props.tripDetails.endTripTime,
								true,
								props.tripDetails.timeZoneMinutesOffset
							) ? (
								<span>{FormatDate(props.tripDetails.startTripTime, true, true, true)}</span>
							) : null}
							<span>{GetHourAndMinutes(props.tripDetails.startTripTime, true)}</span>
						</>
					)}
				</OverviewHistoryTabCard>

				<OverviewHistoryTabCard
					title={TranslateText('tripDetailsSection.endTime')}
					cardClass="time-card"
					cardTextClass={
						!isSameDay(
							props.tripDetails.startTripTime,
							props.tripDetails.endTripTime,
							true,
							props.tripDetails.timeZoneMinutesOffset
						)
							? 'different-day'
							: ''
					}
					materialIconsKey="history"
				>
					{!props.tripDetails.isInProgress ? (
						customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
						!props.tripDetails.endTripTime ? (
							<span>{privateDataMask}</span>
						) : (
							<>
								{!isSameDay(
									props.tripDetails.startTripTime,
									props.tripDetails.endTripTime,
									true,
									props.tripDetails.timeZoneMinutesOffset
								) ? (
									<span>{FormatDate(props.tripDetails.endTripTime, true, true, true)}</span>
								) : null}
								<span>{GetHourAndMinutes(props.tripDetails.endTripTime, true)}</span>
							</>
						)
					) : null}
				</OverviewHistoryTabCard>

				{props.tripDetails.isNonTrip ? (
					<OverviewHistoryTabCard
						title={TranslateText('tripDetailsSection.nonTripVisit')}
						cardClass="duration-card"
						materialIconsKey="history"
					>
						{getTripVisitTime(props.tripDetails, customerPrivacySettings, personId, userCanSeePrivateData)}
					</OverviewHistoryTabCard>
				) : (
					<>
						<OverviewHistoryTabCard
							title={TranslateText('tripDetailsSection.distance')}
							cardClass="distance-card"
							cardTextClass={
								props.tripDetails.totalMileage && props.tripDetails.totalMileage > 9999 ? 'small' : ''
							}
							materialIconsKey="mode_of_travel"
						>
							{props.tripDetails.totalMileage?.toFixed(1)}
							<span className="unit-type">{' ' + TranslateText('tripDetailsSection.kilometers')}</span>
						</OverviewHistoryTabCard>

						<OverviewHistoryTabCard
							title={TranslateText('tripDetailsSection.duration')}
							cardClass="duration-card"
							materialIconsKey="history"
						>
							{customerPrivacySettings === PrivacySettingsEnum.HidePrivateLocationAndTime &&
							props.tripDetails.private &&
							personId !== props.tripDetails.personId &&
							!userCanSeePrivateData ? (
								<span>{privateDataMask}</span>
							) : props.tripDetails.tripDuration >= 0 ? (
								<>
									<span>{DateTimeUtil.hoursAndMinutesDuration(props.tripDetails.tripDuration)}</span>
									<span>{DateTimeUtil.minutesDuration(props.tripDetails.tripDuration)}</span>
								</>
							) : null}
						</OverviewHistoryTabCard>

						<OverviewHistoryTabCard
							title={TranslateText('tripDetailsSection.averageSpeed')}
							cardClass="speed-card"
							cardTextClass={
								props.tripDetails.averageSpeed && props.tripDetails.averageSpeed > 999 ? 'small' : ''
							}
							materialIconsKey="speed"
						>
							{props.tripDetails.averageSpeed?.toFixed(1)}
							<span className="unit-type">{' ' + TranslateText('tripDetailsSection.kmPerHour')}</span>
						</OverviewHistoryTabCard>

						<OverviewHistoryTabCard
							title={TranslateText('tripDetailsSection.maxSpeed')}
							cardClass="speed-card"
							cardTextClass={
								props.tripDetails.maxSpeed && props.tripDetails.maxSpeed > 999 ? 'small' : ''
							}
							materialIconsKey="speed"
						>
							{props.tripDetails.maxSpeed?.toFixed(0)}
							<span className="unit-type">{' ' + TranslateText('tripDetailsSection.kmPerHour')}</span>
						</OverviewHistoryTabCard>

						{getDefaultCustomerTrackTypeSpecificationOn() ? (
							<OverviewHistoryTabCard
								title={TranslateText('tripDetailsSection.type')}
								cardClass="type-card"
								materialIconsKey="card_travel"
							>
								{GetTripType(props.tripDetails.trackType)}
							</OverviewHistoryTabCard>
						) : null}
					</>
				)}
			</div>
		</div>
	);
};
export default OverviewHistoryTab;
