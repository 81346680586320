import { secondsToIntervalStringVisitTime } from 'components/Common/DateTimeUtilFunctions';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import { ProcessedTripDto } from 'store/HistoryStore';

export const LightColorLabel = '#000';
export const DarkColorLabel = '#fff';

export const privateDataMask = '****';

export const getContrastColor = (hexWithHash: string): string => {
	if (!hexWithHash) {
		return DarkColorLabel;
	}
	const hexValInt = parseInt(hexWithHash.replace('#', ''), 16);
	//red from color > 128
	return ((hexValInt >> 16) & 255) > 128 ? LightColorLabel : DarkColorLabel;
};

export const getTripVisitTime = (
	trip: {
		isInProgress: boolean;
		isNonTrip: boolean;
		private: boolean;
		isNextTripPrivate: boolean;
		parentId: string;
		personId: string;
		visitTime: number;
		startTripTime: string;
		endTripTime: string;
	},
	customerPrivacyLevel: PrivacySettingsEnum,
	personId: string,
	userCanSeePrivateData: boolean,
	visitTimeInterval = true
) => {
	if (trip.isNonTrip) {
		return visitTimeInterval ? secondsToIntervalStringVisitTime(trip.visitTime) : trip.visitTime.toString();
	}

	if (trip.isInProgress) {
		return '';
	}

	if (
		customerPrivacyLevel === PrivacySettingsEnum.HidePrivateLocationAndTime &&
		(trip.private || trip.isNextTripPrivate) &&
		trip.parentId !== personId &&
		trip.personId !== personId &&
		!userCanSeePrivateData &&
		!trip.isNonTrip
	) {
		return privateDataMask;
	}

	if (trip.visitTime) {
		return visitTimeInterval ? secondsToIntervalStringVisitTime(trip.visitTime) : trip.visitTime.toString();
	}

	return '';
};

export const setTripsCounting = (trips: ProcessedTripDto[], dayTripsCount: boolean): ProcessedTripDto[] => {
	if (dayTripsCount) {
		for (let i = trips.length - 1; i >= 0; i--) {
			if (trips[i].isParked) {
				continue;
			}

			trips[i] = { ...trips[i], index: trips[i].dayTripsIndex };
		}
	} else {
		const tripsIndexesMap = new Map<string, number>();
		for (let i = trips.length - 1; i >= 0; i--) {
			if (trips[i].isParked) {
				continue;
			}

			const temp = { ...trips[i] };

			let index = 0;
			if (tripsIndexesMap.has(temp.parentId)) {
				index = tripsIndexesMap.get(temp.parentId);
			}

			temp.index = index;
			if (!temp.isParked && !temp.isInProgress) {
				tripsIndexesMap.set(temp.parentId, index + 1);
			}

			trips[i] = temp;
		}
	}

	return [...trips];
};
