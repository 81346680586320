import 'bootstrap/dist/css/bootstrap.css';

import { ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import * as ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { PersistGate } from 'redux-persist/integration/react';
import queryClient from 'utils/queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import userService, { initUserManager } from './auth';
import MainTheme from './MainTheme';
import registerServiceWorker from './registerServiceWorker';
import { CurrentSession, CustomerState } from './store';
import configureStore from './store/configureStore';
import { GlobalCustomerState } from './store/GlobalCustomer';
import { checkBuildNumber } from './utils/GenericUtils';
import React from 'react';

//check build number
checkBuildNumber();

//initialize UserManager
initUserManager();

// Create browser history to use in the Redux store
export const history = createBrowserHistory();

export const ApplicationStore = configureStore(history);

export const getAccessToken = () => ApplicationStore.store.getState()?.oidc?.user?.access_token;
export const getCurrentSession = (): CurrentSession => ApplicationStore.store.getState()?.currentSession;
export const getCustomer = (): CustomerState => getCurrentSession().customer;
export const getGlobalCustomer = (): GlobalCustomerState => ApplicationStore.store.getState()?.globalCustomer;

//Dotenv loads environment variables from a .env file into process.env
require('dotenv').config();

ReactDOM.render(
	<Provider store={ApplicationStore.store}>
		<QueryClientProvider client={queryClient}>
			<OidcProvider store={ApplicationStore.store} userManager={userService.userManager}>
				<PersistGate loading={'Loading...'} persistor={ApplicationStore.persistor}>
					<ThemeProvider theme={MainTheme}>
						<App history={history} />
						{process.env.NODE_ENV === 'development' && (
							<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
						)}
					</ThemeProvider>
				</PersistGate>
			</OidcProvider>
		</QueryClientProvider>
	</Provider>,
	document.getElementById('root')
);

registerServiceWorker();
