import React from 'react';
import { Duration } from './Duration';
import { EvaluationMomentDropdown } from './EvaluationMomentDropdown';
import { useTimeFrameEvaluation } from './hooks';
import { TimeFrameDropdown } from './TimeFrameDropdown';
import { TimeFrameEvaluationProps } from './types';
import { Divider, Grid } from '@material-ui/core';
import { TranslateText } from '../../../../utils/Translations';

const TimeFrameEvaluation = ({
	durationExceededMetadata,
	handleTimeFrameEvaluationChange,
	disabled,
	reInitializeData,
	validateResult,
	durationMaxValue
}: TimeFrameEvaluationProps): JSX.Element => {
	const {
		evaluationMomentId,
		handleOnEvaluationMomentChange,
		handleOnTimeFrameValueChange,
		handleOnDurationChange,
		timeFrameId,
		timeFrameDurationValue,
	} = useTimeFrameEvaluation(durationExceededMetadata, handleTimeFrameEvaluationChange, reInitializeData);

	return (
		<>
			<Grid className={'col'} item xs alignItems={'center'}>
				{TranslateText('fields.timeFrame')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<TimeFrameDropdown
					valueId={timeFrameId}
					handleOnTimeFrameValueChange={handleOnTimeFrameValueChange}
					disabled={disabled}
					hideLabel
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid className={'col'} item xs alignItems={'center'}>
				{TranslateText('fields.duration')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<Duration
					currentValue={timeFrameDurationValue}
					maxValue={durationMaxValue}
					validateResult={validateResult}
					handleOnDurationChange={handleOnDurationChange}
					disabled={disabled}
					hideLabel
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid className={'col'} item xs alignItems={'center'}>
				{TranslateText('fields.evaluationMoment')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<EvaluationMomentDropdown
					valueId={evaluationMomentId}
					handleOnEvaluationMomentValueChange={handleOnEvaluationMomentChange}
					disabled={disabled}
					hideLabel
				/>
			</Grid>
		</>
	);
};


export default TimeFrameEvaluation;
