import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

const Home = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(true));
	}, []);

	return <div className="home">Missing content!!!</div>;
};

export default connect()(Home);
