import './timeCounter.scss';

import React, { useEffect, useState } from 'react';

import DateTimeUtil from '../../../shared/datetime/DateTimeUtil';

type Props = {
	referenceDate: Date;
	refreshRateInMilliseconds: number;
	hidden: boolean;
	showSeconds: boolean;
};

const TimeCounter = (props: Props) => {
	const [duration, setDuration] = useState('');

	useEffect(() => {
		if (props.hidden) {
			return;
		}

		if (props.showSeconds) {
			setDuration(DateTimeUtil.calculateTimePassed(props.referenceDate));
		} else {
			setDuration(DateTimeUtil.calculateTimePassed(props.referenceDate, new Date(), false));
		}

		const id = setInterval(() => {
			if (props.showSeconds) {
				setDuration(DateTimeUtil.calculateTimePassed(props.referenceDate));
			} else {
				setDuration(DateTimeUtil.calculateTimePassed(props.referenceDate, new Date(), false));
			}
		}, props.refreshRateInMilliseconds);
		return () => {
			clearInterval(id);
		};
	}, [props.referenceDate]);

	return (
		<span hidden={props.hidden} className={'duration-counter-inline'}>
			{duration}
		</span>
	);
};

TimeCounter.defaultProps = {
	showSeconds: true,
};

export default TimeCounter;
