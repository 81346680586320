import { Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import ToolbarButton from '../ToolbarButton';
import { useToolbarButtonActive, useToolbarControls } from './hooks';
import { TToolbarProps, TStyleType } from './types';

const Toolbar: FunctionComponent<TToolbarProps> = (props) => {
	const { availableControls } = useToolbarControls(props.controls, props.customControls);
	const { getToolbarButtonActiveState } = useToolbarButtonActive();

	const id = props.inlineMode ? '-inline-toolbar' : '-toolbar';

	const renderToolbarAvailableControlsButtons = (availableControls: TStyleType[]): JSX.Element[] =>
		availableControls.map((style: TStyleType) => {
			if (props.inlineMode && style.type !== 'inline' && style.name !== 'link' && style.name !== 'clear') {
				return null;
			}

			return (
				<ToolbarButton
					id={style.id}
					editorId={props.id}
					key={`key-${style.label}`}
					active={getToolbarButtonActiveState(props.isActive, props.editorState, style)}
					label={style.label}
					onClick={props.onClick}
					style={style.style}
					type={style.type}
					icon={style.icon}
					component={style.component}
					inlineMode={props.inlineMode}
					disabled={props.disabled}
					size={props.size}
				/>
			);
		});

	return (
		<Box id={`${props.id}${id}`} className={props.className}>
			{renderToolbarAvailableControlsButtons(availableControls)}
			{props.customComponents}
		</Box>
	);
};

export default Toolbar;
