import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { TranslateText } from '../../../utils/Translations';
import { IActionButton } from './ButtonWidget';
import './buttonWidget.scss';

import '../connectionWidget.scss';
import PlainOneFieldDialog, { PlainEntity } from '../components/PlainOneFieldDialog';
import ajaxUtil from '../../../utils/Ajax';
import GlobalSettings from '../../../GlobalSettings.json';

const AddLocationCategoryButton = (props: IActionButton) => {
	const [visible, setVisible] = useState<boolean>(false);
	const saveCallback = useCallback(
		async (entity: PlainEntity) => {
			await ajaxUtil
				.post(`${GlobalSettings.customersMaintenanceApi}/createLocationCategory`, {
					name: entity.name,
					customerId: props.entityId,
				})
				.then(() => {
					props.actionExecuted(false);
				});
		},
		[visible]
	);
	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>
			<PlainOneFieldDialog
				categoryNames={props.currentSelection.map((c) => c.name)}
				saveCallBack={saveCallback}
				visible={visible}
				setVisible={setVisible}
				dialogTitle={TranslateText('category.locationCategories')}
				headerRowName={TranslateText('maintenanceOverview.grid.colName')}
			/>
		</>
	);
};

export default AddLocationCategoryButton;
