import { ListSelectionItem } from 'components/ListSelectionView/types';
import { filterWorkingDays, getDefaultWorkingDays } from 'components/WorkingHoursSchedule/WorkingHoursUtils';

import GeofenceTypeEnum from './GeofenceTypeEnum';
import MonitoringPeriodEnum from './MonitoringPeriodEnum';
import { WorkingDay } from './WorkingDay';

class GeofenceAlertMetadata {
	constructor() {
		this.geofenceType = GeofenceTypeEnum.Both;
		this.locations = [];
		this.monitoringPeriod = MonitoringPeriodEnum.Inside;
		this.timeSchedule = filterWorkingDays(getDefaultWorkingDays(true));
	}
	public geofenceType: GeofenceTypeEnum;
	public locations: ListSelectionItem[];
	public timeSchedule: WorkingDay[];
	public monitoringPeriod: MonitoringPeriodEnum;
	[propName: string]: any;
}

export default GeofenceAlertMetadata;
