import { Dispatch } from 'react';
import { Action } from 'redux';

import { AutocompleteItem } from '../components/Common/Autocomplete/MaterialAutocomplete';
import * as GlobalSettings from '../GlobalSettings.json';
import ajaxUtil from '../utils/Ajax';
import { TranslateText } from '../utils/Translations';

const SET_TIMEZONES_FETCHED = 'SET_TIMEZONES_FETCHED';
const SET_TIMEZONES = 'SET_TIMEZONES';
const SET_LANGUAGES_FETCHED = 'SET_LANGUAGES_FETCHED';
const SET_LANGUAGES = 'SET_LANGUAGES';
const SET_COUNTRIES_FETCHED = 'SET_COUNTRIES_FETCHED';
const SET_COUNTRIES = 'SET_COUNTRIES';

export interface ICommonData {
	timezonesFetched: boolean;
	timezonesDataSource: AutocompleteItem[];
	languagesFetched: boolean;
	languagesDataSource: AutocompleteItem[];
	countriesFetched: boolean;
	countriesDataSource: AutocompleteItem[];
}

const initialState: ICommonData = {
	timezonesFetched: false,
	timezonesDataSource: [],
	languagesFetched: false,
	languagesDataSource: [],
	countriesFetched: false,
	countriesDataSource: [],
};

export interface ICommonDataActionsSignature {
	fetchTimezones: () => void;
	fetchLanguages: () => void;
	fetchCountries: () => void;
}

export interface IPayloadAction extends Action {
	payload: any;
}

export const commonDataActionCreators: ICommonDataActionsSignature = {
	fetchTimezones: () => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({ type: SET_TIMEZONES_FETCHED, payload: true });
		ajaxUtil
			.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Timezones')
			.then((data) => {
				dispatch({ type: SET_TIMEZONES, payload: data });
			})
			.catch(() => {
				dispatch({ type: SET_TIMEZONES_FETCHED, payload: false });
			});
	},
	fetchLanguages: () => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({ type: SET_LANGUAGES_FETCHED, payload: true });
		ajaxUtil
			.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Languages')
			.then((data) => {
				data.forEach((l) => {
					l.display = TranslateText(`languages.${l.display}`);
				});
				dispatch({ type: SET_LANGUAGES, payload: data });
			})
			.catch(() => {
				dispatch({ type: SET_LANGUAGES_FETCHED, payload: false });
			});
	},
	fetchCountries: () => (dispatch: Dispatch<IPayloadAction>) => {
		dispatch({ type: SET_COUNTRIES_FETCHED, payload: true });
		ajaxUtil
			.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Countries')
			.then((data) => {
				dispatch({ type: SET_COUNTRIES, payload: data });
			})
			.catch(() => {
				dispatch({ type: SET_COUNTRIES_FETCHED, payload: false });
			});
	},
};

export const commonDataReducer = (state: ICommonData = initialState, action: IPayloadAction) => {
	switch (action.type) {
		case SET_TIMEZONES_FETCHED:
			return { ...state, timezonesFetched: action.payload };
		case SET_TIMEZONES:
			return { ...state, timezonesDataSource: action.payload };
		case SET_LANGUAGES_FETCHED:
			return { ...state, languagesFetched: action.payload };
		case SET_LANGUAGES:
			return { ...state, languagesDataSource: action.payload };
		case SET_COUNTRIES_FETCHED:
			return { ...state, countriesFetched: action.payload };
		case SET_COUNTRIES:
			return { ...state, countriesDataSource: action.payload };
		default:
			return state;
	}
};
