import './styles.scss';

import { Box } from '@material-ui/core';
import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import React, { useRef, useMemo } from 'react';
import { TranslateText } from 'utils/Translations';

import { WIDGET_MIN_SIZE_X, WIDGET_MIN_SIZE_Y } from './constants';
import { BaseWidgetFCProps } from './types';
import { useWidgetEditing, useWidgetVisibility } from './hooks';

const BaseWidgetFC = ({
	id,
	dataCol,
	dataRow,
	sizeX,
	sizeY,
	widgetHeader,
	children,
	checkHasUnsavedData,
	notifyAboutUnsavedData,
	editMode,
	buttons = [],
	showEditButton = false,
	allowEditMode = false,
	removePanelCallback = () => {},
	editPanelCallback = () => {},
}: BaseWidgetFCProps): JSX.Element => {
	const { visible, hideWidgetCallback } = useWidgetVisibility(removePanelCallback);
	const { editWidgetCallback } = useWidgetEditing(checkHasUnsavedData, notifyAboutUnsavedData, editPanelCallback);

	const renderButtons = () => buttons.map((button: JSX.Element) => button);

	const widgetCaption: string = useMemo(() => TranslateText(widgetHeader), [widgetHeader]);

	return (
		visible && (
			<Box
				id={id}
				className={`e-panel widget ${visible ? '' : 'hidden'}`}
				data-row={dataRow}
				data-col={dataCol}
				data-sizex={sizeX}
				data-sizey={sizeY}
				data-minsizex={WIDGET_MIN_SIZE_X}
				data-minsizey={WIDGET_MIN_SIZE_Y}
			>
				<Box className="e-panel-container">
					<WidgetHeader
						caption={widgetCaption}
						showEditButton={showEditButton}
						editMode={editMode}
						allowEditMode={allowEditMode}
						removePanelCallback={hideWidgetCallback}
						editCallback={editWidgetCallback}
					/>
					<Box className="widget-view base-widget-view">{children}</Box>
					{editMode && <Box className="buttons-host">{renderButtons()}</Box>}
				</Box>
			</Box>
		)
	);
};

export default React.memo(BaseWidgetFC);
