import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useEffect, useState } from 'react';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: GeocodingCache;
	//Any other custom props
};

interface GeocodingCache {
	keepAlive: number;
	accuracy: number;
}

const getConstants = () => {
	return {
		defaultDataSource: [
			{ id: true, display: TranslateText('common.yes') },
			{ id: false, display: TranslateText('common.no') },
		],
		keepAliveDataSource: [
			{ id: 90, display: TranslateText('customerOverview.keepAlive90days') },
			{ id: 180, display: TranslateText('customerOverview.keepAlive180days') },
			{ id: 365, display: TranslateText('customerOverview.keepAlive365days') },
			{ id: 730, display: TranslateText('customerOverview.keepAlive2years') },
		],
		accuracyDataSource: [
			{ id: 0, display: TranslateText('customerOverview.accuracy0') },
			{ id: 6, display: TranslateText('customerOverview.accuracy6') },
			{ id: 10, display: TranslateText('customerOverview.accuracy10') },
		],
		defaultValues: {
			keepAlive: 90,
			accuracy: 6,
			default: true,
		} as GeocodingCache & { default: boolean },
		fieldsConfig: { text: 'display', value: 'id' },
	};
};

const GetGeocodingCacheValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		keepAlive: {
			rules: { required: ValidatorFunctions.required() },
		},
		accuracy: {
			rules: { required: ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const GeocodingCacheVie = (props: Props) => {
	const [constants] = useState(getConstants);

	const [defaultField, setDefaultField] = useState<boolean>(() => {
		if (props.data) {
			return (
				props.data?.keepAlive === constants.defaultValues.keepAlive &&
				props.data?.accuracy === constants.defaultValues.accuracy
			);
		}

		return constants.defaultValues.default;
	});

	useEffect(() => {
		setDefaultField(
			props.data?.keepAlive === constants.defaultValues.keepAlive &&
				props.data?.accuracy === constants.defaultValues.accuracy
		);
	}, [props.editMode, props.data?.keepAlive, props.data?.accuracy]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetGeocodingCacheValidationConfig() }));

		setDefaultField(
			props.data?.keepAlive === constants.defaultValues.keepAlive &&
				props.data?.accuracy === constants.defaultValues.accuracy
		);
	}, [props.entityId]);

	const events = {
		onChangeDefault: (value: boolean) => {
			setDefaultField(value);
			props.changeDataCallback({
				...props.data,
				keepAlive: constants.defaultValues.keepAlive,
				accuracy: constants.defaultValues.accuracy,
			});
		},
		onValueChanged: async (value: number, key: 'keepAlive' | 'accuracy') => {
			const newState = { ...props.data };
			newState[key] = value;
			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper'}>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={defaultField}
					dataSource={constants.defaultDataSource}
					name="defaultField"
					disabled={!props.editMode}
					label={TranslateText('fields.default')}
					onChange={({ value }) => events.onChangeDefault(value as boolean)}
				/>
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.keepAlive}
					dataSource={constants.keepAliveDataSource}
					name="keepAlive"
					disabled={!props.editMode || defaultField}
					label={TranslateText('fields.keepAlive')}
					onChange={({ value }) => events.onValueChanged(value as number, 'keepAlive')}
				/>
				<ValidationMessage result={props.validationResult?.keepAlive} />
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={props.data.accuracy}
					dataSource={constants.accuracyDataSource}
					name="accuracy"
					disabled={!props.editMode || defaultField}
					label={TranslateText('fields.accuracy')}
					onChange={({ value }) => events.onValueChanged(value as number, 'accuracy')}
				/>
				<ValidationMessage result={props.validationResult?.accuracy} />
			</div>
		</div>
	);
};

export default GeocodingCacheVie;
