import React, { MouseEventHandler } from 'react';

import { Button } from '@material-ui/core';

import { ClaimConfig } from '../../authorization/ClaimUtil';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';

export interface WidgetHeaderProps {
	caption: string;
	showEditButton: boolean;
	editMode?: boolean;
	allowEditMode: boolean;
	editClaimConfig?: ClaimConfig[];
	optionalEditClaimConfig?: ClaimConfig[];

	//Callbacks
	removePanelCallback: () => void;
	editCallback?: MouseEventHandler;
}

const WidgetHeader = (props: WidgetHeaderProps) => {
	return (
		<div className="e-panel-header">
			<div className="e-header-text">{props.caption}</div>
			{props.showEditButton && (
				<ProtectedContainer claimConfig={props.editClaimConfig} optionalClaimConfig={props.optionalEditClaimConfig}>
					<Button
						className={`e-btn e-flat widget-button header-button edit-icon${
							props.editMode ? ' e-active' : ''
						}`}
						disabled={!props.allowEditMode}
						onClick={props.editCallback}
					/>
				</ProtectedContainer>
			)}
			<Button className="dragicon e-flat widget-button header-button drag-icon e-draggable" />
			<Button
				className="e-flat widget-button header-button visible-icon"
				disabled={props.editMode}
				onClick={props.removePanelCallback}
			/>
			<div className="header-border" />
		</div>
	);
};
export default WidgetHeader;
