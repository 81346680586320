import { extendObjectNameWithTimeZoneLongFormat } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetSelectionDispatcher, IFleetSelectionState } from 'store/FleetSelection';
import { LiveDataDispatcher, LiveDataResultDto } from 'store/LiveData';
import ajaxUtil from 'utils/Ajax';
import { newHashValue } from 'utils/GuidAndHashUtils';

import * as GlobalSettings from '../../../GlobalSettings.json';
import queryClient from '../../../utils/queryClient';
import { LiveDataResponse } from './types';

const useLiveDataSocket = () => {
	const [receivedLiveData, setReceivedLiveData] = useState<LiveDataResponse>(null);
	const [processedEntities, setProcessedEntities] = useState<ITreeNode[]>([]);

	const dispatch = useDispatch();
	const socketMessage = useSelector((state: ApplicationState) => state.liveData.socketMessage);
	const fleetSelectionState: IFleetSelectionState = useSelector((state: ApplicationState) => state.fleetSelection);
	const liveEntitiesUnfiltered: LiveDataResultDto[] = useSelector(
		(state: ApplicationState) => state.liveData.liveEntitiesUnfiltered
	);

	const customerId: string = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const driverIdentification: boolean = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer?.featuresSettings.driverIdentification
	);

	const personId: string = useSelector((state: ApplicationState) => state.currentSession.personId);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);
	//used to have access to latest live entity unfiltered (onSuccess from useMutation is not changed)
	const liveEntityUnfilteredRef = useRef<LiveDataResultDto[]>();
	liveEntityUnfilteredRef.current = liveEntitiesUnfiltered;

	const { mutate } = useMutation(
		(addedEntities: ITreeNode[]) =>
			ajaxUtil.post<LiveDataResultDto[]>(
				`${GlobalSettings.fleetSelection}/GetLiveData/?customerId=${customerId}`,
				addedEntities
			),
		{
			onSettled: () => {
				queryClient.setQueryData(['live', 'get-fleet-selection-data'], false);
			},
			onSuccess: (data: LiveDataResultDto[]) => {
				if (!data) {
					return;
				}

				const filteredReceivedData: LiveDataResultDto[] = data.filter((d: LiveDataResultDto) =>
					liveEntityUnfilteredRef.current.every(
						(liveEntityUnfiltered: LiveDataResultDto) => liveEntityUnfiltered.entityId !== d.entityId
					)
				);

				const liveUnfilteredEntities = [...liveEntityUnfilteredRef.current, ...filteredReceivedData];

				liveUnfilteredEntities.forEach((entity, index) => {
					const newData = data.find((x) => x.entityId === entity.entityId);
					if (newData) {
						if (
							new Date(newData.stateStart) > new Date(entity.stateStart) ||
							new Date(newData.since) > new Date(entity.since) ||
							new Date(newData.lastSeen) > new Date(entity.lastSeen) ||
							newData.lat !== entity.lat ||
							newData.long !== entity.long
						) {
							entity = updateEntityData(entity, newData);
						}
					}
					if (entity.objectName) {
						const hasNullObjectName = data.find((x) => x.entityId === entity.entityId);
						if (hasNullObjectName && hasNullObjectName.objectName === null) {
							liveUnfilteredEntities[index] = hasNullObjectName;
						} else {
							const found = data.find(
								(d) => d.entityId === entity.entityId && d.timeZoneId !== entity.timeZoneId
							);
							if (found) {
								entity.objectNameWithTimeZoneOffset = extendObjectNameWithTimeZoneLongFormat(
									found.objectName,
									found.timeZoneMinutesOffset,
									customerTimezone !== found.timeZoneId
								);
							} else {
								entity.objectNameWithTimeZoneOffset = extendObjectNameWithTimeZoneLongFormat(
									entity.objectName,
									entity.timeZoneMinutesOffset,
									customerTimezone !== entity.timeZoneId
								);
							}
						}
					}
				});

				LiveDataDispatcher.setLiveUnfilteredEntities(dispatch, liveUnfilteredEntities);
			},
		}
	);

	const updateEntityData = (currentData: LiveDataResultDto, newData: LiveDataResultDto): LiveDataResultDto => {
		currentData.stateStart = newData.stateStart;
		currentData.state = newData.state;
		currentData.lastSeen = newData.lastSeen;
		currentData.lastKeepAlive = newData.lastKeepAlive;
		currentData.brand = newData.brand;
		currentData.fuelType = newData.fuelType;
		currentData.hasPrivateMileageInput = newData.hasPrivateMileageInput;
		currentData.isPrivateOff = newData.isPrivateOff;
		currentData.isTripDevice = newData.isTripDevice;
		currentData.lat = newData.lat;
		currentData.long = newData.long;
		currentData.since = newData.since;
		currentData.speed = newData.speed;
		currentData.timeZoneId = newData.timeZoneId;
		currentData.timeZoneMinutesOffset = newData.timeZoneMinutesOffset;
		currentData.tripType = newData.tripType;
		currentData.hasPrivateMileageInput = newData.hasPrivateMileageInput;
		currentData.direction = newData.direction;
		currentData.objectFunction = newData.objectFunction;
		currentData.objectIcon = newData.objectIcon;
		return currentData;
	};
	useEffect(() => {
		if (!receivedLiveData || !receivedLiveData.changes.length) {
			return;
		}

		if (socketMessage?.signature !== receivedLiveData?.signature && receivedLiveData.groupRefresh) {
			FleetSelectionDispatcher.refreshFleetSelection(dispatch, fleetSelectionState, driverIdentification);

			return;
		}

		const notChangedLiveDateEntitiesIds: string[] = liveEntitiesUnfiltered
			.map((liveEntityUnfiltered: LiveDataResultDto) => liveEntityUnfiltered.entityId)
			.filter(
				(liveEntityUnfiltered: string) =>
					!receivedLiveData.changes
						?.map((receivedLiveData: LiveDataResultDto) => receivedLiveData.entityId)
						.includes(liveEntityUnfiltered)
			);

		const notChangedLiveData: LiveDataResultDto[] = liveEntitiesUnfiltered.filter(
			(liveEntityUnfiltered: LiveDataResultDto) =>
				notChangedLiveDateEntitiesIds.includes(liveEntityUnfiltered.entityId)
		);

		const newLive: LiveDataResultDto[] = [...notChangedLiveData, ...receivedLiveData.changes];

		newLive.forEach((liveData) => {
			if (liveData.objectName && !liveData.objectNameWithTimeZoneOffset) {
				liveData.objectNameWithTimeZoneOffset = extendObjectNameWithTimeZoneLongFormat(
					liveData.objectName,
					liveData.timeZoneMinutesOffset,
					customerTimezone !== liveData.timeZoneId
				);
			}
		});

		if (newLive.length < liveEntitiesUnfiltered.length || receivedLiveData.changes.length !== 0) {
			LiveDataDispatcher.setLiveUnfilteredEntities(dispatch, newLive);
		}

		if (receivedLiveData.groupRefresh) {
			FleetSelectionDispatcher.refreshFleetSelection(dispatch, fleetSelectionState, driverIdentification);
		}
	}, [receivedLiveData]);

	useEffect(() => {
		const addedEntities: ITreeNode[] = fleetSelectionState.selectedEntities.filter((selectedEntity: ITreeNode) =>
			processedEntities.every((processedEntity: ITreeNode) => processedEntity.id !== selectedEntity.id)
		);

		const removedEntities: ITreeNode[] = processedEntities.filter((processedEntity: ITreeNode) =>
			fleetSelectionState.selectedEntities.every(
				(selectedEntity: ITreeNode) => selectedEntity.id !== processedEntity.id
			)
		);
		setProcessedEntities([...fleetSelectionState.selectedEntities]);

		if (!addedEntities.length && !removedEntities.length) {
			return;
		}
		mutate(addedEntities);
		LiveDataDispatcher.setSocketMessage(dispatch, {
			addedEntities: addedEntities.map(({ id, type }) => ({ id, entityType: type })),
			removedEntities: removedEntities.map(({ id, type }) => ({ id, entityType: type })),
			customerId: customerId,
			personId: personId,
			signature: newHashValue(8),
		});
	}, [fleetSelectionState.selectedEntities]);

	return { socketMessage, setReceivedLiveData };
};

export { useLiveDataSocket };
