import { useSelector } from 'react-redux';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { ApplicationState } from '../../store';

export const useShowLastDriverOfParkedObject = () => {
	return useSelector((state: ApplicationState) => {
		let showLastDriverOffParkedObject = state.currentSession.fleetOverviewSettings?.showLastDriverOfParkedObject;
		if (showLastDriverOffParkedObject === null || showLastDriverOffParkedObject === undefined) {
			showLastDriverOffParkedObject = state.globalCustomer?.filteredCustomer
				? state.globalCustomer.filteredCustomer.fleetOverviewSettings?.showLastDriverOfParkedObject
				: state.currentSession.customer?.showLastDriverOfParkedObject;
		}
		return showLastDriverOffParkedObject;
	});
};

export const useShowLastObjectOfParkedDriver = () => {
	return useSelector((state: ApplicationState) => {
		let showLastObjectOffParkedDriver = state.currentSession.fleetOverviewSettings?.showLastObjectOfParkedDriver;
		if (showLastObjectOffParkedDriver === null || showLastObjectOffParkedDriver === undefined) {
			showLastObjectOffParkedDriver = state.globalCustomer?.filteredCustomer
				? state.globalCustomer.filteredCustomer.fleetOverviewSettings?.showLastObjectOfParkedDriver
				: state.currentSession.customer?.showLastObjectOfParkedDriver;
		}
		return showLastObjectOffParkedDriver;
	});
};

export const useLocationsLive = () => {
	return useSelector((state: ApplicationState) => {
		let locationFields = state.currentSession?.personLiveSettings?.useLiveLocationFields;
		const currentSessionLevel = state.currentSession?.customerLevel;
		const locationsFeatureCurrentSessionCustomer = state.currentSession?.customer?.featuresSettings?.locations;
		const locationSettings = !!state.globalCustomer.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.locations
			: state.currentSession?.customer?.featuresSettings?.locations;

		if (locationFields === null || locationFields === undefined) {
			locationFields = state.currentSession?.customerLiveSettings?.useLiveLocationFields;
		}

		if (
			currentSessionLevel !== CustomerLevelEnum.Default &&
			!!state.globalCustomer.filteredCustomer &&
			!locationsFeatureCurrentSessionCustomer
		) {
			return false;
		}

		return locationFields && locationSettings;
	});
};

export const useLocationsHistory = () => {
	return useSelector((state: ApplicationState) => {
		let locationFields = state.currentSession?.personHistorySettings?.useHistoryLocationFields;
		const currentSessionLevel = state.currentSession?.customerLevel;
		const locationsFeatureCurrentSessionCustomer = state.currentSession?.customer?.featuresSettings?.locations;
		const locationSettings = !!state.globalCustomer.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.locations
			: locationsFeatureCurrentSessionCustomer;

		if (locationFields === null || locationFields === undefined) {
			locationFields = state.currentSession.customerHistorySettings.useHistoryLocationFields;
		}

		if (
			currentSessionLevel !== CustomerLevelEnum.Default &&
			!!state.globalCustomer.filteredCustomer &&
			!locationsFeatureCurrentSessionCustomer
		) {
			return false;
		}

		return locationFields && locationSettings;
	});
};

export const useLocationsOnMap = (isLiveMap: boolean) => {
	return useSelector((state: ApplicationState) => {
		const useLocationOnMapPersonSettings = isLiveMap
			? state?.currentSession?.personLiveSettings?.useLiveLocationOnMap
			: state?.currentSession?.personHistorySettings?.useHistoryLocationOnMap;
		return useLocationOnMapPersonSettings !== null
			? useLocationOnMapPersonSettings
			: isLiveMap
			? state.currentSession.customerLiveSettings?.useLiveLocationOnMap
			: state.currentSession.customerHistorySettings?.useHistoryLocationOnMap;
	});
};
