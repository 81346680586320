import { SvgBlobIcon } from 'models/BlobIcon';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import PersonIcon from '@material-ui/icons/Person';

const base64PrefexUrl = 'data:image/svg+xml;charset=UTF-8;base64,';
const iconNotAvailable: SvgBlobIcon = {
	name: 'no-image',
	content:
		'<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.95Q41 31.1 41 24q0-3.05-1.05-5.85T37 13.05L13.05 37q2.25 1.95 5.075 2.975Q20.95 41 24 41Zm-12.95-6.05 23.9-23.9q-2.3-1.95-5.1-3T24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 3.05 1.1 5.875t2.95 5.075Z"/></svg>',
	iconSet: 'default',
};
const iconNoImage: SvgBlobIcon = {
	name: 'no-img',
	content:
		'<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 21.898438 21.898438 L 2.101562 2.101562 L 0.691406 3.511719 L 3 5.828125 L 3 19 C 3 20.101562 3.898438 21 5 21 L 18.171875 21 L 20.480469 23.308594 Z M 5 19 L 5 7.828125 L 11.839844 14.671875 L 11 15.71875 L 9 13 L 6 17 L 14.171875 17 L 16.171875 19 Z M 7.828125 5 L 5.828125 3 L 19 3 C 20.101562 3 21 3.898438 21 5 L 21 18.171875 L 19 16.171875 L 19 5 Z M 7.828125 5 "/></g></svg>',
	iconSet: 'default',
};

const renderEntityIcon = (entityType: EntityTypeEnum) =>
	entityType === 'Person' ? (
		<AccountCircleIcon />
	) : entityType === 'Driver' ? (
		<PersonIcon />
	) : (
		<CategoryOutlinedIcon />
	);

const getMarkerIcon = (iconName: string, iconColor: string, icons: { [name: string]: string }): string => {
	if (icons[iconName]) {
		const content = icons[iconName].replace('<svg', `<svg fill="${iconColor}"`);
		return `${base64PrefexUrl}${btoa(content)}`;
	}

	return `${base64PrefexUrl}${btoa(iconNotAvailable.content)}`;
};

export { iconNotAvailable, iconNoImage, base64PrefexUrl, renderEntityIcon, getMarkerIcon };
