export enum FilterEntityType {
	All = 0,
	Object = 1,
	Person = 2,
	User = 3,
	Driver = 4,
	Group = 5,
	Right = 6,
	Report = 7,
	Role = 8,
	Asset = 9,
}

export default FilterEntityType;
