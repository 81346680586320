import { BlobIconDictionary, SvgBlobIcon } from 'models/BlobIcon';
import { useMemo } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { iconNotAvailable } from 'utils/Icons';

const useStyleSvgIcon = () => {
	const styleSvgIcon = (svgIcon: string, color: string, size: number): string => {
		const svgRegExpMatch: RegExpMatchArray = svgIcon.match(/height="[0-9]+"/);

		if (!svgRegExpMatch) {
			return svgIcon;
		}

		const svgHeight: number = parseInt(svgRegExpMatch[0].split('"')[1]);

		svgIcon = svgIcon.replace('<svg', `<svg fill=${color}`);
		svgIcon = svgIcon.replace(/height="[0-9]+"/, `height=${size}`);
		svgIcon = svgIcon.replace(/width="[0-9]+"/, `width=${size}`);
		svgIcon = svgIcon.replace(new RegExp('<path', 'g'), `<path transform=scale(${size / svgHeight})`);

		return svgIcon;
	};

	return { styleSvgIcon };
};

const useTryGetSvgIconFromQueryCache = (iconName: string, defaultIcon = 'objects/car') => {
	const queryClient: QueryClient = useQueryClient();
	const blobIcons: BlobIconDictionary = queryClient.getQueryData<BlobIconDictionary>(['application-icons']);

	const icon: SvgBlobIcon = useMemo((): SvgBlobIcon => {
		const svgBlobIcons: SvgBlobIcon[] = [];

		if (blobIcons) {
			Object.keys(blobIcons).filter((key: string) => {
				svgBlobIcons.push(...blobIcons[key]);
			});
		}
		const icon: SvgBlobIcon =
			svgBlobIcons.find((icon: SvgBlobIcon) => `${icon.iconSet}/${icon.name}` === iconName) ??
			svgBlobIcons.find((icon: SvgBlobIcon) => `${icon.iconSet}/${icon.name}` === defaultIcon) ??
			iconNotAvailable;

		return icon;
	}, [blobIcons, iconName, defaultIcon]);

	return { icon };
};

export { useStyleSvgIcon, useTryGetSvgIconFromQueryCache };
