const SelectionHelper = {
	createNewSelection: (currentSelection: string[], clickedItemId: string, controlPressed: boolean): string[] => {
		let newSelection: string[] = [];
		if (!controlPressed) {
			//control key not pressed
			if (currentSelection.length && currentSelection[0] === clickedItemId) {
				newSelection = [];
			} else {
				newSelection = [clickedItemId];
			}
		} else {
			if (currentSelection.some((id) => id === clickedItemId)) {
				newSelection = currentSelection.filter((id) => id !== clickedItemId);
			} else {
				newSelection = [...currentSelection, clickedItemId];
			}
		}
		return newSelection;
	},
	createNewPreviousSelection: (
		currentSelection: {
			tripId: string;
			prevTripId?: string;
		}[],
		tripId: string,
		controlPressed: boolean,
		prevTripId?: string
	): {
		tripId: string;
		prevTripId?: string;
	}[] => {
		let newSelection: {
			tripId: string;
			prevTripId?: string;
		}[] = [];
		if (!controlPressed) {
			//control key not pressed
			if (currentSelection.length && currentSelection[0].tripId === tripId) {
				newSelection = [];
			} else {
				newSelection = [{ tripId, prevTripId }];
			}
		} else {
			if (currentSelection.some((s) => s.tripId === tripId)) {
				newSelection = currentSelection.filter((s) => s.tripId !== tripId);
			} else {
				newSelection = [...currentSelection, { tripId, prevTripId }];
			}
		}
		return newSelection;
	},
	getPreviousTripsIds: (
		currentSelection: {
			tripId: string;
			prevTripId?: string;
		}[]
	): string[] => currentSelection.filter((x) => x.prevTripId).map((x) => x.prevTripId),
};

export default SelectionHelper;
