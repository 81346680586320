import { useEffect, useState } from 'react';
import ajaxUtil from 'utils/Ajax';

import * as GlobalSettings from '../../../GlobalSettings.json';
import { EntityModel, TrackedEntityModel } from '../HistoryTabs/types';

const useFetchTrackedEntities = (entities: EntityModel[]) => {
	const [trackedEntities, setTrackedEntities] = useState<TrackedEntityModel[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	useEffect(() => {
		const fetchTrackedEntities = async () => {
			if (!entities) {
				return;
			}

			const data = await ajaxUtil.post<TrackedEntityModel[]>(
				`${GlobalSettings.listsApi}/getTrackedEntities`,
				entities
			);
			setIsLoading(false);
			setTrackedEntities(data.sort((a, b) => a.entityName.localeCompare(b.entityName)));
		};

		fetchTrackedEntities();
	}, [entities]);

	return { trackedEntities, isLoading: isLoading };
};

export { useFetchTrackedEntities };
