import '../Widgets/LogWidget/LogWidget.scss';
import './styles.scss';

import { Box } from '@material-ui/core';
import { ReactComponent as EasyTrackLogoTopBar } from 'assets/icons/EasyTrackLogoTopBar.svg';
import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { LogsGrid } from 'components/Widgets/LogWidget/LogsGrid';
import * as GlobalSettings from 'GlobalSettings.json';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';
import { capitalize } from 'utils/TreeSelection/TextUtils';
import { ParamsType } from './types';

const DeviceLogsOverview = (): JSX.Element => {
	const { type, entityId }: ParamsType = useParams();
	const [entityType, setEntityType] = useState<EntityTypeEnum>(
		EntityTypeEnum[capitalize(type) as keyof typeof EntityTypeEnum]
	);

	const user = useSelector((s: ApplicationState) => s.oidc.user);

	useEffect(() => {
		if (!type || !entityId) {
			return;
		}

		setEntityType(EntityTypeEnum[capitalize(type) as keyof typeof EntityTypeEnum]);
	}, [type, entityId]);

	const getMaintenanceUrl = (entityType: EntityTypeEnum) => {
		switch (entityType) {
			case EntityTypeEnum.Object:
				return GlobalSettings.objectsMaintenanceApi;
			case EntityTypeEnum.Device:
				return GlobalSettings.devicesMaintenanceApi;
			default:
				throw Error('Invalid entity type');
		}
	};

	if (type === 'object' && !ClaimUtil.validateHasClaim(user, ClaimType.Objects)) {
		return <Redirect to={'/unauthorized'} />;
	}

	return (
		<Box className={'device-logs-page-container'}>
			<Box className={'help-radio-button-container'}>
				<EasyTrackLogoTopBar />
			</Box>
			<Box id={'deviceMessagesLog'} height={'100vh'} padding={'50px 10px 0 10px'}>
				<Box className={'device-logs-header-title'}>{TranslateText('common.deviceMessagesLog')}</Box>
				{entityType && (
					<LogsGrid
						entityId={entityId}
						entityType={entityType}
						latestLogsDayUrl={`${getMaintenanceUrl(entityType)}/LatestLogsDay/${entityId}`}
					/>
				)}
			</Box>
		</Box>
	);
};

export default DeviceLogsOverview;
