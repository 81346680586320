export interface ReportPeriodType {
	value: string;
	periodLength: number;

	[key: string]: string | number;
}

const ReportPeriodsEnum = {
	Today: { value: 'today', periodLength: 1 } as ReportPeriodType,
	Yesterday: { value: 'yesterday', periodLength: 1 } as ReportPeriodType,
	Last2Days: { value: 'last2days', periodLength: 2 } as ReportPeriodType,
	Last3Days: { value: 'last3days', periodLength: 3 } as ReportPeriodType,
	Last4Days: { value: 'last4days', periodLength: 4 } as ReportPeriodType,
	Last5Days: { value: 'last5days', periodLength: 5 } as ReportPeriodType,
	Last6Days: { value: 'last6days', periodLength: 6 } as ReportPeriodType,
	ThisWeek: { value: 'thisweek', periodLength: 7 } as ReportPeriodType,
	LastWeek: { value: 'lastweek', periodLength: 7 } as ReportPeriodType,
	ThisMonth: { value: 'thismonth', periodLength: 28 } as ReportPeriodType,
	LastMonth: { value: 'lastmonth', periodLength: 28 } as ReportPeriodType,
	ThisQuarter: { value: 'thisquarter', periodLength: 90 } as ReportPeriodType,
	LastQuarter: { value: 'lastquarter', periodLength: 90 } as ReportPeriodType,
	CustomPeriod: { value: 'customperiod', periodLength: 0 } as ReportPeriodType,

	GetReportPeriodTypeByValue(value: string): ReportPeriodType {
		switch (value?.toLowerCase()) {
			case this.Today.value: {
				return this.Today;
			}
			case this.Yesterday.value: {
				return this.Yesterday;
			}
			case this.Last2Days.value: {
				return this.Last2Days;
			}
			case this.Last3Days.value: {
				return this.Last3Days;
			}
			case this.Last4Days.value: {
				return this.Last4Days;
			}
			case this.Last5Days.value: {
				return this.Last5Days;
			}
			case this.Last6Days.value: {
				return this.Last6Days;
			}
			case this.ThisWeek.value: {
				return this.ThisWeek;
			}
			case this.LastWeek.value: {
				return this.LastWeek;
			}
			case this.ThisMonth.value: {
				return this.ThisMonth;
			}
			case this.LastMonth.value: {
				return this.LastMonth;
			}
			case this.ThisQuarter.value: {
				return this.ThisQuarter;
			}
			case this.LastQuarter.value: {
				return this.LastQuarter;
			}
			case this.CustomPeriod.value: {
				return this.CustomPeriod;
			}
			default: {
				return null;
			}
		}
	},
};

export default ReportPeriodsEnum;
