import './styles.scss';

import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import IntegrationDto, {
	IntegrationAfasMetadata,
	IntegrationEasyTrackMetadata,
	IntegrationHereOnTrackMetadata,
} from 'models/IntegrationDto';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { TranslateText, TranslateWithFailText } from 'utils/Translations';

import IntegrationContacts from './IntegrationContacts';
import IntegrationInformation from './IntegrationInformation';
import IntegrationType from './IntegrationType';
import { IntegrationConstants, IntegrationValidationConfig } from './Utils';

const IntegrationsView = (props: AddWizardComponentProps) => {
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);

	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const [integration, setIntegration] = useState<IntegrationDto>(() => new IntegrationDto(defaultCustomerId));

	const [validator] = useState<Validator>(() => {
		return new Validator(
			{
				fieldRules: IntegrationValidationConfig(true),
			},
			true
		);
	});

	useEffect(() => {
		validator.validate(integration).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(integration);
			}
		});
	}, [integration]);

	const handleValueChangeMaterial = (
		value: IntegrationDto[keyof IntegrationDto],
		statePropName: keyof IntegrationDto
	) => {
		const newIntegration = { ...integration, [statePropName]: value };

		if (statePropName === 'integrationType') {
			if (newIntegration.integrationType === IntegrationTypeEnum.Afas) {
				newIntegration.integrationAfasMetadata = new IntegrationAfasMetadata(
					newIntegration.customerId,
					IntegrationConstants.chronExpressionAfas
				);
				newIntegration.integrationHereOnTrackMetadata = null;
			} else if (newIntegration.integrationType === IntegrationTypeEnum.HereOnTrack) {
				newIntegration.integrationHereOnTrackMetadata = new IntegrationHereOnTrackMetadata(
					newIntegration.customerId,
					IntegrationConstants.hereOnTrackRefreshIntervalMinutesDefault
				);
				newIntegration.integrationAfasMetadata = null;
			}
			switch (newIntegration.integrationType) {
				case IntegrationTypeEnum.Afas: {
					newIntegration.integrationAfasMetadata = new IntegrationAfasMetadata(
						newIntegration.customerId,
						IntegrationConstants.chronExpressionAfas
					);
					newIntegration.integrationHereOnTrackMetadata = null;
					newIntegration.integrationEasyTrackMetadata = null;
					break;
				}
				case IntegrationTypeEnum.HereOnTrack: {
					newIntegration.integrationHereOnTrackMetadata = new IntegrationHereOnTrackMetadata(
						newIntegration.customerId,
						IntegrationConstants.hereOnTrackRefreshIntervalMinutesDefault
					);
					newIntegration.integrationAfasMetadata = null;
					newIntegration.integrationEasyTrackMetadata = null;
					break;
				}
				case IntegrationTypeEnum.EasyTrack: {
					newIntegration.integrationEasyTrackMetadata = new IntegrationEasyTrackMetadata(
						newIntegration.customerId
					);
					newIntegration.integrationAfasMetadata = null;
					newIntegration.integrationHereOnTrackMetadata = null;
					break;
				}
			}
		}

		setUnsavedData(true);
		setIntegration(newIntegration);
	};

	const translateIntegrationType = (integrationType: IntegrationTypeEnum) => {
		if (integrationType in IntegrationTypeEnum) {
			return TranslateWithFailText(
				`integrations.integrationTypeEnum.${IntegrationTypeEnum[integration.integrationType]}`,
				IntegrationTypeEnum[integration.integrationType]
			);
		}
		return '';
	};

	return (
		<div className={'integration-view-container'}>
			<div className={'integration-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.systemInformation')}</div>
					<form id="integrationViewSystemInformationForm" noValidate={true}>
						<IntegrationInformation
							integration={integration}
							customerId={integration.customerId}
							validationResult={validationResult}
							handleValueChangeMaterial={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
			<div className={'integration-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.contacts')}</div>
					<form id="integrationContactsForm" noValidate={true}>
						<IntegrationContacts
							integration={integration}
							validationResult={validationResult}
							handleValueChangeMaterial={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
			<div className={'integration-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>{translateIntegrationType(integration.integrationType)}</div>
					<form id="integrationContactsForm" noValidate={true}>
						<IntegrationType
							integrationType={integration.integrationType}
							integrationAfasMetadata={integration.integrationAfasMetadata}
							integrationHereOnTrackMetadata={integration.integrationHereOnTrackMetadata}
							integrationEasyTrackMetadata={integration.integrationEasyTrackMetadata}
							validationResult={validationResult}
							handleValueChangeMaterial={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default IntegrationsView;
