import './styles.scss';

import { HistoryTripStatus } from 'components/HistoryTripStatus';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import { TableCell, TableRow } from '@material-ui/core';

import { getShortTimeZoneFormat, shouldNotDisplayTimeZone } from '../ObjectNameWithTimeZoneOffset/Utils';
import { privateDataMask } from '../RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/SummaryTabTrip/SummaryTabTrip';
import { useTripInfo } from './hooks';
import { HistoryTripRowProps } from './types';

const HistoryTripRow = (props: HistoryTripRowProps): JSX.Element => {
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer?.featuresSettings
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const defaultCustomerTrackTypeSpecification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.trackTypeSpecification
			: state.currentSession.customer?.featuresSettings?.trackTypeSpecification
	);
	const { trip } = useTripInfo(props.trip);
	const customerTimezoneId = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	return (
		<TableRow
			id={props.id}
			onDoubleClick={props.onDoubleClick}
			key={trip.id}
			className={`data-trip-row ${trip.isInProgress ? 'trip-in-progress-row' : ''}`}
		>
			<TableCell className={`status-cell ${trip.isInProgress ? 'in-progress' : 'trip-complete'}`}>
				<HistoryTripStatus trip={trip} />
			</TableCell>
			<TableCell>
				{trip.startTime !== privateDataMask && !shouldNotDisplayTimeZone(customerTimezoneId, trip.timezoneId)
					? trip.startTime + ' ' + getShortTimeZoneFormat(trip.timeZoneMinutesOffset)
					: trip.startTime}
			</TableCell>
			<TableCell>
				{trip.endTime !== privateDataMask &&
				!trip.isInProgress &&
				!shouldNotDisplayTimeZone(customerTimezoneId, trip.timezoneId)
					? trip.endTime + ' ' + getShortTimeZoneFormat(trip.timeZoneMinutesOffset)
					: trip.endTime}
			</TableCell>
			<TableCell>{trip.objectName}</TableCell>
			{!!driverIdentification && <TableCell className={'table-cell-word-wrap'}>{trip.personName}</TableCell>}
			<TableCell>{trip.startAddress}</TableCell>
			<TableCell>{trip.endAddress}</TableCell>
			{!!defaultCustomerTrackTypeSpecification && <TableCell>{trip.trackType}</TableCell>}
			<TableCell>{trip.drivingTime}</TableCell>
			<TableCell>{trip.visitTime}</TableCell>
			<TableCell>{trip.startMileage}</TableCell>
			<TableCell>{trip.endMileage}</TableCell>
			<TableCell className={trip.isInProgress ? 'trip-in-progress' : ''}>{trip.distance}</TableCell>
			<TableCell>{trip.maxSpeed}</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};
export default HistoryTripRow;
