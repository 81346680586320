import GlobalSettings from 'GlobalSettings.json';
import SensorTypeEnum from 'models/SensorTypeEnum';
import { useRef, useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import ajaxUtil from 'utils/Ajax';

import { TemplateListItem } from '../Widgets/DeviceTemplateWidget/DeviceTemplateWidget';
import { DateValidationRequest } from './types';

const useDurationCorrectionDialog = (entityId: string) => {
	const [templatesData, setTemplatesData] = useState<TemplateListItem[]>([]);

	const queryClient: QueryClient = useQueryClient();

	const { mutateAsync: checkDate } = useMutation(
		(data: DateValidationRequest) => {
			queryClient.setQueryData(['correction', 'is-date-unique'], true);
			const url = `${GlobalSettings.objectsMaintenanceApi}/uniqueTemplateDurationCorrectionDate/${entityId}`;
			return ajaxUtil.post<boolean>(url, data);
		},
		{
			onSettled: () => {
				queryClient.setQueryData(['correction', 'is-date-unique'], false);
			},
			onSuccess: (data: boolean) => {
				return new Promise((resolve) => resolve(data));
			},
			onError: (err) => {
				console.error(err);
				return new Promise((resolve) => resolve(false));
			},
		}
	);

	useQuery(['corrections', 'templates-data'], () => {
		const url = `${GlobalSettings.sensorTemplatesMaintenanceApi}/templatesForDuration/${entityId}`;
		ajaxUtil.get<TemplateListItem[]>(url).then((data: TemplateListItem[]) => {
			setTemplatesData(data);
		});
	});

	const isDateUnique = async (templateId: string, sensorType: SensorTypeEnum, date: Date) => {
		return checkDate({ templateId, sensorType, date });
	};
	const isDateUniqueRef = useRef(isDateUnique);
	isDateUniqueRef.current = isDateUnique;

	return { isDateUniqueRef, templatesData };
};

export { useDurationCorrectionDialog };
