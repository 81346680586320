import './locationsAreaMap.scss';

import { LocationAreaTypeEnum } from 'components/LocationsOverview/LocationsOverview';
import RemovablePoint from 'components/RemovablePoint';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import { SvgBlobIcon } from 'models/BlobIcon';
import Location from 'models/Location';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { ValidationResult } from 'shared/validation/interfaces';
import { ApplicationState } from 'store';
import { googleMapLibraries, googleMapScriptId, mapStartCoordinates } from 'utils/MapUtils';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';
import { Circle, GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';

import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import { getDefaultPolygonPath, getMaxDistanceFromPoint, useGetIconPath, usePolygon } from './hooks';
import { PolygonDefinition } from './types';

interface Props {
	onChangeCallback: (location: Location) => void;
	validationResult?: ValidationResult;
	location: Location;
	initialPolygon?: PolygonDefinition;
	readOnly?: boolean;
	icon: SvgBlobIcon;
	isForDialog: boolean;
}

const MAX_RADIUS = 5000;
const MIN_RADIUS = 1;

const shapeIsSmallForZoom = (mapBounds: google.maps.LatLngBounds, radius: number): boolean => {
	if (mapBounds && radius) {
		const distance = google.maps.geometry.spherical.computeDistanceBetween(
			mapBounds.getCenter(),
			mapBounds.getNorthEast()
		);

		return (100 * radius) / distance < 17;
	}
	return false;
};

const LocationsAreaMap = (props: Props) => {
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);
	const startCountryBoundaries = useSelector((s: ApplicationState) => s.currentSession.startCountryBoundaries);
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [pinArea, setPinArea] = useState<google.maps.Circle | null>(null);
	const [radiusKey, setRadiusKey] = useState('0');

	const [areaTypeOptions] = useState(() => {
		return [
			{
				id: LocationAreaTypeEnum.Circle,
				display: TranslateText('locationAreaTypeEnum.Circle'),
			},
			{
				id: LocationAreaTypeEnum.Polygon,
				display: TranslateText('locationAreaTypeEnum.Polygon'),
			},
		];
	});

	const { isLoaded } = useJsApiLoader({
		id: googleMapScriptId,
		mapIds: [GlobalSettings.googleMapId],
		libraries: googleMapLibraries,
		version: '3',
		googleMapsClientId: GlobalSettings.googleMapId,
		googleMapsApiKey: GlobalSettings.googleMapsApiKey,
		language: loginInLanguage,
	});

	const iconPath = useGetIconPath(props.location);

	const { polygonPath, initPolygonPath, resetPolygon, showRemovePopup, removePoint, setRemovePoint } = usePolygon(
		isLoaded,
		props.location,
		props.onChangeCallback,
		props.initialPolygon
	);

	useEffect(() => {
		if (map) {
			if (
				startCountryBoundaries[0].lat &&
				startCountryBoundaries[0].lng &&
				startCountryBoundaries[1].lat &&
				startCountryBoundaries[1].lng
			) {
				map.fitBounds(new google.maps.LatLngBounds(startCountryBoundaries[0], startCountryBoundaries[1]));
			} else {
				map.fitBounds(new google.maps.LatLngBounds(mapStartCoordinates[0], mapStartCoordinates[1]));
			}
		}
	}, [map]);

	useEffect(() => {
		if (map) {
			if (props.location.areaType === LocationAreaTypeEnum.Circle && pinArea) {
				const mapBounds = map.getBounds();
				const pinAreaBounds = pinArea.getBounds();
				if (
					!mapBounds ||
					!mapBounds.contains(pinAreaBounds.getNorthEast()) ||
					!mapBounds.contains(pinAreaBounds.getSouthWest()) ||
					shapeIsSmallForZoom(mapBounds, props.location.radius)
				) {
					map.fitBounds(pinAreaBounds);

					// used to redraw map if the circle is blurred by zooming out #26478
					setTimeout(() => {
						map?.setZoom(map.getZoom() - 1);
					}, 0);
				}
			} else if (props.location.areaType === LocationAreaTypeEnum.Polygon && polygonPath?.polygon) {
				const pathPoints = polygonPath.polygon.getArray();
				if (pathPoints.length) {
					const mapBounds = map.getBounds();
					if (
						!mapBounds ||
						pathPoints.some((x) => !mapBounds.contains(x)) ||
						shapeIsSmallForZoom(mapBounds, props.location.radius)
					) {
						const bounds = new google.maps.LatLngBounds();
						pathPoints.forEach((point) => {
							bounds.extend(point);
						});
						map.fitBounds(bounds);

						// used to redraw map if the polygon is blurred by zooming out #26478
						setTimeout(() => {
							map?.setZoom(map.getZoom() - 1);
						}, 0);
					}
				}
			}
		}
	}, [map, pinArea, polygonPath, props.location]);

	const handleValueChange = (value: any, statePropName: string) => {
		const newLocation = { ...props.location };
		newLocation[statePropName] = value;

		if (statePropName === 'radius') {
			if (value > MAX_RADIUS) {
				value = MAX_RADIUS;
				newLocation[statePropName] = value;
				pinArea.setRadius(MAX_RADIUS);
			} else if (value < MIN_RADIUS) {
				value = MIN_RADIUS;
				newLocation[statePropName] = value;
				pinArea.setRadius(MIN_RADIUS);
			}
		} else if (statePropName === 'areaType') {
			newLocation.polygonPath = [];
			newLocation.radius = 50;

			if (newLocation.areaType === LocationAreaTypeEnum.Polygon) {
				if (newLocation.latitude && newLocation.longitude) {
					const pathPoints = getDefaultPolygonPath(
						newLocation.latitude,
						newLocation.longitude,
						props.initialPolygon
					);
					pathPoints.forEach((pathPoint) => {
						newLocation.polygonPath.push({ lat: pathPoint.lat(), lng: pathPoint.lng() });
					});

					newLocation.radius = getMaxDistanceFromPoint(
						newLocation.latitude,
						newLocation.longitude,
						pathPoints
					);

					initPolygonPath(pathPoints);
				}
			}
		}

		props.onChangeCallback(newLocation);
	};

	const placeLocationMarker = (e: google.maps.MapMouseEvent) => {
		props.onChangeCallback({ ...props.location, latitude: e.latLng.lat(), longitude: e.latLng.lng() });
	};

	return (
		<div className={'address-map-container'}>
			<div className={'location-area-map'} style={{ marginLeft: 20, height: 380, width: props.isForDialog ? 400 : 550 }}>
				{isLoaded && (
					<GoogleMap
						mapContainerStyle={{
							width: '100%',
							height: '100%',
						}}
						onLoad={setMap}
						options={
							{
								mapId: GlobalSettings.googleMapId,
								clickableIcons: false,
								gestureHandling: 'greedy',
								disableDefaultUI: true,
								disableDoubleClickZoom: true,
								zoomControl: true,
								mapTypeControl: true,
								streetViewControl: true,
							} as google.maps.MapOptions
						}
						onDblClick={!props.readOnly ? placeLocationMarker : undefined}
						onClick={() => {
							setRemovePoint(null);
						}}
					>
						{props.location && props.location.latitude && props.location.longitude ? (
							<>
								<Marker
									position={{ lat: props.location?.latitude, lng: props.location?.longitude }}
									draggable={!props.readOnly}
									onDragEnd={!props.readOnly ? placeLocationMarker : undefined}
									key={props.icon.name}
									icon={{
										path: iconPath,
										fillColor: props.location?.iconColor,
										fillOpacity: 1,
										strokeOpacity: 0,
										strokeWeight: 0,
										scale: 0.75,
										anchor: new google.maps.Point(24, 44),
									}}
								/>

								{props.location.areaType === LocationAreaTypeEnum.Circle ? (
									<Circle
										onRadiusChanged={() => {
											const radius = Math.floor(pinArea?.getRadius());
											if (pinArea && radius !== props.location?.radius) {
												handleValueChange(radius, 'radius');
											}
										}}
										editable={!props.readOnly}
										onCenterChanged={() => {
											if (
												pinArea &&
												(pinArea.getCenter().lat() !== props.location.latitude ||
													pinArea.getCenter().lng() !== props.location.longitude)
											) {
												props.onChangeCallback({
													...props.location,
													latitude: pinArea.getCenter().lat(),
													longitude: pinArea.getCenter().lng(),
												});
											}
										}}
										center={{ lat: props.location?.latitude, lng: props.location?.longitude }}
										radius={props.location.radius}
										options={{
											fillOpacity: 0.35,
											fillColor: props.location?.iconColor,
											strokeColor: props.location?.iconColor,
											strokeOpacity: 0.8,
											strokeWeight: 2,
										}}
										onLoad={(c) => {
											c?.set('suppressUndo', true);
											setPinArea(c);
										}}
									/>
								) : props.location.areaType === LocationAreaTypeEnum.Polygon && polygonPath?.polygon ? (
									<>
										{removePoint ? (
											<RemovablePoint
												lat={removePoint.lat}
												lng={removePoint.lng}
												onRemove={() => {
													if (polygonPath?.polygon) {
														const points = polygonPath.polygon.getArray();
														if (
															removePoint.vertex < points.length &&
															points.length > 3 &&
															points[removePoint.vertex].lat() === removePoint.lat &&
															points[removePoint.vertex].lng() === removePoint.lng
														) {
															setRemovePoint(null);
															polygonPath.polygon.removeAt(removePoint.vertex);
														}
													}
												}}
											/>
										) : null}

										<Polygon
											path={polygonPath.polygon}
											editable
											onLoad={(e) => {
												e?.set('suppressUndo', true);
											}}
											options={{
												fillOpacity: 0.35,
												fillColor: props.location?.iconColor,
												strokeColor: props.location?.iconColor,
												strokeOpacity: 0.8,
												strokeWeight: 2,
											}}
											onMouseUp={(e: google.maps.PolyMouseEvent) => {
												if (showRemovePopup.current && (e?.vertex || e?.vertex === 0)) {
													const path = polygonPath.polygon.getArray();
													if (path.length > 3) {
														const coord = path[e.vertex];
														setRemovePoint({
															lat: coord.lat(),
															lng: coord.lng(),
															vertex: e.vertex,
														});
													}
												}
												showRemovePopup.current = false;
											}}
											onMouseDown={() => {
												showRemovePopup.current = true;
											}}
										/>
									</>
								) : null}
							</>
						) : null}
					</GoogleMap>
				)}
			</div>
			<div className={`location-area-fields-container ${props.isForDialog ? 'form-dialog-limit' : ''}`}>
				<div className={`form-group`} style={props.isForDialog ? {minWidth: 100} : {}}>
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={props.location.areaType}
						dataSource={areaTypeOptions}
						disableClearable={true}
						name="areaType"
						disabled={props.readOnly}
						label={TranslateText('fields.areaDefinition')}
						onChange={({ value }) => handleValueChange(value, 'areaType')}
						validationResult={props.validationResult?.areaType}
						className={`${props.isForDialog ? 'field-dialog-limit' : ''}`}
					/>
				</div>
				{props.location.areaType === LocationAreaTypeEnum.Circle ? (
					<div className={`form-group`} style={props.isForDialog ? {minWidth: 100} : {}}>
						<MaterialTextField
							key={radiusKey}
							id={'location-area-radius'}
							disabled={props.readOnly}
							label={TranslateText('fields.radiusInMeters')}
							name="radius"
							value={props.location.radius}
							type={'number'}
							inputProps={{ min: MIN_RADIUS, max: MAX_RADIUS, style: { fontSize: 10 } }}
							handleValueChange={(value) => {
								setRadiusKey(Math.random().toString());
								handleValueChange(value ? parseInt(value) : 0, 'radius');
							}}
							isForNewAddWizard={true}
							validationResult={props.validationResult?.radius}
							className={`${props.isForDialog ? 'field-dialog-limit' : ''}`}
						/>
					</div>
				) : null}
				{props.location.areaType === LocationAreaTypeEnum.Polygon ? (
					<>
						<div className={`form-group`} style={props.isForDialog ? {minWidth: 100} : {}}>
							<ValidationMessage result={props.validationResult?.polygon} isForNewAddWizard={true} className={`${props.isForDialog ? 'field-dialog-limit' : ''}`} />
						</div>
						<div className="form-group" style={props.isForDialog ? {minWidth: 100} : {}}>
							<Button
								disabled={!polygonPath?.hasChanges}
								className={`reset-polygon`}
								style={props.isForDialog ? {minWidth: 100} : {}}
								onClick={() => resetPolygon()}
							>
								{TranslateText('common.reset')}
							</Button>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};

export default LocationsAreaMap;
