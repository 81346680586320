import MessageWrapper from "./MessageWrapper";

class AlertTemplate {
	constructor() {
		this.notificationTemplate = new MessageWrapper();
		this.popupTemplate = new MessageWrapper();
		this.mailTemplate = new MessageWrapper();
		this.smsTemplate = new MessageWrapper();
		this.whatsAppTemplate= new MessageWrapper();
	}
	public notificationTemplate: MessageWrapper;
	public popupTemplate: MessageWrapper;
	public mailTemplate: MessageWrapper;
	public smsTemplate: MessageWrapper;
	public whatsAppTemplate: MessageWrapper;
}
export default AlertTemplate;
