import 'moment/min/locales';

import moment from 'moment';

import { getCurrentSession } from '../../index';
import { CustomerDateTimeState } from '../../store/index';
import { TranslateText } from '../../utils/Translations';

moment.locale(window.navigator.language ?? 'en-US');

const substringCounter = (str: string, substr: string) => (str.match(new RegExp(substr, 'g')) || []).length;
const replaceAll = (str: string, searchSubstr: string, replaceSubstr: string) =>
	str.split(searchSubstr).join(replaceSubstr);

export const DateTimeConversionUtil = {
	/**
	 * Changes the date format from Moment.Js format to Syncfusion as it does not support '.' and Uppercase on years and days.
	 * In Moment each small d represents a letter of day's name
	 */
	momentToSyncFusionDateFormat: (dateFormat: string): string =>
		replaceAll(replaceAll(replaceAll(replaceAll(dateFormat, '.', '-'), '/', '-'), 'D', 'd'), 'Y', 'y'),

	/**
	 * Changes the date format from Moment.Js format to Syncfusion as it does not support Uppercase on minutes and secounds.
	 *  Also moment.js returns single H for hour instead of HH and all upper case
	 */
	momentToSyncFusionTimeFormat: (timeFormat: string): string => {
		timeFormat = replaceAll(timeFormat, 'S', 's').replace('A', '');

		if (!substringCounter(timeFormat, 'HH')) {
			if (substringCounter(timeFormat, 'H')) {
				timeFormat = timeFormat.replace('H', 'HH');
			}
		}
		if (!substringCounter(timeFormat, 'hh')) {
			if (substringCounter(timeFormat, 'h')) {
				timeFormat = timeFormat.replace('h', 'hh');
				if (!substringCounter(timeFormat, 'a')) {
					timeFormat += 'a';
				}
			}
		}
		return timeFormat;
	},

	syncFusionToMomentDateFormat: (dateFormat: string, dateOnly = false): string => {
		if (dateOnly) {
			return replaceAll(replaceAll(dateFormat, 'd', 'D'), 'y', 'Y').replace('EEEE', 'dddd');
		}
		return replaceAll(replaceAll(dateFormat, 'd', 'D'), 'y', 'Y');
	},

	/** Moment.Js understands both H and HH (for 24h) and h and hh(for 12h) so a back conversion is not needed */
	syncFusionToMomentTimeFormat: (timeFormat: string): string => timeFormat.replace('a', 'A'),
	syncFusionToMomentTimeFormatHoursMinutes: (timeFormat: string): string =>
		timeFormat.replace('a', 'A').replace(':ss', ''),
};

export interface TimeCounting {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

const DateTimeUtil = {
	setMomentLocale: (locale: string, startingDay?: number): void => {
		moment.updateLocale(
			locale,
			startingDay !== null && startingDay !== undefined ? { week: { dow: startingDay } } : {}
		);
	},

	dateTimeSettings: (): CustomerDateTimeState => {
		const currentSession = getCurrentSession();
		return { ...currentSession.dateTimeSettings };
	},
	startingDay: () => {
		const currentSession = getCurrentSession();
		return currentSession?.dateTimeSettings?.startingDay ?? 0;
	},
	/** Returns Local date format - eg DD/MM/YYYY */
	momentLocalDateFormat: (): string => moment.localeData().longDateFormat('L'),
	/** Returns local time format - eg h:mm:ss A */
	momentLocalTimeFormat: (): string => moment.localeData().longDateFormat('LTS'),

	localDateFormat: (): string =>
		DateTimeConversionUtil.momentToSyncFusionDateFormat(DateTimeUtil.momentLocalDateFormat()),
	localTimeFormat: (): string =>
		DateTimeConversionUtil.momentToSyncFusionTimeFormat(DateTimeUtil.momentLocalTimeFormat()),
	localDateTimeFormat: () => {
		return `${DateTimeUtil.localDateFormat()} ${DateTimeUtil.localTimeFormat()}`;
	},

	dateFormat: () => {
		const useLocalSettings = DateTimeUtil.dateTimeSettings()?.useLocalSettings ?? true;
		const dateFormat = DateTimeUtil.dateTimeSettings()?.dateFormat;
		return useLocalSettings || !dateFormat ? DateTimeUtil.localDateFormat() : dateFormat;
	},
	timeFormat: (removeSeconds = false) => {
		const useLocalSettings = DateTimeUtil.dateTimeSettings()?.useLocalSettings ?? true;
		const timeFormat = DateTimeUtil.dateTimeSettings()?.timeFormat;
		const result = useLocalSettings || !timeFormat ? DateTimeUtil.localTimeFormat() : timeFormat;

		return removeSeconds ? result.replace(':ss', '').replace(':SS', '') : result;
	},
	dateTimeFormat: () => {
		return `${DateTimeUtil.dateFormat()} ${DateTimeUtil.timeFormat()}`;
	},
	dateTimeRemoveSeconds: () => {
		return `${DateTimeUtil.dateFormat()} ${DateTimeUtil.timeFormat(true)}`;
	},
	momentDateFormat: (): string => {
		return DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true);
	},
	momentTimeFormat: (): string => {
		return DateTimeConversionUtil.syncFusionToMomentTimeFormat(DateTimeUtil.timeFormat());
	},
	momentTimeFormatHoursMinutes: (): string => {
		return DateTimeConversionUtil.syncFusionToMomentTimeFormatHoursMinutes(DateTimeUtil.timeFormat());
	},
	momentDateTimeFormatNoSeconds: (): string => {
		return `${DateTimeUtil.momentDateFormat().replace('EEEE', 'dddd')} ${DateTimeUtil.momentTimeFormat()
			.replace(':ss', '')
			.replace(':SS', '')}`;
	},
	momentDateTimeFormat: (): string => {
		return `${DateTimeUtil.momentDateFormat().replace('EEEE', 'dddd')} ${DateTimeUtil.momentTimeFormat()}`;
	},

	calculateTimePassed: (referenceDate: Date | number, currentDate: Date = new Date(), showSeconds = true) => {
		let timeLeft: TimeCounting;
		let timeCountingString = '';

		let difference: number;
		if (typeof referenceDate === 'number') {
			difference = referenceDate * 1000;
			if (difference < 1) {
				return `< 1${TranslateText('common.minuteAbbreviation')}`;
			}
		} else {
			difference = +currentDate - +referenceDate;
		}
		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
			if (timeLeft.days > 0) {
				if (timeLeft.days === 1) {
					timeCountingString += timeLeft.days + ` ${TranslateText('common.dayLowerCase')} `;
				} else {
					timeCountingString += timeLeft.days + ` ${TranslateText('common.daysLowerCase')} `;
				}
			}
			if (timeLeft.days > 0 || timeLeft.hours > 0) {
				timeCountingString += timeLeft.hours + TranslateText('common.hourAbbreviation') + ' ';
			}
			if (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) {
				timeCountingString += timeLeft.minutes + TranslateText('common.minuteAbbreviation');
			}
			if (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) {
				if (showSeconds) {
					timeCountingString += timeLeft.seconds + TranslateText('common.secondAbbreviation');
				} else if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0) {
					timeCountingString = `< 1${TranslateText('common.minuteAbbreviation')}`;
				}
			}
			if (timeCountingString === '') {
				if (showSeconds) {
					timeCountingString = `1${TranslateText('common.secondAbbreviation')}`;
				} else {
					timeCountingString = `< 1${TranslateText('common.minuteAbbreviation')}`;
				}
			}
			return timeCountingString;
		}
	},
	hoursAndMinutesNonZero: (totalSeconds: number) => {
		const totalMinutes = Math.round(totalSeconds / 60);
		if (totalMinutes < 1) {
			return `<1${TranslateText('common.minuteAbbreviation')}`;
		}

		const minutes = totalMinutes % 60;
		const hours = (totalMinutes - minutes) / 60;

		if (hours > 0) {
			return `${hours}${TranslateText('common.hourAbbreviation')}${minutes}${TranslateText(
				'common.minuteAbbreviation'
			)}`;
		}

		return `${minutes}${TranslateText('common.minuteAbbreviation')}`;
	},
	hoursAndMinutesDuration: (totalSeconds: number) => {
		const totalMinutes = Math.floor(totalSeconds / 60);
		if (totalMinutes < 1) {
			return `0${TranslateText('common.hourAbbreviation')}:1${TranslateText('common.minuteAbbreviation')}`;
		}

		const minutes = totalMinutes % 60;
		const hours = (totalMinutes - minutes) / 60;
		return `${hours}${TranslateText('common.hourAbbreviation')}:${minutes}${TranslateText(
			'common.minuteAbbreviation'
		)}`;
	},
	minutesDuration: (totalSeconds: number) => {
		if (totalSeconds < 60) {
			return `<1${TranslateText('common.minuteAbbreviation')}`;
		}
		return `${Math.floor(totalSeconds / 60)}m`;
	},
	fromMinutesToHoursAndMinutes: (totalMinutes: number = 0): { hours: number; minutes: number } => {
		return {
			hours: Math.floor(totalMinutes / 60),
			minutes: totalMinutes % 60,
		};
	},
};
export default DateTimeUtil;
