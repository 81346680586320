import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {Button, Icon, IconButton} from '@material-ui/core';

import ClaimType, {ClaimValue} from '../../authorization/ClaimType';
import {ClaimUtil} from '../../authorization/ClaimUtil';
import {DEBOUNCE_TIME, noop} from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import GeneratedReportsStatusEnum from '../../models/GeneratedReportsStatusEnum';
import {GridFilters, MatchMode, SortOrder} from '../../models/GridOverview';
import ReportPeriodsEnum from '../../models/ReportPeriodsEnum';
import ReportRenderFormatEnum from '../../models/ReportRenderFormatEnum';
import TemplateData from '../../models/TemplateData';
import {ApplicationState} from '../../store';
import {ReportTemplate} from '../../store/ReportStore';
import ajaxUtil from '../../utils/Ajax';
import {FormatDate} from '../../utils/DateUtils';
import {useToggleWithoutInit} from '../../utils/hooks';
import {TranslateText} from '../../utils/Translations';
import {DebouncedButton} from '../Common/DebouncedButton';
import {DialogUtil} from '../Common/NotificationDialog/NotificationDialog';
import BooleanColumn from '../GridOverview/Columns/BooleanColumn';
import ButtonColumn from '../GridOverview/Columns/ButtonColumn';
import DateColumn from '../GridOverview/Columns/DateColumn';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from '../GridOverview/Columns/MultiSelectionColumn';
import NumberColumn from '../GridOverview/Columns/NumberColumn';
import TextColumn from '../GridOverview/Columns/TextColumn';
import NewGridOverview from '../GridOverview/NewGridOverview';
import {FileDownload} from './FileDownload';
import {GeneratedReportDto} from './GeneratedReportDto';

export function base64ToArrayBuffer(base64: any) {
	const binaryString = window.atob(base64);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i++) {
		const ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
}

const fieldNames = {
	requestedByUserName: 'requestedByUserName',
	oneTimeExecution: 'oneTimeExecution',
	reportName: 'reportName',
	templateCategoryType: 'templateCategoryType',
	outputType: 'outputType',
	selectedGroups: 'selectedGroups',
	selectedEntities: 'selectedEntities',
	customPeriodInterval: 'customPeriodInterval',
	generatedDateTime: 'generatedDateTime',
	status: 'status',
	favorite: 'favorite',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.generatedDateTime,
	sortOrder: SortOrder.Descendent,
	filters: {
		[fieldNames.requestedByUserName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.oneTimeExecution]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.reportName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.templateCategoryType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.outputType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.selectedGroups]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.selectedEntities]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.customPeriodInterval]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.generatedDateTime]: {
			value: null,
			matchMode: MatchMode.DateAfter,
		},
		[fieldNames.status]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.favorite]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

type Props = {
	focus: boolean;
	take: number;
	driverIdentification: boolean;
	resetFiltersTrigger: boolean;
};

const GeneratedReportsOverview = (props: Props) => {
	const userId = useSelector((state: ApplicationState) => state.currentSession.aspNetUserId);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const reportData = useSelector((state: ApplicationState) => state.reportStore);

	const [showReportRemoveDialog, setShowReportRemoveDialog] = useState<boolean>(false);
	const [currentReportId, setCurrentReportId] = useState<string>('');
	const [disableFavorite, setDisableFavorite] = useState<string[]>([]);
	const [disableDownload, setDisableDownload] = useState<string[]>([]);
	const [fetchDataTrigger, toggleFetchDataTrigger] = useToggleWithoutInit();
	const [scrollSize, setScrollSize] = useState(null);
	const [refreshTime, setRefreshTime] = useState<number>(5000);

	const oneTimeExecutionOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: TranslateText('reports.manualGenerated'),
				code: true,
			},
			{
				name: TranslateText('reports.scheduledGenerated'),
				code: false,
			},
		],
		[]
	);

	const outputTypeOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: TranslateText(`reports.formatType.${ReportRenderFormatEnum.pdf}`),
				code: ReportRenderFormatEnum.pdf.toString(),
			},
			{
				name: TranslateText(`reports.formatType.${ReportRenderFormatEnum.xlsx}`),
				code: ReportRenderFormatEnum.xlsx.toString(),
			},
			{
				name: TranslateText(`reports.formatType.${ReportRenderFormatEnum.csv}`),
				code: ReportRenderFormatEnum.csv.toString(),
			},
		],
		[]
	);

	const templateCategoryTypeOptions = useMemo<MultiSelectionColumnOption[]>(
		() =>
			reportData.data?.map((templateCategory: ReportTemplate[]) => {
				return {
					name: TranslateText(templateCategory[0].category),
					code: templateCategory[0].categoryId.toString(),
				};
			}) ?? [],
		[reportData.data]
	);

	const customPeriodIntervalOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				code: ReportPeriodsEnum.Today.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Today.value),
			},
			{
				code: ReportPeriodsEnum.Yesterday.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Yesterday.value),
			},
			{
				code: ReportPeriodsEnum.Last2Days.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Last2Days.value),
			},
			{
				code: ReportPeriodsEnum.Last3Days.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Last3Days.value),
			},
			{
				code: ReportPeriodsEnum.Last4Days.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Last4Days.value),
			},
			{
				code: ReportPeriodsEnum.Last5Days.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Last5Days.value),
			},
			{
				code: ReportPeriodsEnum.Last6Days.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.Last6Days.value),
			},
			{
				code: ReportPeriodsEnum.ThisWeek.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisWeek.value),
			},
			{
				code: ReportPeriodsEnum.LastWeek.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.LastWeek.value),
			},
			{
				code: ReportPeriodsEnum.ThisMonth.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisMonth.value),
			},
			{
				code: ReportPeriodsEnum.LastMonth.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.LastMonth.value),
			},
			{
				code: ReportPeriodsEnum.ThisQuarter.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisQuarter.value),
			},
			{
				code: ReportPeriodsEnum.LastQuarter.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.LastQuarter.value),
			},
			{
				code: ReportPeriodsEnum.CustomPeriod.value,
				name: TranslateText('reports.periods.' + ReportPeriodsEnum.CustomPeriod.value),
			},
		],
		[]
	);

	const statusOptions = useMemo<MultiSelectionColumnOption[]>(
		() => [
			{
				name: TranslateText('common.inProgress'),
				code: GeneratedReportsStatusEnum.InProgress,
			},
			{
				name: TranslateText('common.complete'),
				code: GeneratedReportsStatusEnum.Completed,
			},
			{
				name: TranslateText('common.error'),
				code: GeneratedReportsStatusEnum.Error,
			},
		],
		[]
	);

	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.status]: computeFlagOrderingBasedOnTranslation(statusOptions),
			[fieldNames.customPeriodInterval]: computeFlagOrderingBasedOnTranslation(customPeriodIntervalOptions),
		}),
		[]
	);

	const columns = [
		TextColumn({
			fieldName: fieldNames.requestedByUserName,
			header: TranslateText('maintenanceOverview.grid.colUser'),
			sortable: true,
			filterable: true,
			defaultWidth: 130,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.oneTimeExecution,
			header: TranslateText('maintenanceOverview.grid.colSource'),
			sortable: true,
			filterable: true,
			body: (report: GeneratedReportDto) => {
				return (
					<span title={'oneTimeExecution'}>
						{report.oneTimeExecution
							? TranslateText('reports.manualGenerated')
							: TranslateText('reports.scheduledGenerated')}
					</span>
				);
			},
			selectOptions: oneTimeExecutionOptions,
			defaultWidth: 110,
		}),
		TextColumn({
			fieldName: fieldNames.reportName,
			header: TranslateText('maintenanceOverview.grid.colReportName'),
			sortable: true,
			filterable: true,
			defaultWidth: 290,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.templateCategoryType,
			header: TranslateText('maintenanceOverview.grid.colReportType'),
			sortable: true,
			filterable: true,
			body: (report: GeneratedReportDto) => {
				return <span title={report.templateCategoryType}>{TranslateText(report.templateCategoryType)}</span>;
			},
			selectOptions: templateCategoryTypeOptions,
			defaultWidth: 120,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.outputType,
			header: TranslateText('maintenanceOverview.grid.colOutputType'),
			sortable: true,
			filterable: true,
			selectOptions: outputTypeOptions,
			defaultWidth: 110,
		}),
		NumberColumn({
			fieldName: fieldNames.selectedGroups,
			header: TranslateText('maintenanceOverview.grid.colSelectedGroups'),
			sortable: true,
			filterable: true,
			defaultWidth: 80,
		}),
		NumberColumn({
			fieldName: fieldNames.selectedEntities,
			header: TranslateText('maintenanceOverview.grid.colSelectedEntities'),
			sortable: true,
			filterable: true,
			defaultWidth: 80,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.customPeriodInterval,
			header: TranslateText('maintenanceOverview.grid.colRequestedPeriod'),
			sortable: true,
			filterable: true,
			body: (rowData: GeneratedReportDto) => {
				const formattedData = formatReportPeriod(rowData.periodType, rowData.customPeriodInterval);
				return <span title={formattedData}>{formattedData}</span>;
			},
			selectOptions: customPeriodIntervalOptions,
			defaultWidth: 280,
		}),
		DateColumn({
			fieldName: fieldNames.generatedDateTime,
			header: TranslateText('maintenanceOverview.grid.colDateTime'),
			sortable: true,
			filterable: true,
			formatDateFunction: FormatDate,
			defaultWidth: 180,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.status,
			header: TranslateText('maintenanceOverview.grid.colStatus'),
			sortable: true,
			filterable: true,
			body: (rowData: GeneratedReportDto) => {
				return formatReportStatus(rowData.status);
			},
			selectOptions: statusOptions,
			defaultWidth: 100,
		}),
		ButtonColumn({
			body: (rowData: GeneratedReportDto) => {
				return rowData.status === 'Error' ? (
					<Button
						className="report-grid-button-error"
						disableElevation
						variant="contained"
						onClick={() => runReport(rowData.id)}
					>
						<Icon>sync_problem</Icon>
					</Button>
				) : rowData.status === 'InProgress' &&
				  Date.now() - new Date(rowData.generatedDateTime).getTime() > timeGeneration ? (
					<Button
						className="report-grid-button"
						disableElevation
						variant="contained"
						disabled
						onClick={() => downloadReport(rowData.id)}
					>
						<Icon>hourglass_empty</Icon>
					</Button>
				) : (
					<DebouncedButton
						className="report-grid-button"
						disableElevation
						variant="contained"
						disabled={verifyDisabledButton(rowData.id, disableDownload) || rowData.status === 'InProgress'}
						onClick={() => downloadReport(rowData.id)}
						debounceTime={DEBOUNCE_TIME}
					>
						<Icon>download</Icon>
					</DebouncedButton>
				);
			},
		}),
		ButtonColumn({
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.edit],
			}),
			body: (rowData: GeneratedReportDto) => (
				<Button
					className="report-grid-button"
					disableElevation
					variant="contained"
					disabled={rowData.status === 'InProgress'}
					onClick={() => openDeleteReport(rowData.id)}
				>
					<Icon>close</Icon>
				</Button>
			),
		}),
		BooleanColumn({
			fieldName: fieldNames.favorite,
			header: TranslateText('maintenanceOverview.grid.colFavorite'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.edit],
			}),
			body: (rowData: GeneratedReportDto) =>
				rowData.requestedByUserId === userId && (
					<IconButton
						className="report-grid-icon-button-favorite"
						size="small"
						disabled={
							verifyDisabledButton(rowData.id, disableFavorite) ||
							rowData.status === 'InProgress' ||
							rowData.favorite ||
							(!props.driverIdentification &&
								(rowData.entityType === EntityTypeEnum.Person ||
									rowData.dataType === TemplateData.ObjectWithoutDriver))
						}
						hidden={rowData.dataType === TemplateData.LocationExport ||
							rowData.dataType === TemplateData.ObjectExport
					}
						onClick={
							!props.driverIdentification &&
							(rowData.entityType === EntityTypeEnum.Person ||
								rowData.dataType === TemplateData.ObjectWithoutDriver)
								? noop
								: () => toggleFavoriteReport(rowData)
						}
					>
						{rowData.favorite &&
						!(
							!props.driverIdentification &&
							(rowData.entityType === EntityTypeEnum.Person ||
								rowData.dataType === TemplateData.ObjectWithoutDriver)
						) ? (
							<Icon>favorite</Icon>
						) : (
							<Icon>favorite_border</Icon>
						)}
					</IconButton>
				),
			defaultWidth: 140,
		}),
	];

	// Include other state variables and effect hooks from the previous code
	const getUrl: string = GlobalSettings.reportingApi + '/generatedReports';
	const timeGeneration = 5 * 60000; //5 minutes

	useEffect(() => {
		if (scrollSize !== null && scrollSize > 0 && props.focus) setRefreshTime(30000);
		else setRefreshTime(5000);
	}, [scrollSize]);
	const handleUserResponse = (response: any) => {
		switch (response) {
			case 0:
				deleteReport(currentReportId);
				break;
			case 1:
				removeReportFromList(currentReportId);
				break;
		}
		setShowReportRemoveDialog(false);
	};
	const openDeleteReport = (id: string) => {
		setCurrentReportId(id);
		setShowReportRemoveDialog(true);
	};

	const toggleFavoriteReport = (report: GeneratedReportDto) => {
		setDisableFavorite([...disableFavorite, report.id]);
		const url = GlobalSettings.reportingApi + '/addFavoriteReport';
		ajaxUtil
			.put<boolean>(url, { id: report.id })
			.then((data) => {
				toggleFetchDataTrigger();
			})
			.catch((err) => {
				setDisableFavorite([...disableFavorite.filter((s) => s !== report.id)]);
				console.log(err);
			});
	};

	const downloadReport = (reportId: string) => {
		setDisableDownload([...disableDownload, reportId]);
		const url = GlobalSettings.reportingApi + '/downloadGeneratedReport/' + reportId;
		ajaxUtil
			.get(url)
			.then((data) => {
				const reportDownload = data as FileDownload;
				const bytes = base64ToArrayBuffer(reportDownload.content);
				const url = window.URL.createObjectURL(new Blob([bytes]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', reportDownload.fileName);
				document.body.appendChild(link);
				link.click();
				setDisableDownload([...disableDownload.filter((s) => s !== reportId)]);
			})
			.catch((err) => {
				setDisableDownload([...disableDownload.filter((s) => s !== reportId)]);
				console.log(err);
			});
	};

	const runReport = (reportId: string) => {
		const url = GlobalSettings.reportingApi + '/rerunReport/' + reportId;
		ajaxUtil.post(url, { reportId }).then((data) => {
			toggleFetchDataTrigger();
		});
	};

	const deleteReport = (reportId: string) => {
		const url = GlobalSettings.reportingApi + '/deleteGeneratedReport/' + reportId;
		ajaxUtil.delete(url).then((data) => {
			toggleFetchDataTrigger();
		});
	};

	const removeReportFromList = (reportId: string) => {
		const url = GlobalSettings.reportingApi + '/removeReportFromList/' + reportId;
		ajaxUtil.put(url, {}).then((data) => {
			toggleFetchDataTrigger();
		});
	};

	const formatReportPeriod = (periodType: string, customPeriodInterval: any) => {
		if(periodType != null) {
			if (periodType?.toLowerCase() === ReportPeriodsEnum.CustomPeriod.value) {
				return (
					TranslateText('reports.periods.' + periodType.toLowerCase()) +
					': ' +
					FormatDate(customPeriodInterval[0]) +
					' - ' +
					FormatDate(customPeriodInterval[1])
				);
			} else {
				return TranslateText('reports.periods.' + periodType.toLowerCase());
			}
		}
		return null;
	};

	const formatReportStatus = (status: GeneratedReportsStatusEnum | string) => {
		switch (status) {
			case GeneratedReportsStatusEnum.Completed:
				return TranslateText('common.complete');
			case GeneratedReportsStatusEnum.InProgress:
				return TranslateText('common.inProgress');
			case GeneratedReportsStatusEnum.Error:
				return TranslateText('common.error');
			default:
				return '';
		}
	};
	const verifyDisabledButton = (id: string, array: string[]) => {
		return array.some((s: string) => s === id);
	};

	return reportData.data ? (
		<div className="report-content-container flex-grid-container">
			{DialogUtil.multipleChoices(
				{
					title: TranslateText('common.titleConfirmAction'),
					content: TranslateText('notificationMessages.deleteGeneratedReport'),
					buttonOptions: [
						TranslateText('common.buttonDelete'),
						TranslateText('common.buttonRemoveFromList'),
						TranslateText('common.buttonCancel'),
					],
				},
				showReportRemoveDialog,
				handleUserResponse
			)}
			<NewGridOverview
				columns={columns}
				initialFilters={initialFilters}
				columnFlagOrder={columnFlagOrdering}
				getGridDataUrl={getUrl}
				headerText={''}
				disableAddButton
				entityType={EntityTypeEnum.Report}
				hideOverviewSearchbar
				addGetPageToUrl={false}
				fetchDataTrigger={fetchDataTrigger}
				refreshInterval={props.focus ? refreshTime : null}
				resetFiltersTrigger={props.resetFiltersTrigger}
				setDataScrollCallback={setScrollSize}
				disableImportButton={true}
				disableExportButton={true}
			/>
		</div>
	) : null;
};

export default GeneratedReportsOverview;
