enum TemplateData {
	Trips = 'Trips',
	ObjectCurrentMileage = 'ObjectCurrentMileage',
	ObjectMileage = 'ObjectMileage',
	DriverMileage = 'DriverMileage',
	ObjectPosition = 'ObjectPosition',
	DriverPosition = 'DriverPosition',
	ObjectRawMessage = 'ObjectRawMessage',
	ObjectWithoutDriver = 'ObjectWithoutDriver',
	ObjectTax = 'ObjectTax',
	DriverTax = 'DriverTax',
	CurrentObjectPosition = 'CurrentObjectPosition',
	CumulativeRunningHours = 'CumulativeRunningHours',
	DurationSummary = 'DurationSummary',
	Visits = 'Visits',
	ObjectRunningHours = 'ObjectRunningHours',
	PersonRunningHours = 'PersonRunningHours',
	OutsideBusinessHours = 'OutsideBusinessHours',
	LoginReason = 'LoginReason',
	Location = 'Location',
	DriverRegistration = 'DriverRegistration',
	ObjectRegistration = 'ObjectRegistration',
	MileageCO2 = 'MileageCO2',
	DeviceExport = 'DeviceExport',
	LocationExport = 'LocationExport',
	ObjectExport = 'ObjectExport',
	ObjectCounting = 'ObjectCounting',
	ObjectDailySummary = 'ObjectDailySummary',
	PersonDailySummary = 'PersonDailySummary'
}

export default TemplateData;
