import React, { useState } from 'react';

import { Badge, IconButton } from '@material-ui/core';
import { NotificationsActiveOutlined, NotificationsNoneOutlined } from '@material-ui/icons';

import { AlertsNotificationPopup } from './AlertsNotificationPopup';
import { useAlertsNotificationNumber, useRedirectToUrl } from './hooks';

const AlertsNotification = ({ anchorEl }: { anchorEl: React.MutableRefObject<HTMLDivElement> }): JSX.Element => {
	const [opened, setOpened] = useState<boolean>(false);

	//notification number
	const { alertsNotificationNumber, setNotificationAsSeen } = useAlertsNotificationNumber(opened);

	const setRedirectUrl = useRedirectToUrl();

	return (
		<>
			<IconButton className={opened ? 'opened' : ''} onClick={() => setOpened(true)}>
				{alertsNotificationNumber ? (
					<Badge badgeContent={alertsNotificationNumber} className="notification-badge">
						<NotificationsActiveOutlined className="icon" />
					</Badge>
				) : (
					<NotificationsNoneOutlined className="icon" />
				)}
			</IconButton>
			{opened ? (
				<AlertsNotificationPopup
					setOpened={setOpened}
					setNotificationAsSeen={setNotificationAsSeen}
					setRedirectUrl={setRedirectUrl}
					anchorEl={anchorEl}
				/>
			) : null}
		</>
	);
};

export default AlertsNotification;
