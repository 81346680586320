import { useEffect, useRef, useState } from 'react';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';
import DurationExceededAlertMetadata from 'models/DurationExceededAlert';
import { ValidateResult, ValidationData } from '../../../shared/validation/interfaces';
import { EvaluationMomentEnum, TimeFrameEnum } from './TimeFrameEvaluation/EvaluationMomentDropdown/types';
import { getDurationForSelectedTimeFrame } from './TimeFrameEvaluation/hooks';
import SensorTypeEnum from '../../../models/SensorTypeEnum';

const useAlertDurationExceeded = (
	durationExceededMetadata: DurationExceededAlertMetadata,
	handleDurationExceededMetadataChange: (durationExceededMetadata: DurationExceededAlertMetadata) => void,
	reInitializeData?: boolean
) => {
	const [relatedToTimeChecked, setRelatedToTimeChecked] = useState<boolean>(
		durationExceededMetadata?.relatedToTime ?? true
	);
	const [relatedToTimeCheckboxDisabled, setRelatedToTimeCheckboxDisabled] = useState<boolean>(false);
	const [durationCounter, setDurationCounter] = useState<number>(durationExceededMetadata?.durationCounter ?? 1);
	const [durationTemplateId, setDurationTemplateId] = useState<string | undefined>(
		durationExceededMetadata?.templateDurationId
	);
	const [digitalTemplateId, setDigitalTemplateId] = useState<string | undefined>(
		durationExceededMetadata?.templateDigitalId
	);
	const durationMaxValueRef = useRef<number>(getDurationForSelectedTimeFrame(durationExceededMetadata?.timeFrame));
	const [validationResult, setValidationResult] = useState<ValidateResult>();
	const validator: Validator = new Validator({
		fieldRules: {
			templateId: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: ValidationData): Promise<boolean> => {
							const fieldValue1 = data.templateDurationId;
							const fieldValue2 = data.templateDigitalId;
							if (
								(fieldValue2 === null || fieldValue2 === undefined || fieldValue2 === '') &&
								(fieldValue1 === null || fieldValue1 === undefined || fieldValue1 === '')
							) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
			durationCounter: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: ValidationData): Promise<boolean> => {
							const fieldValue = data.durationCounter;
							if (
								!data.relatedToTime &&
								(fieldValue === null || fieldValue === undefined || fieldValue === '')
							) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					maxValue: {
						message: `${TranslateText('fieldsValidations.maxValue')} 999999999`,
						validationFunction: (data: ValidationData) => {
							if (!data.relatedToTime && data.durationCounter > 999999999) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					minValue: {
						message: `${TranslateText('fieldsValidations.minValue')} 1`,
						validationFunction: (data: ValidationData) => {
							if (!data.relatedToTime && data.durationCounter < 1) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
			duration: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: ValidationData): Promise<boolean> => {
							const fieldValue = data.duration;
							if (
								data.relatedToTime &&
								(fieldValue === null || fieldValue === undefined || fieldValue === '')
							) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					maxValue: {
						message: () => `${TranslateText('fieldsValidations.maxValue')} ${durationMaxValueRef.current}`,
						validationFunction: (data: ValidationData) => {
							if (data.relatedToTime && data.duration > durationMaxValueRef.current) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					minValue: {
						message: `${TranslateText('fieldsValidations.minValue')} 1`,
						validationFunction: (data: ValidationData) => {
							if (data.relatedToTime && data.duration < 1) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
		},
	});

	const setTemplateId = (value: string, sensorType: SensorTypeEnum) => {
		if (!value) {
			setDurationTemplateId(undefined);
			setDigitalTemplateId(undefined);
			setRelatedToTimeCheckboxDisabled(false);
			return;
		}

		switch (sensorType.toString()) {
			case 'Digital':
				setDigitalTemplateId(value);
				setDurationTemplateId(undefined);
				setRelatedToTimeCheckboxDisabled(false);
				break;
			case 'Duration':
				setDigitalTemplateId(undefined);
				setDurationTemplateId(value);
				setRelatedToTimeCheckboxDisabled(false);
				break;
			default:
				setDurationTemplateId(undefined);
				setDigitalTemplateId(undefined);
				setRelatedToTimeCheckboxDisabled(false);
				break;
		}
	};

	useEffect(() => {
		if (!durationExceededMetadata) return;

		setDurationTemplateId(durationExceededMetadata.templateDurationId);
		setDigitalTemplateId(durationExceededMetadata.templateDigitalId);
		setDurationCounter(durationExceededMetadata.durationCounter);
		setRelatedToTimeChecked(durationExceededMetadata.relatedToTime);
		setRelatedToTimeCheckboxDisabled(!!durationExceededMetadata.templateDigitalId)
	}, [reInitializeData]);

	useEffect(() => {
		handleDurationExceededMetadataChange({
			...durationExceededMetadata,
			durationCounter: durationCounter,
			relatedToTime: relatedToTimeChecked,
			templateDurationId: durationTemplateId,
			templateDigitalId: digitalTemplateId,
		});
	}, [durationTemplateId, digitalTemplateId, relatedToTimeChecked, durationCounter]);

	useEffect(() => {
		if (!durationExceededMetadata) return;

		durationMaxValueRef.current = getDurationForSelectedTimeFrame(durationExceededMetadata?.timeFrame);
		validator.validate(durationExceededMetadata).then((result: ValidateResult) => {
			setValidationResult(result);
		});
	}, [durationExceededMetadata]);

	const handleTimeFrameEvaluationChange = (
		duration: number,
		timeFrameId: TimeFrameEnum,
		evaluationMomentId: EvaluationMomentEnum
	) => {
		handleDurationExceededMetadataChange({
			timeFrame: timeFrameId,
			evaluationMoment: evaluationMomentId,
			duration: duration,
			durationCounter: durationCounter,
			relatedToTime: relatedToTimeChecked,
			templateDurationId: durationTemplateId,
			templateDigitalId: digitalTemplateId,
		});
	};

	return {
		durationMaxValue: durationMaxValueRef.current,
		relatedToTimeChecked,
		durationCounterValue: durationCounter,
		templateId: durationTemplateId ?? digitalTemplateId,
		handleTimeFrameEvaluationChange,
		setRelatedToTimeChecked,
		setTemplateId,
		setDurationCounterValue: setDurationCounter,
		validationResult,
		relatedToTimeCheckboxDisabled
	};
};

export { useAlertDurationExceeded };
