import React, {ChangeEvent, useEffect, useState } from "react";

import {WidgetViewDefault} from "./Widget";
import {FieldRules} from "../../shared/validation/interfaces";
import {ValidatorFunctions} from "../../shared/validation/ValidatorFunctions";
import Accordion from "../Accordion/Accordion";
import {TranslateText} from "../../utils/Translations";
import AccordionSection from "../Accordion/AccordionSection";
import {ValidationMessage} from "../ValidationMessage/ValidationMessage";
import {Checkbox, FormControlLabel, TextField, Typography } from "@material-ui/core";
import StartPageEnum from "../../models/StartPageEnum";
import InformationNotification from "../../models/InformationNotification";
import MaterialAutocomplete from "../Common/Autocomplete/MaterialAutocomplete";
import {LOGIN_REASON, noop} from "../../Constants";
import Validator from "../../shared/validation/Validator";
import GlobalSettings from "../../GlobalSettings.json";
import ajaxUtil from "../../utils/Ajax";
import {
	InformationNotificationTypesDto
} from "../NewAddWizard/Views/InformationNotification/InformationNotificationView";
import {FormatDate} from "../../utils/DateUtils";
import CustomerNameDisplay from "../../shared/components/CustomerNameDisplay";
import DateTimeUtil, {DateTimeConversionUtil} from "../../shared/datetime/DateTimeUtil";
import MaterialDatePicker from "../Common/DateTime/MaterialDatePicker";

type Props = WidgetViewDefault & {
	data: InformationNotification;
	initialData: InformationNotification;
};

type ChangeValue = string | number | boolean | Date | StartPageEnum;
type ChangeKey =
	| 'name'
	| 'description'
	| 'urgent'
	| 'driver'
	| 'type'
	| 'startDateTime'
	| 'endDateTime'
	| 'active';

const GetInformationNotificationValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		name: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(200),
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
			}
		},
		description: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(200),
			}
		},
		type: {
			rules: {
				required: ValidatorFunctions.required(),
			}
		},
		startDateTime: {
			rules: {
				graterThanEndDate: {
					message: TranslateText('fieldsValidations.startDateGreaterThanEndDate'),
					validationFunction: (data) => {
						const newNotification = data as InformationNotification;
						const startDateTime = new Date(newNotification.startDateTime);
						const endDateTime = new Date(newNotification.endDateTime);
						if(startDateTime !== null)
							startDateTime.setSeconds(0,0);
						if(endDateTime !== null)
							endDateTime.setSeconds(0,0);
						return ValidatorFunctions.wrapInPromise(
							newNotification.endDateTime === null ||
							startDateTime <= endDateTime
						);
					},
				}
			},
		},
		endDateTime: {
			rules: {
				validDate: {
					message: TranslateText('fieldsValidations.endDateLowerOrEqualThanStartDate'),
					validationFunction: (data) => {
						const newNotification = data as InformationNotification;
						const startDateTime = new Date(newNotification.startDateTime);
						const endDateTime = new Date(newNotification.endDateTime);
						if(startDateTime != null)
							startDateTime.setSeconds(0,0);
						if(endDateTime !== null)
							endDateTime.setSeconds(0,0);
						return ValidatorFunctions.wrapInPromise(
							newNotification.endDateTime == null ||
							endDateTime > startDateTime
						);
					},
				},
			},
		},
	};

	return fieldRules;
}
const NotificationInformationView = (props: Props) => {

	const [expandedSection, setExpandedSection] = useState<number>(0);
	const [informationNotificationTypes, setInformationNotificationTypes] = useState([]);
	const [modifiedDateTime, setModifiedDateTime] = useState(props.data.lastDateTimeModified);
	const [startDateTimeDefault, setStartDateTimeDefault] = useState<Date>(props.data?.startDateTime);

	const events = {
		handleValueChange: (value: ChangeValue, key: ChangeKey) => {
			const newState = {...props.data};
			if (key === 'active' || key === 'urgent' || key === 'driver') {
				newState[key] = value as boolean;
			} else if(key === 'startDateTime') {
				setStartDateTimeDefault(value as Date);
				newState[key] = value as Date;
			}
			else if (key === 'endDateTime') {
				let endDate = value as Date;
				if(endDate !== null) {
					endDate.setSeconds(0, 0);

					if (startDateTimeDefault !== null) {
						let startDate = new Date(startDateTimeDefault);
						startDate.setSeconds(0, 0);
						if (+startDate === +endDate) {
							endDate.setMinutes(endDate.getMinutes() + 1);
						}
					}
				}
				newState[key] = endDate;
				newState.startDateTime = new Date(startDateTimeDefault);
			}
			else {
				newState[key] = value as string;
			}
			newState.lastDateTimeModified = new Date();

			props.changeDataCallback(newState);
		},
	};

	useEffect(() => {
		getTypes();
	}, []);

	useEffect(() => {
		if(props.initialData.lastDateTimeModified === props.data.lastDateTimeModified && !props.editMode)
			setModifiedDateTime(props.data.lastDateTimeModified);
	},[props.editMode])


	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetInformationNotificationValidationConfig() }, true));
		props.setDashboardTitleCallback(props.data?.name);
		props.setDataCallback(props.data.type);
	}, [props.entityId]);

	const getTypes = () => {
		let array: { display: string; id: string; }[] = [];
		const url = `${GlobalSettings.informationNotificationsMaintenanceApi}/informationNotificationTypes`;
		ajaxUtil.get<InformationNotificationTypesDto[]>(url).then((result) => {
			const types = [...result] as InformationNotificationTypesDto[];
			for(let i=0; i < types.length; i++)
				array.push({
					display: TranslateText(`notificationTypes.${types[i]?.name}`),
					id: types[i]?.id,
				});
			setInformationNotificationTypes(array);
		});
	}
	const formatDateCustom = (data: string | Date) => {
		return FormatDate(data, false,false,false);
	}

	return (
		<>
			<Accordion
				expandedCallback={(index) => {
					setExpandedSection(index);
				}}
			>
				<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.systemInformation')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<TextField
								id="name"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.name')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="code"
								value={props.data?.name}
								onChange={(e) => {
									if (e.target) {
										events.handleValueChange(e.target.value, 'name');
									}
								}}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.name} />
						</div>
						<div className="form-group">
							<TextField
								id="description"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.description')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="description"
								value={props.data?.description}
								onChange={(e) => {
									if (e.target) {
										events.handleValueChange(e.target.value, 'description');
									}
								}}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.description} />
						</div>
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data?.type}
								disabled={true}
								dataSource={informationNotificationTypes}
								name="typeOfNotification"
								label={TranslateText('fields.informationNotification.typeOfNotification')}
								onChange={({ value }) => noop()}
							/>
							<ValidationMessage result={props.validationResult?.type} />
						</div>
						<div className="form-group">
							<table>
								<tr>
									<td style={{paddingBottom:"16px"}}>
										<Typography style={{ fontSize: 12, marginRight: 5, color: !props.editMode || props.data.type === LOGIN_REASON ? '#b0b0b0' : 'inherit' }}>
											{TranslateText('fields.informationNotification.urgent')}
										</Typography>
									</td>
									<td style={{paddingBottom:"16px"}}>
										<Checkbox
											name="urgent"
											color={'primary'}
											checked={props.data?.urgent}
											readOnly={!props.editMode || props.data.type === LOGIN_REASON}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												events.handleValueChange(e.target.checked, 'urgent')
											}
											disabled={!props.editMode || props.data.type === LOGIN_REASON}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Typography style={{ fontSize: 12, marginRight: 5, color: !props.editMode || props.data.type === LOGIN_REASON ? '#b0b0b0' : 'inherit' }}>
											{TranslateText('fields.driver')}
										</Typography>
									</td>
									<td>
										<Checkbox
											name="driver"
											color={'primary'}
											checked={props.data?.driver}
											readOnly={!props.editMode || props.data.type === LOGIN_REASON}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												events.handleValueChange(e.target.checked, 'driver')
											}
											disabled={!props.editMode || props.data.type === LOGIN_REASON}
										/>
									</td>
								</tr>
							</table>
						</div>
						<div className="form-group">
							<MaterialDatePicker
								name="startDateTime"
								showTime={true}
								date={props.data?.startDateTime}
								label={TranslateText('fields.informationNotification.startDateTime')}
								format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeRemoveSeconds(), true)}
								onDateChange={(date: Date) => events.handleValueChange(date, 'startDateTime')}
								fullWidth={false}
								disabled={!props.editMode}
								width={240}
								hiddenCloseButton={true}
							/>
							<ValidationMessage result={props.validationResult?.startDateTime} />
						</div>
						<div className="form-group">
							<MaterialDatePicker
								name="endDateTime"
								showTime={true}
								date={props.data.endDateTime}
								label={TranslateText('fields.informationNotification.endDateTime')}
								format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateTimeRemoveSeconds(), true)}
								onDateChange={(date: Date) => events.handleValueChange(date, 'endDateTime')}
								fullWidth={false}
								disabled={!props.editMode}
								width={240}
							/>
							<ValidationMessage result={props.validationResult?.endDateTime} />
						</div>
						<div className="form-group">
							<FormControlLabel
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={props.data?.active}
										readOnly={!props.editMode}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											events.handleValueChange(e.target.checked, 'active')
										}
										disabled={!props.editMode}
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.active')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0 }}
							/>
						</div>
						<CustomerNameDisplay customerId={props.data?.customerId} />
						<div className="form-group">
							<Typography style={{ fontSize: 12, marginLeft: 0, color: '#b0b0b0' }}
							>
								{TranslateText('fields.lastModified')}: {props.data?.userName} {modifiedDateTime !== null ? formatDateCustom(modifiedDateTime) : null}
							</Typography>
						</div>
					</div>
				</AccordionSection>
			</Accordion>
		</>
	);
}

export default NotificationInformationView;
