import './styles.scss';

import ListSelectionView from 'components/ListSelectionView';
import React from 'react';

import { Button, Dialog, DialogContent } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../Constants';
import { TranslateText } from '../../utils/Translations';
import { DebouncedButton } from '../Common/DebouncedButton';
import { ListSelectionDialogProps } from './types';

const ListSelectionDialog = (props: ListSelectionDialogProps) => {
	return (
		<Dialog
			onEscapeKeyDown={props.onCancelClicked}
			fullWidth
			className={'list-selection-dialog'}
			open={props.visible}
		>
			<DialogContent>
				<span className={'dialog-header'}>{props.title ? props.title : TranslateText('common.filter')}</span>
				<div className="dialog-content">
					<ListSelectionView
						filterPlaceholder={TranslateText('geofence.searchLocation')}
						selection={props.selection}
						onSelectionToggle={props.onSelectionToggle}
						removeSelection={props.removeSelection}
						removeAllSelection={props.removeAllSelection}
						columns={props.columns}
						initialFilters={props.initialFilters}
						getGridDataUrl={props.getGridDataUrl}
					/>
				</div>
				<div className={'buttons-container'}>
					<Button onClick={props.onCancelClicked}>{TranslateText('common.buttonCancel')}</Button>
					<DebouncedButton onClick={props.onSaveClicked} debounceTime={DEBOUNCE_TIME}>
						{TranslateText('common.buttonSave')}
					</DebouncedButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ListSelectionDialog;
