import React from 'react';

import { Button } from '@material-ui/core';

import AutoTextField from '../../components/AutoTextfield/AutoTextField';
import { TranslateText } from '../../utils/Translations';
import SelectionPanel from '../SelectionPanel';
import SelectionTree from '../SelectionTree/SelectionTree';
import { ITreeNode } from '../SelectionTree/TreeNode/types';

interface Props {
	selectedItems: ITreeNode[];
	treeData: ITreeNode[];
	selectAllVisible: boolean;
	filterText: string;
	filterPlaceholder?: string;
	changeFilterCallback: (filterText: string) => void;
	selectAll?: () => void;
	selectCallback: (node: ITreeNode) => void;
	removeSelectedItem: (node: ITreeNode) => void;
	clearSelection: () => Promise<void>;
	expandCallback: (node: ITreeNode) => void;
	dataIsLoading: boolean;
}

const SelectionDialogContent = (props: Props) => {
	return (
		<div className={'content'}>
			<div className="data">
				<AutoTextField
					placeHolder={props.filterPlaceholder}
					value={props.filterText}
					onChange={props.changeFilterCallback}
					showClearButton={true}
				/>
				{props.selectAllVisible ? (
					<Button className="e-flat widget-button selectAll" onClick={props.selectAll}>
						{TranslateText('fleetSelection.selectAll')}
					</Button>
				) : null}
				{props.dataIsLoading ? null : (
					<SelectionTree
						data={props.treeData}
						expandCallback={props.expandCallback}
						selectCallback={props.selectCallback}
					/>
				)}
			</div>
			<SelectionPanel
				selection={props.selectedItems}
				removeAll={props.clearSelection}
				removeItem={props.removeSelectedItem}
			/>
		</div>
	);
};

SelectionDialogContent.defaultProps = {
	selectAllVisible: false,
};

export default SelectionDialogContent;
