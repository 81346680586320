import { LogMessage } from 'components/Widgets/LogWidget/LogMessage';
import React from 'react';
import { TranslateText } from 'utils/Translations';

import { Box } from '@material-ui/core';

import { LogWidgetMessagesListProps } from './types';

const LogWidgetMessagesListFooter = ({ totalLogMessages, logMessages }: LogWidgetMessagesListProps): JSX.Element => {
	if (!totalLogMessages) {
		return <></>;
	}

	const getFooterText = (totalLogMessages: number, logMessages: LogMessage[]): string => {
		return ` ${TranslateText('widgets.grid.showing')}  ${logMessages?.length}  ${TranslateText(
			'widgets.grid.of'
		)} ${totalLogMessages}`;
	};

	return <Box className={'logs-grid-footer'}>{getFooterText(totalLogMessages, logMessages)}</Box>;
};

export default LogWidgetMessagesListFooter;
