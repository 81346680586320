import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import React from 'react';
import { MdImageNotSupported } from 'react-icons/md';

import { SvgIcon } from '../IconPicker/SvgIcon';
import { SvgIconRendererProps } from './types';

const SvgIconRenderer = ({ iconName, iconColor, size = 24 }: SvgIconRendererProps): JSX.Element => {
	const { icon } = useTryGetSvgIconFromQueryCache(iconName, 'default/no-image');

	return icon ? (
		<SvgIcon icon={icon.content} color={iconColor} size={size} onClick={() => {}} />
	) : (
		<MdImageNotSupported size={size} />
	);
};

export default SvgIconRenderer;
