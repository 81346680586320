import './styles.scss';

import React from 'react';
import { TranslateText } from 'utils/Translations';

import { Tooltip } from '@material-ui/core';
import { useGetDurationTemplatesValues } from './hooks';
import { DurationTemplatesProps } from './types';

const DurationTemplates = (props: DurationTemplatesProps): JSX.Element => {
	const { durationTemplates } = useGetDurationTemplatesValues(props.id);

	return (
		<>
			{durationTemplates.map((x) => (
				<div key={x.id} className={`duration-templates-item ${props.renderForTable ? 'render-for-table' : ''}`}>
					<Tooltip title={x.templateName}>
						<span className={'template-name'}>{x.templateName}</span>
					</Tooltip>
					<span className="duration-templates-entry">
						<span className="column">
						<span className="field">{TranslateText('tripDetailsSection.startValue')}: </span>
						<span className="field">{TranslateText('tripDetailsSection.endValue')}: </span>
						</span>
						<span>
						<span className="column">
							 <span className="value">
							   {x.totalStartSensorValue}
						 	</span>
							<span className="value">
								{x.totalEndSensorValue}
							</span>
							</span>
							<span className="column">
							{x.totalStartSensorValue == 1
								? <span className="field-value">
							    {TranslateText('tripDetailsSection.hour')}
						      </span>
								: <span className="field-value">
							    {TranslateText('tripDetailsSection.hours')}
						      </span>
							}
							{x.totalEndSensorValue == 1
								? <span className="field-value">
							 	 {TranslateText('tripDetailsSection.hour')}
							     </span>
								: <span className="field-value">
							 	 {TranslateText('tripDetailsSection.hours')}
						     	 </span>
							}
							</span>
						</span>
					</span>
				</div>
			))}
		</>
	);
};
export default DurationTemplates;
