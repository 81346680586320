import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import React, { useRef } from 'react';
import { TranslateText } from 'utils/Translations';
import { EvaluationMomentEnum, EvaluationMomentDropdownProps } from './types';

const EvaluationMomentDropdown = ({
	valueId,
	handleOnEvaluationMomentValueChange,
	disabled,
	hideLabel,
	isForNewAddWizard = false,
}: EvaluationMomentDropdownProps): JSX.Element => {
	const alertEvaluationMomentEnumsRef = useRef<AutocompleteItem[]>([
		{
			display: TranslateText('evaluationMoment.always'),
			id: EvaluationMomentEnum.Always,
		},
		{
			display: TranslateText('evaluationMoment.endOfTimeFrame'),
			id: EvaluationMomentEnum.EndOfTimeFrame,
		},
	]);

	return (
		<div className="form-group">
		<MaterialAutocomplete
			isForNewAddWizard={isForNewAddWizard}
			disabled={disabled}
			name="alertEvaluationMoment"
			className="material-autocomplete alert-duration-evaluation-moment-dropdown-container"
			valueId={valueId}
			dataSource={alertEvaluationMomentEnumsRef.current}
			onChange={(autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => {
				handleOnEvaluationMomentValueChange(autocompleteArgs.value as number);
			}}
			fullWidth={false}
			label={!hideLabel ? TranslateText('fields.evaluationMoment') : null}
			disableClearable
		/>
		</div>
	);
};

export default EvaluationMomentDropdown;
