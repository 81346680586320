import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@material-ui/core';
import GlobalSettings from '../../../GlobalSettings.json';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';
import PlainOneFieldDialog, { PlainEntity } from '../components/PlainOneFieldDialog';
import NotificationPrompt from '../../../shared/components/UserPrompt/NotificationPrompt';

export interface LocationCategory {
	id: string;
	name: string;
	customerId: string;
}

const CustomerLocationCategoriesView = (props: IButtonWidgetView) => {
	const [editEntity, setEditEntity] = useState<LocationCategory>(null);
	const [dialogVisible, setDialogVisible] = useState<boolean>(false);
	const [showConflictPopUp, setShowConflictPopUp] = useState<boolean>(false);

	useEffect(() => {
		props.setUrl(`${GlobalSettings.customersMaintenanceApi}/locationCategories/${props.entityId}`);
	}, []);

	const removeLocationCategory = useCallback(
		async (id: string) => {
			if (id) {
				await ajaxUtil
					.post(`${GlobalSettings.customersMaintenanceApi}/deleteLocationCategory`, { id: id })
					.then((r: any) => {
						if (!r.success) {
							setShowConflictPopUp(true);
						}
					});
				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);

	const saveCallback = useCallback(
		async (entity: PlainEntity) => {
			await ajaxUtil
				.put(`${GlobalSettings.customersMaintenanceApi}/updateLocationCategory`, {
					id: entity.id,
					name: entity.name,
					customerId: props.entityId,
				})
				.then(() => {
					props.reloadDataCallback();
				});
		},
		[props.data]
	);

	return (
		<>
			<NotificationPrompt
				handleUserResponse={() => setShowConflictPopUp((prev) => !prev)}
				title={TranslateText('category.deleteTitle')}
				message={TranslateText('category.deleteConflict')}
				displayDialog={showConflictPopUp}
				showCancelButton={false}
			/>
			<div className={'customer-location-categories-view'}>
				<DataTable
					value={(props.data as LocationCategory[])
						.map((lc) => {
							return {
								name: TranslateText(`category.${lc.name}`).startsWith('category.')
									? lc.name
									: TranslateText(`category.${lc.name}`),
								id: lc.id,
								customerId: lc.customerId,
							} as LocationCategory;
						})
						.sort((a, b) => a.name.localeCompare(b.name))}
					className="gridWidgetOverview"
					emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
				>
					<Column
						className="widget-grid-column"
						header={TranslateText('widgets.grid.colName')}
						body={(rowData: LocationCategory) => <span>{rowData.name}</span>}
					/>
					<Column
						className="clear-column"
						headerClassName={'small-header'}
						style={{ width: '40px' }}
						body={(rowData: LocationCategory) =>
							props.editMode && (
								<Icon
									className="clear-icon"
									onClick={() => {
										setDialogVisible((prev) => !prev);
										setEditEntity({ ...rowData });
									}}
								>
									edit
								</Icon>
							)
						}
					/>
					<Column
						className="clear-column"
						headerClassName={'small-header'}
						style={{ width: '40px' }}
						body={(rowData: LocationCategory) =>
							props.editMode && (
								<Icon className="clear-icon" onClick={() => removeLocationCategory(rowData.id)}>
									clear
								</Icon>
							)
						}
					/>
				</DataTable>
			</div>
			{editEntity && dialogVisible && (
				<PlainOneFieldDialog
					categoryNames={props.data.map((c) => c.name)}
					removeCallBack={removeLocationCategory}
					saveCallBack={saveCallback}
					entity={editEntity}
					setVisible={setDialogVisible}
					headerRowName={TranslateText('maintenanceOverview.grid.colName')}
					dialogTitle={TranslateText('category.locationCategories')}
					visible={dialogVisible}
				/>
			)}
		</>
	);
};

export default CustomerLocationCategoriesView;
