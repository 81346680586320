export const SET_HELP_FILE_NAME = 'SET_HELP_FILE_NAME';
export const SET_LEFT_BAR_STATE = 'SET_LEFT_BAR_STATE';

export interface LeftSideBar {
	helpFileName: string;
	leftBarState: { isExpanded: boolean };
}

const initialState: LeftSideBar = {
	leftBarState: null,
	helpFileName: '',
};

export const leftSideBarReducer = (state: LeftSideBar, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_LEFT_BAR_STATE:
			return {
				...state,
				leftBarState: action.payload,
			};
		case SET_HELP_FILE_NAME:
			return {
				...state,
				helpFileName: action.payload,
			};
		default:
			return state;
	}
};
