import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsFunnelFill } from 'react-icons/bs';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonBase } from '@material-ui/core';

import * as GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { ApplicationState } from '../../store';
import { FleetSelectionDispatcher, IFleetSelectionState } from '../../store/FleetSelection';
import ajaxUtil from '../../utils/Ajax';
import { FleetSelectionClientModel } from '../FleetSelectionArea/FleetSelectionArea';
import FleetSelectionDialog from '../FleetSelectionDialog';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import { useFleetSelectionUpdate, useSaveTracked } from './hooks';
import { GetFleetSelectionDataInput } from './types';

const FleetSelectionButton = (): JSX.Element => {
	const [showDialog, setShowDialog] = useState(false);
	const closedButtonPressed = useRef<boolean>(false);
	const dispatch = useDispatch();
	const fleetSelectionState = useSelector((s: ApplicationState) => s.fleetSelection);
	const isDriverWithNoAuthorization = useSelector(
		(s: ApplicationState) => s.currentSession.isDriverWithNoAuthorization
	);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);
	const customerLevel = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.level
			: state.currentSession.customerLevel
	);
	const isHistoryMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);

	const queryClient: QueryClient = useQueryClient();

	const { saveTracked } = useSaveTracked(customerLevel);

	//used to have access to latest fleet selection state (onSuccess from useMutation is not changed)
	const fleetSelectionStateRef = useRef<IFleetSelectionState>();
	fleetSelectionStateRef.current = fleetSelectionState;

	const { mutateAsync } = useMutation(
		(data: GetFleetSelectionDataInput) => {
			queryClient.setQueryData(['live', 'get-fleet-selection-data'], true);

			return ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetFleetSelectionData`, data);
		},
		{
			onError: () => {
				queryClient.setQueryData(['live', 'get-fleet-selection-data'], false);
			},
			onSuccess: (data: ITreeNode[]) => {
				if (!data || data.length <= 0) {
					FleetSelectionDispatcher.setSelectedEntities(dispatch, [] as ITreeNode[]);
					FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, [] as ITreeNode[]);
					FleetSelectionDispatcher.setExpandedGroups(dispatch, [] as string[]);

					return;
				}

				const selection: ITreeNode[] = fleetSelectionStateRef.current.tempSelectedEntities
					.filter((selectedEntity: ITreeNode) => !!selectedEntity)
					.map((selectedEntity: ITreeNode) => {
						const foundEntity: ITreeNode = data.find(
							(singleData) =>
								!!singleData &&
								(selectedEntity.id === singleData?.id || selectedEntity.parentId === singleData.id)
						);

						if (!foundEntity) {
							return null;
						}

						if (foundEntity.id === selectedEntity.id) {
							return foundEntity;
						}

						return foundEntity.items
							? foundEntity.items.find((item: ITreeNode) => item.id === selectedEntity.id)
							: null;
					})
					.filter((selectedEntity: ITreeNode) => !!selectedEntity);

				const selectionIds = selection.map((e) => e.id);
				if (
					(fleetSelectionStateRef.current.selectedEntities.length === selection.length &&
						fleetSelectionStateRef.current.selectedEntities.every((entity: ITreeNode) =>
							selectionIds.includes(entity.id)
						)) ||
					!isHistoryMapActive
				) {
					queryClient.setQueryData(['live', 'get-fleet-selection-data'], false);
				}
				FleetSelectionDispatcher.setSelectedEntities(dispatch, selection);
				//update tracked entities group members
				const newTrackedEntities: ITreeNode[] = fleetSelectionStateRef.current.trackedEntities
					.map((t: ITreeNode) => {
						const foundGroup = selection.find((se) => !!se && se?.id === t?.id && se.type === 'Group');
						return foundGroup
							? { ...t, items: foundGroup.items, childCount: foundGroup.items?.length ?? 0 }
							: t;
					})
					//filter tracked entities (remove the ones that are not in selected entities)
					.filter((t: ITreeNode) => selection.some((s: ITreeNode) => t?.id === s.id || t?.parentId === s.id));
				FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, newTrackedEntities);

				//filter expanded groups (remove the ones that are not in selected entities)
				const selectedGroups: ITreeNode[] = selection.filter((s: ITreeNode) => s.type === 'Group');
				const newExpandedGroups: string[] = fleetSelectionStateRef.current.expandedGroups.filter(
					(eg: string) => eg && selectedGroups.some((sg: ITreeNode) => sg.id === eg)
				);
				FleetSelectionDispatcher.setExpandedGroups(dispatch, newExpandedGroups);
			},
		}
	);

	useEffect(() => {
		fetchOnDialogChange();

		async function fetchOnDialogChange() {
			if (closedButtonPressed.current) {
				closedButtonPressed.current = false;
				return;
			}

			if (fleetSelectionState.tempSelectedEntities?.length > 0 && !showDialog) {
				await mutateAsync({
					customerId: customerId,
					objectFunction: featuresSettings.assetTracking
						? ObjectFunctionFilter.All
						: ObjectFunctionFilter.Full,
					showPersons: featuresSettings.driverIdentification,
					showObjects: true,
					showInactive: fleetSelectionState.showInactive,
					filterText: '',
				});

				return;
			}

			if (!showDialog) {
				FleetSelectionDispatcher.setSelectedEntities(dispatch, [] as ITreeNode[]);
				FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, [] as ITreeNode[]);
				FleetSelectionDispatcher.setExpandedGroups(dispatch, [] as string[]);
			}
		}
	}, [showDialog]);

	useFleetSelectionUpdate(
		isDriverWithNoAuthorization,
		featuresSettings.driverIdentification,
		featuresSettings.assetTracking,
		customerId,
		fleetSelectionState
	);

	const saveSelectionCallback = (selection: ITreeNode[], showInactive: boolean) => {
		const addedGroups = selection.filter(
			(se) => se.type === 'Group' && !fleetSelectionState.selectedEntities.some((td) => se.id === td.id)
		);
		const newExpanded = [
			...fleetSelectionState.expandedGroups.filter((groupId) =>
				fleetSelectionState.selectedEntities.some(({ id }) => id === groupId)
			),
			...addedGroups.map(({ id }) => id),
		];
		FleetSelectionDispatcher.setExpandedGroups(dispatch, newExpanded);
		FleetSelectionDispatcher.setFleetSelectionShowInactive(dispatch, showInactive);
		let remainingTracked = fleetSelectionState.trackedEntities.filter((tracked) =>
			selection.some(({ id }) => id === tracked.id || tracked.parentId === id)
		);
		const extraSelection = selection.filter(
			(selectedItem) =>
				!fleetSelectionState.selectedEntities.some(
					(selectedEntities) =>
						selectedEntities.id === selectedItem.id && selectedItem.parentId === selectedEntities.parentId
				)
		);

		remainingTracked = remainingTracked.concat(extraSelection);

		let newTracked: ITreeNode[] = remainingTracked
			.map((tracked) => {
				const foundEntity = selection.find((data) => data.id === tracked.id || tracked.parentId === data.id);
				if (foundEntity.id === tracked.id) {
					return foundEntity;
				} else {
					return foundEntity.items.find((item) => item.id === tracked.id);
				}
			})
			.concat(
				...selection.map((newSelection) => {
					if (newSelection.childCount > 0) {
						const newSelectedChildren = newSelection.items.filter(
							(item) =>
								!fleetSelectionState.selectedEntities.some(
									(se) => item.id === se.id || se.items.some((i) => i.id === item.id)
								)
						);

						if (newSelectedChildren.length === 0) {
							return [];
						}

						if (
							newSelectedChildren.length === newSelection.childCount ||
							newSelection.items
								.filter((item) => !newSelectedChildren.some((newItem) => newItem.id === item.id))
								.every((item) =>
									fleetSelectionState.trackedEntities.some(
										(te) => item.id === te.id || te.items.some((i) => i.id === item.id)
									)
								)
						) {
							return newSelection;
						}

						return newSelectedChildren;
					} else if (
						fleetSelectionState.selectedEntities.some(
							(se) => newSelection.id === se.id || se.items.some((i) => i.id === newSelection.id)
						)
					) {
						return fleetSelectionState.trackedEntities.some(
							(te) => newSelection.id === te.id || te?.items?.some((i) => i.id === newSelection.id)
						)
							? newSelection
							: [];
					}

					return newSelection;
				})
			);
		//remove duplicates
		newTracked = newTracked.filter(
			(value, index, self) =>
				!!value && index === self.findIndex((s) => JSON.stringify(s) === JSON.stringify(value))
		);

		if (customerLevel === CustomerLevelEnum.Default) {
			//investigate in the future because without 3 lines above -> issue history and live on fleet selection tool
			ajaxUtil.post(`${GlobalSettings.fleetSelection}/Save`, {
				selectedEntities: selection,
				trackedEntities: fleetSelectionState.trackedEntities,
				showInactive: showInactive,
			} as FleetSelectionClientModel);
		}
		FleetSelectionDispatcher.setTempSelectedEntities(dispatch, selection);
		setShowDialog(false);
		saveTracked(newTracked);
	};

	const closeCallback = useCallback(
		(selection: ITreeNode[]) => {
			FleetSelectionDispatcher.setSelectedEntities(dispatch, selection);
			setShowDialog(false);
			closedButtonPressed.current = true;
		},
		[dispatch]
	);

	return (
		<>
			<ButtonBase onClick={() => setShowDialog(true)} hidden={isDriverWithNoAuthorization} disableRipple={true}>
				<BsFunnelFill
					color={'black'}
					style={{
						width: 24,
						height: 24,
					}}
				/>
			</ButtonBase>

			<FleetSelectionDialog
				visible={showDialog}
				initialSelection={fleetSelectionState.selectedEntities}
				closeCallback={closeCallback}
				saveCallback={saveSelectionCallback}
			/>
		</>
	);
};

export default FleetSelectionButton;
