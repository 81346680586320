import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import LocationCategoryItem from '../LocationCategoryOnMap/LocationCategoryItem';
import { useLocationCategoryArea } from './hooks';
import { ApplicationState } from '../../store';
import { CategoryAreaProps } from './types';
import './locationCategoryArea.scss';
import { Button, Tooltip } from '@material-ui/core';
import { TranslateText } from '../../utils/Translations';
import RemoveDoneIcon from '../Common/Icons/RemoveDoneIcon';
import AutoTextField from '../AutoTextfield/AutoTextField';
import { LocationCategory } from '../Widgets/Views/CustomerLocationCategoriesView';

const LocationCategoryArea = (props: CategoryAreaProps) => {
	const selectedCategories = useSelector((state: ApplicationState) =>
		props.isLiveMap ? state.liveMap.liveSelectedCategories : state.historyStore.historySelectedCategories
	);
	const mapCategories = useSelector((state: ApplicationState) => state.liveMap.mapCategories);
	const actions = useLocationCategoryArea(props.isLiveMap);
	const [localMapCategories, setLocalMapCategories] = useState<LocationCategory[]>(mapCategories);
	const [text, setText] = useState<string>('');

	useEffect(() => {
		text.length
			? setLocalMapCategories(
					mapCategories.filter((mp: LocationCategory) => mp.name.toLowerCase().includes(text.toLowerCase()))
			  )
			: setLocalMapCategories(mapCategories);
	}, [text]);

	return (
		localMapCategories && (
			<div className={'location-category-area'} ref={(r) => props.setRef(r)} style={!props.isLiveMap ? {right: 60} : {}}>
				<div className={'search-category-area'}>
					<span className={'deselect-button'}>
						{selectedCategories?.length === mapCategories?.length ? (
							<Tooltip title={TranslateText('fleetSelection.deselectAll')}>
								<Button onClick={actions.deselectAllCategories}>
									<RemoveDoneIcon />
								</Button>
							</Tooltip>
						) : (
							<Tooltip title={TranslateText('fleetSelection.selectAll')}>
								<Button onClick={() => actions.selectAllCategories(mapCategories)}>
									<SelectAllIcon />
								</Button>
							</Tooltip>
						)}
					</span>
					<span className={'search-field'}>
						<AutoTextField
							placeHolder={TranslateText('category.searchOnLocationCategories')}
							onChange={(newValue) => setText(newValue)}
							style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
							debounceDuration={100}
							value={text}
							showClearButton
						/>
					</span>
				</div>
				<div className={'search-items-area'}>
					{localMapCategories?.length ? (
						localMapCategories.map((se) => (
							<LocationCategoryItem
								key={se.id}
								locationCategory={se}
								selected={selectedCategories.some((sc) => sc.id === se.id)}
								selectCallback={(locationCategory, select) =>
									select
										? actions.selectCategory(locationCategory, selectedCategories)
										: actions.deselectCategory(locationCategory, selectedCategories)
								}
							/>
						))
					) : (
						<span className={'no-record-found-text'}>{TranslateText('common.noRecordFound')}</span>
					)}
				</div>
			</div>
		)
	);
};

export default LocationCategoryArea;
