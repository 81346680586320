import '../style.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import EntityTypeEnum from '../../../models/EntityTypeEnum';
import { ApplicationState } from '../../../store';
import ajaxUtil from '../../../utils/Ajax';
import { getDriverOrPersonRoute } from '../../../utils/DriverUtils';
import { TranslateText } from '../../../utils/Translations';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';

export interface GroupMember {
	id: string;
	name: string;
	memberId: string;
	groupId: string;
	active: boolean;
	type: EntityTypeEnum;
	icon?: string;
	connectionStartDate: Date;
	connectionEndDate?: Date;
}

const GroupMembersView = (props: IButtonWidgetView) => {
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);

	useEffect(() => {
		props.setUrl(
			`${GlobalSettings.groupsMaintenanceApi}/${props.entityId}/members?driverIdentification=${driverIdentification}`
		);
	}, [driverIdentification]);

	const terminateConnection = useCallback(
		async (rowData: GroupMember) => {
			if (rowData) {
				await ajaxUtil.post(`${GlobalSettings.groupsMaintenanceApi}/updateGroupMemberConnection`, {
					removedConnections: [
						{
							id: rowData.id,
							groupId: props.entityId,
							memberId: rowData.memberId,
							type: rowData.type,
						},
					],
				});
				props.reloadDataCallback();
			}
		},
		[props.entityId, props.reloadDataCallback]
	);

	return (
		<DataTable
			value={(props.data as GroupMember[]).sort((a, b) => a.name.localeCompare(b.name))}
			className="gridWidgetOverview"
			emptyMessage={TranslateText('widgets.grid.noDisplayRecords')}
		>
			<Column
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colName')}
				body={(rowData: GroupMember) =>
					props.editMode ? (
						<span className={rowData.active ? '' : 'inactiveEntity'}>{rowData.name}</span>
					) : (
						<Link
							className={rowData.active ? '' : ' inactiveEntity'}
							to={
								rowData.type === EntityTypeEnum.Object || rowData.type === EntityTypeEnum.Asset
									? `${GlobalSettings.route.objects}/${rowData.memberId}`
									: getDriverOrPersonRoute(
											user,
											driverIdentification,
											rowData.type === EntityTypeEnum.Driver,
											rowData.memberId
									  )
							}
						>
							{rowData.name}
						</Link>
					)
				}
			/>
			<Column
				className="clear-column"
				headerClassName={'small-header'}
				style={{ width: '40px' }}
				body={(rowData: GroupMember) =>
					props.editMode ? (
						<Icon className="clear-icon" onClick={() => terminateConnection(rowData)}>
							clear
						</Icon>
					) : null
				}
			/>
		</DataTable>
	);
};

export default GroupMembersView;
