import { History, LocationState } from 'history';
import React from 'react';

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import EntityTypeEnum from '../../models/EntityTypeEnum';
import FilterDialogModeEnum from '../../models/FilterDialogModeEnum';
import FilterEntityType from '../../models/FilterEntityType';
import IdIdentifiable from '../../models/IdIdentifiable';
import BaseWidget from '../BaseWidget';

export type BaseDetailsProps = {
	goBackToCallback: (show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) => void;
	currentRecordId: string;
	history: History<LocationState>;
	setUnsavedDataInStore?: (unsavedData: boolean) => void;
};

export type BaseDetailsState = {
	widgetEditing: boolean;
	widgetHasUnsavedData: boolean;
	notifyAboutUnsavedData: boolean;
	currentRecordId: string;
	breadcrumbDisplay: string;
	notificationContent: string;
	showDialog: boolean;
	dialogMode?: FilterDialogModeEnum;
	widgetEntityType?: EntityTypeEnum;
	filterEntityType?: FilterEntityType;
	exitWidgetEditMode?: boolean;
	reloadInfo: boolean;
};

class BaseDetails<
	P extends BaseDetailsProps = BaseDetailsProps,
	S extends BaseDetailsState = BaseDetailsState
> extends React.PureComponent<P, S> {
	constructor(props: P) {
		super(props);

		this.state = {
			widgetEditing: false,
			widgetHasUnsavedData: false,
			notifyAboutUnsavedData: false,
			currentRecordId: this.props.currentRecordId,
			breadcrumbDisplay: '',
			notificationContent: '',
			showDialog: false,
			dialogMode: FilterDialogModeEnum.Role,
			widgetEntityType: EntityTypeEnum.Role,
			filterEntityType: FilterEntityType.Role,
			exitWidgetEditMode: false,
			reloadInfo: false,
		} as S;
	}

	componentWillUnmount() {
		this.loadedWidgets = [];
	}

	protected checkForUnsavedData(): boolean {
		return this.state.widgetHasUnsavedData;
	}

	protected editModeCallback(editMode: boolean): void {
		this.setState({ widgetEditing: editMode });
	}

	protected changeDataCallback(hasChanges: boolean): void {
		this.setState({ widgetHasUnsavedData: hasChanges });
		this.props.setUnsavedDataInStore && this.props.setUnsavedDataInStore(hasChanges);
	}

	protected clearUnsavedData() {
		this.loadedWidgets
			.filter((widget) => widget !== null)
			.forEach((widget) => {
				widget.hasUnsavedData = false;
			});

		this.setState({ widgetHasUnsavedData: false });
		this.props.setUnsavedDataInStore && this.props.setUnsavedDataInStore(false);
	}

	protected handleGoBackTo(show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) {
		this.loadedWidgets = [];
		this.props.goBackToCallback(show, syncRecord, refreshGrid);
	}

	protected handleSavedCallback(sender: BaseWidget) {
		this.loadedWidgets
			.filter((widget) => widget !== null && (!sender || widget.id !== sender.id))
			.forEach((widget) => {
				setTimeout(() => {
					widget.reloadData();
				}, 100);
			});
	}

	protected syncWidgets(recordId: string) {
		this.setState({
			currentRecordId: recordId,
			widgetEditing: false,
		});
		this.fillWidgets(recordId);
		this.loadedWidgets
			.filter((widget) => widget !== null)
			.forEach((widget) => {
				widget.closeEditPanelMode();
			});
	}

	protected notifyAboutUnsavedData() {
		this.setState({ notifyAboutUnsavedData: true });
	}

	protected handleUnsavedDataResponse(response: boolean) {
		if (response) {
			this.clearUnsavedData();
			this.fillInformationWidget(this.state.currentRecordId);
			this.closeEditModeOnInformationWidget();
		}
		this.setState({ notifyAboutUnsavedData: false });
	}

	protected fillWidgets(recordId: string) {
		this.fillInformationWidget(recordId);
	}

	protected fillInformationWidget(recordId: string) {
		throw Error('Must be overriden in derived class');
	}

	protected closeEditModeOnInformationWidget() {
		throw Error('Must be overriden in derived class');
	}

	protected AddLoadedWidget(widget: BaseWidget) {
		if (widget) {
			const index = this.loadedWidgets.findIndex((x) => x.id === widget.id);
			if (index >= 0) {
				this.loadedWidgets[index] = widget;
			} else {
				this.loadedWidgets.push(widget);
			}
		}
	}
	public loadedWidgets: BaseWidget[] = [];

	// define the dashboard spacing between pannels
	cellSpacing = [5, 5];
	// store the reference to the DashboardLayoutComponent
	dashboardObj: DashboardLayoutComponent;

	removePanel(panelId: string) {
		this.dashboardObj.removePanel(panelId);
	}
}

export default BaseDetails;
