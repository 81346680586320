import { History, LocationState } from 'history';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';

import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { GridOverview } from '../GridOverview/GridOverview';
import {GridFilters, MatchMode, SortOrder} from "../../models/GridOverview";
import TextColumn from "../GridOverview/Columns/TextColumn";
import NumberColumn from "../GridOverview/Columns/NumberColumn";
import BooleanColumn from "../GridOverview/Columns/BooleanColumn";
import NewGridOverview from "../GridOverview/NewGridOverview";
import CustomerLevelEnum from "../../models/CustomerLevelEnum";

interface RoleOverviewDto {
	id: string;
	code: string;
	name: string;
	description: string;
	numberOfUsers: number;
	numberOfReports: number;
	active: boolean;
	customerId: string;
	customerName: string;
}

interface Props {
	history: History<LocationState>;
}

const fieldNames = {
	customerName: 'customerName',
	code: 'code',
	name: 'name',
	description : 'description',
	numberOfUsers: 'numberOfUsers',
	numberOfReports: 'numberOfReports',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.numberOfUsers]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.numberOfReports]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	}
}
const RoleOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (rowData: RoleOverviewDto) => (
				<Link title={rowData.customerName} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Customers),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		NumberColumn({
			fieldName: fieldNames.numberOfUsers,
			header: TranslateText('maintenanceOverview.grid.colNumberOfUsers'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Persons,
				values: [ClaimValue.view],
			}),
		}),
		NumberColumn({
			fieldName: fieldNames.numberOfReports,
			header: TranslateText('maintenanceOverview.grid.colNumberOfReports'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.edit],
			}),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return(
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.rolesMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.roles')}
			disableAddButton={false}
			showAddConfigClaim={[
				{
					claim: ClaimType.Roles,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Role}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};
export { RoleOverview };
