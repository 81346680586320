import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import IntegrationContacts from 'components/NewAddWizard/Views/Integration/IntegrationContacts';
import IntegrationInformation from 'components/NewAddWizard/Views/Integration/IntegrationInformation';
import { IntegrationValidationConfig } from 'components/NewAddWizard/Views/Integration/Utils';
import { WidgetViewDefault } from 'components/Widgets/Widget';
import IntegrationDto from 'models/IntegrationDto';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Validator from 'shared/validation/Validator';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import { TranslateText } from 'utils/Translations';

type Props = WidgetViewDefault & {
	data: IntegrationDto;
	initialData: IntegrationDto;
};

const IntegrationInformationView = (props: Props) => {
	const dispatch = useDispatch();
	const [accordionIndex, setAccordionIndex] = useState<number>(0);

	useEffect(() => {
		if (props.initialData) {
			if (props.setDashboardTitleCallback) {
				props.setDashboardTitleCallback(`${props.initialData.name}`);
			}
			dispatch(
				loadedEntityContextActionCreators.setLoadedIntegrationContext(props.initialData as IntegrationDto)
			);
		}
	}, [props.initialData]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: IntegrationValidationConfig(false) }, true));
	}, [props.entityId]);

	const handleValueChangeMaterial = (
		value: IntegrationDto[keyof IntegrationDto],
		statePropName: keyof IntegrationDto
	) => {
		const newIntegration = { ...props.data, [statePropName]: value };
		props.changeDataCallback(newIntegration, true);
	};

	return (
		<>
			<Accordion
				scrollToTop={props.scrollToTop}
				expandedCallback={(index: number) => {
					setAccordionIndex(index);
				}}
			>
				<AccordionSection
					expanded={accordionIndex === 0}
					header={TranslateText('common.systemInformation')}
					className="auto-system-info"
				>
					<IntegrationInformation
						integration={props.data}
						customerId={props.data.customerId}
						validationResult={props.validationResult}
						handleValueChangeMaterial={handleValueChangeMaterial}
						isAddNew={false}
						editMode={props.editMode}
						isForNewAddWizard={false}
					/>
				</AccordionSection>
				<AccordionSection
					expanded={accordionIndex === 1}
					header={TranslateText('common.contacts')}
					className="auto-contacts-data"
				>
					<IntegrationContacts
						integration={props.data}
						validationResult={props.validationResult}
						handleValueChangeMaterial={handleValueChangeMaterial}
						editMode={props.editMode}
						isForNewAddWizard={false}
					/>
				</AccordionSection>
			</Accordion>
		</>
	);
};

export default IntegrationInformationView;
