import { enableRipple } from '@syncfusion/ej2-base';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { NavigateBefore } from '@material-ui/icons';

enableRipple(true);

enum MenuType {
	None,
	Config,
}

interface BackToMainMenuProps {
	requestMenu: (menu: MenuType) => void;
}

const BackToMainMenu = (props: BackToMainMenuProps) => {
	return (
		<div className="sidebar-item-back-to-menu" id="toggle" onClick={() => props.requestMenu(MenuType.None)}>
			<NavigateBefore />
			<span className="e-back-to-menu-text">
				<Trans>menu.configMenu.backToMenu</Trans>
			</span>
		</div>
	);
};

export default BackToMainMenu;
