import '../connectionWidget.scss';

import BaseView from 'components/BaseView';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { DialogUtil } from 'components/Common/NotificationDialog/NotificationDialog';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import KeyView from 'components/Views/KeyView';
import PersonView from 'components/Views/PersonView';
import RenderForEnum from 'components/Views/RenderForEnum';
import UsedFromEnum from 'components/Views/UsedFromEnum';
import WidgetStateEnum from 'components/Views/WidgetStateEnum';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import Key from 'models/Key';
import { Person } from 'models/Person';
import PersonKeyConnection from 'models/PersonKeyConnection';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

import { Button, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../Constants';
import { DebouncedButton } from '../../Common/DebouncedButton';
import { CustomerPersonSettings } from '../PersonObjectWidget/AddConnection';

const mapStateToProps = (state: ApplicationState) => {
	return {
		customerId: state.loadedEntityContext.entityContextData.customerId,
		defaultCustomerId: state.currentSession.customerId,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterTextObject: state.globalCustomer.filterText,
		userFirstAndLastName: state.currentSession.userFirstAndLastName,
		aspNetUserId: state.currentSession.aspNetUserId,
		displayText: state.loadedEntityContext.entityContextData.displayText,
		autoGeneratePersonCode: state.currentSession.customer.entityDefaultsSettings.autoGeneratePersonCode,
		externalCodeSameCode: state.currentSession.customer.entityDefaultsSettings.externalCodeSameCode,
	};
};

function mapDispatch(dispatch: Dispatch) {
	return {
		unsavedDataActions: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

const connector = connect(mapStateToProps, mapDispatch, null, { forwardRef: true });
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	getAccessTokenCallback: () => string;
	visible: boolean;
	renderFor: EntityTypeEnum;
	showDialog: boolean;
	currentEntityMode: boolean;
	dialogCloseCallback: () => void;
	entityId: string;
	savedCallback: (sender: any) => void;
};

type State = {
	invalidForm: boolean;
	invalidNewEntityForm: boolean;
	entityCustomerId: string;
	customerPersonSettings: CustomerPersonSettings;
	selectedTabIndex: number;
	existingEntityTabInit: boolean;
	newEntityTabInit: boolean;
	personKeyConnection: PersonKeyConnection;
	newPersonKeyConnection: PersonKeyConnection;
	validationResult: ValidationResult;
	productsData: AutocompleteItem[];
	objectsData: AutocompleteItem[];
	isLoading: boolean;
	isDialogOpen: boolean;
};

class AddConnection extends BaseView<Props, State> {
	conflictsCanBeAutomaticallyFixed = false;
	conflictsCanBeAutomaticallyFixedNewPerson = false;
	conflictingConnections: PersonKeyConnection[];
	conflictinConnectionNewPerson: PersonKeyConnection[];
	proposedConnectionCanBeSavedNewEntity: boolean;
	conflictingConnectionNotifierRef: ConnectionConflictsNotifier;
	conflictReceivedOnExistingEntity = false;
	newPerson: Person;
	unsavedNewData = false;
	unsavedData = false;
	keyViewRef: any;
	newKey: Key = new Key();
	newEntityValidator: Validator;
	existingEntityValidator: Validator;
	personViewRef: any;

	customerId: string;
	dataFilter: Object;

	constructor(props: Props) {
		super(props);

		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Key ? FilterFieldTypeEnum.keyId : FilterFieldTypeEnum.personId,
			operator: 'equal',
			fieldValue: props.entityId,
			excludeConnectedEntities:
				this.props.renderFor === EntityTypeEnum.Key ||
				this.props.renderFor === EntityTypeEnum.Person ||
				this.props.renderFor === EntityTypeEnum.Driver,
		};

		const newPersonKeyConnection = new PersonKeyConnection();
		newPersonKeyConnection.connectionStartDate = new Date();
		this.state = {
			invalidForm: true,
			invalidNewEntityForm: true,
			entityCustomerId: this.props.customerId,
			customerPersonSettings: {
				autoGeneratePersonCode: this.props.autoGeneratePersonCode,
				externalCodeSameCode: this.props.externalCodeSameCode,
			},
			selectedTabIndex: 0,
			existingEntityTabInit: false,
			newEntityTabInit: false,
			productsData: [],
			objectsData: [],
			personKeyConnection: newPersonKeyConnection,
			newPersonKeyConnection: null,
			validationResult: null,
			isLoading: false,
			isDialogOpen: false,
		};

		this.setEntityCustomerId();
		this.newPerson = new Person(this.state.entityCustomerId);

		this.setFormValidatorForExistingEntity();
		this.setFormValidatorForNewEntity();
	}

	async componentDidMount() {
		this.entityChanged();
	}

	async componentDidUpdate(prevProps: { showDialog: boolean; currentEntityMode: boolean; entityId: string }) {
		if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
			this.showDialog();
		}

		if (prevProps.entityId !== this.props.entityId) {
			this.entityChanged();
		}
	}

	async entityChanged() {
		this.dataFilter = {
			fieldType:
				this.props.renderFor === EntityTypeEnum.Key ? FilterFieldTypeEnum.keyId : FilterFieldTypeEnum.personId,
			operator: 'equal',
			fieldValue: this.props.entityId,
			excludeConnectedEntities:
				this.props.renderFor === EntityTypeEnum.Person ||
				this.props.renderFor === EntityTypeEnum.Driver ||
				this.props.renderFor === EntityTypeEnum.Key,
		};

		//recompute lists when entity has been changed
		this.prepareProductsList();
		this.getObjectsList();

		await this.setEntityCustomerId().then(async () => {
			await ajaxUtil
				.get<CustomerPersonSettings>(
					`${GlobalSettings.customersMaintenanceApi}/CustomerPersonSettings/${this.state.entityCustomerId}`
				)
				.then((result) => {
					this.setState({
						customerPersonSettings: {
							autoGeneratePersonCode: result.autoGeneratePersonCode,
							externalCodeSameCode: result.externalCodeSameCode,
						},
					});
				});
		});
	}

	async setEntityCustomerId() {
		const url = GlobalSettings.connectionsApi + '/EntityCustomerId?filter=' + JSON.stringify(this.dataFilter);

		await ajaxUtil.get(url).then((id: any) => {
			this.setState({ entityCustomerId: String(id) });
		});
	}

	getObjectsList() {
		const url = GlobalSettings.listsApi + '/Objects?filter=' + JSON.stringify(this.dataFilter);
		ajaxUtil.get<AutocompleteItem[]>(url).then((objects) => {
			this.setState({ objectsData: objects });
		});
	}

	setEntity(valid: boolean, personKeyConnection: PersonKeyConnection, afterStatechange?: () => void) {
		this.setUnsavedData(true, this.unsavedNewData);

		if (afterStatechange) {
			this.setState(
				{
					invalidForm: !valid,
					personKeyConnection: personKeyConnection,
				},
				() => {
					afterStatechange();
				}
			);
		} else {
			this.setState({
				invalidForm: !valid,
				personKeyConnection: personKeyConnection,
			});
		}
	}

	getNewEntityDefault() {
		const newPersonKeyConnection = new PersonKeyConnection();
		newPersonKeyConnection.connectionStartDate = new Date();
		newPersonKeyConnection.resolveConflictsAutomatically = false;

		switch (this.props.renderFor) {
			case EntityTypeEnum.Key: {
				newPersonKeyConnection.keyId = this.props.entityId;
				break;
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				newPersonKeyConnection.personId = this.props.entityId;
				break;
			}
		}

		return newPersonKeyConnection;
	}

	getExistingEntityDefault() {
		const newPersonKeyConnection = new PersonKeyConnection();
		newPersonKeyConnection.connectionStartDate = new Date();
		if (this.props.renderFor === EntityTypeEnum.Key) {
			newPersonKeyConnection.keyId = this.props.entityId;
		} else {
			newPersonKeyConnection.personId = this.props.entityId;
		}

		return newPersonKeyConnection;
	}

	showDialog() {
		this.newPerson = null;
		this.newKey = null;
		this.setUnsavedData(false, false);

		this.proposedConnectionCanBeSavedNewEntity = true;
		this.proposedConnectionCanBeSaved = true;

		const tabIndex = this.props.currentEntityMode ? 0 : 1;
		this.setState(
			{
				isLoading: false,
				selectedTabIndex: tabIndex,
				personKeyConnection: this.getExistingEntityDefault(),
				newPersonKeyConnection: this.getNewEntityDefault(),
				validationResult: null,
			},
			() => {
				this.existingEntityValidator.clearValidation();
				this.newEntityValidator.clearValidation();

				this.handleTabItemSelected(tabIndex);
			}
		);
		this.prepareProductsList();
		this.getObjectsList();
	}

	setFormValidatorForExistingEntity() {
		this.props.renderFor === EntityTypeEnum.Key
			? (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							personId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.existingEntityValidator = new Validator(
					{
						fieldRules: {
							keyId: {
								rules: {
									required: ValidatorFunctions.required(),
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(this.proposedConnectionCanBeSaved),
									},
								},
							},
						},
					},
					true
			  ));
	}

	setFormValidatorForNewEntity() {
		this.props.renderFor === EntityTypeEnum.Key
			? (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							keyId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ))
			: (this.newEntityValidator = new Validator(
					{
						fieldRules: {
							personId: {
								rules: {
									anyConflicts: {
										message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
											TranslateText('connections.' + this.props.renderFor.toLowerCase()),
										]),
										validationFunction: () =>
											ValidatorFunctions.wrapInPromise(
												this.proposedConnectionCanBeSavedNewEntity
											),
									},
								},
							},
						},
					},
					true
			  ));
	}

	async handleValueChange(value: string, statePropName: string) {
		const temppersonKeyConnection = new PersonKeyConnection(this.state.personKeyConnection);
		if (temppersonKeyConnection) {
			temppersonKeyConnection.resolveConflictsAutomatically = null; //reset resolved conflict when entity changed
			if (statePropName === 'productId') {
				if (this.props.renderFor === EntityTypeEnum.Key) {
					temppersonKeyConnection.personId = value;
				} else {
					temppersonKeyConnection.keyId = value;
				}
			}

			this.setEntity(false, temppersonKeyConnection, () => {
				this.checkEntitiesConnectionConflicts(true).then(() => {
					this.setValidationStateForExisting();
				});
			});
		}
	}

	async checkEntitiesConnectionConflicts(
		existingEntity: boolean,
		connectionStartDate: Date = null
	): Promise<boolean> {
		let candidateConnection: any;

		switch (this.props.renderFor) {
			case EntityTypeEnum.Key: {
				if (existingEntity) {
					if (!this.state.personKeyConnection.personId) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.state.personKeyConnection.personId,
						entity2Id: this.props.entityId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.personKeyConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonKey,
					};
				} else {
					if (!this.state.newPersonKeyConnection.keyId) {
						return true;
					}
					candidateConnection = {
						entity1Id: null,
						entity2Id: this.state.newPersonKeyConnection.keyId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newPersonKeyConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonKey,
					};
				}

				break;
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				if (existingEntity) {
					if (!this.state.personKeyConnection.keyId) {
						return true;
					}
					candidateConnection = {
						entity1Id: this.props.entityId,
						entity2Id: this.state.personKeyConnection.keyId,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.personKeyConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonKey,
					};
				} else {
					if (!this.state.newPersonKeyConnection.personId) {
						return true;
					}

					candidateConnection = {
						entity1Id: this.state.newPersonKeyConnection.personId,
						entity2Id: null,
						connectionStartDate: connectionStartDate
							? connectionStartDate
							: this.state.newPersonKeyConnection.connectionStartDate,
						connectionType: ConnectionTypeEnum.PersonKey,
					};
				}

				break;
			}
		}

		const url = GlobalSettings.connectionsApi + '/ValidateEntitiesCandidateConnection';
		this.setState({ isLoading: true });
		return await ajaxUtil.post(url, candidateConnection).then((result: any) => {
			this.setState({ isLoading: false });
			if (existingEntity) {
				if (result.conflictPresent && !this.state.personKeyConnection.resolveConflictsAutomatically) {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						result.conflictingConnections,
						result.fixableConflict
					);
					this.conflictReceivedOnExistingEntity = true;
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSaved = false;
					return false;
				} else {
					this.conflictingConnections = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixed = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSaved = true;
					return true;
				}
			} else {
				if (result.conflictPresent && !this.state.newPersonKeyConnection.resolveConflictsAutomatically) {
					this.conflictinConnectionNewPerson = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewPerson = result.fixableConflict;
					this.conflictingConnectionNotifierRef.setConflictingConnectionsContext(
						this.conflictinConnectionNewPerson,
						this.conflictsCanBeAutomaticallyFixedNewPerson
					);
					this.conflictingConnectionNotifierRef.showDialog(this);
					this.proposedConnectionCanBeSavedNewEntity = false;
					return false;
				} else {
					this.conflictinConnectionNewPerson = result.conflictingConnections;
					this.conflictsCanBeAutomaticallyFixedNewPerson = result.fixableConflict;
					this.handleConflictingConnections(true);
					this.proposedConnectionCanBeSavedNewEntity = true;
					return true;
				}
			}
		});
	}

	prepareProductsList() {
		let url = '';
		switch (this.props.renderFor) {
			case EntityTypeEnum.Key: {
				url =
					GlobalSettings.driversMaintenanceApi + '/driversForKeys?filter=' + JSON.stringify(this.dataFilter);
				break;
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				url = GlobalSettings.keysMaintenanceApi + '/keysForDrivers?filter=' + JSON.stringify(this.dataFilter);
				break;
			}
		}

		ajaxUtil.get<AutocompleteItem[]>(url).then((data) => {
			this.setState({
				productsData: data,
			});
		});
	}

	getHeaderText(): string {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Key:
				return TranslateText('connections.driverKeyHeader');
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person:
				return TranslateText('connections.keyDriverHeader');
		}
	}

	connect(existingEntity: boolean) {
		let newConnection: any;

		switch (this.props.renderFor) {
			case EntityTypeEnum.Key: {
				if (existingEntity) {
					newConnection = {
						keyId: this.props.entityId,
						personId: this.state.personKeyConnection.personId,
						connectionStartDate: this.state.personKeyConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						keyId: this.props.entityId,
						personId: this.state.newPersonKeyConnection.personId,
						connectionStartDate: this.state.newPersonKeyConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newPersonKeyConnection.resolveConflictsAutomatically,
					};
				}
				break;
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				if (existingEntity) {
					newConnection = {
						keyId: this.state.personKeyConnection.keyId,
						personId: this.props.entityId,
						connectionStartDate: this.state.personKeyConnection.connectionStartDate,
						resolveConflictsAutomatically: this.conflictsCanBeAutomaticallyFixed,
					};
				} else {
					newConnection = {
						keyId: this.state.newPersonKeyConnection.keyId,
						personId: this.props.entityId,
						connectionStartDate: this.state.newPersonKeyConnection.connectionStartDate,
						resolveConflictsAutomatically: this.state.newPersonKeyConnection.resolveConflictsAutomatically,
					};
				}
				break;
			}
		}

		const url = `${GlobalSettings.connectionsApi}/CreatePersonKeyConnection`;
		ajaxUtil
			.post(url, newConnection)
			.then(() => {
				this.cleanupData();
				//if connection is charged update available entities to include/exclude newly added entity(key or person)
				this.prepareProductsList();

				this.props.savedCallback(null);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
			});
	}

	tryCloseDialog() {
		if (this.unsavedData || this.unsavedNewData) {
			this.notifyAboutUnsavedData();
			return;
		}

		this.cleanupData();
	}

	onBeforeClose() {
		this.props.dialogCloseCallback();
	}

	notifyAboutUnsavedData() {
		if (this.state.isDialogOpen) return;

		this.setState({
			isDialogOpen: true,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then((response: boolean) => {
			this.setState({
				isDialogOpen: false,
			});
			if (response) {
				//yes was clicked
				this.cleanupData();
			}
		});
	}

	private cleanupData() {
		this.clearUnsavedData();

		this.setState(
			{
				selectedTabIndex: 0,
				existingEntityTabInit: false,
				newEntityTabInit: false,
				personKeyConnection: this.getExistingEntityDefault(),
				newPersonKeyConnection: this.getNewEntityDefault(),
			},
			() => {
				if (this.personViewRef) {
					this.personViewRef.clearView();
				}

				if (this.keyViewRef) {
					this.keyViewRef.clearView();
				}

				this.props.dialogCloseCallback();
			}
		);
	}

	async validateAndConnect() {
		this.setState({ isLoading: true }, () => {
			this.checkEntitiesConnectionConflicts(true)
				.then((noConflicts) => {
					if (noConflicts) {
						this.connect(true);
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	clearUnsavedData() {
		this.unsavedData = false;
		this.unsavedNewData = false;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	setUnsavedData(unsavedData: boolean, unsavedNewData: boolean) {
		this.unsavedData = unsavedData;
		this.unsavedNewData = unsavedNewData;
		this.props.unsavedDataActions(this.unsavedData || this.unsavedNewData);
	}

	private handleConfictingConnections(sender: any, proposedConnectionCanBeSaved: boolean) {
		if (this.conflictReceivedOnExistingEntity) {
			this.proposedConnectionCanBeSaved = proposedConnectionCanBeSaved;
			const tempPersonKeyConnection = new PersonKeyConnection(this.state.personKeyConnection);
			tempPersonKeyConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;
			this.conflictReceivedOnExistingEntity = false;

			this.setState({ personKeyConnection: tempPersonKeyConnection }, () => {
				this.setValidationStateForExisting();
			});
		} else {
			this.proposedConnectionCanBeSavedNewEntity = proposedConnectionCanBeSaved;

			const newPersonKeyConnection = new PersonKeyConnection(this.state.newPersonKeyConnection);
			newPersonKeyConnection.resolveConflictsAutomatically = proposedConnectionCanBeSaved;
			this.setState({ newPersonKeyConnection: newPersonKeyConnection }, () => {
				this.setValidationStateForNew();
			});
		}
	}

	contentCurrentEntity = () => {
		return (
			<>
				<div className="e-panel-content connection-dialog-form">
					<form id="kpConnectionExistingEntityForm" noValidate={true}>
						<MaterialAutocomplete
							valueId={
								this.props.renderFor === EntityTypeEnum.Key
									? this.state.personKeyConnection?.personId
									: this.state.personKeyConnection?.keyId
							}
							dataSource={this.state.productsData}
							name="kpConnectionProductListId"
							disabled={this.state.isLoading}
							onChange={({ value }) => this.handleValueChange(value as string, 'productId')}
							className="material-autocomplete"
							label={
								this.props.renderFor === EntityTypeEnum.Key
									? TranslateText('connections.selectDriverPlaceholder')
									: TranslateText('connections.selectKeyPlaceholder')
							}
						/>
					</form>
				</div>
				<div className="connection-handle-buttons">
					<div>
						<ValidationMessage
							result={
								this.props.renderFor === EntityTypeEnum.Key
									? this.state.validationResult?.personId
									: this.state.validationResult?.keyId
							}
						/>
					</div>
					<div className="left-side-buttons">
						<Button onClick={() => this.tryCloseDialog()} disabled={this.state.isLoading}>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-connect-button"
							onClick={async () => await this.validateAndConnect()}
							disabled={this.state.invalidForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.connect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	setPerson(personData: Person, unsavedData: boolean) {
		this.setUnsavedData(this.unsavedData, unsavedData);
		this.newPerson = personData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setKey(valid: boolean, keyData: Key, unsavedData: boolean) {
		this.setUnsavedData(this.unsavedData, unsavedData);
		this.newKey = keyData;

		if (this.state.selectedTabIndex === 1) {
			this.setValidationStateForNew();
		}
	}

	setValidationStateForNew() {
		switch (this.props.renderFor) {
			case EntityTypeEnum.Key: {
				if (this.personViewRef) {
					this.personViewRef.validateBeforeSave().then((embeddedFormValidationResult: any) => {
						this.newEntityValidator?.validate(this.state.newPersonKeyConnection).then((result) => {
							this.setState({
								validationResult: result.validationResult,
								invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
							});
						});
					});
				}
				break;
			}
			case EntityTypeEnum.Driver:
			case EntityTypeEnum.Person: {
				if (this.keyViewRef) {
					this.keyViewRef.validateBeforeSave().then((embeddedFormValidationResult: any) => {
						this.newEntityValidator?.validate(this.state.newPersonKeyConnection).then((result) => {
							this.setState({
								validationResult: result.validationResult,
								invalidNewEntityForm: !(embeddedFormValidationResult && result.formResult),
							});
						});
					});
				}
				break;
			}
		}
	}

	setValidationStateForExisting() {
		this.existingEntityValidator.validate(this.state.personKeyConnection).then((result) => {
			this.setState({
				validationResult: result.validationResult,
				invalidForm: !result.formResult,
			});
		});
	}

	saveNewPerson() {
		const newPerson = {
			...this.newPerson,
			customerId: this.state.entityCustomerId,
			birthDate: new Date(this.newPerson.birthDate),
			objectPersonConnectionStartDate: this.state.newPersonKeyConnection?.connectionStartDate,
		};

		return ajaxUtil
			.post(GlobalSettings.driversMaintenanceApi, {
				AddModel: newPerson,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText: this.props.filterTextObject.keys,
			})
			.then((data: any) => {
				if (data) {
					return data.id;
				}
				return '';
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return '';
			});
	}

	saveNewKey() {
		const url = GlobalSettings.keysMaintenanceApi;
		const newKey = {
			code: this.newKey.code,
			customerId: this.state.entityCustomerId,
			personId: this.newKey.personId ? this.newKey.personId : null,
			userId: this.props.aspNetUserId,
			serialNumber: this.newKey.serialNumber,
			productionDate: this.newKey.productionDate,
			personKeyConnectionStartDate: this.state.newPersonKeyConnection?.connectionStartDate,
			active: this.newKey.active,
		};

		return ajaxUtil
			.post<{ id: string }>(url, {
				AddModel: newKey,
				GlobalCustomer: this.props.globalCustomer ? this.props.globalCustomer.id : null,
				FilterText:
					this.props.renderFor === EntityTypeEnum.Person
						? this.props.filterTextObject.persons
						: this.props.filterTextObject.drivers,
			})
			.then((data) => {
				if (data) {
					return data.id;
				}
				return '';
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.log(error);
				return '';
			});
	}

	async handleAddEntityAndConnect() {
		this.setState({ isLoading: true }, () => {
			// validate future connection
			this.checkEntitiesConnectionConflicts(false)
				.then((noConflicts) => {
					if (noConflicts) {
						switch (this.props.renderFor) {
							case EntityTypeEnum.Key: {
								this.saveNewPerson().then((idOfNewPerson) => {
									if (idOfNewPerson) {
										this.newPerson.id = idOfNewPerson;
										const newPersonKeyConnection = new PersonKeyConnection(
											this.state.newPersonKeyConnection
										);
										newPersonKeyConnection.personId = idOfNewPerson;
										this.setState({ newPersonKeyConnection: newPersonKeyConnection }, () => {
											this.connect(false);
										});
									}
								});
								break;
							}
							case EntityTypeEnum.Driver:
							case EntityTypeEnum.Person: {
								this.saveNewKey().then((idOfNewKey) => {
									if (idOfNewKey) {
										this.newKey.id = idOfNewKey;
										const newPersonKeyConnection = new PersonKeyConnection(
											this.state.newPersonKeyConnection
										);
										newPersonKeyConnection.keyId = idOfNewKey;
										this.setState({ newPersonKeyConnection: newPersonKeyConnection }, () => {
											this.connect(false);
										});
									}
								});
								break;
							}
						}
					} else {
						this.setState({ isLoading: false });
					}
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
				});
		});
	}

	contentNewEntity = () => {
		return (
			<>
				<div className="e-panel-content auto-link-person-to-key connection-dialog-form">
					{this.props.renderFor === EntityTypeEnum.Key ? (
						<PersonView
							widgetState={WidgetStateEnum.Edit}
							ref={(scope) => (this.personViewRef = scope)}
							defaultCustomerId={this.state.entityCustomerId}
							defaultCustomerSettings={this.state.customerPersonSettings}
							setPerson={this.setPerson.bind(this)}
							objectsData={this.state.objectsData}
							renderFor={RenderForEnum.ConnectDialog}
							addNew={this.state.selectedTabIndex === 1}
							usedFrom={UsedFromEnum.Key}
							readOnly={false}
						/>
					) : (
						<KeyView
							userFirstAndLastName={this.props.userFirstAndLastName}
							defaultCustomerId={this.state.entityCustomerId}
							renderFor={RenderForEnum.ConnectDialog}
							ref={(input) => {
								this.keyViewRef = input;
							}}
							setKey={this.setKey.bind(this)}
							readOnly={false}
							widgetState={WidgetStateEnum.Edit}
						/>
					)}
				</div>
				<div className="connection-handle-buttons">
					<div>
						<div style={{ marginTop: 5 }}>
							<ValidationMessage
								result={
									this.props.renderFor === EntityTypeEnum.Key
										? this.state.validationResult?.keyId
										: this.state.validationResult?.personId
								}
							/>
						</div>
					</div>
					<div className="left-side-buttons">
						<Button onClick={() => this.tryCloseDialog()}>{TranslateText('common.buttonCancel')}</Button>
					</div>
					<div className="right-side-buttons">
						<DebouncedButton
							className="auto-add-and-connect-button"
							onClick={async () => await this.handleAddEntityAndConnect()}
							disabled={this.state.invalidNewEntityForm || this.state.isLoading}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.addAndConnect')}
						</DebouncedButton>
					</div>
				</div>
			</>
		);
	};

	render() {
		return (
			<Dialog
				onClose={() => this.tryCloseDialog()}
				className="connection-dialog add-connection-dialog"
				open={this.props.showDialog}
				fullWidth
				disableBackdropClick
			>
				<DialogTitle>{this.getHeaderText()}</DialogTitle>
				<DialogContent>
					<ConnectionConflictsNotifier
						ref={(scope) => {
							this.conflictingConnectionNotifierRef = scope;
						}}
						connectionType={ConnectionTypeEnum.PersonKey}
						visible={false}
						userAnswearCallback={this.handleConfictingConnections.bind(this)}
					/>
					<Tabs
						className="connection-dialog-tab"
						value={this.state.selectedTabIndex}
						onChange={(e, newValue: number) => {
							this.setState({ selectedTabIndex: newValue }, () => {
								this.handleTabItemSelected(newValue);
							});
						}}
					>
						<Tab
							value={0}
							className="auto-current-key"
							label={
								this.props.renderFor === EntityTypeEnum.Key
									? TranslateText('connections.currentDriver')
									: TranslateText('connections.currentKey')
							}
						/>
						<Tab
							value={1}
							className="auto-new-key"
							disabled={this.state.isLoading}
							label={
								this.props.renderFor === EntityTypeEnum.Key
									? TranslateText('connections.toNewDriver')
									: TranslateText('connections.toNewKey')
							}
						/>
					</Tabs>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 0}>
						{this.contentCurrentEntity()}
					</div>
					<div className="connection-dialog-content" hidden={this.state.selectedTabIndex !== 1}>
						{this.contentNewEntity()}
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	handleTabItemSelected(selectedIndex: number): void {
		if (selectedIndex === 0) {
			if (!this.state.existingEntityTabInit) {
				this.setState({ existingEntityTabInit: true });
			}

			//validate tab
			this.setValidationStateForExisting();
		} else if (selectedIndex === 1) {
			if (this.state.newEntityTabInit) {
				if (this.personViewRef) {
					this.personViewRef.focusDefaultInput();
				}
				if (this.keyViewRef) {
					this.keyViewRef.focusDefaultInput();
				}

				//validate tab
				this.setValidationStateForNew();
			} else {
				if (this.personViewRef) {
					this.personViewRef.prepareViewWithData(this.state.entityCustomerId);
				}

				if (this.keyViewRef) {
					this.keyViewRef.prepareViewWithData(
						this.state.entityCustomerId,
						this.props.entityId,
						this.props.displayText
					);
				}

				this.setState({ newEntityTabInit: true });

				//validate tab
				this.setValidationStateForNew();
			}
		}
	}
}

export default connector(AddConnection);
