import './styles.scss';

import ClaimType from 'authorization/ClaimType';
import EasyTrackFeature from 'authorization/EasyTrackFeature';
import ProtectedContainer from 'components/Layout/SideBar/ProtectedContainer';
import React, { useRef } from 'react';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { FormatDate, GetHourAndMinutes, isSameDay } from 'utils/DateUtils';

import { IconButton } from '@material-ui/core';
import { Close, History, Map } from '@material-ui/icons';

import { useOnScreenView } from './hooks';
import { AlertsNotificationItemProps } from './types';

const AlertsNotificationItem = (props: AlertsNotificationItemProps): JSX.Element => {
	const refEl = useRef<HTMLDivElement>();

	useOnScreenView(refEl, props.notification, props.setNotificationAsSeen);

	return (
		<ReactScrollIntoViewIfNeeded
			active={props.isSelected}
			options={{ behavior: 'auto', scrollMode: 'if-needed', block: 'nearest' }}
		>
			<div
				ref={refEl}
				onClick={() => {
					props.setSelectedNotification(props.isSelected ? '' : props.notification.id);
				}}
				className={`alerts-notification-item ${props.isSelected ? 'expanded' : 'collapsed'}`}
			>
				<div className="notification-container">
					<div className="notification-content">
						<div className="notification-title">
							<div className="notification-date">
								{isSameDay(props.notification.createDate)
									? GetHourAndMinutes(props.notification.createDate)
									: FormatDate(props.notification.createDate, false, false)}
							</div>
							{props.showCustomer ? (
								<div className="notification-customer">{props.notification.customerName}</div>
							) : null}
						</div>

						<div className="notification-text">{props.notification.plainTextMessage}</div>
					</div>
					<div>
						<IconButton
							className="remove-icon"
							onClick={(e) => {
								e.stopPropagation();
								props.removeAlertNotification([props.notification.id]);
							}}
						>
							<Close />
						</IconButton>
					</div>
				</div>
				{props.isSelected ? (
					<div className="redirect-links">
						<ProtectedContainer claim={ClaimType.Live} feature={EasyTrackFeature.Live}>
							<button
								onClick={(e) => {
									e.stopPropagation();
									props.notificationRedirect(
										props.notification.customerId,
										props.notification.createDate,
										'live',
										props.notification.objectId,
										props.notification.personId
									);
								}}
							>
								<Map className="icon" />
							</button>
						</ProtectedContainer>
						<ProtectedContainer claim={ClaimType.History} feature={EasyTrackFeature.History}>
							<button
								onClick={(e) => {
									e.stopPropagation();
									props.notificationRedirect(
										props.notification.customerId,
										props.notification.createDate,
										'history',
										props.notification.objectId,
										props.notification.personId
									);
								}}
							>
								<History className="icon" />
							</button>
						</ProtectedContainer>
					</div>
				) : null}
			</div>
		</ReactScrollIntoViewIfNeeded>
	);
};

export default AlertsNotificationItem;
