import { TranslateText } from '../utils/Translations';

enum TripTypesEnum {
	None = 0,
	Business = 1,
	Private = 2,
	Commuting = 4,
	Correction = 8,
}
export const TripTypeHelper = {
	toString: (value: TripTypesEnum): string => {
		return Object.values(TripTypesEnum).reduce((result, key) => {
			if (typeof key === 'number') {
				if (key & value) {
					return `${result} ${TranslateText(`trackType.${TripTypesEnum[key]}`)}`;
				}
			}
			return result;
		}, '') as string;
	},
};

export default TripTypesEnum;
