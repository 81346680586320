import '../oldWidget.scss';

import { Button } from '@material-ui/core';
import BaseWidget from 'components/BaseWidget';
import WidgetHeader from 'components/BaseWidget/WidgetHeader';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import ConnectionConflictsNotifier from 'components/ConnectionConflictsNotifier';
import { GridWidgetOverview } from 'components/GridOverview/GridWidgetOverview';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import PersonKeyConnection from 'models/PersonKeyConnection';
import { User } from 'oidc-client';
import { Column } from 'primereact/column';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import { ApplicationState } from 'store';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import ajaxUtil from 'utils/Ajax';
import { FormatDate } from 'utils/DateUtils';
import { getDriverOrPersonRoute } from 'utils/DriverUtils';
import { TranslateText } from 'utils/Translations';

import { OldWidgetProps } from '../Widget';
import AddConnection from './AddConnection';

const mapState = (state: ApplicationState) => {
	return {
		entityData: state.loadedEntityContext.entityContextData,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
	};
};

const connector = connect(mapState, mapDispatchToProps, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OldWidgetProps &
	PropsFromRedux & {
		conflictingConnectionNotifier: ConnectionConflictsNotifier;
		showEditButton: boolean;
		user: User;
		loggedCustomerLevel: CustomerLevelEnum;
		hidden?: boolean;
	};

type State = {
	editRowId: string;
	editRow: boolean;
	connections: PersonKeyConnection[];
	canDisconnect: boolean;
	widgetInEditMode: boolean;
	rowInEdit: boolean;
	showDialog: boolean;
	currentEntityMode: boolean;
	visible: boolean;
	customerLevel: CustomerLevelEnum;
	connectionStartDate: Date;
	connectionEndDate: Date;
	selectedRowData: PersonKeyConnection;
	validationResult: ValidationResult;
	disableUpdateButton: boolean;
	disableEditButton: boolean;
};

class KeyPersonWidget extends BaseWidget<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			editRowId: null,
			editRow: false,
			connections: null,
			canDisconnect: false,
			widgetInEditMode: false,
			rowInEdit: false,
			showDialog: false,
			currentEntityMode: false,
			visible: true,
			customerLevel: null,
			connectionStartDate: null,
			connectionEndDate: null,
			selectedRowData: null,
			validationResult: null,
			disableUpdateButton: false,
			//(for persons history) disable edit initialy for EndCustomer/Reseller, enable edit afterwards if no active connection with an inaccessible person is found
			disableEditButton:
				this.props.entityType === EntityTypeEnum.Key &&
				this.props.loggedCustomerLevel !== CustomerLevelEnum.OEM,
		};
	}
	public static defaultProps = {
		showEditButton: true,
	};

	public requestedConnection: PersonKeyConnection;
	public currentEntityId: string;
	conflictingConnections: PersonKeyConnection[];
	conflictsCanBeAutomaticallyFixed: boolean;

	componentDidMount() {
		this.getData(this.props.entityId, true);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
			this.getData(this.props.entityId, true);
		}
	}

	resetDates() {
		this.setState({
			connectionStartDate: null,
			connectionEndDate: null,
			rowInEdit: false,
			editRowId: null,
		});
	}

	closeEditPanelMode() {
		if (this.state.widgetInEditMode) {
			this.setHasUnsavedData(false);
			this.toggleEditMode();
		}
	}

	handleDataSaved() {
		this.getData(this.props.entityId, true);
		if (this.props.savedCallback != null) {
			this.props.savedCallback();
		}
	}

	async getData(entityId: string, forceReload = false) {
		if (forceReload || this.currentEntityId !== entityId) {
			this.currentEntityId = entityId;

			let url = '';
			switch (this.props.entityType) {
				case EntityTypeEnum.Key:
					url += GlobalSettings.keysMaintenanceApi + '/persons/' + this.props.entityId;
					break;
				case EntityTypeEnum.Person:
					url += GlobalSettings.personsMaintenanceApi + '/keys/' + this.props.entityId;
					break;
				case EntityTypeEnum.Driver:
					url += GlobalSettings.driversMaintenanceApi + '/keys/' + this.props.entityId;
					break;
			}

			return await ajaxUtil.get(url).then((result: any) => {
				let disableEditButton = this.state.disableEditButton;
				if (
					this.props.entityType === EntityTypeEnum.Key &&
					this.props.loggedCustomerLevel !== CustomerLevelEnum.OEM &&
					(!result.data?.length || result.data[0].canViewPerson || result.data[0].connectionEndDate)
				) {
					disableEditButton = false;
				}
				const newEntityData = this.props.entityData?.entityDetails;
				if (newEntityData) {
					newEntityData.hasKeyActiveConnections = result.data.some(
						(connection: any) => connection.connectionEndDate === null
					);
				}
				this.props.loadedEntityContextActions.setLoadedPersonContext(newEntityData);
				this.setState({
					disableEditButton: disableEditButton,
					connections: result.data,
					customerLevel: result.customerLevel,
				});
			});
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgetInEditMode;
		this.props.editModeCallback(currentValueFlipped);

		if (!currentValueFlipped) {
			this.setState({
				rowInEdit: false,
			});
			this.setHasUnsavedData(false);
		}

		this.setState({
			widgetInEditMode: currentValueFlipped,
		});
	}

	fixConflictsAndUpdateConnection() {
		if (!this.requestedConnection) {
			return;
		}

		//prepare data for update
		const personKeyConnection = {
			id: this.requestedConnection.id,
			personId: this.requestedConnection.entity1Id,
			keyId: this.requestedConnection.entity2Id,
			connectionStartDate: this.requestedConnection.connectionStartDate,
			connectionEndDate: this.requestedConnection.connectionEndDate,
			resolveConflictsAutomatically: this.requestedConnection.resolveConflictsAutomatically,
		} as PersonKeyConnection;

		const currentEntityId = this.props.entityId;

		const url = GlobalSettings.connectionsApi + '/UpdatePersonKeyConnection';

		ajaxUtil.post(url, { value: personKeyConnection }).then(() => {
			this.getData(currentEntityId, true);
		});

		this.setState({ rowInEdit: false });

		this.handleDataSaved();
		this.setHasUnsavedData(false);
	}

	handleConflictingConnections(confirm: boolean) {
		if (this.conflictsCanBeAutomaticallyFixed && confirm) {
			this.requestedConnection.resolveConflictsAutomatically = true;
			this.fixConflictsAndUpdateConnection();
		} else {
			this.handleDataSaved();
		}
	}

	handleDisconnectButtonState(canDisconnect: boolean): boolean {
		if (this.state.rowInEdit) {
			return false;
		}
		if (
			this.state.selectedRowData &&
			this.state.selectedRowData.connectionEndDate &&
			new Date(this.state.selectedRowData.connectionEndDate) < new Date()
		) {
			return false;
		}
		if (this.state.selectedRowData && new Date(this.state.selectedRowData.connectionStartDate) > new Date()) {
			return false;
		}
		return canDisconnect;
	}

	terminateSelectedConnection() {
		if (this.state.selectedRowData) {
			this.setState({ canDisconnect: false });
			const url =
				GlobalSettings.connectionsApi +
				'/TerminatePersonKeyConnection/' +
				(this.state.selectedRowData as PersonKeyConnection).id;

			const currentEntityId = this.props.entityId;

			ajaxUtil.post(url, null).then((data) => {
				if (data) {
					this.getData(currentEntityId, true);
					this.handleDataSaved();
				}
			});
		}
	}

	isConnectionCurrent(connectionStartDate: Date, connectionEndDate: Date): boolean {
		if (!connectionEndDate) {
			return true;
		}
		const referenceDate = new Date();
		return new Date(connectionStartDate) <= referenceDate && new Date(connectionEndDate) >= referenceDate;
	}

	handleRowSelected(connectionStartDate: Date, connectionEndDate: Date) {
		if (!this.isConnectionCurrent(connectionStartDate, connectionEndDate)) {
			this.setState({ canDisconnect: false });
		} else {
			this.setState({ canDisconnect: true });
		}
	}

	openAddConnectionWidget(currentEntityMode: boolean) {
		this.setState({
			showDialog: true,
			currentEntityMode: currentEntityMode,
		});
	}

	addConnectionDialogClosed() {
		this.setState({
			showDialog: false,
		});
	}

	setHasUnsavedData(hasUnsavedData: boolean) {
		this.hasUnsavedData = hasUnsavedData;
		this.props.changeDataCallback(hasUnsavedData);
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="5"
				data-minsizey="3"
				hidden={this.props.hidden}
			>
				{this.props.showEditButton ? (
					<AddConnection
						savedCallback={() => this.handleDataSaved()}
						entityId={this.props.entityId}
						dialogCloseCallback={() => this.addConnectionDialogClosed()}
						currentEntityMode={this.state.currentEntityMode}
						showDialog={this.state.showDialog}
						getAccessTokenCallback={() => this.props.getAccessTokenCallback()}
						renderFor={this.props.entityType}
						visible={false}
					/>
				) : null}

				<div className="e-panel-container">
					<WidgetHeader
						caption={
							this.props.entityType === EntityTypeEnum.Key
								? TranslateText('common.driverHistory')
								: TranslateText('common.keyHistory')
						}
						showEditButton={
							this.props.showEditButton && !this.state.rowInEdit && !this.state.disableEditButton
						}
						editMode={this.state.widgetInEditMode}
						allowEditMode={this.props.allowEditMode || this.state.widgetInEditMode}
						removePanelCallback={() => this.setState({ visible: false })}
						editCallback={() => this.toggleEditMode()}
					/>
					<div className="e-panel-content">
						<div id="gridHost" className="grid-host auto-person-key-widget">
							<GridWidgetOverview
								columns={[
									<Column
										key={this.props.entityType === EntityTypeEnum.Key ? 'personCode' : 'keyCode'}
										field={this.props.entityType === EntityTypeEnum.Key ? 'personCode' : 'keyCode'}
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colCode')}
										style={{ width: 50 }}
										body={(rowData: any) => {
											const value =
												this.props.entityType === EntityTypeEnum.Key
													? rowData.personCode
													: rowData.keyCode;
											return this.state.widgetInEditMode ||
												(this.props.entityType === EntityTypeEnum.Key &&
													!rowData.canViewPerson) ? (
												value
											) : (
												<Link
													to={
														this.props.entityType === EntityTypeEnum.Key
															? getDriverOrPersonRoute(
																	this.props.user,
																	true,
																	rowData.driver,
																	rowData.personId
															  )
															: `${GlobalSettings.route.keys}/${rowData.keyId}`
													}
												>
													{value}
												</Link>
											);
										}}
									/>,
									this.props.entityType === EntityTypeEnum.Key && (
										<Column
											key="personFirstName"
											field="personFirstName"
											className="widget-grid-column"
											header={TranslateText('widgets.grid.colFirstName')}
											style={{ width: 150 }}
										/>
									),
									this.props.entityType === EntityTypeEnum.Key && (
										<Column
											key="personLastName"
											field="personLastName"
											className="widget-grid-column"
											header={TranslateText('widgets.grid.colLastName')}
											style={{ width: 150 }}
										/>
									),
									<Column
										key="connectionStartDate"
										field="connectionStartDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colStartDate')}
										style={{ width: 250 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerStart"
														showTime={true}
														key="datePickerStart"
														date={this.state.connectionStartDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId && rowData.id === this.state.editRowId && (
														<ValidationMessage
															result={this.state.validationResult?.connectionStartDate}
														/>
													)}
												</div>
											) : (
												FormatDate(new Date(rowData.connectionStartDate))
											)
										}
									/>,
									<Column
										key="connectionEndDate"
										field="connectionEndDate"
										className="widget-grid-column"
										header={TranslateText('widgets.grid.colEndDate')}
										style={{ width: 250 }}
										body={(rowData: any) =>
											this.state.editRowId && rowData.id === this.state.editRowId ? (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<MaterialDatePicker
														name="datePickerEnd"
														showTime={true}
														key="datePickerEnd"
														date={this.state.connectionEndDate}
														format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
															DateTimeUtil.dateTimeFormat(),
															true
														)}
														fullWidth={false}
														width={220}
														disabled={
															!(
																this.state.rowInEdit &&
																this.state.editRowId &&
																rowData.id === this.state.editRowId
															)
														}
													/>
													{this.state.editRowId &&
														rowData.id === this.state.editRowId &&
														this.state.connectionEndDate && (
															<ValidationMessage
																result={this.state.validationResult?.connectionEndDate}
															/>
														)}
												</div>
											) : rowData.connectionEndDate ? (
												FormatDate(new Date(rowData.connectionEndDate))
											) : null
										}
									/>,
								]}
								data={this.state.connections}
								total={this.state.connections?.length}
								paginator={true}
								selectedRowCallback={(rowData) => {
									this.handleRowSelected(rowData.connectionStartDate, rowData.connectionEndDate);
									this.setState({ selectedRowData: rowData });
								}}
							/>
						</div>
					</div>
					{this.state.widgetInEditMode ? (
						<div id="buttonsHost" className="buttons-host">
							<div className="left-side-buttons">
								<Button
									className="widget-button new-connection"
									disabled={
										this.state.rowInEdit ||
										(this.props.entityType === EntityTypeEnum.Person &&
											this.state.customerLevel !== CustomerLevelEnum.Default)
									}
									onClick={() => this.openAddConnectionWidget(false)}
								>
									{TranslateText('common.new')}
								</Button>
								<Button
									className="widget-button disconnect"
									disabled={!this.handleDisconnectButtonState(this.state.canDisconnect)}
									onClick={() => this.terminateSelectedConnection()}
								>
									{TranslateText('common.disconnect')}
								</Button>
							</div>
							<div className="right-side-buttons">
								<Button
									className="widget-button link"
									disabled={
										this.state.rowInEdit ||
										(this.props.entityType === EntityTypeEnum.Person &&
											this.state.customerLevel !== CustomerLevelEnum.Default)
									}
									onClick={() => this.openAddConnectionWidget(true)}
								>
									{TranslateText('common.link')}{' '}
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default connector(KeyPersonWidget);
