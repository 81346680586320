import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {ApplicationState} from "../../../store";
import { Marker } from '@react-google-maps/api';
import {privateDataMask} from "../../../utils/TripUtils";
import {ProcessedTripDto} from "../../../store/HistoryStore";
interface ChartMarkerProps{
	tripConfig: ProcessedTripDto[];
}
const ChartMarker = (props: ChartMarkerProps): JSX.Element => {
	const [hasLocation, setHasLocation] = useState(false);
	const selectedPointHistoryTripChart = useSelector((state: ApplicationState) => state.historyStore.selectedPointHistoryTripChart);
	const focusOnChange = useSelector((state: ApplicationState) => state.historyStore.focusOnChange);
	useEffect(() => {
		setHasLocation(false);
		if (selectedPointHistoryTripChart !== null && props.tripConfig.length && focusOnChange) {
			for (let i = 0; i < props.tripConfig.length; i++) {
				const item = props.tripConfig[i];
				if(item.id === selectedPointHistoryTripChart.tripId){
					if ((item.visible &&
						!item.isParked &&
						item?.startAddress !== privateDataMask &&
						item?.endAddress !== privateDataMask)
					) {
						setHasLocation(true);
					}
				}
			}
		}
	},[selectedPointHistoryTripChart, props.tripConfig])
	if (selectedPointHistoryTripChart !== null && hasLocation) {
		return (
			<Marker
				zIndex={1}
				key={`location-${selectedPointHistoryTripChart.tripId}`}
				position={{lat: selectedPointHistoryTripChart.latitude, lng: selectedPointHistoryTripChart.longitude}}
				icon={{
					path: google.maps.SymbolPath.CIRCLE,
					fillColor: selectedPointHistoryTripChart.tripColor,
					fillOpacity: 1,
					strokeColor: selectedPointHistoryTripChart.tripColor,
					strokeWeight: 1,
					scale: 7
				}}
			/>
		);
	}
	return null;
};

export default React.memo(ChartMarker);
