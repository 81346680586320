import PrivateMileageAlertMetadata from 'models/PrivateMileageAlert';
import { useEffect, useMemo, useRef } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

const usePrivateMileageAlertMetaData = (initialData: PrivateMileageAlertMetadata) => {
	const privateMileageAlertMetaData = useRef<PrivateMileageAlertMetadata>(initialData);

	const changePreThresholds = (preThresholds: number) => {
		switch (preThresholds) {
			case 0: {
				privateMileageAlertMetaData.current = {
					...privateMileageAlertMetaData.current,
					preThresholds,
					firstPreThresholdAlert: 0,
					secondPreThresholdAlert: 0,
					thirdPreThresholdAlert: 0,
				};
				break;
			}

			case 1: {
				privateMileageAlertMetaData.current = {
					...privateMileageAlertMetaData.current,
					preThresholds,
					secondPreThresholdAlert: 0,
					thirdPreThresholdAlert: 0,
				};
				break;
			}

			case 2: {
				privateMileageAlertMetaData.current = {
					...privateMileageAlertMetaData.current,
					preThresholds,
					thirdPreThresholdAlert: 0,
				};
				break;
			}

			case 3: {
				privateMileageAlertMetaData.current = {
					...privateMileageAlertMetaData.current,
					preThresholds,
				};
				break;
			}
		}
	};

	useEffect(() => {
		privateMileageAlertMetaData.current = initialData;
	}, [initialData]);

	const changeFirstPreThreshold = (firstPreThresholdAlert: string | number) =>
		(privateMileageAlertMetaData.current = {
			...privateMileageAlertMetaData.current,
			firstPreThresholdAlert: firstPreThresholdAlert as number,
		});

	const changeSecondPreThreshold = (secondPreThresholdAlert: string | number) =>
		(privateMileageAlertMetaData.current = {
			...privateMileageAlertMetaData.current,
			secondPreThresholdAlert: secondPreThresholdAlert as number,
		});

	const changeThirdPreThreshold = (thirdPreThresholdAlert: string | number) =>
		(privateMileageAlertMetaData.current = {
			...privateMileageAlertMetaData.current,
			thirdPreThresholdAlert: thirdPreThresholdAlert as number,
		});

	return {
		privateMileageAlertMetaData,
		changePreThresholds,
		changeFirstPreThreshold,
		changeSecondPreThreshold,
		changeThirdPreThreshold,
	};
};

const useThresholFieldsdValidation = () => {
	const validator = useMemo(
		() =>
			new Validator(
				{
					fieldRules: {
						alertThreshold: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.thresholdValue) {
											return ValidatorFunctions.wrapInPromise(true);
										}
										return ValidatorFunctions.wrapInPromise(false);
									},
								},
							},
						},
						firstPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.preThresholds >= 1) {
											if (!data.firstPreThresholdAlert && data?.firstPreThresholdAlert !== 0) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}

										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.minValue') + 1,
									validationFunction: (data) => {
										if (data.preThresholds >= 1) {
											if (parseInt(data.firstPreThresholdAlert) < 1) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.preThresholds >= 1) {
											if (parseInt(data.firstPreThresholdAlert) > 99) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.preThresholds >= 1) {
											if (
												data.preThresholds >= 2 &&
												parseFloat(data.firstPreThresholdAlert) ===
													parseFloat(data.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												data.preThresholds === 3 &&
												parseFloat(data.firstPreThresholdAlert) ===
													parseFloat(data.thirdPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						secondPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.preThresholds >= 2) {
											if (!data.secondPreThresholdAlert && data?.secondPreThresholdAlert !== 0) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.prethresholdGreater'),
									validationFunction: (data) => {
										if (data.preThresholds >= 2) {
											if (
												parseFloat(data.secondPreThresholdAlert) <
												parseFloat(data.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.preThresholds >= 2) {
											if (parseInt(data.secondPreThresholdAlert) > 99) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.preThresholds >= 2) {
											if (
												parseFloat(data.secondPreThresholdAlert) ===
												parseFloat(data.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												data.preThresholds === 3 &&
												parseFloat(data.secondPreThresholdAlert) ===
													parseFloat(data.thirdPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						thirdPreThresholdAlert: {
							rules: {
								required: {
									message: TranslateText('fieldsValidations.fieldRequired'),
									validationFunction: (data) => {
										if (data.preThresholds === 3) {
											if (!data.thirdPreThresholdAlert && data?.thirdPreThresholdAlert !== 0) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								minNumber: {
									message: TranslateText('fieldsValidations.prethresholdGreater'),
									validationFunction: (data) => {
										if (data.preThresholds === 3) {
											if (
												parseFloat(data.thirdPreThresholdAlert) <
												parseFloat(data.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								maxNumber: {
									message: TranslateText('fieldsValidations.maxValue') + 99,
									validationFunction: (data) => {
										if (data.preThresholds === 3) {
											if (parseInt(data.thirdPreThresholdAlert) > 99) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
								noSameValue: {
									message: TranslateText('fieldsValidations.preThresholdsSameValue'),
									validationFunction: (data) => {
										if (data.preThresholds === 3) {
											if (
												parseFloat(data.thirdPreThresholdAlert) ===
												parseFloat(data.firstPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											} else if (
												parseFloat(data.thirdPreThresholdAlert) ===
												parseFloat(data.secondPreThresholdAlert)
											) {
												return ValidatorFunctions.wrapInPromise(false);
											}
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
					},
				},
				true
			),
		[]
	);

	const validateThresholdFields = (
		data: PrivateMileageAlertMetadata,
		onChangeHandler: (
			value: PrivateMileageAlertMetadata,
			validationResult: ValidationResult,
			prethresholdsValid: boolean
		) => void
	) => {
		validator.validate(data).then((result) => {
			onChangeHandler(data, result.validationResult, result.formResult);
		});
	};

	return { validateThresholdFields };
};

export { usePrivateMileageAlertMetaData, useThresholFieldsdValidation };
