export type ClaimValueName =
	| 'editdesigner'
	| 'whatsappalert'
	| 'smsalert'
	| 'mailalert'
	| 'notificationalert'
	| 'editplannedreports'
	| 'editgeneratedreports'
	| 'edit'
	| 'roleassign'
	| 'editrestricted'
	| 'view';

type ClaimValueDictionary = { [claimValueName in ClaimValueName]: number };

const claimValueDictionary: ClaimValueDictionary = {
	editdesigner: 50,
	editplannedreports: 50,
	editgeneratedreports: 50,
	whatsappalert: 35,
	smsalert: 35,
	mailalert: 35,
	notificationalert: 35,
	edit: 30,
	roleassign: 25,
	editrestricted: 20,
	view: 10,
};

export default claimValueDictionary;
