import './yesNoCombobox.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import YesNoComboEnum from 'models/YesNoComboEnum';
import React, { useEffect, useState } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

export interface Props {
	placeholder?: string;
	value: number | string | boolean | null;
	readonly?: boolean;
	change: (newValue: number | string | boolean | null) => void;
	hidden?: boolean;
	showDefault?: boolean; //If the default is used, the combo will internally use the -1, 0, 1 values. If it is set to false the data source will be true, false
	name: string;
	validationResult?: ValidationResult;
	showDefaultTrueFalse?: boolean; //If this is true, the combo will internally use the null, true, false values. If it is set to false the data source will be managed by showDefault props
	className?: string;
	isForNewAddWizard?: boolean;
}

const YesNoCombobox = (props: Props) => {
	const [dataSource, setDataSource] = useState([
		{
			id: YesNoComboEnum.Yes,
			display: TranslateText('common.yes'),
			valueToSet: props.showDefaultTrueFalse ? true : props.showDefault ? YesNoComboEnum.Yes : true,
		},
		{
			id: YesNoComboEnum.No,
			display: TranslateText('common.no'),
			valueToSet: props.showDefaultTrueFalse ? false : props.showDefault ? YesNoComboEnum.No : false,
		},
	]);

	useEffect(() => {
		if (props.showDefaultTrueFalse) {
			setDataSource([
				{
					id: YesNoComboEnum.Default,
					display: TranslateText('common.default'),
					valueToSet: null,
				},
				...dataSource,
			]);
		} else {
			if (props.showDefault) {
				setDataSource([
					{
						id: YesNoComboEnum.Default,
						display: TranslateText('common.default'),
						valueToSet: YesNoComboEnum.Default,
					},
					...dataSource,
				]);
			} else {
				setDataSource(dataSource.filter((d) => d.valueToSet !== null));
			}
		}
	}, [props.showDefault, props.showDefaultTrueFalse]);

	return (
		<div className={`yes-no-combobox ${props.className ?? ''}`}>
			<MaterialAutocomplete
				isForNewAddWizard={props.isForNewAddWizard}
				valueId={dataSource.find((d) => d.valueToSet === props.value)?.id}
				dataSource={dataSource}
				name={props.name}
				disabled={props.readonly}
				label={props.placeholder}
				onChange={({ value }) => props.change(dataSource.find((d) => d.id === value)?.valueToSet)}
				disableClearable
				className={props.hidden ? 'hidden' : 'material-autocomplete'}
			/>
			<ValidationMessage result={props.validationResult ? props.validationResult[props.name] : null} />
		</div>
	);
};
YesNoCombobox.defaultProps = {
	showDefault: false,
};

export default YesNoCombobox;
