import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import ajaxUtil from 'utils/Ajax';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import './AddInGroupsSelector.scss';
import { ClaimUtil } from 'authorization/ClaimUtil';
import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { TranslateText } from 'utils/Translations';
import GlobalSettings from '../../../GlobalSettings.json';
import { ApplicationState } from '../../../store';

interface SelectionValue {
	id: string;
	description: string;
}

interface Props {
	onChange: (groups: string[]) => void;
	name: string;
	selectedGroups: string[];
	disabled?: boolean;
	defaultCustomerId?: string;
}

const AddInGroupsSelector = ({ onChange, name, selectedGroups, disabled, defaultCustomerId }: Props) => {
	const customerId = useSelector(
		(state: ApplicationState) =>
			defaultCustomerId ??
			(state.globalCustomer.filteredCustomer
				? state.globalCustomer.filteredCustomer.id
				: state.currentSession.customerId)
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const [dataSource, setDataSource] = useState<SelectionValue[]>([]);

	useEffect(() => {
		let url = `${GlobalSettings.personsMaintenanceApi}/GetPersonGroupSelectionData`;

		if (ClaimUtil.validateClaim(user, { claim: ClaimType.Drivers, values: [ClaimValue.edit] })) {
			url = `${GlobalSettings.driversMaintenanceApi}/GetDriverGroupSelectionData`;
		} else if (ClaimUtil.validateClaim(user, { claim: ClaimType.Objects, values: [ClaimValue.edit] })) {
			url = `${GlobalSettings.objectsMaintenanceApi}/GetObjectGroupSelectionData`;
		}

		ajaxUtil
			.post<ITreeNode[]>(url, {
				customerId,
				showInactive: false,
				filterText: '',
			})
			.then((data) => {
				setDataSource(data.map((d) => ({ id: d.id, description: d.text })));
			});
	}, []);

	useEffect(() => {
		if (disabled) {
			onChange([]);
		}
	}, [disabled]);

	return (
		<div className="add-in-groups-selector">
			<span className={disabled ? 'select-title disabled' : 'select-title'}>
				{TranslateText('fields.addInGroups')}
			</span>
			<Select
				name={name}
				className="select-component"
				value={selectedGroups}
				multiple
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
					style: { zIndex: 15001 },
					classes: { paper: 'add-in-groups-selector-menu-paper' },
				}}
				onChange={({ target: { value } }) => {
					onChange(value as string[]);
				}}
				renderValue={() => {
					return dataSource
						.filter((d) => selectedGroups.includes(d.id))
						.map((d) => d.description)
						.join(', ');
				}}
				disabled={disabled}
			>
				{dataSource.map((d) => (
					<MenuItem key={d.id} value={d.id} className="add-in-groups-selector-menu">
						<Checkbox checked={selectedGroups.includes(d.id)} size="small" />
						<ListItemText primary={d.description} className="add-in-groups-selector-list-item-text" />
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
export default AddInGroupsSelector;
