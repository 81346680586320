import TemplateUnitOfMeasureEnum from 'models/TemplateUnitOfMeasureEnum';

export function getDisplayAnalogSensorValue(sensorValue: number, unitOfMeasure: TemplateUnitOfMeasureEnum): string {
	let stateName = '';
	switch (unitOfMeasure) {
		case TemplateUnitOfMeasureEnum.Percentage:
			stateName = sensorValue || sensorValue === 0 ? `${sensorValue.toFixed(2)} %` : '';
			break;
		case TemplateUnitOfMeasureEnum.Voltage:
			stateName = sensorValue || sensorValue === 0 ? `${sensorValue.toFixed(2)} V` : '';
			break;
		case TemplateUnitOfMeasureEnum.Temperature:
			stateName = sensorValue || sensorValue === 0 ? `${sensorValue.toFixed(2)} ℃` : '';
			break;
		case TemplateUnitOfMeasureEnum.Distance:
			stateName = sensorValue || sensorValue === 0 ? `${sensorValue.toFixed(2)} Km` : '';
			break;
		case TemplateUnitOfMeasureEnum.Time:
			stateName = sensorValue || sensorValue === 0 ? `${sensorValue.toFixed(2)} m` : '';
			break;
		default:
			stateName = sensorValue ? sensorValue.toString() : '';
			break;
	}
	return stateName;
}
export function getDisplayAnalogSensorValueAndState(
	sensorValue: number,
	unitOfMeasure: TemplateUnitOfMeasureEnum,
	state: string
): string {
	let stateName = '';
	let value = state ?? '';
	switch (unitOfMeasure) {
		case TemplateUnitOfMeasureEnum.Percentage:
			stateName = sensorValue || sensorValue === 0 ? `${value} - ${sensorValue.toFixed(2)} %` : `${value}`;
			break;
		case TemplateUnitOfMeasureEnum.Voltage:
			stateName = sensorValue || sensorValue === 0 ? `${value} - ${sensorValue.toFixed(2)} V` : `${value}`;
			break;
		case TemplateUnitOfMeasureEnum.Temperature:
			stateName = sensorValue || sensorValue === 0 ? `${value} - ${sensorValue.toFixed(2)} ℃` : `${value}`;
			break;
		case TemplateUnitOfMeasureEnum.Distance:
			stateName = sensorValue || sensorValue === 0 ? `${value} - ${sensorValue.toFixed(2)} Km` : `${value}`;
			break;
		case TemplateUnitOfMeasureEnum.Time:
			stateName = sensorValue || sensorValue === 0 ? `${value} - ${sensorValue.toFixed(2)} m` : `${value}`;
			break;
		default:
			stateName = sensorValue ? `${value} - ${sensorValue.toString()}` : `${value}`;
			break;
	}
	return stateName;
}
export function getDisplayDigitalSensorDuration(
	stateName: string,
	totalDuration: number,
	currentDuration: number,
	sensorObservationValue: boolean
): string {
	let value = '';
	if (sensorObservationValue === null) value = `${stateName} - ${minutesToHhMm(totalDuration)}`;
	else value = `${stateName} - ${minutesToHhMm(currentDuration)}/${minutesToHhMm(totalDuration)}`;
	return value;
}

function minutesToHhMm(minutes: number) {
	let hours = Math.floor(minutes / 60);
	let mins = minutes % 60;

	let hoursStr = hours.toString();
	let minsStr = mins.toString().padStart(2, '0');

	return `${hoursStr}h:${minsStr}m`;
}
