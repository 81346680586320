import './locationView.scss';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import { getLocationCategoryTranslation } from 'components/LocationsOverview/utils';
import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import GlobalSettings from 'GlobalSettings.json';
import Location from 'models/Location';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import LocationAccessTypeDataSource from 'shared/staticDataSources/LocationAccessTypeDataSource';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

type Props = AddWizardComponentProps & {
	location?: Location;
	readOnly: boolean;
	setUnsavedDataCallback?: (data: boolean) => void;
	isForDialog: boolean;
	startDateFromTrip?: Date;
	setStartDateStringFromTripCallback?: (value: string) => void;
};

const LocationView = (props: Props) => {
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);

	const [location, setLocation] = useState<Location>(
		props.location
			? { ...props.location }
			: !props.isForDialog
				? new Location(defaultCustomerId)
				: new Location(defaultCustomerId, props.startDateFromTrip)
	);

	const [categories, setCategories] = useState<AutocompleteItem[]>([]);
	const validator: Validator = new Validator({
		fieldRules: {
			code: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/LocationFields`, {
								customerId: location.customerId,
								value: data[fieldName],
								Field: fieldName,
								editedEntityId: !!location.id ? location.id : null,
							});
						},
					},
				},
			},
			name: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(200),
				},
			},
			categoryName: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(50),
				},
			},
			startDate: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			description: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(500),
				},
			},
			accessType: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
		},
	});

	useEffect(() => {
		if (!props.location) {
			return;
		}
		setLocation({ ...props.location });

		if(props.location?.categoryId !== undefined &&
			props.location?.categoryId !== null &&
			!categories.some((x) => x.id === props.location?.categoryId))
		{
			let newCategories = [...categories];
			newCategories.push({
				id: props.location.categoryId,
				display: props.location.categoryName
			});
			setCategories(newCategories);
		}
	}, [props.location]);

	useEffect(() => {
		ajaxUtil
			.get<{ id: string; name: string }[]>(
				`${GlobalSettings.locationsMaintenanceApi}/getCategories/${defaultCustomerId}`
			)
			.then((data) => {
				setCategories(
					data.map((category) => {
						return {
							id: category.id,
							display: getLocationCategoryTranslation(category.name),
						};
					})
				);
			});
	}, []);

	useEffect(() => {
		validator
			.validate({
				code: location.code,
				name: location.name,
				accessType: location.accessType,
				categoryId: location.categoryId,
				categoryName: location.categoryName,
				startDate: location.startDate,
				endDate: location.endDate,
				description: location.description,
				active: location.active,
				customerId: defaultCustomerId,
			})
			.then((result) => {
				setValidationResult(result.validationResult);
				props.setValidationCallback(result.formResult);
				if (unsavedData) {
					props.onChangeCallback({
						code: location.code,
						name: location.name,
						accessType: location.accessType,
						categoryId: !!categories.find((c) => c.id === location.categoryId) ? location.categoryId : null,
						categoryName: location.categoryName,
						startDate: location.startDate,
						endDate: location.endDate,
						description: location.description,
						active: location.active,
						customerId: defaultCustomerId,
					});
				}
			});
	}, [location]);

	const handleValueChange = useCallback(
		(value: unknown, statePropName: string) => {
			const newLocation = { ...location };
			const lastValue = newLocation[statePropName];
			newLocation[statePropName] = value;

			if (statePropName === 'categoryId' && value === null) {
				newLocation['categoryName'] = '';
			}

			if(props.isForDialog && statePropName === 'startDate' && props.setStartDateStringFromTripCallback)
			{
				props.setStartDateStringFromTripCallback(null);
			}
			if (statePropName === 'startDate' && value && location.endDate) {
				let newFrom = moment(value as Date);
				const newTo = moment(location.endDate);

				if (newTo < newFrom) {
					newFrom = moment(location.startDate);
				}

				newLocation['startDate'] = newFrom.toDate();
				newLocation['endDate'] = newTo.toDate();
			} else if (statePropName === 'endDate' && value && location.startDate) {
				let newTo = moment(value as Date);
				const newFrom = moment(location.startDate);

				if (newTo < newFrom) {
					newTo = moment(location.endDate);
				}

				newLocation['startDate'] = newFrom.toDate();
				newLocation['endDate'] = newTo.toDate();
			}

			setLocation(newLocation);
			setUnsavedData(newLocation[statePropName] !== lastValue ? true : unsavedData);
			if (props.setUnsavedDataCallback) {
				props.setUnsavedDataCallback(newLocation[statePropName] !== lastValue ? true : unsavedData);
			}
		},
		[location]
	);
	const setNewOptionCallback = (item: AutocompleteItem) => {
		setLocation({
			...location,
			categoryId: item.id as string | null,
			categoryName: item.display,
		});
	};

	return (
		<div className={props.isForDialog ? 'location-view-container-dialog' : 'location-view-container-inline'}>
			<div className={props.isForDialog ? 'location-dialog-column' : ''}>
				<div className="form-group">
					<MaterialTextField
						autoFocus={true}
						id="code"
						label={TranslateText('fields.code')}
						inputProps={{ readOnly: false, style: { fontSize: 10 } }}
						name="code"
						handleValueChange={(value) => handleValueChange(value, 'code')}
						value={location.code}
						disabled={props.readOnly}
						isForNewAddWizard={true}
						validationResult={validationResult?.code}
					/>
				</div>
				{!props.location && !props.isForDialog && (
					<CustomerNameDisplay
						customerId={!!defaultCustomerId?.length ? defaultCustomerId : location.customerId}
						isForNewAddWizard={true}
					/>
				)}
				<div className="form-group">
					<MaterialTextField
						id="name"
						label={TranslateText('fields.name')}
						inputProps={{ readOnly: false, style: { fontSize: 10 } }}
						name="name"
						handleValueChange={(value) => handleValueChange(value, 'name')}
						value={location.name}
						disabled={props.readOnly}
						isForNewAddWizard={true}
						validationResult={validationResult?.name}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={location.accessType}
						dataSource={LocationAccessTypeDataSource.dataSource()}
						name="accessType"
						disabled={props.readOnly}
						disableClearable
						label={TranslateText('fields.accessType')}
						onChange={({ value }) => handleValueChange(value, 'accessType')}
						validationResult={validationResult?.accessType}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						valueId={location.categoryId}
						dataSource={categories}
						name="categoryName"
						disabled={props.readOnly}
						label={TranslateText('fields.category')}
						onChange={({ value }) => {
							handleValueChange(value, 'categoryId');
						}}
						validationResult={validationResult?.categoryName}
						allowNewValues={true}
						setNewOptionCallback={setNewOptionCallback}
						allowEmptyStringAsNewValue={false}
					/>
				</div>
			</div>
			<div className={props.isForDialog ? 'location-dialog-column' : ''}>
				<div className="form-group">
					<MaterialDatePicker
						name="startDate"
						date={location.startDate}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true)}
						label={TranslateText('fields.validFrom')}
						showTime={true}
						disabled={props.readOnly}
						onDateChange={(date?: Date) => {
							handleValueChange(date, 'startDate');
						}}
						fullWidth={true}
						minDate={props.location || props.isForDialog ? undefined : new Date()}
						maxDate={location.endDate? location.endDate : undefined}
						isForNewAddWizard={true}
						validationResult={validationResult?.startDate}
					/>
				</div>
				<div className="form-group">
					<MaterialDatePicker
						name="endDate"
						date={location.endDate}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true)}
						label={TranslateText('fields.validTo')}
						showTime={true}
						disabled={props.readOnly}
						onDateChange={(date?: Date) => {
							handleValueChange(date, 'endDate');
						}}
						fullWidth={true}
						minDate={location.startDate ? location.startDate : new Date()}
						isForNewAddWizard={true}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						id="description"
						label={TranslateText('fields.description')}
						inputProps={{ readOnly: false, style: { fontSize: 10 } }}
						name="description"
						handleValueChange={(value) => handleValueChange(value, 'description')}
						value={location.description}
						disabled={props.readOnly}
						isForNewAddWizard={true}
						multiline={true}
						rows={2}
						validationResult={validationResult?.description}
					/>
				</div>
				<div className="form-group">
					<FormControlLabel
						control={
							<Checkbox
								name="active"
								color={'primary'}
								checked={location.active}
								onChange={(e) => handleValueChange(e.target.checked, 'active')}
								disabled={!props.location || props.readOnly}
							/>
						}
						label={
							<Typography style={{ fontSize: 10, marginRight: 5, color: 'black' }}>
								{TranslateText('fields.active')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0 }}
					/>
				</div>
			</div>
		</div>
	);
};

export default LocationView;
