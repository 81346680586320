import DataExchangeTypeEnum from 'models/DataExchangeTypeEnum';
import IntegrationDto, {
	IntegrationAfasMetadata,
	IntegrationEasyTrackMetadata,
	IntegrationHereOnTrackMetadata,
} from 'models/IntegrationDto';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

export const IntegrationValidationConfig = (includeMetadata: boolean): FieldRules => {
	const fieldRules: FieldRules = {
		name: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(200),
			},
		},
		customerId: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		integrationType: {
			rules: {
				required: ValidatorFunctions.requiredInEnum(IntegrationTypeEnum),
			},
		},
		dataExchangeType: {
			rules: {
				required: ValidatorFunctions.requiredInEnum(DataExchangeTypeEnum),
			},
		},
		remoteAccessCredentials: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(500),
			},
		},
		active: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		customerContactPersonName: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(400),
			},
		},
		customerContactPersonEmail: {
			rules: {
				email: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.email(),
					TranslateText('fieldsValidations.invalidEmailFormat')
				),
				maxLength: ValidatorFunctions.maxLength(40),
			},
		},
		customerContactPersonPhone: {
			rules: {
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				maxLength: ValidatorFunctions.maxLength(20),
			},
		},
		supplierContactPersonName: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(400),
			},
		},
		supplierContactPersonEmail: {
			rules: {
				email: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.email(),
					TranslateText('fieldsValidations.invalidEmailFormat')
				),
				maxLength: ValidatorFunctions.maxLength(40),
			},
		},
		supplierContactPersonPhone: {
			rules: {
				number: ValidatorFunctions.regexIfNotNull(
					ValidationRegex.onlyDigits(),
					TranslateText('fieldsValidations.onlyDigits')
				),
				maxLength: ValidatorFunctions.maxLength(20),
			},
		},
	};

	let metadataRules: FieldRules = {};
	if (includeMetadata) {
		metadataRules = {
			//AFAS
			afasAccessToken: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.Afas ||
									!!integration.integrationAfasMetadata?.accessToken
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.afasAccessTokenMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const accessToken = integration.integrationAfasMetadata?.accessToken;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.Afas ||
									!accessToken ||
									accessToken.toString().length <= IntegrationConstants.afasAccessTokenMaxLength
							);
						},
					},
				},
			},
			afasBaseUrl: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.Afas ||
									!!integration.integrationAfasMetadata?.baseUrl
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.afasBaseURLMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const baseUrl = integration.integrationAfasMetadata?.baseUrl;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.Afas ||
									!baseUrl ||
									baseUrl.toString().length <= IntegrationConstants.afasBaseURLMaxLength
							);
						},
					},
				},
			},

			//Here On Track
			hereOnTrackApiKey: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									!!integration.integrationHereOnTrackMetadata?.hereOnTrackApiKey
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.hereOnTrackApiKeyMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const hereOnTrackApiKey = integration.integrationHereOnTrackMetadata?.hereOnTrackApiKey;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									!hereOnTrackApiKey ||
									hereOnTrackApiKey.toString().length <=
										IntegrationConstants.hereOnTrackApiKeyMaxLength
							);
						},
					},
				},
			},
			hereOnTrackRefreshIntervalMinutes: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									!!integration.integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes
							);
						},
					},
					minNumberConditionally: {
						message:
							TranslateText('fieldsValidations.minValue') +
							IntegrationConstants.hereOnTrackRefreshIntervalMinutesMin,
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const hereOnTrackRefreshInterval =
								integration.integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									(!hereOnTrackRefreshInterval && hereOnTrackRefreshInterval !== 0) ||
									hereOnTrackRefreshInterval >=
										IntegrationConstants.hereOnTrackRefreshIntervalMinutesMin
							);
						},
					},
					maxNumberConditionally: {
						message:
							TranslateText('fieldsValidations.maxValue') +
							IntegrationConstants.hereOnTrackRefreshIntervalMinutesMax,
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const hereOnTrackRefreshInterval =
								integration.integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									(!hereOnTrackRefreshInterval && hereOnTrackRefreshInterval !== 0) ||
									hereOnTrackRefreshInterval <=
										IntegrationConstants.hereOnTrackRefreshIntervalMinutesMax
							);
						},
					},
				},
			},
			hereOnTrackGroupId: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.HereOnTrack ||
									!!integration.integrationHereOnTrackMetadata?.hereOnTrackGroupId
							);
						},
					},
				},
			},
			// EasyTrack API
			accessTokenCallbackURL: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.EasyTrack ||
									!!integration.integrationEasyTrackMetadata?.accessTokenCallbackURL
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.easyTrackCallbackURLMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							const accessTokenCallbackURL =
								integration.integrationEasyTrackMetadata?.accessTokenCallbackURL;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.EasyTrack ||
									!accessTokenCallbackURL ||
									accessTokenCallbackURL.toString().length <=
										IntegrationConstants.easyTrackCallbackURLMaxLength
							);
						},
					},
				},
			},
			bypassAgreementRequired: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.EasyTrack ||
									!integration.integrationEasyTrackMetadata?.bypassAgreementRequired
							);
						},
					},
				},
			},
			geocodingAgreementRequired: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integration = data as IntegrationDto;
							return ValidatorFunctions.wrapInPromise(
								integration.integrationType !== IntegrationTypeEnum.EasyTrack ||
									!integration.integrationEasyTrackMetadata?.geocodingAgreementRequired
							);
						},
					},
				},
			},
		};
	}

	return { ...fieldRules, ...metadataRules };
};

export const IntegrationMetadataValidationConfig = (integrationType: IntegrationTypeEnum): FieldRules => {
	if (integrationType === IntegrationTypeEnum.Afas) {
		return {
			afasAccessToken: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationAfasMetadata = data as IntegrationAfasMetadata;
							return ValidatorFunctions.wrapInPromise(!!integrationAfasMetadata?.accessToken);
						},
					},
					maxLength: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.afasAccessTokenMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integrationAfasMetadata = data as IntegrationAfasMetadata;
							const accessToken = integrationAfasMetadata?.accessToken;
							return ValidatorFunctions.wrapInPromise(
								!accessToken ||
									accessToken.toString().length <= IntegrationConstants.afasAccessTokenMaxLength
							);
						},
					},
				},
			},
			afasBaseUrl: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationAfasMetadata = data as IntegrationAfasMetadata;
							return ValidatorFunctions.wrapInPromise(!!integrationAfasMetadata?.baseUrl);
						},
					},
					maxLength: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.afasBaseURLMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integrationAfasMetadata = data as IntegrationAfasMetadata;
							const baseUrl = integrationAfasMetadata?.baseUrl;
							return ValidatorFunctions.wrapInPromise(
								!baseUrl || baseUrl.toString().length <= IntegrationConstants.afasBaseURLMaxLength
							);
						},
					},
				},
			},
		};
	} else if (integrationType === IntegrationTypeEnum.HereOnTrack) {
		return {
			hereOnTrackApiKey: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!!integrationHereOnTrackMetadata?.hereOnTrackApiKey
							);
						},
					},
					maxLength: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.hereOnTrackApiKeyMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							const hereOnTrackApiKey = integrationHereOnTrackMetadata?.hereOnTrackApiKey;
							return ValidatorFunctions.wrapInPromise(
								!hereOnTrackApiKey ||
									hereOnTrackApiKey.toString().length <=
										IntegrationConstants.hereOnTrackApiKeyMaxLength
							);
						},
					},
				},
			},
			hereOnTrackRefreshIntervalMinutes: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!!integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes
							);
						},
					},
					minNumber: {
						message:
							TranslateText('fieldsValidations.minValue') +
							IntegrationConstants.hereOnTrackRefreshIntervalMinutesMin,
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							const hereOnTrackRefreshInterval =
								integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes;
							return ValidatorFunctions.wrapInPromise(
								(!hereOnTrackRefreshInterval && hereOnTrackRefreshInterval !== 0) ||
									hereOnTrackRefreshInterval >=
										IntegrationConstants.hereOnTrackRefreshIntervalMinutesMin
							);
						},
					},
					maxNumber: {
						message:
							TranslateText('fieldsValidations.maxValue') +
							IntegrationConstants.hereOnTrackRefreshIntervalMinutesMax,
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							const hereOnTrackRefreshInterval =
								integrationHereOnTrackMetadata?.hereOnTrackRefreshIntervalMinutes;
							return ValidatorFunctions.wrapInPromise(
								(!hereOnTrackRefreshInterval && hereOnTrackRefreshInterval !== 0) ||
									hereOnTrackRefreshInterval <=
										IntegrationConstants.hereOnTrackRefreshIntervalMinutesMax
							);
						},
					},
				},
			},
			hereOnTrackGroupId: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationHereOnTrackMetadata = data as IntegrationHereOnTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!!integrationHereOnTrackMetadata?.hereOnTrackGroupId
							);
						},
					},
				},
			},
		};
	} else if (integrationType === IntegrationTypeEnum.EasyTrack) {
		return {
			accessTokenCallbackURL: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationEasyTrackMetadata = data as IntegrationEasyTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!!integrationEasyTrackMetadata?.accessTokenCallbackURL
							);
						},
					},
					maxLengthConditionally: {
						message: TranslateTextInterpolated('fieldsValidations.maxLength', [
							IntegrationConstants.easyTrackCallbackURLMaxLength.toString(),
						]),
						validationFunction: (data) => {
							const integrationEasyTrackMetadata = data as IntegrationEasyTrackMetadata;
							const accessTokenCallbackURL = integrationEasyTrackMetadata?.accessTokenCallbackURL;
							return ValidatorFunctions.wrapInPromise(
								!accessTokenCallbackURL ||
									accessTokenCallbackURL.toString().length <=
										IntegrationConstants.easyTrackCallbackURLMaxLength
							);
						},
					},
				},
			},
			bypassAgreementRequired: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationEasyTrackMetadata = data as IntegrationEasyTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!integrationEasyTrackMetadata?.bypassAgreementRequired
							);
						},
					},
				},
			},
			geocodingAgreementRequired: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const integrationEasyTrackMetadata = data as IntegrationEasyTrackMetadata;
							return ValidatorFunctions.wrapInPromise(
								!integrationEasyTrackMetadata?.geocodingAgreementRequired
							);
						},
					},
				},
			},
		};
	}

	return {};
};

export const IntegrationConstants = {
	hereOnTrackRefreshIntervalMinutesDefault: 60,
	hereOnTrackRefreshIntervalMinutesMin: 5,
	hereOnTrackRefreshIntervalMinutesMax: 99999,
	hereOnTrackApiKeyMaxLength: 50,
	afasAccessTokenMaxLength: 500,
	afasBaseURLMaxLength: 500,
	easyTrackCallbackURLMaxLength: 500,
	chronExpressionAfas: '0 0 12,18 * * *',
};
