import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tooltip } from '@material-ui/core';
import { FormatLineSpacing, SelectAll, Sort, WrapText } from '@material-ui/icons';

import RemoveDoneIcon from '../../components/Common/Icons/RemoveDoneIcon';
import { ApplicationState } from '../../store';
import { historyStoreActionCreators, SET_HISTORY_GROUPING_OPTIONS, TripGrouping } from '../../store/HistoryStore';
import { TranslateText } from '../../utils/Translations';
import MaterialAutocomplete, { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import { FleetSelectionButton } from '../FleetSelectionButton';

const getGroupByOptions = (driverIdentification: boolean): AutocompleteItem[] => {
	const datasource = [
		{
			display: TranslateText('liveMap.tripsDate'),
			id: TripGrouping.tripDate,
		},
		{
			display: TranslateText('liveMap.trip'),
			id: TripGrouping.tripNumber,
		},
		{
			display: TranslateText('liveMap.objectName'),
			id: TripGrouping.objectName,
		},
	];
	if (driverIdentification) {
		datasource.push({
			display: TranslateText('liveMap.driverName'),
			id: TripGrouping.driverName,
		});
	}

	return datasource;
};

const HistoryFilter = () => {
	const historyGrouping = useSelector((s: ApplicationState) => s.historyStore.grouping);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const historyTrips = useSelector((state: ApplicationState) => state.historyStore.historyTrips);
	const historyGridTrips = useSelector((state: ApplicationState) => state.historyStore.historyGridTrips);
	const dateFilter = useSelector((state: ApplicationState) => state.historyFilter.dateFilter);

	const dispatch = useDispatch();

	const [orderDatasource, setOrderDatasource] = useState(() => getGroupByOptions(driverIdentification));
	useEffect(() => {
		setOrderDatasource(getGroupByOptions(driverIdentification));
	}, [driverIdentification]);

	const [allTripsVisible, setAllTripsVisible] = useState<boolean>(false);

	useEffect(() => {
		if (historyTrips) {
			let allVisible = true;

			for (let i = 0; i < historyTrips.length; i++) {
				if (!historyTrips[i].visible) {
					allVisible = false;
					break;
				}
			}

			setAllTripsVisible(allVisible);
		}
	}, [historyTrips]);

	const setTripVisibility = useCallback(
		(visible) => {
			const newTrips = [...historyTrips];

			for (let i = 0; i < newTrips.length; i++) {
				newTrips[i].visible = visible;
				newTrips[i].updateTimestamp = new Date().getTime().toString();
			}

			const newgroupedTrips = [...historyGridTrips];
			for (let i = 0; i < newgroupedTrips.length; i++) {
				newgroupedTrips[i].visible = visible;
			}

			dispatch(historyStoreActionCreators.setHistoryTrips(newTrips));
			dispatch(historyStoreActionCreators.setHistoryGridTrips(newgroupedTrips));

			//should be dispatched after setHistoryTrips
			dispatch(historyStoreActionCreators.updateNotVisibleTrips(dateFilter));
		},
		[allTripsVisible, historyTrips, historyGridTrips]
	);

	const events = {
		handleSort: () => {
			dispatch({
				type: SET_HISTORY_GROUPING_OPTIONS,
				payload: {
					...historyGrouping,
					ascending: !historyGrouping.ascending,
				},
			});
		},
		handleTripCounting: () => {
			dispatch({
				type: SET_HISTORY_GROUPING_OPTIONS,
				payload: {
					...historyGrouping,
					dayTripsCount: !historyGrouping.dayTripsCount,
				},
			});
		},
	};

	return (
		<div className="top-sorting-controls">
			<div className="group-by-control">
				<MaterialAutocomplete
					dataSource={orderDatasource}
					valueId={historyGrouping.grouping}
					label={TranslateText('liveMap.groupBy')}
					disableClearable
					onChange={(e) => {
						dispatch({
							type: SET_HISTORY_GROUPING_OPTIONS,
							payload: {
								...historyGrouping,
								grouping: e.value,
							},
						});
					}}
					fullWidth={false}
				/>
			</div>
			<div>
				<Tooltip
					title={
						!historyGrouping.ascending
							? TranslateText('tripMenuHeader.ascending')
							: TranslateText('tripMenuHeader.descending')
					}
					placement="top"
					PopperProps={{
						popperOptions: {
							modifiers: {
								offset: {
									enabled: true,
									offset: '0px, -16px',
								},
							},
						},
					}}
				>
					<Button disableRipple={true} onClick={events.handleSort}>
						<Sort className={`sort-icon ${!historyGrouping.ascending ? 'sort-icon-ascending' : ''}`} />
					</Button>
				</Tooltip>
				<div className="right-container">
					<Tooltip
						title={
							historyGrouping.dayTripsCount
								? TranslateText('tripMenuHeader.continueCounter')
								: TranslateText('tripMenuHeader.resetCounter')
						}
						placement="top"
						PopperProps={{
							popperOptions: {
								modifiers: {
									offset: {
										enabled: true,
										offset: '0px, -16px',
									},
								},
							},
						}}
					>
						<Button disableRipple={true} onClick={events.handleTripCounting} className="counter-button">
							{historyGrouping.dayTripsCount ? (
								<FormatLineSpacing className="continue-counter-icon" />
							) : (
								<WrapText className="reset-counter-icon" />
							)}
						</Button>
					</Tooltip>
					<Tooltip
						title={
							allTripsVisible
								? TranslateText('fleetSelection.deselectAll')
								: TranslateText('fleetSelection.selectAll')
						}
						placement="top"
						PopperProps={{
							popperOptions: {
								modifiers: {
									offset: {
										enabled: true,
										offset: '0px, -16px',
									},
								},
							},
						}}
					>
						<Button disableRipple={true} onClick={() => setTripVisibility(!allTripsVisible)}>
							{allTripsVisible ? (
								<RemoveDoneIcon className="deselect-all-icon" />
							) : (
								<SelectAll className="select-all-icon" />
							)}
						</Button>
					</Tooltip>
					<FleetSelectionButton />
				</div>
			</div>
		</div>
	);
};
export default HistoryFilter;
