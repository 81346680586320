import './WidgetDashboard.scss';

import { History, LocationState } from 'history';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import IdIdentifiable from '../../../models/IdIdentifiable';
import { ApplicationState } from '../../../store';
import DetailsNavigationBar from '../../DetailsNavigationBar/DetailsNavigationBar';
import { WidgetProps } from '../Widget';

type Props = WidgetDashboardTransferableProps & WidgetDashboard;

export type WidgetDashboardTransferableProps = {
	goBackToCallback: (show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) => void;
	currentRecordId: string;
	history: History<LocationState>;
	dashboardConfig?: DashboardLayoutConfiguration;
	goBackText?: string;
	urlPath?: string;
	breadcrumbText?: string;
	filterText?: string;
	entityType: EntityTypeEnum;
};

type WidgetDashboard = {
	children?: Array<React.ReactElement<WidgetProps>> | React.ReactElement<WidgetProps>;
	goBackText: string;
};

type DashboardLayoutConfiguration = {
	allowResizing?: boolean;
	allowFloating?: boolean;
	allowPushing?: boolean;
	columns?: number;
	cellAspectRatio?: number;
	cellSpacing?: [number, number];
};

const WidgetDashboard = (props: Props) => {
	const dispatch = useDispatch();
	const filteredCustomerId = useSelector((s: ApplicationState) => s.globalCustomer.filteredCustomer?.id);
	const gridFilters = useSelector((s: ApplicationState) => s.gridOverview[props.entityType].gridFilters);

	const [allowWidgetEditMode, setAllowWidgetEditMode] = useState<boolean>(true);

	const events = {
		changeDataCallback: (hasChanges: boolean): void => {
			dispatch(unsavedDataStoreActionCreators.setUnsavedData(hasChanges));
		},
		editModeCallback: (editMode: boolean): void => {
			setAllowWidgetEditMode(!editMode);
		},
		handleGoBackTo: (show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) => {
			props.goBackToCallback(show, syncRecord, refreshGrid);
		},
	};
	const util = {
		widgetsFromProps: () => {
			const children = Array.isArray(props.children) ? props.children : [props.children];
			return children.map((c, index) => {
				return React.cloneElement(c, {
					...c.props,
					key: index,
					allowEditMode: allowWidgetEditMode,
					editModeCallback: events.editModeCallback,
					changeDataCallback: events.changeDataCallback,
					entityId: props.currentRecordId,
				});
			});
		},
	};

	const [children, setChildren] = useState(util.widgetsFromProps());

	useEffect(() => {
		setChildren(util.widgetsFromProps());
	}, [props.children, props.currentRecordId, allowWidgetEditMode]);

	return (
		<div className="widget-dashboard" style={{ marginLeft: 0, overflow: 'auto' }}>
			<DetailsNavigationBar
				history={props.history}
				breadcrumbsText={props.breadcrumbText}
				goBackText={props.goBackText}
				currentRecordId={props.currentRecordId}
				navigationUrl={`/api/maintenance${props.urlPath}/navigation`}
				detailUrl={props.urlPath}
				filter={{
					filterText: props.filterText,
					customerId: filteredCustomerId,
					gridFilters: gridFilters,
				}}
				goBackToCallback={events.handleGoBackTo}
				entityType={props.entityType}
			/>
			<DashboardLayoutComponent
				immediateRender={true}
				draggableHandle=".dragicon"
				mediaQuery={'max-width: 1100px'}
				allowResizing={props.dashboardConfig.allowResizing}
				allowFloating={props.dashboardConfig.allowFloating}
				allowPushing={props.dashboardConfig.allowPushing}
				columns={props.dashboardConfig.columns}
				cellAspectRatio={props.dashboardConfig.cellAspectRatio}
				id="predefine_dashboard"
				cellSpacing={props.dashboardConfig.cellSpacing}
			>
				{children}
			</DashboardLayoutComponent>
		</div>
	);
};

WidgetDashboard.defaultProps = {
	breadcrumbDisplay: '',
	useSkipTakeNavigation: false,
	dashboardConfig: {
		allowResizing: true,
		allowFloating: true,
		allowPushing: true,
		columns: 20,
		cellAspectRatio: 95 / 100,
		cellSpacing: [5, 5],
	} as DashboardLayoutConfiguration,
};

export default WidgetDashboard;
