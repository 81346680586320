import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import NumberColumn from 'components/GridOverview/Columns/NumberColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import { History, LocationState } from 'history';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import React from 'react';
import { useSelector } from 'react-redux';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';

const fieldNames = {
	code: 'code',
	name: 'name',
	description: 'description',
	memberObjectCount: 'memberObjectCount',
	memberDriverCount: 'memberDriverCount',
	authorizedPersonCount: 'authorizedPersonCount',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.memberObjectCount]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.memberDriverCount]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.authorizedPersonCount]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

interface Props {
	history: History<LocationState>;
}

export const GroupsOverview = (props: Props) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const columns = [
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		NumberColumn({
			fieldName: fieldNames.memberObjectCount,
			header: TranslateText('maintenanceOverview.grid.colMembersObjects'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
		}),
		NumberColumn({
			fieldName: fieldNames.memberDriverCount,
			header: TranslateText('maintenanceOverview.grid.colMembersDrivers'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Persons) && driverIdentification,
		}),
		NumberColumn({
			fieldName: fieldNames.authorizedPersonCount,
			header: TranslateText('maintenanceOverview.grid.colAuthorizedPersons'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Persons),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.groupsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.groups')}
			disableAddButton={customerLevel === CustomerLevelEnum.OEM || customerLevel === CustomerLevelEnum.Reseller}
			showAddConfigClaim={[
				{
					claim: ClaimType.Groups,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Group}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};
