import React, { useEffect } from 'react';
import SwitchComponent from 'shared/components/SwitchComponent';
import DateTimeUtil from 'shared/datetime/DateTimeUtil';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { DateTimeConstants } from 'utils/DateTimeConstants';
import { TranslateText } from 'utils/Translations';

import { TextField } from '@material-ui/core';
import { ChangeEventArgs as ChangeEventArgsButtons } from '@syncfusion/ej2-buttons';

import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import { WidgetViewDefault } from './Widget';

type Props = WidgetViewDefault & {
	data: DateTimeSettings;
};

interface DateTimeSettings {
	useLocalSettings?: boolean;
	dateFormat: string;
	timeFormat: string;
	calendarFormat: number;
	startingDay: number;
}

const constants = {
	defaultValues: () => {
		return {
			useLocalSettings: true,
			dateFormat: DateTimeUtil.localDateFormat(),
			timeFormat: DateTimeUtil.localTimeFormat(),
			calendarFormat: 0,
			startingDay: 0,
		} as DateTimeSettings;
	},
	fieldsConfig: { text: 'display', value: 'id' },
};

export const CustomerDateTimeViewValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		dateFormat: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as DateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings || !!dateTimeSettings.dateFormat
						);
					},
				},
			},
		},
		timeFormat: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const dateTimeSettings = data as DateTimeSettings;
						return ValidatorFunctions.wrapInPromise(
							dateTimeSettings.useLocalSettings || !!dateTimeSettings.timeFormat
						);
					},
				},
			},
		},
		calendarFormat: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		startingDay: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
	};

	return fieldRules;
};

const CustomerDateTimeView = (props: Props) => {
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: CustomerDateTimeViewValidationConfig() }, true));
		props.setShowSaveConfirmation({
			callback: () =>
				new Promise((resolve) =>
					resolve({
						title: TranslateText('common.titleSavedData'),
						messages: [TranslateText('notificationMessages.save')],
					})
				),
		});
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | boolean, key: keyof DateTimeSettings) => {
			const newState = { ...props.data };
			(newState[key] as any) = value;

			if (key === 'useLocalSettings') {
				if (value) {
					newState.dateFormat = null;
					newState.timeFormat = null;
					newState.calendarFormat = constants.defaultValues().calendarFormat;
					newState.startingDay = constants.defaultValues().startingDay;
				}
			}

			props.changeDataCallback(newState);
		},
	};

	return (
		<div className={'view-section-wrapper'}>
			<div className={'form-group'}>
				<SwitchComponent
					id={'useLocalSettings'}
					name={'useLocalSettings'}
					placeholder={TranslateText('fields.useLocalSettings')}
					readonly={!props.editMode}
					enabled={props.editMode}
					value={props.data.useLocalSettings}
					change={(e: ChangeEventArgsButtons) => events.onValueChanged(e.checked, 'useLocalSettings')}
				/>
				<ValidationMessage result={props.validationResult?.useLocalSettings} />
			</div>
			<div className="form-group">
				{props.data.useLocalSettings ? (
					<TextField
						id="dateFormat"
						type="text"
						fullWidth
						label={TranslateText('fields.dateFormat')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="dateFormat"
						value={TranslateText('fields.useLocalSettings')}
						disabled={!props.editMode}
					/>
				) : (
					<>
						<MaterialAutocomplete
							valueId={props.data.dateFormat}
							dataSource={DateTimeConstants.dateFormat}
							name="dateFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.dateFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'dateFormat')}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.dateFormat} />
					</>
				)}
			</div>
			<div className="form-group">
				{props.data.useLocalSettings ? (
					<TextField
						id="timeFormat"
						type="text"
						fullWidth
						label={TranslateText('fields.timeFormat')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="timeFormat"
						value={TranslateText('fields.useLocalSettings')}
						disabled={!props.editMode}
					/>
				) : (
					<>
						<MaterialAutocomplete
							valueId={props.data.timeFormat}
							dataSource={DateTimeConstants.timeFormat}
							name="timeFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.timeFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'timeFormat')}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.timeFormat} />
					</>
				)}
			</div>
			<div className="form-group">
				{props.data.useLocalSettings ? (
					<TextField
						id="calendarFormat"
						type="text"
						fullWidth
						label={TranslateText('fields.calendarFormat')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="calendarFormat"
						value={TranslateText('fields.useLocalSettings')}
						disabled={!props.editMode}
					/>
				) : (
					<>
						<MaterialAutocomplete
							valueId={props.data.calendarFormat}
							dataSource={DateTimeConstants.calendarFormat()}
							name="calendarFormat"
							disabled={!props.editMode}
							label={TranslateText('fields.calendarFormat')}
							onChange={({ value }) => events.onValueChanged(value as string, 'calendarFormat')}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.calendarFormat} />
					</>
				)}
			</div>
			<div className="form-group">
				{props.data.useLocalSettings ? (
					<TextField
						id="startingDay"
						type="text"
						fullWidth
						label={TranslateText('fields.startingDay')}
						inputProps={{ readOnly: true, style: { fontSize: 12 } }}
						name="startingDay"
						value={TranslateText('fields.useLocalSettings')}
						disabled={!props.editMode}
					/>
				) : (
					<>
						<MaterialAutocomplete
							valueId={props.data.startingDay}
							dataSource={DateTimeConstants.startingDay()}
							name="startingDay"
							disabled={!props.editMode}
							label={TranslateText('fields.startingDay')}
							onChange={({ value }) => events.onValueChanged(value as string, 'startingDay')}
							disableClearable
						/>
						<ValidationMessage result={props.validationResult?.startingDay} />
					</>
				)}
			</div>
		</div>
	);
};

export default CustomerDateTimeView;
