import { AnyAction, Dispatch } from 'redux';

import * as GlobalSettings from '../GlobalSettings.json';
import ajaxUtil from '../utils/Ajax';
import { IAvailableCustomer } from './index';

export interface IAvailableCustomersState {
	data: IAvailableCustomer[];
	hasNewCustomerAdded: boolean;
	hasCustomerWithChangedName: boolean;
	selectionEnabled: boolean;
}

const initialState: IAvailableCustomersState = {
	data: [],
	hasNewCustomerAdded: false,
	hasCustomerWithChangedName: false,
	selectionEnabled: true,
};

export const availableCustomersActions = {
	fetch: (dispatch: Dispatch) => {
		ajaxUtil
			.get<{ success: boolean; customers: IAvailableCustomer[] }>(
				GlobalSettings.customersMaintenanceApi + '/HasAccessTo'
			)
			.then((result) => {
				if (result.success) {
					dispatch({
						type: 'SET_AVAILABLE_CUSTOMERS',
						payload: result.customers,
					});
				}
			});
	},
	set: (dispatch: Dispatch, customers: IAvailableCustomer[]) => {
		dispatch({
			type: 'SET_AVAILABLE_CUSTOMERS',
			payload: customers,
		});
	},
	hasNewCustomer: (value: boolean) => (dispatch: any) => {
		dispatch({
			type: 'NEW_CUSTOMER_ADDED',
			payload: value,
		});
	},
	customerChangedName: (value: boolean) => (dispatch: any) => {
		dispatch({
			type: 'CUSTOMER_CHANGED_NAME',
			payload: value,
		});
	},
	toggleSelection: (value: boolean) => (dispatch: any) => {
		dispatch({
			type: 'TOGGLE_SELECTION',
			payload: value,
		});
	},
};

export const availableCustomersReducer = (state: IAvailableCustomersState, action: AnyAction) => {
	state = state || initialState;

	switch (action.type) {
		case 'SET_AVAILABLE_CUSTOMERS': {
			return {
				...state,
				data: action.payload,
			};
		}
		case 'NEW_CUSTOMER_ADDED': {
			return {
				...state,
				hasNewCustomerAdded: action.payload,
			};
		}
		case 'CUSTOMER_CHANGED_NAME': {
			return {
				...state,
				hasCustomerWithChangedName: action.payload,
			};
		}
		case 'TOGGLE_SELECTION': {
			return {
				...state,
				selectionEnabled: action.payload,
			};
		}
		default:
			return state;
	}
};
