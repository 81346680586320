import './rietveld-menu.scss';

import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';

import { Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Assessment, Commute, History, LocationOn, Map, NotificationsActiveOutlined, RecentActors } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';


import { useDispatch } from 'react-redux';
import { gridActionCreators } from 'store/GridOverview';
import EntityTypeEnum from 'models/EntityTypeEnum';
import ClaimType from '../../../authorization/ClaimType';
import EasyTrackFeature from '../../../authorization/EasyTrackFeature';
import GoogleMaterialIcon from '../../Common/Icons/GoogleMaterialIcon';
import LinkHighlighted from './LinkHighlighted';
import ProtectedContainer from './ProtectedContainer';
import { IMainMenuStyles } from './RietveldMenu';

enum MenuType {
	None,
	Config,
}

interface MenuProps {
	requestMenu: (menu: MenuType) => void;
	configAvailable: boolean;
	showDrivers: boolean;
	mainMenuStyles: IMainMenuStyles;
}

const MainMenu = (props: MenuProps) => {
	const dispatch = useDispatch();
	const updateGridFromMenu = useCallback(
		(entityType: EntityTypeEnum) => dispatch(gridActionCreators.updateGridFromMenu(entityType, true)),
		[]
	);
	return (
		<>
			<div id={'main-menu-container'} className={props.mainMenuStyles.menuLayout}>
				<div className="menu-group-container">
					<ProtectedContainer claim={ClaimType.Live} feature={EasyTrackFeature.Live}>
						<LinkHighlighted to="/live" className={props.mainMenuStyles.linkHighLighted}>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<Map />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.live</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					<ProtectedContainer claim={ClaimType.History} feature={EasyTrackFeature.History}>
						<LinkHighlighted to="/history" className={props.mainMenuStyles.linkHighLighted}>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<History />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.history</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					<ProtectedContainer claim={ClaimType.Reporting} feature={EasyTrackFeature.Reports}>
						<LinkHighlighted to="/reports" className={props.mainMenuStyles.linkHighLighted}>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<Assessment />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.reports</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
				</div>
				<div className="menu-group-container">
					<Divider className="sidebar-item-separator" />
					<ProtectedContainer claim={ClaimType.Locations} feature={EasyTrackFeature.Locations}>
						<LinkHighlighted
							to="/locations"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Location)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<LocationOn className={'locations-icon'} />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.locations</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					<ProtectedContainer claim={ClaimType.Objects}>
						<LinkHighlighted
							to="/objects"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Object)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<Commute className={'objects-icon'} />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.objects</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					<ProtectedContainer claim={ClaimType.Persons}>
						<LinkHighlighted
							to="/persons"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Person)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<RecentActors />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.persons</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					{props.showDrivers ? (
						<LinkHighlighted
							to="/drivers"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Driver)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<PersonIcon className={'drivers-icon'} />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.drivers</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					) : null}
					<ProtectedContainer claim={ClaimType.Groups}>
						<LinkHighlighted
							to="/groups"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Group)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<GoogleMaterialIcon iconName={'groups'} />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.groups</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
					<ProtectedContainer claim={ClaimType.Alerts} feature={EasyTrackFeature.Alerts}>
						<LinkHighlighted
							to="/alerts"
							className={props.mainMenuStyles.linkHighLighted}
							onClick={() => updateGridFromMenu(EntityTypeEnum.Alert)}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<NotificationsActiveOutlined/>
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.alerts</Trans>
								</ListItemText>
							</ListItem>
						</LinkHighlighted>
					</ProtectedContainer>
				</div>
				<div className="menu-group-container">
					<Divider className="sidebar-item-separator" />
					{props.configAvailable ? (
						<div
							id={props.mainMenuStyles.config}
							onClick={() => {
								props.requestMenu(MenuType.Config);
							}}
						>
							<ListItem>
								<ListItemIcon className={props.mainMenuStyles.linkItemIcon}>
									<GoogleMaterialIcon iconName={'settings_applications'} />
								</ListItemIcon>
								<ListItemText className={props.mainMenuStyles.listItemText}>
									<Trans>menu.config</Trans>
								</ListItemText>
							</ListItem>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};

export default MainMenu;
