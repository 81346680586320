import AuthenticationTypeEnum from './AuthenticationTypeEnum';
import DriverDisconnectSettings from './DriverDisconnectSettings';
import EasyDrive2Setting from "./EasyDrive2Setting";

class UpdateDeviceMergedSettingsDto {
	public deviceId: string;
	public authenticationType?: AuthenticationTypeEnum = null;
	public driverDisconnectSettings?: DriverDisconnectSettings = null;
	public deviceNeedToBeSyncedWithEd2?: EasyDrive2Setting = null;
	public deviceShouldHaveEd2MediaSupport?: EasyDrive2Setting = null;
}

export default UpdateDeviceMergedSettingsDto;
