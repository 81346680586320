import { useCallback } from 'react';
import { useToggle } from 'utils/hooks';

const useWidgetEditing = (
	checkHasUnsavedData: () => boolean,
	notifyAboutUnsavedData: (cancel: boolean) => void,
	editPanelCallback: () => void
) => {
	const editWidgetCallback = (): void => {
		// if (checkHasUnsavedData()) {
		// 	notifyAboutUnsavedData(true);
		// 	return;
		// }

		editPanelCallback();
	};

	return { editWidgetCallback };
};

const useWidgetVisibility = (removePanelCallback: () => void) => {
	const [visible, setVisible] = useToggle(true);

	const hideWidgetCallback = useCallback((): void => {
		setVisible();
		removePanelCallback();
	}, []);

	return { visible, hideWidgetCallback };
};

export { useWidgetEditing, useWidgetVisibility };
