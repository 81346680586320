import './AlertMessagesDetails.scss';

import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {ClaimValueAlertTypes} from 'authorization/ClaimType';
import AlertMessageViewer from 'components/Common/AlertMessageViewer/AlertMessageViewer';
import {useAlertNotificationClaims} from 'components/Views/AlertNotificationTypes/hooks';
import {ALERT_MAIL_SUBJECT_MAX_LENGTH, DEBOUNCE_TIME} from 'Constants';
import * as GlobalSettings from 'GlobalSettings.json';
import Alert from 'models/Alert';
import EntityTypeEnum from 'models/EntityTypeEnum';
import MessageTargetEnum from 'models/MessageTargetEnum';
import {useDispatch, useSelector} from 'react-redux';
import {FieldRules, ValidationResult} from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import {ValidatorFunctions} from 'shared/validation/ValidatorFunctions';
import {ApplicationState} from 'store';
import {loadedEntityContextActionCreators} from 'store/LoadedEntityContextData';
import ajaxUtil from 'utils/Ajax';
import {convertToStringContentState} from 'utils/AlertUtils';
import {TranslateText, TranslateTextInterpolated} from 'utils/Translations';
import {
	Box,
	Button,
	Checkbox,
	Fade,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	MenuList,
	Popover,
	Tab,
	Tabs
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

import {AlertMessageEditor} from './AlertMessageEditor';
import {DebouncedButton} from "../Common/DebouncedButton";
import {AlertLanguage} from "../NewAddWizard/Views/Message/MessageView";
import {Add, Clear} from '@material-ui/icons';
import AlertTemplate from "../../models/AlertTemplate";
import MessageWrapper from "../../models/MessageWrapper";
import {MessageValidation} from "../Widgets/AlertMessagesWidget";

export interface AlertMessage {
	alertId: string;
	enabled: boolean;
	subject: string | null;
	body: string;
	appliesTo: MessageTargetEnum;
	useDefault: boolean;
}

export interface EditAlertMessage extends AlertMessage {
	editorBody?: string;

	//used for detecting editor text changes
	editorChangeCheck?: string;
}

interface DefaultMessage {
	subject: string;
	body: string;
	appliesTo: MessageTargetEnum;
}

interface MessageTypeTab {
	type: MessageTargetEnum;
	isEnabled: boolean;
	icon: React.ReactElement;
	labelKey: string;
}

interface LanguageButton{
	id: string;
	code: string;
}

interface Props {
	entityId: string;
	editMode: boolean;
	hasChanges: boolean;
	editedAlertMessage: EditAlertMessage | null;
	alertMessages: EditAlertMessage[];
	formValid: boolean;
	validationResult: ValidationResult | null;
	cancelClicked: () => Promise<boolean>;
	checkChanges: () => Promise<boolean>;
	setHasChanges: (hasChanges: boolean) => void;
	setEditMode: (editMode: boolean) => void;
	setEditedAlertMessage: (alertMessage: EditAlertMessage | null) => void;
	setAlertMessages: (alertMessages: EditAlertMessage[]) => void;
	validateForm: () => Promise<boolean>;
	setValidatorCallback: (validator: Validator) => void;
	formValidations: MessageValidation[];
	setValidationsCallback: (validations: MessageValidation[]) => void;
}

const getAvailableTabs = (alert: Alert, alertClaims: ClaimValueAlertTypes): MessageTypeTab[] => {
	const tabs: MessageTypeTab[] = [];

	if (alertClaims.notificationAlert) {
		tabs.push({
			type: MessageTargetEnum.Notification,
			isEnabled: alert.useNotification,
			icon: <ChatIcon />,
			labelKey: 'fields.notification',
		});
	}
	//TO DO: usePopup will be implemented in the future
	// tabs.push({
	//     type: MessageTargetEnum.Popup,
	//     isEnabled: alert.usePopup,
	//     icon: <WarningIcon />,
	//     labelKey: 'fields.popup',
	// });
	if (alertClaims.mailAlert) {
		tabs.push({
			type: MessageTargetEnum.Mail,
			isEnabled: alert.useEmail,
			icon: <EmailIcon />,
			labelKey: 'fields.email',
		});
	}
	if (alertClaims.smsAlert) {
		tabs.push({
			type: MessageTargetEnum.Sms,
			isEnabled: alert.useSms,
			icon: <SmsIcon />,
			labelKey: 'fields.sms',
		});
	}
	if (alertClaims.whatsAppAlert) {
		tabs.push({
			type: MessageTargetEnum.WhatsApp,
			isEnabled: alert.useWhatsApp,
			icon: <WhatsAppIcon />,
			labelKey: 'fields.whatsApp',
		});
	}

	return tabs;
};

const setAlertUsage = (alert: Alert, editedAlertMessage: EditAlertMessage[]): Alert => {
	const newAlert = {...alert};
	for(let i = 0; i < editedAlertMessage.length; i++) {

		switch (editedAlertMessage[i].appliesTo) {
			case MessageTargetEnum.Notification:
				newAlert.useNotification = editedAlertMessage[i].enabled;
				break;
			case MessageTargetEnum.Popup:
				newAlert.usePopup = editedAlertMessage[i].enabled;
				break;
			case MessageTargetEnum.Mail:
				newAlert.useEmail = editedAlertMessage[i].enabled;
				break;
			case MessageTargetEnum.Sms:
				newAlert.useSms = editedAlertMessage[i].enabled;
				break;
			case MessageTargetEnum.WhatsApp:
				newAlert.useWhatsApp = editedAlertMessage[i].enabled;
				break;
			default:
				break;
		}
	}
	return newAlert;
};

const GetAlertMessagesDetailsValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		subject: {
			rules: {
				requiredConditionally: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const alertMessage = data as AlertMessage;
						return ValidatorFunctions.wrapInPromise(
							alertMessage.appliesTo !== MessageTargetEnum.Mail || !!alertMessage.subject
						);
					},
				},
				maxLength: ValidatorFunctions.maxLength(
					ALERT_MAIL_SUBJECT_MAX_LENGTH,
					TranslateTextInterpolated('fieldsValidations.maxLengthMailSubject', [
						ALERT_MAIL_SUBJECT_MAX_LENGTH.toString(),
					])
				),
			},
		},
		body: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						const alertMessage = data as AlertMessage;
						return ValidatorFunctions.wrapInPromise(!!alertMessage.body && alertMessage.body.length > 8);
					},
				},
			},
		},
	};

	return fieldRules;
};

const AlertMessagesDetails = (props: Props, ref: React.Ref<unknown>) => {
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetAlertMessagesDetailsValidationConfig() }, true));
	}, [props.entityId]);

	const dispatch = useDispatch();
	const [showTestMessage, setShowTestMessage] = useState(false);

	const { alertClaims } = useAlertNotificationClaims();
	const [initialized, setInitialized] = useState(false);
	const [availableTabs, setAvailableTabs] = useState<MessageTypeTab[]>([]);
	const [lengthMessage, setLengthMessage] = useState<number>(0);

	const [selectedMessageType, setSelectedMessageType] = useState<MessageTargetEnum | null>(null);
	const [alert, setAlert] = useState<Alert | null>(null);
	const entityContextData = useSelector((s: ApplicationState) => s.loadedEntityContext.entityContextData);
	const [languages, setLanguages] = useState<AlertLanguage[]>([]);
	const [prevLanguageSelected, setPrevLanguageSelected] = useState<string>(null);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [languageSelected, setLanguageSelected] = useState<LanguageButton>(null);
	const [templates, setTemplates] = useState<Record<string, AlertMessage[]>>({});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'fade-popover' : undefined;

	useEffect(() => {
		getLanguages();
	},[alert?.alertType]);

	useImperativeHandle(ref, () => ({
		forceResetData: async () => {
			getLanguages();
			props.setValidationsCallback([]);
		},
	}));

	useEffect(() => {
		if(props.editMode)
			props.setHasChanges(true);
	},[languages]);

	useEffect(() => {
		if (entityContextData.EntityType === EntityTypeEnum.Alert && entityContextData.id === props.entityId) {
			const editedAlert = entityContextData.entityDetails as Alert;
			if (editedAlert) {
				const tabs = getAvailableTabs(editedAlert, alertClaims);
				setAvailableTabs(tabs);
				setInitialized(true);
				setAlert(editedAlert);
				if (tabs.length && !selectedMessageType) {
					setSelectedMessageType(tabs[0].type);
					const validations = [...props.formValidations];
					if(!validations.some(x => x.appliesTo === tabs[0].type)) {
						props.setValidationsCallback([...validations, {appliesTo: tabs[0].type, formValid: true}]);
					}
				}
			}
		}
	}, [entityContextData]);

	useEffect(() => {
		if(props.editMode) {
			const tempTemplates = {...templates};
			const value = {...props.editedAlertMessage};
			for (let q = 0; q < languages.length; q++) {
				if (languages[q].shown && languages[q].active && tempTemplates.hasOwnProperty(languages[q]?.id)) {
					const copyMessages = JSON.parse(JSON.stringify(templates[languages[q]?.id])) as AlertMessage[];
					for (let i = 0; i < copyMessages.length; i++)
						if (copyMessages[i].appliesTo === props.editedAlertMessage.appliesTo) {
							if (languages[q]?.id === languageSelected?.id) {
								if(copyMessages[i].enabled !== value.enabled ||
								copyMessages[i].subject !== value.subject ||
								copyMessages[i].body !== value.body) {
									props.setHasChanges(true);
								}
								copyMessages[i].body = JSON.parse(JSON.stringify(value.body));
								copyMessages[i].subject = JSON.parse(JSON.stringify(value.subject));
							}
							copyMessages[i].enabled = JSON.parse(JSON.stringify(value.enabled));
							break;
						}
					tempTemplates[languages[q]?.id] = JSON.parse(JSON.stringify(copyMessages));
				}
			}
			setTemplates(tempTemplates);
		}
	},[props.editedAlertMessage]);

	useEffect(() => {
		if (selectedMessageType) {
			const alertMessage = templates[languageSelected?.id]?.find((x) => x.appliesTo === selectedMessageType);
			if (alertMessage) {
				props.setEditedAlertMessage({
					...alertMessage,
					editorBody: convertToStringContentState(alertMessage.body),
					editorChangeCheck: null,
				});
			} else {
				getMessages(languageSelected?.id, languageSelected?.code);
			}
		}
		if(prevLanguageSelected !== languageSelected?.id) {
				getMessages(languageSelected?.id, languageSelected?.code);
		}
		switch (selectedMessageType) {
			case MessageTargetEnum.Notification:
				setLengthMessage(500);
				break;
			case MessageTargetEnum.Sms:
				setLengthMessage(156);
				break;
			case MessageTargetEnum.Mail:
				setLengthMessage(1500);
				break;
			case MessageTargetEnum.WhatsApp:
				setLengthMessage(500);
				break;
		}
	}, [selectedMessageType, languageSelected]);

	useEffect(() => {
		props.setHasChanges(unsavedData);
	},[unsavedData]);

	const getAllMessages = (id: string, code: string) => {
		ajaxUtil
			.get<Record<string, AlertMessage[]>>(
				`${GlobalSettings.alertsApi}/${props.entityId}/alertAllMessages`
			)
			.then((data) => {
				if(Object.keys(data).length !== 0) {
					const temp = {...data};
					const firstTab = availableTabs[0];
					const tabs = [...availableTabs];
					for (let i = 0; i < temp[id]?.length; i++) {
						if(temp[id][i].appliesTo === tabs[i]?.type)
							tabs[i].isEnabled = temp[id][i].enabled;
						if (temp[id][i].appliesTo === firstTab?.type) {
							const data = JSON.parse(JSON.stringify(temp[id][i]));
							setSelectedMessageType(data.appliesTo);
							const validations = [...props.formValidations];
							if(!validations.some(x => x.appliesTo === data.appliesTo)) {
								props.setValidationsCallback([...validations, {appliesTo: data.appliesTo, formValid: true}]);
							}
							if (!data.enabled && data.useDefault) {
								//if alert message was never enabled use default message
								ajaxUtil
									.get<DefaultMessage>(
										`${GlobalSettings.defaultValuesApi}/alertDefaultMessage/${alert?.alertType}/${data.appliesTo}/${code}`
									)
									.then((defaultMessage) => {
										data.subject = TranslateText(defaultMessage.subject);
										data.body = TranslateText(defaultMessage.body);
										addAlertMessageToState(data);
									});
							} else {
								addAlertMessageToState(data);
							}

						}
					}
					if(tabs.length > 0)
						setAvailableTabs(tabs);
					setTemplates({...data});
				}
				else
				{
					if(alert?.alertType !== undefined)
						getDefaultMessagesInit(id, code);
				}
			});
	}
	const getMessages = (id: string, code: string) => {
		if(templates !== undefined) {
			for (let i = 0; i < templates[languageSelected?.id]?.length; i++) {
				if (templates[languageSelected?.id][i].appliesTo === selectedMessageType) {
					const data = JSON.parse(JSON.stringify(templates[languageSelected?.id][i]));
					if (!data.enabled && data.useDefault) {
						//if alert message was never enabled use default message
						ajaxUtil
							.get<DefaultMessage>(
								`${GlobalSettings.defaultValuesApi}/alertDefaultMessage/${alert?.alertType}/${selectedMessageType}/${code}`
							)
							.then((defaultMessage) => {
								data.subject = TranslateText(defaultMessage.subject);
								data.body = TranslateText(defaultMessage.body);
								addAlertMessageToState(data);
							});
					} else {
						addAlertMessageToState(data);
					}
				}
			}
		}
	}

	const getDefaultMessagesInit = (languageId: string, code: string) => {
		const entityType = entityContextData.entityDetails?.alertType;
		let tempTemplates = {} as Record<string, AlertMessage[]>;
		const languageCode = code;
		let alertTemplates = new AlertTemplate();
		const baseUrl =
			GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + entityType + '/';

		let promises = Object.keys(MessageTargetEnum).map((element) => {
			const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

			if (!entityType) {
				return Promise.resolve();
			}
			return ajaxUtil.get(baseUrl + tempCurrentMessageTab + '/' + languageCode).then((result: any) => {
				switch (tempCurrentMessageTab) {
					case MessageTargetEnum.Notification:
						alertTemplates.notificationTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Popup:
						alertTemplates.popupTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Mail:
						alertTemplates.mailTemplate = {
							body: result.body,
							subject: result.subject,
						} as MessageWrapper;
						break;
					case MessageTargetEnum.Sms:
						alertTemplates.smsTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					case MessageTargetEnum.WhatsApp:
						alertTemplates.whatsAppTemplate = {
							body: result.body,
							subject: '',
						} as MessageWrapper;
						break;
					default:
						break;
				}
			}).catch((err) => {
				console.log(err);
			});
		});

		Promise.all(promises).then(() => {
			const copyMessages = [] as AlertMessage[];
			const firstTab = availableTabs[0];
			for(let key in MessageTargetEnum) {
				const newMessage = {
					alertId: alert?.id,
					subject: '',
					body: '',
					appliesTo: key,
					enabled: false,
					useDefault: true,
				} as AlertMessage;
				switch (key) {
					case MessageTargetEnum.Notification:
						newMessage.body = JSON.parse(JSON.stringify(alertTemplates.notificationTemplate.body));
						newMessage.subject = JSON.parse(JSON.stringify(alertTemplates.notificationTemplate.subject));
						break;
					case MessageTargetEnum.Popup:
						newMessage.body = JSON.parse(JSON.stringify(alertTemplates.popupTemplate.body));
						newMessage.subject = JSON.parse(JSON.stringify(alertTemplates.popupTemplate.subject));
						break;
					case MessageTargetEnum.Mail:
						newMessage.body = JSON.parse(JSON.stringify(alertTemplates.mailTemplate.body));
						newMessage.subject = JSON.parse(JSON.stringify(alertTemplates.mailTemplate.subject));
						break;
					case MessageTargetEnum.Sms:
						newMessage.body = JSON.parse(JSON.stringify(alertTemplates.smsTemplate.body));
						newMessage.subject = JSON.parse(JSON.stringify(alertTemplates.smsTemplate.subject));
						break;
					case MessageTargetEnum.WhatsApp:
						newMessage.body = JSON.parse(JSON.stringify(alertTemplates.whatsAppTemplate.body));
						newMessage.subject = JSON.parse(JSON.stringify(alertTemplates.whatsAppTemplate.subject));
						break;
					default:
						break;
				}
				copyMessages.push(newMessage);
				if(firstTab !== null && firstTab !== undefined && firstTab.type === key)
				{
					addAlertMessageToState(newMessage);
				}
			}

			tempTemplates[languageId] = JSON.parse(JSON.stringify(copyMessages));
			setTemplates(tempTemplates);
		});
	}
	const getDefaultMessages = (languageId: string, code: string) => {
			const entityType = entityContextData.entityDetails.alertType;
			let tempTemplates = {...templates};
			const languageCode = code;
			let alertTemplates = new AlertTemplate();
			const baseUrl =
				GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + entityType + '/';

			let promises = Object.keys(MessageTargetEnum).map((element) => {
				const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

				if (!entityType) {
					return Promise.resolve();
				}
				return ajaxUtil.get(baseUrl + tempCurrentMessageTab + '/' + languageCode).then((result: any) => {
					switch (tempCurrentMessageTab) {
						case MessageTargetEnum.Notification:
							alertTemplates.notificationTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Popup:
							alertTemplates.popupTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Mail:
							alertTemplates.mailTemplate = {
								body: result.body,
								subject: result.subject,
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Sms:
							alertTemplates.smsTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.WhatsApp:
							alertTemplates.whatsAppTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						default:
							break;
					}
				}).catch((err) => {
					console.log(err);
				});
			});

			Promise.all(promises).then(() => {
				const tempLanguage = languages.find(x => x.shown && x.id !== languageId).id;

				const copyMessages = JSON.parse(JSON.stringify(templates[tempLanguage]));

				for(let i = 0; i < copyMessages.length; i++) {
					switch (copyMessages[i].appliesTo) {
						case MessageTargetEnum.Notification:
							copyMessages[i].body = JSON.parse(JSON.stringify(alertTemplates.notificationTemplate.body));
							copyMessages[i].subject = JSON.parse(JSON.stringify(alertTemplates.notificationTemplate.subject));
							break;
						case MessageTargetEnum.Popup:
							copyMessages[i].body = JSON.parse(JSON.stringify(alertTemplates.popupTemplate.body));
							copyMessages[i].subject = JSON.parse(JSON.stringify(alertTemplates.popupTemplate.subject));
							break;
						case MessageTargetEnum.Mail:
							copyMessages[i].body = JSON.parse(JSON.stringify(alertTemplates.mailTemplate.body));
							copyMessages[i].subject = JSON.parse(JSON.stringify(alertTemplates.mailTemplate.subject));
							break;
						case MessageTargetEnum.Sms:
							copyMessages[i].body = JSON.parse(JSON.stringify(alertTemplates.smsTemplate.body));
							copyMessages[i].subject = JSON.parse(JSON.stringify(alertTemplates.smsTemplate.subject));
							break;
						case MessageTargetEnum.WhatsApp:
							copyMessages[i].body = JSON.parse(JSON.stringify(alertTemplates.whatsAppTemplate.body));
							copyMessages[i].subject = JSON.parse(JSON.stringify(alertTemplates.whatsAppTemplate.subject));
							break;
						default:
							break;
					}
				}

				tempTemplates[languageId] = JSON.parse(JSON.stringify(copyMessages));
				setTemplates(tempTemplates);
			});
	}


	const addAlertMessageToState = useCallback((data: AlertMessage) => {
		const newAlertMessage: EditAlertMessage = {
			...data,
			editorBody: convertToStringContentState(data.body),
		};
		props.setAlertMessages([...props.alertMessages, newAlertMessage]);
		props.setEditedAlertMessage({ ...newAlertMessage });
	}, []);

	const saveAlertMessage = async () => {
		const isValid = await props.validateForm();
		if (isValid) {
			ajaxUtil
				.put<boolean>(`${GlobalSettings.alertsApi}/alertmessage`, {
					alertId: props.editedAlertMessage.alertId,
					enabled: props.editedAlertMessage.enabled,
					appliesTo: props.editedAlertMessage.appliesTo,
					messages: templates,
				})
				.then((result) => {
					const alertMessageIndex = props.alertMessages.findIndex(
						(x) => x.appliesTo === props.editedAlertMessage.appliesTo
					);
					if (result) {
						//enable has change update the alert
						dispatch(
								loadedEntityContextActionCreators.setLoadedAlertContext(
									setAlertUsage(alert, templates[languageSelected.id])
								)
						);
						//update changes in alertMessages list
						const updatedAlertMessage = {
							...props.alertMessages[alertMessageIndex],
							enabled: props.editedAlertMessage.enabled,
							subject: props.editedAlertMessage.subject,
							body: props.editedAlertMessage.body,
						};
						props.alertMessages[alertMessageIndex] = updatedAlertMessage;
						props.setAlertMessages([...props.alertMessages]);

						props.setEditMode(false);
					}
				});
		}
	};

	const getLanguages = () => {
		let languages = [] as AlertLanguage[];
		const url = `${GlobalSettings.alertsApi}/getAlertLanguagesForEditMessages/${props.entityId}`;
		ajaxUtil.get<AlertLanguage[]>(url).then((result) => {
			const languagesDto = [...result] as AlertLanguage[];
			for(let i = 0; i < languagesDto.length; i++)
			{
				languages.push({
					id: languagesDto[i].id,
					code: languagesDto[i].code,
					active: languagesDto[i].active,
					name: languagesDto[i].name,
					shown: languagesDto[i].shown,
				})
			}
			if(languages.length > 0)
			{
				const firstLanguage = languages.find(x => x.shown);
				setLanguages(languages);
				setLanguageSelected({
					id: firstLanguage.id,
					code: firstLanguage.code
				});
				setPrevLanguageSelected(firstLanguage.id);
				getAllMessages(firstLanguage.id, firstLanguage.code);
			}
		});
	}

	const clickLanguage = (id: string, code: string) => {
		if(id !== languageSelected?.id) {
				setPrevLanguageSelected(languageSelected.id);
				setLanguageSelected({id: id, code: code});
			}
	}
	const clearLanguage = (id: string) => {
		let tempLanguages = [...languages];
		for(let i = 0; i < tempLanguages?.length; i++)
		{
			if(tempLanguages[i].id === id)
			{
				tempLanguages[i].shown = false;
				break;
			}
		}
		let first = tempLanguages.find((x) => x.shown);
		setPrevLanguageSelected(languageSelected.id);
		setLanguageSelected({id: first.id, code: first.code});
		setLanguages(tempLanguages);
		let temTemplates = {...templates};
		delete temTemplates[id];
		setTemplates(temTemplates);
	}
	const handleClickAdd = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (id: string, code: string) => {
		getDefaultMessages(id, code);
		let tempLanguages = [...languages];
		for(let i = 0; i < tempLanguages?.length; i++)
		{
			if(tempLanguages[i].active && !tempLanguages[i].shown && tempLanguages[i].id === id)
			{
				tempLanguages[i].shown = true;
				break;
			}
		}
		setLanguages(tempLanguages);
		setAnchorEl(null);
	}
	const handleCloseLanguages = () => {
		setAnchorEl(null);
	}

	return (
		<div className="alert-messages-details">
			<div className={'language messages-alert-column column1-edit-message'} style={{width: "20%"}}>
				<div className={'column-content'}>
					<div className={'buttons-container'} style={props.editMode ? {height: "calc(100% - 40px)"} : {height: "100%"}} >
						{languages.map((language) => (
							language.active && language.shown
								? <div className="language-button-alert"
									   style={language.id == languageSelected?.id ? {backgroundColor: '#cccccc'} : {backgroundColor: ' #f5f5f5'}}
								>
									<Button
										className="language-btn"
										key={language.id}
										onClick={() => clickLanguage(language.id, language.code.toLowerCase())}
										disabled={!props.formValid && language?.id !== languageSelected?.id && props.editMode}
									>
										{language.code.toUpperCase()}
									</Button>
									<IconButton
										className="clear-btn"
										disabled={!props.formValid}
										hidden={(languages.filter((x) => x.shown)?.length <= 1) || !props.editMode || language.id === languageSelected.id}
										size={'small'}
										onClick={() => {clearLanguage(language.id)}}
									>
										<Clear fontSize={'small'} />
									</IconButton>
								</div>
								: null
						))}
					</div>
					<Button
						className={'add-btn'}
						variant="outlined"
						disabled={!languages.some((x) => x.active && !x.shown) || anchorEl !== null}
						hidden={!props.editMode}
						startIcon={<Add />}
						aria-describedby={id}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClickAdd}
					>
						{TranslateText('fields.addLanguage')}
					</Button>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleCloseLanguages}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						TransitionComponent={Fade}
					>
						<MenuList
							aria-labelledby="fade-button"
							style={{maxHeight: 150, minWidth: 120, overflow: 'auto'}}
						>
							{languages.map((language) => (
								language.active && !language.shown
									? <MenuItem onClick={() => handleClose(language.id, language.code)}>
										{TranslateText(`languages.${language.name}`)}
									  </MenuItem>
									: null
							))}

						</MenuList>
					</Popover>
				</div>
			</div>
			<div className={'language messages-alert-column column2-edit-message'} style={ selectedMessageType !== MessageTargetEnum.Mail ? {width: "10%", paddingTop: 31} : {width: "10%", paddingTop: 106}}>
			{availableTabs.length ? (
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={selectedMessageType}
					onChange={(event, value) => {
						if (value !== selectedMessageType) {
							setSelectedMessageType(value);
							const validations = [...props.formValidations];
							if(!validations.some(x => x.appliesTo === value)) {
								props.setValidationsCallback([...validations, {appliesTo: value, formValid: true}]);
							}
						}
					}}
					TabIndicatorProps={{ style: { display: 'none' } }}
				>
					{availableTabs.map((tab) => (
						<Tab
							key={tab.type}
							value={tab.type}
							icon={tab.icon}
							className={tab.isEnabled ? 'tab-button-edit' : 'tab-button-edit disabled-message'}
						/>
					))}
				</Tabs>
			) : null}
			</div>
				<div className={'language messages-alert-column column3-edit-message'} style={{width: "70%"}}>
			<div className="alert-message-editor">
				{props.editedAlertMessage && alert?.alertType ? (
					<AlertMessageEditor
						alertType={alert?.alertType}
						editMode={props.editMode}
						alertMessage={props.editedAlertMessage}
						validationResult={props.validationResult}
						onChangeCallback={(alertMessage, hasChanges) => {
							props.setEditedAlertMessage(alertMessage);
						}}
					/>
				) : null}

				{initialized && !availableTabs.length ? (
					<div className="no-notification-available">
						<span>{TranslateText('alert.noNotificationAvailable')}</span>
					</div>
				) : null}
				{props.editedAlertMessage ? (
				<Box display="flex" justifyContent="left" style={{marginLeft:10}}>
					<InputLabel className={'validation-warning'}>
						{TranslateTextInterpolated('fieldsValidations.limitCharacters', [lengthMessage.toString()])}
					</InputLabel>
				</Box>)
					: null }
				{props.editedAlertMessage ? (
				<Box className="checkbox-form-group">
					<label htmlFor="EnableAlertMessage">{TranslateText('messageEditor.enable')}</label>
					<Checkbox
						id="EnableAlertMessage"
						checkedIcon={<CheckBoxOutlinedIcon />}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						icon={<CheckBoxOutlineBlankOutlinedIcon />}
						checked={props.editedAlertMessage.enabled}
						disabled={!props.editMode}
						onChange={(event, checked) => {
							props.setEditedAlertMessage({ ...props.editedAlertMessage, enabled: checked });
							const index = availableTabs.findIndex((x) => x.type === props.editedAlertMessage.appliesTo);
							if (index >= 0 && availableTabs[index].isEnabled !== checked) {
								availableTabs[index] = { ...availableTabs[index], isEnabled: checked };
								setAvailableTabs( [...availableTabs]);
							}
							setUnsavedData(true);
						}}
					/>
				</Box>
					)
					: null }
				<div className="action-buttons">
					{props.editMode ? (
						<>
							<div>
								<Button
									style={{ backgroundColor: '#c4c4c4' }}
									className="widget-button"
									onClick={() => {
										props.cancelClicked();
									}}
								>
									{TranslateText('common.buttonCancel')}
								</Button>
							</div>
							<div>
								<Button
									style={{ backgroundColor: '#537bec', color: '#fff' }}
									className="widget-button"
									onClick={() => setShowTestMessage(true)}
								>
									{TranslateText('messageEditor.showTestMessage')}
								</Button>
								<DebouncedButton
									style={{ backgroundColor: '#467b2e', color: '#fff' }}
									className="widget-button"
									disabled={!props.formValid}
									onClick={() => saveAlertMessage()}
									debounceTime={DEBOUNCE_TIME}
								>
									{TranslateText('common.buttonSave')}
								</DebouncedButton>
							</div>
						</>
					) : null}
				</div>
			</div>
			{showTestMessage ? (
				<AlertMessageViewer
					showDialog={showTestMessage}
					setShowDialog={setShowTestMessage}
					subject={props.editedAlertMessage.subject}
					body={props.editedAlertMessage.body}
					appliesTo={props.editedAlertMessage.appliesTo}
				/>
			) : null}
		</div>
		</div>
	);
};

export default forwardRef(AlertMessagesDetails);
