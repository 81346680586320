import { useCallback, useEffect, useState } from 'react';

const usePreThresholdValue = (initialValue: number, onChangeHandler: (value: number) => void) => {
	const [preThresholdValue, setPreThresholdValue] = useState<number>(initialValue);

	useEffect(() => {
		setPreThresholdValue(initialValue);
	}, [initialValue]);

	const setPreThreshold = useCallback((value: number) => {
		setPreThresholdValue(value);
		onChangeHandler(value);
	}, []);

	return { preThresholdValue, setPreThreshold };
};

export { usePreThresholdValue };
