import React, { FunctionComponent, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
	Cancel,
	CheckBox,
	Delete,
	FormatAlignCenter,
	FormatAlignLeft,
	FormatAlignRight,
	InsertPhoto,
	Movie,
} from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { ToolbarPopupEnum } from '../types';

export type TAlignment = 'left' | 'center' | 'right';

export type TMediaType = 'image' | 'video';

export type TUrlData = {
	url?: string;
	width?: number;
	height?: number;
	alignment?: TAlignment;
	type?: TMediaType;
};

interface IUrlPopoverStateProps extends WithStyles<typeof styles> {
	anchor?: HTMLElement;
	data?: TUrlData;
	type?: ToolbarPopupEnum;
	component?: React.FC<any>;
	onConfirm: (type: ToolbarPopupEnum, ...args: any) => void;
	fontSize: number;
	fontFamily: string;
}

const styles = ({ spacing }: Theme) =>
	createStyles({
		linkPopover: {
			padding: spacing(2, 2, 2, 2),
			maxWidth: 250,
		},
		linkTextField: {
			width: '100%',
		},
	});

const UrlPopover: FunctionComponent<IUrlPopoverStateProps> = (props) => {
	const [data, setData] = useState<TUrlData>(
		props.data || {
			url: undefined,
			width: undefined,
			height: undefined,
			alignment: undefined,
			type: undefined,
		}
	);

	const [fontFamily, setFontFamily] = useState(props.fontFamily);
	const fontSizeFieldRef = useRef<HTMLInputElement>(null);

	const { classes } = props;

	const onSizeChange = (value: any, prop: 'width' | 'height') => {
		if (value === '') {
			setData({ ...data, [prop]: undefined });
			return;
		}
		const intValue = parseInt(value, 10);
		if (isNaN(intValue)) {
			return;
		}
		setData({ ...data, [prop]: intValue });
	};

	return (
		<Popover
			open={props.anchor !== undefined}
			anchorEl={props.anchor}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{(props.component && <props.component />) || (
				<div className={classes.linkPopover}>
					<Grid container spacing={1}>
						<Grid container item xs spacing={1}>
							{props.type === ToolbarPopupEnum.Media || props.type === ToolbarPopupEnum.Link ? (
								<Grid item xs={12}>
									<TextField
										className={classes.linkTextField}
										onChange={(event) => setData({ ...data, url: event.target.value })}
										label={
											props.type === ToolbarPopupEnum.Link ||
											props.type === ToolbarPopupEnum.Media
												? 'URL'
												: 'Font Size'
										}
										defaultValue={props.data && props.data.url}
										autoFocus={true}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							) : (
								<>
									<Grid item xs={12}>
										<TextField
											fullWidth
											className={'font-size-text-field'}
											inputRef={fontSizeFieldRef}
											type={'number'}
											inputProps={{ min: 10, max: 24 }}
											label={'Font Size'}
											defaultValue={props.fontSize}
											autoFocus={true}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											color={fontFamily === 'Arial' ? 'primary' : 'inherit'}
											fullWidth
											onClick={() => setFontFamily('Arial')}
										>
											Arial
										</Button>
										<Button
											color={fontFamily === 'Times New Roman' ? 'primary' : 'inherit'}
											fullWidth
											onClick={() => setFontFamily('Times New Roman')}
										>
											Times New Roman
										</Button>
										<Button
											color={fontFamily === 'Courier New' ? 'primary' : 'inherit'}
											fullWidth
											onClick={() => setFontFamily('Courier New')}
										>
											Courier New
										</Button>
									</Grid>
								</>
							)}
							{props.type === ToolbarPopupEnum.Media ? (
								<>
									<Grid item xs={12}>
										<ButtonGroup fullWidth>
											<Button
												color={!data.type || data.type === 'image' ? 'primary' : 'inherit'}
												size="small"
												onClick={() => setData({ ...data, type: 'image' })}
											>
												<InsertPhoto />
											</Button>
											<Button
												color={data.type === 'video' ? 'primary' : 'inherit'}
												size="small"
												onClick={() => setData({ ...data, type: 'video' })}
											>
												<Movie />
											</Button>
										</ButtonGroup>
									</Grid>
									<Grid item xs={6}>
										<TextField
											onChange={(event) => onSizeChange(event.target.value, 'width')}
											value={data.width || ''}
											label="Width"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											onChange={(event) => onSizeChange(event.target.value, 'height')}
											value={data.height || ''}
											label="Height"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<ButtonGroup fullWidth>
											<Button
												color={data.alignment === 'left' ? 'primary' : 'inherit'}
												size="small"
												onClick={() => setData({ ...data, alignment: 'left' })}
											>
												<FormatAlignLeft />
											</Button>
											<Button
												color={data.alignment === 'center' ? 'primary' : 'inherit'}
												size="small"
												onClick={() => setData({ ...data, alignment: 'center' })}
											>
												<FormatAlignCenter />
											</Button>
											<Button
												color={data.alignment === 'right' ? 'primary' : 'inherit'}
												size="small"
												onClick={() => setData({ ...data, alignment: 'right' })}
											>
												<FormatAlignRight />
											</Button>
										</ButtonGroup>
									</Grid>
								</>
							) : null}
						</Grid>
						<Grid
							container
							item
							xs={12}
							direction="row"
							justify={props.type === ToolbarPopupEnum.Font ? 'space-between' : 'flex-end'}
						>
							{props.data && props.data.url ? (
								<Button onClick={() => props.onConfirm(props.type, '')}>
									<Delete />
								</Button>
							) : null}
							{props.type === ToolbarPopupEnum.Font ? (
								<Button onClick={() => props.onConfirm(ToolbarPopupEnum.None)}>
									<Cancel />
								</Button>
							) : null}
							<Button
								onClick={() => {
									if (props.type === ToolbarPopupEnum.Media || props.type === ToolbarPopupEnum.Link) {
										props.onConfirm(
											props.type,
											data.url,
											data.width,
											data.height,
											data.alignment,
											data.type
										);
										return;
									}
									if (props.type === ToolbarPopupEnum.Font) {
										const size = Number(fontSizeFieldRef.current.value);
										props.onConfirm(props.type, size, fontFamily);
										return;
									}
									if (props.type === ToolbarPopupEnum.Variables) {
										props.onConfirm(props.type);
										return;
									}
								}}
							>
								<CheckBox />
							</Button>
						</Grid>
					</Grid>
				</div>
			)}
		</Popover>
	);
};

export default withStyles(styles, { withTheme: true })(UrlPopover);
