import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import userService from '../auth';
import * as AddWizard from './AddWizard';
import * as AvailableCustomers from './AvailableCustomers';
import * as CommonData from './CommonData';
import * as FilteredEntities from './FilteredEntities';
import * as FleetSelection from './FleetSelection';
import * as GlobalCustomer from './GlobalCustomer';
import * as Grid from './GridOverview';
import * as GroupMembersSelectionStore from './GroupMembersSelectionStore';
import * as HistoryFilter from './HistoryFilter';
import * as HistoryStore from './HistoryStore';
import * as LeftSideBar from './LeftSideBar';
import * as LiveData from './LiveData';
import * as LiveFilter from './LiveDataFilter';
import * as LiveMap from './LiveMap';
import * as LoadedEntityContextData from './LoadedEntityContextData';
import * as NotificationsStore from './NotificationsStore';
import * as Persons from './Persons';
import * as ReportStore from './ReportStore';
import * as ResizableEntityState from './ResizableEntityState';
import * as RightSideBar from './RightSideBar';
import * as Translations from './Translations';
import * as UnsavedDataStore from './UnsavedDataStore';
import * as UserData from './UserData';

const rootPersistConfig = {
	key: 'persistedState',
	storage: storage,
	whitelist: [
		'currentSession',
		'globalCustomer',
		'historyFilter',
		'gridOverview',
		'historyStore',
		'oidc',
		'fleetSelection',
		'leftSideBar',
		'rightSideBar',
		'liveData',
		'liveMap',
	],
};

const sessionPersistConfig = {
	key: 'persistedSession',
	storage: storageSession,
	whitelist: [''],
};

export default function configureStore(history: History) {
	const reducers = {
		persons: Persons.personsReducer,
		addWizard: AddWizard.addWizardReducer,
		currentSession: UserData.CurrentSessionReducer,
		loadedEntityContext: LoadedEntityContextData.loadedEntityContextReducer,
		rightSideBar: RightSideBar.rightSideBarReducer,
		leftSideBar: LeftSideBar.leftSideBarReducer,
		filteredEntities: FilteredEntities.filteredEntitiesReducer,
		historyFilter: HistoryFilter.historyFilterReducer,
		liveMap: LiveMap.liveMapReducer,
		liveData: LiveData.liveDataActionReducer,
		liveDataFilter: LiveFilter.LiveFilterReducer,
		translations: Translations.translationsReducer,
		globalCustomer: GlobalCustomer.globalCustomerReducer,
		historyStore: HistoryStore.historyStoreReducer,
		unsavedDataStore: UnsavedDataStore.unsavedDataStoreReducer,
		gridOverview: Grid.gridReducer,
		fleetSelection: FleetSelection.fleetSelectionReducer,
		availableCustomers: AvailableCustomers.availableCustomersReducer,
		commonData: CommonData.commonDataReducer,
		notificationsStore: NotificationsStore.notificationsReducer,
		resizableEntity: ResizableEntityState.resizableEntityReducer,
		reportStore: ReportStore.reportStoreReducer,
		groupMembersSelection: GroupMembersSelectionStore.groupMembersSelectionReducer,
	};

	userService.userManager.events.addSilentRenewError(function(error) {
		console.error('error while renewing the access token', error);
		history.push('/logout');
	});

	userService.userManager.events.addUserSignedOut(function() {
		// clear store persistance on user log-out
		persistor.pause();
		persistor.purge();
		localStorage.clear();
	});

	userService.userManager.events.addUserLoaded(function() {
		userService.userManager.clearStaleState();
	});

	const middleware = [thunk, routerMiddleware(history)];

	const rootReducer = combineReducers({
		fleetSelection: persistReducer(sessionPersistConfig, FleetSelection.fleetSelectionReducer),
		...reducers,
		router: connectRouter(history),
		oidc: oidcReducer,
	});

	const enhancers = [];
	const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development') {
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const store = createStore(
		persistReducer(rootPersistConfig, rootReducer),
		{},
		compose(applyMiddleware(...middleware), ...enhancers)
	);

	const persistor = persistStore(store);
	loadUser(store, userService.userManager);
	return { store, persistor };
}
