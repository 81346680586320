import CustomerLevelEnum from './CustomerLevelEnum';
import IdIdentifiable from './IdIdentifiable';

export class Person extends IdIdentifiable {
	constructor(customerId?: string, customerType?: CustomerLevelEnum) {
		super();
		this.customerId = customerId;
		this.customerType =
			customerType || customerType === CustomerLevelEnum.OEM ? customerType : CustomerLevelEnum.Default;
		this.username = '';
		this.code = '';
		this.externalCode = '';
	}

	code?: string = null;
	externalCode?: string = null;
	customerId: string = null;
	customerType: CustomerLevelEnum;
	active = true;
	activatedScreensaver: boolean;
	firstName?: string = null;
	lastName?: string = null;
	emailAddress?: string = null;
	phoneNumber?: string = null;
	languageId?: string = null;
	birthDate?: Date;
	birthName?: string = null;
	initials?: string = null;
	socialSecurityNumber = '';
	homeAddress: HomeAddress = new HomeAddress();
	username?: string = null;
	password?: string = null;
	driver = false;
	loginCode?: string = null;
	keypadCode: string = null;
	tachoId?: string = null;
	hasObjectActiveConnections = false;
	hasKeyActiveConnections = false;
	hasGroupActiveConnections = false;
	objectName?: string = null;
	objectId?: string = null;
	addInGroups: string[] = [];
}

export class HomeAddress {
	[propName: string]: any;

	street?: string = null;
	number?: string = null;
	postalCode?: string = null;
	city?: string = null;
	countryId?: string = null;
	countryCode?: string = null;
}
