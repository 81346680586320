import React, { useEffect, useState } from 'react';
import { JsxElement } from 'typescript';

export interface AccordionSectionView {
	className?: string;
	index?: number;
	toggleCallback?: (index: number, expanded: boolean) => void;
	header?: string;
	operationInProgress?: boolean;
	expanded?: boolean;
}

export interface Props {
	children?: React.ReactElement<AccordionSectionView>[] | React.ReactElement<AccordionSectionView>;
	expandedCallback?: (childIndex: number) => void;
	scrollToTop?: () => void;
	unExpandedCallback?: () => void;
	className?: string;
}

const utils = {
	getChildrenArray: (props: Props) => {
		const children = Array.isArray(props.children) ? props.children : [props.children];
		return children.filter((el) => el);
	},
};
const Accordion = (props: Props) => {
	const events = {
		handleToggle: (index: number, expanded: boolean) => {
			setChildren(
				utils.getChildrenArray(props).map((c, i) => {
					return React.cloneElement(c, {
						index: i,
						key: i,
						expanded: i === index ? !expanded : false,
						toggleCallback: events.handleToggle,
						operationInProgress: c.props.operationInProgress,
					});
				})
			);
			if (props.expandedCallback && !expanded) {
				props.expandedCallback(index);
				props.scrollToTop && props.scrollToTop();
			}
			else {
				if (props.unExpandedCallback)
					props.unExpandedCallback();
			}
		},
	};

	const [children, setChildren] = useState(
		//keep the same children between renders, do not recreate them completely
		utils.getChildrenArray(props).map((section, index) => {
			return React.cloneElement(section, {
				index: index,
				key: index,
				toggleCallback: events.handleToggle,
				operationInProgress: section.props.operationInProgress,
			});
		})
	);

	useEffect(() => {
		setChildren(
			utils.getChildrenArray(props)?.map((c, i) => {
				return React.cloneElement(c, {
					index: i,
					expanded: c.props.expanded,
					key: i,
					toggleCallback: events.handleToggle,
					operationInProgress: c.props.operationInProgress,
				});
			})
		);
	}, [props.children]);

	return <div className={`accordion ${props.className || ''}`}>{children}</div>;
};

export default Accordion;
