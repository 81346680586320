import './styles.scss';

import { Box, InputBase } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { TranslateText } from 'utils/Translations';

import { GroupConstruct as GroupConstructComponent } from './GroupConstruct';
import { GroupConstruct, GroupConstructVariable } from './GroupConstruct/types';
import { GroupedDropdownContainerProps } from './types';

const GroupedDropdownContainer = ({
	groups,
	onVariableClickedCallback,
	displaySearchBox = false,
}: GroupedDropdownContainerProps): JSX.Element => {
	const [searchText, setSearchText] = useState<string>('');
	const [groupConstructs, setGroupConstructs] = useState<GroupConstruct[]>(groups);

	useEffect(() => {
		setGroupConstructs(groups);
	}, [groups]);

	const filterGroupConstructs = (searchText: string, groupConstructs: GroupConstruct[]): void => {
		if (!searchText) {
			setGroupConstructs(groups);

			return;
		}

		const searchTextRegEx = new RegExp(searchText, 'i');

		const filteredGroupConstructs: GroupConstruct[] = groupConstructs.filter(
			(groupConstruct: GroupConstruct): boolean => {
				if (groupConstruct.groupName.match(searchTextRegEx)) {
					return true;
				}

				const newGroupVariables: GroupConstructVariable[] = groupConstruct.groupVariables.filter(
					(groupVariable: GroupConstructVariable) => groupVariable.displayValue.match(searchTextRegEx)
				);

				if (newGroupVariables.length > 0) {
					groupConstruct.groupVariables.splice(0, groupConstruct.groupVariables.length, ...newGroupVariables);

					return true;
				}

				return false;
			}
		);

		setGroupConstructs(filteredGroupConstructs);
	};

	return (
		<>
			{displaySearchBox && (
				<Box paddingX="12px" paddingY="6px">
					<InputBase
						data-testid="grouped-dropdown-container-input-base-test-id"
						className="grouped-dropdown-container-input-search"
						value={searchText}
						placeholder={TranslateText('tagPicker.searchMessage')}
						onChange={(e) => {
							filterGroupConstructs(e.target.value, _.cloneDeep(groups));
							setSearchText(e.target.value);
						}}
					/>
				</Box>
			)}
			{groupConstructs.map((groupConstruct: GroupConstruct) => (
				<GroupConstructComponent
					key={groupConstruct.groupName}
					groupName={groupConstruct.groupName}
					groupNameDisplayValue={groupConstruct.groupNameDisplayValue}
					groupVariables={groupConstruct.groupVariables}
					onVariableClickedCallback={onVariableClickedCallback}
				/>
			))}
		</>
	);
};

export default GroupedDropdownContainer;
