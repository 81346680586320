import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React from 'react';
import { TranslateText } from 'utils/Translations';

import { DurationProps } from './types';
import { TextField } from '@material-ui/core';
import MaterialTextField from "../../../../../shared/components/MaterialTextField/MaterialTextField";

const Duration = ({
	currentValue,
	maxValue,
	validateResult,
	handleOnDurationChange,
	hideLabel,
	disabled,
	isForNewAddWizard = false,
}: DurationProps): JSX.Element => {
	return (
		<div className="form-group">
			<MaterialTextField
				isForNewAddWizard={isForNewAddWizard}
				type="number"
				id="duration"
				name="duration"
				className="alert-duration-exceeded-duration-resize-font"
				fullWidth
				label={!hideLabel ? TranslateText('fields.duration') : null}
				inputProps={{
					style: {
						fontSize: isForNewAddWizard ? 10 : 12
					},
					inputProps: {
						min: 0,
						max: maxValue,
					},
				}}
				value={currentValue?.toString()}
				onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
					if (event.key === 'e' ||
						event.key === '+' ||
						event.key === '-' ||
						event.key === ',' ||
						event.key === '.') {
						event.preventDefault();
					}
				}}
				handleValueChange={(value) => {
					handleOnDurationChange(+value);
				}}
				disabled={disabled}
				validationResult={isForNewAddWizard && !!validateResult && !validateResult.formResult ? validateResult?.validationResult?.duration : null}
			/>
			{!!validateResult && !validateResult.formResult && !isForNewAddWizard && (
				<div className={'validation-error-container'}>
					<ValidationMessage result={validateResult.validationResult.duration} />
				</div>
			)}
		</div>
	);
};

export default Duration;
