import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

import { Box, Button, ButtonGroup } from '@material-ui/core';

import { ITreeNode } from '../SelectionTree/TreeNode/types';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import { SelectionPanelItem } from './SelectionPanelItem';

interface Props {
	selection: ITreeNode[];
	removeItem: (node: ITreeNode) => void;
	removeAll: () => void;
	translationNamespace: string;
	hasSecondButton?: boolean;
	secondButtonText?: string;
	secondButtonCallback?: () => void;
	hasValidations?: boolean;
	validationResult?: ValidationResult;
}

const groupBy = (array: { [key: string]: any }[], key: string): { [key: string]: { [key: string]: any }[] } => {
	return array.reduce(function(result, value) {
		(result[value[key]] = result[value[key]] || []).push(value);
		return result;
	}, {});
};

interface IGroupedNodes {
	key: string;
	value: ITreeNode[];
}

const SelectionPanel = (props: Props) => {
	const [data, setData] = useState([] as ITreeNode[]);
	const [groupedData, setGroupedData] = useState([] as IGroupedNodes[]);

	useEffect(() => {
		setData(props.selection);
	}, [props.selection]);

	useEffect(() => {
		const groups: IGroupedNodes[] = [];
		const groupObj = groupBy(data, 'type');
		for (const key in groupObj) {
			groups.push({
				key: key,
				value: groupObj[key] as ITreeNode[],
			});
		}
		setGroupedData(groups);
	}, [data]);

	const sort = useCallback(
		(dataCollection: IGroupedNodes[]) => {
			dataCollection.sort((a: IGroupedNodes, b: IGroupedNodes) => {
				return a.key.localeCompare(b.key);
			});

			dataCollection.forEach((test) => {
				test.value.sort((a, b) => a.text.localeCompare(b.text));
			});

			return dataCollection;
		},
		[data]
	);

	return (
		<div className={'selection-panel'}>
			<span className={'title'}>{TranslateText(`${props.translationNamespace}.selectionHeader`)}</span>
			<div className={'selection'}>
				{sort(groupedData).map((keyValue) => {
					return (
						<div key={keyValue.key} className={keyValue.key}>
							<span className="title">
								{TranslateText(`${props.translationNamespace}.${keyValue.key}`)}
							</span>
							{keyValue.value.map((node) => (
								<SelectionPanelItem
									key={node.id}
									active={node.active}
									title={node.text}
									type={node.type}
									removeCallback={() => props.removeItem(node)}
									icon={node.icon}
								/>
							))}
						</div>
					);
				})}
			</div>
			{props.hasValidations && (
				<div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
					<ValidationMessage result={props.validationResult?.selectedItems} />
				</div>
			)}
			<ButtonGroup fullWidth>
				<Button
					className="e-flat widget-button clearAll"
					disabled={!props.selection.length}
					onClick={props.removeAll}
				>
					{TranslateText(`${props.translationNamespace}.clearAll`)}
				</Button>
				{props.hasSecondButton && (
					<Button className="e-flat widget-button clearAll" onClick={props.secondButtonCallback}>
						{props.secondButtonText}
					</Button>
				)}
			</ButtonGroup>
		</div>
	);
};

SelectionPanel.defaultProps = {
	translationNamespace: 'fleetSelection',
};

export default SelectionPanel;
