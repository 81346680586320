const DistanceUtils = {
	distanceInKM: (distance: number) => {
		if (distance < 1000) {
			return '<1km';
		}

		return `${Math.round(distance / 1000)}km`;
	},
};
export default DistanceUtils;
