import './rightSidebarMenu.scss';

import { extendObjectNameWithTimeZoneLongFormat } from 'components/ObjectNameWithTimeZoneOffset/Utils';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import * as GlobalSettings from 'GlobalSettings.json';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightSideBarOptions } from 'shared/components/RightSideBar/RightSideBarOptions';
import { ApplicationState } from 'store';
import { LiveDataDispatcher, LiveDataResultDto } from 'store/LiveData';
import { resizableEntityActionCreators, ResizableType } from 'store/ResizableEntityState';
import { SET_LIVE_OPTION } from 'store/RightSideBar';
import ajaxUtil from 'utils/Ajax';

import FleetSelectionArea from '../FleetSelectionArea';
import { NumberConstants } from '../Widgets/CustomerFeaturesView';
import FleetOverviewContainer from './FleetOverviewContainer';
import FleetOverviewFilter from './FleetOverviewFilter';

type LiveMenuProps = {
	visible: boolean;
	resizableExpanded: boolean;
};

const LiveMenu = (props: LiveMenuProps): JSX.Element => {
	const fleetSelectionState = useSelector((state: ApplicationState) => state.fleetSelection);
	const selectedOption = useSelector((state: ApplicationState) => state.rightSideBar.liveOption);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const featuresSettings = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings
			: state.currentSession.customer?.featuresSettings
	);
	const liveEntitiesUnfiltered = useSelector((state: ApplicationState) => state.liveData.liveEntitiesUnfiltered);
	const liveResizable = useSelector((state: ApplicationState) => state.resizableEntity.resizableMenuLive);

	const isDriverWithNoAuthorization = useSelector(
		(state: ApplicationState) => state.currentSession.isDriverWithNoAuthorization
	);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const dispatch = useDispatch();

	const toggleRightMenu = (): void => {
		dispatch(resizableEntityActionCreators.setCollapsed(ResizableType.Live, liveResizable.isCollapsed));
		dispatch(resizableEntityActionCreators.setWidth(ResizableType.Live, liveResizable.width));
	};

	const syncLiveData = useCallback(async (): Promise<void> => {
		try {
			const fleetSelectionData: ITreeNode[] = await ajaxUtil.post<ITreeNode[]>(
				`${GlobalSettings.listsApi}/GetFleetSelectionData`,
				{
					customerId: customerId,
					objectFunction: featuresSettings.assetTracking
						? ObjectFunctionFilter.All
						: ObjectFunctionFilter.Full,
					showPersons: featuresSettings.driverIdentification,
					showObjects: true,
					showInactive: fleetSelectionState.showInactive,
					filterText: '',
				}
			);

			if (!fleetSelectionData.length || !liveEntitiesUnfiltered.length) {
				return;
			}

			const newLiveData: LiveDataResultDto[] = liveEntitiesUnfiltered.map((liveEntity: LiveDataResultDto) => {
				const foundTreeNode: ITreeNode = fleetSelectionData.find(
					(fleetData: ITreeNode) =>
						fleetData.childCount > 0 &&
						fleetData.items.some((item: ITreeNode) => item.id === liveEntity.entityId)
				);

				if (!foundTreeNode) {
					return liveEntity;
				}

				const foundItem: ITreeNode = foundTreeNode.items.find(
					(item: ITreeNode) => item.id === liveEntity.entityId
				);

				if (liveEntity.entityType === EntityTypeEnum.Person) {
					liveEntity.personName = foundItem.text;

					return liveEntity;
				}

				if (liveEntity.objectName !== foundItem.text) {
					liveEntity.objectNameWithTimeZoneOffset = extendObjectNameWithTimeZoneLongFormat(
						liveEntity.objectName,
						liveEntity.timeZoneMinutesOffset,
						customerTimezone !== liveEntity.timeZoneId
					);
				}

				liveEntity.objectName = foundItem.text;
				liveEntity.objectIcon = foundItem.icon;

				return liveEntity;
			});

			LiveDataDispatcher.setLiveUnfilteredEntities(dispatch, newLiveData);
		} catch (e) {
			console.error(e);
		}
	}, [
		featuresSettings.assetTracking,
		featuresSettings.driverIdentification,
		fleetSelectionState,
		liveEntitiesUnfiltered,
	]);

	const handleChangeOption = (value: number) => {
		if (selectedOption === value) {
			toggleRightMenu();

			return;
		}

		dispatch({
			type: SET_LIVE_OPTION,
			payload: value,
		});

		if (liveResizable.isCollapsed) {
			toggleRightMenu();

			return;
		}

		if (value === NumberConstants.rightSideBarOverviewOption && liveEntitiesUnfiltered.length) {
			syncLiveData();
		}
	};

	useEffect(() => {
		if (isDriverWithNoAuthorization && selectedOption === 0) {
			handleChangeOption(1);
		}

		syncLiveData();
	}, []);

	if (!props.visible) {
		return null;
	}

	return (
		<div className="right-sidebar-menu">
			<RightSideBarOptions
				selectedOption={selectedOption}
				changeOption={handleChangeOption}
				isHistory={false}
				isDriverWithNoAuthorization={isDriverWithNoAuthorization}
			/>
			{selectedOption === NumberConstants.rightSideBarFleetSelectionOption && <FleetSelectionArea isForLive />}
			{selectedOption === NumberConstants.rightSideBarOverviewOption && (
				<div className="fleet-area-container">
					<FleetOverviewFilter />
					<FleetOverviewContainer resizableExpanded={props.resizableExpanded} />
				</div>
			)}
		</div>
	);
};

export default LiveMenu;
