import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { SvgIcon } from 'components/IconPicker/SvgIcon';
import { noop } from 'Constants';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PersonIcon from '@material-ui/icons/Person';

import { TreeSelectionIconProps } from './types';

const TreeSelectionIcon = (props: TreeSelectionIconProps): JSX.Element => {
	if (props.type === EntityTypeEnum.Group) {
		return <CategoryOutlinedIcon className="tree-selection-icon" id="category-outlined-icon" />;
	}

	if (props.type === EntityTypeEnum.Driver) {
		return <PersonIcon className="tree-selection-icon" id="person-icon" />;
	}

	if (props.type === EntityTypeEnum.Object || props.type === EntityTypeEnum.Asset) {
		return <SvgIcon icon={props.iconContent} color="#212529" size={20} onClick={noop} />;
	}

	if (props.type === EntityTypeEnum.Person || props.type === EntityTypeEnum.User) {
		return <AccountCircleIcon className="tree-selection-icon" id="account-circle-icon" />;
	}

	if (props.type === EntityTypeEnum.ReportCategory) {
		return <FilterNoneIcon className="tree-selection-icon" id="filter-none-icon" />;
	}

	if (props.type === EntityTypeEnum.Report) {
		return <ReportIcon className={'tree-selection-icon'} id="report-icon" />;
	}

	return <></>;
};

export default React.memo(TreeSelectionIcon);
