import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import Group from 'models/Group';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay/CustomerNameDisplay';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import { WidgetViewDefault } from './Widget';

type Props = WidgetViewDefault & {
	data: Group;
	initialData: Group;
};

const GroupInformationViewValidationConfig = (config: { entityId: string }): FieldRules => {
	const fieldRules: FieldRules = {
		code: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(40),
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data, fieldName) => {
						const groupData = data as Group;
						if (!data[fieldName]) {
							return ValidatorFunctions.wrapInPromise(true);
						}

						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/GroupFields`, {
							customerId: groupData.customerId,
							value: data[fieldName],
							Field: 'code',
							editedEntityId: config.entityId,
						});
					},
				},
			},
		},
		name: {
			rules: {
				required: ValidatorFunctions.required(),
				maxLength: ValidatorFunctions.maxLength(200),
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
			},
		},
		description: {
			rules: {
				maxLength: ValidatorFunctions.maxLength(200),
			},
		},
	};

	return fieldRules;
};

const GroupInformationView = (props: Props) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (props.initialData) {
			if (props.setDashboardTitleCallback) {
				props.setDashboardTitleCallback(`${props.initialData.name} (${props.initialData.code})`);
			}
			dispatch(loadedEntityContextActionCreators.setLoadedGroupContext(props.initialData as Group));
		}
	}, [props.initialData]);

	useEffect(() => {
		props.setValidatorCallback(
			new Validator({ fieldRules: GroupInformationViewValidationConfig({ entityId: props.entityId }) })
		);
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: string | number | boolean, key: keyof Group) => {
			const newState = { ...props.data };
			(newState[key] as any) = value;
			props.changeDataCallback(newState);
		},
	};

	const [expandedSection, setExpandedSection] = useState<number>(0);

	return (
		<Accordion
			scrollToTop={props.scrollToTop}
			expandedCallback={(index) => {
				setExpandedSection(index);
			}}
		>
			<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.systemInformation')}>
				<div className="form-group">
					<TextField
						fullWidth
						id="code"
						type="text"
						className="resize-font"
						label={TranslateText('fields.code')}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
						name="code"
						value={props.data.code}
						onChange={(args) => {
							if (args.target) {
								events.onValueChanged(args.target.value, 'code');
							}
						}}
						disabled={!props.editMode}
					/>
					<ValidationMessage result={props.validationResult?.code} />
				</div>
				<CustomerNameDisplay customerId={props.data.customerId} />
			</AccordionSection>

			<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.groupData')}>
				<div className={'grouptInformations-form'}>
					<div className="form-group">
						<TextField
							id="name"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="name"
							value={props.data.name}
							onChange={(args) => {
								if (args.target) {
									events.onValueChanged(args.target.value, 'name');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.name} />
					</div>
					<div className="form-group">
						<TextField
							id="description"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.description')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="description"
							value={props.data.description ? props.data.description : ''}
							onChange={(args) => {
								if (args.target) {
									events.onValueChanged(args.target.value, 'description');
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.description} />
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'primary'}
									checked={props.data.active}
									readOnly={!props.editMode}
									onChange={(args: any) => {
										events.onValueChanged(args.target.checked, 'active');
									}}
									disabled={!props.editMode}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
						<ValidationMessage result={props.validationResult?.active} />
					</div>
				</div>
			</AccordionSection>
		</Accordion>
	);
};

export default GroupInformationView;
