import './deviceView.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import {
	ConflictConfirmation,
	ConnectionsConflictResult,
	Props as ConflictConfirmationProps,
} from 'components/ConnectionConflictsNotifier/ConflictConfirmation';
import ProtectedContainer from 'components/Layout/SideBar/ProtectedContainer';
import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import GlobalSettings from 'GlobalSettings.json';
import ConnectionTypeEnum from 'models/ConnectionTypeEnum';
import DevicePropertiesEnum from 'models/DevicePropertiesEnum';
import FilterFieldTypeEnum from 'models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from 'models/FilterOperatorTypeEnum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText, TranslateTextInterpolated } from 'utils/Translations';

type Props = AddWizardComponentProps & {
	id: string;
};

const DeviceView = (props: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [devicesData, setDevicesData] = useState([]);
	const [conflictConfirmationProps, setConflictConfirmationProps] = useState<ConflictConfirmationProps>({
		visible: false,
		confirmCallback: null,
		canSolveConflicts: true,
		conflictData: [],
		connectionType: ConnectionTypeEnum.DeviceObject,
	});
	const [deviceId, setDeviceId] = useState<string | null>(null);
	const [connectedSimIMSI, setConnectedSimIMSI] = useState<string>('');
	const [lastMessageReceived, setLastMessageReceived] = useState<Date | null>(null);
	const [lastMessageWithValidGPS, setLastMessageWithValidGPS] = useState<Date | null>(null);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const validator: Validator = new Validator({
		fieldRules: {
			deviceId: {
				rules: {
					anyConflicts: {
						message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
							TranslateText('connections.device'),
						]),
						validationFunction: (data, fieldName) => {
							return checkDeviceConnectionConflicts(data[fieldName]);
						},
					},
				},
			},
		},
	});

	const checkDeviceConnectionConflicts = async (deviceId: string): Promise<boolean> => {
		if (!deviceId) {
			return true;
		}

		const candidateConnection = {
			entity1Id: deviceId,
			entity2Id: props.id,
			connectionStartDate: new Date(),
			connectionType: ConnectionTypeEnum.DeviceObject,
		};

		setIsLoading(true);
		const result = await ajaxUtil.post<ConnectionsConflictResult>(
			`${GlobalSettings.connectionsApi}/ValidateEntitiesCandidateConnection`,
			candidateConnection
		);
		setIsLoading(false);

		if (result.conflictPresent) {
			return new Promise<boolean>((resolve) => {
				setConflictConfirmationProps({
					visible: true,
					canSolveConflicts: result.fixableConflict,
					connectionType: ConnectionTypeEnum.DeviceObject,
					conflictData: result.conflictingConnections,
					confirmCallback: (confirm: boolean) => {
						setConflictConfirmationProps({
							...conflictConfirmationProps,
							visible: false,
						});
						resolve(confirm);
					},
				});
			});
		} else {
			return true;
		}
	};

	const getConnectedDeviceMetadata = (deviceId: string) => {
		if (!deviceId) {
			setConnectedSimIMSI(null);
			setLastMessageReceived(null);
			setLastMessageWithValidGPS(null);

			return;
		}

		if (ClaimUtil.validateHasClaim(user, ClaimType.Devices)) {
			ajaxUtil
				.get(`${GlobalSettings.devicesMaintenanceApi}/${deviceId}/devicemeta`)
				.then((response: any) => {
					setConnectedSimIMSI(response.connectedSimImsi);
					setLastMessageReceived(
						response.lastMessageReceived && new Date(response.lastMessageReceived).getFullYear() !== 1
							? new Date(response.lastMessageReceived)
							: null
					);
					setLastMessageWithValidGPS(
						response.lastMessageWithValidGPS &&
							new Date(response.lastMessageWithValidGPS).getFullYear() !== 1
							? new Date(response.lastMessageWithValidGPS)
							: null
					);
				})
				.catch((error) => console.log(error));
		}
	};

	useEffect(() => {
		// if (ClaimUtil.validateClaim(this.props.user, { claim: ClaimType.Devices, values: [ClaimValue.edit] })) {
		const filter = {
			fieldType: FilterFieldTypeEnum.customerId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: defaultCustomerId,
			display: DevicePropertiesEnum.Code,
		};
		ajaxUtil.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, filter).then((data) => {
			setDevicesData(data);
		});
	}, []);

	useEffect(() => {
		if (!props.visible) return;

		getConnectedDeviceMetadata(deviceId);

		validator.validate({ deviceId: deviceId }).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			props.onChangeCallback({ deviceId: deviceId });
		});
	}, [deviceId]);

	return (
		<div className={'device-view-container'}>
			<div className={'column-title'}>{TranslateText('common.technicalData')}</div>
			<div className="form-group">
				<MaterialAutocomplete
					valueId={deviceId}
					dataSource={devicesData}
					name={'deviceId'}
					disabled={isLoading}
					onChange={({ value }) => {
						setDeviceId(value as string);
					}}
					label={TranslateText('fields.connectedDevice')}
					isForNewAddWizard={true}
					validationResult={validationResult?.deviceId}
				/>
			</div>
			<ProtectedContainer claim={ClaimType.SimCards}>
				<div className="form-group">
					<MaterialTextField
						id="connectedSIM"
						label={TranslateText('fields.connectedSim')}
						inputProps={{ readOnly: true, style: { fontSize: 10 } }}
						name="connectedSIM"
						value={connectedSimIMSI}
						disabled={true}
						isForNewAddWizard={true}
					/>
				</div>
			</ProtectedContainer>
			<ProtectedContainer claimConfig={[{ claim: ClaimType.Devices, values: [ClaimValue.edit] }]}>
				<div className="form-group">
					<MaterialDatePicker
						name="lastMessageReceived"
						date={lastMessageReceived}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
							DateTimeUtil.dateTimeFormat(),
							true
						)}
						label={TranslateText('fields.lastMessageReceived')}
						showTime={true}
						disabled={true}
						fullWidth={true}
						isForNewAddWizard={true}
					/>
				</div>
				<div className="form-group">
					<MaterialDatePicker
						name="lastMessageWithValidGPS"
						date={lastMessageWithValidGPS}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
							DateTimeUtil.dateTimeFormat(),
							true
						)}
						label={TranslateText('fields.lastMessageWithValidGPS')}
						showTime={true}
						disabled={true}
						fullWidth={true}
						isForNewAddWizard={true}
					/>
				</div>
			</ProtectedContainer>
			<ConflictConfirmation {...conflictConfirmationProps} />
		</div>
	);
};

export default DeviceView;
