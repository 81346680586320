import { RietveldNonTrip } from 'components/HistoryOverview/RietveldNonTrip';
import { RietveldTrip } from 'components/HistoryOverview/RietveldTrip';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { privateDataMask } from 'utils/TripUtils';

import { ApplicationState } from '../../store';
import { ProcessedTripDto } from '../../store/HistoryStore';
import { MapPosition } from './RietveldMap';

interface Props {
	tripConfig: ProcessedTripDto[];
	isLiveMap: boolean;
	map: google.maps.Map;
	flagsRef: React.MutableRefObject<{
		internalChange: boolean;
		mouseIn: boolean;
		hoverClusterInfo: boolean;
	}>;
	historyInfoWindowOffset: google.maps.Size | null;
	setMapPosition: (mapPosition: MapPosition) => void;
	setGoogleMapOptions: (opt: (prev: google.maps.MapOptions) => google.maps.MapOptions) => void;
}

const RietveldHistoryTrips = ({
	tripConfig,
	isLiveMap,
	map,
	flagsRef,
	historyInfoWindowOffset,
	setMapPosition,
	setGoogleMapOptions,
}: Props) => {
	const detailedTripsOnTheMapIds = useSelector(
		(state: ApplicationState) => state.historyStore.detailedTripsOnTheMapIds
	);
	const detailedVisitsOnTheMapIds = useSelector(
		(state: ApplicationState) => state.historyStore.detailedVisitsOnTheMapIds
	);

	const detailedPreviousVisitsOnTheMapIds = useSelector(
		(state: ApplicationState) => state.historyStore.detailedPreviousVisitsOnTheMapIds
	);

	const previousTrips = useSelector((s: ApplicationState) => s.historyStore.previousHistoryTrips);
	const nextTrips = useSelector((s: ApplicationState) => s.historyStore.nextHistoryTrips);

	const highlightedTripIds = useSelector((state: ApplicationState) => state.historyStore.highlightedTripIds);
	const hiddenTrips = useSelector((state: ApplicationState) => state.historyStore.hiddenHistoryTrips);

	const focusOnChange = useSelector((s: ApplicationState) => s.historyStore.focusOnChange);
	const tripPointChart = useSelector((s: ApplicationState) => s.historyStore.selectedPointHistoryTripChart);

	useEffect(() => {
		if (map && tripConfig.length && !isLiveMap && focusOnChange) {
			let hasLocationOnMap = false;
			const bounds = new google.maps.LatLngBounds();

			for (let i = 0; i < tripConfig.length; i++) {
				const item = tripConfig[i];
				if (
					!item.visible ||
					item.isParked ||
					(highlightedTripIds.length && highlightedTripIds.every((st) => st !== item.idWithParent)) ||
					item?.startAddress === privateDataMask ||
					item?.endAddress === privateDataMask
				) {
					continue;
				}
				hasLocationOnMap = true;

				if (item.path?.length) {
					item.path.forEach((p) => bounds.extend(p));
				} else if (item.isNonTrip) {
					bounds.extend(item.start);
				}
			}
			if (hasLocationOnMap) {
				flagsRef.current.internalChange = true;
				setMapPosition({ bounds: { mapBounds: bounds, offSet: true } });
			}
		}
	}, [highlightedTripIds]);

	useEffect(() => {
		if (!isLiveMap && map) {
			//the highlighted trips will be focused using focus points
			if (
				focusOnChange &&
				!highlightedTripIds.some((ht) =>
					tripConfig.some((t) => t.idWithParent === ht && t.visible && !t.isParked)
				)
			) {
				let hasLocationOnMap = false;
				const bounds = new google.maps.LatLngBounds();
				tripConfig.forEach((c) => {
					if (c.visible && !c.isParked && c.path?.length > 0) {
						hasLocationOnMap = true;
						c.path.forEach((p) => {
							bounds.extend(p);
						});
					}
				});
				if (hasLocationOnMap) {
					setMapPosition({ bounds: { mapBounds: bounds, offSet: true } });
				}
			}
		}
	}, [tripConfig, map]);

	useEffect(() => {
		if (tripPointChart !== null && map && tripConfig.length && !isLiveMap && focusOnChange) {
			const bounds = new google.maps.LatLngBounds();
			const tripPoint = new google.maps.LatLng(tripPointChart.latitude, tripPointChart.longitude);
			let hasLocationOnMap = false;
			for (let i = 0; i < tripConfig.length; i++) {
				const item = tripConfig[i];
				if(item.id === tripPointChart.tripId){
					if ((item.visible &&
						!item.isParked &&
						item?.startAddress !== privateDataMask &&
						item?.endAddress !== privateDataMask)
					) {
						hasLocationOnMap = true;
					}
				}
			}
			if(hasLocationOnMap) {
				bounds.extend(tripPoint);
				setMapPosition({bounds: {mapBounds: bounds, offSet: true}});
			}
		}
	},[tripPointChart, tripConfig])

	const setFlagsRefMouseIn = useCallback(
		(isInside: boolean) => {
			setGoogleMapOptions((prev) => {
				return { ...prev, gestureHandling: isInside ? 'none' : 'greedy' };
			});
		},
		[setGoogleMapOptions]
	);

	return (
		<>
			{tripConfig.map((config) => {
				return !config.visible || config.isParked ? null : config.isNonTrip ? (
					<RietveldNonTrip
						key={config.idWithParent}
						trip={config}
						showTripDetailsOnMap={detailedTripsOnTheMapIds.some(
							(historyTrip) => historyTrip === config.idWithParent
						)}
						highlight={highlightedTripIds.some((historyTrip) => historyTrip === config.idWithParent)}
						map={map}
						offset={historyInfoWindowOffset}
					/>
				) : (
					<RietveldTrip
						key={config.idWithParent}
						trip={config}
						previousTrip={
							config.prevTripId
								? tripConfig?.find(
										(trip) => trip.idWithParent === config.prevTripId + config.parentId
								  ) ??
								  hiddenTrips?.find((trip) => trip.idWithParent === config.prevTripId + config.parentId)
								: previousTrips?.find((trip) => trip.parentId === config.parentId)
						}
						nextTrip={
							!config.nextTripId
								? nextTrips?.find((trip) => trip.parentId === config.parentId)
								: tripConfig?.find(
										(trip) =>
											trip.idWithParent === config.nextTripId + config.parentId && !trip.isParked
								  ) ??
								  hiddenTrips?.find(
										(trip) =>
											trip.idWithParent === config.nextTripId + config.parentId && !trip.isParked
								  )
						}
						prevConnectedVisible={tripConfig.some(
							(t) =>
								t.idWithParent === config.prevConnectedTripId + config.parentId &&
								t.visible &&
								!t.isParked
						)}
						nextConnectedVisible={tripConfig.some(
							(t) =>
								t.idWithParent === config.nextConnectedTripId + config.parentId &&
								t.visible &&
								!t.isParked
						)}
						highlight={highlightedTripIds.some((historyTrip) => historyTrip === config.idWithParent)}
						showTripDetailsOnMap={detailedTripsOnTheMapIds.some(
							(historyTrip) => historyTrip === config.idWithParent
						)}
						showVisitDetailsOnMap={detailedVisitsOnTheMapIds.some(
							(historyTrip) => historyTrip === config.idWithParent
						)}
						showPreviousVisitDetailsOnMap={detailedPreviousVisitsOnTheMapIds.some(
							(s) => s.tripId === config.idWithParent
						)}
						offset={historyInfoWindowOffset}
						mouseInCallback={setFlagsRefMouseIn}
						map={map}
					/>
				);
			})}
		</>
	);
};

export default React.memo(RietveldHistoryTrips);
