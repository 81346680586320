import './personsView.scss'

import React, {useEffect, useRef, useState } from 'react';
import {useSelector } from 'react-redux';

import {TranslateText} from "../../../../utils/Translations";
import TreeSelectionView from "../../../TreeSelectionView";
import {EntityTypeFilter, TreeViewFilter} from "../../../TreeSelectionView/TreeSelectionView";
import ajaxUtil from "../../../../utils/Ajax";
import {ITreeNode} from "../../../SelectionTree/TreeNode/types";
import GlobalSettings from "../../../../GlobalSettings.json";
import PersonTypeFilterEnum from "../../../../models/PersonTypeFilterEnum";
import {AddWizardComponentProps} from "../../NewAddWizard";
import SelectionItem from "../../../../models/SelectionItem";
import EntityTypeEnum from "../../../../models/EntityTypeEnum";
import {ClaimUtil} from "../../../../authorization/ClaimUtil";
import ClaimType, {ClaimValue} from "../../../../authorization/ClaimType";
import {ApplicationState} from "../../../../store";
import FilterEntityType from "../../../../models/FilterEntityType";
import Validator from "../../../../shared/validation/Validator";
import {AlertsEntitiesData} from "../Alert/AlertView";



const PersonsView = (props: AddWizardComponentProps) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const [alertData, setAlertData] = useState(props.getDataCallback());
	const [selectedPersons, setSelectedPersons] = useState<Object[]>([]);
	const [valid, setValid] = useState(false);
	const [entityTypeFilters, setEntityTypeFilters] = useState([]);
	const personsStepRef = useRef<{ forceRefetchData: () => Promise<void> }>(null);
	const personsFilters= (): EntityTypeFilter[] => {
		return [
			{
				filterEntityType: FilterEntityType.All,
				translationText: 'fleetSelection.all',
			},
			{
				filterEntityType: FilterEntityType.Person,
				translationText: 'fleetSelection.persons',
			},
			{
				filterEntityType: FilterEntityType.Driver,
				translationText: 'fleetSelection.drivers',
			},
		];
	}

	const validator: Validator = new Validator({
		fieldRules: {
			selectedItems: {
				rules: {
					required: {
						message: '',
						validationFunction: async (data: any) => {
							return data !== null && data.length > 0;
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		setEntityTypeFilters(getReceiversFilter());
	},[]);

	useEffect(() => {
		if (!props.visible) return;
		if(selectedPersons === null || selectedPersons.length === 0)
			props.setValidationCallback(false);
	}, [props.visible]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await props.getDataCallback() as AlertsEntitiesData;
			if (data !== null && data.eventTypeId !== undefined) {
				setAlertData(data);
				if (personsStepRef.current) {
					personsStepRef.current.forceRefetchData();
				}
			}
		};
		fetchData();
	},[props.getDataCallback()?.hasAlertNotification,
		props.getDataCallback()?.hasSmsNotification,
		props.getDataCallback()?.hasEmailNotification,
		props.getDataCallback()?.hasWhatsAppNotification,
		props.getDataCallback()?.hasPopup]);

	useEffect(() => {
		props.setValidationCallback(valid);
	},[valid, selectedPersons]);

	useEffect(() => {
		validator.validate(selectedPersons).then((result) => {
			props.setValidationCallback(result.formResult && valid);
		});
	},[selectedPersons]);
	const prepareData = (data: ITreeNode[]) => {
		if (data) {
			for (let index = 0; index < data.length; index++) {
				if (data[index].id === null && data[index].text === 'All') {
					data[index].text = TranslateText('common.all');
					break;
				}
			}
		}
		return data;
	}
	const retrievePersonsData = (filter: TreeViewFilter) => {
		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.alertsApi}/getAlertRecipients`, {
				customerId: filter.customerId,
				showInactive: filter.showInactive,
				filterText: filter.filterText.trim(),
				personType:
					filter.showPersons && filter.showDrivers
						? PersonTypeFilterEnum.All
						: filter.showPersons
							? PersonTypeFilterEnum.NonDriver
							: PersonTypeFilterEnum.Driver,
				hasAlertNotification: alertData?.hasAlertNotification ?? true,
				hasEmailNotification: alertData?.hasEmailNotification ?? true,
				hasSmsNotification: alertData?.hasSmsNotification ?? true,
				hasWhatsAppNotification: alertData?.hasWhatsAppNotification ?? true,
			})
			.then((data) => prepareData(data));
	}

	const addPersons = (entities: ITreeNode[]) => {
		let newData = [] as SelectionItem[];
		entities.forEach((item) => {
			const person: SelectionItem = {
				entityId: item.id,
				entityType: item.type as EntityTypeEnum,
				id: item.id,
			};
			newData.push(person);
		});
		setSelectedPersons([...newData]);
		const prevAlertData = props.getDataCallback() as AlertsEntitiesData;
		if(props.visible) {
			props.onChangeCallback({
				trackedEntities: [...prevAlertData.trackedEntities],
				alertRecipients: [...newData]
			});
			props.setDataCallback({...prevAlertData, alertRecipients: [...newData]});
		}
	}
	const handlePersonsSelections = (entities: any[]) => {
		if (entities !== null) {
			addPersons(entities);
		}
	}

	const containsItem = (node: ITreeNode) => {
		const tempAlertData = props.getDataCallback() as AlertsEntitiesData;
		const trackedEntities: SelectionItem[] = tempAlertData.trackedEntities as SelectionItem[];
		for (let i = 0; i < trackedEntities.length; i++) {
			if (trackedEntities[i].entityId === node.id) {
				return true;
			}
		}
		return false;
	}

	const addDriversFromEntities = (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
    )  => {
		if (treeData) {
			let selectedEntities: ITreeNode[] = [];
			let selectedEntitiesIds: string[] = [];
			treeData.forEach((node) => {
				if (
					node.items.length === 0 &&
					containsItem(node) &&
					!selectedEntities.includes(node) &&
					!node.selected
				) {
					selectedEntities = [...selectedEntities, node];
					selectedEntitiesIds = [...selectedEntitiesIds, node.id];
				} else if (node.items.length > 0) {
					node.items.forEach((childNode) => {
						if (
							(containsItem(childNode) || containsItem(node)) &&
							!selectedEntitiesIds.includes(childNode.id) &&
							!childNode.selected
						) {
							selectedEntities = [...selectedEntities, childNode];
							selectedEntitiesIds = [...selectedEntitiesIds, childNode.id];
						}
					});
				}
			});

			setSelectedItems([...selectedItems, ...selectedEntities]);
		}
	}

	const getReceiversFilter = (): EntityTypeFilter[] => {
		if (
			ClaimUtil.validateClaim(user, {
				claim: ClaimType.Persons,
				values: [ClaimValue.view],
			})
		) {
			return personsFilters();
		}

		return [];
	}


	return (
		<div className={'persons-view-container'}>
			<TreeSelectionView
				ref={personsStepRef}
				buttonContainerVisible={true}
				retrieveDataCallback={retrievePersonsData}
				selectionChanged={handlePersonsSelections}
				enableClientFilter={false}
				filters={entityTypeFilters}
				filterPlaceholder={TranslateText('fleetSelection.searchPersonDriverPlaceholder')}
				hasSecondButton={true}
				secondButtonText={TranslateText('fleetSelection.addDriversFromEntities')}
				secondButtonCallback={addDriversFromEntities}
				hasValidations={true}
				validator={validator}
				setDataCallBack={(isValid: boolean) => {setValid(isValid)}}
				isForNewAddWizard={true}
			/>
		</div>
	);
}

export default PersonsView;
