import moment from 'moment-timezone';

import DateTimeUtil from '../shared/datetime/DateTimeUtil';
import { TranslateMonth, TranslateText } from './Translations';

// Use this function when you want that the choosen date to be stored exactly in the database
// and not the date relative to UTC offset
export function GetUTCNormalizedDate(currentDate?: Date): Date {
	if (currentDate === null) {
		return null;
	}

	let utcNormalizedDate: Date = null;
	// get the timezone offset
	const offset = currentDate.getTimezoneOffset();

	// add the retrieved timezoneOffset to the received date so, when, the serialization to UTC will be made the DATE part will be
	// the same as the one choosen by the user
	utcNormalizedDate = moment(currentDate)
		.add(offset * -1, 'm')
		.toDate();

	return utcNormalizedDate;
}

export function FormatDate(
	inputDate: Date | string,
	dateOnly = false,
	showSeconds = true,
	ignoreBrowserUtc = false
): string {
	const date = ignoreBrowserUtc ? moment.utc(inputDate) : moment(inputDate);

	if (dateOnly) {
		return date.format(DateTimeUtil.momentDateFormat().replace('EEEE', 'dddd'));
	}
	if (!showSeconds) {
		return date.format(DateTimeUtil.momentDateTimeFormatNoSeconds());
	}
	return date.format(DateTimeUtil.momentDateTimeFormat());
}

export function FormatDateWithTimezone(inputDate: Date, timezone: string, dateOnly = false): string {
	let format = DateTimeUtil.momentDateTimeFormat();
	if (dateOnly) {
		format = DateTimeUtil.momentDateFormat();
	}
	return moment(inputDate)
		.tz(timezone)
		.format(format);
}

export function GetTimezoneOffset(date: Date, timezone: string): number {
	const timezoneInfo = moment(date).tz(timezone);
	if (timezoneInfo && timezoneInfo.isValid()) {
		return timezoneInfo.utcOffset();
	}
	return 0;
}

export function GetMomentTimezone(): string {
	return moment.tz.guess();
}

export function GetHourAndMinutes(date: Date | string, ignoreBrowserUtc = false): string {
	const timeFormat = DateTimeUtil.timeFormat()
		.replace(':ss', '')
		.replace(':SS', '')
		.replace('a', 'A');

	if (ignoreBrowserUtc) {
		return moment.utc(date).format(timeFormat);
	}
	return moment(date).format(timeFormat);
}
export function GetHourMinutesAndSeconds(date: Date | string, ignoreBrowserUtc = false): string {
	let timeFormat = DateTimeUtil.timeFormat();
	timeFormat = timeFormat.replace('a', 'A');

	if (ignoreBrowserUtc) {
		return moment.utc(date).format(timeFormat);
	}
	return moment(date).format(timeFormat);
}
export function GetHourMinutesAndDays(date: string, ignoreBrowserUtc = false): string {
	const dateTimeFormat = DateTimeUtil.momentDateTimeFormat()
		.replace(':ss', '')
		.replace(':SS', '')
		.replace('a', 'A');

	if (ignoreBrowserUtc) {
		return moment.utc(date).format(dateTimeFormat);
	}
	return moment(date).format(dateTimeFormat);
}

export function FormatDateWithReferenceDate(date: string, refDate: string | Date, ignoreBrowserUtc = false): string {
	if (!date || !refDate) {
		return '';
	}

	const momentDate = ignoreBrowserUtc ? moment.utc(date) : moment(date);

	if (momentDate.isSame(refDate, 'day')) {
		return GetHourAndMinutes(date, ignoreBrowserUtc);
	}

	return GetHourMinutesAndDays(date, ignoreBrowserUtc);
}

export function DaysPassed(referenceDate: Date, currentDate: Date) {
	const difference = +currentDate - +referenceDate;
	return Math.floor(difference / (1000 * 60 * 60 * 24));
}

export function IsDifferentDay(referenceDate: Date, currentDate: Date) {
	const isDifferentDay = currentDate.getDay() !== referenceDate.getDay();
	const isDifferentMonth = currentDate.getMonth() !== referenceDate.getMonth();
	const isDifferentYear = currentDate.getFullYear() !== referenceDate.getFullYear();

	return isDifferentDay || isDifferentMonth || isDifferentYear;
}

export function isSameDay(
	localStartTime: string | Date,
	localEndTime?: string | Date,
	ignoreBrowserUtc = false,
	timeZoneOffset = 0
): boolean {
	const startDate = ignoreBrowserUtc ? moment.utc(localStartTime) : moment(localStartTime);
	let endDate = moment(localEndTime ? localEndTime : new Date());

	if (ignoreBrowserUtc) {
		endDate = localEndTime ? moment.utc(localEndTime) : moment.utc().utcOffset(timeZoneOffset);
	}

	return startDate.isSame(endDate, 'day');
}

export function GetTemplateHourTranslation(hours: number) {
	if (hours === 1) {
		return TranslateText('objectTemplateSummary.hour');
	}

	return TranslateText('objectTemplateSummary.hours');
}
