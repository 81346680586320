import './buttonWidget.scss';

import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionDialog from 'components/TreeSelectionDialog/TreeSelectionDialog';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@material-ui/core';

import GlobalSettings from '../../../GlobalSettings.json';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';
import FilterEntityType from '../../../models/FilterEntityType';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { IActionButton } from './ButtonWidget';

interface RoleInfo {
	active: boolean;
	claimWithValues: any;
	claims: any;
	code: string;
	customerId: string;
	customerName: string;
	customerType: number;
	description: string;
	display: string;
	generic: boolean;
	id: string;
	name: string;
	reportCategories: any;
	reportIds: any;
	systemRole: boolean;
	roleScope: CustomerLevelEnum;
}

const mapToTreeNode = (data: any[]): ITreeNode[] => {
	const treeNodeSelection: ITreeNode[] = [];
	const personGroupList = data as RoleInfo[];
	if (personGroupList) {
		personGroupList.forEach((item) => {
			treeNodeSelection.push({
				id: item.id,
				text: item.name,
				active: item.active,
				type: FilterEntityType[FilterEntityType.Role],
				selected: true,
				items: [],
				childCount: 0,
				canBeChecked: true,
			});
		});
	}
	return treeNodeSelection;
};

const AddRoleActionButton = (props: IActionButton) => {
	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setInitialTreeData(mapToTreeNode(props.currentSelection));
	}, [props.currentSelection]);

	const [dialogVisible, setDialogVisible] = useState(false);
	const events = {
		saveEntities: async (data: { id: string }[]) => {
			await ajaxUtil.post(
				`${GlobalSettings.personsMaintenanceApi}/${props.entityId}/updatePersonRoleConnections`,
				data.map((r) => r.id)
			);
			setDialogVisible(false);
			props.actionExecuted(true);
		},
		closeDialog: () => {
			setDialogVisible(false);
		},
		retrieveData: useCallback((filter: { customerId: string; filterText: string }) => {
			return ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.personsMaintenanceApi}/GetAvailableRolesForSelection`, {
				customerId: filter.customerId,
				filterText: filter.filterText,
			});
		}, []),
	};
	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionDialog
				title={TranslateText(`common.add${FilterEntityType[FilterEntityType.Role]}`)}
				filterPlaceholder={TranslateText('fleetSelection.searchRolePlaceholder')}
				visible={dialogVisible}
				displayInactive={false}
				initialSelection={initialTreeData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveEntities}
				retrieveData={events.retrieveData}
			/>
		</>
	);
};

export default AddRoleActionButton;
