import TreeSelectionDialog from 'components/TreeSelectionDialog';
import { SelectionFilter } from 'components/TreeSelectionDialog/TreeSelectionDialog';
import EntityData from 'models/EntityData';
import EntityTypeEnum from 'models/EntityTypeEnum';
import PersonTypeFilterEnum from 'models/PersonTypeFilterEnum';
import React, { useCallback, useEffect, useState } from 'react';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import ajaxUtil from 'utils/Ajax';
import { useGetPlaceholderAndButtons } from 'utils/ReceiversUtil/hooks';
import { TranslateText } from 'utils/Translations';

import * as GlobalSettings from '../../../GlobalSettings.json';
import { ITreeNode } from '../../SelectionTree/TreeNode/types';

interface Props {
	showDialog: boolean;
	selectedEntities: EntityData[];
	saveCallback: (data: EntityData[]) => void;
	closeDialogCallback: () => void;
}

const ReceiversSelectionArea = (props: Props) => {
	const { filterPlaceholder, filterButtons } = useGetPlaceholderAndButtons();

	const [localSelection, setLocalSelection] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setLocalSelection(
			props.selectedEntities.map((x) => {
				return {
					id: x.id,
					text: x.text,
					active: x.active,
					type: x.entityType as string,
					selected: true,
					childCount: 0,
					canBeChecked: true,
				};
			})
		);
	}, [props.selectedEntities]);

	const retrieveData = useCallback((filter: SelectionFilter) => {
		let selectedFilter = FleetEntityTypeFilter.all;
		if (filter.selectedFilter !== null && filter.selectedFilter !== undefined) {
			selectedFilter = filter.selectedFilter;
		}

		let personType = PersonTypeFilterEnum.All;
		if (selectedFilter === FleetEntityTypeFilter.driver) {
			personType = PersonTypeFilterEnum.Driver;
		} else if (selectedFilter === FleetEntityTypeFilter.person) {
			personType = PersonTypeFilterEnum.NonDriver;
		}

		return ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.reportingApi}/GetReportReceivers`, {
			customerId: filter.customerId,
			filterText: filter.filterText,
			personType,
			showInactive: filter.showInactive,
		});
	}, []);

	const saveSelected = useCallback(
		(treeSelection: ITreeNode[]) => {
			const data: EntityData[] = [];
			treeSelection.forEach((item) => {
				data.push({
					id: item.id,
					text: item.text,
					entityType: item.type as EntityTypeEnum,
					active: item.active,
					childCount: item.childCount,
					items: item.items?.map((i) => ({
						id: i.id,
						text: i.text,
						entityType: i.type as EntityTypeEnum,
						active: i.active,
						childCount: 0,
						items: [],
					})),
				});
			});
			props.saveCallback(data);
		},
		[props.saveCallback]
	);

	return (
		<TreeSelectionDialog
			visible={props.showDialog}
			initialSelection={localSelection}
			title={TranslateText('common.addReceivers')}
			filterPlaceholder={filterPlaceholder}
			displayInactive={true}
			filterButtons={filterButtons}
			retrieveData={retrieveData}
			closeCallback={props.closeDialogCallback}
			saveCallback={saveSelected}
		/>
	);
};

export default ReceiversSelectionArea;
