import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { WorkingHoursSchedule } from 'components/WorkingHoursSchedule';
import { noop } from 'Constants';
import MonitoringPeriodEnum from 'models/MonitoringPeriodEnum';
import { WorkingDayDisplay } from 'models/WorkingDay';
import React, { useState } from 'react';
import { TranslateText } from 'utils/Translations';

import { TimeScheduleViewProps } from './types';

const TimeScheduleView = (props: TimeScheduleViewProps) => {
	const [monitoringOptions] = useState<AutocompleteItem[]>(() => {
		return [
			{ id: MonitoringPeriodEnum.Inside, display: TranslateText('geofence.monitoringInPeriod') },
			{ id: MonitoringPeriodEnum.Outside, display: TranslateText('geofence.monitoringOutsidePeriod') },
		];
	});

	const updateTimeScheduleDays = (workingDay: WorkingDayDisplay) => {
		const index = props.data.timeSchedule.findIndex((x) => x.dayOfWeek === workingDay.dayOfWeek);
		props.data.timeSchedule[index] = { ...workingDay };
		const newFormData = {
			...props.data,
			timeSchedule: [...props.data.timeSchedule],
		};

		props.updateDataCallback(newFormData);
	};

	const updateMonitoring = (monitoringPeriod: MonitoringPeriodEnum) => {
		const newFormData = {
			...props.data,
			monitoringPeriod: monitoringPeriod,
		};

		props.updateDataCallback(newFormData);
	};

	return (
		<div className={`time-schedule-view ${props.className ?? ''}`}>
			<div className="schedule-column">
				<MaterialAutocomplete
					isForNewAddWizard={true}
					disabled={props.disabled}
					valueId={0}
					dataSource={[{ id: 0, display: TranslateText('hoursSchedule.customSchedule') }]}
					name="scheduleType"
					className="schedule-type"
					disableClearable
					label={TranslateText('hoursSchedule.chooseSchedule')}
					onChange={noop}
				/>
				<WorkingHoursSchedule
					disabled={props.disabled}
					hideWarning
					workingDays={props.data.timeSchedule}
					updateWorkingDay={updateTimeScheduleDays}
					validationResult={props.validationResult?.timeSchedule}
				/>
			</div>
			<div className="schedule-column">
				<MaterialAutocomplete
					isForNewAddWizard={true}
					disabled={props.disabled}
					valueId={props.data.monitoringPeriod}
					dataSource={monitoringOptions}
					name="monitoringPeriod"
					disableClearable
					label={TranslateText('geofence.monitoring')}
					onChange={(args: any) => updateMonitoring(args.value as MonitoringPeriodEnum)}
					validationResult={props.validationResult?.monitoringPeriod}
				/>
			</div>
		</div>
	);
};

export default TimeScheduleView;
