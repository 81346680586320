import { HistoryTimeRange } from 'components/HistoryOverview/HistoryTimeRange';
import { NumberConstants } from 'components/Widgets/CustomerFeaturesView';
import moment from 'moment';
import { Dispatch } from 'redux';
import { historyFilterActionCreators } from 'store/HistoryFilter';
import {
	SET_FOCUS_ON_CHANGE,
	SET_IS_MAP_ACTIVE,
	SET_TRIP_GROUPED_IDS,
	SET_TRIP_SELECTED_IDS,
	SHOW_HISTORY_TRIP_DETAILS,
} from 'store/HistoryStore';
import { resizableEntityActionCreators, ResizableType } from 'store/ResizableEntityState';
import { SET_HISTORY_MAP_OPTION } from 'store/RightSideBar';

export const setStoreForHistoryRedirect = (
	entityId: string,
	date: Date | string,
	hasMap: boolean,
	isMapActive: boolean,
	isHistoryMenuCollapsed: boolean,
	dispatch: Dispatch<any>
) => {
	dispatch(
		historyFilterActionCreators.setDateFilter({
			from: moment(date)
				.startOf('days')
				.toDate(),
			to: moment(date)
				.endOf('days')
				.toDate(),
			historyTimeRange: HistoryTimeRange.Day,
		})
	);
	if (hasMap && !isMapActive) {
		dispatch({
			type: SET_IS_MAP_ACTIVE,
			payload: isMapActive,
		});
	}
	if (isHistoryMenuCollapsed) {
		dispatch(
			resizableEntityActionCreators.setCollapsed(
				hasMap ? ResizableType.HistoryMap : ResizableType.HistoryGrid,
				isHistoryMenuCollapsed
			)
		);
	}
	if (hasMap) {
		dispatch({
			type: SET_HISTORY_MAP_OPTION,
			payload: NumberConstants.rightSideBarOverviewOption,
		});
	}

	//remove previous selected tabs from history details
	dispatch({
		type: SHOW_HISTORY_TRIP_DETAILS,
		payload: null,
	});
	//remove all merged tab groups
	dispatch({
		type: SET_TRIP_GROUPED_IDS,
		payload: [],
	});
	//select tab
	dispatch({
		type: SET_TRIP_SELECTED_IDS,
		payload: [entityId],
	});
	//focus the trips
	dispatch({
		type: SET_FOCUS_ON_CHANGE,
		payload: true,
	});
};

export const filterHistoryTrips = <T extends { parentId: string }>(
	historyTrips: T[],
	entities: { id: string; type: string }[]
): T[] => {
	return historyTrips.filter((x) => !entities.some((e: { id: string; type: string }) => e.id === x.parentId));
};
export const filterTemplates = <T extends { tripId: string}, H extends {id: string}>(
	historyTemplates: T[],
	historyTrips: H[]
): T[] => {
	return historyTemplates.filter((x) => historyTrips.some((t) => t.id === x.tripId));
}
