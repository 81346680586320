import DayOfWeekEnum from 'models/DayOfWeekEnum';
import { WorkingDay, WorkingDayDisplay } from 'models/WorkingDay';
import { TranslateText } from 'utils/Translations';

const defaultWorkingTime = {
	startHour: 6,
	startMinute: 0,
	endHour: 19,
	endMinute: 0,
};
const fullWorkingTime = {
	startHour: 0,
	startMinute: 0,
	endHour: 23,
	endMinute: 59,
};

export const getDefaultWorkingDays = (fullSchedule = false): WorkingDayDisplay[] => {
	const workingTime = fullSchedule ? fullWorkingTime : defaultWorkingTime;

	return [
		{
			dayOfWeek: DayOfWeekEnum.Sunday,
			checked: fullSchedule,
			label: TranslateText('fieldsDataSource.sunday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Monday,
			checked: true,
			label: TranslateText('fieldsDataSource.monday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Tuesday,
			checked: true,
			label: TranslateText('fieldsDataSource.tuesday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Wednesday,
			checked: true,
			label: TranslateText('fieldsDataSource.wednesday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Thursday,
			checked: true,
			label: TranslateText('fieldsDataSource.thursday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Friday,
			checked: true,
			label: TranslateText('fieldsDataSource.friday'),
			...workingTime,
		},
		{
			dayOfWeek: DayOfWeekEnum.Saturday,
			checked: fullSchedule,
			label: TranslateText('fieldsDataSource.saturday'),
			...workingTime,
		},
	];
};

export const validateWorkingDay = (workingDay: WorkingDay): boolean => {
	return (
		workingDay.startHour < workingDay.endHour ||
		(workingDay.startHour === workingDay.endHour && workingDay.startMinute < workingDay.endMinute)
	);
};

export const filterWorkingDays = (workingDay: WorkingDayDisplay[]): WorkingDay[] => {
	return workingDay
		.filter((x) => x.checked)
		.map((x) => {
			return {
				dayOfWeek: x.dayOfWeek,
				startHour: x.startHour,
				startMinute: x.startMinute,
				endHour: x.endHour,
				endMinute: x.endMinute,
			};
		});
};

export const mapSavedWorkingDays = (
	startingDay: number,
	defaultWorkingDay: WorkingDayDisplay[],
	savedWorkingDay?: WorkingDay[]
): WorkingDayDisplay[] => {
	let workingDays: WorkingDayDisplay[] = [];
	const lastWorkingDays: WorkingDayDisplay[] = [];
	defaultWorkingDay.forEach((day) => {
		//check if this day is saved, if not, use default day params(with checked false)
		let workingDay: WorkingDayDisplay | null = null;
		const storedWorkingDays = savedWorkingDay?.find((x) => x.dayOfWeek === day.dayOfWeek);
		if (storedWorkingDays) {
			workingDay = { ...day, ...storedWorkingDays, checked: true };
		} else {
			workingDay = { ...day, checked: false };
		}

		if (day.dayOfWeek < startingDay) {
			lastWorkingDays.push(workingDay);
		} else {
			workingDays.push(workingDay);
		}
	});
	workingDays = [...workingDays, ...lastWorkingDays];

	return workingDays;
};
