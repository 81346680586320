import './historyGroupingTable.scss';

import { HistoryTripRow } from 'components/HistoryTripRow';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ProcessedTripDto,
	SET_DETAILED_TRIPS_ON_MAP,
	SET_SELECTED_TRIPS_IN_OVERVIEW,
	SHOW_HISTORY_TRIP_DETAILS,
} from 'store/HistoryStore';

import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { SET_HISTORY_DETAILS_TABS_SELECTION } from '../../store/RightSideBar';
import SelectionHelper from '../../utils/SelectionHelper';
import { TranslateText } from '../../utils/Translations';
import { HistoryTripRowDetails } from '../HistoryTripRowDetails';
import { GroupedGridData, IGrouping, TableData, TotalResult } from './HistoryOverviewGrid';
import { HistoryTimeRange } from './HistoryTimeRange';

export interface DisplayData {
	text: string;
	colSpan: number;
}

const GroupingHeader = (props: {
	indent: number;
	displayData: DisplayData[];
	name: string;
	expanded: boolean;
	nameSpan: number;
	visible: boolean;
	onClick: () => void;
}) => {
	return (
		props.visible && (
			<TableRow
				className={'grouping-row'}
				onClick={() => {
					props.onClick();
				}}
			>
				<TableCell colSpan={props.nameSpan} className={`header-cell indent-${props.indent}`}>
					{props.name}
				</TableCell>
				{props.displayData.map((display: DisplayData, index: number) => {
					return (
						<TableCell key={index} colSpan={display.colSpan} className={'header-cell'}>
							{display.text}
						</TableCell>
					);
				})}
				<TableCell className={'grid-group-row-expand-btn'} colSpan={1}>
					{props.expanded ? <ExpandLess /> : <ExpandMore />}
				</TableCell>
			</TableRow>
		)
	);
};

interface TripGroupingKeys {
	monthKey: string;
	weekKey: string;
	dayKey: string;
	entityKey: string;
}

interface Props {
	data: GroupedGridData;
	groupingData: TableData<ProcessedTripDto, TotalResult>[];
	nameSpan: number;
	multipleEntitiesSelected: boolean;
	setData: (data: GroupedGridData) => void;
}

const getIndent = (flags: boolean[]): number => {
	return flags.reduce((sum, flag) => (flag ? sum + 1 : sum), 0);
};

const getDisplayData = <T1, T2>(data: IGrouping<any> | T1[], columnValues: TableData<T1, T2>[]): DisplayData[] => {
	return columnValues.map((v) => {
		const total = getTotals(data, v);
		return { text: v.formatText(total), colSpan: v.colSpan } as DisplayData;
	});
};

const getTotals = <T1, T2>(data: IGrouping<any> | T1[], value: TableData<T1, T2>): T2 => {
	let total;
	if (Array.isArray(data)) {
		total = value.GetTotalsFromTrips(data as T1[]);
	} else {
		const totals = Object.keys(data).map((key) => {
			return getTotals((data as IGrouping<any>)[key].data, value);
		});
		total = value.CalculateTotals(totals);
	}

	return total;
};

const HistoryGroupingTableBody = (props: Props) => {
	const selectedTimeRange = useSelector((state: ApplicationState) => state.historyFilter.dateFilter.historyTimeRange);
	const [tripHasUnsavedData, setTripHasUnsavedData] = useState(false);
	const tripUnsavedData = useSelector((s: ApplicationState) => s.historyStore.tripUnsavedData);
	const [notifyAboutUnsavedData, setNotifyAboutUnsavedData] = useState(false);
	const [promptCallback, setPromptCallback] = React.useState<() => void>(null);
	const [promptMessage, setPromptMessage] = React.useState<string>(TranslateText('notificationMessages.unsavedData'));
	const selectedTripIdWithParent = useSelector((s: ApplicationState) => s.historyStore.tripDetails?.idWithParent);
	const historyTrips = useSelector((s: ApplicationState) =>
		s.historyStore.historyGridTrips?.filter((historyTrip) => !historyTrip.isParked)
	);
	const [tripIdToIndexMap, setTripIdToIndexMap] = useState<Map<string, number>>(new Map<string, number>());
	const [tripIdToTripGroupingKeysMap, setTripIdToTripGroupingKeysMap] = useState<Map<string, TripGroupingKeys>>(
		new Map<string, TripGroupingKeys>()
	);
	const dataExpanded = useRef(false);
	let currentIndex = 0;

	const getTripIdFromIndex = (tripIndex: number) => {
		const iterator = tripIdToIndexMap[Symbol.iterator]();
		for (const [key, value] of iterator) {
			if (value === tripIndex) {
				return key;
			}
		}
	};

	const dispatch = useDispatch();

	const navigateToTrip = (trip: ProcessedTripDto) => {
		let dataWasExpanded = false;
		const newData = { ...props.data };

		const { monthKey, weekKey, dayKey, entityKey } = tripIdToTripGroupingKeysMap.get(trip.idWithParent);

		if (!newData[monthKey].expanded) {
			newData[monthKey].expanded = true;
			dataWasExpanded = true;
		}
		if (!newData[monthKey].data[weekKey].expanded) {
			newData[monthKey].data[weekKey].expanded = true;
			dataWasExpanded = true;
		}
		if (!newData[monthKey].data[weekKey].data[dayKey].expanded) {
			newData[monthKey].data[weekKey].data[dayKey].expanded = true;
			dataWasExpanded = true;
		}
		if (!newData[monthKey].data[weekKey].data[dayKey].data[entityKey].expanded) {
			newData[monthKey].data[weekKey].data[dayKey].data[entityKey].expanded = true;
			dataWasExpanded = true;
		}
		if (dataWasExpanded) {
			setExpandedData(newData);
		}

		if (selectedTripIdWithParent === trip.idWithParent) {
			dispatch({
				type: SHOW_HISTORY_TRIP_DETAILS,
				payload: null,
			});
			dispatch({
				type: SET_SELECTED_TRIPS_IN_OVERVIEW,
				payload: {
					selectedTripsInOverviewIds: [],
					selectedFromOverview: false,
				},
			});
			dispatch({
				type: SET_DETAILED_TRIPS_ON_MAP,
				payload: [],
			});
			return;
		}

		dispatch({
			type: SHOW_HISTORY_TRIP_DETAILS,
			payload: trip,
		});
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: SelectionHelper.createNewSelection([], trip.idWithParent, false),
				selectedFromOverview: false,
			},
		});
		dispatch({
			type: SET_DETAILED_TRIPS_ON_MAP,
			payload: [],
		});
	};

	const navigateToTripWithNotification = useCallback(
		(saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean, tripIndex: number) => {
			const tripId = getTripIdFromIndex(tripIndex);
			const trip = historyTrips.find((historyTrip) => historyTrip.idWithParent === tripId);

			if (notifyAboutUnsavedData) {
				if (saveTripDetailsCallback) {
					setPromptMessage(TranslateText('notificationMessages.unsavedDataForMileageCorrection'));
				} else {
					setPromptMessage(TranslateText('notificationMessages.unsavedData'));
				}
				setPromptCallback(() => async () => {
					if (saveTripDetailsCallback) {
						await saveTripDetailsCallback();
					}
					navigateToTrip(trip);
				});
				setNotifyAboutUnsavedData(true);
				return;
			}
			navigateToTrip(trip);
		},
		[historyTrips, selectedTripIdWithParent]
	);

	const navigateToPreviousTrip = useCallback(
		(saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean, tripId: string) => {
			const previousTripIndex = tripIdToIndexMap.get(tripId) + 1;
			navigateToTripWithNotification(saveTripDetailsCallback, notifyAboutUnsavedData, previousTripIndex);
		},
		[tripIdToIndexMap, selectedTripIdWithParent]
	);

	const navigateToNextTrip = useCallback(
		(saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean, tripId: string) => {
			const nextTripIndex = tripIdToIndexMap.get(tripId) - 1;
			navigateToTripWithNotification(saveTripDetailsCallback, notifyAboutUnsavedData, nextTripIndex);
		},
		[tripIdToIndexMap, selectedTripIdWithParent]
	);

	useEffect(() => {
		if (!tripUnsavedData) {
			setTripHasUnsavedData(false);
		}
	}, [tripUnsavedData]);

	useEffect(() => {
		if (!dataExpanded.current) {
			const newTripIdToIndexMap = new Map<string, number>();
			const newTripIdToTripGroupingKeysMap = new Map<string, TripGroupingKeys>();

			for (const monthKey in props.data) {
				const weekData = props.data[monthKey].data;
				for (const weekKey in weekData) {
					const dayData = weekData[weekKey].data;
					for (const dayKey in dayData) {
						const entityData = dayData[dayKey].data;
						for (const entityKey in entityData) {
							const trips = entityData[entityKey].data;
							for (let i = 0; i < trips.length; i++) {
								newTripIdToIndexMap.set(trips[i].idWithParent, currentIndex++);
								newTripIdToTripGroupingKeysMap.set(trips[i].idWithParent, {
									monthKey,
									weekKey,
									dayKey,
									entityKey,
								});
							}
						}
					}
				}
			}
			setTripIdToIndexMap(newTripIdToIndexMap);
			setTripIdToTripGroupingKeysMap(newTripIdToTripGroupingKeysMap);
		} else {
			dataExpanded.current = false;
		}
	}, [props.data]);

	const setExpandedData = useCallback(
		(data: GroupedGridData) => {
			props.setData(data);
			dataExpanded.current = true;
		},
		[props.data]
	);

	return (
		<>
			<TableBody className={'history-table'}>
				{Object.keys(props.data).map((monthKey) => {
					const monthVisible =
						Object.keys(props.data).length > 1 ||
						selectedTimeRange === HistoryTimeRange.Month ||
						selectedTimeRange === HistoryTimeRange.Custom;
					const monthExpanded = props.data[monthKey].expanded;
					return (
						<Fragment key={monthKey}>
							<GroupingHeader
								indent={getIndent([monthVisible])}
								visible={monthVisible}
								expanded={monthExpanded}
								onClick={() => {
									const newData = { ...props.data };
									newData[monthKey].expanded = !monthExpanded;
									setExpandedData(newData);
								}}
								displayData={getDisplayData(props.data[monthKey].data, props.groupingData)}
								nameSpan={props.nameSpan}
								name={monthKey}
							/>
							{monthExpanded &&
								Object.keys(props.data[monthKey].data).map((weekKey) => {
									const weekVisible =
										monthVisible || Object.keys(props.data[monthKey].data).length > 1;
									const weekExpanded = props.data[monthKey].data[weekKey].expanded;
									return (
										<Fragment key={weekKey}>
											<GroupingHeader
												indent={getIndent([monthVisible, weekVisible])}
												visible={weekVisible}
												expanded={weekExpanded}
												onClick={() => {
													const newData = { ...props.data };
													newData[monthKey].data[weekKey].expanded = !weekExpanded;
													setExpandedData(newData);
												}}
												displayData={getDisplayData(
													props.data[monthKey].data[weekKey].data,
													props.groupingData
												)}
												nameSpan={props.nameSpan}
												name={weekKey}
											/>
											{weekExpanded &&
												Object.keys(props.data[monthKey].data[weekKey].data).map((dayKey) => {
													const dayVisible =
														weekVisible ||
														Object.keys(props.data[monthKey].data[weekKey].data).length > 1;
													const dayExpanded =
														props.data[monthKey].data[weekKey].data[dayKey].expanded;
													return (
														<Fragment key={dayKey}>
															<GroupingHeader
																indent={getIndent([
																	monthVisible,
																	weekVisible,
																	dayVisible,
																])}
																visible={dayVisible}
																expanded={dayExpanded}
																onClick={() => {
																	const newData = { ...props.data };
																	newData[monthKey].data[weekKey].data[
																		dayKey
																	].expanded = !dayExpanded;
																	setExpandedData(newData);
																}}
																displayData={getDisplayData(
																	props.data[monthKey].data[weekKey].data[dayKey]
																		.data,
																	props.groupingData
																)}
																nameSpan={props.nameSpan}
																name={dayKey}
															/>
															{dayExpanded &&
																Object.keys(
																	props.data[monthKey].data[weekKey].data[dayKey].data
																).map((entityKey) => {
																	const entityExpanded =
																		props.data[monthKey].data[weekKey].data[dayKey]
																			.data[entityKey].expanded;
																	return (
																		<Fragment key={entityKey}>
																			<GroupingHeader
																				indent={getIndent([
																					monthVisible,
																					weekVisible,
																					dayVisible,
																					props.multipleEntitiesSelected,
																				])}
																				visible={props.multipleEntitiesSelected}
																				expanded={entityExpanded}
																				onClick={() => {
																					const newData = {
																						...props.data,
																					};
																					newData[monthKey].data[
																						weekKey
																					].data[dayKey].data[
																						entityKey
																					].expanded = !entityExpanded;
																					setExpandedData(newData);
																				}}
																				displayData={getDisplayData(
																					props.data[monthKey].data[weekKey]
																						.data[dayKey].data[entityKey]
																						.data,
																					props.groupingData
																				)}
																				nameSpan={props.nameSpan}
																				name={entityKey}
																			/>
																			{entityExpanded &&
																				props.data[monthKey].data[weekKey].data[
																					dayKey
																				].data[entityKey].data.map((trip) => {
																					return (
																						<Fragment
																							key={
																								'trip-details-' +
																								trip.idWithParent
																							}
																						>
																							<HistoryTripRow
																								id={`trip-${trip.idWithParent}`}
																								trip={trip}
																								onDoubleClick={() => {
																									if (
																										tripHasUnsavedData ||
																										tripUnsavedData
																									) {
																										setPromptMessage(
																											TranslateText(
																												'notificationMessages.unsavedData'
																											)
																										);
																										setPromptCallback(
																											() => () => {
																												navigateToTrip(
																													trip
																												);
																											}
																										);
																										setNotifyAboutUnsavedData(
																											true
																										);
																										return;
																									}

																									dispatch({
																										type: SET_HISTORY_DETAILS_TABS_SELECTION,
																										payload: 1,
																									});
																									navigateToTrip(
																										trip
																									);
																								}}
																							/>
																							{trip.idWithParent ===
																								selectedTripIdWithParent && (
																								<HistoryTripRowDetails
																									trip={trip}
																									tripHistoryIndex={tripIdToIndexMap.get(
																										trip.idWithParent
																									)}
																									historyTripsLength={
																										historyTrips.length
																									}
																									navigateToPreviousTrip={
																										navigateToPreviousTrip
																									}
																									navigateToNextTrip={
																										navigateToNextTrip
																									}
																									setTripHasUnsavedData={
																										setTripHasUnsavedData
																									}
																								/>
																							)}
																						</Fragment>
																					);
																				})}
																		</Fragment>
																	);
																})}
														</Fragment>
													);
												})}
										</Fragment>
									);
								})}
						</Fragment>
					);
				})}
			</TableBody>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={promptMessage}
				handleUserResponse={(response) => {
					setNotifyAboutUnsavedData(false);
					if (response) {
						promptCallback();
						setPromptCallback(null);
						setTripHasUnsavedData(false);
					}
				}}
				displayDialog={notifyAboutUnsavedData}
			/>
		</>
	);
};

export default HistoryGroupingTableBody;
