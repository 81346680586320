import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { MaterialTimePickerProps } from './types';

const getDateTime = (hour: number, minute: number): Date => {
	const tempDate = new Date();
	return new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay(), hour ?? 0, minute ?? 0);
};

const MaterialTimePicker = (props: MaterialTimePickerProps): JSX.Element => {
	const [selectedDate, setSelectedDate] = useState(() => getDateTime(props.hour, props.minute));

	const firstRender = useRef(false);
	useEffect(() => {
		if (firstRender.current) {
			setSelectedDate(getDateTime(props.hour, props.minute));
		} else {
			firstRender.current = true;
		}
	}, [props.hour, props.minute]);

	const [resetOnBlur, setResetOnBlur] = useState(false);

	return (
		<div>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardTimePicker
					size="small"
					className={props.className}
					value={selectedDate}
					format={props.format}
					ampm={!props.format.includes('H')}
					disabled={props.disabled}
					onChange={(e) => {
						if (e && e.isValid()) {
							const date = e.toDate();
							setSelectedDate(date);
							setResetOnBlur(false);
							props.onChange(date.getHours(), date.getMinutes());
						} else {
							setResetOnBlur(true);
						}
					}}
					onBlur={() => {
						if (resetOnBlur) {
							setSelectedDate(moment(selectedDate).toDate());
							setResetOnBlur(false);
						}
					}}
					KeyboardButtonProps={{
						size: 'small',
					}}
				/>
			</MuiPickersUtilsProvider>
		</div>
	);
};

export default MaterialTimePicker;
