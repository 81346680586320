import AlertTypesEnum from 'models/AlertTypesEnum';
import DigitalAlertMetadata from 'models/DigitalAlertMetadata';
import { useEffect, useMemo, useState } from 'react';
import { ValidateResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';

const useDigitalAlerts = (alertType: AlertTypesEnum, digitalAlertMetadata?: DigitalAlertMetadata) => {
	const [validationResult, setValidationResult] = useState<ValidateResult>();
	const validator = useMemo(
		() =>
			alertType === AlertTypesEnum.Digital
				? new Validator(
						{
							fieldRules: {
								templateDigitalId: {
									rules: {
										required: ValidatorFunctions.required(),
									},
								},
								triggerCondition: {
									rules: {
										required: ValidatorFunctions.required(),
									},
								},
							},
						},
						true
				  )
				: new Validator({ fieldRules: {} }),
		[alertType]
	);

	useEffect(() => {
		if (alertType === AlertTypesEnum.Digital && digitalAlertMetadata) {
			validator.validate(digitalAlertMetadata).then((result) => {
				setValidationResult(result);
			});
		}
	}, [digitalAlertMetadata]);

	return validationResult;
};

export { useDigitalAlerts };
