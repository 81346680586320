import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

import { enableRipple } from '@syncfusion/ej2-base';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import Key from '../../models/Key';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import KeyInformationWidget from '../Widgets/KeyInformationWidget';
import KeyPersonWidget from '../Widgets/KeyPersonWidget';

enableRipple(true);

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type Props = WidgetDashboardTransferableProps & MatchProps;
const pathToOverview = '/keys';
let connectionConflictsNotifierRef = null as ConnectionConflictsNotifier;

const KeyDetails = (props: Props) => {
	const access_token = useSelector((state: ApplicationState) => state.oidc.user.access_token);
	const currentSession = useSelector((state: ApplicationState) => state.currentSession);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer.featuresSettings.driverIdentification
	);
	const dispatch = useDispatch();
	const [reloadCounter, setReloadCounter] = useState(0);
	const [currentTitle, setCurrentTitle] = useState<string>('');

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedKeyContext(null));
		};
	}, []);

	const events = {
		handleConflictingConnections: (sender: ConnectionsConflictResolver, confirm: boolean) => {
			sender.handleConflictingConnections(confirm);
		},
		handleDataSaved: () => {
			setReloadCounter(reloadCounter + 1);
		},
		handleDisplayCallback: (key: Key) => {
			setCurrentTitle(key.code);
			loadedEntityContextActionCreators.dispatchLoadedKeyContext(dispatch, key);
		},
	};

	return (
		<>
			<ConnectionConflictsNotifier
				ref={(scope) => {
					connectionConflictsNotifierRef = scope;
				}}
				connectionType={ConnectionTypeEnum.PersonKey}
				visible={false}
				userAnswearCallback={events.handleConflictingConnections}
			/>
			<WidgetDashboard
				history={props.history}
				breadcrumbText={currentTitle ? `${TranslateText('common.key')} > ${currentTitle}` : ''}
				goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.Key}s`)}
				currentRecordId={props.match.params.id}
				urlPath={pathToOverview}
				filterText={props.filterText}
				goBackToCallback={() => {
					props.history.push('/keys', { id: props.match.params.id });
				}}
				entityType={EntityTypeEnum.Key}
			>
				<KeyInformationWidget
					id="keyInformation"
					row="0"
					col="0"
					sizeX="4"
					sizeY="6"
					entityId={props.match.params.id}
					customerId={currentSession.customerId}
					displayCallback={events.handleDisplayCallback}
					getAccessTokenCallback={() => access_token}
					forceReload={reloadCounter}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.Keys,
						values: [ClaimValue.edit],
					})}
				/>
				<ProtectedContainer
					optionalClaimList={[ClaimType.Persons, ClaimType.Drivers]}
					claimConfig={[
						{ claim: ClaimType.Keys, values: [ClaimValue.view] },
						{ claim: ClaimType.KeyPersonConnection, values: [ClaimValue.edit] },
					]}
				>
					{driverIdentification && (
						<KeyPersonWidget
							row="0"
							col="4"
							sizeX="14"
							sizeY="3"
							id={'personHistory'}
							entityId={props.match.params.id}
							entityType={EntityTypeEnum.Key}
							conflictingConnectionNotifier={connectionConflictsNotifierRef}
							getAccessTokenCallback={() => access_token}
							user={user}
							loggedCustomerLevel={currentSession.customerLevel}
							savedCallback={events.handleDataSaved}
							showEditButton={
								ClaimUtil.validateClaimList(user, [
									{
										claim: ClaimType.Keys,
										values: [ClaimValue.edit],
									},
									{
										claim: ClaimType.KeyPersonConnection,
										values: [ClaimValue.edit],
									},
								]) &&
								ClaimUtil.validateOptionalClaimList(user, [
									{
										claim: ClaimType.Persons,
										values: [ClaimValue.edit],
									},
									{
										claim: ClaimType.Drivers,
										values: [ClaimValue.edit],
									},
								])
							}
						/>
					)}
				</ProtectedContainer>
			</WidgetDashboard>
		</>
	);
};

export default KeyDetails;
