import EntityTypeEnum from './EntityTypeEnum';
import IdIdentifiable from './IdIdentifiable';

class EntityContextData extends IdIdentifiable {
	public customerId: string;
	public EntityType?: EntityTypeEnum;
	public isActive: boolean;
	public displayText: string;
	public entityDetails: any;
}

export default EntityContextData;
