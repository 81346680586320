import './plainOneFieldDialog.scss';

import AutoTextField from 'components/AutoTextfield/AutoTextField';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';
import { TranslateText } from 'utils/Translations';

import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../Constants';
import { DebouncedButton } from '../../Common/DebouncedButton';

export interface PlainEntity {
	name: string;
	id: string;
}

interface Props {
	categoryNames?: string[];
	visible: boolean;
	setVisible: (visible: boolean) => void;
	dialogTitle: string;
	headerRowName: string;
	entity?: PlainEntity;
	removeCallBack?: (id: string) => void;
	saveCallBack?: (entity: PlainEntity) => void;
}

const PlainOneFieldDialog = (props: Props) => {
	const dispatch = useDispatch();

	const [entity, setEntity] = useState<PlainEntity>(
		props.entity ? props.entity : ({ id: '', name: '' } as PlainEntity)
	);
	const [validationResult, setValidationResult] = useState<ValidationResult>(null);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
	const validator = new Validator({
		fieldRules: {
			name: {
				rules: {
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: () => {
							const result = props.categoryNames.some(
								(c) => c.toLowerCase() === entity.name.toLowerCase()
							);
							return ValidatorFunctions.wrapInPromise(!result);
						},
					},
					maxLength: ValidatorFunctions.maxLength(200),
				},
			},
		},
	});

	useEffect(() => {
		return () => {
			dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
		};
	}, []);

	useEffect(() => {
		if ((props.entity && props.entity?.name !== entity.name) || !!!props.entity) {
			validator
				.validate({
					name: entity.name,
				})
				.then((result) => {
					setValidationResult(result.validationResult);
					setSaveButtonDisabled(!result.formResult);
				});
		}

		dispatch(
			unsavedDataStoreActionCreators.setUnsavedData(
				(entity.name && !props.entity) || (props.entity && props.entity.name !== entity.name)
			)
		);
	}, [entity]);

	return (
		<Dialog className={'plain-field-dialog'} open={props.visible} fullWidth>
			<div className="dialog-min-height">
				<DialogTitle>{props.dialogTitle}</DialogTitle>
				<DialogContent className="dialog-content">
					<div className={'create-edit-dialog-row'}>
						<div className={'create-edit-dialog-row title '}>
							<div>{props.headerRowName}</div>
						</div>
						<div className={'create-edit-dialog-row value'}>
							<AutoTextField
								value={entity.name}
								onChange={(e) => {
									setEntity({ name: e, id: entity.id });
								}}
							/>
							<ValidationMessage result={validationResult?.name} />
						</div>
					</div>
				</DialogContent>
				<div className="buttons-row">
					<div className="cancel-button">
						<Button
							className="auto-cancel-button"
							onClick={() => {
								props.setVisible(false);
								setEntity({ id: '', name: '' } as PlainEntity);
							}}
						>
							{TranslateText('common.buttonCancel')}
						</Button>
					</div>
					<div className="right-side-buttons">
						{props.entity && (
							<Button
								className="remove-button"
								onClick={() => {
									props.removeCallBack(entity.id);
									props.setVisible(false);
									setEntity({ id: '', name: '' } as PlainEntity);
								}}
							>
								{TranslateText('common.buttonRemove')}
							</Button>
						)}
						<DebouncedButton
							disabled={saveButtonDisabled || !entity?.name?.trim().length}
							className="save-button"
							onClick={() => {
								props.saveCallBack({ ...entity, name: entity.name.trim() });
								props.setVisible(false);
								setEntity({ id: '', name: '' } as PlainEntity);
							}}
							debounceTime={DEBOUNCE_TIME}
						>
							{TranslateText('common.buttonSave')}
						</DebouncedButton>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

PlainOneFieldDialog.defaultProps = {
	value: null,
};

export default PlainOneFieldDialog;
