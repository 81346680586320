import EntityTypeEnum from 'models/EntityTypeEnum';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { enableRipple } from '@syncfusion/ej2-base';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import * as GlobalSettings from '../../GlobalSettings.json';
import IdIdentifiable from '../../models/IdIdentifiable';
import Template from '../../models/Template';
import TemplateUnitOfMeasureEnum from '../../models/TemplateUnitOfMeasureEnum';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import BaseDetails from '../BaseDetails';
import { BaseDetailsProps, BaseDetailsState } from '../BaseDetails/BaseDetails';
import DetailsNavigationBar from '../DetailsNavigationBar/DetailsNavigationBar';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import TemplateDefinitionWidget from '../Widgets/TemplateDefinitionWidget';
import TemplateInformationWidget from '../Widgets/TemplateInformationWidget';
import TemplateStateDefinitionView from '../Widgets/Views/TemplateStateDefinitionView';
import Widget, { CustomModel } from '../Widgets/Widget';

enableRipple(true);

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		editingInstance: state.loadedEntityContext.editingInstance,
		customerId: state.currentSession.customerId,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterText: state.globalCustomer.filterText.templates,
		gridFilters: state.gridOverview[EntityTypeEnum.Template].gridFilters,
		user: state.oidc.user,
		tripTypes: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: state.currentSession.customer.featuresSettings.tripTypes,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
		availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		setUnsavedDataInStore: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

type MatchProps = {
	match: { params: { [key: string]: string } };
};

const connector = connect(mapState, mapDispatchToProps);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type TemplateDetailsProps = BaseDetailsProps & PropsFromRedux & MatchProps & {};
type TemplateDetailsState = BaseDetailsState & {
	currentTemplateType: TemplateTypesEnum;
	currentMaxValueTemplate?: number;
	currentUnitValueTemplate?: TemplateUnitOfMeasureEnum;
	numberOfStatesChanged?: boolean;
};

class TemplateDetails extends BaseDetails<TemplateDetailsProps, TemplateDetailsState> {
	constructor(props: TemplateDetailsProps) {
		super(props);

		this.state = {
			...this.state,
			currentRecordId: props.match.params.id,
			currentTemplateType: props.match.params.templateType as TemplateTypesEnum,
			currentMaxValueTemplate: null,
			numberOfStatesChanged: false,
		};
	}

	templateInformationWidgetRef: TemplateInformationWidget;

	componentDidMount() {
		this.props.availableCustomersActions.toggleSelection(false);
	}

	componentWillUnmount() {
		this.loadedWidgets = [];
		this.props.loadedEntityContextActions.setEditingInstance(null);
		this.props.loadedEntityContextActions.setLoadedTemplateContext(null);
	}

	displayCallback(template: Template) {
		this.setState({
			breadcrumbDisplay: TranslateText('common.templates') + ' > ' + template.name,
		});
		this.props.loadedEntityContextActions.setLoadedTemplateContext(template);
	}

	protected fillInformationWidget(recordId: string) {
		this.templateInformationWidgetRef.fillTemplate(recordId);
	}

	protected closeEditModeOnInformationWidget() {
		this.templateInformationWidgetRef.closeEditPanelMode();
	}

	protected handleGoBackTo(show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) {
		this.loadedWidgets = [];
		this.props.history.push('/templates', { id: syncRecord.id });
	}

	public setValueForReloadData({
		maxValue = null,
		unitValue = TemplateUnitOfMeasureEnum.Unknown,
		hasChanged = false,
	}: { maxValue?: number; unitValue?: TemplateUnitOfMeasureEnum; hasChanged?: boolean } = {}) {
		if (maxValue !== null) this.setState({ currentMaxValueTemplate: maxValue as number });
		if (unitValue !== null) this.setState({ currentUnitValueTemplate: unitValue as TemplateUnitOfMeasureEnum });
		if (hasChanged !== null) this.setState({ numberOfStatesChanged: hasChanged as boolean });
	}

	render() {
		return (
			<div className="content-container">
				<NotificationPrompt
					title={TranslateText('common.titleUnsavedData')}
					message={TranslateText('notificationMessages.cancel')}
					handleUserResponse={this.handleUnsavedDataResponse.bind(this)}
					displayDialog={this.state.notifyAboutUnsavedData}
				/>
				<DetailsNavigationBar
					history={this.props.history}
					currentRecordId={this.props.match.params.id}
					goBackText={TranslateText('detailsScreen.navigationBar.backToTemplates')}
					goBackToCallback={this.handleGoBackTo.bind(this)}
					breadcrumbsText={this.state.breadcrumbDisplay}
					navigationUrl={`${GlobalSettings.sensorTemplatesMaintenanceApi}/navigation`}
					detailUrl={GlobalSettings.route.templates}
					filter={{
						customerId: this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId,
						filterText: this.props.filterText,
						gridFilters: this.props.gridFilters,
					}}
					entityType={EntityTypeEnum.Template}
				/>
				<Scrollbar
					style={{
						maxHeight: 'calc(100vh - 110px)',
						minHeight: 'calc(100vh - 110px)',
					}}
					native={true}
				>
					<DashboardLayoutComponent
						immediateRender={true}
						draggableHandle=".dragicon"
						mediaQuery={'max-width: 1100px'}
						allowResizing={true}
						allowFloating={true}
						allowPushing={true}
						columns={20}
						cellAspectRatio={95 / 100}
						ref={(scope) => {
							this.dashboardObj = scope;
						}}
						id="predefine_dashboard"
						cellSpacing={this.cellSpacing}
					>
						<ProtectedContainer claim={ClaimType.Templates}>
							<TemplateInformationWidget
								id="templateInformation"
								row="0"
								col="0"
								sizeX="4"
								sizeY="5"
								entityId={this.state.currentRecordId}
								editingInstance={this.props.editingInstance}
								setEditingInstance={this.props.loadedEntityContextActions.setEditingInstance}
								savedCallback={this.handleSavedCallback.bind(this)}
								notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
								displayCallback={this.displayCallback.bind(this)}
								getAccessTokenCallback={() => this.props.access_token}
								ref={(scope) => {
									this.templateInformationWidgetRef = scope;
									this.AddLoadedWidget(scope);
								}}
								changeDataCallback={this.changeDataCallback.bind(this)}
								allowEditMode={!this.state.widgetEditing}
								editModeCallback={this.editModeCallback.bind(this)}
								showEditButton={ClaimUtil.validateClaim(this.props.user, {
									claim: ClaimType.Templates,
									values: [ClaimValue.edit],
								})}
								customerId={
									this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId
								}
								templateType={this.state.currentTemplateType}
							/>
						</ProtectedContainer>
						<ProtectedContainer claim={ClaimType.Templates}>
							<TemplateDefinitionWidget
								id="templateDefinition"
								row="0"
								col="4"
								sizeX="4"
								sizeY="5"
								entityId={this.state.currentRecordId}
								editingInstance={this.props.editingInstance}
								setEditingInstance={this.props.loadedEntityContextActions.setEditingInstance}
								savedCallback={this.handleSavedCallback.bind(this)}
								notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
								getAccessTokenCallback={() => this.props.access_token}
								ref={(scope) => {
									this.AddLoadedWidget(scope);
								}}
								changeDataCallback={this.changeDataCallback.bind(this)}
								allowEditMode={!this.state.widgetEditing}
								editModeCallback={this.editModeCallback.bind(this)}
								showEditButton={ClaimUtil.validateClaim(this.props.user, {
									claim: ClaimType.Templates,
									values: [ClaimValue.edit],
								})}
								templateType={this.state.currentTemplateType}
								tripTypes={this.props.tripTypes}
								setDataCallback={this.setValueForReloadData.bind(this)}
							/>
						</ProtectedContainer>
						{this.state.currentTemplateType === TemplateTypesEnum.Analog && (
							<ProtectedContainer claim={ClaimType.Templates}>
								<Widget
									position={{
										row: 0,
										col: 9,
										sizeX: 5,
										sizeY: 5,
									}}
									allowEditMode={!this.state.widgetEditing}
									editModeCallback={this.editModeCallback.bind(this)}
									entityId={this.state.currentRecordId}
									url={`${GlobalSettings.sensorTemplatesMaintenanceApi}/stateDefinition`}
									widgetTitle={TranslateText('common.templateStateDefinition')}
									viewComponent={TemplateStateDefinitionView}
									changeDataCallback={this.changeDataCallback.bind(this)}
									showEditButton={ClaimUtil.validateClaim(this.props.user, {
										claim: ClaimType.Templates,
										values: [ClaimValue.edit],
									})}
									getDataCallBack={() => ({
										maxValue: this.state.currentMaxValueTemplate,
										unitValue: this.state.currentUnitValueTemplate,
										hasChanged: this.state.numberOfStatesChanged,
									})}
									setDataCallback={this.setValueForReloadData.bind(this)}
									getDataModelCallback={() =>
										({ name: 'templateAnalogStates', typeArray: true } as CustomModel)
									}
								/>
							</ProtectedContainer>
						)}
					</DashboardLayoutComponent>
				</Scrollbar>
			</div>
		);
	}
}

export default connector(TemplateDetails);
