import './groupView.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';

import ClaimType, { ClaimValue } from '../../../../authorization/ClaimType';
import { ClaimUtil } from '../../../../authorization/ClaimUtil';
import GlobalSettings from '../../../../GlobalSettings.json';
import EntityTypeEnum from '../../../../models/EntityTypeEnum';
import Validator from '../../../../shared/validation/Validator';
import { ApplicationState } from '../../../../store';
import ajaxUtil from '../../../../utils/Ajax';
import { TranslateText } from '../../../../utils/Translations';
import { ITreeNode } from '../../../SelectionTree/TreeNode/types';
import TreeSelectionView from '../../../TreeSelectionView';
import { TreeViewFilter } from '../../../TreeSelectionView/TreeSelectionView';
import { AddWizardComponentProps } from '../../NewAddWizard';

const GroupView = (props: AddWizardComponentProps) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const [selectedGroups, setSelectedGroups] = useState<ITreeNode[]>([]);

	const validator: Validator = new Validator({
		fieldRules: {
			selectedItems: {
				rules: {
					warningEmpty: {
						message: TranslateText('fieldsValidations.addInGroupsWarning'),
						isWarning: true,
						validationFunction: async (data, fieldName) => {
							if (props.wizardEntityType === EntityTypeEnum.Integration) {
								return ValidatorFunctions.wrapInPromise(true);
							}

							return data.length > 0;
						},
					},
				},
			},
		},
	});

	const retrieveGroupsData = (filter: TreeViewFilter) => {
		if (ClaimUtil.validateClaim(user, { claim: ClaimType.Groups, values: [ClaimValue.edit] })) {
			return ajaxUtil
				.post<ITreeNode[]>(`${GlobalSettings.groupsMaintenanceApi}/GetPersonGroupSelectionData`, {
					customerId: filter.customerId,
					showPersons: filter.showPersons,
					showObjects: filter.showObjects,
					showInactive: filter.showInactive,
					filterText: filter.filterText.trim(),
				})
				.then((data) => prepareData(data));
		}
		return new Promise<ITreeNode[]>(() => []);
	};

	const prepareData = (data: ITreeNode[]) => {
		if (data) {
			for (let index = 0; index < data.length; index++) {
				if (data[index].id === null && data[index].text === 'All') {
					data[index].text = TranslateText('common.all');
					break;
				}
			}
		}

		return data;
	};

	const handleGroupsSelection = (groups: any[]) => {
		if (!props.visible) return;

		setSelectedGroups(groups);
		props.onChangeCallback({ groups: groups.map((gr) => gr.id) });
	};

	useEffect(() => {
		if (!props.visible) return;
		props.setValidationCallback(true);
	}, [props.visible]);

	return (
		<div className={'group-view-container'}>
			<TreeSelectionView
				buttonContainerVisible={true}
				retrieveDataCallback={retrieveGroupsData}
				filterPlaceholder={TranslateText('fleetSelection.searchGroupsPlaceholder')}
				enableClientFilter={false}
				selectionChanged={handleGroupsSelection}
				hasValidations={true}
				validator={validator}
			/>
		</div>
	);
};

export default GroupView;
