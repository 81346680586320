import './styles.scss';

import React from 'react';
import { TranslateText } from 'utils/Translations';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { BusinessHoursTripsDefinitionProps } from './types';

const BusinessHoursTripsDefinition = (props: BusinessHoursTripsDefinitionProps): JSX.Element => {
	return (
		<div className={`business-hours-trips-definition ${props.disabled ? 'disabled' : ''}`}>
			<div className="definition-title">{TranslateText('reports.parameter.defineTripsTemporarySchedule')}</div>
			<div className="definition-container">
				<div className="radio-container">
					<span className="radio-title">
						{TranslateText('reports.parameter.tripsStartedBeforeBusinessHoursAre')}...
					</span>
					<RadioGroup row={false}>
						<FormControlLabel
							value={true}
							disabled={props.disabled}
							control={<Radio size="small" color="primary" />}
							label={`...${TranslateText('reports.parameter.tripsOutsideBusinessHours')}.`}
							labelPlacement="end"
							checked={props.includeTripsStartedBefore}
							className={'radio-option no-grouping'}
							onChange={(_, checked) => {
								props.updateBusinessHoursDefinition({ includeTripsStartedBefore: checked });
							}}
						/>
						<FormControlLabel
							value={false}
							disabled={props.disabled}
							control={<Radio size="small" color="primary" />}
							label={`...${TranslateText('reports.parameter.tripsInsideBusinessHours')}.`}
							labelPlacement="end"
							checked={!props.includeTripsStartedBefore}
							className={'radio-option'}
							onChange={(_, checked) => {
								props.updateBusinessHoursDefinition({ includeTripsStartedBefore: !checked });
							}}
						/>
					</RadioGroup>
				</div>

				<div className="radio-container">
					<span className="radio-title">
						{TranslateText('reports.parameter.tripsFinishedOutsideBusinessHoursAre')}...
					</span>
					<RadioGroup row={false}>
						<FormControlLabel
							value={true}
							disabled={props.disabled}
							control={<Radio size="small" color="primary" />}
							label={`...${TranslateText('reports.parameter.tripsOutsideBusinessHours')}.`}
							labelPlacement="end"
							checked={props.includeTripsEndedAfter}
							className={'radio-option no-grouping'}
							onChange={(_, checked) => {
								props.updateBusinessHoursDefinition({ includeTripsEndedAfter: checked });
							}}
						/>
						<FormControlLabel
							value={false}
							disabled={props.disabled}
							control={<Radio size="small" color="primary" />}
							label={`...${TranslateText('reports.parameter.tripsInsideBusinessHours')}.`}
							labelPlacement="end"
							checked={!props.includeTripsEndedAfter}
							className={'radio-option'}
							onChange={(_, checked) => {
								props.updateBusinessHoursDefinition({ includeTripsEndedAfter: !checked });
							}}
						/>
					</RadioGroup>
				</div>
			</div>
		</div>
	);
};

export default BusinessHoursTripsDefinition;
