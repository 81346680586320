import './tableViewMap.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { googleMapLibraries, googleMapScriptId } from 'utils/MapUtils';

import Tab from '@material-ui/core/Tab';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import { ProcessedTripDto } from '../../store/HistoryStore';
import GoogleMaterialIconOutlined from '../Common/Icons/GoogleMaterialIconOutlined';
import { HistoryDetailsTabsEnum } from '../RightSidebarMenu/ExtendedMenu/HistoryDetailsTabs/types';
import { RietveldNonTrip } from './RietveldNonTrip';
import { RietveldTrip } from './RietveldTrip';

interface Props {
	trip: ProcessedTripDto;
}

const TableViewMap = (props: Props) => {
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	const { isLoaded } = useJsApiLoader({
		id: googleMapScriptId,
		mapIds: [GlobalSettings.googleMapId],
		libraries: googleMapLibraries,
		version: '3',
		googleMapsClientId: GlobalSettings.googleMapId,
		googleMapsApiKey: GlobalSettings.googleMapsApiKey,
		language: loginInLanguage,
	});
	const [map, setMap] = useState<google.maps.Map | null>(null);

	const [availableTabs, setAvailableTabs] = useState<HistoryDetailsTabsEnum[]>([]);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);

	useEffect(() => {
		const tabs: HistoryDetailsTabsEnum[] = [];
		// if (
		// 	currentSession?.customer?.featuresSettings &&
		// 	currentSession?.customer?.featuresSettings[EasyTrackFeature.History] &&
		// 	user?.profile[ClaimType.History]
		// ) {
		// 	tabs.push(HistoryDetailsTabsEnum.Summary);
		// }
		tabs.push(
			HistoryDetailsTabsEnum.Map
			// ...[
			// 	HistoryDetailsTabsEnum.Speed,
			// 	HistoryDetailsTabsEnum.Fuell,
			// 	HistoryDetailsTabsEnum.Settings,
			// 	HistoryDetailsTabsEnum.Messages,
			// ]
		);
		setAvailableTabs(tabs);
	}, [user, currentSession]);

	return isLoaded ? (
		<>
			<div className={'side-buttons-container'}>
				{/*<div className={'side-button selected'}>*/}

				{/*</div>*/}
				{/*<div className={'side-button'}>*/}

				{/*</div>*/}
				{/*<div className={'side-button'}>*/}

				{/*</div>*/}
				{/*<div className={'side-button'}>*/}

				{/*</div>*/}
				{availableTabs.map((tab) => {
					switch (tab) {
						case HistoryDetailsTabsEnum.Map:
							return (
								<Tab
									key={'tab-header-' + tab}
									className={'side-button'}
									label={<GoogleMaterialIconOutlined iconName={'map'} />}
								/>
							);

						case HistoryDetailsTabsEnum.Overall:
							return (
								<Tab
									key={'tab-header-' + tab}
									className={'side-button'}
									label={<GoogleMaterialIconOutlined iconName={'widgets'} />}
								/>
							);

						case HistoryDetailsTabsEnum.Summary:
							return (
								<Tab
									className={'side-button'}
									key={'tab-header-' + tab}
									label={<GoogleMaterialIconOutlined iconName={'query_builder'} />}
								/>
							);
						case HistoryDetailsTabsEnum.Speed:
							return (
								<Tab
									className={'side-button'}
									key={'tab-header-' + tab}
									label={<GoogleMaterialIconOutlined iconName={'speed'} />}
									disabled
								/>
							);
						case HistoryDetailsTabsEnum.Fuel:
							return (
								<Tab
									className={'side-button'}
									key={'tab-header-' + tab}
									label={<GoogleMaterialIconOutlined iconName={'insights'} />}
									disabled
								/>
							);
						case HistoryDetailsTabsEnum.Settings:
							return (
								<Tab
									className={'side-button'}
									key={'tab-header-' + tab}
									label={<GoogleMaterialIconOutlined iconName={'settings'} />}
									disabled
								/>
							);
						case HistoryDetailsTabsEnum.Messages:
							return (
								<Tab
									className={'side-button'}
									key={'tab-header-' + tab}
									label={<GoogleMaterialIconOutlined iconName={'chat'} />}
									disabled
								/>
							);
					}
				})}
			</div>
			<div className={'tableview-map-container'}>
				<GoogleMap
					onLoad={setMap}
					mapContainerStyle={{ width: '100%', height: '100%' }}
					zoom={12}
					options={
						{
							mapId: GlobalSettings.googleMapId,
							clickableIcons: false,
							gestureHandling: 'greedy',
							disableDefaultUI: true,
							mapTypeControl: true,
							disableDoubleClickZoom: true,
							zoomControl: true,
							streetViewControl: true,
							zoomControlOptions: {
								position: google.maps.ControlPosition.RIGHT_BOTTOM,
							},
							streetViewControlOptions: {
								position: google.maps.ControlPosition.RIGHT_BOTTOM,
							},
						} as google.maps.MapOptions
					}
					id={GlobalSettings.googleMapId}
				>
					{props.trip.isNonTrip ? (
						<RietveldNonTrip
							key={props.trip.id}
							trip={props.trip}
							highlight={true}
							offset={new google.maps.Size(16, -8, 'px', 'px')}
							map={map}
							renderForTableView={true}
						/>
					) : (
						<RietveldTrip
							key={props.trip.id}
							trip={props.trip}
							highlight={true}
							offset={new google.maps.Size(16, -8, 'px', 'px')}
							map={map}
							renderForTableView={true}
						/>
					)}
				</GoogleMap>
			</div>
		</>
	) : null;
};

export default TableViewMap;
