import { Column } from 'primereact/column';
import React from 'react';
import './iconSelectionColumn.scss';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { IconSelectionProps } from './types';
import { getSvgIcon } from '../../../LocationsOverview/LocationsOverview';
import { MultiSelectionColumnOption } from '../MultiSelectionColumn';

const IconSelectionColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	selectOptions,
	shown = true,
	showHeaderTooltip = true,
	showContentTooltip = true,
	optionItemTemplate,
	filterDisabled,
}: IconSelectionProps) =>
	shown ? (
		<Column
			className={`sortIcon icon-selection-column ${filterDisabled ? 'disabled' : ''}`}
			key={fieldName}
			field={fieldName}
			header={() => (
				<span
					className={`p-column-title ${filterDisabled ? 'disabled' : ''}`}
					title={showHeaderTooltip ? header : ''}
				>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) => (
							<span title={showContentTooltip ? rowData[fieldName] : ''}>{rowData[fieldName]}</span>
					  )
			}
			sortable={sortable}
			showClearButton={false}
			showFilterMenu={false}
			filter={filterable}
			filterElement={(options) => {
				return (
					<Dropdown
						showClear={
							!(
								options.filterModel.value === undefined ||
								!options.filterModel?.value?.toString()?.length ||
								filterDisabled
							)
						}
						value={options.value}
						options={selectOptions.filter((s: MultiSelectionColumnOption) => !s.hidden)}
						onChange={(e: DropdownChangeParams) => options.filterApplyCallback(e.value ?? [])}
						optionLabel="name"
						className="singleSelect"
						panelClassName="singleSelectDropdown"
						itemTemplate={optionItemTemplate}
						valueTemplate={() => {
							if (!options.value) {
								return null;
							}
							return <span className={'dropdown-selected-icon'}>{getSvgIcon(options.value)}</span>;
						}}
						disabled={filterDisabled}
					/>
				);
			}}
		/>
	) : null;

export default IconSelectionColumn;
