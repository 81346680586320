import './styles.scss';

import React, { useMemo } from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { IntegrationContactsProps } from './types';

const IntegrationContacts = ({
	integration,
	validationResult,
	handleValueChangeMaterial,
	editMode = true,
	isForNewAddWizard = true,
}: IntegrationContactsProps) => {
	const inputFontSize = useMemo(() => (isForNewAddWizard ? 10 : 12), [isForNewAddWizard]);
	return (
		<div className="view-section-wrapper integration-contacts">
			<div className="form-group-section">
				<div className={`title-group ${editMode ? '' : 'disabled'}`}>
					{TranslateText('integrations.customerItContact')}
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="customerContactPersonName"
						className="resize-font"
						label={TranslateText('integrations.personName')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="customerContactPersonName"
						value={integration.customerContactPersonName}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'customerContactPersonName')}
						validationResult={validationResult?.customerContactPersonName}
						disabled={!editMode}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="customerContactPersonEmail"
						className="resize-font"
						label={TranslateText('integrations.personEmail')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="customerContactPersonEmail"
						value={integration.customerContactPersonEmail}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'customerContactPersonEmail')}
						validationResult={validationResult?.customerContactPersonEmail}
						disabled={!editMode}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="customerContactPersonPhone"
						className="resize-font"
						label={TranslateText('integrations.personPhone')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="customerContactPersonPhone"
						value={integration.customerContactPersonPhone}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'customerContactPersonPhone')}
						validationResult={validationResult?.customerContactPersonPhone}
						disabled={!editMode}
					/>
				</div>
			</div>
			<div className="form-group-section">
				<div className={`title-group ${editMode ? '' : 'disabled'}`}>
					{TranslateText('integrations.itSupplierContact')}
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="supplierContactPersonName"
						className="resize-font"
						label={TranslateText('integrations.personName')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="supplierContactPersonName"
						value={integration.supplierContactPersonName}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'supplierContactPersonName')}
						validationResult={validationResult?.supplierContactPersonName}
						disabled={!editMode}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="supplierContactPersonEmail"
						className="resize-font"
						label={TranslateText('integrations.personEmail')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="supplierContactPersonEmail"
						value={integration.supplierContactPersonEmail}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'supplierContactPersonEmail')}
						validationResult={validationResult?.supplierContactPersonEmail}
						disabled={!editMode}
					/>
				</div>
				<div className="form-group">
					<MaterialTextField
						isForNewAddWizard={isForNewAddWizard}
						id="supplierContactPersonPhone"
						className="resize-font"
						label={TranslateText('integrations.personPhone')}
						inputProps={{ style: { fontSize: inputFontSize } }}
						name="supplierContactPersonPhone"
						value={integration.supplierContactPersonPhone}
						handleValueChange={(value) => handleValueChangeMaterial(value, 'supplierContactPersonPhone')}
						validationResult={validationResult?.supplierContactPersonPhone}
						disabled={!editMode}
					/>
				</div>
			</div>
		</div>
	);
};

export default IntegrationContacts;
