import { LiveDataResultDto } from 'store/LiveData';

export interface RowData extends LiveDataResultDto {
	id: string;
	entityName: string;
	isPerson: boolean;
	isParked?: boolean;
	connectedToEntityName: string;
	driverName: string;
	address: string;
	stateStart: string;
	speed: string;
	direction: string;
	objectStatus: string;
	objectIcon: string;
	unitId: string;
	fromPersonId: boolean;
}

export enum StateFilterEnum {
	None = 0,
	Driving = 1,
	Parked = 2,
	Idle = 4,
	Unknown = 8,
	Alert = 16,
	All = 1 | 2 | 4 | 8, //31
}

export const StateFilterDataSource: StateFilterEnum[] = [
	StateFilterEnum.All,
	StateFilterEnum.Driving,
	StateFilterEnum.Idle,
	StateFilterEnum.Parked,
	StateFilterEnum.Unknown,
];

export const primaryColor = '#e91e63';
