import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { TreeSelectionGroupDialog } from 'components/TreeSelectionDialog';
import { AlertData } from 'components/Widgets/Views/AlertRecipientsView/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useGetPlaceholderAndButtons } from 'utils/ReceiversUtil/hooks';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import { useInitialTreeData, useTreeDialogEvents } from './hooks';
import { AddNewAlertRecipientButtonProps } from './types';

const AddNewAlertRecipientButton = (props: AddNewAlertRecipientButtonProps): JSX.Element => {
	const [dialogVisible, setDialogVisible] = useState<boolean>(false);
	const user = useSelector((s: ApplicationState) => s.oidc.user);

	const { useNotification, useEmail, useSms, useWhatsApp } = useSelector(
		(s: ApplicationState) => s.loadedEntityContext.entityContextData.entityDetails
	);

	const { filterPlaceholder, filterButtons } = useGetPlaceholderAndButtons();
	const initialTreeData = useInitialTreeData(props.currentSelection as AlertData);

	const hasNotificationAlertClaim: boolean = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Alerts,
		values: [ClaimValue.notificationAlert],
	});
	const hasMailAlertClaim: boolean = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Alerts,
		values: [ClaimValue.mailAlert],
	});
	const hasSmsAlertClaim: boolean = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Alerts,
		values: [ClaimValue.smsAlert],
	});
	const hasWhatsAppAlertClaim: boolean = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Alerts,
		values: [ClaimValue.whatsAppAlert],
	});

	const {
		closeDialogCallback,
		showDialogCallback,
		saveSelectionCallback,
		retrieveDataCallback,
	} = useTreeDialogEvents(
		props.entityId,
		(props.currentSelection as AlertData).alertRecipients,
		useNotification && hasNotificationAlertClaim,
		useEmail && hasMailAlertClaim,
		useSms && hasSmsAlertClaim,
		useWhatsApp && hasWhatsAppAlertClaim,
		props.actionExecuted,
		setDialogVisible
	);

	return (
		<>
			<Button className="widget-button add-button" onClick={showDialogCallback}>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionGroupDialog
				title={TranslateText('common.addPersons')}
				filterPlaceholder={filterPlaceholder}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={closeDialogCallback}
				saveCallback={saveSelectionCallback}
				retrieveData={retrieveDataCallback}
				filterButtons={filterButtons}
			/>
		</>
	);
};

export default AddNewAlertRecipientButton;
