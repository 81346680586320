const EasyTrackFeature = {
	Map: 'map',
	Live: 'live',
	History: 'history',
	Dashboard: 'dashboard',
	Reports: 'reports',
	Alerts: 'alerts',
	GeoFence: 'geoFence',
	Templates: 'templates',
	Locations: 'locations',
	DriverIdentification: 'driverIdentification',
	Keypad: 'keypad',
	AddUnknownId: 'addUnknownId',
	AssetTracking: 'assetTracking',
	Integration: 'integration',
	DriverStyleScores: 'driverStyleScores',
	MediaFootage: 'mediaFootage',
};

export default EasyTrackFeature;
