import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router';

import { ApplicationState } from './store';

const AuthenticationPath = '/';
const UnauthorizedPath = '/unauthorized';

type Props = {
	claim?: string;
	feature?: string;
	path: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

const ProtectedRoute = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);

	let redirectPath = null;
	if (!user) {
		redirectPath = AuthenticationPath;
	} else {
		if (props.feature && !!currentSession?.customer?.featuresSettings) {
			const customerFeatureSettings = currentSession.customer.featuresSettings;
			if (!customerFeatureSettings[props.feature]) {
				redirectPath = AuthenticationPath;
			}
		}
		if (!redirectPath && props.claim) {
			if (!user?.profile[props.claim]) {
				redirectPath = UnauthorizedPath;
			}
		}
	}

	if (redirectPath) {
		return <Redirect exact to={{ pathname: redirectPath }} />;
	} else {
		return <Route exact path={props.path} component={props.component} />;
	}
};

export default ProtectedRoute;
