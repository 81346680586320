import './MileageCorrectionDialog.scss';
import '../Common/RemoveSpinnerOptionInput.scss'

import {Button, Dialog, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import {DialogUtil} from 'components/Common/NotificationDialog/NotificationDialog';
import {ValidationMessage} from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import React, {useCallback, useEffect, useState} from 'react';
import DateTimeUtil, {DateTimeConversionUtil} from 'shared/datetime/DateTimeUtil';
import {ValidationResult} from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import {ValidatorFunctions} from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import {TranslateText} from 'utils/Translations';
import {MileageCorrectionRowData} from '../Widgets/CorrectionsView';
import {DebouncedButton} from "../Common/DebouncedButton";
import {AutocompleteItem} from "../Common/Autocomplete/MaterialAutocomplete";

import {handleKeyDown} from "../Common/RemoveSpinnerOptionInput";
import {DEBOUNCE_TIME, noop} from "../../Constants";
import TimezoneSelection from "../Common/CommonDataSelections/TimezoneSelection";

type Props = {
	visible: boolean;
	isEditMode: boolean;
	dialogTitle: string;
	initialData?: MileageCorrectionRowData;
	entityId?: string;
	closeCallback: () => void;
	saveCallback: (data: any) => void;
	removeCallback?: (id: string) => void;
	changeDataCallback?: (data: object | null, hasChanges: boolean) => void;
	timeZone?: AutocompleteItem;
	activationDate?: (data?: string) => string;
};

const MileageCorrectionDialog = (props: Props) => {

	const [correctionData, setCorrectionData] = useState<MileageCorrectionRowData>({
		id: null,
		requestDate: props.activationDate(),
		oldMileage: null,
		newMileage: null,
		personName: null,
	});
	const [formValidator, setFormValidator] = useState(null as Validator);
	const [validForm, setValidForm] = useState<boolean>(false);
	const [unsavedData, setUnsavedData] = useState(false);
	const [validationResult, setValidationResult] = useState<ValidationResult>();
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const isDateUnique = async (date: any) => {
		if (!props.entityId) return true;

		const url = `${GlobalSettings.objectsMaintenanceApi}/mileageCorrectionDates/${props.entityId}`;
		return await ajaxUtil
			.post(url, date)
			.then((data: any) => {
				if (data) {
					return data;
				}
				return false;
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		//Set data for edit mode
		const initialData: MileageCorrectionRowData = {...props.initialData,
			requestDate: props.activationDate(props.initialData?.requestDate as string)
		};
		setCorrectionData(initialData);
	}, [props.initialData]);

	useEffect(() => {
		if (props.visible) {
			const validator = new Validator(
				{
					fieldRules: {
						requestDate: {
							rules: {
								required: ValidatorFunctions.required(),
								validDate: {
									message: TranslateText('fieldsValidations.dateInFuture'),
									validationFunction: (data) => {
									const newDate = data as MileageCorrectionRowData;
										return ValidatorFunctions.wrapInPromise(
											new Date(newDate.requestDate) <= new Date(props.activationDate()) || props.isEditMode
										);
									},
								},
								validUniqueDate: {
									message: TranslateText('fieldsValidations.uniqueDate'),
									validationFunction: (data) => {
										if (!props.isEditMode) {
											return isDateUnique(data.requestDate);
										}
										return ValidatorFunctions.wrapInPromise(true);
									},
								},
							},
						},
						newMileage: {
							rules: {
								required: ValidatorFunctions.required(),
								maxLength: ValidatorFunctions.maxLength(10),
								minNumber: ValidatorFunctions.minNumber(0),
							},
						},
					},
				},
				true
			);
			setFormValidator(validator);
		}
		if (!props.isEditMode) {
			const initialData: MileageCorrectionRowData = {
				id: null,
				requestDate: props.activationDate(),
				oldMileage: null,
				newMileage: null,
				personName: null,
			};
			setCorrectionData(initialData);
		}
	}, [props.visible]);

	useEffect(() => {
		if (formValidator) {
			setValidationResult(null);
			formValidator.validate(correctionData).then((result) => {
				setValidationResult(result.validationResult);
				setValidForm(result.formResult);
			});
		}
	}, [formValidator]);

	useEffect(() => {
		if (!formValidator) {
			return;
		}
		formValidator.validate(correctionData).then((result) => {
			setValidationResult(result.validationResult);
			setValidForm(result.formResult);
		});
	}, [correctionData]);

	const events = {
		onValueChanged: async (value: number | Date, key: keyof MileageCorrectionRowData) => {
			const newState = { ...correctionData };
			(newState[key] as number | Date) = value;
			setCorrectionData(newState);
			if (
				newState.requestDate !== MileageCorrectionDialog.defaultProps.initialData.requestDate ||
				newState.newMileage !== MileageCorrectionDialog.defaultProps.initialData.newMileage
			) {
				setUnsavedData(true);
				props.changeDataCallback(correctionData, true);
			}
		},
	};

	const saveClicked = useCallback(async () => {
		formValidator.validate(correctionData).then((result) => {
			if (result.formResult) {
				props.saveCallback(correctionData);
				setUnsavedData(false);
				props.changeDataCallback(correctionData, false);
			}
			setValidForm(result.formResult);
		});
	}, [correctionData, props.saveCallback, props.changeDataCallback]);

	const okClick = () => {
		setUnsavedData(false);
		props.closeCallback();
		if (!props.isEditMode) {
			const initialData: MileageCorrectionRowData = {
				id: null,
				requestDate: props.activationDate(),
				oldMileage: null,
				newMileage: null,
				personName: null,
			};
			setCorrectionData(initialData);
		} else {
			const initialData: MileageCorrectionRowData = {...props.initialData,
				requestDate: props.activationDate(props.initialData.requestDate as string)
			};
			setCorrectionData(initialData);
		}
		props.changeDataCallback(correctionData, false);
	};

	const notifyAboutUnsavedData = () => {
		if (isDialogOpen) return;

		setIsDialogOpen(true);
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.cancel'),
		}).then((response: boolean) => {
			setIsDialogOpen(false);
			if (response) {
				//yes was clicked
				okClick();
			}
		});
	};

	const tryCloseWizard = () => {
		if (unsavedData === true) {
			notifyAboutUnsavedData();
		} else {
			props.closeCallback();
			if (!props.isEditMode) {
				const initialData: MileageCorrectionRowData = {
					id: null,
					requestDate: props.activationDate(),
					oldMileage: null,
					newMileage: null,
					personName: null,
				};
				setCorrectionData(initialData);
			} else {
				const initialData: MileageCorrectionRowData = {...props.initialData,
					requestDate: props.activationDate(props.initialData?.requestDate as string)
				};
				setCorrectionData(initialData);
			}
		}
	};
	const removeClicked = useCallback(() => {
		props.removeCallback(props.initialData.id);
	}, [props.initialData, props.removeCallback]);

	return props.visible ? (
		<Dialog
			onClose={() => tryCloseWizard()}
			className="correction-dialog"
			open={props.visible}
			fullWidth
			disableBackdropClick
		>
			<DialogTitle>{TranslateText(props.dialogTitle)}</DialogTitle>
			<DialogContent className="dialog-content">
				<div className="content correctionForm">
					<div className="form-group">
						<MaterialDatePicker
							name="requestDate"
							showTime={true}
							date={correctionData?.requestDate}
							format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
								DateTimeUtil.dateTimeFormat(),
								true
							)}
							label={TranslateText('fields.activationDate')}
							disabled={props.isEditMode}
							onDateChange={(date?: Date) => {
								events.onValueChanged(date, 'requestDate');
							}}
							fullWidth={true}
						/>
						<ValidationMessage result={validationResult?.requestDate} />
					</div>
					<div className="form-group">
						<TimezoneSelection
							label={TranslateText('fields.timezone')}
							name="timezoneId"
							valueId={props.timeZone?.id as string}
							disabled={true}
							isForNewAddWizard={false}
						 	onChange={() => noop()}/>
					</div>
					<div className="form-group">
						<TextField
							id="outlined-number"
							type="number"
							className={`resize-font noSpinner`}
							fullWidth
							label={TranslateText('widgets.grid.colNewOdo')}
							InputProps={{
								inputProps: {
									min: 0,
									maxLength: 10,
									style: { fontSize: 12 },
								},
							}}
							name="newMileage"
							value={correctionData.newMileage}
							size="small"
							onChange={(e: any) => events.onValueChanged(e.target.value, 'newMileage')}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={validationResult?.newMileage} />
						<div className={'warning-container'}>{TranslateText('widgets.processCorrectionWarning')}</div>
					</div>
				</div>
				<div className={'buttons-container'}>
					<Button onClick={() => tryCloseWizard()}>{TranslateText('common.buttonCancel')}</Button>
					{props.isEditMode ? (
						<Button onClick={removeClicked}>{TranslateText('common.buttonRemove')}</Button>
					) : null}
					<DebouncedButton onClick={saveClicked} disabled={!validForm || !unsavedData} debounceTime={DEBOUNCE_TIME}>
						{TranslateText('common.buttonSave')}
					</DebouncedButton>
				</div>
			</DialogContent>
		</Dialog>
	) : null;
};

MileageCorrectionDialog.defaultProps = {
	visible: true,
	initialData: { requestDate: new Date(), newMileage: null },
};

export default MileageCorrectionDialog;
