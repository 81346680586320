import { CreateCSSProperties, CSSProperties, PropsFunc, WithStyles } from '@material-ui/styles';
import { ContentBlock, DraftBlockType, DraftHandleValue, EditorState, SelectionState } from 'draft-js';
import { FunctionComponent } from 'react';

import { TAutocompleteItem } from './components/Autocomplete';
import { TCustomControl, TToolbarButtonSize, TToolbarControl } from './components/Toolbar/types';
import { TUrlData } from './components/UrlPopover';

export type AvailableCustomComponents = 'alert-message-tags';

export type TDraftEditorProps = {
	spellCheck?: boolean;
	stripPastedStyles?: boolean;
	handleDroppedFiles?: (selectionState: SelectionState, files: Blob[]) => DraftHandleValue;
};

export type TKeyCommand = {
	key: number;
	name: string;
	callback: (state: EditorState) => EditorState;
};

export type TDecorator = {
	component: FunctionComponent;
	regex: RegExp;
};

export type TAutocompleteStrategy = {
	triggerChar: string;
	items: TAutocompleteItem[];
	insertSpaceAfter?: boolean;
	atomicBlockName?: string;
};

export type TAutocomplete = {
	strategies: TAutocompleteStrategy[];
	suggestLimit?: number;
};

export type TAsyncAtomicBlockResponse = {
	data: any;
};

export type TMUIRichTextEditorRef = {
	focus: () => void;
	save: () => void;
	/**
	 * @deprecated Use `insertAtomicBlockSync` instead.
	 */
	insertAtomicBlock: (name: string, data: any) => void;
	insertAtomicBlockSync: (name: string, data: any) => void;
	insertAtomicBlockAsync: (name: string, promise: Promise<TAsyncAtomicBlockResponse>, placeholder?: string) => void;
	getEditorState: () => EditorState;
};

export enum ToolbarPopupEnum {
	Media,
	Link,
	Font,
	Variables,
	None,
}

export type TMUIRichTextEditorProps = {
	id?: string;
	/**
	 * @deprecated Use `defaultValue` instead.
	 */
	value?: any;
	defaultValue?: any;
	label?: string;
	readOnly?: boolean;
	inheritFontSize?: boolean;
	error?: boolean;
	controls?: Array<TToolbarControl>;
	customControls?: TCustomControl[];
	customComponents?: AvailableCustomComponents[];
	decorators?: TDecorator[];
	toolbar?: boolean;
	toolbarButtonSize?: TToolbarButtonSize;
	inlineToolbar?: boolean;
	inlineToolbarControls?: Array<TToolbarControl>;
	draftEditorProps?: TDraftEditorProps;
	keyCommands?: TKeyCommand[];
	maxLength?: number;
	autocomplete?: TAutocomplete;
	onSave?: (data: string) => void;
	onChange?: (state: EditorState) => void;
	onFocus?: () => void;
	onBlur?: () => void;
};

export type TMUIRichTextEditorState = {
	anchorUrlPopover?: HTMLElement;
	urlKey?: string;
	urlData?: TUrlData;
	type?: ToolbarPopupEnum;
	toolbarPosition?: TPosition;
};

export type TPosition = {
	top: number;
	left: number;
};

export type TSelectionInfo = {
	inlineStyle: Immutable.OrderedSet<string>;
	blockType: DraftBlockType;
	entityType: string;
	linkKey: string;
	block: ContentBlock;
};

export type TStateOffset = {
	start: number;
	end: number;
};

export interface TMUIRichTextEditorStyles {
	overrides?: {
		MUIRichTextEditor?: {
			root?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			container?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			inheritFontSize?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			editor?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			editorContainer?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			editorReadOnly?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			error?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			hidePlaceholder?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			placeHolder?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			linkPopover?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			linkTextField?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			anchorLink?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			toolbar?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
			inlineToolbar?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
		};
	};
}
