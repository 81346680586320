import './HistoryTripGroup.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ApplicationState } from '../../store';
import { HISTORY_SET_EXPANDED_SECTIONS, ProcessedTripDto } from '../../store/HistoryStore';
import HistoryOverviewTripCard from './HistoryOverviewTripCard';
import { Group } from './HistorySideBar';
import HistoryTripGroupSummary from './HistoryTripGroupSummary/HistoryTripGroupSummary';
import { useDelayedDisplay, useTripsObservable } from './hooks';
import { HistoryTripCardPropsRef, TripCardCommonProps } from './types';

const getScrollableFromSubgroup = (trips: ProcessedTripDto[]) => {
	const trip = trips.find((x) => !x.isParked);
	if (trip) {
		return trip.idWithParent;
	} else if (trips.length) {
		return trips[0].idWithParent;
	}
	return null;
};

//we have max 2 group level so no need for recursion
const getScrollableTripId = (
	childGroups: Group[],
	trips: ProcessedTripDto[],
	expandedChilds: string[]
): string | null => {
	let idWithParent: string = null;

	if (childGroups.length) {
		//get first expanded group, if none expanded get first
		let firstExpandedChild = childGroups.find((x) => expandedChilds.some((c) => c === x.name));
		if (!firstExpandedChild) {
			firstExpandedChild = childGroups[0];
		}

		//get first not parked trip, if none get first
		idWithParent = getScrollableFromSubgroup(firstExpandedChild.trips);
	} else if (trips.length) {
		//get first not parked trip, if none get first
		idWithParent = getScrollableFromSubgroup(trips);
	}

	return idWithParent;
};

interface Props {
	index?: number;
	parentGroupName?: string;
	group: Group;
	parentGroupFinishedExpanding: boolean;
	scrollTrip: { idWithParent: string | null } | null;
	setExpandedChild?: (groupId: string, expanded: boolean) => void;

	groupVisibilityCallback: React.MutableRefObject<(group: Group, visible: boolean) => void>;
	cardVisibilityChanged: React.MutableRefObject<(visible: boolean, trip: ProcessedTripDto) => void>;
	selectionCallback: React.MutableRefObject<(trip: ProcessedTripDto, controlPressed: boolean) => void>;
	historyTripCardPropsRef: React.MutableRefObject<HistoryTripCardPropsRef>;
	tripCardCommonProps: TripCardCommonProps;
	appendToBatchDisplay?: (group: { name: string; render?: () => void }, add: boolean) => void;
}

const HistoryTripGroup = (props: Props) => {
	const dispatch = useDispatch();

	const fullGroupName = `${props.parentGroupName ?? ''}${props.group.name}`;
	const expandedSection = useSelector((s: ApplicationState) =>
		s.historyStore.expandedSections.find((s) => s.group === fullGroupName)
	);
	const [groupFinishedExpanding, setGroupFinishedExpanding] = useState(props.parentGroupFinishedExpanding);

	const scrollTrip = useMemo<{ idWithParent: string | null } | null>(
		() => props.scrollTrip ?? { idWithParent: null },
		[]
	);
	const expandedChilds = useRef<string[]>([]);
	const setExpandedChild = useCallback((groupName: string, expanded: boolean) => {
		const index = expandedChilds.current.findIndex((x) => x === groupName);
		if (expanded && index < 0) {
			expandedChilds.current.push(groupName);
		} else if (!expanded && index >= 0) {
			expandedChilds.current.splice(index, 1);
		}
	}, []);
	useEffect(() => {
		if (props.setExpandedChild) {
			props.setExpandedChild(props.group.name, !expandedSection || expandedSection.expanded);
		}
	}, [expandedSection]);

	const onExpandedChange = useCallback(
		(event: React.ChangeEvent<{}>, expanded: boolean) => {
			if (expanded) {
				scrollTrip.idWithParent = getScrollableTripId(
					props.group.childGroups,
					props.group.trips,
					expandedChilds.current
				);
			}

			dispatch({
				type: HISTORY_SET_EXPANDED_SECTIONS,
				payload: {
					expanded: expanded,
					groupName: fullGroupName,
				},
			});
		},
		[props.group.trips, props.group.childGroups]
	);

	useEffect(() => {
		setGroupFinishedExpanding(props.parentGroupFinishedExpanding);
	}, [props.parentGroupFinishedExpanding]);

	const display = useDelayedDisplay(props.group.name, props.index, props.appendToBatchDisplay);

	const { trips, tripsObservable, tripCardCommonPropsRef } = useTripsObservable(
		props.group.trips,
		props.tripCardCommonProps
	);

	const groupVisibilityCallbackRef = useRef<(checked: boolean) => void>();
	groupVisibilityCallbackRef.current = (checked: boolean) => {
		props.groupVisibilityCallback.current && props.groupVisibilityCallback.current(props.group, checked);
	};

	if (!display) {
		return null;
	}
	return (
		<Accordion
			key={props.group.name}
			expanded={!expandedSection || expandedSection.expanded}
			onChange={onExpandedChange}
			TransitionProps={{
				onEntered: () => {
					setGroupFinishedExpanding(true);
				},
				onEnter: () => {
					setGroupFinishedExpanding(false);
				},
				onExit: () => {
					setGroupFinishedExpanding(false);
				},
			}}
		>
			<AccordionSummary
				className={`summary-group accordion-summary-level${props.group.groupInfo.level}`}
				expandIcon={<ExpandMore />}
			>
				<HistoryTripGroupSummary
					displayName={props.group.displayName}
					groupingType={props.group.groupInfo.groupingType}
					iconType={props.group.groupInfo.iconType}
					tripsNumber={props.group.groupInfo.tripsNumber}
					allNonTrips={props.group.groupInfo.allNonTrips}
					totalDrivingTime={props.group.groupInfo.totalDrivingTime}
					totalDistance={props.group.groupInfo.totalDistance}
					allVisible={props.group.groupInfo.allVisible}
					someVisible={props.group.groupInfo.someVisible}
					groupVisibilityCallbackRef={groupVisibilityCallbackRef}
				/>
			</AccordionSummary>
			<AccordionDetails>
				{props.group.childGroups.length ? (
					props.group.childGroups.map((childGroup) => {
						return (
							<HistoryTripGroup
								key={childGroup.name}
								group={childGroup}
								parentGroupName={fullGroupName}
								groupVisibilityCallback={props.groupVisibilityCallback}
								selectionCallback={props.selectionCallback}
								cardVisibilityChanged={props.cardVisibilityChanged}
								parentGroupFinishedExpanding={groupFinishedExpanding}
								setExpandedChild={setExpandedChild}
								scrollTrip={scrollTrip}
								historyTripCardPropsRef={props.historyTripCardPropsRef}
								tripCardCommonProps={props.tripCardCommonProps}
							/>
						);
					})
				) : (
					<div className="trip-rows">
						{trips.map((tripCard) => (
							<HistoryOverviewTripCard
								key={tripCard.key}
								tripConfig={tripCard}
								selectionCallback={props.selectionCallback}
								cardVisibilityChanged={props.cardVisibilityChanged}
								groupFinishedExpanding={props.parentGroupFinishedExpanding && groupFinishedExpanding}
								scrollTrip={scrollTrip}
								historyTripCardPropsRef={props.historyTripCardPropsRef}
								tripCardCommonPropsRef={tripCardCommonPropsRef}
								tripsObservable={tripsObservable}
							/>
						))}
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(HistoryTripGroup);
