import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Column, ColumnBodyType } from 'primereact/column';
import { addLocale } from 'primereact/api';

import DateTimeUtil, { DateTimeConversionUtil } from 'shared/datetime/DateTimeUtil';
import {ApplicationState} from "../../../store";

type Props = {
	fieldName: string;
	header: string;
	body?: ColumnBodyType;
	sortable?: boolean;
	filterable?: boolean;
	leftPadding?: boolean;
	shown?: boolean;
	showHeaderTooltip?: boolean;
	showFilterTooltip?: boolean;
	showContentTooltip?: boolean;
	formatDateFunction?: (date: string | Date) => string;
	defaultWidth?: number;
	showTime?: boolean;
};

const formatDate = (date: string | Date) => {
	const userFormat = DateTimeUtil.dateFormat();
	const format = DateTimeConversionUtil.syncFusionToMomentDateFormat(userFormat, true);
	return moment(date?.toString()).format(format);
};

const getFormat = () => {
	const userFormat = DateTimeUtil.dateFormat();
	const format = DateTimeConversionUtil.syncFusionToMomentDateFormat(userFormat, true);

	let newFormat = format
		.replace('dddd', 'DD')
		.replace('YYYY', 'yy')
		.replace('YY', 'y')
		.replace('DD', 'dd');

	if (newFormat.includes('MMM')) {
		newFormat = newFormat.replace('MMM', 'M');
	} else {
		newFormat = newFormat.replace('MM', 'mm');
	}

	return newFormat;
};
const formatTime = () => {
	const useFormat = DateTimeUtil.timeFormat();
	if(useFormat.includes('a') === true)
		return 12;
	return 24
};

const languageCalendar: any = {
	'en': {
		firstDayOfWeek: 0,
		dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
		monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		today: 'Today',
		clear: 'Clear'
	},
	'nl': {
		firstDayOfWeek: 0,
		dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
		dayNamesShort: ['Zo', 'Ma', 'Di', 'Woe', 'Do', 'Vr', 'Za'],
		dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo ', 'Do', 'Vr ', 'Za'],
		monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
		monthNamesShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
		today: 'Vandaag',
		clear: 'Legen'
	}
};

const DateColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	leftPadding,
	shown = true,
	showHeaderTooltip = true,
	showFilterTooltip = true,
	showContentTooltip = true,
	formatDateFunction = formatDate,
	defaultWidth,
	showTime = false,
}: Props) => {
	const loginInLanguage = useSelector((s: ApplicationState) => s.translations?.language);

	return shown ? (
		<Column
			className={`sortIcon ${leftPadding ? 'leftPadding' : ''} minWidth`}
			key={fieldName}
			field={fieldName}
			style={
				defaultWidth !== undefined
					? {
						flexGrow: '1',
						flexShrink: '1',
						flexBasis: `${defaultWidth}px`,
					}
					: null
			}
			header={() => (
				<span className="p-column-title" title={showHeaderTooltip ? header : ''}>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) =>
						rowData[fieldName] ? (
							<span title={showContentTooltip ? formatDateFunction(rowData[fieldName]) : ''}>
									{formatDateFunction(rowData[fieldName])}
								</span>
						) : null
			}
			dataType="date"
			sortable={sortable}
			showClearButton={false}
			filter={filterable}
			filterElement={(options) => {
				if (loginInLanguage !== null)
					addLocale(loginInLanguage, languageCalendar[loginInLanguage]);
				else
					addLocale('en', languageCalendar['en']);

				return (
					<span title={showFilterTooltip && options.value ? formatDateFunction(options.value) : ''}>
						<Calendar
							locale={loginInLanguage !== null ? loginInLanguage : 'en'}
							showTime={showTime}
							hourFormat={showTime && formatTime().toString()}
							dateFormat={getFormat()}
							value={options.value}
							onChange={({value}) => options.filterApplyCallback(value)}
						/>
					</span>
				);
			}}
		/>
	) : null;
}

export default DateColumn;
