import { TranslateText } from '../utils/Translations';
import ConnectionTypeEnum from './ConnectionTypeEnum';
import IdIdentifiable from './IdIdentifiable';
import RelatedConnectionResolution from './RelatedConnectionResolution';

class BaseConnection extends IdIdentifiable {
	constructor(copyOf?: BaseConnection) {
		if (copyOf) {
			super(copyOf.id);
			this.entity1Id = copyOf.entity1Id;
			this.entity2Id = copyOf.entity2Id;
			this.displayNameEntity1 = copyOf.displayNameEntity1;
			this.displayExtraEntity1 = copyOf.displayExtraEntity1;
			this.displayNameEntity2 = copyOf.displayNameEntity2;
			this.displayExtraEntity2 = copyOf.displayExtraEntity2;
			this.connectionType = copyOf.connectionType;
			this.connectionStartDate = copyOf.connectionStartDate;
			this.connectionEndDate = copyOf.connectionEndDate;
			this.resolveConflictsAutomatically = copyOf.resolveConflictsAutomatically;
			this.relatedConnections = [];
			this.connectionAction = copyOf.connectionAction;
			if (copyOf.relatedConnections) {
				copyOf.relatedConnections.forEach((element) => {
					this.relatedConnections.push(element);
				});
			}
		} else {
			super(null);
		}
	}

	public entity1Id: string;
	public entity2Id: string;
	public displayNameEntity1: string;
	public displayExtraEntity1: string;
	public displayNameEntity2: string;
	public displayExtraEntity2: string;
	public connectionType: ConnectionTypeEnum;
	public relatedConnections: Array<RelatedConnectionResolution>;
	public connectionStartDate: Date;
	public connectionEndDate?: Date;
	public resolveConflictsAutomatically: boolean;
	public connectionAction: string;

	get formatedDisplay(): string {
		return this.formatDisplay();
	}
	get formatedDisplayEntity1(): string {
		return this.formatDisplayEntity1();
	}
	get formatedDisplayEntity2(): string {
		return this.formatDisplayEntity2();
	}

	formatDisplay(): string {
		switch (this.connectionType) {
			case ConnectionTypeEnum.CustomerDevice:
				return (
					TranslateText('connections.customer') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.device') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.CustomerObject:
				return (
					TranslateText('connections.customer') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.object') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.CustomerPerson:
				return (
					TranslateText('connections.customer') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.person') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.CustomerSimCard:
				return (
					TranslateText('connections.customer') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.simcard') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.DeviceObject:
				return (
					TranslateText('connections.device') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.object') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.PersonObject:
				return (
					TranslateText('connections.person') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.object') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			case ConnectionTypeEnum.DeviceSimCard:
				return (
					TranslateText('connections.device') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					TranslateText('connections.simcard') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']'
				);
			default:
				return '';
		}
	}

	formatDisplayEntity1(): string {
		switch (this.connectionType) {
			case ConnectionTypeEnum.DeviceObject:
				return (
					TranslateText('connections.device') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					(this.displayExtraEntity1 ? ' (' + this.displayExtraEntity1 + ')' : '')
				);
			case ConnectionTypeEnum.PersonObject:
				return (
					TranslateText('connections.person') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					(this.displayExtraEntity1 ? ' (' + this.displayExtraEntity1 + ')' : '')
				);
			case ConnectionTypeEnum.DeviceSimCard:
				return (
					TranslateText('connections.device') +
					': [' +
					this.displayNameEntity1 +
					'] ' +
					(this.displayExtraEntity1 ? ' (' + this.displayExtraEntity1 + ')' : '')
				);
			default:
				return '';
		}
	}

	formatDisplayEntity2(): string {
		switch (this.connectionType) {
			case ConnectionTypeEnum.DeviceObject:
				return (
					TranslateText('connections.object') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']' +
					(this.displayExtraEntity2 ? ' (' + this.displayExtraEntity2 + ')' : '')
				);
			case ConnectionTypeEnum.PersonObject:
				return (
					TranslateText('connections.object') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']' +
					(this.displayExtraEntity2 ? ' (' + this.displayExtraEntity2 + ')' : '')
				);
			case ConnectionTypeEnum.DeviceSimCard:
				return (
					TranslateText('connections.simcard') +
					': [' +
					(this.displayNameEntity2 === null ? '' : this.displayNameEntity2) +
					']' +
					(this.displayExtraEntity2 ? ' (' + this.displayExtraEntity2 + ')' : '')
				);
			default:
				return '';
		}
	}
}

export default BaseConnection;
