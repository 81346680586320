import '../oldWidget.scss';

import DeviceSensorTemplateConnections from 'models/DeviceSensorTemplateConnections';
import SensorTypeEnum from 'models/SensorTypeEnum';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import { User } from 'oidc-client';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import ClaimType, { ClaimValue } from '../../../authorization/ClaimType';
import { ClaimUtil } from '../../../authorization/ClaimUtil';
import GlobalSettings from '../../../GlobalSettings.json';
import TemplateEventActionEnum from '../../../models/TemplateEventActionEnum';
import TripTypesEnum from '../../../models/TripTypesEnum';
import { ApplicationState } from '../../../store';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import BaseWidget from '../../BaseWidget';
import WidgetHeader from '../../BaseWidget/WidgetHeader';
import { GridWidgetOverview } from '../../GridOverview/GridWidgetOverview';
import { OldWidgetProps } from '../Widget';
import InputTemplateConnectionDialog from './InputTemplateConnectionDialog';

export enum DialogMode {
	None = '',
	Link = 'link',
	Edit = 'edit',
}

const mapState = (state: ApplicationState) => {
	return {
		tripTypes: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: state.currentSession.customer.featuresSettings.tripTypes,
	};
};

const connector = connect(mapState, null);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends OldWidgetProps, PropsFromRedux {
	getAccessTokenCallback: () => string;
	user: User;
}
interface OverviewResponseWrapperDto<T> {
	items: T[];
	count: number;
}

interface DeviceSensorTypeDto {
	deviceId: string;
	sensorType: SensorTypeEnum;
	sensorId: string;
}

interface State {
	widgedInEditMode: boolean;
	inputData: DeviceSensorTemplateConnections[];
	currentSelectedRowData: DeviceSensorTemplateConnections;
	visible: boolean;
	canEditRow: boolean;
	inputTemplates: DeviceSensorTemplateConnections[];
	connectedObjectId?: string;
}

export interface ListItem {
	id: string;
	display: string;
}

export type TemplateListItem = ListItem & {
	sensorType: SensorTypeEnum;
	eventAction?: TemplateEventActionEnum;
	countRelevantState?: boolean;
	durationCounter?: boolean;
};

class DeviceTemplateWidget extends BaseWidget<Props, State> {
	private currentDeviceId: string;
	private templateConnectionRef: any;
	private templatesData: Map<SensorTypeEnum, TemplateListItem[]>;

	constructor(props: Props) {
		super(props);

		this.state = {
			widgedInEditMode: false,
			inputData: [],
			currentSelectedRowData: null,
			visible: true,
			canEditRow: false,
			inputTemplates: [],
		};
		this.templatesData = new Map<SensorTypeEnum, TemplateListItem[]>();
	}

	componentDidMount() {
		this.bindDataSource(this.props.entityId, true);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.entityId !== this.props.entityId) {
			this.closeEditPanelMode();
		}
	}

	public async bindDataSource(deviceId: string, forceReload = false) {
		if (forceReload || this.currentDeviceId !== deviceId) {
			this.currentDeviceId = deviceId;

			const data = await ajaxUtil.get<OverviewResponseWrapperDto<DeviceSensorTemplateConnections>>(
				`${GlobalSettings.devicesMaintenanceApi}/${deviceId}/inputtemplates`
			);
			const connectedObjectId = await ajaxUtil.get<string>(
				`${GlobalSettings.connectionsApi}/GetDeviceConnectedObjectId/${deviceId}`
			);

			this.setState({
				inputTemplates: data.items,
				connectedObjectId: connectedObjectId,
			});
		}
	}

	async fillTemplatesList(deviceSensorType: DeviceSensorTypeDto) {
		await ajaxUtil
			.post<TemplateListItem[]>(
				`${GlobalSettings.sensorTemplatesMaintenanceApi}/GetTemplatesForDevice`,
				deviceSensorType
			)
			.then((templates: TemplateListItem[]) => {
				const templatesMap = new Map<SensorTypeEnum, TemplateListItem[]>();
				templatesMap.set(deviceSensorType.sensorType, templates);
				this.templatesData = templatesMap;
			});
	}

	async reloadData() {
		this.templatesData.clear();
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.widgedInEditMode;
		this.props.editModeCallback(currentValueFlipped);
		this.setState({
			widgedInEditMode: currentValueFlipped,
		});
	}

	closeEditPanelMode() {
		if (this.state.widgedInEditMode) {
			this.hasUnsavedData = false;
			this.toggleEditMode();
		}
	}

	//TODO: remove setTimeout and templateConnectionRef | replace with state management for visible and mode and send them to InputTemplateConnectionDialog
	async openEditDialog(mode: DialogMode, rowData: DeviceSensorTemplateConnections) {
		await this.fillTemplatesList({
			deviceId: rowData.deviceId,
			sensorType: rowData.sensorType,
			sensorId: rowData.sensorId,
		} as DeviceSensorTypeDto);
		this.setState({ currentSelectedRowData: rowData });
		setTimeout(() => {
			this.templateConnectionRef.showDialog(mode);
		}, 100);
	}

	columnLinkTemplate(rowData: DeviceSensorTemplateConnections) {
		let templateType: TemplateTypesEnum = TemplateTypesEnum.Unknown;

		switch (rowData.templateType) {
			case SensorTypeEnum.Event:
				templateType = TemplateTypesEnum.Event;
				break;
			case SensorTypeEnum.Duration:
				templateType = TemplateTypesEnum.Duration;
				break;
			case SensorTypeEnum.Analog:
				templateType = TemplateTypesEnum.Analog;
				break;
			case SensorTypeEnum.Digital:
				templateType = TemplateTypesEnum.Digital;
				break;
		}

		if (
			rowData.templateType === SensorTypeEnum.Event &&
			!(this.props.tripTypes & TripTypesEnum.Private) &&
			rowData.eventAction === TemplateEventActionEnum.PrivateBusiness
		) {
			return <span title={rowData.templateName}>{rowData.templateName}</span>;
		}

		return (
			<a
				title={rowData.templateName}
				href={`${GlobalSettings.route.templates}/${rowData.templateId}/${templateType}`}
			>
				{rowData.templateName}
			</a>
		);
	}

	getColumns() {
		return [
			<Column
				key="sensorCode"
				field="sensorCode"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colInput')}
				style={{ width: 150 }}
				body={(rowData: DeviceSensorTemplateConnections) => {
					//always translate sensor code
					const sensorTranslation = TranslateText(`widgets.sensors.${rowData.sensorCode}`);
					return sensorTranslation !== `widgets.sensors.${rowData.sensorCode}`
						? sensorTranslation
						: rowData.sensorCode;
				}}
			/>,
			<Column
				key="templateName"
				field="templateName"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colTemplateName')}
				style={{ width: 150 }}
				body={this.props.allowEditMode ? this.columnLinkTemplate.bind(this) : null}
			/>,
			<Column
				key="templateRelevantValue"
				field="templateRelevantValue"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colValue')}
				style={{ width: 100 }}
				body={(rowData: DeviceSensorTemplateConnections) => {
					if (rowData.templateRelevantValue === null || rowData.templateRelevantValue === undefined) {
						return '';
					}
					return rowData.templateRelevantValue
						? TranslateText('fields.template.stateHigh')
						: TranslateText('fields.template.stateLow');
				}}
			/>,
			this.state.widgedInEditMode && (
				<Column
					key="buttons"
					className="widget-grid-column"
					style={{ width: '100px' }}
					body={(rowData: DeviceSensorTemplateConnections) => (
						<>
							<Button
								icon="e-flat link"
								className="p-button-rounded p-button-text p-button-plain removable-shadow"
								onClick={() => this.openEditDialog(DialogMode.Link, rowData)}
								disabled={
									this.state.inputTemplates?.length === 0 ||
									!!this.state.inputTemplates.find((t) => t.sensorId === rowData.sensorId)
										?.connectionId
								}
							/>
							<Button
								icon="pi pi-pencil"
								className="p-button-rounded p-button-text p-button-text removable-shadow"
								onClick={() => this.openEditDialog(DialogMode.Edit, rowData)}
							/>
						</>
					)}
				/>
			),
		];
	}

	getTemplatesData(): TemplateListItem[] {
		const sensorType: SensorTypeEnum = this.state.currentSelectedRowData.sensorType;
		const connectedTemplatesId: string[] = this.state.inputTemplates
			.filter(
				(t) =>
					t.sensorId !== this.state.currentSelectedRowData.sensorId &&
					t.sensorType === sensorType &&
					t.templateId
			)
			.map((t) => t.templateId);

		let templateData: TemplateListItem[] = this.templatesData.get(sensorType);
		if (templateData) {
			templateData = templateData.filter((t) => connectedTemplatesId.every((ct) => ct !== t.id));

			if (!(this.props.tripTypes & TripTypesEnum.Private)) {
				templateData = templateData.filter(
					(t) =>
						t.id === this.state.currentSelectedRowData.templateId ||
						t.eventAction !== TemplateEventActionEnum.PrivateBusiness
				);
			}
		} else {
			templateData = [];
		}
		return templateData;
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="5"
				data-minsizey="3"
			>
				{this.state.currentSelectedRowData !== null ? (
					<InputTemplateConnectionDialog
						savedCallback={() => this.bindDataSource(this.props.entityId, true)}
						closedCallback={() => this.setState({ currentSelectedRowData: null })}
						templatesData={this.getTemplatesData()}
						deviceId={this.props.entityId}
						getAccessTokenCallback={() => this.props.getAccessTokenCallback()}
						inputTemplateData={this.state.currentSelectedRowData}
						ref={(dialog) => {
							this.templateConnectionRef = dialog;
						}}
						deviceType={this.state.currentSelectedRowData.sensorType}
						connectedObjectId={this.state.connectedObjectId}
					/>
				) : null}
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('widgets.deviceSensorTemplate')}
							showEditButton={ClaimUtil.validateClaimList(this.props.user, [
								{ claim: ClaimType.Templates, values: [ClaimValue.edit] },
								{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
								{ claim: ClaimType.DeviceSensorTemplateConnection, values: [ClaimValue.edit] },
							])}
							editMode={this.state.widgedInEditMode}
							allowEditMode={this.props.allowEditMode || this.state.widgedInEditMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditMode()}
						/>
					</div>
					<div className="e-panel-content">
						<div id="gridHostInput" className="grid-host">
							<GridWidgetOverview
								columns={this.getColumns()}
								data={this.state.inputTemplates}
								total={this.state.inputTemplates?.length ?? 0}
								paginator={true}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connector(DeviceTemplateWidget);
