const initialState = {
	displayWizard: false,
	newPerson: {},
	newPersonPresent: false,
};

export interface ActionCreatorsSignature {
	showWizard: () => void;
	closeWizard: () => void;
	personAdded: (person: {}) => void;
	resetNewPerson: () => void;
}

export const addWizardActionCreators = {
	showWizard: () => (dispatch: any) => {
		const show = true;
		dispatch({ type: 'SHOW_WIZARD', show });
	},
	closeWizard: () => (dispatch: any) => {
		const show = false;
		dispatch({ type: 'CLOSE_WIZARD', show });
	},
	personAdded: (newPerson: {}) => (dispatch: any) => {
		dispatch({ type: 'PERSON_ADDED', newPerson });
	},
	resetNewPerson: () => (dispatch: any) => {
		const newPerson = {};
		dispatch({ type: 'RESET_PERSON_ADDED', newPerson });
	},
};

export const addWizardReducer = (state: any, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case 'SHOW_WIZARD': {
			return {
				...state,
				displayWizard: action.show,
			};
		}
		case 'CLOSE_WIZARD': {
			return {
				...state,
				displayWizard: action.show,
			};
		}
		case 'PERSON_ADDED': {
			return {
				...state,
				newPersonPresent: true,
				newPerson: action.newPerson,
			};
		}
		case 'RESET_PERSON_ADDED': {
			return {
				...state,
				newPersonPresent: false,
				newPerson: action.newPerson,
			};
		}
		default: {
			return state;
		}
	}
};
