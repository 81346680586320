import AlertMessagesWidget from 'components/Widgets/AlertMessagesWidget';
import ButtonWidget from 'components/Widgets/ButtonWidget';
import { AddEntityToAlertButton } from 'components/Widgets/ButtonWidget/AddNewAlertEntityButton';
import { AddNewAlertLocationButton } from 'components/Widgets/ButtonWidget/AddNewAlertLocationButton';
import { AlertEventView } from 'components/Widgets/Views/AlertEventView';
import { AlertLocationsView } from 'components/Widgets/Views/AlertLocationsView';
import { AlertRecipientsView } from 'components/Widgets/Views/AlertRecipientsView';
import { AlertTimeScheduleView } from 'components/Widgets/Views/AlertTimeScheduleView';
import Widget from 'components/Widgets/Widget';
import AlertTypesEnum from 'models/AlertTypesEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { bindActionCreators, Dispatch } from 'redux';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { enableRipple } from '@syncfusion/ej2-base';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import * as GlobalSettings from '../../GlobalSettings.json';
import Alert from '../../models/Alert';
import IdIdentifiable from '../../models/IdIdentifiable';
import NotificationPrompt from '../../shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState } from '../../store';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import BaseDetails from '../BaseDetails';
import { BaseDetailsProps, BaseDetailsState } from '../BaseDetails/BaseDetails';
import DetailsNavigationBar from '../DetailsNavigationBar/DetailsNavigationBar';
import AlertInformationWidget from '../Widgets/AlertInformationWidget';
import AddNewAlertRecipientButton from '../Widgets/ButtonWidget/AddNewAlertRecipientButton/AddNewAlertRecipientButton';
import { IButtonWidgetView } from '../Widgets/ButtonWidget/ButtonWidget';
import { AlertEntitiesView } from '../Widgets/Views/AlertEntitiesView';

enableRipple(true);

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		editingInstance: state.loadedEntityContext.editingInstance,
		customerId: state.loadedEntityContext.entityContextData.customerId,
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterText: state.globalCustomer.filterText.alerts,
		user: state.oidc.user,
		gridFilters: state.gridOverview[EntityTypeEnum.Alert].gridFilters,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadedEntityContextActions: bindActionCreators(loadedEntityContextActionCreators, dispatch),
		availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		setUnsavedDataInStore: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

type MatchProps = {
	match: { params: { [key: string]: string } };
};

const connector = connect(mapState, mapDispatchToProps);
//Extract type from connector
type PropsFromRedux = ConnectedProps<typeof connector>;

type AlertDetailsProps = BaseDetailsProps & PropsFromRedux & MatchProps & {};

type AlertDetailsState = BaseDetailsState & {
	showAlertEdit: boolean;
	showEditEntities: boolean;
	showEditMessages: boolean;
	alertType: AlertTypesEnum | null;
};

class AlertDetails extends BaseDetails<AlertDetailsProps, AlertDetailsState> {
	constructor(props: AlertDetailsProps) {
		super(props);

		this.state = {
			...this.state,
			currentRecordId: props.match.params.id,
			...this.getEditRights(),
			alertType: null,
		};
	}

	alertInformationWidgetRef: AlertInformationWidget;

	componentDidMount() {
		this.props.availableCustomersActions.toggleSelection(false);
	}

	componentDidUpdate(prevProps: AlertDetailsProps) {
		if (this.props.user !== prevProps.user) {
			this.setState(this.getEditRights());
		}
	}

	componentWillUnmount() {
		this.loadedWidgets = [];
		this.props.loadedEntityContextActions.setEditingInstance(null);
		this.props.loadedEntityContextActions.setLoadedAlertContext(null);
	}

	displayCallback(alert: Alert) {
		this.setState({
			breadcrumbDisplay: TranslateText('common.alerts') + ' > ' + alert.name,
			alertType: alert.alertType,
		});

		this.props.loadedEntityContextActions.setLoadedAlertContext(alert);
	}

	getEditRights() {
		//AlertEdit
		const showAlertEdit = ClaimUtil.validateClaimList(this.props.user, [
			{
				claim: ClaimType.Alerts,
				values: [ClaimValue.edit],
			},
		]);

		//Entities
		const showEditEntities = ClaimUtil.validateClaimList(this.props.user, [
			{
				claim: ClaimType.Alerts,
				values: [ClaimValue.edit],
			},
		]);

		//Messages
		const showEditMessages =
			ClaimUtil.validateClaimList(this.props.user, [
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.edit],
				},
			]) &&
			ClaimUtil.validateOptionalClaimList(this.props.user, [
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.notificationAlert],
				},
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.mailAlert],
				},
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.smsAlert],
				},
				{
					claim: ClaimType.Alerts,
					values: [ClaimValue.whatsAppAlert],
				},
			]);

		return {
			showAlertEdit,
			showEditEntities,
			showEditMessages,
		};
	}

	protected fillInformationWidget(recordId: string) {
		this.alertInformationWidgetRef.fillAlert(recordId);
	}

	protected closeEditModeOnInformationWidget() {
		this.alertInformationWidgetRef.closeEditPanelMode();
		this.props.loadedEntityContextActions.setEditingInstance(null);
	}

	protected handleGoBackTo(show: boolean, syncRecord: IdIdentifiable, refreshGrid: boolean) {
		this.loadedWidgets = [];
		this.props.history.push('/alerts', { id: syncRecord.id });
	}

	render() {
		return (
			<div className="content-container">
				<NotificationPrompt
					title={TranslateText('common.titleUnsavedData')}
					message={TranslateText('notificationMessages.cancel')}
					handleUserResponse={this.handleUnsavedDataResponse.bind(this)}
					displayDialog={this.state.notifyAboutUnsavedData}
				/>
				<DetailsNavigationBar
					history={this.props.history}
					currentRecordId={this.props.match.params.id}
					goBackText={TranslateText('detailsScreen.navigationBar.backToAlerts')}
					goBackToCallback={this.handleGoBackTo.bind(this)}
					breadcrumbsText={this.state.breadcrumbDisplay}
					navigationUrl={`${GlobalSettings.alertsApi}/navigation`}
					detailUrl={GlobalSettings.route.alerts}
					filter={{
						customerId: this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId,
						filterText: this.props.filterText,
						gridFilters: this.props.gridFilters,
					}}
					entityType={EntityTypeEnum.Alert}
				/>
				<Scrollbar
					style={{
						maxHeight: 'calc(100vh - 110px)',
						minHeight: 'calc(100vh - 110px)',
					}}
					native={true}
				>
					<DashboardLayoutComponent
						immediateRender={true}
						draggableHandle=".dragicon"
						mediaQuery={'max-width: 1100px'}
						allowResizing={true}
						allowFloating={true}
						allowPushing={true}
						columns={20}
						cellAspectRatio={95 / 100}
						ref={(scope) => {
							this.dashboardObj = scope;
						}}
						id="predefine_dashboard"
						cellSpacing={this.cellSpacing}
					>
						<AlertInformationWidget
							customerId={this.props.customerId}
							id="alertInformation"
							row="0"
							col="0"
							sizeX="4"
							sizeY="5"
							entityId={this.state.currentRecordId}
							editingInstance={this.props.editingInstance}
							setEditingInstance={this.props.loadedEntityContextActions.setEditingInstance}
							savedCallback={this.handleSavedCallback.bind(this)}
							notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
							displayCallback={this.displayCallback.bind(this)}
							changeDataCallback={this.changeDataCallback.bind(this)}
							getAccessTokenCallback={() => this.props.access_token}
							ref={(scope) => {
								this.alertInformationWidgetRef = scope;
								this.AddLoadedWidget(scope);
							}}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							showEditButton={this.state.showAlertEdit}
						/>
						<ButtonWidget
							position={{
								row: 0,
								col: 4,
								sizeX: 4,
								sizeY: 5,
							}}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							entityId={this.state.currentRecordId}
							widgetTitle={TranslateText('alert.entities')}
							changeDataCallback={this.changeDataCallback.bind(this)}
							viewComponent={(props: IButtonWidgetView) => (
								<AlertEntitiesView
									{...props}
									getUrl={`${GlobalSettings.alertsApi}/${props.entityId}/information`}
									updateUrl={`${GlobalSettings.alertsApi}`}
								/>
							)}
							buttons={[AddEntityToAlertButton]}
							showEditButton={this.state.showEditEntities}
						/>
						<ButtonWidget
							position={{
								row: 0,
								col: 8,
								sizeX: 4,
								sizeY: 5,
							}}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							entityId={this.state.currentRecordId}
							widgetTitle={TranslateText('alert.persons')}
							changeDataCallback={this.changeDataCallback.bind(this)}
							viewComponent={(props: IButtonWidgetView) => (
								<AlertRecipientsView
									{...props}
									getUrl={`${GlobalSettings.alertsApi}/${props.entityId}/information`}
									updateUrl={`${GlobalSettings.alertsApi}`}
								/>
							)}
							buttons={[AddNewAlertRecipientButton]}
							showEditButton={this.state.showAlertEdit}
						/>
						<AlertEventView
							customerId={this.props.customerId}
							id="alertEvent"
							row="0"
							col="12"
							sizeX="4"
							sizeY="5"
							entityId={this.state.currentRecordId}
							editingInstance={this.props.editingInstance}
							setEditingInstance={this.props.loadedEntityContextActions.setEditingInstance}
							savedCallback={this.handleSavedCallback.bind(this)}
							notifyAboutUnsavedData={this.notifyAboutUnsavedData.bind(this)}
							displayCallback={this.displayCallback.bind(this)}
							changeDataCallback={this.changeDataCallback.bind(this)}
							getAccessTokenCallback={() => this.props.access_token}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							showEditButton={this.state.showAlertEdit}
						/>
						<AlertMessagesWidget
							position={{
								row: 5,
								col: 0,
								sizeX: 8,
								sizeY: 5,
							}}
							entityId={this.state.currentRecordId}
							widgetTitle={TranslateText('alert.message')}
							editModeCallback={this.editModeCallback.bind(this)}
							changeDataCallback={this.changeDataCallback.bind(this)}
							allowEditMode={!this.state.widgetEditing}
							showEditButton={this.state.showEditMessages}
						/>
						<ButtonWidget
							position={{
								row: 5,
								col: 8,
								sizeX: 4,
								sizeY: 5,
							}}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							entityId={this.state.currentRecordId}
							widgetTitle={TranslateText('alert.locations')}
							changeDataCallback={this.changeDataCallback.bind(this)}
							hidden={this.state.alertType !== AlertTypesEnum.Geofence}
							url={
								this.state.alertType === AlertTypesEnum.Geofence
									? `${GlobalSettings.alertsApi}/getGeofenceAlertLocations`
									: undefined
							}
							viewComponent={AlertLocationsView}
							buttons={[AddNewAlertLocationButton]}
							showEditButton={this.state.showAlertEdit}
						/>

						<Widget
							position={{
								row: 5,
								col: 12,
								sizeX: 4,
								sizeY: 5,
							}}
							hidden={this.state.alertType !== AlertTypesEnum.Geofence}
							widgetTitle={TranslateText('alert.timeSchedule')}
							allowEditMode={!this.state.widgetEditing}
							editModeCallback={this.editModeCallback.bind(this)}
							entityId={this.state.currentRecordId}
							url={
								this.state.alertType === AlertTypesEnum.Geofence
									? `${GlobalSettings.alertsApi}/geofenceAlertTimeSchedule`
									: undefined
							}
							viewComponent={AlertTimeScheduleView}
							changeDataCallback={this.changeDataCallback.bind(this)}
							showEditButton={this.state.showAlertEdit}
						/>
					</DashboardLayoutComponent>
				</Scrollbar>
			</div>
		);
	}
}

export default connector(AlertDetails);
