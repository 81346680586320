import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { ButtonConfig } from 'components/RadioButtonContainer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetEntityTypeFilter } from 'store/FleetSelection';
import TreeConfigurationUtil from 'utils/TreeSelection/TreeConfigurationUtil';

import { TranslateText } from '../Translations';

const useGetPlaceholderAndButtons = () => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const hasGroupAuthorisation: boolean = useSelector(
		(state: ApplicationState) => state.currentSession.hasGroupAuthorisation
	);
	const driverIdentification = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: state.currentSession.customer?.featuresSettings.driverIdentification
	);

	const [filterPlaceholder, setFilterPlaceholder] = useState<string>('');
	const [filterButtons, setFilterButtons] = useState<ButtonConfig[]>([]);

	useEffect(() => {
		const hasPersonClaim: boolean = ClaimUtil.validateHasClaim(user, ClaimType.Persons);
		const hasAllEntitiesClaim: boolean = ClaimUtil.validateHasClaim(user, ClaimType.AllEntities);

		if (driverIdentification && hasPersonClaim && (hasAllEntitiesClaim || hasGroupAuthorisation)) {
			setFilterPlaceholder(TranslateText('fleetSelection.searchPersonDriverPlaceholder'));
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.all,
					FleetEntityTypeFilter.driver,
					FleetEntityTypeFilter.person,
				])
			);

			return;
		}
		if (driverIdentification) {
			setFilterPlaceholder(TranslateText('fleetSelection.searchDriverPlaceholder'));
			return;
		}

		if (hasPersonClaim) {
			setFilterPlaceholder(TranslateText('fleetSelection.searchPersonPlaceholder'));
			return;
		}

		return () => {
			setFilterPlaceholder('');
			setFilterButtons([]);
		};
	}, [driverIdentification, user]);

	return { filterPlaceholder, filterButtons, driverIdentification, user };
};

export { useGetPlaceholderAndButtons };
