import BaseConnection from './BaseConnection';

class DeviceSimCardConnection extends BaseConnection {
	constructor(copyOf?: DeviceSimCardConnection) {
		if (copyOf) {
			super(copyOf);
			this.deviceId = copyOf.deviceId;
			this.deviceCode = copyOf.deviceCode;
			this.deviceSerialNumber = copyOf.deviceSerialNumber;
			this.simCardId = copyOf.simCardId;
			this.simCardCode = copyOf.simCardCode;
		} else {
			super(null);
		}
	}

	public deviceId: string;
	public deviceCode: string;
	public deviceSerialNumber: string;
	public simCardId: string;
	public simCardCode: string;
}

export default DeviceSimCardConnection;
