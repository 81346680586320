import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AlertTypesEnum from 'models/AlertTypesEnum';
import AnalogAlertMetadata from 'models/AnalogAlertMetadata';
import { useEffect, useMemo, useState } from 'react';
import { ValidateResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';
import MainPowerAlertMetadata from '../../../../../models/MainPowerAlertMetadata';
import MainPowerEventTypesEnum from '../../../../../models/MainPowerEventTypesEnum';

const useMainPowerAlerts = (alertType: AlertTypesEnum, mainPowerAlertMetadata?: MainPowerAlertMetadata) => {
	const [validationResult, setValidationResult] = useState<ValidateResult>();
	const validator = useMemo(
		() =>
			alertType === AlertTypesEnum.MainPower
				? new Validator(
						{
							fieldRules: {
								mainPowerTriggerEvent: {
									rules: {
										requiredConditionally: {
											message: TranslateText('fieldsValidations.fieldRequired'),
											validationFunction: (data: any) => {
												const alertData = data as MainPowerAlertMetadata;
												return ValidatorFunctions.wrapInPromise(
													!!alertData.mainPowerTriggerEvent ||
														alertData.mainPowerTriggerEvent === MainPowerEventTypesEnum.Both
												);
											},
										},
									},
								},
							},
						},
						true
				  )
				: new Validator({ fieldRules: {} }),
		[alertType]
	);

	useEffect(() => {
		if (alertType === AlertTypesEnum.MainPower && mainPowerAlertMetadata) {
			validator.validate(mainPowerAlertMetadata).then((result) => {
				setValidationResult(result);
			});
		}
	}, [mainPowerAlertMetadata]);

	return validationResult;
};

export { useMainPowerAlerts };
