import { Checkbox } from '@material-ui/core';
import React from 'react';
import { useDebounceCallback } from 'utils/hooks';

import { DebouncedCheckboxProps } from './types';

const DebouncedCheckbox = ({ debounceTime, onChange, ...rest }: DebouncedCheckboxProps): JSX.Element => {
	const debouncedCall = useDebounceCallback(onChange, debounceTime);

	return <Checkbox data-testid="debounced-checkbox-testid" onChange={debouncedCall} {...rest} />;
};

export default DebouncedCheckbox;
