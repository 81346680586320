import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import { TranslateText, TranslateTextInterpolated } from '../../utils/Translations';
import { ImpersonationDialogProps } from './types';

const ImpersonationPopUp = (props: ImpersonationDialogProps) => {
	return (
		<Dialog fullWidth open={true}>
			<DialogTitle>{TranslateText('impersonation.dialogTitle')}</DialogTitle>
			<DialogContent className="impersonation-dialog">
				<div className="impersonation-message">
					{TranslateTextInterpolated('impersonation.dialogMessage', [
						`${props.dialogData.firstName} ${props.dialogData.lastName}`,
					])}
				</div>
				<div className="link-container">
					<div className="link">{props.dialogData.impersonationLink}</div>
					<div className="copy-button-container">
						<Tooltip title={TranslateText('common.copy')}>
							<IconButton
								size="small"
								onClick={(event) => {
									event.stopPropagation();
									navigator.clipboard.writeText(props.dialogData.impersonationLink);
								}}
							>
								<Icon>content_copy</Icon>
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setDialogData(null);
					}}
				>
					{TranslateText('common.buttonClose')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImpersonationPopUp;
