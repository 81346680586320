import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import CountrySelection from 'components/Common/CommonDataSelections/CountrySelection';
import LanguageSelection from 'components/Common/CommonDataSelections/LanguageSelection';
import TimezoneSelection from 'components/Common/CommonDataSelections/TimezoneSelection';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { PRODUCTION_ENVIRONMENT } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import Customer from 'models/Customer';
import StartPageEnum from 'models/StartPageEnum';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState, CustomerFeaturesState } from 'store';
import { loadedEntityContextActionCreators } from 'store/LoadedEntityContextData';
import ajaxUtil from 'utils/Ajax';
import { getStartPages, StartPage } from 'utils/StartPageUtils';
import { TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import { WidgetViewDefault } from './Widget';

//extend with custom props
type Props = WidgetViewDefault & {
	data: Customer;
	initialData: Customer;
	//Any other custom props
};

type ChangeValue = string | number | boolean | StartPageEnum;
type ChangeKey =
	| 'code'
	| 'description'
	| 'name'
	| 'languageId'
	| 'timezoneId'
	| 'startCountryId'
	| 'startPage'
	| 'active'
	| 'isTest';

const GetCustomerInformationValidationConfig = (): FieldRules => {
	const fieldRules: FieldRules = {
		code: {
			rules: {
				required: ValidatorFunctions.required(),
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
				maxLength: ValidatorFunctions.maxLength(40),
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data, fieldName) => {
						const customerData = data as Customer;
						if (!data[fieldName]) {
							return ValidatorFunctions.wrapInPromise(true);
						}

						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/CustomerFields`, {
							value: data[fieldName],
							Field: 'code',
							editedEntityId: customerData.id,
						});
					},
				},
			},
		},
		name: {
			rules: {
				required: ValidatorFunctions.required(),
				noEmptySpace: ValidatorFunctions.noEmptySpace(),
				maxLength: ValidatorFunctions.maxLength(200),
			},
		},
		description: {
			rules: {
				noEmptySpace: ValidatorFunctions.noEmptySpace(TranslateText('fieldsValidations.invalidValue')),
				maxLength: ValidatorFunctions.maxLength(200),
			},
		},
		languageId: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		startCountryId: {
			rules: { required: ValidatorFunctions.required() },
		},
		startPage: {
			rules: { required: ValidatorFunctions.required() },
		},
		timezoneId: {
			rules: { required: ValidatorFunctions.required() },
		},
	};

	return fieldRules;
};

const CustomerInformationView = (props: Props) => {
	const dispatch = useDispatch();

	const [startPageDataSource, setStartPageDataSource] = useState<StartPage[]>([]);
	const currentSessionCustomerId = useSelector((state: ApplicationState) => state.currentSession.customerId);
	const customerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);

	const setStartPages = () => {
		const url = `${GlobalSettings.customersMaintenanceApi}/features/${props.entityId}`;
		ajaxUtil.get<CustomerFeaturesState>(url).then((data) => {
			const pages = getStartPages(data);
			pages.push({
				id: StartPageEnum.WhitePage,
				display: TranslateText(`customerOverview.startPage.${StartPageEnum.WhitePage}`),
			});

			setStartPageDataSource(pages);
		});
	};

	useEffect(() => {
		if (props.initialData) {
			if (props.setDashboardTitleCallback) {
				props.setDashboardTitleCallback(props.initialData.name);
			}
			dispatch(loadedEntityContextActionCreators.setLoadedCustomerContext(props.initialData as Customer));
			setStartPages();
		}
	}, [props.initialData]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: GetCustomerInformationValidationConfig() }));
	}, [props.entityId]);

	const events = {
		handleValueChange: (value: ChangeValue, key: ChangeKey) => {
			const newState = { ...props.data };
			if (key === 'startCountryId') {
				newState[key] = value as number;
			} else if (key === 'startPage') {
				newState[key] = value as StartPageEnum;
			} else if (key === 'active' || key === 'isTest') {
				newState[key] = value as boolean;
			} else {
				newState[key] = value as string;
			}

			props.changeDataCallback(newState);
		},
		confirmChangesOnAllUsers: (value: ChangeValue, key: ChangeKey) => {
			setPopupConfirmation({
				userResponse: (response: boolean) => {
					if (response) {
						events.handleValueChange(value, key);
					}
					setPopupConfirmation(null);
				},
			});
		},
	};

	const [expandedSection, setExpandedSection] = useState<number>(0);

	const [popupConfirmation, setPopupConfirmation] = useState<{
		userResponse: (response: boolean) => void;
	} | null>();

	const showIsTestCheckBox =
		customerLevel === CustomerLevelEnum.OEM && process.env.REACT_APP_ENV !== PRODUCTION_ENVIRONMENT;

	return (
		<>
			<Accordion
				scrollToTop={props.scrollToTop}
				expandedCallback={(index) => {
					setExpandedSection(index);
				}}
			>
				<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.systemInformation')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<TextField
								id="code"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.code')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="code"
								value={props.data.code}
								onChange={(e) => {
									if (e.target) {
										events.handleValueChange(e.target.value, 'code');
									}
								}}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.code} />
							<FormControlLabel
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={props.data.active}
										readOnly={!props.editMode}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											events.handleValueChange(e.target.checked, 'active')
										}
										disabled={
											!props.editMode ||
											!props.data.canModifyActive ||
											props.entityId === currentSessionCustomerId
										}
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.active')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0, marginTop: 5 }}
							/>
							{showIsTestCheckBox && (
								<div>
									<FormControlLabel
										control={
											<Checkbox
												name="isTest"
												color={'primary'}
												checked={props.data.isTest}
												readOnly={!props.editMode}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													events.handleValueChange(e.target.checked, 'isTest')
												}
												disabled={!props.editMode}
											/>
										}
										label={
											<Typography style={{ fontSize: 12, marginRight: 5 }}>
												{TranslateText('fields.isTest')}
											</Typography>
										}
										labelPlacement="start"
										style={{ margin: 0, marginTop: 10 }}
									/>
								</div>
							)}
						</div>
					</div>
				</AccordionSection>
				<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.customerData')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<TextField
								id="name"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.name')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="name"
								value={props.data.name}
								onChange={(e) => {
									if (e.target) {
										events.handleValueChange(e.target.value, 'name');
									}
								}}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.name} />
						</div>
						<div className="form-group">
							<TextField
								id="description"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.description')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="description"
								value={props.data.description ?? ''}
								onChange={(e) => {
									if (e.target) {
										events.handleValueChange(e.target.value, 'description');
									}
								}}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.description} />
						</div>
						<div className="form-group">
							<LanguageSelection
								label={TranslateText('fields.language')}
								name="languageId"
								valueId={props.data.languageId}
								disabled={!props.editMode}
								onChange={({ value }) => events.handleValueChange(value, 'languageId')}
							/>
							<ValidationMessage result={props.validationResult?.languageId} />
						</div>
						<div className="form-group">
							<TimezoneSelection
								label={TranslateText('fields.timezone')}
								name="timezoneId"
								valueId={props.data.timezoneId}
								disabled={!props.editMode}
								onChange={({ value }) => events.handleValueChange(value, 'timezoneId')}
							/>
							<ValidationMessage result={props.validationResult?.timezoneId} />
						</div>
						<div className="form-group">
							<CountrySelection
								label={TranslateText('fields.startCountryCode')}
								name="startCountryId"
								valueId={props.data.startCountryId ? props.data.startCountryId.toString() : null}
								disabled={!props.editMode}
								onChange={({ value }) => events.confirmChangesOnAllUsers(value, 'startCountryId')}
							/>
							<ValidationMessage result={props.validationResult?.startCountryId} />
						</div>
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data.startPage}
								dataSource={startPageDataSource}
								name="startPage"
								disabled={!props.editMode}
								label={TranslateText('fields.startPage')}
								onChange={({ value }) => events.confirmChangesOnAllUsers(value, 'startPage')}
							/>
							<ValidationMessage result={props.validationResult?.startPage} />
						</div>
					</div>
				</AccordionSection>
			</Accordion>
			{popupConfirmation ? (
				<NotificationPrompt
					title={TranslateText('common.titleSavedData')}
					message={TranslateText('notificationMessages.save')}
					handleUserResponse={popupConfirmation.userResponse}
				/>
			) : null}
		</>
	);
};

export default CustomerInformationView;
