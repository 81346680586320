const ValidationRegex = {
	noEmptySpace(): RegExp {
		return /(?!^ +$)^.+$/gi;
	},
	onlyZeros(): RegExp {
		return /^0+$/;
	},
	whiteSpacesInBetween(): RegExp {
		return /\s/;
	},
	onlyDigits(): RegExp {
		return /^[0-9]*$/;
	},
	onlyIntegerDigits(): RegExp {
		return /^-?[0-9]*$/;
	},
	email(): RegExp {
		return /^[A-Za-z0-9._%+-]{1,}@[A-Za-z0-9._%+-]{1,}([.]{1}[a-zA-Z0-9]{2,}|[.]{1}[a-zA-Z0-9]{2,4}[.]{1}[a-zA-Z0-9]{2,4})$/;
	},
	onlyAlphaNumeric(): RegExp {
		return /^[a-zA-Z0-9_-]{1,50}$/;
	},
	noZeroAsFirstDigit(): RegExp {
		return /^[1-9][0-9]*$/;
	},
};

export { ValidationRegex };
