import { ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AlertDigitalTriggerConditionEnum from 'models/AlertDigitalTriggerConditionEnum';
import GeofenceTypeEnum from 'models/GeofenceTypeEnum';
import { User } from 'oidc-client';

import ClaimType from '../authorization/ClaimType';
import GlobalSettings from '../GlobalSettings.json';
import EntityTypeEnum from '../models/EntityTypeEnum';
import EventFrequencyEnum from '../models/EventFrequencyEnum';
import { TranslateText } from './Translations';

export const convertToStringContentState = (bodyData: string) => {
	if (!bodyData) {
		return bodyData;
	}

	const contentHTML = htmlToDraft(bodyData);
	const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
	return JSON.stringify(convertToRaw(state));
};

export const getEntityLink = (
	user: User,
	entityId: string,
	entityType: EntityTypeEnum,
	driverIdentification: boolean,
	assetTracking: boolean
): string => {
	if (entityType === 'Driver') {
		if (user.profile[ClaimType.Drivers] && driverIdentification) {
			return `${GlobalSettings.route.drivers}/${entityId}`;
		}
		if (user.profile[ClaimType.Persons]) {
			return `${GlobalSettings.route.persons}/${entityId}`;
		}
		return null;
	}
	if (entityType === 'Person' && user.profile[ClaimType.Persons]) {
		return `${GlobalSettings.route.persons}/${entityId}`;
	}
	if (entityType === 'Group' && user.profile[ClaimType.Groups]) {
		return `${GlobalSettings.route.groups}/${entityId}`;
	}
	if (entityType === 'Object' && user.profile[ClaimType.Objects]) {
		return `${GlobalSettings.route.objects}/${entityId}`;
	}
	if (entityType === 'Asset' && assetTracking && user.profile[ClaimType.Objects]) {
		return `${GlobalSettings.route.objects}/${entityId}`;
	}

	return null;
};

export const getAlertFrequencyDescription = (alertEventFrequency: EventFrequencyEnum) => {
	switch (alertEventFrequency) {
		case 0:
			return 'Once';
		case 1:
			return 'Always';
		case 2:
			return 'Once An Hour';
		case 3:
			return 'Once A Day';
		case 4:
			return 'Once A Week';
		case 5:
			return 'Once A Month';
	}
};

export const getAnalogTriggerConditions = () => [
	{
		display: TranslateText('analogTriggerCondition.everyStateChange'),
		id: AlertAnalogTriggerConditionEnum.AnyStateChange,
	},
	{
		display: TranslateText('analogTriggerCondition.toSpecificState'),
		id: AlertAnalogTriggerConditionEnum.ToSpecificState,
	},
	{
		display: TranslateText('analogTriggerCondition.fromSpecificState'),
		id: AlertAnalogTriggerConditionEnum.FromSpecificState,
	},
];

export const getDigitalTriggerConditions = () => [
	{
		display: TranslateText('digitalTriggerCondition.everyChange'),
		id: AlertDigitalTriggerConditionEnum.AnyStateChange,
	},
	{
		display: TranslateText('digitalTriggerCondition.toRelevantState'),
		id: AlertDigitalTriggerConditionEnum.ToRelevantState,
	},
	{
		display: TranslateText('digitalTriggerCondition.fromRelevantState'),
		id: AlertDigitalTriggerConditionEnum.FromRelevantState,
	},
];

export const getGeofenceTypes = () => [
	{
		display: TranslateText('geofence.geofenceTypeEnum.In'),
		id: GeofenceTypeEnum.In,
	},
	{
		display: TranslateText('geofence.geofenceTypeEnum.Out'),
		id: GeofenceTypeEnum.Out,
	},
	{
		display: TranslateText('geofence.geofenceTypeEnum.Both'),
		id: GeofenceTypeEnum.Both,
	},
];
