import './reportPeriodAutocomplete.scss';

import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import MaterialDatePicker from 'components/Common/DateTime/MaterialDatePicker';
import {ValidationMessage} from 'components/ValidationMessage/ValidationMessage';
import {getDefaultPeriodSelectionInReportDataSource} from 'Constants';
import ReportPeriodsEnum, {ReportPeriodType} from 'models/ReportPeriodsEnum';
import TemplateData from 'models/TemplateData';
import moment from 'moment';
import * as React from 'react';
import DateTimeUtil, {DateTimeConversionUtil} from 'shared/datetime/DateTimeUtil';
import {ValidationResult} from 'shared/validation/interfaces';
import {ValidationRulesUtil} from 'shared/validation/utils';
import Validator from 'shared/validation/Validator';
import {ValidatorFunctions} from 'shared/validation/ValidatorFunctions';
import {ReportsSettingsState} from 'store';
import {TranslateText} from 'utils/Translations';

interface Props {
	reportsSettings: ReportsSettingsState;
	defaultPeriodType: ReportPeriodType;
	templateDataType: TemplateData;
	defaultCustomPeriodStart: string | Date;
	defaultCustomPeriodEnd: string | Date;
	filledPeriodCallback: (periodType: ReportPeriodType, customDateInterval?: Date[]) => void;
	validationChangedCallback: (isValidPeriod: boolean) => void;
}

interface State {
	reportPeriodType: ReportPeriodType;
	customPeriodStart: Date;
	customPeriodEnd: Date;
	validationResult?: ValidationResult;
	isValid?: boolean;
}

function getPeriodSelectionInReportDataSource(templateDataType: TemplateData) {
	let values = getDefaultPeriodSelectionInReportDataSource();

	if(templateDataType === TemplateData.ObjectPosition ||
		templateDataType === TemplateData.DriverPosition ||
		templateDataType === TemplateData.ObjectRawMessage)
	{
		values = values.filter((x) => x.id !== ReportPeriodsEnum.ThisQuarter.value &&
			x.id !== ReportPeriodsEnum.LastQuarter.value &&
			x.id !== ReportPeriodsEnum.ThisMonth.value &&
			x.id !== ReportPeriodsEnum.LastMonth.value);

	}
	if(templateDataType === TemplateData.DriverRegistration ||
		templateDataType === TemplateData.ObjectRegistration)
	{
		values = values.filter((x) => x.id !== ReportPeriodsEnum.LastQuarter.value && x.id !== ReportPeriodsEnum.ThisQuarter.value)
	}

	return values;
}

class ReportPeriod extends React.PureComponent<Props, State> {
	periodTypes = getPeriodSelectionInReportDataSource(this.props.templateDataType);

	private defaultPeriodStart: Date;
	private defaultPeriodEnd: Date;
	validator: Validator;

	constructor(props: Props) {
		super(props);
		this.state = {
			customPeriodStart: new Date(this.props.defaultCustomPeriodStart),
			customPeriodEnd: new Date(this.props.defaultCustomPeriodEnd),
			reportPeriodType: this.periodTypes.some((x) => x.id.toLowerCase() === this.props.defaultPeriodType.value.toLowerCase())
									? this.props.defaultPeriodType
									:  this.periodTypes.some((x) => x.id.toLowerCase() === this.props.reportsSettings.defaultPeriodSelectionInReport.toLowerCase())
										? ReportPeriodsEnum.GetReportPeriodTypeByValue(this.props.reportsSettings.defaultPeriodSelectionInReport)
										: ReportPeriodsEnum.GetReportPeriodTypeByValue(this.periodTypes[0].id),
			isValid: null,
		};
		this.validator = new Validator(
			{
				fieldRules: {
					reportPeriodType: {
						rules: {
							required: ValidatorFunctions.required(),
							validPeriodLength: {
								message: TranslateText('fieldsValidations.maximumPeriodLength').replace(
									'{0}',
									this.getMaxPeriodOfDaysInReport().toString()
								),
								validationFunction: (data) => {
									const object = data as { reportPeriodType: ReportPeriodType };
									const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
										object.reportPeriodType.value
									);
									if (periodType === ReportPeriodsEnum.CustomPeriod) {
										return ValidatorFunctions.wrapInPromise(true);
									}
									return ValidatorFunctions.wrapInPromise(
										ValidationRulesUtil.numberRange(
											periodType.periodLength,
											1,
											this.getMaxPeriodOfDaysInReport()
										)
									);
								},
							},
						},
					},
					customPeriodSelection: {
						rules: {
							required: {
								message: TranslateText('fieldsValidations.fieldRequired'),
								validationFunction: (data) => {
									const object = data as { reportPeriodType: ReportPeriodType };
									const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
										object.reportPeriodType.value
									);
									if (periodType !== ReportPeriodsEnum.CustomPeriod) {
										return ValidatorFunctions.wrapInPromise(true);
									}

									return ValidatorFunctions.wrapInPromise(
										!this.isCustomPeriodSelectedAndEmpty(
											this.state.customPeriodStart,
											this.state.customPeriodEnd
										)
									);
								},
							},
							validPeriodLength: {
								message: TranslateText('fieldsValidations.maximumPeriodLength').replace(
									'{0}',
									this.getMaxPeriodOfDaysInReport().toString()
								),
								validationFunction: (data) => {
									const object = data as { reportPeriodType: ReportPeriodType };
									const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
										object.reportPeriodType.value
									);
									if (periodType !== ReportPeriodsEnum.CustomPeriod) {
										return ValidatorFunctions.wrapInPromise(true);
									}


									return ValidatorFunctions.wrapInPromise(this.differenceInDaysValidation());
								},
							},
							maxPeriod: {
								message: TranslateText('fieldsValidations.maximumPeriodLengthYears').replace('{0}', '1'),
								validationFunction: (data) => {
									const object = data as { reportPeriodType: ReportPeriodType };
									const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
										object.reportPeriodType.value
									);
									if (periodType !== ReportPeriodsEnum.CustomPeriod) {
										return ValidatorFunctions.wrapInPromise(true);
									}

									return ValidatorFunctions.wrapInPromise(this.maxPeriodForYears());
								},
							}
						},
					},
				},
			},
			true
		);
	}
	componentDidMount() {
		this.defaultPeriodStart = new Date();
		this.defaultPeriodEnd = new Date();
		this.defaultPeriodStart.setHours(0, 0, 0);
		this.defaultPeriodEnd.setHours(23, 59, 59);
		let performValidation = true;

		if (this.state.customPeriodStart === null && this.state.customPeriodEnd === null) {
			performValidation = false;
			this.setState({
				customPeriodStart: this.defaultPeriodStart,
				customPeriodEnd: this.defaultPeriodEnd,
			});
		}
		if (
			this.props.templateDataType === TemplateData.ObjectCurrentMileage ||
			this.props.templateDataType === TemplateData.CurrentObjectPosition
		) {
			performValidation = false;
			this.setState({
				customPeriodStart: this.defaultPeriodStart,
				customPeriodEnd: this.defaultPeriodEnd,
				reportPeriodType: ReportPeriodsEnum.Today,
			});
		}

		//if the state is changed don't validate, the validation will be made on componentDidUpdate
		if (performValidation) {
			this.validator.validate({ reportPeriodType: this.state.reportPeriodType }).then((result) => {
				this.setState({
					validationResult: result.validationResult,
					isValid: result.formResult,
				});
				this.props.validationChangedCallback(result.formResult);
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (
			this.state.reportPeriodType !== prevState.reportPeriodType ||
			this.state.customPeriodStart !== prevState.customPeriodStart ||
			this.state.customPeriodEnd !== prevState.customPeriodEnd
		) {
			this.props.filledPeriodCallback(this.state.reportPeriodType, [
				this.state.customPeriodStart,
				this.state.customPeriodEnd,
			]);

			this.validator.validate({ reportPeriodType: this.state.reportPeriodType }).then((result) => {
				if (this.state.isValid !== result.formResult) {
					this.props.validationChangedCallback(result.formResult);
				}

				this.setState({
					validationResult: result.validationResult,
					isValid: result.formResult,
				});
			});
		}
	}
	componentWillUnmount() {
		this.props.filledPeriodCallback(this.state.reportPeriodType, [
			this.state.customPeriodStart,
			this.state.customPeriodEnd,
		]);
	}

	getMaxPeriodOfDaysInReport(): number {
		if(this.props.templateDataType === TemplateData.ObjectPosition ||
			this.props.templateDataType === TemplateData.DriverPosition ||
		    this.props.templateDataType === TemplateData.ObjectRawMessage) {
			// limit the reporting period for position reports to a maximum of one month (7 days)
			return 7;
		}
		if (this.props.templateDataType === TemplateData.ObjectRegistration ||
			this.props.templateDataType === TemplateData.DriverRegistration
		)
		{
			// limit the reporting period for position reports to a maximum of one month (31 days)
			return 31;
		}
		if (this.props.templateDataType === TemplateData.MileageCO2) {
			// override the reporting period for this report to one year (365 days)
			return 365;
		}

		if(this.props.templateDataType === TemplateData.DurationSummary) {
			// override the reporting period for this report to one year (366 days)
			return 366;
		}

		return this.props.reportsSettings?.maxPeriodOfDaysInReport ?? 62;
	}
	maxPeriodForYears(): boolean {
		// limit for 1 full calendar year
		if(this.props.templateDataType === TemplateData.MileageCO2)
			return this.state.customPeriodStart.getFullYear() === this.state.customPeriodEnd.getFullYear();
		return true;
	}
	handleValueChange(arg: { value: Date }, statePropName: string) {
		let newState = { ...this.state };

		if (statePropName === 'reportPeriodType') {
			if (arg.value) {
				const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(arg.value?.toString());

				newState.reportPeriodType = periodType;
				newState.customPeriodStart = this.defaultPeriodStart;
				newState.customPeriodEnd = this.defaultPeriodEnd;
			}
		}
		else if (statePropName === 'customPeriodStart') {
			const maxPeriodDays = this.getMaxPeriodOfDaysInReport();
			const newFrom = moment(this.getPeriodStart(arg.value));
			let newTo = moment(this.state.customPeriodEnd);

			if (newTo < newFrom) {
				newTo.add(newFrom.diff(this.state.customPeriodStart, 'seconds'), 'seconds');
			} else if (newTo.diff(newFrom, 'seconds') / 24 / 60 / 60 > maxPeriodDays) {
				newTo = moment(newFrom);
				newTo.add(maxPeriodDays, 'day').subtract(1, 'second');
			}

			if (newTo > moment()) {
				newTo = moment();
				newTo.endOf('day');
			}
			if(this.props.templateDataType === TemplateData.MileageCO2 && newFrom.year() !== newTo.year())
			{
				newTo = moment().year(newFrom.year()).endOf('year');
			}

			newState.customPeriodStart = newFrom.toDate();
			newState.customPeriodEnd = newTo.toDate();
		} else if (statePropName === 'customPeriodEnd') {
			const maxPeriodDays = this.getMaxPeriodOfDaysInReport();
			const newTo = moment(this.getPeriodEnd(arg.value));
			let newFrom = moment(this.state.customPeriodStart);

			if (newTo < newFrom) {
				newFrom.add(newTo.diff(this.state.customPeriodEnd, 'seconds'), 'seconds');
			} else if (newTo.diff(newFrom, 'seconds') / 24 / 60 / 60 > maxPeriodDays) {
				newFrom = moment(newTo);
				newFrom.add(-maxPeriodDays, 'day').add(1, 'second');
			}
			if(this.props.templateDataType === TemplateData.MileageCO2 && newFrom.year() !== newTo.year())
			{
				newFrom = moment().year(newTo.year()).startOf('year');
			}
			newState.customPeriodStart = newFrom.toDate();
			newState.customPeriodEnd = newTo.toDate();
		} else {
			newState = { ...newState, [statePropName]: arg.value };
		}

		this.setState(newState);
	}

	getPeriodStart(value: string | Date) {
		const date = value as Date;
		if (
			date &&
			this.state.customPeriodStart &&
			(this.state.customPeriodStart.getFullYear() !== date.getFullYear() ||
				this.state.customPeriodStart.getMonth() !== date.getMonth() ||
				this.state.customPeriodStart.getDate() !== date.getDate())
		) {
			return new Date(date.setHours(0, 0, 0));
		}

		return date;
	}

	getPeriodEnd(value: string | Date) {
		const date = value as Date;
		if (
			date &&
			this.state.customPeriodEnd &&
			(this.state.customPeriodEnd.getFullYear() !== date.getFullYear() ||
				this.state.customPeriodEnd.getMonth() !== date.getMonth() ||
				this.state.customPeriodEnd.getDate() !== date.getDate())
		) {
			return new Date(date.setHours(23, 59, 59));
		}

		return date;
	}

	isCustomPeriodSelected(selectedReportPeriodsEnum: ReportPeriodType): boolean {
		return selectedReportPeriodsEnum === ReportPeriodsEnum.CustomPeriod;
	}

	isCustomPeriodSelectedAndEmpty(start: Date, end: Date): boolean {
		return start === null || end === null;
	}

	differenceInDaysValidation() {
		// Take the difference between the dates and divide by milliseconds per day.
		// Round to nearest whole number to deal with DST.
		if(this.props.templateDataType === TemplateData.MileageCO2)
			return true;
		if (this.state.customPeriodStart && this.state.customPeriodEnd) {
			const time =
				(this.state.customPeriodEnd.getTime() - this.state.customPeriodStart.getTime()) / (1000 * 60 * 60 * 24);
			const days = Math.ceil(Math.abs(time));
			return days <= this.getMaxPeriodOfDaysInReport();
		}
		return false;
	}

	getMaxDay() {
		// add 1 day to today
		const date = new Date(Date.now() + 3600 * 1000 * 24);
		date.setHours(23, 59, 59);
		return date;
	}

	render() {
		return (
			<div className="categories-container">
				<div className="e-text active-step-title">{'3.' + TranslateText('reports.period')}</div>
				<div className="e-text">{TranslateText('reports.periodDescription')}</div>

				<form
					id="reportPeriodForm"
					noValidate={true}
					style={{
						marginTop: 10,
					}}
				>
					<div className="form-group">
						<div className="control-pane">
							<div className="control-section">
								<div className="ddl-control-section">
									<MaterialAutocomplete
										className="autocomplete-max-width material-autocomplete report-selection-component"
										valueId={
											this.props.templateDataType === TemplateData.ObjectCurrentMileage ||
											this.props.templateDataType === TemplateData.CurrentObjectPosition
												? ReportPeriodsEnum.Today.value
												: this.state.reportPeriodType.value
										}
										dataSource={this.periodTypes}
										disabled={
											this.props.templateDataType === TemplateData.ObjectCurrentMileage ||
											this.props.templateDataType === TemplateData.CurrentObjectPosition
										}
										label={TranslateText('fields.period')}
										onChange={(args: any) => this.handleValueChange(args, 'reportPeriodType')}
										fullWidth={false}
										disableClearable
									/>
									<ValidationMessage result={this.state.validationResult?.reportPeriodType} />
								</div>
							</div>
						</div>
						<div
							hidden={this.state.reportPeriodType === ReportPeriodsEnum.CustomPeriod}
							id="errorReportPeriodType"
						/>
					</div>

					{this.isCustomPeriodSelected(this.state.reportPeriodType) ? (
						<>
							<div className="custom-period-container">
								<span className="label-span" key="from-span">
									{TranslateText('reports.from')}:
								</span>
								<MaterialDatePicker
									name="datePickerStart"
									showTime={true}
									date={this.state.customPeriodStart}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									maxDate={this.getMaxDay()}
									maxWidth={300}
									onDateChange={(date?: Date) => {
										this.handleValueChange({ value: date }, 'customPeriodStart');
									}}
									fullWidth={true}
									hiddenCloseButton={true}
								/>
								<span className="label-span label-picker-end" key="to-span">
									{TranslateText('reports.to')}:
								</span>
								<MaterialDatePicker
									name="datePickerEnd"
									showTime={true}
									date={this.state.customPeriodEnd}
									format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
										DateTimeUtil.dateTimeFormat(),
										true
									)}
									maxDate={this.getMaxDay()}
									maxWidth={300}
									onDateChange={(date?: Date) => {
										this.handleValueChange({ value: date }, 'customPeriodEnd');
									}}
									fullWidth={true}
									hiddenCloseButton={true}
								/>
							</div>
							<ValidationMessage result={this.state.validationResult?.customPeriodSelection} />
						</>
					) : null}
				</form>
			</div>
		);
	}
}

export default ReportPeriod;
