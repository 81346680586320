import './styles.scss';

import React, { useRef, useState } from 'react';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { TranslateText } from 'utils/Translations';

import { Button, ClickAwayListener, IconButton, LinearProgress, Popper } from '@material-ui/core';
import { Close, NotificationsNoneOutlined } from '@material-ui/icons';

import { AlertsNotificationItem } from '../AlertsNotificationItem';
import { useAlertRedirect, useAlertsNotification } from './hooks';
import { AlertsNotificationPopupProps } from './types';

const AlertsNotificationPopup = (props: AlertsNotificationPopupProps): JSX.Element => {
	const {
		notifications,
		notificationsLoading,
		removeAlertNotification,
		removeLoading,
		showCustomer,
	} = useAlertsNotification();

	const { notificationRedirect, redirectLoading, notificationPrompt } = useAlertRedirect(
		props.setOpened,
		props.setRedirectUrl
	);

	const [selectedNotification, setSelectedNotification] = useState('');

	return (
		<>
			<Popper
				className="alerts-notification-popper"
				open={true}
				anchorEl={props.anchorEl.current}
				placement="bottom-end"
			>
				<ClickAwayListener
					onClickAway={() => {
						if (!notificationPrompt) {
							props.setOpened(false);
						}
					}}
				>
					<div>
						{notificationsLoading || removeLoading || redirectLoading ? (
							<div className="loading-container">
								<LinearProgress className="linear-progress" />
							</div>
						) : null}

						<div className="alerts-notification-container">
							<div className="alerts-notification-header">
								<div className="header-text">{TranslateText('notifications.notifications')}</div>
								<IconButton
									className="close-button"
									size="small"
									onClick={() => props.setOpened(false)}
								>
									<Close />
								</IconButton>
							</div>

							{notifications?.length ? (
								<div className="notification-items">
									{notifications.map((notification) => (
										<AlertsNotificationItem
											key={notification.id}
											notification={notification}
											isSelected={selectedNotification === notification.id}
											showCustomer={showCustomer}
											setSelectedNotification={setSelectedNotification}
											removeAlertNotification={removeAlertNotification}
											setNotificationAsSeen={props.setNotificationAsSeen}
											notificationRedirect={notificationRedirect}
										/>
									))}
								</div>
							) : (
								<div className="no-notifications">
									<NotificationsNoneOutlined className="icon" />
									<div className="no-text">{TranslateText('notifications.noNotifications')}</div>
								</div>
							)}

							<div className="alerts-notification-footer">
								<Button
									variant="contained"
									disableElevation
									className="clear-all-button"
									disabled={!notifications?.length}
									onClick={() => removeAlertNotification(notifications.map((n) => n.id))}
								>
									{TranslateText('notifications.clearAllNotifications')}
								</Button>
							</div>
						</div>
					</div>
				</ClickAwayListener>
			</Popper>
			{notificationPrompt ? (
				<NotificationPrompt
					title={notificationPrompt.title}
					message={notificationPrompt.message}
					handleUserResponse={notificationPrompt.handleUserResponse}
					showCancelButton={notificationPrompt.showCancelButton}
					displayDialog={true}
				/>
			) : null}
		</>
	);
};

export default AlertsNotificationPopup;
