enum StartPageEnum {
	Default = 'Default',
	Live = 'Live',
	History = 'History',
	Dashboard = 'Dashboard',
	Reports = 'Reports',
	WhitePage = 'WhitePage',
}

export default StartPageEnum;
