import AutoTextField from 'components/AutoTextfield/AutoTextField';
import React from 'react';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';

import SelectionPanel from '../SelectionPanel';
import SelectionTree from '../SelectionTree/SelectionTree';
import { TreeSelectionDialogContentProps } from './types';

const TreeSelectionDialogContent = (props: TreeSelectionDialogContentProps): JSX.Element => (
	<div className={'content'}>
		<div className="data">
			<AutoTextField
				placeHolder={props.filterPlaceholder}
				value={props.filterText}
				onChange={props.changeFilterCallback}
				showClearButton={true}
			/>
			{props.selectAllVisible ? (
				<Button className="e-flat widget-button selectAll" onClick={props.selectAll}>
					{TranslateText('fleetSelection.selectAll')}
				</Button>
			) : null}
			<SelectionTree
				data={props.treeData}
				expandCallback={props.expandCallback}
				selectCallback={props.selectCallback}
			/>
		</div>
		<SelectionPanel
			selection={props.selectedItems}
			removeAll={props.clearSelection}
			removeItem={props.removeSelectedItem}
		/>
	</div>
);

TreeSelectionDialogContent.defaultProps = {
	selectAllVisible: false,
};

export default TreeSelectionDialogContent;
