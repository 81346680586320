import { Icon, IconButton } from '@material-ui/core';
import { Column, ColumnBodyType } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import React from 'react';

type Props = {
	fieldName: string;
	header: string;
	body?: ColumnBodyType;
	sortable?: boolean;
	filterable?: boolean;
	maxFractionDigits?: number;
	shown?: boolean;
	showHeaderTooltip?: boolean;
	showFilterTooltip?: boolean;
	showContentTooltip?: boolean;
	defaultWidth?: number;
};

const NumberColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	maxFractionDigits,
	shown = true,
	showHeaderTooltip = true,
	showFilterTooltip = true,
	showContentTooltip = true,
	defaultWidth,
}: Props) =>
	shown ? (
		<Column
			className="numberColumn sortIcon"
			filterMenuClassName="numberColumn"
			footerClassName="numberColumn"
			style={
				defaultWidth !== undefined
					? {
							flexGrow: '1',
							flexShrink: '1',
							flexBasis: `${defaultWidth}px`,
					  }
					: null
			}
			key={fieldName}
			field={fieldName}
			header={() => (
				<span className="p-column-title" title={showHeaderTooltip ? header : ''}>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) => (
							<span title={showContentTooltip ? rowData[fieldName] : ''}>{rowData[fieldName]}</span>
					  )
			}
			dataType="numeric"
			sortable={sortable}
			showClearButton={false}
			filter={filterable}
			filterElement={(options) => {
				return (
					<span className="p-input-icon-right" title={showFilterTooltip ? options.value : ''}>
						<InputNumber
							min={0}
							mode="decimal"
							className="numericInputPadingRight"
							maxFractionDigits={maxFractionDigits ?? 0}
							value={options.value}
							useGrouping={false}
							onChange={(e) => options.filterApplyCallback(e.value?.toString())}
						/>
						<IconButton
							hidden={!options.value}
							onClick={() => options.filterApplyCallback(null)}
							size="small"
							className="inputClearButton"
						>
							<Icon>close</Icon>
						</IconButton>
					</span>
				);
			}}
		/>
	) : null;

export default NumberColumn;
