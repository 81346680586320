import { FleetSelectionClientModel } from 'components/FleetSelectionArea/FleetSelectionArea';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { FleetSelectionDispatcher, IFleetSelectionState } from 'store/FleetSelection';
import { resizableEntityActionCreators, ResizableType } from 'store/ResizableEntityState';
import { SET_HISTORY_GRID_OPTION, SET_HISTORY_MAP_OPTION, SET_LIVE_OPTION } from 'store/RightSideBar';
import ajaxUtil from 'utils/Ajax';
import { setSelectionForDriverWithNoAuth } from 'utils/FleetSelectionUtils';

import * as GlobalSettings from '../../GlobalSettings.json';

const useSaveTracked = (customerLevel: CustomerLevelEnum) => {
	const dispatch = useDispatch();

	const { isLoading: isSavingTracked, mutate } = useMutation((data: ITreeNode[]) =>
		ajaxUtil.post(`${GlobalSettings.fleetSelection}/SaveTracked`, data)
	);

	const saveTracked = useCallback(
		(tracked: ITreeNode[]) => {
			const data: ITreeNode[] = tracked.filter((e) => e);
			if (customerLevel === CustomerLevelEnum.Default) {
				mutate(data);
			}
			FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, tracked);
		},
		[dispatch]
	);

	return { isSavingTracked, saveTracked };
};

const useFleetSelectionUpdate = (
	isDriverWithNoAuthorization: boolean,
	driverIdentification: boolean,
	assetTracking: boolean,
	customerId: string,
	fleetSelectionState: IFleetSelectionState
) => {
	const dispatch = useDispatch();
	const isMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);

	const customerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);
	const saveFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveFleetSelection
	);
	const saveTrackedFleetSelection = useSelector(
		(state: ApplicationState) => state.currentSession.customer?.saveTrackedFleetSelection
	);

	const firstLoad = useRef<boolean>(true);
	useEffect(() => {
		let validRequest = true;
		if (!firstLoad.current) {
			ajaxUtil
				.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetFleetSelectionData`, {
					customerId: customerId,
					objectFunction: assetTracking ? ObjectFunctionFilter.All : ObjectFunctionFilter.Full,
					showPersons: driverIdentification,
					showObjects: true,
					showInactive: fleetSelectionState.showInactive,
					filterText: '',
				})
				.then(async (data) => {
					if (validRequest) {
						if (isDriverWithNoAuthorization && driverIdentification) {
							setSelectionForDriverWithNoAuth(data, dispatch);
						} else {
							const newSelectedEntities = fleetSelectionState.selectedEntities.filter((se) =>
								data.some((fs) => fs.id === se.id || fs.items?.some((fsc) => fsc.id === se.id))
							);
							const newTrackedEntities = fleetSelectionState.trackedEntities.filter((te) =>
								data.some((fs) => fs.id === te.id || fs.items?.some((fsc) => fsc.id === te.id))
							);

							if (customerLevel === CustomerLevelEnum.Default && saveFleetSelection) {
								await ajaxUtil.post(`${GlobalSettings.fleetSelection}/Save`, {
									selectedEntities: newSelectedEntities,
									trackedEntities: newTrackedEntities,
									showInactive: fleetSelectionState.showInactive,
								} as FleetSelectionClientModel);

								if (saveTrackedFleetSelection) {
									await ajaxUtil.post(
										`${GlobalSettings.fleetSelection}/SaveTracked`,
										newTrackedEntities
									);
								}
							}

							FleetSelectionDispatcher.setSelectedEntities(dispatch, newSelectedEntities);
							FleetSelectionDispatcher.setTempSelectedEntities(dispatch, newSelectedEntities);
							FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, newTrackedEntities);
						}

						if (isDriverWithNoAuthorization) {
							dispatch({
								type: SET_LIVE_OPTION,
								payload: 1,
							});

							if (isMapActive) {
								dispatch({
									type: SET_HISTORY_MAP_OPTION,
									payload: 1,
								});
							} else {
								dispatch({
									type: SET_HISTORY_GRID_OPTION,
									payload: 1,
								});
							}
							dispatch(resizableEntityActionCreators.setCollapsed(ResizableType.HistoryGrid, false));
						}
					}
				});
		} else {
			firstLoad.current = false;
		}

		return () => {
			validRequest = false;
		};
	}, [fleetSelectionState.recheckTimestamp]);
};

export { useSaveTracked, useFleetSelectionUpdate };
