import { AlertMessageTag } from 'components/AlertMessageTagsButton/types';
import AlertTypesEnum from 'models/AlertTypesEnum';
import { useLayoutEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import ajaxUtil from 'utils/Ajax';

import * as GlobalSettings from '../../../GlobalSettings.json';
import { EditAlertMessage } from '../AlertMessagesDetails';

const useAlertTagsVariable = (
	alertMessage: EditAlertMessage,
	alertType: AlertTypesEnum,
	textIdentifier: string,
	onChangeCallback: (alertMessage: EditAlertMessage, hasChanges: boolean) => void
) => {
	useQuery(
		['alert-message-tags'],
		() => ajaxUtil.get<AlertMessageTag>(`${GlobalSettings.alertsApi}/getAlertTagGroup?alertType=${alertType}`)
	);

	const caretPosition: React.MutableRefObject<number> = useRef<number>(-1);

	const handleAlertMessageTagsChange = (value: string): void => {
		const element: HTMLInputElement = document.getElementById(textIdentifier) as HTMLInputElement;

		caretPosition.current = element.selectionStart + value.length;

		const newSubject: string =
			element.value.slice(0, element.selectionStart) + value + element.value.slice(element.selectionStart);

		onChangeCallback(
			{
				...alertMessage,
				subject: newSubject,
			},
			true
		);
	};

	useLayoutEffect(() => {
		const element: HTMLInputElement = document.getElementById('subject') as HTMLInputElement;

		if (!element || caretPosition.current < 0) {
			return;
		}

		element.setSelectionRange(caretPosition.current, caretPosition.current);
	});

	return { handleAlertMessageTagsChange };
};

export { useAlertTagsVariable };
