import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SendIcon from '@material-ui/icons/Send';

import { TranslateText } from 'utils/Translations';
import BookmarkAddedIcon from '../../../components/Common/Icons/BookmarkAddedIcon';
import TableChartCustomIcon from '../../../components/Common/Icons/TableChartCustomIcon';
import ViewListCustomIcon from '../../../components/Common/Icons/ViewListCustomIcon';
import { ApplicationState } from '../../../store';
import { historyStoreActionCreators, SET_TRIP_UNSAVED_DATA } from '../../../store/HistoryStore';
import { NumberConstants } from '../../../components/Widgets/CustomerFeaturesView';
import NotificationPrompt from '../UserPrompt/NotificationPrompt';

interface Props {
	selectedOption: number;
	changeOption: (value: number) => void;
	isHistory: boolean;
	isDriverWithNoAuthorization: boolean;
}

export const RightSideBarOptions = (props: Props) => {
	const dispatch = useDispatch();
	const hasMap = useSelector((state: ApplicationState) => state.currentSession.customer?.featuresSettings.map);
	const isHistoryMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);

	const [showNotificationPrompt, setShowNotificationPrompt] = React.useState<boolean>(false);
	const [promptCallback, setPromptCallback] = React.useState<() => void>(null);

	const validateCurrentTripData = (callbackFn: () => void) => {
		if (!tripUnsavedData) {
			callbackFn();
		} else {
			setShowNotificationPrompt(true);
			setPromptCallback(() => () => callbackFn());
		}
	};
	const tripMenuEnabled = !props.isHistory || isHistoryMapActive;
	return (
		<div className={'right-sidebar-options'}>
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.unsavedData')}
				handleUserResponse={(response) => {
					setShowNotificationPrompt(false);
					if (response) {
						promptCallback();
						setPromptCallback(null);
						dispatch({
							type: SET_TRIP_UNSAVED_DATA,
							payload: false,
						});
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			<BookmarkAddedIcon
				style={{ width: 20, height: 21 }}
				className={`option-icon ${(props.selectedOption === NumberConstants.rightSideBarFleetSelectionOption
					? 'selected'
					: '') + (props.isDriverWithNoAuthorization ? ' disabled' : '')}`}
				onClick={
					props.isDriverWithNoAuthorization
						? null
						: () => {
								validateCurrentTripData(() =>
									props.changeOption(NumberConstants.rightSideBarFleetSelectionOption)
								);
						  }
				}
			/>
			<TableChartCustomIcon
				style={{ width: 19, height: 19 }}
				className={`option-icon ${tripMenuEnabled ? '' : 'disabled'} ${
					props.selectedOption === NumberConstants.rightSideBarOverviewOption ? 'selected' : ''
				}`}
				onClick={() => {
					if (tripMenuEnabled) {
						props.changeOption(NumberConstants.rightSideBarOverviewOption);
					}
				}}
			/>
			{props.isHistory && hasMap ? (
				isHistoryMapActive ? (
					<ViewListCustomIcon
						style={{ width: 20, height: 16 }}
						className="option-icon selected"
						onClick={() =>
							validateCurrentTripData(() => {
								dispatch(historyStoreActionCreators.setIsMapActive());
							})
						}
					/>
				) : (
					<SendIcon
						style={{ width: 20, height: 20 }}
						className="option-icon selected rotate"
						onClick={() =>
							validateCurrentTripData(() => dispatch(historyStoreActionCreators.setIsMapActive()))
						}
					/>
				)
			) : null}
		</div>
	);
};
