import { LogMessage, MileageType } from 'components/Widgets/LogWidget/LogMessage';
import * as GlobalSettings from 'GlobalSettings.json';
import CommandTypeEnum from 'models/CommandTypeEnum';
import React from 'react';
import ajaxUtil from 'utils/Ajax';
import { FormatDateWithTimezone } from 'utils/DateUtils';
import { TranslateText } from 'utils/Translations';

import { DEBOUNCE_TIME } from '../../../../../Constants';
import { DebouncedButton } from '../../../../Common/DebouncedButton';
import { GENERIC_KEEP_ALIVE } from '../constants';

/* primereact datatable columns must be functions which return JSX and not components */
const useLogWidgetMessagesList = (handleTripDownload: (rowData: LogMessage, data: string) => void) => {
	const detailsColumn = (rowData: LogMessage) => {
		if (rowData.commandType !== null) {
			return (
				<>
					<div className="log-info-row">
						<span>{TranslateText('widgets.grid.colUnit')}: </span>
						<span>{rowData.unitId}</span>
					</div>
					<div className="log-info-row">
						<span>{TranslateText('widgets.grid.colMessageReceivedTime')}: </span>
						<span>
							{rowData.messsageReceivedDateTime
								? `${FormatDateWithTimezone(rowData.messsageReceivedDateTime, rowData.timezone)}(${
										rowData.timeDiff
								  })`
								: '-'}
						</span>
					</div>
					<div className="log-info-row">
						<span>{TranslateText('widgets.grid.colCommand')}: </span>
						<span className="log-event">{CommandTypeEnum[rowData.commandType]}</span>
					</div>
				</>
			);
		}

		return (
			<>
				<div className="log-info-row">
					<span>{TranslateText('widgets.grid.colUnit')}: </span>
					<span>{rowData.unitId}</span>
				</div>
				<div className="log-info-row">
					<span>{TranslateText('widgets.grid.colMessageReceivedTime')}: </span>
					<span>
						{rowData.messsageReceivedDateTime
							? `${FormatDateWithTimezone(rowData.messsageReceivedDateTime, rowData.timezone)}(${
									rowData.timeDiff
							  })`
							: '-'}
					</span>
				</div>
				<div className="log-info-row">
					<span>{TranslateText('widgets.grid.colMessageObservationTime')}: </span>
					<span>
						{rowData.messsageObservationTime
							? FormatDateWithTimezone(rowData.messsageObservationTime, rowData.timezone)
							: '-'}
					</span>
				</div>
				<div className="log-info-row">
					<span>{TranslateText('widgets.grid.colMessageLastGpsFix')}: </span>
					<span>
						{rowData.messsageLastGpsFix
							? FormatDateWithTimezone(rowData.messsageLastGpsFix, rowData.timezone)
							: FormatDateWithTimezone(rowData.messsageObservationTime, rowData.timezone)}
					</span>
				</div>
				<div className="log-info-row">
					<span>{TranslateText('widgets.grid.colEvent')}: </span>
					<span className="log-event">{rowData.event ? rowData.event : '-'}</span>
				</div>
			</>
		);
	};

	const gpsColumn = (rowData: LogMessage) => {
		if (rowData.event === GENERIC_KEEP_ALIVE) {
			// generic keep alive
			return <></>;
		}
		if (rowData.commandType !== null) {
			return <></>;
		}

		return (
			<>
				<div className="position-info">
					<span className="position">
						<span>
							<span>{TranslateText('widgets.grid.colLatitude')}: </span>
							<span>{rowData.latitude}</span>
						</span>
						<span>
							<span>{TranslateText('widgets.grid.colLongitude')}: </span>
							<span>{rowData.longitude}</span>
						</span>
					</span>
					<span className="valid-gps-icons">
						{rowData.validatedGpsPosition ? (
							<span className="valid-gps-true material-icons">check</span>
						) : (
							<span className="valid-gps-false material-icons">close</span>
						)}
					</span>
				</div>
				<div className="log-info-row">
					<span className="right-margin">
						{TranslateText('widgets.grid.colSpeed')}: {rowData.speed}
					</span>
					<span className="right-margin">
						{TranslateText('widgets.grid.colDirection')}: {rowData.direction}
					</span>
					{rowData.altitude ? (
						<span className="right-margin">
							{TranslateText('widgets.grid.colAltitude')}: {rowData.altitude}
						</span>
					) : null}
				</div>
				<div>
					{rowData.satelliteCount ? (
						<>
							<span>{TranslateText('widgets.grid.colSatCount')}: </span>
							<span className="right-margin">{rowData.satelliteCount}</span>
						</>
					) : null}
					<span>
						{TranslateText('common.valid')}:{' '}
						{rowData.validatedGpsPosition ? TranslateText('common.true') : TranslateText('common.false')}
					</span>
				</div>
			</>
		);
	};

	const mileageColumn = (rowData: LogMessage) => {
		if (rowData.event === GENERIC_KEEP_ALIVE) {
			// generic keep alive
			return <></>;
		}

		if (rowData.commandType !== null) {
			return <></>;
		}

		return (
			<>
				<div>
					<span>{TranslateText('common.type')}: </span>
					<span>{rowData.mileageType !== null ? MileageType[rowData.mileageType] : ''}</span>
				</div>
				<div>
					<span>{TranslateText('common.value')}: </span>
					<span>{rowData.mileageValue}</span>
				</div>
				<div>
					<span>{TranslateText('widgets.grid.colKID')}: </span>
					<span>{rowData.driverKeyId && /[^0]/.test(rowData.driverKeyId) ? rowData.driverKeyId : ''}</span>
				</div>
				{rowData.garminEnabled ? (
					<div>
						<span>{TranslateText('widgets.grid.colGarminConnected')}: </span>
						<span>
							{rowData.garminConnected ? TranslateText('common.yes') : TranslateText('common.no')}
						</span>
					</div>
				) : null}
			</>
		);
	};

	const ioColumn = (rowData: LogMessage) => {
		if (rowData.event === GENERIC_KEEP_ALIVE) {
			// generic keep alive
			return <></>;
		}
		if (rowData.commandType !== null || !rowData.ioJSON) {
			return <></>;
		}

		return (
			<div className="io-data">
				{Object.entries(rowData.ioJSON).map(([key, value]) => (
					<span key={key}>{`${key}: ${value}`}</span>
				))}
			</div>
		);
	};

	const ignitionColumn = (rowData: LogMessage) => {
		if (rowData.event === GENERIC_KEEP_ALIVE) {
			// generic keep alive
			return <></>;
		}
		if (rowData.commandType !== null) {
			return <></>;
		}

		return (
			<>
				<div>
					<span className="ignition-label">
						{rowData.ignitionState ? TranslateText('common.on') : TranslateText('common.off')}
					</span>
				</div>
				<div>
					{rowData.ignitionState ? (
						<span className="toggle-icon-on material-icons">toggle_on</span>
					) : (
						<span className="toggle-icon-off material-icons">toggle_off</span>
					)}
				</div>
			</>
		);
	};

	const tripColumn = (rowData: LogMessage) => {
		if (rowData.event === GENERIC_KEEP_ALIVE) {
			// generic keep alive
			return <></>;
		}
		if (rowData.commandType !== null) {
			return <></>;
		}

		return (
			<>
				<div>
					<span>{TranslateText('widgets.grid.colTripStart')}: </span>
					<span>{rowData.isTripStart ? TranslateText('common.yes') : TranslateText('common.no')}</span>
				</div>
				<div>
					<span>{TranslateText('widgets.grid.colTripStop')}: </span>
					<span>{rowData.isTripStop ? TranslateText('common.yes') : TranslateText('common.no')}</span>
				</div>
				<div>
					<span>{TranslateText('widgets.grid.colLocationIsAssumed')}: </span>
					<span>{rowData.locationIsAssumed ? TranslateText('common.yes') : TranslateText('common.no')}</span>
				</div>
				<div>
					<span>{TranslateText('widgets.grid.colValidMessage')}: </span>
					<span>{rowData.messageIsInvalid ? TranslateText('common.no') : TranslateText('common.yes')}</span>
				</div>
			</>
		);
	};

	const tripDownloadColumn = (rowData: LogMessage) => {
		const handleFileDownloadClick = async (rowData: LogMessage) => {
			const result: string = await ajaxUtil.get<string>(
				`${GlobalSettings.messagesApi}/${rowData.unitId}/${rowData.id}`
			);

			handleTripDownload(rowData, result);
		};

		if (rowData.commandType !== null || rowData.event === GENERIC_KEEP_ALIVE) {
			return <></>;
		}

		return (
			<DebouncedButton onClick={() => handleFileDownloadClick(rowData)} debounceTime={DEBOUNCE_TIME}>
				<span className="view-message material-icons">file_download</span>
			</DebouncedButton>
		);
	};

	return { detailsColumn, gpsColumn, mileageColumn, ioColumn, ignitionColumn, tripColumn, tripDownloadColumn };
};

export { useLogWidgetMessagesList };
