import ObjectTypeEnum from '../../models/ObjectTypeEnum';
import { TranslateText } from '../../utils/Translations';

const ObjectTypeDataSource = {
	dataSource: () => [
		{
			id: ObjectTypeEnum.Unknown,
			display: TranslateText('objects.objectType.Unknown'),
		},
		{
			id: ObjectTypeEnum.Car,
			display: TranslateText('objects.objectType.Car'),
		},
		{
			id: ObjectTypeEnum.Van,
			display: TranslateText('objects.objectType.Van'),
		},
		{
			id: ObjectTypeEnum.Truck,
			display: TranslateText('objects.objectType.Truck'),
		},
		{
			id: ObjectTypeEnum.Trailer,
			display: TranslateText('objects.objectType.Trailer'),
		},
		{
			id: ObjectTypeEnum.Crane,
			display: TranslateText('objects.objectType.Crane'),
		},
		{
			id: ObjectTypeEnum.Container,
			display: TranslateText('objects.objectType.Container'),
		},
		{
			id: ObjectTypeEnum.Coach,
			display: TranslateText('objects.objectType.Coach'),
		},
		{
			id: ObjectTypeEnum.Person,
			display: TranslateText('objects.objectType.Person'),
		},
		{
			id: ObjectTypeEnum.Bike,
			display: TranslateText('objects.objectType.Bike'),
		},
		{
			id: ObjectTypeEnum.Motorcycle,
			display: TranslateText('objects.objectType.Motorcycle'),
		},
		{
			id: ObjectTypeEnum.Boat,
			display: TranslateText('objects.objectType.Boat'),
		},
		{
			id: ObjectTypeEnum.Bus,
			display: TranslateText('objects.objectType.Bus'),
		},
		{
			id: ObjectTypeEnum.Moped,
			display: TranslateText('objects.objectType.Moped'),
		},
		{
			id: ObjectTypeEnum.Tractor,
			display: TranslateText('objects.objectType.Tractor'),
		},
	],
};

export default ObjectTypeDataSource;
