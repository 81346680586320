import './LiveMap.scss';

import { NumberSize, Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DrivingSvgImage from '../../assets/icons/DrivingStateSideBar.svg';
import IdleSvgImage from '../../assets/icons/Idle.svg';
import ParkedSvgImage from '../../assets/icons/Parked.svg';
import { ResizableConstants } from '../../shared/constants/ResizableConstants';
import { ApplicationState } from '../../store';
import { availableCustomersActions } from '../../store/AvailableCustomers';
import { liveMapActionCreators, SET_FOCUSED_ENTITY_ID, SET_MAP_SELECTED_ENTITY } from '../../store/LiveMap';
import { resizableEntityActionCreators, ResizableType } from '../../store/ResizableEntityState';
import FeatureUnavailable from '../FeatureUnavailable';
import { LiveDetailsTabs } from '../RightSidebarMenu/ExtendedMenu/LiveDetailsTabs';
import LiveMenu from '../RightSidebarMenu/LiveMenu';
import { useLiveFilterService } from '../RightSidebarMenu/UseLiveFilterService';
import { LiveDataSocket } from '../WebSocketEngine/LiveDataSocket';
import RietveldMap, { MarkerConfig } from './RietveldMap';

const LiveMap = () => {
	const filteredCustomerId = useSelector((state: ApplicationState) => state.globalCustomer.filteredCustomer?.id);
	const hasMap = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.map
			: state.currentSession.customer?.featuresSettings.map
	);
	const liveEntities = useSelector((state: ApplicationState) => state.liveData.liveEntities);
	const showRightBar = useSelector((state: ApplicationState) => state.rightSideBar.showRightBar);
	const dispatch = useDispatch();
	const [markers, setMarkers] = React.useState<MarkerConfig[]>([]);
	const [resizableExpanded, setResizableExpanded] = React.useState<boolean>(false);
	const resizableRef = useRef<Resizable>(null);
	const selectedEntityId = useSelector((state: ApplicationState) => state.liveMap.selectedEntityId);
	const liveResizable = useSelector((state: ApplicationState) => state.resizableEntity.resizableMenuLive);
	const focusedEntityId = useSelector((state: ApplicationState) => state.liveMap.focusedEntityId);

	const calculateExpanded = () => {
		if (resizableRef.current) {
			if (
				(resizableRef.current.getParentSize().width / 2 - ResizableConstants.defaultWidth) / 2 +
					ResizableConstants.defaultWidth <
				resizableRef.current.size.width
			) {
				if (!resizableExpanded) {
					setResizableExpanded(true);
				}
			} else if (resizableExpanded) {
				setResizableExpanded(false);
			}
		}
	};

	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(true));

		return () => {
			dispatch({
				type: SET_FOCUSED_ENTITY_ID,
				payload: null,
			});
		};
	}, []);

	useEffect(() => {
		if (hasMap) {
			setMarkers(
				liveEntities.map(
					(liveData) =>
						({
							position: { lat: liveData.lat, lng: liveData.long },
							liveData: liveData,
							id: liveData.entityId,
						} as MarkerConfig)
				)
			);
		}
		if (selectedEntityId && !liveEntities.some((le) => le.entityId === selectedEntityId)) {
			dispatch({
				type: SET_MAP_SELECTED_ENTITY,
				payload: null,
			});
		}
		if (focusedEntityId) {
			const focusedEntity = liveEntities.find((le) => le.entityId === focusedEntityId);
			if (focusedEntity) {
				dispatch({
					type: SET_FOCUSED_ENTITY_ID,
					payload: null,
				});
				dispatch(
					liveMapActionCreators.toggleFocusedEntity(focusedEntity.entityId, {
						lat: focusedEntity.lat,
						lng: focusedEntity.long,
					})
				);
			}
		}
	}, [liveEntities, hasMap, focusedEntityId]);

	useEffect(() => {
		setMarkers([]);
	}, [filteredCustomerId]);

	useLiveFilterService();
	calculateExpanded();

	return (
		<div className={'live-map-container'}>
			{/* preload images used on maps */}
			<div style={{ display: 'none' }}>
				<img style={{ display: 'none' }} alt={''} src={ParkedSvgImage} />
				<img style={{ display: 'none' }} alt={''} src={IdleSvgImage} />
				<img style={{ display: 'none' }} alt={''} src={DrivingSvgImage} />
			</div>
			<LiveDataSocket />
			<div className={'map'}>
				{hasMap ? (
					<>
						<RietveldMap markers={markers} isLiveMap={true} />
						<LiveDetailsTabs />
					</>
				) : (
					<FeatureUnavailable />
				)}
			</div>
			<Resizable
				ref={resizableRef}
				size={{
					width: liveResizable.isCollapsed ? ResizableConstants.minWidth : liveResizable.width,
					height: ResizableConstants.maxHeight,
				}}
				enable={{
					top: false,
					right: false,
					bottom: false,
					left: !liveResizable.isCollapsed,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				minWidth={liveResizable.isCollapsed ? ResizableConstants.minWidth : ResizableConstants.defaultWidth}
				maxWidth={ResizableConstants.maxWidth}
				style={{ display: 'flex' }}
				className={`${!showRightBar ? 'hide-resizable-component' : ''}`}
				onResize={calculateExpanded}
				onResizeStop={(event: Event, direction: Direction, ref: HTMLElement, dimension: NumberSize) => {
					dispatch(
						resizableEntityActionCreators.setWidth(
							ResizableType.Live,
							liveResizable.width + dimension.width
						)
					);
				}}
			>
				<LiveMenu key={'LiveMenu'} visible={showRightBar} resizableExpanded={resizableExpanded} />
			</Resizable>
		</div>
	);
};

export default LiveMap;
