import './alertView.scss';

import AlertTemplate from 'models/AlertTemplate';
import GeofenceAlertMetadata from 'models/GeofenceAlertMetadata';
import GeofenceTypeEnum from 'models/GeofenceTypeEnum';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {Box, Checkbox, FormControlLabel, Typography} from '@material-ui/core';

import GlobalSettings from '../../../../GlobalSettings.json';
import Alert from '../../../../models/Alert';
import AlertAnalogTriggerConditionEnum from '../../../../models/AlertAnalogTriggerConditionEnum';
import AlertDigitalTriggerConditionEnum from '../../../../models/AlertDigitalTriggerConditionEnum';
import AlertEntityTypeEnum from '../../../../models/AlertEntityTypeEnum';
import AlertTypesEnum from '../../../../models/AlertTypesEnum';
import AnalogAlertMetadata from '../../../../models/AnalogAlertMetadata';
import AnalogTemplateItem from '../../../../models/AnalogTemplateItem';
import DigitalAlertMetadata from '../../../../models/DigitalAlertMetadata';
import DurationExceededAlertMetadata from '../../../../models/DurationExceededAlert';
import EventFrequencyEnum from '../../../../models/EventFrequencyEnum';
import MainPowerAlertMetadata from '../../../../models/MainPowerAlertMetadata';
import MainPowerEventTypesEnum from '../../../../models/MainPowerEventTypesEnum';
import MessageTargetEnum from '../../../../models/MessageTargetEnum';
import MessageWrapper from '../../../../models/MessageWrapper';
import PrivateMileageAlertMetadata from '../../../../models/PrivateMileageAlert';
import TripTypesEnum from '../../../../models/TripTypesEnum';
import CustomerNameDisplay from '../../../../shared/components/CustomerNameDisplay';
import MaterialTextField from '../../../../shared/components/MaterialTextField/MaterialTextField';
import {ValidationResult} from '../../../../shared/validation/interfaces';
import Validator from '../../../../shared/validation/Validator';
import {ValidatorFunctions} from '../../../../shared/validation/ValidatorFunctions';
import {ApplicationState} from '../../../../store';
import ajaxUtil from '../../../../utils/Ajax';
import {getAnalogTriggerConditions, getDigitalTriggerConditions, getGeofenceTypes} from '../../../../utils/AlertUtils';
import {TranslateText} from '../../../../utils/Translations';
import MaterialAutocomplete, {AutocompleteItem} from '../../../Common/Autocomplete/MaterialAutocomplete';
import {AlertDurationExceeded} from '../../../Views/AlertDurationExceeded';
import {
	EvaluationMomentEnum
} from '../../../Views/AlertDurationExceeded/TimeFrameEvaluation/EvaluationMomentDropdown/types';
import {AlertNotificationType} from '../../../Views/AlertNotificationTypes/AlertNotificationTypes';
import {AddWizardComponentProps} from '../../NewAddWizard';
import TowingEventTypesEnum from "../../../../models/TowingEventTypesEnum";
import TowingAlertMetadata from "../../../../models/TowingAlertMetadata";
import {getAlertFrequencyDatasource} from "../../../Views/AlertsConstants";

export interface AlertsEntitiesData {
	eventTypeId: AlertTypesEnum;
	entityType: AlertEntityTypeEnum;
	hasAlertNotification: boolean;
	hasEmailNotification: boolean;
	hasSmsNotification: boolean;
	hasWhatsAppNotification: boolean;
	hasPopup: boolean;
	trackedEntities?: Object[];
	alertRecipients?: Object[];
}

const AlertView = (props: AddWizardComponentProps) => {
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const allowTrackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const tripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);
	const templates = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.templates
			: s.currentSession.customer.featuresSettings.templates
	);

	const geoFence = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.geoFence
			: s.currentSession.customer.featuresSettings.geoFence
	);

	const autoGenerateAlertCode = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.entityDefaultsSettings.autoGenerateAlertCode
			: s.currentSession.customer.entityDefaultsSettings.autoGenerateAlertCode
	);

	const [alert, setAlert] = useState(new Alert(defaultCustomerId));
	const [digitalTemplates, setDigitalTemplates] = useState<AutocompleteItem[]>([]);
	const [digitalTemplatesLoaded, setDigitalTemplatesLoaded] = useState(false);
	const [analogTemplates, setAnalogTemplates] = useState<AnalogTemplateItem[]>([]);
	const [analogTemplatesLoaded, setAnalogTemplatesLoaded] = useState(false);
	const [analogStatesTemplate, setAnalogStatesTemplate] = useState<AutocompleteItem[]>([]);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [customerDefaultLanguage, setCustomerDefaultLanguage] = useState(null);
	const noEmptySpaceRegEx = '(?!^ +$)^.+$';
	const validationDurationExceededAlert = useRef(true);
	const [entitiesData, setEntitiesData] = useState<AlertsEntitiesData>({
		eventTypeId: AlertTypesEnum.Unknown,
		entityType: AlertEntityTypeEnum.NotEntityRelated,
		hasWhatsAppNotification: true,
		hasSmsNotification: true,
		hasEmailNotification: true,
		hasAlertNotification: true,
		hasPopup: false,
		trackedEntities: [],
		alertRecipients: [],
	});

	const analogTriggerConditionDataSource = getAnalogTriggerConditions();
	const digitalTriggerConditionDataSource = getDigitalTriggerConditions();
	const geofenceTypesDataSource = getGeofenceTypes();

	const validator: Validator = new Validator({
		fieldRules: {
			code: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					regex: ValidatorFunctions.regex(new RegExp(noEmptySpaceRegEx)),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data: any) => {
							const alert = data as Alert;
							if (!alert.code?.trim()) {
								return ValidatorFunctions.wrapInPromise(true);
							}
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/AlertFields`, {
								customerId: alert.customerId,
								value: alert.code,
								Field: 'code',
								editedEntityId: alert.id ? alert.id : null,
							});
						},
					},
				},
			},
			name: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(200),
					regex: ValidatorFunctions.regex(new RegExp(noEmptySpaceRegEx)),
				},
			},
			eventTypeId: {
				rules: { required: ValidatorFunctions.required() },
			},
			eventFrequency: {
				rules: { required: ValidatorFunctions.required() },
			},
			templateAnalogId: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Analog ||
									!!alertData.analogAlertMetadata.templateAnalogId
							);
						},
					},
				},
			},
			mainPowerTriggerEvent: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.MainPower ||
									!!alertData.mainPowerAlertMetadata.mainPowerTriggerEvent ||
									alertData.mainPowerAlertMetadata.mainPowerTriggerEvent ===
										MainPowerEventTypesEnum.Both
							);
						},
					},
				},
			},
			towingTriggerEvent: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Towing ||
								!!alertData.towingAlertMetadata.towingTriggerEvent ||
								alertData.towingAlertMetadata.towingTriggerEvent === TowingEventTypesEnum.Both
							);
						},
					},
				},
			},
			analogTriggerCondition: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Analog ||
									!!alertData.analogAlertMetadata.triggerCondition ||
									alertData.analogAlertMetadata.triggerCondition ===
										AlertAnalogTriggerConditionEnum.AnyStateChange
							);
						},
					},
				},
			},
			templateAnalogStateId: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Analog ||
									(alertData.analogAlertMetadata.triggerCondition !==
										AlertAnalogTriggerConditionEnum.ToSpecificState &&
										alertData.analogAlertMetadata.triggerCondition !==
											AlertAnalogTriggerConditionEnum.FromSpecificState) ||
									!!alertData.analogAlertMetadata.templateAnalogStateId
							);
						},
					},
				},
			},
			templateDigitalId: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Digital ||
									!!alertData.digitalAlertMetadata?.templateDigitalId
							);
						},
					},
				},
			},
			digitalTriggerCondition: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Digital ||
									alertData.digitalAlertMetadata?.triggerCondition in AlertDigitalTriggerConditionEnum
							);
						},
					},
				},
			},
			geofenceType: {
				rules: {
					requiredConditionally: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							return ValidatorFunctions.wrapInPromise(
								alertData.eventTypeId !== AlertTypesEnum.Geofence ||
									alertData.geofenceAlertMetadata?.geofenceType in GeofenceTypeEnum
							);
						},
					},
				},
			},
			alertThreshold: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType !== AlertTypesEnum.PrivateMileage ||
								alertData.PrivateMileageAlertMetadata.thresholdValue
							) {
								return ValidatorFunctions.wrapInPromise(true);
							}
							return ValidatorFunctions.wrapInPromise(false);
						},
					},
					maxNumber: {
						message: TranslateText('fieldsValidations.maxValue') + 999999999,
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType !== AlertTypesEnum.PrivateMileage ||
								Number(alertData.PrivateMileageAlertMetadata.thresholdValue) <= 999999999
							) {
								return ValidatorFunctions.wrapInPromise(true);
							}
							return ValidatorFunctions.wrapInPromise(false);
						},
					},
				},
			},
			preTreshold: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								!alertData.PrivateMileageAlertMetadata.preThresholds &&
								alertData.PrivateMileageAlertMetadata.preThresholds !== 0
							) {
								return ValidatorFunctions.wrapInPromise(false);
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
			firstPreThresholdAlert: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 1
							) {
								if (
									!alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert &&
									alertData.PrivateMileageAlertMetadata?.firstPreThresholdAlert !== 0
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}

							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.minValue') + 1,
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								data.PrivateMileageAlertMetadata.preThresholds >= 1
							) {
								if (alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert < 1) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					maxNumber: {
						message: TranslateText('fieldsValidations.maxValue') + 99,
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 1
							) {
								if (alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert > 99) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					noSameValue: {
						message: TranslateText('fieldsValidations.preThresholdsSameValue'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								data.PrivateMileageAlertMetadata.preThresholds >= 1
							) {
								if (
									alertData.PrivateMileageAlertMetadata.preThresholds >= 2 &&
									alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert ===
										alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								} else if (
									alertData.PrivateMileageAlertMetadata.preThresholds === 3 &&
									alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert ===
										alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
			secondPreThresholdAlert: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 2
							) {
								if (
									!alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert &&
									alertData.PrivateMileageAlertMetadata?.secondPreThresholdAlert !== 0
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.prethresholdGreater'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 2
							) {
								if (
									alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert <
									alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					maxNumber: {
						message: TranslateText('fieldsValidations.maxValue') + 99,
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 2
							) {
								if (alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert > 99) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					noSameValue: {
						message: TranslateText('fieldsValidations.preThresholdsSameValue'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds >= 2
							) {
								if (
									alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert ===
									alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								} else if (
									alertData.PrivateMileageAlertMetadata.preThresholds === 3 &&
									alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert ===
										alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
			thirdPreThresholdAlert: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds === 3
							) {
								if (
									!alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert &&
									alertData.PrivateMileageAlertMetadata?.thirdPreThresholdAlert !== 0
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					minNumber: {
						message: TranslateText('fieldsValidations.prethresholdGreater'),
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds === 3
							) {
								if (
									alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert <
									alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					maxNumber: {
						message: TranslateText('fieldsValidations.maxValue') + 99,
						validationFunction: (data: any) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds === 3
							) {
								if (alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert > 99) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
					noSameValue: {
						message: TranslateText('fieldsValidations.preThresholdsSameValue'),
						validationFunction: (data) => {
							const alertData = data as Alert;
							if (
								alertData.alertType === AlertTypesEnum.PrivateMileage &&
								alertData.PrivateMileageAlertMetadata.preThresholds === 3
							) {
								if (
									alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert ===
									alertData.PrivateMileageAlertMetadata.firstPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								} else if (
									alertData.PrivateMileageAlertMetadata.thirdPreThresholdAlert ===
									alertData.PrivateMileageAlertMetadata.secondPreThresholdAlert
								) {
									return ValidatorFunctions.wrapInPromise(false);
								}
							}
							return ValidatorFunctions.wrapInPromise(true);
						},
					},
				},
			},
		},
	});
	const validateAnalogAlert = (alert: Alert) => {
		return (
			alert.eventTypeId !== AlertTypesEnum.Analog ||
			(alert.analogAlertMetadata.templateAnalogId &&
				alert.analogAlertMetadata.triggerCondition >= 0 &&
				(alert.analogAlertMetadata.templateAnalogStateId ||
					alert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.AnyStateChange))
		);
	};

	const validateDigitalAlert = (alert: Alert) => {
		return (
			alert.eventTypeId !== AlertTypesEnum.Digital ||
			(alert.digitalAlertMetadata.templateDigitalId &&
				alert.digitalAlertMetadata.triggerCondition in AlertDigitalTriggerConditionEnum)
		);
	};

	const validateMainPowerAlert = (alert: Alert) => {
		return (
			alert.eventTypeId !== AlertTypesEnum.MainPower ||
			(alert.mainPowerAlertMetadata.mainPowerTriggerEvent !== undefined &&
				alert.mainPowerAlertMetadata.mainPowerTriggerEvent in MainPowerEventTypesEnum)
		);
	};

	const validateTowingAlert = (alert: Alert) => {
		return (
			alert.eventTypeId !== AlertTypesEnum.Towing ||
			(alert.towingAlertMetadata.towingTriggerEvent !== undefined &&
				alert.towingAlertMetadata.towingTriggerEvent in TowingEventTypesEnum)
		);
	};
	const alertValidations = (alert: Alert) => {
		return (
			!!alert.code &&
			!!alert.name &&
			!!alert.eventTypeId &&
			!!alert.entityType &&
			(!!alert.eventFrequency || alert.eventFrequency === EventFrequencyEnum.Once) &&
			validateAnalogAlert(alert) &&
			validateDigitalAlert(alert) &&
			validateMainPowerAlert(alert) &&
			validateTowingAlert(alert) &&
			(alert.eventTypeId !== AlertTypesEnum.PrivateMileage ||
				(alert.eventTypeId === AlertTypesEnum.PrivateMileage &&
					!!alert.PrivateMileageAlertMetadata &&
					!!alert.PrivateMileageAlertMetadata.thresholdValue))
		);
	};

	useEffect(() => {
		const tempAlert = { ...alert } as Alert;
		const url = `${GlobalSettings.alertsApi}/generateAlertCode/${defaultCustomerId}`;
		if (autoGenerateAlertCode) {
			ajaxUtil
				.get(url)
				.then((result) => {
					tempAlert.code = result.toString();
					setAlert(tempAlert);
				})
				.catch((err) => {
					console.log(err);
				});
		}
		defaultLanguage();
	}, []);

	useEffect(() => {
		validator.validate(alert).then((result) => {
			setValidationResult(result.validationResult);
			const isValid = alertValidations(alert);
			props.setValidationCallback(result.formResult && isValid);
			if (alert.eventTypeId === AlertTypesEnum.DurationExceeded && !validationDurationExceededAlert?.current) {
				props.setValidationCallback(validationDurationExceededAlert.current);
			}
			if (unsavedData) {
				props.onChangeCallback({
					...alert,
					trackedEntities: entitiesData.trackedEntities,
					alertRecipients: entitiesData.alertRecipients,
				});
			}
		});
	}, [alert]);

	useEffect(() => {
		defaultMessages();
	}, [alert.eventTypeId]);

	const setMetadataValuesToNull = (alert: Alert, alertType: AlertTypesEnum) => {
		if (alertType !== AlertTypesEnum.PrivateMileage) {
			alert.PrivateMileageAlertMetadata = null;
		}
		if (alertType !== AlertTypesEnum.DurationExceeded) {
			alert.DurationExceededAlertMetadata = null;
		}
		if (alertType !== AlertTypesEnum.Digital) {
			alert.digitalAlertMetadata = null;
		}
		if (alertType !== AlertTypesEnum.Analog) {
			alert.analogAlertMetadata = null;
		}
		if (alertType !== AlertTypesEnum.MainPower) {
			alert.mainPowerAlertMetadata = null;
		}
		if (alertType !== AlertTypesEnum.Geofence) {
			alert.geofenceAlertMetadata = null;
		}
		if(alertType !== AlertTypesEnum.Towing)	{
			alert.towingAlertMetadata = null;
		}
	};

	const defaultLanguage = () => {
		const url = `${GlobalSettings.customersMaintenanceApi}/getCustomerLanguageForAlert/${defaultCustomerId}`;
		ajaxUtil
			.get(url)
			.then((result: any) => {
				const language = result;
				if (language !== null) {
					setCustomerDefaultLanguage({
						id: language.id as string,
						code: language.languageCode as string,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const defaultMessages = () => {
		const tempAlert = { ...alert } as Alert;
		const entityType = alert.eventTypeId;
		const alertTemplates = new AlertTemplate();
		const baseUrl = GlobalSettings.defaultValuesApi + '/alertDefaultMessage/' + entityType + '/';
		Object.keys(MessageTargetEnum).forEach((element) => {
			const tempCurrentMessageTab: MessageTargetEnum = element as MessageTargetEnum;

			if (!entityType || !customerDefaultLanguage?.code) {
				return;
			}
			ajaxUtil
				.get(baseUrl + tempCurrentMessageTab + '/' + customerDefaultLanguage?.code)
				.then((result: any) => {
					switch (tempCurrentMessageTab) {
						case MessageTargetEnum.Notification:
							tempAlert.notificationTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							alertTemplates.notificationTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Popup:
							tempAlert.popupTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							alertTemplates.popupTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Mail:
							tempAlert.mailTemplate = {
								body: result.body,
								subject: result.subject,
							} as MessageWrapper;
							alertTemplates.mailTemplate = {
								body: result.body,
								subject: result.subject,
							} as MessageWrapper;
							break;
						case MessageTargetEnum.Sms:
							tempAlert.smsTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							alertTemplates.smsTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						case MessageTargetEnum.WhatsApp:
							tempAlert.whatsAppTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							alertTemplates.whatsAppTemplate = {
								body: result.body,
								subject: '',
							} as MessageWrapper;
							break;
						default:
							break;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
		if (customerDefaultLanguage !== null) {
			tempAlert.templates[customerDefaultLanguage?.id] = alertTemplates;
		}
		setAlert(tempAlert);
	};
	const initMetadataValues = (alert: Alert, alertType: AlertTypesEnum) => {
		switch (alertType) {
			case AlertTypesEnum.PrivateMileage:
				alert.PrivateMileageAlertMetadata = new PrivateMileageAlertMetadata();
				break;
			case AlertTypesEnum.DurationExceeded:
				alert.DurationExceededAlertMetadata = new DurationExceededAlertMetadata();
				break;
			case AlertTypesEnum.Digital:
				alert.digitalAlertMetadata = new DigitalAlertMetadata();
				break;
			case AlertTypesEnum.Analog:
				alert.analogAlertMetadata = new AnalogAlertMetadata();
				break;
			case AlertTypesEnum.MainPower:
				alert.mainPowerAlertMetadata = new MainPowerAlertMetadata();
				break;
			case AlertTypesEnum.Geofence:
				alert.geofenceAlertMetadata = new GeofenceAlertMetadata();
				break;
			case AlertTypesEnum.Towing:
				alert.towingAlertMetadata = new TowingAlertMetadata();
				break;
			default:
				break;
		}
	};

	const getMainPowerEventTypeDataSource = (): AutocompleteItem[] => {
		return [
			{
				display: TranslateText('mainPowerEventTypes.both'),
				id: MainPowerEventTypesEnum.Both,
			},
			{
				display: TranslateText('mainPowerEventTypes.connected'),
				id: MainPowerEventTypesEnum.Connected,
			},
			{
				display: TranslateText('mainPowerEventTypes.disconnected'),
				id: MainPowerEventTypesEnum.Disconnected,
			},
		];
	};

	const getTowingEventTypeDataSource = (): AutocompleteItem[] => {
		return [
			{
				display: TranslateText('towingEventTypes.both'),
				id: TowingEventTypesEnum.Both,
			},
			{
				display: TranslateText('towingEventTypes.start'),
				id: TowingEventTypesEnum.Start,
			},
			{
				display: TranslateText('towingEventTypes.stop'),
				id: TowingEventTypesEnum.Stop,
			},
		];
	};

	const eventTypeDataSource = (): AutocompleteItem[] => {
		return [
			{
				display: TranslateText('eventType.privateMileage'),
				id: AlertTypesEnum.PrivateMileage,
			},
			{
				display: TranslateText('eventType.ignition'),
				id: AlertTypesEnum.Ignition,
			},
			{
				display: TranslateText('eventType.unknownKey'),
				id: AlertTypesEnum.UnknownKey,
			},
			{
				display: TranslateText('eventType.unauthorizedDrive'),
				id: AlertTypesEnum.UnauthorizedDrive,
			},
			{
				display: TranslateText('eventType.drivingWithoutAuthentication'),
				id: AlertTypesEnum.DrivingWithoutAuthentication,
			},
			{
				display: TranslateText('eventType.durationExceeded'),
				id: AlertTypesEnum.DurationExceeded,
			},
			{
				display: TranslateText('eventType.analog'),
				id: AlertTypesEnum.Analog,
			},
			{
				display: TranslateText('eventType.digital'),
				id: AlertTypesEnum.Digital,
			},
			{
				display: TranslateText('eventType.mainPower'),
				id: AlertTypesEnum.MainPower,
			},
			{
				display: TranslateText('eventType.geofence'),
				id: AlertTypesEnum.Geofence,
			},
			{
				display: TranslateText('eventType.towing'),
				id: AlertTypesEnum.Towing,
			},
		];
	};


	const handlePrivateMileageValuesChange = (value: number | string, statePropName: string) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const tempAlert = { ...alert } as Alert;
		tempAlert.PrivateMileageAlertMetadata[statePropName] = value;

		if (statePropName === 'preThresholds') {
			if (value === undefined || value === null) {
				tempAlert.preThresholds = 0;
				value = 0;
			}
			if (value === 0) {
				tempAlert.PrivateMileageAlertMetadata.firstPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.secondPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
			if (value === 1) {
				tempAlert.PrivateMileageAlertMetadata.secondPreThresholdAlert = 0;
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
			if (value === 2) {
				tempAlert.PrivateMileageAlertMetadata.thirdPreThresholdAlert = 0;
			}
		}
		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleDigitalTemplateChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const templateDigitalId = value as string;
		const tempAlert = { ...alert };
		tempAlert.digitalAlertMetadata.templateDigitalId = templateDigitalId;

		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleDigitalTriggerConditionChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const triggerCondition = value as AlertDigitalTriggerConditionEnum;
		const tempAlert = { ...alert };
		tempAlert.digitalAlertMetadata.triggerCondition = triggerCondition;

		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleAnalogTemplateStateChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}

		const templateAnalogStateId = value as string;
		const tempAlert = { ...alert };
		tempAlert.analogAlertMetadata.templateAnalogStateId = templateAnalogStateId;

		setAlert(tempAlert);
		setUnsavedData(true);
	};
	const handleAnalogTemplateChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const templateAnalogId = value as string;
		const tempAlert = { ...alert };
		tempAlert.analogAlertMetadata.templateAnalogId = templateAnalogId;
		tempAlert.analogAlertMetadata.templateAnalogStateId = null;

		let analogStatesTemplate: AutocompleteItem[] = [];
		if (
			templateAnalogId &&
			(tempAlert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.ToSpecificState ||
				tempAlert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.FromSpecificState)
		) {
			const selectedAnalogTemplate = analogTemplates.find((x) => x.id === templateAnalogId);
			if (selectedAnalogTemplate?.templateStates?.length) {
				analogStatesTemplate = selectedAnalogTemplate.templateStates;
				tempAlert.analogAlertMetadata.templateAnalogStateId = selectedAnalogTemplate.templateStates[0]
					.id as string;
			}
		}
		setAlert(tempAlert);
		setAnalogStatesTemplate(analogStatesTemplate);
		setUnsavedData(true);
	};

	const handleMainPowerStateChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const trigerValue = value as MainPowerEventTypesEnum;
		const tempAlert = { ...alert };
		tempAlert.mainPowerAlertMetadata.mainPowerTriggerEvent = trigerValue;
		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleGeofenceTypeChange = (value: any) => {
		if (value === null || value === '') props.setValidationCallback(false);
		const geofenceType = value as GeofenceTypeEnum;
		const tempAlert = { ...alert };
		tempAlert.geofenceAlertMetadata.geofenceType = geofenceType;

		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleTowingStateChange = (value: any) => {
		if(value === null || value === '') {
			props.setValidationCallback(false);
		}

		const triggerValue = value as TowingEventTypesEnum;
		const tempAlert = { ...alert };
		tempAlert.towingAlertMetadata.towingTriggerEvent = triggerValue;
		setAlert(tempAlert);
		setUnsavedData(true);
	}

	const handleAnalogTriggerConditionChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		let newAnalogStatesTemplate = [...analogStatesTemplate];
		const stateChangeTrigger = value as AlertAnalogTriggerConditionEnum;
		const oldStateChangeTrigger = alert.analogAlertMetadata.triggerCondition;

		const tempAlert = { ...alert } as Alert;
		tempAlert.analogAlertMetadata.triggerCondition = stateChangeTrigger;

		if (stateChangeTrigger === AlertAnalogTriggerConditionEnum.AnyStateChange) {
			tempAlert.analogAlertMetadata.templateAnalogStateId = null;
			newAnalogStatesTemplate = [];
		} else if (
			(stateChangeTrigger === AlertAnalogTriggerConditionEnum.ToSpecificState ||
				stateChangeTrigger === AlertAnalogTriggerConditionEnum.FromSpecificState) &&
			oldStateChangeTrigger !== AlertAnalogTriggerConditionEnum.ToSpecificState &&
			oldStateChangeTrigger !== AlertAnalogTriggerConditionEnum.FromSpecificState &&
			!!tempAlert.analogAlertMetadata.templateAnalogId
		) {
			const selectedAnalogTemplate = analogTemplates.find(
				(x) => x.id === tempAlert.analogAlertMetadata.templateAnalogId
			);
			if (selectedAnalogTemplate?.templateStates?.length) {
				newAnalogStatesTemplate = selectedAnalogTemplate.templateStates;
				tempAlert.analogAlertMetadata.templateAnalogStateId = selectedAnalogTemplate.templateStates[0]
					.id as string;
			}
		}

		setAlert(tempAlert);
		setAnalogStatesTemplate(newAnalogStatesTemplate);
		setUnsavedData(true);
	};

	const handleValueChange = (value: any, statePropName: keyof Alert) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const tempAlert = { ...alert };
		tempAlert[statePropName] = value;

		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const handleEventTypeChange = (value: any) => {
		if (value === null || value === '') {
			props.setValidationCallback(false);
		}
		const alertTypesValue = value as AlertTypesEnum;
		const tempAlert = { ...alert } as Alert;
		tempAlert['eventTypeId'] = alertTypesValue;
		tempAlert['alertType'] = alertTypesValue;
		tempAlert['entityType'] = getAlertEntityType(alertTypesValue);
		setMetadataValuesToNull(tempAlert, alertTypesValue);
		initMetadataValues(tempAlert, alertTypesValue);

		if (alertTypesValue === AlertTypesEnum.DurationExceeded) {
			props.setValidationCallback(false);
		}

		//Analog
		if (alertTypesValue === AlertTypesEnum.Analog) {
			props.setValidationCallback(false);
			tempAlert.analogAlertMetadata.triggerCondition = AlertAnalogTriggerConditionEnum.ToSpecificState;
			if (!analogTemplatesLoaded) {
				getAnalogTemplatesList();
			}
		}

		//Digital
		if (alertTypesValue === AlertTypesEnum.Digital) {
			props.setValidationCallback(false);
			tempAlert.digitalAlertMetadata.triggerCondition = AlertDigitalTriggerConditionEnum.AnyStateChange;
			if (!digitalTemplatesLoaded) {
				getDigitalTemplatesList();
			}
		}

		const alertTypeEntity = getAlertEntityType(alertTypesValue);
		const newEntitiesData = { ...entitiesData };
		const prevEntitiesData = props.getDataCallback() as AlertsEntitiesData;
		newEntitiesData.eventTypeId = alertTypesValue;
		newEntitiesData.entityType = alertTypeEntity;
		if (
			prevEntitiesData.alertRecipients !== undefined &&
			prevEntitiesData.alertRecipients !== null &&
			prevEntitiesData.alertRecipients?.length > 0
		) {
			props.onChangeCallback({ alertRecipients: [...prevEntitiesData.alertRecipients] });
			newEntitiesData.alertRecipients = [...prevEntitiesData.alertRecipients];
			tempAlert.alertRecipients = [...prevEntitiesData.alertRecipients];
		}

		setEntitiesData(newEntitiesData);
		props.setDataCallback(newEntitiesData);
		setAlert(tempAlert);
		setUnsavedData(true);
	};

	const changeCheck = (data: Alert) => {
		const tempEntitiesData = { ...entitiesData } as AlertsEntitiesData;
		const prevEntitiesData = props.getDataCallback() as AlertsEntitiesData;
		tempEntitiesData.hasEmailNotification = data.useEmail;
		tempEntitiesData.hasSmsNotification = data.useSms;
		tempEntitiesData.hasWhatsAppNotification = data.useWhatsApp;
		tempEntitiesData.hasAlertNotification = data.useNotification;
		if (prevEntitiesData.trackedEntities !== undefined && prevEntitiesData.trackedEntities !== null) {
			tempEntitiesData.trackedEntities = [...prevEntitiesData.trackedEntities];
			data.trackedEntities = [...prevEntitiesData.trackedEntities];
		}
		if (prevEntitiesData.alertRecipients !== undefined && prevEntitiesData.alertRecipients !== null) {
			tempEntitiesData.alertRecipients = [...prevEntitiesData.alertRecipients];
			data.alertRecipients = [...prevEntitiesData.alertRecipients];
		}
		setAlert(data);
		setEntitiesData(tempEntitiesData);
		props.setDataCallback(tempEntitiesData);
	};

	const getAlertEntityType = (type: AlertTypesEnum): AlertEntityTypeEnum => {
		switch (type) {
			case AlertTypesEnum.DrivingWithoutAuthentication:
			case AlertTypesEnum.Ignition:
			case AlertTypesEnum.UnauthorizedDrive:
			case AlertTypesEnum.UnknownKey:
			case AlertTypesEnum.DurationExceeded:
			case AlertTypesEnum.Analog:
			case AlertTypesEnum.Digital:
			case AlertTypesEnum.MainPower:
			case AlertTypesEnum.Geofence:
			case AlertTypesEnum.Towing:
				return AlertEntityTypeEnum.Objects;
			case AlertTypesEnum.PrivateMileage:
				return AlertEntityTypeEnum.Drivers;
			case AlertTypesEnum.Unknown:
				return AlertEntityTypeEnum.NotEntityRelated;
			default:
				return AlertEntityTypeEnum.NotEntityRelated;
		}
	};
	const getAlertTypeDatasource = () => {
		let alertTypeDatasource = eventTypeDataSource();
		if (!allowTrackTypeSpecification || !(tripTypes & TripTypesEnum.Private)) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) => alertType.id !== AlertTypesEnum.PrivateMileage
			);
		}
		if (!templates) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.id !== AlertTypesEnum.DurationExceeded &&
					alertType.id !== AlertTypesEnum.Analog &&
					alertType.id !== AlertTypesEnum.Digital
			);
		}
		if (!driverIdentification) {
			alertTypeDatasource = alertTypeDatasource.filter(
				(alertType) =>
					alertType.id !== AlertTypesEnum.PrivateMileage &&
					alertType.id !== AlertTypesEnum.UnknownKey &&
					alertType.id !== AlertTypesEnum.UnauthorizedDrive &&
					alertType.id !== AlertTypesEnum.DrivingWithoutAuthentication
			);
		}
		if (!geoFence) {
			alertTypeDatasource = alertTypeDatasource.filter((alertType) => alertType.id !== AlertTypesEnum.Geofence);
		}
		return alertTypeDatasource;
	};
	const getDigitalTemplatesList = async () => {
		const digitalTemplates = await ajaxUtil.get<AutocompleteItem[]>(
			`${GlobalSettings.listsApi}/digitalTemplatesList?customerId=${alert.customerId}`
		);
		if (digitalTemplates) {
			setDigitalTemplates(digitalTemplates);
			setDigitalTemplatesLoaded(true);
		}
	};

	const getAnalogTemplatesList = async () => {
		const analogTemplates = await ajaxUtil.get<AnalogTemplateItem[]>(
			`${GlobalSettings.listsApi}/analogTemplatesList?customerId=${alert.customerId}`
		);
		if (analogTemplates) {
			setAnalogTemplates(analogTemplates);
			setAnalogTemplatesLoaded(true);
		}
	};

	const getAnalogStatesDataSource = () => {
		if (alert.analogAlertMetadata.templateAnalogId !== null) {
			return analogStatesTemplate;
		}
		return [];
	};
	const privateMileageTypeData = () => {
		return (
			<div>
				<div className="form-group" id="dynamicTypeContent">
					<hr
						style={{
							color: '#cfd0d1',
							backgroundColor: '#cfd0d1',
							height: 0.1,
							borderColor: '#cfd0d1',
						}}
					/>
					<div className="form-group">
						<MaterialTextField
							isForNewAddWizard={true}
							id="alertThreshold"
							type="number"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.threshold')}
							inputProps={{
								style: { fontSize: 10 },
								inputProps: {
									min: 0,
									max: 999999999,
									readOnly: false,
								},
							}}
							name="alertThreshold"
							value={alert.PrivateMileageAlertMetadata.thresholdValue.toString()}
							onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
								if (event.key === 'e' || event.key === '+' || event.key === '-') {
									event.preventDefault();
								}
							}}
							handleValueChange={(value) => handlePrivateMileageValuesChange(+value, 'thresholdValue')}
							disabled={false}
							validationResult={validationResult?.alertThreshold}
						/>
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							isForNewAddWizard={true}
							name="preTreshold"
							className="material-autocomplete report-selection-component"
							valueId={alert.PrivateMileageAlertMetadata.preThresholds}
							dataSource={[
								{ display: '0', id: 0 },
								{ display: '1', id: 1 },
								{ display: '2', id: 2 },
								{ display: '3', id: 3 },
							]}
							onChange={(args: any) => handlePrivateMileageValuesChange(args.value, 'preThresholds')}
							disabled={false}
							fullWidth
							label={TranslateText('fields.preThresholds')}
							disableClearable
							validationResult={validationResult?.preThreshold}
						/>
					</div>
					{alert.PrivateMileageAlertMetadata.preThresholds >= 1 ? (
						<Box marginBottom={'0.5rem'}>
							<div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 20,
										display: 'inline-grid',
										width: 60,
									}}
								>
									{TranslateText('fields.firstAt') + ':'}{' '}
								</span>
								<div
									className="form-group"
									style={{
										fontSize: 10,
										display: 'inline-block',
										marginBottom: 0,
									}}
								>
									<MaterialTextField
										isForNewAddWizard={true}
										id="firstPreThresholdAlert"
										type="number"
										inputProps={{
											style: { fontSize: 10, width: 260, maxWidth: 260 },
											inputProps: {
												min: 1,
												max: 99,
											},
										}}
										name="firstPreThresholdAlert"
										value={alert.PrivateMileageAlertMetadata.firstPreThresholdAlert.toString()}
										onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
											if (event.key === 'e' || event.key === '+' || event.key === '-') {
												event.preventDefault();
											}
										}}
										handleValueChange={(value) =>
											handlePrivateMileageValuesChange(+value, 'firstPreThresholdAlert')
										}
										disabled={false}
										label={null}
										validationResult={validationResult?.firstPreThresholdAlert}
									/>
								</div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 10,
										display: 'inline-grid',
									}}
								>
									{TranslateText('fields.percentOfThreshold')}
								</span>
							</div>
						</Box>
					) : null}
					{2 <= alert.PrivateMileageAlertMetadata.preThresholds ? (
						<Box marginBottom={'0.5rem'}>
							<div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 20,
										display: 'inline-grid',
										width: 60,
									}}
								>
									{TranslateText('fields.secondAt') + ':'}
								</span>
								<div
									className="form-group"
									style={{
										display: 'inline-block',
										marginBottom: 0,
									}}
								>
									<MaterialTextField
										isForNewAddWizard={true}
										id="secondPreThresholdAlert"
										type="number"
										inputProps={{
											style: { fontSize: 10, width: 260, maxWidth: 260 },
											inputProps: {
												min: 0,
												max: 100,
												readOnly: false,
											},
										}}
										name="secondPreThresholdAlert"
										value={alert.PrivateMileageAlertMetadata.secondPreThresholdAlert.toString()}
										onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
											if (event.key === 'e' || event.key === '+' || event.key === '-') {
												event.preventDefault();
											}
										}}
										handleValueChange={(value) =>
											handlePrivateMileageValuesChange(+value, 'secondPreThresholdAlert')
										}
										disabled={false}
										label={null}
										validationResult={validationResult?.secondPreThresholdAlert}
									/>
								</div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 10,
										display: 'inline-grid',
									}}
								>
									{TranslateText('fields.percentOfThreshold')}
								</span>
							</div>
						</Box>
					) : null}

					{3 <= alert.PrivateMileageAlertMetadata.preThresholds ? (
						<Box marginBottom={'0.5rem'}>
							<div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 20,
										display: 'inline-grid',
										width: 60,
									}}
								>
									{`${TranslateText('fields.thirdAt')}:`}
								</span>
								<div
									className="form-group"
									style={{
										display: 'inline-block',
										marginBottom: 0,
									}}
								>
									<MaterialTextField
										isForNewAddWizard={true}
										id="thirdPreThresholdAlert"
										type="number"
										inputProps={{
											style: { fontSize: 10, width: 260, maxWidth: 260 },
											inputProps: {
												min: 0,
												max: 100,
												readOnly: false,
											},
										}}
										name="thirdPreThresholdAlert"
										value={alert.PrivateMileageAlertMetadata.thirdPreThresholdAlert.toString()}
										onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
											if (event.key === 'e' || event.key === '+' || event.key === '-') {
												event.preventDefault();
											}
										}}
										handleValueChange={(value) =>
											handlePrivateMileageValuesChange(+value, 'thirdPreThresholdAlert')
										}
										disabled={false}
										label={null}
										validationResult={validationResult?.thirdPreThresholdAlert}
									/>
								</div>
								<span
									style={{
										fontSize: 10,
										marginLeft: 10,
										display: 'inline-grid',
									}}
								>
									{TranslateText('fields.percentOfThreshold')}
								</span>
							</div>
						</Box>
					) : null}
					<hr
						style={{
							color: '#cfd0d1',
							backgroundColor: '#cfd0d1',
							height: 0.1,
							borderColor: '#cfd0d1',
						}}
					/>
				</div>
			</div>
		);
	};
	const durationExceededTypeData = () => {
		return (
			<div className="form-group" id="durationExceededContent">
				<hr
					style={{
						color: '#cfd0d1',
						backgroundColor: '#cfd0d1',
						height: 0.1,
						borderColor: '#cfd0d1',
					}}
				/>
				<AlertDurationExceeded
					durationExceededMetadata={alert.DurationExceededAlertMetadata}
					handleDurationExceededMetadataChange={(metadata: DurationExceededAlertMetadata) => {
						if (
							metadata.duration === null ||
							metadata.duration <= 0 ||
							metadata.durationCounter === null ||
							metadata.durationCounter <= 0 ||
							metadata.templateDurationId === null ||
							metadata.templateDigitalId === null
						) {
							props.setValidationCallback(false);
						}
						const newAlert = { ...alert, DurationExceededAlertMetadata: metadata };
						if (
							newAlert.eventTypeId === AlertTypesEnum.DurationExceeded &&
							newAlert.DurationExceededAlertMetadata.relatedToTime &&
							newAlert.DurationExceededAlertMetadata.evaluationMoment ===
								EvaluationMomentEnum.EndOfTimeFrame
						) {
							newAlert['eventFrequency'] = EventFrequencyEnum.Once;
						}
						setAlert(newAlert);
					}}
					setValidationCallback={(isValid: boolean) => (validationDurationExceededAlert.current = isValid)}
					isForNewAddWizard={true}
				/>
			</div>
		);
	};
	const digitalTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="templateDigitalId"
						className="material-autocomplete report-selection-component"
						valueId={alert.digitalAlertMetadata.templateDigitalId}
						dataSource={digitalTemplates}
						onChange={(args: any) => handleDigitalTemplateChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('common.template')}
						validationResult={validationResult?.templateDigitalId}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="digitalTriggerCondition"
						className="material-autocomplete report-selection-component"
						valueId={alert.digitalAlertMetadata.triggerCondition}
						dataSource={digitalTriggerConditionDataSource}
						onChange={(args: any) => handleDigitalTriggerConditionChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('alert.sendNotification')}
						validationResult={validationResult?.digitalTriggerCondition}
					/>
				</div>
			</div>
		);
	};
	const analogTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="templateAnalogId"
						className="material-autocomplete report-selection-component"
						valueId={alert.analogAlertMetadata.templateAnalogId}
						dataSource={analogTemplates}
						onChange={(args: any) => handleAnalogTemplateChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('common.template')}
						validationResult={validationResult?.templateAnalogId}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="analogTriggerCondition"
						className="material-autocomplete report-selection-component"
						valueId={alert.analogAlertMetadata.triggerCondition}
						dataSource={analogTriggerConditionDataSource}
						onChange={(args: any) => handleAnalogTriggerConditionChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('alert.sendNotification')}
						validationResult={validationResult?.analogTriggerCondition}
					/>
				</div>
				{alert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.ToSpecificState ||
				alert.analogAlertMetadata.triggerCondition === AlertAnalogTriggerConditionEnum.FromSpecificState ? (
					<div className="form-group">
						<MaterialAutocomplete
							isForNewAddWizard={true}
							name="templateAnalogStateId"
							className="material-autocomplete report-selection-component"
							valueId={alert.analogAlertMetadata.templateAnalogStateId}
							dataSource={getAnalogStatesDataSource()}
							onChange={(args: any) => handleAnalogTemplateStateChange(args.value)}
							disabled={false}
							fullWidth={false}
							label={TranslateText('alert.state')}
							validationResult={validationResult?.templateAnalogStateId}
						/>
					</div>
				) : null}
			</div>
		);
	};

	const mainPowerTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="mainPowerTriggerEvent"
						className="material-autocomplete report-selection-component"
						valueId={alert.mainPowerAlertMetadata.mainPowerTriggerEvent}
						dataSource={getMainPowerEventTypeDataSource()}
						onChange={(args: any) => handleMainPowerStateChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('alert.mainPowerState')}
						validationResult={validationResult?.mainPowerTriggerEvent}
					/>
				</div>
			</div>
		);
	};

	const geofenceTypeData = () => {
		return (
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={true}
					name="geofenceType"
					className="material-autocomplete report-selection-component"
					valueId={alert.geofenceAlertMetadata.geofenceType}
					dataSource={geofenceTypesDataSource}
					onChange={(args: any) => handleGeofenceTypeChange(args.value)}
					disabled={false}
					fullWidth={false}
					label={TranslateText('geofence.geofenceType')}
					validationResult={validationResult?.geofenceType}
				/>
			</div>
		);
	};
	const towingTypeData = () => {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						isForNewAddWizard={true}
						name="towingTriggerEvent"
						className="material-autocomplete report-selection-component"
						valueId={alert.towingAlertMetadata.towingTriggerEvent}
						dataSource={getTowingEventTypeDataSource()}
						onChange={(args: any) => handleTowingStateChange(args.value)}
						disabled={false}
						fullWidth={false}
						label={TranslateText('alert.towingState')}
						validationResult={validationResult?.towingTriggerEvent}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={'alert-view-container'}>
			<div className={'alert-column'} style={{ width: '50%' }}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.systemInformation')}</div>
					<form id="alertViewSystemInformationForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="alertCode"
									className="resize-font"
									label={TranslateText('fields.code')}
									inputProps={{ style: { fontSize: 10 } }}
									name="alertCode"
									value={alert.code}
									handleValueChange={(value) => handleValueChange(value, 'code')}
									disabled={false}
									validationResult={validationResult?.code}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="name"
									className="resize-font"
									label={TranslateText('fields.name')}
									inputProps={{ style: { fontSize: 10 } }}
									name="name"
									value={alert.name}
									handleValueChange={(value) => handleValueChange(value, 'name')}
									disabled={false}
									validationResult={validationResult?.name}
								/>
							</div>
							<CustomerNameDisplay
								customerId={!!defaultCustomerId?.length ? defaultCustomerId : alert.customerId}
								isForNewAddWizard={true}
							/>
							<div className="form-group">
								<FormControlLabel
									control={
										<Checkbox
											id="active"
											name="active"
											color={'primary'}
											checked={alert.active}
											readOnly={true}
											onChange={() => {
												handleValueChange(alert.active, 'active');
											}}
											disabled={true}
										/>
									}
									label={
										<Typography style={{ fontSize: 10, marginRight: 5 }}>
											{TranslateText('fields.active')}
										</Typography>
									}
									labelPlacement="start"
									style={{ margin: 0 }}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className={'alert-column'} style={{ width: '50%' }}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.eventData')}</div>
					<form id="alertDataForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									name="eventTypeId"
									className="material-autocomplete report-selection-component"
									valueId={alert.eventTypeId}
									dataSource={getAlertTypeDatasource()}
									onChange={(args: any) => handleEventTypeChange(args.value)}
									disabled={false}
									fullWidth
									label={TranslateText('fields.type')}
									validationResult={validationResult?.eventTypeId}
								/>
							</div>
							{alert.eventTypeId === AlertTypesEnum.PrivateMileage
								? privateMileageTypeData()
								: alert.eventTypeId === AlertTypesEnum.DurationExceeded
								? durationExceededTypeData()
								: alert.eventTypeId === AlertTypesEnum.Digital
								? digitalTypeData()
								: alert.eventTypeId === AlertTypesEnum.Analog
								? analogTypeData()
								: alert.eventTypeId === AlertTypesEnum.MainPower
								? mainPowerTypeData()
								: alert.eventTypeId === AlertTypesEnum.Geofence
								? geofenceTypeData()
								: alert.eventTypeId === AlertTypesEnum.Towing
								? towingTypeData()
								: null}
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									name="eventFrequency"
									className="material-autocomplete"
									valueId={alert.eventFrequency}
									dataSource={getAlertFrequencyDatasource()}
									onChange={(args: any) => handleValueChange(args.value, 'eventFrequency')}
									disabled={
										alert.eventTypeId === AlertTypesEnum.DurationExceeded &&
										alert.DurationExceededAlertMetadata.relatedToTime &&
										alert.DurationExceededAlertMetadata.evaluationMoment ===
											EvaluationMomentEnum.EndOfTimeFrame
									}
									fullWidth={false}
									label={TranslateText('fields.frequencyType')}
									validationResult={validationResult?.eventFrequency}
								/>
							</div>
							<div>
								<AlertNotificationType alert={alert} setAlert={changeCheck} readOnly={false} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default AlertView;
