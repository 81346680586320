import { IconButton } from '@material-ui/core';
import { SuperscriptIcon } from 'components/Common/Icons/SuperscriptIcon';
import { GroupedDropdown } from 'components/GroupedDropdown';
import { GroupConstruct } from 'components/GroupedDropdown/GroupedDropdownContainer/GroupConstruct/types';
import React from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { TranslateText } from 'utils/Translations';

import { AlertMessageTag, AlertMessageTagsButtonProps } from './types';

const AlertMessageTagsButton = ({
	onChange,
	disabled = false,
	size = 'small',
}: AlertMessageTagsButtonProps): JSX.Element => {
	const queryClient: QueryClient = useQueryClient();
	const alertMessageTags: AlertMessageTag = queryClient.getQueryData<AlertMessageTag>(['alert-message-tags']);

	return (
		!!alertMessageTags && (
			<IconButton
				data-testid="alert-message-tags-icon-button"
				color="default"
				className={'MUIRichTextEditor-grouped-dropdown-icon-button'}
				disabled={disabled}
				onMouseDown={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.preventDefault()}
				size={size}
			>
				<GroupedDropdown
					baseComponent={<SuperscriptIcon displayChevron />}
					displaySearchBox
					keepOpenAfterSelection
					groups={Object.keys(alertMessageTags).map(
						(alertMessageTagKey: string): GroupConstruct => ({
							groupName: alertMessageTagKey,
							groupNameDisplayValue: TranslateText(
								`variableGroup.${alertMessageTagKey[0].toLowerCase()}${alertMessageTagKey
									.slice(1)
									.replace(' ', '')}`
							),
							groupVariables: alertMessageTags[alertMessageTagKey].map((groupVariable: string) => ({
								displayValue: TranslateText(`variable.${groupVariable}`),
								value: groupVariable,
							})),
						})
					)}
					onVariableClickedCallback={(_: string, groupVariable: string) => onChange(groupVariable)}
					width="240px"
				/>
			</IconButton>
		)
	);
};

export default AlertMessageTagsButton;
