enum FuelTypeEnum {
	Unknown = 0,
	Gasoline = 1,
	Diesel = 2,
	Hybrid = 3,
	Electric = 4,
	LPG = 5,
	CNG = 6,
	Alcohol = 7,
	LNG = 8,
	Hydrogen = 9,
	BioCNG = 10,
	E85 = 11,
	HVO20_30 = 12,
	HVO100 = 13,
}

export default FuelTypeEnum;
