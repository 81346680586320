const initialState = {
	persons: [] as object[],
	loading: false,
	errors: {},
	forceReload: false,
	showAdd: false,
};

export const personsActionCreators = {
	addPerson: () => (dispatch: any) => {
		const showAdd = true;
		dispatch({ type: 'ADD_PERSON', showAdd });
	},
};

export const personsReducer = (state: any, action: any) => {
	state = state || initialState;

	switch (action.type) {
		case 'ADD_PERSON': {
			return {
				...state,
				showAdd: action.showAdd,
			};
		}
		default:
			return state;
	}
};
