import { useEffect, useState } from 'react';
import { useDebounceCallback } from 'utils/hooks';

const useDebouncedInput = (initialValue: string, debounceTime: number, onChange: (value: string) => void) => {
	const [value, setValue] = useState<string>(initialValue || '');
	const debouncedCall = useDebounceCallback(onChange, debounceTime);

	const handleOnChange = (value: string): void => {
		setValue(value);
		debouncedCall(value);
	};

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return { value, handleOnChange };
};

export { useDebouncedInput };
