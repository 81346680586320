import './styles.scss';

import { ListSelectionItem } from 'components/ListSelectionView/types';
import React, { useMemo } from 'react';
import { TranslateText } from 'utils/Translations';

import { Button, ButtonGroup } from '@material-ui/core';

import { SelectionListPanelProps } from './types';

const SelectionListPanel = (props: SelectionListPanelProps) => {
	const items = useMemo(() => {
		if (props.selection) {
			return [...props.selection].sort((a: ListSelectionItem, b: ListSelectionItem) => {
				return a.name.localeCompare(b.name);
			});
		}
		return [];
	}, [props.selection]);

	return (
		<div className={'selection-list-panel'}>
			<span className={'title'}>{TranslateText('fleetSelection.selectionHeader')}</span>
			<div className={'selection'}>
				{items.map((item) => {
					return (
						<div key={item.id} className={`selection-list-panel-item ${!item.active ? 'inactive' : ''}`}>
							<span className="name">{TranslateText(item.name)}</span>
							<span className={'m-icon delete'} onClick={() => props.removeItem(item)} />
						</div>
					);
				})}
			</div>
			<ButtonGroup fullWidth>
				<Button
					className="e-flat widget-button clearAll"
					disabled={!props.selection.length}
					onClick={props.removeAll}
				>
					{TranslateText('fleetSelection.clearAll')}
				</Button>
			</ButtonGroup>
		</div>
	);
};

export default SelectionListPanel;
