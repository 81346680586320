import React from 'react';
import { useDispatch } from 'react-redux';

import { Marker } from '@react-google-maps/api';

import { historyStoreActionCreators, ProcessedTripDto } from '../../store/HistoryStore';
import { LatLng } from '../../store/LiveMap';
import { getContrastColor, LightColorLabel } from '../../utils/TripUtils';
import { TripIcons } from './RietveldMap';

interface Props {
	trip: ProcessedTripDto;
	highlight: boolean;
	tripCenterPosition: LatLng;
	renderForTableView?: boolean;
	tripIndex?: number;
}

const TripNumberMarker = (props: Props) => {
	const dispatch = useDispatch();

	const handleTripClick = (controlPressed: boolean) => {
		dispatch(historyStoreActionCreators.showDetailedTripOnMap(props.trip.idWithParent, controlPressed));
	};

	const fontColor = getContrastColor(props.trip.tripColor);

	return (
		<Marker
			zIndex={(props.highlight ? 1000 : 100) + (props.tripIndex ?? 0)}
			onClick={
				!props.renderForTableView
					? (e) => {
							handleTripClick((e.domEvent as MouseEvent).ctrlKey);
					  }
					: null
			}
			position={props.tripCenterPosition}
			label={{
				text: props.trip.isInProgress ? '-' : (props.trip.index + 1).toString(),
				color: fontColor,
				fontWeight: fontColor === LightColorLabel ? 'bold' : null,
			}}
			options={{
				icon: {
					path: TripIcons.tripNumber,
					fillColor: props.trip.tripColor,
					strokeWeight: 0,
					fillOpacity: 1,
					anchor: new google.maps.Point(15, 10),
					labelOrigin: new google.maps.Point(13, 13),
				},
			}}
		/>
	);
};

export default TripNumberMarker;
