import { History, LocationState } from 'history';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import { GridOverview } from '../GridOverview/GridOverview';

interface Props {
	history: History<LocationState>;
}

const TranslationsOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const filterText = useSelector((s: ApplicationState) => s.globalCustomer.filterText.translations);
	const [translation, setTranslation] = useState(null);

	const onEditorValueChange = (rowData: any, changedValue: any) => {
		const translationDto = {
			id: rowData.id,
			language: rowData.language,
			key: rowData.key,
			value: changedValue,
		};
		setTranslation(translationDto);
	};

	const inputTextEditor = (evt: any) => {
		return (
			<InputText
				type="text"
				style={{ height: '2rem' }}
				defaultValue={evt.rowData.value}
				onChange={(e: any) => onEditorValueChange(evt.rowData, e.target.value)}
			/>
		);
	};

	const saveEditedRow = useCallback(() => {
		if (translation) {
			return new Promise<void>((resolve) => {
				ajaxUtil.post(`${GlobalSettings.translationsApi}/update`, translation).then(() => {
					resolve();
				});
			});
		}

		return new Promise<void>((resolve) => resolve());
	}, [translation]);

	const columns = [
		<Column
			key="key"
			field="key"
			header={TranslateText('maintenanceOverview.grid.colKey')}
			style={{ width: '300px', textAlign: 'left' }}
		/>,
		<Column
			key="value"
			field="value"
			header={TranslateText('maintenanceOverview.grid.colValue')}
			style={{ width: '300px', textAlign: 'left' }}
			editor={(evt) => inputTextEditor(evt)}
		/>,
		<Column
			key="language"
			field="language"
			header={TranslateText('maintenanceOverview.grid.colLanguage')}
			style={{ width: '300px', textAlign: 'left' }}
		/>,
	];

	if (
		ClaimUtil.validateClaim(user, {
			claim: ClaimType.Translations,
			values: [ClaimValue.edit],
		})
	) {
		columns.push(
			<Column key="editor" rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} />
		);
	}

	return (
		<GridOverview
			key={'gridOverview'}
			history={props.history}
			filter={filterText}
			getGridDataUrl={`${GlobalSettings.translationsApi}/getPage`}
			entityType={EntityTypeEnum.Translation}
			headerText={TranslateText('maintenanceOverview.translations')}
			disabledAddButton={true}
			showAddConfigClaim={[
				{
					claim: ClaimType.Customers,
					values: [ClaimValue.edit],
				},
			]}
			columns={columns}
			editedRowSaved={saveEditedRow}
			disabledImportButton={true}
			disableExportButton={true}
		/>
	);
};

export default TranslationsOverview;
