import './styles.scss';

import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import AlertDigitalTriggerConditionEnum from 'models/AlertDigitalTriggerConditionEnum';
import React, { useEffect, useState } from 'react';
import ajaxUtil from 'utils/Ajax';
import { getDigitalTriggerConditions } from 'utils/AlertUtils';
import { TranslateText } from 'utils/Translations';

import { Divider, Grid } from '@material-ui/core';

import { DigitalAlertDataProps } from './types';

const DigitalAlertData = (props: DigitalAlertDataProps) => {
	const [digitalTriggerConditions] = useState(() => getDigitalTriggerConditions());
	const [digitalTemplates, setDigitalTemplates] = useState<AutocompleteItem[]>([]);

	//load digital templates
	useEffect(() => {
		if (props.digitalAlertMetadata.templateDigitalId) {
			getDigitalTemplatesList();
		}
	}, []);

	const getDigitalTemplatesList = async () => {
		const digitalTemplates = await ajaxUtil.get<AutocompleteItem[]>(
			`${GlobalSettings.listsApi}/digitalTemplatesList?customerId=${props.alertCustomerId}&selectedTemplateId=${props.digitalAlertMetadata.templateDigitalId}`
		);

		setDigitalTemplates(digitalTemplates);
	};

	//digital template change
	const onDigitalTemplateChange = async (arg: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => {
		props.handleDigitalAlertMetadataChange({
			...props.digitalAlertMetadata,
			templateDigitalId: arg.value as string,
		});
	};

	//trigger condition change
	const onTriggerConditionChange = async (arg: {
		event: React.ChangeEvent<{}>;
		value: string | number | boolean;
	}) => {
		props.handleDigitalAlertMetadataChange({
			...props.digitalAlertMetadata,
			triggerCondition: arg.value as AlertDigitalTriggerConditionEnum,
		});
	};

	return (
		<>
			<Grid className={'col'} item xs>
				{TranslateText('common.template')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'digitalTemplate'}
					className="digital-alert-event-autocomplete"
					valueId={props.digitalAlertMetadata.templateDigitalId}
					dataSource={digitalTemplates}
					onChange={onDigitalTemplateChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
					disabledItems
				/>
				{props.validationResult?.validationResult?.templateDigitalId && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.templateDigitalId} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid className={'col'} item xs>
				{TranslateText('alert.sendNotification')}
			</Grid>
			<Grid className={'vertical-divider'} item xs={1}>
				<Divider orientation={'vertical'} />
			</Grid>
			<Grid className={'col'} item xs>
				<MaterialAutocomplete
					name={'triggerCondition'}
					className="digital-alert-event-autocomplete"
					valueId={props.digitalAlertMetadata.triggerCondition}
					dataSource={digitalTriggerConditions}
					onChange={onTriggerConditionChange}
					disabled={!props.isEditing}
					fullWidth={false}
					disableClearable
				/>
				{props.validationResult?.validationResult?.triggerCondition && (
					<div className={'validation-error-container'}>
						<ValidationMessage result={props.validationResult.validationResult.triggerCondition} />
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

export default DigitalAlertData;
