import './importDialog.scss'

import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {TranslateText} from "../../../utils/Translations";
import FileUploadIcon from "../../Common/Icons/FileUploadIcon";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Icon,
	IconButton,
	LinearProgress,
	Typography
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import NotificationPrompt from "../../../shared/components/UserPrompt/NotificationPrompt";
import ajaxUtil from "../../../utils/Ajax";
import {ApplicationState} from "../../../store";
import {FileDownload} from "../../ReportsOverview/FileDownload";
import {base64ToArrayBuffer} from "../../ReportsOverview/GeneratedReportsOverview";
import {unsavedDataStoreActionCreators} from "../../../store/UnsavedDataStore";

interface ImportDialogProps{
	open: boolean;
	setOpen: (value: boolean) => void;
	entityType: EntityTypeEnum;
	importUrlData: string;
	setOnClose: (value: boolean) => void;
}


const ImportDialog = (props: ImportDialogProps) => {

	const dispatch = useDispatch();
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.id
			: s.currentSession.customerId
	);
	const [includeInactive, setIncludeInactive] = useState(false);
	const inputFileUpload = useRef<HTMLInputElement>(null);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [importResult, setImportResult] = useState<{ message: string; success: boolean }>(null);
	const [validationMessage, setValidationMessage] = useState<string>(null);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [isImporting, setIsImporting] = useState(false);
	const [counterImporting, setCounterImporting] = useState<number>(0);
	const [popupConfirmation, setPopupConfirmation] = useState<{
		title: string;
		message: string;
		userResponse: (response: boolean) => void;
	} | null>();
	const validateXlsxFormat = (name: string) => {
		return name.endsWith('.xlsx');
	};

	useEffect(() => {
		setUnsavedData(selectedFile != null || includeInactive);
		dispatch(unsavedDataStoreActionCreators.setUnsavedData(selectedFile != null || includeInactive));
	},[selectedFile, includeInactive]);

	const getTitle = (type: EntityTypeEnum): string => {
		return TranslateText(`maintenanceOverview.import.${type.toLowerCase()}`);
	}

	const getNumberOfErrors = (str: string) => {
		return (str?.match(/\n/g) || []).length;
	}

	const utils = {
		importFile: async () => {
			const formData = new FormData();
			formData.append('File', selectedFile, selectedFile.name);
			setIsImporting(true);
			const url = props.importUrlData;
			formData.append('IncludeInactiveEntities', includeInactive ? 'true' : 'false');
			const response = await ajaxUtil.post<{ errorMessage: string; success: boolean, numberOfEntitiesImported: number }>(
				`${url}/import/${defaultCustomerId}`,
				formData,
				{
					isFormData: true,
				}
			);
			setImportResult({
				success: response.success,
				message: response.success
					? `${TranslateText('maintenanceOverview.import.successContent')}\n${TranslateText('maintenanceOverview.import.numberImported')}: ${response?.numberOfEntitiesImported}` :
					response.errorMessage,
			});
			if (response.success === false) {
				setValidationMessage(TranslateText('maintenanceOverview.import.conflictImport'));
			}
			else {
				setCounterImporting(response.numberOfEntitiesImported);
			}
			setIsImporting(false);
		},
		download: () => {
			const url = `${props.importUrlData}/getImportTemplateXlsx`;
			setIsImporting(true);
			ajaxUtil
				.get(url)
				.then((data) => {
					setIsImporting(false);
					const templateDownload = data as FileDownload;
					const bytes = base64ToArrayBuffer(templateDownload.content);
					const url = window.URL.createObjectURL(new Blob([bytes]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', templateDownload.fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((err) => {
					setIsImporting(false);
					console.log(err);
				});
		},
		cancelConfirmation: async () => {
			return new Promise<boolean>((resolve) => {
				setPopupConfirmation({
					title: TranslateText('common.titleUnsavedData'),
					message: TranslateText('notificationMessages.cancel'),
					userResponse: (response: boolean) => {
						resolve(response);
						setPopupConfirmation(null);
					},
				});
			});
		},
	}

	const events = {
		onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => {
			if (!e?.target?.files[0]) {
				return;
			}
			if (e?.target?.files?.length && validateXlsxFormat(e?.target?.files[0].name)) {
				setSelectedFile(e.target.files[0]);
				setValidationMessage('');
			} else {
				setImportResult({message: TranslateText('dataImport.wrongFileExtension'), success: false});
			}
		},
		onInactiveChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			setIncludeInactive(event.target.checked);
		},
		handleUploadClick: () => {
			if (inputFileUpload.current) {
				inputFileUpload.current.click();
			}
		},
		onClose: async (force: boolean) => {
			if (unsavedData) {
				if(force === true || importResult?.success === true || (await utils.cancelConfirmation())) {
					if(counterImporting > 0)
						props.setOnClose(true);
					props.setOpen(false);
					setValidationMessage('');
					setSelectedFile(null);
					setIncludeInactive(false);
					setUnsavedData(false);
					dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
					setImportResult(null);
					setCounterImporting(0);
				}
			}
			else {
				props.setOpen(false);
			}

		},
	}

	return (
		<>
			{props.open ? <>
			{popupConfirmation ? (
				<NotificationPrompt
					title={popupConfirmation.title}
					message={popupConfirmation.message}
					handleUserResponse={popupConfirmation.userResponse}
				/>
			) : null}
			<input
				ref={inputFileUpload}
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				style={{display: 'none'}}
				id="input-file-upload"
				type="file"
				onChange={events.onFileChange}
			/>
			<Dialog
				open={props.open}
				onClose={events.onClose}
				className={'import-dialog import-download-dialog'}
			>
				{!importResult || importResult?.message && !importResult.success ?
					<>
				<DialogTitle id="parent-dialog-title">{getTitle(props?.entityType)}</DialogTitle>
				<DialogContent>
					<Box className={'content-dialog'}>
						<DialogContentText>
							{TranslateText('maintenanceOverview.import.description')}
						</DialogContentText>
						<div className="selected-file-container">
									<span className={"selected-file-name"}>
										{selectedFile ? selectedFile.name : ''}
									</span>
							{selectedFile ? (
								<IconButton
									disabled={isImporting}
									onClick={() => {
										setSelectedFile(null);
										setImportResult(null);
										setIncludeInactive(false);
										setValidationMessage('');
										if (inputFileUpload.current) {
											inputFileUpload.current.value = null;
										}
									}}
									size={'small'}
								>
									<ClearIcon/>
								</IconButton>
							) : null}
						</div>
						<div>
							<span className="error-validation-message">
								{validationMessage ? validationMessage : ''}
							</span>
						</div>
						{selectedFile && props.entityType !== EntityTypeEnum.Location ?
							<div className="form-group">
							<FormControlLabel
								disabled={isImporting || !!validationMessage}
								control={
									<Checkbox
										checked={includeInactive}
										onChange={events.onInactiveChange}
										name="inactiveEntities"
										color="primary"
									/>
								}
								label={
									<Typography style={{marginLeft: 5}}>
										{TranslateText('dataImport.checkboxLabel')}
									</Typography>
								}
								style={{marginTop: 12, marginLeft: 0, marginRight: 0, marginBottom: 2}}
							/>
						</div> : null}
						{isImporting &&
						<div className="loading-container">
							<LinearProgress className="linear-progress" />
						</div>
						}
						{importResult?.message &&
							<pre className={`scrollable-container ${getNumberOfErrors(importResult?.message) > 3 ? 'container-errors' : ''}`}>
								{getNumberOfErrors(importResult?.message) === 1 ?  importResult?.message.replace(/\n/g, '') : importResult?.message}
							</pre>
						}
					</Box>
				</DialogContent>
				<DialogActions className={'dialog-buttons-actions'}>
					<Button
						startIcon={<Icon>download</Icon>}
						disabled={isImporting || !!validationMessage}
						className={'download-button-custom-dialog'}
						onClick={() => utils.download()}
						color="primary"
					>
						{TranslateText('maintenanceOverview.import.downloadTemplateButton')}
					</Button>
					<div style={{flex: '1 0 0'}}/>
					{!selectedFile
						? <label
							className="input-file-label"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								events.handleUploadClick();
							}}
							htmlFor="input-file-upload"
						>
							<Button
								startIcon={<FileUploadIcon/>}
								disabled={isImporting || !!validationMessage}
								className={'import-button-custom-dialog'}
								color="primary"
								autoFocus
							>
								{TranslateText('maintenanceOverview.import.fileUploadButton')}
							</Button>
						</label>
						: <Button
							startIcon={<CloudUploadIcon/>}
							disabled={!selectedFile || isImporting || !!validationMessage}
							className={'import-button-custom-dialog'}
							onClick={utils.importFile}
							color="primary"
							autoFocus
						>
							{TranslateText('maintenanceOverview.import.importButton')}
						</Button>
					}
				</DialogActions>
				</>
				:<>
						<DialogTitle id="scroll-dialog-title">
							{importResult.success
								? TranslateText('dataImport.popupTitleSuccess')
								: TranslateText('dataImport.popupTitleFail')}
						</DialogTitle>
						<DialogContent dividers>
							<Box className={'content-dialog'}>
							<pre>{importResult.message}</pre>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => events.onClose(true)} color="primary">
								{TranslateText('common.buttonOk')}
							</Button>
						</DialogActions>
				</>}
			</Dialog>
			</> : null}
			</>
	);
}

export default ImportDialog;
