import { History, LocationState } from 'history';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import NumberColumn from 'components/GridOverview/Columns/NumberColumn';
import PrivacySettingsEnum from 'models/PrivacySettingsEnum';
import TripTypesEnum from 'models/TripTypesEnum';
import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimConfig, ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { GridEntity } from '../GridOverview/GridOverview';
import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import AuthenticationTypeEnum from "../../models/AuthenticationTypeEnum";

interface Props {
	history: History<LocationState>;
	getGridDataUrl: string;
	showAddConfigClaim?: ClaimConfig[];
	entityType: EntityTypeEnum;
	headerText: string;
	disabledAddButton: boolean;
	columns: ReactNode[];
	filter: string;
	editedRowSaved?: () => Promise<void>;
}

interface DriverOverviewDto extends GridEntity {
	id: string;
	code: string;
	externalCode: string;
	firstName: string;
	lastName: string;
	userName: string;
	email: string;
	phoneNumber: string;
	keypadCode?: number;
	active: boolean;
	keyIds: string[];
	keyCodes: string[];
	objectId?: string;
	objectName: string;
	canViewObject: boolean;
	totalPrivateMileage: number;
}

const fieldNames = {
	code: 'code',
	externalCode: 'externalCode',
	firstName: 'firstName',
	lastName: 'lastName',
	username: 'username',
	email: 'email',
	phoneNumber: 'phoneNumber',
	keyCodes: 'keyCodes',
	objectName: 'objectName',
	active: 'active',
	totalPrivateMileage: 'totalPrivateMileage',
	keypadCode: 'keypadCode'
};

const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.externalCode]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.firstName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.lastName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.username]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.email]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.phoneNumber]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.keyCodes]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.keypadCode]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.objectName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.totalPrivateMileage]: {
			value: null,
			matchMode: MatchMode.GreaterThanOrEqual,
		}
	},
};

const DriversOverview = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const customerPrivacySettings = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.privacySettings
			: s.currentSession.customer.featuresSettings.privacySettings
	);
	const customerDefaultDeviceAuthenticationType = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer?.entityDefaultsSettings.defaultDeviceAuthenticationType.valueOf()
			: s.currentSession.customer.entityDefaultsSettings.defaultDeviceAuthenticationType.valueOf());

	const isDriver = useSelector((state: ApplicationState) => state.currentSession.isDriver);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);

	const tripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);
	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	const showTotalPrivateMileage =
		trackTypeSpecification &&
		!!(tripTypes & TripTypesEnum.Private) &&
		(customerPrivacySettings !== PrivacySettingsEnum.HidePrivateData || userCanSeePrivateData || isDriver);

	const keyData = () => {
		if(customerDefaultDeviceAuthenticationType != AuthenticationTypeEnum.Keypad)
			return TextColumn({
				fieldName: fieldNames.keyCodes,
				header: TranslateText('common.keys'),
				sortable: true,
				filterable: true,
				shown: ClaimUtil.validateHasClaim(user, ClaimType.Keys),
				body: (rowData: DriverOverviewDto) =>
					rowData.keyIds.map((keyId, i) => (
						<span key={keyId} title={rowData.keyCodes[i]}>
						<Link to={`${GlobalSettings.route.keys}/${keyId}`}>{rowData.keyCodes[i]}</Link>{' '}
					</span>
					)),
			});

		return NumberColumn({
			fieldName: fieldNames.keypadCode,
			header: TranslateText('common.keypadCode'),
			sortable: true,
			filterable: true,
			body: (rowData: DriverOverviewDto) => {
				const data = rowData.keypadCode == null ? '' : rowData.keypadCode.toString();
				return <span title={data}>{data}</span>;
			}
		});
	}

	const columns = [
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.externalCode,
			header: TranslateText('maintenanceOverview.grid.colExternalCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.firstName,
			header: TranslateText('maintenanceOverview.grid.colFirstName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.lastName,
			header: TranslateText('maintenanceOverview.grid.colLastName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.username,
			header: TranslateText('maintenanceOverview.grid.colUserName'),
			sortable: true,
			filterable: true,
			body: (rowData: DriverOverviewDto) => {
				let userName = rowData.userName;
				const data = rowData.userName.split('.');
				if (data.length > 1) {
					userName = data[1];
				}
				return <span title={userName}>{userName}</span>;
			},
		}),
		TextColumn({
			fieldName: fieldNames.email,
			header: TranslateText('fields.emailAddress'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.phoneNumber,
			header: TranslateText('fields.phoneNumber'),
			sortable: true,
			filterable: true,
		}),
		NumberColumn({
			fieldName: fieldNames.totalPrivateMileage,
			header: TranslateText('liveExtendedMenuOverallTab.totalPrivateMileage'),
			sortable: true,
			filterable: true,
			maxFractionDigits: 1,
			defaultWidth: 170,
			body: (rowData: DriverOverviewDto) => {
				const data = rowData.totalPrivateMileage === -1 ? '****' : rowData.totalPrivateMileage.toString();
				return <span title={data}>{data}</span>;
			},
			shown: showTotalPrivateMileage,
		}),
		keyData()
		,
		TextColumn({
			fieldName: fieldNames.objectName,
			header: TranslateText('maintenanceOverview.grid.colObjectName'),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateHasClaim(user, ClaimType.Objects),
			body: (rowData: DriverOverviewDto) =>
				rowData.canViewObject ? (
					<span title={rowData.objectName}>
						<Link to={`${GlobalSettings.route.objects}/${rowData.objectId}`}>{rowData.objectName}</Link>
					</span>
				) : (
					<span title={rowData.objectName}>{rowData.objectName}</span>
				),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.driversMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.drivers')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default}
			showAddConfigClaim={[
				{
					claim: ClaimType.Drivers,
					values: [ClaimValue.edit],
				},
			]}
			entityType={EntityTypeEnum.Driver}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};

export default DriversOverview;
