import FuelTypeEnum from '../../models/FuelTypeEnum';
import { TranslateText } from '../../utils/Translations';

const FuelTypeDataSource = {
	dataSource: () => [
		{
			id: FuelTypeEnum.Unknown,
			display: TranslateText('objects.fuelType.Unknown'),
		},
		{
			id: FuelTypeEnum.Gasoline,
			display: TranslateText('objects.fuelType.Gasoline'),
		},
		{
			id: FuelTypeEnum.Diesel,
			display: TranslateText('objects.fuelType.Diesel'),
		},
		{
			id: FuelTypeEnum.Hybrid,
			display: TranslateText('objects.fuelType.Hybrid'),
		},
		{
			id: FuelTypeEnum.Electric,
			display: TranslateText('objects.fuelType.Electric'),
		},
		{
			id: FuelTypeEnum.LPG,
			display: TranslateText('objects.fuelType.LPG'),
		},
		{
			id: FuelTypeEnum.CNG,
			display: TranslateText('objects.fuelType.CNG'),
		},
		{
			id: FuelTypeEnum.Alcohol,
			display: TranslateText('objects.fuelType.Alcohol'),
		},
		{
			id: FuelTypeEnum.LNG,
			display: TranslateText('objects.fuelType.LNG'),
		},
		{
			id: FuelTypeEnum.Hydrogen,
			display: TranslateText('objects.fuelType.Hydrogen'),
		},
		{
			id: FuelTypeEnum.BioCNG,
			display: TranslateText('objects.fuelType.BioCNG'),
		},
		{
			id: FuelTypeEnum.E85,
			display: TranslateText('objects.fuelType.E85'),
		},
		{
			id: FuelTypeEnum.HVO20_30,
			display: TranslateText('objects.fuelType.HVO20_30'),
		},
		{
			id: FuelTypeEnum.HVO100,
			display: TranslateText('objects.fuelType.HVO100'),
		},
	],
};

export default FuelTypeDataSource;
