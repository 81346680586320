import React from 'react';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { CategoryItemProps } from './types';
import './categoryItem.scss';

const LocationCategoryItem = (props: CategoryItemProps) => {
	return (
		<span
			className={'category-name-row'}
			onClick={() => props.selectCallback(props.locationCategory, !props.selected)}
		>
			<Checkbox
				className="category-selection-checkbox"
				color={'default'}
				checkedIcon={<CheckBoxOutlinedIcon />}
				icon={<CheckBoxOutlineBlankOutlinedIcon />}
				checked={props.selected}
			/>
			<span className="category-selection-name" title={props.locationCategory.name}>
				{props.locationCategory.name}
			</span>
		</span>
	);
};
export default LocationCategoryItem;
