import '../../oldWidget.scss';
import './styles.scss';

import { BaseWidgetFC } from 'components/BaseWidgetFC';
import * as _ from 'lodash';
import Alert from 'models/Alert';
import AlertTypesEnum from 'models/AlertTypesEnum';
import PrivateMileageAlertMetadata from 'models/PrivateMileageAlert';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

import { Button, Divider, Grid } from '@material-ui/core';

import { DEBOUNCE_TIME } from '../../../../Constants';
import DurationExceededAlertMetadata from '../../../../models/DurationExceededAlert';
import EventFrequencyEnum from '../../../../models/EventFrequencyEnum';
import { DebouncedButton } from '../../../Common/DebouncedButton';
import { DurationCounter } from '../../../Views/AlertDurationExceeded/DurationCounter';
import { useAlertDurationExceeded } from '../../../Views/AlertDurationExceeded/hooks';
import { RelatedTimeCheckbox } from '../../../Views/AlertDurationExceeded/RelatedTimeCheckbox';
import { TemplateDropdown } from '../../../Views/AlertDurationExceeded/TemplateDropdown';
import { EvaluationMomentEnum } from '../../../Views/AlertDurationExceeded/TimeFrameEvaluation/EvaluationMomentDropdown/types';
import TimeFrameEvaluationEventWidget from '../../../Views/AlertDurationExceeded/TimeFrameEvaluation/TimeFrameEvaluationEventWidget';
import { AnalogAlertData } from './AnalogAlertData';
import { useAnalogAlerts } from './AnalogAlertData/hooks';
import { DigitalAlertData } from './DigitalAlertData';
import { useDigitalAlerts } from './DigitalAlertData/hooks';
import { EventFrequency } from './EventFrequency';
import { GeofenceAlertData } from './GeofenceAlertData';
import { useGeofenceAlerts } from './GeofenceAlertData/hooks';
import { useAlertEventViewData } from './hooks';
import { PreThresholds } from './PreThresholds';
import { useThresholFieldsdValidation } from './PreThresholds/hooks';
import { Threshold } from './Threshold';
import { AlertEventViewProps, ChangeAbleData } from './types';
import { MainPowerAlertData } from './MainPowerAlertData';
import { useMainPowerAlerts } from './MainPowerAlertData/hooks';
import {useTowingAlerts} from "./TowingAlertData/hooks";
import {TowingAlertData} from "./TowingAlertData";

const AlertEventView = (props: AlertEventViewProps) => {
	const instance = useRef<string>((Math.random() * 100000000000000000).toString());
	const [changeAbleData, setChangeAbleData] = useState<ChangeAbleData>();
	const [thresholdFieldsValid, setThresholdFieldsValid] = useState<boolean>(true);
	const [reInitializeData, setReInitializeData] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);

	const { validateThresholdFields } = useThresholFieldsdValidation();

	const resetChangeAbleData = (alertData: Alert): void => {
		setChangeAbleData({
			eventFrequency: alertData.eventFrequency,
			privateMileageAlertMetadata: alertData.privateMileageAlertMetadata,
			durationExceededAlertMetadata: alertData.durationExceededAlertMetadata,
			analogAlertMetadata: alertData.analogAlertMetadata,
			digitalAlertMetadata: alertData.digitalAlertMetadata,
			mainPowerAlertMetadata: alertData.mainPowerAlertMetadata,
			geofenceAlertMetadata: alertData.geofenceAlertMetadata,
			towingAlertMetadata: alertData.towingAlertMetadata,
			validationMessage: null,
		});
		setReInitializeData(!reInitializeData);
	};

	const { isLoading, isUpdating, alert, updateAlertEvents } = useAlertEventViewData(
		props.entityId,
		resetChangeAbleData
	);

	const handleDurationExceededMetadataChange = (metadata: DurationExceededAlertMetadata) => {
		setChangeAbleData((old: ChangeAbleData) => ({
			...old,
			durationExceededAlertMetadata: metadata,
			eventFrequency:
				metadata.relatedToTime && metadata.evaluationMoment === EvaluationMomentEnum.EndOfTimeFrame
					? EventFrequencyEnum.Once
					: old?.eventFrequency,
		}));
	};

	const {
		setRelatedToTimeChecked,
		setDurationCounterValue,
		handleTimeFrameEvaluationChange,
		relatedToTimeChecked,
		durationCounterValue,
		templateId,
		setTemplateId,
		durationMaxValue,
		validationResult,
		relatedToTimeCheckboxDisabled,
	} = useAlertDurationExceeded(
		changeAbleData?.durationExceededAlertMetadata,
		handleDurationExceededMetadataChange,
		reInitializeData
	);

	useEffect(() => {
		if (!changeAbleData || !alert) {
			return;
		}
		props.changeDataCallback(checkHasUnsavedData());
	}, [changeAbleData]);

	useEffect(() => {
		// reset to initial values after editing data and clicking cancel
		if (!props.editingInstance && alert && !isUpdating) {
			resetChangeAbleData(alert);
		}
		setEditMode(isEditingSelf());
	}, [props.editingInstance]);

	const analogValidationResult = useAnalogAlerts(alert?.alertType, changeAbleData?.analogAlertMetadata);
	const digitalValidationResult = useDigitalAlerts(alert?.alertType, changeAbleData?.digitalAlertMetadata);
	const mainPowerValidationResult = useMainPowerAlerts(alert?.alertType, changeAbleData?.mainPowerAlertMetadata);
	const geofenceValidationResult = useGeofenceAlerts(alert?.alertType, changeAbleData?.geofenceAlertMetadata);
	const towingValidationResult = useTowingAlerts(alert?.alertType, changeAbleData?.towingAlertMetadata);

	const alertTypeDescription: string = useMemo((): string => {
		if (!alert) {
			return '';
		}

		return TranslateText(`alertOverview.alertType.${AlertTypesEnum[alert.alertType]}`);
	}, [alert]);

	const isEditingSelf = () => props.editingInstance === instance.current;

	const checkHasUnsavedData = () =>
		!_.isEqual(changeAbleData, {
			eventFrequency: alert.eventFrequency,
			privateMileageAlertMetadata: alert.privateMileageAlertMetadata,
			durationExceededAlertMetadata: alert.durationExceededAlertMetadata,
			analogAlertMetadata: alert.analogAlertMetadata,
			digitalAlertMetadata: alert.digitalAlertMetadata,
			mainPowerAlertMetadata: alert.mainPowerAlertMetadata,
			geofenceAlertMetadata: alert.geofenceAlertMetadata,
			towingAlertMetadata: alert.towingAlertMetadata,
			validationMessage: changeAbleData.validationMessage,
		});

	const toggleEditPanel = (): void => {
		props.setEditingInstance(!editMode ? instance.current : null);
		props.editModeCallback(!editMode);
	};

	const onCancelButtonClicked = (): void => {
		if (editMode && checkHasUnsavedData()) {
			props.notifyAboutUnsavedData(true);
			props.editModeCallback(!editMode);

			return;
		}

		toggleEditPanel();
	};

	const onSaveButtonClicked = (): void => {
		if (
			!thresholdFieldsValid ||
			(!!validationResult &&
				!validationResult?.formResult &&
				(!!changeAbleData?.durationExceededAlertMetadata?.templateDurationId ||
					!!changeAbleData?.durationExceededAlertMetadata?.templateDigitalId))
		) {
			return;
		}

		props.changeDataCallback(false);
		toggleEditPanel();
		updateAlertEvents({
			eventFrequency: changeAbleData.eventFrequency,
			privateMileageAlertMetadata: changeAbleData.privateMileageAlertMetadata,
			analogAlertMetadata: changeAbleData.analogAlertMetadata,
			digitalAlertMetadata: changeAbleData.digitalAlertMetadata,
			geofenceAlertMetadata: changeAbleData.geofenceAlertMetadata,
			durationExceededAlertMetadata: !!changeAbleData.durationExceededAlertMetadata
				? {
						...changeAbleData.durationExceededAlertMetadata,
						duration: changeAbleData.durationExceededAlertMetadata.relatedToTime
							? changeAbleData.durationExceededAlertMetadata.duration
							: null,
						durationCounter: !changeAbleData.durationExceededAlertMetadata.relatedToTime
							? changeAbleData.durationExceededAlertMetadata.durationCounter
							: null,
				  }
				: null,
			mainPowerAlertMetadata: changeAbleData.mainPowerAlertMetadata,
			towingAlertMetadata: changeAbleData.towingAlertMetadata,
		});
	};

	return (
		<BaseWidgetFC
			id={props.id}
			dataRow={props.row}
			dataCol={props.col}
			sizeX={props.sizeX}
			sizeY={props.sizeY}
			editMode={editMode}
			widgetHeader={'common.eventData'}
			checkHasUnsavedData={checkHasUnsavedData}
			notifyAboutUnsavedData={props.notifyAboutUnsavedData}
			editPanelCallback={onCancelButtonClicked}
			allowEditMode={props.allowEditMode || editMode}
			showEditButton={props.showEditButton}
			buttons={[
				<Button
					key={'alertEvent-remove'}
					className="widget-button cancel-button"
					onClick={() => onCancelButtonClicked()}
					type="submit"
				>
					{TranslateText('common.buttonCancel')}
				</Button>,
				<DebouncedButton
					key={'alertEvent-save'}
					disabled={
						!thresholdFieldsValid ||
						(!!validationResult &&
							!validationResult?.formResult &&
							(!!changeAbleData?.durationExceededAlertMetadata?.templateDurationId ||
								!!changeAbleData?.durationExceededAlertMetadata?.templateDigitalId))
					}
					className="widget-button save-button"
					onClick={() => onSaveButtonClicked()}
					type="submit"
					debounceTime={DEBOUNCE_TIME}
				>
					{TranslateText('common.buttonSave')}
				</DebouncedButton>,
			]}
		>
			{!isLoading && !!changeAbleData && (
				<Grid
					container
					className={'alert-event-widget'}
					spacing={1}
					style={{ color: editMode ? '#000000' : '#b0b0b0' }}
				>
					<Grid className={'col'} item xs style={{ color: '#b0b0b0' }}>
						{TranslateText('common.type')}
					</Grid>
					<Grid className={'vertical-divider'} item xs={1}>
						<Divider orientation={'vertical'} />
					</Grid>
					<Grid className={'col'} item xs style={{ color: '#b0b0b0' }}>
						{alertTypeDescription}
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid className={'col'} item xs>
						{TranslateText('fields.frequencyType')}
					</Grid>
					<Grid className={'vertical-divider'} item xs={1}>
						<Divider orientation={'vertical'} />
					</Grid>
					<Grid className={'col'} item xs>
						<EventFrequency
							initialValue={changeAbleData.eventFrequency}
							name={'eventFrequency'}
							disabled={
								!isEditingSelf() ||
								(changeAbleData.durationExceededAlertMetadata?.relatedToTime &&
									changeAbleData.durationExceededAlertMetadata.evaluationMoment ===
										EvaluationMomentEnum.EndOfTimeFrame)
							}
							onChangeHandler={(eventFrequency: number) =>
								setChangeAbleData((old: ChangeAbleData) => ({
									...old,
									eventFrequency,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					{!!alert.privateMileageAlertMetadata && (
						<>
							<Grid className={'col'} item xs>
								{TranslateText('fields.threshold')}
							</Grid>
							<Grid className={'vertical-divider'} item xs={1}>
								<Divider orientation={'vertical'} />
							</Grid>
							<Grid className={'col'} item xs>
								<Threshold
									id="threshold"
									name="threshold"
									initialValue={changeAbleData.privateMileageAlertMetadata.thresholdValue}
									validationMessage={changeAbleData.validationMessage?.alertThreshold}
									onChangeHandler={(thresholdValue: number | string) => {
										setChangeAbleData((old: ChangeAbleData) => ({
											...old,
											privateMileageAlertMetadata: {
												...old.privateMileageAlertMetadata,
												thresholdValue: thresholdValue as number,
											},
										}));
										const newChangeableData = {
											...changeAbleData.privateMileageAlertMetadata,
											thresholdValue: thresholdValue as number,
										};
										validateThresholdFields(
											newChangeableData,
											(
												privateMileageAlertMetadata: PrivateMileageAlertMetadata,
												validationResult: ValidationResult,
												thresholdValid: boolean
											) => {
												setThresholdFieldsValid(thresholdValid);
												setChangeAbleData((old: ChangeAbleData) => ({
													...old,
													validationMessage: validationResult,
												}));
											}
										);
									}}
									disabled={!isEditingSelf()}
								/>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid container spacing={1} style={{ padding: '4px' }}>
								<PreThresholds
									initialValue={changeAbleData.privateMileageAlertMetadata}
									validationMessage={changeAbleData.validationMessage}
									name={'preThresholds'}
									onChangeHandler={(
										privateMileageAlertMetadata: PrivateMileageAlertMetadata,
										validationResult: ValidationResult,
										prethresholdsValid: boolean
									) => {
										setThresholdFieldsValid(prethresholdsValid);
										setChangeAbleData((old: ChangeAbleData) => ({
											eventFrequency: old.eventFrequency,
											durationExceededAlertMetadata: old.durationExceededAlertMetadata,
											analogAlertMetadata: old.analogAlertMetadata,
											digitalAlertMetadata: old.digitalAlertMetadata,
											geofenceAlertMetadata: old.geofenceAlertMetadata,
											validationMessage: validationResult,
											privateMileageAlertMetadata: {
												thresholdValue: old.privateMileageAlertMetadata.thresholdValue,
												preThresholds: privateMileageAlertMetadata.preThresholds,
												firstPreThresholdAlert:
													privateMileageAlertMetadata.firstPreThresholdAlert,
												secondPreThresholdAlert:
													privateMileageAlertMetadata.secondPreThresholdAlert,
												thirdPreThresholdAlert:
													privateMileageAlertMetadata.thirdPreThresholdAlert,
											},
											mainPowerAlertMetadata: old.mainPowerAlertMetadata,
											towingAlertMetadata: old.towingAlertMetadata,
										}));
									}}
									disabled={!isEditingSelf()}
								/>
							</Grid>
						</>
					)}
					{!!alert.durationExceededAlertMetadata && (
						<>
							<Grid className={'col'} item xs>
								{TranslateText('common.template')}
							</Grid>
							<Grid className={'vertical-divider'} item xs={1}>
								<Divider orientation={'vertical'} />
							</Grid>
							<Grid className={'col'} item xs>
								<TemplateDropdown
									handleOnTemplateValueChange={setTemplateId}
									valueId={templateId}
									validationResult={validationResult}
									hideLabel
									disableClearable
									disabled={!isEditingSelf()}
								/>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid className={'col'} item xs alignItems={'center'}>
								{TranslateText('fields.relatedToTime')}
							</Grid>
							<Grid className={'vertical-divider'} item xs={1}>
								<Divider orientation={'vertical'} />
							</Grid>
							<Grid className={'col'} item xs>
								<RelatedTimeCheckbox
									checked={relatedToTimeChecked}
									handleOnRelatedTimeCheckboxChange={setRelatedToTimeChecked}
									hideLabel
									disabled={!isEditingSelf() || relatedToTimeCheckboxDisabled}
								/>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							{!relatedToTimeChecked && (
								<>
									<Grid className={'col'} item xs alignItems={'center'}>
										{TranslateText('fields.durationCounter')}
									</Grid>
									<Grid className={'vertical-divider'} item xs={1}>
										<Divider orientation={'vertical'} />
									</Grid>
									<Grid className={'col'} item xs>
										<DurationCounter
											counterValue={durationCounterValue}
											handleOnDurationCounterChange={setDurationCounterValue}
											validationResult={validationResult}
											hideLabel
											disabled={!isEditingSelf()}
										/>
									</Grid>
									<Grid item xs={12}>
										<Divider />
									</Grid>
								</>
							)}
							{relatedToTimeChecked && (
								<TimeFrameEvaluationEventWidget
									durationExceededMetadata={changeAbleData.durationExceededAlertMetadata}
									handleTimeFrameEvaluationChange={handleTimeFrameEvaluationChange}
									durationMaxValue={durationMaxValue}
									validateResult={validationResult}
									reInitializeData={reInitializeData}
									disabled={!isEditingSelf()}
								/>
							)}
						</>
					)}
					{!!changeAbleData.analogAlertMetadata ? (
						<AnalogAlertData
							analogAlertMetadata={changeAbleData.analogAlertMetadata}
							alertCustomerId={alert.customerId}
							isEditing={isEditingSelf()}
							validationResult={analogValidationResult}
							handleAnalogAlertMetadataChange={(newAnalogMetadata) => {
								setChangeAbleData((prev) => {
									return { ...prev, analogAlertMetadata: newAnalogMetadata };
								});
							}}
						/>
					) : null}
					{!!changeAbleData.digitalAlertMetadata ? (
						<DigitalAlertData
							digitalAlertMetadata={changeAbleData.digitalAlertMetadata}
							alertCustomerId={alert.customerId}
							isEditing={isEditingSelf()}
							validationResult={digitalValidationResult}
							handleDigitalAlertMetadataChange={(newDigitalMetadata) => {
								setChangeAbleData((prev) => {
									return { ...prev, digitalAlertMetadata: newDigitalMetadata };
								});
							}}
						/>
					) : null}
					{!!changeAbleData.mainPowerAlertMetadata ? (
						<MainPowerAlertData
							mainPowerAlertMetadata={changeAbleData.mainPowerAlertMetadata}
							alertCustomerId={alert.customerId}
							isEditing={isEditingSelf()}
							validationResult={mainPowerValidationResult}
							handleMainPowerAlertMetadataChange={(newMainPowerMetadata) => {
								setChangeAbleData((prev) => {
									return { ...prev, mainPowerAlertMetadata: newMainPowerMetadata };
								});
							}}
						/>
					) : null}
					{!!changeAbleData.geofenceAlertMetadata ? (
						<GeofenceAlertData
							geofenceAlertMetadata={changeAbleData.geofenceAlertMetadata}
							alertCustomerId={alert.customerId}
							isEditing={isEditingSelf()}
							validationResult={geofenceValidationResult}
							handleGeofenceAlertMetadataChange={(newGeofenceMetadata) => {
								setChangeAbleData((prev) => {
									return { ...prev, geofenceAlertMetadata: newGeofenceMetadata };
								});
							}}
						/>
					) : null}
					{!!changeAbleData.towingAlertMetadata ? (
						<TowingAlertData
							towingAlertMetadata={changeAbleData.towingAlertMetadata}
							alertCustomerId={alert.customerId}
							isEditing={isEditingSelf()}
							validationResult={towingValidationResult}
							handleTowingAlertMetadataChange={(newTowingMetadata) => {
								setChangeAbleData((prev) => {
									return { ...prev, towingAlertMetadata: newTowingMetadata };
								});
							}}
							/>
					) : null}
				</Grid>
			)}
		</BaseWidgetFC>
	);
};

export default AlertEventView;
