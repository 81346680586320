import {
	EvaluationMomentEnum,
	TimeFrameEnum,
} from '../components/Views/AlertDurationExceeded/TimeFrameEvaluation/EvaluationMomentDropdown/types';

class DurationExceededAlertMetadata {
	constructor() {
		this.templateDurationId = undefined;
		this.templateDigitalId = undefined;
		this.relatedToTime = true;
		this.timeFrame = TimeFrameEnum.Month;
		this.duration = 1;
		this.evaluationMoment = EvaluationMomentEnum.Always;
		this.durationCounter = 1;
	}
	public templateDurationId: string;
	public templateDigitalId: string;
	public relatedToTime: boolean;
	public timeFrame: TimeFrameEnum;
	public duration: number | null;
	public evaluationMoment: EvaluationMomentEnum;
	public durationCounter: number | null;
	[propName: string]: any;
}

export default DurationExceededAlertMetadata;
