import './claimSelectionDialog.scss';

import React, { useCallback, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import * as GlobalSettings from '../../GlobalSettings.json';
import ClaimValuePair from '../../models/ClaimValuePair';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import ClaimSelectionView from '../ClaimSelectionView';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import {DebouncedButton} from "../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../Constants";

interface Props {
	entityId: string;
	visible: boolean;
	currentSelection: ClaimValuePair[];
	closeCallback: (dataChanged: boolean) => void;
}

const ClaimSelectionDialog = (props: Props) => {
	const [selectedItems, setSelectedItems] = useState<ITreeNode[]>([]);
	const saveCallback = useCallback(() => {
		ajaxUtil
			.post(`${GlobalSettings.rolesMaintenanceApi}/${props.entityId}/updateRoleClaims`, {
				claims: selectedItems.filter((n) => !n.parent).map((n) => n.id),
				claimWithValues: selectedItems.filter((n) => n.parent).map((n) => n.id),
			})
			.then(() => {
				props.closeCallback(true);
			});
	}, [props.closeCallback, selectedItems]);

	return (
		<Dialog
			onEscapeKeyDown={() => props.closeCallback(false)}
			open={props.visible}
			maxWidth={'lg'}
			className={'claim-selection-dialog'}
		>
			<DialogTitle>{TranslateText('common.addRight')}</DialogTitle>
			<DialogContent>
				<div className={'selection-dialog-content'}>
					<ClaimSelectionView
						currentSelection={props.currentSelection}
						visible={props.visible}
						selectionChanged={setSelectedItems}
					/>
				</div>
			</DialogContent>
			<DialogActions className={'actions'}>
				<Button
					onClick={() => {
						props.closeCallback(false);
					}}
				>
					{TranslateText('common.buttonCancel')}
				</Button>
				<DebouncedButton onClick={saveCallback} debounceTime={DEBOUNCE_TIME}>{TranslateText('common.buttonSave')}</DebouncedButton>
			</DialogActions>
		</Dialog>
	);
};

export default ClaimSelectionDialog;
