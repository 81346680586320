import React from 'react';

import GlobalSettings from '../../../GlobalSettings.json';
import { IButtonWidgetView } from '../ButtonWidget/ButtonWidget';
import PersonGroupsView from './PersonGroupsView';

export const DriverGroupMember = (props: IButtonWidgetView) => {
	return (
		<PersonGroupsView
			{...props}
			getUrl={`${GlobalSettings.driversMaintenanceApi}/${props.entityId}/groups`}
			updateUrl={`${GlobalSettings.driversMaintenanceApi}/updatePersonGroupConnection`}
		/>
	);
};

export const PersonGroupsMember = (props: IButtonWidgetView) => {
	return (
		<PersonGroupsView
			{...props}
			getUrl={`${GlobalSettings.personsMaintenanceApi}/${props.entityId}/groups`}
			updateUrl={`${GlobalSettings.personsMaintenanceApi}/updatePersonGroupConnection`}
			navigateFromPersons={true}
		/>
	);
};

export const PersonGroupsAuth = (props: IButtonWidgetView) => {
	return (
		<PersonGroupsView
			{...props}
			getUrl={`${GlobalSettings.personsMaintenanceApi}/${props.entityId}/groups/authorization`}
			updateUrl={`${GlobalSettings.personsMaintenanceApi}/updatePersonGroupConnection/authorization`}
		/>
	);
};
