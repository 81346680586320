import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import Alert from '../../models/Alert';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import AlertView from '../Views/AlertView';
import RenderForEnum from '../Views/RenderForEnum';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';
import {DebouncedButton} from "../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../Constants";

type Props = OldWidgetProps & {
	displayCallback: (alert: Alert) => void;
	notifyAboutUnsavedData: (cancel: boolean) => void;
	savedCallback: (sender: BaseWidget) => void;
	changeDataCallback: (hasChanges: boolean) => void;
	customerId: string;
	editingInstance: string;
	setEditingInstance: (editingInstance: string) => void;
	showEditButton: boolean;
};

type State = {
	alert: Alert;
	editMode: boolean;
	invalidForm: boolean;
	validAlert: boolean;
	visible: boolean;
};

class AlertInformationWidget extends BaseWidget<Props, State> {
	alertViewRef: any;

	constructor(props: any) {
		super(props);
		this.state = {
			alert: new Alert(),
			editMode: false,
			invalidForm: false,
			validAlert: true,
			visible: true,
		};
	}

	public static defaultProps = {
		showEditButton: true,
	};

	componentDidMount() {
		this.fillAlert(this.props.entityId);
		this.closeEditPanelMode();
	}

	fillAlert(alertId: string) {
		ajaxUtil.get<Alert>(`${GlobalSettings.alertsApi}/${alertId}/information`).then((data) => {
			if (this.alertViewRef) {
				this.setState({
					alert: data,
				});

				this.alertViewRef.setEditRecord(data);
				this.props.displayCallback(data);
			}
		});
	}

	getAccessToken(): string {
		return this.props.getAccessTokenCallback();
	}

	toggleEditPanel(checkForUnsaved: boolean) {
		if (!this.state.editMode) {
			this.props.setEditingInstance(this.instance);
		} else {
			this.props.setEditingInstance(null);
		}
		if (checkForUnsaved && this.state.editMode && this.hasUnsavedData === true) {
			this.props.notifyAboutUnsavedData(true);
		} else {
			this.toggleEditMode();
			this.props.displayCallback(this.state.alert);
		}
	}

	toggleEditMode() {
		const edit = this.state.editMode;
		this.props.editModeCallback(!edit);
		this.props.setEditingInstance(!edit ? this.instance : null);

		this.setState(
			{
				...this.state,
				editMode: !edit,
				invalidForm: false,
			},
			() => {
				this.toggleEditButton(!edit);
			}
		);
	}

	closeEditPanelMode() {
		if (this.state.editMode) {
			this.toggleEditMode();
		}
	}

	tryCloseWidget() {
		this.toggleEditPanel(true);
	}

	save() {
		this.hasUnsavedData = false;
		this.props.changeDataCallback(false);

		const url = GlobalSettings.alertsApi;
		const updatedTemplate = this.state.alert;

		ajaxUtil
			.put(url, updatedTemplate)
			.then((response: any) => {
				this.props.savedCallback(this);
				return response;
			})
			.then((data) => {
				if (data) {
					this.hasUnsavedData = false;
					this.props.changeDataCallback(false);
					this.toggleEditPanel(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	setAlert(valid: boolean, alertData: Alert) {
		this.hasUnsavedData = !(
			this.state.alert.code === alertData.code &&
			this.state.alert.name === alertData.name &&
			alertData.validAlert
		);

		this.setState(
			{
				...this.state,
				validAlert: valid,
				alert: alertData,
				invalidForm: !valid,
			},
			() => {
				this.props.changeDataCallback(true);
			}
		);
	}

	disableSaveButton() {
		this.setState({
			invalidForm: true,
			validAlert: false,
		});
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="4"
			>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={this.props.showEditButton}
							editMode={this.state.editMode}
							allowEditMode={this.props.allowEditMode || this.state.editMode}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel(true)}
						/>
					</div>
					<div className="widget-view">
						<AlertView
							informationWidget={true}
							readOnly={!this.state.editMode}
							ref={(input) => {
								this.alertViewRef = input;
							}}
							getAccessTokenCallback={this.getAccessToken.bind(this)}
							setAlert={this.state.editMode ? this.setAlert.bind(this) : null}
							renderFor={RenderForEnum.Widget}
							widgetState={this.state.editMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							disableNavigation={this.disableSaveButton.bind(this)}
						/>
					</div>
					{this.state.editMode && (
						<div className="buttons-host">
							<Button
								className="widget-button cancel-button"
								onClick={() => {
									this.tryCloseWidget();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default AlertInformationWidget;
