import { useEffect, useState } from 'react';
import { EvaluationMomentEnum, TimeFrameEnum } from './EvaluationMomentDropdown/types';
import DurationExceededAlertMetadata from '../../../../models/DurationExceededAlert';

export const getDurationForSelectedTimeFrame = (timeFrame: TimeFrameEnum): number => {
	switch (timeFrame) {
		case TimeFrameEnum.Year:
			return 365 * 24;
		case TimeFrameEnum.Month:
			return 31 * 24;
		case TimeFrameEnum.Week:
			return 7 * 24;
		case TimeFrameEnum.Day:
			return 24;
		default:
			return 1;
	}
};

const useTimeFrameEvaluation = (
	durationExceededMetadata: DurationExceededAlertMetadata,
	handleTimeFrameEvaluationChange: (
		durationHours: number,
		timeFrameId: TimeFrameEnum,
		evaluationMomentId: EvaluationMomentEnum
	) => void,
	reInitializeData?: boolean
) => {
	const [timeFrameId, setTimeFrameId] = useState<TimeFrameEnum>(durationExceededMetadata.timeFrame);
	const [durationHours, setDurationHours] = useState<number>(durationExceededMetadata.duration);
	const [evaluationMomentId, setEvaluationMomentId] = useState<number>(durationExceededMetadata.evaluationMoment);

	const handleOnDurationChange = async (durationValue: number): Promise<void> => {
		setDurationHours(durationValue);
	};

	const handleOnTimeFrameValueChange = async (id: number): Promise<void> => {
		setTimeFrameId(id);
	};

	useEffect(() => {
		setDurationHours(durationExceededMetadata.duration);
		setTimeFrameId(durationExceededMetadata.timeFrame);
		setEvaluationMomentId(durationExceededMetadata.evaluationMoment);
	}, [reInitializeData]);

	useEffect(() => {
		handleTimeFrameEvaluationChange(durationHours, timeFrameId, evaluationMomentId);
	}, [durationHours, timeFrameId, evaluationMomentId]);

	return {
		evaluationMomentId,
		handleOnEvaluationMomentChange: setEvaluationMomentId,
		handleOnTimeFrameValueChange: handleOnTimeFrameValueChange,
		handleOnDurationChange,
		timeFrameId: timeFrameId,
		timeFrameDurationValue: durationHours,
	};
};

export { useTimeFrameEvaluation };
