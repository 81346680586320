import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import * as GlobalSettings from 'GlobalSettings.json';
import ClaimValuePair from 'models/ClaimValuePair';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import Role from 'models/Role';
import SelectionItem from 'models/SelectionItem';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import PrefixTextField from 'shared/components/PrefixTextField/PrefixTextField';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import ClaimSelectionView from '../ClaimSelectionView';
import MaterialAutocomplete from '../Common/Autocomplete/MaterialAutocomplete';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import TreeSelectionView from '../TreeSelectionView';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';

const constants = {
	genericRolePrefix: 'Gen_',
};

const mapState = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
	};
};

const connector = connect(mapState, null, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	setRole: (valid: boolean, role: Role) => void;
	getAccessTokenCallback: () => string;
	readOnly: boolean;
	renderFor: RenderForEnum;
	widgetState: WidgetStateEnum;
	informationWidget: boolean;
	resetUnsavedFlagCallback?: () => void;
	currentStep: number;
	notifyNavigationCallback?: (valid: boolean, currentStep: number) => void;
	selectedCustomerId?: string;
	selectedCustomerLevel?: CustomerLevelEnum;
	setMaxStepCount?: (maxStepCount: number) => void;
	currentUserCustomerLevel: CustomerLevelEnum;
	currentUserCustomerId?: string;
	enableReportsEdit: boolean;
	setUnsavedData?: (unsavedData: boolean) => void;
};

interface State {
	role: Role;
	unsavedData: boolean;
	validationResult?: ValidationResult;
	roleTemplateDataSource: any[];
}

class RoleView extends BaseView<Props, State> {
	expandedItemIndex: number;

	public validForm = false;
	public typeProperties: JSX.Element;

	public static defaultProps = {
		readOnly: false,
		informationWidget: false,
		selectedCustomerId: '',
		enableReportsEdit: false,
	};

	validator: Validator;

	constructor(props: Props) {
		super(props);

		this.validator = new Validator(
			{
				fieldRules: {
					code: {
						rules: {
							required: ValidatorFunctions.required(),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							maxLength: ValidatorFunctions.maxLength(40),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: (data) => {
									const roleData = data as Role;
									if (!roleData.code) {
										return ValidatorFunctions.wrapInPromise(true);
									}
									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/RoleFields`, {
										customerId: roleData.customerId,
										generic: roleData.generic,
										editedEntityId: roleData.id ? roleData.id : null,
										value: roleData.code,
										field: 'code',
									});
								},
							},
						},
					},

					name: {
						rules: {
							required: ValidatorFunctions.required(),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							maxLength: ValidatorFunctions.maxLength(200),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: (data) => {
									const roleData = data as Role;
									if (!roleData.name) {
										return ValidatorFunctions.wrapInPromise(true);
									}
									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/RoleFields`, {
										customerId: roleData.customerId,
										generic: roleData.generic,
										editedEntityId: roleData.id ? roleData.id : null,
										value: roleData.name,
										Field: 'name',
									});
								},
							},
						},
					},
					description: {
						rules: {
							maxLength: ValidatorFunctions.maxLength(200),
						},
					},
				},
			},
			true
		);

		const role = new Role(
			this.props.selectedCustomerId ? this.props.selectedCustomerId : this.props.currentUserCustomerId
		);

		role.generic = !(
			this.props.renderFor === RenderForEnum.Wizard &&
			(this.props.currentUserCustomerLevel === CustomerLevelEnum.Default ||
				(this.props.currentUserCustomerLevel === CustomerLevelEnum.Reseller &&
					this.props.selectedCustomerId !== null) ||
				(this.props.currentUserCustomerLevel === CustomerLevelEnum.OEM &&
					this.props.selectedCustomerId !== null &&
					this.props.selectedCustomerLevel === CustomerLevelEnum.Default))
		);

		this.state = {
			role: role,
			validationResult: null,
			roleTemplateDataSource: [],
			unsavedData: false,
		};

		this.expandedItemIndex = 0;
	}

	componentDidMount() {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			document.getElementById('roleCodeId').focus();

			//initial validation
			this.validator.validate(this.state.role).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});

				if (this.props.renderFor === RenderForEnum.Wizard) {
					this.props.setMaxStepCount(this.props.enableReportsEdit ? 3 : 2);
					this.props.notifyNavigationCallback(result.formResult, 1);
				}
			});
		}

		if (this.props.renderFor === RenderForEnum.Wizard) {
			this.getRoleTemplateData();
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.state.role !== prevState.role) {
			if (this.props.renderFor === RenderForEnum.Wizard || !this.props.readOnly) {
				if (this.props.renderFor === RenderForEnum.Widget || this.props.currentStep === 1) {
					//validate step 1
					this.validator.validate(this.state.role).then((result) => {
						this.setState({
							validationResult: result.validationResult,
						});
						if (this.props.renderFor === RenderForEnum.Wizard) {
							this.props.notifyNavigationCallback(result.formResult, 1);
						}

						if (this.state.unsavedData) {
							this.setRole(result.formResult, this.state.role);
						}
					});
				} else {
					const step1state = true;
					const formValid = this.isRoleValid(step1state);
					this.setRole(formValid, this.state.role);
					const canNavigate = RoleView.navigationEnabled(this.props.currentStep, step1state);
					this.props.notifyNavigationCallback(canNavigate, this.props.currentStep);
				}
			}
		}
	}

	setEditRecord(role: Role) {
		this.setState({
			role: role,
		});
	}

	public async validateBeforeSave() {
		return this.validator.validate(this.state.role).then((result) => {
			this.setState({
				validationResult: result.validationResult,
			});

			return result.formResult;
		});
	}

	setRole(valid: boolean, role: Role) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			this.props.setRole(valid, role);
		}
	}

	updateView(props: any) {
		this.goToStep(props.currentStep);
	}

	private getRoleTemplateData(): void {
		const url = `${GlobalSettings.rolesMaintenanceApi}/GetAvailableRoles/${
			this.props.selectedCustomerId ? this.props.selectedCustomerId : this.props.currentUserCustomerId
		}`;
		ajaxUtil.get<any>(url).then((data) => {
			this.setState({
				roleTemplateDataSource: data,
			});
		});
	}

	private async handleValueChange(newValue: string | boolean, statePropName: string) {
		const tempRole = { ...this.state.role };
		tempRole[statePropName] = newValue;

		if (statePropName === 'generic') {
			if (!newValue) {
				if (tempRole.code && tempRole.code.startsWith(constants.genericRolePrefix)) {
					tempRole.code = tempRole.code
						.slice(constants.genericRolePrefix.length, tempRole.code.length)
						.trim();
				}
			} else {
				if (tempRole.code && !tempRole.code.startsWith(constants.genericRolePrefix)) {
					tempRole.code = constants.genericRolePrefix + tempRole.code.trim();
				}
			}
			this.props.setUnsavedData && this.props.setUnsavedData(true);
			this.setState({ role: tempRole, unsavedData: true });
		} else if (statePropName === 'durationTemplateId') {
			tempRole.roleClaims = [];
			tempRole.reportsNodes = [];
			tempRole.templateDescription = newValue as string;
			if (newValue) {
				this.setState(
					(prevState) => ({
						role: {
							...prevState.role,
							roleClaims: [],
							reportNodes: [],
						},
						unsavedData: true,
					}),
					async () => {
						await ajaxUtil
							.get<{ description: string; claims: ClaimValuePair[]; reports: ITreeNode[] }>(
								`${GlobalSettings.rolesMaintenanceApi}/GetTemplateData/${newValue}`
							)
							.then(async (data) => {
								const roleClaims: ClaimValuePair[] = [];
								if (data.claims) {
									data.claims.forEach((item) => {
										if (this.props.user.profile[item.claim]) {
											const claimValues = this.props.user.profile[item.claim].split(',');
											if (claimValues && claimValues.includes(item.values)) {
												roleClaims.push(item);
											}
										}
									});
								}
								tempRole.templateDescription = data.description;
								tempRole.reportsNodes = this.sortData(data.reports);
								this.setState(() => ({
									role: {
										...tempRole,
										templateDescription: data.description,
										roleClaims: roleClaims,
										reportsNodes: this.props.enableReportsEdit ? tempRole.reportsNodes : [],
									},
								}));
							});
					}
				);
			} else {
				this.props.setUnsavedData && this.props.setUnsavedData(true);
				this.setState({ role: tempRole, unsavedData: true });
			}
		} else {
			this.props.setUnsavedData && this.props.setUnsavedData(true);
			this.setState({ role: tempRole, unsavedData: true });
		}
	}

	private static navigationEnabled(currentStep: number, step1State: boolean): boolean {
		const step2State = true;
		const step3State = true;

		switch (currentStep) {
			case 1:
				return step1State;
			case 2:
				return step1State && step2State;
			case 3:
				return step1State && step2State && step3State;
			default:
				return false;
		}
	}

	private goToStep(stepNumber: number) {
		if (this.props.currentStep === 1) {
			this.validator.validate(this.state.role).then((result) => {
				this.setState({
					validationResult: result.validationResult,
				});

				if (RoleView.navigationEnabled(stepNumber - 1, result.formResult)) {
					const canNavigate = RoleView.navigationEnabled(stepNumber, result.formResult);
					this.props.notifyNavigationCallback(canNavigate, stepNumber);
				}
			});
		} else if (this.props.currentStep > stepNumber || RoleView.navigationEnabled(stepNumber - 1, true)) {
			const canNavigate = RoleView.navigationEnabled(stepNumber, true);
			this.props.notifyNavigationCallback(canNavigate, stepNumber);
		}
	}

	private handleRightsEntitiesSelection(selection: ITreeNode[]) {
		//transform selection in claim value pair
		const roleClaims: ClaimValuePair[] = [];
		selection.forEach((item) => {
			if (!item.parent) {
				item.items.forEach((child) => {
					const ckp = child.id.split('/');
					if (ckp && ckp.length >= 2) {
						roleClaims.push({
							claim: ckp[0],
							values: ckp[1],
						});
					}
				});
			} else {
				const ckp = item.id.split('/');
				if (ckp && ckp.length >= 2) {
					roleClaims.push({
						claim: ckp[0],
						values: ckp[1],
					});
				}
			}
		});

		const tempRole = {
			...this.state.role,
			roleClaims: roleClaims,
		};
		this.setState({ role: tempRole });
	}

	private handleReportsEntitiesSelection(entities: ITreeNode[]) {
		const tempRole = { ...this.state.role };
		tempRole.reports = [];

		//transform ITreeNode[] to SelectionItem[]
		if (entities !== null) {
			this.addReportsToRole(entities, tempRole);
		}
		this.setState({ role: tempRole });
	}

	private addReportsToRole(entities: ITreeNode[], tempRole: Role) {
		tempRole.reports = [
			...tempRole.reports,
			...entities.map((en) =>
				en.items?.length || en.type === EntityTypeEnum.ReportCategory
					? ({ entityType: EntityTypeEnum.ReportCategory, entityId: en.id } as SelectionItem)
					: ({ entityType: EntityTypeEnum.Report, entityId: en.id } as SelectionItem)
			),
		];
	}

	private isRoleValid(step1State: boolean) {
		const step2State = true;
		const step3State = true;

		let notificationContentRequired = false;
		if (
			this.state.role.useNotification &&
			(this.state.role.notificationContent === undefined ||
				this.state.role.notificationContent === null ||
				this.state.role.notificationContent.body === null ||
				this.state.role.notificationContent.body.trim() === '')
		) {
			notificationContentRequired = true;
		}
		let popupContentRequired = false;
		if (
			this.state.role.usePopup &&
			(this.state.role.popupContent === undefined ||
				this.state.role.popupContent === null ||
				this.state.role.popupContent.body === null ||
				this.state.role.popupContent.body.trim() === '')
		) {
			popupContentRequired = true;
		}
		let mailContentRequired = false;
		if (
			this.state.role.useEmail &&
			(this.state.role.mailContent === undefined ||
				this.state.role.mailContent === null ||
				this.state.role.mailContent.body === null ||
				this.state.role.mailContent.body.trim() === '' ||
				this.state.role.mailContent.subject === null ||
				this.state.role.mailContent.subject.trim() === '')
		) {
			mailContentRequired = true;
		}
		let smsContentRequired = false;
		if (
			this.state.role.useSms &&
			(this.state.role.smsContent === undefined ||
				this.state.role.smsContent === null ||
				this.state.role.smsContent.body === null ||
				this.state.role.smsContent.body.trim() === '')
		) {
			smsContentRequired = true;
		}
		let whatsAppContentRequired = false;
		if (
			this.state.role.useWhatsApp &&
			(this.state.role.whatsAppContent === undefined ||
				this.state.role.whatsAppContent === null ||
				this.state.role.whatsAppContent.body === null ||
				this.state.role.whatsAppContent.body.trim() === '')
		) {
			whatsAppContentRequired = true;
		}

		const step4State = !(
			notificationContentRequired ||
			popupContentRequired ||
			mailContentRequired ||
			smsContentRequired ||
			whatsAppContentRequired
		);
		return step1State && step2State && step3State && step4State;
	}

	private systemInformation() {
		return (
			<Scrollbar
				native={true}
				className={this.props.renderFor === RenderForEnum.Widget ? '' : 'view-section-scroll'}
			>
				{this.fillSystemInformation()}
			</Scrollbar>
		);
	}

	private fillSystemInformation() {
		return (
			<form id="roleViewSystemInformationForm" noValidate={true}>
				<div className="view-section-wrapper">
					<div className="form-group">
						<PrefixTextField
							id={'roleCodeId'}
							name={'code'}
							className="resize-font"
							value={this.state.role.code}
							label={TranslateText('fields.code')}
							applyPrefix={this.state.role.generic}
							prefixValue={constants.genericRolePrefix}
							onChange={(value) => this.handleValueChange(value, 'code')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.code} />
					</div>
					<div className="form-group">
						<MaterialTextField
							id="name"
							className="resize-font"
							label={TranslateText('fields.name')}
							inputProps={{
								readOnly: this.props.readOnly,
								style: { fontSize: 12 },
							}}
							name="name"
							value={this.state.role.name}
							handleValueChange={(value) => this.handleValueChange(value?.trim(), 'name')}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.name} />
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="generic"
									color={'default'}
									checked={this.state.role.generic}
									readOnly={this.props.readOnly}
									onChange={(args: any) => this.handleValueChange(args.target.checked, 'generic')}
									disabled={
										this.props.readOnly ||
										(this.props.renderFor === RenderForEnum.Widget && this.state.role.generic) ||
										this.props.currentUserCustomerLevel === CustomerLevelEnum.Default ||
										(this.props.currentUserCustomerLevel === CustomerLevelEnum.Reseller &&
											this.props.selectedCustomerId !== null) ||
										(this.props.currentUserCustomerLevel === CustomerLevelEnum.OEM &&
											this.props.selectedCustomerId !== null &&
											this.props.selectedCustomerLevel === CustomerLevelEnum.Default)
									}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.generic')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
					</div>
					<CustomerNameDisplay customerId={this.state.role.customerId} />
					{this.props.renderFor === RenderForEnum.Wizard ? (
						<>
							<div className="form-group">
								<MaterialAutocomplete
									valueId={this.state.role.durationTemplateId}
									dataSource={this.state.roleTemplateDataSource}
									name="durationTemplateId"
									disabled={this.props.readOnly}
									label={TranslateText('fields.roleTemplate')}
									onChange={({ value }) =>
										this.handleValueChange(value as string, 'durationTemplateId')
									}
								/>
							</div>
							<div className="form-group">
								<TextField
									id="templateDescription"
									type="text"
									className="resize-font"
									fullWidth
									label={TranslateText('fields.templateDescription')}
									inputProps={{ style: { fontSize: 12 } }}
									name="templateDescription"
									value={this.state.role.templateDescription ?? ''}
									disabled={true}
									multiline
								/>
							</div>
						</>
					) : null}
					<div className="form-group">
						<MaterialTextField
							id="description"
							className="resize-font"
							label={TranslateText('fields.description')}
							inputProps={{ style: { fontSize: 12 } }}
							name="description"
							value={this.state.role.description}
							handleValueChange={(value) => this.handleValueChange(value, 'description')}
							disabled={this.props.readOnly}
							multiline
						/>
						<ValidationMessage result={this.state.validationResult?.description} />
					</div>
					<div
						className="form-group"
						style={this.props.renderFor === RenderForEnum.Wizard ? { visibility: 'hidden' } : {}}
					>
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'primary'}
									checked={this.state.role.active}
									readOnly={this.props.readOnly}
									onChange={(args: any) => this.handleValueChange(args.target.checked, 'active')}
									disabled={this.props.readOnly}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							style={{ margin: 0 }}
							labelPlacement="start"
						/>
					</div>
				</div>
			</form>
		);
	}

	retrieveReportsData() {
		return ajaxUtil
			.get<ITreeNode[]>(`${GlobalSettings.rolesMaintenanceApi}/GetReportCategoriesTree`)
			.then((data) => this.sortData(data));
	}

	sortData(reportTemplatesCollection: ITreeNode[]) {
		reportTemplatesCollection.forEach((reportTemplates: ITreeNode) => {
			if (reportTemplates.items && reportTemplates.items.length > 0) {
				reportTemplates.items.sort((a: ITreeNode, b: ITreeNode) => {
					a.text = TranslateText(a.text);
					b.text = TranslateText(b.text);
					return a.text.localeCompare(b.text);
				});
			}
		});
		return reportTemplatesCollection.sort((a: ITreeNode, b: ITreeNode) => {
			a.text = TranslateText(a.text);
			b.text = TranslateText(b.text);
			return a.text.localeCompare(b.text);
		});
	}

	private renderForWizard() {
		return (
			<div style={{ marginBottom: 0 }}>
				<div>
					<ul id="addWizard-horizontal-list" className="e-text" style={{ cursor: 'pointer' }}>
						<li
							onClick={() => this.goToStep(1)}
							className={this.props.currentStep === 1 ? 'addWizard-selected-wizard-step' : ''}
						>
							{`1. ${TranslateText('role.role')}`}
						</li>
						<li
							onClick={() => this.goToStep(2)}
							className={this.props.currentStep === 2 ? 'addWizard-selected-wizard-step' : ''}
						>
							{`2 .${TranslateText('role.claims')}`}
						</li>
						{this.props.enableReportsEdit ? (
							<li
								onClick={() => this.goToStep(3)}
								className={this.props.currentStep === 3 ? 'addWizard-selected-wizard-step' : ''}
							>
								{`3. ${TranslateText('role.reports')}`}
							</li>
						) : null}
					</ul>
				</div>
				<div className="wizard-page">
					<div className="pannel" hidden={this.props.currentStep !== 1}>
						<div className="column" style={{ width: '33%' }}>
							<Accordion>
								<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
									{this.systemInformation()}
								</AccordionSection>
							</Accordion>
						</div>
					</div>
					<ClaimSelectionView
						isFromWizard={this.props.renderFor === RenderForEnum.Wizard}
						visible={this.props.currentStep === 2}
						currentSelection={this.state.role.roleClaims}
						selectionChanged={this.handleRightsEntitiesSelection.bind(this)}
					/>
					{this.props.enableReportsEdit ? (
						<div hidden={this.props.currentStep !== 3}>
							<TreeSelectionView
								buttonContainerVisible={true}
								currentSelection={this.state.role.reportsNodes}
								retrieveDataCallback={this.retrieveReportsData.bind(this)}
								selectionChanged={this.handleReportsEntitiesSelection.bind(this)}
								filterPlaceholder={TranslateText('fleetSelection.searchDriverPlaceholder')}
								enableClientFilter
								disableShowInactiveFilter
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	private renderForWidget() {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<Accordion>
					<AccordionSection
						expanded={!!this.props.informationWidget}
						header={TranslateText('common.systemInformation')}
					>
						{this.fillSystemInformation()}
					</AccordionSection>
				</Accordion>
			</div>
		);
	}

	render() {
		return this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget();
	}
}

export default connector(RoleView);
