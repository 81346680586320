import { ITreeSelectionUtil } from 'utils/TreeSelection/ITreeSelectionUtil';

import { TranslateText } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog/NotificationDialog';
import { ITreeNode } from '../SelectionTree/TreeNode/types';

let isAllSelected = false;
let firstTimeRendered = true;
const SelectionUtil: ITreeSelectionUtil = {
	select: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		if (selectedNode.type === 'Group' || selectedNode.type === 'ReportCategory') {
			SelectionUtil.selectGroup(selectedNode, treeData, setTreeData, selectedItems, setSelectedItems);
		} else {
			SelectionUtil.selectItem(selectedNode, treeData, setTreeData, selectedItems, setSelectedItems);
		}
	},
	selectAll: (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		const selectedItems: ITreeNode[] = [];
		treeData.forEach((data: ITreeNode) => {
			if (data.id) {
				selectedItems.push(data);
				data.selected = true;
			} else if (data?.items.length) {
				data.items.forEach((d: ITreeNode) => {
					selectedItems.push(d);
					d.selected = true;
				});
				data.selected = false;
			}
		});
		setSelectedItems(selectedItems);
		setTreeData([...treeData]);
	},
	selectGroup: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		const node = treeData.find((value) => {
			return value.id === selectedNode.id;
		});
		const toAdd: ITreeNode[] = [];
		const toRemove: ITreeNode[] = [];
		if (!node.id) {
			if (firstTimeRendered) {
				isAllSelected = !node.selected;
				firstTimeRendered = false;
			} else {
				if (node.items) {
					const hasCheckedEntitiesButNotAll =
						node.items.some((item) => item.selected) && !node.items.every((item) => item.selected);
					if (hasCheckedEntitiesButNotAll) {
						isAllSelected = !node.selected;
					} else {
						isAllSelected = !isAllSelected;
					}
				} else {
					isAllSelected = !isAllSelected;
				}
			}
			if (node.items) {
				node.items = node.items.map((child) => {
					if (isAllSelected) {
						child.selected = true;
					} else {
						child.selected = !child.selected;
					}
					SelectionUtil.updateSelectionArray(child, toAdd, toRemove);
					return child;
				});
			}
		} else {
			node.selected = !node.selected;
			SelectionUtil.updateSelectionArray(node, toAdd, toRemove);

			if (node.items) {
				node.items = node.items.map((child) => {
					child.selected = false;
					toRemove.push(child);
					return child;
				});
			}
		}

		SelectionUtil.updateSelectedItems(toAdd, toRemove, selectedItems, setSelectedItems);
	},
	selectItem: (
		selectedNode: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		let node: ITreeNode;
		const toAdd: ITreeNode[] = [];
		const toRemove: ITreeNode[] = [];
		const parentNode = treeData.find((value) => value.id === selectedNode.parentId);
		if (parentNode) {
			node = parentNode.items.find((value) => value.id === selectedNode.id);
			node.selected = parentNode.selected ? false : !node.selected; //Calculate selected value based on current selection or parent selection
			if (!node.selected) {
				for (let i = 0; i < selectedItems.length; i++) {
					if (
						parentNode.selected &&
						!node.selected &&
						parentNode.items.length > 1 &&
						selectedItems[i].type !== 'Group'
					) {
						parentNode.items = parentNode.items.map((childNode) => {
							childNode.selected = childNode.id !== node.id;
							SelectionUtil.updateSelectionArray(childNode, toAdd, toRemove);
							return childNode;
						});
					} else {
						const selectedParentNode = treeData.find((value) => value.id === selectedItems[i].id);
						const unselectEntityFromGroup = selectedParentNode?.items?.some((item) => item.id === node.id);
						if (unselectEntityFromGroup && parentNode.id === selectedItems[i].id) {
							selectedParentNode.selected = false;
							selectedParentNode.items?.forEach((item) => {
								if (item.id !== node.id) {
									item.selected = true;
								} else {
									item.selected = false;
								}
								SelectionUtil.updateSelectionArray(item, toAdd, toRemove);
							});

							SelectionUtil.updateSelectionArray(selectedParentNode, toAdd, toRemove);
						}
					}
				}
			}
			SelectionUtil.updateSelectionArray(node, toAdd, toRemove);
			parentNode.selected = false;
			isAllSelected = false;
			SelectionUtil.updateSelectionArray(parentNode, toAdd, toRemove);
		} else {
			node = treeData.find((value) => {
				return value.id === selectedNode.id;
			});
			node.selected = !node.selected;
			SelectionUtil.updateSelectionArray(node, toAdd, toRemove);
		}
		SelectionUtil.updateSelectedItems(toAdd, toRemove, selectedItems, setSelectedItems);
	},
	updateSelectionArray: (node: ITreeNode, toAdd: ITreeNode[], toRemove: ITreeNode[]) => {
		if (node.selected && !toAdd.includes(node) && !toAdd.some((t) => t.id === node.id)) {
			toAdd.push(node);
		} else if (!node.selected && !toRemove.includes(node) && !toRemove.some((t) => t.id === node.id)) {
			toRemove.push(node);
		}
	},
	updateSelectedItems: (
		toAdd: ITreeNode[],
		toRemove: ITreeNode[],
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		toAdd.forEach((node) => {
			//Remove children of complete groups
			toRemove = [...toRemove, ...selectedItems.filter((selectedNode) => selectedNode.parentId === node.id)];
		});
		setSelectedItems([
			...selectedItems.filter((item) => !toRemove.some((toRemove) => toRemove.id === item.id)),
			...toAdd.filter((item) => !selectedItems.some((selected) => selected.id === item.id)),
		]);
	},
	deselectNode: (
		node: ITreeNode,
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		selectedItems: ITreeNode[],
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		let update = false;
		const parentNode = treeData.find((n) => n.id === node.id);
		if (!parentNode) {
			treeData.forEach((n) => {
				if (n.items) {
					const itemNode = n.items.find((i) => i.id === node.id);
					if (itemNode) {
						itemNode.selected = false;
						update = true;
					}
				}
			});
		} else {
			parentNode.selected = false;
			update = true;
			if (parentNode.items) {
				parentNode.items = parentNode.items.map((i) => {
					i.selected = false;
					return i;
				});
			}
		}
		if (update) {
			setTreeData([...treeData]);
		}

		setSelectedItems([...selectedItems.filter((i) => i.id !== node.id)]);
	},

	clearSelection: async (
		treeData: ITreeNode[],
		setTreeData: (selectedItems: ITreeNode[]) => void,
		setSelectedItems: (selectedItems: ITreeNode[]) => void
	) => {
		if (
			await DialogUtil.confirm({
				content: TranslateText('selectionDialog.clearAllContent'),
				title: TranslateText('selectionDialog.clearAllTitle'),
			})
		) {
			const newData = [...treeData];
			newData.forEach((node) => {
				node.selected = false;
				if (node.items) {
					node.items.forEach((i) => {
						i.selected = false;
					});
				}
			});
			setTreeData(newData);
			setSelectedItems([]);
		}
	},

	prepareInactiveEntities: (
		showInactive: boolean,
		treeData: ITreeNode[],
		selectedItems: ITreeNode[],
		setSelectedItems: (data: ITreeNode[]) => void
	) => {
		const newSelectedItems: ITreeNode[] = [];

		selectedItems.forEach((item) => {
			if (item.active || showInactive) {
				if (item.type === 'Group') {
					//get existing child selection
					const newSelectedChildren: ITreeNode[] = [];
					item.items.forEach((selectedChild) => {
						if (selectedChild.active || showInactive) {
							newSelectedChildren.push(selectedChild);
						}
					});

					//append new children selection to group item selection
					const treeDataGroup = treeData.find((x) => x.id === item.id);
					if (treeDataGroup && treeDataGroup.items) {
						treeDataGroup.items.forEach((treeDataChild) => {
							if (
								(treeDataChild.active || showInactive) &&
								!newSelectedChildren.some((x) => x.id === treeDataChild.id)
							) {
								newSelectedChildren.push(treeDataChild);
							}
						});
					}

					item.items = newSelectedChildren;
					item.childCount = newSelectedChildren.length;
					newSelectedItems.push(item);
				} else {
					newSelectedItems.push(item);
				}
			}
		});

		setSelectedItems(newSelectedItems);
	},
	prepareTreeData: (
		treeData: ITreeNode[],
		selectedItems: ITreeNode[],
		expandedItems: string[],
		setTreeData: (data: ITreeNode[]) => void
	) => {
		const newTreeData: ITreeNode[] = treeData.map((tn) => ({ ...tn }));
		newTreeData.forEach((group) => {
			group.text = TranslateText(group.text);
			group.expanded = expandedItems.some((i) => i === group.id);
			group.items?.forEach((item) => {
				item.selected = selectedItems.some((s) => s?.id === item?.id);
				item.text = TranslateText(item.text);
			});
			group.selected = selectedItems.some((s) => s?.id === group?.id);
			if (!group.id) {
				isAllSelected = group.items?.every((node) => node.selected);
			}
		});
		setTreeData(newTreeData);
	},

	expandCallback: (node: ITreeNode, expanded: string[], setExpanded: (data: string[]) => void) => {
		if (expanded.includes(node.id)) {
			setExpanded([...expanded.filter((i) => i !== node.id)]);
		} else {
			setExpanded([...expanded, node.id]);
		}
	},
	find: (treeData: ITreeNode[], id: string): ITreeNode | null => {
		if (treeData) {
			for (let i = 0; i < treeData.length; i++) {
				if (treeData[i].id === id) {
					return treeData[i];
				}
				const child = SelectionUtil.find(treeData[i].items, id);
				if (child) {
					return child;
				}
			}
		}
		return null;
	},
	getSelection: (treeData: ITreeNode[]): ITreeNode[] => {
		let result: ITreeNode[] = [];
		treeData.forEach((n) => {
			if (n.selected) {
				result.push(n);
			} else {
				result = [...result, ...n.items.filter((i) => i.selected)];
			}
		});
		return result;
	},
};
export default SelectionUtil;
