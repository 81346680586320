import './styles.scss';

import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { ChangeEvent } from 'react';

import { Box, TextField } from '@material-ui/core';

import { usePreThresholdValue } from './hooks';
import { PreThresholdProps } from './types';

const PreThreshold = ({
	id,
	name,
	initialValue,
	validationMessage,
	prefix,
	suffix,
	onChangeHandler,
	disabled = false,
}: PreThresholdProps) => {
	const { preThresholdValue, setPreThreshold } = usePreThresholdValue(initialValue, onChangeHandler);

	return (
		<Box className="prethreshold-container">
			<Box id="pre-threshold-box" display={'flex'} alignItems={'center'} justifyContent={'end'}>
				<Box component={'span'}>{prefix}</Box>
				<Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
					<TextField
						id={id}
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							inputProps: {
								min: 1,
								max: 99,
							},
						}}
						name={name}
						value={preThresholdValue.toString()}
						size="small"
						onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (event.key === 'e' || event.key === '+' || event.key === '-') {
								event.preventDefault();
							}
						}}
						onChange={
							(arg: ChangeEvent<HTMLInputElement>) => {
								setPreThreshold(+arg.target.value);
							}
							// run validations as well (after)
						}
						disabled={disabled}
					/>
					<Box component={'span'}>{suffix}</Box>
				</Box>
			</Box>
			<ValidationMessage result={validationMessage} />
		</Box>
	);
};

export default PreThreshold;
