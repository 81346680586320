import { ListSelectionItem } from 'components/ListSelectionView/types';
import { LocationDto } from 'components/LocationsOverview/LocationsOverview';
import ajaxUtil from 'utils/Ajax';

import GlobalSettings from '../../../../GlobalSettings.json';

const useDialogEvents = (
	alertId: string,
	currentSelection: ListSelectionItem[],
	newSelection: ListSelectionItem[],
	onUpdateCallback: (exitEditMode: boolean) => void,
	setShowDialog: (show: boolean) => void,
	setSelection: (selection: ListSelectionItem[]) => void
) => {
	const onSaveClicked = async () => {
		const removedLocations = currentSelection
			.filter((x) => !newSelection.some((cs) => cs.id === x.id))
			.map((x) => x.id);

		const newLocations = newSelection
			.filter((x) => !currentSelection.some((cs) => cs.id === x.id))
			.map((x) => x.id);

		if (removedLocations.length || newLocations.length) {
			await ajaxUtil.put(`${GlobalSettings.alertsApi}/geofenceAlertLocations/${alertId}`, {
				newLocations,
				removedLocations,
			});
		}

		setShowDialog(false);
		onUpdateCallback(true);
	};
	const onCancelClicked = () => {
		setShowDialog(false);
		setSelection(currentSelection);
	};

	const onSelectionToggle = (rowData: object, selected: boolean) => {
		const locatation = rowData as LocationDto;
		if (selected && !newSelection.some((x) => x.id === locatation.id)) {
			setSelection([
				...newSelection,
				{
					id: locatation.id,
					name: `${locatation.category ?? ''} ${locatation.name ?? ''}`,
					active: locatation.active,
				},
			]);
		} else if (!selected) {
			setSelection(newSelection.filter((x) => x.id !== locatation.id));
		}
	};
	const removeAllSelection = () => {
		setSelection([]);
	};
	const removeSelection = (item: ListSelectionItem) => {
		setSelection(newSelection.filter((x) => x.id !== item.id));
	};

	return { onSaveClicked, onCancelClicked, onSelectionToggle, removeAllSelection, removeSelection };
};

export { useDialogEvents };
