import IdIdentifiable from "./IdIdentifiable";

class LoginReasonNotification extends IdIdentifiable {
	constructor(name: string,
		translated: boolean,
		generic?: boolean)
	{
		super();
		this.id = null;
		this.name = name;
		this.generic = generic;
		this.translated = translated;
		this.active = true;
		this.loginReasonLanguageId = null;
	}

	id: string;
	name: string;
	generic?: boolean;
	active: boolean;
	translated: boolean;
	loginReasonLanguageId?: string; // id from LoginReasonsLanguages table
}

export default LoginReasonNotification;
