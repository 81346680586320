import { getSelectionInfo } from 'components/MuiRichTextEditor/utils';
import { ContentBlock, EditorState, SelectionState } from 'draft-js';
import { useEffect, useState } from 'react';
import { STYLE_TYPES } from './constants';
import { TCustomControl, TStyleType, TToolbarControl } from './types';

const useToolbarControls = (controls?: TToolbarControl[], customControls?: TCustomControl[]) => {
	const [availableControls, setAvailableControls] = useState(() => {
		const filteredControls: TStyleType[] | undefined = filterControls(controls, customControls);

		if (!filteredControls) {
			return STYLE_TYPES;
		}

		return filteredControls;
	});

	useEffect(() => {
		const filteredControls: TStyleType[] | undefined = filterControls(controls, customControls);

		if (!filteredControls) {
			return;
		}

		setAvailableControls(filteredControls);
	}, [controls, customControls]);

	function filterControls(
		currentControls?: TToolbarControl[],
		currentCustomControls?: TCustomControl[]
	): TStyleType[] | undefined {
		if (!currentControls) {
			return undefined;
		}

		const filteredControls: TStyleType[] = [];
		const controls: string[] = currentControls.filter(
			(control: string, index: number) => currentControls!.indexOf(control) >= index
		);

		controls.forEach((name: string) => {
			const style = STYLE_TYPES.find((style: TStyleType) => style.name === name);
			if (style) {
				filteredControls.push(style);

				return;
			}

			if (!currentCustomControls) {
				return;
			}

			const customControl: TCustomControl = currentCustomControls.find(
				(style: TCustomControl) => style.name === name
			);

			if (customControl && customControl.type !== 'atomic' && (customControl.icon || customControl.component)) {
				filteredControls.push({
					id: customControl.id || customControl.name + 'Id',
					name: customControl.name,
					label: customControl.name,
					style: customControl.name.toUpperCase(),
					icon: customControl.icon,
					component: customControl.component,
					type: customControl.type,
					clickFnName: 'onCustomClick',
				});
			}
		});

		return filteredControls;
	}

	return { availableControls };
};

const useToolbarButtonActive = () => {
	const getToolbarButtonActiveState = (isActive: boolean, editorState: EditorState, style: TStyleType): boolean => {
		if (!isActive) {
			return false;
		}

		if (style.type === 'inline') {
			return editorState.getCurrentInlineStyle().has(style.style);
		}

		if (style.type === 'block') {
			const selection: SelectionState = editorState.getSelection();
			const block: ContentBlock = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
			if (block) {
				return style.style === block.getType();
			}
		}

		if (style.style === 'IMAGE' || style.style === 'LINK') {
			return style.style === getSelectionInfo(editorState).entityType;
		}

		return false;
	};

	return { getToolbarButtonActiveState };
};

export { useToolbarButtonActive, useToolbarControls };
