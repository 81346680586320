import './styles.scss';

import { BusinessHoursTripsDefinition } from 'components/BusinessHoursTripsDefinition';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import { WorkingHoursSchedule } from 'components/WorkingHoursSchedule';
import { mapSavedWorkingDays } from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import { noop } from 'Constants';
import { WorkingDayDisplay } from 'models/WorkingDay';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { reportStoreActionCreators } from 'store/ReportStore';
import { TranslateText } from 'utils/Translations';

import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { filterSelectedOutsideBusinessHours, getDefaultBusinessHoursParameters } from './OutsideBusinessHoursUtils';
import { OutsideBusinessHoursParametersProps, OutsideBusinessHoursState } from './types';

const OutsideBusinessHoursParameters = (props: OutsideBusinessHoursParametersProps): JSX.Element => {
	const dispatch = useDispatch();

	const dateTimeSettings = useSelector((s: ApplicationState) => s.currentSession.dateTimeSettings);
	const outsideBusinessHours = useSelector((s: ApplicationState) => s.reportStore.outsideBusinessHours);
	const reportId = useSelector((s: ApplicationState) => s.reportStore.reportId);
	const [disabled, setDisabled] = useState(reportId ? true : false);

	const [parameters, setParameters] = useState<OutsideBusinessHoursState>(() => {
		const startingDay = dateTimeSettings.startingDay >= 0 ? dateTimeSettings.startingDay : 0;
		const defaultDaysParams = getDefaultBusinessHoursParameters();

		return {
			includeTripsStartedBefore: outsideBusinessHours
				? outsideBusinessHours.includeTripsStartedBefore
				: defaultDaysParams.includeTripsStartedBefore,
			includeTripsEndedAfter: outsideBusinessHours
				? outsideBusinessHours.includeTripsEndedAfter
				: defaultDaysParams.includeTripsEndedAfter,
			workingDays: mapSavedWorkingDays(
				startingDay,
				defaultDaysParams.workingDays,
				outsideBusinessHours?.workingDays
			),
		};
	});

	const updateBusinessHoursDefinition = (
		changedValue: { includeTripsStartedBefore: boolean } | { includeTripsEndedAfter: boolean }
	) => {
		//update state
		const newParameters = { ...parameters, ...changedValue };
		setParameters(newParameters);

		//update store
		dispatch(
			reportStoreActionCreators.setOutsideBusinessHours(filterSelectedOutsideBusinessHours(newParameters), true)
		);
		props.unsavedDataCallback(true);
	};

	const updateWorkingDay = (workingDay: WorkingDayDisplay) => {
		//update state
		const index = parameters.workingDays.findIndex((x) => x.dayOfWeek === workingDay.dayOfWeek);
		if (index >= 0) {
			parameters.workingDays[index] = { ...workingDay };
		}
		const newParameters = { ...parameters, workingDays: [...parameters.workingDays] };
		setParameters(newParameters);

		//update store
		dispatch(
			reportStoreActionCreators.setOutsideBusinessHours(filterSelectedOutsideBusinessHours(newParameters), true)
		);
		props.unsavedDataCallback(true);
	};

	return (
		<div className="outside-business-hours-parameters">
			<MaterialAutocomplete
				isForNewAddWizard={true}
				valueId={0}
				dataSource={[{ id: 0, display: TranslateText('hoursSchedule.customSchedule') }]}
				name="scheduleType"
				className="schedule-type"
				disableClearable
				label={TranslateText('hoursSchedule.chooseSchedule')}
				onChange={noop}
			/>

			<div className="outside-business-widgets">
				{reportId ? (
					<div className="outside-business-header">
						<Button
							size="small"
							className={`edit-button ${disabled ? '' : 'edit-mode'}`}
							onClick={() => setDisabled((prev) => !prev)}
						>
							<EditIcon fontSize="small" />
						</Button>
					</div>
				) : null}

				<div className="outside-business-container">
					<WorkingHoursSchedule
						disabled={disabled}
						className={'report-working-hours'}
						title={TranslateText('reports.parameter.defineTemporarySchedule')}
						workingDays={parameters.workingDays}
						updateWorkingDay={updateWorkingDay}
					/>
					<BusinessHoursTripsDefinition
						disabled={disabled}
						includeTripsStartedBefore={parameters.includeTripsStartedBefore}
						includeTripsEndedAfter={parameters.includeTripsEndedAfter}
						updateBusinessHoursDefinition={updateBusinessHoursDefinition}
					/>
				</div>
			</div>
		</div>
	);
};

export default OutsideBusinessHoursParameters;
