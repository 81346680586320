import './styles.scss';

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useCoords, useOnClickOutside, useToggle } from 'utils/hooks';

import { GroupedDropdownProps } from './types';
import { GroupedDropdownContainer } from './GroupedDropdownContainer';

const GroupedDropdown = ({
	baseComponent,
	onVariableClickedCallback,
	groups,
	displaySearchBox = false,
	keepOpenAfterSelection = false,
	height = '220px',
	width = '180px',
}: GroupedDropdownProps): JSX.Element => {
	const baseComponentRef = useRef<HTMLDivElement>();
	const dropdownBodyRef = useRef<HTMLDivElement>();

	const [showGroupedDropdownBody, setShowGroupedDropdownBody, setShowGroupedDropdownBodyFalse] = useToggle(false);

	const { coords, updateCoords } = useCoords(baseComponentRef.current);

	useOnClickOutside(setShowGroupedDropdownBodyFalse, baseComponentRef, dropdownBodyRef);

	return (
		<>
			<div
				data-testid="grouped-dropdown-base-component-test-id"
				ref={baseComponentRef}
				onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					updateCoords(e.currentTarget as HTMLElement);
					setShowGroupedDropdownBody();
				}}
				style={{
					display: 'flex',
					color: showGroupedDropdownBody ? '#e91e63' : 'inherit',
				}}
			>
				{baseComponent}
			</div>
			{showGroupedDropdownBody &&
				ReactDOM.createPortal(
					<div
						ref={dropdownBodyRef}
						data-testid="grouped-dropdown-dropdown-body-test-id"
						className="grouped-dropdown-dropdown-body"
						onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
						style={{ ...coords, height, width }}
					>
						<GroupedDropdownContainer
							groups={groups}
							onVariableClickedCallback={(groupName: string, groupVariable: string) => {
								onVariableClickedCallback(groupName, groupVariable);
								if (!keepOpenAfterSelection) {
									setShowGroupedDropdownBodyFalse();
								}
							}}
							displaySearchBox={displaySearchBox}
						/>
					</div>,
					document.getElementById('grouped-dropdown')
				)}
		</>
	);
};

export default GroupedDropdown;
