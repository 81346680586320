import EntityTypeEnum from 'models/EntityTypeEnum';
import ajaxUtil from 'utils/Ajax';

import GlobalSettings from '../../../../GlobalSettings.json';
import { AlertRecipient } from './types';

const useTerminateConnection = () => {
	const terminateConnectionCallback = async (
		alertDataId: string,
		alertRecipients: AlertRecipient[],
		updateUrl: string,
		reloadDataCallback: () => void,
		rowData: AlertRecipient
	) => {
		if (rowData) {
			const entityToBeRemoved: AlertRecipient = alertRecipients.find(
				(entity: AlertRecipient) => entity.entityId === rowData.entityId
			);

			await ajaxUtil.delete(
				`${updateUrl}/${alertDataId}/recipients/${entityToBeRemoved.entityId}?entityType=${entityToBeRemoved.entityType}`
			);

			reloadDataCallback();
		}
	};

	return terminateConnectionCallback;
};

const useGetEntityLink = () => {
	const getLinkCallback = (entityId: string, entityType: EntityTypeEnum) => {
		const baseUrl =
			entityType === 'Driver'
				? GlobalSettings.route.drivers
				: entityType === 'Person'
				? GlobalSettings.route.persons
				: GlobalSettings.route.groups;
		return `${baseUrl}/${entityId}`;
	};

	return getLinkCallback;
};

export { useGetEntityLink, useTerminateConnection };
