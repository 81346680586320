import FuelTypeEnum from 'models/FuelTypeEnum';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import TemplateUnitOfMeasureEnum from 'models/TemplateUnitOfMeasureEnum';
import { Dispatch } from 'redux';

import { ObjectTemplatesDuration } from '../components/RightSidebarMenu/ExtendedMenu/Tabs/types';
import EntityTypeEnum from '../models/EntityTypeEnum';
import { StateFilterEnum } from '../models/LiveMenuUtils';
import TripTypesEnum from '../models/TripTypesEnum';
import { REMOVE_CUSTOMER } from './GlobalCustomer';
import ObjectFunctionEnum from "../models/ObjectFunctionEnum";

const SET_LIVE_ENTITIES = 'SET_LIVE_ENTITIES';
const SET_LIVE_UNFILTERED_ENTITIES = 'SET_LIVE_UNFILTERED_ENTITIES';
const SET_LIVE_SOCKET_MESSAGE = 'SET_LIVE_SOCKET_MESSAGE';

export interface LiveDataState {
	liveEntities: LiveDataResultDto[];
	liveEntitiesUnfiltered: LiveDataResultDto[];
	socketMessage: ILiveSocketMessage | null;
}

export interface ObjectDigitalTemplates {
	templateId: string;
	templateName: string;
	rank: number;
	stateName: string;
	labelColor: string;
	iconName: string;
	stateDuration?: number;
	totalDuration?: number;
	nonRelevantStateName: string;
	sensorObservationValue?: boolean;
}

export interface ObjectAnalogTemplates extends ObjectDigitalTemplates {
	sensorValue: number;
	unitOfMeasure: TemplateUnitOfMeasureEnum;
}

export interface LiveDataResultDto {
	unitId: string;
	entityId: string;
	entityType: EntityTypeEnum;
	lat: number | undefined;
	long: number | undefined;
	address: string;
	locationName: string;
	locationId: string;
	lastUpdatedDateTime: string;
	hasPrivateMileageInput: boolean;
	isPrivateOff: boolean;
	lastSeen: string;
	privateTripColor: string;
	privateTemplateName: string;
	privateTripRank: number | undefined;
	lastKeepAlive: string;
	since: string;
	licensePlate: string;
	noConnection: boolean;
	objectFunction: ObjectFunctionEnum;
	objectName: string;
	objectIcon: string;
	personName: string;
	speed: string;
	direction: string;
	stateStartDateTime: string;
	sensorTemplatesData: ObjectTemplatesDuration[];
	sensorDigitalTemplatesData: ObjectDigitalTemplates[];
	sensorAnalogTemplatesData: ObjectAnalogTemplates[];
	isTripDevice: boolean;

	objectType: ObjectTypeEnum;
	vin: string;
	brand: string;
	model: string;
	fuelType: FuelTypeEnum;

	email: string;
	phoneNumber: string;
	personAddress: string;

	state: StateFilterEnum;
	stateStart: string;
	tripType: TripTypesEnum;
	objectNameWithTimeZoneOffset: string;
	timeZoneMinutesOffset: number;
	timeZoneId: string;
}

const initialState: LiveDataState = {
	liveEntities: [] as LiveDataResultDto[],
	liveEntitiesUnfiltered: [] as LiveDataResultDto[],
	socketMessage: null,
};

interface IEntityId {
	id: string;
	entityType: string;
}

interface ILiveSocketMessage {
	addedEntities: IEntityId[];
	removedEntities: IEntityId[];
	customerId: string;
	personId: string;
	signature: string;
}

export const LiveDataDispatcher = {
	setLiveEntities: (dispatch: Dispatch, payload: LiveDataResultDto[]) => {
		dispatch({
			type: SET_LIVE_ENTITIES,
			payload: payload,
		});
	},
	setLiveUnfilteredEntities: (dispatch: Dispatch, payload: LiveDataResultDto[]) => {
		dispatch({
			type: SET_LIVE_UNFILTERED_ENTITIES,
			payload: payload,
		});
	},

	setSocketMessage: (dispatch: Dispatch, payload: ILiveSocketMessage) => {
		dispatch({
			type: SET_LIVE_SOCKET_MESSAGE,
			payload: payload,
		});
	},
};

export const liveDataActionReducer = (state: LiveDataState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case REMOVE_CUSTOMER:
			return { ...initialState };
		case SET_LIVE_ENTITIES:
			return {
				...state,
				liveEntities: action.payload,
			};
		case SET_LIVE_UNFILTERED_ENTITIES:
			return {
				...state,
				liveEntitiesUnfiltered: action.payload,
			};
		case SET_LIVE_SOCKET_MESSAGE:
			return {
				...state,
				socketMessage: action.payload,
			};
		default:
			return state;
	}
};
