import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import React, { useRef } from 'react';
import { TranslateText } from 'utils/Translations';
import { TimeFrameDropdownProps } from './types';
import { TimeFrameEnum } from '../EvaluationMomentDropdown/types';

const TimeFrameDropdown = ({
	valueId,
	handleOnTimeFrameValueChange,
	hideLabel,
	disabled,
	isForNewAddWizard = false,
}: TimeFrameDropdownProps): JSX.Element => {
	const alertEvaluationMomentEnumsRef = useRef<AutocompleteItem[]>([
		{
			display: TranslateText('timeFrame.year'),
			id: TimeFrameEnum.Year,
		},
		{
			display: TranslateText('timeFrame.month'),
			id: TimeFrameEnum.Month,
		},
		{
			display: TranslateText('timeFrame.week'),
			id: TimeFrameEnum.Week,
		},
		{
			display: TranslateText('timeFrame.day'),
			id: TimeFrameEnum.Day,
		},
	]);

	return (
		<div className="form-group">
		<MaterialAutocomplete
			isForNewAddWizard={isForNewAddWizard}
			name="timeFrame"
			className="material-autocomplete alert-duration-time-frame-dropdown-container"
			valueId={valueId}
			dataSource={alertEvaluationMomentEnumsRef.current}
			onChange={(autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => {
				handleOnTimeFrameValueChange(autocompleteArgs.value as number);
			}}
			fullWidth={false}
			label={!hideLabel ? TranslateText('fields.timeFrame') : null}
			disableClearable
			disabled={disabled}
		/>
		</div>
	);
};

export default TimeFrameDropdown;
