import './PersonSettingsDialog.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { Build_Number } from 'buildVariables';
import { DialogUtil } from 'components/Common/NotificationDialog';
import { getSupportedHelpPath, openInNewTab } from 'components/Help/HelpButton';
import { StringConstants } from 'components/Widgets/CustomerFeaturesView';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { SET_HELP_FILE_NAME } from 'store/LeftSideBar';
import { TranslateText } from 'utils/Translations';

import {
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Help, History, Lock, Map, Person } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as DashboardSvg } from '../../assets/icons/Dashboard.svg';
import PersonLiveSettings from './PersonLiveSettings/PersonLiveSettings';
import PersonReportSettings from './PersonReportSettings/PersonReportSettings';
import PersonSettingsDashboard from './PersonSettingsDashboard/PersonSettingsDashboard';
import PersonSettingsPassword from './PersonSettingsPassword/PersonSettingsPassword';
import PersonSettingsProfile from './PersonSettingsProfile/PersonSettingsProfile';
import PersonTimeSettings from './PersonTimeSettings/PersonTimeSettings';
import TabPanel from './TabPanel';
import PersonHistorySettings from './PersonHistorySettings/PersonHistorySettings';
import { usePersonProfileSettingsAccess } from './hooks';

interface Props {
	visible: boolean;
	closeCallback: () => void;
}

const PersonSettingsWindows = (props: Props) => {
	const dispatch = useDispatch();
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLocationsFeature = useSelector(
		(s: ApplicationState) => s.currentSession.customer?.featuresSettings.locations
	);

	const userCanEditApplicatinSettings = ClaimUtil.validateClaim(user, {
		claim: ClaimType.Profile,
		values: [ClaimValue.editApplicationSettings],
	});

	const [value, setValue] = useState(0);

	const live = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.live);
	const history = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.history);
	const reports = useSelector((state: ApplicationState) => state.currentSession.customer.featuresSettings.reports);

	const [unsavedData, setUnsavedData] = useState(false);
	const [isDialogDisplayed, setIsDialogDisplayed] = useState<boolean>(false);

	const {
		canAccessHistorySettings,
		canAccessLiveSettings,
		canAccessReportsSettings,
	} = usePersonProfileSettingsAccess();

	const handleChange = (event: any, newValue: number) => {
		if (!isDialogDisplayed) {
			if (unsavedData && value !== newValue) {
				setIsDialogDisplayed(true);
				DialogUtil.confirm({
					title: TranslateText('common.titleUnsavedData'),
					content: TranslateText('notificationMessages.cancel'),
				}).then((response: boolean) => {
					if (response) {
						//yes was clicked
						setValue(newValue);
						setUnsavedData(false);
						window.onbeforeunload = null;
					}
					setIsDialogDisplayed(false);
				});
			} else {
				setValue(newValue);
			}
		}
	};

	const closeDialog = () => {
		if (!isDialogDisplayed) {
			if (unsavedData) {
				setIsDialogDisplayed(true);
				DialogUtil.confirm({
					title: TranslateText('common.titleUnsavedData'),
					content: TranslateText('notificationMessages.cancel'),
				}).then((response: boolean) => {
					if (response) {
						//yes was clicked
						setUnsavedData(false);
						props.closeCallback();
						setTimeout(() => setValue(0), 200);
						window.onbeforeunload = null;
					}
					setIsDialogDisplayed(false);
				});
			} else {
				props.closeCallback();
				setTimeout(() => setValue(0), 200);
			}
		}
	};

	return (
		<Dialog
			open={props.visible}
			fullWidth
			maxWidth={'lg'}
			className={'person-settings-dialog paper'}
			onClose={closeDialog}
			disableBackdropClick
		>
			<DialogContent>
				<div className={'person-dialog-content'}>
					<div className={'dialog'}>
						<div>
							<div>
								<ListItem className={'header'}>
									<ListItemIcon className={'settings-info'}>
										<AccountCircleIcon className={'account-circle-icon'} />
									</ListItemIcon>
									<ListItemText>{TranslateText('personSettingsWindow.settings')}</ListItemText>
								</ListItem>
							</div>
							<br />
							<br />
							<div className={'tabs-header'}>
								<Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange}>
									<Tab
										className={'tab tab-settings'}
										label={TranslateText('menu.dashboard')}
										icon={<DashboardSvg className={'dashboard-icon tab-icon-color'} />}
									/>
									<Tab
										className={'tab tab-settings'}
										label={TranslateText('menu.accountSection.profile')}
										icon={<Person className={'tab-icon-color'} />}
									/>
									<Tab
										hidden={!canAccessLiveSettings()}
										className={'tab tab-settings'}
										label={TranslateText('personSettingsWindow.liveSettings')}
										icon={<Map className={'tab-icon-color'} />}
									/>
									<Tab
										hidden={!canAccessHistorySettings()}
										className={'tab tab-settings'}
										label={TranslateText('personSettingsWindow.historySettings')}
										icon={<History className={'tab-icon-color'} />}
									/>
									<Tab
										hidden={!canAccessReportsSettings()}
										className={'tab tab-settings'}
										label={TranslateText('personSettingsWindow.reportSettings')}
										icon={<AssessmentOutlinedIcon className={'tab-icon-color'} />}
									/>
									<Tab
										hidden={!userCanEditApplicatinSettings}
										className={'tab tab-settings'}
										label={TranslateText('personSettingsWindow.timeSettings')}
										icon={<AccessTimeIcon className={'tab-icon-color'} />}
									/>
									<Tab
										className={'tab tab-settings'}
										label={TranslateText('fields.password')}
										icon={<Lock className={'tab-icon-color'} />}
									/>
								</Tabs>
							</div>
						</div>
						<div className={'help-section'}>
							<Tab
								className={'tab tab-settings'}
								label={TranslateText('menu.support')}
								icon={<Help className={'dashboard-icon tab-icon-color'} />}
								onClick={() => {
									dispatch({
										type: SET_HELP_FILE_NAME,
										payload: getSupportedHelpPath('/settings'),
									});
									openInNewTab(StringConstants.helpRoute);
								}}
							/>
							<span className={'build-number-settings'}>
								{TranslateText('personSettingsWindow.version')}: {Build_Number}
							</span>
						</div>
					</div>
					<div className={'content-header'}>
						<TabPanel value={value} index={0}>
							<PersonSettingsDashboard changeTab={(value) => setValue(value)} />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<PersonSettingsProfile
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
						<TabPanel value={value} index={2}>
							<PersonLiveSettings
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
						<TabPanel value={value} index={3}>
							<PersonHistorySettings
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
						<TabPanel value={value} index={4}>
							<PersonReportSettings
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
						<TabPanel value={value} index={5}>
							<PersonTimeSettings
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
						<TabPanel value={value} index={6}>
							<PersonSettingsPassword
								changeTab={(value) => setValue(value)}
								unsavedData={unsavedData}
								setUnsavedData={setUnsavedData}
							/>
						</TabPanel>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<IconButton
					className={'customized-close-button'}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						closeDialog();
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogActions>
		</Dialog>
	);
};

export default PersonSettingsWindows;
