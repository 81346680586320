import ajaxUtil from 'utils/Ajax';

import GlobalSettings from '../../../../GlobalSettings.json';

const useTerminateConnection = (alertId: string, reloadDataCallback: () => void) => {
	const terminateConnectionCallback = async (locationId: string) => {
		await ajaxUtil.put(`${GlobalSettings.alertsApi}/geofenceAlertLocations/${alertId}`, {
			removedLocations: [locationId],
		});

		reloadDataCallback();
	};
	return terminateConnectionCallback;
};

export { useTerminateConnection };
