import DrivingSvgImage from './assets/icons/DrivingStateSideBar.svg';
import IdleSvgImage from './assets/icons/Idle.svg';
import ParkedSvgImage from './assets/icons/Parked.svg';
import { StateFilterEnum } from './models/LiveMenuUtils';
import ReportCleanupRecentPeriodEnum from './models/ReportCleanupRecentPeriodEnum';
import ReportPeriodsEnum from './models/ReportPeriodsEnum';
import { TranslateText } from './utils/Translations';

export const OBJECT_LISTVIEW_STORAGE_KEY = 'objectListview-selectedCheckboxes';
export const OBJECT_DEFAULT_ICON = 'YellowTruck';
export const ALERT_MAIL_SUBJECT_MAX_LENGTH = 300;
export const DEBOUNCE_TIME = 500;
export const NL_COUNTRY_ID = 528;
export const getDefaultPeriodSelectionInReportDataSource = () => {
	return [
		{
			id: ReportPeriodsEnum.Today.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Today.value),
		},
		{
			id: ReportPeriodsEnum.Yesterday.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Yesterday.value),
		},
		{
			id: ReportPeriodsEnum.Last2Days.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Last2Days.value),
		},
		{
			id: ReportPeriodsEnum.Last3Days.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Last3Days.value),
		},
		{
			id: ReportPeriodsEnum.Last4Days.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Last4Days.value),
		},
		{
			id: ReportPeriodsEnum.Last5Days.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Last5Days.value),
		},
		{
			id: ReportPeriodsEnum.Last6Days.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.Last6Days.value),
		},
		{
			id: ReportPeriodsEnum.ThisWeek.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisWeek.value),
		},
		{
			id: ReportPeriodsEnum.LastWeek.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.LastWeek.value),
		},
		{
			id: ReportPeriodsEnum.ThisMonth.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisMonth.value),
		},
		{
			id: ReportPeriodsEnum.LastMonth.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.LastMonth.value),
		},
		{
			id: ReportPeriodsEnum.ThisQuarter.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.ThisQuarter.value),
		},
		{
			id: ReportPeriodsEnum.LastQuarter.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.LastQuarter.value),
		},
		{
			id: ReportPeriodsEnum.CustomPeriod.value,
			display: TranslateText('reports.periods.' + ReportPeriodsEnum.CustomPeriod.value),
		},
	];
};

export const getCleanupMostRecentReportsDataSource = () => {
	return [
		{ id: 'Session', display: TranslateText('common.session') },
		{ id: 'LastDay', display: TranslateText('common.lastDay') },
		{ id: 'Last7Days', display: TranslateText('common.last7Days') },
		{ id: 'Last14Days', display: TranslateText('common.last14Days') },
	];
};

export const REPORTS_SETTINGS_DATASOURCE = {
	fieldsConfig: { text: 'display', value: 'id' },
	defaultValues: {
		defaultMaxPeriodOfDaysInReport: 93,
		defaultPeriodSelectionInReport: 'LastWeek',
		defaultOutputSelectionInReport: 'pdf',
		defaultMinimalTripInReport: 0.1,
		defaultCleanupGeneratedReports: 14,
		defaultCleanupMostRecentReports: ReportCleanupRecentPeriodEnum.Session,
	},
};

export const MARKER_URL: { [key in StateFilterEnum]?: string } = {
	[StateFilterEnum.Driving]: DrivingSvgImage,
	[StateFilterEnum.Parked]: ParkedSvgImage,
	[StateFilterEnum.Idle]: IdleSvgImage,
};

export const MAP_ZOOM_SHOW_LOCATIONS_LABELS = 16;
export const MAX_LOCATIONS_LABELS_NUMBER = 30;
export const PRODUCTION_ENVIRONMENT = 'Production';

//notifications types
export const LOGIN_REASON = '58d3787a-1cdc-4027-abeb-430ba03ec7e8';

export const noop: () => void = (): void => {};
