import { AnyAction, Dispatch } from 'redux';
import { FleetEntityTypeFilter } from './FleetSelection';
import { StateFilterEnum } from '../models/LiveMenuUtils';

const SET_LIVE_FILTER_TEXT = 'SET_LIVE_FILTER_TEXT';
const SET_LIVE_FILTER_TYPE = 'SET_LIVE_FILTER_TYPE';
const SET_LIVE_FILTER_STATE = 'SET_LIVE_FILTER_STATE';

export interface LiveDataFilterState {
	text: string;
	entityType: FleetEntityTypeFilter;
	stateType: StateFilterEnum;
}

const initialState: LiveDataFilterState = {
	text: '',
	entityType: FleetEntityTypeFilter.all,
	stateType: StateFilterEnum.All,
};
export const LiveDataFilterDispatcher = {
	setFilterText: (dispatch: Dispatch, text: string) => {
		dispatch({
			type: SET_LIVE_FILTER_TEXT,
			payload: text,
		});
	},
	setFilterType: (dispatch: Dispatch, type: FleetEntityTypeFilter) => {
		dispatch({
			type: SET_LIVE_FILTER_TYPE,
			payload: type,
		});
	},
	setFilterState: (dispatch: Dispatch, state: StateFilterEnum) => {
		dispatch({
			type: SET_LIVE_FILTER_STATE,
			payload: state,
		});
	},
};

export const LiveFilterReducer = (state: LiveDataFilterState, action: AnyAction) => {
	state = state || { ...initialState };
	switch (action.type) {
		case SET_LIVE_FILTER_TEXT:
			return {
				...state,
				text: action.payload,
			};
		case SET_LIVE_FILTER_TYPE:
			return {
				...state,
				entityType: action.payload,
			};
		case SET_LIVE_FILTER_STATE:
			return {
				...state,
				stateType: action.payload,
			};
	}

	return state;
};
