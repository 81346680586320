import React, { useEffect, useState } from 'react';
import { IconsRowPosition, ImagePickerProps } from './types';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { AddPhotoAlternate, Close, Delete, Edit } from '@material-ui/icons';
import './styles.scss';
import { useFilePicker } from 'use-file-picker';
import PlaceholderImage from 'assets/images/placeholder.png';
import { ImagePickerEditor } from './ImagePickerEditor';
import { DialogUtil } from '../Common/NotificationDialog';
import { TranslateText, TranslateTextInterpolated } from '../../utils/Translations';

const acceptedImageTypes = ['.png', '.jpg', '.jpeg'];

const ImagePicker = ({
	previewImage,
	onImageEdit,
	aspectRatio = 1,
	percentageWidth = 70,
	iconsRowPosition = IconsRowPosition.right,
	readOnly = false,
}: ImagePickerProps) => {
	const [selectedImage, setSelectedImage] = useState<string>(previewImage);
	const [tempSelectedImage, setTempSelectedImage] = useState<string>(null);
	const [imageEditorOpen, setImageEditorOpen] = useState<boolean>(false);
	const [imagePopupOpen, setImagePopupOpen] = useState<boolean>(false);
	const [openFileSelector, { plainFiles, filesContent, errors, clear }] = useFilePicker({
		readAs: 'DataURL',
		accept: acceptedImageTypes,
		multiple: false,
		maxFileSize: 2,
	});

	useEffect(() => {
		setSelectedImage(previewImage);
	}, [previewImage]);

	useEffect(() => {
		if (errors.length > 0) {
			if (errors[0].fileSizeToolarge) {
				// error message file too large
				DialogUtil.alert({
					content: TranslateTextInterpolated('imageUpload.imageSizeTooBig', ['2']),
					title: TranslateText('imageUpload.uploadFailed'),
				});
			} else {
				console.error(errors);
			}
		}
	}, [errors]);

	useEffect(() => {
		if (plainFiles.length > 0) {
			if (
				!acceptedImageTypes.map((str) => str.slice(1)).some((imgType) => plainFiles[0].type.includes(imgType))
			) {
				DialogUtil.alert({
					content: TranslateText('imageUpload.invalidFileType'),
					title: TranslateText('imageUpload.uploadFailed'),
				});
				clear();
				return;
			}

			setTempSelectedImage(filesContent[0].content);
			setImageEditorOpen(true);
			clear();
		}
	}, [plainFiles]);

	const onEditingComplete = (base64Image: string) => {
		setSelectedImage(base64Image);
		onImageEdit(base64Image);
		setTempSelectedImage(null);
		setImageEditorOpen(false);
	};

	const onCancel = () => {
		setImageEditorOpen(false);
		setTempSelectedImage(null);
	};

	return (
		<div className={`image-picker-container row-position-${iconsRowPosition}`}>
			<div
				className={'image-picker-image-preview'}
				style={{
					width: `calc(${percentageWidth}% - ${
						iconsRowPosition === IconsRowPosition.right || iconsRowPosition === IconsRowPosition.left
							? '52px'
							: '0px'
					})`,
				}}
			>
				<img
					src={selectedImage ?? PlaceholderImage}
					alt={'Preview image'}
					style={{ cursor: selectedImage ? 'pointer' : 'auto' }}
					onClick={selectedImage ? () => setImagePopupOpen(true) : null}
				/>
			</div>
			<div className={`image-picker-icons-row position-${iconsRowPosition}`}>
				{!!selectedImage && (
					<>
						<IconButton
							disabled={readOnly}
							onClick={() => {
								// setSelectedImage(null);
								onImageEdit(null);
								setTempSelectedImage(null);
							}}
						>
							<Delete />
						</IconButton>
						<IconButton
							disabled={readOnly}
							onClick={() => {
								setImageEditorOpen(true);
							}}
						>
							<Edit />
						</IconButton>
					</>
				)}

				<IconButton disabled={readOnly} onClick={openFileSelector}>
					<AddPhotoAlternate />
				</IconButton>
			</div>
			{imageEditorOpen && (
				<ImagePickerEditor
					initialImage={tempSelectedImage ?? selectedImage}
					aspectRatio={aspectRatio}
					onEditingComplete={onEditingComplete}
					onCancel={onCancel}
				/>
			)}
			<Dialog
				className={'image-popup-dialog'}
				open={imagePopupOpen}
				maxWidth={'xl'}
				onClose={() => setImagePopupOpen(false)}
			>
				<DialogTitle style={{ marginBottom: 12 }}>
					<IconButton className={'image-popup-close-button'} onClick={() => setImagePopupOpen(false)}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent className={'image-popup-container'}>
					<img src={selectedImage} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ImagePicker;
