import './styles.scss';

import { Typography } from '@material-ui/core';
import React from 'react';
import { GroupConstructProps, GroupConstructVariable } from './types';

const GroupConstruct = ({
	groupName,
	groupNameDisplayValue,
	groupVariables,
	onVariableClickedCallback,
}: GroupConstructProps): JSX.Element => {
	const renderGroupVariables = (groupVariables: GroupConstructVariable[]): JSX.Element[] =>
		groupVariables.map((groupVariable: GroupConstructVariable) => (
			<Typography
				key={`${groupName}-${groupVariable.value}`}
				className="group-variable"
				onClick={() => onVariableClickedCallback(groupName, groupVariable.value)}
				onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => e.preventDefault()}
			>
				{groupVariable.displayValue}
			</Typography>
		));

	return (
		<>
			<Typography className="group-name">{groupNameDisplayValue}</Typography>
			{renderGroupVariables(groupVariables)}
		</>
	);
};

export default GroupConstruct;
