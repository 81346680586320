import TripTypesEnum from 'models/TripTypesEnum';
import { Dispatch } from 'redux';
import { IAvailableCustomer } from 'store';
import { fleetSelectionActionCreator, FleetSelectionDispatcher } from 'store/FleetSelection';
import { globalCustomerActionCreators, SelectedCustomer } from 'store/GlobalCustomer';
import { historyFilterActionCreators } from 'store/HistoryFilter';

export const getCustomersTree = (customerId: string, customers: IAvailableCustomer[]) => {
	let treeCustomers: { id: string; name: string; active: boolean }[] = [];

	const customer = customers.find((x) => x.value === customerId);
	if (customer) {
		treeCustomers.push({ id: customer.value, name: customer.text, active: customer.active });

		let parent: IAvailableCustomer = customer.parentId
			? customers.find((x) => x.value === customer.parentId)
			: null;
		let index = 0; //prevent infinite loop
		while (parent !== null && index < 20) {
			treeCustomers.push({ id: parent.value, name: parent.text, active: parent.active });

			parent = parent.parentId ? customers.find((x) => x.value === parent.parentId) : null;
			index++;
		}
		treeCustomers = treeCustomers.reverse();
	}

	return treeCustomers;
};

export const getSelectedCustomer = (
	customer: IAvailableCustomer,
	customersList: IAvailableCustomer[]
): SelectedCustomer => {
	return {
		id: customer.value,
		active: customer.active,
		code: customer.code,
		level: customer.level,
		timeZone: customer.timeZone,
		featuresSettings: customer.featuresSettings,
		reportsSettings: customer.reportsSettings,
		entityDefaultsSettings: customer.entityDefaultsSettings,
		fleetOverviewSettings: customer.fleetOverviewSettings,
		customersTree: getCustomersTree(customer.value, customersList),
		customerLiveSettings: customer.customerLiveSettingsDto,
		historySettings: customer.historySettings,
		startCountryId: customer.startCountryId,
	};
};

export const changeSelectedCustomer = (
	customer: IAvailableCustomer,
	customers: IAvailableCustomer[],
	dispatch: Dispatch<any>
) => {
	dispatch(globalCustomerActionCreators.addCustomer(getSelectedCustomer(customer, customers)));

	FleetSelectionDispatcher.setFleetSelectionShowInactive(dispatch, false);
	dispatch(fleetSelectionActionCreator.clearTrackedEntities());
	dispatch(historyFilterActionCreators.setTrackTypeFilter(TripTypesEnum.None));
};
