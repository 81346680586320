import './buttonWidget.scss';

import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { groupMembersSelectionActionCreators } from 'store/GroupMembersSelectionStore';
import { useGetPlaceholderAndButtons } from 'utils/ReceiversUtil/hooks';

import GlobalSettings from '../../../GlobalSettings.json';
import PersonTypeFilterEnum from '../../../models/PersonTypeFilterEnum';
import { FleetEntityTypeFilter } from '../../../store/FleetSelection';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import GroupMemberSelectionUtil from '../../../utils/TreeSelection/GroupMemberSelectionUtil';
import { ITreeNode } from '../../SelectionTree/TreeNode/types';
import TreeSelectionDialog from '../../TreeSelectionDialog';
import { GroupMemberAuthorization } from '../Views/GroupAuthorizationView';
import { IActionButton } from './ButtonWidget';

interface SelectionInfo {
	id?: string;
	groupId: string;
	memberId: string;
	type: string;
}

interface SelectionDataFilter {
	customerId: string;
	showInactive: boolean;
	filterText: string;
	selectedFilter: FleetEntityTypeFilter;
}

const mapToTreeNode = (data: any[]): ITreeNode[] => {
	const treeNodeSelection: ITreeNode[] = [];
	const groupMemberList = data as GroupMemberAuthorization[];
	if (groupMemberList) {
		groupMemberList.forEach((item) => {
			treeNodeSelection.push({
				id: item.memberId,
				text: item.name,
				active: item.active,
				type: item.type,
				selected: true,
				items: [],
				childCount: 0,
				canBeChecked: true,
			});
		});
	}
	return treeNodeSelection;
};

type Props = {} & IActionButton;

const AddGroupAuthorizationButton = (props: Props) => {
	const dispatch = useDispatch();

	const showInactiveAuthorization = useSelector(
		(state: ApplicationState) => state.groupMembersSelection.showInactiveAuthorization
	);

	const { filterPlaceholder, filterButtons, driverIdentification } = useGetPlaceholderAndButtons();

	const [initialTreeData, setInitialTreeData] = useState<ITreeNode[]>([]);
	useEffect(() => {
		setInitialTreeData(mapToTreeNode(props.currentSelection));
	}, [props.currentSelection]);

	const [dialogVisible, setDialogVisible] = useState(false);
	const events = {
		closeDialog: () => {
			setDialogVisible(false);
		},
		saveSelectionCallback: (selection: ITreeNode[]) => {
			const removedEntries: SelectionInfo[] = [];
			const addedEntries: SelectionInfo[] = [];
			const currentSelection = props.currentSelection as GroupMemberAuthorization[];

			//get removed entries
			if (currentSelection) {
				currentSelection.forEach((item) => {
					if (!selection.some((x) => x.id === item.memberId)) {
						removedEntries.push({
							id: item.id,
							groupId: props.entityId,
							memberId: item.memberId,
							type: item.type,
						});
					}
				});
			}

			//get added entries
			selection.forEach((item) => {
				if (!currentSelection || !currentSelection.some((x) => x.memberId === item.id)) {
					addedEntries.push({
						groupId: props.entityId,
						memberId: item.id,
						type: item.type,
					});
				}
			});

			//update connections
			if (addedEntries.length || removedEntries.length) {
				ajaxUtil
					.post(`${GlobalSettings.groupsMaintenanceApi}/updateGroupPersonConnection/authorization`, {
						newConnections: addedEntries,
						removedConnections: removedEntries,
					})
					.then(() => {
						setDialogVisible(false);
						props.actionExecuted(true);
					});
			} else {
				setDialogVisible(false);
				props.actionExecuted(true);
			}
		},
		retrieveData: useCallback((filter: SelectionDataFilter) => {
			let selectedFilter = FleetEntityTypeFilter.all;
			if (filter.selectedFilter !== null && filter.selectedFilter !== undefined) {
				selectedFilter = filter.selectedFilter;
			}

			let personType = PersonTypeFilterEnum.All;
			if (selectedFilter === FleetEntityTypeFilter.driver) {
				personType = PersonTypeFilterEnum.Driver;
			} else if (selectedFilter === FleetEntityTypeFilter.person) {
				personType = PersonTypeFilterEnum.NonDriver;
			}

			dispatch(groupMembersSelectionActionCreators.setShowInactiveAuthorization(filter.showInactive));

			return ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.groupsMaintenanceApi}/GetGroupPersonSelectionData`, {
				customerId: filter.customerId,
				showInactive: filter.showInactive,
				filterText: filter.filterText,
				showObjects: false,
				showPersons: true,
				personType: personType,
			});
		}, []),
	};

	return (
		<>
			<Button
				className="widget-button add-button"
				onClick={() => {
					setDialogVisible(true);
				}}
			>
				{TranslateText('common.buttonAdd')}
			</Button>

			<TreeSelectionDialog
				title={
					driverIdentification
						? TranslateText('common.driverPersonGroupAuthorization')
						: TranslateText('common.personGroupAuthorization')
				}
				filterPlaceholder={filterPlaceholder}
				visible={dialogVisible}
				initialSelection={initialTreeData}
				closeCallback={events.closeDialog}
				saveCallback={events.saveSelectionCallback}
				retrieveData={events.retrieveData}
				selectionUtil={GroupMemberSelectionUtil}
				filterButtons={filterButtons}
				showInactive={showInactiveAuthorization}
			/>
		</>
	);
};

export default AddGroupAuthorizationButton;
