import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { SelectedCustomer } from '../../store/GlobalCustomer';
import { loadedEntityContextActionCreators } from '../../store/LoadedEntityContextData';
import { TranslateText } from '../../utils/Translations';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import ProtectedContainer from '../Layout/SideBar/ProtectedContainer';
import ButtonWidget from '../Widgets/ButtonWidget';
import AddSimCardCustomerActionButton from '../Widgets/ButtonWidget/AddSimCardCustomerActionButton';
import WidgetDashboard, { WidgetDashboardTransferableProps } from '../Widgets/Dashboard/WidgetDashboard';
import DeviceSimCardWidget from '../Widgets/DeviceSimCardWidget';
import SimCardCustomerView from '../Widgets/SimCardCustomerView';
import SimCardInformationView from '../Widgets/SimCardInformationView';
import Widget from '../Widgets/Widget';

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type PropsFromRedux = {
	globalCustomer: SelectedCustomer | null;
	filterText: string;
	user: User;
};

type Props = WidgetDashboardTransferableProps & MatchProps & PropsFromRedux & {};
const pathToOverview = GlobalSettings.route.simcards;
let connectionConflictsNotifierRef = null as ConnectionConflictsNotifier;

const SimCardDetail = (props: Props) => {
	const [reloadInfo, setReloadInfo] = useState(false);
	const [reloadSimInfo, setReloadSimInfo] = useState(false);
	const [currentTitle, setCurrentTitle] = useState<string>('');
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(availableCustomersActions.toggleSelection(false));
		return () => {
			dispatch(loadedEntityContextActionCreators.setLoadedSimCardContext(null));
		};
	}, []);
	const events = {
		handleConflictingConnections: (sender: ConnectionsConflictResolver, confirm: boolean) => {
			sender.handleConflictingConnections(confirm);
		},
		handleDataSaved: () => {
			setReloadInfo(true);
		},
		handleDisplayedCallback: () => {
			setReloadInfo(false);
		},
		handleDelayedCallback: () => {
			setReloadSimInfo(false);
		},
		connectionChangedCallback: (hasChanged: boolean) => {
			if (hasChanged) {
				setReloadInfo(true);
				setReloadSimInfo(true);
			}
		},
	};
	return (
		<>
			<ConnectionConflictsNotifier
				ref={(scope) => {
					connectionConflictsNotifierRef = scope;
				}}
				connectionType={ConnectionTypeEnum.DeviceSimCard}
				visible={false}
				userAnswearCallback={(s, c) => events.handleConflictingConnections(s, c)}
			/>
			<WidgetDashboard
				goBackText={TranslateText(`detailsScreen.navigationBar.backTo${EntityTypeEnum.SimCard}s`)}
				history={props.history}
				currentRecordId={props.match.params.id}
				goBackToCallback={() => {
					props.history.push(pathToOverview, { id: props.match.params.id });
				}}
				urlPath={pathToOverview}
				breadcrumbText={currentTitle ? `${TranslateText('common.simcard')} > ${currentTitle}` : ''}
				filterText={props.filterText}
				entityType={EntityTypeEnum.SimCard}
			>
				<Widget
					position={{
						row: 0,
						col: 0,
						sizeX: 5,
						sizeY: 6,
					}}
					url={`${GlobalSettings.simCardsMaintenanceApi}/information`}
					widgetTitle={TranslateText('simCardsOverview.information')}
					viewComponent={SimCardInformationView}
					setDashboardTitleCallback={setCurrentTitle}
					displayedCallback={events.handleDisplayedCallback}
					forceReload={reloadInfo}
					showEditButton={ClaimUtil.validateClaim(props.user, {
						claim: ClaimType.SimCards,
						values: [ClaimValue.edit],
					})}
				/>
				<ProtectedContainer claimList={[ClaimType.Customers, ClaimType.SimCards]}>
					<ButtonWidget
						position={{
							row: 0,
							col: 5,
							sizeX: 12,
							sizeY: 3,
						}}
						className="auto-sim-card-customers"
						widgetTitle={TranslateText('common.customers')}
						viewComponent={SimCardCustomerView}
						buttons={[AddSimCardCustomerActionButton]}
						dataChangedEvent={events.connectionChangedCallback}
						showEditButton={ClaimUtil.validateClaimList(props.user, [
							{ claim: ClaimType.Customers, values: [ClaimValue.edit] },
							{ claim: ClaimType.SimCards, values: [ClaimValue.edit] },
							{ claim: ClaimType.Devices, values: [ClaimValue.edit] },
							{ claim: ClaimType.Objects, values: [ClaimValue.edit] },
						])}
					/>
				</ProtectedContainer>
				<ProtectedContainer
					claimConfig={[
						{ claim: ClaimType.Devices, values: [ClaimValue.view] },
						{ claim: ClaimType.SimCards, values: [ClaimValue.view] },
						{ claim: ClaimType.SimDeviceConnection, values: [ClaimValue.edit] },
					]}
				>
					<DeviceSimCardWidget
						row="3"
						col="5"
						sizeX="12"
						sizeY="3"
						conflictingConnectionNotifier={connectionConflictsNotifierRef}
						id={'deviceHistory'}
						entityType={EntityTypeEnum.SimCard}
						entityId={props.match.params.id}
						forceReload={reloadSimInfo}
						delayedCallback={events.handleDelayedCallback}
						savedCallback={events.handleDataSaved}
					/>
				</ProtectedContainer>
			</WidgetDashboard>
		</>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		globalCustomer: state.globalCustomer.filteredCustomer,
		filterText: state.globalCustomer.filterText.simcards,
		user: state.oidc.user,
	};
}

export default connect(mapStateToProps, null)(SimCardDetail);
