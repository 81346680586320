import './oldWidget.scss';

import React from 'react';

import { Button } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { DEBOUNCE_TIME } from '../../Constants';
import GlobalSettings from '../../GlobalSettings.json';
import ConnectionTypeEnum from '../../models/ConnectionTypeEnum';
import DevicePropertiesEnum from '../../models/DevicePropertiesEnum';
import FilterFieldTypeEnum from '../../models/FilterFieldTypeEnum';
import FilterOperatorTypeEnum from '../../models/FilterOperatorTypeEnum';
import TrackableObject from '../../models/TrackableObject';
import ajaxUtil from '../../utils/Ajax';
import { TranslateText } from '../../utils/Translations';
import BaseWidget from '../BaseWidget';
import WidgetHeader from '../BaseWidget/WidgetHeader';
import { AutocompleteItem } from '../Common/Autocomplete/MaterialAutocomplete';
import ConnectionsConflictResolver from '../Common/ConnectionsConflictResolver';
import { DebouncedButton } from '../Common/DebouncedButton';
import ConnectionConflictsNotifier from '../ConnectionConflictsNotifier';
import { FileDownload } from '../ReportsOverview/FileDownload';
import ObjectView from '../Views/ObjectView';
import RenderForEnum from '../Views/RenderForEnum';
import WidgetStateEnum from '../Views/WidgetStateEnum';
import { OldWidgetProps } from './Widget';

interface Props extends OldWidgetProps {
	displayCallback: (object: TrackableObject) => void;
	notifyAboutUnsavedData: () => void;
	savedCallback: (sender: BaseWidget) => void;
	changeDataCallback: (hasChanges: boolean) => void;
	customerId: string;
	forceReload: boolean;
	loadDevices: boolean;
}

interface State {
	objectId: string;
	editMode: boolean;
	invalidForm: boolean;
	validObject: boolean;
	object: TrackableObject;
	conflictsCanBeAutomaticallyFixed: boolean;
	conflictingConnections: any;
	visible: boolean;
	devicesData: AutocompleteItem[];
	disabled: boolean;
	timeZoneIdCorrections?: string;
}

class ObjectInformationWidget extends BaseWidget<Props, State> {
	objectViewObj: any;
	connectionConflictsNotifierRef: ConnectionConflictsNotifier;
	scrollElRef: React.RefObject<HTMLDivElement>;

	constructor(props: any) {
		super(props);

		this.state = {
			objectId: '',
			editMode: false,
			invalidForm: false,
			validObject: true,
			object: new TrackableObject(),
			conflictsCanBeAutomaticallyFixed: false,
			conflictingConnections: null,
			visible: true,
			devicesData: null,
			disabled: false,
			timeZoneIdCorrections: null,
		};

		this.scrollElRef = React.createRef<HTMLDivElement>();

		this.fetchDevicesList();
	}

	componentDidMount() {
		this.fillObject(this.props.entityId);
		this.closeEditPanelMode();
		this.getDevicesList();
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.entityId !== this.props.entityId || this.props.forceReload) {
			this.setState({ editMode: false });
			this.fillObject(this.props.entityId);
		}
		if (prevState.object.customerId !== this.state.object.customerId) {
			this.getDevicesList();
		}
		if (!this.state.editMode && this.state.object.timezoneId !== this.state.timeZoneIdCorrections) {
			this.setState({ timeZoneIdCorrections: this.state.object.timezoneId });
		}
	}

	reloadData() {
		this.getDevicesList(true);
		this.fillObject(this.props.entityId);
	}

	fillObject(objectId: string) {
		const me = this;
		ajaxUtil.get<TrackableObject>(`${GlobalSettings.objectsMaintenanceApi}/${objectId}`).then(async (obj) => {
			me.setState({
				objectId: obj.id,
				object: obj,
			});
			if (!!obj.objectImageId) {
				const file = await ajaxUtil.get<FileDownload>(
					`${GlobalSettings.objectsMaintenanceApi}/getObjectImage/${objectId}`
				);

				if (!!file) {
					obj.base64Image = `data:image/jpeg;base64,${file.content as any}`;
				}
			}

			if (me.objectViewObj) {
				me.objectViewObj.setEditRecord(obj);
			}
			me.props.displayCallback(obj);
		});
	}

	getDevicesList(force = false): AutocompleteItem[] {
		if (!force && this.state.devicesData !== null) {
			return this.state.devicesData;
		}

		this.fetchDevicesList();
	}

	fetchDevicesList() {
		if (!this.state.object.customerId || !this.props.loadDevices) {
			return;
		}
		const filter = {
			fieldType: FilterFieldTypeEnum.customerId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: this.state.object.customerId,
			display: DevicePropertiesEnum.Code,
		};

		ajaxUtil.post<AutocompleteItem[]>(`${GlobalSettings.devicesMaintenanceApi}/list`, filter).then((data) => {
			this.setState({
				devicesData: data,
			});
		});
	}

	closeEditPanelMode() {
		if (this.state.editMode) {
			this.toggleEditMode();
		}
	}

	toggleEditPanel(checkForUnsaved: boolean) {
		if (checkForUnsaved && this.state.editMode && this.hasUnsavedData === true) {
			this.props.notifyAboutUnsavedData();
		} else {
			this.toggleEditMode();
			this.objectViewObj.setEditRecord(this.state.object);
			this.props.displayCallback(this.state.object);
		}
	}

	toggleEditMode() {
		const currentValueFlipped = !this.state.editMode;
		this.props.editModeCallback(currentValueFlipped);

		this.setState(() => ({
			editMode: currentValueFlipped,
			invalidForm: false,
		}));

		this.props.changeDataCallback(false);
		this.hasUnsavedData = false;
	}

	setObject(valid: boolean, objectData: TrackableObject, unsavedData: boolean) {
		this.setState({
			validObject: valid,
			object: objectData,
			invalidForm: !valid,
		});
		this.hasUnsavedData = unsavedData;
		this.props.changeDataCallback(true);
	}

	tryCloseWidget() {
		this.toggleEditPanel(true);
	}

	handleConfictingConnections(sender: ConnectionsConflictResolver, proposedConnectionCanBeSaved: boolean) {
		setTimeout(() => {
			sender.handleConflictingConnections(proposedConnectionCanBeSaved);
		}, 500);
	}

	save() {
		this.setState({ disabled: true });
		this.objectViewObj.validateBeforeSave().then((valid: any) => {
			if (!valid) {
				this.setState({
					invalidForm: true,
					disabled: false,
				});
			} else {
				this.hasUnsavedData = false;
				this.props.changeDataCallback(false);

				const url = GlobalSettings.objectsMaintenanceApi;
				const updatedObject = {
					...this.state.object,
					timezoneId: this.state.object.timezoneId ? this.state.object.timezoneId : null,
				};
				this.setState({ timeZoneIdCorrections: updatedObject.timezoneId });

				ajaxUtil
					.put(url, updatedObject)
					.then((response: any) => {
						this.setState({ disabled: false });
						this.props.savedCallback(this);
						return response;
					})
					.then((data) => {
						if (data) {
							this.hasUnsavedData = false;
							this.props.changeDataCallback(false);
							this.toggleEditPanel(false);
						}
					})
					.catch((error) => {
						this.setState({ disabled: false });
						console.log(error);
					});
			}
		});
	}

	scrollToTop() {
		if (this.scrollElRef?.current?.scrollTo) {
			this.scrollElRef.current.scrollTo({ top: 0 });
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`e-panel oldwidget ${this.state.visible ? '' : 'hidden'}`}
				data-row={this.props.row}
				data-col={this.props.col}
				data-sizex={this.props.sizeX}
				data-sizey={this.props.sizeY}
				data-minsizex="4"
				data-minsizey="5"
			>
				<ConnectionConflictsNotifier
					ref={(scope) => {
						this.connectionConflictsNotifierRef = scope;
					}}
					visible={false}
					connectionType={ConnectionTypeEnum.DeviceObject}
					userAnswearCallback={this.handleConfictingConnections.bind(this)}
				/>
				<div className="e-panel-container">
					<div className="e-panel-header">
						<WidgetHeader
							caption={TranslateText('common.information')}
							showEditButton={true}
							editMode={this.state.editMode}
							allowEditMode={this.props.allowEditMode || this.state.editMode}
							editClaimConfig={[
								{ claim: ClaimType.Objects, values: [ClaimValue.edit, ClaimValue.editRestricted] },
							]}
							removePanelCallback={() => this.setState({ visible: false })}
							editCallback={() => this.toggleEditPanel(true)}
						/>
					</div>
					<div ref={this.scrollElRef} className="widget-view">
						<ObjectView
							widgetState={this.state.editMode ? WidgetStateEnum.Edit : WidgetStateEnum.View}
							renderFor={RenderForEnum.Widget}
							readOnly={!this.state.editMode}
							devicesData={this.state.devicesData}
							ref={(input) => {
								this.objectViewObj = input;
							}}
							setObject={this.state.editMode ? this.setObject.bind(this) : null}
							scrollToTop={this.scrollToTop.bind(this)}
						/>
					</div>
					{this.state.editMode ? (
						<div className="buttons-host">
							<Button
								className="widget-button cancel-button"
								onClick={() => {
									this.tryCloseWidget();
								}}
							>
								{TranslateText('common.buttonCancel')}
							</Button>
							<DebouncedButton
								className="widget-button save-button"
								disabled={this.state.disabled || this.state.invalidForm}
								onClick={() => this.save()}
								debounceTime={DEBOUNCE_TIME}
							>
								{TranslateText('common.buttonSave')}
							</DebouncedButton>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default ObjectInformationWidget;
