import './templateView.scss';
import '../Common/RemoveSpinnerOptionInput.scss'

import {ColorPicker} from 'components/ColorPicker';
import {DialogUtil} from 'components/Common/NotificationDialog';
import * as GlobalSettings from 'GlobalSettings.json';
import Template from 'models/Template';
import TemplateEventActionEnum from 'models/TemplateEventActionEnum';
import TemplateTypesEnum from 'models/TemplateTypesEnum';
import React, {ChangeEvent} from 'react';
import {Scrollbar} from 'react-scrollbars-custom';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import {ValidationResult} from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import {ValidatorFunctions} from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import {iconNoImage} from 'utils/Icons';
import {TemplateDefaults} from 'utils/TemplateUtils';
import {TranslateText} from 'utils/Translations';

import {Button, Checkbox, FormControlLabel, TextField, Typography} from '@material-ui/core';

import TemplateState from '../../models/TemplateState';
import TemplateUnitOfMeasureEnum from '../../models/TemplateUnitOfMeasureEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import Accordion from '../Accordion/Accordion';
import AccordionSection from '../Accordion/AccordionSection';
import BaseView from '../BaseView';
import MaterialAutocomplete, {AutocompleteItem} from '../Common/Autocomplete/MaterialAutocomplete';
import IconPickerWrapper from '../IconPicker/IconPickerWrapper/IconPickerWrapper';
import {ValidationMessage} from '../ValidationMessage/ValidationMessage';
import YesNoCombobox from '../Widgets/components/YesNoCombobox';
import AlertListItemDto from './AlertListItemDto';
import RenderForEnum from './RenderForEnum';
import WidgetStateEnum from './WidgetStateEnum';
import {handleKeyDown} from "../Common/RemoveSpinnerOptionInput";

interface TemplateDefinitionDto extends AutocompleteItem {
	relevantValue: boolean;
	tripColor: string;
	labelColor: string;
	rank: number;
	templateType: TemplateTypesEnum;
	countRelevantState: boolean;
	durationCounter: boolean;
	showOnMap: boolean;
	relevantStateName: string;
	relevantLabelColor: string;
	relevantIconName: string;
	nonRelevantStateName: string;
	nonRelevantLabelColor: string;
	nonRelevantIconName: string;
	maxValue: number;
	minValue: number;
	unitOfMeasure: TemplateUnitOfMeasureEnum;
	stateDefinition: number;
	templateAnalogStates: TemplateState[];
	numberOfStates: number;
	graphColor: string;
}

type Props = {
	setTemplate: (valid: boolean, template: Template) => void;
	readOnly: boolean;
	renderFor: RenderForEnum;
	widgetState: WidgetStateEnum;
	informationWidget: boolean;
	defaultCustomerId: string;
	allowCustomerChange: boolean;
	allowTrackTypeSpecification: boolean;
	templateType: TemplateTypesEnum;
	tripTypes: TripTypesEnum;
};

interface State {
	template: Template;
	genericTemplateId: string;
	genericTemplatesList: TemplateDefinitionDto[];
	validationResult?: ValidationResult;
	unsavedData: boolean;
	affectedAlerts: AlertListItemDto[];
}

class TemplateView extends BaseView<Props, State> {
	initialTemplate: Template;
	validator: Validator;

	public validForm = false;

	templateTypes = TemplateDefaults.getTemplateTypes();
	eventActionList = TemplateDefaults.getEventActionList();
	stateLowHigh = TemplateDefaults.getStateLowHigh();
	unitValues = TemplateDefaults.getUnitValues();
	defaultValueStateName = TemplateDefaults.getStateNames();

	public static defaultProps = {
		readOnly: false,
		informationWidget: false,
		defaultCustomerId: '',
		allowCustomerChange: false,
		allowTrackTypeSpecification: true,
		templateType: TemplateTypesEnum.Unknown,
		tripTypes: TripTypesEnum.None,
	};

	constructor(props: Props) {
		super(props);

		const template = new Template(
			this.props.defaultCustomerId,
			this.getTemplateTypes().length > 0 ? this.getTemplateTypes()[0].id : props.templateType,
			!!(this.props.tripTypes & TripTypesEnum.Private) ? TemplateEventActionEnum.PrivateBusiness : null
		);
		this.initialTemplate = template;

		this.state = {
			template: { ...template, rank: template.rank ?? 0 },
			genericTemplateId: '',
			genericTemplatesList: [],
			unsavedData: false,
			affectedAlerts: [],
		};

		this.validator = new Validator(
			{
				//System Information
				fieldRules: {
					code: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(100),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
							unique: {
								message: TranslateText('fieldsValidations.uniqueValue'),
								validationFunction: (data) => {
									const templateData = data as Template;
									if (!templateData.code.trim()) {
										return ValidatorFunctions.wrapInPromise(true);
									}
									return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/TemplateFields`, {
										customerId: templateData.customerId,
										value: templateData.code,
										Field: 'code',
										editedEntityId: templateData.id ? templateData.id : null,
									});
								},
							},
						},
					},
					name: {
						rules: {
							required: ValidatorFunctions.required(),
							maxLength: ValidatorFunctions.maxLength(300),
							noEmptySpace: ValidatorFunctions.noEmptySpace(),
						},
					},
					description: {
						rules: {
							maxLength: ValidatorFunctions.maxLength(500),
						},
					},
				},
			},
			true
		);
		if (
			props.renderFor === RenderForEnum.Wizard ||
			(this.props.renderFor === RenderForEnum.Widget && !this.props.informationWidget)
		) {
			this.validator.addRules({
				//Template Data
				templateType: {
					rules: {
						required: ValidatorFunctions.required(),
					},
				},
				//EventTypeFormValidator
				eventAction: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Event ||
										templateData.eventAction === TemplateEventActionEnum.PrivateBusiness
								);
							},
						},
					},
				},
				relevantValue: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(templateData.relevantValue !== null);
							},
						},
					},
				},
				//DigitalTypeFormValidator
				countRelevantState: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Digital ||
										(templateData.countRelevantState !== null &&
											templateData.countRelevantState !== undefined)
								);
							},
						},
					},
				},
				durationCounter: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Digital ||
										(templateData.durationCounter !== null &&
											templateData.durationCounter !== undefined)
								);
							},
						},
					},
				},
				showOnMap: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Digital ||
										(templateData.showOnMap !== null && templateData.showOnMap !== undefined)
								);
							},
						},
					},
				},
				relevantStateName: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Digital ||
										!!templateData.relevantStateName?.trim()
								);
							},
						},
						maxLength: ValidatorFunctions.maxLength(10),
					},
				},
				nonRelevantStateName: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Digital ||
										!!templateData.nonRelevantStateName?.trim()
								);
							},
						},
						maxLength: ValidatorFunctions.maxLength(10),
					},
				},
				rank: {
					rules: {
						required: ValidatorFunctions.required(),
						minNumber: ValidatorFunctions.minNumber(0),
						maxNumber: ValidatorFunctions.maxNumber(99),
					},
				},

				//AnalogTypeFormValidator
				maxValue: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										(templateData.maxValue !== null &&
											templateData.maxValue !== undefined &&
											templateData.maxValue.toString() !== '')
								);
							},
						},
						minNumber: {
							message: TranslateText('fieldsValidations.minValue') + 0,
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										templateData.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature ||
										Number(templateData?.maxValue) >= 0
								);
							},
						},
						maxNumber: ValidatorFunctions.maxNumber(999999),
					},
				},
				minValue: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										(templateData.minValue !== null &&
											templateData.minValue !== undefined &&
											templateData.minValue.toString() !== '')
								);
							},
						},
						lowerThanMax: {
							message: TranslateText('fieldsValidations.templateLowerMinValue'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										Number(templateData?.minValue) < Number(templateData?.maxValue)
								);
							},
						},
						minNumber: {
							message: TranslateText('fieldsValidations.minValue') + 0,
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										templateData.unitOfMeasure === TemplateUnitOfMeasureEnum.Temperature ||
										Number(templateData?.minValue) >= 0
								);
							},
						},
						maxNumber: ValidatorFunctions.maxNumber(999999),
					},
				},
				unitOfMeasure: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										(templateData.unitOfMeasure !== null &&
											templateData.unitOfMeasure !== undefined &&
											templateData.unitOfMeasure.toString() !== '')
								);
							},
						},
					},
				},
				numberOfStates: {
					rules: {
						requiredConditionally: {
							message: TranslateText('fieldsValidations.fieldRequired'),
							validationFunction: (data) => {
								const templateData = data as Template;
								return ValidatorFunctions.wrapInPromise(
									templateData.templateType !== TemplateTypesEnum.Analog ||
										(templateData.numberOfStates !== null &&
											templateData.numberOfStates !== undefined &&
											templateData.numberOfStates.toString() !== '')
								);
							},
						},
					},
				},
			});
		}
	}

	async componentDidMount() {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			document.getElementById('templateCode').focus();

			//initial validation
			const result = await this.validator.validate(this.state.template);
			this.setState({
				validationResult: result.validationResult,
			});
		}

		if (this.props.renderFor === RenderForEnum.Wizard || !this.props.informationWidget) {
			await this.getGenericTemplatesList(this.state.template.templateType);
		}
	}

	async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (prevProps.readOnly !== this.props.readOnly) {
			if (this.props.readOnly) {
				this.validator.clearValidation();
				this.setState({ validationResult: null });
			} else {
				setTimeout(() => {
					document.getElementById('templateCode').focus();
				}, 300);
			}
		}

		if (this.state.template !== prevState.template) {
			if (
				this.props.renderFor === RenderForEnum.Wizard ||
				this.props.renderFor === RenderForEnum.ConnectDialog ||
				!this.props.readOnly
			) {
				const result = await this.validator.validate(this.state.template);
				this.setState({
					validationResult: result.validationResult,
				});

				if (this.state.unsavedData) {
					this.setTemplate(result.formResult, this.state.template);
				}
			}
		}

		if (this.state.template.templateType !== prevState.template.templateType) {
			if (this.props.renderFor === RenderForEnum.Wizard || !this.props.informationWidget) {
				await this.getGenericTemplatesList(this.state.template.templateType);
			}
		}
	}

	public setEditRecord(template: Template) {
		this.initialTemplate = { ...template };
		this.setState({
			template: { ...template },
			unsavedData: false,
			genericTemplateId: '',
		});
	}

	public async checkIfAlertsAreAffectedByEdit() {
		if (
			(this.state.template.templateType === TemplateTypesEnum.Analog ||
				this.state.template.templateType === TemplateTypesEnum.Digital) &&
			(this.state.template.durationCounter !== this.initialTemplate.durationCounter ||
				this.state.template.numberOfStates !== this.initialTemplate.numberOfStates ||
				(!this.state.template.active && this.state.template.active !== this.initialTemplate.active ||
				(this.state.template.relevantValue !== this.initialTemplate.relevantValue)))
		) {
			const url = `${GlobalSettings.alertsApi}/checkIfAnyAlertIsAffectedBySensorTemplateUpdate/${
				this.state.template.id
			}?templateType=${this.state.template.templateType.toString()}`;
			const data = await ajaxUtil.get<AlertListItemDto[]>(url);
			this.setState({
				affectedAlerts: data,
			});
			return data;
		}
	}

	public async validateBeforeSave() {
		const result = await this.validator.validate(this.state.template);
		if (result.validationResult) {
			this.setState({
				validationResult: result.validationResult,
				genericTemplateId: '',
			});
		} else {
			this.setState({
				validationResult: result.validationResult,
			});
		}

		return result.formResult;
	}

	private async getGenericTemplatesList(templateType: TemplateTypesEnum) {
		const url = `${GlobalSettings.listsApi}/GenericTemplates?templateType=${templateType.toString()}`;
		const data = await ajaxUtil.get<TemplateDefinitionDto[]>(url);
		this.setState({
			genericTemplatesList: data,
			genericTemplateId: '',
		});
	}

	private async handleValueChange(
		value: boolean | number | string | TemplateTypesEnum | TemplateEventActionEnum,
		statePropName: keyof Template
	) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			if (statePropName === 'templateType') {
				const tempTemplate = { ...this.state.template };

				if (value === TemplateTypesEnum.Event) {
					tempTemplate.relevantValue = false;
				} else {
					tempTemplate.relevantValue = true;
				}

				tempTemplate.templateType = value as TemplateTypesEnum;
				this.initialTemplate = tempTemplate;

				this.setState({
					template: tempTemplate,
					genericTemplateId: '',
					unsavedData: true,
				});
			} else if (statePropName === 'generic') {
				if (value) {
					const tempTemplate = { ...this.state.template };

					const genericTemplate = this.state.genericTemplatesList.find((temp) => temp.id === value);

					tempTemplate.relevantValue = genericTemplate.relevantValue;
					tempTemplate.tripColor = genericTemplate.tripColor;
					tempTemplate.labelColor = genericTemplate.labelColor;
					tempTemplate.rank = genericTemplate.rank;

					if (genericTemplate.templateType === TemplateTypesEnum.Digital) {
						tempTemplate.countRelevantState = genericTemplate.countRelevantState;
						tempTemplate.durationCounter = genericTemplate.durationCounter;
						tempTemplate.showOnMap = genericTemplate.showOnMap;
						tempTemplate.relevantStateName = genericTemplate.relevantStateName;
						tempTemplate.relevantLabelColor = genericTemplate.relevantLabelColor;
						tempTemplate.relevantIconName = genericTemplate.relevantIconName;
						tempTemplate.nonRelevantStateName = genericTemplate.nonRelevantStateName;
						tempTemplate.nonRelevantLabelColor = genericTemplate.nonRelevantLabelColor;
						tempTemplate.nonRelevantIconName = genericTemplate.nonRelevantIconName;
						tempTemplate.graphColor = genericTemplate.graphColor;
					}

					if (genericTemplate.templateType === TemplateTypesEnum.Analog) {
						tempTemplate.showOnMap = genericTemplate.showOnMap;
						tempTemplate.durationCounter = genericTemplate.durationCounter;
						tempTemplate.graphColor = genericTemplate.graphColor;
						tempTemplate.maxValue = genericTemplate.maxValue;
						tempTemplate.minValue = genericTemplate.minValue;
						tempTemplate.unitOfMeasure = genericTemplate.unitOfMeasure;
						tempTemplate.numberOfStates = genericTemplate.numberOfStates;
						tempTemplate.templateAnalogStates = genericTemplate.templateAnalogStates.sort(
							(x, y) => x.rank - y.rank
						);
					}

					this.setState({
						template: tempTemplate,
						genericTemplateId: value as string,
						unsavedData: true,
					});
				} else {
					this.setState({
						template: {
							...this.initialTemplate,
							code: this.state.template.code,
							name: this.state.template.name,
							description: this.state.template.description,
						},
						genericTemplateId: value as string,
						unsavedData: true,
					});
				}
			} else {
				const tempTemplate = { ...this.state.template };
				if (statePropName === 'numberOfStates' && Number(value) > tempTemplate.templateAnalogStates.length) {
					tempTemplate.templateAnalogStates = [...this.newStates(Number(value))];
				} else if (
					statePropName === 'numberOfStates' &&
					Number(value) < tempTemplate.templateAnalogStates.length
				) {
					tempTemplate.templateAnalogStates = this.state.template.templateAnalogStates
						.sort((x, y) => x.rank - y.rank)
						.slice(0, Number(value));
				}

				tempTemplate[statePropName] = value;
				this.setState({
					template: tempTemplate,
					unsavedData: true,
				});
			}
		}
	}

	private handleColorChange(color: string, statePropName: string) {
		if (
			(statePropName === 'tripColor' && this.state.template.tripColor === color) ||
			(statePropName === 'labelColor' && this.state.template.labelColor === color) ||
			(statePropName === 'graphColor' && this.state.template.graphColor === color)
		) {
			return;
		}
		const tempTemplate = { ...this.state.template };
		if (statePropName === 'tripColor') {
			tempTemplate.tripColor = color;
		} else if (statePropName === 'labelColor') {
			tempTemplate.labelColor = color;
		} else if (statePropName === 'graphColor'){
			tempTemplate.graphColor = color;
		}

		this.setState({
			template: tempTemplate,
			unsavedData: true,
			genericTemplateId: '',
		});
	}

	private handleStateFieldsChange(value: boolean) {
		const tempTemplate = { ...this.state.template };
		tempTemplate.relevantValue = value;

		this.setState({
			template: tempTemplate,
			unsavedData: true,
			genericTemplateId: '',
		});
	}

	private setTemplate(valid: boolean, template: Template) {
		if (this.props.renderFor === RenderForEnum.Wizard || this.props.widgetState === WidgetStateEnum.Edit) {
			this.props.setTemplate(valid, template);
		}
	}

	private selectDefaultTripColor(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		this.handleColorChange('', 'tripColor');
	}

	private handleUnitFieldsChange(value: TemplateUnitOfMeasureEnum) {
		const tempTemplate = { ...this.state.template };
		tempTemplate.unitOfMeasure = value;

		this.setState({
			template: tempTemplate,
			unsavedData: true,
			genericTemplateId: '',
		});
	}

	private async handleCheckboxChange(checked: boolean) {
		const tempTemplate = { ...this.state.template };
		tempTemplate.active = checked;

		if (!checked && this.props.renderFor === RenderForEnum.Widget) {
			const response = await DialogUtil.confirm({
				content: TranslateText('fields.template.inactiveDescription'),
				title: TranslateText('fields.template.inactive'),
			});
			if (!response) {
				tempTemplate.active = !checked;
			}
		}

		this.setState({
			template: tempTemplate,
			unsavedData: true,
		});
	}

	private getActionList() {
		return !!(this.props.tripTypes & TripTypesEnum.Private)
			? this.eventActionList
			: this.eventActionList.filter((s) => s.id != TemplateEventActionEnum.PrivateBusiness);
	}
	private getTemplateTypes() {
		if (!this.props.allowTrackTypeSpecification || this.getActionList().length === 0) {
			this.templateTypes = this.templateTypes.filter((t) => t.id !== TemplateTypesEnum.Event);
		}
		return this.templateTypes;
	}
	private stateDefinitionsValues(start: number, end: number) {
		const result = [];
		for (let i = start; i <= end; i++) {
			result.push({
				id: i,
				display: i.toString(),
			});
		}
		return result;
	}

	private newStates(x: number): TemplateState[] {
		let array = [...this.state.template.templateAnalogStates];
		array = array.sort((x, y) => x.rank - y.rank);
		for (let i = array.length; i < x; i++) {
			array.push(new TemplateState());
		}
		for (let i = 0; i < x; i++) {
			array[i].rank = i;
			if (array[i].name === '') {
				array[i].name = this.defaultValueStateName[i].display;
			}
		}
		return array;
	}
	private eventTypeData() {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.eventAction}
						dataSource={this.getActionList()}
						name="eventAction"
						disabled={this.props.readOnly}
						label={TranslateText('fields.action')}
						onChange={({ value }) => this.handleValueChange(value as string, 'eventAction')}
					/>
					<ValidationMessage result={this.state.validationResult?.eventAction} />
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.relevantValue}
						dataSource={this.stateLowHigh}
						name="relevantValue"
						disabled={this.props.readOnly}
						label={TranslateText('fields.template.privateState')}
						onChange={({ value }) => this.handleStateFieldsChange(value as boolean)}
					/>
					<ValidationMessage result={this.state.validationResult?.relevantValue} />
				</div>
				{this.rankData()}
				<div className="form-group">
					<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.tripColor')}
					</label>
					<ColorPicker
						initialColor={this.state.template.tripColor ? this.state.template.tripColor : ''}
						onColorChangedHandler={(color: string) => this.handleColorChange(color, 'tripColor')}
						disabled={this.props.readOnly}
					/>
					<span style={{ float: 'right' }}>
						<Button
							disabled={this.props.readOnly}
							hidden={this.props.readOnly}
							onClick={(e) => this.selectDefaultTripColor(e)}
						>
							{TranslateText('fields.default')}
						</Button>
					</span>
				</div>
				<div className="form-group">
					<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.labelColor')}
					</label>
					<ColorPicker
						initialColor={this.state.template.labelColor ? this.state.template.labelColor : ''}
						onColorChangedHandler={(color: string) => this.handleColorChange(color, 'labelColor')}
						disabled={this.props.readOnly}
					/>
				</div>
			</div>
		);
	}

	private digitalTypeData() {
		return (
			<div className={'digital-type-view'}>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.relevantValue}
						dataSource={this.stateLowHigh}
						name="relevantValue"
						disabled={this.props.readOnly}
						label={TranslateText('fields.template.relevantState')}
						onChange={({ value }) => this.handleStateFieldsChange(value as boolean)}
					/>
					<ValidationMessage result={this.state.validationResult?.relevantValue} />
				</div>
				<div className="form-group">
					<YesNoCombobox
						value={this.state.template.countRelevantState}
						name={'countRelevantState'}
						readonly={this.props.readOnly}
						placeholder={TranslateText('fields.template.countRelevantState')}
						change={(value) => this.handleValueChange(value as boolean, 'countRelevantState')}
						validationResult={this.state.validationResult}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						value={this.state.template.durationCounter}
						name={'durationCounter'}
						readonly={this.props.readOnly}
						placeholder={TranslateText('fields.template.durationCounter')}
						change={(value) => this.handleValueChange(value as boolean, 'durationCounter')}
						validationResult={this.state.validationResult}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						value={this.state.template.showOnMap}
						name={'showOnMap'}
						readonly={this.props.readOnly}
						placeholder={TranslateText('fields.template.showOnMap')}
						change={(value) => this.handleValueChange(value as boolean, 'showOnMap')}
						validationResult={this.state.validationResult}
					/>
				</div>
				{this.rankData()}
				<div className="form-group">
					<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.graphColor')}
					</label>
					<ColorPicker
						initialColor={this.state.template.graphColor ? this.state.template.graphColor : ''}
						onColorChangedHandler={(color: string) => this.handleColorChange(color, 'graphColor')}
						disabled={this.props.readOnly}
					/>
				</div>
				{this.state.template.showOnMap && (
					<div className="form-group">
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
							{TranslateText('fields.template.tripColor')}
						</label>
						<ColorPicker
							initialColor={this.state.template.tripColor ? this.state.template.tripColor : ''}
							onColorChangedHandler={(color: string) => this.handleColorChange(color, 'tripColor')}
							disabled={this.props.readOnly}
						/>
						<span style={{ float: 'right' }}>
							<Button
								disabled={this.props.readOnly}
								hidden={this.props.readOnly}
								onClick={(e) => this.selectDefaultTripColor(e)}
							>
								{TranslateText('fields.default')}
							</Button>
						</span>
					</div>
				)}
				<div className={'relevant-state-fields'}>
					<div style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.relevantState')}
					</div>
					<div className="form-group">
						<TextField
							id="relevantStateName"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.template.stateName')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="relevantStateName"
							value={this.state.template.relevantStateName}
							onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
								if (args.target) {
									this.handleValueChange(args.target.value, 'relevantStateName');
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.relevantStateName} />
					</div>
					<div className="form-group">
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
							{TranslateText('fields.template.labelFleetTagColor')}
						</label>
						<ColorPicker
							initialColor={this.state.template.relevantLabelColor ?? ''}
							onColorChangedHandler={(color: string) =>
								this.handleValueChange(color, 'relevantLabelColor')
							}
							disabled={this.props.readOnly}
						/>
					</div>
					<div className="form-group" style={{ display: 'flex' }}>
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1, marginTop: 'auto' }}>
							{TranslateText('fields.template.labelFleetTagIconName')}
						</label>
						<div style={{ marginLeft: 8 }}>
							<IconPickerWrapper
								iconName={this.state.template.relevantIconName}
								onIconSelected={(name, content, iconSet) =>
									this.handleValueChange(
										iconNoImage.name === name ? null : `${iconSet}/${name}`,
										'relevantIconName'
									)
								}
								readOnly={this.props.readOnly}
								containers={['sensor-templates']}
								allowNoImage={true}
							/>
						</div>
					</div>
				</div>
				<div className={'relevant-state-fields'}>
					<div style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.nonRelevantState')}
					</div>
					<div className="form-group">
						<TextField
							id="nonRelevantStateName"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.template.stateName')}
							inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
							name="nonRelevantStateName"
							value={this.state.template.nonRelevantStateName}
							onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
								if (args.target) {
									this.handleValueChange(args.target.value, 'nonRelevantStateName');
								}
							}}
							disabled={this.props.readOnly}
						/>
						<ValidationMessage result={this.state.validationResult?.nonRelevantStateName} />
					</div>
					<div className="form-group">
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
							{TranslateText('fields.template.labelFleetTagColor')}
						</label>
						<ColorPicker
							initialColor={this.state.template.nonRelevantLabelColor ?? ''}
							onColorChangedHandler={(color: string) =>
								this.handleValueChange(color, 'nonRelevantLabelColor')
							}
							disabled={this.props.readOnly}
						/>
					</div>
					<div className="form-group" style={{ display: 'flex' }}>
						<label style={{ opacity: this.props.readOnly ? 0.5 : 1, marginTop: 'auto' }}>
							{TranslateText('fields.template.labelFleetTagIconName')}
						</label>
						<div style={{ marginLeft: 8 }}>
							<IconPickerWrapper
								iconName={this.state.template.nonRelevantIconName}
								onIconSelected={(name, content, iconSet) =>
									this.handleValueChange(
										iconNoImage.name === name ? null : `${iconSet}/${name}`,
										'nonRelevantIconName'
									)
								}
								readOnly={this.props.readOnly}
								containers={['sensor-templates']}
								allowNoImage={true}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private analogTypeData() {
		return (
			<div>
				<div className="form-group">
					<YesNoCombobox
						value={this.state.template.durationCounter}
						name={'durationCounter'}
						readonly={this.props.readOnly}
						placeholder={TranslateText('fields.template.durationCounter')}
						change={(value) => this.handleValueChange(value as boolean, 'durationCounter')}
						validationResult={this.state.validationResult}
					/>
				</div>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.unitOfMeasure}
						dataSource={this.unitValues}
						name="unit"
						disabled={this.props.readOnly}
						label={TranslateText('fields.template.unit')}
						onChange={({ value }) => this.handleUnitFieldsChange(value as TemplateUnitOfMeasureEnum)}
					/>
					<ValidationMessage result={this.state.validationResult?.unitOfMeasure} />
				</div>
				<div className="form-group">
					<TextField
						id="templateMaxValue"
						type="number"
						fullWidth
						className="resize-font noSpinner"
						label={TranslateText('fields.template.maxValue')}
						inputProps={{ style: { fontSize: 12 } }}
						name="maxValue"
						value={this.state.template.maxValue ?? 0}
						onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
							if (args.target) {
								this.handleValueChange(args.target.value, 'maxValue');
							}
						}}
						disabled={this.props.readOnly}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
					/>
					<ValidationMessage result={this.state.validationResult?.maxValue} />
				</div>
				<div className="form-group">
					<TextField
						id="templateMinValue"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.template.minValue')}
						inputProps={{ style: { fontSize: 12 } }}
						name="minValue"
						value={this.state.template.minValue ?? 0}
						onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
							if (args.target) {
								this.handleValueChange(args.target.value, 'minValue');
							}
						}}
						disabled={this.props.readOnly}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
					/>
					<ValidationMessage result={this.state.validationResult?.minValue} />
				</div>
				{this.rankData()}
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.numberOfStates}
						dataSource={this.stateDefinitionsValues(2, 5)}
						name="numberOfStates"
						disabled={this.props.readOnly}
						label={TranslateText('fields.template.numberOfStates')}
						onChange={({ value }) => this.handleValueChange(value as number, 'numberOfStates')}
					/>
					<ValidationMessage result={this.state.validationResult?.numberOfStates} />
				</div>
				<div className="form-group">
					<label style={{ opacity: this.props.readOnly ? 0.5 : 1 }}>
						{TranslateText('fields.template.graphColor')}
					</label>
					<ColorPicker
						initialColor={this.state.template.graphColor ? this.state.template.graphColor : ''}
						onColorChangedHandler={(color: string) => this.handleColorChange(color, 'graphColor')}
						disabled={this.props.readOnly}
					/>
				</div>
				<div className="form-group">
					<YesNoCombobox
						value={this.state.template.showOnMap}
						name={'showOnMap'}
						readonly={this.props.readOnly}
						placeholder={TranslateText('fields.template.showOnMap')}
						change={(value) => this.handleValueChange(value as boolean, 'showOnMap')}
						validationResult={this.state.validationResult}
					/>
				</div>
			</div>
		);
	}

	private durationTypeData() {
		return (
			<div>
				<div className="form-group">
					<MaterialAutocomplete
						valueId={this.state.template.relevantValue}
						dataSource={this.stateLowHigh}
						name="relevantValue"
						disabled={this.props.readOnly}
						label={TranslateText('fields.template.relevantState')}
						onChange={({ value }) => this.handleStateFieldsChange(value as boolean)}
					/>
					<ValidationMessage result={this.state.validationResult?.relevantValue} />
				</div>
				{this.rankData()}
			</div>
		);
	}

	private rankData() {
		return (
			<div className="form-group">
				<TextField
					id="templateRank"
					type="number"
					className={`resize-font noSpinner`}
					fullWidth
					label={TranslateText('fields.template.rank')}
					inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
					name="rank"
					value={this.state.template.rank ?? 0}
					onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
						if (args.target) {
							this.handleValueChange(args.target.value, 'rank');
						}
					}}
					disabled={this.props.readOnly}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
				<ValidationMessage result={this.state.validationResult?.rank} />
			</div>
		);
	}

	private systemInformation() {
		return (
			<Scrollbar
				native={true}
				className={this.props.renderFor === RenderForEnum.Widget ? '' : 'view-section-scroll'}
			>
				<form id="templateViewSystemInformationForm" noValidate={true}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<TextField
								id="templateCode"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.code')}
								inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
								name="code"
								value={this.state.template.code?.trimStart()}
								onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
									if (args.target) {
										this.handleValueChange(args.target.value, 'code');
									}
								}}
								disabled={this.props.readOnly}
							/>
							<ValidationMessage result={this.state.validationResult?.code} />
						</div>
						<div className="form-group">
							<TextField
								id="name"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.name')}
								inputProps={{ readOnly: this.props.readOnly, style: { fontSize: 12 } }}
								name="name"
								value={this.state.template.name?.trimStart()}
								onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
									if (args.target) {
										this.handleValueChange(args.target.value, 'name');
									}
								}}
								disabled={this.props.readOnly}
							/>
							<ValidationMessage result={this.state.validationResult?.name} />
						</div>
						<CustomerNameDisplay
							customerId={
								this.props.defaultCustomerId.length > 0
									? this.props.defaultCustomerId
									: this.state.template.customerId
							}
						/>
						<div className="form-group">
							<TextField
								id="description"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.description')}
								inputProps={{ style: { fontSize: 12 } }}
								name="description"
								value={this.state.template.description?.trimStart()}
								onChange={(args: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
									if (args.target) {
										this.handleValueChange(args.target.value, 'description');
									}
								}}
								disabled={this.props.readOnly}
								multiline={true}
							/>
							<ValidationMessage result={this.state.validationResult?.description} />
						</div>
						<div
							className="form-group"
							style={this.props.renderFor === RenderForEnum.Wizard ? { visibility: 'hidden' } : {}}
						>
							<FormControlLabel
								control={
									<Checkbox
										name="active"
										color={'primary'}
										checked={this.state.template.active}
										readOnly={this.props.readOnly}
										onChange={(args: ChangeEvent<HTMLInputElement>) =>
											this.handleCheckboxChange(args.target.checked)
										}
										disabled={this.props.readOnly}
									/>
								}
								label={
									<Typography style={{ fontSize: 12, marginRight: 5 }}>
										{TranslateText('fields.active')}
									</Typography>
								}
								labelPlacement="start"
								style={{ margin: 0 }}
							/>
						</div>
					</div>
				</form>
			</Scrollbar>
		);
	}

	private templateData() {
		return (
			<Scrollbar
				native={true}
				className={this.props.renderFor === RenderForEnum.Widget ? '' : 'view-section-scroll'}
			>
				<form id="templateDataForm" noValidate={true}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<MaterialAutocomplete
								valueId={this.state.template.templateType}
								dataSource={this.getTemplateTypes()}
								name="templateType"
								disabled={this.props.renderFor === RenderForEnum.Widget || this.props.readOnly}
								label={TranslateText('fields.type')}
								onChange={({ value }) =>
									this.handleValueChange(value as TemplateTypesEnum, 'templateType')
								}
							/>
							<ValidationMessage result={this.state.validationResult?.templateType} />
						</div>
						{!this.props.informationWidget && this.props.widgetState === WidgetStateEnum.Edit ? (
							<div className="form-group">
								<MaterialAutocomplete
									valueId={this.state.genericTemplateId}
									dataSource={this.state.genericTemplatesList}
									name="generic"
									disabled={this.props.readOnly || !this.state.template.templateType}
									label={TranslateText('fields.generic')}
									onChange={({ value }) => this.handleValueChange(value as string, 'generic')}
								/>
							</div>
						) : (
							<div></div>
						)}
						<hr className="line" />
						{this.state.template.templateType === TemplateTypesEnum.Event
							? this.eventTypeData()
							: this.state.template.templateType === TemplateTypesEnum.Digital
							? this.digitalTypeData()
							: this.state.template.templateType === TemplateTypesEnum.Analog
							? this.analogTypeData()
							: this.state.template.templateType === TemplateTypesEnum.Duration
							? this.durationTypeData()
							: null}
					</div>
				</form>
			</Scrollbar>
		);
	}

	private renderForWizard() {
		return (
			<div className="pannel">
				<div className="column" style={{ width: '49%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
							{this.systemInformation()}
						</AccordionSection>
					</Accordion>
				</div>
				<div className="column" style={{ width: '49%' }}>
					<Accordion>
						<AccordionSection expanded={true} header={TranslateText('common.templateData')}>
							{this.templateData()}
						</AccordionSection>
					</Accordion>
				</div>
			</div>
		);
	}

	private renderForWidget() {
		return this.props.informationWidget ? (
			<div style={{ width: '100%' }}>
				<Accordion>
					<AccordionSection expanded={true} header={TranslateText('common.systemInformation')}>
						{this.systemInformation()}
					</AccordionSection>
				</Accordion>
			</div>
		) : (
			<div style={{ width: '100%', height: '100%' }}>
				<Accordion>
					<AccordionSection expanded={true} header={TranslateText('common.templateData')}>
						{this.templateData()}
					</AccordionSection>
				</Accordion>
			</div>
		);
	}

	render() {
		return this.props.renderFor === RenderForEnum.Wizard ? this.renderForWizard() : this.renderForWidget();
	}
}

export default TemplateView;
