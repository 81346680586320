import AlertAnalogTriggerConditionEnum from 'models/AlertAnalogTriggerConditionEnum';
import AlertTypesEnum from 'models/AlertTypesEnum';
import AnalogAlertMetadata from 'models/AnalogAlertMetadata';
import { useEffect, useMemo, useState } from 'react';
import { ValidateResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

const useAnalogAlerts = (alertType: AlertTypesEnum, analogAlertMetadata?: AnalogAlertMetadata) => {
	const [validationResult, setValidationResult] = useState<ValidateResult>();
	const validator = useMemo(
		() =>
			alertType === AlertTypesEnum.Analog
				? new Validator(
						{
							fieldRules: {
								templateAnalogId: {
									rules: {
										required: ValidatorFunctions.required(),
									},
								},
								triggerCondition: {
									rules: {
										required: ValidatorFunctions.required(),
									},
								},
								templateAnalogStateId: {
									rules: {
										requiredConditionally: {
											message: TranslateText('fieldsValidations.fieldRequired'),
											validationFunction: (data) => {
												const alertMetadata = data as AnalogAlertMetadata;
												return ValidatorFunctions.wrapInPromise(
													(alertMetadata.triggerCondition !==
														AlertAnalogTriggerConditionEnum.ToSpecificState &&
														alertMetadata.triggerCondition !==
															AlertAnalogTriggerConditionEnum.FromSpecificState) ||
														!!alertMetadata.templateAnalogStateId
												);
											},
										},
									},
								},
							},
						},
						true
				  )
				: new Validator({ fieldRules: {} }),
		[alertType]
	);

	useEffect(() => {
		if (alertType === AlertTypesEnum.Analog && analogAlertMetadata) {
			validator.validate(analogAlertMetadata).then((result) => {
				setValidationResult(result);
			});
		}
	}, [analogAlertMetadata]);

	return validationResult;
};

export { useAnalogAlerts };
