import './claimSelectionView.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AutoTextField from '../../components/AutoTextfield/AutoTextField';
import ClaimValuePair from '../../models/ClaimValuePair';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog/NotificationDialog';
import SelectionUtil from '../SelectionDialog/SelectionUtil';
import SelectionPanel from '../SelectionPanel';
import SelectionTree from '../SelectionTree';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import { StringConstants } from '../Widgets/CustomerFeaturesView';
import claimValueDictionary, { ClaimValueName } from '../../models/ClaimValuesDictionary';

interface Props {
	visible: boolean;
	currentSelection: ClaimValuePair[];
	selectionChanged: (data: ITreeNode[]) => void;
	isFromWizard?: boolean;
}

const ClaimSelectionView = (props: Props) => {
	const userProfile = useSelector((s: ApplicationState) => s.oidc.user.profile);
	const [data, setData] = useState<ITreeNode[]>(() => {
		const result: ITreeNode[] = [];
		const claims: string[] = [];
		for (const name in userProfile) {
			if (
				userProfile.hasOwnProperty(name) &&
				name.startsWith(StringConstants.easytrack) &&
				name.split('.').length === 2
			) {
				claims.push(name);
			}
		}
		claims.sort();
		claims.forEach((claimName) => {
			const values: string[] = userProfile[claimName].split(',');
			const claimValues = values
				.map((value: string) => {
					const claimValueName: ClaimValueName = value.toLowerCase() as ClaimValueName;

					return {
						claimName: claimValueName,
						claimValue: claimValueDictionary[claimValueName],
					};
				})
				.sort((a, b) => b.claimValue - a.claimValue);

			const node: ITreeNode = {
				id: claimName,
				childCount: values.length,
				text: TranslateText(claimName),
				active: true,
				type: 'claim',
				canBeChecked: true,
				selected: false,
				selectedCount: 0,
				items: claimValues.map((value) => ({
					id: `${claimName}/${value.claimName}`,
					childCount: 0,
					text: TranslateText(`${claimName}/${value.claimName}`),
					value: value.claimValue,
					parentId: claimName,
					active: true,
					type: 'claimValue',
					selected: false,
					canBeChecked: true,
				})),
			};
			node.items.forEach((i) => (i.parent = node));
			result.push(node);
		});
		return result;
	});
	const [filter, setFilter] = useState('');
	const [selectedItems, setSelectedItems] = useState<ITreeNode[]>([]);

	useEffect(() => {
		//Reset selection to default, or clear
		const newData = [...data];
		if (props.currentSelection && props.currentSelection.length) {
			props.currentSelection.forEach((selection) => {
				const claimNode = newData.find((a) => a.id === selection.claim);
				if (claimNode) {
					const claimValueNode = claimNode.items.find(
						(n) => n.id.toLowerCase() === `${selection.claim}/${selection.values}`.toLowerCase()
					);
					if (claimValueNode && !claimValueNode.selected) {
						claimValueNode.selected = true;
						claimNode.selectedCount++;
					}
				}
			});
			newData.forEach((c) => {
				if (c.items && c.items.every((i) => i.selected)) {
					c.selected = true;
				}
			});
		} else {
			newData.forEach((n) => {
				n.selected = false;
				n.selectedCount = 0;
				n.items.forEach((i) => (i.selected = false));
			});
		}
		setData(newData);
		setSelectedItems(SelectionUtil.getSelection(newData));
		setFilter('');
	}, [props.currentSelection]);

	useEffect(() => {
		const textToSearchFor = filter.toLocaleLowerCase();
		setData(
			data.map((d) => {
				d.hidden =
					d.text.toLowerCase().indexOf(textToSearchFor) < 0 &&
					d.items.every((i) => i.text.toLowerCase().indexOf(textToSearchFor) < 0);
				return d;
			})
		);
	}, [filter]);

	useEffect(() => {
		if (!props.isFromWizard) {
			props.selectionChanged(selectedItems);
		}
	}, [selectedItems]);

	const events = {
		expandCallback: useCallback(
			(treeNode: ITreeNode) => {
				setData(
					data.map((node) => {
						if (node.id === treeNode.id) {
							node.expanded = !node.expanded;
						}
						return node;
					})
				);
			},
			[data]
		),
		selectCallback: useCallback(
			(treeNode: ITreeNode) => {
				const newData = [...data];
				const node = SelectionUtil.find(newData, treeNode.id);
				if (node) {
					node.selected = !node.selected;
					if (node.parent) {
						const parent = node.parent;
						const parentItems = parent.items;
						const valueIndex = parentItems.indexOf(node);
						if (node.selected) {
							for (let i = valueIndex; i < parentItems.length; i++) {
								if (node.value > parentItems[i].value) {
									parentItems[i].selected = node.selected;
								}
							}
						} else {
							for (let i = 0; i < valueIndex; i++) {
								if (node.value < parentItems[i].value && parentItems[i].selected) {
									parentItems[i].selected = node.selected;
								}
							}
						}

						//Select parent if all items are selected;
						const selectedCount = parentItems.filter((i) => i.selected).length;
						if (selectedCount === parentItems.length) {
							parent.selected = true;
							parent.selectedCount = 0;
						} else {
							parent.selected = false;
							parent.selectedCount = selectedCount;
						}
					} else {
						//this is a claim type
						if (node.items) {
							node.items.forEach((i) => {
								i.selected = node.selected;
							});
							node.selectedCount = node.selected ? node.items.length : 0;
						}
					}
				}

				const newSelectedItems = SelectionUtil.getSelection(newData);
				setData(newData);
				setSelectedItems(newSelectedItems);
				props.selectionChanged(newSelectedItems);
			},
			[data, props.selectionChanged]
		),
		removeAllCallback: useCallback(async () => {
			if (selectedItems.length) {
				const result = await DialogUtil.confirm({
					title: TranslateText('selectionDialog.clearAllTitle'),
					content: TranslateText('selectionDialog.clearAllContent'),
				});
				if (result) {
					const newData = [...data];
					newData.forEach((n) => {
						n.selected = false;
						n.selectedCount = 0;
						n.items.forEach((child) => (child.selected = false));
					});

					const newSelectedItems = SelectionUtil.getSelection(newData);
					setData(newData);
					setSelectedItems(newSelectedItems);

					//trigger selection changed
					props.selectionChanged(newSelectedItems);
				}
			}
		}, [data, selectedItems, props.selectionChanged]),
	};

	return (
		<div hidden={!props.visible} className={'claim-selection-view'}>
			<div className="selection-container">
				<AutoTextField
					placeHolder={TranslateText('claimDialog.search')}
					value={filter}
					showClearButton={true}
					debounceDuration={100}
					onChange={(value) => {
						setFilter(value ?? ''.toLowerCase().trim());
					}}
				/>
				<SelectionTree
					data={data.map((d) => ({ ...d, text: TranslateText(d.id) }))}
					expandCallback={events.expandCallback}
					selectCallback={events.selectCallback}
				/>
			</div>
			<SelectionPanel
				selection={selectedItems}
				removeAll={events.removeAllCallback}
				removeItem={events.selectCallback}
				translationNamespace={'claimDialog'}
			/>
		</div>
	);
};

export default ClaimSelectionView;
