import './ReportOverview.scss';

import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { availableCustomersActions } from 'store/AvailableCustomers';
import { unsavedDataStoreActionCreators } from 'store/UnsavedDataStore';

import { Tab, Tabs } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import EntityData from '../../models/EntityData';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog/NotificationDialog';
import FavoriteReportsOverview from './FavoriteReportsOverview';
import GeneratedReportsOverview from './GeneratedReportsOverview';
import NewReport from './NewReport/NewReport';
import PlannedReportsOverview from './PlannedReportsOverview';
import RecentReportsOverview from './RecentReportsOverview';
const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user.access_token,
		userId: state.currentSession.aspNetUserId,
		personId: state.currentSession.personId,
		currentUserName: state.currentSession.userFirstAndLastName,
		currentUserIsDriver: state.currentSession.isDriver,
		customerLevel: state.currentSession.customerLevel,
		take: state.currentSession.defaultLines,
		customerId: state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId,
		user: state.oidc.user,
		isFavorite: state.reportStore.isFavorite,
		isPlanned: state.reportStore.isPlanned,
		driverIdentification: state.currentSession.customer?.featuresSettings.driverIdentification,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
		setUnsavedDataInStore: bindActionCreators(unsavedDataStoreActionCreators.setUnsavedData, dispatch),
	};
}

const connector = connect(mapState, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ReportsProps = PropsFromRedux & {
	location: {
		state: {
			selectedIndex?: number;
			resetGeneratedReportsFiltersTrigger?: boolean;
		}
	}
};
type ReportsState = {
	unsavedData: boolean;
	selectedIndex: number;
	isDialogOpen: boolean;
	resetGeneratedReportsFiltersTrigger: boolean;
};

class ReportsOverview extends React.PureComponent<ReportsProps, ReportsState> {
	constructor(props: ReportsProps) {
		super(props);
		const { state } = this.props.location;

		this.state = {
			unsavedData: false,
			selectedIndex: state && state.selectedIndex ? state.selectedIndex : 0,
			isDialogOpen: false,
			resetGeneratedReportsFiltersTrigger: state && state.resetGeneratedReportsFiltersTrigger ? state.resetGeneratedReportsFiltersTrigger : false,
		};
	}

	componentDidMount() {
		if (
			!ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.edit],
			})
		) {
			this.setState({ selectedIndex: 4 });
		}
	}

	componentDidUpdate(prevProps: Readonly<ReportsProps>, prevState: Readonly<ReportsState>) {
		if (this.state.unsavedData !== prevState.unsavedData) {
			this.handleUnsavedDataCallback(this.state.unsavedData);
		}
	}

	getCurrentUserEntityData(): EntityData[] {
		const entity = {
			id: this.props.personId,
			entityType: this.props.currentUserIsDriver ? EntityTypeEnum.Driver : EntityTypeEnum.Person,
			text: this.props.currentUserName,
			active: true,
			childCount: 0,
		};
		return [entity];
	}

	redirectToTab(tabIndex: number, resetGeneratedReportsFilters?: boolean) {
		this.setState({
			selectedIndex: tabIndex,
			...(resetGeneratedReportsFilters && {
				resetGeneratedReportsFiltersTrigger: !this.state.resetGeneratedReportsFiltersTrigger,
			}),
		});
	}

	handleUnsavedDataCallback(unsavedData: boolean) {
		this.props.setUnsavedDataInStore(unsavedData);
		this.setState({
			unsavedData: unsavedData,
		});
	}

	reloadGrid(e: any) {
		this.setState({
			selectedIndex: e.selectedIndex,
		});
	}

	notifyAboutUnsavedData(newValue: number) {
		if (this.state.isDialogOpen) {
			return;
		}

		this.setState({
			isDialogOpen: true,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleUnsavedData'),
			content: TranslateText('notificationMessages.navigateAway'),
		}).then((change) => {
			this.setState({
				isDialogOpen: false,
			});
			if (change) {
				this.setState({ selectedIndex: newValue, unsavedData: false });
				if (newValue === 0) {
					this.props.availableCustomersActions.toggleSelection(false);
				} else {
					this.props.availableCustomersActions.toggleSelection(true);
				}
			} else {
				this.props.availableCustomersActions.toggleSelection(false);
			}
		});
	}

	render() {
		return (
			<div className="reports-overview-content content-container reporting-pages">
				<Tabs
					value={this.state.selectedIndex}
					onChange={(e, newValue: number) => {
						if (
							(this.state.selectedIndex === 0 || this.props.isPlanned || this.props.isFavorite) &&
							this.state.unsavedData
						) {
							if (newValue !== 0) {
								this.notifyAboutUnsavedData(newValue);
							}
						} else {
							this.setState({ selectedIndex: newValue, unsavedData: false });
							if (newValue === 0) {
								this.props.availableCustomersActions.toggleSelection(false);
							} else {
								this.props.availableCustomersActions.toggleSelection(true);
							}
						}
					}}
				>
					<Tab
						hidden={
							!ClaimUtil.validateClaim(this.props.user, {
								claim: ClaimType.Reporting,
								values: [ClaimValue.edit],
							})
						}
						value={0}
						label={
							this.props.isPlanned || this.props.isFavorite
								? TranslateText('reports.editReport')
								: TranslateText('reports.addReport')
						}
						className={'reports-icon'}
					/>
					<Tab
						hidden={
							!ClaimUtil.validateClaim(this.props.user, {
								claim: ClaimType.Reporting,
								values: [ClaimValue.edit],
							})
						}
						value={1}
						label={TranslateText('reports.favorite')}
						className={'favorite-reports-icon'}
					/>
					<Tab value={2} label={TranslateText('reports.planned')} className={'planned-reports-icon'} />
					<Tab
						hidden={
							!ClaimUtil.validateClaim(this.props.user, {
								claim: ClaimType.Reporting,
								values: [ClaimValue.edit],
							})
						}
						value={3}
						label={TranslateText('reports.mostRecent')}
						className={'mostrecent-reports-icon'}
					/>
					<Tab value={4} label={TranslateText('reports.generated')} className={'generated-reports-icon'} />
					{/* <Tab
							hidden={
								!ClaimUtil.validateClaim(this.props.user, {
									claim: ClaimType.Reporting,
									values: [ClaimValue.editDesigner],
								})
							}
							value={5}
							label={TranslateText('reports.reportDesigner')}
							className={'designer-reports-icon'}
						/> */}
				</Tabs>
				<div hidden={this.state.selectedIndex !== 0}>
					<NewReport
						active={this.state.selectedIndex === 0}
						redirectAfterSaveCallback={this.redirectToTab.bind(this)}
						unsavedDataCallback={this.handleUnsavedDataCallback.bind(this)}
						getCurrentUserEntityDataCallback={this.getCurrentUserEntityData.bind(this)}
					/>
				</div>
				<div hidden={this.state.selectedIndex !== 1}>
					<FavoriteReportsOverview
						focus={this.state.selectedIndex === 1}
						redirectOnEditCallback={this.redirectToTab.bind(this)}
						take={this.props.take}
						customerId={this.props.customerId}
						driverIdentification={this.props.driverIdentification}
					></FavoriteReportsOverview>
				</div>
				<div hidden={this.state.selectedIndex !== 2}>
					<PlannedReportsOverview
						focus={this.state.selectedIndex === 2}
						take={this.props.take}
						customerId={this.props.customerId}
						customerLevel={this.props.customerLevel}
						redirectOnEditCallback={this.redirectToTab.bind(this)}
						driverIdentification={this.props.driverIdentification}
					></PlannedReportsOverview>
				</div>
				<div hidden={this.state.selectedIndex !== 3}>
					<RecentReportsOverview
						focus={this.state.selectedIndex === 3}
						take={this.props.take}
						customerId={this.props.customerId}
						driverIdentification={this.props.driverIdentification}
					></RecentReportsOverview>
				</div>
				<div hidden={this.state.selectedIndex !== 4}>
					<GeneratedReportsOverview
						focus={this.state.selectedIndex === 4}
						take={this.props.take}
						driverIdentification={this.props.driverIdentification}
						resetFiltersTrigger={this.state.resetGeneratedReportsFiltersTrigger}
					></GeneratedReportsOverview>
				</div>
			</div>
		);
	}
}

export default connector(ReportsOverview);
