import './styles.scss';

import React from 'react';

import TemplateUnitOfMeasureEnum from '../../../models/TemplateUnitOfMeasureEnum';
import { FormatDate, GetHourMinutesAndSeconds } from '../../../utils/DateUtils';
import { TranslateText } from '../../../utils/Translations';

export const isDateToday = (dateString: string) => {
	const providedDate = new Date(dateString);
	const currentDate = new Date();
	providedDate.setHours(0, 0, 0, 0);
	currentDate.setHours(0, 0, 0, 0);

	return providedDate.getTime() === currentDate.getTime();
};
const ChartTooltip = ({ active, payload, label }: any) => {
	const getValue = (p: any) => {
		if (p.name.includes('speed')) {
			return (
				<>
					<span className="intro" style={{ color: p.color }}>{`${TranslateText('chartValue.speed')}: ${
						p.value
					} km\\h`}</span>
					<br />
				</>
			);
		} else if (p.name.includes('analog')) {
			switch (p.payload[`unitOfMeasure${p.name}`]) {
				case TemplateUnitOfMeasureEnum.Percentage:
					return (
						<>
							<span className="intro" style={{ color: p.color }}>{`${TranslateText(
								'chartValue.percentage'
							)}: ${p.value} %`}</span>
							<br />
						</>
					);
				case TemplateUnitOfMeasureEnum.Temperature:
					return (
						<>
							<span className="intro" style={{ color: p.color }}>{`${TranslateText(
								'chartValue.temperature'
							)}: ${p.value} ℃`}</span>
							<br />
						</>
					);
				case TemplateUnitOfMeasureEnum.Voltage:
					return (
						<>
							<span className="intro" style={{ color: p.color }}>{`${TranslateText(
								'chartValue.voltage'
							)}: ${p.value} V`}</span>
							<br />
						</>
					);
				case TemplateUnitOfMeasureEnum.Distance:
					return (
						<>
							<span className="intro" style={{ color: p.color }}>{`${TranslateText(
								'chartValue.distance'
							)}: ${p.value} Km`}</span>
							<br />
						</>
					);
				case TemplateUnitOfMeasureEnum.Time:
					return (
						<>
							<span className="intro" style={{ color: p.color }}>{`${TranslateText('chartValue.time')}: ${
								p.value
							} m`}</span>
							<br />
						</>
					);
			}
		} else if (p.name.includes('digital')) {
			return (
				<>
					<span className="intro" style={{ color: p.color }}>{`${
						p.payload[`digitalNameForTooltip${p.name}`]
					}: ${
						p.payload[`digitalValueForTooltip${p.name}`] === 1
							? `${TranslateText('common.on')}`
							: `${TranslateText('common.off')}`
					}`}</span>
					<br />
				</>
			);
		}
	};

	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip">
				<div className="tooltip-background">
					<span className="label">{`${TranslateText('chartValue.time')}: ${GetHourMinutesAndSeconds(
						label,
						true
					)}`}</span>
					<br />
					{!isDateToday(label) ? (
						<>
							<span className="label">{`${TranslateText('chartValue.date')}: ${FormatDate(
								label,
								true,
								false,
								true
							)}`}</span>
							<br />
						</>
					) : null}
					{payload.map((p: any) => getValue(p))}
				</div>
			</div>
		);
	}
	return null;
};

export default ChartTooltip;
