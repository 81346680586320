import MainPowerEventTypesEnum from './MainPowerEventTypesEnum';

class MainPowerAlertMetadata {
	constructor() {
		this.mainPowerTriggerEvent = MainPowerEventTypesEnum.Both;
	}
	public mainPowerTriggerEvent?: MainPowerEventTypesEnum;
	[propName: string]: any;
}

export default MainPowerAlertMetadata;
